import {Component, Input, Output, EventEmitter} from '@angular/core';
import {LookupModel} from "../../core/lookup.model";

@Component({
  selector: 'app-dictionary-sneak-peek',
  templateUrl: './dictionary-sneak-peek.component.html',
})
export class DictionarySneakPeekComponent {

  constructor() {
  }

  @Input() lookup: LookupModel;

  @Output() closeRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();

  closeSide() {
    this.closeRequested.emit();
  }

}
