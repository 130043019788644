import * as shajs from 'sha.js';
import Query from "../../core/query/query";
import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

@Injectable({providedIn: 'root'})
export class QueryBuilder {

  private query: Query;

  constructor(
    private router: Router,
    private location: Location
  ) {
  }

  // added component name to make query hash unique for different tabs
  build(query: Query, key?: string, componentName?: string) {
    this.query = JSON.parse(JSON.stringify(query));
    if (key) {
      this.query.key = key;
    } else {
      this.query.key = this.createKey(this.query, new Date().toString());
    }
    return this;
  }

  navigate(route: ActivatedRoute, key?: string): Promise<any> {
    return this.router.navigate(
      [],
      {
        relativeTo: route,
        queryParams: {key: key || this.query.key},
        queryParamsHandling: 'merge'
      });
  }

  getQuery(): Query {
    return this.query;
  }

  // to avoid redirecting if no params are changed
  buildQueryAndNavigate({component, query, route, queryBuilder, queryToSet = undefined}) {
    this.build(query, null, component.COMPONENT_NAME)
    let url = this.location.path()
    let urlKey = url.indexOf('?key=') !== -1 ? url.substring(url.indexOf('?key=') + 5) : ''
    if (urlKey !== this.query.key) {
      component[queryToSet || 'query'] = new queryBuilder(this.query)
      this.navigate(route)
    }
  }

  createKey(query: Query, component = ''): string {
    return shajs('sha256').update(JSON.stringify({
      component,
      where: query.where || {}
    })).digest('hex')
  }

  applyEntry(entry: any): any {
    this.query = this.build(this.query).getQuery();

    const obsolete = ['isTrusted'];

    const nullProperties = Object.keys(entry).filter((key => entry[key] === null));
    const nonNullProperties = Object.keys(entry).filter((key => entry[key] !== null));

    nonNullProperties.forEach((key) => {
      this.query.where[key] = entry[key];
    });

    nullProperties.forEach((key) => {
      delete this.query.where[key];
    });

    obsolete.forEach((key) => {
      delete this.query.where[key];
    });

    return this;
  }

}
