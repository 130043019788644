import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import { MatDialogRef } from '@angular/material/dialog';

import {SortingBuilder, SortingService} from '../../../shared/sorting/sorting.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {IMessagesResourceService, ResourcesService} from '../../../core/resources/resources.service';
import { PageManageDialogComponent } from 'app/core/page-manage-dialog.component';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { FormControl } from '@angular/forms';
import { RandomService } from 'app/shared/random/random.service';
import { AlertItemExclusionGridService } from 'app/common-alert/core/alert-item-exclusion-grid.service';

@Component({
  selector: 'app-alert-pre-gl-exclusion-dialog',
  templateUrl: './alert-pre-gl-exclusion-dialog.component.html'
})

export class AlertPreGLExclusionDialogComponent extends PageManageDialogComponent implements AfterViewInit, OnInit {
  public items = [];
  public onExclusionConfirmed: Function;
  actionText = 'exclude';
  title = 'Exclude items';
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public note: FormControl;
  public itemSorting: SortingBuilder;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'invoice';
  deletePlaceholder = 'Type in generated string shown below';
  randomString = this.randomService.getRandomCheckString();
  placeHolder = 'Note';
  config : any ;

  constructor(
    public randomService: RandomService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<AlertPreGLExclusionDialogComponent>,
    public sortingService: SortingService,
    public alertService: AlertService,
    public alertItemExclusionGridService: AlertItemExclusionGridService) {
    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.itemSorting = this.sortingService.builder();
  }

  ngOnInit() {
    this.columns = this.alertItemExclusionGridService.columns();
    this.note = new FormControl('');
  }


  ngAfterViewInit(): void {
    this.setDialogContent();
  }

  close(deleted = false) {
    this.dialogRef.close({
      deleted
    });
  }

  delete() {
    this.onExclusionConfirmed({
      note: this.note.value,
      closeDialog: this.dialogRef.close.bind(this.dialogRef)
    });
  }

  setDialogContent() {
    if (!this.dialogRef.componentInstance['config']) {
      this.placeHolder = 'Note';
      this.alertItemExclusionGridService.create(this.dataGrid.instance, {
        noDataText: 'No data'
      });
    } else {
      this.placeHolder = 'Reason';
    }
  }
}
