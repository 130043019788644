import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReportService} from "./core/report.service";
import {ReportingService} from "../srm/core/srm.service";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [ReportService, ReportingService]
})
export class ReportModule { }
