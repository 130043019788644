import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DisputeListComponent} from "./dispute-list/dispute-list.component";
import { DisputeDetailsComponent } from './dispute-details/dispute-details.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: DisputeListComponent
      },
      {
        path: ':id/show',
        component: DisputeDetailsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class DisputeRoutingModule { }
