import {Address} from "../../address/core/address.model";

export class Customer {

  id: number;
  company: string;
  office_phone: string;
  account_number: string;
  master_customer_number: string;

  corporate_address_id: number;
  billing_address_id: number;
  billing_address: Address;
  corporate_address: Address;
  address: Address;
  corporate_address2: string;
  corporate_address3: string;
  billing_address2: string;
  billing_address3: string;


  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }

}
