<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{ formTitle }}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <form [formGroup]="form" class="ca-form">

    <div fxLayout="row" class="entry-list">
      <div fxFlex class="entry-list-item">
        <label style="width: 2px;">Id</label>
        <p>{{service.quote_service_id || 'N/A'}}</p>
      </div>
      <div fxFlex class="entry-list-item">
        <label>Vendor</label>
        <p>{{offer?.vendor?.name || 'N/A'}}</p>
      </div>
    </div>
    <div fxLayout="row" class="entry-list">
      <div fxFlex class="entry-list-item">
        <label>Medium</label>
        <p>{{offer.quote_service_medium?.value || 'N/A'}}</p>
      </div>
      <div fxFlex class="entry-list-item">
        <label>Interval</label>
        <p>{{offer.interval}} Days</p>
      </div>
    </div>
    <div class="flex-container" fxLayout="row">
      <div class="flex-item" fxFlex>
        
        <ca-default-picker>
          <mat-form-field caInput>
              <mat-select placeholder="Term" formControlName="term">

                  <mat-option [value]="4" *ngIf="offer.mtm_mrc!=null && offer.mtm_nrc!=null">
                    MTM {{ offer.mtm_mrc | caCurrency:"USD":"symbol-narrow":"1.2-2" }} / {{offer.mtm_nrc | caCurrency:"USD":"symbol-narrow":"1.2-2" }}
                  </mat-option>
        
                  <mat-option [value]="1" *ngIf="offer.one_year_mrc!=null && offer.one_year_nrc!=null">
                    1 Year {{ offer.one_year_mrc | caCurrency:"USD":"symbol-narrow":"1.2-2" }} / {{offer.one_year_nrc |
                    caCurrency:"USD":"symbol-narrow":"1.2-2" }}
                  </mat-option>
                  <mat-option [value]="2" *ngIf="offer.two_year_mrc!=null && offer.two_year_nrc!=null">
                    2 Year {{ offer.two_year_mrc | caCurrency:"USD":"symbol-narrow":"1.2-2" }} / {{offer.two_year_nrc |
                    caCurrency:"USD":"symbol-narrow":"1.2-2" }}
                  </mat-option>
                  <mat-option [value]="3" *ngIf="offer.three_year_mrc!=null && offer.three_year_nrc!=null">
                    3 Year {{ offer.three_year_mrc | caCurrency:"USD":"symbol-narrow":"1.2-2" }} / {{offer.three_year_nrc |
                    caCurrency:"USD":"symbol-narrow":"1.2-2"
                    }}
                  </mat-option>
        
        
                  <mat-option [value]="5" *ngIf="offer.five_year_mrc!=null && offer.five_year_nrc!=null">
                    5 Year {{ offer.five_year_mrc | caCurrency:"USD":"symbol-narrow":"1.2-2" }} / {{offer.five_year_nrc |
                    caCurrency:"USD":"symbol-narrow":"1.2-2" }}
                  </mat-option>
        
                  <mat-option [value]="6" *ngIf="offer.seven_year_mrc!=null && offer.seven_year_nrc!=null">
                    7 Year {{ offer.seven_year_mrc | caCurrency:"USD":"symbol-narrow":"1.2-2" }} / {{offer.seven_year_nrc |
                    caCurrency:"USD":"symbol-narrow":"1.2-2" }}
                  </mat-option>
        
                </mat-select>
          </mat-form-field>
        </ca-default-picker>

        
      </div>
    </div>

  </form>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button #cancelButton mat-button type="submit" (click)="closeDialog()">
    Cancel
  </button>
  <button #genericButton mat-button type="submit" [disabled]="form.invalid" (click)="onSubmit(form)">Confirm
  </button>
</mat-dialog-actions>
