import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { BaseService } from '../../core/base.service';
import { Role } from './role';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {ApiService} from "../../core/api";

@Injectable()
export class RolesService extends BaseService<Role> {

  constructor(
    public restangular: Restangular,
    private entityLock: EntityLockService,
    private apiService: ApiService) {
    super('role', restangular, entityLock);
  }

  save(data) {
    return this.apiService.post([this.name, 'save'], data)
  }

}
