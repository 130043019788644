<mat-toolbar color="accent" mat-scroll-shrink>

  <span class="sneak-peek-title">{{ account?.account?.account_no }}</span>

  <span class="example-fill-remaining-space"></span>

  <button mat-icon-button (click)="closeSide()">
    <ca-icon [key]="'CLOSE'"></ca-icon>
  </button>
</mat-toolbar>
<div class="ca-card ca-card-sneak-peek">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Vendor</label>
          <p>
            {{ account?.account?.vendor?.name || 'N/A'}}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Entity</label>
          <p>
            {{ account?.account?.entity?.name || 'N/A' }}
          </p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Status</label>
          <p>
            {{ account?.account?.status?.value || 'N/A'}}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Country</label>
          <p>
            {{ account?.account?.country?.alpha_2_code || 'N/A'}}
          </p>
        </div>
      </div>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Rule Name</label>
          <p>
            {{ account?.rule_name || 'N/A'}}
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

