
import {timer as observableTimer, interval as observableInterval,  Subscription ,  Observable } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntityLock } from './entity-lock';

@Component({
  selector: 'app-data-lock-alert',
  template: `
    <div class="mat-dialog-top-spacer"></div>

    <div class="ca-dialog-title">
      <h1 mat-dialog-title>Continue editing?</h1>
    </div>

    <div mat-dialog-content class="ca-dialog-content">
      <p>
        Time remaining 0:{{ closeCountdownSeconds < 10 ? '0' + closeCountdownSeconds : closeCountdownSeconds }}
      </p>
    </div>
    <mat-dialog-actions class="ca-dialog-action">
      <div class="example-fill-remaining-space">
      </div>

      <button mat-button type="submit" (click)="discard()">
        Cancel
      </button>

      <button mat-button type="submit" (click)="continue()">
        Continue
      </button>
    </mat-dialog-actions>
  `,
})
export class EntityLockAlertComponent implements OnInit {
  closeCountdownSeconds;
  closeInterval: Subscription;

  constructor(
    private dialogRef: MatDialogRef<EntityLockAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
  }

  ngOnInit() {
    this.closeInterval = observableInterval(1000).subscribe(
      () => {
        this.closeCountdownSeconds--;
      }
    );

    observableTimer(this.closeCountdownSeconds * 1000)
      .subscribe(() => {
        this.closeInterval.unsubscribe();
        this.dialogRef.close({status: EntityLock.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_TIMER});
      });
  }

  discard(): void {
    this.closeInterval.unsubscribe();
    this.dialogRef.close({
      status: EntityLock.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_USER
    });
  }

  continue(): void {
    this.dialogRef.close({
      status: EntityLock.DATA_LOCK_CLOSE_STATUS.TIME_EXTEND
    });
  }

}
