
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { Customer } from '../core/customer';
import { CustomerQuery } from '../core/customer.query';
import { PageListComponent } from '../../core/page-list.component';
import { CustomerService } from '../core/customer.service';
import { CustomerGridService } from '../core/customer-grid.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { AlertService } from '../../shared/alert/alert.service';
import { CustomerManageDialogComponent } from '../shared/customer-manage-dialog/customer-manage-dialog.component';
import { PageContext } from '../../core/page.context';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { DataLockDialogService } from '../../core/data-lock/data-lock-dialog.service';
import { CustomerFilterService } from '../core/customer-filter.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
})
export class CustomerListComponent extends PageListComponent implements AfterViewInit, OnInit {
  public query: CustomerQuery = new CustomerQuery({
    orderBy: [['company', 'ASC']]
  });

  public customers: Array<Customer>;
  public totalCustomers: number;
  public selection: Customer;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public sorting: any[][];

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'customer';
  readonly CORE_MODULE: string = 'core';

  constructor(public customerService: CustomerService,
              public dialogService: DialogService,
              public alertService: AlertService,
              public settingsService: UserSettingsService,
              public customerGridService: CustomerGridService,
              public loaderService: LoaderService,
              public dataLockDialogService: DataLockDialogService,
              public customerFilterService: CustomerFilterService) {
    super(new PageContext({
      name: 'app.customer.customer-list',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  showDetails() {
    let customer = this.selection;
    if (customer) {
      this.dialogService.open(CustomerManageDialogComponent, {
        customer: customer,
        viewOnly: true
      }, { width: '780px' })
        .afterClosed()
        .subscribe((result) => {
          this.afterModalClosed(result, customer);
        });
    }
  }

  afterModalClosed(result, customer) {
    if (result) {
      if (result.cancelByTimer || result.status == 2) {
        this.alertService.success('', this.coreMessages.get('TIMER_EXPIRED'));
      } else if (result.deleted) {
        this.loadData(this.query);
        this.selectFirstRow();
        setTimeout(() => {
          this.alertService.snackBar.dismiss();
          this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
        }, 255);
      } else if (result.id) {
        Object.assign(customer, result);
        this.dataGrid.instance.refresh();
        this.dataGrid.instance.repaint();
        this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
      }
    }
  }

  loadData(query?: CustomerQuery) {
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.customerService.findAllList(query)
      .subscribe((result) => {
        this.customers = result.items;
        this.query.total = this.totalCustomers = result.total;
        this.selectFirstRow();
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      },
        () => {
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        });
  }

  filterData(query: CustomerQuery) {
    this.query = query;
    this.loadData(query);
  }

  refresh() {
    this.loadData(this.query);
  }

  clearFilter() {
    this.query.where = null;
    this.loadData(this.query);
  }

  newCustomer() {
    this.dialogService.open(CustomerManageDialogComponent, {
      customer: {}
    }, { width: '780px' })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.refresh();
          this.alertService.success('', this.messages.get('CREATE_SUCCESS'));
        }
      });
  }

  editCustomer(customer) {
    this.customerService.findByIdForEdit(customer.id)
      .subscribe(res => {
        this.dialogService.edit(
          CustomerManageDialogComponent,
          {
            customer: res
          }, { width: '780px' })
          .subscribe((items: any) => {
            if (items) {
              let obs = observableOf(items);
              obs.pipe(mergeMap(x => x.afterClosed()))
                .subscribe((result) => {
                  this.afterModalClosed(result, customer);
                })
              // obs.pipe(mergeMap(x => x.afterClosed()))
              //   .subscribe((result) => {
              //     if (result) {
              //       this.refresh();
              //       this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
              //     }
              //   })
            }
          })
      })
  }

  csv() {

    const { customerService,customerGridService, query } = this;
    customerGridService.csvMap().subscribe(fields =>{
      customerService.exportToCSV(
        'customers',
        {
          fields,
          query
        }
      );
    })
  }

  onSelectionChanged(row) {
    this.selection = <Customer>row.selectedRowsData[0];
  }

  ngOnInit() {
    this._init();
  }

  ngAfterViewInit(): void {
    this.customerGridService.create(this.dataGrid.instance, {});
    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.customerGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService() {
    return this.customerGridService;
  }

  getDefaultSort() {
    return [['company', 'ASC']];
  }
}
