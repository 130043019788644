import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageListComponent } from '../../core/page-list.component';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { PageContext } from '../../core/page.context';
import {ActivatedRoute, Router} from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { AlertService } from '../../shared/alert/alert.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { TabGroup, TabService } from '../../shared/tabs/tab.service';
import Query from "../../core/query/query";
import { CommonAlertService } from '../core/common-alert.service';
import { AlertDisconnectGridService } from '../core/alert-disconnect-grid.service';
import { AuditAlertDisconnectAuditFilterService } from '../core/audit-alert-disconnect-audit-filter.service';
import {DisputeManageDialogComponent} from "../../dispute/shared/dispute-manage-dialog/dispute-manage-dialog.component";
import {InvoiceCharge} from "../../charge/core/invoice-charge";
import {DialogService} from "../../shared/dialog/dialog.service";
import {ChargeQuery} from "../../charge/core/charge.query";
import {InvoiceChargeService} from "../../charge/core/invoice-charge.service";
import query from "../../core/query/query";
import {InventoryService} from "../../inventory/core/inventory.service";
import {UserService} from "../../user/core/user.service";
import {LocationService} from "../../location/core/location.service";
import {DictionaryService} from "../../dictionary/core/dictionary.service";
import {LOOKUP_MODELS_ENUM} from "../../dictionary/core/lookup-models.enum";


@Component({
  selector: 'app-alert-disconnect',
  templateUrl: './alert-disconnect.component.html',
  styleUrls: ['./alert-disconnect.component.css']
})

export class AlertDisconnectComponent extends PageListComponent implements OnInit, AfterViewInit, OnDestroy {
  alert;
  public summary: any;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTab: number = 0;
  public query: Query = new Query({ orderBy: [['id', 'ASC']] });
  public queryFilter: Query = new Query();
  public dataSource: Array<any> = [];
  public columns: any;
  public inventoryQuery: Query = new Query({ orderBy: [['id', 'ASC']] });

  chargesSelectionActive = false;
  selectedCharges: Array<any> = [];
  addDisputeDisabled: boolean = true;
  delayChargesSelectionChange = false;

  charges: any
  chargeQuery: ChargeQuery = new ChargeQuery({orderBy: [['id', 'ASC']]});
  public dictionaryQuery: Query = new Query({ orderBy: [['id', 'ASC']] });


  @ViewChild('dataGrid', {static: false}) dataGrid: DxDataGridComponent;

  activeTabIndex: number = 0;

  public alertId: number;

  readonly PLACEHOLDERS = {
    OWNER_UNASSIGNED: 'Unassigned'
  };
  public DISPUTE_CATEGORY_LOOKUP_MODEL = LOOKUP_MODELS_ENUM.DISPUTE_CATEGORY.modelName;


  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'common-alert';
  public currentTabIndex = 0;

  constructor(public settingsService: UserSettingsService,
              public route: ActivatedRoute,
              public router: Router,
              public toastAlertService: AlertService,
              public loaderService: LoaderService,
              public tabService: TabService,
              public alertDisconnectGridService: AlertDisconnectGridService,
              public alertService: CommonAlertService,
              public auditAlertDisconnectAuditFilterService: AuditAlertDisconnectAuditFilterService,
              public commonAlertService: AlertService,
              public dialogService: DialogService,
              public chargeService: InvoiceChargeService,
              public inventoryService: InventoryService,
              public userService: UserService,
              public locationService: LocationService,
              public dictionaryService: DictionaryService,



  ) {
    super(new PageContext({
      name: 'app.alert.alert-disconnect',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }


  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    this.currentTabIndex = index;

    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }
  }

  public loadTabs() {
    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({ key: 1, title: 'Summary' });
    this.tabGroup.addTab({ key: 2, title: 'Details' });

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe((tab) => {
      setTimeout(() => {
        if (this.tabGroup.isActive(2)) {
          if(this.sider.isActive(this.SECTIONS.FILTER_SECTION_NAME)) {
            this.panelSide.open(this.SECTIONS.FILTER_SECTION_NAME);
            this.tabChanged = !this.tabChanged;
          }
        }
      });
    });
  }

  loadData(query: Query) {
    this.dataSource = [];
    this.loaderService.displayLoader(true)
    this.query.where.invoice_id =  this.alert.invoice_id;

    this.alertService.findDisconnect(this.alertId, this.query)
      .subscribe((results) => {
        if(results.items && results.items.length) {
          this.dataSource = results.items;
          query.total = results.total;
        }

        this.loaderService.hideLoader(true)
        setTimeout(() => {
          this.selectRows()
        }, 100)
      }, () => {
        this.toastAlertService.error('', this.messages.get('ERROR_LOADING_DISCONNECT_AUDIT'));
        this.loaderService.hideLoader(true)
      });
  }

  refresh() {
    this.loadData(this.query);
  }

  onPageChange(query: Query) {
    this.loadData(query);
  }


  ngOnInit() {
    this.loadTabs();
    this.alert = this.route.snapshot.data.alert;
    this.tabGroup.tabs[1].disabled = this.alert.message === 'No issues found';
    this.alertId = this.alert.id;
    this.loadData(this.query);
    this.columns = this.alertDisconnectGridService.columns();
  }

  ngAfterViewInit(): void {
    this.alertDisconnectGridService.create(this.dataGrid.instance, {
      noDataText: this.alertDisconnectGridService.noDataMessage,
      selection: {
        mode: 'multiple',
        selectAllMode: 'page'
      }
    });

    super.ngAfterViewInit();
  }

  back(): void {
    if (document.referrer.indexOf(window.location.host) >= 0) {
      history.back();
    } else {
      this.router.navigate(['/alert']);
    }
  }

  ngOnDestroy() {
    this.tabGroup.onActivate.unsubscribe();
  }

  clickFilterButton() {
    this.sider && this.sider.isActive(this.SECTIONS.FILTER_SECTION_NAME) ? this.panelSide.close() : this.panelSide.open();
    this.sider && this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
  }

  filterDisconnectAudit(query) {
    this.query = query;
    this.queryFilter.where = {...query.where};
    this.chargesSelectionActive = false;
    this.loadData(query);
  }

  clearDisconnectAudit() {
    this.query = new Query();
    this.queryFilter.where = {};
    this.chargesSelectionActive = false;
    this.loadData(this.query);
  }

  public goToInvoiceDetails() {
    if (this.alert && this.alert.invoice && this.alert.invoice.invoice_id) {
      this.router.navigate(['/invoice', this.alert.invoice.invoice_id, 'show']);
    }
  }

  showDispute(id: any) {
    this.router.navigate(['/dispute', id, 'show']);
  }

  findIndexInSelectedCharges(chargeId): number {
    for (let i = 0, l = this.selectedCharges.length; i < l; i++) {
      if (this.selectedCharges[i].id === chargeId) {
        return i;
      }
    }
    return -1;
  }

  onChargesSelectionChanged(event) {
    setTimeout(() => {
      if (!this.delayChargesSelectionChange && this.dataSource.length) {
        /** Selected rows */
        if (event.selectedRowKeys.length) {
          event.selectedRowsData.forEach(item => {
            let index = this.findIndexInSelectedCharges(item.id);
            if (index === -1) {
              this.selectedCharges.push(item);
            }
          });
        }

        /** Deselected rows */
        if (event.currentDeselectedRowKeys.length) {
          event.currentDeselectedRowKeys.forEach(id => {
            let index = this.findIndexInSelectedCharges(id);
            if (index > -1) {
              this.selectedCharges.splice(index, 1);
            }
          });
        }
      }

      // * Dispute Add enabled/disabled */
      if (this.selectedCharges.length) {
        this.addDisputeDisabled = false;
        this.selectedCharges.forEach(item => {
          if (item['charge']['charge_dispute']) {
            this.addDisputeDisabled = true;
          }
        });
      } else {
        this.addDisputeDisabled = true;
      }
    })
  }

  getDispDesc(value){
    let disputeDescriptionArray = []
    value.forEach(i => {
      disputeDescriptionArray.push(`Incorrect charges billed for in-process disconnect.`);
    })
    return disputeDescriptionArray;
  }

  getCalculatedCharges(value){
    let newArray = []
    value.sort((a,b) => (a.charge_id > b.charge_id) ? 1 : ((b.charge_id > a.charge_id) ? -1 : 0))
    value.forEach(i  => {
      newArray.push(i['calc_amt'])
    })
    return newArray
  }

  loadSelectedAlerts() {
    this.query.where = {}
    if (this.chargesSelectionActive) {
      if (this.queryFilter.where)
        this.query.where = {...this.queryFilter.where}
    } else {
      this.query.set('id', {
        $in: this.selectedCharges.map(x => {
          return x.id;
        })
      });
    }

    this.query.offset = 0;
    this.query.page = 1;

    this.chargesSelectionActive = !this.chargesSelectionActive;
    this.loadData(this.query);

    // if (this.chargesSelectionActive) {
    //   this.panelSide.close();
    // }
  }

  addDispute() {
    this.chargeQuery['where'] = {};
    this.chargeQuery.set('id', {
      $in: this.selectedCharges.map(x => {
        return x['charge_id'];
      })
    });

    this.chargeQuery.offset = 0;
    this.chargeQuery.page = 1;
    this.chargeQuery.limit = 10000;
    this.chargeQuery.set('invoice_id', this.alert.invoice_id);
    this.chargeService.filters(this.chargeQuery)
      .subscribe(async (result) => {
        this.charges = result.items;
        let arrayOfCalculatedCharges = this.getCalculatedCharges(this.selectedCharges)
        let invoice = this.selectedCharges[0].charge.invoice;
        let resDate = this.selectedCharges[0].alert.invoice.inv_date
        let dispDesc = this.getDispDesc(this.selectedCharges)
        if (!this.selectedCharges.length || this.addDisputeDisabled) {
          return;
        }
        this.dialogService
          .open(
            DisputeManageDialogComponent,
            {
              charges: this.charges,
              invoice: invoice,
              calculatedCharges: arrayOfCalculatedCharges,
              // resDate: resDate,
              dispDesc: dispDesc,
              alertId: this.alertId

            },
            {width: '60%'}
          )
          .afterClosed()
          .subscribe(async result => {
            if (result) {
              this.selectedCharges.forEach(i => {
                i['charge']['charge_dispute'] = {dispute_id: result.id};
              });
              this.selectedCharges = [];
              this.loadData(this.query);
              this.commonAlertService.success(
                '',
                this.messages.get('DISPUTE_CREATE_SUCCESS')
              );
            }
          });
      })
  }

  onChargesDataGridClick(event) {
    if (!event.target.classList.contains('selection-badge')) {
      this.delayChargesSelectionChange = false;
    }
  }

  selectRows() {
    this.dataGrid.instance.selectRows(this.selectedCharges.reduce((acc, item) => [...acc, item.id], []), false)
  }
}
