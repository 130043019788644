import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {INVENTORY_TOPOLOGY_ENUM} from "../../../inventory/core/inventory-topology.enum";

@Component({
  selector: 'app-alert-account-sneak-peek',
  templateUrl: './alert-account-sneak-peek.component.html',
})
export class AlertAccountSneakPeekComponent implements OnInit {

  public INVENTORY_TOPOLOGY = INVENTORY_TOPOLOGY_ENUM;


  constructor() { }

  id: number;

  @Input() account: any;
  @Output() closeRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();

  ngOnInit() {
  }

  closeSide() {
    this.closeRequested.emit();
  }

  ngOnChanges(): void {
    if (this.account) {
      this.id = this.account.id;
    }
  }

}
