import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "app/material-barrel/material-barrel.module";
import { SharedModule } from "../shared/shared.module";
import { LocationService } from "./core/location.service";
import { CaSubdivisionPickerComponent } from './shared/subdivision-picker/ca-subdivision-picker.component';
import { CaCountryPickerComponent } from './shared/country-picker/ca-country-picker.component';
import { ExchangeRateService } from './core/exchange-rate.service';

@NgModule({
  imports: [

  CommonModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule
  ],
  exports: [
    CaSubdivisionPickerComponent
  ],
  declarations: [
    CaSubdivisionPickerComponent
  ],
  providers: [LocationService, ExchangeRateService]
})
export class LocationModule {
}
