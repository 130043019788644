<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title" *ngIf="title !== ''">
  <h1 mat-dialog-title>{{title}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <div>{{bodyText}}</div>
  <div class="mat-dialog-top-spacer" *ngIf="order"></div>

  <div class="grid-container" *ngIf="order">
    <div style="height: 220px" class="grid-body app-content-list app-content-list-no-pager dx-nodata-absolute">
      <dx-data-grid #grid
                    class="grid-with-checkboxes"
                    [columns]="columns"
                    [dataSource]="order.services"
                    [showRowLines]="true"
                    [showColumnLines]="false"
                    [showBorders]="true"
                    style="height: 100%">
        <div *dxTemplate="let d of 'statusDate'">
          <div caGridTooltip>{{ d.value | caDate }}</div>
        </div>
        <div *dxTemplate="let d of 'statusType'">
          <div caGridTooltip>{{ d && d.data.disconnect_state ? d.data.disconnect_status.value : (d && d.value ? d.data.status.value : 'N/A') }}</div>
        </div>
      </dx-data-grid>
    </div>
  </div>
</div>

<div class="mat-dialog-top-spacer"></div>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
    <ca-confirm-delete-button
      #genericButton [requestInput]="true" (deleteRequested)="confirm()" [deleteText]="confirmButtonLabel"
      [snackBarDeleteInputMessage]="'Please type in '+ randomString + ' to ' +  confirmButtonLabel"
      [placeHolder]="cancelPlaceholder" [entityName]="randomString">
    </ca-confirm-delete-button>
  </div>
  <button #cancelButton mat-button type="submit" (click)="cancel()">
    Cancel
  </button>
</mat-dialog-actions>
