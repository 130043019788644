import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import Query from 'app/core/query/query';
import {CustomerInventoryService} from 'app/customer-inventory/core/customer-inventory.service';
import {LocationService} from 'app/location/core/location.service';
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import * as moment from 'moment';
import {CustomValidators} from 'ng2-validation';
import {combineLatest, of as observableOf} from 'rxjs';
import {flatMap} from "rxjs/internal/operators";
import {startWith, takeUntil} from 'rxjs/operators';
import {ContactService} from '../../../contact/core/contact.service';
import {ContractQuery} from '../../../contract/core/contract.query';
import {ContractService} from "../../../contract/core/contract.service";
import {SvcOrderService} from "../../../contract/core/svc-order.service";
import {PageManageDialogComponent} from '../../../core/page-manage-dialog.component';
import {IMessagesResourceService, ResourcesService} from '../../../core/resources/resources.service';
import {Demarc} from '../../../demarc/core/demarc.model';
import {DemarcService} from '../../../demarc/core/demarc.service';
import {DictionaryService} from '../../../dictionary/core/dictionary.service';
import {LOOKUP_MODELS_ENUM} from '../../../dictionary/core/lookup-models.enum';
import {LOOKUP_ENUM} from '../../../dictionary/core/lookup.enum';
import {PermissionService} from "../../../permissions/core/permission.service";
import {AlertService} from '../../../shared/alert/alert.service';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {EntityEditContext} from '../../../shared/entity-lock/entity-edit-context';
import {TabGroup, TabService} from '../../../shared/tabs/tab.service';
import {SiteService} from '../../../site/core/site.service';
import {Inventory} from '../../core/inventory';
import {InventoryRateFilterService} from "../../core/inventory-rate-filter.service";
import {INVENTORY_STATUS_ENUM} from "../../core/inventory-status.enum";
import {INVENTORY_TOPOLOGY_ENUM} from '../../core/inventory-topology.enum';
import {INVENTORY_TYPE_ENUM} from '../../core/inventory-type.enum';
import {InventoryService} from '../../core/inventory.service';
import {RateContractScheduleInventoryGridService} from "../../core/rate-contract-schedule-inventory-grid-service";
import {SvcOrderServiceInventoryGridService} from "../../core/svc-order-service-inventory-grid.service";
import {ConfigService} from "../../../core/config/config.service";

const MAX_TERM = 60;
const DEFAULT_TERM = 1;
const DEFAULT_CURRENCY_ID = 1;

@Component({
  selector: 'app-inventory-manage-dialog',
  templateUrl: './inventory-manage-dialog.component.html',
  styleUrls: ['./inventory-manage-dialog.component.scss'],
  providers: [CustomerInventoryService]
})
export class InventoryManageDialogComponent extends PageManageDialogComponent
  implements OnInit, OnDestroy {
  messages: IMessagesResourceService;
  demarc_messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'inventory';
  readonly MESSAGES_DEMARC_MODULE: string = 'demarc';

  @ViewChild('relatedCircuitPicker') relatedCircuitPicker;
  @ViewChild('relationshipTypePicker') relationshipTypePicker;
  @ViewChild('contractPicker') contractPicker;
  @ViewChild('currencyPicker') currencyPicker;
  @ViewChild('banPicker') banPicker;
  @ViewChild('hubPickerA') hubPickerA;
  @ViewChild('svcOrderServiceGrid') svcOrderServiceGrid: DxDataGridComponent;
  @ViewChild('rateScheduleServiceGrid') rateScheduleServiceGrid: DxDataGridComponent;

  tabGroup: TabGroup;
  tabChanged: boolean;
  activeTabIndex = 0;
  inventorySites = [];
  topology;
  singleDemarcTmp = true;
  circuits: Array<any> = [];
  selectedCircuits: Array<any> = [];
  svcOrderServices: Array<any> = [];
  contractScheduleRates: Array<any> = [];
  billingAliases = [];
  contracts = [];
  isFormDisabled: boolean;
  isPlusDisabled: boolean;
  mrc = 0;
  nrc = 0;

  public svcOrderServiceColumnsSorting: any[];
  public rateScheduleServiceColumnsSorting: any[];
  public isFilterOpen = false;

  hub_labels = {
    level_1: 'Level 1',
    level_2: 'Level 2'
  };

  associatedCircuitId: object;
  customMessage: string;

  currentDate = new Date();
  selectedCurrencyId: any;
  selectedCurrency: any;

  svcOrderHeaderId: number;
  scheduleId: number;

  readonly INVENTORY_CKT_USAGE_GROUP_NAME: string = 'inventory-ckt-usage';

  readonly CONTACT_TYPE_ENUM: any = LOOKUP_ENUM.CONTACT_TYPE;
  readonly CAPACITY_TYPES = LOOKUP_ENUM.NETWORK_HUB_CAPACITY_TYPES;

  readonly INVENTORY_BW_MODEL_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.INVENTORY_BW_MODEL.modelName;

  readonly INVENTORY_TECHNOLOGY_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName;

  readonly INVENTORY_PROTECTED_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.INVENTORY_PROTECTED.modelName;

  readonly INVENTORY_PRIM_FAILOVER_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.INVENTORY_PRIM_FAILOVER.modelName;

  readonly INVENTORY_CKT_TYPE_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.INVENTORY_CKT_TYPE.modelName;

  readonly INVENTORY_HANDOFF_TYPE_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.INVENTORY_HANDOFF_TYPE.modelName;

  readonly INVENTORY_STATUS_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.INVENTORY_STATUS.modelName;

  readonly INVENTORY_STATUS = INVENTORY_STATUS_ENUM;

  readonly INVENTORY_TOPOLOGY_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.INVENTORY_TOPOLOGY.modelName;

  readonly INVENTORY_TOPOLOGY = INVENTORY_TOPOLOGY_ENUM;

  readonly INVENTORY_TYPE = INVENTORY_TYPE_ENUM;

  readonly INVENTORY_TYPE_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.INVENTORY_TYPE.modelName;

  readonly INVENTORY_CONTRACT_TYPE_ENUM = LOOKUP_ENUM.INVENTORY_CONTRACT_TYPE;

  readonly INVENTORY_CONTRACT_TYPE: string =
    LOOKUP_MODELS_ENUM.INVENTORY_CONTRACT_TYPE.modelName;

  readonly INVENTORY_CIRCUIT_USAGE_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.INVENTORY_CIRCUIT_USAGE.modelName;

  readonly NETWORK_HUB_CAPACITY = LOOKUP_ENUM.NETWORK_HUB_CAPACITY;

  readonly NETWORK_HUB_TYPE = LOOKUP_ENUM.NETWORK_HUB_TYPE;

  INVALID_SP_CKT_ID_MSG = 'Only alphanumeric characters allowed';

  HUB_PICKER_LEVEL = {
    LEVEL_1: 1,
    LEVEL_2: 2
  };

  public inventory: any;
  public excludeStatuses: Array<any> = [];
  labels: Array<any> = [];
  fields: Array<any> = [];
  public svcOrderServiceColumns: Array<any>;
  public rateScheduleServiceColumns: Array<any>;
  selectedRows: Array<any> = [];
  selectedRateRows: Array<any> = [];

  // GPX-9202 - won't work now
  // accountQuery = new Query({where: {status_id: 1}});
  // statusDisabled: boolean = false;

  level1: Object;
  level2: Object;
  technologyId;
  siteADemarcs: Array<Demarc>;
  siteADemarcFormMode: boolean;
  selectedSiteADemarc: Demarc;
  siteZDemarcs: Array<Demarc>;
  siteZDemarcFormMode: boolean;
  selectedSiteZDemarc: Demarc;
  siteADemarcTmp: Demarc = new Demarc({unsaved: true});
  siteZDemarcTmp: Demarc = new Demarc({unsaved: true});
  contractQuery = new ContractQuery();
  svcOrderServiceQuery = new Query({
    orderBy: [['line_number', 'ASC']]
  });
  contractScheduleQuery = new Query({where: {contains_rates: {$eq: true}}});
  scheduleRateQuery = new Query({
    orderBy: [['line_number', 'ASC']],
    limit: 10000
  });
  disabledSites: Array<number> = [];
  contractIdsArrray = [];

  maxInstallDate: any;
  minDiscDate: any;
  categories: any = [];
  showCategory: boolean = false;
  ordersAllowed: boolean;
  auditsEnabled: boolean = false;
  public delaySelectionChange = false;

  readonly custom_values_config_key = 'inventory_detail';

  constructor(
    public alert: AlertService,
    public formBuilder: FormBuilder,
    public inventoryService: InventoryService,
    public dialogService: DialogService,
    public tabService: TabService,
    public siteService: SiteService,
    public dialogRef: MatDialogRef<InventoryManageDialogComponent>,
    public dictionaryService: DictionaryService,
    public contactService: ContactService,
    public demarcService: DemarcService,
    public customerInventoryService: CustomerInventoryService,
    public locationService: LocationService,
    public configService: ConfigService,
    private cd: ChangeDetectorRef,
    public svcOrderServiceInventoryGridService: SvcOrderServiceInventoryGridService,
    public permissionService: PermissionService,
    public rateContractScheduleInventoryGridService: RateContractScheduleInventoryGridService,
    public contractService: ContractService,
    public inventoryRateFilterService: InventoryRateFilterService
  ) {
    super(dialogService, dialogRef);
    this.inventory = this.inventory ? this.inventory : new Inventory();
    this.inventory.type_id = this.INVENTORY_TYPE.CIRCUIT;

    this.technologyId = this.inventory.technology_id;

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.demarc_messages = ResourcesService.messages(
      this.MESSAGES_DEMARC_MODULE
    );
  }


  ngOnInit() {
    this.permissionService.package().subscribe(perm => {
      const filtered = perm.resources.filter(arr => arr.key == 'orders')
      if (filtered.length && filtered[0]['allowed']) {
        this.ordersAllowed = true
      }

      const auditPermission = perm.resources.filter(arr => arr.key == 'audits');
      this.auditsEnabled = auditPermission.length && auditPermission[0]['allowed']

      this.tabGroup = this.tabService.create();
      this.inventory = this.inventory || new Inventory();
      this.isUpdate = !!this.inventory.id;
      if (this.ordersAllowed) {
        this.excludeStatuses.push(this.INVENTORY_STATUS.PENDING_DISCONNECT)
      }
      if (this.isUpdate) {
        if (this.inventory && this.inventory.currency_obj) {
          this.selectedCurrency = this.inventory.currency_obj.currency
        }
        this.inventoryService.findById(this.inventory.id).subscribe(res => {
          this.inventory = res;
          this.svcOrderServiceQuery.set('currency_id', res.currency_id)


          if (res.svc_header) {
            this.selectedRows = res.svc_order_service;
            this.svcOrderHeaderId = res.svc_header.id
            this.svcOrderServiceQuery.set('svc_order_header_id', this.svcOrderHeaderId)
            this.svcOrderServiceQuery.set('inventory_id', this.inventory.id)
            this.isFormDisabled = true;
            this.inventoryService.findAllSvcOrderServices(this.svcOrderServiceQuery)
              .subscribe(res => {
                this.svcOrderServices = res;

                if (this.inventory && this.inventory?.svc_order_service?.length) {
                  const results = this.svcOrderServices.filter(({id: id1}) => this.selectedRows.some(({id: id2}) => id2 === id1));
                  setTimeout(() => {
                    this.svcOrderServiceGrid.instance.selectRows(results, false);
                    this.isFormDisabled = false;
                  }, 500);
                } else {
                  this.isFormDisabled = false;
                }
              })
          }
          if (res.contract_type_id === this.INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT &&
            res.inventory_schedule_rates && res.inventory_schedule_rates.length) {
            let prevSelectedRate = []
            res.inventory_schedule_rates.forEach((rate) => {
              if (rate.rates) {
                prevSelectedRate.push(rate.rates)
              }
            });
            this.selectedRateRows = prevSelectedRate;
            const currency = this.inventory.currency_id;
            const scheduleId = this.inventory.inventory_schedule_rates[0].contract_schedule_id
            this.scheduleRateQuery.set('currency_id', currency)
            this.scheduleRateQuery.set('contract_schedule_id', scheduleId)
            if (scheduleId) {
              this.inventoryService.findAllScheduleRates(this.scheduleRateQuery)
                .subscribe(res => {
                  this.contractScheduleRates = res.items;
                  const results = this.contractScheduleRates.filter(({id: id1}) => this.selectedRateRows.some(({id: id2}) => id2 === id1));
                  setTimeout(() => {
                    this.selectedRateRows = results;
                    this.rateScheduleServiceGrid.instance.selectRows(results, false);
                  }, 500);
                })
            }
          }

          this.contracts = res.contract
          this.inventorySites = this.inventory.inventory_sites;
        });

        this.contractQuery.set('vendor_id', this.inventory.vendor_id);

        this.inventoryService
          .fetchInventoryAliases(this.inventory.id)
          .subscribe(aliases => {
            this.billingAliases = aliases;
          });

        this.inventoryService
          .fetchCustomerInventoryForNetworkInventory(this.inventory.id)
          .subscribe(res => {
            if (res.customer_inventory) {
              this.associatedCircuitId = res.customer_inventory;
              // tslint:disable-next-line: max-line-length
              this.customMessage = `You are about to delete customer inventory association for: ${
                res.customer_inventory.id
                } -  ${
                res.customer_inventory.customer_circuit_id
                }. Click Confirm to delete`;
            }
          });
      }

      this.inventory.network_hub_circuit_a = this.inventory.network_hub_circuit_a
        ? this.inventory.network_hub_circuit_a.toString()
        : null;
      this.inventory.network_hub_circuit_child_a = this.inventory.network_hub_circuit_child_a
        ? this.inventory.network_hub_circuit_child_a.toString()
        : null;

      /* Demarcs load on edit network inventory */
      if (this.inventory.site_a_id) {
        this.loadSiteADemarcs(this.inventory.site_a_id);
      }
      if (this.inventory.site_z_id) {
        this.loadSiteZDemarcs(this.inventory.site_z_id);
      }

      this.inventory.site_id = null;

      let formControls = {
        ban: new FormControl(this.inventory.ban),
        bw_max_id: new FormControl(this.inventory.bw_max_id),
        bw_model_id: new FormControl(this.inventory.bw_model_id),
        cir_bw_id: new FormControl(this.inventory.cir_bw_id),
        ckt_usage_id: new FormControl(this.inventory.ckt_usage_id),
        ckt_type_id: new FormControl(this.inventory.ckt_type_id),
        customer_circuit_id: new FormControl(this.inventory.customer_circuit_id),
        customer_id: new FormControl(this.inventory.customer_id),
        contract_id: new FormControl(this.inventory &&this.inventory.inventory_schedule_rates &&
        this.inventory.inventory_schedule_rates.length ? this.inventory.inventory_schedule_rates[0].contract_id : null),
        contract_schedule_id: new FormControl(this.inventory &&this.inventory.inventory_schedule_rates &&
        this.inventory.inventory_schedule_rates.length ? this.inventory.inventory_schedule_rates[0].contract_schedule_id : null),
        vendor_id: new FormControl(this.inventory.vendor_id, Validators.required),
        install_date: new FormControl(
          this.inventory && this.inventory.install_date
            ? new Date(this.inventory.install_date)
            : null,
          Validators.required
        ),
        disc_date: new FormControl(
          this.inventory && this.inventory.disc_date
            ? new Date(this.inventory.disc_date)
            : null
        ),
        stop_billing_date: new FormControl(
          this.inventory && this.inventory.stop_billing_date
            ? new Date(this.inventory.stop_billing_date)
            : null,
        ),
        status_id: new FormControl(this.inventory.status_id, Validators.required),
        sp_ckt_id: new FormControl(this.inventory.sp_ckt_id, [
          Validators.pattern('^[a-z0-9A-Z_]+')
        ]),
        handoff_type_id: new FormControl(this.inventory.handoff_type_id),
        internal_id: new FormControl(this.inventory.internal_id),
        term: new FormControl(
          this.inventory.term,
          Validators.compose([
            Validators.required,
            CustomValidators.min(1),
            Validators.pattern('^(0|-?[1-9][0-9]*)$')
          ])
        ),
        eff_date: new FormControl(this.inventory && this.inventory.eff_date ? new Date(this.inventory.eff_date) : null, Validators.required),
        exp_date: new FormControl(this.inventory && this.inventory.exp_date ? new Date(this.inventory.exp_date) : null, Validators.required),
        est_mrc: new FormControl(this.inventory.est_mrc, Validators.required),
        est_nrc: new FormControl(this.inventory.est_nrc, Validators.required),
        topology_id: new FormControl(
          this.inventory.topology_id,
          Validators.required
        ),
        technology_id: new FormControl(this.inventory.technology_id),
        protected_id: new FormControl(this.inventory.protected_id),
        prim_failover_id: new FormControl(this.inventory.prim_failover_id),
        site_id: new FormControl(this.inventory.site_id),
        site_lcontact_id: new FormControl(this.inventory.site_lcontact_id),
        site_a_id: new FormControl(this.inventory.site_a_id),
        site_z_id: new FormControl(this.inventory.site_z_id),
        site_a_lcontact_id: new FormControl(this.inventory.site_a_lcontact_id),
        site_z_lcontact_id: new FormControl(this.inventory.site_z_lcontact_id),
        vlan_id: new FormControl(this.inventory.vlan_id),

        billing_alias: new FormControl(null),

        site_a_row_id: new FormControl(''),
        site_a_rack_id: new FormControl(''),
        site_a_shelf_id: new FormControl(''),
        site_a_cpe_id: new FormControl(''),
        site_a_card_id: new FormControl(''),
        site_a_port_id: new FormControl(''),
        site_a_notes: new FormControl(''),
        site_a_extended: new FormControl(false),

        site_z_row_id: new FormControl(''),
        site_z_rack_id: new FormControl(''),
        site_z_shelf_id: new FormControl(''),
        site_z_cpe_id: new FormControl(''),
        site_z_card_id: new FormControl(''),
        site_z_port_id: new FormControl(''),
        site_z_notes: new FormControl(''),
        site_z_extended: new FormControl(false),

        is_customer_provided_access: new FormControl(this.inventory && this.inventory.is_customer_provided_access ? this.inventory.is_customer_provided_access : false),
        currency: new FormControl(this.inventory && this.inventory.currency ? this.inventory.currency : ''),
        currency_id: new FormControl(this.inventory && this.inventory.currency_id ? this.inventory.currency_id : DEFAULT_CURRENCY_ID),
        contract_type_id: new FormControl(this.inventory && this.inventory.contract_type_id ? this.inventory.contract_type_id : this.INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN),
        svc_order_id: new FormControl(this.inventory && this.inventory.svc_order_id ? this.inventory.svc_order_id : null),
        svc_order_service_id: new FormControl(this.inventory && this.inventory.svc_order_service_id ? this.inventory.svc_order_service_id : null),
        enable_audit_run: new FormControl(this.inventory && this.inventory.enable_audit_run ? this.inventory.enable_audit_run : false),
        rates: new FormControl('')
      }
      if (this.inventory.network_hub_map) {
      }

      this.form = this.formBuilder.group(formControls);
      combineLatest([
        this.form.get('site_a_id').valueChanges.pipe(startWith(this.form.value.site_a_id)),
        this.form.get('site_z_id').valueChanges.pipe(startWith(this.form.value.site_z_id))]
      ).pipe(
        takeUntil(this.destroy$))
        .subscribe((selectedSites: Array<number>) => {
          this.disabledSites = selectedSites;
        });

      if (this.form.get('disc_date').value) {
        this.getMaxInstallDate();
      }

      if (this.form.get('install_date').value) {
        this.getMinDiscDate(this.inventory.install_date);
      }

      this.form.get('contract_id').valueChanges.subscribe(() => {
        this.selectedRateRows = [];
        this.form.get('contract_schedule_id').setValue(null);
        this.contractScheduleRates = null;
        this.form.get('exp_date').setValue(null);
        this.form.get('eff_date').setValue(null);
      })

      this.form.get('contract_type_id').valueChanges.subscribe((contract_type_id) => {
        if (contract_type_id === this.INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT) {
          this.form.get('svc_order_id').setValue(null);
          this.form.get('svc_order_service_id').setValue(null);
          this.form.get('est_mrc').setValue(0);
          this.form.get('est_nrc').setValue(0);
          this.form.get('rates').setValue(null);
          this.form.get('term').setValue(this.inventory.term || DEFAULT_TERM);
          this.clearContractSchedulePicker()
          this.form.get('currency_id').setValue(this.inventory.currency_id || DEFAULT_CURRENCY_ID);
          this.contractScheduleRates = null;
          this.selectedRateRows = [];
          this.selectedRows = [];
          this.svcOrderServices = [];
          this.svcOrderServiceGrid && this.svcOrderServiceGrid.instance.refresh();
          this.rateScheduleServiceGrid && this.rateScheduleServiceGrid.instance.refresh();

        } else if (contract_type_id === this.INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER) {
          this.form.get('contract_id').setValue(null);
          this.form.get('contract_schedule_id').setValue(null);
          this.form.get('rates').setValue(null);
          this.selectedRateRows = [];
          this.form.get('est_mrc').setValue(0);
          this.form.get('est_nrc').setValue(0);
          this.form.get('term').setValue(this.inventory.term || DEFAULT_TERM);
          this.form.get('currency_id').setValue(this.inventory.currency_id || DEFAULT_CURRENCY_ID);
          this.clearContractSchedulePicker()
          this.contractScheduleRates = null;
          this.selectedRateRows = [];
          this.selectedRows = [];
          this.svcOrderServices = [];
          this.svcOrderServiceGrid && this.svcOrderServiceGrid.instance.refresh();
          this.rateScheduleServiceGrid && this.rateScheduleServiceGrid.instance.refresh();
        } else {
          this.form.get('contract_id').setValue(null);
          this.form.get('svc_order_id').setValue(null);
          this.form.get('contract_schedule_id').setValue(null);
          this.form.get('svc_order_service_id').setValue(null);
          this.form.get('rates').setValue(null);
          this.selectedRateRows = [];
          this.form.get('est_mrc').setValue(0);
          this.form.get('est_nrc').setValue(0);
          this.form.get('term').setValue(this.inventory.term || DEFAULT_TERM);
          this.form.get('currency_id').setValue(this.inventory.currency_id || DEFAULT_CURRENCY_ID);
          this.contractScheduleRates = null;
          this.selectedRateRows = [];
          this.selectedRows = [];
          this.svcOrderServices = [];
          this.svcOrderServiceGrid && this.svcOrderServiceGrid.instance.refresh();
          this.rateScheduleServiceGrid && this.rateScheduleServiceGrid.instance.refresh();


        }
      });

      this.form.controls.topology_id.valueChanges.pipe(
        takeUntil(this.destroy$))
        .subscribe(toplogyId => {
          this.resetFormControlValues(this.form, [
            'site_id',
            'site_lcontact_id',
            'site_a_id',
            'site_z_id',
            'site_a_lcontact_id',
            'site_z_lcontact_id'
          ]);
        });

      this.loadTabs();

      this.form.controls.site_a_id.valueChanges.pipe(
        takeUntil(this.destroy$))
        .subscribe(siteAId => {
          if (!siteAId) {
            return (this.siteADemarcs = []);
          }
          this.loadSiteADemarcs(siteAId);
        });

      this.form.controls.site_z_id.valueChanges.pipe(
        takeUntil(this.destroy$))
        .subscribe(siteZId => {
          if (!siteZId) {
            return (this.siteZDemarcs = []);
          }
          this.loadSiteZDemarcs(siteZId);
        });

      this.form.controls.vendor_id.valueChanges.pipe(
        takeUntil(this.destroy$))
        .subscribe(vendorId => {
          this.contractQuery = new ContractQuery({
            where: {
              vendor_id: vendorId
            }
          });
          this.form.controls.ban.reset(null);

          if (this.form.controls.vendor_id.touched) {
            this.form.controls.contract_id.reset();
            this.form.controls.svc_order_id.reset();
            this.form.controls.svc_order_service_id.reset();
          }
        });

      this.form.get('svc_order_id').valueChanges.subscribe((svc_order_id) => {
        this.form.get('svc_order_service_id').setValue(null);
      });

      this.initializeLabels();

      this.formTitle = this.isUpdate
        ? `Edit Service Inventory`
        : `Create Service Inventory`;

      if (this.isUpdate) {
        this.inventoryService
          .fetchNetworkCircuits(this.inventory.id)
          .subscribe(result => {
            this.selectedCircuits = result.items.map(item => {
              return {
                ...item.circuits,
                relationshipType: item.relationship_type?.value,
                relationshipTypeId: item.relationship_type?.id
              }
            });
          });
        // GPX-9202 - won't work now
        // this.statusDisabled = !this.inventory.ban_object.status_id;
      }

      // Load custom fields
      this.configService.loadCustomFields()
        .subscribe(config => {
          this.fields = this.configService.getCustomFieldsFromConfig(config, this.custom_values_config_key);

          let fieldsWithCategory = [];
          let cus_val = this.inventory.custom_values && this.isUpdate ? this.inventory.custom_values : {};
          for (let i = 0; i < this.fields.length; i++) {
            if (this.fields[i].category && this.fields[i].category.length > 0) {
              fieldsWithCategory = [...fieldsWithCategory, this.fields[i].category];
            }
            if (!this.categories.includes(this.fields[i].category)) {
              this.categories.push(this.fields[i].category)
            }
            this.fields[i].value = cus_val[this.fields[i].field_name]
            this.form.addControl(this.fields[i].field_name, new FormControl(this.fields[i].value));
          }
          if (fieldsWithCategory.length === this.fields.length) {
            this.showCategory = true;
          }
          this.cd.detectChanges();
        });


      this.afterInit();

      this.svcOrderServiceInventoryGridService.loadSettings()
        .subscribe((settings) => {
          this.initSvcOrderServiceColumns(settings);
        });
      this.rateContractScheduleInventoryGridService.loadSettings()
        .subscribe((settings) => {
          this.initRateScheduleServiceColumns(settings);
        });

    })
  }

  initSvcOrderServiceColumns(settings?) {
    this.svcOrderServiceColumnsSorting = this.svcOrderServiceInventoryGridService.getOrderBy(settings && settings.sorting ? settings.sorting : []);
    this.svcOrderServiceColumns = this.svcOrderServiceInventoryGridService.getColumns(settings && settings.columns ? settings.columns : [])

    if (this.svcOrderServiceColumnsSorting.length) {
      this.svcOrderServiceQuery.orderBy = this.svcOrderServiceColumnsSorting;
    }

    if (this.svcOrderServiceGrid && this.svcOrderServiceGrid.instance) {
      this.svcOrderServiceGrid.instance.option('columns', this.svcOrderServiceColumns);
    }
  }

  initRateScheduleServiceColumns(settings?) {
    this.rateScheduleServiceColumnsSorting = this.rateContractScheduleInventoryGridService.getOrderBy(settings && settings.sorting ? settings.sorting : []);
    this.rateScheduleServiceColumns = this.rateContractScheduleInventoryGridService.getColumns(settings && settings.columns ? settings.columns : [])

    if (this.rateScheduleServiceColumnsSorting.length) {
      this.svcOrderServiceQuery.orderBy = this.rateScheduleServiceColumnsSorting;
    }

    if (this.rateScheduleServiceGrid && this.rateScheduleServiceGrid.instance) {
      this.rateScheduleServiceGrid.instance.option('columns', this.rateScheduleServiceColumns);
    }
  }

  init() {
    if (this.inventory && this.inventory.id) {
      return this.inventoryService
        .findByIdForEdit(
          this.inventory.id,
          new EntityEditContext({
            dialogRef: this.dialogRef
          })
        )
        .pipe(flatMap(inventory => {
          this.inventory = inventory;
          return observableOf(inventory);
        }));
    }
    return observableOf(this.inventory);
  }

  public onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);

      this.siteADemarcFormMode = false;
      this.siteZDemarcFormMode = false;
    }
  }

  getMrc(contractRate = false) {
    let mrc = 0
    const rateType = contractRate ? this.selectedRateRows : this.selectedRows;
    rateType.forEach(item => {
      let itemMrc = +item.mrc
      mrc += itemMrc
    });
    this.form.get('est_mrc').setValue(mrc.toString());
  }

  getNrc(contractRate = false) {
    let nrc = 0
    const rateType = contractRate ? this.selectedRateRows : this.selectedRows;
    rateType.forEach(item => {
      let itemNrc = +item.nrc
      nrc += itemNrc
    });
    this.form.get('est_nrc').setValue(nrc.toString());
  }

  setDateField(column, field, contractRate = false) {
    const rateType = contractRate ? this.selectedRateRows : this.selectedRows;
    const dates = rateType.map(row => moment(row[column])).filter(rateDate => rateDate.isValid());
    if(dates.length) {
      if (!this.selectedRows.length && !this.selectedRateRows.length) {
        this.setContractScheduleDates();
        return;
      }

      if (contractRate) {
        const maxDate = moment.max(dates).format('YYYY-MM-DD');
        const minDate = moment.min(dates).format('YYYY-MM-DD');
        if (field === 'eff_date') {
          this.form.get(field).setValue(minDate);
        } else {
          this.form.get(field).setValue(maxDate);
        }
        return;
      }
      const maxDate = moment.max(dates).format('YYYY-MM-DD');
      this.form.get(field).setValue(maxDate);
      return
    }
    this.form.get(field).setValue(null);

  }

  public loadTabs() {
    let sitesTitle = 'Site A/Z';

    this.tabGroup.addTab({key: 1, title: 'Summary'});
    this.tabGroup.addTab({key: 2, title: 'Financial'});
    this.tabGroup.addTab({key: 3, title: 'Configuration'});
    this.tabGroup.addTab({key: 4, title: sitesTitle});
    this.tabGroup.addTab({key: 5, title: 'Related Services'});

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe(tab => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }

  setTerm(contractRate = false) {
    if (contractRate) {
      const effectiveDate = this.form.get('eff_date')?.value
      const terminationDate = this.form.get('exp_date')?.value
      const dateMin = moment(effectiveDate, 'YYYY-MM-DD')
      const dateMax = moment(terminationDate, 'YYYY-MM-DD')
      const term = 1 + dateMax.diff(dateMin, 'months');
      this.form.get('term').setValue(term);
      this.form.get('term').markAsTouched();
      return;
    }
    const termList = this.selectedRows.map(row => row.term);
    this.form.get('term').setValue(Math.max(...termList));
  }

  public isHubPickerVisible(hubId, level, hubPicker) {
    if (hubPicker && hubId) {
      let selectedHub = hubPicker.entries.filter(item => item.id === hubId);
      if (selectedHub && selectedHub[0]) {
        let capacity = selectedHub[0].hub_capacity_id;
        switch (capacity) {
          case this.NETWORK_HUB_CAPACITY.DS_3:
            return level === this.HUB_PICKER_LEVEL.LEVEL_2;
          case this.NETWORK_HUB_CAPACITY.OC_12:
            return true;
          default:
            return false;
        }
      }
    } else {
      return false;
    }
  }

  public gethubType(hubId, hubPicker) {
    if (hubPicker && hubId) {
      let selectedHub = hubPicker.entries.filter(item => item.id === hubId);
      if (selectedHub && selectedHub[0]) {
        return selectedHub[0].hub_capacity_id;
      }
    } else {
      return null;
    }
  }

  private wantsToRemoveFromNetworkHubCircuit(value: Inventory) {
    return new Promise((resolve, reject) => {
      this.inventoryService.canBeEdited(this.inventory.id).subscribe(res => {
        if (!res.canBeEdited && res.data && res.data.network_hub_id) {
          this.dialogService.confirm({
            bodyText: `Service is assigned to Network Hub ${res.data.network_hub_id}. Updating it will remove it from assigned circuits.`
          }).afterClosed()
            .subscribe((wantsToUpdate: boolean) => {
              resolve(wantsToUpdate)
            })
        } else {
          resolve(true);
        }
      })
    })
  }

  /**
   * Compares if vendor, site A and technology is changed. Its needed for linkin with network hub
   *
   * @private
   * @memberof InventoryManageDialogComponent
   */
  private areValuesChangedForHubMap(formValue: string, inventoryValue: string) {
    return !(formValue === inventoryValue);
  }

  onSubmit({value, valid}: { value: Inventory; valid: boolean }): void {
    if (valid) {
      value.custom_values = this.configService.getCustomFieldValue(this.fields, value);
      if (this.inventory.id) {
        const {vendor_id: form_vendor_id, site_a_id: form_site_a_id, technology_id: form_technology_id} = value;
        const {vendor_id, site_a_id, technology_id} = this.inventory;

        if (this.areValuesChangedForHubMap(`${form_vendor_id}, ${form_site_a_id}, ${form_technology_id}`, `${vendor_id}, ${site_a_id}, ${technology_id}`)) {
          this.wantsToRemoveFromNetworkHubCircuit(value).then(wantsToUpdate => {
            if (wantsToUpdate) {
              this.updateInventory(value, true);
            }
          })
        } else {
          this.updateInventory(value);
        }
      } else {
        this.createInventory(value);
      }
    } else {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }

  createInventory(inventory: Inventory) {
    inventory.type_id = LOOKUP_ENUM.INVENTORY_TYPE.CIRCUIT;
    inventory.est_mrc = this.unmask(inventory.est_mrc);
    inventory.est_nrc = this.unmask(inventory.est_nrc);
    inventory.billing_aliases = this.billingAliases;

    this.toggleDialogButtons();
    this.inventoryService.create(inventory).subscribe(
      result => {
        this.insertSites(result.id);
        result.inventory_sites = this.inventorySites;

        if (result.topology_id === this.INVENTORY_TOPOLOGY.POINT_TO_POINT) {
          this.insertDemarcsTmp(result.id, result.site_a_id, result.site_z_id);
        }

        const {billingAliases} = this;

        this.inventoryService
          .addInventoryAliases(result.id, billingAliases)
          .pipe(flatMap(() =>
            this.inventoryService.addNetworkCircuits(
              result.id,
              this.selectedCircuits
            )
          ))
          .subscribe(res => this.closeDialog(result, true), this.handleNotUniqueAlias);

        this.linkNetworkToCustomerCircuit(result.id);
      },
      this.handleInventoryEror('CREATE_ERROR')
    );
  }

  private linkNetworkToCustomerCircuit(network_id: number) {
    const {customer_circuit_id} = this.form.value;

    if (network_id && customer_circuit_id) {
      this.customerInventoryService.linkCustomerWithNetworkCircuit(customer_circuit_id, network_id);
    }
  }

  handleInventoryEror = defaultMessage => error => {
    this.toggleDialogButtons(false);
    const message = error.status = 400 && error.data === 'Duplicate unique id' ? 'DUPLICAT_VENDOR_ACCOUNT_SP_CKT_ID' : defaultMessage;
    this.alert.error('', this.messages.get(message));
  }

  insertDemarcsTmp(inventoryId, siteAId, siteZId) {
    this.siteADemarcTmp.site_id = siteAId;
    this.siteADemarcTmp.row_id = this.form.controls['site_a_row_id'].value;
    this.siteADemarcTmp.rack_id = this.form.controls['site_a_rack_id'].value;
    this.siteADemarcTmp.shelf_id = this.form.controls['site_a_shelf_id'].value;
    this.siteADemarcTmp.cpe_id = this.form.controls['site_a_cpe_id'].value;
    this.siteADemarcTmp.card_id = this.form.controls['site_a_card_id'].value;
    this.siteADemarcTmp.notes = this.form.controls['site_a_notes'].value;
    this.siteADemarcTmp.extended = this.form.controls['site_a_extended'].value;
    this.siteADemarcTmp.port_id = this.form.controls['site_a_port_id'].value;
    this.siteADemarcs = [this.siteADemarcTmp];

    this.siteZDemarcTmp.site_id = siteZId;
    this.siteZDemarcTmp.row_id = this.form.controls['site_z_row_id'].value;
    this.siteZDemarcTmp.rack_id = this.form.controls['site_z_rack_id'].value;
    this.siteZDemarcTmp.shelf_id = this.form.controls['site_z_shelf_id'].value;
    this.siteZDemarcTmp.cpe_id = this.form.controls['site_z_cpe_id'].value;
    this.siteZDemarcTmp.card_id = this.form.controls['site_z_card_id'].value;
    this.siteZDemarcTmp.notes = this.form.controls['site_z_notes'].value;
    this.siteZDemarcTmp.extended = this.form.controls['site_z_extended'].value;
    this.siteZDemarcTmp.port_id = this.form.controls['site_z_port_id'].value;
    this.siteZDemarcs = [this.siteZDemarcTmp];

    let unsavedDemarcs = this.siteADemarcs
      .filter(item => {
        return item.unsaved && !item.id && item.site_id;
      })
      .concat(
        this.siteZDemarcs.filter(item => {
          return item.unsaved && !item.id && item.site_id;
        })
      );

    let demarcsToUpdate = this.siteADemarcs
      .filter(item => {
        return item.id && item.site_id;
      })
      .concat(
        this.siteZDemarcs.filter(item => {
          return item.id && item.site_id;
        })
      );

    unsavedDemarcs.forEach(demarc => {
      demarc.network_circuit_id = inventoryId;
      this.demarcService.create(demarc).subscribe(
        result => {
        },
        err => {
          console.log('Error save demarcs');
        }
      );
    });

    demarcsToUpdate.forEach(demarc => {
      demarc.network_circuit_id = inventoryId;
      this.demarcService.update(demarc.id, demarc).subscribe(
        result => {
        },
        err => {
          console.log('Error update demarcs');
        }
      );
    });
  }

  updateInventory(inventory: Inventory, deleteNetworkHubCircuitMap = false) {
    inventory.est_mrc = this.unmask(inventory.est_mrc);
    inventory.est_nrc = this.unmask(inventory.est_nrc);
    inventory.billing_aliases = this.billingAliases;

    this.toggleDialogButtons();
    this.inventoryService.update(this.inventory.id, inventory).subscribe(
      result => {
        this.insertSites(result.id);
        result.inventory_sites = this.inventorySites;

        if (result.topology_id === this.INVENTORY_TOPOLOGY.POINT_TO_POINT) {
          this.insertDemarcsTmp(result.id, result.site_a_id, result.site_z_id);
        }

        const {billingAliases} = this;

        this.inventoryService
          .addInventoryAliases(result.id, billingAliases)
          .pipe(flatMap(() =>
            this.inventoryService.addNetworkCircuits(
              result.id,
              this.selectedCircuits
            )
          ), flatMap(() => {
            if (deleteNetworkHubCircuitMap) {
              return this.inventoryService.unlinkFromNetworkHubCircuisMap(this.inventory.id)
            } else {
              return observableOf(result)
            }
          }), flatMap(() => this.inventoryService.findById(result.id)))
          .subscribe(res => {
            this.toggleDialogButtons(false);
            this.closeDialog(res, true);
          }, this.handleNotUniqueAlias);

        this.linkNetworkToCustomerCircuit(this.inventory.id);
      },
      this.handleInventoryEror('UPDATE_ERROR')
    );
  }

  deleteInventory(event) {
    const deleteInv = (deleteNetworkHubCircuitMap = false) => {
      this.inventoryService.delete(this.inventory.id)
        .pipe(flatMap(() => {
          if (deleteNetworkHubCircuitMap) {
            return this.inventoryService.unlinkFromNetworkHubCircuisMap(this.inventory.id)
          } else {
            return observableOf(this.inventory)
          }
        })).subscribe(
        (deleteResult) => {
          if (deleteResult) {
            this.dialogRef.close({deleted: true});
          }
        },
        err => {
          this.toggleDialogButtons(false);
          this.alert.error('', this.messages.get('DELETE_ERROR'));
        }
      );
    }

    if (this.isUpdate && event) {
      const {value} = this.form;
      const {vendor_id: form_vendor_id, site_a_id: form_site_a_id, technology_id: form_technology_id} = value;
      const {vendor_id, site_a_id, technology_id} = this.inventory;
      this.toggleDialogButtons();
      if (this.areValuesChangedForHubMap(`${form_vendor_id}, ${form_site_a_id}, ${form_technology_id}`, `${vendor_id}, ${site_a_id}, ${technology_id}`)) {
        this.wantsToRemoveFromNetworkHubCircuit(value).then(wantsToUpdate => {
          if (wantsToUpdate) {
            deleteInv();
          }
        })
      } else {
        deleteInv();
      }
    }
  }


  // TODO optimize this
  public toggleLabel(date: string) {
    if (this.form.controls[date].value) {
      if (this.labels.indexOf(date) === -1) {
        this.labels.push(date);
      }
    } else {
      if (this.labels.indexOf(date) === -1) {
        this.labels.push(date);
      } else {
        let index = this.labels.indexOf(date);
        this.labels.splice(index, 1);
      }
    }
  }

  public initializeLabels() {
    let dates = ['disc_date', 'install_date', 'stop_billing_date'];
    dates.forEach((item) => {
      if (this.form.controls[item].value) {
        this.labels.push(item);
      }
    });
  }

  public unmask(value) {
    if (value) {
      // http://stackoverflow.com/questions/559112
      return value.replace(/[^0-9\.-]+/g, '');
    }
  }

  deleteSite(site_id, siteName) {
    this.dialogService.confirm({
      bodyText: `Delete site ${siteName}`
    }).afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.inventoryService.deleteSite(this.inventory.id, site_id)
              .subscribe(() => {
                  this.filterSiteFromList(site_id);
                },
                () => {
                  this.filterSiteFromList(site_id);
                }
              );
          }
          this.filterSiteFromList(site_id);
        });
  }

  filterSiteFromList(site_id) {
    this.inventorySites = this.inventorySites.filter(elem => {
      return elem.site.id !== site_id;
    });
  }

  addSite(site_id, contact_id) {
    if (this.ifSiteExist(site_id)) {
      this.alert.error('', this.messages.get('SITE_EXIST_ERROR'));
    } else {
      this.siteService.findById(site_id).subscribe(res => {
        if (contact_id) {
          this.contactService.findById(contact_id).subscribe(contact => {
            this.inventorySites.push({
              site: res,
              siteId: site_id,
              site_lcontact: contact,
              contactId: contact_id,
              new: true
            });
          });
        } else {
          this.inventorySites.push({site: res, siteId: site_id, new: true});
        }
        this.form.get('site_id').reset();
        this.form.get('site_lcontact_id').reset();
      });
    }
  }

  changedTopology(changes) {
    let siteTitle = 'Site A/Z';

    this.inventorySites = [];
    this.inventory.site_id = null;
    this.inventory.site_lcontact_id = null;

    this.inventory.site_a_id = null;
    this.inventory.site_z_id = null;
    this.inventory.site_a_lcontact_id = null;
    this.inventory.site_z_lcontact_id = null;

    this.tabGroup.tabs[3].title = siteTitle;
  }

  public insertSites(inventoryId) {
    let sites = this.inventorySites.filter(item => {
      return item.new === true;
    });

    sites.forEach(element => {
      this.inventoryService
        .addSite(inventoryId, element.siteId, element.contactId)
        .subscribe(
          () => {
          },
          () => {
            this.alert.error('', this.messages.get('UPDATE_ERROR'));
          }
        );
    });

    this.inventory.inventory_sites = this.inventorySites;
  }

  public ifSiteExist(site_id) {
    let sites = this.inventorySites;

    let siteExists = false;

    if (
      sites &&
      sites.filter(item => {
        return item.siteId === site_id || item.site_id === site_id;
      }).length
    ) {
      siteExists = true;
    }

    return siteExists;
  }

  addRelatedCircuit(selectedServiceId: number, selectedRelationshipTypeId: number,
                    servicePickerCollection: Array<any>, relationshipTypePickerCollection: Array<any>) {
    const isCircuitAlreadySelected = this.selectedCircuits.filter(
      item => item.id === selectedServiceId
    ).length;

    if (isCircuitAlreadySelected) {
      return this.alert.error(
        '',
        this.messages.get('RELATED_CIRCUIT_ALREADY_SELECTED')
      );
    }

    const itemToAdd = servicePickerCollection.filter(item => item.id === selectedServiceId);
    const relationshipType = relationshipTypePickerCollection.filter(item => item.id === selectedRelationshipTypeId);
    itemToAdd[0].relationshipType = relationshipType[0].value;
    itemToAdd[0].relationshipTypeId = selectedRelationshipTypeId

    this.selectedCircuits = Array.from(
      new Set(this.selectedCircuits.concat(itemToAdd))
    );

    this.relatedCircuitPicker.caPicker.clearHandler();
    this.relationshipTypePicker.caPicker.clearHandler();
  }

  deleteRelatedCircuit(selectedCircuitId) {
    this.selectedCircuits = this.selectedCircuits.filter(
      item => item.id !== selectedCircuitId
    );
  }

  resetFormControlValues(form: FormGroup, formControlNames: Array<string>) {
    formControlNames.map(formControlName => {
      if (form.controls[formControlName]) {
        form.controls[formControlName].reset();
      }
    });
  }

  /* Demarcs related */

  /* Site A demarcs */
  loadSiteADemarcs(siteId: number) {
    if (this.inventory && this.inventory.id && siteId) {
      this.demarcService.getDemarcs(siteId, this.inventory.id).subscribe(
        results => {
          this.siteADemarcs = results.items;
          this.siteADemarcTmp =
            this.siteADemarcs[0] || new Demarc({unsaved: true});
          this.form.controls['site_a_row_id'].patchValue(
            this.siteADemarcTmp.row_id
          );
          this.form.controls['site_a_rack_id'].patchValue(
            this.siteADemarcTmp.rack_id
          );
          this.form.controls['site_a_shelf_id'].patchValue(
            this.siteADemarcTmp.shelf_id
          );
          this.form.controls['site_a_cpe_id'].patchValue(
            this.siteADemarcTmp.cpe_id
          );
          this.form.controls['site_a_card_id'].patchValue(
            this.siteADemarcTmp.card_id
          );
          this.form.controls['site_a_notes'].patchValue(
            this.siteADemarcTmp.notes
          );
          this.form.controls['site_a_port_id'].patchValue(
            this.siteADemarcTmp.port_id
          );
          this.form.controls['site_a_extended'].patchValue(
            this.siteADemarcTmp.extended
          );
        },
        () => {
          console.log('Error loading Site A Demarcs');
        }
      );
    }
  }

  onSiteADemarcAddRequested(event) {
    this.selectedSiteADemarc = null;
    this.siteADemarcFormMode = true;
  }

  onSiteADemarcEditRequested(demarc: Demarc) {
    this.selectedSiteADemarc = demarc;
    this.siteADemarcFormMode = true;
  }

  onSiteADemarcDeleteRequested(demarc: Demarc) {
    if (demarc.id && !demarc.unsaved) {
      this.demarcService.delete(demarc.id).subscribe(
        () => {
        },
        () => {
          console.log('Error delete demarc');
        }
      );
    }
    this.siteADemarcs = this.siteADemarcs.filter(item => {
      return item !== demarc;
    });
  }

  onSiteADemarcSaveRequested(demarc: Demarc) {
    if (this.selectedSiteADemarc) {
      Object.assign(this.selectedSiteADemarc, demarc);
    } else {
      this.siteADemarcs = this.siteADemarcs || [];
      this.siteADemarcs.push(demarc);
    }
    this.selectedSiteADemarc = null;
    this.siteADemarcFormMode = false;
  }

  onSiteADemarcFormCancelled(event) {
    this.selectedSiteADemarc = null;
    this.siteADemarcFormMode = false;
  }

  /* Site Z demarcs */
  loadSiteZDemarcs(siteId: number) {
    if (this.inventory && this.inventory.id && siteId) {
      this.demarcService.getDemarcs(siteId, this.inventory.id).subscribe(
        results => {
          this.siteZDemarcs = results.items;
          this.siteZDemarcTmp =
            this.siteZDemarcs[0] || new Demarc({unsaved: true});
          this.form.controls['site_z_row_id'].patchValue(
            this.siteZDemarcTmp.row_id
          );
          this.form.controls['site_z_rack_id'].patchValue(
            this.siteZDemarcTmp.rack_id
          );
          this.form.controls['site_z_shelf_id'].patchValue(
            this.siteZDemarcTmp.shelf_id
          );
          this.form.controls['site_z_cpe_id'].patchValue(
            this.siteZDemarcTmp.cpe_id
          );
          this.form.controls['site_z_card_id'].patchValue(
            this.siteZDemarcTmp.card_id
          );
          this.form.controls['site_z_notes'].patchValue(
            this.siteZDemarcTmp.notes
          );
          this.form.controls['site_z_port_id'].patchValue(
            this.siteZDemarcTmp.port_id
          );
          this.form.controls['site_z_extended'].patchValue(
            this.siteZDemarcTmp.extended
          );
        },
        () => {
          console.log('Error loading Site Z Demarcs');
        }
      );
    }
  }

  onSiteZDemarcAddRequested(event) {
    this.selectedSiteZDemarc = null;
    this.siteZDemarcFormMode = true;
  }

  onSiteZDemarcEditRequested(demarc: Demarc) {
    this.selectedSiteZDemarc = demarc;
    this.siteZDemarcFormMode = true;
  }

  onSiteZDemarcDeleteRequested(demarc: Demarc) {
    if (demarc.id && !demarc.unsaved) {
      this.demarcService.delete(demarc.id).subscribe(
        () => {
        },
        () => {
          console.log('Error delete demarc');
        }
      );
    }
    this.siteZDemarcs = this.siteZDemarcs.filter(item => {
      return item !== demarc;
    });
  }

  onSiteZDemarcSaveRequested(demarc: Demarc) {
    if (this.selectedSiteZDemarc) {
      Object.assign(this.selectedSiteZDemarc, demarc);
    } else {
      this.siteZDemarcs = this.siteZDemarcs || [];
      this.siteZDemarcs.push(demarc);
    }
    this.selectedSiteZDemarc = null;
    this.siteZDemarcFormMode = false;
  }

  onSiteZDemarcFormCancelled(event) {
    this.selectedSiteZDemarc = null;
    this.siteZDemarcFormMode = false;
  }

  /* insert demarcs */

  // TODO: refactor using bulk create and bulk update
  insertDemarcs(inventoryId) {
    this.siteADemarcs = this.siteADemarcs || [];
    this.siteZDemarcs = this.siteZDemarcs || [];

    let unsavedDemarcs = this.siteADemarcs
      .filter(item => {
        return item.unsaved;
      })
      .concat(
        this.siteZDemarcs.filter(item => {
          return item.unsaved;
        })
      );

    let demarcsToUpdate = this.siteADemarcs
      .filter(item => {
        return item.id;
      })
      .concat(
        this.siteZDemarcs.filter(item => {
          return item.id;
        })
      );

    unsavedDemarcs.forEach(demarc => {
      demarc.network_circuit_id = inventoryId;
      this.demarcService.create(demarc).subscribe(
        () => {
        },
        () => {
          console.log('Error save demarcs');
        }
      );
    });

    demarcsToUpdate.forEach(demarc => {
      demarc.network_circuit_id = inventoryId;
      this.demarcService.update(demarc.id, demarc).subscribe(
        () => {
        },
        () => {
          console.log('Error update demarcs');
        }
      );
    });
  }

  /* /Demarcs related */

  /* Billing Alias code */
  isAddBillingAliasDisabled(form: FormGroup) {
    const {value} = form.get('billing_alias');
    const {billingAliases} = this;

    return !this.form.get('vendor_id').value || !this.form.get('ban').value || !!(
      !value ||
      billingAliases.filter(
        item => item.alias.toLowerCase() === value.toLowerCase()
      ).length
    );
  }

  removeAlias = billingAlias =>
    (this.billingAliases = this.billingAliases.filter(
      item => item.alias !== billingAlias.alias
    ));

  handleNotUniqueAlias = () => {
    this.alert.error('', this.messages.get('NOT_UNIQUE_ALIAS'));
    this.toggleDialogButtons(false);
  };

  addBillingAlias(form: FormGroup) {
    const {value} = form.get('billing_alias');
    return this.inventoryService.checkAlias({
      inventoryId: this.inventory.id,
      ban: form.get('ban').value,
      vendor: form.get('vendor_id').value,
      alias: value
    }).subscribe((result) => {
      const {valid} = result;
      if (valid) {
        this.billingAliases = [...this.billingAliases, {alias: value}];
        this.form.get('billing_alias').reset();
      } else {
        this.handleNotUniqueAlias();
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.tabGroup.onActivate.unsubscribe();
  }

  cancel() {
    this.inventoryService.cancelEdit();
    this.closeDialog();
  }

  getMaxInstallDate() {
    let maxInstallDate = this.form.get('disc_date').value;

    if (maxInstallDate) {
      let dateOriginal = moment(maxInstallDate).toDate();
      dateOriginal.setDate(dateOriginal.getDate());
      maxInstallDate = dateOriginal;
    }

    this.maxInstallDate = maxInstallDate ? maxInstallDate : new Date();
  }

  getMinDiscDate(date) {
    this.minDiscDate = moment(date).add(1, 'day');
  }

  onCurrencyChange(event) {
    switch (this.form.get('contract_type_id').value) {
      case(this.INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT): {
        this.onRateCurrencyChange(event);
        break;
      }
      case(this.INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER): {
        this.currencyChangeSvcOrder(event);
        break;
      }
      default: {
        console.error("The contract type is unknown or not selected");
        break;
      }
    }
  }

  currencyChangeSvcOrder(event) {
    this.selectedCurrency = event.selection.currency;
    this.svcOrderServiceQuery.set('currency_id', event.selection.id);
    this.svcOrderServiceQuery.set('inventory_id', this.inventory.id);

    if (this.svcOrderHeaderId) {
      this.inventoryService.findAllSvcOrderServices(this.svcOrderServiceQuery)
        .subscribe(res => {
          this.svcOrderServices = res;
        })
    }
  }

  addContract() {
    const {value} = this.form.get('contract_id');
    let contract;
    if (!this.contractIdsArrray.includes(value)) {
      this.inventoryService.findContractById(value).subscribe(res => {
        contract = res;
        this.contracts.push(contract);
        this.contractIdsArrray.push(value)
        this.form.get('contract_id').reset();
        this.form.controls['contract_id'].setValue(this.contractIdsArrray);
      })
    }

  }


  removeContract(contract) {
    this.contracts = this.contracts.filter(
      item => item.id !== contract.id)

    this.contractIdsArrray = this.contractIdsArrray.filter(
      item => item !== contract.id)

    this.form.controls['contract_id'].setValue(this.contractIdsArrray.length ? this.contractIdsArrray : null);

  }

  isContractDisabled() {
    if (this.form.get('contract_id').value === null || this.form.get('contract_id').value.length) {
      return true
    }
    return false
  }

  findIndexInSelectedRows(id): number {
    for (let i = 0, l = this.selectedRows.length; i < l; i++) {
      if (this.selectedRows[i]['id'] === id) {
        return i;
      }
    }
    return -1;
  }

  findIndexInRateSelectedRows(id): number {
    for (let i = 0, l = this.selectedRateRows.length; i < l; i++) {
      if (this.selectedRateRows[i]['id'] === id) {
        return i;
      }
    }
    return -1;
  }

  onSelectionRateChanged(event) {
    if (!this.delaySelectionChange && this.contractScheduleRates) {
      if (event.currentDeselectedRowKeys.length) {
        event.currentDeselectedRowKeys.forEach(item => {
          let index = this.findIndexInRateSelectedRows(item.id);
          if (index > -1) {
            this.selectedRateRows.splice(index, 1);
          }
        });
      }
    }

    //** Selected rows */
    if (event.currentSelectedRowKeys.length) {
      event.currentSelectedRowKeys.forEach(item => {
        let index = this.findIndexInRateSelectedRows(item.id);
        if (index === -1) {
          this.selectedRateRows.push(item);
        }
      });
    }
    this.getMrc(true);
    this.getNrc(true);
    this.setDateField('rate_effective_date', 'eff_date', true);
    this.setDateField('rate_termination_date', 'exp_date', true);
    this.setTerm(true)

    let newArray = []
    this.selectedRateRows.forEach(item => {
      newArray.push(item.id)
    })

    this.form.get('rates').setValue(newArray);
  }


  onSelectionChanged(event) {
    if (event.currentDeselectedRowKeys.length) {
      event.currentDeselectedRowKeys.forEach(item => {
        let index = this.findIndexInSelectedRows(item.id);
        if (index > -1) {
          this.selectedRows.splice(index, 1);
        }
      });
    }

    //** Selected rows */
    if (event.currentSelectedRowKeys.length) {
      event.currentSelectedRowKeys.forEach(item => {
        let index = this.findIndexInSelectedRows(item.id);
        if (index === -1) {
          this.selectedRows.push(item);
        }
      });
    }

    this.getMrc();
    this.getNrc();
    this.setDateField('rate_eff_date', 'eff_date');
    this.setDateField('rate_term_date', 'exp_date');
    this.setTerm();

    let newArray = []

    this.selectedRows.forEach(item => {
      newArray.push(item.id)
    })

    this.form.get('svc_order_service_id').setValue(newArray);
  }

  loadSvcOrderServices(e) {
    this.svcOrderHeaderId = e.value;
    const currency_id = this.form.get('currency_id').value;
    if(currency_id) {
      this.svcOrderServiceQuery.set('svc_order_header_id', this.svcOrderHeaderId)
      this.svcOrderServiceQuery.set('inventory_id', this.inventory.id)
      this.svcOrderServiceQuery.set('currency_id', currency_id);
      this.inventoryService.findAllSvcOrderServices(this.svcOrderServiceQuery)
        .subscribe(res => {
          this.svcOrderServices = res;
        })
    }
  }

  loadContractScheduleRates(e) {
    this.scheduleId = e.value;
    if (this.form.get('currency_id').value) {
      const currency = this.form.get('currency_id').value;
      this.scheduleRateQuery.set('currency_id', currency)
      this.scheduleRateQuery.set('contract_schedule_id', this.scheduleId)
      this.inventoryService.findAllScheduleRates(this.scheduleRateQuery)
        .subscribe(res => {
          this.delaySelectionChange = false;
          this.contractScheduleRates = res.items;
          this.selectedRateRows = [];
        })
    }
  }

  setContractScheduleDates() {
    this.contractService.findSchedule(this.scheduleId).subscribe((res) => {
      this.form.get('eff_date').setValue(moment.utc(res.effective_date))
      this.form.get('exp_date').setValue(moment.utc(res.termination_date))
      this.setTerm(true);
    })
  }

  onRateCurrencyChange(event) {
    if (this.form.get('contract_schedule_id').value) {
      this.scheduleId = this.form.get('contract_schedule_id').value;
      this.selectedCurrency = event.selection.currency;
      this.scheduleRateQuery.set('currency_id', event.selection.id)
      this.scheduleRateQuery.set('contract_schedule_id', this.scheduleId)
      this.inventoryService.findAllScheduleRates(this.scheduleRateQuery)
        .subscribe(res => {
          this.delaySelectionChange = false;
          this.contractScheduleRates = res.items;
          this.selectedRateRows = [];
        })
    }
  }

  clearSvcOrderServicePicker() {
    this.svcOrderServices = null;
  }

  clearContractSchedulePicker() {
    this.contractScheduleRates = null;
    this.form.get('contract_schedule_id').setValue(null);
    this.form.get('eff_date').setValue(null);
    this.form.get('exp_date').setValue(null);
    this.selectedRateRows = [];
    delete this.scheduleRateQuery.where.contract_schedule_id;
  }

  clearRateCurrencyPicker() {
    this.contractScheduleRates = null;
    this.selectedRateRows = [];
    this.selectedRows = [];
    this.svcOrderServices = [];
    this.form.get('rates').setValue(null);
    this.form.get('currency_id').setValue(null);
    delete this.scheduleRateQuery.where.currency_id;
    this.svcOrderServiceGrid && this.svcOrderServiceGrid.instance.refresh();
    this.rateScheduleServiceGrid && this.rateScheduleServiceGrid.instance.refresh();
  }

  onAgreementTypeSelectionChange(event) {
    if (event.value === this.INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT) {
      this.form.get('contract_schedule_id').setValidators([Validators.required]);
      this.form.get('contract_id').setValidators([Validators.required]);
    } else {
      this.form.get('contract_schedule_id').clearValidators();
      this.form.get('contract_id').clearValidators();
    }

    this.form.get('contract_schedule_id').updateValueAndValidity();
    this.form.get('contract_id').updateValueAndValidity();
  }

  toggleFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  filterData(query) {
    const contractScheduleId = this.form.get('contract_schedule_id').value;
    const currencyId = this.form.get('currency_id').value;
    if (contractScheduleId && currencyId) {
      this.delaySelectionChange = true;
      this.scheduleRateQuery.where = {};
      this.scheduleRateQuery.set('contract_schedule_id', contractScheduleId);
      this.scheduleRateQuery.set('currency_id', currencyId)
      this.scheduleRateQuery.where = Object.assign(this.scheduleRateQuery.where, query.where);
      this.inventoryService.findAllScheduleRates(this.scheduleRateQuery)
        .subscribe(res => {
          this.contractScheduleRates = res.items;
          if (this.contractScheduleRates.length) {
            setTimeout(() => {
              this.delaySelectionChange = false;
              this.rateScheduleServiceGrid.instance.selectRows(this.selectedRateRows, false);
            }, 500);
          }
        })
    }
  }

  clearFilter() {
    const contractScheduleId = this.form.get('contract_schedule_id').value;
    const currencyId = this.form.get('currency_id').value;
    if (contractScheduleId && currencyId) {
      this.scheduleRateQuery.where = {};
      this.scheduleRateQuery.set('contract_schedule_id', contractScheduleId);
      this.scheduleRateQuery.set('currency_id', currencyId)

      this.inventoryService.findAllScheduleRates(this.scheduleRateQuery)
        .subscribe(res => {
          this.delaySelectionChange = true;
          this.contractScheduleRates = res.items;
          if (this.contractScheduleRates.length) {
            setTimeout(() => {
              this.delaySelectionChange = false;
              this.rateScheduleServiceGrid.instance.selectRows(this.selectedRateRows, false);
            }, 500);
          }
        })
    }
  }

}
