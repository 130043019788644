import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class GlBatchInvoiceGridService extends GridService {
  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.cost.batch-output-invoice-charges-grid', userSettings);
  }

  defaultColumns = [
    {
      caption: 'Invoice ID',
      dataField: 'invoice.header.id',
      width: 100
    },
    {
      caption: 'Invoice Number',
      dataField: 'invoice.sp_inv_num',
      cellTemplate: 'invoiceNumberTemplate',
      width: 150
    },
    {
      caption: 'AP Invoice Number',
      dataField: 'ap_invoice_number',
      width: 150,
    },
    {
      caption: 'Status',
      dataField: 'invoice.header.status.value',
      width: 160
    },
    {
      caption: 'Vendor',
      dataField: 'invoice.vendor.name',
      width: 150
    },
    {
      caption: 'BAN',
      dataField: 'invoice.acct_level_1',
      width: 150
    },
    {
      caption: 'Invoice Date',
      dataField: 'invoice.inv_date',
      cellTemplate: 'dateTemplate',
      dataType: 'date',
      alignment: 'left',
      width: 150
    },
    {
      caption: 'Due Date',
      dataField: 'invoice.due_date',
      cellTemplate: 'dateTemplate',
      dataType: 'date',
      alignment: 'left',
      width: 150
    },
    {
      alignment: 'right',
      caption: 'Amount Processed',
      dataField: 'invoice.gl_invoice_amt.gl_amt',
      dataType: 'number',
      cellTemplate: 'currencyTemplate',
      csvDataType: 'currency',
      width: 150
    },
    {
      caption: 'Currency',
      dataField: 'invoice.currency',
      width: 150
    },
    {
      alignment: 'right',
      caption: 'Charge Qty',
      dataField: 'invoice.header.charges_count',
      dataType: 'number',
      width: 150,

    },
    {
      caption: 'Deactivated At',
      dataField: 'deactivated_at',
      dataType: 'datetime',
      alignment: 'left',
      cellTemplate: 'deactivatedAtTemplate',
      width: 170
    },
    {
      caption: 'Deactivated By',
      dataField: 'deactivator.full_name',
      width: 170
    },
    {
      caption: 'Deactivation Note',
      dataField: 'deactivation_note',
      width: 170,
      cellTemplate: 'deactivatedNote'
    },
    {
      caption: 'File Name',
      dataField: 'file_name',
      width: 170,
      cellTemplate: 'fileTemplate'
    },
  ];
}




