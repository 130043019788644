import { MAT_DATE_FORMATS } from '@angular/material/core';
import {Directive} from '@angular/core';

@Directive({
  selector: '[dateFormat]',
  providers: [
    {provide: MAT_DATE_FORMATS, useFactory: () => {

      let dateFormat = localStorage.getItem('date_format').toUpperCase();

        return {
          parse: {
            dateInput: dateFormat || 'MM/DD/YYYY',
          },
          display: {
            dateInput: dateFormat || 'MM/DD/YYYY',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM YYYY',
          },
        }
    }}
  ]
})
export class DatePickerFormatDirective {
}


