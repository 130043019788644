import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Restangular} from "ngx-restangular";
import {Address} from "./address.model";
import {ApiService} from "../../core/api";
import Query from "../../core/query/query";

@Injectable()
export class AddressService extends BaseService<any> {

  constructor(public restangular: Restangular,
              private apiService: ApiService) {
    super('address', restangular);
  }

  suggest(q: string,country_id?:any): any {
    const query = new Query({
      where: {q,country_id }
    });
    return this.apiService.get([this.name, 'suggest'], query);
    //return this.service().all(this.name).all('suggest').customGET(null, this.toFilter(strAddress));
  }

  suggestInternal(q: string): any {
    const query = new Query({
      where: {q}
    });
    return this.service().all(this.name).all('suggestinternal').customGET(null, this.toFilter(query));
  }

  verify(strAddress: Object): any {
    return this.service().all(this.name).all('verify').customGET(null, this.toFilter(strAddress));
  }

  createOrUpdate(item: Address): any {
    return this.service().all(this.name).all('check').post(item);
  }

}
