import {Injectable} from '@angular/core';
import Query from "../../core/query/query";
import {GridService} from "../../shared/grid/grid.service";
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";
import { LocationService } from "app/location/core/location.service";
import { DictionaryService } from 'app/dictionary/core/dictionary.service';
import {LOOKUP_MODELS_ENUM} from 'app/dictionary/core/lookup-models.enum';

@Injectable()
export class ContractScheduleDetailRateGridService extends GridService {

  public rateSchedule: any = [];
  public rateCurrency: any = [];
  public rateQtyUom: any = [];
  public rateCategory: any = [];
  public rateType: any = [];
  public rateCountry: any = [];
  public rateState: any = [];
  public rateTermUom: any = [];
  public defaultColumns = [];
  public rateJur: any = [];
  private jurIds = {
    us: 1,
    int: 2
  }

  constructor(public userSettings: UserSettingsService,
              public locationService: LocationService,
              public dictionaryService: DictionaryService,
              public formatService: FormatService) {
    super("app.contract.contract-schedule-detail-rate", userSettings);

    this.getFilteredStates = this.getFilteredStates.bind(this);
    this.getFilteredJur = this.getFilteredJur.bind(this);

    this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.CONTRACT_RATE_QTY_UOM.modelName, new Query, true)
      .subscribe(result => {
        this.rateQtyUom = result.items;
      })
    this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.TERM_UNIT_OF_MEASURE.modelName, new Query, true)
      .subscribe(result => {
        this.rateTermUom = result.items;
      })
    this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.RATE_CATEGORY.modelName, new Query, true)
      .subscribe(result => {
        this.rateCategory = result.items;
      })
    this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.RATE_TYPE.modelName, new Query, true)
      .subscribe(result => {
        this.rateType = result.items;
      })
    this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.JUR.modelName, new Query({orderBy: [["value", "ASC"]]}), true)
      .subscribe(result => {
        this.rateJur = result.items.map(jur => ({ ...jur, code_value : `${jur.key} - ${jur.value}`, country_id: jur.key === 'INT' ? this.jurIds.int : this.jurIds.us}));
      })
    this.locationService.findAllSubdivision(new Query({orderBy: [["country_code", "ASC"]]}))
      .subscribe(result => {
        this.rateState = result.items;
      })
    this.locationService.findAll(new Query({orderBy: [["name", "ASC"]]}))
      .subscribe(result => {
        this.rateCountry = result.items.map(country => ({...country, code_name: `${country.alpha_2_code} - ${country.name}`}));
      })
    this.locationService.findAllUniqueCurrencies(new Query({orderBy: [["currency", "ASC"]]}))
      .subscribe(result => {
        this.rateCurrency = result.items;
      })
  }

  setCountryValue(rowData: any, value: any): void {
    rowData.state_id = null;
    rowData.jur = null;
    (<any> this).defaultSetCellValue(rowData, value);
  }

  setJurValue(rowData: any, value: any): void {
    rowData.state_id = null;
    (<any> this).defaultSetCellValue(rowData, value);
  }

  getFilteredStates(options) {
    return {
      store: this.rateState.map(state => ({ ...state, abbrv_name: `${state.abbreviation} - ${state.name}` })),
      filter: options.data ? ['countries.id', '=', options.data.country_id] : null,
      sort: { field: "abbreviation", desc: false }

    };
  }

  getFilteredJur(options) {
    return {
      store: this.rateJur,
      filter: options.data ? ['country_id', '=', options.data.country_id == 1 ? this.jurIds.us : this.jurIds.int] : null,
      sort: { field: "value", desc: false }
    };
  }

  getColumns(columns = [], dateFormat?: string) {
    dateFormat = dateFormat ?? 'MM/dd/yyyy';

      this.defaultColumns = [
        {
          caption: "",
          headerCellTemplate: 'selectionBadgeHeaderTemplate',
          cellTemplate: "selectionBadgeCellTemplate",
          fixed: true,
          width: 40,
          allowSorting: false
        },
        {
          caption: 'Line Number',
          dataField: 'line_number',
          width: 100
        },
        {
          caption: "Section Name",
          dataField: "section_name",
          dataType: 'string',
          alignment: 'left',
          width: 140
        },
        {
          headerCellTemplate: 'contractScheduleDetailRateHeaderTemplate',
          caption: "Rate Code*",
          dataField: "rate_code",
          alignment: 'left',
          dataType: 'string',
          width: 120
        },
        {
          caption: "SPID",
          dataField: "spid",
          alignment: 'left',
          allowSorting: false,
          allowEditing: false,
          width: 120
        },
        {
          caption: "Charge Description",
          dataField: "svc_chg_desc",
          alignment: 'left',
          width: 150
        },
        {
          caption: "Rate Qty",
          dataField: "rate_qty",
          alignment: 'left',
          dataType: 'number',
          width: 150
        },
        {
          caption: "Rate Qty Uom",
          dataField: "rate_qty_uom_id",
          dataType: 'number',
          lookup: {
            dataSource: this.rateQtyUom,
            valueExpr: "id",
            displayExpr: "value"
          },
          alignment: 'left',
          width: 120
        },
        {
          caption: "Rate Qty Type",
          dataField: "rate_qty_type",
          alignment: 'left',
          dataType: 'string',
          width: 150
        },
        {
          caption: "Rate Type",
          dataField: "rate_type",
          lookup: {
            dataSource: this.rateType,
            valueExpr: "id",
            displayExpr: "value"
          },
          alignment: 'left',
          width: 120
        },
        {
          headerCellTemplate: 'contractScheduleDetailRateHeaderTemplate',
          caption: "Rate Category*",
          dataField: "rate_category",
          lookup: {
            dataSource: this.rateCategory,
            valueExpr: "id",
            displayExpr: "value"
          },
          alignment: 'left',
          width: 120
        },
        {
          headerCellTemplate: 'contractScheduleDetailRateHeaderTemplate',
          caption: "MRC*",
          dataField: "mrc",
          dataType: 'number',
          csvDataType: 'currency',
          cellTemplate: 'rateCurrencyTemplate',
          alignment: 'left',
          width: 120
        },
        {
          headerCellTemplate: 'contractScheduleDetailRateHeaderTemplate',
          caption: "NRC",
          dataField: "nrc",
          dataType: 'number',
          csvDataType: 'currency',
          cellTemplate: 'rateCurrencyTemplate',
          alignment: 'left',
          width: 120
        },
        {
          headerCellTemplate: 'contractScheduleDetailRateHeaderTemplate',
          caption: 'Currency*',
          dataField: 'currency_id',
          lookup: {
            dataSource: this.rateCurrency,
            valueExpr: "id",
            displayExpr: "currency"
          },
          alignment: 'left',
          width: 120
        },
        {
          caption: "Country",
          dataField: "country_id",
          setCellValue: this.setCountryValue,
          lookup: {
            dataSource: this.rateCountry,
            valueExpr: "id",
            displayExpr: "code_name"
          },
          alignment: 'left',
          width: 150
        },
        {
          caption: "Jur",
          dataField: "jur",
          setCellValue: this.setJurValue,
          lookup: {
            dataSource: this.getFilteredJur,
            valueExpr: "id",
            displayExpr: "code_value"
          },
          dataType: 'string',
          alignment: 'left',
          width: 150
        },
        {
          caption: "StateProv",
          dataField: "state_id",
          lookup: {
            dataSource: this.getFilteredStates,
            valueExpr: "id",
            displayExpr: "abbrv_name"
          },
          alignment: 'left',
          width: 150
        },
        {
          caption: "Rate Term Qty",
          dataField: "rate_term_qty",
          dataType: 'number',
          alignment: 'left',
          width: 120
        },
        {
          caption: "Rate Term Uom",
          dataField: "rate_term_uom_id",
          lookup: {
            dataSource: this.rateTermUom,
            valueExpr: "id",
            displayExpr: "value"
          },
          alignment: 'left',
          width: 150
        },
        {
          cellTemplate: "dateTimeTemplate",
          caption: "Effective Date",
          dataField: "rate_effective_date",
          dataType: 'date',
          format: dateFormat,
          alignment: 'left',
          width: 120
        },
        {
          cellTemplate: "dateTimeTemplate",
          caption: "Termination Date",
          dataField: "rate_termination_date",
          dataType: 'date',
          format: dateFormat,
          alignment: 'left',
          width: 120
        },
        {
          caption: "Rate Note",
          dataField: "rate_note",
          alignment: 'left',
          width: 120
        },
        {
          caption: "Audits",
          dataField: 'audits',
          dataType: 'string',
          allowSorting: false,
          allowEditing: false,
          alignment: 'left',
          width: 150
        }
      ]

    const defaultColumns = [...this.defaultColumns];
    if (!columns.length) {
      return defaultColumns.map((col) => {
        const colFromSettings = columns?.find(x => x.dataField === col.dataField)
        return Object.assign({}, col, colFromSettings || {})
      });
    } else {
      return columns.map((col) => {
        const colFromDefault = defaultColumns?.find(x => x.dataField === col.dataField)
        return Object.assign({}, colFromDefault, col || {})
      });
    }
  }
}
