import {UserSettingsService} from "../user/core/user-settings.service";
import {map} from "rxjs/operators";

export class PageContext {

  constructor(private config?: any) {
    if (config) {
      Object.assign(this, config);
    }
  }

  settings: UserSettingsService;
  name: string;
  resource: string;

  loadSettings() {

    return this.settings.me().pipe(
      map((data) => {
        let settings = data[this.name] || {}; //this.settings.entrySettings(this.name);

        if (this.config.data) {
          settings = Object.assign({}, this.config.data, settings);
        }

        return settings;
      }));

  }
}
