
import {EventEmitter, Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {User} from './user';
import {Restangular} from 'ngx-restangular';
import {AppService} from "../../app.service";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {ApiService} from "../../core/api";
import {UserSettingsService} from "./user-settings.service";
import Query from 'app/core/query/query';
import {map} from "rxjs/operators";
@Injectable()
export class UserService extends BaseService<User> {
  public userChanged: EventEmitter<User>;

  constructor(public restangular: Restangular,
              public appService: AppService,
              public apiService: ApiService,
              private userSettingsService: UserSettingsService,
              private entityLock: EntityLockService) {
    super('user', restangular, entityLock);
    this.userChanged = new EventEmitter();
  }

  cache() {
    return this.apiService.cache(this.name);
  }

  settings() {
    return this.userSettingsService.me();
  }

  me() {
    const cache = this.cache();
    return this.apiService.get(['user', 'me'], null, {cache})
  }

  getUserInitials(user: User): string {
    if (user && user.first_name && user.last_name) {
      return user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0];
    } else {
      return "NN";
    }
  }

  findOnlyNecessary(query?: Query): any {
    return this.apiService.get([this.name, 'findOnlyNecessary'], query);
  }

  search(id){
    return this.apiService.get([this.name,id, 'search']);
  }

  update(id, user) {
    const cache = this.cache();
    return super.update(id, user)
      .pipe(map((user) => {
        cache.removeAll();
        this.appService.emit("user", user);
        return user;
      }));
  }
  checkPassword(resetToken: string, password: string) {
    return this.apiService.post(['auth', 'checkPassword'], {
      resetToken: resetToken,
      password: password
    });
  }

  findAll(query?: Query): any {
    return this.apiService.get([this.name, 'users'], query);
  }

}


