<app-inventory-details-toolbar
  [inventory]="inventory"
  [isAddPricingHidden]="true"
  [isAddPricingDisabled]="true"
  [isCreateOrderHidden]="false"
  [isCreateOrderDisabled]="true"
  [isAddGLHidden]="false"
  [isAddGLDisabled]="true"
  [isEditInventoryDisabled]="true"
  [isDocumentAction]="false"
  [isPricingDocumentAction]="false"
  [isFilterDisabled]="false"
  [isNotesOn]="($activeSiderSection | async) === SECTIONS.NOTES_SECTION_NAME"
  [isHistoryOn]="($activeSiderSection | async) === SECTIONS.HISTORY_SECTION_NAME"
  [isFilterOn]="($activeSiderSection | async) === SECTIONS.FILTER_SECTION_NAME"
  [isCSVDisabled]="false"
  [notesCount]="notesCount"
  
  (toggleNotesRequested)="toggleNotes()"
  (toggleHistoryRequested)="toggleHistory()"
  (toggleFilterRequested)="toggleFilter()"
  (downloadCSVRequested)="onDownloadCSVRequested()"
  (backRequested)="back()"
>
</app-inventory-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-inventory-details-tabs [activeTabIndex]="3"></app-inventory-details-tabs>
  <div class="app-content app-content-list dx-nodata-absolute" style="height: 100%">
  <dx-data-grid #billingGrid
                class="app-grid-with-pager charge-browser-grid"
                style="height: 100%; padding-bottom: 50px;"
                [columns]="chargesColumns"
                [dataSource]="charges"
                (onCellClick)="onChargesCellClick($event)"
                [showRowLines]="true"
                [showColumnLines]="false"
  >

    <div *dxTemplate="let d of 'chargeDisputeTemplate'" class="grid-selection-badge-container">
      <a [class]="d.data.charge_dispute ? 'icon-danger' : 'disabled'"
         (click)="d.data.charge_dispute?.dispute_id ? showDispute(d.data.charge_dispute.dispute_id) : null"
         matTooltip="Dispute" class="selection-badge grid-badge">
        <ca-icon [key]="'DISPUTE'" [innerClass]="'charge-grid-icon'"></ca-icon>
      </a>
    </div>

    <div *dxTemplate="let d of 'currencyTemplate'">
      <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.currency_obj?.currency}}</div>
    </div>
    <div *dxTemplate="let d of 'dateTemplate'">
      <div caGridTooltip>{{ d.value | caDate }}</div>
    </div>
    <div *dxTemplate="let d of 'chargeRateTemplate'">
      <div caGridTooltip class="right">{{ d.value | caNumber}}</div>
    </div>
  </dx-data-grid>
</div>
  <ca-pager class="app-pager ca-default-border-top" [pageContext]="billingGridService"  [query]="chargeQuery" (onChange)="loadCharges(inventory)"></ca-pager>
  <mat-sidenav #panelSide
               [mode]="'side'"
               position="end"
               [opened]="false">
    <app-filter-container [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                          [query]="chargeQuery"
                          [filterService]="inventoryBillingFilterService"
                          (filterRequested)="filterData($event)"
                          (clearFilterRequested)="clearFilter()"></app-filter-container>

    <ca-history
      #history
      [entityId]="inventory?.id"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
      [entityType]="'inventory'">
    </ca-history>

    <ca-notes
      [entityId]="inventory?.id"
      [entityType]="SYSTEM_MODULE.INVENTORY"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
      (countChanged)="onNotesCountChanged($event)">
    </ca-notes>
  </mat-sidenav>
</mat-sidenav-container>
