import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Restangular} from 'ngx-restangular';
import {HttpClient} from "@angular/common/http";
import { DialogService } from 'app/shared/dialog/dialog.service';

@Injectable()
export class ReconExcludedService extends BaseService<any> {

  constructor(public restangular: Restangular,
              public http: HttpClient) {
    super('recon-excluded', restangular);
  }

}
