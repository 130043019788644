<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{ formTitle }}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content app-inventory-manage-dialog" *ngIf="form">
  <div style="min-height: 320px;">
    <div class="ca-tab tabbed-content padding-bottom centered-tabs">
      <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="activeTabIndex" class="tabs">
        <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs">
        </mat-tab>
      </mat-tab-group>
      <button *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT && tabGroup.isActive(2)"
              matTooltip="Filter"
              [ngClass]="{'on': isFilterOpen}"
              (click)="isFilterOpen = !isFilterOpen" mat-icon-button
      >
        <ca-icon [key]="'FILTER'"></ca-icon>
      </button>
    </div>

    <div [ngClass]="{'hidden': !tabGroup.isActive(1)}" class="tabs-body fixed tabs-content-padding">
      <form class="ca-form dialog-content-no-margin" [formGroup]="form">
        <div class="flex-container" fxLayout>
          <div class="flex-item caGapRight" fxFlex><!--="60%"-->
            <div class="flex-container" fxLayout fxLayout.sm="column">
              <div class="flex-item caGap caGapRight caGapRightRowItem" fxFlex>

                <ca-vendor-picker
                  caInput
                  formControlName="vendor_id"
                  [pickerForm]="form"
                  placeholder="Vendor"
                  [initialItem]="inventory?.vendor"
                  [clearEnabled]="true"
                  [required]="true"
                  [customMethod]="'findOnlyVendors'">
                </ca-vendor-picker>
                <mat-hint class="ca-select-hint"
                          *ngIf="form.hasError('required', ['vendor_id']) && form.controls.vendor_id.touched">
                  {{VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </div>

              <div class="flex-item caGap caGapRightRowItem" fxFlex>
                <ca-account-picker #accountPicker
                                   caInput
                                   formControlName="ban"
                                   [pickerForm]="form"
                                   placeholder="BAN"
                                   [initialItemId]="inventory?.ban"
                                   [initialItem]="inventory?.ban ? { account_no: inventory?.ban } : null"
                                   [clearEnabled]="true"
                                   [vendorId]="form.get('vendor_id').value"
                                   [required]="true"
                                   [disabled]="!form.get('vendor_id').value">
                </ca-account-picker>
              </div>

            </div>

            <div class="flex-container" fxLayout fxLayout.sm="column">
              <div class="flex-item caGap caGapRight caGapRightRowItem" fxFlex>
                <ca-customer-picker
                  caInput
                  name="customer"
                  formControlName="customer_id"
                  placeholder="Customer"
                  [clearEnabled]="true"
                  [searchEnabled]="true"
                  [initialItem]="inventory?.customer"
                  [pickerForm]="form">
                </ca-customer-picker>
              </div>

              <div class="flex-item caGap caGapRightRowItem" fxFlex>
                <ca-lookup-model-picker
                  caInput
                  [lookupModel]="INVENTORY_STATUS_LOOKUP_MODEL"
                  [required]="true"
                  [pickerForm]="form"
                  formControlName="status_id"
                  placeholder="Status"
                >
                </ca-lookup-model-picker>
                <mat-hint class="ca-select-hint"
                          *ngIf="form.hasError('required', ['status_id']) && form.controls.status_id.touched">
                  {{VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </div>
            </div>

            <div class="flex-container" fxLayout fxLayout.sm="column">
              <div class="flex-item caGap caGapRight caGapRightRowItem" fxFlex>
                <ca-date-picker [required]="true" placeholder="Install Date"
                                caInput
                                [maxDate]="maxInstallDate"
                                formControlName="install_date"
                                (selectedChanged)="getMinDiscDate(this.form.get('install_date').value)"></ca-date-picker>
              </div>
              <div class="flex-item caGap caGapRightRowItem" fxFlex>
                <ca-date-picker caInput placeholder="Disconnect Date" formControlName="disc_date"
                                [minDate]="minDiscDate"
                                (selectedChanged)="getMaxInstallDate()"
                >
                </ca-date-picker>
              </div>
            </div>

            <div class="flex-container" fxLayout fxLayout.sm="column">
              <div class="flex-item caGap caGapRight caGapRightRowItem" fxFlex>
                <mat-form-field caInput>
                  <input matInput type="text" placeholder="SP Svc ID" formControlName="sp_ckt_id">
                  <mat-hint *ngIf="form.hasError('pattern', ['sp_ckt_id']) && form.controls.sp_ckt_id.touched">
                    {{INVALID_SP_CKT_ID_MSG}}
                  </mat-hint>
                </mat-form-field>
              </div>
              <div class="flex-item caGap caGapRightRowItem" fxFlex>
                <mat-form-field caInput>
                  <input matInput type="text" placeholder="Internal ID" formControlName="internal_id">
                </mat-form-field>
              </div>
            </div>

            <div class="flex-container" fxLayout fxLayout.sm="column">
              <div class="flex-item caGap caGapRight caGapRightRowItem" fxFlex>
                <ca-customer-inventory-picker
                  caInput
                  name="customer_circuit_id"
                  formControlName="customer_circuit_id"
                  placeholder="Customer Service ID"
                  [pickerForm]="form"
                  [initialItem]="inventory?.customer_inventory"
                  [clearEnabled]="true"
                  [searchEnabled]="true">
                </ca-customer-inventory-picker>
              </div>

              <div  class="flex-item caGap hiddenOn959" fxFlex>
                <ca-date-picker *ngIf="!ordersAllowed" placeholder="Stop Billing Date"
                                caInput
                                formControlName="stop_billing_date"></ca-date-picker>
              </div>
            </div>
          </div>

          <div fxFlex="240px" fxLayout="column" class="alias-address-container"
               [ngClass]="{'caGapRight': fields && fields.length}">

            <div class="flex-item" fxFlex="0 0 auto" fxLayout="row wrap">
              <div fxFlex class="caGap caGapRight">
                <mat-form-field caInput>
                  <input matInput placeholder="Alias" formControlName="billing_alias" type="text">
                </mat-form-field>
              </div>


              <div fxFlex="0 0 auto" fxLayoutAlign="start center" class="add-alias-button">
                <button  mat-icon-button (click)="addBillingAlias(form)" [disabled]="isAddBillingAliasDisabled(form)">
                  <ca-icon [key]="'ADD'"></ca-icon>
                </button>
              </div>
            </div>

            <div class="billing-list-container">
              <div class="ca-empty-state-container"
                   *ngIf="!billingAliases.length">
                <p class="card-message">
                  No billing aliases added
                </p>
              </div>

              <div fxLayout="row" fxLayoutAlign="flex-start center"
                   class="flex-container hide-delete-icon-container ca-min-height-40"
                   *ngFor="let billingAlias of billingAliases; let i=index"
                   style="display: flex;
                                align-items: center;">
                <div class="flex-item flex-item-centered billing-alias__text-container ca-min-height-40" fxFlex>
                  <span>{{billingAlias.alias}}</span>
                </div>
                <div class="flex-item flex-item-centered billing-alias__text-container delete-button-container"
                     fxFlex="40px">
                  <button mat-icon-button tabindex="-1" matTooltip="Remove Alias" (click)="removeAlias(billingAlias)">
                    <ca-icon [key]="'DELETE'"></ca-icon>
                  </button>
                </div>
              </div>
            </div>


          </div>

          <div *ngIf="fields && fields.length"
               fxFlex="300px" fxLayout="column" style="position: relative"
               class="custom-fields-wrapper"
          >
            <ca-custom-fields
              [form] = "this.form"
              [customFieldService]="this.inventoryService"
              [customField]="this.inventory"
              [fields]="fields"
              [categories]="categories"
              [showCategory]="showCategory"
              [clearEnabled]="true"
              [onlyRequiredCustomFields] = "false"
            ></ca-custom-fields>
          </div>
        </div>
      </form>
    </div>
    <!--Financial -->
    <div  [fxLayout]="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT ? 'row' : 'column'"
      [ngStyle]="{'height':tabGroup.isActive(2)? '85%':'0'}">
    <div [ngClass]="{'hidden': !tabGroup.isActive(2)}"
         class="tabs-body tabs-content-padding gl-string-grid-panel app-content-list no-padding-app-content-list app-content-list-no-pager"
          [ngClass]="{'grid-filter': isFilterOpen && form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT}"
         [ngStyle]="{'width':!form.controls.vendor_id.value? '100%':''}">

      <div *ngIf="!form.controls.vendor_id.value" class="ca-empty-state-container">
        <p class="small-div-message">No Vendor selected</p>
      </div>

      <form *ngIf="form.controls.vendor_id.value" [formGroup]="form" class="ca-form dialog-content-no-margin">
        <div class="flex-container" fxLayout>
          <div class="flex-item" fxFlex [fxLayout]="(form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN || form.get('contract_type_id').value === null) ? 'row' : 'column'" >
            <div class="flex-container" [fxLayout]="(form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN || form.get('contract_type_id').value === null) ? 'column' : 'row'" fxFlex >

              <div class="flex-container" fxLayout fxFlex fxLayoutGap="24px" fxLayout.sm="column" fxLayoutAlign="center none" >
                <div class="flex-item caGap caGapRight" fxFlex="100%">
                  <ca-lookup-model-picker
                    caInput
                    [lookupModel]="INVENTORY_CONTRACT_TYPE"
                    formControlName="contract_type_id"
                    placeholder="Agreement type"
                    [pickerForm]="form"
                    (onSelectionChange)="onAgreementTypeSelectionChange($event)"
                    [clearEnabled]="true">
                  </ca-lookup-model-picker>
                </div>
              </div>

              <div class="flex-item caGap caGapRight" fxFlex *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER">
                <ca-contract-picker
                  clearEnabled="true"
                  caInput
                  #contractPicker
                  name="contract_id"
                  formControlName="svc_order_id"
                  [query]="contractQuery"
                  [pickerForm]="form"
                  (onSelectionChange)="loadSvcOrderServices($event)"
                  [initialItem]="inventory?.contract"
                  placeholder="Svc Order Header"
                  [disabled]="!form.get('vendor_id').value"
                  [showOrderSvc]="true"
                  (onPickerClear)="clearSvcOrderServicePicker()"
                  [vendorId]="form.get('vendor_id').value">
                </ca-contract-picker>
              </div>

              <div class="flex-item caGap caGapRight" fxFlex *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT">
                <ca-contract-picker
                  clearEnabled="true"
                  caInput
                  #contractPicker
                  name="contract_id"
                  formControlName="contract_id"
                  [query]="contractQuery"
                  [pickerForm]="form"
                  placeholder="Contract"
                  [disabled]="!form.get('vendor_id').value"
                  [showOrderSvc]="false"
                  [showContract]="true"
                  [vendorId]="form.get('vendor_id').value"
                >
                </ca-contract-picker>
              </div>
              <div class="flex-item caGap caGapRight" fxFlex *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT">
                <ca-contract-schedule-picker
                  caInput
                  formControlName="contract_schedule_id"
                  [pickerForm]="form"
                  placeholder="Contract Schedule"
                  [clearEnabled]="true"
                  [disabled]="!form.get('contract_id').value"
                  [contractId]="form.get('contract_id').value"
                  [query]="contractScheduleQuery"
                  [svcOrderServices]="false"
                  [dateToday]="true"
                  (onSelectionChange)="loadContractScheduleRates($event)"
                  (onPickerClear)="clearContractSchedulePicker()"
                >
                </ca-contract-schedule-picker>
              </div>
              <div class="flex-item caGap" fxFlex
                *ngIf="(form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER || form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT) && auditsEnabled"
                style = "padding-bottom: 16px">
                <mat-slide-toggle formControlName="enable_audit_run" class = "toogle-button-chechbox">
                  Audit run
                </mat-slide-toggle>
              </div>
              <div class="flex-item caGap" fxFlex
                *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER || form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT"
                style = "padding-bottom: 16px">
                <mat-checkbox formControlName="is_customer_provided_access" class="svc-order-service">Customer Access</mat-checkbox>
              </div>

            </div>

            <div class="flex-container"
                 [fxLayout]="(form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN || form.get('contract_type_id').value === null) ? 'row' : 'column'"
                 fxFlex *ngIf="form.get('contract_type_id').value !== INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN">
              <div class="flex-item caGap" fxFlex
                   [fxLayout]="(form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN || form.get('contract_type_id').value === null) ? 'column' : 'row'">

                <div class="flex-container" fxLayout fxLayoutGap="24px" fxLayout.sm="column" fxLayoutAlign="space-between none" *ngIf="form.get('contract_type_id').value === null">
                  <div class="flex-item caGap caGapRight" fxFlex="55%" *ngIf="form.get('contract_type_id').value === null">
                    <ca-currency-picker
                      clearEnabled="true"
                      caInput
                      #currencyPicker
                      [required]="true"
                      [searchEnabled]="true"
                      (onSelectionChange)="onCurrencyChange($event)"
                      name="currency_id"
                      formControlName="currency_id"
                      [pickerForm]="form"
                      placeholder="Currency">
                    </ca-currency-picker>
                  </div>
                  <div class="flex-item caGapRight" fxFlex="55%">
                    <mat-form-field caInput>
                      <input matInput placeholder="Term (months)" formControlName="term" required min="1" max="60"
                             type="number">
                      <mat-hint *ngIf="form.hasError('required', ['term']) && form.controls.term.touched">
                        {{VALIDATION.REQUIRED_ERROR}}
                      </mat-hint>
                      <mat-hint
                        *ngIf="form.hasError('range', ['term']) && form.controls.term.touched && !form.hasError('pattern', ['term'])">
                        {{RangeErrorMessage('1','60')}}
                      </mat-hint>
                      <mat-hint *ngIf="form.hasError('pattern', ['term']) && form.controls.term.touched">
                        {{VALIDATION.INTEGER_INVALID}}
                      </mat-hint>
                    </mat-form-field>

                  </div>
                </div>

                  <div class="flex-item caGap caGapRight" fxFlex="55%"
                    *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER || form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT">
                    <ca-currency-picker
                      clearEnabled="true"
                      caInput
                      #currencyPicker
                      [required]="true"
                      [searchEnabled]="true"
                      (onSelectionChange)="onCurrencyChange($event)"
                      (onPickerClear) = "clearRateCurrencyPicker()"
                      name="currency_id"
                      formControlName="currency_id"
                      [pickerForm]="form"
                      placeholder="Currency">
                    </ca-currency-picker>
                  </div>

                  <div class="flex-item caGapRight" fxLayout="row" fxFlex="100%">
                    <div class="flex-item" fxFlex="100%">
                      <ca-date-picker caInput placeholder="Eff Date" [disabled] = "form.get('contract_type_id').value ? true : false" formControlName="eff_date"></ca-date-picker>
                    </div>
                  </div>
                  <div class="flex-item caGapRight" fxLayout="row" fxFlex="55%">
                    <div class="flex-item" fxFlex="100%">
                      <ca-date-picker caInput placeholder="Exp Date" [disabled] = "form.get('contract_type_id').value ? true : false" formControlName="exp_date"></ca-date-picker>
                    </div>
                  </div>

                  <div class="flex-item caGapRight" fxFlex="55%" *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER ||
                    form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT">
                    <mat-form-field caInput>
                      <input matInput placeholder="Term (months)" formControlName="term" required min="1" max="60"
                             type="number">
                      <mat-hint *ngIf="form.hasError('required', ['term']) && form.controls.term.touched">
                        {{VALIDATION.REQUIRED_ERROR}}
                      </mat-hint>
                      <mat-hint
                        *ngIf="form.hasError('range', ['term']) && form.controls.term.touched && !form.hasError('pattern', ['term'])">
                        {{RangeErrorMessage('1','60')}}
                      </mat-hint>
                      <mat-hint *ngIf="form.hasError('pattern', ['term']) && form.controls.term.touched">
                        {{VALIDATION.INTEGER_INVALID}}
                      </mat-hint>
                    </mat-form-field>

                  </div>

                <div class="flex-container" fxLayout="row" fxLayoutAlign="space-between none" *ngIf="form.get('contract_type_id').value === null">
                  <div class="flex-item caGap caGapRight" fxFlex="55%" >
                    <ca-currency caInput formControlName="est_mrc" placeholder="MRC" required="true" [currency]="selectedCurrency" [disabled] = "form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER ? true : false" ></ca-currency>
                    <mat-hint class="ca-select-hint"
                              *ngIf="form.hasError('required', ['est_mrc']) && form.controls.est_mrc.touched">
                      {{VALIDATION.REQUIRED_ERROR}}
                    </mat-hint>
                  </div>
                  <div class="flex-item caGap caGapRight" fxFlex="55%">
                    <ca-currency caInput formControlName="est_nrc" placeholder="NRC" required="true"  [currency]="selectedCurrency" [disabled] = "form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER ? true : false"></ca-currency>
                    <mat-hint class="ca-select-hint"
                              *ngIf="form.hasError('required', ['est_nrc']) && form.controls.est_nrc.touched">
                      {{VALIDATION.REQUIRED_ERROR}}
                    </mat-hint>
                  </div>
                </div>

                <div class="flex-item caGap caGapRight" fxFlex="55%" *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER ||
                    form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT">
                  <ca-currency caInput formControlName="est_mrc" placeholder="MRC" required="true" [currency]="selectedCurrency" [disabled] = "form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER ? true : false" ></ca-currency>
                  <mat-hint class="ca-select-hint"
                            *ngIf="form.hasError('required', ['est_mrc']) && form.controls.est_mrc.touched">
                    {{VALIDATION.REQUIRED_ERROR}}
                  </mat-hint>
                </div>
                <div class="flex-item caGap" fxFlex="55%" *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER ||
                    form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT">
                  <ca-currency caInput formControlName="est_nrc" placeholder="NRC" required="true"  [currency]="selectedCurrency" [disabled] = "form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER ? true : false"></ca-currency>
                  <mat-hint class="ca-select-hint"
                            *ngIf="form.hasError('required', ['est_nrc']) && form.controls.est_nrc.touched">
                    {{VALIDATION.REQUIRED_ERROR}}
                  </mat-hint>
                </div>
                <div class="flex-container" fxLayout="row"
                     *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN || !form.get('contract_type_id').value">
                  <div class="flex-item caGap" fxFlex="50%">
                    <mat-checkbox formControlName="is_customer_provided_access">Customer Provided Access</mat-checkbox>
                  </div>
                  <div class="flex-item caGap" fxFlex *ngIf="auditsEnabled">
                    <mat-slide-toggle formControlName="enable_audit_run">
                      Audit run
                    </mat-slide-toggle>
                  </div>
                </div>

              </div>
            </div>
            <div class="flex-container" [fxLayout]="(form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN || form.get('contract_type_id').value === null) ? 'row' : 'column'" fxFlex="50%"
                 *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN">
              <div class="flex-item caGap caGapRight" fxFlex [fxLayout]="(form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN || form.get('contract_type_id').value === null) ? 'column' : 'row'">

                <div class="flex-container" fxLayout fxLayoutGap="24px" fxLayout.sm="column" fxLayoutAlign="space-between none">

                  <div class="flex-item caGap caGapRight" fxFlex="55%">
                    <ca-currency-picker
                      clearEnabled="true"
                      caInput
                      #currencyPicker
                      [required]="true"
                      [searchEnabled]="true"
                      (onSelectionChange)="onCurrencyChange($event)"
                      name="currency_id"
                      formControlName="currency_id"
                      [pickerForm]="form"
                      placeholder="Currency">
                    </ca-currency-picker>
                  </div>
                  <div class="flex-item caGapRight" fxFlex="55%">
                    <mat-form-field caInput>
                      <input matInput placeholder="Term (months)" formControlName="term" required min="1" max="60"
                            type="number">
                      <mat-hint *ngIf="form.hasError('required', ['term']) && form.controls.term.touched">
                        {{VALIDATION.REQUIRED_ERROR}}
                      </mat-hint>
                      <mat-hint
                        *ngIf="form.hasError('range', ['term']) && form.controls.term.touched && !form.hasError('pattern', ['term'])">
                        {{RangeErrorMessage('1','60')}}
                      </mat-hint>
                      <mat-hint *ngIf="form.hasError('pattern', ['term']) && form.controls.term.touched">
                        {{VALIDATION.INTEGER_INVALID}}
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div class="flex-item caGapRight" fxFlex="55%">
                  <ca-date-picker caInput placeholder="Eff Date" formControlName="eff_date" [required]="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN"></ca-date-picker>
                </div>

                <div class="flex-item caGapRight" fxFlex="55%">
                  <ca-date-picker caInput placeholder="Exp Date" formControlName="exp_date" [required]="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN"></ca-date-picker>
                </div>

                <div class="flex-container caGapRight" fxLayout="row" fxLayoutAlign="space-between none">

                <div class="flex-item caGap caGapRight" fxFlex="55%">
                  <ca-currency caInput formControlName="est_mrc" placeholder="MRC" required="true" [currency]="selectedCurrency"></ca-currency>
                  <mat-hint class="ca-select-hint"
                            *ngIf="form.hasError('required', ['est_mrc']) && form.controls.est_mrc.touched">
                    {{VALIDATION.REQUIRED_ERROR}}
                  </mat-hint>
                </div>
                <div class="flex-item caGap" fxFlex="55%">
                  <ca-currency caInput formControlName="est_nrc" placeholder="NRC" required="true"  [currency]="selectedCurrency"></ca-currency>
                  <mat-hint class="ca-select-hint"
                            *ngIf="form.hasError('required', ['est_nrc']) && form.controls.est_nrc.touched">
                    {{VALIDATION.REQUIRED_ERROR}}
                  </mat-hint>
                  </div>
                </div>
                <div class="flex-item caGap" fxFlex *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT" style = "padding-bottom: 16px">
                  <mat-checkbox formControlName="is_customer_provided_access" class="svc-order-service">Customer Provided Access</mat-checkbox>
                </div>

                <div class="flex-container" fxLayout="row" *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN">
                  <div class="flex-item caGap" fxFlex="50%">
                    <mat-checkbox formControlName="is_customer_provided_access">Customer Provided Access</mat-checkbox>
                  </div>
                  <div class="flex-item caGap" fxFlex *ngIf="auditsEnabled">
                    <mat-slide-toggle formControlName="enable_audit_run">
                      Audit run
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
      <div style="overflow: hidden; margin-top: 10px;" class="app-content-list app-content-list-no-pager first-column" *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER">
          <dx-data-grid #svcOrderServiceGrid
                        [showColumnLines]="false"
                        [height]="'230px'"
                        [showRowLines]="true"
                        [showBorders]="true"
                        [dataSource]="svcOrderServices"
                        [columns]="svcOrderServiceColumns"
                        (onSelectionChanged)="onSelectionChanged($event)"
                         class="first-column">

            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-paging [enabled]="false" visible="false"></dxo-paging>
            <dxo-sorting mode="none"></dxo-sorting>
            <div *dxTemplate="let d of 'svcOrderServiceCurrencyTemplate'">
              <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency.currency }}</div>
            </div>
            <div *dxTemplate="let d of 'dateTemplate'">
              <div caGridTooltip class="right">
                {{ d.value | caDate}}
              </div>
            </div>
          </dx-data-grid>
      </div>
      <div style="overflow: hidden; margin-top: 10px;" class="app-content-list app-content-list-no-pager first-column dx-nodata-absolute"
           *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT" >
        <dx-data-grid #rateScheduleServiceGrid
                      [showColumnLines]="false"
                      [height]="'230px'"
                      [showRowLines]="true"
                      [showBorders]="true"
                      [dataSource]="contractScheduleRates"
                      [columns]="rateScheduleServiceColumns"
                      (onSelectionChanged)="onSelectionRateChanged($event)"
                      class="first-column">

          <dxo-selection mode="multiple"></dxo-selection>
          <dxo-paging [enabled]="false" visible="false"></dxo-paging>
          <dxo-sorting mode="none"></dxo-sorting>
          <!--            <dxo-load-panel [enabled]="false"></dxo-load-panel>-->
          <div *dxTemplate="let d of 'scheduleRateCurrencyTemplate'">
            <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency.currency }}</div>
          </div>
        </dx-data-grid>
      </div>
    </div>
      <div [ngClass]="{'visible': !isFilterOpen}"   [class]="!tabGroup.isActive(2)? 'filter-width-no-margin':'filter-width'" *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT">
        <mat-sidenav-container style="height: 100%" class="app-content-list">
          <mat-sidenav style="height: 100%; right: -300px; width: 300px; visibility: unset !important;">
            <new-app-filter [query]="scheduleRateQuery"
                            (filterRequested)="filterData($event)"
                            inventoryRateFilterService
                            [filterService]="inventoryRateFilterService"
                            (clearFilterRequested)="clearFilter()">
            </new-app-filter>
          </mat-sidenav>
        </mat-sidenav-container>
      </div>
    </div>
    <div [ngClass]="{'hidden': !tabGroup.isActive(3)}" class="tabs-body fixed tabs-content-padding">
      <form [formGroup]="form" class="ca-form dialog-content-no-margin">
        <div class="flex-container" fxLayout>
          <div class="flex-item" fxFlex>
            <!-- Form Section 3: Configuration -->
            <div class="flex-container" fxLayout="row" fxLayout.sm="column">
              <div class="flex-item caGap" fxFlex="33%">
                <ca-lookup-model-picker [lookupModel]="INVENTORY_TOPOLOGY_LOOKUP_MODEL"
                                        caInput
                                        (onSelectionChange)="changedTopology($event)" [disabled]="isUpdate"
                                        name="topology_id" required="true" formControlName="topology_id"
                                        placeholder="Topology" [pickerForm]="form">
                </ca-lookup-model-picker>
                <mat-hint class="ca-select-hint"
                          *ngIf="form.hasError('required', ['topology_id']) && form.controls.topology_id.touched">
                  {{VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </div>
            </div>

            <div class="flex-container" fxLayout="row" fxLayout.sm="column">
              <div class="flex-item caGap caGapRight" fxFlex="33%">
                <ca-lookup-model-picker
                  caInput
                  [lookupModel]="INVENTORY_TECHNOLOGY_LOOKUP_MODEL"
                  [pickerForm]="form"
                  formControlName="technology_id"
                  placeholder="Svc Type / Technology"
                  [clearEnabled]="true"
                  [disabled]="inventory.technology_id"
                >
                </ca-lookup-model-picker>
              </div>
              <div class="flex-item caGap caGapRight" fxFlex="22%">
                <ca-bandwidth-picker
                  caInput
                  name="cir_bw_id"
                  formControlName="cir_bw_id"
                  placeholder="Service Bandwidth"
                  [pickerForm]="form"
                  [initialItem]="inventory?.circuit_bandwidth"
                  [searchEnabled]="true"
                  [clearEnabled]="true"
                >
                </ca-bandwidth-picker>
              </div>
              <div class="flex-item caGap caGapRight" fxFlex="22%">
                <ca-bandwidth-picker
                  caInput
                  name="bw_max_id"
                  formControlName="bw_max_id"
                  placeholder="Port Bandwidth"
                  [initialItem]="inventory?.bandwidth_max"
                  [pickerForm]="form"
                  [searchEnabled]="true"
                  [clearEnabled]="true"
                >
                </ca-bandwidth-picker>
              </div>
              <div class="flex-item caGap" fxFlex="23%">
                <ca-lookup-model-picker caInput [lookupModel]="INVENTORY_PROTECTED_LOOKUP_MODEL" formControlName="protected_id"
                                        placeholder="Protection" [pickerForm]="form" [clearEnabled]="true">
                </ca-lookup-model-picker>
              </div>
            </div>

            <div class="flex-container" fxLayout="row" fxLayout.sm="column">
              <div class="flex-item caGap caGapRight" fxFlex="33%">
                <ca-lookup-model-picker caInput [lookupModel]="INVENTORY_BW_MODEL_LOOKUP_MODEL" formControlName="bw_model_id"
                                        placeholder="Bandwidth Model" [pickerForm]="form" [clearEnabled]="true">
                </ca-lookup-model-picker>
              </div>
              <div class="flex-item caGap caGapRight" fxFlex="33%">
                <ca-lookup-model-picker caInput [lookupModel]="INVENTORY_CKT_TYPE_LOOKUP_MODEL" formControlName="ckt_type_id"
                                        placeholder="Service Type" [pickerForm]="form" [clearEnabled]="true">
                </ca-lookup-model-picker>
              </div>
              <div class="flex-item caGap" fxFlex="34%">
                <ca-lookup-model-picker caInput [lookupModel]="INVENTORY_HANDOFF_TYPE_LOOKUP_MODEL" [pickerForm]="form"
                                        formControlName="handoff_type_id" placeholder="Handoff Type"
                                        [clearEnabled]="true">
                </ca-lookup-model-picker>
              </div>
            </div>

            <div class="flex-container" fxLayout="row">
              <div class="flex-item caGap caGapRight" fxFlex="33%">
                <ca-lookup-model-picker caInput [lookupModel]="INVENTORY_CIRCUIT_USAGE_LOOKUP_MODEL" [pickerForm]="form"
                                        formControlName="ckt_usage_id" placeholder="Usage" [clearEnabled]="true">
                </ca-lookup-model-picker>
              </div>
              <div class="flex-item caGap caGapRight" fxFlex="33%">
                <mat-form-field caInput>
                  <input matInput type="text" placeholder="VLAN ID" formControlName="vlan_id">
                </mat-form-field>
              </div>
              <div class="flex-item caGap" fxFlex="34%">
                <ca-lookup-model-picker caInput [lookupModel]="INVENTORY_PRIM_FAILOVER_LOOKUP_MODEL" [pickerForm]="form"
                                        formControlName="prim_failover_id" placeholder="Primary/Failover"
                                        [clearEnabled]="true">
                </ca-lookup-model-picker>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div [ngClass]="{'hidden': !tabGroup.isActive(4)}" class="tabs-body tabs-content-padding" style="height: 85%;">

      <div class="ca-empty-state-container" *ngIf="!form.controls.topology_id.value">
        <p class="small-div-message">Please select Topology on Configuration tab</p>
      </div>

      <div *ngIf="form.controls.topology_id.value" fxFlex>
        <form [formGroup]="form" class="ca-form dialog-content-no-margin"
              *ngIf="form.controls.topology_id.value === INVENTORY_TOPOLOGY.POINT_TO_POINT || topology === INVENTORY_TOPOLOGY.POINT_TO_POINT">
          <div class="flex-container" fxLayout="row">
            <div class="flex-item caGap caGapRight" fxFlex>
              <ca-site-picker
                caInput
                [disabledSites]="disabledSites"
                [pickerForm]="form"
                [initialItem]="inventory?.siteA"
                formControlName="site_a_id"
                placeholder="Site A (Carrier Location)"
                [searchEnabled]="true"
                [clearEnabled]="true">
              </ca-site-picker>
            </div>
            <div class="flex-item caGap" fxFlex>
              <ca-site-picker
                caInput
                [disabledSites]="disabledSites"
                [pickerForm]="form"
                [initialItem]="inventory?.siteZ"
                formControlName="site_z_id"
                placeholder="Site Z (Customer Location)"
                [searchEnabled]="true"
                [clearEnabled]="true">
              </ca-site-picker>
            </div>
          </div>


          <!--Single Demarc -->
          <div class="flex-container" fxLayout="row" *ngIf="singleDemarcTmp">
            <!-- Site A Single Demarc -->
            <div class="flex-item caGap" fxFlex="">

              <div *ngIf="form.controls.site_a_id.value">
                <div class="flex-container-3" fxLayout>
                  <div class="flex-item caGap caGapRight" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_a_row_id" placeholder="Row">
                    </mat-form-field>
                  </div>
                  <div class="flex-item caGap caGapRight" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_a_rack_id" placeholder="Rack">
                    </mat-form-field>
                  </div>
                  <div class="flex-item caGap caGapRight" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_a_shelf_id" placeholder="Shelf">
                    </mat-form-field>
                  </div>
                </div>

                <div class="flex-container-3" fxLayout>
                  <div class="flex-item caGap caGapRight" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_a_cpe_id" placeholder="CPE">
                    </mat-form-field>
                  </div>
                  <div class="flex-item caGap caGapRight" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_a_card_id" placeholder="Card">
                    </mat-form-field>
                  </div>
                  <div class="flex-item caGap caGapRight" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_a_port_id" placeholder="Port">
                    </mat-form-field>
                  </div>

                </div>
                <div class="flex-container" fxLayout>
                  <div fxFlex="94px">
                    <mat-checkbox formControlName="site_a_extended">
                      Extended
                    </mat-checkbox>
                  </div>
                </div>


              </div>
              <div *ngIf="!form.controls.site_a_id.value" class="ca-empty-state-container" style="min-height: 200px;">
                <p class="card-message">
                  {{demarc_messages.get('DEMARC_NO_SITE')}}
                </p>
              </div>

            </div>
            <!-- /Site A Single Demarc -->

            <!-- Site Z Single Demarc -->
            <div class="flex-item" fxFlex="">
              <div *ngIf="form.controls.site_z_id.value">

                <div class="flex-container-3" fxLayout>
                  <div class="flex-item caGap caGapRight" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_z_row_id" placeholder="Row">
                    </mat-form-field>
                  </div>
                  <div class="flex-item caGap caGapRight" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_z_rack_id" placeholder="Rack">
                    </mat-form-field>
                  </div>
                  <div class="flex-item caGap" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_z_shelf_id" placeholder="Shelf">
                    </mat-form-field>
                  </div>
                </div>

                <div class="flex-container-3" fxLayout>
                  <div class="flex-item caGap caGapRight" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_z_cpe_id" placeholder="CPE">
                    </mat-form-field>
                  </div>
                  <div class="flex-item caGap caGapRight" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_z_card_id" placeholder="Card">
                    </mat-form-field>
                  </div>
                  <div class="flex-item caGap" fxFlex>
                    <mat-form-field caInput>
                      <input matInput type="text" formControlName="site_z_port_id" placeholder="Port">
                    </mat-form-field>
                  </div>
                </div>
                <div class="flex-container" fxLayout>
                  <div fxFlex="94px" class="caGap">
                    <mat-checkbox formControlName="site_z_extended">
                      Extended
                    </mat-checkbox>
                  </div>
                </div>


              </div>
              <div *ngIf="!form.controls.site_z_id.value" class="ca-empty-state-container" style="min-height: 200px;">
                <p class="card-message">
                  {{demarc_messages.get('DEMARC_NO_SITE')}}
                </p>
              </div>
            </div>
            <!-- /Site Z  Single Demarc -->

          </div>
          <div class="flex-container" fxLayout="row" *ngIf="singleDemarcTmp">
            <div class="flex-item caGap caGapRight" fxFlex *ngIf="form.controls.site_a_id.value">
              <mat-form-field caInput>
                <textarea matInput formControlName="site_a_notes" placeholder="Notes" fxFlex="100%"></textarea>
              </mat-form-field>
            </div>
            <div class="flex-item caGap caGapRight" fxFlex *ngIf="form.controls.site_z_id.value">
              <mat-form-field caInput>
                <textarea matInput formControlName="site_z_notes" placeholder="Notes" fxFlex="100%"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-container" fxLayout="row">
            <div class="flex-item align-left" fxFlex="50%">

              <div class="flex-container-3" fxLayout="row">
                <div class="flex-item caGap" fxFlex>
                  <ca-person-picker
                    caInput
                    formControlName="site_z_lcontact_id"
                    placeholder="Local Contact"
                    [pickerForm]="form"
                    [clearEnabled]="true"
                    [initialItem]="inventory?.site_z_lcontact"
                    type="{{ CONTACT_TYPE_ENUM.CUSTOMER }}"
                    pickerType="contact">
                  </ca-person-picker>
                </div>
              </div>

            </div>
          </div>
          <!-- Demarc -->
          <div class="flex-container" fxLayout="row" *ngIf="!singleDemarcTmp">
            <!-- Site A Demarc -->
            <div class="flex-item caGap" fxFlex>
              <ng-container *ngIf="tabGroup.isActive(4)">
                <ca-demarc-list style="width: 100%;" [demarcs]="siteADemarcs"
                                [title]="'Site A demarcation point(s)'"
                                [siteId]="form.controls.site_a_id?.value"
                                (addRequested)="onSiteADemarcAddRequested($event)"
                                (editRequested)="onSiteADemarcEditRequested($event)"
                                (deleteRequested)="onSiteADemarcDeleteRequested($event)"
                                *ngIf="!siteADemarcFormMode"></ca-demarc-list>

                <div *ngIf="form.controls.site_a_id">
                  <ca-demarc-form [siteId]="form.controls.site_a_id.value"
                                  [demarc]="selectedSiteADemarc"
                                  (saveRequested)="onSiteADemarcSaveRequested($event)"
                                  (formCancelled)="onSiteADemarcFormCancelled($event)"
                                  *ngIf="siteADemarcFormMode"></ca-demarc-form>
                </div>
              </ng-container>
            </div>
            <!-- /Site A Demarc -->

            <!-- Site Z Demarc -->
            <div class="flex-item" fxFlex>
              <ng-container *ngIf="tabGroup.isActive(4)">
                <ca-demarc-list style="width: 100%;" [demarcs]="siteZDemarcs" [title]="'Site Z demarcation point(s)'"
                                (addRequested)="onSiteZDemarcAddRequested($event)"
                                [siteId]="form.controls.site_z_id?.value"
                                (editRequested)="onSiteZDemarcEditRequested($event)"
                                (deleteRequested)="onSiteZDemarcDeleteRequested($event)"
                                *ngIf="!siteZDemarcFormMode"></ca-demarc-list>

                <div *ngIf="form.controls.site_z_id">
                  <ca-demarc-form [siteId]="form.controls.site_z_id.value"
                                  [demarc]="selectedSiteZDemarc"
                                  (saveRequested)="onSiteZDemarcSaveRequested($event)"
                                  (formCancelled)="onSiteZDemarcFormCancelled($event)"
                                  *ngIf="siteZDemarcFormMode"></ca-demarc-form>
                </div>
              </ng-container>
            </div>
            <!-- /Site Z Demarc -->

          </div>

        </form>
      </div>


    </div>

    <!-- Manage related circuits tab -->
    <div [ngClass]="{'hidden': !tabGroup.isActive(5)}" class="tabs-body fixed tabs-content-padding">

      <div class="flex-container related-circuits-container" fxLayout="column">

        <div class="related-inventory-picker-container ca-form caGap" fxFlex="auto" fxLayout
             fxLayoutAlign="flex-start">
          <div fxFlex="240px" class="caGap">
            <ca-inventory-picker
              caInput
              [pickerForm]="form"
              #relatedCircuitPicker
              [placeholder]="'Service'"
              [customerID]="form.controls?.customer_id?.value"
              [inventoryId]="inventory.id">
            </ca-inventory-picker>
          </div>
          <div fxFlex="240px" class="caGap caGapLeft caGapRight">
            <ca-inventory-relationship-type-picker
              caInput
              [pickerForm]="form"
              #relationshipTypePicker
              [placeholder]="'Type'">
            </ca-inventory-relationship-type-picker>
          </div>

          <div fxFlex class="add-circuit-button caGap" fxLayoutAlign="start center">
            <button mat-icon-button [disabled]="!relatedCircuitPicker?.caPicker?.value || !relationshipTypePicker?.caPicker?.value"
                    (click)="addRelatedCircuit(relatedCircuitPicker.caPicker.value, relationshipTypePicker.caPicker.value, relatedCircuitPicker.entries, relationshipTypePicker.entries)">
              <ca-icon [key]="'CIRCUIT_ADD'" [tooltip]="'Add Service'"></ca-icon>
            </button>
          </div>

        </div>

        <div fxFlex>
          <div class="circuits-selected-container ca-default-border">
            <ng-container *ngFor="let selectedCircuit of selectedCircuits">
              <div class="related-circuit-item ca-default-border-bottom" fxLayout fxLayoutAlign="flex-start center">
                <div class="related-circuit-item__text" fxFlex> {{ selectedCircuit ? selectedCircuit.sp_ckt_id : '' }} • {{ selectedCircuit ? selectedCircuit.relationshipType : '' }}</div>
                <div fxFlex=40px>
                  <button mat-icon-button (click)="deleteRelatedCircuit(selectedCircuit?.id)">
                    <ca-icon [key]="'CIRCUIT_DELETE'"></ca-icon>
                  </button>
                </div>
              </div>
            </ng-container>

          </div>
        </div>


      </div>

    </div>
  </div>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
    <!--
    <div *ngIf="isUpdate">
      <ca-confirm-delete-button *permission="{'action':'Delete', 'key':'inventory'}" #genericButton [confirmText]="'Delete?'" [customMessage]="customMessage"
                                [disabled]="siteADemarcFormMode || siteZDemarcFormMode"
                                (deleteRequested)="deleteInventory($event)"></ca-confirm-delete-button>
    </div>
    -->
  </div>

  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>

  <button mat-button #saveButton type="submit" (click)="onSubmit(form)" [disabled]="form?.invalid  || this.isFormDisabled">
    Save
  </button>
</mat-dialog-actions>
