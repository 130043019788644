import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';

import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";
import {
  UpdateInventoryContractTermsActiveSiderSection,
} from "./inventory-contract-terms.actions";

const INVENTORY_CONTRACT_TERMS_STATE_TOKEN = new StateToken<InventoryContractTermsStateModel>('inventory_contract_terms');

export interface InventoryContractTermsStateModel extends QueryableStateModel<any> {

}

@State<InventoryContractTermsStateModel>({
  name: INVENTORY_CONTRACT_TERMS_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InventoryContractTermsState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  // @ts-ignore
  @Action(UpdateInventoryContractTermsActiveSiderSection)
  updateInventoryContractTermsActiveSiderSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
