import {Subdivision} from "../../location/core/subdivision";
import {Country} from "../../location/core/country";
export class Address {
  id: number;
  address1: string;
  city: string;
  subdivision_id: number;
  postal_code: string;
  country_id: number;
  longitude: number;
  latitude: number;
  is_validated: boolean;
  last_updated_date: Date;
  smarty_streets_active: string;

  subdivision: Subdivision;
  country: Country;

  stateAbbreviation: string;
  internal: boolean;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
