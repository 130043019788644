<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Tags</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button mat-icon-button (click)="newTag()">
    <ca-icon [key]="'ADD_NEW'" [tooltip]="'New Tag'"></ca-icon>
  </button>
  <button mat-icon-button (click)="editTag(selection)" [disabled]="selectedRows.length != 1">
    <ca-icon [key]="'EDIT'" [tooltip]="'Edit Tag'"></ca-icon>
  </button>
  <button mat-icon-button (click)="refresh()" matTooltip="Refresh List">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button (click)="mergeTags(selectedRows)" [disabled]="selectedRows.length < 2">
    <ca-icon [key]="'MERGE_TAGS'" [tooltip]="'Merge Tags'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button matTooltip="Filter" [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"  (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list">
  <dx-data-grid #grid class="grid-with-selection-badge" (onRowClick)="onRowClick($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onCellClick)="onCellClick($event)" [columns]="columns" [height]="'100%'" [width]="'100%'"
                [dataSource]="tags">
    <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
      <a class="selection-badge grid-badge"
         matTooltip="Selection"
         (click)="loadSelected()"
         [ngClass]="{'active':selectionActive}">
        {{selectedRows.length}}
      </a>
    </div>
    <div *dxTemplate="let d of 'selectionBadgeCellTemplate'">
    </div>
    <div *dxTemplate="let d of 'usageTemplate'">
      <div caGridTooltip class="right">{{ (d.data.entities && d.data.entities.length) ? d.data.entities.length : 0 }}</div>
    </div>
  </dx-data-grid>
  <ca-pager  [gridPager]="defaultGridPager" [pageContext]="tagAdministrationGridService"  [query]="query" class="app-pager ca-default-border-top" (onChange)="loadData(query)"></ca-pager>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">

    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                [query]="query"
                [filterService]="tagAdministrationFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter()">
    </new-app-filter>
  </mat-sidenav>

</mat-sidenav-container>
