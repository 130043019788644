import { Injectable } from '@angular/core';
import { GridService } from "../../shared/grid/grid.service";
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class BANCodingGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.gl.gl-ban-coding-grid", userSettings);
  }

  defaultColumns = [
      {
      caption: "BAN",
      dataField: "account_no",
      cellTemplate: 'banCellTemplate'
    },
    {
      caption: "Method",
      dataField: "coding_method",
      cellTemplate: 'methodCellTemplate',
      width: '120'
    },
    {
      alignment: 'left',
      caption: "Status",
      dataField: "gl_coding_enabled",
      cellTemplate: 'statusCellTemplate',
      width: '120'
    }
   ];

  /*columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
