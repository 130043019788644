import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {ThemeSchemaService} from '../../core/theme.scheme';
import {ChartService} from '../../../shared/chart/chart.service';
import {UserSettingsService} from '../../../user/core/user-settings.service';
import {ThemeService} from '../../core/theme.service';
import {CustomThemeService} from '../../core/custom-theme.service';
import Query from '../../../core/query/query';
import {ChartReloadService} from "../../core/chart-reload.service";
import {AlertService} from "../../../shared/alert/alert.service";
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-theme-manage',
  templateUrl: './theme-manage.component.html',
  styleUrls: ['./theme-manage.component.css'],
  host: {
    '(document:click)': 'checkColorPickerVisibility($event)'
  }
})
export class ThemeManageComponent implements OnInit {

  readonly CUSTOM_THEME_NAME = 'Custom Theme';
  readonly hexColorValPattern: string = "#+[0-9a-fA-F]{6}";

  readonly INPUTS = {
    THEME_PRIMARY_COLOR: 'theme.primary.color',
    THEME_PRIMARY_CONTRAST: 'theme.primary.contrast',
    THEME_BACKGROUND_COLOR: 'theme.background.color',
    THEME_BACKGROUND_CONTRAST: 'theme.background.contrast',
    THEME_PRIMARY_DARK_COLOR: 'theme.primaryDark.color',
    THEME_PRIMARY_DARK_CONTRAST: 'theme.primaryDark.contrast',
    THEME_PRIMARY_LIGHT_COLOR: 'theme.primaryLight.color',
    THEME_PRIMARY_LIGHT_CONTRAST: 'theme.primaryLight.contrast',
    THEME_WARNING_COLOR: 'theme.warning.color',
    THEME_WARNING_CONTRAST: 'theme.warning.contrast',
    THEME_DANGER_COLOR: 'theme.danger.color',
    THEME_DANGER_CONTRAST: 'theme.danger.contrast',
    THEME_ACCENT_COLOR: 'theme.accent.color',
    THEME_ACCENT_CONTRAST: 'theme.accent.contrast',
    THEME_FOREGROUND_COLOR: 'theme.foreground.color',
    THEME_FOREGROUND_CONTRAST: 'theme.foreground.contrast',
    THEME_SUCCESS_COLOR: 'theme.success.color',
    THEME_SUCCESS_CONTRAST: 'theme.success.contrast',
    THEME_DISABLED_COLOR: 'theme.disabled.color',
    CHART_ONE: 'chart.one',
    CHART_TWO: 'chart.two',
    CHART_THREE: 'chart.three',
    CHART_FOUR: 'chart.four',
    CHART_FIVE: 'chart.five',
    CHART_SIX: 'chart.six',
    CHART_SEVEN: 'chart.seven',
    CHART_EIGHT: 'chart.eight',
    CHART_BACKGROUND:'chart.background',
    CHART_INNER_FONT: 'chart.inner_font',
    CHART_COLOR_FONT: 'chart.font_color',
    CHART_TOOLTIP_COLOR_FONT: 'chart.tooltip_font_color',
    CHART_TOOLTIP_BACKGROUND: 'chart.tooltip_background'
  };

  themeCount: number = 0;

  selectedInput: string = null;

  selectedTheme: {
    settings: null,
    name: null
  };
  public themes = [];
  public customTheme;
  public chartPelette;
  public chartColors: any = {};
  public chartPelettes = [];
  public shareColors;
  public shareChartColors;
  originalSelectedTheme;

  cancelDisabled: boolean = false;
  tabIndex: number = 0;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "theme";

  constructor(public dialogRef: MatDialogRef<ThemeManageComponent>,
              public chartService: ChartService,
              public schemaService: ThemeSchemaService,
              public themeService: ThemeService,
              public chartReloadService: ChartReloadService,
              public userSettingsService: UserSettingsService,
              public customThemeService: CustomThemeService,
              public alertService: AlertService,) {
                this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  checkColorPickerVisibility(event) {

    const colorPickerVisible = event.target.classList.contains('color-picker-trigger') ||
      event.target.classList.contains('saturation-lightness') ||
      event.target.classList.contains('hue') ||
      event.target.classList.contains('box') ||
      event.target.classList.contains('right') ||
      event.target.classList.contains('left') ||
      event.target.classList.contains('cursor');

    if (!colorPickerVisible) {
      this.selectInput(null, false);
    }
  }

  selectInput(inputName: string, isDisabled: boolean) {
    if (isDisabled) {
      return;
    }

    if (inputName === this.selectedInput) {
      return this.selectedInput = null;
    }

    this.selectedInput = inputName;
  }

  displayColorPicker(inputName: string, isDisabled: boolean) {
    return !isDisabled && inputName === this.selectedInput;
  }

  public originalShit = {};

  ngOnInit() {

    const themeQuery = new Query({
      orderBy: [['id', 'ASC']],
    });

    this.themes = ThemeService.appThemes;
    this.themeCount = this.themes.length;

    forkJoin([
      this.schemaService.customTheme(),
      this.themeService.selectedTheme(),
      this.chartService.getSelectedPalette(),
      this.chartService.customPalette()])
      .subscribe(([
        customTheme,
        selectedTheme,
        selectedPalette,
        customPalette
    ]) => {

        let selectedThemeName = selectedTheme.name;

        if (selectedThemeName) {
          if (selectedThemeName !== this.CUSTOM_THEME_NAME) {
            this.selectedTheme = this.themes.filter(item => item.name === selectedThemeName)[0];
          }
          else if (selectedThemeName === this.CUSTOM_THEME_NAME) {
            this.selectedTheme = customTheme;
          }
        }
        else {
          this.selectedTheme = this.themes.filter(item => item.name === 'Default')[0];
        }

        /*  */

        this.themes = this.themes.map(item => {
          if (item.name === this.CUSTOM_THEME_NAME) {

            if (customTheme && Object.keys(customTheme).length) {
              item = Object.assign(
                {},
                customTheme,
                { name: this.CUSTOM_THEME_NAME }
              );
            }

          }

          return item;
        });

        this.setCustomTheme(this.selectedTheme);

        this.originalSelectedTheme = this.selectedTheme;

        // Chart color logic
        this.chartPelettes = JSON.parse(JSON.stringify(ChartService.chartThemes));

        let selPalette = selectedPalette;
        let storedPalette = customPalette;

        if (selPalette.name) {

          if (selPalette.name !== this.CUSTOM_THEME_NAME) {
            this.chartPelette = JSON.parse(JSON.stringify(this.chartPelettes.filter(item => item.name === selPalette.name)[0]));

          } else {
            this.chartPelette = JSON.parse(JSON.stringify(storedPalette));
          }

        }
        else {
          this.chartPelette = JSON.parse(JSON.stringify(this.chartPelettes.filter(item => item.name === this.chartService.THEME_NAMES.DEFAULT)[0]));
        }

        if (storedPalette.name) {
          let tempPalette = storedPalette;

          this.chartPelettes[this.chartPelettes.length - 1] = Object.assign({}, tempPalette, {name: this.CUSTOM_THEME_NAME});
        }
        else {
          this.chartPelettes = this.chartPelettes.map((item, index, originalArray) => {

            if ( (index - 1) === originalArray.length ) {
              item = Object.assign({}, this.chartPelettes[0], {name: this.CUSTOM_THEME_NAME});
            }

            return item;
          });
        }

        setTimeout(() => {
          this.selectPalette(this.chartPelette);
        }, 1000);

        this.setColors(this.selectedTheme.settings);
        this.setChartColors(this.chartPelette.settings);
      })
  }

  selectTheme(theme) {
    this.selectedTheme = theme;
    this.setCustomTheme(theme);
    this.setColors(theme.settings);
  }

  setColors(settings) {
    let colors = [];

    for (let obj in settings) {
      if (settings.hasOwnProperty(obj)) {
        for (let color in settings[obj]) {
          if (settings[obj].hasOwnProperty(color)) {
            let re = new RegExp(this.hexColorValPattern);
            if (re.test(settings[obj][color])) {
              colors.push(settings[obj][color]);
            }
          }
        }
      }
    }
    this.shareColors = colors.join(',');
  }

  setColorsOnChange(colorInput) {
    let colors = colorInput.split(',');
    if (colors.length === 19) {
      let i = 0;
      for (let obj in this.customTheme.settings) {
        for (let color in this.customTheme.settings[obj]) {
          let re = new RegExp(this.hexColorValPattern);
          if (re.test(colors[i])) {
            this.customTheme.settings[obj][color] = colors[i];
            i = i+1;
          }
        }
      }
    }

  }

  setChartColors (settings) {
    let colors = [];
    for (let color in settings) {
      if (settings.hasOwnProperty(color)) {
        let re = new RegExp(this.hexColorValPattern);
        if (re.test(settings[color])) {
          colors.push(settings[color]);
        }
      }
    }
    this.shareChartColors = colors.join(',');
  }

  setChartColorsOnChange(colorInput) {
    let colors = colorInput.split(',');
    let i = 0;
    for (let color in this.chartPelette.settings) {
      let re = new RegExp(this.hexColorValPattern);
      if (re.test(colors[i])) {
        this.chartPelette.settings[color] = colors[i];
        i = i+1;
      }
    }
  }

  setCustomTheme(theme) {
    this.customTheme = JSON.parse(JSON.stringify(theme));
    // fix for buggy color picker
    setTimeout(() => {
      this.customTheme = JSON.parse(JSON.stringify(theme));
    }, 100);
  }

  selectPalette(palette) {
    this.chartPelette = JSON.parse(JSON.stringify(palette));
    this.chartColors = palette.settings;
    this.setChartColors(palette.settings);
  }

  apply(form?) {
    this.cancelDisabled = true;



    if(!this.tabIndex) {
      this.customThemeService.generateCustomTheme(this.customTheme);
      let themeTemp = Object.assign({}, this.customTheme);
      this.themeService.saveSettings(themeTemp)
        .subscribe(() => {
          this.cancelDisabled = false;
          this.alertService.success("", this.messages.get('UPDATE_SUCCESS'));
        })
    } else {
      this.chartPelette.settings = {...this.chartPelette.settings, ...this.chartColors};
      this.chartService.getChartColorsAsArray(this.chartPelette);
      this.chartService.saveSettings(this.chartPelette)
        .subscribe(() => {
          this.cancelDisabled = false;
          this.alertService.success("", this.messages.get('UPDATE_SUCCESS'));
        })
    }

  }

  close() {
    this.customTheme = null;
    this.setCustomTheme(null);
    this.dialogRef.close();
  }

  /* Transform to 6 char color hex value */
  onThemeColorChange(value, type, color) {
    if (value.length === 4) {
      value = `#${value[1]}${value[1]}${value[2]}${value[2]}${value[3]}${value[3]}`;
      setTimeout(() => {
        this.customTheme.settings[type][color] = value;
      }, 50);
    }
  }

  onChartColorChange(value, color) {
    if (value.length === 4) {
      value = `#${value[1]}${value[1]}${value[2]}${value[2]}${value[3]}${value[3]}`;
      setTimeout(() => {
        this.chartColors[color] = value;
      }, 50);
    }
  }

  onSelectedIndexChange(event) {
    this.tabIndex = event;
  }
}
