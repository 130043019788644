export class Sider {
  private settings: SiderSettings;

  constructor(settings: SiderSettings) {
    this.settings = settings;
  }

  private emitChange() {
    this.settings.emitChange();
  };

  onChange(fn) {
    this.settings.onChange(fn);
    return this;
  }

  isAnyActive() {
    return !this.settings.isNoneSectionActive();
  }

  isActive(sectionName: string): boolean {
    return this.settings.isActive(sectionName);
  }

  findSection(sectionName: string): SiderSection {
    return this.settings.findSection(sectionName)
  }

  toggle(sectionName) {
    return this.settings.toggle(sectionName);
  }

  open(sectionName) {
    return this.settings.open(sectionName);
  }

  close() {
    return this.settings.close();
  }

  closeComponent() {
    if (this.settings.component) {
      return this.settings.component.close();
    }
  }

  addSection(section: SiderSection): SiderSection {
    return this.settings.addSection(section);
  }

  getActiveSection() {
    return this.settings.getActiveSection();
  }

  setActiveSection(activeSection: SiderSection) {
    this.settings.activeSection = activeSection;
  }

}

export class SiderSettings {
  noneSection: SiderSection;
  activeSection: SiderSection;
  private watchers = [];

  private NONE_SECTION_NAME: string = "none";

  constructor(public sections: Array<SiderSection> = [], public component: any) {
    // Adding default "none" state
    this.noneSection = this.addSection(new SiderSection(this.NONE_SECTION_NAME));
    // Set default active section to none
    this.activeSection = this.noneSection;
  }

  emitChange() {
    this.watchers.forEach((watcher) => {
      watcher(this.getActiveSection());
    })
  };

  onChange(fn) {
    this.watchers.push(fn);
  }

  getActiveSection() {
    return this.activeSection;
  }

  isActive(sectionName: string): boolean {
    return this.activeSection && this.activeSection.name === sectionName;
  }

  open(sectionName: string) {
    let section = this.findSection(sectionName);
    if (!section) {
      section = this.noneSection;
      //TODO: throw new Error(`Cannot find section with name ${sectionName}`);
    }
    this.activeSection = section;

    this.emitChange();

    if (this.component) {
      this.component.open();
    }
  }

  close() {
    this.activeSection = this.noneSection;
    this.emitChange();
    if (this.component) {
      this.component.close();
    }
  }

  toggle(sectionName: string): SiderSection {

    let section = this.findSection(sectionName);

    if (!section) {
      section = this.noneSection;
      //TODO: throw new Error(`Cannot find section with name ${sectionName}`);
    }

    if (this.isNonSection(section)) {
      if (this.component) {
        this.component.close();
      }
      this.activeSection = this.noneSection;
      this.emitChange();
      return this.activeSection;
    }

    if (this.isNoneSectionActive()) {
      if (this.component) {
        this.component.open();
      }
      this.activeSection = section;
      this.emitChange();
      return this.activeSection;
    }

    if (this.activeSection.name === sectionName) {
      if (this.component) {
        this.component.close();
      }
      this.activeSection = this.noneSection;
      this.emitChange();
      return this.activeSection;
    }

    if (this.component) {
      this.component.open();
    }

    this.activeSection = section;
    this.emitChange();
    return this.activeSection;
  }

  isNonSection(section) {
    return this.noneSection.name === section.name;
  }

  isNoneSectionActive() {
    return this.activeSection.name === this.noneSection.name;
  }

  addSection(section: SiderSection): SiderSection {

    if (!section) {
      throw new Error(`Section cannot be a null`);
    }

    let existing = this.findSection(section.name);
    if (existing) {
      throw new Error(`Sections already contains section with name ${section.name}`);
    }

    this.sections.push(section);
    return section;
  }

  findSection(sectionName: string): SiderSection {
    return this.sections.filter((section) => {
      return section.name === sectionName;
    })[0];
  }

}

export class SiderSection {

  constructor(public name: string) {

  }

}
