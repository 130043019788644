import {Injectable} from '@angular/core';

@Injectable()
export class SortingService {

  constructor() {
  }

  builder(): SortingBuilder {
    return new SortingBuilder();
  }

}

export class SortingBuilder {

  currentSortIndex = -1;
  originalCaption;


  ARROW_UP = '2191';
  ARROW_DOWN = '2193'

  getOriginalCaption(component, columnIndex) {
    const caption = component.columnOption(columnIndex, 'caption') ;
    return ( columnIndex > -1 && caption) ? caption
    .replace(/\u{2191}/u, '')
    .replace(/\u{2193}/u, '') : '';
  }

  private clearSortArrow(event) {
    if (this.currentSortIndex > -1) {
      const originalCaption = this.getOriginalCaption(event.component, this.currentSortIndex);
      event.component.columnOption(this.currentSortIndex, 'caption', originalCaption);
    }
  }

  buildSorting(event, interceptor?) {
    this.clearSortArrow(event);

    const originalCaption = event.component.originalCaption || this.getOriginalCaption(event.component, event.column.index);
    const sameColumn = this.currentSortIndex === event.column.index;
    this.currentSortIndex = event.column.index;
    event.component.isSortAsc = sameColumn ? !event.component.isSortAsc : true;
    let isSortAsc = sameColumn ? event.component.isSortAsc : true;

    if (event.column.dataType === 'boolean') {
      isSortAsc = !isSortAsc;
    }

    event.component.columnOption(
      event.column.index,
      'caption',
      event.column.dataType === 'boolean' ? (isSortAsc ? originalCaption + ' \u2193' : originalCaption + ' \u2191') : (isSortAsc ? originalCaption + ' \u2191' : originalCaption + ' \u2193')
    );

    let field = event.column.dataField;
    if (interceptor) {
      field = interceptor.transform(field);
    }

    return [[field, isSortAsc ? 'ASC' : 'DESC']];
  }

  apply(event, q, interceptor?) {
    let sorting = this.buildSorting(event, interceptor);
    q = q || {};
    q['orderBy'] = sorting;
    return q;
  }

}
