export const inventoryCodingFilterContext = {
  filters: [
    {
      field: 'account_no',
      label: 'BAN',
      type: 'string',
      index: 1
    }
  ]
};
