import {of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';
import {DxDataGridComponent} from 'devextreme-angular';

import {GLStringQuery} from '../../gl-string/core/gl-string.query';
import {LoaderService} from '../..//shared/loader/loader.service';
import {GLStringService} from '../../gl-string/core/gl-string.service';
import {GLString} from '../../gl-string/core/gl-string';
import {GLChargeDetailViewService} from './gl-charge-detail-view.service';
import Query from '../../core/query/query';
import {SortingBuilder, SortingService} from '../../shared/sorting/sorting.service';
import {ChargeQuery} from '../../charge/core/charge.query';
import {GLRuleExecutionCsvInterceptor} from './gl-rule-execution-csv-interceptor';
import {GlRuleExecutionChargesGridService} from './gl-rule-execution-charges-grid.service';
import {GlRuleExecutionChargesFlatGridService} from './gl-rule-execution-charges-flat-grid.service';
import {GridService} from 'app/shared/grid/grid.service';
import 'rxjs/add/operator/do';
import {catchError, map} from "rxjs/operators";


@Injectable()
export class GLService {
  private chargesSorting: SortingBuilder;

  constructor(
    private loaderService: LoaderService,
    private glStringService: GLStringService,
    private sortingService: SortingService,
    private glDetailViewService: GLChargeDetailViewService,
    private glRuleExecutionChargesGridService: GlRuleExecutionChargesGridService,
    private glRuleExecutionChargesFlatGridService: GlRuleExecutionChargesFlatGridService
  ) {
    this.chargesSorting = this.sortingService.builder();
  }

  getChargeDetailsColumns(masterDetailsEnabled) {
    return this.gridService(masterDetailsEnabled).columns();
  }

  loadSortedGlStrings(glStringQuery){
    return this.glStringService.findAllWithApportions(glStringQuery)
  }

  loadGLStrings(grid: DxDataGridComponent, query?: GLStringQuery) {
    // if (query && query.where) {
    //   if (query.where['gl_code_type'] && query.where['gl_code_type']['$in']) {
    //     query.where['gl_code_type']['$in'].forEach((val, i) => {
    //       if (val === 'true') {
    //         query.where['gl_code_type']['$in'][i] = 'Verified';
    //       } else {
    //         query.where['gl_code_type']['$in'][i] = 'Non-verified';
    //       }
    //     });
    //   }
    // }

    this.loaderService.displayLoaderAndManageGrid([grid]);

    try {
      return this.glStringService
        .findAllWithCharges(query)
        .do((result: { items: GLString[]; total: number }) => {
          const {total = 0} = result;
          query.total = total;
          this.loaderService.hideLoaderAndManageGrid([grid]);
        })
        .pipe(map((result: any) => ({
            items: result.items,
            query
          })));
    } catch (err) {
      this.loaderService.hideLoaderAndManageGrid([grid]);
    }
  }

  clearStringFilter(
    query: GLStringQuery,
    masterDetailsEnabled = false,
    grid: DxDataGridComponent
  ) {
    delete query.where['full_string_formatted'];
    delete query.where['gl_code_type'];
    delete query.where['date_added'];
    delete query.where['status'];
    delete query.where['full_name'];
    query['flat_data'] = !masterDetailsEnabled;
    return this.loadGLStrings(grid, query);
  }

  gridService(isGroupedView): GridService {
    return isGroupedView ?
      this.glRuleExecutionChargesGridService : this.glRuleExecutionChargesFlatGridService;
  }

  exportChargesToCsv(query, options) {

    this.gridService(options.masterDetailsEnabled).csvMap().subscribe(fields => {
      const glRuleExecutionTransformMiddleware = items => {
        const glRuleExecutionCsvInterceptor = new GLRuleExecutionCsvInterceptor();
        return items.map(item => glRuleExecutionCsvInterceptor.transform(item));
      };

      return this.glDetailViewService.exportToCSV('charges', {
        query,
        middleware: [glRuleExecutionTransformMiddleware],
        fields: fields,
        fetchDataHandler: (csvQuery) => {
          return this.loadChargesBase(csvQuery, options);
        }
      });
    })
  }

  private loadChargesBase(chargeQuery: ChargeQuery | Query,
                          {masterDetailsEnabled, account_id, vendorId, invoice}) {
    chargeQuery['flat_data'] = !masterDetailsEnabled;

    if (account_id) {
    } else {
      delete chargeQuery.where.account_id;
    }

    if (vendorId) {
      // TODO: why we are not handling this case ???
    } else {
      delete chargeQuery.where.vendor_id;
    }

    // @ts-ignore
    chargeQuery.most_recent = true;
    // @ts-ignore
    chargeQuery.include_gl = true;

    if (invoice) {
      chargeQuery.where.invoice_id = invoice;
    } else {
      delete chargeQuery.where.invoice_id;
    }

    const isFlat = !masterDetailsEnabled;
    return isFlat
      ? this.glDetailViewService.flat(chargeQuery)
      : this.glDetailViewService.grouping(chargeQuery);
  }

  loadCharges(
    chargeQuery: ChargeQuery | Query,
    {masterDetailsEnabled, account_id, vendorId, invoice, grid}
  ) {
    this.loaderService.displayLoaderAndManageGrid([grid]);
    try {
      return this.loadChargesBase(chargeQuery, {
        masterDetailsEnabled,
        account_id,
        vendorId,
        invoice
      })
        .do(charges => {
          chargeQuery.total = charges.total;
          this.loaderService.hideLoaderAndManageGrid([grid]);
        })
        .pipe(map((result: any) => ({
          query: chargeQuery,
          items: result.items
        })))
    } catch (error) {
      this.loaderService.hideLoaderAndManageGrid([grid]);
    }
  }

  async rowExpanded(row, charges) {
    const {key} = row;
    const query = new Query();
    query.set('charge_id', key);
    const charge = charges.find(chg => chg.charge_id === key);
    if (charge && !charge.gl_output) {
      if (!key) {
        const {invoice_id} = charge;
        query.set('invoice_id', invoice_id);
      }
      const result = await this.glDetailViewService
        .getString(query)
        .toPromise();
      charges.forEach(chg => {
        if (chg.charge_id === key) {
          chg.gl_output = result;
        }
      });
    }
  }

  public handleChargesCellClick(
    event,
    chargeQuery,
    {masterDetailsEnabled, account_id, vendorId, invoice, grid}
  ) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.chargesSorting.apply(event, chargeQuery);
      return this.loadCharges(chargeQuery, {
        masterDetailsEnabled,
        account_id,
        vendorId,
        invoice,
        grid
      });
    }
    return observableOf(null);
  }

  isFilterOpened(sider, sections) {
    return sider.isActive(sections.FILTER_SECTION_NAME);
  }

  activeFilter(
    masterDetailsEnabled,
    sider,
    sections,
    {isChargeDetailsTab, isStringsTab}
  ) {
    if (!this.isFilterOpened(sider, sections)) {
      return null;
    }

    if (masterDetailsEnabled && isChargeDetailsTab) {
      return 'groupedChargeDetails';
    }

    if (!masterDetailsEnabled && isChargeDetailsTab) {
      return 'flatChargeDetails';
    }

    if (isStringsTab) {
      return 'strings';
    }
  }
}
