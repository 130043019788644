<app-inventory-details-toolbar
  [inventory]="inventory"
  [isAddPricingHidden]="true"
  [isAddPricingDisabled]="true"
  [isCreateOrderHidden]="false"
  [isCreateOrderDisabled]="true"
  [isAddGLHidden]="false"
  [isAddGLDisabled]="true"
  [isEditInventoryDisabled]="true"
  [isDocumentAction]="false"
  [isPricingDocumentAction]="false"
  [isFilterDisabled]="true"
  [isNotesOn]="($activeSiderSection | async) === SECTIONS.NOTES_SECTION_NAME"
  [isHistoryOn]="($activeSiderSection | async) === SECTIONS.HISTORY_SECTION_NAME"
  [notesCount]="notesCount"

  (toggleNotesRequested)="toggleNotes()"
  (toggleHistoryRequested)="toggleHistory()"
  (backRequested)="back()"
>
</app-inventory-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-inventory-details-tabs [activeTabIndex]="1"></app-inventory-details-tabs>
    <div *ngIf="inventory?.topology_id === INVENTORY_TOPOLOGY.POINT_TO_POINT">
        <div class="flex-container ca-card custom-mat-card-container" fxLayout="row">
           <!--SITE A SECTION-->
          <mat-card fxFlex caCard>
            <mat-card-header>
              Site A (Carrier Location)
            </mat-card-header>
            <mat-card-content>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Site</label>
                  <p>
                    {{ inventory?.siteA?.site_id || 'N/A'}}
                  </p>
                </div>
              </div>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Vendor</label>
                  <p>
                    {{ inventory?.siteA?.vendor?.name || 'N/A'}}
                  </p>
                </div>
                <div fxFlex class="entry-list-item">
                  <label>Site type</label>
                  <p>
                    {{ inventory?.siteA?.type?.value || 'N/A'}}
                  </p>
                </div>
              </div>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Room</label>
                  <p>
                    {{ inventory?.siteA?.room || 'N/A'}}
                  </p>
                </div>
                <div fxFlex class="entry-list-item">
                  <label>Floor</label>
                  <p>
                    {{ inventory?.siteA?.floor || 'N/A'}}
                  </p>
                </div>
              </div>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item" fxFlex="50%" *ngIf="inventory?.siteA?.building">
                  <label>Building</label>
                  <div>
                    <p>
                      {{ inventory?.siteA?.building?.name}}
                    </p>
                    <p *ngIf="inventory?.siteA?.building?.address?.address1">
                      {{ inventory?.siteA?.building?.address?.address1}}
                    </p>
                    <p>
                      {{ inventory?.siteA?.building?.address?.city}}&nbsp; {{
                      inventory?.siteA?.building?.address?.subdivision?.name}}&nbsp;
                      {{ inventory?.siteA?.building?.address?.postal_code}}
                    </p>
                    <p *ngIf="inventory?.siteA?.building?.address?.country">
                      {{ inventory?.siteA?.building?.address?.country?.name}}
                    </p>
                  </div>
                </div>
                <div fxLayout="row" class="entry-list" fxFlex="50%">
<!--                  <div fxFlex="27%" class="entry-list-item" *ngIf="!level1 && !level2 && inventory?.network_hub_map?.network_hub?.hub_name">-->
<!--                    <label>{{CAPACITY_TYPES[inventory?.network_hub_map?.network_hub?.hub_capacity_id]}}</label>-->
<!--                    <div>-->
<!--                      <p>Host circuit</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div fxFlex="27%" class="entry-list-item" *ngIf="level1">-->
<!--                    <label>{{level1.name || 'E-NNI'}}</label>-->
<!--                    <div fxLayout fxLayoutAlign="flex-start center">-->
<!--                      <span class="ca-field-with-icon" *ngIf="level1.order">-->
<!--                        Slot {{level1.order}}-->
<!--                      </span>-->
<!--                      <p *ngIf="!level1.order">-->
<!--                        No order-->
<!--                      </p>-->
<!--&lt;!&ndash;                      <ca-icon&ndash;&gt;-->
<!--&lt;!&ndash;                        [key]="'NAVIGATE_TO_LINK'"&ndash;&gt;-->
<!--&lt;!&ndash;                        [innerClass]="'ca-link-icon'"&ndash;&gt;-->
<!--&lt;!&ndash;                        (click)="navigateToRelatedNetworkHub(inventory.network_hub_map.network_hub_id, level1.id)"&ndash;&gt;-->
<!--&lt;!&ndash;                        [tooltip]="'Navigate to circuit'">&ndash;&gt;-->
<!--&lt;!&ndash;                      </ca-icon>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div fxFlex="27%" class="entry-list-item" *ngIf="level2">-->
<!--                    <label>{{level2?.name || 'N/A'}}</label>-->
<!--                    <div fxLayout fxLayoutAlign="flex-start center">-->
<!--                      <span class="ca-field-with-icon" *ngIf="level2.order">-->
<!--                        Slot {{level2.order}}-->
<!--                      </span>-->
<!--                      <p *ngIf="!level2.order">-->
<!--                        No order-->
<!--                      </p>-->
<!--&lt;!&ndash;                      <ca-icon&ndash;&gt;-->
<!--&lt;!&ndash;                        [key]="'NAVIGATE_TO_LINK'"&ndash;&gt;-->
<!--&lt;!&ndash;                        [innerClass]="'ca-link-icon'"&ndash;&gt;-->
<!--&lt;!&ndash;                        (click)="navigateToRelatedNetworkHub(inventory.network_hub_map.network_hub_id, level2.id)"&ndash;&gt;-->
<!--&lt;!&ndash;                        [tooltip]="'Navigate to circuit'">&ndash;&gt;-->
<!--&lt;!&ndash;                      </ca-icon>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        <!--SITE Z SECTION-->
        <mat-card fxFlex fxFlex caCard>
          <mat-card-header>
            Site Z (Customer Location)
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Site</label>
                <p>
                  {{ inventory?.siteZ?.site_id || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Vendor</label>
                <p>
                  {{ inventory?.siteZ?.vendor?.name || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Site type</label>
                <p>
                  {{ inventory?.siteZ?.type?.value || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Room</label>
                <p>
                  {{ inventory?.siteZ?.room || 'N/A'}}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Floor</label>
                <p>
                  {{ inventory?.siteZ?.floor || 'N/A'}}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list" *ngIf="inventory?.siteZ?.building">
              <div fxFlex class="entry-list-item">
                <label>Building</label>
                <div>
                  <p>
                    {{ inventory?.siteZ?.building?.name}}
                  </p>
                  <p *ngIf="inventory?.siteZ?.building?.address?.address1">
                    {{ inventory?.siteZ?.building?.address?.address1}}
                  </p>
                  <p>
                    {{ inventory?.siteZ?.building?.address?.city}}&nbsp; {{
                    inventory?.siteZ?.building?.address?.subdivision?.name}}&nbsp;
                    {{ inventory?.siteZ?.building?.address?.postal_code}}
                  </p>
                  <p *ngIf="inventory?.siteZ?.building?.address?.country">
                    {{ inventory?.siteZ?.building?.address?.country?.name}}
                  </p>
                </div>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Local Contact</label>
                <p>
                  {{ inventory?.site_z_lcontact?.full_name}}
                </p>
                <p>
                  {{ inventory?.site_z_lcontact?.email}}
                </p>
                <p>
                  {{ inventory?.site_z_lcontact?.office_phone}}
                </p>
                <p *ngIf="!inventory?.site_z_lcontact_id">N/A</p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        </div>
        <div class="flex-container ca-card custom-mat-card-container" fxLayout="row">
          <!--SITE A DEMARC SECTION-->
          <mat-card fxFlex caCard>
            <mat-card-header>
              Site A Demarcation Point
            </mat-card-header>
            <mat-card-content>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Row</label>
                  <p>
                    {{ siteADemarcs[0]?.row_id || 'N/A'}}
                  </p>
                </div>
                <div fxFlex class="entry-list-item">
                  <label>Rack</label>
                  <p>
                    {{ siteADemarcs[0]?.rack_id || 'N/A'}}
                  </p>
                </div>
              </div>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Shelf</label>
                  <p>
                    {{ siteADemarcs[0]?.shelf_id || 'N/A'}}
                  </p>
                </div>
                <div fxFlex class="entry-list-item">
                  <label>CPE</label>
                  <p>
                    {{ siteADemarcs[0]?.cpe_id || 'N/A'}}
                  </p>
                </div>
              </div>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Card</label>
                  <p>
                    {{ siteADemarcs[0]?.card_id || 'N/A'}}
                  </p>
                </div>
                <div fxFlex class="entry-list-item">
                  <label>Port</label>
                  <p>
                    {{ siteADemarcs[0]?.port_id || 'N/A'}}
                  </p>
                </div>
              </div>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Extended</label>
                  <p>
                    {{ siteADemarcs[0]?.extended ? 'Yes' : 'No' || 'N/A'}}
                  </p>
                </div>
                <div fxFlex class="entry-list-item">
                  <label>Notes</label>
                  <p>
                    {{ siteADemarcs[0]?.notes || 'N/A'}}
                  </p>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        <!--SITE Z DEMARC SECTION-->
        <mat-card fxFlex caCard>
            <mat-card-header>
              Site Z Demarcation Point
            </mat-card-header>
            <mat-card-content>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Row</label>
                  <p>
                    {{ siteZDemarcs[0]?.row_id || 'N/A'}}
                  </p>
                </div>
                <div fxFlex class="entry-list-item">
                  <label>Rack</label>
                  <p>
                    {{ siteZDemarcs[0]?.rack_id || 'N/A'}}
                  </p>
                </div>
              </div>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Shelf</label>
                  <p>
                    {{ siteZDemarcs[0]?.shelf_id || 'N/A'}}
                  </p>
                </div>
                <div fxFlex class="entry-list-item">
                  <label>CPE</label>
                  <p>
                    {{ siteZDemarcs[0]?.cpe_id || 'N/A'}}
                  </p>
                </div>
              </div>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Card</label>
                  <p>
                    {{ siteZDemarcs[0]?.card_id || 'N/A'}}
                  </p>
                </div>
                <div fxFlex class="entry-list-item">
                  <label>Port</label>
                  <p>
                    {{ siteZDemarcs[0]?.port_id || 'N/A'}}
                  </p>
                </div>
              </div>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Extended</label>
                  <p>
                    {{ siteZDemarcs[0]?.extended ? 'Yes' : 'No' || 'N/A'}}
                  </p>
                </div>
                <div fxFlex class="entry-list-item">
                  <label>Notes</label>
                  <p>
                    {{ siteZDemarcs[0]?.notes || 'N/A'}}
                  </p>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
      </div>
    </div>
    <mat-sidenav #panelSide
                 [mode]="'side'"
                 position="end"
                 [opened]="false">
      <ca-history
        #history
        [entityId]="inventory?.id"
        [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
        [entityType]="'inventory'">
      </ca-history>

      <ca-notes
        [entityId]="inventory?.id"
        [entityType]="SYSTEM_MODULE.INVENTORY"
        [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
        (countChanged)="onNotesCountChanged($event)">
      </ca-notes>
    </mat-sidenav>
</mat-sidenav-container>
