import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Restangular} from "ngx-restangular";
import {Account} from "./account";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {ApiService} from "../../core/api";
import Query from "../../core/query/query";


@Injectable()
export class AccountService extends BaseService<Account> {

  constructor(restangular: Restangular,
              private entityLock: EntityLockService,
              private apiService: ApiService,
  ) {
    super('account', restangular, entityLock);
  }

  findAccountsForDisputeVATCheck(query): any {
    let concreteQuery = (query || new Query({limit: 100000}));
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('findAccountsForDisputeVATCheck').customPOST(this.toFilter(transformedQuery));
  }

  findOnlyAccounts(query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('findAccountsForPicker').customPOST(this.toFilter(transformedQuery));
  }

  findAccountsWithInvoices(query): any {
    return this.findAll(query);
  }

  findActiveAccountsWithInvoices(query): any {
    return this.findAll(query);
  }

  findActiveAccounts(query): any {
    if (query) query.limit = 10000;
    let concreteQuery = (query || new Query({limit: 10000}));
    let transformedQuery = concreteQuery.transform();
    //Request query has limitation he can receive a limited number of characters. We decided to use the post method because the limit is larger. We didn't find a solution for how to change the req.query limit
    return this.service().all(this.name).all('findAccounts').customPOST(this.toFilter(transformedQuery));
  }

  findAccountsForPicker(query): any {
    if (query) query.limit = 50;
    let concreteQuery = (query || new Query({limit: 50}));
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('findAccountsForPicker').customPOST(this.toFilter(transformedQuery));
  }

  findActiveAccountsWithInvoicesAndSubAccounts(query): any {
    return this.findAll(query);
  }

  save(dataToSave): any {
    return this.service().all(this.name).all('save').post(dataToSave);
  }

  findVendors(query): any {
    return this.apiService.get([this.name, 'vendors'], query);
  }

  codingUpdate(id, account): any {
    return this.service().one(`${this.name}/coding`, id).customPUT(account);
  }

  codingCreateUpdate(id, account): any {
    return this.service().one(`${this.name}/coding-create`, id).customPUT(account);
  }

  findAccountCustomRules(account_id): any {
    return this.service().one(`${this.name}/find-account-custom-rules`).customGET(account_id);
  }

  checkGLRules(newAccount){
    return this.service().all(`${this.name}/check-gl-rules`).post(newAccount);
  }

  findAllForRateAudit(query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('findAllForRateAudit').customGET(null, this.toFilter(transformedQuery));
  }

}
