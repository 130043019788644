
import {switchMap} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { DialogService } from "app/shared/dialog/dialog.service";
import { AccountManageDialogComponent } from "../shared/account-manage-dialog/account-manage-dialog.component";
import { AccountService } from "./account.service";

@Injectable()
export class AccountDialogService {
  constructor(
    private dialog: DialogService,
    private accountService: AccountService
  ) {}

  openEditAccountDialog(account, hasCustomFields = false) {
    return this.dialog.edit(AccountManageDialogComponent, {
        account: this.accountService.findByIdForEdit(account.id)
      }, {
        width: hasCustomFields ? '1200px' : '820px'
      }).pipe(switchMap((item) => {
        return item.afterClosed();
      }));
  }
}
