import { Injectable } from "@angular/core";
import { ChargeTypeLookupProvider } from "../../charge/core/charge-type-lookup.provider";
import { FacilityBandwidthLookupProvider } from "../../charge/core/facility-bandwidth-lookup.provider";
import { InvoiceChargeService } from "../../charge/core/invoice-charge.service";
import { DefaultDateRangesLookupProvider } from "../../core/filter/default-date-ranges-lookup.provider";
import { FilterService } from "../../core/filter/filter.service";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { InvoiceFacepageService } from "../../invoice/core/invoice-facepage.service";
import { SubAccountLookupProvider } from "../../invoice/core/sub-account-lookup.provider";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { alertChargeFilterContext } from "./alert-charge-filter.context";

@Injectable()
export class AlertChargesFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public userSettingsService: UserSettingsService,
              public chargeService: InvoiceChargeService,
              public dictionaryService: DictionaryService,
              public invoiceService: InvoiceFacepageService) {
    super("app.cost.invoice-charge-filter", alertChargeFilterContext, userSettingsService);

    this.addLookupProvider('chargeType', new ChargeTypeLookupProvider(dictionaryService));
    this.addLookupProvider('subAccount', new SubAccountLookupProvider(invoiceService));
    this.addLookupProvider('facilityBandwidth', new FacilityBandwidthLookupProvider(chargeService));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
