<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
    <h1 mat-dialog-title>Approve Selected Invoices</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
<form class="form dialog-content-no-margin" [formGroup]="invoiceApproveForm">

    <div fxLayout="row" style="max-height: 500px !important;">
      <dx-data-grid #grid [showColumnLines]="false"
                    [showRowLines]="true" [showBorders]="true"
                    [dataSource]="invoices" [paging]="false">
        <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>
        <dxi-column dataField="sp_inv_num" caption="Invoice Number" [width]="200"></dxi-column>
        <dxi-column dataField="vendor.name" caption="Vendor" [width]="120"></dxi-column>
        <dxi-column dataField="acct_level_1" caption="Account" [width]="150"></dxi-column>
        <dxi-column dataField="date_issued" caption="Issue Date" [width]="120" cellTemplate="dateTemplate"></dxi-column>

        <div *dxTemplate="let d of 'dateTemplate'">
          <div caGridTooltip>{{ d.data.date_issued | caDate }}</div>
        </div>
      </dx-data-grid>
      <!--<ca-pager
        [gridPager]="defaultGridPager"
        [pageContext]="inventoryGridService"
        [query]="query"
        class="app-pager ca-default-border-top"
        (onChange)="loadData(query)"
      ></ca-pager>-->
    </div>
</form>
  </div>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
  </div>

  <button mat-button type="submit" (click)="close()">
    Cancel
  </button>

  <button mat-button type="submit" (click)="onSubmit()">
    Approve
  </button>
</mat-dialog-actions>
