<mat-tab-group
  #tabgroup
  (selectedIndexChange)="onTabChange(tabgroup, $event)"
  [selectedIndex]="index"
  (click)="checkForDisabledProperty()"
  >
  <mat-tab
    *ngFor="let tab of tabGroup.tabs"
    [label]="tab.label">
   
  </mat-tab>
</mat-tab-group>
