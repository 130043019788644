import {Observable, throwError as observableThrowError} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {HttpClient, HttpResponse} from "@angular/common/http";

import {BaseService} from '../../core/base.service';
import Auth from '../user-session/auth';
import {environment} from '../../../environments/environment';

@Injectable()
export class FileService extends BaseService<any> {

  constructor(public restangular: Restangular, public http: HttpClient) {
    super('file', restangular);
  }


  getFile(context: any): Observable<any> {
    let cookie = Auth.getSession();
    const {key, bucket} = context;
    const endPoint: string = [this.name, '/download'].join('');
    try {
      return this.http.get(`${environment.API_URL}${endPoint}`, {
          params: {
            key, bucket
          },
          responseType: 'blob', headers: {
            authorization: `Bearer ${cookie.token}`
          }
        }
      )
        .pipe(map(response => this.extractCSV(response)))
    } catch (error) {
      this.handleCSVError(error)
    }
  }

  public extractCSV(data) {
    let blob = new Blob([data], {type: 'application/csv'});
    return blob || {};
  }

  public handleCSVError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      const body = error || '';
      const err = body['error'] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  download(context): Observable<any> {
    const {name} = context;
    return this.getFile(context).pipe(
      tap((response) => {
        let objectUrl = URL.createObjectURL(response);

        let save = document.createElement('a');
        save.href = objectUrl;
        save.target = '_blank';
        save.download = name;

        let event = document.createEvent('MouseEvents');
        event.initMouseEvent(
          'click', true, false, window, 0, 0, 0, 0, 0
          , false, false, false, false, 0, null
        );
        save.dispatchEvent(event);
      }));
  }
}
