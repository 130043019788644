import {Observable} from "rxjs";
import {ContractService} from "./contract.service";
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import Query from "../../core/query/query";

export class ContractLookupProvider extends IFilterLookupProvider {
  constructor(public contractService: ContractService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    let query = searchQuery? searchQuery : new Query();
    query.orderBy = [['name', 'ASC']];

    return this.contractService.findAll(searchQuery)
      .map((result) => {
        return {items: result.items.map((entry) => {
          return entry.name;
        }), total: result.total};
      });
  }
}
