import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { clone } from 'ramda';

import { FilterSettingsComponent } from '../filter-settings/filter-settings.component';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { FilterContext } from '../filter-context';
import Query from '../../query/query';
import { ACCOUNT_STATUS_ENUM } from '../../../account/core/account-status.enum';
import { NewFilterInputComponent } from '../new-filter-input/new-filter-input.component';
import {path} from 'ramda';
@Component({
  // tslint:disable-next-line
  selector: 'new-app-filter',
  templateUrl: './new-filter.component.html',
  styles: [':host { width: 100%; }']
})
export class NewFilterComponent implements OnInit, OnChanges {
  @Input() query: Query = new Query();
  @Input() report;
  @Input() filterService;
  @Input() isToRefresh: boolean;
  @Input() documents: boolean;
  @Input() includeTypes: Array<number> = [];
  @Output() filterRequested: EventEmitter<any>;
  @Output() clearFilterRequested: EventEmitter<any>;
  @Input() glChargeString: boolean;
  @Input() autoApprover: boolean;
  @Input() reportDynamic: boolean;
  @Input() entryInterceptor: any;
  @Input() fetchReconQuery: boolean = false;
  @Input() disableButtons: boolean = false;

  @Input() initialDateRange = {
    from: null,
    to: null
  };

  initialQuery: Query;

  numbersDidNotPassValidation: boolean;

  @ViewChild('filterInput') filterInput: NewFilterInputComponent;

  readonly ACCOUNT_STATUS_ACTIVE = ACCOUNT_STATUS_ENUM.ACTIVE;

  public filterContext: FilterContext;
  public tmpReportFilterContext: any = [];

  /* Monthly Variance report specific */
  readonly MONTHLY_VARIANCE_KEY: string = 'monthly_variance';
  public monthlyVarianceMonths = [];
  public monthlyVarianceMonth: string;
  public monthlyVarianceMonthInitial: string = moment().format('YYYY-MM');
  queryCloned: Query;

  private initQueryChangesEmitters = (outputName) => {
    const eventEmitter = new EventEmitter();
    this[outputName] = Object.create(eventEmitter);
    this[outputName].emit = (value: Query) => {
      return eventEmitter.emit(Object.assign(value.clone(), {
        // workaround for the paging state to be persisted
        // as the current codebase havely relies on one query object reference through multiple components
        // eg. here we pass the whole object as an input even though the three props from below means nothing to this components
        // Principle of least privilege not fallowed
        orderBy: this.query.orderBy,
        limit: this.query.limit,
        offset: this.query.offset
      }));
    };
  }

  constructor(public dialogService: DialogService) {
    ['filterRequested', 'clearFilterRequested'].forEach(this.initQueryChangesEmitters)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.reportDynamic) {
      if (changes && changes.report) {
        let settings = changes.report.currentValue.settings;
        if (
          settings.hasOwnProperty('dynamic_filters') &&
          settings.dynamic_filters &&
          settings.dynamic_filters.filters &&
          settings.dynamic_filters.filters.length
        ) {
          this.filterService.name = settings.dynamic_filters.name;
          this.tmpReportFilterContext = this.filterService.reportFilterContext =
            settings.dynamic_filters.filters;
        }
      }
    }
    if (changes.isToRefresh) {
      if (this.isToRefresh) {
        this.loadContext();
      }
    }

    // part for filters on roles
    const previousValue = path(['filterService', 'previousValue', 'name'], changes);
    const currentValue = path(['filterService', 'currentValue', 'name'], changes);
    if (previousValue !== currentValue) {
      this.loadContext();
    }
  }

  public manuallyChangeQueryAndRefresh(query: Query, contextChanger) {
    this.queryCloned = new Query(
    Object.assign(JSON.parse(JSON.stringify(query)))
    );
    this.initialQuery = this.queryCloned.clone();
    this.filterRequested.emit(this.initialQuery)

    if (contextChanger) {
      this.filterContext = contextChanger(this.filterContext);
    }

    this.applyFilter();
  }

  ngOnInit() {
    if (this.reportDynamic) {
      this.filterService.create().subscribe((context: FilterContext) => {
        this.filterContext = context;
      });
      this.loadMonthlyVarianceMonths();
    } else {
      this.loadContext();
    }

    this.queryCloned = new Query(
      Object.assign(JSON.parse(JSON.stringify(this.query)))
    );
    this.initialQuery = this.queryCloned.clone();
  }

  get initialFilter() {
    return clone(this.initialQuery.where);
  }

  loadContext() {
    if (this.documents) {
      this.filterService.addFilteredLookupProvider(
        new Query({
          where: { id: { $in: this.includeTypes } }
        })
      );
    }
    this.filterService.create().subscribe((context: FilterContext) => {
      this.filterContext = context;
    });
  }

  applyFilter() {
    this.queryCloned.offset = 0;
    this.query.offset = 0;
    this.queryCloned.page = 1;
    this.filterRequested.emit(this.queryCloned);
    if (this.reportDynamic) {
      /* Monthly Variance report specific */
      if (this.monthlyVarianceMonth) {
        this.queryCloned.where.monthlyVarianceMonth = this.monthlyVarianceMonth;
      }
    }
  }

  clearFilter() {
    this.queryCloned.filterMeta = {};
    if (this.glChargeString) {
      delete this.queryCloned['where']['full_string_formatted'];
      delete this.queryCloned['where']['gl_code_type'];
      delete this.queryCloned['where']['date_added'];
      delete this.queryCloned['where']['full_name'];
      delete this.queryCloned['where']['status'];
      let assignedQuery = Object.assign(new Query(), this.queryCloned);
      this.queryCloned = assignedQuery;
      this.queryCloned.offset = 0;
      this.queryCloned.page = 1;
      this.clearFilterRequested.emit(this.queryCloned);
      this.filterRequested.emit(this.queryCloned);
    } else if (this.autoApprover) {
      this.queryCloned['where'] = { status_id: this.ACCOUNT_STATUS_ACTIVE };
      this.queryCloned.offset = 0;
      this.queryCloned.page = 1;
      let assignedQuery = Object.assign(new Query(), this.queryCloned);
      this.queryCloned = assignedQuery;
      this.clearFilterRequested.emit(this.queryCloned);
      this.filterRequested.emit(this.queryCloned);
    } else if (this.reportDynamic) {
      let reportQuery = Object.assign(new Query(), this.queryCloned);
      reportQuery['where'] = {};

      this.queryCloned = reportQuery;
      this.queryCloned.offset = 0;
      this.queryCloned.page = 1;

      // reset monthlyVarianceMonth   /* Monthly Variance report specific */
      if (this.monthlyVarianceMonth) {
        this.monthlyVarianceMonth = this.monthlyVarianceMonthInitial;
        this.queryCloned.where.monthlyVarianceMonth = this.monthlyVarianceMonth;
      }

      this.clearFilterRequested.emit(this.queryCloned);
    } else {
      let assignedQuery = Object.assign(new Query({}), this.queryCloned);
      assignedQuery.where = this.initialFilter;

      this.queryCloned = assignedQuery;
      this.queryCloned.offset = 0;
      this.queryCloned.page = 1;
      this.clearFilterRequested.emit(this.queryCloned);
    }

    this.filterInput.mustClearFilters();
  }

  /*
  This function will remove filters from query if any of them is disabled in settings.
  */
  refreshFiltering(activeFilters) {
    if (this.queryCloned && this.queryCloned.where) {
      activeFilters.filters.forEach(filter => {
        if (!filter.visible) {
          Object.keys(this.queryCloned.where).forEach(property => {
            if (property === filter.field) {
              delete this.queryCloned.where[filter.field];
            }

            // TODO Put special cases here when field name is not same as property name in where object.
            if (
              property === 'vendor_id' ||
              property === 'invoice.acct_level_1'
            ) {
              if (filter.field === 'vendor_and_account') {
                delete this.queryCloned.where[property];
              }
            }
          });
        }
      });
    }
    this.loadContext();
  }

  settings() {
    this.dialogService
      .open(FilterSettingsComponent, {
        context: this.filterContext
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.refreshFiltering(result);
          this.clearFilter()
        }
      });
  }

  numberValidation(event) {
    this.numbersDidNotPassValidation = event;
  }

  // Dates selection for monthly variance report
  loadMonthlyVarianceMonths() {
    if (this.report.settings.report_key === this.MONTHLY_VARIANCE_KEY) {
      for (let i = 0; i < 6; i++) {
        this.monthlyVarianceMonths.push(
          moment()
            .subtract(i, 'months')
            .format('YYYY-MM')
        );
      }
      this.monthlyVarianceMonths.push('2016-05'); // temp month with data
      this.monthlyVarianceMonth = this.monthlyVarianceMonthInitial;
    }
  }

  // gets event and calls function in filter input component
  public removeFilterCard(e) {
    this.filterInput.removeFilterCard(e);
  }
}
