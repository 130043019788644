
import { Injectable } from '@angular/core';
import { VendorRemitAddressLookupProvider } from 'app/vendor-remit-address/core/vendor-remit-address-lookup.provider';
import { VendorRemitAddressService } from 'app/vendor-remit-address/core/vendor-remit-address.service';
import { Observable, of as observableOf } from 'rxjs';
import { AccountLookupProvider } from "../../account/core/account-lookup.provider";
import { AccountService } from '../../account/core/account.service';
import { SubclientLookupProvider } from "../../account/core/subclient-lookup.provider";
import { IFilterLookupProvider } from '../../core/filter/filter-lookup.provider';
import { FilterService } from '../../core/filter/filter.service';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { InvoiceFacepageService } from '../../invoice/core/invoice-facepage.service';
import { SubAccountLookupProvider } from '../../invoice/core/sub-account-lookup.provider';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { VendorLookupProvider } from '../../vendor/core/vendor-lookup.provider';
import { VendorService } from '../../vendor/core/vendor.service';
import { chargeFilterContext } from './charge-filter.context';
import { ChargeTypeLookupProvider } from './charge-type-lookup.provider';
import { InvoiceChargeService } from './invoice-charge.service';
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

export class DirectGlTypeLookupProvider extends IFilterLookupProvider{

  findAll(searchQuery?): Observable<any> {
    return observableOf([
      {
        value: 'Yes',
        key: true
      },
      {
        value: 'No',
        key: false
      }
    ])
  }
}

@Injectable()
export class ChargeFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];

  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public userSettingsService: UserSettingsService,
              public dictionaryService: DictionaryService,
              public invoiceService: InvoiceFacepageService,
              public chargeService: InvoiceChargeService,
              public vendorService: VendorService,
              public accountService: AccountService,
              public vendorRemitAddressService: VendorRemitAddressService) {
    super('app.cost.charge-filter', chargeFilterContext, userSettingsService);

    this.addLookupProvider('chargeType', new ChargeTypeLookupProvider(dictionaryService));
    this.addLookupProvider('subAccount', new SubAccountLookupProvider(invoiceService));
    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('account', new AccountLookupProvider(accountService));
    this.addLookupProvider('unsetAdjustment',
      new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.UNSET_ADJUSTMENTS.modelName));
    this.addLookupProvider('vendorRemitAddress', new VendorRemitAddressLookupProvider(vendorRemitAddressService, true));
    this.addLookupProvider('subclient', new SubclientLookupProvider(this.dictionaryService));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
