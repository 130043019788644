<app-contract-schedule-toolbar
    [isSummaryTab]="true"
    (editScheduleRequested)="editSchedule()"
    [isFilterDisabled]="true"
    [notesCount]="notesCount"
    [isNotesOn] = "this.sider.getActiveSection()?.name === this.SECTIONS.NOTES_SECTION_NAME"
    (toggleNotesRequested)="toggleNotes()"
></app-contract-schedule-toolbar>
<mat-sidenav-container class="app-content app-content-list tabbed-content dx-nodata-absolute">
    <app-contract-schedule-tabs [activeTabIndex]="0" [schedule]="contractSchedule"></app-contract-schedule-tabs>
    <div class="tabs-body" fxLayout="row"
         style="height: fit-content">
      <div class="flex-container ca-card" fxLayout="row" fxLayout.sm="column" fxFlex="50%">
        <mat-card fxFlex="100%" class="flex-item" fxFlex.sm="100%" caCard>
          <mat-card-header>
            Contract Info
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Doc/Series Type</label>
                <p>
                  {{ contractSchedule?.contract_schedule_series?.value || 'N/A'}}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Customer Sign Doc Date </label>
                <p> {{ (contractSchedule?.customer_sign_date | caDate )|| 'N/A' }} </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Vendor Sign Doc Date</label>
                <p> {{ (contractSchedule?.vendor_sign_date | caDate) || 'N/A' }} </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Rate Eff Date</label>
                <p> {{ (contractSchedule?.effective_date | caDate) || 'N/A' }} </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Rate Term Date</label>
                <p> {{ (contractSchedule?.termination_date | caDate) || 'N/A' }} </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Rate Term</label>
                <p> {{ contractSchedule?.rate_term|| 'N/A' }} {{contractSchedule?.rate_term ? 'Months': ''}} </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>At Expiration Event</label>
                <p> {{ contractSchedule?.at_exp_event?.value || 'N/A' }} </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Cancel Notice (Days)</label>
                <p> {{ contractSchedule?.cancel_notice_pd || 'N/A' }} </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Cancel Notice Date</label>
                <p> {{ (contractSchedule?.cancel_notice_dt | caDate) || 'N/A' }} </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Description</label>
                <p class = "show-two-line" matTooltip =" {{ contractSchedule?.schedule_description || 'N/A' }}">
                  {{ contractSchedule?.schedule_description || 'N/A' }}
                </p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div *ngIf ="contractSchedule?.contract_schedule_commitment" class="flex-container ca-card" fxLayout="row" fxLayout.sm="column" fxFlex="50%">
        <mat-card fxFlex="100%" class="flex-item" fxFlex.sm="100%" caCard>
          <mat-card-header>
            Commitment Info
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Contributory Svcs</label>
                <p>
                  {{ contractSchedule?.contract_schedule_commitment?.test || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Commit Eff Date</label>
                <p>
                  {{ (contractSchedule?.contract_schedule_commitment?.effective_date | caDate) || 'N/A'}}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Commit Term Date</label>
                <p>
                  {{ (contractSchedule?.contract_schedule_commitment?.termination_date | caDate) || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Commit Term Type</label>
                <p>
                  {{ contractSchedule?.contract_schedule_commitment?.schedule_commitment_type?.value || 'N/A'}}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Commit Term</label>
                <p>
                  {{ contractSchedule?.contract_schedule_commitment?.term || 'N/A'}} {{contractSchedule?.contract_schedule_commitment?.term ? scheduleCommitmentType : ''}}
                </p>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Commit Per Term</label>
                <p>
                  {{ contractSchedule?.contract_schedule_commitment?.commit_per_term|| 'N/A'}}
                </p>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Commit Total</label>
                <p> {{ commitTotal | caCurrency: (contractSchedule?.contract_schedule_commitment?.currency?.currency) || 'N/A'}} </p>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Commit Vendor Per....</label>
                <p>
                  {{ contractSchedule?.contract_schedule_commitment?.test || 'N/A'}}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item show-two-line">
                <label>Commit Description</label>
                <p class = "show-two-line" matTooltip="{{ contractSchedule?.contract_schedule_commitment?.commitment_description || 'N/A'}}">
                  {{ contractSchedule?.contract_schedule_commitment?.commitment_description || 'N/A'}}
                </p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false" (close-start)="panelClose()">
      <ca-notes [entityId]="contractSchedule.id" [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                [entityType]="SYSTEM_MODULE.CONTRACT" (countChanged)="onNotesCountChanged($event)"></ca-notes>
    </mat-sidenav>
</mat-sidenav-container>
