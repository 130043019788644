
import {of as observableOf} from 'rxjs';

import {takeUntil, mergeMap, debounceTime} from 'rxjs/operators';
import {LOOKUP_MODELS_ENUM} from '../../../dictionary/core/lookup-models.enum';
import {PageManageDialogComponent} from '../../../core/page-manage-dialog.component';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {Component, ElementRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, Validators} from "@angular/forms";
import {Order} from "../../core/order";
import {OrderService} from "../../core/order.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {EntityEditContext} from "../../../shared/entity-lock/entity-edit-context";

@Component({
  selector: 'app-order-approval-manage',
  templateUrl: './order-approval-manage.component.html',
})
export class OrderApprovalManageComponent extends PageManageDialogComponent implements OnInit, OnDestroy {

  @ViewChild('reason') reason;
  @ViewChild('rejectNote') rejectNote: ElementRef;


  note: string;
  public noteRequired: boolean = false;

  public canReject: boolean = false;

  order: Order;

  readonly ORDER_REJECT_REASON_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.ORDER_REJECT_REASON.modelName;

  readonly LOCALS = {
    NOTE_MIN_CHARS: 20,
    NOTE_VALIDATION_MSG: 'Minimum 20 characters'
  };

  constructor(public formBuilder: FormBuilder,
              public orderService: OrderService,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<OrderApprovalManageComponent>,
              public alertService: AlertService) {
    super(dialogService, dialogRef);
  }

  ngOnInit() {
      this.form = this.formBuilder.group({
        note: [""],
        reason_id: [""]
      });

      this.form.valueChanges.pipe(
        takeUntil(this.destroy$),
        debounceTime(250),)
        .subscribe((data) => {
          this.validateReject();
        });
  }

  init() {
    if (this.order && this.order.id) {
      return this.orderService.findByIdForEdit(this.order.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .subscribe((items: any) => {
          if (items) {
            let obs = observableOf(items);
            obs.pipe(mergeMap(x => x.afterClosed() ))
              .subscribe((result) => {
                if (result) {
                  return observableOf(result);
                }
              })
          }
        })
    }
  }

  onRejectResonChange(rejectReason) {
    this.noteRequired = (rejectReason.text === 'Other');
    if(this.noteRequired) {
      this.form.controls['note'].setValidators(Validators.compose([Validators.minLength(this.LOCALS.NOTE_MIN_CHARS), Validators.required]));
      setTimeout(()=> {
        this.rejectNote.nativeElement.focus();
      }, 100);
    } else {
      this.form.controls['note'].clearValidators();
    }
  }

  validateReject() {
    if (this.noteRequired) {
      let note = this.form.controls['note'].value;
      this.canReject = note && note.length >= this.LOCALS.NOTE_MIN_CHARS;
    } else {
      this.canReject = this.form.controls['reason_id'].value;
    }
  }

  approve({value, valid}: { value: any, valid: boolean }) {
    this.dialogRef.close({
      approved: true,
      note: value.note
    });
  }

  reject({value, valid}: { value: any, valid: boolean }) {
    let reasonText = this.reason.entries.filter((item) => {
      return item.id == this.reason.value;
    })[0] || {};

    this.dialogRef.close({
      rejected: true,
      note: value.note,
      reasonText: reasonText.text
    });
  }

  cancel() {
    this.orderService.cancelEdit();
    this.closeDialog();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
