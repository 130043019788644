import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output
} from '@angular/core';
import {NotesService} from './core/notes.service';
import {Notes} from './core/notes';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';

import Query from '../../core/query/query';
import {DialogService} from "../dialog/dialog.service";
import {UserModalComponent} from "../user-modal/user-modal.component";
import {DomSanitizer} from "@angular/platform-browser";
import Entity from "../../core/entity.model";
import {flatMap} from "rxjs/internal/operators";

@Component({
  selector: 'ca-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.css']
})
export class NotesListComponent implements OnInit, OnChanges {

  @Input("master") master: Entity;
  @Input("multiple") multiple: boolean = true;
  @Input("showViewLink") showViewLink: Function = (note) => {
    return false;
  };
  @Input("selectedId") selectedId: number;
  @Input("mapMeta") mapMeta: Function = () => {
    return false;
  };

  @Output('onViewEntitiesRequested') onViewEntitiesRequested = new EventEmitter();
  @Output('onViewEntityRequested') onViewEntityRequested = new EventEmitter();
  @Output() countChanged = new EventEmitter();
  @Output() noteCreated = new EventEmitter();

  public notes: Array<Notes> = [];
  public replyMode = false;
  noteForm: FormGroup;
  selectedNote: Notes;
  private _entities: Array<Entity> = [];
  groupedNotes: Array<any> = [];
  selectedLineItem;

  get entities(): Array<Entity> {
    return this._entities;
  }

  @Input("entities")
  set entities(value: Array<Entity>) {
    this._entities = value ? value.filter((entity) => {
      return !!entity;
    }) : [];
  }


  private _entity: Entity;
  get entity(): Entity {
    return this._entity;
  }

  @Input("entity")
  set entity(value: Entity) {
    this._entity = value;
    if (value && this.multiple) {
      this.entities = [];
      this.entities.push(value);
    }
  }

  private _options: Array<any> = [];
  get options(): Array<any> {
    return this._options;
  }

  @Input("options")
  set options(value: Array<any>) {
    this._options = value;
  }

  private _groups: Array<any> = [];
  get groups(): Array<any> {
    return this._groups;
  }

  @Input("groups")
  set groups(value: Array<any>) {
    this._groups = value;
    if (value && value.length) {
      this.draw();
    }
  }

  constructor(public notesService: NotesService,
              public formBuilder: FormBuilder,
              public dialog: DialogService,
              public sanitizer: DomSanitizer) {
  }

  ngOnChanges(changes: SimpleChanges) {

    let master = changes['master'];
    if (master && master.currentValue) {
      this.loadNotes();
    }

  }

  ngOnInit() {

    this.noteForm = this.formBuilder.group({
      content: new FormControl('', Validators.required)
    });

  }

  onLineItemChange(item) {
    this.selectedLineItem = this.options.filter((option) => {
      return item.value && parseInt(item.value) === option.id;
    })[0];
  }

  draw() {
    if (this.groups && this.groups.length && this.notes && this.notes.length) {

      let groups = [].concat(this.groups),
        notes = this.notes;

      notes.forEach((note) => {
        let group = this.findGroup(note.group_id, note.group_type);
        if (!group) {
          group = this.findGroup(note.entity_id, note.entity_type);
        }
        if (!group) {
          group = this.findGroup(note.master_id, note.master_type);
        }

        if (group) {
          group.notes = group.notes || [];
          let existingNote = this.findNote(group, note.id);
          if (!existingNote) {
            group.notes.push(note);
          }
          else {
            Object.assign(existingNote, note);
          }
        }
      });

      this.groupedNotes = groups.filter((group) => {
        return group.notes && group.notes.length;
      });
    }
  }

  selectGroup(group) {
    let status = group.open;
    this.groupedNotes.forEach((g) => {
      g.open = false;
    });
    group.open = !status;
  }

  findGroup(id, type) {
    return this.groups.filter((group) => {
      return group.id == id && type == parseInt(group.type);
    })[0];
  }

  findNote(group, noteId) {
    return group.notes.filter((note) => {
      return noteId === note.id;
    })[0];
  }

  loadNotes() {
    let query = new Query({
      where: {master_type: this.master.type, master_id: this.master.id},
      orderBy: [['created_at', 'DESC']]
    });

    this.notesService.findAllForMaster(query)
      .subscribe(
        (result) => {
          this.notes = result.items;
          this.emitCount();
          this.draw();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  cancel(note: Notes) {
    note.reply = null;
    this.replyMode = false;
  }

  createNote(parentId?: number, content?: string): void {
    let note = new Notes();

    let query = new Query({
      where: {master_type: this.master.type, master_id: this.master.id},
      orderBy: [['created_at', 'DESC']]
    });

    if (this.master) {
      query.where['master_id'] = this.master.id
    }


    let noopMetaFn = (entity) => {
      return entity;
    };

    let mapFnMeta = this.mapMeta || noopMetaFn;

    note.content = content || this.noteForm.controls['content'].value;
    note.parent_id = parentId;
    note.entity_id = this.entity ? this.entity.id : this.master.id;
    note.entity_type = this.entity ? this.entity.type : this.master.type;
    note.entity_meta = this.entity ? mapFnMeta(this.entity) : mapFnMeta(this.master);

    let lineItem = this.selectedLineItem;

    if (lineItem) {
      note.group_id = note.entity_id;
      note.group_type = note.entity_type;
      note.entity_id = lineItem.id;
      note.entity_type = lineItem.type;
      note.entity_meta = mapFnMeta(lineItem);
    }

    note.master_id = this.master.id;
    note.master_type = this.master.type;
    note.entities = this._entities;

    this.noteForm.reset();

    this.notesService.create(note)
      .pipe(flatMap(() => this.notesService.findAllForMaster(query)))
      .subscribe(
        (result) => {
          this.notes = result.items;
          this.emitCount();
          const formControlContent = this.noteForm.controls['content'];
          formControlContent.setErrors({'required': false});
          this.replyMode = false;
          this.selectedLineItem = null;
          this.noteCreated.emit();
          this.draw();
        },
        (error) => {
          console.log(error);
        }
      );
  }


  reply(note: Notes) {
    if (this.replyMode) {
      this.createNote(note.id, note.reply);
    } else {
      this.replyMode = true;
      this.selectedNote = note;
      note.reply = null;
    }
  }

  getUserInitials(note): string {
    return note.user
      ? note.user.first_name.toUpperCase()[0] + note.user.last_name.toUpperCase()[0]
      : 'NA';
  }

  viewEntities(entities) {
    this.onViewEntitiesRequested.emit(entities);
  }

  viewEntity(note) {
    this.onViewEntityRequested.emit(note);
  }

  public showUserModal(user) {
    this.dialog.open(UserModalComponent, {user: user}, {width: '400px'});
  }

  emitCount() {
    this.countChanged.emit(this.notes.length);
  }

  sanitizeNoteContent(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
