import {Vendor} from "../../vendor/core/vendor";

export class SvcOrderHeader {
  id: number;
  name: string;
  description: string;
  vendor: Vendor;
  vendor_id: number;
  total_mrc: number;
  total_nrc: number;
  vendor_so_number: string

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
