import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-svc-order-details-tabs',
  templateUrl: './svc-order-details-tabs.component.html',
  styleUrls: ['./svc-order-details-tabs.component.css']
})
export class SvcOrderDetailsTabsComponent implements OnInit {

  @Output() tabChangeRequested: EventEmitter<any> = new EventEmitter<any>();

  tabs = [
    {label: 'Summary', key: 'summary'},
    {label: 'Services', key: 'services'},
    {label: 'Key Terms & Conditions', key: 'terms-conditions'},
    {label: 'Attachments', key: 'attachments'}
  ];

  @Input() activeTabIndex: number = 0;
  @Input() svcOrderHeader

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router) { }

  ngOnInit(): void {
  }

  onTabChange(index: number) {
    const key = this.activatedRoute.snapshot.queryParams['key'];
    const activeTab = this.tabs[index].key;
    this.router.navigate(['contract', this.svcOrderHeader.id,'order' ,'show' ,activeTab], {
      replaceUrl: !key
    });
  }

}
