import { Component, OnInit, Inject } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'range-filter-base',
  templateUrl: 'range-filter-base.component.html'
})
export class RangeFilterBaseComponent {
  public readonly FOCUS = {
    FROM: 'from',
    TO: 'to',
    MAX: 'max'
  };

  public readonly NUMBER_VALIDATION_ERROR = {
    SMALLER_THEN_1: 'Must be >= 0',
    FROM_BIGGER: 'Must be < To',
    TO_SMALLER: 'Must be > From',
    MAX: 'Must be < '
  };

  public fromNumberError: boolean;
  public fromNumberErrorText: string;
  public toNumberError: boolean;
  public toNumberErrorText: string;

  constructor(@Inject('allowNegative') public allowNegative: boolean) { }

  public validateFromField(to: any, from: any, max?: any) {
    this.resetErrors();
    if (to || to === 0) {
      if (!this.allowNegative && to < 0) {
        this.toNumberError = true;
        this.toNumberErrorText = this.NUMBER_VALIDATION_ERROR.SMALLER_THEN_1;
      } else if (from && from > to) {
        this.fromNumberError = true;
        this.fromNumberErrorText =
          this.NUMBER_VALIDATION_ERROR.FROM_BIGGER;
      }
    }
    if (max && from > max) {
      this.fromNumberError = true;
      this.fromNumberErrorText = this.NUMBER_VALIDATION_ERROR.MAX + max;
    }
  }

  public validateToField(from: any, to: any, max?: any) {
    this.resetErrors();

    if (from || from === 0) {
      if (!this.allowNegative && from < 0) {
        this.fromNumberError = true;
        this.fromNumberErrorText = this.NUMBER_VALIDATION_ERROR.SMALLER_THEN_1;
      }
      if (to && to < from) {
        this.toNumberError = true;
        this.toNumberErrorText = this.NUMBER_VALIDATION_ERROR.TO_SMALLER;
      } else {
        this.toNumberError = false;
        this.toNumberErrorText = '';
      }
    }
    if (max && to > max) {
      this.toNumberError = true;
      this.toNumberErrorText = this.NUMBER_VALIDATION_ERROR.MAX + max;
    }
  }

  public resetErrors() {
    this.fromNumberError = this.toNumberError = false;
    this.fromNumberErrorText = this.toNumberErrorText = '';
  }
}
