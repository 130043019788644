import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import {Observable} from "rxjs";
import Query from "../../core/query/query";
import {map} from "rxjs/operators";
import {ContractService} from "./contract.service";
export class ContractScheduleLookupProvider extends IFilterLookupProvider {

  constructor(public contractService: ContractService,) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    let query = searchQuery? searchQuery : new Query();

    return this.contractService.findSchedules(query)
      .pipe(map((result: any) => {
        return {items: result.items.map((entry: any) => {
            return entry.schedule_name;
          }), total: result.total};
      }));
  }

}
