import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuditResultService } from "app/audit/core/audit-result.service";
import { AuditsService } from "app/audit/core/audits.service";
import { RateAuditResultTmpGridService } from 'app/audit/rate-audits/core/rate-audit-result-tmp-grid.service';
import { RateAuditResultService } from 'app/audit/rate-audits/core/rate-audit-result.service';
import { RateAuditRuleService } from 'app/audit/rate-audits/core/rate-audit-rule.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { takeUntil } from "rxjs/operators";
import { ChargeQuery } from "../../charge/core/charge.query";
import { InvoiceChargeService } from "../../charge/core/invoice-charge.service";
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import Query from "../../core/query/query";
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { LOOKUP_ENUM } from "../../dictionary/core/lookup.enum";
import { DisputeManageDialogComponent } from '../../dispute/shared/dispute-manage-dialog/dispute-manage-dialog.component';
import { InventoryService } from "../../inventory/core/inventory.service";
import { LocationService } from "../../location/core/location.service";
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { GridService } from "../../shared/grid/grid.service";
import { LoaderService } from '../../shared/loader/loader.service';
import { TabGroup, TabService } from '../../shared/tabs/tab.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { UserService } from "../../user/core/user.service";
import { AlertDisconnectGridService } from '../core/alert-disconnect-grid.service';
import { AlertInventoryChargeTotalGridService } from '../core/alert-inventory-charge-total-grid.service';
import { AlertInventoryMrcChargesGridService } from "../core/alert-inventory-mrc-charges-grid.service";
import { AlertInventoryMrcGridService } from "../core/alert-inventory-mrc-grid.service";
import { AlertMissingInventoryDetailsGridService } from "../core/alert-missing-inventory-details-grid.service";
import { AlertMissingInventorySpidGridService } from "../core/alert-missing-inventory-spid-grid.service";
import { AlertRateAuditGridService } from '../core/alert-rate-audit-grid.service';
import { AuditAlertDisconnectAuditFilterService } from '../core/audit-alert-disconnect-audit-filter.service';
import { AuditAlertInventoryMRCFilterService } from '../core/audit-alert-inventory-mrc-filter.service';
import { AuditAlertMissingInventoryFilterService } from '../core/audit-alert-missing-inventory-filter.service';
import { AuditAlertRateFilterService } from '../core/audit-alert-rate-filter.service';
import { CommonAlertService } from "../core/common-alert.service";
import { AlertAuditNavigationService } from "./alert-audit-navigation.service";

import {
  sortCalculatedChargesByChargeId,
  getTotalChargeAmountFromInvoice,
  calculateDisputesFromTotalAmount,
  groupDisputesBySpid
} from "../helper/common-alert"

function compare(a, b) {
  const bandA = a.title.toUpperCase();
  const bandB = b.title.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}


@Component({
  selector: 'app-alert-audit',
  templateUrl: './alert-audit.component.html',
  styleUrls: ['./alert-audit.component.scss']
})

export class AlertAuditComponent extends PageListComponent implements OnInit, AfterViewInit, OnDestroy {
  alert;
  public summary: any;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTab: number = 0;
  public query: Query = new Query({orderBy: [['id', 'ASC']], total: 1});
  public rateQuery: Query = new Query({orderBy: [['id', 'ASC']], total: 1});
  public queryInventoryMrc: Query = new Query({orderBy: [['id', 'ASC']]});
  public queryInventoryMrcFilter: Query = new Query({orderBy: [['id', 'ASC']]});
  public queryMissingInventory: Query = new Query();
  public queryMissingInventoryFilter: Query = new Query();
  public queryChargesTotal: Query = new Query();
  public queryDisconnectOrder: Query = new Query();
  public queryDisconnectOrderFilter: Query = new Query();
  public queryRateFilter: Query = new Query();
  public chargesTotal = [];
  public columns: any;
  public missingInvenory = [];
  public filterDisable = false;
  masterColumns = [];
  chargeTotalColumns = [];
  missingInventoryColumns = [];
  delayChargesSelectionChange: boolean = false;
  public sizes = [5, 10, 20];
  public auditNames: any;
  rateAuditTotal = 0;
  systemTotal = 0;

  public entries = [
    {
      value: "System",
      key: "system",
      child: [],
      open: true
    },
    {
      value: "Rate Audits",
      key: "rate",
      child: [],
      open: false
    }
  ];

  public goodEntries = [
    {
      value: "System",
      key: "system",
      child: [],
      open: false
    },
    {
      value: "Rate Audits",
      key: "rate",
      child: [],
      open: false
    }
  ];

  selectColumns = {
    chg_class: 'Charge Class',
    chg_code_1: 'Charge Code 1',
    chg_code_2: 'Charge Code 2',
    chg_code_3: 'Charge Code 3',
    chg_desc_1: 'Charge Description 1',
    chg_desc_2: 'Charge Description 2',
    chg_desc_3: 'Charge Description 3',
    fac_bw: 'Facility Bandwidth'
  }

  rulesDefinition: Array<any> = [];

  @ViewChild('masterGrid', {static: false}) masterGrid: DxDataGridComponent;
  @ViewChild('dataGrid', {static: false}) dataGrid: DxDataGridComponent;
  @ViewChild('chargeGrid', {static: false}) chargeGrid: DxDataGridComponent;
  @ViewChild('missingInventoryGrid', {static: false}) missingInventoryGrid: DxDataGridComponent;
  @ViewChild('detailsRategrid', {static: false}) detailsRategrid: DxDataGridComponent;
  @ViewChild('chargesDataGrid', {static: false}) chargesDataGrid: DxDataGridComponent;
  @ViewChild('spidChargesDataGrid', {static: false}) spidChargesDataGrid: DxDataGridComponent;
  @ViewChildren(DxDataGridComponent) dataGrids: QueryList<DxDataGridComponent>

  activeTabIndex: number = 0;
  firstTabSelect = true;
  MULTIPLE_ALERT = LOOKUP_ENUM.ALERT_TYPE.MULTIPLE_AUDITS;
  public alertId: number;

  readonly PLACEHOLDERS = {
    OWNER_UNASSIGNED: 'Unassigned'
  };

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'common-alert';
  public currentTabIndex = 0;

  navigationModeMini: boolean = false;
  navigationOpened: boolean = true;
  miniNavigationHovered: boolean = false;
  navState: boolean = true;

  selectedAudit: any;

  inventoryMrcAudits: any;
  chargesRowsExpanded: any[] = [];
  public chargeColumns: any;
  disconnectColumns: any;
  disconnectData: any[] = [];

  public GROUP = {
    RATE_AUDIT: 'Rate Audit',
    MRC_INVENTORY: 'Inventory MRC',
    MISSING_INVENTORY: 'Missing Inventory'
  };

  rateAuditRowsExpanded: any[] = [];

  public selectedAlertId = null;

  addDisputeDisabled: boolean = true;
  chargeQuery: ChargeQuery = new ChargeQuery({orderBy: [['id', 'ASC']]});
  public dictionaryQuery: Query = new Query({orderBy: [['id', 'ASC']]});
  public inventoryQuery: Query = new Query({orderBy: [['id', 'ASC']]});

  public selectedCharges: Array<any> = [];
  public selectionActive = false;
  charges: Array<any> = [];
  public DISPUTE_CATEGORY_LOOKUP_MODEL = LOOKUP_MODELS_ENUM.DISPUTE_CATEGORY.modelName;

  constructor(public alertAuditNavigationService: AlertAuditNavigationService,
              public settingsService: UserSettingsService,
              public route: ActivatedRoute,
              public router: Router,
              public auditsService: AuditsService,
              public toastAlertService: AlertService,
              public loaderService: LoaderService,
              public tabService: TabService,
              public alertService: CommonAlertService,
              public alertRateAuditGridService: AlertRateAuditGridService,
              public rateAuditResultService: RateAuditResultService,
              public auditResultService: AuditResultService,
              public alertInventoryMrcChargesGridService: AlertInventoryMrcChargesGridService,
              public alertInventoryMrcGridService: AlertInventoryMrcGridService,
              public rateAuditResultTmpGridService: RateAuditResultTmpGridService,
              public rateAuditRuleService: RateAuditRuleService,
              public alertInventoryChargeTotalGridService: AlertInventoryChargeTotalGridService,
              public alertMissingInventoryDetailsGridService: AlertMissingInventoryDetailsGridService,
              public alertMissingInventorySpidGridService: AlertMissingInventorySpidGridService,
              public alertDisconnectGridService: AlertDisconnectGridService,
              public auditAlertMissingInventoryFilterService: AuditAlertMissingInventoryFilterService,
              public auditAlertInventoryMRCFilterService: AuditAlertInventoryMRCFilterService,
              public auditAlertDisconnectAuditFilterService: AuditAlertDisconnectAuditFilterService,
              public auditAlertRateFilterService: AuditAlertRateFilterService,
              public chargeService: InvoiceChargeService,
              public inventoryService: InventoryService,
              public dictionaryService: DictionaryService,
              public userService: UserService,
              public locationService: LocationService,
              public dialogService: DialogService
  ) {
    super(new PageContext({
      name: 'app.alert.alert-rate-audit',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    this.currentTabIndex = index;

    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
      if (index === 1 && this.firstTabSelect) {
        this.selectFirstAudit();
      }
    }
  }

  public loadTabs() {
    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({key: 1, title: 'Summary'});
    this.tabGroup.addTab({key: 2, title: 'Details'});

    this.tabGroup.activate(1);


    this.tabGroup.onActivate.subscribe((tab) => {
      setTimeout(() => {
        if (this.tabGroup.isActive(2) && this.sider.isActive(this.SECTIONS.FILTER_SECTION_NAME)) {
          this.panelSide.open(this.SECTIONS.FILTER_SECTION_NAME);
          this.tabChanged = !this.tabChanged
        }
      });
    });
  }

  clickFilterButton() {
    this.sider && this.sider.isActive(this.SECTIONS.FILTER_SECTION_NAME) ? this.panelSide.close() : this.panelSide.open();
    this.sider && this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
  }

  refresh() {
    this.selectFirstAudit();
  }

  onPageChange(query: Query, selected) {
    if (selected.key === 'MISSING_INVENTORY') {
      this.loadMissingInventory(selected)
    } else if (selected.key === 'CHARGE_TOTAL') {
      this.loadChargeTotal();
    } else if (selected.key === 'MRC_INVENTORY') {
      this.loadInventoryMRCs();
    } else if (selected.key === 'DISCONNECT_AUDIT') {
      this.loadDisconnectAudits();
    } else if (selected.key === 'RATE_AUDIT') {
      this.loadRateAudits(selected);
    }

  }

  ngOnInit() {
    this.loadTabs();
    this.alert = this.route.snapshot.data.alert;
    this.alertId = this.alert.id;
    this.tabGroup.tabs[1].disabled = this.alert.message === 'No issues found';
    const auditQuery = new Query({
      where: {
        run_id: this.alert.run_id,
        invoice_id: this.alert.invoice_id
      },
      limit: 1000,
    });
    this.auditsService.getAuditHistory(auditQuery)
      .subscribe(result => {
        this.auditNames = []
        if (result && result.items) {
          let entriesView = result.items.map(row => {

            let entry = {
              key: row.audit.key,
              title: row.audit.name,
              audit_id: row.audit_id,
              run_by_user_id: row.run_by_user_id,
              issues_found: row.issues_found
            }
            return entry;
          })
          entriesView.forEach(entry => {
            this.auditNames.push(entry.title)
            if (entry.issues_found) {
              if (entry.key === 'RATE_AUDIT') {
                this.rateAuditTotal += entry.issues_found;
                this.entries[1].child.push(entry);
              } else {
                this.systemTotal += entry.issues_found;
                this.entries[0].child.push(entry);
              }
            } else {
              if (entry.key === 'RATE_AUDIT') {
                this.goodEntries[1].child.push(entry);
              } else {
                this.goodEntries[0].child.push(entry);
              }
            }
          });
          this.entries[0].child.sort(compare);
          this.entries[1].child.sort(compare);
          this.goodEntries[0].child.sort(compare);
          this.goodEntries[1].child.sort(compare);
        }
      });
    this.masterColumns = this.alertRateAuditGridService.columns();
    this._init();
  }

  ngAfterViewInit(): void {
    if (this.masterGrid) {
      this.alertRateAuditGridService.create(this.masterGrid.instance, {
        noDataText: this.alertRateAuditGridService.noDataMessage
      });
    }
    if (this.dataGrid) {
      this.alertInventoryMrcGridService.create(this.dataGrid.instance, {
        noDataText: this.alertInventoryMrcGridService.noDataMessage
      });
    }
    if (this.chargeGrid) {
      this.alertInventoryChargeTotalGridService.create(this.chargeGrid.instance, {
        noDataText: 'No Data'
      });
    }
    if (this.missingInventoryGrid) {
      this.alertMissingInventorySpidGridService.create(this.missingInventoryGrid.instance, {
        noDataText: 'No data'
      });
    }
    if (this.chargesDataGrid) {
      this.alertInventoryMrcChargesGridService.create(this.chargesDataGrid.instance, {
        noDataText: this.alertInventoryMrcChargesGridService.noDataMessage
      });
    }
    if (this.spidChargesDataGrid) {
      this.alertMissingInventoryDetailsGridService.create(this.spidChargesDataGrid.instance, {
        noDataText: 'No data'
      });
    }
    super.ngAfterViewInit();
  }

  selectFirstAudit() {
    if (this.entries[0].child[0]) {
      this.selectedAudit = this.entries[0].child[0];
      this.entries[0].open = true;
    } else if (this.entries[1].child[0]) {
      this.selectedAudit = this.entries[1].child[0];
      this.entries[1].open = true;
    }

    this.selectAudit(this.entries[0].child[0] || this.entries[1].child[0] || {}, true);
    this.firstTabSelect = false;
  }

  selectAudit(entry, flag?) {
    this.selectedCharges = [];
    this.addDisputeDisabled = true;
    if (entry.key === 'CHARGE_TOTAL') {
      this.panelSide.close();
      this.sider.isActive(this.SECTIONS.FILTER_SECTION_NAME) && this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
    }

    if (!flag && entry.audit_id === this.selectedAudit.audit_id) return false;
    this.selectedAudit = entry;
    if (this.selectedAudit) {
      this.filterDisable = false;
      this.loadGridData()
    }
  }

  loadGridData() {
    const gridService = this.getProperGridService();
    if (!gridService)
      return;
    gridService.loadSettings()
      .subscribe((settings) => {
        this.sorting = settings ? settings.sorting : [];
        this.columns = gridService.getColumns(settings ? settings.columns : []);
        this.defaultGridPager = settings ? settings.gridPager : 20;
        this.query.limit = this.defaultGridPager;

        if (this.sorting && this.sorting.length) {
          this.query.orderBy = gridService.getOrderBy(this.sorting);
        }

        if (this.dataGrid && this.dataGrid.instance) {
          this.dataGrid.instance.option('columns', this.columns);
        }

        if (this.selectedAudit.key === 'MISSING_INVENTORY') {
          this.loadMissingInventory(this.selectedAudit)
        } else if (this.selectedAudit.key === 'CHARGE_TOTAL') {
          this.filterDisable = true;
          this.loadChargeTotal();
        } else if (this.selectedAudit.key === 'DISCONNECT_AUDIT') {
          this.loadDisconnectAudits();
        } else if (this.selectedAudit.key === 'MRC_INVENTORY') {
          this.loadInventoryMRCs();
        } else if (this.selectedAudit.key === 'RATE_AUDIT') {
          this.loadRateAudits(this.selectedAudit);
        } else {
          this.filterDisable = true;
        }
      })
  }

  back(): void {
    if (document.referrer.indexOf(window.location.host) >= 0) {
      history.back();
    } else {
      this.router.navigate(['/alert']);
    }
  }

  ngOnDestroy() {
    this.tabGroup.onActivate.unsubscribe();
  }

  generateRuleTitle(ruleDefiniton) {
    let title = `Rule ${ruleDefiniton.rule_id}: `;
    ruleDefiniton.settings.fields.forEach(field => {
      title += `${this.selectColumns[field.name]} ${field.operator.toUpperCase()} ${field.value}. `;
    })
    return title;
  }

  findRuleDefinition(id) {
    return this.rulesDefinition.find(definition => definition.audit_rate_rule_id === id);
  }

  async onRateRuleExpanded(row, manual?: boolean) {
    this.loaderService.displayLoader(true)

    let rule = this.selectedAudit.rules.find(x => (manual ? x.id === row : x.id === row.key));
    if (!rule) return;

    rule.resultQuery = new Query({
      where: {
        rule_id: rule.id,
        run_id: this.alert.run_id,
        invoice_id: this.alert.invoice_id
      },
      orderBy: [["id", "ASC"]],
      limit: rule.resultQuery ? rule.resultQuery.limit : 20
    });

    if (this.queryRateFilter && this.queryRateFilter.where) {
      rule.resultQuery.where = {...rule.resultQuery.where, ...this.queryRateFilter.where};
    }

    this.rateAuditResultService.findAll(rule.resultQuery)
      .subscribe(result => {
        result.items.forEach(res => {
          res.auditKey = 'RATE_AUDIT';
          res.calculated_charges_for_dispute = res.calc_chg_amt;
          res.description = this.getDispDesc();
        })

        Object.assign(rule, {charges: result.items});
        this.loaderService.hideLoader(true)
        rule.resultQuery.total = result.total;

        // add selected charge ids into audit.selectedRows
        const charges = this.selectedCharges.filter(chg => rule.id === chg['rule_id'])
        rule.selectedRows = charges.length ? charges.reduce((acc, item) => ([...acc, item.id]), []) : []

        rule.selectionActive = false;
        this.delayChargesSelectionChange = true;

        setTimeout(() => {
          this.rateAuditRowsExpanded.forEach(rowId => {
            this.selectRowsWithinDetailsGrid(rowId, this.selectedAudit.rules)
          })
        }, 100)
      })

    const foundInExpanded = this.rateAuditRowsExpanded.findIndex(obj => row.key === obj)
    if (!manual && foundInExpanded === -1) {
      this.rateAuditRowsExpanded.push(row.key)
    }
  }

  onRateRuleCollapsed(row) {
    this.rateAuditRowsExpanded.splice(this.rateAuditRowsExpanded.indexOf(row.key), 1)
    this.loaderService.hideLoaderAndManageGrid([this.detailsRategrid]);
  }

  toggleNavigation() {
    this.navigationModeMini = !this.navigationModeMini;
    this.miniNavigationHovered = false;
    this.navState = !this.navigationModeMini;
  }

  navigationMouseOver() {
    setTimeout(() => {
      this.miniNavigationHovered = this.navigationModeMini;
      if (this.navigationModeMini) {
        this.navState = true;
      }
    });
  }

  navigationMouseLeave() {
    setTimeout(() => {
      this.miniNavigationHovered = false;
      if (this.navigationModeMini) {
        this.navState = false;
      }
    });
  }

  update() {
  }

  toggle(entry) {
    entry.open = !entry.open;
  }

  //---------------------------LOAD RATE AUDITS ------------------------------------------------
  loadRateAudits(child) {
    let detailQuery = new Query({
      where: {
        audit_id: child.audit_id
      }
    })

    this.loaderService.displayLoader(true)
    child['rules'] = [];
    this.rateQuery.total = 0;

    this.rateAuditRuleService.getRules(detailQuery)
      .subscribe(rules => {
        if (rules && rules.items) {
          this.rulesDefinition = rules.items;
          let attributesList = this.rulesDefinition[0].settings.fields.map(field => field.name);
          child['detailColumns'] = this.rateAuditResultTmpGridService.generateColumns(attributesList);

          this.detailsRategrid && this.rateAuditResultTmpGridService.create(this.detailsRategrid.instance, {
            noDataText: this.rateAuditResultTmpGridService.noDataMessage,
            showColumnLines: true,
            allowColumnResizing: true,
          })
          this.rateQuery.where = {
            audit_id: child.audit_id,
            run_id: this.alert.run_id,
            invoice_id: this.alert.invoice_id
          }

          this.rateQuery.where = {
            ...this.rateQuery.where,
            ...this.queryRateFilter.where
          }

          this.loaderService.displayLoader(true)
          this.rateAuditResultService.grouping(this.rateQuery)
            .subscribe((result) => {
              if (result && result.items) {
                child['rules'] = result.items.map(rule => {
                  const ruleDefiniton = this.findRuleDefinition(rule.id)
                  rule.name = ruleDefiniton ? this.generateRuleTitle(ruleDefiniton) : 'N/A';
                  return rule;
                });
                this.rateQuery.total = result.total;

                this.rateAuditRowsExpanded.map(rowId => {
                  this.onRateRuleExpanded(rowId, true);
                });
                this.loaderService.hideLoader(true)
              }
            }, () => {
              this.toastAlertService.error('', this.messages.get('ERROR_LOADING_GL_VALIDATION'));
              this.loaderService.hideLoaderAndManageGrid([this.masterGrid]);
            });
        }
      })
  }

  filterRateAudit(query) {
    this.rateQuery.offset = 0;
    this.rateQuery.page = 1;
    this.queryRateFilter = query;
    this.loadRateAudits(this.selectedAudit);
  }

  clearRateAudit() {
    this.rateQuery.offset = 0;
    this.rateQuery.page = 1;
    this.queryRateFilter = new Query;
    this.loadRateAudits(this.selectedAudit);
  }

// ----------------------------- INVENTORY MRC AUDIT ---------------------------------------------

  loadInventoryMRCs(resetPager = false) {
    if (resetPager) {
      const where = {...this.queryInventoryMrc.where}
      const orderBy = {...this.queryInventoryMrc.orderBy}
      this.queryInventoryMrc = new Query({orderBy, where})
      this.resetDataPager()
    }
    // add necessary invoice and charge id range for chargeID filter
    this.queryInventoryMrc.where.invoice_id = this.alert.invoice_id
    this.loaderService.displayLoader(true)

    this.alertService.findInventoryMRCs(this.alertId, this.queryInventoryMrc)
      .subscribe((result) => {
        this.loaderService.hideLoader(true)
        this.inventoryMrcAudits = result.rows;
        this.queryInventoryMrc.total = result.count;
        setTimeout(() => {
          if (this.dataGrid) {
            this.dataGrid.instance.refresh();
            this.chargesRowsExpanded.map(item => {
              this.onRowExpanded(item, true);
            });
          }
        }, 200);
      });
  }

  // inventoryMRC
  async onRowExpanded(row, manual?: boolean) {
    let audit = this.inventoryMrcAudits.find((x) => {
      return manual ? x.id === row : x.id === row.key;
    });
    if (!audit) return;

    if (!audit.resultQuery) {
      audit['resultQuery'] = new Query({
        where: {
          id: this.queryInventoryMrcFilter.where['charge_id'] || this.queryInventoryMrcFilter.where['id'] || null,
          spid: audit.sp_ckt,
          invoice_id: this.alert.invoice_id,
          alert_id: this.alert.id
        },
        orderBy: [["id", "ASC"]],
        limit: audit['resultQuery'] ? audit['resultQuery'].limit : 20
      });
    }

    if (!audit.charges) {
      this.loaderService.displayLoader(true)

      this.alertService.findChargesByMRCandSPID(this.alert.id, audit.resultQuery)
        .subscribe((result) => {
          Object.assign(audit, {charges: result.rows});
          this.loaderService.hideLoader(true)
          audit.charges.forEach(inv => {
            inv.auditKey = 'MRC_INVENTORY';
            inv.calculated_charges_for_dispute = inv.chg_amt;
          });
          audit.resultQuery.total = result.count;

          const charges = this.selectedCharges.filter(chg => audit.id === chg['rule_id'])
          audit.selectedRows = charges.length ? charges.reduce((acc, item) => ([...acc, item.id]), []) : []

          audit.selectionActive = false;
          this.delayChargesSelectionChange = true;
          setTimeout(() => {
            this.chargesRowsExpanded.forEach(rowId => {
              this.selectRowsWithinDetailsGrid(rowId, this.inventoryMrcAudits)
            })
          }, 100)
        })
    }

    if (!manual) {
      this.chargesRowsExpanded.push(row.key);
    }
  }

  onRowCollapsed(row) {
    this.chargesRowsExpanded.splice(this.chargesRowsExpanded.indexOf(row.key), 1);
  }

  getGridService(): GridService {
    return this.alertInventoryMrcGridService;
  }

  getProperGridService(): GridService {
    if (this.selectedAudit.key === 'MISSING_INVENTORY') {
      return this.alertMissingInventorySpidGridService;
    } else if (this.selectedAudit.key === 'CHARGE_TOTAL') {
      return this.alertInventoryChargeTotalGridService;
    } else if (this.selectedAudit.key === 'DISCONNECT_AUDIT') {
      return this.alertDisconnectGridService;
    } else if (this.selectedAudit.key === 'MRC_INVENTORY') {
      return this.alertInventoryMrcGridService;
    } else if (this.selectedAudit.key === 'RATE_AUDIT') {
      return this.alertRateAuditGridService;
    } else {
      return null
    }
  }

  _init() {
    super.prepareList();

    this.chargeColumns = this.alertInventoryMrcChargesGridService.columns();

    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.refresh();
    }

    this.route.params.pipe(
      takeUntil(this.destroy$))
      .subscribe((params: Params) => {
        this.alertId = params['id'];
        this.loadInventoryMRCs();
      });
  }

  filterInventoryMRC(query) {
    this.queryInventoryMrcFilter.where.id = query['where']['charge_id'] || null
    this.queryInventoryMrc = query;
    this.loadInventoryMRCs(true);
  }

  clearInventoryMRC() {
    this.queryInventoryMrcFilter = new Query;
    this.queryInventoryMrc = new Query;
    this.loadInventoryMRCs(true);
  }

// ----------------------------- CHARGE TOTAL AUDIT --------------------------------------------->
  loadChargeTotal() {
    this.chargeTotalColumns = this.alertInventoryChargeTotalGridService.columns();
    this.loaderService.displayLoaderAndManageGrid([this.chargeGrid]);

    this.alertService.findChargeTotal(this.alert.id).subscribe(result => {
      this.loaderService.hideLoaderAndManageGrid([this.chargeGrid]);
      this.chargesTotal = result.items;
      this.queryChargesTotal.total = result.total;
    })
  }

// ------------------------------ MISSING INVENTORY --------------------------------------->

  async loadMissingInventory(entry?) {
    this.delayChargesSelectionChange = true
    this.missingInventoryColumns = this.alertMissingInventoryDetailsGridService.columns()
    this.loaderService.displayLoader(true)

    await this.alertService.findMissingInventory(this.alertId, this.queryMissingInventory)
      .subscribe(result => {
        this.loaderService.hideLoader(true)
        this.missingInvenory = result.items;
        this.queryMissingInventory.total = result.total;
        this.missingInvenory.forEach(inv => {
          inv.auditKey = 'MISSING_INVENTORY';
          inv.description = this.getMissingInvDispDesc(inv.message);
          inv.calculated_charges_for_dispute = inv.charge.chg_amt;
        })
    });
  }

  filterMissingInventory(query) {
    this.queryMissingInventory = query;
    this.queryMissingInventoryFilter.where = {...query.where}
    this.selectionActive = false
    this.loadMissingInventory();
  }

  clearMissingInventory() {
    this.queryMissingInventory = new Query;
    this.queryMissingInventoryFilter.where = {}
    this.selectionActive = false
    this.loadMissingInventory();
  }

  // ------------------------------ DISCONNECT AUDIT --------------------------------------->

  async loadDisconnectAudits() {
    this.disconnectColumns = this.alertDisconnectGridService.columns();
    this.queryDisconnectOrder.where.invoice_id = this.alert.invoice_id;
    this.loaderService.displayLoader(true)

    this.alertService.findDisconnect(this.alertId, this.queryDisconnectOrder)
      .subscribe(result => {
        this.loaderService.hideLoader(true)
        this.disconnectData = result.items;
        this.queryDisconnectOrder.total = result.total;
        this.disconnectData.forEach(inv => {
          inv.auditKey = 'DISCONNECT_AUDIT';
          inv.calculated_charges_for_dispute = inv.calc_amt;
          inv.description = this.getDispDisconnectDesc();
        })

        this.selectRowsWithinGrid(this.chargeGrid)
      });
  }

  filterDisconnectAudit(query) {
    this.queryDisconnectOrder = query;
    this.queryDisconnectOrderFilter.where = {...query.where}
    this.selectionActive = false
    this.loadDisconnectAudits();
  }

  clearDisconnectAudit() {
    this.queryDisconnectOrder = new Query;
    this.queryDisconnectOrderFilter.where = {}
    this.selectionActive = false
    this.loadDisconnectAudits();
  }


  public goToInvoiceDetails() {
    if (this.alert && this.alert.invoice && this.alert.invoice.invoice_id) {
      this.router.navigate(['/invoice', this.alert.invoice.invoice_id, 'show']);
    }
  }

  // ---------------------------- DISPUTE ------------------------------------>

  getDispDesc() {
    const descString = `Charges billed do not equal calculated charges.`;
    return descString;
  }

  getDispDisconnectDesc() {
    const descString = `Incorrect charges billed for in-process disconnect.`;
    return descString;
  }

  getMissingInvDispDesc(message) {
    let descString;
    if (message.includes('does not have related inventory')) {
      descString = 'Inventory item not found for invoice charge.';
    } else {
      const messageList = message.split(`"`);
      const status = messageList[3];
      descString = `Billing detected for item in ${status} state.`
    }
    return descString;
  }

  getDescriptionFromSelectedCharges() {
    let newArray = []
    this.selectedCharges.forEach(i => {
      newArray.push(i.description ? i.description : null);
    })
    return newArray;
  }

  getCalculatedCharges(value) {
    let result = [];

    if (value[0]?.auditKey !== "MRC_INVENTORY") {
      value.sort((a, b) => (a.charge_id > b.charge_id) ? 1 : ((b.charge_id > a.charge_id) ? -1 : 0));
      value.forEach(i => {
        if (i.auditKey === "MISSING_INVENTORY") {
          result.push(0);
        } else {
          result.push(i.calculated_charges_for_dispute);
        }
      })
      return result;
    }

    const totalVarianceTypes = groupDisputesBySpid(value)

    for(let key in totalVarianceTypes) {
      totalVarianceTypes[key].forEach(chargeList => {
        const totalChargeAmt = getTotalChargeAmountFromInvoice([chargeList]);
        result.push(...calculateDisputesFromTotalAmount([chargeList], totalChargeAmt))
      })
    }

    const sortedResult = sortCalculatedChargesByChargeId(result)
    return sortedResult.map(calculatedChargeAmount => calculatedChargeAmount.value)
  }

  addDispute() {
    this.chargeQuery['where'] = {};
    this.chargeQuery.set('id', {
      $in: this.selectedCharges.map(x => {
        return x.auditKey === 'MRC_INVENTORY' ? x.id : x['charge_id'];
      })
    });

    this.chargeQuery.offset = 0;
    this.chargeQuery.page = 1;
    this.chargeQuery.limit = 10000;
    this.chargeQuery.set('invoice_id', this.alert.invoice_id);
    this.chargeService.filters(this.chargeQuery)
      .subscribe(async (result) => {
        this.charges = result.items;
        let invoice = this.alert.invoice;
        let arrayOfCalculatedCharges = this.getCalculatedCharges(this.selectedCharges);
        let dispDesc = this.getDescriptionFromSelectedCharges();

        if (!this.selectedCharges.length || this.addDisputeDisabled) {
          return;
        }

        this.dialogService
          .open(
            DisputeManageDialogComponent,
            {
              charges: this.charges,
              invoice: invoice,
              calculatedCharges: arrayOfCalculatedCharges,
              dispDesc: dispDesc,
              alertId: this.alertId
            },
            {width: '60%'}
          )
          .afterClosed()
          .subscribe(async result => {
            if (result) {
              this.addDisputeDisabled = true;
              this.selectedCharges = [];

              if (this.selectedAudit.key === 'MISSING_INVENTORY') {
                this.missingInventoryGrid.selectedRowKeys = [];
                this.loadMissingInventory()
                this.missingInventoryGrid.instance.refresh(); /// G ///
              } else if (this.selectedAudit.key === 'MRC_INVENTORY') {
                this.chargesDataGrid.selectedRowKeys = [];
                this.loadInventoryMRCs();
              } else if (this.selectedAudit.key === 'DISCONNECT_AUDIT') {
                // this.chargeGrid.instance.refresh();
                this.chargeGrid.selectedRowKeys = [];
                this.loadDisconnectAudits();
              } else if (this.selectedAudit.key === 'RATE_AUDIT') {
                this.masterGrid.instance.refresh();
                this.loadRateAudits(this.selectedAudit)
              }

              this.toastAlertService.success(
                '',
                this.messages.get('DISPUTE_CREATE_SUCCESS')
              );
            }
          });
      })
  }

  showDispute(id: number) {
    this.router.navigate(['/dispute', id, 'show']);
  }

  findIndexInSelectedCharges(chargeId): number {
    for (let i = 0, l = this.selectedCharges.length; i < l; i++) {
      if (this.selectedCharges[i].id === chargeId) {
        return i;
      }
    }
    return -1;
  }

  findIndexInResultCharges(chargeId, rule): number {
    for (let i = 0, l = rule.selectedRows.length; i < l; i++) {
      if (rule.selectedRows[i] === chargeId) {
        return i;
      }
    }
    return -1;
  }

  onChargesSelectionChanged(event, rule?) {
    setTimeout(() => {
      if (!this.delayChargesSelectionChange) {

        this.selectedAlertId = rule?.id || null;
        /** Selected rows */
        if (event.selectedRowKeys.length) {
          event.selectedRowsData.forEach(item => {
            if (rule && rule.variance) {
              item['variance'] = rule.variance;
              item['billed_mrc'] = rule.billed_mrc;
            }
            let index = this.findIndexInSelectedCharges(item.id);
            if (index === -1) {
              if (rule)
                item['rule_id'] = rule.id
              this.selectedCharges.push(item);
            }

            if (rule) {
              let resultIndex = this.findIndexInResultCharges(item.id, rule);
              if (resultIndex === -1 && !rule.selectedRows.includes(item.id)) {
                rule.selectedRows.push(item.id);
              }
            }
          });
        }

        /** Deselected rows */
        if (event.currentDeselectedRowKeys.length) {
          event.currentDeselectedRowKeys.forEach(id => {
            let index = this.findIndexInSelectedCharges(id);
            if (index > -1) {
              this.selectedCharges.splice(index, 1);
            }

            if (rule) {
              let resultIndex = this.findIndexInResultCharges(id, rule);
              if (resultIndex >= 0) {
                rule.selectedRows = [...rule.selectedRows.filter(rowId => id !== rowId)]
              }
            }
          });
        }

        this.delayChargesSelectionChange = false;

        // * Dispute Add enabled/disabled */
        if (this.selectedCharges.length) {
          this.addDisputeDisabled = false;
          this.selectedCharges.forEach(item => {
            if (this.selectedAudit.key === 'MISSING_INVENTORY' && item.charge.charge_dispute) {
              this.addDisputeDisabled = true;
            } else if (this.selectedAudit.key === 'MRC_INVENTORY' && item.charge_dispute_id || (+item.variance) <= 0) {
              this.addDisputeDisabled = true;
            } else if (this.selectedAudit.key === 'DISCONNECT_AUDIT' && item.charge?.charge_dispute) {
              this.addDisputeDisabled = true;
            } else if (this.selectedAudit.key === 'RATE_AUDIT' && item.cost_invoice_charge?.charge_dispute) {
              this.addDisputeDisabled = true;
            }
          });
        } else {
          this.addDisputeDisabled = true;
          this.selectedAlertId = null;
        }
      }
    })
  }

  // Inventory MRC
  loadSelectedAuditResults(rule) {
    if (rule.selectionActive) {
      if (this.queryInventoryMrcFilter.where.id)
        rule.resultQuery.where.id = {...this.queryInventoryMrcFilter.where.id}
      else
        rule.resultQuery.remove('id')
    } else {
      rule.resultQuery.set('id', {
        $in: rule.selectedRows
      });
    }
    rule.resultQuery.offset = 0;
    rule.resultQuery.page = 1;
    rule.selectionActive = !rule.selectionActive;
    this.loadAuditResult(rule.resultQuery, rule)
  }

  // InventoryMRC
  loadAuditResult(query, audit, fromPager?) {
    this.delayChargesSelectionChange = true;

    this.loaderService.displayLoader(true);
    this.alertService.findChargesByMRCandSPID(this.alert.id, query)
      .subscribe((result) => {
        Object.assign(audit, {
          charges: result.rows.map(item => {
            item.auditKey = 'MRC_INVENTORY';
            item.description = 'Charges billed do not equal inventory MRC.';
            return item
          })
        });

        audit.resultQuery.total = result.count;
        this.loaderService.hideLoader(true)

        setTimeout(() => {
          this.selectRowsWithinDetailsGrid(audit.id, this.inventoryMrcAudits)
        }, 100)
      })
  }

  onAuditResultPageChange(query: Query, rule) {
    this.loadAuditResult(query, rule, true);
  }

  // Rate audit
  loadSelectedRuleResults(rule) {
    if (rule.selectionActive) {
      if (this.queryRateFilter.where.charge_id)
        rule.resultQuery.where.charge_id = {...this.queryRateFilter.where.charge_id}
      else
        rule.resultQuery.remove('charge_id')
    } else {
      const chargeIds = this.selectedCharges.filter(chg => rule.selectedRows.includes(chg.id)).reduce((acc, item) => [...acc, item.charge_id], [])
      rule.resultQuery.set('charge_id', {
        $in: chargeIds
      });
    }
    rule.resultQuery.offset = 0;
    rule.resultQuery.page = 1;
    rule.selectionActive = !rule.selectionActive;
    this.loadRuleResult(rule.resultQuery, rule)
  }

  loadRuleResult(query, rule, fromPager?) {
    this.delayChargesSelectionChange = true;
    this.loaderService.displayLoader(true);

    this.rateAuditResultService.findAll(query)
      .subscribe(async result => {
        result.items.forEach(res => {
          res.auditKey = 'RATE_AUDIT';
          res.calculated_charges_for_dispute = res.calc_chg_amt;
          res.description = this.getDispDesc();
        })
        Object.assign(rule, {charges: result.items})

        rule.resultQuery.total = result.total;
        this.loaderService.hideLoader(true);

        setTimeout(() => {
          this.selectRowsWithinDetailsGrid(rule.id, this.selectedAudit.rules)
        }, 100)
      })
  }

  onResultPageChange(query: Query, rule) {
    this.loadRuleResult(query, rule, true);
  }

  onChargesDataGridClick(event) {
    if (!event.target.classList.contains('selection-badge')) {
      this.delayChargesSelectionChange = false;
    }
  }

  selectRowsWithinGrid(grid) {
    const data = grid.instance.selectRows(this.selectedCharges.reduce((acc, item) => ([...acc, item.id]), []), false)
     this.delayChargesSelectionChange = true;
     setTimeout(() => {
       if (grid)
         grid.instance.selectRows(this.selectedCharges.reduce((acc, item) => ([...acc, item.id]), []), false)
     }, 500)
  }

  selectRowsWithinDetailsGrid(ruleId: number, collection: [any]) {
    this.delayChargesSelectionChange = true
    const rule = collection.find(item => item.id === ruleId)

    if (rule && rule.charges) {
      this.dataGrids.forEach(grid => {
        const id = grid['element'].nativeElement.id
        if (id === 'charges-grid-' + rule.id) {
          let results = []
          rule.charges.forEach(chg => {
            if (this.selectedCharges.length && this.selectedCharges.reduce((acc, item) => ([...acc, item.id]), []).includes(chg.id))
              results.push(chg.id)
          })
          if (results.length)
            grid.instance.selectRows(results, false);
        }
      })
    }
  }

  loadSelected() {
    if (this.selectedAudit.key === "MISSING_INVENTORY") {
      this.queryMissingInventory.where = {}
      if (this.selectionActive) {
        if (this.queryMissingInventoryFilter.where)
          this.queryMissingInventory.where = {...this.queryMissingInventoryFilter.where}
      } else {
        this.queryMissingInventory.set('id', {
          $in: this.selectedCharges.reduce((acc, item) => [...acc, item.id], [])
        })
      }
      this.queryMissingInventory.offset = 0;
      this.queryMissingInventory.page = 1;
      this.selectionActive = !this.selectionActive
      this.loadMissingInventory();

      // Disconnect
    } else if (this.selectedAudit.key === "DISCONNECT_AUDIT") {
      this.queryDisconnectOrder.where = {}
      if (this.selectionActive) {
        if (this.queryDisconnectOrderFilter.where)
          this.queryDisconnectOrder.where = {...this.queryDisconnectOrderFilter.where}
      } else {
        this.queryDisconnectOrder.set('id', {
          $in: this.selectedCharges.reduce((acc, item) => [...acc, item.id], [])
        })
      }
      this.queryDisconnectOrder.offset = 0;
      this.queryDisconnectOrder.page = 1;
      this.selectionActive = !this.selectionActive
      this.loadDisconnectAudits();
    }
  }
}
