import { Injectable } from '@angular/core';

import { GridService } from '../../shared/grid/grid.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { GLStringService } from '../../gl-string/core/gl-string.service';

@Injectable()
export class GlRuleExecutionGLStringsGridService extends GridService {


  public defaultColumns: Array<any> = [
    {
      caption: 'Full GL String',
      dataField: 'full_string_formatted',
      visible: true,
      width: 200
    },
    {
      caption: 'GL Code Type',
      dataField: 'gl_code_type',
      visible: true,
      width: 120
    },
    {
      caption: 'Date Added',
      dataField: 'date_added',
      dataType: 'datetime',
      cellTemplate: 'dateTimeTemplate',
      visible: true,
      width: 100
    },
    {
      caption: 'Created By',
      dataField: 'full_name',
      visible: true,
      width: 120
    },
    {
      alignment: 'left',
      caption: 'Status',
      dataField: 'status',
      cellTemplate: 'statusTemplate',
      width: 100,
      visible: true,
      transformValue: {
        true: 'Active',
        false: 'Inactive'
      }
    },
    {
      alignment: 'left',
      cellTemplate: 'chgAmtCurrencyTemplate',
      caption: 'Charge Amount',
      dataType: 'currency',
      dataField: 'sum',
      visible: true,
      width: 100
    }, {
      caption: 'Currency',
      dataField: 'currency',
      width: 100
    }];

  public originalDefaultColumns = [];

  public flatColumns(columns) {
    if (this.originalDefaultColumns.length === 0) {
      this.originalDefaultColumns = this.defaultColumns;
    }
    this.defaultColumns = columns;
  }

  public restoreColumns() {
    this.defaultColumns = this.originalDefaultColumns;
  }

  constructor(public userSettings: UserSettingsService,
    public glStringService: GLStringService) {
    super('app.cost.gl-strings-charges-grid', userSettings);
  }
}
