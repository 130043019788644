import { Component, OnInit, Input } from "@angular/core";
import { QuoteService } from "app/quoting/core/quote-service.model";

@Component({
  selector: "ca-quoting-service-sneak-peek",
  templateUrl: "quoting-service-sneak-peek.component.html"
})
export class QuotingServiceSneakPeekComponent implements OnInit {
  @Input("quoteService") quoteService: QuoteService;
  constructor() {}

  ngOnInit() {}
}
