import { Component, OnInit } from '@angular/core';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../../../shared/alert/alert.service';
import { QuotingService } from '../../core/quoting.service';
import { FormBuilder, Validators } from '@angular/forms';
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";

@Component({
  selector: 'app-confirm-quote-dialog',
  templateUrl: './confirm-quote-dialog.component.html',
  styleUrls: ['./confirm-quote-dialog.component.css']
})
export class ConfirmQuoteDialogComponent extends PageManageDialogComponent implements OnInit {

  formTitle: string;
  offer: any;
  service: any;
  term: any;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "quote";

  constructor(
    public alert: AlertService,
    public quotingService: QuotingService,
    public dialogService: DialogService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmQuoteDialogComponent>
  ) {
    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.formTitle = 'Select Quote';

    this.form = this.formBuilder.group({
      term: [this.term, Validators.required]
    });
  }

  onSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (valid) {
      this.toggleDialogButtons();
      this.quotingService.confirmQuote(this.service.quote_id, this.service.id, this.offer.id, value.term)
        .subscribe(
        (result) => {
          this.closeDialog(result, true);
        },
        (error) => {
          this.alert.error('', this.messages.get('CLOSE_ERROR'));
        }
        );
    } else {
      this.toggleDialogButtons(false);
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }
}
