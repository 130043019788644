import { Injectable } from '@angular/core';
import {FilterService} from "../../core/filter/filter.service";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {inventoryCodingFilterContext} from "./inventory-coding-filter.context";

@Injectable()
export class InventoryCodingFilterService extends FilterService{

  constructor(public userSettingsService: UserSettingsService) {
    super("app.gl.gl-inventory-coding-filter", inventoryCodingFilterContext, userSettingsService);
  }

}
