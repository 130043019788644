import {of as observableOf, Observable, forkJoin} from 'rxjs';

import {debounceTime, map, takeUntil} from 'rxjs/operators';
import {CustomValidators} from 'ng2-validation/dist';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {path} from 'ramda';

import {LOOKUP_MODELS_ENUM} from '../../../dictionary/core/lookup-models.enum';
import {LOOKUP_ENUM} from '../../../dictionary/core/lookup.enum';
import {PageManageDialogComponent} from '../../../core/page-manage-dialog.component';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {Site} from '../../core/site';
import {SiteService} from '../../core/site.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {Address} from '../../../address/core/address.model';
import {BuildingService} from '../../../building/core/building.service';
import {IMessagesResourceService, ResourcesService} from '../../../core/resources/resources.service';
import {DataLockService} from '../../../core/data-lock/data-lock.service';
import {EntityEditContext} from '../../../shared/entity-lock/entity-edit-context';
import {EntityLockData} from '../../../shared/entity-lock/entity-lock-data';
import {PermissionService} from '../../../permissions/core/permission.service';
import {flatMap} from "rxjs/internal/operators";
import {ConfigService} from "../../../core/config/config.service";

@Component({
  selector: 'app-site-manage-dialog',
  templateUrl: './site-manage-dialog.component.html',
  styleUrls: ['./site-manage-dialog.component.css']
})
export class SiteManageDialogComponent extends PageManageDialogComponent implements OnInit, OnDestroy {
  messages: IMessagesResourceService;
  messagesCore: IMessagesResourceService;

  readonly MESSAGES_MODULE: string = 'site';
  readonly MESSAGES_MODULE_CORE: string = 'core';

  readonly SITE_OWNER_CATEGORY_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.SITE_OWNER_CATEGORY.modelName;
  readonly SITE_TYPES_ENUM = LOOKUP_ENUM.SITE_TYPES_ENUM;
  readonly NO_BUILDING_SELECTED = 'No building selected';

  site: any;
  buildingAddress: Address;
  siteType: number;

  deleteSitePermission = false;

  // Custom fields
  fields: Array<any> = [];
  categories: any = [];
  showCategory: boolean = false;

  readonly custom_values_config_key = 'appdata_site';

  constructor(public formBuilder: FormBuilder,
              public alert: AlertService,
              public siteService: SiteService,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<SiteManageDialogComponent>,
              public buildingService: BuildingService,
              private permissionService: PermissionService,
              public configService: ConfigService,
              private cd: ChangeDetectorRef,
              public dataLockService?: DataLockService
  ) {
    super(dialogService, dialogRef, dataLockService);
    this.site = (this.site) ? this.site : new Site();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.messagesCore = ResourcesService.messages(this.MESSAGES_MODULE_CORE);
  }

  ngOnInit() {
    this.loadPermissions().subscribe(() => {
    });
    if (this.site && this.site.id) {
      this.isUpdate = true;
      this.buildingAddress = this.site.building.address;
      this.siteType = this.site.type.site_type_id;
    }

    this.createForm(this.site);
    this.formTitle = this.isUpdate ? 'Edit Site' : 'Create Site';

    // Load custom fields
    this.configService.loadCustomFields()
      .pipe(takeUntil(this.destroy$))
      .subscribe(config => {
        this.fields = this.configService.getCustomFieldsFromConfig(config, this.custom_values_config_key);

        let fieldsWithCategory = [];
        let custom_values = this.site.custom_values && this.isUpdate ? this.site.custom_values : {};
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].category && this.fields[i].category.length > 0)
            fieldsWithCategory = [...fieldsWithCategory, this.fields[i].category];

          if (!this.categories.includes(this.fields[i].category))
            this.categories.push(this.fields[i].category);

          this.fields[i].value = custom_values[this.fields[i].field_name];
          this.form.addControl(this.fields[i].field_name, new FormControl({
            value: this.fields[i].value,
            disabled: this.viewOnly
          }));

        }

        if (fieldsWithCategory.length === this.fields.length)
          this.showCategory = true;

        this.cd.detectChanges();
      });

    this.afterInit();
  }

  onSubmit(form) {
    if (form.valid) {
      const siteData = form.getRawValue();
      siteData.site_id = siteData?.site_id?.trim();

      if (this.site.id) {
        this.updateSite(siteData);
      } else {
        this.createSite(siteData);
      }
    } else {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }

  init() {
    if (this.site && this.site.id) {
      return this.siteService.findByIdForEdit(this.site.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .pipe(flatMap((site) => {
          this.site = site;
          return observableOf(site);
        }));
    }

    return observableOf(this.site)
  }

  createSite(site: Site) {
    this.toggleDialogButtons();
    site.custom_values = this.configService.getCustomFieldValue(this.fields, site);

    this.siteService.create(site)
      .subscribe(
        (result) => {
          this.closeDialog(result, true);
        },
        (error) => {
          this.toggleDialogButtons(false);
          const message = (error.error && error.error['message'] == 'Duplicate error') ? 'DUPLICATE_SITE' : 'CREATE_ERROR';
          this.alert.error('', this.messages.get(message));
        }
      );
  }

  updateSite(site: Site) {
    this.toggleDialogButtons();
    site.custom_values = this.configService.getCustomFieldValue(this.fields, site);

    this.siteService.update(this.site.id, site)
      .subscribe(
        (result) => {
          this.closeDialog(result, true);
        },
        (error) => {
          this.toggleDialogButtons(false);
          const message = (error.error && error.error['message'] == 'Duplicate error') ? 'DUPLICATE_SITE' : 'UPDATE_ERROR';
          this.alert.error('', this.messages.get(message));
        }
      );
  }

  deleteSite(event) {
    if (this.isUpdate && event) {
      this.toggleDialogButtons();
      this.siteService.delete(this.site.id)
        .subscribe((deleteResult) => {
          if (deleteResult) {
            this.dialogRef.close({deleted: true});
          }
        }, (err) => {
          this.toggleDialogButtons(false);
          if (err.data === 'SequelizeForeignKeyConstraintError') {
            setTimeout(() => {
              this.alert.error('', this.messages.get('CONSTRAIN_DELETE_ERROR'));
            }, 250);
          } else {
            setTimeout(() => {
              this.alert.error('', this.messages.get('DELETE_ERROR'));
            }, 250);
          }
        });
    }
  }

  createForm(site) {
    this.form = this.formBuilder.group({
      site_id: new FormControl(site.site_id, Validators.required),
      parent_id: new FormControl(site.parent_id),
      vendor_id: new FormControl(site.vendor_id),
      customer_id: new FormControl(site.customer_id),
      building_id: new FormControl(site.building_id, Validators.compose([Validators.required, CustomValidators.number])),
      floor: new FormControl(site.floor, CustomValidators.range([1, 200])),
      room: new FormControl(site.room),
      site_owner_category: new FormControl(site.site_owner_category)
    });

    this.form.controls['building_id'].valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(250),)
      .subscribe((value) => {
          if (value) {
            this.buildingService.findById(value)
              .subscribe((result) => {
                this.buildingAddress = result.address;
              })
          } else {
            this.buildingAddress = null;
          }
        }
      );

    this.initViewOnlyForm(this.site.id, 'View Site', 'Edit Site', this.dataLockService.DATA_LOCK_TYPES.SITE);
  }

  cancel() {
    this.siteService.cancelEdit();
    this.closeDialog();
  }

  onOwnerChanged(event) {
    this.siteType = event.site_type_id;

    if (event.site_type_id === this.SITE_TYPES_ENUM.CUSTOMER) {
      this.form.controls['vendor_id'].setValue(null);
    } else if (event.site_type_id === this.SITE_TYPES_ENUM.VENDOR) {
      this.form.controls['customer_id'].setValue(null);
    } else {
      this.form.controls['vendor_id'].setValue(null);
      this.form.controls['customer_id'].setValue(null);
    }
  }

  editForm() {
    this.siteService.findByIdForEdit(this.site.id).subscribe((result: EntityLockData) => {
      if (result['$lock'] && result['$lock']['status'] === 403) {
        this.alert.error('', `${this.messagesCore.get('DATA_LOCKED')}${result['$lock']['user']}`)
      } else {
        this.form.enable();
        this.siteType = <number>path(['type', 'site_type_id'])(this.site);
        this.viewOnly = false;
        this.formTitle = this.editTitle;

        let vendorField = this.form.get('vendor_id');
        let customerField = this.form.get('customer_id');
        if (this.siteType !== LOOKUP_ENUM.SITE_TYPES_ENUM.VENDOR) {
          vendorField.disable();
        }
        if (this.siteType !== LOOKUP_ENUM.SITE_TYPES_ENUM.CUSTOMER) {
          customerField.disable();
        }
      }
    })
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadPermissions() {
    const deleteSite$ = this.permissionService.isAllowed("sites", "DELETE");
    return forkJoin(
      deleteSite$
    ).pipe(map(([deleteSite]) => {
      this.deleteSitePermission = deleteSite;
    }));
  }
}
