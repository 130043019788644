import {Component, OnInit} from '@angular/core';
import {InvoiceFacepage} from "../../core/invoice-facepage";
import { MatDialogRef } from "@angular/material/dialog";
import {FormGroup, FormBuilder} from "@angular/forms";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";
import {INVOICE_STATUS_ENUM} from "../../core/invoice-status.enum";
import {InvoiceBalanceErrorDialogComponent} from "../invoice-balance-error-dialog/invoice-balance-error-dialog.component";
import {DialogService} from "../../../shared/dialog/dialog.service";
import Query from "../../../core/query/query";
import {InvoiceQuery} from "../../core/invoice.query";
import {AlertService} from "../../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";

@Component({
  selector: 'app-invoice-approve-dialog',
  templateUrl: './invoice-approve-dialog.component.html',
})
export class InvoiceApproveDialogComponent implements OnInit {

  invoiceApproveForm: FormGroup;
  invoices: Array<InvoiceFacepage>;
  invoiceNotInBalance: string;
  isInvoicesOnHold: boolean = true;
  public messages: IMessagesResourceService;
  public readonly MESSAGES_MODULE: string = 'invoice';


  constructor(public dialogRef: MatDialogRef<InvoiceApproveDialogComponent>,
              public formBuilder: FormBuilder,
              public dialog: DialogService,
              public alertService: AlertService,
              public invoiceService: InvoiceFacepageService) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.invoiceApproveForm = this.formBuilder.group({});
    let ids = this.invoices.map(item => item.invoice_id);
    this.isInBalance(ids);
  }

  close() {
    this.dialogRef.close(null);
  }

  onSubmit() {
    if (this.invoices && this.invoices.length) {

      let invoiceIds = this.invoices.map(i => i.invoice_id);

      let newQuery: InvoiceQuery = new InvoiceQuery();
      newQuery.set('id', {
        $in: this.invoices.map((x) => {
          return x.id;
        })
      });

      this.invoiceService.isInvoiceOnHold(newQuery)
        .subscribe(res => {
          let newArray = []
          this.isInvoicesOnHold = res.filter(invoice => {
            if(invoice.account.on_hold){
              newArray.push(invoice.invoice_id)
              return invoice
            }
          }).length > 0;

          if(this.isInvoicesOnHold){
            this.alertService.error('', `${this.messages.get('INVOICE_ON_HOLD_MESSAGE')}${newArray}`)
            this.dialogRef.close({success: false});
          } else {
            if (!this.invoiceNotInBalance) {
              this.invoiceService.approveMultiple(invoiceIds)
                .subscribe((data) => {
                  if (data?.rows?.length === this.invoices.length) {
                    this.dialogRef.close({success: true});
                  }
                }, (err) => {
                  console.log('invoiceService.update error: ', err);
                });
            } else {
              this.dialog.open(InvoiceBalanceErrorDialogComponent,
                {
                  inv_num: this.invoiceNotInBalance
                });
            }
          }
        })
    }
  }

  isInBalance(invoiceIds) {
    let query = new Query({where: {invoice_id: {$in: invoiceIds}}})
    this.invoiceService.getNonBalancedInvoices(query)
      .subscribe(res => {
        this.invoiceNotInBalance = res && res['0'] ? res['0'] : null;
      })
  }
}
