import {EventEmitter, Injectable, Output} from '@angular/core';
import { BaseService } from '../../core/base.service';
import { Contract } from './contract';
import { Restangular } from 'ngx-restangular';

import {  HttpParams } from '@angular/common/http';


import { environment } from '../../../environments/environment';
import Auth from "../../shared/user-session/auth";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {EntityEditContext} from "../../shared/entity-lock/entity-edit-context";
import Query from 'app/core/query/query';
import {HttpClient} from "@angular/common/http";
import { ApiService } from 'app/core/api';
import {map} from "rxjs/operators";

@Injectable()
export class ContractService extends BaseService<Contract> {
  section: string = 'section';
  schedule: string = 'schedule';
  document: string = 'document';

  @Output() toolbarAction: EventEmitter<any> = new EventEmitter<{
    action: any,
    params: any
  }>();

  constructor(public restangular: Restangular,
              public http: HttpClient,
              private entityLock: EntityLockService,
              private apiService: ApiService) {
    super('contract', restangular, entityLock);
  }

  findOnlyContracts(query?: Query): any {
    return this.apiService.get([this.name, 'findOnlyContracts'], query);
  }

  public findAllSections(contractId, query) {
    return this.service().one(this.name, contractId).one('sections').customGET(null, query);
  }

  public findOneContractByVendorId(contractId) {
    return this.service().one(this.name, contractId).one('findOneContractByVendorId').customGET(null);
  }

  public findAllSectionsByInstance(contractId, instanceId, query?) {
    return this.service().one(this.name, contractId).one('sections', instanceId).customGET(null, query);
  }

  public findScheduleSeriesById(id) {
    return this.apiService.get([this.name, 'schedule_series', id]);
  }

  public findScheduleCommitmentTypeById(id) {
    return this.apiService.get([this.name, 'schedule_commitment_type', id]);
  }

  public findAllSchedulesByShell(contractId, query?) {
    return this.service().one(this.name, contractId).one('schedules').customGET(null, query);
  }

  public findAllScheduleTermCondition(contractId, query?) {
    return this.apiService.get([this.name, contractId, 'schedules-term-condition'], query);
  }

  public findAllScheduleCommitment(contractId, query?) {
    return this.apiService.get([this.name, contractId, 'schedules-commitment'], query);
  }

  findSectionForEdit(id: number, contractSectionId: number) {
    return this.service()
      .one(this.name, id)
      .one(this.section, contractSectionId)
      .one('edit', null)
      .get()
  }

  findAllByVendor(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    // return this.service().one(this.name, 'findAllByVendor').customGET(null, this.toFilter(transformedQuery));
    return this.apiService.get([this.name, 'findAllByVendor'], transformedQuery);
  }

  public createSection(contractId, item) {
    return this.service().one(this.name, contractId).one(this.section).customPOST(item);
  }

  public updateSection(contractId, contractSectionId, item) {
    return this.service().one(this.name, contractId).one(this.section, contractSectionId).customPUT(item);
  }

  deleteSection(contractId, contractSectionId) {
    return this.service().one(this.name, contractId).one(this.section, contractSectionId).remove();
  }

  uploadDocument(id: number, files) {
    return this.service().one(this.name, id).all('document').post(files);
  }

  downloadDocument(id: number,contractID,folderName) {
    let cookie = Auth.getSession();
    let params: HttpParams = new HttpParams();
    params.set('authorization', cookie.token);

    let endPoint: string = [environment.API_URL, this.name, '/', id.toString(), '/document'].join('');

    try {
      return this.http.get(endPoint, {
          params: {
            contractID, folderName
          },
          responseType: 'blob',
          headers: {
            authorization: `Bearer ${cookie.token}`
          }
        }
      )
        // .pipe(map(this.extractPdfBlob))
    } catch(error) {
      console.log(error,'ERROR')
      // this.handleError(error)
    }
  }

  deleteDocument(id: number, documentId: number) {
    return this.service().one(this.name, id).all('document').remove();
  }

  public createSchedule(contractId, item) {
    return this.service().one(this.name, contractId).one(this.schedule).customPOST(item);
  }
  public updateSchedule(contractId, item) {
    return this.service().one(this.name, contractId).one(this.schedule).customPUT(item);
  }

  deleteSchedule(id) {
    return this.apiService.delete([this.name,'deleteSchedule',id])
  }

  findSchedules(query?: Query): any {
    return this.apiService.get([this.name, 'schedules'], query);
  }

  findSchedule(id): any {
    return this.apiService.get([this.name, 'schedule', id]);
  }
  findScheduleById(id,query?: Query): any {
    return this.apiService.get([this.name, 'schedule',id]);
  }
  findRateById(id,query?: Query): any {
    return this.apiService.get([this.name, 'rates',id]);
  }
  public createDocument(contractId,document){
    return this.service().one(this.name,contractId).one(this.document).customPOST(document)
  }
  public findDocuments(contractId, query?) {
    return this.service().one(this.name, contractId).one('documents').customGET(null, this.toFilter(query));
  }
  deleteContractDocument(id: number) {
    return this.service().one(this.name, id).all('documents').remove();
  }
  public findDuplicateDocument(query?) {
    return this.service().one(this.name).one('duplicateDocuments').customGET(null,this.toFilter(query));
  }

  findAllRates(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.apiService.get([this.name, 'rates'], transformedQuery);
  }

  findAllAuditsRates(query?: Query): any {
    query.limit = 10000;
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all('audit').one('ratesAudits').customGET(null, this.toFilter(transformedQuery));
  }

  saveRates(contractId, dataToSave): any {
    return this.service().one(this.name, contractId).all('saveRates').customPOST(dataToSave);
  }

  deleteRates(rateIds){
    return this.service().all(this.name).all('deleteRates').remove({rateIds});
  }
  createTermsAndConditions(query?: Query):any {
    return this.apiService.post([this.name, 'termAndCondition'], query);
  }

  findAllTermsAndConditions(query?: Query): any {
    return this.apiService.get([this.name, 'termsAndConditions'], query);
  }

  findTCForEdit(id: number): any {
    return this.apiService.get([this.name,id,'termAndCondition'])
  }

  updateTermsAndConditions(id, value): any {
    return this.apiService.put([this.name,'updateTC', id],value)
  }

  deleteTC(id): any {
    return this.apiService.delete([this.name,'deleteTC',id])
  }

  createSvcOrderTermsAndConditions(query?: Query): any {
    return this.apiService.post([this.name, 'createSvcOrderTC'], query);
  }

  findAllSvcOrderTermsAndConditions(query?: Query): any {
    return this.apiService.get([this.name, 'findAllSvcOrderTC'], query);
  }
  findSvcOrderTCForEdit(id: number): any{
    return this.apiService.get([this.name,id,'svcOrderTCForEdit'])
  }
  updateSvcOrderTermsAndConditions(id, value){
    return this.apiService.put([this.name,'updateSvcOrderTC', id],value)
  }
  deleteSvcOrderTC(id): any {
    return this.apiService.delete([this.name,'deleteSvcOrderTC',id])
  }

  isScheduleContainsAudit(scheduleId){
    return this.apiService.get([this.name,scheduleId,'isScheduleContainsAudit'])
  }

 }
