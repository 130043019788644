import {Injectable} from "@angular/core";
import {BaseService} from "../../core/base.service";
import Query from "../../core/query/query";
import {Quote} from './quote';
import {Restangular} from "ngx-restangular";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class QuotingReportService extends BaseService<Quote> {
  constructor(public restangular: Restangular,
              public http: HttpClient) {
    super('quotes', restangular);
  }

  fetchRequestsAnalyze(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().all(this.name).all('offers/analyze').customGET(null, this.toFilter(transformedQuery));
  }

  findServiceById(quoteId: number, serviceId: number) {
    return this.service().one(this.name, quoteId).one('service', serviceId).get();
  }

  generateChartPoint(item: any, term: number, type: string) {
    let data = {
      "id": item.id,
      "term": `${term === 0 ? 'MTM' : term + ' year'}`,
      "tagData": {
        "vendor": item.vendor.name,
        "service_type": item.quoteService.quote_service_type ? item.quoteService.quote_service_type.value : 'N/A',
        "bandwidth": item.quoteService.bandwidth,
        "quote_method": item.quote_type ? item.quote_type.value : 'N/A',
        "quote_service_offer_id": item.quote_service_offer_id,
        "type": type,
        "quote_service_id": item.quoteService.quote_service_id,
        "term": `${term} year`,
      },
      "received_date": item.received_date,
      "type": type
    };
    data["mrc"] =
      term === 1 ? item.one_year_mrc :
      term === 2 ? item.two_year_mrc :
      term === 3 ? item.three_year_mrc :
      term === 5 ? item.five_year_mrc :
      term === 7 ? item.seven_year_mrc : item.mtm_mrc;
    return data;
  }

  chartOptions(chartPalette, _currencyPipe) {
    return {
      commonSeriesSettings: {
        type: "scatter",
        label: {
          visible: false,
          font: {
            size: 11
          },
          connector: {
            visible: true
          }
        },
        ignoreEmptyPoints: true,
        tagField: 'tagData',
        argumentField: 'received_date',
        valueField: 'mrc'
      },
      onSeriesClick: function (e) {
        var series = e.target;
        series.isVisible() ? series.hide() : series.show();
      },
      customizePoint: function () {
        if (this.tag.type === 'current') {
          return {
            symbol: "triangleUp"
          };
        } else {
          return {
            symbol: "circle"
          };
        }
      },
      seriesTemplate: {
        nameField: "term"
      },
      palette: chartPalette,
      argumentAxis: {
        title: 'Received Date',
        grid: {
          visible: false
        },
        minorGrid: {
          visible: false
        },
        argumentType: 'datetime',
        label: {
          format: {
            type: 'shortDate'
          },
          alignment: 'center'
        },
        //tickInterval: 'month'
      },
      valueAxis: {
        title: 'Quote Amount',
        valueType: 'numeric',
        label: {
          format: {
            type: 'currency',
            precision: 2
          }
        }
      },
      legend: {
        horizontalAlignment: 'center',
        verticalAlignment: 'top',
        orientation: 'horizontal',
        position: 'outside',
        border: {
          visible: true
        }
      },
      tooltip: {
        enabled: true,
        location: 'edge',
        zIndex: 10000,
        customizeTooltip: function (arg) {
          let tag = arg.point.tag;
          return {
            text: `${tag.vendor} <br />
            ${tag.term} / ${arg.originalValue ? _currencyPipe.transform(arg.originalValue, "USD", 'symbol-narrow', "1.2-2") : 'N/A'}<br />
            ${tag.service_type} / ${tag.bandwidth || 'N/A'} / ${tag.quote_method}<br />
                   ${tag.quote_service_id} ${tag.quote_service_offer_id}`
          };
        }
      },
      commonPaneSettings: {
        border: {
          visible: false
        }
      }
    }
  };

  csvMap() {
    return [
      {
        value: 'type',
        label: 'Type',
        type: 'string'
      },
      {
        value: 'quote_service_offer_id',
        label: 'ID',
        type: 'string'
      },
      {
        value: 'quoteService.quote_service_id',
        label: 'Quote Service ID',
        type: 'string'
      },
      {
        value: 'vendor.name',
        label: 'Vendor',
        type: 'string'
      },
      {
        value: 'quoteService.quote_service_type.value',
        label: 'Service Type',
        type: 'string'
      },
      {
        value: 'quoteService.bandwidth',
        label: 'Service Bandwidth',
        type: 'string'
      },
      {
        value: 'quote_type.value',
        label: 'Quote Method',
        type: 'string'
      },
      {
        value: 'one_year_mrc',
        label: '1 Year Term MRC',
        type: 'currency'
      },
      {
        value: 'two_year_mrc',
        label: '2 Year Term MRC',
        type: 'currency'
      },
      {
        value: 'three_year_mrc',
        label: '3 Year Term MRC',
        type: 'currency'
      },
      {
        value: 'five_year_mrc',
        label: '5 Year Term MRC',
        type: 'currency'
      },
      {
        value: 'seven_year_mrc',
        label: '7 Year Term MRC',
        type: 'currency'
      },
      {
        value: 'mtm_mrc',
        label: 'MTM Term MRC',
        type: 'currency'
      },
      {
        value: 'network_hub.hub_name',
        label: 'Hub location',
        type: 'string'
      },
      {
        value: 'sent_date',
        label: 'Sent date',
        type: 'date'
      },
      {
        value: 'received_date',
        label: 'Received date',
        type: 'date'
      },
      {
        value: 'quoteService.quote.close_date',
        label: 'Close date',
        type: 'date'
      }
    ];
  }
}
