export const contractInventoryFilterContext = {
  filters: [
    {
      label: "SPID",
      field: "unique_id",
      type: 'string',
      index: 1,
    },
    {
      label: "Install Date",
      field: "install_date",
      index: 2,
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
    },
    {
      label: "Site A (Carrier Location)",
      field: "siteA.site_id",
      index: 3,
      type: 'lookup',
      lookupProviderName: 'site',
      visible: true,
      secondRowField: 'siteA.building.address.search_address'
    },
    {
      label: "Site Z (Customer Location)",
      field: "siteZ.site_id",
      type: 'lookup',
      lookupProviderName: 'site',
      index: 4,
      visible: true,
      secondRowField: 'siteZ.building.address.search_address'
    },
    {
      field: 'inventory_status.key',
      label: 'Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'inventoryStatus',
      index: 5,
      visible: true
    },
    {
      label: "BAN",
      field: "ban",
      type: "lookup",
      lookupProviderName: 'account',
      index: 6
    },
    {
      label: 'Svc Type/Technology',
      field: 'technology.value',
      type: 'lookup',
      lookupProviderName: 'inventoryTechnology',
      index: 7
    },
    {
      label: 'Service Type',
      field: 'inventory_ckt_type.value',
      type: 'lookup',
      lookupProviderName: 'serviceType',
      index: 8
    },
    {
      label: 'Service Bandwidth',
      field: 'circuit_bandwidth.value',
      type: 'lookup',
      lookupProviderName: 'serviceBandwidth',
      index: 9
    },
    {
      label: 'Disconnect Date',
      field: 'disc_date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 10
    },
    {
      label: 'Exp Date',
      field: 'exp_date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 11
    },
    {
      label: 'Term',
      field: 'term',
      type: 'number',
      index: 12,
    },
    {
      label: 'MRC',
      field: 'est_mrc',
      type: 'negativeNumber',
      index: 13,
    },
    {
      label: 'NRC',
      field: 'est_nrc',
      type: 'negativeNumber',
      index: 14
    }
  ]
};
