<form [formGroup]="form$ | async" class="ca-form">
<mat-toolbar mat-scroll-shrink class="ca-second-level-toolbar" style="height: 60px !important; padding-top: 8px;">
    <div class="flex-item" fxFlex="0 0 159px" style="margin-bottom: -15px;">

        <ca-vendor-picker
          (onSelectionChange)="handleVendorChange()"
          hiDensityInput
          formControlName="vendor_id"
          placeholder="Vendor"
          [pickerForm]="form$ | async"
          [required]="true"
          customMethod="findVendorsWithActiveBans"
          [initialItemId]="selectedVendor"
          [searchEnabled]="true"
          [clearEnabled]="true"
          class="gl-coding-picker"
          (onClear)="onClearVendor()">
        </ca-vendor-picker>
    </div>

    <div class="flex-item" fxFlex="0 0 159px" style="margin-left: 10px; margin-bottom: -15px;">
        <ca-account-picker
          hiDensityInput
          formControlName="ban"
          [required]="true"
          [vendorId]="selectedVendor"
          placeholder="BAN"
          class="gl-coding-picker"
          [searchEnabled]="true"
          [clearEnabled]="true"
          customMethod="findActiveAccountsWithInvoices"
          [pickerForm]="form$ | async"
          [disabled]="banDisabled$ | async"
          [initialItemId]="selectedBAN"
          (onClear)="onClearAccount()">
        </ca-account-picker>
    </div>
</mat-toolbar>
</form>

<mat-sidenav-container style="height: 100%;" class="app-content-list grid-container">

  <dx-data-grid #grid
                (onRowClick)="onSpdidsGridRowClick($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onCellClick)="onSpidCellClick($event)"
                [columns]="columns" [width]="'100%'" [height]="'100%'" [dataSource]="spids">

    <div *dxTemplate="let d of 'statusCellTemplate'">
      <button mat-button matTooltip="Change Status" (click)="changeStatus(d.row.data)"
              [ngClass]="{'btn-disabled':!d.row.data.gl_coding_enabled, 'btn-enabled': d.row.data.gl_coding_enabled}"
              [disabled]="isUndefinedOrNull(d.row.data.gl_coding_enabled) || !this.permissionModify" class="switch-button">
        {{d.row.data.gl_coding_enabled?'Enabled':'Disabled'}}
      </button>
    </div>

    <div *dxTemplate="let d of 'spidCellTemplate'">
      <div caGridTooltip>{{d.row.data.spid}}</div>
    </div>

  </dx-data-grid>

</mat-sidenav-container>
