import {InvoiceHeader} from './invoice-header';
import {Vendor} from '../../vendor/core/vendor';
import {Account} from '../../account/core/account';
import {InvoiceContact} from './invoice-contact';
import {InvoiceNote} from './invoice-note';

export class InvoiceFacepage {
  id: number;
  invoice_id: number;
  account_id: number;
  header_id: number;
  uniq_id: string;
  acct_level: number;
  acct_level_1: string;
  acct_level_2: string;
  sp_inv_num: string;
  acct_type: string;
  inv_date: Date;
  bill_period_start: Date;
  bill_period_end: Date;
  due_date: Date;
  date_issued: Date;
  prev_bill_amt: number;
  pmts_rcvd: number;
  pmts_app_thru_date: Date;
  bal_fwd_adj: number;
  bal_fwd: number;
  tot_new_chg_adj: number;
  tot_new_chgs: number;
  tot_amt_due_adj: number;
  tot_amt_due: number;
  tot_mrc_chgs: number;
  tot_occ_chgs: number;
  tot_usage_chgs: number;
  tot_taxsur: number;
  tot_disc_amt: number;
  sp_acct_status_ind: string;
  sp_name: string;
  sp_remit_addr_1: string;
  sp_remit_addr_2: string;
  sp_remit_addr_3: string;
  sp_remit_addr_4: string;
  sp_remit_city: string;
  sp_remit_state: string;
  sp_remit_zip: string;
  sp_remit_country: string;
  sp_inquiry_tel_num: string;
  billed_company_name: string;
  billed_company_addr_1: string;
  billed_company_addr_2: string;
  billed_company_addr_3: string;
  billed_company_addr_4: string;
  billed_company_city: string;
  billed_company_state: string;
  billed_company_zip: string;
  billed_company_country: string;
  acna: string;
  currency: string;
  sp_code: string;
  old_acct_num: string;
  contract_id: string;
  contract_eff_date: Date;
  contract_end_date: Date;
  contract_commitment: string;
  contract_commitment_met: string;
  sp_inv_line_num: number;
  sp_inv_record_type: string;
  sp_tot_new_chgs: number;
  sp_tot_amt_due: number;
  udf: string;
  sp_bal_fwd: number;
  vendor_id: number;
  status: any;
  owner: any;
  gl_amt: any;
  dnp_reason:any;

  header: InvoiceHeader;
  vendor: Vendor;
  account: Account;

  amount_witheld: number;
  total_amount_due_adjad: number;
  bal_fwd_adjbf: number;
  
  tot_adjman: number;
  tot_amt_due_with_adj: number = this.SetTotAmtDueWithAdj();

  total: number = this.SetTotal();
  withheld_disputes_amount: number;

  contacts: Array<InvoiceContact>;
  notes: Array<InvoiceNote>;


  $new: boolean;
  $removed: boolean;
  gl_invoice_amt: InvoiceFacepage;
  batch_item: any;
  withheld_excluded_sum: any;

  file_name: string;

  public SetTotAmtDueWithAdj(): number {
    let tot_new_chgs: number = this.tot_new_chgs || 0;
    let tot_amt_due_adj: number = this.tot_amt_due_adj || 0;
    return tot_new_chgs + tot_amt_due_adj;
  }

  public SetTotal(): number {
    let wh: number = this.amount_witheld  || 0;
    let wh_excluded: number = this.withheld_excluded_sum  || 0;
    return this.tot_amt_due_with_adj - wh - wh_excluded;
  }


  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
