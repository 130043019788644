import {Component, OnInit} from '@angular/core';
import {InvoiceFacepage} from "../../core/invoice-facepage";
import { MatDialogRef } from "@angular/material/dialog";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";
import {AlertService} from "../../../shared/alert/alert.service";

@Component({
  selector: 'app-invoice-balance-error-dialog',
  templateUrl: './invoice-balance-error-dialog.component.html',
})
export class InvoiceBalanceErrorDialogComponent implements OnInit {

  invoices: Array<InvoiceFacepage>;
  invoice: InvoiceFacepage;
  inv_num: string;

  constructor(public dialogRef: MatDialogRef<InvoiceBalanceErrorDialogComponent>,
              public alertService: AlertService,
              public invoiceService: InvoiceFacepageService) {
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(null);
    throw Error(`Invoice ${this.invoice ? this.invoice.sp_inv_num : this.inv_num} is not in balance`);
  }
}
