import { Injectable } from '@angular/core';
import { UserManageDialogComponent } from "../user/shared/user-manage-dialog/user-manage-dialog.component";
import { BuildingManageDialogComponent } from "../building/shared/building-manage-dialog/building-manage-dialog.component";
import { SiteManageDialogComponent } from "../site/shared/site-manage-dialog/site-manage-dialog.component";
import { CustomerManageDialogComponent } from "../customer/shared/customer-manage-dialog/customer-manage-dialog.component";
import { ContactManageDialogComponent } from "../contact/shared/contact-manage-dialog/contact-manage-dialog.component";
import { BuildingService } from "../building/core/building.service";
import { UserService } from "../user/core/user.service";
import { SiteService } from "../site/core/site.service";
import { CustomerService } from "../customer/core/customer.service";
import { ContactService } from "../contact/core/contact.service";
import {AppService} from "../app.service";
import {DialogService} from "../shared/dialog/dialog.service";
import {ProfileManageDialogComponent} from "../user/shared/profile-manage-dialog/profile-manage-dialog.component";

@Injectable()
export class SearchResultService {

    constructor(
      public buildingService: BuildingService,
      public userService: UserService,
      public siteService: SiteService,
      public customerService: CustomerService,
      public contactService: ContactService,
      public appService: AppService,
      public dialogService: DialogService) {

      /* GPX-2401 solution to remove reference on UserManageDialogComponent from MasterComponent */
      this.appService.onChange
        .subscribe((data) => {
          if (data && data.type === 'USER:PROFILE' && data.object.user) {
            this.dialogService.open(ProfileManageDialogComponent, {
              user: data.object.user
            }, {width: '800px'})
              .afterClosed()
              .subscribe(result => {

              });
          }
        });
    }

    public getResultFormats() {
        return {
            'account': {
                'icon': "SEARCH_ACCOUNT",
                'title': "Account",
                'text': [
                    {
                        "label": "BAN ID",
                        "field": 'account_no'
                    },
                    {
                        "label": "Vendor",
                        "field": 'vendor_name'
                    },
                    {
                        "label": "Status",
                        "field": 'account_status_value'
                    }
                ],
                'action': 'navigate',
                'action_config': {
                    'path': '/account',
                    'field': 'id',
                    'view': 'show'
                }
            },
            'user': {
                'icon': "SEARCH_USER",
                'title': 'User',
                'path': '/user',
                'text': [
                    {
                        "label": "",
                        "field": 'first_name'
                    },
                    {
                        "label": "",
                        "field": 'last_name'
                    }
                ],
                'action': 'open',
                'action_config': {
                    "entityId": 'id',
                    "openParams": 'user',
                    'componentType': UserManageDialogComponent,
                    'service': this.userService
                }
            },
            'contact': {
                'icon': "SEARCH_CONTACT",
                'title': 'Contact',
                'path': '/contact',
                'text': [
                    {
                        "label": "Contact Name",
                        "field": 'full_name'
                    },
                    {
                        "label": "Type",
                        "field": 'type_value'
                    },
                    {
                        "label": "Company",
                        "field": 'company_name'
                    },
                    {
                        "label": "Functions",
                        "field": 'functions_value'
                    }
                ],
                'action': 'open',
                'action_config': {
                    "entityId": 'id',
                    "openParams": 'contact',
                    'componentType': ContactManageDialogComponent,
                    'service': this.contactService
                }
            },
            'building': {
                'icon': "SEARCH_BUILDING",
                'title': 'Building',
                'path': '/building',
                'text': [
                    {
                        "label": "Address",
                        "field": 'name',
                        "type": 'truncate',
                        "format": 60
                    }
                ],
                'action': 'open',
                'action_config': {
                    "entityId": 'id',
                    "openParams": 'building',
                    'componentType': BuildingManageDialogComponent,
                    'service': this.buildingService
                }
            },
            'site': {
                'icon': "SEARCH_SITE",
                'title': 'Site',
                'path': '/site',
                'text': [
                    {
                        "label": "Site Type",
                        "field": 'site_type_value'
                    },
                    {
                        "label": 'Site Owner',
                        "field": "vendor_name"
                    },
                    {
                        "label": 'Site Address',
                        "field": "site_id"
                    }
                ],
                'action': 'open',
                'action_config': {
                    "entityId": 'id',
                    "openParams": 'site',
                    'componentType': SiteManageDialogComponent,
                    'service': this.siteService
                }
            },
            'invoice': {
                'icon': "SEARCH_INVOICE",
                'title': 'Invoice',
                'text': [
                    {
                        "label": "BAN",
                        "field": 'acct_level_1',
                        "type": 'truncate',
                        "format": 20
                    },
                    {
                        "label": 'Inv. Date',
                        "field": "inv_date",
                        "type": 'date'
                    },
                    {
                        "label": 'Inv. Number',
                        "field": "sp_inv_num",
                        "type": 'truncate',
                        "format": 20
                    }
                ],
                'action': 'navigate',
                'action_config': {
                    'path': '/invoice',
                    'field': 'id',
                    'view': 'show'
                }
            },
            'inventory': {
                'icon': "SEARCH_INVENTORY",
                'title': 'Inventory',
                'text': [
                    {
                        "label": "SP Ckt ID",
                        "field": 'sp_ckt_id'
                    },
                    {
                        "label": 'Vendor',
                        "field": "vendor_name"
                    },
                    {
                        "label": 'Status',
                        "field": "status_value"
                    }
                ],
                'action': 'navigate',
                'action_config': {
                    'path': '/inventory',
                    'field': 'id',
                    'view': 'show'
                }
            },
            'dispute': {
                'icon': "SEARCH_DISPUTE",
                'title': 'Dispute',
                'text': [
                    {
                        "label": "Dispute ID",
                        "field": 'dispute_id'
                    },
                    {
                        "label": 'Vendor',
                        "field": "vendor_name"
                    },
                    {
                        "label": 'Status',
                        "field": "status_value"
                    }
                ],
                'action': 'navigate',
                'action_config': {
                    'path': '/dispute',
                    'field': 'id',
                    'view': 'show'
                }
            },
            'customer': {
                'icon': "SEARCH_CUSTOMER",
                'title': 'Customer',
                'path': '/customer',
                'text': [
                    {
                        "label": "Company",
                        "field": 'company'
                    }
                ],
                'action': 'open',
                'action_config': {
                    "entityId": 'id',
                    "openParams": 'customer',
                    'componentType': CustomerManageDialogComponent,
                    'service': this.customerService
                }
            },
            'order': {
                'icon': "SEARCH_ORDER",
                'title': 'Order',
                'text': [
                    {
                        "label": "Order ID",
                        "field": 'id'
                    },
                    {
                        "label": "Status",
                        "field": 'order_status_value'
                    },
                ],
                'action': 'navigate',
                'action_config': {
                    'path': '/order',
                    'field': 'id',
                    'view': 'show'
                }
            },
            'quoteservice': {
                'icon': "SEARCH_QUOTE",
                'title': 'Quote',
                'text': [
                    {
                        "label": "Quote ID",
                        "field": 'quote_full_id'
                    }
                ],
                'action': 'navigate',
                'action_config': {
                    'path': '/quotes',
                    'field': 'quote_id',
                    'view': 'show'
                }
            },
            'orderservice': {
                'icon': "SEARCH_ORDER",
                'title': 'Order',
                'text': [
                    {
                        "label": "Order ID",
                        "field": 'order_id'
                    },
                    {
                        "label": "Status",
                        "field": 'order_status_value'
                    },
                ],
                'action': 'navigate',
                'action_config': {
                    'path': '/order',
                    'field': 'order_id',
                    'view': 'show'
                }
            },
            'customerinventory': {
                'icon': "SEARCH_CUSTOMER_INVENTORY",
                'title': 'Customer Inventory',
                'text': [
                    {
                        "label": "Customer Service ID",
                        "field": 'customer_circuit_id'
                    },
                    {
                        "label": "Customer",
                        "field": 'customer_company'
                    },
                    {
                        "label": "Status",
                        "field": 'customer_inventory_status_value'
                    }
                ],
                'action': 'navigate',
                'action_config': {
                    'path': '/customer-inventory',
                    'field': 'id',
                    'view': 'show'
                }
            },
            'contract': {
                'icon': "SEARCH_CONTRACT",
                'title': 'Contract',
                'text': [
                    {
                        "label": "Contract Name",
                        "field": 'name'
                    },
                    {
                        "label": "Vendor",
                        "field": 'vendor_name'
                    },
                    {
                        "label": "Type",
                        "field": 'contract_type_value'
                    }
                ],
                'action': 'navigate',
                'action_config': {
                    'path': '/contract',
                    'field': 'id',
                    'view': 'show'
                }
            }
        };
    }
}
