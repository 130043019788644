import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {DownloadInvoicePDF} from "../state/invoice-details.actions";
import {Store} from "@ngxs/store";
import {ActivatedRoute, Router} from "@angular/router";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";
import {AccountDialogService} from "../../../account/core/account-dialog.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {InvoiceFlowService} from "../../core/invoice-flow.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {HistoryService} from "../../../shared/history/history.service";
import Query from "../../../core/query/query";
import {InvoiceChargeService} from "../../../charge/core/invoice-charge.service";
import {MESSAGES_ENUM} from "../../../core/resources/messages.enum";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {InvoiceDocumentService} from "../../core/invoice-document.service";

@Component({
  selector: 'app-invoice-details-toolbar',
  templateUrl: './invoice-details-toolbar.component.html',
  styleUrls: ['./invoice-details-toolbar.component.css']
})
export class InvoiceDetailsToolbarComponent implements OnInit, OnDestroy {
  documentInvoiceType = Object.keys(LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE)
  unsetAdjustments = LOOKUP_ENUM.UNSET_ADJUSTMENTS;
  adjustmentOptions = {
    INCLUDED: 'Include',
    EXCLUDED: 'Exclude'
  };

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'invoice';
  public readonly  INVOICE_HAS_DISPUTE = MESSAGES_ENUM.invoice.INVOICE_HAS_DISPUTE

  unsetAdjustmentsKeys = Object.keys(LOOKUP_ENUM.UNSET_ADJUSTMENTS)
    .filter(
      item => item !== 'NONE'
    );

  isDeleteInvoiceDisabled: boolean = true;
  public hasDispute = false;
  public disputeQuery: Query = new Query();

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() invoice: InvoiceFacepage;
  @Input() isDownloadPDFHidden = false;
  @Input() isDropDownDownloadPDFHidden = false;
  @Input() isDownloadContainerHidden = false;
  @Input() isDropDownFileHidden = true;
  @Input() isDropDownCsvHidden = true;
  @Input() isDropDownCsvDisabled = true;
  @Input() isDropDownUploadDocumentDisabled = true;
  @Input() isCSVDisabled = true;
  @Input() isCSVHidden = false;
  @Input() isDoNotProcessDisabled = true;
  @Input() isDoNotProcessHidden = false;
  @Input() isGoToRuleExecutionPageHidden = false;
  @Input() isDocumentTypeHidden = false;
  @Input() isAddDisputeDisabled = true;
  @Input() isAddDisputeHidden = false;
  @Input() isChangeAdjustmentDisabled = true;
  @Input() isChangeAdjustmentHidden = false;
  // @Input() isDeleteInvoiceDisabled = true;
  @Input() isDeleteInvoiceHidden = false;
  @Input() isOnHoldDisabled = true;
  @Input() isOnHoldHidden = false;
  @Input() isGLChargeViewSwitchDisabled = true;
  @Input() isGLChargeViewSwitchOn = true;
  @Input() isGLChargeViewSwitchHidden = false;
  @Input() isAddAuditHidden = true;
  @Input() isAddAuditDisabled = true;
  @Input() isAddDirectGLDisabled = true;
  @Input() isAddDirectGLHidden = false;
  @Input() isEditDirectGLDisabled = true;
  @Input() isEditDirectGLHidden = false;
  @Input() isGridSettingsDisabled = true;
  @Input() isGridSettingsHidden = false;
  @Input() isFilterOn = false;
  @Input() isFilterDisabled = true;
  @Input() isFilterHidden = false;
  @Input() isNotesOn = false;
  @Input() notesCount = 0;
  @Input() isHistoryOn = false;
  @Input() isHistoryDisabled = false;
  @Input() isSelectedDocument = false;
  @Input() isSelectedSystemFile = false;
  @Input() isManualAdjustment = false;

  @Input() isManualAdjustmentDisabled = true;
  @Input() isManualAdjustmentHidden = true;

  @Input() isDeleteChargesDisabled = true;
  @Input() isDeleteChargesHidden = true;

  @Output() backRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadPDFRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadCSVRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadContainerRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadSingularSourceRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() moveInvoiceToDoNotProcesseRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToRuleExecutionPageRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() addDisputeRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() putOnHoldRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteInvoiceRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() glChargeViewSwitchRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() createNewRateAuditRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() createDirectGLRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() editDirectGLRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeAdjustmentRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeInvoiceDocumentTypeRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeInvoiceActionRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() openGridSettingsRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleFilterRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleNotesRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleHistoryRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() addDocumentRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadDocumentRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkDocumentRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteDocumentRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() editDocumentRequested: EventEmitter<any> = new EventEmitter<any>();

  @Output() addManualAdjustmentRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteManualAdjustmentChargesRequested: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly invoiceService: InvoiceFacepageService,
    private readonly invoiceDocumentService: InvoiceDocumentService,
    public invoiceFlowService: InvoiceFlowService,
    private readonly accountDialogService: AccountDialogService,
    public chargeService: InvoiceChargeService,
    public alertService: AlertService,
    public historyService: HistoryService
  ) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.invoiceService.invoiceChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(invoice => {
      this.invoice = invoice;
      this.isDeleteInvoiceDisabled = !this.invoiceService.isDeleteEligible(this.invoice);
    })
  }

  ngOnInit(): void {
    this.invoice = this.getInvoice();
    this.isDeleteInvoiceDisabled = !this.invoiceService.isDeleteEligible(this.invoice);
    this.disputeQuery.set('invoice_id', this.invoice.invoice_id)

    this.chargeService.hasDispute(this.disputeQuery)
      .subscribe(res => {
        if(res.length){
          this.hasDispute = true
        }else {
          this.hasDispute = false;
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getInvoice() {
    return this.activatedRoute.parent.snapshot.data.invoice;
  }

  back() {
    this.backRequested.emit();
  }

  downloadPDF() {
    this.downloadPDFRequested.emit();
    this.store.dispatch([
      new DownloadInvoicePDF(this.invoice)
    ]);
  }

  csv() {
    this.downloadCSVRequested.emit();
  }

  uploadDocument() {
    this.addDocumentRequested.emit();
  }
  editDocument() {
    this.editDocumentRequested.emit();
  }

  downloadDocument() {
    this.downloadDocumentRequested.emit();
  }

  deleteDocument() {
    this.deleteDocumentRequested.emit();
  }

  documentDropDownCheck() {
    this.checkDocumentRequested.emit();
  }

  actionDropDownCheck() {
    this.checkDocumentRequested.emit();
  }

  // downloadContainer() {
  //   this.downloadContainerRequested.emit();
  //   this.store.dispatch([
  //     new DownloadInvoiceContainer(this.invoice)
  //   ]);
  // }

  downloadContainer(): any {
    return this.invoiceService.downloadContainer(this.invoice.invoice_id).subscribe(
      (response: Blob) => {
        let objectUrl = URL.createObjectURL(response);

        let save = document.createElement('a');
        save.href = objectUrl;
        save.target = '_blank';
        save.download = this.invoice.header.file_source;

        let event = document.createEvent('MouseEvents');
        event.initMouseEvent(
          'click',
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        save.dispatchEvent(event);
      },
      Error => {
        this.alertService.error('', this.messages.get('NO_FILE_FOUND'));
      }
    );
  }

  // downloadSingularSource() {
  //   this.downloadSingularSourceRequested.emit();
  //   this.store.dispatch([
  //     new DownloadInvoiceSource(this.invoice)
  //   ]);
  // }

  addManualAdjustment(): any {
    this.addManualAdjustmentRequested.emit();
  }

  downloadSingularSource(): any {

    return this.invoiceService.downloadSingularFileSourceName(this.invoice.invoice_id)
      .subscribe((response) => {
          if (response.key) {
            const folderName = response.key.substr(response.key.indexOf('/') + 1)
            this.invoiceService.downloadSource(this.invoice.invoice_id).subscribe(
              (sourceResponse: Blob) => {
                let objectUrl = URL.createObjectURL(sourceResponse);

                let save = document.createElement('a');
                save.href = objectUrl;
                save.target = '_blank';
                save.download = `${folderName}`;
                let event = document.createEvent('MouseEvents');
                event.initMouseEvent(
                  'click',
                  true,
                  false,
                  window,
                  0,
                  0,
                  0,
                  0,
                  0,
                  false,
                  false,
                  false,
                  false,
                  0,
                  null
                );
                save.dispatchEvent(event);
              },
              Error => {
                this.alertService.error('', this.messages.get('NO_VENDOR_SOURCE_FILE'));
              }
            )
          } else {
            this.alertService.error('', this.messages.get('NO_VENDOR_SOURCE_FILE'))
          }
        }
      )
  }


  moveInvoiceToDoNotProcess() {
    this.moveInvoiceToDoNotProcesseRequested.emit();
  }

  goToRuleExecutionPage() {
    this.goToRuleExecutionPageRequested.emit();
    this.router.navigate(['gl-rule-execution', this.invoice.invoice_id, this.invoice.vendor_id, this.invoice.account_id]);
  }

  addDispute() {
    this.addDisputeRequested.emit();
  }

  putOnHold() {
    this.putOnHoldRequested.emit();
  }

  deleteInvoice() {
    this.chargeService.hasDispute(this.disputeQuery)
      .subscribe(res => {
        if(res.length){
          return  this.alertService.success('', this.INVOICE_HAS_DISPUTE);
        } else {
          this.deleteInvoiceRequested.emit();
          this.invoiceService.deleteWithConfirmation(this.invoice).subscribe(() => {
            this.router.navigate(['invoice'])
          });
        }
      })
  }

  deleteCharges() {
    this.deleteManualAdjustmentChargesRequested.emit();
  }

  glChargeViewSwitch() {
    this.glChargeViewSwitchRequested.emit();
  }

  createNewRateAudit() {
    this.createNewRateAuditRequested.emit();
  }

  createDirectGL() {
    this.createDirectGLRequested.emit();
  }

  editDirectGL() {
    this.editDirectGLRequested.emit();
  }

  changeAdjustment(adjustmentElement: any) {
    this.changeAdjustmentRequested.emit(adjustmentElement);
  }

  changeInvoiceDocumentType(param: any) {
    this.changeInvoiceDocumentTypeRequested.emit(param);
    // this.invoiceService.toolbarAction.emit({
    //   action: 'onChangeInvoiceDocumentTypeRequested',
    //   params: param
    // })
  }

  openGridSettings() {
    this.openGridSettingsRequested.emit();
  }

  toggleFilter() {
    this.toggleFilterRequested.emit();
  }

  toggleNotes() {
    this.toggleNotesRequested.emit();
  }

  toggleHistory() {
    this.toggleHistoryRequested.emit();
  }
}
