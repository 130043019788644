  <mat-form-field fxFlex class="flex-item" hiDensityInput="">
    <input [(ngModel)]="internalNumberQuery['from']"
           (focusout)="ngOnNumberChange($event, entry, FOCUS.FROM)"
           type="number"
           [placeholder]="'From'"
            matInput
           (keydown.arrowdown)="onKeyDownArrowDown()"
           (keydown.arrowup)="onKeyDownArrowUp()"
           (keydown.enter)="ngOnNumberChange($event, entry, FOCUS.FROM)">
    <mat-hint>
      {{fromNumberErrorText}}
    </mat-hint>
  </mat-form-field>
  <span fxFlex="10px"></span>
  <mat-form-field fxFlex class="flex-item" hiDensityInput="">
    <input  [(ngModel)]="internalNumberQuery['to']"
            (focusout)="ngOnNumberChange($event, entry, FOCUS.TO)"
            type="number"
            [placeholder]="'To'"
            matInput
            [min]="internalNumberQuery['from']"
            (keydown.arrowup)="onKeyDownArrowUp()"
            (keydown.arrowdown)="onKeyDownArrowDown()"
            (keydown.enter)="ngOnNumberChange($event, entry, FOCUS.TO)">
    <mat-hint>
      {{toNumberErrorText}}
    </mat-hint>
  </mat-form-field>
