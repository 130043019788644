import { LookupModel } from "../../dictionary/core/lookup.model";
import { VendorRemitAddress } from "../../vendor-remit-address/core/vendor-remit-address";
import { Vendor } from "../../vendor/core/vendor";
import { VendorEntity } from "../../vendor/core/vendor-entity";

export class Account {
  id: number;
  account_no: string;
  billing_cycle: number;
  status_id: number;
  late_bill_lag_days: number;
  vendor_id: number;
  vendor_entity?: number;
  acct_desc: string;
  pay_meth?: number;
  media_typ?: number;
  vendor_location_id?: number;
  coding_method: string;
  gl_coding_enabled: boolean;
  gl_inv_coding_enabled: boolean;
  autoapprover_amount_max: number;
  autoapprover_amount_min: number;
  autoapprover_enabled: boolean;
  is_static: boolean;
  pct_total: number;
  vendor_remit_address: VendorRemitAddress;
  status: LookupModel;
  vendor: Vendor;
  entity: VendorEntity;
  payment_method: LookupModel;
  audit_threshold: number;
  media_type: any;
  on_hold: boolean;
  on_hold_desc: string;
  subclient_id: number;
  subclient: LookupModel;
  ap_vend_id: any;
  billing_freq_id: number;
  billing_frequency: LookupModel;
  currency:string;
  // REVIEW: check if this is ok
  // we need this for auto approver check if an account has been touched and should be saved to DB
  changed: boolean;
  country_id: number;
  country: any;
  is_vat_gl_output: boolean;
  region_id: number;
  region: any;
  currency_obj: any;
  currency_id: number;
  ap_configuration_id: any;
  ap_configuration: any;
  custom_values: any;
  svc_pd_month: any;
  svc_pd_month_id: number;
  svc_pd_range: any;
  svc_pd_range_id: number;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
