export class Demarc {
  id: number;
  site_id: number;
  network_circuit_id: number;
  extended: boolean;
  row_id: string;
  rack_id: string;
  shelf_id: string;
  cpe_id: string;
  card_id: string;
  port_id: string;
  notes: string;

  unsaved: boolean;
  uuid: string;

  constructor(data?: any) {
    if (data) {
      if(data['unsaved']) {
        data['uuid'] = this.guid();
      }
      Object.assign(this, data);
    }
  }

  guid() {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }


}
