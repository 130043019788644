<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-title">{{ (alert?.type?.value || '') + ' Alert: ' + (alert?.rule_name || 'N/A') }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button [disabled]="!(alert && alert.invoice && alert.invoice.invoice_id)" (click)="goToInvoiceDetails()" mat-icon-button>
    <ca-icon [key]="'VIEW_INVOICE_DETAILS'" [tooltip]="'View Invoice Details'"></ca-icon>
  </button>
  <button
    *permission="{'action':'Create', 'key':'disputes'}"
    mat-icon-button
    [disabled]="addDisputeDisabled"
    (click)="addDispute()"
    [hidden] = "!tabGroup.isActive(2)"
  >
    <ca-icon [key]="'ADD_DISPUTE'" [tooltip]="'Create Dispute'"></ca-icon>
  </button>
  <button [disabled]="!tabGroup.isActive(2)" mat-icon-button matTooltip="Refresh" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [disabled]="!tabGroup.isActive(2)" mat-icon-button (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'" [tooltip]="'Download CSV'"></ca-icon>
  </button>
  <button [disabled]="!tabGroup.isActive(2) || isGridSettingsDisabled" mat-icon-button (click)="gridSettings()">
    <ca-icon [key]="'GRID_SETTINGS'" [tooltip]="'Settings'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [disabled]="!tabGroup.isActive(2)" [disabled]="!selection"
          [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(2)}"
          (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="'FILTER'" [tooltip]="'Filter'"></ca-icon>
  </button>
  <button [disabled]="!tabGroup.isActive(2)" #toggleDetailsEnableButton
          [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
          [disabled]="!selection"
          (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>
  </button>
</mat-toolbar>


<mat-sidenav-container class="app-content tabbed-content">


  <mat-tab-group (selectedIndexChange)="onTabChange($event)" >
    <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs" [disabled]="tab.disabled">
    </mat-tab>
  </mat-tab-group>

  <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(1)}" class="tabs-body" fxLayout="column">
    <div class="flex-container ca-card  custom-mat-card-container" fxLayout="row">
        <mat-card fxFlex="60%" fxFlexFill caCard>

          <mat-card-content>
            <div fxLayout="row" class="entry-list">
              <div fxFlex="33%" class="entry-list-item">
                <label>Category</label>
                <p>
                  {{ alert?.category?.value || 'N/A' }}
                </p>
              </div>

              <div fxFlex="33%" class="entry-list-item">
                <label>Created At</label>
                <p>
                  {{ (alert?.created_at | caDateTime) || 'N/A' }}
                </p>
              </div>

              <div fxFlex="33%" class="entry-list-item">
                <label>Created By</label>
                <p>
                  {{ alert?.creator?.full_name || 'System'}}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex="33%" class="entry-list-item">
                <label>Owner</label>
                <p>
                  {{ alert?.owner?.full_name || PLACEHOLDERS.OWNER_UNASSIGNED }}
                </p>
              </div>

              <div fxFlex="33%" class="entry-list-item">
                <label>Priority</label>
                <p>
                  {{ alert?.priority.value || 'N/A' }}
                </p>
              </div>

              <div fxFlex="33%" class="entry-list-item">
                <label>Status</label>
                <p>
                  {{ alert?.status.value || 'N/A' }}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex="33%" class="entry-list-item">
                <label>Last Modified At</label>
                <p>
                  {{ (alert?.last_modified_at | caDate) || 'N/A' }}
                </p>
              </div>

              <div fxFlex="33%" class="entry-list-item">
                <label>Closed At</label>
                <p>
                  {{ (alert?.closed_at | caDate) || 'N/A' }}
                </p>
              </div>

            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Message</label>
                <p>
                  {{ alert?.message || 'N/A' }}
                </p>
              </div>
            </div>

          </mat-card-content>

        </mat-card>
    </div>
  </div>

  <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}" class="tabs-body master-detail-arrow-color">
    <dx-data-grid #dataGrid
                  class="app-grid-with-pager app-content-list master-charge-table"
                  (onSelectionChanged)="onSelectionChanged($event)"
                  [masterDetail]="{ enabled: true, template: 'detail' }"
                  (onRowExpanded)="onRowExpanded($event)"
                  (onRowCollapsed)="onRowCollapsed($event)"
                  keyExpr="id"
                  [columns]="columns"
                  [dataSource]="audits"
                  (onCellClick)="onCellClick($event)">

      <div *dxTemplate="let d of 'auditCurrencyTemplate'">
        <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency }}</div>
      </div>
      <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
        <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency }}</div>
      </div>

      <div *dxTemplate="let d of 'chargeRateTemplate'">
        <div caGridTooltip class="right">{{  d.value | caNumber }}
        </div>
      </div>

      <div *dxTemplate="let d of 'rateStatusTemplate'">
        <div caGridTooltip class="left"
             [ngClass]="(d.value && d.value === 'Expired') ? 'batch_inactive demi-bold' : (d.value && d.value === 'Active') ? 'batch_active demi-bold' : ''"
        >{{ d.value || 'N/A' }}</div>
      </div>

      <div *dxTemplate="let audit of 'detail'" class="detail-table"
           (click)="onChargesDataGridClick($event)"
      >
        <mat-card fxFlex="100%" class="flex-item result-card" caCard>
          <mat-card-content>
            <dx-data-grid
              class="charge-table"
              [ngClass]="{'pager-grid': audit.data.resultQuery && audit.data.resultQuery.total < 5}"
              #chargesDataGrid
              [columns]="chargeColumns"
              [dataSource]="audit.data.charges"
              (onSelectionChanged)="onChargesSelectionChanged($event, audit.data)"
              [showBorders]="true"
              [showRowLines]="true"
              [showColumnLines]="false"
              [allowColumnResizing]="true"
              [columnMinWidth]="80"
              keyExpr="id"
              [id]="'charges-grid-' + audit.data.id"
            >

              <dxo-selection mode="multiple" class="select"></dxo-selection>
              <!-- <dxo-paging [enabled]="false"></dxo-paging> -->
              <dxo-load-panel [enabled]="false"></dxo-load-panel>

              <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
                <a class="selection-badge grid-badge"
                    matTooltip="Selection"
                    (click)="loadSelectedAuditResults(audit.data)"
                    [ngClass]="{'active':audit.data.selectionActive}">
                  {{audit.data.selectedRows ? audit.data.selectedRows.length : '0'}}
                </a>
              </div>
              <div
                  *dxTemplate="let d of 'chargeDisputeNoteTemplate'"
                  style="position: relative;"
                  class="icon-td"
                >
                  <a [ngClass]="
                        d.data.charge_dispute_id ? 'icon-danger' : 'disabled'
                      "
                    (click)="showDispute(d.data.charge_dispute_id)"
                    class="charge-grid-icons"
                  >
                    <ca-icon
                      [key]="'DISPUTE'"
                      [innerClass]="'charge-grid-icon'"
                      [tooltip]="'Dispute'"
                    ></ca-icon>
                  </a>
                </div>

              <div *dxTemplate="let d of 'amountCurrencyTemplate'">
                <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency }}</div>
              </div>
              <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
                <div caGridTooltip class="center">{{ d.value | caCurrency:d.data.currency }}</div>
              </div>

              <div *dxTemplate="let d of 'chargeRateTemplate'">
                <div caGridTooltip class="right">{{  d.value | caNumber }}
                </div>
              </div>
            </dx-data-grid>
            <ca-pager
              *ngIf="audit.data.resultQuery && audit.data.resultQuery.total >= 5"
              [query]="audit.data.resultQuery || {}"
              class="app-pager ca-default-border-top"
              [sizes]="sizes"
              (onChange)="onResultPageChange(audit.data.resultQuery || {}, audit.data)"
              [gridPager]="audit.data.resultQuery.limit || 20"
              >
            </ca-pager>
          </mat-card-content>
        </mat-card>
      </div>
    </dx-data-grid>
    <ca-pager [gridPager]="defaultGridPager" [pageContext]="alertInventoryMrcGridService" [query]="query"
              class="app-pager ca-default-border-top" (onChange)="onPageChange(query)"></ca-pager>


  </div>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                    [query]="query"
                    [filterService]="inventoryMrcFilterService"
                    (filterRequested)="filterData($event)"
                    (clearFilterRequested)="clearFilter($event)">
    </new-app-filter>
    <app-alert-inventory-mrc-sneak-peek [entry]="selection"
                                        (closeRequested)="sider?.close()"
                                        [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"></app-alert-inventory-mrc-sneak-peek>
  </mat-sidenav>

</mat-sidenav-container>

