export const svcOrderHeaderFilterContext = {
  filters: [
    {
      field: 'name',
      label: 'Name',
      type: 'string',
      index: 1
    },
    {
      field: 'description',
      label: 'Svc Order Description',
      type: 'string',
      index: 2
    },
    {
      field: 'vendor_so_number',
      label: 'Vendor SO Number',
      type: 'string',
      index: 3
    },
    {
      field: 'internal_so_number',
      label: 'Internal SO Number',
      type: 'string',
      index: 4
    },
    {
      field: 'so_date',
      label: 'SO Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 5
    },
  ]
};
