import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchService} from "./search.service";
import {SearchComponent} from "./search.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "app/material-barrel/material-barrel.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {SharedModule} from "../shared/shared.module";
import {SearchResultService} from "./search-result.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    FlexLayoutModule
  ],
  declarations: [SearchComponent],
  exports: [SearchComponent],
  providers: [SearchService, SearchResultService]
})
export class SearchModule {
}
