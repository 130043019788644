export const siteFilterContext = {
  filters: [
    {
      field: 'site_id',
      label: 'Site Name',
      type: 'string',
      index: 1,
      visible: true
    },
    {
      field: 'vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 2,
      visible: true
    },
    {
      field: 'building.address.city',
      label: 'City',
      type: 'string',
      index: 3,
      visible: true
    },
    {
      field: 'building.address.subdivision.abbreviation',
      label: 'Subdivision',
      type: 'lookup',
      editor: 'subdivision',
      lookupProviderName: 'state',
      index: 4,
      visible: true
    },
    {
      field: 'building.address.country.alpha_2_code',
      label: 'Country',
      type: 'lookup',
      editor: 'country',
      lookupProviderName: 'country',
      index: 4,
      visible: true
    },
    {
      field: 'building.address.postal_code',
      label: 'Postal Code',
      exactMatch: false,
      matchCase: false,
      type: 'string',
      index: 5,
      visible: true
    },
    {
      field: 'building.name',
      label: 'Building',
      type: 'lookup',
      lookupProviderName: 'building',
      index: 6,
      visible: true
    },
    {
      field: 'building.address.address1',
      label: 'Address',
      type: 'string',
      index: 7,
      visible: true
    },
    {
      field: 'type.value',
      label: 'Site Owner Type',
      type: 'lookup',
      lookupProviderName: 'SiteOwnerCategory',
      index: 8,
      visible: true
    },
    {
      field: "customer.company",
      label: "Customer",
      type: 'lookup',
      lookupProviderName: 'customer',
      index: 9,
      visible: true
    },
    {
      field: 'floor',
      label: 'Floor ',
      type: 'number',
      index: 9,
      visible: true,
      max: 200
    },
    {
      field: 'room',
      label: 'Room ',
      type: 'string',
      index: 10,
      visible: false
    },
  ]
};
