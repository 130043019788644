<div *ngIf="order">
<mat-toolbar color="accent" mat-scroll-shrink>
  <a [routerLink]="['/order', order.id, 'show']" class="sneak-peek-title">{{
    order?.full_order_id || "N/A"
  }}</a>

  <span class="example-fill-remaining-space"></span>

  <button mat-icon-button (click)="closeSide()">
    <ca-icon [key]="'CLOSE'"></ca-icon>
  </button>
</mat-toolbar>

<div class="ca-card ca-card-sneak-peek">
  <mat-card caCard="">
    <mat-card-content>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Vendor</label>
          <p>
            {{ order?.vendor?.name || "N/A" }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Total Service Items</label>
          <p>
            {{ (order?.tot_svc_items | caNumber) || "N/A" }}
          </p>
        </div>
      </div>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Processor</label>
          <p>
            {{ order?.processor?.full_name || "N/A" }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Requester-Initiator</label>
          <p>
            {{ order?.requester?.full_name || "N/A" }}
          </p>
        </div>
      </div>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Approver</label>
          <p>
            {{ order?.approver?.full_name || "N/A" }}
          </p>
        </div>
      </div>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Total Est MRC</label>
          <p>
            {{
              (order?.total_est_mrc | caCurrency: order?.currency_obj?.currency) ||
                "N/A"
            }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Total Est NRC</label>
          <p>
            {{
              (order?.total_est_nrc | caCurrency: order?.currency_obj?.currency) ||
                "N/A"
            }}
          </p>
        </div>
      </div>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Request Date</label>
          <p>
            {{ (order?.request_date | caDate) || "N/A" }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Create Date</label>
          <p>
            {{ (order?.created_at | caDate) || "N/A" }}
          </p>
        </div>
      </div>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Ready for Approval</label>
          <p>
            {{ (order?.ready_for_approval | caDate) || "N/A" }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Approve Date</label>
          <p>
            {{ (order?.approve_date | caDate) || "N/A" }}
          </p>
        </div>
      </div>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Sent Date</label>
          <p>
            {{ (order?.send_date | caDate) || "N/A" }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Ack Date</label>
          <p>
            {{ (order?.vendor_ack_date | caDate) || "N/A" }}
          </p>
        </div>
      </div>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Accept/Reject Date</label>
          <p>
            {{ (order?.vendor_accept_date | caDate) || "N/A" }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Complete Date</label>
          <p>
            {{ (order?.complete_date | caDate) || "N/A" }}
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-content> </ng-content>
</div>


<div *ngIf="!order" class="ca-empty-state-container">
  <p class="small-div-message">No item selected.</p>
</div>
