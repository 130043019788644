
import {of as observableOf} from 'rxjs';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import * as moment from 'moment';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {Order} from "../../core/order";
import {OrderService} from "../../core/order.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {EntityEditContext} from "../../../shared/entity-lock/entity-edit-context";
import {Observable} from "../../../../../node_modules/rxjs";
import {flatMap} from "rxjs/internal/operators";

@Component({
  selector: 'app-order-vendor-approval-manage',
  templateUrl: './order-vendor-approval-manage.component.html',
})
export class OrderVendorApprovalManageComponent extends PageManageDialogComponent implements OnInit {

  note: string;
  vendor_acc_date: Date;
  order: any;
  public minAllowedDate;

  constructor(
    public formBuilder: FormBuilder,
    public orderService: OrderService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<OrderVendorApprovalManageComponent>,
    public alertService: AlertService) {
      super(dialogService, dialogRef);
  }

  ngOnInit() {
      this.getMinDate()
      this.form = this.formBuilder.group({
        note: ["", Validators.minLength(20)],
        date: [this.vendor_acc_date ? new Date(this.vendor_acc_date) : null, Validators.required]
      });
  }

  init() {
    if (this.order && this.order.id) {
      return this.orderService.findByIdForEdit(this.order.id, new EntityEditContext({
        dialogRef: this.dialogRef
      })).pipe(
        flatMap((order) => {
          this.order = order;
          return observableOf(order);
        }))
    }
  }

  approve({value, valid}: {value: any, valid: boolean}) {
    this.dialogRef.close({
      approved: true,
      date: value.date,
      note: value.note
    });
  }

  reject({value, valid}: {value: any, valid: boolean}) {
    this.dialogRef.close({
      reject: true,
      date: value.date,
      note: value.note
    });
  }

  cancel() {
    this.orderService.cancelEdit();
    this.closeDialog();
  }
  getMinDate(){
    this.minAllowedDate = moment(this.order.vendor_ack_date).format('YYYY-MM-DD');
  }
}
