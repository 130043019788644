import {DialogService} from '../dialog/dialog.service';
import {PageManageDialogComponent} from '../../core/page-manage-dialog.component';
import {PageInfoService} from './core/page-info.service';
import {PageInfo} from './core/page-info';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {UserService} from '../../user/core/user.service';
import {User} from '../../user/core/user';
import {ConfigService} from '../../core/config/config.service';
import {Config} from '../../core/config/config';
import {AlertService} from '../alert/alert.service';
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import {LOOKUP_ENUM} from '../../dictionary/core/lookup.enum';

@Component({
  selector: 'app-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.css']
})
export class PageInfoComponent extends PageManageDialogComponent implements OnInit {

  public userId: any = 'Fetching...';
  public userFullName: string = 'Fetching...';
  public instanceName: string = 'Fetching...';
  public user: User;
  public versionNumber: string;
  public appName: string;
  public pagePath: string = window.location.href;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "shared";
  readonly ALERT_LEVELS_ENUM = LOOKUP_ENUM.ALERT_LEVELS;

  constructor(public userService: UserService,
              public formBuild: FormBuilder,
              public alert: AlertService,
              public dialogService: DialogService,
              public configService: ConfigService,
              public pageInfoService: PageInfoService,
              public dialogRef: MatDialogRef<PageInfoComponent>) {
    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.userService.me()
      .subscribe(
        (user: User) => {
          this.userId = user.id;
          this.user = user;
          this.userFullName = user.full_name;
        }
      );

    this.configService.findAll()
      .subscribe(
        (config: Config) => {
          this.instanceName = config.application_info.instance;
          this.appName = config.application_info.name;
          this.versionNumber = config.version;
        },
        (error) => {
          console.log('Error in info init sequence', error);
        }
      );

    this.form = this.formBuild.group({
      level: new FormControl(null, Validators.required),
      message: new FormControl("", Validators.required),
    });
  }

  onSubmit({value, valid}: { value: any, valid: boolean }): void {
    if (valid) {
      Object.assign(value, {
        requester_name: this.user.full_name,
        requester_phone: this.user.phone_number,
        requester_email: this.user.email,
        instance: this.instanceName,
        app: this.appName,
        log_level: value.level
      });
      this.sendReport(value);
    }
    else {
      this.alert.error('', this.messages.get('PAGE_INFO_FORM_INVALID'));
    }
  }

  public sendReport(value: PageInfo) {
    this.pageInfoService.create(value)
      .subscribe((result) => {
        this.closeDialog(result, true);
        this.alert.success('', this.messages.get('PAGE_INFO_SUBMIT_SUCCESS'));
      }, (err) => {
        this.alert.error('', this.messages.get('PAGE_INFO_SUBMIT_ERROR'));

      });
  }
}
