export const GLStringFilterContext = {
  filters: [
    {
      field: 'full_string_formatted',
      label: 'Full GL String',
      type: 'string',
      index: 1
    },
     {
      field: 'full_string_text',
      label: 'GL String Desc',
      type: 'string',
      index: 2
    },
  ]
};
