import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AddressComponent} from './address.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: AddressComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AddressRoutingModule {
}
