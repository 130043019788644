
import {of as observableOf, Observable} from 'rxjs';

import {filter} from 'rxjs/operators';
import {Component, OnInit, Input, ViewChild, forwardRef, EventEmitter, Output} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ContactService} from '../../../contact/core/contact.service';
import {ContactQuery} from '../../../contact/core/contact.query';

const validateEmail = (email) => {
  let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

  return EMAIL_REGEXP.test(email);

}

@Component({
  selector: 'app-email-tag',
  templateUrl: './email-tag.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailTagComponent),
      multi: true
    }
  ]
})
export class EmailTagComponent implements OnInit, ControlValueAccessor {
  // This one is not used but have to be there because typescript compiler compains
  public showEmailInvalid: boolean = false;

  constructor(public contactService: ContactService) {
  }

  @Input('value') _value: any;
  @Input('form') form: FormGroup;
  @Input('placeholder') placeholder: string = "";
  @Input('functionKey') functionKey: string = "";
  @Output() onPickerChange = new EventEmitter();
  @ViewChild('tagInputAsync') tagInputAsync;

  public autocompleteItems = [];

  writeValue(value: any): void {
    this.value = value;
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.propagateChange(val);
    this.onPickerChange.emit(this.value);
  }

  propagateChange = (_: any) => {
  };

  registerOnChange(fn: any): void {
    this.propagateChange = (value) => {
      Promise.resolve(null).then(() => fn(value))
    };
  }

  registerOnTouched(fn: any): void {
  }

  public onSelect(tag) {
    return tag;
  }

  public onAdding(tag): Observable<any> {

    tag = typeof tag === "string" ? tag : tag.value;

    const valid = validateEmail(tag);

    return observableOf(tag).pipe(
      filter(() => {

        this.showEmailInvalid = !valid;
        return valid;
      }));
  }

  onTagInputChange(input: string) {

    if (input && input.length) {
      this.showEmailInvalid = false;
      this.contactService.findAll(new ContactQuery({
        where: {
          $or: {
            email: {'$ilike': "%" + input + "%"},
            first_name: {'$ilike': "%" + input + "%"},
            last_name: {'$ilike': "%" + input + "%"}
          }
        }
      }))
        .subscribe((result) => {
          let emails = this.functionKey ? result.items.filter((contact) => {
            return contact.contact_functions.filter((func) => {
              return func.key === this.functionKey;
            }).length > 0;
          }) : result.items;

          emails = emails.map(contact => {
            return {
              value: contact.email,
              display: `${contact.first_name} ${contact.last_name} <${contact.email}>`
            }
          });
          this.autocompleteItems = (emails && emails.length) ? emails : [];
          this.tagInputAsync.blur();
          setTimeout(() => {
            this.tagInputAsync.focus();
          });
        })
    }
  }

  ngOnInit() {
    this.showEmailInvalid = false;
  }


}
