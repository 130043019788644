<p>
  <mat-form-field>
    <input type="text" placeholder="Search for address" matInput [formControl]="searchFormControl"
           [matAutocomplete]="auto">
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let entry of entries" [value]="entry.text" (onSelect)="suggestionSelected(entry.text)">
      {{ entry.text }}
    </mat-option>
  </mat-autocomplete>

  <br>
  <mat-checkbox [formControl]="typeFormControl">External</mat-checkbox>
</p>
