export class AlertAuditNavigationModel {

  public title: string;
  public icon?: string;
  public value?: string;
  public items?: AlertAuditNavigationModel[];
  public open: boolean = false;
  public selected: boolean = false;
  group: string;

  focused: boolean = false;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
