<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>FOC Dates</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">

<form class="ca-form dialog-content-no-margin" [formGroup]="form">
  <div style="overflow: hidden !important;">
    <div class="flex-container" fxLayout="row wrap" fxLayoutGap="24px">
      <div class="flex-item" fxFlex="{{data?.length>0? '25%': '30%'}}">
        <ca-date-picker caInput placeholder="FOC Receipt" formControlName="foc_rec_date" required="true"></ca-date-picker>
      </div>
      <div class="flex-item" fxFlex="{{data?.length>0? '25%': '30%'}}">
        <ca-date-picker caInput placeholder="FOC Date" formControlName="foc_date" required="true" [minDate]="form.get('foc_rec_date').value"></ca-date-picker>
      </div>
      <div class="flex-item" fxFlex="30%" *ngIf="data?.length>0">
        <ca-lookup-model-picker #reason
                                caInput
                                [clearEnabled]="true"
                                [lookupModel]="ORDER_SERVICE_MISSED_REASON_LOOKUP_MODEL"
                                [pickerForm]="form"
                                [valueAsString]="true"
                                formControlName="reason"
                                placeholder="Reason Missed *"> 
        </ca-lookup-model-picker>
      </div>
    </div>
    <div class="flex-container">
      <mat-form-field caInput>
        <textarea formControlName="note" rows="1" matInput placeholder="Note"></textarea>
        <mat-hint *ngIf="form.hasError('minlength', ['note']) && form.controls.note.touched">Minimum
          20 characters
        </mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="data?.length>0">
      <h3 class="flex-container no-margin-top" fxLayout="row">FOC Date History</h3>

      <dx-data-grid [columns]="columns" class="foc-date-grid" [dataSource]="data">
        <div *dxTemplate="let d of 'dateTemplate'">
          <div caGridTooltip>{{d.value | caDate}}</div>
        </div>
        <div *dxTemplate="let d of 'changedDateCellTemplate'">
          <div caGridTooltip>{{d.value | caDateTime}}</div>
        </div>
      </dx-data-grid>
    </div>

  </div>

</form>

</div>
<mat-dialog-actions class="ca-dialog-action">
    <span class="example-fill-remaining-space"></span>
    <button mat-button type="submit" (click)="cancel()">
        Cancel
    </button>
    <button mat-button
            [disabled]="form.invalid || reasonInvalid || form.get('foc_rec_date').value > form.get('foc_date').value"
            type="button"
            (click)="submit(form)">
      Save
    </button>
  </mat-dialog-actions>
