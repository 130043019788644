import {QueryParam} from './query-param';

export default class Query {
  key: string;
  limit = 20;
  offset = 0;
  page = 1;
  total = 0;
  where: any = {};
  params: QueryParam[] = [];
  orderBy = [];
  meta: any = {};
  settings: any = {};
  filterMeta: any = {};
  scope: string;

  constructor(data?: any) {
    Object.assign(this, data || {});
  }

  clone(data?: any) {
    const clone = JSON.parse(JSON.stringify(this));
    return new Query(Object.assign(clone, data));
  }


  getParam(name: string): QueryParam {
    return <QueryParam>this.params.filter((param) => {
      return param.name === name;
    })[0];
  }

  setParam(param: QueryParam) {
    let existingParam = this.getParam(param.name);
    if (existingParam) {
      existingParam.value = param.value;
    } else {
      this.params.push(param);
    }
  }

  clearParams() {
    this.params = [];
  }

  clear(data?: any) {
    Object.assign(this, {where: {}})
    return this;
  }

  set(key, value) {
    this.where[key] = value;
  }

  get(key) {
    return this.where[key];
  }

  remove(key) {
    delete this.where[key];
  }

  from() {
    if (this.total === 0) {
      return 0;
    }

    return ((this.page - 1) * this.limit) + 1;
  }

  to() {
    let all = this.page * this.limit;
    return all > this.total ? this.total : all;
  }

  canNext() {
    return this.page < this.totalPages();
  }

  canPrev() {
    return this.page > 1;
  }

  totalPages() {
    if (this.total) {
      return Math.ceil(this.total / this.limit);
    }

    return this.total;
  }

  transform(): any {
    return this;
  }
}
