import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {IMessagesResourceService, ResourcesService} from '../../../core/resources/resources.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { FormControl } from '@angular/forms';
import { RandomService } from '../../../shared/random/random.service';

@Component({
  selector: 'app-dispute-delete-charges-manage-dialog',
  templateUrl: './dispute-delete-charges-manage-dialog.component.html',
})
export class DeleteDisputeChargesManageDialog
  extends PageManageDialogComponent
  implements AfterViewInit, OnInit
{
  note: FormControl;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'dispute';
  deactivationMessage: string;
  actionText = 'Delete';
  randomString: string;
  deletePlacecholder = 'Type in generated string shown below';

  constructor(public randomService: RandomService,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<DeleteDisputeChargesManageDialog>) {
    super(dialogService, dialogRef)
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.note = new FormControl('');
    this.randomString = this.randomService.getRandomCheckString();
  }

  cancel() {
    this.closeDialog();
  }

  submit() {
    this.closeDialog({note: this.note.value});
  }
}
