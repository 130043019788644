import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { User } from '../../user/core/user';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.css']
})
export class UserModalComponent {

  public user: User;

  constructor(
    public dialogRef: MatDialogRef<UserModalComponent>
  ) { }

  public close() {
    this.dialogRef.close();
  }

  public getUserInitials(): string {
    return this.user.first_name.toUpperCase()[0] + this.user.last_name.toUpperCase()[0];
  }

}
