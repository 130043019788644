import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class ContractScheduleCommitmentGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.contract.contract-schedule-commitment-grid", userSettings);
  }

  defaultColumns = [
    {
      caption: 'Schedule Name',
      dataField: 'contract_schedule.schedule_name',
      alignment: 'left',
      width: 200
    },
    {
      caption: 'Commitment Name',
      dataField: 'commitment_name',
      alignment: 'left',
      width: 200
    },
    {
      caption: 'Description',
      dataField: 'commitment_description',
      alignment: 'left',
      width: 200
    },
    {
      caption: 'Contributory Service',
      dataField: 'contribute_services',
      alignment: 'left',
      width: 200
    },
    {
      caption: 'Term Type',
      dataField: 'schedule_commitment_type.value',
      alignment: 'left',
      width: 120
    },
    {
      caption: 'Term (Qty)',
      dataField: 'term',
      alignment: 'left',
      width: 100
    },
    {
      caption: 'Commit Amount',
      dataField: 'commit_per_term',
      cellTemplate: "commitAmountTemplate",
      alignment: 'left',
      width: 120
    },
    {
      caption: 'Total Commit',
      dataField: 'commit_total',
      cellTemplate: "totalCommitTemplate",
      alignment: 'left',
      width: 120
    },
    {
      caption: 'Effective Date',
      dataField: 'effective_date',
      dataType: 'date',
      cellTemplate: "effectiveDateTemplate",
      alignment: 'left',
      width: 140
    },
    {
      caption: 'End Date',
      dataField: 'termination_date',
      dataType: 'date',
      cellTemplate: "terminationDateTemplate",
      alignment: 'left',
      width: 140
    }];

  columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }
}
