import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {PermissionService} from "../../permissions/core/permission.service";
import {map} from "rxjs/operators";

@Injectable()
export class PermissionGuardService implements CanActivate {
  constructor(public permissionService: PermissionService,
              public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.permissionService.isAllowed(route.data['key'], 'View').pipe(
      map((allowed) => {
        if (!allowed) {
          this.router.navigateByUrl('/dash');
          return false;
        }
        return true;
      }));
  }
}
