import { IFilterLookupProvider } from "../../core/filter/filter-lookup.provider";
import { LocationService } from "./location.service";
import { Observable } from "rxjs";
import {map} from "rxjs/operators";
import Query from '../../core/query/query';

export class SubdivisionLookupProvider extends IFilterLookupProvider {
  constructor(public locationService: LocationService,
    public keyValuePairs?: boolean) {
    super();
  }

  readonly US_COUNTRY_CODE = 'US';

  findAll(query?: Query): Observable<any> {
    return this.locationService.findAllCountrySubdivisions(this.US_COUNTRY_CODE, query)
      .pipe(map((result: any) => {
        let entriesSortedASC = this.sortEntriesInAsc(result.items);

        if (this.keyValuePairs) {
          return entriesSortedASC.map((entry) => {
            return {
              key: entry.id,
              value: entry.abbreviation,
              name: entry.name
            };
          });

        } else {
          return entriesSortedASC.map((entry) => {
            return entry.name;
          });
        }

      }));
  }

  sortEntriesInAsc(entries) {
    return entries.sort((a, b) => {
      let textA = a.name.toUpperCase();
      let textB = b.name.toUpperCase();
      if (textA < textB) {
        return -1;
      } else if (textA > textB) {
        return 1
      }
      return 0;
    });
  }
}
