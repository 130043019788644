<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>

  <button
    mat-icon-button
    matTooltip="Back"
    (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>

  <span class="toolbar-details-title">{{ inventory?.unique_id }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>

  <button
    mat-icon-button
    *permission="{'action':'Create', 'key':'contracts'}"
    [disabled]="isAddContractTermsDisabled"
    [hidden]="isContractTermsHidden"
    (click)="addContractTerm()">
    <ca-icon [key]="'ADD_NEW'" [tooltip]="'New Contract Term'"></ca-icon>
  </button>

  <button
    mat-icon-button
    *permission="{'action':'Modify', 'key':'contracts'}"
    [disabled]="isEditContractTermsDisabled"
    [hidden]="isContractTermsHidden"
    (click)="editContractTerm()">
    <ca-icon [key]="'EDIT'" [tooltip]="'Edit Contract Term'"></ca-icon>
  </button>

  <button
    mat-icon-button
    *permission="{'action':'Delete', 'key':'contracts'}"
    [disabled]="isDeleteContractTermsDisabled"
    [hidden]="isContractTermsHidden"
    (click)="deleteContractTerm()">
    <ca-icon [key]="'DELETE'" [tooltip]="'Delete Contract Term'"></ca-icon>
  </button>

  <button
    *permission="{'action':'Create', 'key':'gl'}"
    mat-icon-button
    [disabled]="isAddGLDisabled"
    [hidden]="isAddGLHidden"
    (click)="addGL()">
    <ca-icon [key]="'ADD_NEW'" [tooltip]="'New'"></ca-icon>
  </button>

  <button
    *permission="{'action':'Create', 'key':'inventory'}"
    mat-icon-button
    [disabled]="isAddPricingDisabled"
    [hidden]="isAddPricingHidden"
    (click)="addPricing()">
    <ca-icon [key]="'ADD_NEW'" [tooltip]="'New'"></ca-icon>
  </button>

  <button
    *permission="{'action':'Modify', 'key':'inventory'}"
    mat-icon-button
    (click)="edit()"
    [hidden]="isEditInventoryHidden"
    [disabled]="isEditInventoryDisabled">
    <ca-icon [key]="'EDIT'" [tooltip]="'Edit'"></ca-icon>
  </button>

  <button
    mat-icon-button
    *permission="{ action: 'Create', key: 'orders' }"
    [hidden]="isCreateOrderHidden"
    [matMenuTriggerFor]="menu"
    [disabled]="!isCreateOrderEnabled()">
    <ca-icon [key]="'ORDER_TYPE'" [tooltip]="'Create Order'"></ca-icon>
  </button>

  <div class="vertical-divider" *ngIf="isPricingDocumentAction"></div>

  <app-document-manager-commands
    *ngIf="isPricingDocumentAction"

    [deleteDocumentActionHandler]="PRICING_MANAGER_SETTINGS.DELETE_PRICING_ACTION"
    [deleteDocumentDisablePredicate]="!pricingSelection"

    [uploadDocumentActionHandler]="PRICING_MANAGER_SETTINGS.UPLOAD_PRICING_ACTION"
    [uploadDocumentDisablePredicate]="false"

    [downloadDocumentActionHandler]="PRICING_MANAGER_SETTINGS.DOWNLOAD_PRICING_ACTION"
    [downloadDocumentDisablePredicate]="!pricingSelection"
  >
  </app-document-manager-commands>

  <button
    mat-icon-button
    (click)="csv()"
    [disabled]="isCSVDisabled"
    [hidden]="isCSVHidden"
  >
    <ca-icon [key]="'DOWNLOAD'" [tooltip]="'Download CSV'"></ca-icon>
  </button>

  <div class="vertical-divider" *ngIf="isDocumentAction"></div>

  <app-document-manager-commands
    *ngIf="isDocumentAction"

    [deleteDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DELETE_DOCUMENT_ACTION"
    [deleteDocumentDisablePredicate]="!documentSelection"

    [uploadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.UPLOAD_DOCUMENT_ACTION"
    [uploadDocumentDisablePredicate]="false"

    [downloadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DOWNLOAD_DOCUMENT_ACTION"
    [downloadDocumentDisablePredicate]="!documentSelection">

  </app-document-manager-commands>

  <div class="vertical-divider"></div>

  <button
    [disabled]="isFilterDisabled"
    [ngClass]="{'on' : isFilterOn}"
    (click)="toggleFilter()"
    mat-icon-button>
    <ca-icon [key]="'FILTER'" [tooltip]="'Filter'"></ca-icon>
  </button>

  <button
    [ngClass]="{'on' : isNotesOn}"
    (click)="toggleNotes()"
    mat-icon-button>
    <ca-icon [key]="notesCount > 0 ? 'NOTES' : 'NOTES_EMPTY'" [tooltip]="'Notes'"></ca-icon>
  </button>

  <button
    [ngClass]="{'on' : isHistoryOn}"
    (click)="toggleHistory()"
    mat-icon-button>
    <ca-icon [key]="'HISTORY'" [tooltip]="'History'"></ca-icon>
  </button>

  <mat-menu class="ca-user-menu" #menu="matMenu">
    <ng-container *ngFor="let item of adjustmentsKeys">
      <button mat-menu-item (click)="createOrder(item)" style="text-transform: capitalize">
        {{ item.split('_').join(' ').toLowerCase() }}
      </button>
    </ng-container>
  </mat-menu>
</mat-toolbar>
