import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentService } from '../core/document.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../../alert/alert.service';
// import { Headers, RequestOptions } from '@angular/http';
import { HttpHeaders, HttpRequest } from '@angular/common/http'
import { LoaderService } from "../../loader/loader.service";
import { LOOKUP_ENUM } from "../../../dictionary/core/lookup.enum";
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import Entity from "../../../core/entity.model";
import Query from '../../../core/query/query';


@Component({
  selector: 'app-document-manage-dialog',
  templateUrl: './document-manage-dialog.component.html',
  styleUrls: ['./document-manage-dialog.component.css']
})
export class DocumentManageDialogComponent implements OnInit {

  private _entityId;
  private parentId: any;

  get entityId() {
    return this._entityId;
  }

  set entityId(value) {
    this._entityId = value;
    if (value) {
      this.selectedEntity = this.entities.filter((x) => {
        return x.id === value;
      })[0];
    }

    this.internalEntityId = value;
  }

  @ViewChild('fileInput') fileInput;

  //public entity;
  public folder: string;
  public subfolder: string;
  public entityType;
  public entityMeta;
  public master;
  public masterId;
  public masterType;
  public description: string;
  public fileName: string = '';
  public isUploading: boolean = false;
  public progressMode = 'determinate';
  public files = [];
  public documentType;
  public entities: Array<Entity> = [];
  public selectedEntity: Entity;
  public lineItem: Entity;
  public mapMeta: any;
  public internalEntityId;

  public fileNameTaken: any = null;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "shared";

  constructor(
    public documentService: DocumentService,
    public dialogRef: MatDialogRef<DocumentManageDialogComponent>,
    public alert: AlertService,
    public loaderService: LoaderService,
  ) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    if (this.internalEntityId) {
      this.onEntitySelectionChange(this.internalEntityId, true);
    }
  }

  fileNameCheckHandler = (result) => this.fileNameTaken = result.fileExists;

  close(result?: any) {
    this.dialogRef.close(result);
  }

  onFileSelect(event) {
    if (event.target && event.target.files.length) {
      this.fileName = event.target.files[0].name;
      this.files = event.target.files;

      if (this.selectedEntity) {
        this
          .isFileNameTaken(this.selectedEntity.id, this.selectedEntity.type, this.fileName)
          .subscribe(this.fileNameCheckHandler);
      }
      else {
        this
          .isFileNameTaken(this.masterId, this.masterType, this.fileName)
          .subscribe(this.fileNameCheckHandler);
      }

    }
    else {
      this.fileName = '';
      this.files = [];
    }
  }

  onDrop(files) {
    if (files && files.length) {
      this.fileName = files[0].name;
      this.files = files;

      if (this.selectedEntity) {
        this
          .isFileNameTaken(this.selectedEntity.id, this.selectedEntity.type, this.fileName)
          .subscribe(this.fileNameCheckHandler);
      }
      else {
        this
          .isFileNameTaken(this.masterId, this.masterType, this.fileName)
          .subscribe(this.fileNameCheckHandler);
      }
    } else {
      this.fileName = '';
      this.files = [];
    }
  }

  isFileNameTaken(entityId: number, entityType: number, fileName: string) {

    this.fileNameTaken = null;

    const query = new Query({
      where: {
        entity_id: entityId,
        entity_type: entityType,
        file_name: fileName,
      },
    });

    return this
      .documentService
      .isFileNameTaken(entityId, query);
  }

  onEntitySelectionChange(event, runAtInit?: boolean) {
    let id = runAtInit ? parseInt(event) : parseInt(event.value);
    let entity = this.entities.filter((x) => {
      return x.id == id;
    })[0];

    if (entity) {
      this.entityType = entity.type;
      this._entityId = entity.id;
      this.selectedEntity = entity;

      this
        .isFileNameTaken(this.selectedEntity.id, this.selectedEntity.type, this.fileName)
        .subscribe(this.fileNameCheckHandler);
    }
  }

  onLineItemSelectionChange(event, entity) {
    let id = parseInt(event.value);
    let lineItem = entity.entities.filter((x) => {
      return x.id === id;
    })[0];

    if (lineItem) {
      this.entityType = lineItem.type;
      this.parentId = this._entityId;
      this._entityId = lineItem.id;
      this.lineItem = lineItem;

      this
        .isFileNameTaken(lineItem.id, lineItem.type, this.fileName)
        .subscribe(this.fileNameCheckHandler);
    }
  }

  onSubmit(files, description: string) {

    let noopMetaFn = (entity) => {
      return entity;
    };

    let mapFnMeta = this.mapMeta || noopMetaFn;

    this.entityMeta = this.lineItem ? mapFnMeta(this.lineItem, this.master) : mapFnMeta(this.selectedEntity, this.master);

    if (files.length) {
      this.create(files, description);
    }
    else {
      this.alert.error('', this.messages.get('NO_FILE'));
    }
  }

  create(files, description) {

    this.loaderService.displayLoader();

    // TODO: needs refactoring

    let fileList: FileList = files;
    let file: File = fileList[0];

    this.isUploading = true;
    this.progressMode = 'indeterminate';

    this.masterId = parseInt(this.masterId);

    let folderPath = [this.folder];
    if (this.masterId && this.masterId !== this.entityId) {
      folderPath.push(this.masterId);
    }
    if (this.parentId && this.parentId !== this.entityId) {
      folderPath.push(this.parentId);
    }
    folderPath.push(this.entityId);

    if (this.subfolder && this.entityId && (this.subfolder !== this.entityId.toString() && this.subfolder !== this.entityId.toString() + "/")) {
      folderPath.push(this.subfolder);
    }

    if (folderPath.length) {
      this.folder = folderPath.join("/");
    }

    let formData: FormData = new FormData();
    formData.append('files', file, file.name);
    formData.append('entity_meta', JSON.stringify(this.entityMeta));
    formData.append('entity_type', this.entityType || this.masterType);
    formData.append('entity_id', this._entityId || this.masterId);
    formData.append('master_type', this.masterType);
    formData.append('master_id', this.masterId);
    formData.append('folder', this.folder);
    //formData.append('entity', JSON.stringify(this.entity));
    formData.append('description', description || 'N/A');
    formData.append('file_type', LOOKUP_ENUM.DOCUMENT_TYPE.SUPPORTING_DOCUMENT.toString());
    formData.append('subfolder', this.subfolder);

    if (this.documentType) {
      formData.append('file_type', this.documentType);
    }

    let headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data');

    let options = new HttpRequest('PUT', '/', { headers: headers });

    this.documentService.upload(this._entityId, formData)
      .subscribe(
        (result) => {
          this.loaderService.hideLoader();
          this.dialogRef.close(result);
        },
        (error) => {
          this.loaderService.hideLoader();
          this.alert.error('', this.messages.get('UPLOAD_ERROR'));
          this.progressMode = 'determinate';
          this.isUploading = false;
        }
      );

  }

  isSaveButtonDisabled() {
    if (this.fileNameTaken == null || this.fileNameTaken || this.isUploading
      || !this.files.length || (this.entities.length > 0 && !this.selectedEntity)) {
        return true
      } else {
        return false
      }
  }

}
