export const svcOrderTermsAndConditionFilterContext = {
  filters: [
    {
      field: 'name',
      label: 'Name',
      type: 'string',
      index: 1
    },
    {
      field: 'section_id',
      label: 'Section ID',
      type: 'string',
      index: 2
    },
    {
      field: 'text',
      label: 'Contract Text',
      type: 'string',
      index: 3
    },
    {
      field: 'abstract',
      label: 'Abstract',
      type: 'string',
      index: 4
    },

  ]
};
