export const disputeFilterContext = {
  filters: [
    {
      field: 'dispute_id',
      label: 'Dispute ID',
      type: 'string',
      index: 1
    },
    {
      field: 'status_obj.key',
      label: 'Dispute Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'disputeStatus',
      index: 2
    },
    {
      field: 'withheld_status_obj.key',
      label: 'Withheld Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'disputeWithheldStatus',
      index: 3
    },
    {
      field: 'vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 4,
      visible: true
    },
    {
      field: 'disp_stat_dt',
      label: 'Last Update',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 5
    },
    {
      field: 'created_at',
      label: 'Filed Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 6
    },
    {
      field: 'total_amount',
      label: 'Billed Charges ',
      type: 'negativeNumber',
      index: 7
    },
    {
      field: 'calculated_amount',
      label: 'Calculated Charges ',
      type: 'negativeNumber',
      index: 8
    },
    {
      field: 'disputed_amount',
      label: 'Total Dispute ',
      type: 'negativeNumber',
      index: 9
    },
    {
      field: 'dispute_value_awarded',
      label: 'Dispute Awarded ',
      type: 'negativeNumber',
      index: 10
    },
    {
      field: 'payback_amount',
      label: 'Payback Amount ',
      type: 'negativeNumber',
      index: 11
    },
    // {
    //   field: 'subclient_ids.value',
    //   label: 'Subclient',
    //   type: 'lookup',
    //   lookupProviderName: 'subclient',
    //   index: 15,
    //   visible: true
    // },
    {
      field: 'currency.currency',
      label: 'Currency',
      type: 'lookup',
      lookupProviderName: 'currency',
      index: 16,
      visible: true
    }
  ]
};
