import {Injectable} from '@angular/core';

import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class ShortInvoiceChargeGridService extends GridService {
  defaultColumns = [
    {
      caption: 'Charge Amount',
      dataField: 'chg_amt',
      dataType: 'currency',
      alignment: 'right',
      cellTemplate: 'currencyTemplate',
      visible: true,
      width: 120
    },
    {
      caption: 'Beg Charge Date',
      dataField: 'beg_chg_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      visible: true,
      width: 150
    },
    {
      caption: 'End Charge Date',
      dataField: 'end_chg_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      visible: true,
      width: 150
    },
    {
      caption: 'Charge Description 1',
      dataField: 'chg_desc_1',
      visible: true,
      width: 200
    }
  ];

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.cost.invoice-short-charges-grid', userSettings);
  }
}




