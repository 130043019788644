import {UserSessionModel} from "./models/user-session-model";
import {UserSessionService} from "./services/user-session.service";


export default class Auth {
  static getSession(): UserSessionModel {
    return new UserSessionService().getSession();
  }

  static removeSession() {
    return new UserSessionService().removeSession();
  }
}
