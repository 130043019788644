import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class QuotingDetailsRequestsGridService extends GridService {

    constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
        super('app.quoting.quoting-grid', userSettings);
    }

    // We don't need this for now, grid for offers is in details-grid.service.ts
    defaultColumns = [
    ];

    /*columns() {

        let settings = this.userSettings.entrySettings(this.name);
        if (settings && settings.columns) {
            return settings.columns;
        }

        return this.defaultColumns;
    }*/
}
