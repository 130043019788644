export class Site {
  id: number;
  site_id: string;
  site_type: number;
  parent_id: number;
  vendor_id: number;
  customer_id: number;
  building_id: number;
  floor: number;
  room: string;
  building: any;
  vendor: any;
  customer: any;
  type: any;
  parent: any;
  site_owner_category: number;
  custom_values?: any

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
