<ng-container *ngIf="customer">
  <mat-toolbar color="accent" mat-scroll-shrink>

    <span class="sneak-peek-title">{{ customer?.company }}</span>

    <span class="example-fill-remaining-space"></span>

    <button mat-icon-button (click)="closeSide()">
      <ca-icon [key]="'CLOSE'"></ca-icon>
    </button>
  </mat-toolbar>

  <div class="ca-card ca-card-sneak-peek">
    <mat-card caCard>
      <mat-card-content>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Company</label>
            <p>
              {{ customer?.company || 'N/A'}}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Account Number</label>
            <p>
              {{ customer?.account_number || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Master Number</label>
            <p>
              {{ customer?.master_customer_number || 'N/A'}}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Corporate Address</label>
            <div>
              <p *ngIf="customer?.corporate_address?.address1">
                {{ customer?.corporate_address?.address1}}
              </p>
              <p *ngIf="customer?.corporate_address2">
                {{ customer?.corporate_address2 }}
              </p>
              <p *ngIf="customer?.corporate_address3">
                {{ customer?.corporate_address3 }}
              </p>
              <p>
                {{ customer?.corporate_address?.city }}&nbsp;
                {{ customer?.corporate_address?.subdivision?.abbreviation }}&nbsp;
                {{ customer?.corporate_address?.postal_code
              }}
              </p>
              <p *ngIf="customer?.corporate_address?.country">
                {{ customer?.corporate_address?.country?.name }}
              </p>
            </div>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Billing Address</label>
            <div>
              <p *ngIf="!customer || !customer.billing_address">N/A</p>
              <p *ngIf="customer?.billing_address?.address1">
                {{ customer?.billing_address?.address1}}
              </p>
              <p *ngIf="customer?.billing_address2">
                {{ customer?.billing_address2 }}
              </p>
              <p *ngIf="customer?.billing_address3">
                {{ customer?.billing_address3 }}
              </p>
              <p>
                {{ customer?.billing_address?.city }}&nbsp;
                {{ customer?.billing_address?.subdivision?.abbreviation }}&nbsp;
                {{ customer?.billing_address?.postal_code
              }}
              </p>
              <p *ngIf="customer?.billing_address?.country">
                {{ customer?.billing_address?.country?.name }}
              </p>
            </div>
          </div>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="!customer">
  <div class="ca-empty-state-container">
    <p class="card-message">
      No customer selected
    </p>
  </div>
</ng-container>