export const glStringFilterContext = {
  filters: [
    {
      field: 'full_string_formatted',
      label: 'Full GL String',
      type: 'string',
      index: 1
    },
    {
      field: 'gl_code_type',
      label: 'GL Code Type',
      type: 'boolean',
      trueLabel: "Verified",
      falseLabel: "Non-verified",
      index: 2
    },
    {
      field: 'date_added',
      label: 'Date added',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 3
    },
    {
      field: 'status',
      label: 'Status',
      type: 'boolean',
      trueLabel: "Active",
      falseLabel: "Inactive",
      index: 4
    },
    {
      field: 'full_name',
      label: 'Created By',
      type: 'lookup',
      lookupProviderName: 'createdBy',
      index: 5
    }
  ]
};
