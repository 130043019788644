<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Run Audits</h1>
</div>

<mat-dialog-content mat-dialog-content class="ca-dialog-content">
  <div class="app-content-list app-content-list-no-scroll app-content-list-no-pager" style="height: 350px">
    <dx-data-grid #auditGrid
                  (onSelectionChanged)="onSelectionChanged($event)"
                  [showColumnLines]="true"
                  [columns]="columns"
                  [showRowLines]="true"
                  [showBorders]="true"
                  [dataSource]="audits">
      <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">

        <a class="selection-badge grid-badge"
           matTooltip="Selection"
           [ngClass]="{'active': selectionActive}"
        >
          {{ selectedRows.length }}
        </a>
      </div>
      <div *dxTemplate="let d of 'selectionBadgeCellTemplate'"></div>
      <dxo-sorting mode="none"></dxo-sorting>
      <dxo-paging [enabled]="false" visible="false"></dxo-paging>
    </dx-data-grid>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
  </div>

  <button mat-button type="submit" (click)="close()">
    Cancel
  </button>

  <button mat-button type="submit" (click)="run()" [disabled]="selectedRows.length === 0 || isRunButtonDisabled">
    Run
  </button>
</mat-dialog-actions>
