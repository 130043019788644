import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Contract } from '../../core/contract';
import {StateContext, Store} from "@ngxs/store";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertService} from "../../../shared/alert/alert.service";
import {HistoryService} from "../../../shared/history/history.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";

@Component({
    selector: 'app-contract-details-toolbar',
    templateUrl: './contract-details-toolbar.component.html',
    styleUrls: ['./contract-details-toolbar.component.scss']
  })

  export class ContractDetailsToolbarComponent implements OnInit {
 
    messages: IMessagesResourceService;
    readonly MESSAGES_MODULE: string = 'contract';

    readonly DOCUMENT_MANAGER_SETTINGS = {
      DELETE_DOCUMENT_ACTION: this.deleteDocument.bind(this),
      UPLOAD_DOCUMENT_ACTION: this.uploadDocument.bind(this),
      DOWNLOAD_DOCUMENT_ACTION: this.downloadDocument.bind(this)
    }
    
    @Input() contract: Contract;
    @Input() isSummaryTab = false;
    @Input() isSchedulesTab = false;
    @Input() isEditScheduleDisabled = true;
    @Input() isInventoryTab = false;
    @Input() isUnlinkInventoryDisabled = true;
    @Input() isTermsConditionsTab = false;
    @Input() isEditTCDisabled = true;
    @Input() isAttachmentsTab = false;
    @Input() documentNotSelected = true;
    @Input() isRatesTab = false;
    @Input() isSaveDisabled = false;
    @Input() isDeleteRateDisabled = true;
    @Input() isFilterOn: boolean = false;
    @Input() isFilterDisabled: boolean = true;
    @Input() isNotesOn: boolean = false;
    @Input() notesCount: number = 0;
    @Input() isHistoryOn = false;
    @Input() hasSchedulesWithRates = false;

    @Output() editContractShellRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() createScheduleRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() editScheduleRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteScheduleRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() addInventoryRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() unlinkInventoryRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() addNewTCRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() editTCRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteTCRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() addRateRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveRatesRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteRateRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteDocumentRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() uploadDocumentRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() downloadDocumentRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() csvRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() gridSettingsRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() toggleFilterRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() toggleNotesRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() toggleHistoryRequested: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private readonly store: Store,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        
        public alertService: AlertService,
        public historyService: HistoryService
      ) {
        this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
      }
    
    ngOnInit(): void {
        this.contract = this.activatedRoute.parent.snapshot.data.contract;
    }

    editContractShell() {
      this.editContractShellRequested.emit();
    }

    createSchedule() {
      this.createScheduleRequested.emit();
    }

    editSchedule() {
      this.editScheduleRequested.emit();
    }

    deleteSchedule() {
      this.deleteScheduleRequested.emit();
    }

    addInventory() {
      this.addInventoryRequested.emit();
    }

    removeInventory() {
      this.unlinkInventoryRequested.emit();
    }

    addNewTC() {
      this.addNewTCRequested.emit();
    }

    editTC() {
      this.editTCRequested.emit();
    }

    deleteTC() {
      this.deleteTCRequested.emit();
    }

    downloadCsv() {
      this.csvRequested.emit();
    }

    addRateData() {
      this.addRateRequested.emit();
    }

    saveRates() {
      this.saveRatesRequested.emit();
    }

    deleteRate() {
      this.deleteRateRequested.emit();
    }

    uploadDocument() {
      this.uploadDocumentRequested.emit();
    }

    downloadDocument() {
      this.downloadDocumentRequested.emit();
    }

    deleteDocument() {
      this.deleteDocumentRequested.emit();
    }

    gridSettings() {
      this.gridSettingsRequested.emit();
    }

    toggleFilter() {
      this.toggleFilterRequested.emit();
    }

    toogleNotes() {
      this.toggleNotesRequested.emit();
    }

    toggleHistory() {
      this.toggleHistoryRequested.emit();
    }

    back() {
      this.router.navigate(['/contract', this.contract.vendor_id, 'info']);
    }
  }