<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{formTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content app-site-manage-dialog" *ngIf="form">
  <form class="ca-form" [formGroup]="form" fxLayout="column">

    <div class="flex-container" fxLayout>
      <div class="flex-item"
           [ngClass]="(!fields || fields && fields.length) ? 'caGapRight' : ''"
           fxFlex="{{ (!fields || fields && fields.length) ? '70%' : '100%'  }}">

        <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
          <div class="flex-item caGap" fxFlex>
            <mat-form-field caInput>
              <input matInput placeholder="Name" required formControlName="site_id">
              <mat-hint *ngIf="form.hasError('required', ['site_id']) && form.controls.site_id.touched" class="error-class">
                {{VALIDATION.REQUIRED_ERROR}}
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
          <div  class="flex-item caGap caGapRight" fxFlex="60" >
            <ca-building-picker caInput
                                [disabled] = "viewOnly"
                                placeholder="Building"
                                formControlName="building_id"
                                [initialItem]="site?.building" required="true"
                                [pickerForm]="form"></ca-building-picker>
            <mat-hint *ngIf="form.hasError('required', ['building_id']) && form.controls.building_id.touched"
                      class="ca-select-hint">{{VALIDATION.REQUIRED_ERROR}}
            </mat-hint>
          </div>
          <div class="flex-item caGap"  fxFlex >
            <ca-lookup-model-picker caInput
                                    [pickerForm]="form"
                                    [disabled] = "viewOnly"
                                    [lookupModel]="SITE_OWNER_CATEGORY_LOOKUP_MODEL"
                                    formControlName="site_owner_category"
                                    required="true"
                                    placeholder="Site Owner Category"
                                    [siteType]="true"
                                    (onSelectionChange)="onOwnerChanged($event)">
            </ca-lookup-model-picker>
            <mat-hint class="ca-select-hint"
                      *ngIf="form.hasError('required', ['site_owner_category']) && form.controls.site_owner_category.touched">
              {{VALIDATION.REQUIRED_ERROR}}
            </mat-hint>
          </div>
        </div>

        <div class="flex-container-3" fxLayout="row wrap" fxLayout.sm="column">
          <div class="flex-item building-address-container caGap caGapRight mb-24" fxFlex>
            <div class="ca-empty-state-container site-building-address-empty" *ngIf="!buildingAddress">
              <p class="card-message">
                {{NO_BUILDING_SELECTED}}
              </p>
            </div>
            <div class="site-building-address" *ngIf="buildingAddress">
              <p>
                <span style="display: block">{{buildingAddress?.address1}}</span>
                <span style="display: block">{{buildingAddress?.city}},
                  {{buildingAddress?.subdivision ? buildingAddress?.subdivision.abbreviation : 'N/A'}}
                  {{buildingAddress?.postal_code}}</span>
                <span>{{buildingAddress?.country ? buildingAddress?.country.name : 'N/A'}}</span>
              </p>
            </div>
          </div>
          <div fxFlex fxLayout="column customer-vendor-container" fxLayout.sm="column"
            class="customer-vendor-container"
          >

              <ca-customer-picker
                caInput
                [initialItemId]="site.customer_id"
                formControlName="customer_id"
                placeholder="Customer"
                [searchEnabled]="true"
                [pickerForm]="form"
                [disabled]="viewOnly || siteType !== SITE_TYPES_ENUM.CUSTOMER"
                [clearEnabled]="true">
              </ca-customer-picker>

              <ca-vendor-picker
                [initialItemId]="site.vendor_id"
                caInput
                formControlName="vendor_id"
                [pickerForm]="form"
                placeholder="Vendor"
                [disabled]="viewOnly || siteType !== SITE_TYPES_ENUM.VENDOR"
                [clearEnabled]="true">
              </ca-vendor-picker>

          </div>
        </div>
        <div class="flex-container" fxLayout="row wrap">
          <div class="flex-item caGap caGapRight" fxFlex="25%">
            <mat-form-field caInput>
              <input matInput type="number" placeholder="Floor" formControlName="floor">
              <mat-hint *ngIf="form.hasError('range', ['floor']) && form.controls.floor.touched" class="error-class">
                {{RangeErrorMessage('1','200')}}
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="flex-item caGap" fxFlex="25%">
            <mat-form-field caInput>
              <input matInput placeholder="Room" formControlName="room">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="fields && fields.length"
           fxFlex="300px" fxLayout="column"
           class="custom-fields-wrapper"
      >
        <ca-custom-fields
          [form] = "this.form"
          [customFieldService]="this.siteService"
          [customField]="this.site"
          [fields]="fields"
          [categories]="categories"
          [showCategory]="showCategory"
          [clearEnabled]="true"
          [onlyRequiredCustomFields] = "false"
        ></ca-custom-fields>
      </div>
    </div>

  </form>
</div>

<mat-dialog-actions class="ca-dialog-action" *ngIf="!viewOnly">
  <div class="example-fill-remaining-space">
    <ca-confirm-delete-button #genericButton *ngIf="isUpdate && deleteSitePermission" [confirmText]="'Delete?'"
                              (deleteRequested)="deleteSite($event)"></ca-confirm-delete-button>
  </div>
  <button #cancelButton mat-button type="submit" (click)="cancel()">
    Cancel
  </button>
  <button #saveButton mat-button type="submit" (click)="onSubmit(form)" [disabled]="form?.invalid">Save
  </button>
</mat-dialog-actions>

<mat-dialog-actions class="ca-dialog-action" *ngIf="viewOnly">
  <div class="example-fill-remaining-space"></div>

  <button mat-button type="submit" (click)="closeDialog()">
    Cancel
  </button>

  <button  *permission="{'action':'Modify', 'key':'sites'}" mat-button type="submit" (click)="editForm()">
    Edit
  </button>
</mat-dialog-actions>
