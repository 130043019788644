<div *ngIf="charge">
  <mat-toolbar color="accent" mat-scroll-shrink>

    <span class="sneak-peek-title">Charge Info</span>

    <span class="example-fill-remaining-space"></span>

    <button mat-icon-button (click)="closeSide()">
      <ca-icon [key]="'CLOSE'"></ca-icon>
    </button>
  </mat-toolbar>
  <div class="ca-card ca-card-sneak-peek">
    <mat-card caCard>
      <mat-card-content>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Vendor</label>
            <p>
              {{ charge?.vendor?.name || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Account</label>
            <p>
              {{ charge?.acct_level_1 || 'N/A'}}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Invoice Number</label>
            <p>
              {{ charge?.invoice?.sp_inv_num || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Invoice Date</label>
            <p>
              {{ (charge?.invoice?.inv_date | caDate) || 'N/A'}}
            </p>
          </div>
        </div>

      </mat-card-content>

    </mat-card>
  </div>
  <div class="ca-card ca-card-sneak-peek">
    <mat-card caCard>
      <mat-card-content>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Charge Amount</label>
            <p>
              {{ (charge?.chg_amt | caCurrency: charge?.currency ) || 'N/A'}}
            </p>
          </div>
        </div>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Charge Type</label>
            <p>
              {{ charge?.chg_class || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Service ID</label>
            <p style="word-break: break-word">
              {{ charge?.sp_serv_id || 'N/A'}}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Charge Code 1</label>
            <p>
              {{ charge?.chg_code_1 || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Charge Description 1</label>
            <p>
              {{ charge?.chg_desc_1 || 'N/A'}}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Beg Charge Date</label>
            <p>
              {{ (charge?.beg_chg_date | caDate) || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>End Charge Date</label>
            <p>
              {{ (charge?.end_chg_date | caDate) || 'N/A'}}
            </p>
          </div>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</div>

<div *ngIf="!charge" class="ca-empty-state-container">
  <p class="small-div-message">No item selected.</p>
</div>

