export const customerInventoryFilterContext = {

  filters: [
    {
      field: 'customer_circuit_id',
      label: 'Customer Circuit ID',
      type: 'string',
      index: 1,
      visible: true
    },
    {
      field: 'customer.company',
      label: 'Customer',
      type: 'lookup',
      lookupProviderName: 'customer',
      index: 2,
      visible: true
    },
    {
      field: 'product.value',
      label: 'Product',
      type: 'string',
      index: 3,
      visible: true
    },
    {
      field: 'customer_inventory_topology.key',
      label: 'Circuit Type',
      type: 'lookup',
      lookupProviderName: 'topology',
      editor: 'checkboxList',
      index: 4,
      visible: true
    },
    {
      field: 'circuit_bandwidth.value',
      label: 'Circuit Bandwidth',
      type: 'lookup',
      lookupProviderName: 'bandwidth',
      index: 5,
      visible: true
    },
    {
      field: 'port_bandwidth.value',
      label: 'Port Bandwidth',
      type: 'lookup',
      lookupProviderName: 'bandwidth',
      index: 6,
      visible: true
    },
    {
      field: 'bandwidth_model',
      label: 'Bandwidth Model ',
      type: 'lookup',
      lookupProviderName: 'bandwidthModel',
      editor: 'checkboxList',
      index: 7,
      visible: true
    },
    {
      field: 'install_date',
      label: 'Install Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 8,
      visible: true
    },
    {
      field: 'disconnect_date',
      label: 'Disconnect Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 9,
      visible: true
    },
    {
      field: 'customer_inventory_status.key',
      label: 'Status ',
      type: 'lookup',
      lookupProviderName: 'status',
      editor: 'checkboxList',
      index: 10,
      visible: true
    },
    {
      field: 'term',
      label: 'Term ',
      type: 'number',
      index: 11,
      visible: true,
      max: 60
    },
    {
      field: 'contract_expiration_date',
      label: 'Contract End Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 12,
      visible: true
    },
    {
      field: 'contract_sign_date',
      label: 'Contract Sign Date ',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 13,
      visible: false
    },
    {
      field: 'contract_id',
      label: 'Contract ID',
      type: 'string',
      index: 14,
      visible: true
    },
    {
      field: 'billing_start_date',
      label: 'Billing Start Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 15,
      visible: true
    },
    {
      field: 'billing_end_date',
      label: 'Billing End Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 14,
      visible: true
    },
    {
      field: 'currency.currency',
      label: 'Currency',
      type: 'lookup',
      lookupProviderName: 'currency',
      index: 16,
      visible: true
    },
    {
      field: 'siteA.site_id',
      label: 'Site A (Carrier Location)',
      type: 'lookup',
      lookupProviderName: 'site',
      index: 17,
      visible: true,
    },
    {
      field: 'siteZ.site_id',
      label: 'Site Z (Customer Location)',
      type: 'lookup',
      lookupProviderName: 'site',
      index: 18,
      visible: true,
    },
  ]

};
