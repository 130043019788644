import { Injectable } from '@angular/core';
import {FilterService} from "../../core/filter/filter.service";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {spidCodingFilterContext} from "./spid-coding-filter.context";

@Injectable()
export class SpidCodingFilterService extends FilterService{


  constructor(public userSettingsService: UserSettingsService) {
    super("app.gl.gl-spid-without-gl-codes-coding-filter", spidCodingFilterContext, userSettingsService);
  }

}
