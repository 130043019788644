<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Move Vendor Entity</h1>
</div>
<div mat-dialog-content class="ca-dialog-content">
  <form class='ca-form' [formGroup]="form">
      <div class="flex-container" fxLayout="row">
        <div class="flex-item form-group no-bottom-padding" fxFlex="100%">
          <ca-vendor-picker
            caInput
            formControlName="vendor_id"
            [pickerForm]="form"
            placeholder="Vendor"
            [clearEnabled]="true"
            customMethod="findAll">
          </ca-vendor-picker>
        </div>
    </div>
  </form>
</div>
<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="submit" (click)="close()">
      Cancel
  </button>
  <button mat-button type="submit" (click)="moveEntity(vendor, entity)" [disabled]="form.invalid">Save</button>
</mat-dialog-actions>
