import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Customer } from '../../core/customer';

@Component({
  selector: 'ca-customer-sneak-peek',
  templateUrl: 'customer-sneak-peek.component.html',
})
export class CustomerSneakPeekComponent {
  @Input() customer: Customer;

  @Output() closeRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();

  constructor() { }

  deleteCustomer() {
    this.deleteRequested.emit(this.customer);
  }

  closeSide() {
    this.closeRequested.emit();
  }
}
