<app-invoice-details-toolbar
  (toggleNotesRequested)="toggleSider(SECTIONS.NOTES_SECTION_NAME)"
  [isNotesOn]="($activeSiderSection | async) == SECTIONS.NOTES_SECTION_NAME"
  (toggleHistoryRequested)="toggleSider(SECTIONS.HISTORY_SECTION_NAME)"
  [isHistoryOn]="($activeSiderSection | async) == SECTIONS.HISTORY_SECTION_NAME"
  (addDocumentRequested)="invokeAddDocument()"
  (downloadDocumentRequested)="invokeDownloadDocument()"
  (checkDocumentRequested)="checkIsDocumentSelected()"
  (deleteDocumentRequested)="invokeDeleteDocument()"
  (editDocumentRequested)="invokeEditDocument()"
  (backRequested)="back()"

  [isDropDownFileHidden]="false"
  [isCSVDisabled]="true"
  [isCSVHidden]="true"
  [isDownloadPDFHidden]="true"
  [isDownloadContainerHidden]="true"
  [isDoNotProcessHidden]="true"
  [isGoToRuleExecutionPageHidden]="false"
  [isDocumentTypeHidden]="true"
  [isAddDisputeHidden]="true"
  [isDeleteInvoiceHidden]="false"
  [isOnHoldHidden]="false"
  [isOnHoldDisabled]="true"
  [isChangeAdjustmentHidden]="true"
  [isGLChargeViewSwitchHidden]="true"
  [isAddDirectGLHidden]="true"
  [isEditDirectGLHidden]="true"
  [isGridSettingsHidden]="false"
  [isGridSettingsDisabled]="true"
  [isFilterDisabled]="true"
  [isFilterHidden]="false"
  [isSelectedDocument]="this.isSelectedDocumentRow"
  [isSelectedSystemFile]="this.isSelectedSystemFile"
  [isDropDownUploadDocumentDisabled] = "false"
  [isDropDownCsvDisabled] = "true"
  [isDropDownCsvHidden] = "false"
  [notesCount]="notesCount"
></app-invoice-details-toolbar>

<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-invoice-details-tabs [activeTabIndex]="8" [invoice]="invoice"></app-invoice-details-tabs>
  <div class="app-content app-content-list tabbed-content tabbed-content-no-scroll-except-first-ch" style="top: 0;">
    <div class="tabs-body tabs-disable-scroll">

      <dx-data-grid
        #documentsGrid
        class="app-grid-with-pager"
        [height]="'100%'"
        [columns]="documentColumns"
        [dataSource]="documents"
        (onSelectionChanged)="onDocumentSelectionChange($event)"
      >
        <div *dxTemplate="let d of 'onHoldCellTemplate'">
          <div caGridTooltip>{{ d.value ? 'Yes' : 'No' }}</div>
        </div>
      </dx-data-grid>
      <ca-pager
        class="app-pager ca-default-border-top"
        [gridPager]="defaultGridPager"
        [pageContext]="invoiceDocumentService"
        [query]="query"
        (onChange)="onPageChange()"
      ></ca-pager>
    </div>
  </div>
  <mat-sidenav #panelSide
               [mode]="'side'"
               position="end"
               [opened]="false">
    <ca-history
      #history
      [entityId]="invoice?.invoice_id"
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME) }"
      [entityType]="'invoice'"
      [invoice]="invoice"
    ></ca-history>
    <ca-notes
      #notes
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
      [entityId]="invoice?.invoice_id"
      [entityType]="SYSTEM_MODULE.INVOICE"
      (countChanged)="onNotesCountChanged($event)"
      (notesCountChanged)="getDNPInfo($event)"
    >
      <ca-account-notes [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                        [entityId]="invoice?.account?.id"
                        [entityType]="SYSTEM_MODULE.ACCOUNT"
                        [entityName]="'Account'"
                        [enableReply]="false"></ca-account-notes>
    </ca-notes>
  </mat-sidenav>
</mat-sidenav-container>
