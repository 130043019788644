import {Injectable} from "@angular/core";
import {BaseService} from "../../core/base.service";
import {ChargesCallCost} from "./charges-call-cost.model";
import {Restangular} from "ngx-restangular";

@Injectable()
export class ChargesCallCostService extends BaseService<ChargesCallCost> {
  constructor(restangular: Restangular) {
    super('charges_call_cost', restangular);
  }

  getDistinctVendors() {
    return this.service().all(this.name).all('getDistinctVendors').customGET(null);
  }

  getCallCostReport(query) {
    return this.service().all(this.name).all('getCallCostReport').customGET(null, this.toFilter(query));
  }
}
