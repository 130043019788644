<app-invoice-details-toolbar
  [invoice]="invoice"
  [isFilterDisabled]="false"

  (toggleFilterRequested)="toggleSider(SECTIONS.FILTER_SECTION_NAME)"
  [isFilterOn]="($activeSiderSection | async) == SECTIONS.FILTER_SECTION_NAME"
  (toggleNotesRequested)="toggleSider(SECTIONS.NOTES_SECTION_NAME)"
  [isNotesOn]="($activeSiderSection | async) == SECTIONS.NOTES_SECTION_NAME"
  (toggleHistoryRequested)="toggleSider(SECTIONS.HISTORY_SECTION_NAME)"
  [isHistoryOn]="($activeSiderSection | async) == SECTIONS.HISTORY_SECTION_NAME"

  [isCSVDisabled]="false"
  (downloadCSVRequested)="onDownloadCSVRequested()"

  [isDoNotProcessDisabled]="isDoNotProcessDisabled"
  [isAddDisputeDisabled]="isAddDisputeDisabled"
  [isAddDisputeHidden]="false"
  (addDisputeRequested)="addDispute()"

  (openGridSettingsRequested)="onOpenGridSettingsRequested()"

  (createDirectGLRequested)="manageDirectGL(false)"
  (editDirectGLRequested)="manageDirectGL(true)"
  [isAddDirectGLDisabled]="isGLAddDisabled"
  [isEditDirectGLDisabled]="isGLEditDisabled"

  (createNewRateAuditRequested)="createNewRateAudit()"
  [isAddAuditHidden]="false"
  [isAddAuditDisabled]="!selectedCharges.length"

  (changeAdjustmentRequested)="changeAdjustment($event)"
  [isChangeAdjustmentDisabled]="!selectedCharges.length || !canChangeAdjustment"

  [isDownloadContainerHidden]="true"
  [isDoNotProcessHidden]="true"
  [isGoToRuleExecutionPageHidden]="false"
  [isDocumentTypeHidden]="true"
  [isDeleteInvoiceHidden]="false"
  [isOnHoldHidden]="false"
  [isOnHoldDisabled]="true"
  [isGLChargeViewSwitchHidden]="true"
  [isGridSettingsDisabled]="false"

  [isDropDownFileHidden]="false"
  [isDropDownCsvHidden]="false"
  [isDropDownCsvDisabled]="false"
  [isDropDownUploadDocumentDisabled]="true"

  [isDownloadPDFHidden]="true"
  [isCSVHidden]="true"
  [notesCount]="notesCount"

  (addManualAdjustmentRequested)="addNewCharge()"
  [isManualAdjustmentDisabled]="isManualAdjustmentDisabled"
  [isManualAdjustmentHidden]="false"

  (deleteManualAdjustmentChargesRequested)="deleteCharges()"
  [isDeleteChargesDisabled]="isDeleteChargesDisabled"
  [isDeleteChargesHidden]="false"

  (backRequested)="back()"
></app-invoice-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-invoice-details-tabs [activeTabIndex]="1" [invoice]="invoice"></app-invoice-details-tabs>
  <div class="app-content app-content-list tabbed-content tabbed-content-no-scroll-except-first-ch" style="top: 0;">
    <div class="tabs-body tabs-disable-scroll">

      <dx-data-grid
        #chargesGrid
        class="app-grid-with-pager charge-browser-grid grid-with-selection-badge"
        [columns]="chargesColumns"
        [dataSource]="charges"
        (onSelectionChanged)="onChargesSelectionChanged($event)"
        (onCellClick)="onChargesCellClick($event)"
      >
        <dxo-selection mode="multiple"></dxo-selection>
        <div
          *dxTemplate="let d of 'selectedChargesHeaderTemplate'"
          class="grid-selection-badge-container"
        >
          <a
            class="selection-badge grid-badge"
            matTooltip="Selection"
            (click)="loadSelectedCharges()"
            [ngClass]="{ active: chargesSelectionActive }"
          >
            {{ selectedCharges.length }}
          </a>
        </div>

        <div *dxTemplate="let d of 'eligible'" style="text-align: left;">
          <span>{{
            d?.data?.contract_commitment?.is_commitment_eligible ? "Yes" : "No"
            }}</span>
        </div>

        <div *dxTemplate="let d of 'dateTemplate'" style="text-align: left;">
          <span>{{ d.value | caDate }}</span>
        </div>
        <div *dxTemplate="let d of 'withheldCellTemplate'">
          <div *ngIf="d.value != null" caGridTooltip>{{ d.value ? "Yes" : "No" }}</div>
        </div>

        <div
          *dxTemplate="let d of 'chargeDisputeNoteTemplate'"
          style="position: relative;"
          class="icon-td"
        >
          <a [ngClass]="
              d.data.charge_dispute ? 'icon-danger' : 'disabled'
            "
             (click)="goToDispute(d.data.charge_dispute?.dispute_id)"
             class="charge-grid-icons"
          >
            <ca-icon
              [key]="'DISPUTE'"
              [innerClass]="'charge-grid-icon'"
              [tooltip]="'Dispute'"
            ></ca-icon>
          </a>
          <a
            class="grid-link charge-grid-icons note-charge-grid-icon"
            [ngClass]="
              d.data.note_charges?.length ||
              d.data.note_charges_info_only?.length
                ? 'icon-accent'
                : 'disabled'
            "
            (click)="toggleNotes(false, d.data)"
          >
            <ca-icon
              [key]="
                d.data.note_charges?.length ||
                d.data.note_charges_info_only?.length
                  ? 'NOTES'
                  : 'NOTES_EMPTY'
              "
              [innerClass]="'charge-grid-icon'"
              [tooltip]="'Note'"
            ></ca-icon>
          </a>

          <a
            class="grid-link charge-grid-icons manual-adjustment-grid-icon"
            [ngClass]="
              d.data.chg_class?.length
                ? 'icon-accent'
                : 'disabled'
            "
          >
            <ca-icon
              [innerClass]="'charge-grid-icon'"
              [key]="
                d.data.chg_class?.length && d.data.chg_class === 'adjman'
                  ? 'CHECK'
                  : ''
              "
              [tooltip]="'Manual Adjustment'"
            ></ca-icon>
          </a>
        </div>

        <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caCurrency:d.data.currency }}
          </div>
        </div>
        <div *dxTemplate="let d of 'chargeRateTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caNumber }}
          </div>
        </div>

        <div *dxTemplate="let d of 'chargeCodedTemplate'">
          <div *ngIf="d.value != null" caGridTooltip>{{ d.value ? "Coded" : "Uncoded" }}</div>
        </div>
      </dx-data-grid>
      <ca-pager
        class="app-pager ca-default-border-top"
        [pageContext]="invoiceChargeGridService"
        [query]="query"
        (onChange)="invoice?.invoice_id ? onPageChange(query) : null"
      ></ca-pager>
    </div>
  </div>
  <mat-sidenav #panelSide
               mode="side"
               position="end"
               opened="false">
    <app-filter-container [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                          [query]="query"
                          [filterService]="invoiceChargeFilterService"
                          (filterRequested)="filterData($event)"
                          (clearFilterRequested)="clearFilter()"></app-filter-container>

    <ca-charge-notes
      fxFlex
      fxLayout="column"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
      [entityType]="SYSTEM_MODULE.CHARGE"
      [charges]="selectedCharges"
      [chargesRequired]="true"
      [chargesNote]="chargesNote"
      [selectedId]="selectedNoteId"
      (countChanged)="onNotesCountChanged($event)"
      (viewNoteCharges)="onViewNoteCharges($event)"
      (noteCreated)="onNoteCreated()"
      [query]="query"
      #notesComponent>
    </ca-charge-notes>

    <ca-history
      #history
      [entityId]="invoice?.invoice_id"
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME) }"
      [entityType]="'invoice'"
      [invoice]="invoice"
    ></ca-history>
  </mat-sidenav>
</mat-sidenav-container>

