import {Injectable} from '@angular/core';
import {FilterService} from "../../core/filter/filter.service";
import {UserSettingsService} from "../../user/core/user-settings.service";
 
@Injectable()
export class AlertRateAuditFilterService extends FilterService {
   
  constructor(public userSettingsService: UserSettingsService) {
    super("app.alert.alert-rate-audit-filter", 
    {
      filters: [
        {
          field: 'charge_id',
          label: 'Charge ID',
          type: 'number',
        }, 
        {
          field: 'cost_invoice_charge.sp_serv_id', 
          label: 'SPID',
          type: 'string',
        },
      ]
    }, userSettingsService);

    
  }

}
