import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import Query from "../../../core/query/query";
import {InvoiceBalanceErrorDialogComponent} from "../../../invoice/shared/invoice-balance-error-dialog/invoice-balance-error-dialog.component";
import {InvoiceFacepageService} from "../../../invoice/core/invoice-facepage.service";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {ConfirmDialogComponent} from "../../../shared/dialog/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-invoice-confirm-dialog',
  templateUrl: './invoice-confirm-dialog.component.html',
})
export class InvoiceConfirmDialog extends ConfirmDialogComponent  implements OnInit {

  title = 'Please confirm';
  bodyText = 'Are you sure you want to?';
  hideTitle = false;
  confirmButtonLabel = 'Confirm';
  invoice = null;


  constructor(
    public dialogRef: MatDialogRef<InvoiceConfirmDialog>,
    public dialog: DialogService,
    public invoiceService: InvoiceFacepageService,
    ) {
    super(dialogRef)
  }

  ngOnInit() {
  }

  async confirm() {
    let newQuery = new Query({where: {invoice_id: {$in: [this.invoice.invoice_id]}}})
    const isInvoiceValid = await this.invoiceService.getNonBalancedInvoices(newQuery).toPromise()
      if(!isInvoiceValid[0]){
        this.dialogRef.close(true);
      }else {
        this.dialog.open(InvoiceBalanceErrorDialogComponent,
          {
            invoice: this.invoice
      })
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
