import {AfterViewInit, Component, EventEmitter, OnInit, OnDestroy, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {filter, switchMap, takeUntil, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {path, pathOr} from 'ramda';
import {Flow} from '../../../shared/flow/flow';
import {InvoiceFlowService} from '../../core/invoice-flow.service';
import {ChargeQuery} from "../../../charge/core/charge.query";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {LoaderService} from "../../../shared/loader/loader.service";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";
import {NewFilterComponent} from "../../../core/filter/new-filter-component/new-filter.component";
import {FilterContext} from "../../../core/filter/filter-context";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {Config} from "../../../core/config/config";
import {LOOKUP_MODELS_ENUM} from "../../../dictionary/core/lookup-models.enum";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {DictionaryService} from "../../../dictionary/core/dictionary.service";
import {NotificationService} from "../../../shared/notification/notification.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {DisputeQuery} from "../../../dispute/core/dispute.query";
import {DisputeService} from "../../../dispute/core/dispute.service";
import {Select, Store} from "@ngxs/store";
import {INVOICE_STATUS_ENUM} from "../../core/invoice-status.enum";
import {AccountDialogService} from "../../../account/core/account-dialog.service";
import {HistoryService} from "../../../shared/history/history.service";
import {InvoiceCharge} from "../../../charge/core/invoice-charge";
import {UpdateInvoiceChargesQuery} from "../charges/state/invoice-charges.actions";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {HistoryComponent} from "../../../shared/history/history.component";
import {UpdateOverviewActiveSiderSection} from "./state/invoice-overview.actions";
import {LoadSingleInvoice} from "../state/invoice-details.actions";
import {InvoiceFlowHandleService} from "../../core/invoice-flow-handle.service";
import {ConfigService} from "../../../core/config/config.service";

const unsetAdjQueryChanger = (context: FilterContext): FilterContext => {
  if (context && context.filterSet) {
    const fieldName = 'include_in_amount_due_status';
    context.filterSet.forEach(r => {
      if (r.field === fieldName) {
        r.defaultValue = [LOOKUP_ENUM.UNSET_ADJUSTMENTS.NONE];
      }
    });
  }

  return context;
};

@Component({
  selector: 'app-invoice-overview',
  templateUrl: './invoice-overview.component.html',
  styleUrls: ['./invoice-overview.component.css']
})
export class InvoiceOverviewComponent extends PageDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly MESSAGES_MODULE: string = 'invoice';
  readonly DOCUMENT_INVOICE_TYPE = LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  invoice: InvoiceFacepage;
  strInvoiceStatus: string;
  flow: Flow;
  onStepChangeMediator = new EventEmitter();
  isAdjustment = false;
  isClickOnInvoiceFlow = false;
  dnpInfo: any = null
  onHoldInfo: any = null
  charges: Array<any> = [];
  disputedCharges: number = 0;
  billedCharges: number = 0;
  calculatedCharges: number = 0;
  totalDisputedAmount: number = 0;
  totalWithheldAmount: number = 0;
  appWithheld: number = 0;
  disputeWithheldAmount: number = 0;
  numOfDisputes: number = 0;
  config: Config;
  invoiceId: number;
  spInvNum: any;
  messages: IMessagesResourceService;
  invoiceStatusEnum = INVOICE_STATUS_ENUM;
  totalAmountToBePaid: number = 0;

  isDoNotProcessDisabled = true;
  isOnHoldDisabled = true;

  selectedCharges: Array<InvoiceCharge> = [];
  selectedNoteId: number;
  chargesNote: boolean;
  notesCount = 0;

  @ViewChild('panelSide') panelSide;
  @ViewChild('history') history: HistoryComponent;
  @ViewChild('testNotes') testNotes;

  @ViewChild('appFilter') appFilter: NewFilterComponent;
  @Output() changeEmitter = new EventEmitter<any>();

  @Select(state => state.invoice_overview.activeSiderSection) $activeSiderSection: Observable<any>;

  constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly invoiceService: InvoiceFacepageService,
    public invoiceFlowService: InvoiceFlowService,
    public loaderService: LoaderService,
    public settingsService: UserSettingsService,
    public dictionaryService: DictionaryService,
    private readonly notificationService: NotificationService,
    public alertService: AlertService,
    public disputeService: DisputeService,
    private readonly accountDialogService: AccountDialogService,
    public historyService: HistoryService,
    public invoiceFlowHandleService: InvoiceFlowHandleService,
    public configService: ConfigService
  ) {
    super(new PageContext({
      name: 'app.invoice.invoice-overview',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.invoiceFlowHandleService.onHoldChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(async res => {
        if(res){
          let accountHistory = await this.historyService.findAllForEntity('account', this.invoice.account.id).toPromise()
          let onHoldFound = accountHistory.filter(account => (account.meta_data.on_hold && account.meta_data.on_hold.value === true))
          this.onHoldInfo = (onHoldFound && onHoldFound.length > 0) ? onHoldFound[0] : null
        } else {
          this.onHoldInfo = null
        }
      })

    this.invoiceService.invoiceChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(async (invoice) => {
      this.selectedCharges = [];
      this.refreshWithInvoice(invoice)
      this.history.refreshList();
    })

  }

  ngOnInit(): void {
    this.invoice = this.route.parent.snapshot.data.invoice
    this.invoiceId = this.invoice.invoice_id
    this.spInvNum = this.invoice.sp_inv_num
    this.config = this.route.parent.snapshot.data.config;
        if (this.config) {
          this.invoiceFlowHandleService.setConfig(this.config);
        }

        this.getOnHoldInfo()
        if (this.invoice && this.invoice.header.status_code === this.invoiceStatusEnum.DO_NOT_PROCESS) {
        //  this.testNotes && this.testNotes.emitCount();
        } else {
          this.dnpInfo = null
        }
        this.isDoNotProcessDisabled = this.invoiceService.isDoNotProcessDisabled(this.invoice);
        this.isOnHoldDisabled = !this.invoiceFlowHandleService.isOnHoldEligible(path(['header', 'status_code'], this.invoice));
        this.createFlow(this.invoice);
        this.loadDisputeSummary(this.invoiceId);
        this.loadDisputeCalculations(this.invoice);
        this.updateTotalAmountToBePaid();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.sider = this.createSider();
  }

  public async loadData(id: number) {}

  updateTotalAmountToBePaid() {
    const adjMan = (this.invoice.tot_adjman) ? this.invoice.tot_adjman : 0;
    this.totalAmountToBePaid = Number(this.invoice.total) - Number(this.invoice.withheld_disputes_amount) + Number(adjMan);
  }
  // TODO: check if there is a better way using old method bellow
  private refreshWithInvoice(invoice) {
    this.invoice = invoice;
    this.strInvoiceStatus = invoice.header.status.value;
    this.createFlow(invoice);
    this.isOnHoldDisabled = !this.invoiceFlowHandleService.isOnHoldEligible(path(['header', 'status_code'], this.invoice));
    this.isDoNotProcessDisabled = this.invoiceService.isDoNotProcessDisabled(this.invoice);

    this.getOnHoldInfo()
    if (this.invoice && this.invoice.header.status_code !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
      this.dnpInfo = null
    }

    this.updateTotalAmountToBePaid();
  }

  public createFlow(invoice) {
    this.invoiceFlowService.loadFlowPermissions(invoice).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {

        this.flow = this.invoiceFlowService.createFlow(invoice);

        if(this.invoice.account.on_hold){
          const step = this.flow.steps[this.flow.currentStep]
          if(step){
            step.disabled = true;
          }
        }
      })
  }

  myFunc() {};

  onStepSelection(data) {
    this.onStepChangeMediator.subscribe(step => {
      if (step.code === this.invoiceFlowHandleService.getStepNumberForKey('GL')) {
        this.isAdjustment = true;
        const newQuery = new ChargeQuery({
          orderBy: [['id', 'ASC']],
          where: {
            chg_class: {$in: ['ADJAD', 'ADJBF', 'ADJMAN']},
            $or: [
              {include_in_amount_due_status: null},
              {
                include_in_amount_due_status: LOOKUP_ENUM.UNSET_ADJUSTMENTS.NONE
              }
            ]
          }
        });
        this.isClickOnInvoiceFlow = true;
        this.changeQueryAndRefresh(newQuery);
        this.store.dispatch([
          new UpdateInvoiceChargesQuery({query: newQuery})
        ]);
        return this.router.navigate(['/invoice', this.invoice.invoice_id, 'show', 'charges-adj']);
      }
    });
    this.invoiceFlowService.handleStepSelection(
      data,
      this.onStepChangeMediator,
      this.myFunc.bind(this)
    )
  }

  private changeQueryAndRefresh(newQuery: ChargeQuery) {
    if (this.appFilter) {
      this.appFilter.manuallyChangeQueryAndRefresh(
        newQuery,
        unsetAdjQueryChanger
      );
    }
  }

  public getVendorEntityName(invoice: InvoiceFacepage): string {
    return pathOr('N/A', ['account', 'entity', 'name'])(invoice);
  }

  loadDisputeSummary(id){
    let newQuery = new DisputeQuery();
    newQuery.set('invoice_id', id);
    newQuery.limit = 1000;
    this.disputeService.findChargesDisputes(newQuery).subscribe(
      result => {
        let value = result.items;
        this.billedCharges = value.total_amount;
        this.calculatedCharges = value.calculated_amount;
        this.totalDisputedAmount = value.disputed_amount;
        this.totalWithheldAmount = value.payment_witheld_amt;
      },
      () => {
        this.alertService.error('', this.messages.get('DISPUTE_LOAD_ERROR'));
      }
    );
  }

  loadDisputeCalculations(invoice: InvoiceFacepage) {
    let disputeQuery = new DisputeQuery({orderBy: [['id', 'ASC']]});
    disputeQuery.set('invoice_id', invoice.invoice_id);
    this.disputeService.findDisputesWithCharges(disputeQuery).subscribe(
      result => {
        // TODO: Check if the number is correct when we have a dispute with 2 or more charges
        this.numOfDisputes = result.total
        let allDisputes;
        let allDisputesCharges = [];

        allDisputes = result.items.map(item => item.dispute);
        allDisputes.forEach(dispute => {
          dispute.dispute_charges.filter(dc => dc.charge.invoice_id === invoice.invoice_id)
            .forEach(charge => {
              this.disputedCharges += (+charge.disputed_amount);
              allDisputesCharges.push(charge);
            })
        });

        allDisputesCharges = allDisputesCharges.flat();
        allDisputesCharges.forEach(charge => {
          if (charge.dispute_withheld) {
            this.disputeWithheldAmount += (+charge.disputed_amount)
          }
        });
        this.updateTotalAmountToBePaid();
      },
      () => {
        this.alertService.error('', this.messages.get('DISPUTE_LOAD_ERROR'));
      }
    );
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  /** Toolbar actions */
  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateOverviewActiveSiderSection(activeSection.name)
    ]);
  }
  onMoveInvoiceToDoNotProcessRequested() {
    let status;
    const invoiceId = this.invoice.invoice_id;
    this.invoiceService.findByIdForEdit(invoiceId).subscribe((invoice) => {
      this.invoiceService.openConfirmationDialog(
        {
          invoices: invoice,
          actionText: 'Confirm',
          title: 'Do Not Process',
          config: {isDoNotProcess: true},
          onDeleteConfirmed: (deleteResult) => {
            status = this.invoiceStatusEnum.DO_NOT_PROCESS;
            this.invoiceService.updateStatus(invoiceId, {status: status,dnp_reason_code_id:deleteResult.dnp_reason_code_id})
              .subscribe(async () => {
                if (deleteResult.note && deleteResult.note.length && deleteResult.dnp_reason_code_id) {
                  const invoiceReasonIds = {
                    dnp_reason_code_id:deleteResult.dnp_reason_code_id,
                    invoiceId
                  }
                  this.testNotes.createNote(null, 'Do Not Process: ' + deleteResult.note , invoiceReasonIds);
                }
              });
            deleteResult.closeDialog();
          }
        },
      )
    });
  }

  onChangeInvoiceDocumentTypeRequested(documentTypeId) {
    let documentInvoiceTypeId;
    for (const property in LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE) {
      if (property === documentTypeId) {
        documentInvoiceTypeId = LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE[property]
      }
    }
    if (
      documentInvoiceTypeId === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.CREDIT_MEMO &&
      this.invoice.header.document_type_id === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.INVOICE
    ) {
      this.invoiceService.updateDocumentTypeStatus(this.invoice.invoice_id, {
        document_type_id: documentInvoiceTypeId
      })
        .subscribe(async (res) => {
          // TODO: patch state with new updated invoice
          const invoice = await this.invoiceService.findById(res.invoice_id).toPromise()
          this.invoiceService.invoiceChange.emit(invoice)
        });
    } else if (
      documentInvoiceTypeId === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.INVOICE &&
      this.invoice.header.document_type_id === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.CREDIT_MEMO
    ) {
      this.invoiceService.updateDocumentTypeStatus(this.invoice.invoice_id, {
        document_type_id: documentInvoiceTypeId
      })
        .subscribe(async (res) => {
          // TODO: patch state with new updated invoice
          const invoice = await this.invoiceService.findById(res.invoice_id).toPromise()
          this.invoiceService.invoiceChange.emit(invoice)
        });
    }
  }

  async onPutOnHoldRequested() {
    const hasCustomFields = await this.configService.hasCustomFields('account_configuration');

    this.accountDialogService.openEditAccountDialog(this.invoice.account , hasCustomFields)
      .subscribe(async (account) => {
        if (!account)
          return;
        const {changeDisputes = []}=account;
        let activateSetTimeOut= false;
        if((this.invoice.account.is_vat_gl_output !== account.is_vat_gl_output) && account.is_vat_gl_output){
          if (changeDisputes.length > 0) {
            let message = this.messages.get('IS_VAT_GL_OUTPUT_ENABLED').replace('<number>', changeDisputes.length)
            message += `. Dispute IDs: ${changeDisputes.join(', ')}.`
            this.alertService.success('', message)
          }
          activateSetTimeOut=true;
        }
        if(activateSetTimeOut){
          setTimeout(()=> {
            this.getAccountMessage(account)
          },5000);
        }else {
          this.getAccountMessage(account)
        }
        const invoice = await this.invoiceService.findById(this.invoice.invoice_id).toPromise()
        this.invoiceService.invoiceChange.emit(invoice)
      });
  }

  getAccountMessage(account){
    const message = this.invoiceFlowService.getAccountUpdatedMessage(this.invoice.account, account);
    this.alertService.success('', this.messages.get(message));
  }

  back() {
    if (
      document.referrer.indexOf(window.location.host) >= 0 ||
      document.referrer === ''
    ) {
      history.back();
    } else {
      this.router.navigate(['/invoice']);
    }
  }

  async getOnHoldInfo() {
    if (!this.invoice.account.on_hold) {
      this.onHoldInfo = null;
      return;
    }
    let accountHistory = await this.historyService.findAllForEntity('account', this.invoice.account.id).toPromise()
    let onHoldFound = accountHistory.filter(account => (account.meta_data.on_hold && account.meta_data.on_hold.value === true))
    this.onHoldInfo = (onHoldFound && onHoldFound.length > 0) ? onHoldFound[0] : null
  }

  getDNPInfo(notes: any, forceEmpty: boolean = false) {
      const dnpStatus = this.invoiceFlowService.LOCALS.DO_NOT_PROCESS

      if (forceEmpty || (this.invoice && this.invoice.header.status_code !== this.invoiceStatusEnum.DO_NOT_PROCESS)) {
        this.dnpInfo = null
      } else {
        const dnpNotes = notes?.filter(note => note.content.startsWith(dnpStatus)) || [];
        this.dnpInfo = (dnpNotes.length) ? dnpNotes[0] : {};
        this.dnpInfo.status = `${dnpStatus}`;
        if(this.invoice?.header?.dnp_reason?.value)
        if(this.invoice.header.dnp_reason && this.invoice.header.dnp_reason.value){
          this.dnpInfo.value = `${this.invoice.header.dnp_reason.value}`;
        }
        this.dnpInfo.message = this.dnpInfo.content ? this.dnpInfo.content.substring(dnpStatus.length + 2) : this.messages.get('DNP_FALLBACK_MESSAGE');
        let newMessage = this.dnpInfo.content;
        newMessage = newMessage.replace('Do Not Process: ', '');
        this.dnpInfo.concatMessage = this.dnpInfo.content ? newMessage : this.messages.get('DNP_FALLBACK_MESSAGE')
      }
  }

  /* Notes count changed */
  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  onNoteCreated() {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
