import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class AuditGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super('app.audits.audit-grid', userSettings);
  }

  defaultColumns = [
    {
      caption: '',
      headerCellTemplate: 'selectionBadgeHeaderTemplate',
      cellTemplate: 'selectionBadgeCellTemplate',
      fixed: true,
      width: 40,
      allowSorting: false
    },
    {
      caption: 'Audit Name',
      dataField: 'name',
      width: 200
    },
    {
      caption: 'Vendor',
      dataField: 'vendor.name',
      width: 150
    },
    {
      caption: 'Contract',
      dataField: 'contract.name',
      width: 150
    },
    {
      caption: 'Description',
      dataField: 'description',
      width: 200
    }, {
      caption: 'Audit Type',
      dataField: 'type.value',
      width: 150,
      alignment: 'left'
    }, {
      caption: 'Create Date',
      dataField: 'created_at',
      dataType: 'date',
      cellTemplate: 'createdAtTemplate',
      alignment: 'left',
      width: 180
    }, {
      caption: 'Created By',
      dataField: 'created_by.full_name',
      alignment: 'left',
      width: 180
    }
  ];
}
