
export class QueryParamOperator {
  static And = "$and";
  static Or = "$or";
  static GreaterThan = "$gt";
  static GreaterThanOrEqual = "$gte";
  static LessThan = "$lt";
  static LessThanOrEqual = "$lte";
  static NotEqual = "$ne";
  static Equal = "$eq";
  static Not = "$not";
  static Between = "$between";
  static NotBetween = "$notBetween";
  static In = "$in";
  static NotIn = "$notIn";
  static Like = "$like";
  static ILike = "$ilike";
  static NotLike = "$notLike";
  static NotILike = "$notILike";
  static Contains = "$contains";
  static Overlap = "$overlap";
  static Contained = "$contained";
  static Any = "$any";
}
