import {of as observableOf, Observable, forkJoin} from 'rxjs';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {PageManageDialogComponent} from '../../../core/page-manage-dialog.component';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Building} from '../../core/building';
import {MatDialogRef} from '@angular/material/dialog';
import {BuildingService} from '../../core/building.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {Address} from '../../../address/core/address.model';
import {AddressService} from '../../../address/core/address.service';
import {IMessagesResourceService, ResourcesService} from '../../../core/resources/resources.service';
import {DataLockService} from '../../../core/data-lock/data-lock.service';
import {EntityEditContext} from '../../../shared/entity-lock/entity-edit-context';
import {EntityLockData} from '../../../shared/entity-lock/entity-lock-data';
import {PermissionService} from '../../../permissions/core/permission.service';
import {map, takeUntil} from "rxjs/operators";
import {flatMap} from "rxjs/internal/operators";
import {ConfigService} from "../../../core/config/config.service";

@Component({
  selector: 'app-building-manage-dialog',
  templateUrl: './building-manage-dialog.component.html',
  styleUrls: ['./building-manage-dialog.component.css']
})
export class BuildingManageDialogComponent extends PageManageDialogComponent implements OnInit {

  building: any;
  address: Address;

  messages: IMessagesResourceService;
  messagesCore: IMessagesResourceService;

  readonly MESSAGES_MODULE: string = 'building';
  readonly MESSAGES_MODULE_CORE: string = 'core';

  deletePermission = false;
  formModeEnabled = false;
  // Custom fields
  fields: Array<any> = [];
  categories: any = [];
  showCategory: boolean = false;

  readonly custom_values_config_key = 'appdata_building';

  constructor(public formBuilder: FormBuilder,
              public alert: AlertService,
              public buildingService: BuildingService,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<BuildingManageDialogComponent>,
              public addressService: AddressService,
              private permissionService: PermissionService,
              public configService: ConfigService,
              private cd: ChangeDetectorRef,
              public dataLockService: DataLockService) {
    super(dialogService, dialogRef, dataLockService);

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.messagesCore = ResourcesService.messages(this.MESSAGES_MODULE_CORE);
  }

  ngOnInit() {
    this.loadPermissions().subscribe(() => {
    });
    this.building = this.building || new Building();
    this.isUpdate = !!this.building.id;
    this.address = (this.isUpdate && this.building.address) ? this.building.address : null;

    this.form = this.formBuilder.group({
      name: [this.building ? this.building.name : '', Validators.required],
    });

    this.formTitle = this.isUpdate ? 'Edit Building' : 'Create Building';

    this.initViewOnlyForm(this.building.id, 'View Building', 'Edit Building', this.dataLockService.DATA_LOCK_TYPES.BUILDING);

    // Load custom fields
    this.configService.loadCustomFields()
      .pipe(takeUntil(this.destroy$))
      .subscribe(config => {
        this.fields = this.configService.getCustomFieldsFromConfig(config, this.custom_values_config_key);

        let fieldsWithCategory = [];
        let custom_values = this.building.custom_values && this.isUpdate ? this.building.custom_values : {};
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].category && this.fields[i].category.length > 0)
            fieldsWithCategory = [...fieldsWithCategory, this.fields[i].category];

          if (!this.categories.includes(this.fields[i].category))
            this.categories.push(this.fields[i].category);

          this.fields[i].value = custom_values[this.fields[i].field_name];
          this.form.addControl(this.fields[i].field_name, new FormControl({
            value: this.fields[i].value,
            disabled: this.viewOnly
          }));

        }

        if (fieldsWithCategory.length === this.fields.length)
          this.showCategory = true;

        this.cd.detectChanges();
      });

    this.afterInit();
  }

  init() {
    if (this.building && this.building.id) {
      return this.buildingService.findByIdForEdit(this.building.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .pipe(flatMap((building) => {
          this.building = building;
          return observableOf(building);
        }))
    }
    return observableOf(this.building);
  }

  onFormModeChanged(isFormMode: boolean) {
    this.formModeEnabled = isFormMode;
  }

  onAddressChanged(address: Address) {
    if (address) {
      if (address.internal || address.is_validated) {
        this.address = new Address(address);
      } else {
        this.address = null;
      }
    } else {
      this.address = null;
    }
  }

  onSubmit({value, valid}: { value: Building, valid: boolean }) {

    if (valid && this.address) {
      value.name = value?.name?.trim();
      value.custom_values = this.configService.getCustomFieldValue(this.fields, value);
      this.building = Object.assign({}, this.building, value);

      this.addressCheck(this.address)
        .subscribe((address: Address) => {
          this.building.address_id = address.id;

          if (this.building.id) {
            this.update(this.building);
          } else {
            this.create(this.building);
          }

        }, (err) => {
          this.alert.error('', this.messages.get('CREATE_ADDRESS_ERROR'));

        });

    } else {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }

  addressCheck(address: Address): Observable<Address> {
    if (address.internal && address.id) {
      return observableOf(address);
    } else {
      return this.addressService.createOrUpdate(address);
    }
  }

  create(building: Building) {
    this.toggleDialogButtons();
    this.buildingService.create(building)
      .subscribe((result) => {
        this.closeDialog(result, true);
      }, (error) => {
        this.toggleDialogButtons(false);
        const message = (error.error && error.error['message'] == 'Duplicate error') ? 'DUPLICATE_BUILDING' : 'CREATE_ERROR';
        this.alert.error('', this.messages.get(message));
      });
  }

  update(building: Building) {
    this.toggleDialogButtons();
    this.buildingService.update(building.id, building)
      .subscribe((result) => {
        this.closeDialog(result, true);
      }, (error) => {
        this.toggleDialogButtons(false);
        const message = (error.error && error.error['message'] == 'Duplicate error') ? 'DUPLICATE_BUILDING' : 'UPDATE_ERROR';
        this.alert.error('', this.messages.get(message));
      });
  }


  deleteBuilding(event) {
    if (this.isUpdate && event) {
      this.toggleDialogButtons();
      this.buildingService.delete(this.building.id)
        .subscribe((deleteResult) => {
          if (deleteResult) {
            this.dialogRef.close({deleted: true});
          }
        }, (err) => {
          this.toggleDialogButtons(false);
          if (err.data === 'SequelizeForeignKeyConstraintError' || err.error.message.includes('violates foreign key constraint')) {
            setTimeout(() => {
              this.alert.error('', this.messages.get('CONSTRAIN_DELETE_ERROR'));
            }, 250);
          } else {
            setTimeout(() => {
              this.alert.error('', this.messages.get('DELETE_ERROR'));
            }, 250);
          }
        });
    }
  }

  editForm() {
    this.buildingService.findByIdForEdit(this.building.id).subscribe((result: EntityLockData) => {
      if (result['$lock'] && result['$lock']['status'] === 403) {
        this.alert.error('', `${this.messagesCore.get('DATA_LOCKED')}${result['$lock']['user']}`)
      } else {
        this.form.enable();
        this.viewOnly = false;
        this.formTitle = this.editTitle;
      }
    })
  }

  cancel() {
    this.buildingService.cancelEdit();
    this.closeDialog();
  }

  loadPermissions() {
    const deletePermiss$ = this.permissionService.isAllowed("buildings", "DELETE");
    return forkJoin(
      deletePermiss$
    ).pipe(map(([deletePermiss]) => {
      this.deletePermission = deletePermiss;
    }));
  }
}
