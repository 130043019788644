<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{formTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">
  <form class="ca-form" [formGroup]="form">

    <!-- main container -->
    <div class="flex-container" fxLayout="row">

      <!-- personal and company -->
      <div class="flex-container" fxFlex fxLayout="column">

        <!-- personal -->
        <div class="flex-container caGapRight" fxLayout="column">

          <!-- names -->
          <div class="flex-container" fxLayout.sm="column">
            <div class="flex-item caGap caGapRight" fxFlex="48%">
              <mat-form-field caInput>
                <input matInput placeholder="First Name" required formControlName="first_name" type="text">
                <mat-hint *ngIf="form.hasError('required', ['first_name']) && form.controls.first_name.touched"
                          class="error-class">{{COMPONENT_VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </mat-form-field>
            </div>

            <div class="flex-item caGap" fxFlex>
              <mat-form-field caInput>
                <input matInput placeholder="Last Name" required formControlName="last_name" type="text">
                <mat-hint *ngIf="form.hasError('required', ['last_name']) && form.controls.last_name.touched"
                          class="error-class">{{COMPONENT_VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <!-- email -->
          <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">

            <div class="flex-item form-group caGap" fxFlex>
              <mat-form-field caInput>
                <input matInput required placeholder="Email" formControlName="email" type="text">
                <mat-hint *ngIf="form.hasError('required', ['email']) && form.controls.email.touched && emailUnique"
                          class="error-class">
                  {{COMPONENT_VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
                <mat-hint *ngIf="form.hasError('email', ['email']) && form.controls.email.touched && emailUnique"
                          class="error-class">
                  {{COMPONENT_VALIDATION.EMAIL_ERROR}}
                </mat-hint>
                <mat-hint *ngIf="!emailUnique" class="error-class">
                  {{COMPONENT_VALIDATION.EMAIL_ALREADY_USED}}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <!-- office phone -->
          <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
            <div class="flex-item caGap caGapRight entry-list-container" fxFlex="67px">
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item" style="padding: 8px 0;">
                  <label>Country Code</label>
                  <p style="text-align: end">{{ phoneCountryCode || ''}}</p>
                </div>
              </div>
            </div>
            <div class="flex-item caGap caGapRight" fxFlex fxFlex="45%">
              <mat-form-field caInput>
                <input matInput placeholder="Office Phone" formControlName="office_phone"
                       [mask]="phoneRegexMask" [validation]="true"
                       type="text">
                <mat-hint *ngIf="(form.hasError('pattern', ['office_phone']) || this.form.get('office_phone').invalid) && form.controls.office_phone.touched"
                          class="error-class">{{phoneRegexMask}}
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="flex-item caGap" fxFlex>
              <mat-form-field caInput>
                <input matInput placeholder="Extension" formControlName="office_phone_extension"
                       [textMask]="{mask: extensionMask, placeholderChar: '\u2000'}"
                       type="text">
              </mat-form-field>
            </div>

          </div>

          <!-- mobile phone -->
          <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
            <div class="flex-item caGap caGapRight entry-list-container" fxFlex="67px">

            </div>
            <div class="flex-item form-group caGap" fxFlex  fxFlex="45%">
              <mat-form-field caInput>
                <input matInput placeholder="Mobile Phone" formControlName="mobile_number" type="text"
                       [mask]="phoneRegexMask" [validation]="true" ui-br-phone-number-mask="countryCode">
                <mat-hint *ngIf="(form.hasError('pattern', ['mobile_number']) || this.form.get('mobile_number').invalid) && form.controls.mobile_number.touched"
                          class="error-class">{{phoneRegexMask}}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- company -->
        <div class="flex-container caGapRight" fxLayout="column" fxFlex>
          <div class="flex-container" fxLayout.sm="column" fxFlex>
            <div class="flex-item caGap caGapRight" fxLayout="row" fxFlex fxFlex="48%">
              <ca-lookup-model-picker [disabled]="viewOnly" caInput fxFlex
                          [lookupModel]="CONTACT_TYPE_LOOKUP_MODEL" formControlName="contact_type_id"
                          placeholder="Type" [pickerForm]="form" [clearEnabled]="true">
              </ca-lookup-model-picker>
            </div>
          </div>
          <div
            *ngIf="form.controls.contact_type_id.value !== CONTACT_TYPE_ENUM.VENDOR && form.controls.contact_type_id.value !== CONTACT_TYPE_ENUM.CUSTOMER"
            class="flex-item form-group caGap" fxLayout.sm="column">
            <ca-default-picker caInput>
              <mat-form-field>
                <mat-select [disabled]="true" placeholder="Company"></mat-select>
              </mat-form-field>
            </ca-default-picker>
          </div>
          <div *ngIf="form.controls.contact_type_id.value === CONTACT_TYPE_ENUM.VENDOR"
                class="flex-item form-group caGap"
                fxLayout.sm="column">
            <ca-vendor-picker caInput
                              [disabled]="viewOnly"
                              formControlName="vendor_id"
                              [pickerForm]="form"
                              placeholder="Company"
                              [initialItem]="contact?.vendor"
                              [clearEnabled]="true"
                              customMethod="findAll">
            </ca-vendor-picker>
          </div>
          <div *ngIf="form.controls.contact_type_id.value === CONTACT_TYPE_ENUM.CUSTOMER"
                class="flex-item form-group caGap"
                fxLayout.sm="column">
            <ca-customer-picker caInput
                                [disabled]="viewOnly"
                                formControlName="customer_id"
                                placeholder="Company"
                                [clearEnabled]="true"
                                [searchEnabled]="true"
                                [initialItem]="contact?.customer"
                                [pickerForm]="form">
            </ca-customer-picker>
          </div>
          <div class="flex-item caGap" fxLayout.sm="column">
              <mat-form-field caInput>
                <input matInput placeholder="Title" formControlName="title" type="text">
              </mat-form-field>
          </div>
          <div class="flex-item caGap" fxLayout.sm="column">
            <ca-site-picker caInput
              [disabled]="viewOnly"
              placeholder="Site"
              formControlName="site_id"
              [pickerForm]="form"
              [initialItem]="contact?.site"
              [searchEnabled]="true"></ca-site-picker>
            </div>

        </div>
      </div>

      <!-- address and function -->
      <div class="flex-container" fxFlex fxLayout="column">
        <div class="flex-container">
          <div fxFlex class="dialog-content-no-margin-form-child" fxLayout="column">
            <ca-address-selection 
              [required]="!formModeEnabled"
              [disabled]="viewOnly" 
              [address]="contact?.address"
              class="address-positon-padding-fix"
              (addressChanged)="onAddressChanged($event)"
              style="margin: 0"
              (countryChanged)="onCountryChanged($event)"
              [addressCityInlineFlex]="true"
              (isFormMode)="onFormModeChanged($event)"
            ></ca-address-selection>
  
            <div class="flex-container address-2-3-container" fxLayout="row" fxLayout.sm="column">
              <div class="flex-item caGap caGapRight" fxFlex>
                <mat-form-field caInput>
                  <input matInput placeholder="Address 2" formControlName="address2" type="text">
                </mat-form-field>
              </div>
              <div class="flex-item form-group caGap" fxFlex>
                <mat-form-field caInput>
                  <input matInput placeholder="Address 3" formControlName="address3" type="text"/>
                </mat-form-field>
              </div>
            </div>
  
          </div>

        </div>

        <!-- function -->
        <div class="flex-container" fxFlex>
          <div class="flex-container" fxFlex>
            <div class="flex-container" fxLayout.sm="column">
              <div class="flex-item caGap caGapRight" fxFlex="70%">

                <ca-default-picker caInput>
                  <mat-form-field>
                    <mat-select placeholder="Function"
                                [(ngModel)]="selectedFunction"
                                [disabled]="viewOnly || !form.controls['contact_type_id'].value || !isContactInternal"
                                [ngModelOptions]="{standalone: true}">
                      <mat-option
                        [value]="entry"
                        *ngFor="let entry of contactFunctionsUI">
                        {{entry.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ca-default-picker>


              </div>
              <div class="flex-item function-add-button" fxFlex>
                <button [disabled]="!selectedFunction" mat-icon-button tabindex="-1"
                        (click)="addFunction()">
                  <ca-icon [key]="'ADD'"></ca-icon>
                </button>
              </div>
            </div>
            <div class="function-container">
              <div class="ca-empty-state-container"
                  *ngIf="!contact.contact_functions || contact?.contact_functions?.length==0">
                <p class="card-message">
                  No functions selected
                </p>
              </div>

              <div fxLayout="row" class="flex-container hide-delete-icon-container"
                  *ngFor="let functionObj of contact.contact_functions; let i=index"
                  style="display:flex; align-items: center;">
                <div class="flex-item flex-item-centered" fxFlex="70%">
                  <span>{{functionObj.value}}</span>
                </div>
                <div class="flex-item flex-item-centered" fxFlex>
                  <button mat-icon-button tabindex="-1" matTooltip="Remove Function" [disabled]="viewOnly" (click)="removeFunction(functionObj)">
                    <ca-icon [key]="'DELETE'"></ca-icon>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<mat-dialog-actions class="ca-dialog-action" *ngIf="!viewOnly">
  <div class="example-fill-remaining-space">
    <ca-confirm-delete-button #genericButton *ngIf="isUpdate" [confirmText]="'Delete?'"
                              (deleteRequested)="deleteContact($event)"></ca-confirm-delete-button>
  </div>

  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>

  <button mat-button #saveButton type="submit" (click)="onSubmit(form)"
          [disabled]="form?.invalid || !address || !emailUnique">
    Save
  </button>
</mat-dialog-actions>

<mat-dialog-actions class="ca-dialog-action" *ngIf="viewOnly">
  <div class="example-fill-remaining-space"></div>

  <button mat-button type="submit" (click)="closeDialog()">
    Cancel
  </button>

  <button mat-button type="submit" (click)="editForm()">
    Edit
  </button>
</mat-dialog-actions>
