import {Observable} from "rxjs";
import {UserService} from "./user.service";
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import Query from "../../core/query/query";
import {map} from "rxjs/operators";
export class UserLookupProvider extends IFilterLookupProvider {
  constructor(public userService: UserService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    let query = searchQuery? searchQuery : new Query();
    query.orderBy = [['full_name', 'ASC',]];

    return this.userService.findAll(query)
      .pipe(map((result: any) => {
        return {items: result.items.map(entry => {
          return entry.full_name;
        }), total: result.total};
      }));
  }
}
