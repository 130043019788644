
import {debounceTime} from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import Query from '../../query/query';
import { FilterEntry } from '../filter-entry';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'ca-tag-filter-input',
  templateUrl: './tag-filter-input.component.html',
  styleUrls: ['./tag-filter-input.component.css'],
})

export class TagFilterInputComponent implements OnInit, OnChanges {
  @Input('filter') isFilter = false;
  @Input('entry') entry: FilterEntry;
  @Input('query') query: Query;
  @Output() queryChanged = new EventEmitter();
  @ViewChild('inputField') inputField: ElementRef;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes = [ENTER, COMMA];

  matChipInput: FormControl = new FormControl();
  selectedTags: any = [];

  lookupQuery: Query = new Query({
    where: {},
  });

  lookupItems: any = [];

  @ViewChild(MatAutocompleteTrigger) auto: MatAutocompleteTrigger;

  ngOnInit() {
    this.matChipInput.valueChanges.pipe(
      debounceTime(250))
      .subscribe((text) => {
        this.lookupItems = [];
        if (text && text.length) {
          let searchDataField;
          if (this.entry && this.entry.fieldToFilterWith) {
            searchDataField = this.entry.fieldToFilterWith.split('.').pop() || null;
          }
          const searchField = this.entry.field.split('.').pop();
          this.lookupQuery.where[searchDataField ? searchDataField : searchField] = { '$ilike': `${text}%` };

          this.entry.lookupProvider.findAll(this.lookupQuery)
            .subscribe((result) => {
              this.lookupQuery.total = result.total;
              this.lookupItems = result.items.filter((item) => {
                return !this.selectedTags.includes(item);
              });
            });
        }
      });
  }

  searchAllOnInitialFocus() {
    if (!this.matChipInput.value && this.entry.lookupProviderName === 'vendor' || !this.matChipInput.value && 'entity') {
      this.entry.lookupProvider.findAll(this.lookupQuery)
        .subscribe((result) => {
          this.lookupQuery.total = result.total;
          this.lookupItems = this.lookupItems.concat(result.items.filter((item) => {
            return !this.selectedTags.includes(item) && !this.lookupItems.includes(item);
          }));
          //close and open is necessary in order for autocomplete to correctly estimate position
          this.auto.closePanel();
          this.auto.openPanel();
        });
    }
  }

  onOptionSelected(event) {
    event.option.first_row ? this.selectedTags.push(event.option.first_row.value) : this.selectedTags.push(event.option.value);
    this.matChipInput.reset('');
    this.inputField.nativeElement.blur();
    this.setQuery();
  }

  onAdd(event: any): void {
    let input = event.input;
    let value = event.value;
    /*if (value) {
      this.selectedTags.push(value);
    }*/
    if (input) {
      input.value = '';
    }
    //this.setQuery();
  }

  onRemove(tag: any): void {
    let index = this.selectedTags.indexOf(tag);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
    this.setQuery();
  }

  setQuery() {
    if (this.selectedTags.length) {
      if (this.entry.secondRowField) {
        this.query.where[this.entry['field']] = { '$in': this.selectedTags.map((item) => item.first_row) };
      } else {
        this.query.where[this.entry['field']] = { '$in': this.selectedTags };

      }
    } else {
      delete this.query.where[this.entry['field']];
    }

    this.queryChanged.emit(this.query);
  }

  public clearQueryOnFocusOut() {
    this.lookupQuery = new Query();
    this.matChipInput.reset('');
  }

  onScrollEvent($event) {
    let element = $event.srcElement;
    if (Math.ceil(element.scrollTop) >= (element.scrollHeight - element.clientHeight)) {
      if (this.lookupQuery.canNext()) {
        this.lookupQuery.page++;
        this.lookupQuery.offset = this.lookupQuery.from() - 1;

        if (this.entry.lookupProviderName !== 'currency' && this.entry.lookupProviderName !== 'country')
        this.entry.lookupProvider.findAll(this.lookupQuery)
          .subscribe((result) => {
            this.lookupQuery.total = result.total;
            this.lookupItems = this.lookupItems.concat(result.items.filter((item) => {
              return !this.selectedTags.includes(item);
            }));
          });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['query']) {
      this.selectedTags = [];
    }
  }
}
