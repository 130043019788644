import {Injectable} from '@angular/core';
import {FilterService} from '../../core/filter/filter.service'
import {tagAdministrationFilterContext} from './tag-administration-filter.context';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class TagAdministrationFilterService extends FilterService {

  constructor(public userSettingsService: UserSettingsService) {
    super("app.tag.tag-administration-filter", tagAdministrationFilterContext, userSettingsService);
  }
}


