import { Pipe, PipeTransform, Directive } from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {UserService} from "../../user/core/user.service";
import {LocationService} from "../../location/core/location.service";
import {LocationQuery} from "../../location/core/location.query";

// @Directive()
@Pipe({
  name: 'caCurrency'
})
export class CaCurrencyPipe extends CurrencyPipe implements PipeTransform {
  private __currencyDisplay: any = JSON.parse(localStorage.getItem('__currencyDisplay'));
  private __currDigit: any = JSON.parse(localStorage.getItem('__currDigit'));
  private __currLocale: any = JSON.parse(localStorage.getItem('__currLocale'));
  private __negativeValue: any = JSON.parse(localStorage.getItem('__negativeValue'));
  private countries: any = [];
  public currency = localStorage.getItem('currency');


  constructor(public userService: UserService,
              public locationService: LocationService) {
    super(localStorage.getItem('__localeId'));
  }

  transform(value: any, currencyCode: string = this.currency, symbolDisplay?: string, digit?: string, locale?: string, useBigNumberFormats:boolean = false): any {
    let custom_symbol;
    this.countries = JSON.parse(localStorage.getItem('__countries'));
    for (let i = 0; i < this.countries.items.length; i++) {
      if (this.countries.items[i].currency === currencyCode) {
        custom_symbol = this.countries.items[i].currency_display;
        break;
      }
    }
    let symbol = custom_symbol ? custom_symbol : this.__currencyDisplay ? this.__currencyDisplay : 'symbol-narrow';
    let dig = digit || (this.__currDigit ? this.__currDigit : '1.2-2');
    let loc = this.__currLocale ? this.__currLocale : 'en';
    let neg = this.__negativeValue ? this.__negativeValue : '-';

    let bigNumSymbol = '';
    if(useBigNumberFormats){
      if (value <= -1000 || value >= 1000) {
        value = Math.floor((value / 1000));
        bigNumSymbol = 'K';
      }
      if (value <= -1000 || value >= 1000) {
        value = Math.floor((value / 1000));
        bigNumSymbol = 'M';
      }
      if (value <= -1000 || value >= 1000) {
        value = Math.floor((value / 1000));
        bigNumSymbol = 'B';
      }
    }

    let formattedByCurrencyPipe = super.transform(value, currencyCode, symbol, bigNumSymbol === '' ? dig:'1.0-0', loc);
    if (formattedByCurrencyPipe == null)
        return null;

    let retValue = formattedByCurrencyPipe.charAt(0) === '-' && neg === '()'?
    '(' + formattedByCurrencyPipe.substr(1) + ')' :
    formattedByCurrencyPipe;

    if(useBigNumberFormats){
      if(retValue.charAt(retValue.length-1)==')' && bigNumSymbol!=='') {
          retValue= [retValue.slice(0, retValue.length-1), bigNumSymbol, retValue.slice(retValue.length-1)].join('');
        } else retValue +=bigNumSymbol;
    }

    return retValue;
  }

}
