export const alertInventoryFilterContext = {
  filters: [
    {
      field: 'unique_id',
      label: 'ID',
      type: 'string',
      index: 1,
      visible: true
    },
    {
      field: 'internal_id',
      label: 'Internal ID',
      type: 'string',
      index: 2,
      visible: true
    },
    {
      field: 'vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 3,
      visible: true
    },
    {
      field: 'install_date',
      label: 'Install date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 4,
      visible: true
    },
    {
      field: 'siteA.site_id',
      label: 'Site A (Carrier Location)',
      type: 'lookup',
      lookupProviderName: 'site',
      index: 5,
      visible: true
    },
    {
      field: 'siteZ.site_id',
      label: 'Site Z (Customer Location)',
      type: 'lookup',
      lookupProviderName: 'site',
      index: 6,
      visible: true
    },
    {
      field: 'status_id',
      label: 'Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'inventoryStatus',
      index: 7,
      visible: true
    },
    {
      field: 'type.value',
      label: 'Type',
      type: 'string',
      index: 8,
      visible: true
    },
    {
      field: 'message',
      label: 'Message',
      type: 'string',
      index: 9,
      visible: true
    }
  ]
};
