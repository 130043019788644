import {InvoiceFacepage} from "../../core/invoice-facepage";

export interface SharedFilterOptions {
  component?: string;
  query?: any
}

export class UpdateActiveSiderSection {
  static readonly type = '[INVOICE_DETAILS] Update active sider section';
  constructor(public name: string) {}
}

export class UpdateInvoiceDetailsFilter {
  static readonly type = '[INVOICE_DETAILS] Update shared filter';
  constructor(public options?: SharedFilterOptions) {}
}

export class LoadSingleInvoice {
  static readonly type = '[INVOICE_DETAILS] Load invoice details';
  constructor(public id: number) {}
}

export class DownloadInvoicePDF {
  static readonly type = '[INVOICE_DETAILS] Download invoice pdf';
  constructor(public invoice: InvoiceFacepage) {}
}

export class DownloadInvoiceContainer {
  static readonly type = '[INVOICE_DETAILS] Download invoice container';
  constructor(public invoice: InvoiceFacepage) {}
}

export class DownloadInvoiceSource {
  static readonly type = '[INVOICE_DETAILS] Download invoice source';
  constructor(public invoice: InvoiceFacepage) {}
}
