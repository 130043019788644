<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Disputes</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button *permission="'Modify'"
    mat-icon-button
    (click)="editDispute(selection)"
    matTooltip="Edit Dispute"
    [disabled]="!selection || (selection && (
      selection.status_id == DISPUTE_STATUS_ENUM.CLOSED_LOST ||
      selection.status_id == DISPUTE_STATUS_ENUM.CLOSED_WON
    ))">
    <ca-icon [key]="'EDIT'"></ca-icon>
  </button>
  <button *permission="'Delete'"
          mat-icon-button
          matTooltip="Delete Dispute"
          (click)="deleteDispute(selection)"
          [disabled]="!selection || (selection && selection.deleteDisabled)">
    <ca-icon [key]="'DELETE'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh List" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button matTooltip="Filter" [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button>
          <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button #toggleDetailsEnableButton [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
          [disabled]="!selection" (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)"
          mat-icon-button>
          <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>
  </button>
</mat-toolbar>


<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">

  <dx-data-grid #grid (onSelectionChanged)="onSelectionChanged($event)" [columns]="columns" [showColumnLines]="false"
                (onCellClick)="onCellClick($event)"
                [showRowLines]="true" [showBorders]="true" (onRowClick)="onRowClick($event)" [dataSource]="disputes">
    <div *dxTemplate="let d of 'withheldCellTemplate'">
      <div caGridTooltip>{{ d.value ? 'Yes' : 'No' }}</div>
    </div>
    <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
      <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency?.currency }}</div>
    </div>
    <div *dxTemplate="let d of 'dateTimeTemplate'">
      <div caGridTooltip class="right">
        {{ d.value | caDateTime}}
      </div>
    </div>

    <div *dxTemplate="let d of 'subclientTemplate'">
      <div caGridTooltip class="right">
       {{ d.value.length && d.value.length > 1 ? 'Multiple' : (d.value[0] ? d.value[0].value : '')  }}
      </div>
    </div>

  </dx-data-grid>
  <ca-pager  [gridPager]="defaultGridPager" [pageContext]="disputeGridService"  [query]="query" class="app-pager ca-default-border-top" (onChange)="loadData(query)"></ca-pager>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                [query]="query"
                [filterService]="disputeFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter()">
    </new-app-filter>
    <app-dispute-sneak-peek [dispute]="selection" (closeRequested)="sider?.close()"
                            [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}">
    </app-dispute-sneak-peek>
  </mat-sidenav>

</mat-sidenav-container>
