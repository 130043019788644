import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Building } from '../../core/building';


@Component({
  selector: 'app-building-sneak-peek',
  templateUrl: './building-sneak-peek.component.html',
})
export class BuildingSneakPeekComponent {
  @Input() building: Building;

  @Output() closeRequested = new EventEmitter();
  @Output() editRequested = new EventEmitter();
  @Output() deletedRequested = new EventEmitter()

  constructor() { }

  editBuilding() {
    this.editRequested.emit(this.building);
  }

  deleteBuilding() {
    this.deletedRequested.emit(this.building);
  }

  closeSide() {
    this.closeRequested.emit();
  }
}
