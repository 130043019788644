import { Pipe, PipeTransform } from '@angular/core';
import {FormatService} from "./format.service";

@Pipe({
  name: 'caPhone'
})
export class PhonePipe implements PipeTransform {

  constructor(public formatService: FormatService) {
  }

  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    return this.formatService.phone(value);
  }

}
