export class NavigationModel {

  public title: string;
  public icon?: string;
  public value?: string;
  public resource?: any = {};
  public key?: string;
  public items?: NavigationModel[];
  public open: boolean = false;
  public selected: boolean = false;
  public unprotected: boolean = false;
  public allowed: boolean = false;
  public visible: boolean = true;
  group: any;

  focused: boolean = false;

  constructor(data: any) {
    Object.assign(this, data);
  }

  toggle() {
    this.open = !this.open;
  }

  isOpen() {
    return this.open;
  }

  hasItems() {
    return this.items && this.items.length;
  }
}
