// import { ControlValueAccessor } from '@angular/forms/src/directives';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import {UserService} from "../../user/core/user.service";

MomentDateAdapter.prototype.createDate = function (year, month, date) {
  // Moment.js will create an invalid date if any of the components are out of bounds, but we
  // explicitly check each case so we can throw more descriptive errors.
  if (month < 0 || month > 11) {
    throw Error('Invalid month index "' + month + '". Month index has to be between 0 and 11.');
  }
  if (date < 1) {
    throw Error('Invalid date "' + date + '". Date has to be greater than 0.');
  }
  const result = moment.utc({ year: year, month: month, date: date });
  if (!result.isValid()) {
    throw Error('Invalid date "' + date + '" for month with index "' + month + '".');
  }
  return result;
}

MomentDateAdapter.prototype.deserialize = function (value) {
  let date;
  if (value instanceof Date) {
    value = value.toISOString();
  }
  if (typeof value === 'string') {
    if (!value) {
      return null;
    }
    date = moment.utc(value, moment.ISO_8601);
  }
  if (date && this.isValid(date)) {
    return date;
  }
  return DateAdapter.prototype.deserialize.call(this, value);
};


@Component({
  selector: 'ca-date-picker',
  templateUrl: './ca-date-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaDatePickerComponent),
      multi: true
    }
  ]
})
export class CaDatePickerComponent implements OnInit, ControlValueAccessor {
  // tslint:disable-next-line
  @Input('required') isRequired = false;
  // tslint:disable-next-line
  @Input('disabled') isDisabled = false;
  // tslint:disable-next-line
  @Input('readonly') isReadonly = false;
  // tslint:disable-next-line
  @Input('value') _value: any;
  @Input() placeholder = 'Date';
  @Output() selectedChanged = new EventEmitter();
  @ViewChild('date') dateInput;
  @Input() minDate = new Date(1000, 0, 1);
  @Input() maxDate = new Date(3000, 0, 1);
  @Input() startMonth = false;
  @Input() endMonth = false;
  public validDate = true;
  public touched = false;

  public onPasteCheck = false;

  public VALIDATION = {
    REQUIRED_ERROR: 'Date is required',
    DATE_INVALID: 'Date format is invalid'
  }

  constructor(private userService: UserService) {
    userService.me().subscribe(user => {
      this.VALIDATION.DATE_INVALID = user.country.date_format;
    });
  }

  ngOnInit() {
  }

  ngOnDateChange(event) {
    if (this.onPasteCheck) {
      this.onPasteCheck = false;
    } else {
      this.selectedChanged.emit(event.value);
      const newValue = moment(event.value);
      this.value = newValue.isValid() ? newValue.format('YYYY-MM-DD') : null;
    }
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
  }

  propagateChange = (_: any) => {
  }

  onFocusOut(e) {
    this.touched = true;
  }

  onTouched: any = (e) => {
  };

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData
    let pastedText = clipboardData.getData('text');
    this.onPasteCheck = true;
    this._value = pastedText;
    this.selectedChanged.emit(pastedText);
    // this._value = pastedText;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  showRequireError() {
    return this.isRequired
      && this.validDate
      && !this.value
      && this.touched;
  }

  validate(value) {
    let m = moment(value).isValid();

    // if value is not empty string or null
    if(value){
      this.validDate = m;
      return this.validDate;
    }

    // validDate is true so error message is not shown for empty values
    this.validDate = true;
    return false;
  }

  onChange(e) {
    let value = e.target.value;

    if (this.validate(value)) {
      value = moment(value).format('YYYY-MM-DD');
      this.selectedChanged.emit(value);
    } else {
      this.selectedChanged.emit(null);
    }
  }

  onPickerChange(e) {
    this.validate(this.value);
  }

  showValidationError() {
    return !this.validDate
      && this.touched;
  }

  dateFilter = (date) => {
    const momentDate = moment.utc(date);
    if (this.minDate && this.maxDate) {
      return momentDate.isSameOrAfter(moment.utc(this.minDate)) && momentDate.isSameOrBefore(moment.utc(this.maxDate));
    } else if(this.minDate) {
      return momentDate.isSameOrAfter(moment.utc(this.minDate));
    } else if (this.maxDate) {
      return momentDate.isSameOrBefore(moment.utc(this.maxDate));
    } else {
      return true;
    }
  };
}
