export const projectFilterContext = {
  filters: [
    {
      field: 'project_id',
      label: 'Project ID',
      type: 'string',
      index: 1
    },
    {
      field: 'name',
      label: 'Project Name',
      type: 'string',
      index: 2
    },
    {
      field: 'status.key',
      label: 'Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'projectStatus',
      index: 4
    },
    {
      field: 'start_date',
      label: 'Start Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 5
    },
    {
      field: 'end_date',
      label: 'End Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 6
    },
    {
      field: 'description',
      label: 'Description',
      type: 'string',
      index: 7,
      visible: true
    }
  ]
};
