import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  IterableDiffers,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import { NotesService } from 'app/shared/notes/core/notes.service';
import { InvoiceChargeService } from 'app/charge/core/invoice-charge.service';
import { Notes } from 'app/shared/notes/core/notes';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Query from 'app/core/query/query';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { UserModalComponent } from 'app/shared/user-modal/user-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ChargeQuery } from 'app/charge/core/charge.query';
import { of } from 'ramda';
import {flatMap} from "rxjs/internal/operators";

@Component({
  selector: 'ca-charge-notes',
  templateUrl: './charge-notes.component.html',
  styleUrls: ['./charge-notes.component.css']
})
export class ChargeNotesComponent implements OnInit, DoCheck, OnChanges {
  @Input() entityType: string;
  @Input() entityId: number;
  @Input() createNoteAllowed = true;
  @Input() chargesNote: boolean;
  @Input() chargesRequired: boolean;
  @Input() charges = [];
  @Input() query: ChargeQuery = new ChargeQuery();
  @Input() selectedId: number;

  @Output() viewNoteCharges = new EventEmitter();
  @Output() countChanged = new EventEmitter();

  @Output() noteCreated = new EventEmitter();

  public notes: Array<Notes> = [];
  public replyMode = false;
  noteForm: FormGroup;
  selectedNote: Notes;

  _charges = [];

  // isFetchingNotes = false;

  iterableDiffer;

  constructor(
    public notesService: NotesService,
    public formBuilder: FormBuilder,
    public dialog: DialogService,
    public sanitizer: DomSanitizer,
    public elRef: ElementRef,
    public invoiceChargeService: InvoiceChargeService,
    private iterableDiffers: IterableDiffers,
  ) {
    this.iterableDiffer = this.iterableDiffers.find([]).create(null);
  }

  ngOnInit() {
    this.noteForm = this.formBuilder.group({
      content: new FormControl('', Validators.required)
    });

    if (this.charges.length) {
      this.fetchNotes();
    }
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.charges);

    if (changes) {
      this._charges = changes.collection;
      this.fetchNotes()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.query && changes.query.previousValue) {
      if (changes.query.previousValue.total > changes.query.currentValue.total) {
        this.notes = [];
      }
    }
  }

  loadNotes() {
    let query = new Query({
      where: { entity_type: this.entityType, parent_id: null },
      orderBy: [['created_at', 'DESC']]
    });

    if (this.entityId) {
      query.where['entity_id'] = this.entityId;
    }
    this.notesService.findAll(query)
      .subscribe(
        (result) => {
          this.notes = [...result.items];
          this.emitCount();
          console.log({ notes: this.notes })
        },
        (error) => {
          console.log(error);
        }
      );
  }

  cancel(note: Notes) {
    note.reply = null;
    this.replyMode = false;
  }

  createNote(parentId?: number, content?: string): void {
    let note = new Notes();

    let query = new Query({
      where: { entity_type: this.entityType, parent_id: null },
    });

    if (this.entityId) {
      query.where['entity_id'] = this.entityId
    }

    note.content = content || this.noteForm.controls['content'].value;
    note.entity_id = this.entityId || null;
    note.entity_type = this.entityType;
    note.parent_id = parentId;
    note.master_id = parentId;

    note.charges = ((this.charges && this.charges.length) && !parentId) ? this.charges : [];

    this.noteForm.reset();

    this.notesService.create(note)
      .subscribe(
        () => {
          this.fetchNotes();
          this.noteCreated.emit();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  reply(note: Notes) {
    if (this.replyMode) {
      this.createNote(note.note_id, note.reply);
    } else {
      this.replyMode = true;
      this.selectedNote = note;
    }
  }

  getUserInitials(note): string {
    return note.user
      ? note.user.first_name.toUpperCase()[0] + note.user.last_name.toUpperCase()[0]
      : 'NA';
  }

  viewCharges(charges) {
    this.viewNoteCharges.emit(charges);
  }

  public showUserModal(user) {
    this.dialog.open(UserModalComponent, { user: user }, { width: '400px' });
  }

  emitCount() {
    this.countChanged.emit(this.notes.length);
  }

  sanitizeNoteContent(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  public fetchNotes() {
    if (this.charges.length) {
      this.fetchNotesForSelectedCharges(this.charges);
    } else {
      this.fetchNotesForQuery(this.query);
    }
  }

  public fetchNotesForSelectedCharges(charges: Array<any>) {
    if(charges.length == 0) return;

    const chargeNoteQueryIds = charges.filter(charge=>charge.info_only_ind === 'N').map((charge) => charge.id);

    const chargeNoteQueryInfoOnlyIds = charges.filter(charge=>charge.info_only_ind !== 'N').map((charge) => charge.id);

    const chargeNoteQuery = new Query({
        where: {
          charge_id: {
            '$in': chargeNoteQueryIds
          },
        },
        limit: 100000,
      }
    );

    const chargeNoteInfoOnlyQuery = new Query({
        where: {
          charge_id: {
            '$in': chargeNoteQueryInfoOnlyIds
          },
        },
        limit: 100000,
      }
    );
    this.notes = [];

    if(chargeNoteQueryIds.length>0 && chargeNoteQueryInfoOnlyIds.length>0)  {
    // combine info only notes with ordinery

    this.invoiceChargeService.findChargeNoteLargeRequest(chargeNoteQuery)
      .subscribe(
        result => {
          this.notes = [...result].map(this.mapInfoOnlyNotes);

          this.invoiceChargeService.findChargeInfoNotes(chargeNoteInfoOnlyQuery)
            .subscribe(
              result => {
                // tslint:disable-next-line:no-unused-expression
                result.length > 0 && (this.notes = [...this.notes, ...result].map(this.mapInfoOnlyNotes));
                this.emitCount();

                this.replyMode = false;

                // this.isFetchingNotes = false;
              },
              () => this.replyMode = false
              // error => this.isFetchingNotes = false
            );
        },
        () => this.replyMode = false
      );
    } else {

      (chargeNoteQueryIds.length > 0 ?
        this.invoiceChargeService.findChargeNoteLargeRequest(chargeNoteQuery) :
        this.invoiceChargeService.findChargeInfoNotes(chargeNoteInfoOnlyQuery))
      .subscribe(
        result => {
          this.notes = [...result].map(this.mapInfoOnlyNotes);
          this.emitCount();

          this.replyMode = false;
        },
        () => this.replyMode = false
      );

    }


  }

  public fetchNotesForQuery(chargeIdsQuery: ChargeQuery) {

    chargeIdsQuery['attributes'] = ['id'];

    this.invoiceChargeService.filters(chargeIdsQuery)
      .pipe(flatMap(
        ({ items }) => {
          const chargeNoteQuery = new Query({
              where: {
                charge_id: {
                  '$in': items.filter(item => item.info_only_ind === 'N').map(item => item.id)
                },
              },
              limit: 100000,
            }
          );
          if(chargeNoteQuery.where.charge_id.$in.length === 0) return of([]);
          return this.invoiceChargeService.findChargeNotes(chargeNoteQuery);
        }
      ))
      .subscribe(
        result => {
          this.notes = [...result].map(this.mapInfoOnlyNotes);
          this.emitCount();

          this.replyMode = false;
        },
        () => this.replyMode = false
      );

    this.invoiceChargeService.filters(chargeIdsQuery)
      .pipe(flatMap(
        ({ items }) => {
          const chargeNoteInfoQuery = new Query({
              where: {
                charge_id: {
                  '$in': items.filter(item => item.info_only_ind !== 'N').map(item => item.id)
                },
              },
              limit: 100000,
            }
          );
          if(chargeNoteInfoQuery.where.charge_id.$in.length === 0) return of([]);
          return this.invoiceChargeService.findChargeInfoNotes(chargeNoteInfoQuery);
        }
      ))
      .subscribe(
        result => {
          this.notes = [...this.notes, ...result].map(this.mapInfoOnlyNotes);
          this.emitCount();

          this.replyMode = false;
        },
        () => this.replyMode = false
      );
  }

  mapInfoOnlyNotes = (infoOnlyNote) => {
      if(infoOnlyNote.note) return infoOnlyNote;
      return {
        charge:infoOnlyNote.charges[0],
        charge_id:infoOnlyNote.charges[0].id,
        note:{...infoOnlyNote},
        note_id:infoOnlyNote.id,
        id:infoOnlyNote.id
      };
  }
}
