import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class WizardVendorGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super('app.quoting.wizard-selected-vendors-grid', userSettings);
  }

  defaultColumns = [
    {
      caption: 'Vendor',
      dataField: 'vendor.name',
      width: 130
    },
    {
      caption: 'Method',
      cellTemplate: 'methodCellTemplate',
      width: 100
    },
    {
      caption: 'Status',
      cellTemplate: 'emailCellTemplate',
      width: 100

    }
  ];

}
