import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import qs from 'qs';
import {CacheFactory} from 'cachefactory';
import {Cache} from "../cache.servce";
import {Router} from "@angular/router";


@Injectable()
export class ApiService {
  private cacheFactory: CacheFactory = Cache.factory;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }


  private formatErrors(response: any) {
    if (response && response.error && response.error.status === 403) {
      return this.router.navigate(['/auth/sign-in']);
    }
    return throwError(response);
  }

  getQueryString(query?: any) {
    const params = Object.assign({}, query || {});
    // TODO: Should we flatten where param in the query?
    // if (params.where) {
    //   Object.assign(params, params.where);
    //   delete params.where;
    // }
    return qs.stringify(params,
      {
        encoder: function (str, defaultEncoder, charset, type) {
          if (type === 'value') {
            return encodeURIComponent(str);
          } else if (type === 'key') {
            return str;
          }
        }
      })
  }

  getPath(path) {
    if (Array.isArray(path)) {
      path = path.join('/');
    }

    return path;
  }

  getFullUrl(path, query?) {
    path = this.getPath(path);
    query = this.transformQuery(query);
    const args = [`${environment.API_URL}${path}`];
    const queryString = this.getQueryString(query);

    if (queryString) {
      args.push(queryString);
    }
    return args.join('?');
  }

  transformQuery(query) {
    return query && query.transform ? query.transform(query) : query;
  }

  clearAllCache() {
    this.cacheFactory.clearAll();
  }

  cache(name, options = {
    maxAge: 60 * 60 * 1000,
    deleteOnExpire: 'aggressive'
  }) {
    let cache;
    if (!this.cacheFactory) {
      this.cacheFactory = Cache.factory;
    }

    const {maxAge, deleteOnExpire} = options;
    if (!this.cacheFactory?.exists(name)) {
      cache = this.cacheFactory?.createCache(name, {
        maxAge,
        // @ts-ignore
        deleteOnExpire
      });
    } else {
      cache = this.cacheFactory?.get(name);
    }
    return cache;
  }

  get(path: string | any[], query = {}, params: any = {}): Observable<any> {
    const url = this.getFullUrl(path, query);
    return this.http.get(url)
      .pipe(
        catchError(this.formatErrors)
      );
  }

  getCustom(path: string | any[], query = {}, params: any = {}): Observable<any> {
    const url = this.getFullUrl(path, query);
    return this.http.get(url, {responseType: "blob", observe: "response"})
      .pipe(
        catchError(this.formatErrors)
      );
  }

  postCustom(path: string | any[], body = {}): Observable<any> {
    const url = this.getFullUrl(path);
    return this.http.post(
      url,
      JSON.stringify(body),
      {responseType: "blob", observe: "response"}
    ).pipe(
      catchError(this.formatErrors)
    );
  }


  put(path: string | any[], body = {}): Observable<any> {
    const url = this.getFullUrl(path);

    return this.http.put(
      url,
      JSON.stringify(body)
    ).pipe(
      catchError(this.formatErrors)
    );
  }

  post(path: string | any[], body = {}): Observable<any> {
    const url = this.getFullUrl(path);
    return this.http.post(
      url,
      JSON.stringify(body)
    ).pipe(
      catchError(this.formatErrors)
    );
  }

  delete(path: string | any[], body = {}): Observable<any> {
    const url = this.getFullUrl(path);
    return this.http.delete(
      url,
      body
    ).pipe(
      catchError(this.formatErrors)
    );
  }
}
