<app-contract-details-toolbar
    [isAttachmentsTab]="true"
    [documentNotSelected]="!selection"
    (deleteDocumentRequested)="delete()"
    (uploadDocumentRequested)="upload()"
    (downloadDocumentRequested)="download()"
    [isFilterDisabled]="true"
    [notesCount]="notesCount"
    [isNotesOn] = "this.sider.getActiveSection().name === this.SECTIONS.NOTES_SECTION_NAME"
    (toggleNotesRequested)="toggleNotes()"
    [isHistoryOn] = "this.sider.getActiveSection().name === this.SECTIONS.HISTORY_SECTION_NAME"
    (toggleHistoryRequested)="toggleHistory()"
>
</app-contract-details-toolbar>

<mat-sidenav-container class="app-content app-content-list tabbed-content dx-nodata-absolute">
    <app-contract-details-tabs [activeTabIndex]="4" [contract]="contract"></app-contract-details-tabs>

    <div class="tabs-body grid-container grid-with-toolbar"
            style="top: -2px; z-index: -1; overflow-y: hidden;">
        <app-contracts-document-manager 
            #documentsManager 
            *ngIf="DOCUMENT_INFO.SUBFOLDER" 
            [tabChanged]="false"
            [folder]="DOCUMENT_INFO.FOLDER" 
            [subfolder]="DOCUMENT_INFO.SUBFOLDER" 
            [parentType]="DOCUMENT_INFO.PARENT_TYPE"
            [entityType]="DOCUMENT_INFO.ENTITY_TYPE" 
            [entity]="contract" 
            [contractDocument]="contract?.document"
            (docAdd)="history.refreshList()" 
            (docDelete)="history.refreshList()" 
            (docSelected)="docSelected($event)">
        </app-contracts-document-manager>
    </div>

    <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false" (close-start)="panelClose()">  
        <new-app-filter *ngIf="contract" [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                        [query]="documentQuery" [includeTypes]="includeTypes" [documents]="true" [filterService]="documentFilterService"
                        (filterRequested)="filterDocumentData($event)" (clearFilterRequested)="clearDocumentFilter($event)">
        </new-app-filter>   
        <ca-notes [entityId]="contract?.id" [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                  [entityType]="SYSTEM_MODULE.CONTRACT" (countChanged)="onNotesCountChanged($event)"></ca-notes>
    
        <ca-history #history
                    [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
                    [entityId]="contract?.id"
                    [entityType]="'contract'"
        ></ca-history>
      </mat-sidenav>
</mat-sidenav-container>