import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { takeUntil } from "rxjs/operators";
import { ChargeQuery } from "../../charge/core/charge.query";
import { InvoiceChargeService } from "../../charge/core/invoice-charge.service";
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import Query from "../../core/query/query";
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LOOKUP_MODELS_ENUM } from '../../dictionary/core/lookup-models.enum';
import { DisputeManageDialogComponent } from "../../dispute/shared/dispute-manage-dialog/dispute-manage-dialog.component";
import { InventoryQuery } from "../../inventory/core/inventory.query";
import { InventoryService } from "../../inventory/core/inventory.service";
import { LocationService } from "../../location/core/location.service";
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from "../../shared/dialog/dialog.service";
import { GridService } from "../../shared/grid/grid.service";
import { LoaderService } from '../../shared/loader/loader.service';
import { TabGroup, TabService } from '../../shared/tabs/tab.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { UserService } from "../../user/core/user.service";
import { AlertMissingInventoryDetailsGridService } from '../core/alert-missing-inventory-details-grid.service';
import { AlertMissingInventorySpidGridService } from "../core/alert-missing-inventory-spid-grid.service";
import { AlertMissingInventoryFilterService } from '../core/alert-missing-invertory-filter.service';
import { CommonAlertService } from '../core/common-alert.service';

@Component({
  selector: 'app-alert-missing-inventory',
  templateUrl: './alert-missing-inventory.component.html',
  styleUrls: ['./alert-missing-inventory.component.css']
})

export class AlertMissingInventoryComponent extends PageListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @ViewChild('spidChargesDataGrid', {static: false}) spidChargesDataGrid: DxDataGridComponent;
  @ViewChildren(DxDataGridComponent) dataGrids: QueryList<DxDataGridComponent>

  public alert: any;
  public summary: any;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTab: number = 0;
  public query: Query = new Query({ orderBy: [['id', 'ASC']] });
  public queryInventorySpidFilter: Query = new Query({orderBy: [['id', 'ASC']], limit: 20});
  public dataSource: Array<any> = [];
  public spids: Array<any> = [];
  public columns: any;
  chargesSelectionActive = false;
  selectedCharges: Array<any> = [];
  selectedSpids: Array<any> = [];
  addDisputeDisabled = true;
  delayChargesSelectionChange = false;
  charges: any
  public chargeColumns: any;
  public sizes = [5, 10, 20];

  public chargeQuery: ChargeQuery = new ChargeQuery({orderBy: [['id', 'ASC']]});
  public inventoryQuery: Query = new Query({ orderBy: [['id', 'ASC']] });
  public dictionaryQuery: Query = new Query({ orderBy: [['id', 'ASC']] });
  public filterQuery: Query = new Query({ orderBy: [['id', 'ASC']] });

  public activeTabIndex: number = 0;
  public currentTabIndex = 0;

  readonly PLACEHOLDERS = {
    OWNER_UNASSIGNED: 'Unassigned'
  };

  public DISPUTE_CATEGORY_LOOKUP_MODEL = LOOKUP_MODELS_ENUM.DISPUTE_CATEGORY.modelName;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'common-alert';
  chargesRowsExpanded: any[] = [];

  public selectedAlertId = null;
  public alertId: number;

  constructor(public settingsService: UserSettingsService,
              public route: ActivatedRoute,
              public router: Router,
              public toastAlertService: AlertService,
              public loaderService: LoaderService,
              public tabService: TabService,
              public alertMissingInventoryDetailsGridService: AlertMissingInventoryDetailsGridService,
              public alertMissingInventorySpidDetailsGridService: AlertMissingInventorySpidGridService,
              public alertService: CommonAlertService,
              public commonAlertService: AlertService,
              public alertMissingInventoryFilterService:AlertMissingInventoryFilterService,
              public dialogService: DialogService,
              public chargeService: InvoiceChargeService,
              public inventoryService: InventoryService,
              public userService: UserService,
              public locationService: LocationService,
              public dictionaryService: DictionaryService,


  ) {
    super(new PageContext({
      name: 'app.alert.alert-missing-inventory',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    this.currentTabIndex = index;

    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }
  }

  public loadTabs() {
    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({ key: 1, title: 'Summary' });
    this.tabGroup.addTab({ key: 2, title: 'Details' });

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe((tab) => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }

  findInventorySpid(query: InventoryQuery, filter?:boolean) {
    this.alertService.findMissingInventorySpid(this.alertId, query)
      .subscribe((result) => {
        this.spids = result.items;
        query.total = result.total;
        if (filter) {
          this.spids.forEach((spid) => {
            let chargeQuery = new Query({
              where: {
                ...this.query.where, sp_serv_id: spid.id
              }
            })
            this.alertService.findMissingInventory(this.alertId, chargeQuery)
              .subscribe((results) => {
                if (results.items && results.items.length) {
                  this.dataSource = results.items;
                  Object.assign(spid, {
                    charges: results.items,
                    resultQuery: chargeQuery
                  });
                  spid.resultQuery.total = results.total;
                  // // add selected charge ids into spid.selectedRows
                  const charges = this.selectedCharges.filter(chg => spid.id === chg['rule_id'])
                  spid.selectedRows = charges.length ? charges.reduce((acc, item) => ([...acc, item.id]), []) : []
                  spid.selectionActive = false;
                  this.delayChargesSelectionChange = true;
                  setTimeout(() => {
                    this.selectRowsWithinDetailsGrid(spid.id)
                  }, 200)
                }
              })
          });

          this.loaderService.hideLoader(true)
        } else {
          setTimeout(() => {
            if (this.dataGrid) {
              this.dataGrid.instance.refresh();
              this.chargesRowsExpanded.map(item => {
                this.onRowExpanded(item, true);
              });
              this.loaderService.hideLoader(true)
            }
          }, 100);
        }
      },
      error => {
        console.error(error);
        this.loaderService.hideLoader(true);
      })
  }

  loadData(query,filter?) {
    this.dataSource = [];
    this.loaderService.displayLoader(true)
    this.findInventorySpid(this.query,filter)
  }

  refresh() {
    this.loadData(this.query);
  }

  filterData(query: Query) {
    this.query = query;
    this.filterQuery.where = {...query.where}
    this.chargesSelectionActive = false;
    this.loadData(this.query,true);
  }

  clearFilter() {
    this.query.where = {};
    this.filterQuery.where = {};
    this.chargesSelectionActive = false;
    this.loadData(this.query);
  }

  onPageChange(query: Query) {
    this.loadData(query);
  }
  _init() {
    this.prepareList();
    this.chargeColumns = this.alertMissingInventoryDetailsGridService.columns();
    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.refresh();
    }

    this.route.params.pipe(
      takeUntil(this.destroy$))
      .subscribe((params: Params) => {
        this.alertId = params['id'];

        this.loadData(this.alertId);
      });
  }

  getGridService(): GridService {
    return this.alertMissingInventorySpidDetailsGridService;
  }

  ngOnInit() {
    this.loadTabs();
    this.alert = this.route.snapshot.data.alert;
    this.tabGroup.tabs[1].disabled = this.alert.message === 'No issues found';
    this.alertId = this.alert.id;
    this._init();
    this.columns = this.alertMissingInventorySpidDetailsGridService.columns();
  }

  ngAfterViewInit(): void {
    this.alertMissingInventorySpidDetailsGridService.create(this.dataGrid.instance, {
      noDataText: this.alertMissingInventorySpidDetailsGridService.noDataMessage
    });
    if(this.spidChargesDataGrid) {
      this.alertMissingInventoryDetailsGridService.create(this.spidChargesDataGrid.instance, {
        noDataText: this.alertMissingInventoryDetailsGridService.noDataMessage
      });
    }

    super.ngAfterViewInit();
  }

  csv() {
    const { alertMissingInventoryDetailsGridService, query, inventoryService, alertService, alertId, alert } = this;

    alertMissingInventoryDetailsGridService.csvMap().subscribe(fields => {
      const alertMissingInventoryCSVTransform = (items) => {
        return items.map(item => {
          const { id } = item;
          const details = alert.charge_details.find(detail => id === detail.charge_id);

          if (details) {
            item.message = details.message;
          }

          return item;
        });
      }

      inventoryService.exportToCSV(
        'alert-missing-inventory',
        {
          fields,
          query: query,
          fetchDataHandler: csvQuery => {
            return alertService.findMissingInventory(alertId, csvQuery);
          },
          middleware: [alertMissingInventoryCSVTransform],
        }
      );

    });
  }

  back(): void {
    if (document.referrer.indexOf(window.location.host) >= 0) {
      history.back();
    } else {
      this.router.navigate(['/alert']);
    }
  }

  ngOnDestroy() {
    this.tabGroup.onActivate.unsubscribe();
  }

  showDispute(id: any) {
    if(id){
      this.router.navigate(['/dispute', id, 'show']);
    }
  }

  findIndexInSelectedCharges(chargeId): number {
    for (let i = 0, l = this.selectedCharges.length; i < l; i++) {
      if (this.selectedCharges[i].id === chargeId) {
        return i;
      }
    }
    return -1;
  }

  findIndexInResultCharges(chargeId, rule): number {
    for (let i = 0, l = rule.selectedRows.length; i < l; i++) {
      if (rule.selectedRows[i] === chargeId) {
        return i;
      }
    }
    return -1;
  }

  confirmNoDisputeChargesAreSelected(charges) {
    return charges.every(charge => !charge['charge']['charge_dispute'])
  }

  onChargesSelectionChanged(event, spid) {
    setTimeout(() => {
      if (!this.delayChargesSelectionChange && spid.charges.length) {
        /** Selected rows */
        this.selectedAlertId = spid.id;
        if (event.selectedRowKeys.length) {
          event.selectedRowsData.forEach(item => {
            item['variance'] = spid.variance;
            let index = this.findIndexInSelectedCharges(item.id);
            if (index === -1) {
              item['rule_id'] = spid.id // rule_id needed for dataGrid pager / charges selection
              this.selectedCharges.push(item);
            }
            let resultIndex = this.findIndexInResultCharges(item.id, spid);
            if (resultIndex === -1 && !spid.selectedRows.includes(item.id)) {
              spid.selectedRows.push(item.id);
            }
          });
        }
        /** Deselected rows */
        if (event.currentDeselectedRowKeys.length) {
          event.currentDeselectedRowKeys.forEach(id => {
            let index = this.findIndexInSelectedCharges(id);
            if (index > -1) {
              this.selectedCharges.splice(index, 1);
            }
            let resultIndex = this.findIndexInResultCharges(id, spid);
            if (resultIndex >= 0) {
              spid.selectedRows = [...spid.selectedRows.filter(rowId => id !== rowId)]
            }
          });
        }
      }

      /** Dispute Add enabled/disabled */
      if (this.selectedCharges.length) {
        // if client want to be able to create dispute part of the charges excluding those that already have dispute
        this.addDisputeDisabled = !this.confirmNoDisputeChargesAreSelected(this.selectedCharges)
      } else {
        this.selectedSpids = this.selectedSpids.filter(spd => spd.sp_serv_id !== spid.sp_serv_id);
        this.addDisputeDisabled = true;
        this.selectedAlertId = null;
      }

      this.delayChargesSelectionChange = false;
    })
  }

  loadSelectedAuditResults(rule) {
    if (rule.selectionActive) {
      if (this.queryInventorySpidFilter.where.id)
        rule.resultQuery.where.id = {...this.queryInventorySpidFilter.where.id}
      else
        rule.resultQuery.remove('id')
    } else {
      rule.resultQuery.set('id', {
        $in: rule.selectedRows
      });
    }
    rule.resultQuery.offset = 0;
    rule.resultQuery.page = 1;
    rule.selectionActive = !rule.selectionActive;
    this.loadAuditResult(rule.resultQuery, rule)
  }

  getCalculatedCharges(value){
    let newArray = []
    value.sort((a,b) => (a.charge_id > b.charge_id) ? 1 : ((b.charge_id > a.charge_id) ? -1 : 0))
    value.forEach(i  => {
      i.charge && i.charge.chg_amt && newArray.push(0) // We push zero, because user shouldn't be charged for this item on the bill
    })
    return newArray;
  }

  getDispDesc(value){
    let disputeDescriptionArray = [];
    value.forEach(i => {
      let descString;
      if (i.message.includes('does not have related inventory')) {
        descString = 'Inventory item not found for invoice charge.';
      } else {
        const messageList = i.message.split(`"`);
        const status = messageList[3];
        descString = `Billing detected for item in ${status} state.`
      }
      disputeDescriptionArray.push(descString);
    })
    return disputeDescriptionArray;
  }

  addDispute() {
    this.chargeQuery['where'] = {};
    this.chargeQuery.set('id', {
      $in: this.selectedCharges.map(x => {
        return x['charge_id'];
      })
    });

    this.chargeQuery.offset = 0;
    this.chargeQuery.page = 1;
    this.chargeQuery.limit = 10000;
    this.chargeQuery.set('invoice_id', this.selectedCharges[0]['alert']['invoice_id']);
    this.chargeService.filters(this.chargeQuery)
      .subscribe(async (result) => {
        this.charges = result.items;
        let resDate =this.selectedCharges[0].alert.invoice.inv_date;
        let arrayOfCalculatedCharges = this.getCalculatedCharges(this.selectedCharges);
        let invoice = this.selectedCharges[0].charge.invoice;
        let dispDesc = this.getDispDesc(this.selectedCharges);
        if (!this.selectedCharges.length || this.addDisputeDisabled) {
          return;
        }
        this.dialogService
          .open(
            DisputeManageDialogComponent,
            {
              charges: this.charges,
              invoice: invoice,
              calculatedCharges: arrayOfCalculatedCharges,
              dispDesc: dispDesc,
              alertId: this.alertId
            },
            {width: '60%'}
          )
          .afterClosed()
          .subscribe(async result => {
            if (result) {
              this.selectedCharges.forEach(i => {
                i['charge']['charge_dispute'] = {dispute_id: result.id};
              });
              this.selectedCharges = [];
              this.loadData(this.query);
              this.commonAlertService.success(
                '',
                this.messages.get('DISPUTE_CREATE_SUCCESS')
              );
            }
          });
      })
  }

  onChargesDataGridClick(event) {
    if (!event.target.classList.contains('selection-badge')) {
      this.delayChargesSelectionChange = false;
    }
  }

  selectRows() {
    this.dataGrid.instance.selectRows(this.selectedCharges.reduce((acc, item) => [...acc, item.id], []), false)
  }

  onRowCollapsed(row) {
    this.chargesRowsExpanded.splice(this.chargesRowsExpanded.indexOf(row.key), 1);
  }
  onRowExpanded(row, manual?: boolean) {
    let spid = this.spids.find((x) => {
      return manual ? x.id === row : x.id === row.key;
    });
    if (!spid) return;

    if (!spid.resultQuery) {
      spid.resultQuery = new Query({
        where: {
          ...this.queryInventorySpidFilter.where,
          sp_serv_id: spid.sp_serv_id,
          alert_id: this.alert.id
        },
        orderBy: [["id", "ASC"]],
      });
    }
    if(!spid.charges) {
      this.loaderService.displayLoader(true);
      this.alertService.findMissingInventory(this.alert.id, spid.resultQuery)
        .subscribe((results) => {
          if (results.items && results.items.length) {
            this.dataSource = results.items;
            Object.assign(spid, {charges: results.items});
            this.loaderService.hideLoader(true);
            spid.resultQuery.total = results.total;
            // // add selected charge ids into spid.selectedRows
            const charges = this.selectedCharges.filter(chg => spid.id === chg['rule_id'])
            spid.selectedRows = charges.length ? charges.reduce((acc, item) => ([...acc, item.id]), []) : []
            spid.selectionActive = false;
            this.delayChargesSelectionChange = true;
            setTimeout(() => {
              this.selectRowsWithinDetailsGrid(spid.id)
            }, 100)
          }
        })
    }
    if (!manual) {
      this.chargesRowsExpanded.push(row.key);
    }
  }

  selectRowsWithinDetailsGrid(spidId) {
    this.delayChargesSelectionChange = true;
    const rule = this.spids.find(item => item.id === spidId);
    if (rule && rule.charges && rule.charges.length) {
      this.dataGrids.forEach(grid => {
        const id = grid['element'].nativeElement.id
        if (id === 'charges-grid-' + rule.id) {
          let results = []
          rule.charges.forEach(chg => {
            if (this.selectedCharges.length && this.selectedCharges.reduce((acc, item) => ([...acc, item.id]), []).includes(chg.id))
              results.push(chg.id)
          })
          if (results.length)
            grid.instance.selectRows(results, false);
        }
      })
    }
  }
  loadAuditResult(query, spid) {
    this.delayChargesSelectionChange = true;
    this.loaderService.displayLoader(true);

    this.alertService.findMissingInventory(this.alert.id, query)
      .subscribe((result) => {
        spid.charges = result.items;
        spid.resultQuery.total = result.total;
        this.loaderService.hideLoader(true);
        setTimeout(() => {
          this.selectRowsWithinDetailsGrid(spid.id)
        }, 100)
      })
  }
  onResultPageChange(query: Query, rule) {
    let ruleOne = this.spids.find(x => x.id === rule.id);
    this.loadAuditResult(query, ruleOne);
  }
}
