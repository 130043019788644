
import {Pipe} from "@angular/core";
@Pipe({
  name: 'truncate'
})
export class TruncatePipe {
  transform(value: string, limit: number, trail: string): string {

    trail = trail || "..."

    return value && value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
