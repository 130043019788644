export class Quote {
  id: number;
  quote_id: string;
  request_date: Date;
  services: Array<any>;
  requestor_id: number;
  customer_id: number;
  quote_status: any;
  close_date: Date;
  opportunity_type_id: number;
  sales_representative_id: number;
  sales_engineer_id: number;
  crm_opportunity: string;
  customer: any;
  requester: any;
  opportunity_type: any;
  sales_representative: any;
  sales_engineer: any;
  quote_description: any;
  due_date: Date;
}
