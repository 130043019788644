import { DndModule } from 'ng2-dnd';
import { SiteModule } from '../site/site.module';
import { ContactModule } from '../contact/contact.module';
import { OrderServiceDateGridService } from './core/order-service-date-grid.service';
import { OrderServiceFlowService } from './core/order-service-flow.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DictionaryModule } from "../dictionary/dictionary.module";
import { ContractModule } from "../contract/contract.module";
import { OrderServiceRoutingModule } from './order-service-routing.module';
import { OrderServiceService } from './core/order-service.service';
import { OrderServiceComponent } from './order-service.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MaterialModule } from "app/material-barrel/material-barrel.module";
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserModule } from "../user/user.module";
import { AccountModule } from "../account/account.module";
import { CustomerModule } from "../customer/customer.module";
import { OrderServiceManageDialogComponent } from './shared/order-service-manage-dialog/order-service-manage-dialog.component';
import { FocReceiveComponent } from './flow/foc-receive/foc-receive.component';
import { ServiceInstallComponent } from './flow/service-install/service-install.component';
import { ServiceTestComponent } from './flow/service-test/service-test.component';
import {SharedModule} from "../shared/shared.module";
import { AccordionModule } from 'ngx-accordion';
import {QuotingModule} from "../quoting/quoting.module";
import {OrderServiceAcceptDateGridService} from "./core/order-service-accept-date-grid.service";
import {DemarcModule} from "../demarc/demarc.module";
import {OrderServiceDisconnectFlowService} from "./core/order-service-disconnect-flow.service";
import { OrderServicRecordOnlyFlowService } from "./core/order-service-record-only-flow.service"
import {DisconnectDateComponent} from './flow/disconnect-date/disconnect-date.component';
import { StopBillingDateComponent } from './flow/stop-billing-date/stop-billing-date.component';
import { DisconnectCompleteDateComponent } from './flow/disconnect-complete-date/disconnect-complete-date.component';
import { NetworkHubModule} from "../network-hub/network-hub.module";
import { CaTechnologyPickerComponent } from './shared/technology-picker-component/ca-technology-picker.component';
import { FinalBillingDateComponent } from './flow/final-billing-date/final-billing-date.component';

@NgModule({
  imports: [
    CommonModule,
    OrderServiceRoutingModule,
    FormsModule,
    UserModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTabsModule,
    FlexLayoutModule,
    DxDateBoxModule,
    DictionaryModule,
    ContractModule,
    AccountModule,
    CustomerModule,
    DxDataGridModule,
    SharedModule,
    ContactModule,
    SiteModule,
    AccordionModule,
    DndModule,
    MaterialModule,
    QuotingModule,
    DemarcModule
  ],
  entryComponents: [
    FocReceiveComponent,
    ServiceInstallComponent,
    ServiceTestComponent,
    DisconnectDateComponent,
    StopBillingDateComponent,
    DisconnectCompleteDateComponent,
    FinalBillingDateComponent
  ],
  providers: [
    OrderServiceService,
    OrderServiceFlowService,
    OrderServiceDateGridService,
    OrderServiceAcceptDateGridService,
    OrderServiceDisconnectFlowService,
    OrderServicRecordOnlyFlowService
  ],
  exports: [
    OrderServiceComponent,
    CaTechnologyPickerComponent
  ],
  declarations: [
    OrderServiceComponent,
    OrderServiceManageDialogComponent,
    FocReceiveComponent,
    DisconnectDateComponent,
    ServiceInstallComponent,
    ServiceTestComponent,
    StopBillingDateComponent,
    DisconnectCompleteDateComponent,
    FinalBillingDateComponent,
    CaTechnologyPickerComponent
  ]
})
export class OrderServiceModule { }
