import {Injectable} from '@angular/core';
import {GridService} from '../../grid/grid.service';
import {UserSettingsService} from "../../../user/core/user-settings.service";

@Injectable()
export class DocumentGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.documents.documents-grid", userSettings);
  }

  columns() {
    return [
      {
        dataField: 'file_name',
        caption: 'Name',
        width: 300
      },
      {
        dataField: 'description',
        caption: 'Description'
      }
    ];
  }

}
