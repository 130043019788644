export const vendorFilterContext = {
  filters: [
    {
      field: 'name',
      label: 'Name',
      type: 'string',
      index: 1,
      visible: true
    }
  ]
};
