
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap, delay} from 'rxjs/operators';
import { PreloadingStrategy, Route } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class PreloadSelectedModulesList implements PreloadingStrategy {

    iterator = 0;

    preload(route: Route, load: Function): any {

        if (route.data && route.data['preload']) {
            this.iterator++;
            return observableOf(true).pipe(delay(2000 * this.iterator),mergeMap(_ => load()),);
        }
        else {
            return observableOf(null);
        }

    }
}
