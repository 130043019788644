import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

import { BaseService } from '../../core/base.service';
import { ConfigService } from '../../core/config/config.service';
import Query from '../../core/query/query';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';
import {path, pipe, map, tap, pathOr} from 'ramda';
import {HttpClient} from "@angular/common/http";


const getInfoOnlyFilter = pipe(
  pathOr([], ['where', 'info_only_ind', '$in']),
  map((filter: any) => ({
    true: 'Y',
    false: 'N'
  }[filter])),
  (transformed = []) => transformed.length && ({
    $in: transformed
  })
);

const transformQuery = (query) => {
  const infoOnly = getInfoOnlyFilter(query);
  return {
    ...query,
    where: {
      ...query.where,
      ...infoOnly ? {
        info_only_ind: infoOnly
      } : undefined
    }
  }
};

@Injectable()
export class GLChargeDetailViewService extends BaseService<any> {
  constructor(
    public configService: ConfigService,
    public restangular: Restangular,
    public http: HttpClient,
    public entityLock: EntityLockService
  ) {
    super('gl_detail_view', restangular, entityLock);
  }

  flat(query?: Query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = transformQuery(concreteQuery);
    transformedQuery = this.removeSpecialParams(transformedQuery);

    return this.service()
      .all(this.name)
      .all('flat')
      .customPOST(this.toFilter(transformedQuery));
  }

  removeSpecialParams(query) {
    const ignored = ['$gpx'];
    if(!query.where) query.where = {};
    let where = {};
    Object.keys(query.where)
      .forEach((key) => {
        const param = query.where[key];
        if (typeof param !== 'object' || param === null) {
          where[key] = param;
        } else {
          const reduced = {};
          Object.keys(param).forEach((k) => {
            if (ignored.indexOf(k) === -1) {
              reduced[k] = param[k]
            }
          })
          where[key] = reduced;
        }
      });

    return Object.assign({}, query, {where});
  }

  grouping(query?: Query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = transformQuery(concreteQuery);
    transformedQuery = this.removeSpecialParams(transformedQuery);


    return this.service()
      .all(this.name)
      .all('grouping')
      .customGET(null, this.toFilter(transformedQuery));
  }

  getString(query?: Query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .all(this.name)
      .all('string')
      .customGET(null, this.toFilter(transformedQuery));
  }
}
