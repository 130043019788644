import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SvcOrderHeader } from "app/contract/core/svc-order-header";
import {
  IMessagesResourceService,
  ResourcesService
} from "app/core/resources/resources.service";
import { DialogService } from "app/shared/dialog/dialog.service";
import { Subject } from "rxjs";
import { AlertService } from "../../../shared/alert/alert.service";
import { SvcOrderService } from "../../core/svc-order.service";
import { SvcOrderHeaderManageDialogComponent } from "../../shared/svc-order-header-manage-dialog/svc-order-header-manage-dialog.component";

@Component({
  selector: "app-svc-order-details-summary",
  templateUrl: "./svc-order-details-summary.component.html",
  styleUrls: ["./svc-order-details-summary.component.css"],
})
export class SvcOrderDetailsSummaryComponent implements OnInit {
  readonly ORDER_MESSAGES_MODULE: string = "svc-order";
  messagesOrder: IMessagesResourceService;
  destroy$: Subject<boolean> = new Subject<boolean>();
  svcOrderHeaderId: any;
  svcOrderHeader: any;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public svcOrderService: SvcOrderService,
    public dialogService: DialogService,
    public alertService: AlertService
  ) {
    this.messagesOrder = ResourcesService.messages(this.ORDER_MESSAGES_MODULE);
  }

  ngOnInit(): void {
    this.svcOrderHeaderId = this.getSvcOrder();
    this.svcOrderHeader = this.loadData(this.svcOrderHeaderId);
  }

  private getSvcOrder() {
    return this.route.parent.snapshot.data.svc_order.id;
  }

  public async loadData(id: number) {
    this.svcOrderHeader = await this.loadSvcOrder(id).toPromise();
  }

  loadSvcOrder(svcId) {
    return this.svcOrderService.findById(svcId);
  }

  updateSvcOrder() {
    this.dialogService
      .open(
        SvcOrderHeaderManageDialogComponent,
        {
          svcOrderHeader: new SvcOrderHeader({
            id: this.svcOrderHeader.id,
            name: this.svcOrderHeader.name,
            description: this.svcOrderHeader.description,
            vendor_id: this.svcOrderHeader.vendor_id,
            vendor_so_number: this.svcOrderHeader.vendor_so_number,
            internal_so_number: this.svcOrderHeader.internal_so_number,
            so_date: this.svcOrderHeader.so_date,
            disableVendorPicker: true,
          }),
        },
        { width: "500px" }
      )
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.loadData(this.svcOrderHeaderId);
            this.alertService.error(
              "",
              this.messagesOrder.get("UPDATE_SUCCESS")
            );
          }
        },
        (err) => {
          this.alertService.error("", this.messagesOrder.get("UPDATE_ERROR"));
        }
      );
  }

  back() {
    if (
      document.referrer.indexOf(window.location.host) >= 0 ||
      document.referrer === ""
    ) {
      history.back();
    } else {
      this.router.navigate(["/contract"]);
    }
  }
}
