export const invoiceSubaccountFilterContext = {
    filters: [
      {
        field: 'acct_level_2',
        label: 'Sub Account',
        type: 'string',
        index: 1
      },
      {
        field: 'prev_bill_amt',
        label: 'Prev Bill Amount',
        type: 'negativeNumber',
        index: 2
      },
      {
        field: 'pmts_rcvd',
        label: 'Payments Received',
        type: 'negativeNumber',
        index: 3
      },
      {
        field: 'bal_fwd_adj',
        label: 'Balance Forward Adj',
        type: 'negativeNumber',
        index: 4
      },
      {
        field: 'bal_fwd',
        label: 'Balance Forward',
        type: 'negativeNumber',
        index: 5
      },
      {
        field: 'tot_mrc_chgs',
        label: 'Total MRCs',
        type: 'negativeNumber',
        index: 6
      },
      {
        field: 'tot_occ_chgs',
        label: 'Total OCCs',
        type: 'negativeNumber',
        index: 7
      },
      {
        field: 'tot_usage_chgs',
        label: 'Total Usage',
        type: 'negativeNumber',
        index: 8
      },
      {
        field: 'tot_taxsur',
        label: 'Total Taxes / Surcharges',
        type: 'negativeNumber',
        index: 9
      },
      {
        field: 'tot_disc_amt',
        label: 'Total Discounts',
        type: 'negativeNumber',
        index: 10
      },
      {
        field: 'tot_new_chg_adj',
        label: 'New Charge Adj',
        type: 'negativeNumber',
        index: 11
      },
      {
        field: 'tot_new_chgs',
        label: 'Total New Charges',
        type: 'negativeNumber',
        index: 12
      },
      {
        field: 'tot_amt_due_adj',
        label: 'Total Amount Due Adj',
        type: 'negativeNumber',
        index: 13
      },
      {
        field: 'tot_amt_due',
        label: 'Total Amount Due',
        type: 'negativeNumber',
        index: 14
      },
    ]
  };
