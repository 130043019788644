import {Injectable} from '@angular/core';
import {GridService} from "../../shared/grid/grid.service";
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class ProjectGridService extends GridService {

  defaultColumns = [{
    caption: "Project ID",
    dataField: "project_id",
    width: 150
  }, {
    caption: "Project Name",
    dataField: "name",
    width: 150
  }, {
    caption: "Status",
    dataField: "status.value",
    width: 100
  }, {
    caption: "Start date",
    dataField: "start_date",
    cellTemplate: 'dateTemplate',
    dataType: 'date',
    alignment: 'left',
    width: 100
  }, {
    caption: "End date",
    dataField: "end_date",
    cellTemplate: 'dateTemplate',
    dataType: 'date',
    alignment: 'left',
    width: 100
  }, {
    caption: "Description",
    dataField: "description",
    width: 100
  }];

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.order.project-grid", userSettings);
  }

  /*columns() {

    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
