import {UpdateQueryOptions} from "../../../../common/state/query.actions";

export class UpdateInvoiceSubAccountsQuery {
  static readonly type = '[INVOICE_SUB_ACCOUNTS] Update Query';
  constructor(public options?: UpdateQueryOptions) {
  }
}

export class UpdateInvoiceSubAccountsSiderSection {
  static readonly type = '[INVOICE_SUB_ACCOUNTS] Update active sider section';
  constructor(public name: string) {}
}
