import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DialogService } from '../../../shared/dialog/dialog.service';
import { Vendor } from '../../core/vendor';
import { VendorService } from '../../core/vendor.service';

@Component({
  selector: 'ca-vendor-sneak-peek',
  templateUrl: './vendor-sneak-peek.component.html',
  styleUrls: ['./vendor-sneak-peek.component.css']
})
export class VendorSneakPeekComponent {
  @Input() vendor: Vendor;

  @Output() closeRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();

  constructor(
    public vendorService: VendorService,
    public dialogService: DialogService,
  ) { }

  ngOnInit() { }

  deleteVendor() {
    this.deleteRequested.emit(this.vendor);
  }

  closeSide() {
    this.closeRequested.emit();
  }

  getVendorInitials(): string {
    if (this.vendor && this.vendor.name.length > 1) {
      return [this.vendor.name[0], this.vendor.name[1]].join('').toUpperCase();
    }
  }
}
