import Query from "../../core/query/query";
export class ChargeQuery extends Query {

  most_recent: boolean;
  include_gl: boolean;
  flat_data: boolean;

  transformIt(vendorId: number, accountNo: string, fromDate?: Date, toDate?: Date, date?: Date) {

    vendorId ? this.set('invoice.vendor_id', vendorId) : this.remove('invoice.vendor_id');
    accountNo ? this.set('invoice.acct_level_1', accountNo) : this.remove('invoice.acct_level_1');

    if (fromDate && toDate) {
      this.where['invoice.inv_date'] = {'$between': [fromDate, toDate]}
    }
    else if (fromDate) {
      this.where['invoice.inv_date'] = {'$gte': fromDate}
    }
    else if (toDate) {
      this.where['invoice.inv_date'] = {'$lte': toDate}
    }
    else if(date) {
      this.where['invoice.inv_date'] = {'$eq': date }
    }
    else {
      delete this.where['invoice.inv_date'];
    }
  }
}
