<div class="ca-form ca-full-width">
  <mat-form-field fxFlex class="flex-item" hiDensityInput>
    <input
      type="number"
      [formControl]="from"
      matInput
      placeholder="From">
  </mat-form-field>
    <span fxFlex="10px"></span>
  <mat-form-field fxFlex class="flex-item" hiDensityInput>
    <input
      [formControl]="to"
      type="number"
      matInput
      placeholder="To"/>
  </mat-form-field>
</div>
