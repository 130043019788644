import {Injectable} from '@angular/core';
import {Permission} from "./permission";
import {Restangular} from "ngx-restangular";
import {BaseService} from '../../core/base.service';
import {ApiService} from "../../core/api";
import {zip} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {map} from "rxjs/operators";
import {flatMap} from "rxjs/internal/operators";

@Injectable()
export class PermissionService extends BaseService<Permission> {

  constructor(public restangular: Restangular,
              private apiService: ApiService) {
    super('permission', restangular);

  }

  cache() {
    return this.apiService.cache(this.name);
  }

  me() {
    const cache = this.cache();
    return this.apiService.get('permission/me', null, {cache});
  }

  app() {
    const cache = this.cache();
    return this.apiService.get('permission/app', null, {cache});
  }

  package() {
    const cache = this.cache();
    return this.apiService.get('package', null, {cache});
  }

  isUnrestricted(permissions) {
    return permissions[0] === "*";
  }

  loadPermissions() {
    return zip(
      this.me(),
      this.package()
    )
  }

  isAllowed(resource, action) {
    return this.loadPermissions().pipe(
      map((result) => {
        const permissions = result[0];
        const {resources} = result[1];
        const resourcesFromPackage = resources.find(res => res.key === resource);
        const isAllowed = resourcesFromPackage && Object.keys(resourcesFromPackage).includes("allowed") ? resourcesFromPackage.allowed : true;
        const isVisible = resourcesFromPackage && Object.keys(resourcesFromPackage).includes("visible") ? resourcesFromPackage.visible : true;
        if (resourcesFromPackage && (!isVisible || !isAllowed)) {
          return false;
        }
        return this.canPerformAction({key: resource}, action, permissions)
      }));
  }

  isAllowedTo(permissions, resources, resource, action) {
    const resourcesFromPackage = resources.find(res => res.key === resource);
    const isAllowed = resourcesFromPackage && Object.keys(resourcesFromPackage).includes("allowed") ? resourcesFromPackage.allowed : true;
    const isVisible = resourcesFromPackage && Object.keys(resourcesFromPackage).includes("visible") ? resourcesFromPackage.visible : true;
    if (resourcesFromPackage && (!isVisible || !isAllowed)) {
      return false;
    }
    return this.canPerformAction({key: resource}, action, permissions)
  }

  isRouteAllowed(route: ActivatedRoute, action) {
    return route.data
      .pipe(flatMap((result = {}) => {
        const {key} = result;
        return this.isAllowed(key, action)
      }));
  }

  isAdmin() {
    return this.me().pipe(map((permissions) => {
      if (this.isUnrestricted(permissions[0])) {
        return true;
      } else {
        return false;
      }
    }));
  }

  canPerformAction(resource, action, permissions) {

    if (this.isUnrestricted(permissions)) {
      return true;
    }

    let permissionForResources = this.findPermissionForResource(resource, permissions);
    const any = permissionForResources.filter((perm) => {
      return perm.action.toLowerCase() === action.toLowerCase();
    });

    return !!any.length;
  }

  reportPermission() {    
    return this.loadPermissions().pipe(
      map((result) => {
        const permissions = result[0];
        const {resources} = result[1];

        // is allowed
        const resourcesFromPackage = resources.find(res => res.key === 'reports');
        const all = resourcesFromPackage && Object.keys(resourcesFromPackage).includes("allowed") ? resourcesFromPackage.allowed : true;
        const vis = resourcesFromPackage && Object.keys(resourcesFromPackage).includes("visible") ? resourcesFromPackage.visible : true;
        if (resourcesFromPackage && (!vis || !all)) {
          return false;
        }
        // have permission
        const filteredAdmin = permissions[0] === '*' && permissions.length > 1
                ? permissions.slice(1, permissions.length+1)
                : permissions[0] === '*' 
                ? []
                : permissions;
        let permissionForResources = filteredAdmin.filter((permission) => {
          return permission.resource_key === 'reports';
        });
        const reportPerm = permissionForResources.some(perm => perm.action.toLowerCase() === 'view');

        return reportPerm;
      })
    )
  }

  public findPermissionForResource(resource, permissions) {
    return permissions.filter((permission) => {
      return permission.resource_key === resource.key;
    });
  }

  public create(item) {
    const {role_id, permissions} = item;
    return this.service().one(this.name).customPOST({role_id, permissions});
  }

  public update(id: number, permission: Permission) {
    return this.service().one(this.name, id).customPUT(permission);
  }

  delete(id: number) {
    return this.service().one(this.name, id).remove();
  }

  bulkDelete(ids: number[]) {
    return this.service().several(`${this.name}/bulk`, ids).remove();
  }
}
