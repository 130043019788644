import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
// import { OnChanges, SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import Query from 'app/core/query/query';

import { DemarcService } from '../../../demarc/core/demarc.service';
import { Site } from '../../core/site';




@Component({
  selector: 'ca-site-sneak-peek',
  templateUrl: './site-sneak-peek.component.html',
})
export class SiteSneakPeekComponent implements OnChanges {
  @Input() site: Site;

  @Output() closeRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();

  public allSiteDemarcs: Array<any> = [];

  constructor( public demarcService: DemarcService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['site'] && changes['site'].currentValue) {
      this.loadDemarcs(this.site.id);
    }
  }

  loadDemarcs(siteId) {
    this.demarcService.findAll(new Query({where: { site_id: siteId}}))
    .subscribe((results) => {
      this.allSiteDemarcs = results.items;
    });
  }

  deleteSite() {
    this.deleteRequested.emit(this.site);
  }

  closeSide() {
    this.closeRequested.emit();
  }
}
