import { PageInfo } from './page-info';
import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { BaseService } from '../../../core/base.service';

@Injectable()
export class PageInfoService extends BaseService<PageInfo> {

  constructor(public restangular: Restangular) {
    super('user-report', restangular);
  }

  sendReport(data: PageInfo) {
    return this.service().one(this.name).all('sendReport').post(data);
  }

}
