<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Contract</h1>
</div>
<div mat-dialog-content class="ca-dialog-content" *ngIf="form">
  <form class="ca-form" [formGroup]="form" fxLayout="column">
    <div class="flex-container" fxLayout.sm="column">
      <div class="flex-item caGap " fxFlex>
        <ca-vendor-picker
          caInput
          formControlName="vendor_id"
          [pickerForm]="form"
          placeholder="Vendor"
          [required]="true"
          [initialItem]=""
          [clearEnabled]="true"
          [customMethod]="'findOnlyVendors'"
          [disabled]= "this.contract?.disableVendorPicker">
        </ca-vendor-picker>
        <mat-hint class="ca-select-hint"
                  *ngIf="form.hasError('required', ['vendor_id']) && form.controls.vendor_id.touched">
          {{VALIDATION.REQUIRED_ERROR}}
        </mat-hint>
      </div>
    </div>
    <div class="flex-container" fxLayout.sm="column">
      <div class="flex-item caGap" fxFlex>
        <mat-form-field caInput>
          <input required matInput placeholder="Name" formControlName="name" type="text">
          <mat-hint *ngIf="form.hasError('required', ['name']) && form.controls['name'].touched" class="error-class">
            {{VALIDATION.REQUIRED_ERROR}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="flex-container" fxLayout.sm="column">
      <div class="flex-item caGap" fxFlex="" caInput>
        <mat-form-field>
          <input required matInput placeholder="Description" formControlName="description" type="text">
          <mat-hint *ngIf="form.hasError('required', ['description']) && form.controls['description'].touched" class="error-class">
            {{VALIDATION.REQUIRED_ERROR}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="flex-container" fxLayout.sm="column">
      <div class="flex-item caGap" fxFlex="" caInput>
        <mat-form-field>
          <input matInput placeholder="Company Name" formControlName="company_name" type="text">
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<mat-dialog-actions class="ca-align-baseline ca-dialog-action">
  <div class="example-fill-remaining-space">
    <!--<ca-confirm-delete-button #genericButton *ngIf="isUpdate"-->
                              <!--[requestInput]="true"-->
                              <!--[placeHolder]="'Contract name'"-->
                              <!--[entityName]="contract?.name"-->
                              <!--(deleteRequested)="deleteContract($event)"></ca-confirm-delete-button>-->
  </div>

  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>

  <button mat-button #saveButton type="submit"
          (click)="onSubmit(form)"
          [disabled]="form?.invalid">
    Save
  </button>
</mat-dialog-actions>
