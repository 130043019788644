import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { DialogService } from '../../../shared/dialog/dialog.service';
import * as moment from 'moment';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-vendor-ack-date',
  templateUrl: './order-vendor-ack-date.component.html',
})
export class OrderVendorAckDateComponent extends PageManageDialogComponent implements OnInit {

  public vendor_ack_date: Date;
  public order ;
  public minAllowedDate ;

  constructor(
    public formBuilder: FormBuilder,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<OrderVendorAckDateComponent>) {
      super(dialogService, dialogRef);
  }

  ngOnInit() {
    this.getMinDate()
    this.form = this.formBuilder.group({
      vendor_ack_date: [this.vendor_ack_date ? new Date(this.vendor_ack_date) : null, Validators.required]
    });
  }

  approve({value, valid}: {value: any, valid: boolean}) {
    this.dialogRef.close({
      approved: true,
      vendor_ack_date: value.vendor_ack_date
    });
  }

  reject() {
    this.dialogRef.close({
      rejected: true
    });
  }
  getMinDate(){
    this.minAllowedDate = moment(this.order.send_date).format('YYYY-MM-DD');
  }
}
