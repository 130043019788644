import {UpdateQueryOptions} from "../../../../common/state/query.actions";

export class UpdateInvoiceChargesQuery {
  static readonly type = '[INVOICE_CHARGES] Update Query';

  constructor(public options?: UpdateQueryOptions) {
  }
}

export class UpdateActiveSiderSection {
  static readonly type = '[INVOICE_CHARGES] Update active sider section';
  constructor(public name: string) {}
}
