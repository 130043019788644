<div class="mat-dialog-top-spacer"></div>
<div class="ca-dialog-title" style="padding-bottom: 6px;">
  <h1 mat-dialog-title>Service Test</h1>
</div>
<div mat-dialog-content class="ca-dialog-content">

  <form class="ca-form dialog-content-no-margin" [formGroup]="form">
      <div class="flex-container ca-card custom-mat-card-container" fxLayout="row" fxLayoutAlign="center flex-start">
        <mat-card fxFlex>
          <mat-card-content>
            <div fxLayout="column">
              <div fxLayout="row" fxFlex="45%">
                <ca-date-picker class="date-picker" caInput fxItem placeholder="Vendor test date" [minDate]="minAllowedDate" formControlName="test_date"></ca-date-picker>
              </div>
              <mat-form-field fxItem caInput>
                <textarea formControlName="note" name="note" rows="1" matInput placeholder="Note"></textarea>
                <mat-hint *ngIf="form.hasError('minlength', ['note'])">Minimum 20 characters
                </mat-hint>
              </mat-form-field>
            </div>
          </mat-card-content>
          <mat-card-footer class="action-container">
              <div fxLayout="row" class="test-action flex-container ca-dialog-action">
                <span class="example-fill-remaining-space"></span>
                <button mat-button class="flat-button" type="button"
                    [disabled]="isFailDisabled('test_date', 'note')"
                    (click)="reject(form, TEST_TYPE.VENDOR)">
                  Fail
                </button>
                <button mat-button class="flat-button" type="button" (click)="approve(form, TEST_TYPE.VENDOR)"
                    [disabled]="isPassDisabled('test_date')">
                  Pass
                </button>
              </div>
            </mat-card-footer>
        </mat-card>

        <mat-card fxFlex>
            <mat-card-content>
              <div fxLayout="column">
                <div fxLayout="row" fxFlex="45%">
                  <ca-date-picker class="date-picker" caInput placeholder="Customer test date" [minDate]="minAllowedDate" formControlName="customer_test_date"></ca-date-picker>
                </div>
                <mat-form-field fxItem caInput>
                  <textarea formControlName="note_customer" name="note_customer" rows="1" matInput placeholder="Note"></textarea>
                  <mat-hint *ngIf="form.hasError('minlength', ['note_customer'])">Minimum 20 characters
                  </mat-hint>
                </mat-form-field>
              </div>
            </mat-card-content>
            <mat-card-footer class="action-container">
              <div fxLayout="row" class="test-action flex-container ca-dialog-action">
                <span class="example-fill-remaining-space"></span>
                <button mat-button class="flat-button" type="button"
                    [disabled]="isFailDisabled('customer_test_date', 'note_customer')"
                    (click)="reject(form, TEST_TYPE.CUSTOMER)">
                  Fail
                </button>
                <button mat-button class="flat-button" type="button" (click)="approve(form, TEST_TYPE.CUSTOMER)"
                    [disabled]="isPassDisabled('customer_test_date')">
                  Pass
                </button>
              </div>
            </mat-card-footer>
          </mat-card>
      </div>

        <div fxLayout *ngIf="service_test_date_history?.length>0">
          <!--h3 class="flex-container" fxLayout="row">History</h3-->

          <dx-data-grid fxFlex [columns]="columns" class="foc-date-grid" [dataSource]="service_test_date_history">
            <div *dxTemplate="let d of 'changedDateCellTemplate'">
              <div caGridTooltip>{{d.value | date:'MM/DD/YYYY hh:mm:ss a'}}</div>
            </div>
            <div *dxTemplate="let d of 'dateCellTemplate'">
              <div caGridTooltip>{{d.value | date}}</div>
            </div>
            <div *dxTemplate="let d of 'statusCellTemplate'">
              <div caGridTooltip>{{d.value ? 'Pass' : 'Fail'}}</div>
            </div>
          </dx-data-grid>
        </div>


  </form>
</div>
<mat-dialog-actions class="ca-align-baseline ca-dialog-action">
    <mat-hint class="error-class-mrc-nrc" *ngIf="!hasMrcNrc">{{NO_MRC_NRC_MSG}}</mat-hint>
    <span class="example-fill-remaining-space"></span>
    <button mat-button type="submit" (click)="cancel()">
        Cancel
    </button>
  </mat-dialog-actions>
