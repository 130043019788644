<div class="mat-dialog-top-spacer"></div>

<div mat-dialog-content class="ca-dialog-content">  
  {{bodyText}}?
</div>

<mat-dialog-actions class="ca-dialog-action padding-top-25">
  <span class="example-fill-remaining-space"></span>
  <button mat-button (click)="cancel()" tabindex="-1" class="margin-right-5 mat-no-focus">Cancel</button>
  <button mat-button autofocus (click)="confirm()">Discard</button>
</mat-dialog-actions>
