
import {map, takeUntil} from 'rxjs/operators';
import { combineLatest, forkJoin } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import {SubAccountCodingGridService} from '../core/sub-account-coding-grid.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {PageListComponent} from '../../core/page-list.component';
import {AlertService} from '../../shared/alert/alert.service';
import {AccountService} from '../../account/core/account.service';
import Query from '../../core/query/query';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {PageContext} from '../../core/page.context';
import {SortingBuilder, SortingService} from '../../shared/sorting/sorting.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {IMessagesResourceService, ResourcesService} from '../../core/resources/resources.service';
import {SystemRuleStore} from '../core/system-rule.store';
import {SystemRuleSelectors} from '../core/system-rule.selectors';
import {GLSystemRuleService} from '../core/gl-system-rule.service';
import {PermissionService} from '../../permissions/core/permission.service';
import { IActions } from 'app/gl-system-rules/shared/actions.interface';

@Component({
  selector: 'app-sub-account-coding',
  templateUrl: './sub-account-coding.component.html',
  styleUrls: ['./sub-account-coding.component.css']
})
export class SubAccountCodingComponent extends PageListComponent implements AfterViewInit, OnDestroy, OnChanges, OnInit {
  public query: Query = new Query({
    where: {
      id: {$ne: new Date().getMilliseconds() * -1}
    }
  });

  public subAccounts: Array<any> = [];
  public selection: any;
  public selectedVendor: any;
  public selectedBAN: any;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'gl';
  banDisabled$: any;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public selectedCodings: Array<any>;
  public originalCodings: Array<any>;
  @Input() triggerReload = false;
  @Output() public onSelection = new EventEmitter();
  @Output() public onActionsLoaded = new EventEmitter();
  @Output() public onClearSelection = new EventEmitter();

  public subAccountSorting: SortingBuilder;
  form: FormGroup;
  form$: any;

  permissionModify = false;

  actionSchema: IActions = {
    add: false,
    edit: false
  };

  constructor(
    public glSystemRuleService: GLSystemRuleService,
    public dialog: DialogService,
    public router: Router,
    public alert: AlertService,
    public subAccountCodingGridService: SubAccountCodingGridService,
    public accountService: AccountService,
    public settingsService: UserSettingsService,
    public sortingService: SortingService,
    public loaderService: LoaderService,
    public formBuilder: FormBuilder,
    private systemRuleStore: SystemRuleStore,
    private permissionService: PermissionService,
    private systemRuleSelectors: SystemRuleSelectors) {
    super(new PageContext({
      name: 'app.gl.gl-ban-coding',
      settings: settingsService
    }));
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.subAccountSorting = this.sortingService.builder();
  }

  saveSubAccountConfig(params) {
    this.selectedCodings = params;

    let createCodings = this.selectedCodings.filter((coding) => {
      coding.ban = this.selectedBAN;
      coding.sub_account = this.selection.sub_account;
      coding.vendor_id = this.selectedVendor;
      return coding.id == null && coding.gl_apportion_pct > 0;
    });

    let updateCodings = this.selectedCodings.filter((coding) => {
      return coding.id != null && coding.gl_apportion_pct > 0;
    });

    let selectedIds = updateCodings.map((coding) => {
      return coding.id;
    });

    let deleteCodings = this.originalCodings.filter((coding) => {
      return selectedIds.indexOf(coding) === -1;
    });

    this.glSystemRuleService.saveSubAccountCodings(this.selection, createCodings, updateCodings, deleteCodings)
      .then((result) => {
        this.selection = result;
        //this.alert.success('', this.messages.get('SUB_ACCOUNT_UPDATE_SUCCESS'));
        setTimeout(() => {
          this.loadSubAccountCodings();

          let obj = this.selection;
          obj.gl_coding_enabled = !this.selection.gl_coding_enabled;
          this.changeSubAccountStatus(obj)
        }, 500); // TODO: there is some problem because api returns old data even they are saved
      });
  }

  onSelectionChanged(row) {
    this.selection = row.selectedRowsData[0];
    if (this.selection) {
      this.loadSubAccountCodings();
    }
  }

  ngOnChanges(changes) {
    if (changes && changes.triggerReload && changes.triggerReload.currentValue) {
      this.saveSubAccountConfig(changes.triggerReload.currentValue);
    }
  }

  public loadSubAccountCodings() {
    let selection = this.selection;
    let selectedBan = this.selectedBAN;
    this.glSystemRuleService.loadSubAccountCodings(this.selectedBAN, selection.sub_account)
      .subscribe((result) => {
        this.selectedCodings = result.items;
        this.originalCodings = result.items.map((o) => {
          return o.id;
        });
        this.onSelection.emit({
          coding: selection,
          strings: this.selectedCodings,
          show: selection == null,
          ruleInfo: {
            meta: {
              ban: selectedBan,
              sub_account: selection.sub_account
            },
            type: 'subaccount'
          }
        });
      });
  }

  public onCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.subAccountSorting.apply(event, this.query);
      this.loadSubAccounts(this.query);
    }
  }

  loadSubAccounts(query: Query) {
    if (this.selectedVendor && this.selectedBAN) {
      this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

      this.glSystemRuleService.loadSubAccounts(this.selectedVendor, this.selectedBAN, query)
        .subscribe((result) => {
            this.subAccounts = result;
            this.selectFirstRow();
            this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
          },
          () => {
            this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
          });
    }
  }

  handleVendorChange() {
    this.systemRuleStore.resetSubAccountBan();
  }

  onClearVendor() {
    this.systemRuleStore.resetSubAccountBan();
    this.onClearSelection.emit();
  }

  onClearAccount() {
    this.onClearSelection.emit();
  }

  ngOnInit() {
    this.loadPermissions().subscribe(() => {
            })
    this.columns = this.subAccountCodingGridService.columns();
    this.form$ = this.systemRuleStore.select('subAccountForm');
    this.banDisabled$ = this.systemRuleSelectors.isSubAccountBanDisabled(this.systemRuleStore);
    combineLatest(
      [this.systemRuleSelectors.getSubAccountVendor(this.systemRuleStore),
      this.systemRuleSelectors.getSubAccountBan(this.systemRuleStore)]
    ).pipe(takeUntil(this.destroy$)).subscribe(([vendor, ban]) => {
      this.selectedVendor = vendor;
      this.selectedBAN = ban;
      if (ban != null && vendor != null) {
        this.loadSubAccounts(this.query);
      } else {
        this.subAccounts = [];
      }
    });
  }

  ngAfterViewInit(): void {
    this.subAccountCodingGridService.create(this.dataGrid.instance, {
      noDataText: this.subAccountCodingGridService.noDataMessage
    });
    super.ngAfterViewInit();
  }

  changeSubAccountStatus(obj) {
    if (!this.isUndefinedOrNull(obj.gl_coding_enabled)) {
      obj.ban = this.selectedBAN;
      obj.gl_coding_enabled = !obj.gl_coding_enabled;

      let changeSubAccount = this.permissionModify ? this.glSystemRuleService.changeSubAccountStatus(obj) : this.glSystemRuleService.codingChangeSubAccountStatus(obj);

      changeSubAccount
        .subscribe(() => {
          this.alert.success('', this.messages.get('SUB_ACCOUNT_GL_STATUS_CHANGE_SUCCESS'));
        });
    }
  }

  isUndefinedOrNull(val) {
    return val === undefined || val === null;
  }

  reset() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadPermissions() {
    const glModify$ = this.permissionService.isAllowed("gl", "MODIFY");
    const glCreate$ = this.permissionService.isAllowed("gl", "CREATE");

    return forkJoin([
      glModify$,
      glCreate$]
    ).pipe(map(([glModify, glCreate]) => {
      this.permissionModify = glModify;
      this.actionSchema = {
        add: glCreate,
        edit: glCreate && glModify,
      };
      this.onActionsLoaded.emit(this.actionSchema)
    }));
  }

}
