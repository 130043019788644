import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {UserService} from "../../user/core/user.service";

@Pipe({
  name: 'caNumber'
})
export class CaNumberPipe extends DecimalPipe implements PipeTransform {
  private __currencyDisplay: any = JSON.parse(localStorage.getItem('__currencyDisplay'));
  private __currDigit: any = JSON.parse(localStorage.getItem('__currDigit'));
  private __currLocale: any = JSON.parse(localStorage.getItem('__currLocale'));
  private __negativeValue: any = JSON.parse(localStorage.getItem('__negativeValue'));

  constructor(private userService: UserService) {
    super("en-US");
  }

  transform(value: any, digit?: string, locale?: string, useBigNumberFormats:boolean = false): any {
    let dig = this.__currDigit ? this.__currDigit : '1.2-2';
    let loc = this.__currLocale ? this.__currLocale : 'en-US';

    let bigNumSymbol = '';
    if(useBigNumberFormats){
      if (value <= -1000 || value >= 1000) {
        value = Math.floor((value / 1000));
        bigNumSymbol = 'K';
      }
      if (value <= -1000 || value >= 1000) {
        value = Math.floor((value / 1000));
        bigNumSymbol = 'M';
      }
      if (value <= -1000 || value >= 1000) {
        value = Math.floor((value / 1000));
        bigNumSymbol = 'B';
      }
    }


    let formattedByCurrencyPipe = super.transform(value, bigNumSymbol === '' ? dig:'1.0-0', loc);
    if (formattedByCurrencyPipe == null)
        return null;

    let retValue = formattedByCurrencyPipe.charAt(0) === '-' && this.__negativeValue === '()'?
      '(' + formattedByCurrencyPipe.substr(1) + ')' :
      formattedByCurrencyPipe;

      if(useBigNumberFormats){
        if(retValue.charAt(retValue.length-1)==')' && bigNumSymbol!=='') {
            retValue= [retValue.slice(0, retValue.length-1), bigNumSymbol, retValue.slice(retValue.length-1)].join('');
          } else retValue +=bigNumSymbol;
      }

      return retValue;
  }

}
