<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{formTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">
    <form class="ca-form" [formGroup]="form">

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column" fxLayoutGap="24px">
      <div class="flex-item" fxFlex>
        <mat-form-field caInput>
          <input
            matInput
            placeholder="Vendor Location ID"
            formControlName="vendor_location_id"
            required
            type="text"
            [disabled]="viewOnly">
          <mat-hint *ngIf="form.hasError('required', ['vendor_location_id']) && form.controls.vendor_location_id.touched" class="error-class">{{VALIDATION.REQUIRED_ERROR}}</mat-hint>
        </mat-form-field>
      </div>
      <div class="flex-item" fxFlex>
        <ca-vendor-picker caInput
          [disabled]="viewOnly"
          formControlName="vendor_id"
          [pickerForm]="form"
          placeholder="Vendor"
          [initialItem]="vendorRemitAddress?.vendor"
          [clearEnabled]="true"
          [required]="true"
          [customMethod]="'findOnlyVendors'">
        </ca-vendor-picker>
        <mat-hint class="ca-select-hint"
                  *ngIf="form.hasError('required', ['vendor_id']) && form.controls.vendor_id.touched">{{VALIDATION.REQUIRED_ERROR}}</mat-hint>
      </div>
    </div>

    <ca-address-selection [disabled]="viewOnly" [required]="!formModeEnabled" [address]="vendorRemitAddress?.address" (addressChanged)="onAddressChanged($event)" (isFormMode)="onFormModeChanged($event)"></ca-address-selection>
  </form>

</div>
<mat-dialog-actions class="ca-dialog-action" *ngIf="!viewOnly">
  <div class="example-fill-remaining-space">
    <ca-confirm-delete-button #genericButton *ngIf="isUpdate && deletePermission" [confirmText]="'Delete?'"
      (deleteRequested)="deleteVendorRemitAddress($event)"></ca-confirm-delete-button>
  </div>
  <button #cancelButton mat-button type="submit" (click)="cancel()">
      Cancel
  </button>
  <button #saveButton mat-button type="submit"
          [disabled]="form?.invalid || !address"
          (click)="onSubmit(form)">Save</button>
</mat-dialog-actions>

<mat-dialog-actions class="ca-dialog-action" *ngIf="viewOnly">
  <div class="example-fill-remaining-space"></div>

  <button mat-button type="submit" (click)="closeDialog()">
    Cancel
  </button>

  <button  *permission="{'action':'Modify', 'key':'vendor-remit-address'}" mat-button type="submit" (click)="editForm()">
    Edit
  </button>
</mat-dialog-actions>

