<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-details-title">{{quote?.quote_id}}</span>

  <span class="example-fill-remaining-space">&nbsp;</span>

  <button *ngIf="quoteServices.length > 0"
          matTooltip="Add Service"
          mat-icon-button
          [matMenuTriggerFor]="newQuoteMenu"
          [(disabled)]="isQuoteClosed">
    <ca-icon [key]="'QUOTE_ADD_SERVICE'"></ca-icon>
  </button>
  <button *ngIf="quoteServices.length === 0"
          matTooltip="Add Service"
          [matMenuTriggerFor]="newQuoteMenu"
          mat-icon-button>
    <ca-icon [key]="'QUOTE_ADD_SERVICE'"></ca-icon>
  </button>

  <mat-menu #newQuoteMenu="matMenu">
      <button *ngFor="let type of serviceTypes"
              mat-menu-item type="button"
              (click)="addNewQuoteService(type.id, type.value)">
        {{type?.value}}
      </button>
    </mat-menu>

  <button mat-icon-button
          matTooltip="Refresh List"
          (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <button mat-icon-button
          matTooltip="Report"
          [matMenuTriggerFor]="srmReportMenu">
    <ca-icon [key]="'QUOTE_SRM_REPORT'"></ca-icon>
  </button>
  <mat-menu #srmReportMenu="matMenu">
    <ng-container *ngIf="!selectedQuoteService">
      <button mat-menu-item type="button" (click)="srmReport(SRM_REPORTS.QUOTE_SUMMARY, SRM_REPORT_VIEW_TYPE.VIEW)">
        Quote Summary Report
      </button>
      <button mat-menu-item type="button" (click)="srmReport(SRM_REPORTS.QUOTE_SUMMARY, SRM_REPORT_VIEW_TYPE.CSV)">
        Quote Summary CSV
      </button>

      <button mat-menu-item type="button"
              (click)="srmReport(SRM_REPORTS.QUOTE_DETAILED_SUMMARY, SRM_REPORT_VIEW_TYPE.VIEW)">
        Quote Detailed Report
      </button>
      <button mat-menu-item type="button"
              (click)="srmReport(SRM_REPORTS.QUOTE_DETAILED_SUMMARY, SRM_REPORT_VIEW_TYPE.CSV)">
        Quote Detailed CSV
      </button>
    </ng-container>
    <ng-container *ngIf="selectedQuoteService">
      <button mat-menu-item type="button"
              (click)="srmReport(SRM_REPORTS.QUOTE_SERVICE_SUMMARY, SRM_REPORT_VIEW_TYPE.VIEW)">
        Service Summary Report
      </button>
      <button mat-menu-item type="button"
              (click)="srmReport(SRM_REPORTS.QUOTE_SERVICE_SUMMARY, SRM_REPORT_VIEW_TYPE.CSV)">
        Service Summary CSV
      </button>
    </ng-container>
  </mat-menu>


  <button *ngIf="tabGroup.isActive(OVERVIEW_TAB_KEY)" mat-icon-button (click)="bulkEmail()"
          [disabled]="!selectedOverviewRows.length">
    <ca-icon [key]="'EMAIL'" [tooltip]="'Email'"></ca-icon>
  </button>


  <div class="vertical-divider" *ngIf="selectedQuoteService"></div>

  <button *ngIf="selectedQuoteService" mat-icon-button (click)="editQuoteService()"
          [disabled]="isClosed">
    <ca-icon [key]="'QUOTE_ADD'" [tooltip]="'Add Quote'"></ca-icon>
  </button>
  <button *ngIf="selectedQuoteService" mat-icon-button (click)="updateRequest()"
          [disabled]="!selectedRequest || isClosed || ( selectedRequest.status === QUOTE_SERVICE_OFFER_STATUS.FAILED )">
    <ca-icon [key]="'QUOTE_UPDATE'" [tooltip]="'Update Quote'"></ca-icon>
  </button>
  <button *ngIf="selectedQuoteService" mat-icon-button (click)="addManual()"
          [disabled]="!selectedRequest || isClosed || selectedRequest.quote_type_id === QUOTE_SERVICE_OFFER_METHOD.MANUAL">
    <ca-icon [key]="'QUOTE_ADD_MANUAL'" [tooltip]="'Add Manual'"></ca-icon>
  </button>
  <div class="vertical-divider" *ngIf="selectedQuoteService"></div>
  <button *ngIf="selectedQuoteService" mat-icon-button (click)="confirmRequest()"
          [disabled]="isClosed  || !enableSelectQuote">
    <ca-icon [key]="'QUOTE_SELECT'" [tooltip]="'Select Quote'"></ca-icon>
  </button>
  <button *ngIf="selectedQuoteService" mat-icon-button (click)="closeRequest()"
          [disabled]="isClosed">
    <ca-icon [key]="'QUOTE_CLOSE'" [tooltip]="'Close Quote'"></ca-icon>
  </button>
  <button matTooltip="Analyze" mat-icon-button *ngIf="!!(selectedQuoteService && selectedQuoteService.id)"
          (click)="openAnalyzeReport()">
    <ca-icon [key]="'QUOTE_CHART'"></ca-icon>
  </button>

  <div class="vertical-divider"></div>

  <app-document-manager-commands
    *ngIf="isDocumentCommandsVisible()"

    [deleteDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DELETE_DOCUMENT_ACTION"
    [deleteDocumentDisablePredicate]="isDeleteDisabled(selection)"

    [uploadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.UPLOAD_DOCUMENT_ACTION"
    [uploadDocumentDisablePredicate]="false"

    [downloadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DOWNLOAD_DOCUMENT_ACTION"
    [downloadDocumentDisablePredicate]="isDeleteDisabled(selection)"
  >
  </app-document-manager-commands>

  <div *ngIf="isDocumentCommandsVisible()" class="vertical-divider"></div>

  <button *ngIf="selectedQuoteService" mat-icon-button matTooltip="Download CSV" (click)="csvServiceRequests()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button *ngIf="!selectedQuoteService" mat-icon-button matTooltip="Download CSV" (click)="csvSummary()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button [disabled]="tabGroup.isActive(3)" mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [ngClass]="{'on' : sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.NOTES_SECTION_NAME)"
          matTooltip="Notes" mat-icon-button>
    <ca-icon [key]="notesCount > 0 ? 'NOTES' : 'NOTES_EMPTY'"></ca-icon>
  </button>
  <button [ngClass]="{'on' : sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.HISTORY_SECTION_NAME)"
          matTooltip="History" mat-icon-button>
    <ca-icon [key]="'HISTORY'"></ca-icon>
  </button>
</mat-toolbar>


<mat-sidenav-container class="app-content tabbed-content default-card">

  <div fxLayout="column" style="height: 100%;">
      <div class="tabbed-navigation-container" [ngClass]="{'with-more': tabGroup.tabs.length >= 7}">
          <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="activeTabIndex">
            <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs"></mat-tab>
          </mat-tab-group>
        <div class="quote-tabs-show-more">
          <button *ngIf="tabGroup.tabs.length >= 7" matTooltip="Show all" mat-icon-button
                  [matMenuTriggerFor]="menu">
            <ca-icon [key]="'MORE_VERTICAL'"></ca-icon>
          </button>
        </div>

          <mat-menu #menu="matMenu">
            <div style="max-height: 500px; overflow-y: auto">
              <button mat-menu-item *ngFor="let tab of tabGroup.tabs; let i = index" (click)="onTabChange(i)">
                <span>{{tab.title}}</span>
              </button>
            </div>
          </mat-menu>
        </div>

        <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(0)}" style="padding:12px; height: 100%"
        fxLayout="column">
          <div class="flex-container" fxLayout="column" fxFlexFill>
            <div fxLayout="row" fxLayout.sm="column" fxLayoutGap="15px">
              <mat-card fxFlex class="flex-item details-side" caCard>
                <mat-card-header>
                  Quote Info
                </mat-card-header>
                <mat-card-content class="inner-content">
                  <div fxLayout="row" class="entry-list">
                    <div fxFlex class="entry-list-item">
                      <label>Customer</label>
                      <p>
                        {{ (quote?.customer?.company) || 'N/A'}}
                      </p>
                    </div>
                    <div fxFlex class="entry-list-item">
                      <label>Requestor</label>
                      <p>
                        {{ (quote?.requester?.full_name) || 'N/A'}}
                      </p>
                    </div>
                    <div fxFlex class="entry-list-item">
                      <label>Status</label>
                      <p>
                        {{ (quote?.quote_status?.value) || 'N/A'}}
                      </p>
                    </div>
                  </div>
                  <div fxLayout="row" class="entry-list">

                    <div fxFlex class="entry-list-item">
                      <label>Request Date</label>
                      <p>
                        {{ (quote?.request_date | date) || 'N/A'}}
                      </p>
                    </div>
                    <div fxFlex class="entry-list-item">
                      <label>Close Date</label>
                      <p>
                        {{ (quote?.close_date | date) || 'N/A'}}
                      </p>
                    </div>
                    <div fxFlex class="entry-list-item">
                      <label>Description</label>
                      <p>
                        {{ (quote?.quote_description) || 'N/A'}}
                      </p>
                    </div>
                  </div>

                </mat-card-content>
              </mat-card>
              <mat-card fxFlex class="flex-item details-main" fxFlex.sm="70%" caCard>
                <mat-card-header>
                  Sales Info
                </mat-card-header>
                <mat-card-content class="inner-content">
                  <div fxLayout="row" class="entry-list">

                    <div fxFlex class="entry-list-item">
                      <label>Opportunity Type</label>
                      <p>
                        {{ (quote?.opportunity_type?.value) || 'N/A'}}
                      </p>
                    </div>
                    <div fxFlex class="entry-list-item">
                      <label>CRM Opportunity</label>
                      <p>
                        {{ (quote?.crm_opportunity) || 'N/A'}}
                      </p>
                    </div>

                  </div>
                  <div fxLayout="row" class="entry-list">

                    <div fxFlex class="entry-list-item">
                      <label>Sales Representative</label>
                      <p>
                        {{ (quote?.sales_representative?.full_name) || 'N/A'}}
                      </p>
                    </div>
                    <div fxFlex class="entry-list-item">
                      <label>Sales Engineer</label>
                      <p>
                        {{ (quote?.sales_engineer?.full_name) || 'N/A'}}
                      </p>
                    </div>

                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="app-content-list app-content-list-no-pager dx-nodata-absolute" caCard style="flex: 1">
              <dx-data-grid
                #summaryGrid
                [columns]="summaryGridColumns"
                [dataSource]="quoteServices">
                <div *dxTemplate="let cell of 'emptyCellTemplate'">
                  <div>{{cell.text || 'Not selected'}}</div>
                </div>

                <div *dxTemplate="let d of 'addresstemplate'" style="text-align: left;" class="customer-inv-grid-site-template">
                  <div class="customer-inv-grid-sites-rows-wraper">
                      <div caGridTooltip class="customer-inv-grid-site-name">{{d.data.address?.address1}}</div>
                      <div caGridTooltip class="customer-inv-grid-site-address">{{d.data.address?.search_address || 'N/A'}}</div>
                  </div>
              </div>
              </dx-data-grid>
            </div>
          </div>
        </div>

    <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(OVERVIEW_TAB_KEY)}"
         fxFlex="calc(100% - 48px)"
         fxFlexFill
         fxLayout="column">
      <div fxFlex="1 0 calc(100% - 180px)" class="app-content-list app-content-list-no-pager dx-nodata-absolute">
        <dx-data-grid style="height: 100%;overflow-y: hidden" #overviewGrid
                      (onRowClick)="onRowClick($event)"
                      (onCellClick)="onCellClick($event)"
                      (onCellPrepared)="onOverviewGridCellPrepared($event)"
                      [(selectedRowKeys)]="selectedOverviewRows"
                      (onSelectionChanged)="onSelectionOverviewChanged($event)"
                      [columns]="overviewGridColumns"
                      class="grid-with-selection-badge-inner"
                      [dataSource]="overviewRequests">
          <div *dxTemplate="let cell of 'emptyCellTemplate'">
            <span>{{cell.text || 'Not Recd.'}}</span>
          </div>
          <div *dxTemplate="let d of 'addressAtemplate'" style="text-align: left;" class="customer-inv-grid-site-template">
              <div class="customer-inv-grid-sites-rows-wraper">
                <div caGridTooltip class="customer-inv-grid-site-name">{{d.data.quoteService.quoting_type | quoteServiceAddressA: d.data}}</div>

                <!--<div caGridTooltip class="customer-inv-grid-site-name">{{d.data.quoteService.address?.address1}}</div>-->
                <!--<div caGridTooltip class="customer-inv-grid-site-address">{{d.data.quoteService.quoting_type | quoteServiceAddressA: d.data}}</div>-->
              </div>
          </div>

          <div *dxTemplate="let d of 'addressZtemplate'" style="text-align: left;" class="customer-inv-grid-site-template">
            <div class="customer-inv-grid-sites-rows-wraper">
              <div caGridTooltip class="customer-inv-grid-site-name">{{d.data.quoteService.quoting_type | quoteServiceAddressZ: d.data}}</div>

              <!--<div caGridTooltip class="customer-inv-grid-site-name">{{d.data.quoteService.address?.address1}}</div>-->
              <!--<div caGridTooltip class="customer-inv-grid-site-address">{{d.data.quoteService.quoting_type | quoteServiceAddressZ: d.data}}</div>-->
            </div>
          </div>
          <div *dxTemplate="let cell of 'quoteRequestStatusTemplate'">
            <div *ngIf="cell.data?.quote_type?.id === QUOTE_SERVICE_OFFER_METHOD.CONTRACT">
              <div *ngIf="cell.data?.status === QUOTE_SERVICE_OFFER_STATUS.FAILED">{{cell.data?.failure_reason |
                contractQuoteFailureReason}}
              </div>
              <div *ngIf="cell.data?.status !== QUOTE_SERVICE_OFFER_STATUS.FAILED">{{cell.data?.status |
                quoteRequestStatus:cell.data?.quote_type?.id}}
              </div>
            </div>
            <div *ngIf="cell.data?.quote_type?.id !== QUOTE_SERVICE_OFFER_METHOD.CONTRACT">
              <div>{{cell.data?.status | quoteRequestStatus:cell.data?.quote_type?.id}}</div>
            </div>
          </div>
          <div *dxTemplate="let d of 'term1YTemplate'"
               [ngClass]="{'selected-term ca-theme-accent': d.data.quoteService?.selected_term==1 && d.rowIndex==0}">
            <div>
              <div caGridTooltip *ngIf="d.data.one_year_mrc">
                {{ d.data.one_year_mrc | caCurrency }}
                / {{ d.data.one_year_nrc | caCurrency }}
              </div>
              <span *ngIf="!d.data.one_year_mrc">Not Recd.</span>
            </div>
          </div>
          <div *dxTemplate="let d of 'termMTMTemplate'"
               [ngClass]="{'selected-term ca-theme-accent': d.data.quoteService?.selected_term==4 && d.rowIndex==0}">
            <div>
              <div caGridTooltip *ngIf="d.data.mtm_mrc">
                {{ d.data.mtm_mrc | caCurrency }}
                / {{ d.data.mtm_nrc | caCurrency }}
              </div>
              <span *ngIf="!d.data.mtm_mrc">Not Recd.</span>
            </div>
          </div>
          <div *dxTemplate="let d of 'term2YTemplate'"
               [ngClass]="{'selected-term ca-theme-accent': d.data.quoteService?.selected_term==2 && d.rowIndex==0}">
            <div>
              <div caGridTooltip *ngIf="d.data.two_year_mrc">
                {{ d.data.two_year_mrc | caCurrency }}
                / {{ d.data.two_year_nrc | caCurrency }}
              </div>
              <span *ngIf="!d.data.two_year_mrc">Not Recd.</span>
            </div>
          </div>
          <div *dxTemplate="let d of 'term3YTemplate'"
               [ngClass]="{'selected-term ca-theme-accent': d.data.quoteService?.selected_term==3 && d.rowIndex==0}">
            <div>
              <div caGridTooltip *ngIf="d.data.three_year_mrc">
                {{ d.data.three_year_mrc | caCurrency }}
                / {{ d.data.three_year_nrc | caCurrency }}
              </div>
              <span *ngIf="!d.data.three_year_mrc">Not Recd.</span>
            </div>
          </div>
          <div *dxTemplate="let d of 'term5YTemplate'"
               [ngClass]="{'selected-term ca-theme-accent': d.data.quoteService?.selected_term==5 && d.rowIndex==0}">
            <div>
              <div caGridTooltip *ngIf="d.data.five_year_mrc">
                {{ d.data.five_year_mrc | caCurrency }}
                / {{ d.data.five_year_nrc | caCurrency }}
              </div>
              <span *ngIf="!d.data.five_year_mrc">Not Recd.</span>
            </div>
          </div>
          <div *dxTemplate="let d of 'term7YTemplate'"
               [ngClass]="{'selected-term ca-theme-accent': d.data.quoteService?.selected_term==6 && d.rowIndex==0}">
            <div>
              <div caGridTooltip *ngIf="d.data.seven_year_mrc">
                {{ d.data.seven_year_mrc | caCurrency }}
                / {{ d.data.seven_year_nrc | caCurrency }}
              </div>
              <span *ngIf="!d.data.seven_year_mrc">Not Recd.</span>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </div>

    <div *ngFor="let service of quoteServices"
             [ngClass]="{'visibility-hidden': !tabGroup.isActive(service.id)}"
             style="padding:12px; height: 100%"
             fxLayout="column">

          <div fxLayout="row" fxLayout.sm="column">
            <mat-card fxFlex class="flex-item details-side" fxFlex="35%" fxFlex.sm="50%" caCard>
              <div *ngIf="service.quoting_type.id === QUOTING_SERVICE_TYPE.P2P; else serviceAddressOtherTypes">
                <mat-card-header>
                  Service Address
                </mat-card-header>
                <mat-card-content class="inner-content">
                  <div fxLayout="row" class="entry-list">
                    <div fxFlex class="entry-list-item">
                      <label>A Address</label>
                      <p *ngIf="service.address?.address1">
                        {{ service.address?.address1}}
                      </p>
                      <p>
                        {{ service.address?.city }}&nbsp; {{ service.address?.subdivision?.name }}&nbsp; {{
                                                        service.address?.postal_code
                                                        }}
                      </p>
                    </div>
                  </div>
                  <div fxLayout="row" class="entry-list">
                    <div fxFlex class="entry-list-item">
                      <label>Z Address</label>
                      <p *ngIf="service.address?.address1">
                        {{ service.secondary_address?.address1 || 'N/A'}}
                      </p>
                      <p>
                        {{ service.secondary_address?.city }}&nbsp; {{ service.secondary_address?.subdivision?.name }}&nbsp; {{
                                                          service.secondary_address?.postal_code
                                                          }}
                      </p>
                    </div>
                  </div>
                </mat-card-content>
              </div>
              <ng-template #serviceAddressOtherTypes>
                <mat-card-header>
                  Service Address
                </mat-card-header>
                <mat-card-content class="inner-content">
                  <div fxLayout="row" class="entry-list">
                    <div fxFlex class="entry-list-item">
                      <p *ngIf="service.address?.address1">
                        {{ service.address?.address1}}
                      </p>
                      <p>
                        {{ service.address?.city }}&nbsp; {{ service.address?.subdivision?.name }}&nbsp; {{
                                          service.address?.postal_code
                                          }}
                      </p><br>
                      <p>
                        &nbsp;</p>
                    </div>
                  </div>
                </mat-card-content>
              </ng-template>
            </mat-card>

            <mat-card fxFlex class="flex-item details-main" fxFlex="65%" caCard>
              <mat-card-header>
                Service Details
              </mat-card-header>
              <mat-card-content class="inner-content">
                <div fxLayout="row" class="entry-list">
                  <div fxFlex class="entry-list-item">
                    <label>Type</label>
                    <p>
                      {{ (service.quoting_type?.value) || 'N/A'}}
                    </p>
                  </div>

                  <!-- Hide if service type is not Other -->
                  <ng-container *ngIf="service.quote_service_type_id !== QUOTING_SERVICE_TYPE.OTHER">
                  <div fxFlex class="entry-list-item">
                    <label>Service Type</label>
                    <p>
                      {{ (service.quote_service_type?.value) || 'N/A'}}
                    </p>
                  </div>
                  <div fxFlex class="entry-list-item">
                    <label>Bandwidth</label>
                    <p>
                      {{ (service.circuit_bandwidth?.value) || 'N/A'}}
                    </p>
                  </div>
                  <div fxFlex class="entry-list-item">
                    <label>Port Bandwidth</label>
                    <p>
                      {{ (service.port_bandwidth?.value) || 'N/A'}}
                    </p>
                  </div>
                  <div fxFlex class="entry-list-item">
                    <label>Diversity</label>
                    <p>
                      {{ (service.diversity) ? 'Yes' : 'No'}}
                    </p>
                  </div>
                </ng-container>

                <!-- Show if service type is Other -->
                <ng-container *ngIf="service.quote_service_type_id === QUOTING_SERVICE_TYPE.OTHER">
                  <div fxFlex fxGrow="5" class="entry-list-item">
                    <label>Service Type</label>
                    <p>
                      {{ getOtherServiceType(service.client_service_type) || 'N/A'}}
                    </p>
                  </div>
                </ng-container>
                </div>

                <div fxLayout="row" class="entry-list">
                  <div fxFlex class="entry-list-item">
                    <label>Quote Status</label>
                    <p>
                      {{ (service?.quote_service_status?.value) || 'Open'}}
                    </p>
                  </div>
                  <div fxFlex class="entry-list-item">
                    <label>Desired Install Date</label>
                    <p>
                      {{ (service?.desired_install_date | date) || 'N/A'}}
                    </p>
                  </div>
                  <div fxFlex class="entry-list-item">
                    <label>Create Date</label>
                    <p>
                      {{ (service?.create_date | date) || 'N/A'}}
                    </p>
                  </div>
                  <div fxFlex class="entry-list-item">
                    <label>Complete Date</label>
                    <p>
                      {{ (service?.complete_date | date) || 'N/A'}}
                    </p>
                  </div>
                  <div fxFlex class="entry-list-item">
                    <label>Quote Selection</label>
                    <p *ngIf="isClosed && service?.selected_offer">
                      {{ ('Quote' + ' ' + service?.selected_offer?.quote_service_offer_id + ' - ' + service?.selected_term +
                      ' Year Term') }}
                    </p>
                    <p *ngIf="!isClosed">
                      None
                    </p>
                    <p *ngIf="isClosed && !service?.selected_offer">
                      No selection made
                    </p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="app-content-list app-content-list-no-pager dx-nodata-absolute" caCard style="flex: 1">
            <dx-data-grid style="height: 100%;overflow-y: hidden"  #offerGrid
                          (onSelectionChanged)="onSelectionChanged($event)"
                          (onRowClick)="onRowClick($event)"
                          (onCellClick)="onCellClick($event)"
                          [columns]="columns"
                          [dataSource]="quoteServiceRequests">
              <div *dxTemplate="let cell of 'emptyCellTemplate'">
                <span>{{cell.text || 'Not Recd.'}}</span>
              </div>
              <div *dxTemplate="let cell of 'quoteRequestStatusTemplate'">
                {{cell.data?.status | quoteRequestGridStatus:cell.data?.quote_type?.id:cell.data?.failure_reason}}
              </div>
              <div *dxTemplate="let d of 'term1YTemplate'"
                   [ngClass]="{'selected-term ca-theme-accent': service?.selected_term==1 && d.rowIndex==0}">
                <div *ngIf="isTermSelected(d.column)">
                  <div caGridTooltip *ngIf="d.data.one_year_mrc">
                    {{ d.data.one_year_mrc | caCurrency }}
                    / {{ d.data.one_year_nrc | caCurrency }}
                  </div>
                  <span *ngIf="!d.data.one_year_mrc">Not Recd.</span>
                </div>
              </div>
              <div *dxTemplate="let d of 'termMTMTemplate'"
                   [ngClass]="{'selected-term ca-theme-accent': service?.selected_term==4 && d.rowIndex==0}">
                <div *ngIf="isTermSelected(d.column)">
                  <div caGridTooltip *ngIf="d.data.mtm_mrc">
                    {{ d.data.mtm_mrc | caCurrency }}
                    / {{ d.data.mtm_nrc | caCurrency }}
                  </div>
                  <span *ngIf="!d.data.mtm_mrc">Not Recd.</span>
                </div>
              </div>
              <div *dxTemplate="let d of 'term2YTemplate'"
                   [ngClass]="{'selected-term ca-theme-accent': service?.selected_term==2 && d.rowIndex==0}">
                <div *ngIf="isTermSelected(d.column)">
                  <div caGridTooltip *ngIf="d.data.two_year_mrc">
                    {{ d.data.two_year_mrc | caCurrency }}
                    / {{ d.data.two_year_nrc | caCurrency }}
                  </div>
                  <span *ngIf="!d.data.two_year_mrc">Not Recd.</span>
                </div>
              </div>
              <div *dxTemplate="let d of 'term3YTemplate'"
                   [ngClass]="{'selected-term ca-theme-accent': service?.selected_term==3 && d.rowIndex==0}">
                <div *ngIf="isTermSelected(d.column)">
                  <div caGridTooltip *ngIf="d.data.three_year_mrc">
                    {{ d.data.three_year_mrc | caCurrency }}
                    / {{ d.data.three_year_nrc | caCurrency }}
                  </div>
                  <span *ngIf="!d.data.three_year_mrc">Not Recd.</span>
                </div>
              </div>
              <div *dxTemplate="let d of 'term5YTemplate'"
                   [ngClass]="{'selected-term ca-theme-accent': service?.selected_term==5 && d.rowIndex==0}">
                <div *ngIf="isTermSelected(d.column)">
                  <div caGridTooltip *ngIf="d.data.five_year_mrc">
                    {{ d.data.five_year_mrc | caCurrency }}
                    / {{ d.data.five_year_nrc | caCurrency }}
                  </div>
                  <span *ngIf="!d.data.five_year_mrc">Not Recd.</span>
                </div>
              </div>
              <div *dxTemplate="let d of 'term7YTemplate'"
                   [ngClass]="{'selected-term ca-theme-accent': service?.selected_term==6 && d.rowIndex==0}">
                <div *ngIf="isTermSelected(d.column)">
                  <div caGridTooltip *ngIf="d.data.seven_year_mrc">
                    {{ d.data.seven_year_mrc | caCurrency }}
                    / {{ d.data.seven_year_nrc | caCurrency }}
                  </div>
                  <span *ngIf="!d.data.seven_year_mrc">Not Recd.</span>
                </div>
              </div>
              <div *dxTemplate="let d of 'networkHub'">
                {{d.data.network_hub?.hub_name || 'N/A'}}
              </div>
            </dx-data-grid>
          </div>
        </div>

        <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(3)}" class="tabs-body grid-container"
             style="overflow-y: hidden;">
          <!--SECTION DOCUMENTS-->
          <app-document-manager [tabChanged]="tabChanged"
                                [folder]="DOCUMENT_INFO.FOLDER"
                                [master]="quote"
                                [parentType]="selectedEntity?.type"
                                [subfolder]="DOCUMENT_INFO.SUBFOLDER"
                                [masterId]="selectedMaster?.id"
                                [masterType]="selectedMaster?.type"
                                [entityType]="selectedEntity?.type"
                                [entityId]="selectedEntity?.id"
                                [entities]="entities"
                                [mapMeta]="mapDocumentsMeta"
                                (docAdd)="history.refreshList()"
                                (docSelected)="docSelected($event)"
                                (docDelete)="history.refreshList()"
                                #documentsManager>
          </app-document-manager>
        </div>
  </div>


  <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false">
    <ca-notes-list *ngIf="dataReady"
                   [master]="selectedMaster"
                   [entity]="selectedEntity"
                   [showViewLink]="showNotesViewLink"
                   [mapMeta]="mapNotesMeta"
                   [options]="lineItems"
                   [groups]="groups"
                   [multiple]="false"
                   (onViewEntityRequested)="onViewEntityRequested($event)"
                   [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                   (countChanged)="onNotesCountChanged($event)"></ca-notes-list>
    <ca-history #history
                [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
                [entityId]="quote?.id" [entityType]="'quote'"></ca-history>
  </mat-sidenav>
</mat-sidenav-container>
