import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CommonAlert} from "../../../common-alert/core/common-alert";
import {CommonAlertQuery} from "../../../common-alert/core/common-alert.query";
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {CommonAlertService} from "../../../common-alert/core/common-alert.service";
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {Select, Store} from "@ngxs/store";
import {QueryBuilder} from "../../../common/query/query.builder";
import {ActivatedRoute, Router} from "@angular/router";
import {InvoiceQuery} from "../../core/invoice.query";
import {AlertService} from "../../../shared/alert/alert.service";
import {Config} from "../../../core/config/config";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {CommonAlertGridNoSelectionService} from "../../../common-alert/core/common-alert-grid-no-selection.service";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {HistoryComponent} from "../../../shared/history/history.component";
import {Observable, Subject} from "rxjs";
import {INVOICE_STATUS_ENUM} from "../../core/invoice-status.enum";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {InvoiceFlowService} from "../../core/invoice-flow.service";
import {HistoryService} from "../../../shared/history/history.service";
import {UpdateInvoiceAlertSiderSection} from "./state/invoice-alert.actions";
import {SortingBuilder, SortingService} from "../../../shared/sorting/sorting.service";
import {takeUntil} from "rxjs/operators";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";

@Component({
  selector: 'app-invoice-alerts',
  templateUrl: './invoice-alerts.component.html',
  styleUrls: ['./invoice-alerts.component.css']
})

//TODO: The naming convention is confusing. I'm on the alerts tab, but we're using audit for variable name instead alerts
export class InvoiceAlertsComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    HISTORY_SECTION_NAME: "history",
    NOTES_SECTION_NAME: 'notes',
  };

  readonly AUDIT_RULE_NAMES = {
    10: 'CHARGE',
    25: 'inventory-mrc',
    50: 'account',
    70: 'gl-validation',
    80: 'rate-audit',
    90: 'batch',
    100: 'audit',
    110: 'disconnect',
    120: 'missing-inventory'
  }

  invoice: InvoiceFacepage;
  invoiceQuery: InvoiceQuery = new InvoiceQuery();
  alertsCount = 0;
  singleDigit = false;
  config: Config;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'invoice';
  auditQuery: CommonAlertQuery = new CommonAlertQuery();
  audits: Array<CommonAlert> = [];
  auditColumns: any;
  defaultGridPager: any;
  alertSorting: SortingBuilder;
  sider: Sider;

  dnpInfo: any = null
  onHoldInfo: any = null
  notesCount = 0;
  invoiceStatusEnum = INVOICE_STATUS_ENUM;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('auditGrid') auditGrid: DxDataGridComponent;
  @ViewChild('panelSide') panelSide;
  @ViewChild('history') history: HistoryComponent;
  @ViewChild('testNotes') testNotes;

  @Select(state => state.invoice_alert.activeSiderSection) $activeSiderSection: Observable<any>;

  constructor(
    private readonly invoiceFacepageService: InvoiceFacepageService,
    public store: Store,
    public queryBuilder: QueryBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public commonAlertService: CommonAlertService,
    public commonAlertGridNoSelectionService: CommonAlertGridNoSelectionService,
    public alertService: AlertService,
    public invoiceFlowService: InvoiceFlowService,
    public historyService: HistoryService,
    public sortingService: SortingService,
  ) {
    this.alertSorting = this.sortingService.builder();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.invoiceFacepageService.invoiceChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(invoice => {
      this.invoice = invoice;
      this.history.refreshList();
    })
  }

  ngOnInit(): void {
    this.invoice = this.route.parent.snapshot.data.invoice;
    this.auditColumns = this.commonAlertGridNoSelectionService.columns();
    this.loadAudits(this.invoice)
    this.getOnHoldInfo()
    this.testNotes && this.testNotes.emitCount();
    //this.getDNPInfo(this.invoice.notes, false)
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();

    this.commonAlertGridNoSelectionService.create(this.auditGrid.instance, {
      noDataText: this.commonAlertGridNoSelectionService.noDataMessage
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  public onAuditRowClick(row) {
    let component = row.component;
    let prevClickTime = component.lastClickTime;

    component.lastClickTime = new Date();

    if (prevClickTime && component.lastClickTime - prevClickTime < 250) {
      // TODO: logic for charge view
      /*let chargeIds = [];
       row.data.map(item => chargeIds = chargeIds.concat(item.charge_ids.charge_ids));
       chargeIds = Array.from(new Set(chargeIds));

       if (chargeIds.length) {
       this.router.navigate(['/charge'], {
       queryParams: {
       chargeids: chargeIds,
       vendorid: this.invoice.vendor_id,
       accountNo: this.invoice.acct_level_1
       }
       });
       }*/
    } else {
      // setTimeout(() => this.showSneakPeek(), 300);
    }
  }

  onRowDblClick(event) {
    let alertType = this.AUDIT_RULE_NAMES[event.data.type_id] || 'inventory';
    this.router.navigate(['/alert', event.data.id, alertType]);
  }

  onPageAlertChange() {
    this.loadAudits(this.invoice);
  }

  loadAudits({invoice_id: invoiceId}: InvoiceFacepage) {
    this.invoiceQuery.set('invoice_id', invoiceId);

    this.auditQuery['where']['invoice_id'] = invoiceId;
    if (!this.auditQuery.orderBy.length) {
      this.auditQuery.orderBy = [['created_at', 'desc']]
    }

    this.commonAlertService.getAlertsForInvoice(this.auditQuery).subscribe(
      resultAudits => {
        this.audits = resultAudits.items;
        this.auditQuery.total = resultAudits.total;
        this.alertsCount = resultAudits.total;
        this.singleDigit = resultAudits.total < 10 && resultAudits.total > 0;
        let auditsTitle = 'Alerts';
        if (this.audits.length) {
          auditsTitle += `<span class="selection-badge">${
            this.audits.length
            }</span>`;
        } else {
          // this.tabGroup.disable(this.tabGroup.find(5), true);
        }
      },
      err => {
        this.alertService.error('', this.messages.get('AUDIT_LOAD_ERROR'));
      }
    );
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInvoiceAlertSiderSection(activeSection.name)
    ]);
  }

  async getOnHoldInfo() {
    if (!this.invoice.account.on_hold) {
      this.onHoldInfo = null;
      return;
    }
    let accountHistory = await this.historyService.findAllForEntity('account', this.invoice.account.id).toPromise()
    let onHoldFound = accountHistory.filter(account => (account.meta_data.on_hold && account.meta_data.on_hold.value === true))
    this.onHoldInfo = (onHoldFound && onHoldFound.length > 0) ? onHoldFound[0] : null
  }

  getDNPInfo(notes: any, forceEmpty: boolean = false) {
    const dnpStatus = this.invoiceFlowService.LOCALS.DO_NOT_PROCESS

    if (forceEmpty || this.invoice.header.status_code !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
      this.dnpInfo = null
    } else {
      const dnpNotes = notes.filter(note => note.content.startsWith(dnpStatus));
      this.dnpInfo = (dnpNotes.length) ? dnpNotes[0] : {};
      this.dnpInfo.status = dnpStatus;
      this.dnpInfo.message = this.dnpInfo.content ? this.dnpInfo.content.substring(dnpStatus.length + 2) : this.messages.get('DNP_FALLBACK_MESSAGE');
    }
  }

  /* Notes count changed */
  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }


  onNoteCreated() {
    // this.refresh(this.query)
    // // TODO: was this.loadCharges(this.invoice); now refresh / check if valid
    // this.selectedCharges.length = 0;
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/invoice']);
    }
  }

  onDownloadCSVRequested() {
    const {
      invoice: {sp_inv_num},
      auditQuery,
      commonAlertService
    } = this;
    this.commonAlertGridNoSelectionService.csvMap().subscribe(fieldsAudit => {
      commonAlertService.exportToCSV(`${sp_inv_num}_alerts`, {
        fields: fieldsAudit,
        query: auditQuery
      });
    });
  }

  onAlertCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.alertSorting.apply(event, this.auditQuery);
      this.loadAudits(this.invoice);
    }
  }
}
