import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms'
import {ContactFormModel} from "./contact-form.model";
import {SingleContactFormModel, SingleContactModel} from "./single-contact-form.model";
import {ConfigService} from "../../core/config/config.service";
import {Config} from "../../core/config/config";

@Injectable()
export class ContactFormService {
  private contactForm: BehaviorSubject<FormGroup | undefined> =
    new BehaviorSubject(this.fb.group(new ContactFormModel()));

  contactForm$: Observable<FormGroup> = this.contactForm.asObservable();

  constructor(
    private fb: FormBuilder,
    private configService: ConfigService,
  ) {
  }

  /**
   * Empty contact form
   */
  emptyContactForm() {
    this.contactForm = new BehaviorSubject(this.fb.group(new ContactFormModel()));
    this.contactForm$ = this.contactForm.asObservable();
  }

  addContact(data) {
    const contactForm = this.contactForm.getValue();
    const contacts = contactForm.get('contacts') as FormArray;

    contacts.push(
      this.fb.group(data)
    );
  }

  getEmptyForm(phone_regex) {
    return this.fb.group(new SingleContactFormModel(null, phone_regex));
  }

  getFormWithData(data, phone_regex?: string) {
    return this.fb.group(new SingleContactFormModel(data, phone_regex));
  }

  deleteContact(i: number) {
    const contactForm = this.contactForm.getValue();
    const contacts = contactForm.get('contacts') as FormArray;
    contacts.removeAt(i);
    this.contactForm.next(contactForm);
  }

  /**
   * Init only when it's needed to have required forms (init inside component)
   * @private
   */
  public initRequiredForm() {
    const contactForm = this.contactForm.getValue();
    const contacts = contactForm.get('contacts') as FormArray;

    this.configService.findAll()
      .subscribe((config: Config) => {
        const requiredContacts = config.order.contact.required_contacts;
        if (requiredContacts.length > 0) {
          requiredContacts.forEach((contact, index) => {
            let form = this.fb.group(new SingleContactFormModel(
              new SingleContactModel(index, null, null, null, null, contact, null, true)
            ));

            form.controls['first_name'].setValidators([Validators.required]);
            form.controls['first_name'].updateValueAndValidity();
            form.controls['last_name'].setValidators([Validators.required]);
            form.controls['last_name'].updateValueAndValidity();
            form.controls['email'].setValidators([Validators.required]);
            form.controls['email'].updateValueAndValidity();
            form.controls['contact_function'].setValidators([Validators.required]);
            form.controls['contact_function'].updateValueAndValidity();
            contacts.push(form);
          });
        }
    });
  }
}
