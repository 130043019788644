import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class AlertBatchInvoicesGridService extends GridService {
  defaultColumns = [
      {
        caption: 'Invoice ID',
        dataField: 'invoice.invoice_id',
        width: 110
      },
      {
        caption: 'Invoice Number',
        headerCellTemplate: 'invNumTemplate',
        dataField: 'invoice.sp_inv_num',
        width: 220
      },
      {
        caption: 'Message',
        dataField: 'message',
        width: 220
      },
      {
        caption: 'Vendor',
        dataField: 'invoice.vendor.name',
        width: 150
      },
      {
        caption: 'Account',
        dataField: 'invoice.acct_level_1',
        width: 150
      },
      {
        caption: 'Invoice Date',
        dataField: 'invoice.inv_date',
        cellTemplate: "invoiceDate",
        dataType: 'date',
        format: "dateTemplate",
        alignment: 'left',
        width: 130
      }
  ];

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.alert.batch-invoices-grid', userSettings);
  }

}
