<div *ngIf="alert">
  <mat-toolbar color="accent" mat-scroll-shrink>
    <a (click)="redirectToCharges()" class="sneak-peek-title">{{ alert?.account_no || 'N/A'}}</a>
    <span class="example-fill-remaining-space"></span>
    <button mat-icon-button (click)="closeSide()">
      <ca-icon [key]="'CLOSE'"></ca-icon>
    </button>
  </mat-toolbar>
  <div class="ca-card ca-card-sneak-peek">
    <mat-card>
      <mat-card-content>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Message</label>
            <p>
              {{ alert?.message || 'N/A'}}
            </p>
          </div>
        </div>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Type</label>
            <p>
              {{ alert?.type?.value || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Owner</label>
            <p>
              {{ alert?.owner?.full_name || 'N/A'}}
            </p>
          </div>
        </div>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Vendor</label>
            <p>
              {{ alert?.vendor?.name || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Account</label>
            <p>
              {{ alert?.account_no || 'N/A'}}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Priority</label>
            <p>
              {{ alert?.priority?.value || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Rule Name</label>
            <p>
              {{ alert?.rule_name || 'N/A'}}
            </p>
          </div>
        </div>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Created By</label>
            <p>
              {{ alert?.creator?.full_name || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Created Time</label>
            <p>
              {{ (alert?.created_at | caDateTime) || 'N/A' }}
            </p>
          </div>
        </div>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Last Modification Time</label>
            <p>
              {{ alert?.last_modified_at ? (alert.last_modified_at | caDateTime) : 'N/A' }}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Status</label>
            <p>
              {{ alert?.status?.value || 'N/A'}}
            </p>
          </div>
        </div>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Closed Time</label>
            <p>
              {{ alert?.closed_at ? ( alert.closed_at | caDateTime) : 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Invoice Number</label>
            <p class="text-overflow-18">
              {{ alert?.invoice?.sp_inv_num || 'N/A'}}
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div *ngIf="!alert" class="ca-empty-state-container">
    <p class="small-div-message">No item selected.</p>
</div>
