import { Injectable } from "@angular/core";
import { Restangular } from "ngx-restangular";
import { BaseService } from "../../core/base.service";

@Injectable()
export class BillService extends BaseService<any> {
  constructor(public restangular: Restangular) {
    super("bill", restangular);
  }

  public service() {
    return this.restangular;
  }

  checkBillStatus(id: number): any {
    return this.service().one(`${this.name}/bill-status`, id).get();
  }

}
