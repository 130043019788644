
<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>GL Coding Warning</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <p>

    There are <strong>{{state?.count}}</strong> charges without GL codes applied.
    In order to process an invoice, please click on Proceed to GL button to make
    the GL changes or Cancel button to continue in this interface.
  </p>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="submit" (click)="cancel()">
      Cancel
    </button>
  <button mat-button
          type="button"
          (click)="proceedToGL()">
    Proceed to GL
  </button>
</mat-dialog-actions>
