import { of as observableOf } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { AfterViewInit, Component, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import { Inventory } from '../../inventory/core/inventory';
import { InventoryQuery } from '../../inventory/core/inventory.query';
import { InventoryService } from '../../inventory/core/inventory.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { GridService } from '../../shared/grid/grid.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { TabGroup, TabService } from '../../shared/tabs/tab.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { AlertInventoryMrcChargesGridService } from '../core/alert-inventory-mrc-charges-grid.service';
import { AlertInventoryMrcFilterService } from '../core/alert-inventory-mrc-filter.service';
import { AlertInventoryMrcGridService } from '../core/alert-inventory-mrc-grid.service';
import { CommonAlertService } from '../core/common-alert.service';
import { AlertService } from '../../shared/alert/alert.service';
import Query from "../../core/query/query";
import { ChargeQuery } from "../../charge/core/charge.query";
import { DisputeManageDialogComponent } from '../../dispute/shared/dispute-manage-dialog/dispute-manage-dialog.component';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { InvoiceChargeService } from "../../charge/core/invoice-charge.service";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { UserService } from "../../user/core/user.service";
import { LocationService } from "../../location/core/location.service";

//HELPER
import {
  findIndexInSelectedCharges,
  getTotalChargeAmountFromInvoice,
  calculateDisputesFromTotalAmount,
  groupDisputesBySpid,
  sortCalculatedChargesByChargeId
} from "../helper/common-alert"

@Component({
  selector: 'app-alert-inventory-mrc',
  templateUrl: './alert-inventory-mrc.component.html',
  styleUrls: ['./alert-inventory-mrc.component.css']
})
export class AlertInventoryMrcComponent extends PageListComponent implements AfterViewInit, OnDestroy {
  alert;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTab = 0;
  public audits: Array<any> = [];
  public loadedAudits: Array<any> = [];
  public columns: any;
  public sorting: any[][];
  public chargeColumns: any;
  public selection: any;
  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @ViewChild('chargesDataGrid', { static: false }) chargesDataGrid: DxDataGridComponent;
  @ViewChildren(DxDataGridComponent) dataGrids: QueryList<DxDataGridComponent>

  readonly sideSections = {
    none: null,
    info: 'info',
    filter: 'filter',
  };

  _isSidePanelOpen = false;
  activeTabIndex = 0;
  isGridSettingsDisabled = true;

  readonly PLACEHOLDERS = {
    OWNER_UNASSIGNED: 'Unassigned'
  }

  public query: InventoryQuery = new InventoryQuery({ orderBy: [['sp_ckt', 'ASC']] });
  public queryInventoryMrcFilter: Query = new Query({ orderBy: [['id', 'ASC']], limit: 20 });

  addDisputeDisabled: boolean = true;
  public selectedCharges: Array<any> = [];
  charges: Array<any> = [];
  chargeQuery: ChargeQuery = new ChargeQuery({ orderBy: [['id', 'ASC']] });
  public dictionaryQuery: Query = new Query({ orderBy: [['id', 'ASC']] });
  public DISPUTE_CATEGORY_LOOKUP_MODEL = LOOKUP_MODELS_ENUM.DISPUTE_CATEGORY.modelName;
  resultQuery: Query = new Query({ total: 0, orderBy: [['id', 'ASC']] });
  delayChargesSelectionChange: boolean = false;
  public sizes = [5, 10, 20];
  public selectionActive = false;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'common-alert';

  public alertId: number;

  public selectedAlertId = null;

  chargesRowsExpanded: any[] = [];
  inventoryMRCPagerLimit: number = 20;

  constructor(public settingsService: UserSettingsService,
    public route: ActivatedRoute,
    public alertService: CommonAlertService,
    public toastAlertService: AlertService,
    public alertInventoryMrcGridService: AlertInventoryMrcGridService,
    public alertInventoryMrcChargesGridService: AlertInventoryMrcChargesGridService,
    public router: Router,
    public dialogService: DialogService,
    public inventoryService: InventoryService,
    public chargeService: InvoiceChargeService,
    public dictionaryService: DictionaryService,
    public userService: UserService,
    public locationService: LocationService,
    public inventoryMrcFilterService: AlertInventoryMrcFilterService,
    public tabService: TabService,
    public loaderService: LoaderService) {
    super(new PageContext({
      name: 'app.alert.alert-inventory-mrc',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    if (tab.key === 2 && this.sider.getActiveSection().name === this.sideSections.filter) {
      this._isSidePanelOpen = true;

      observableOf(true)
        .subscribe(
          () => {
            this._isSidePanelOpen ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME) : this.sider.closeComponent();

            this.tabGroup.forceRerender();
          }
        );
    }

    if (tab.key !== 2 && this.sider.getActiveSection().name === this.sideSections.filter) {
      this._isSidePanelOpen = false;

      observableOf(true)
        .subscribe(
          () => {
            this._isSidePanelOpen ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME) : this.sider.closeComponent();

            this.tabGroup.forceRerender();
          }
        );
    }

    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }

    /* Settings grid disabled */
    if (this.tabGroup.isActive(2) || this.tabGroup.isActive(3) || this.tabGroup.isActive(4)) {
      this.isGridSettingsDisabled = false;
    } else {
      this.isGridSettingsDisabled = true;
    }
  }

  public loadTabs() {
    this.tabGroup = this.tabService.create();
    this.tabGroup.addTab({ key: 1, title: 'Summary' });
    this.tabGroup.addTab({ key: 2, title: 'Details' });
    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe((tab) => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }

  loadData(alertId: number) {
    this.alertService.findById(this.route.params['_value'].id)
      .subscribe(
        (alert) => {
          this.alert = alert;
          this.loadInventoryMRCs(this.query);
        },
        (error) => {
          console.log('Error fetching alert info');
        }
      );
  }

  loadInventoryMRCs(query: InventoryQuery) {
    // add invoice for chargeID filter
    query.where.invoice_id = this.alert.invoice_id
    this.alertService.findInventoryMRCs(this.alertId, query)
      .subscribe((result) => {
        this.audits = result.rows;
        query.total = result.count;
        setTimeout(() => {
          if (this.dataGrid) {
            this.dataGrid.instance.refresh();
            this.chargesRowsExpanded.map(item => {
              this.onRowExpanded(item, true);
            });
          }
        }, 200);
      });
  }

  refresh() {
    this.loadInventoryMRCs(this.query);
  }

  onSelectionChanged(row) {
    this.selection = <Inventory>row.selectedRowsData[0];
  }

  onRowCollapsed(row) {
    this.chargesRowsExpanded.splice(this.chargesRowsExpanded.indexOf(row.key), 1);
  }

  onRowExpanded(row, manual?: boolean) {
    let audit = this.audits.find((x) => {
      return manual ? x.id === row : x.id === row.key;
    });
    if (!audit) return;

    if (!audit.resultQuery) {
      audit.resultQuery = new Query({
        where: {
          ...this.queryInventoryMrcFilter.where,
          spid: audit.sp_ckt,
          invoice_id: this.alert.invoice_id,
          alert_id: this.alert.id
        },
        orderBy: [["id", "ASC"]],
      });
    }

    if (!audit.charges) {
      this.loaderService.displayLoader(true);
      this.alertService.findChargesByMRCandSPID(this.alert.id, audit.resultQuery)
        .subscribe((result) => {
          Object.assign(audit, { charges: result.rows });
          this.loaderService.hideLoader(true);
          audit.resultQuery.total = result.count;
          // add selected charge ids into audit.selectedRows
          const charges = this.selectedCharges.filter(chg => audit.id === chg['rule_id'])
          audit.selectedRows = charges.length ? charges.reduce((acc, item) => ([...acc, item.id]), []) : []
          audit.selectionActive = false;
          this.delayChargesSelectionChange = true;
          setTimeout(() => {
            this.selectRowsWithinDetailsGrid(audit.id)
          }, 100)
        })
    }

    if (!manual) {
      this.chargesRowsExpanded.push(row.key);
    }
  }

  onPageChange(query: InventoryQuery) {
    this.loadInventoryMRCs(query);
  }

  onResultPageChange(query: Query, rule) {
    let ruleOne = this.audits.find(x => x.id === rule.id);
    this.loadAuditResult(query, ruleOne);
  }

  filterData(query: InventoryQuery) {
    this.queryInventoryMrcFilter.where.id = query['where']['charge_id'] || null
    this.query = query;
    this.loadInventoryMRCs(query);
  }

  clearFilter(query: InventoryQuery) {
    delete this.queryInventoryMrcFilter['where']['id']
    this.query = query;
    this.loadInventoryMRCs(query);
  }

  ngOnInit() {
    this.loadTabs();
    this.alert = this.route.snapshot.data.alert;
    this.tabGroup.tabs[1].disabled = this.alert.message === 'No issues found';
    this.alertId = this.alert.id;
    this._init();
  }

  gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.alertInventoryMrcGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.alertInventoryMrcGridService;
  }

  getDefaultSort(): any {
    return [['sp_ckt', 'ASC']];
  }

  _init() {
    super.prepareList();
    this.chargeColumns = this.alertInventoryMrcChargesGridService.columns();
    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.refresh();
    }

    this.route.params.pipe(
      takeUntil(this.destroy$))
      .subscribe((params: Params) => {
        this.alertId = params['id'];
        this.loadData(this.alertId);
      });
  }

  ngAfterViewInit(): void {
    this.alertInventoryMrcGridService.create(this.dataGrid.instance, {
      noDataText: this.alertInventoryMrcGridService.noDataMessage
    });
    if (this.chargesDataGrid) {
      this.alertInventoryMrcChargesGridService.create(this.chargesDataGrid.instance, {
        noDataText: this.alertInventoryMrcChargesGridService.noDataMessage
      });
    }
    super.ngAfterViewInit();
  }

  sortColumn(sorting) {
    this.query['orderBy'] = sorting;

    this.loadInventoryMRCs(this.query);
  }

  ;

  back(): void {
    if (document.referrer.indexOf(window.location.host) >= 0) {
      history.back();
    }
    else {
      this.router.navigate(['/alert']);
    }
  }

  csv() {
    const { alertInventoryMrcGridService, query, inventoryService, alertService, alertId, alert } = this;
    alertInventoryMrcGridService.csvMap().subscribe(fields => {

      const alertInventoryCSVTransform = (items) => {
        return items.map(item => {
          const { id } = item;
          const details = alert.inventory_details.find(detail => id === detail.inventory_id);

          if (details) {
            item.message = details.message;
          }

          return item;
        });
      }

      inventoryService.exportToCSV(
        'alert-inventories-mrc',
        {
          fields,
          query: query,
          fetchDataHandler: csvQuery => {
            return alertService.findInventoryMRCs(alertId, csvQuery);
          },
          middleware: [alertInventoryCSVTransform],
        }
      );

    });
  }

  toggleFilter() {
    this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
    this.tabGroup.forceRerender();
  }

  ngOnDestroy() {
    if (this.sider.getActiveSection().name === this.sideSections.filter) {
      this.toggleFilter()
    }
    this.destroy$.next(true);
    this.destroy$.complete();
    this.tabGroup.onActivate.unsubscribe();
  }

  public goToInvoiceDetails() {
    if (this.alert && this.alert.invoice && this.alert.invoice.invoice_id) {
      this.router.navigate(['/invoice', this.alert.invoice.invoice_id, 'show', 'overview']);
    }
  }


  getCalculatedCharges(value) {
    const result = []
    const totalVarianceTypes = groupDisputesBySpid(value);

    for(let key in totalVarianceTypes) {
      totalVarianceTypes[key].forEach(chargeList => {
        const totalChargeAmt = getTotalChargeAmountFromInvoice([chargeList]);
        result.push(...calculateDisputesFromTotalAmount([chargeList], totalChargeAmt))
      })
    }
    const sortedResult = sortCalculatedChargesByChargeId(result)
    return sortedResult.map(calculatedChargeAmount => calculatedChargeAmount.value)
  }

  getDispDesc(value) {
    let disputeDescriptionArray = []
    value.forEach(i => {
      const descString = `Charges billed do not equal inventory MRC.`;
      disputeDescriptionArray.push(descString);
    })
    return disputeDescriptionArray;
  }

  addDispute() {
    this.chargeQuery['where'] = {};
    this.chargeQuery.set('id', {
      $in: this.selectedCharges.map(x => {
        return x.id;
      })
    });

    this.chargeQuery.offset = 0;
    this.chargeQuery.page = 1;
    this.chargeQuery.limit = 10000;
    this.chargeQuery.set('invoice_id', this.alert.invoice_id);
    this.chargeService.filters(this.chargeQuery)
      .subscribe(async (result) => {
        this.charges = result.items;
        let arrayOfCalculatedCharges = this.getCalculatedCharges(this.selectedCharges);
        let invoice = this.alert.invoice;
        let dispDesc = this.getDispDesc(this.selectedCharges);
        if (!this.selectedCharges.length || this.addDisputeDisabled) {
          return;
        }

        this.dialogService
          .open(
            DisputeManageDialogComponent,
            {
              charges: this.charges,
              invoice: invoice,
              calculatedCharges: arrayOfCalculatedCharges,
              dispDesc: dispDesc,
              alertId: this.alertId
            },
            { width: '60%' }
          )
          .afterClosed()
          .subscribe(async result => {
            if (result) {
              this.selectedCharges.forEach(i => {
                i.charge_dispute_id = { dispute_id: result.id };
              });
              this.selectedCharges = [];
              this.addDisputeDisabled = true;
              this.loadData(this.alertId);
              this.toastAlertService.success(
                '',
                this.messages.get('DISPUTE_CREATE_SUCCESS')
              );
            }
          });
      })

  }

  showDispute(id: any) {
    this.router.navigate(['/dispute', id, 'show']);
  }

  loadAuditResult(query, audit) {
    this.delayChargesSelectionChange = true;
    this.loaderService.displayLoader(true);

    this.alertService.findChargesByMRCandSPID(this.alert.id, query)
      .subscribe((result) => {
        audit.charges = result.rows;
        audit.resultQuery.total = result.count;
        this.loaderService.hideLoader(true);
        setTimeout(() => {
          this.selectRowsWithinDetailsGrid(audit.id)
        }, 100)
      })
  }

  loadSelectedAuditResults(rule) {
    if (rule.selectionActive) {
      if (this.queryInventoryMrcFilter.where.id)
        rule.resultQuery.where.id = { ...this.queryInventoryMrcFilter.where.id }
      else
        rule.resultQuery.remove('id')
    } else {
      rule.resultQuery.set('id', {
        $in: rule.selectedRows
      });
    }
    rule.resultQuery.offset = 0;
    rule.resultQuery.page = 1;
    rule.selectionActive = !rule.selectionActive;
    this.loadAuditResult(rule.resultQuery, rule)
  }

  findIndexInResultCharges(chargeId, rule): number {
    for (let i = 0, l = rule.selectedRows.length; i < l; i++) {
      if (rule.selectedRows[i] === chargeId) {
        return i;
      }
    }
    return -1;
  }

  onChargesSelectionChanged(event, audit) {
    setTimeout(() => {
      if (!this.delayChargesSelectionChange && audit.charges.length) {
        /** Selected rows */
        this.selectedAlertId = audit.id;
        if (event.selectedRowKeys.length) {
          event.selectedRowsData.forEach(item => {
            item['variance'] = audit.variance;
            item['billed_mrc'] = audit.billed_mrc;
            let index = findIndexInSelectedCharges(item.id, this.selectedCharges);
            if (index === -1) {
              item['rule_id'] = audit.id // rule_id needed for dataGrid pager / charges selection
              this.selectedCharges.push(item);
            }
            let resultIndex = this.findIndexInResultCharges(item.id, audit);
            if (resultIndex === -1 && !audit.selectedRows.includes(item.id)) {
              audit.selectedRows.push(item.id);
            }
          });
        }
        /** Deselected rows */
        if (event.currentDeselectedRowKeys.length) {
          event.currentDeselectedRowKeys.forEach(id => {
            let index = findIndexInSelectedCharges(id, this.selectedCharges);
            if (index > -1) {
              this.selectedCharges.splice(index, 1);
            }
            let resultIndex = this.findIndexInResultCharges(id, audit);
            if (resultIndex >= 0) {
              audit.selectedRows = [...audit.selectedRows.filter(rowId => id !== rowId)]
            }
          });
        }
      }
      /** Dispute Add enabled/disabled */
      if (this.selectedCharges.length) {
        this.addDisputeDisabled = false;
        this.selectedCharges.forEach(item => {
          if (item.charge_dispute_id || (+item.variance) <= 0) {
            this.addDisputeDisabled = true;
          }
        });
      } else {
        this.addDisputeDisabled = true;
        this.selectedAlertId = null;
      }
      this.delayChargesSelectionChange = false;
    })
  }

  onChargesDataGridClick(event) {
    if (!event.target.classList.contains('selection-badge')) {
      this.delayChargesSelectionChange = false;
    }
  }

  selectRowsWithinDetailsGrid(ruleId) {
    this.delayChargesSelectionChange = true
    const rule = this.audits.find(item => item.id === ruleId)
    if (rule && rule.charges && rule.charges.length) {
      this.dataGrids.forEach(grid => {
        const id = grid['element'].nativeElement.id
        if (id === 'charges-grid-' + rule.id) {
          let results = []
          rule.charges.forEach(chg => {
            if (this.selectedCharges.length && this.selectedCharges.reduce((acc, item) => ([...acc, item.id]), []).includes(chg.id))
              results.push(chg.id)
          })
          if (results.length)
            grid.instance.selectRows(results, false);
        }
      })
    }
  }
}
