export const scvOrderServiceFilterContext = {
  filters: [
    {
      field: 'description',
      label: 'Description',
      type: 'string',
      index: 1
    },
    {
      field: 'technology.value',
      label: 'Service Type Tech',
      type: 'lookup',
      lookupProviderName: 'circuitTech',
      index: 2
    },
    {
      field: 'inventory_ckt_type.value',
      label: 'Svc Type',
      type: 'lookup',
      lookupProviderName: 'circuitType',
      index: 3
    },
    {
      field: 'rate_term_date',
      label: 'Rate Term Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 4
    },
    {
      field: 'rate_eff_date',
      label: 'Rate Eff Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 4
    },
    {
      field: 'spid',
      label: 'SPID',
      type: 'string',
      index: 5
    },
    {
      field: 'int_bandwidth.value',
      label: 'Int Bandwidth',
      type: 'lookup',
      lookupProviderName: 'int_bandwidth',
      index: 6
    },
    {
      field: 'ckt_bandwidth.value',
      label: 'Svc Bandwidth',
      type: 'lookup',
      lookupProviderName: 'bandwidth',
      index: 6
    },
    {
      field: 'port_bandwidth.value',
      label: 'Port Bandwidth',
      type: 'lookup',
      lookupProviderName: 'bandwidth',
      index: 7
    },
    {
      field: 'currency.currency',
      label: 'Currency',
      type: 'lookup',
      lookupProviderName: 'currency',
      index: 6
    },
    {
      field: 'term',
      label: 'Term',
      type: 'number',
      index: 7
    },
    {
      field: 'unit_of_measure_term.value',
      label: 'Term Qty UOM',
      type: 'lookup',
      lookupProviderName: 'term_unit_of_measure',
      index: 8
    },
    {
      field: 'service_qty',
      label: 'Service Qty',
      type: 'number',
      index: 10
    },
    {
      field: 'unit_of_measure_service.value',
      label: 'Service Qty UOM',
      type: 'lookup',
      lookupProviderName: 'qty_unit_of_measure',
      index: 11
    },
    {
      field: 'mrc',
      label: 'MRC',
      type: 'negativeNumber',
      index: 13
    },
    {
      field: 'nrc',
      label: 'NRC',
      type: 'negativeNumber',
      index: 14
    },
    {
      field: 'location_a.site_id',
      label: 'Location A',
      type: 'lookup',
      lookupProviderName: 'site',
      secondRowField: 'location_a.building.address.search_address',
      index: 15
    },
    {
      field: 'location_z.site_id',
      label: 'Location Z',
      type: 'lookup',
      lookupProviderName: 'site',
      secondRowField: 'location_z.building.address.search_address',
      index: 16
    },
    {
      field: 'at_exp_event.value',
      label: 'At Expiration Event',
      type: 'lookup',
      lookupProviderName: 'at_exp_event',
      index: 0
    },
    {
      field: 'cancel_notice_pd',
      label: 'Cancel Notice (Days)',
      type: 'number',
      index: 0
    },
    {
      field: 'cancel_notice_dt',
      label: 'Cancel Notice Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 0
    },
    {
      field: 'service_details',
      label: 'Service Details',
      type: 'string',
      index: 4
    },
  ]
};
