import { ViewChild, AfterViewInit, Directive } from "@angular/core";
import {PageContext} from "./page.context";
import {HistoryComponent} from "../shared/history/history.component";
import {Sider, SiderSection, SiderSettings} from "./sider/sider";
import { Subject } from "rxjs";
import { GridService } from '../shared/grid/grid.service';

// @ts-ignore
@Directive({
  selector: 'page-details'
})
export class PageDetailsComponent implements AfterViewInit {

  @ViewChild('history') history: HistoryComponent;
  @ViewChild('panelMore') panelMore: any = '';
  @ViewChild('panelMoreTabs') panelMoreTabs: any;

  sider: Sider = new Sider(new SiderSettings([], this.panelMore));
  public settings: any;
  public defaultGridPager: number;

  SECTIONS = {
    FILTER_SECTION_NAME: "filter",
    NOTES_SECTION_NAME: "notes",
    HISTORY_SECTION_NAME: "history",
    DETAILS_SECTION_NAME: "details"
  };

  destroy$: Subject<boolean> = new Subject<boolean>();
  columns: Array<any>;
  sorting: any;
  query: any;
  dataGrid: any;

  constructor(public context: PageContext) {
    this.settings = this.context.loadSettings();
  }

  ngAfterViewInit(): void {
    this.sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME)
    ], this.panelMore))
      .onChange((section: SiderSection) => {
        this.saveSettings({siderSection: section.name});
      });

    this.applySettings();
  }

  public applySettings() {
    let sideSectionName = this.settings['siderSection'];
    if (sideSectionName) {
      this.sider.toggle(sideSectionName);
    }
  }

  resource(action) {
    return [this.context.resource, action].join(':')
  }

  panelClose() {

  }

  saveSettings(data) {
    this.context.settings.save(this.context.name, data);
  }

  public resetDataPager() {
    this.defaultGridPager = 20;
  }

  // override this in child class
  loadData(query) {
  }

  getDefaultSort(): any {
    return [];
  }

  getGridService(): GridService {
    throw new Error('getGridService not implemented');
  }


  protected prepareList() {
    const gridService = this.getGridService();

    gridService.loadSettings()
      .subscribe((settings) => {
        this.sorting = settings ? settings.sorting : [];
        this.columns = gridService.getColumns(settings ? settings.columns : []);
        this.defaultGridPager = settings ? settings.gridPager : 20;
        this.query.limit = this.defaultGridPager;

        if (this.sorting && this.sorting.length) {
          this.query.orderBy = gridService.getOrderBy(this.sorting);
        }

        if (this.dataGrid && this.dataGrid.instance) {
          this.dataGrid.instance.option('columns', this.columns);
        }

        this.loadData(this.query);
      })
  }

  _init() {
    this.prepareList();
  }

  public showDetails(data: object): void {
    // Override in child components
  };

  public onRowClick(row) {
    let component = row.component;
    let prevClickTime = component.lastClickTime;

    component.lastClickTime = new Date();

    if (prevClickTime && (component.lastClickTime - prevClickTime < 250) && row.data) {
      this.showDetails(row.data);
    } else {
      setTimeout(() => {
      }, 300);
    }
  }
}
