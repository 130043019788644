import * as moment from "moment";

export const reconAccrualsFilterContext = {
  filters: [
    {
      field: 'unique_id',
      label: 'ID',
      type: 'string',
      index: 1
    },
    {
      field: 'subc.value',
      label: 'Subclient',
      type: 'string',
      index: 1
    },
    // {
    //   field: 'name',
    //   label: 'Vendor',
    //   type: 'lookup',
    //   lookupProviderName: 'vendor',
    //   index: 2,
    //   visible: true
    // },
    {
      field: 'site_a.site_id',
      label: 'Site A',
      type: 'lookup',
      lookupProviderName: 'site',
      index: 3,
      visible: true
    },
    {
      field: 'site_z.site_id',
      label: 'Site Z',
      type: 'lookup',
      lookupProviderName: 'site',
      index: 4,
      visible: true
    },
    {
      field: 'install_date',
      label: 'Install Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      defaultValue:[7],
      index: 1,
      visible: true
    },
    {
      field: 'est_mrc',
      label: 'MRC',
      type: 'negativeNumber',
      index: 6
    },
    {
      field: 'est_nrc',
      label: 'NRC',
      type: 'negativeNumber',
      index: 7
    },
    {
      field: 'currency',
      label: 'Currency',
      type: 'lookup',
      lookupProviderName: 'currency',
      index: 8,
    },
    {
      field: 'vendor_and_account',
      vendorFields: {
        vendor: {label: 'Vendor', field: 'v.name'},
        account: {label: 'Account', field: 'ac.account_no'},
      },
      label: 'Vendor / Account',
      editor: 'vendorAccountAllFilter',
      expanded: true,
      index: 1
    },

    // {
    //   field: 'ban',
    //   label: 'Account',
    //   type: 'lookup',
    //   lookupProviderName: 'account',
    //   index: 8,
    // }
  ]
};
