import { Injectable } from "@angular/core";
import { AccountLookupProvider } from "../../account/core/account-lookup.provider";
import { AccountService } from "../../account/core/account.service";
import { InvoiceChargeService } from "../../charge/core/invoice-charge.service";
import { FilterService } from "../../core/filter/filter.service";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { InvoiceFacepageService } from "../../invoice/core/invoice-facepage.service";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { VendorLookupProvider } from "../../vendor/core/vendor-lookup.provider";
import { VendorService } from "../../vendor/core/vendor.service";
import { alertBatchInvoicesFilterContext } from "./alert-batch-invoices-filter.context";
import { DefaultDateRangesLookupProvider } from "app/core/filter/default-date-ranges-lookup.provider";

@Injectable()
export class AlertBatchInvoicesFilterService extends FilterService {

  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public userSettingsService: UserSettingsService,
              public chargeService: InvoiceChargeService,
              public dictionaryService: DictionaryService,
              public invoiceService: InvoiceFacepageService,
              public vendorService: VendorService,
              public accountService: AccountService) {
    super("app.cost.invoice-charge-filter", alertBatchInvoicesFilterContext, userSettingsService);

    this.addLookupProvider('alertType', new LookupModelLookupProvider(dictionaryService,
      LOOKUP_MODELS_ENUM.ALERT_TYPE.modelName));
    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('account', new AccountLookupProvider(accountService));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
