import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

import {UserSettingsService} from "../../../user/core/user-settings.service";
import {HistoryService} from "../../../shared/history/history.service";
import {HistoryComponent} from "../../../shared/history/history.component";
import {AlertService} from '../../../shared/alert/alert.service';
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";

import {ContractService} from '../../core/contract.service';
import {Contract} from '../../core/contract';
import {DocumentQuery} from "../../core/contract-document.query";
import {DocumentFilterService} from "../../../shared/document/core/document-filter.service";

@Component({
    selector: 'app-contract-details-attachments',
    templateUrl: './contract-details-attachments.component.html',
    styleUrls: ['./contract-details-attachments.component.scss']
  })
  export class ContractAttachmentsComponent extends PageDetailsComponent implements OnInit, AfterViewInit {

    @ViewChild('panelSide') panelSide;
    @ViewChild('history') history: HistoryComponent;
    @ViewChild('testNotes') testNotes;
    @ViewChild('documentsManager') documentsManager;
    readonly CONTRACT_DOCUMENT_FOLDER_NAME: string = 'contracts';

    public contract: Contract;
    public includeTypes: Array<number> = [];

    readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
    selection: any;
    public documentQuery = new DocumentQuery();

    DOCUMENT_INFO = {
        FOLDER: 'contracts',
        SUBFOLDER: '',
        PARENT_TYPE: this.SYSTEM_MODULE.CONTRACT,
        ENTITY_TYPE: this.SYSTEM_MODULE.CONTRACT
      };
    public notesCount: number = 0;

    constructor(
        public contractService: ContractService,
        private readonly route: ActivatedRoute,
        public settingsService: UserSettingsService,
        public documentFilterService: DocumentFilterService,
        public alert: AlertService,
        public historyService: HistoryService
    ) {
    super(new PageContext({
        name: 'app.contract.contract-details-attachments',
        settings: settingsService
    }));

    }

    ngOnInit(): void {
        this.contract = this.route.parent.snapshot.data.contract;
        this.includeTypes = this.contract.document ? [this.contract.document.type_id, LOOKUP_ENUM.DOCUMENT_TYPE.AMENDMENT, LOOKUP_ENUM.DOCUMENT_TYPE.SUPPORTING_DOCUMENT] : null;
        this.DOCUMENT_INFO.SUBFOLDER = `${this.contract.vendor.id}/${this.contract.id}/`;
    }

    docSelected(doc: Document) {
        this.selection = doc;
    }

    public delete() {
      this.documentsManager.deleteEntityDocument(this.selection);
    }

    upload() {
      this.documentsManager.addEntityDocument(this.contract);
    }

    download() {
      this.contractService.downloadDocument(this.selection.id,this.contract.id,this.CONTRACT_DOCUMENT_FOLDER_NAME)
      .subscribe((response) => {
        let objectUrl = URL.createObjectURL(response);

        let save = document.createElement('a');
        save.href = objectUrl;
        save.target = '_blank';
        save.download = `${this.selection.document_name}`;
        let event = document.createEvent('MouseEvents');
        event.initMouseEvent(
          'click',
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        save.dispatchEvent(event);
      });
    }

    filterDocumentData(documentQuery) {
      this.documentsManager.loadData(documentQuery);
    }
  
    clearDocumentFilter(documentQuery) {
      this.documentsManager.loadData(documentQuery);
    }

    onNotesCountChanged(count: number) {
      this.notesCount = count;
    }

    toggleFilter() {
      this.toggleSider(this.SECTIONS.FILTER_SECTION_NAME);
    }
  
    toggleNotes() {
      this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME);
    }
  
    toggleHistory() {
      this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME);
    }
  
    toggleSider(sectionName: string) {
      this.sider.toggle(sectionName);
    }
  }