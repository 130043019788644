import {Injectable} from '@angular/core';

import {Flow} from './flow';
import {FlowStep} from './flow-step';

@Injectable()
export class FlowService {
  flow: Flow = new Flow();

  constructor() {
  }

  create(data?: any): Flow {
    return new Flow(data);
  }

  updateStep(code: any, name: string, className?: string, icon?: string) {
    let step = this.findStep(code);
    if (step) {
      step.name = name;
      step.className = className;
      step.icon = icon;
    }
  }

  updateStepByKey(code: any, name: string, key:string, className?: string, icon?: string) {
    let step = this.findStepByKey(key);
    if (step) {
      step.name = name;
      step.className = className;
      step.icon = icon;
    }
  }

  findStep(code: any) {
    return this.flow.steps.filter((step) => {
      return step.code === code;
    })[0]
  }

  findStepByKey(key: any) {
    return this.flow.steps.filter((step) => {
      return step.key === key;
    })[0]
  }

  replaceStep(step: FlowStep) {
    const stepIndex = this.flow.steps.findIndex(flowItem => flowItem.code === step.code);
    if (stepIndex >= 0) {
      this.flow.steps[stepIndex] = step;
    }
  }
}
