import {Component, OnInit , Input } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {PageManageDialogComponent} from "../../../core/page-manage-dialog.component";
import { MatDialogRef } from "@angular/material/dialog";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {LOOKUP_MODELS_ENUM} from "../../../dictionary/core/lookup-models.enum";
import {DictionaryService} from "../../../dictionary/core/dictionary.service";
import {ContactFormService} from "../../core/contact-form.service";
import {UserService} from "../../../user/core/user.service";
import { User } from '../../../user/core/user';
import {ValidationService} from "../../../shared/validation/validation.service";
import {LocationService} from "../../../location/core/location.service";
import Query from "../../../core/query/query";
import {CurrencyPipe, DecimalPipe} from "@angular/common";


@Component({
  selector: 'app-single-contact-manage-dialog',
  templateUrl: './single-contact-manage-dialog.component.html',
  styleUrls: ['./single-contact-manage-dialog.component.css']
})
export class SingleContactManageDialogComponent extends PageManageDialogComponent implements OnInit  {
  readonly CONTACT_TYPE_ENUM: any = LOOKUP_ENUM.CONTACT_TYPE;
  readonly CONTACT_FUNCTION_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.CONTACT_FUNCTION.modelName;
  public form: FormGroup;
  // contact functions used in UI which are mutated
  contactFunctionsUI: Array<any> = [];
  @Input() contactType = [];
  formTitle: any;
  contact: any;
  data: any;
  public control;
  @Input('formControlName') formControlName;
  @Input('initialItem') initialItem;
  public contactFunctionId : any;
  public showAll : boolean;

  phoneRegexMask = '(000) 000-0000';
  userDate: User;
  readonly VALIDATION = ValidationService.MESSAGES;
  allCountries: Array<any> = [];
  query = new Query();
  selectedCountryId: any;
  selectedCountry: any;

  constructor(
    public formBuilder: FormBuilder,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<SingleContactManageDialogComponent>,
    public dictionaryService: DictionaryService,
    public contactFormService: ContactFormService,
    public locationService: LocationService,
    private userService: UserService

  ) {
    super(dialogService, dialogRef);
  }

  ngOnInit() {
    this.loadCountries();
    this.initContactFunctionsUI();
    this.setTitle();
    this.userService.me().subscribe(data => {
      this.userDate = data
      this.initForm();
    });
  }

  onSubmit() {
    const data = this.form.getRawValue();
    this.dialogRef.close(data);

  }

  private initForm() {
    const {country: {phone_regex, phone_regex_mask}} = this.userDate;
    if(phone_regex_mask) {
      this.phoneRegexMask = phone_regex_mask
    }
    if (typeof(this.data) !== 'undefined') {
      this.form = this.contactFormService.getFormWithData(this.data, phone_regex);
    } else {
      this.form = this.contactFormService.getEmptyForm(phone_regex);
    }
    // this.form = this.contactFormService.getEmptyForm();
  }

  onSelectionChange(event: any) {
    const selection = event.selection;
    if (selection) {
      this.form.controls['id'].setValue(selection.id);
      this.form.controls['first_name'].setValue(selection.first_name);
      this.form.controls['last_name'].setValue(selection.last_name);
      this.form.controls['email'].setValue(selection.email);
      this.form.controls['mobile_number'].setValue(selection.mobile_number);
      this.form.controls['country_id'].setValue(selection.address.country_id);
    }
  }

  private setTitle() {
    if (typeof(this.data) == 'undefined') {
      this.formTitle = 'Add Contact';
    } else {
      this.formTitle = 'Edit Contact';
    }
  }

  private initContactFunctionsUI() {
    this.dictionaryService
      .getByLookup(this.CONTACT_FUNCTION_LOOKUP_MODEL)
      .subscribe(result => {
        this.contactFunctionsUI = result.items;
      });
  }

  contactFunctionChange($event) {
    this.contactFunctionId = this.form.value.contact_function
    if(this.contactFunctionId){
      this.showAll = true
    }
  }
  toggleShowAllContacts(showAll: any) {
    this.showAll = !showAll;
    if(!this.showAll){
      this.contactFunctionId = null
    }else {
      this.contactFunctionId = this.form.value.contact_function
    }
  }

  loadCountries() {
    const cloned = this.query.clone();
    cloned.limit = 1000;
    cloned.orderBy = [['name', 'ASC']];
    if (!cloned.where.is_enabled) {
      cloned.where = {is_enabled: true};
    }
    return this.locationService.findAll(cloned)
      .subscribe(result => {
        this.allCountries = result.items;
        if (this.form && !this.form.controls['country_id'].value) {
          this.form.controls['country_id'].setValue(this.getCountryIdFromAphaCode('US'));
        }
      })
  }

  getCountryIdFromAphaCode(value: any) {
    if (this.allCountries.length > 0) {
      this.allCountries.filter(country => {
        if (country.alpha_2_code === value) {
          return country.id;
        }
      });
    }

    return 1;
  }

  onCountryChange(event) {
    this.selectedCountryId = event.value;
    this.locationService.findCountryById(this.selectedCountryId)
      .subscribe(result => {
        this.phoneRegexMask = result.phone_regex_mask;
        this.selectedCountry = result;
        this.form.get('mobile_number').markAsTouched();
      })
  }
}
