import {Injectable} from '@angular/core';
import {GridService} from "../../shared/grid/grid.service";
import {FormatService} from "../../shared/format/format.service";
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class AlertInventoryMrcChargesGridService extends GridService {

  /*
  * chg_class
chg_desc_1
chg_desc_2
chg_qty_billed
chg_rate
chg_amt
  * */
  defaultColumns = [
    {
      caption: '',
      headerCellTemplate: 'selectionBadgeHeaderTemplate',
      cellTemplate: 'chargeDisputeNoteTemplate',
      fixed: true,
      width: 30,
      allowSorting: false
    },
    {
      caption: "Charge ID",
      dataField: "id",
      width: 140,
      allowSorting: false
    },
    {
      caption: "Charge Description 1",
      dataField: "chg_desc_1",
      width: 200,
      allowSorting: false
    },
    {
      caption: "Charge Description 2",
      dataField: "chg_desc_2",
      width: 200,
      allowSorting: false
    },
    {
      alignment: 'right',
      caption: "Chg Qty",
      dataField: "chg_qty1_billed",
      dataType: 'number',
      width: 100,
      allowSorting: false
    },
    {
      caption: "Charge Rate",
      dataField: "chg_rate",
      cellTemplate: 'chargeRateTemplate',
      width: 100,
      allowSorting: false
    },
    {
      caption: "Charge Amount",
      dataField: "chg_amt",
      cellTemplate: "invoiceCurrencyTemplate",
      width: 170,
      allowSorting: false
    },
  ];

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.alert.inventory-mrc-charge-grid', userSettings);
  }
}
