import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class OrderServiceDateGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super('app.orders.order-service-foc-date-grid', userSettings);
  }

  columns() {
    return [{
      caption: 'FOC Date',
      dataField: 'foc_date',
      dataType: 'date',
      // format: this.formatService.date,
      cellTemplate: 'dateTemplate',
      width: 110
    },
    {
      caption: 'FOC Rec Date',
      dataField: 'foc_rec_date',
      dataType: 'datetime',
      // format: this.formatService.date,
      cellTemplate: 'dateTemplate',
      width: 110
    },
    {
      caption: 'Changed By',
      dataField: 'changed_by',
      width: 150
    },
    {
      caption: 'Reason',
      dataField: 'reason',
      width: 170
    },
    {
      caption: 'Changed At',
      dataField: 'changed_at',
      dataType: 'datetime',
      cellTemplate: "changedDateCellTemplate",
      width: 180
    },
    {
      caption: 'Note',
      dataField: 'note',
      width: 200
    }];
  }
}
