import { Injectable } from '@angular/core';
import { FilterService } from '../../core/filter/filter.service';
import { vendorRemitAddressFilterContext } from './vendor-remit-address-filter.context';
import { LocationService } from '../../location/core/location.service';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {SubdivisionLookupProvider} from '../../location/core/subdivision-lookup.provider';
import {VendorLookupProvider} from '../../vendor/core/vendor-lookup.provider';
import {VendorService} from '../../vendor/core/vendor.service';
import {CountryLookupProvider} from "../../location/core/country-lookup.provider";

@Injectable()
export class VendorRemitAddressFilterService extends FilterService{

  constructor(public locationService: LocationService,
              public userSettingsService: UserSettingsService,
              public vendorService: VendorService) {
    super('app.vendor.vendor-remit-address-filter', vendorRemitAddressFilterContext, userSettingsService);
    this.addLookupProvider('subdivision', new SubdivisionLookupProvider(locationService, true));
    this.addLookupProvider('country', new CountryLookupProvider(locationService, true));
    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true))
  }
}
