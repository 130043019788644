import {Injectable} from "@angular/core";
import {FilterService} from "../../core/filter/filter.service";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {DictionaryService} from "../../dictionary/core/dictionary.service";
import {LocationService} from "../../location/core/location.service";
import {ContractService} from "../../contract/core/contract.service";
import {ConfigService} from "../../core/config/config.service";
import {RateQtyUomLookupProvider} from "../../contract/core/rate-qty-uom.provider";
import {RateCategoryLookupProvider} from "../../contract/core/rate-category.provider";
import {RateTypeLookupProvider} from "../../contract/core/rate-type.provider";
import {CurrencyLookupProvider} from "../../location/core/currency-lookup.provider";
import {StateLookupProvider} from "../../location/core/state-lookup.provider";
import {CountryLookupProvider} from "../../location/core/country-lookup.provider";
import {RateTermUomLookupProvider} from "../../contract/core/rate-term-unit-of-measure.provider";
import {ContractScheduleLookupProvider} from "../../contract/core/contract-schedule-lookup.provider";
import {inventoryRateFilterContext} from "./inventory-rate-filter.context";

@Injectable()
export class InventoryRateFilterService extends FilterService {


  constructor(public userSettingsService: UserSettingsService,
              public dictionaryService: DictionaryService,
              public locationService: LocationService,
              public contractService: ContractService,
              public configService: ConfigService
  ) {
    super("app.inventory-rate-filter", inventoryRateFilterContext, userSettingsService);

    this.addLookupProvider('rateQtyUom', new RateQtyUomLookupProvider(dictionaryService));
    this.addLookupProvider('rateCategory', new RateCategoryLookupProvider(dictionaryService));
    this.addLookupProvider('rateType', new RateTypeLookupProvider(dictionaryService));
    this.addLookupProvider('currency', new CurrencyLookupProvider(locationService));
    this.addLookupProvider('state', new StateLookupProvider(locationService));
    this.addLookupProvider('country', new CountryLookupProvider(locationService, true));
    this.addLookupProvider('rateTermUom', new RateTermUomLookupProvider(dictionaryService));
    this.addLookupProvider('contractSchedule', new ContractScheduleLookupProvider(contractService));

  }
}
