import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';
import {UpdateInvoiceSubAccountsQuery, UpdateInvoiceSubAccountsSiderSection,} from './invoice-sub-accounts.actions';
import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";

const INVOICE_SUB_ACCOUNTS_STATE_TOKEN = new StateToken<InvoiceSubAccountsStateModel>('invoice_sub_accounts');

export interface InvoiceSubAccountsStateModel extends QueryableStateModel<any> {

}

@State<InvoiceSubAccountsStateModel>({
  name: INVOICE_SUB_ACCOUNTS_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InvoiceSubAccountsState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  @Action(UpdateInvoiceSubAccountsQuery)
  // @ts-ignore
  updateInvoiceSubAccountsQuery(ctx: StateContext<InvoiceSubAccountsStateModel>, {options}): void {
    return this.updateQuery(ctx, {options});
  }

  @Action(UpdateInvoiceSubAccountsSiderSection)
  // @ts-ignore
  updateInvoiceSubAccountsSiderSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
