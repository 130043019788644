import {Injectable} from '@angular/core';

import { DialogService } from '../../shared/dialog/dialog.service';
import { AlertPreGLExclusionDialogComponent } from '../shared/alert-pre-gl-exclusion-dialog/alert-pre-gl-exclusion-dialog.component';

@Injectable()
export class AlertPreGLValidationService {

    constructor(public dialogService: DialogService){}

    openConfirmationDialog({items, onExclusionConfirmed, actionText, title, config}) {
        items = Array.isArray(items) ? items : [items];
        return this.dialogService.open(AlertPreGLExclusionDialogComponent, {
          items,
          actionText,
          title,
          onExclusionConfirmed,
          config
        }, {
          width: !config ? '700px' : '600px',
    
        });
      }
}