import { User } from 'app/user/core/user';

export class Notes {
  id: number;
  user_id: number;
  user: User;
  created_at: Date;
  parent_id: number;
  entity_id: number;
  entity_type: any;
  master_id: number;
  master_type: any;
  content: string;
  reply: string;
  // Obsolete
  charges: any;
  note_id: any;
  info_charges: any;
  entities: any[];
  group_id: number;
  group_type: number;
  entity_meta: any;
  note_charges_copy: any[];
  note: any;
  notes: any[];
  message:string;
  dnpCode:string;
  dnpResonCodeId:number;
  invoiceId:number;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
