<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{formTitle}}</h1>
</div>
<div mat-dialog-content class="ca-dialog-content app-order-service-manage-dialog" style="height: 500px;" *ngIf="form">

  <div class="ca-tab tabbed-content padding-bottom">
    <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="activeTabIndex">
      <mat-tab [label]="tab.title" [disabled]="tab.disabled" *ngFor="let tab of tabGroup.tabs">
      </mat-tab>
    </mat-tab-group>
  </div>


  <div [ngClass]="{'hidden': !tabGroup.isActive(1)}" class="tabs-body fixed">
    <form [formGroup]="form" class="ca-form dialog-content-no-margin" style="display: flex;">
      <div style="flex: 1 1 50%;">
        <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
          <div class="flex-item" fxFlex>
            <div formGroupName="inventory">
              <div class="flex-container" fxLayout="row">
                <div class="flex-item caGap caGapRight" fxFlex="45%">
                  <mat-form-field caInput>
                    <input matInput type="text" placeholder="SP Service ID" formControlName="sp_ckt_id" [required]="isUpdate">
                    <mat-hint
                      *ngIf="form.get(['inventory', 'sp_ckt_id']).hasError('required') && form.get(['inventory', 'sp_ckt_id']).touched">
                      {{VALIDATION.REQUIRED_ERROR }}
                    </mat-hint>
                    <mat-hint
                      *ngIf="form.get(['inventory', 'sp_ckt_id']).hasError('pattern') && form.get(['inventory', 'sp_ckt_id']).touched">
                      {{INVALID_SP_CKT_ID_MSG }}
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="flex-item caGap" fxFlex>
                  <ca-lookup-model-picker
                    caInput
                    [pickerForm]="form.controls['inventory']"
                    [disabled]="viewOnly"
                    [lookupModel]="SUBCLIENT_LOOKUP_MODEL"
                    formControlName="subclient_id"
                    [required]="true"
                    clearEnabled="true"
                    placeholder="Subclient"
                    [initialItem]="orderService?.inventory?.account?.subclient?.id ? orderService?.inventory?.account?.subclient?.id : null"
                    [customOption] = "{id: -1, value: 'Unassigned'}"
                    [account]="selectedBan">
                  </ca-lookup-model-picker>
                </div>
              </div>
            </div>
            <div class="flex-container" fxLayout="row">
              <div class="flex-item caGap caGapRight" fxFlex="45%">
                <ca-date-picker caInput
                                placeholder="Desired Due Date"
                                required="true"
                                formControlName="des_due_date"
                                [disabled]="passedRfa"></ca-date-picker>
              </div>
              <div formGroupName="inventory" class="flex-container" fxLayout="row" fxFlex>
                <div class="flex-item caGap" fxFlex>
                  <ca-account-picker
                    caInput
                    placeholder="Billing Account"
                    formControlName="ban"
                    [vendorId]="order?.vendor?.id"
                    [initialItem]="orderService?.inventory?.account || null"
                    [initialItemId]="orderService?.inventory?.ban || null"
                    name="ban"
                    [required]="true"
                    clearEnabled="true"
                    [pickerForm]="form.controls['inventory']"
                    [approveTemp]="!this.isUpdate"
                    [tempBanName] = "tempBanName"
                    [subclient_id]="selectedSubclientId"
                    (onSelectionChange)="onAccountChange($event)"
                    [account]="selectedBan"
                    >
                  </ca-account-picker>
                </div>
              </div>
            </div>
            <div class="flex-container" fxLayout="row">
              <div formGroupName="inventory" class="flex-container" fxLayout="row" fxFlex>
                <div class="flex-item caGap caGapRight" fxFlex="45%">
                  <ca-customer-picker
                    caInput
                    formControlName="customer_id"
                    placeholder="Customer"
                    [searchEnabled]="true"
                    [clearEnabled]="true"
                    [initialItem]="orderService?.inventory?.customer"
                    [pickerForm]="form.controls.inventory"
                    [disabled]="passedRfa">
                  </ca-customer-picker>
                </div>
                <div class="flex-item caGap" fxFlex>
                  <ca-customer-inventory-picker
                    caInput
                    formControlName="customer_circuit_id"
                    placeholder="Customer Service ID"
                    [pickerForm]="form.controls.inventory"
                    [initialItem]="orderService?.inventory?.customer_circuit_id"
                    [clearEnabled]="true"
                    [disabled]="passedRfa">
                  </ca-customer-inventory-picker>
                </div>
              </div>
            </div>
              <div class="flex-container" fxLayout="row">
                <div class="flex-item caGap caGapRight" fxFlex="35%">
                  <mat-form-field caInput>
                    <input matInput placeholder="Service Order No" type="text" formControlName="order_number">
                  </mat-form-field>
                </div>
                <div class="flex-item caGap" fxFlex="35%">
                  <mat-form-field caInput>
                    <input matInput placeholder="Purchase Order No" type="text" formControlName="purchase_number">
                  </mat-form-field>
                </div>
                <div class="flex-item caGap" fxFlex fxLayout fxLayoutAlign="center center">
                  <mat-checkbox caInput placeholder="Expedite" [disabled]="passedRfa" formControlName="expedited_order">
                    Expedite
                  </mat-checkbox>
                </div>
              </div>

            <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
              <div class="flex-item caGap" fxFlex>
                <mat-form-field caInput>
                  <textarea matInput type="text" placeholder="Description" formControlName="description" style="height: 39px"
                            [disabled]="passedRfa"></textarea>
                </mat-form-field>
              </div>
              </div>
          </div>
        </div>
    </div>
      <!-- Custom Fields -->
      <div class="caGap caGapLeft" style="flex: 1 1 50%; justify-content: flex-end;">
        <mat-toolbar style="background-color: #303E4D !important;" color="accent">
          <div class="order-custom-fields-box-header">CUSTOM ATTRIBUTES</div>
          <button mat-icon-button type="button" [ngClass]="{'on' : onlyRequiredCustomFields}" (click)="toggleReqFields()" [disabled]="categories && categories.length === 0">
            <ca-icon [key]="'REQUIRED_FIELDS'" [tooltip]="'Required Fields'"></ca-icon>
          </button>
        </mat-toolbar>

        <div class="order-custom-fields-box" style="float: left">
          <div *ngIf="categories && categories.length === 0" class="order-custom-fields-no-data">No Data</div>
          <mat-accordion displayMode="flat" multi class="mat-table">
            <div [@.disabled]="disableAnimation" >
              <mat-expansion-panel *ngFor="let category of categories" [expanded]="false" #mep="matExpansionPanel"
                   class="custom-fields-expansion-panel" [ngClass]="{'expansion-panel-body-bottom-line': mep.expanded && category !== categories[categories.length-1]}">
                <mat-expansion-panel-header *ngIf="category" class="custom-fields-expansion-panel-header" [collapsedHeight]="'35px'" [expandedHeight]="'35px'">
                  {{category}}
                </mat-expansion-panel-header>
                <div *ngFor="let field of custom_fields" class="caGap caGapRight order-custom-fields">
                  <mat-form-field caInput="" *ngIf="field.type_id === 'number_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields) || (!onlyRequiredCustomFields))"
                                  [style.width.px]="field.width">
                    <input matInput
                           [placeholder]="field.placeholder"
                           type="number"
                           [formControlName]="field.field_name"
                           [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                           [minlength]="field.min_value"
                           [maxlength]="field.max_value"
                           rows="1">
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'text_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields) || (!onlyRequiredCustomFields))"
                                  [style.width.px]="field.width">
                    <input matInput
                           [placeholder]="field.placeholder"
                           type="text"
                           [formControlName]="field.field_name"
                           [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                           [minlength]="field.min_value"
                           [maxlength]="field.max_value"
                           rows="1">
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'textarea_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields) || (!onlyRequiredCustomFields))"
                                  [style.width.px]="field.width">
                      <textarea matInput
                                [placeholder]="field.placeholder"
                                type="text"
                                [formControlName]="field.field_name"
                                [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                [minlength]="field.min_value"
                                [maxlength]="field.max_value"
                                rows="1">
                      </textarea>
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'email' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields) || (!onlyRequiredCustomFields))"
                                  [style.width.px]="field.width">
                    <input matInput
                           [placeholder]="field.placeholder"
                           [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                           class="form-control"
                           type="email"
                           [formControlName]="field.field_name"
                           [pattern]="field.pattern">
                    <mat-hint *ngIf="(form.hasError('pattern', [field.field_name]) || this.form.get(field.field_name).invalid) && form.controls[field.field_name].touched"
                              class="error-class">
                      {{VALIDATION.EMAIL_ERROR}}
                    </mat-hint>
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'custom_dropdown' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields) || (!onlyRequiredCustomFields))"
                                  [style.width.px]="field.width">
                    <mat-select
                        [placeholder]="field.placeholder" [formControlName]="field.field_name"
                        [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)">
                      <mat-option *ngFor="let obj of field.dropdown_values" [value]="obj.label">
                        {{obj.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <ca-lookup-model-picker *ngIf="field.type_id === 'lookup_dropdown' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields) || (!onlyRequiredCustomFields))"
                                          [style.width.px]="field.width"
                                          clearEnabled="true"
                                          caInput
                                          #lookupPicker
                                          [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                          [searchEnabled]="false"
                                          [lookupModel]="field.lookup_table"
                                          name="lookup_id"
                                          [formControlName]="field.field_name"
                                          [pickerForm]="form"
                                          [placeholder]="field.placeholder">
                  </ca-lookup-model-picker>

                  <div [style.width.px]="field.width">
                    <ca-date-picker *ngIf="field.type_id === 'date' && field.category === category &&
                    ((field.is_required && onlyRequiredCustomFields) ||
                    (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields) || (!onlyRequiredCustomFields))"
                                    caInput
                                    [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                    [minDate]="field.min_value"
                                    [maxDate]="field.max_value"
                                    [placeholder]="field.placeholder"
                                    [formControlName]="field.field_name">
                    </ca-date-picker>
                  </div>

                </div>
              </mat-expansion-panel>

            </div>

          </mat-accordion>
        </div>
      </div>

    </form>
  </div>

  <div [ngClass]="{'hidden': !tabGroup.isActive(2)}" class="tabs-body fixed" style="max-height: 80vh !important;">
    <form [formGroup]="form" class="ca-form dialog-content-no-margin" style="display: flex;">
      <div style="flex: 1 1 50%;">
        <div class="flex-container" fxLayout="row" fxLayout.sm="column">
          <div class="flex-item" fxFlex>

            <div formGroupName="inventory">
              <!-- Form Section 2: Financial / Contract -->
              <div class="flex-container" fxLayout="row">
                <div class="flex-item caGap caGapRight" fxFlex="30%">
                  <mat-form-field caInput>
                    <input matInput placeholder="Term (months)" formControlName="term"
                            [min]="minimumTermMonths" [max]="maximumTermMonths" type="number"
                            [required]="orderTypeRequiredField"
                            [disabled]="passedRfa">
                    <mat-hint
                      *ngIf="form.get(['inventory', 'term']).hasError('required') && form.get(['inventory', 'term']).touched"
                    >{{VALIDATION.REQUIRED_ERROR}}
                    </mat-hint>
                    <mat-hint
                      *ngIf="form.get(['inventory', 'term']).hasError('range') && form.get(['inventory', 'term']).touched
                              && !form.get(['inventory', 'term']).hasError('pattern')"
                    >{{RangeErrorMessage(minimumTermMonths,maximumTermMonths)}}
                    </mat-hint>
                    <mat-hint
                      *ngIf="form.get(['inventory', 'term']).hasError('pattern') && form.get(['inventory', 'term']).touched"
                    >{{VALIDATION.INTEGER_INVALID}}
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="flex-item caGap caGapRight" fxFlex="35%">
                  <ca-currency caInput [required]="orderTypeRequiredField" placeholder="MRC" formControlName="est_mrc"
                               [disabled]="passedRfa"
                               [currency]="currency"></ca-currency>
                  <mat-hint
                    *ngIf="form.get(['inventory', 'est_mrc']).hasError('required') && form.get(['inventory', 'est_mrc']).touched"
                    class="ca-select-hint">{{VALIDATION.REQUIRED_ERROR}}
                  </mat-hint>
                </div>
                <div class="flex-item caGap" fxFlex="35%">
                  <ca-currency caInput [required]="orderTypeRequiredField" placeholder="NRC" formControlName="est_nrc"
                               [disabled]="passedRfa"
                               [currency]="currency"></ca-currency>
                  <mat-hint
                    *ngIf="form.get(['inventory', 'est_nrc']).hasError('required') && form.get(['inventory', 'est_nrc']).touched"
                    class="ca-select-hint">{{VALIDATION.REQUIRED_ERROR}}
                  </mat-hint>
                </div>

              </div>
              <div class="flex-container-3" fxLayout="row">
                <div class="flex-item caGap" fxFlex="30%">
                  <ca-date-picker caInput placeholder="Expiration Date" formControlName="exp_date"
                                  [disabled]="passedRfa"></ca-date-picker>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Custom Fields -->
      <div class="caGap caGapLeft" style="flex: 1 1 50%; justify-content: flex-end;">
        <mat-toolbar style="background-color: #303E4D !important;" color="accent">
          <div class="order-custom-fields-box-header">CUSTOM ATTRIBUTES</div>
          <button mat-icon-button type="button" [ngClass]="{'on' : onlyRequiredCustomFields1}" (click)="toggleReqFields1()" [disabled]="categories1 && categories1.length === 0">
            <ca-icon [key]="'REQUIRED_FIELDS'" [tooltip]="'Required Fields'"></ca-icon>
          </button>
        </mat-toolbar>

        <div class="order-custom-fields-box" style="float: left">
          <div *ngIf="categories1 && categories1.length === 0" class="order-custom-fields-no-data">No Data</div>
          <mat-accordion displayMode="flat" multi class="mat-table">
            <div [@.disabled]="disableAnimation" >
              <mat-expansion-panel *ngFor="let category of categories1" [expanded]="false" #mep="matExpansionPanel"
                                   class="custom-fields-expansion-panel" [ngClass]="{'expansion-panel-body-bottom-line': mep.expanded && category !== categories1[categories1.length-1]}">
                <mat-expansion-panel-header *ngIf="category" class="custom-fields-expansion-panel-header" [collapsedHeight]="'35px'" [expandedHeight]="'35px'">
                  {{category}}
                </mat-expansion-panel-header>
                <div *ngFor="let field of custom_fields1" class="caGap caGapRight order-custom-fields">
                  <mat-form-field caInput="" *ngIf="field.type_id === 'number_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields1) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields1) || (!onlyRequiredCustomFields1))"
                                  [style.width.px]="field.width">
                    <input matInput
                           [placeholder]="field.placeholder"
                           type="number"
                           [formControlName]="field.field_name"
                           [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                           [minlength]="field.min_value"
                           [maxlength]="field.max_value"
                           rows="1">
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'text_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields1) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields1) || (!onlyRequiredCustomFields1))"
                                  [style.width.px]="field.width">
                    <input matInput
                           [placeholder]="field.placeholder"
                           type="text"
                           [formControlName]="field.field_name"
                           [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                           [minlength]="field.min_value"
                           [maxlength]="field.max_value"
                           rows="1">
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'textarea_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields1) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields1) || (!onlyRequiredCustomFields1))"
                                  [style.width.px]="field.width">
                      <textarea matInput
                                [placeholder]="field.placeholder"
                                type="text"
                                [formControlName]="field.field_name"
                                [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                [minlength]="field.min_value"
                                [maxlength]="field.max_value"
                                rows="1">
                      </textarea>
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'email' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields1) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields1) || (!onlyRequiredCustomFields1))"
                                  [style.width.px]="field.width">
                    <input matInput
                           [placeholder]="field.placeholder"
                           [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                           class="form-control"
                           type="email"
                           [formControlName]="field.field_name"
                           [pattern]="field.pattern">
                    <mat-hint *ngIf="(form.hasError('pattern', [field.field_name]) || this.form.get(field.field_name).invalid) && form.controls[field.field_name].touched"
                              class="error-class">
                      {{VALIDATION.EMAIL_ERROR}}
                    </mat-hint>
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'custom_dropdown' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields1) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields1) || (!onlyRequiredCustomFields1))"
                                  [style.width.px]="field.width">
                    <mat-select
                        [placeholder]="field.placeholder"
                        [formControlName]="field.field_name"
                        [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)">
                      <mat-option *ngFor="let obj of field.dropdown_values" [value]="obj.label">
                        {{obj.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <ca-lookup-model-picker *ngIf="field.type_id === 'lookup_dropdown' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields1) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields1) || (!onlyRequiredCustomFields1))"
                                          [style.width.px]="field.width"
                                          clearEnabled="true"
                                          caInput
                                          #lookupPicker
                                          [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                          [searchEnabled]="false"
                                          [lookupModel]="field.lookup_table"
                                          name="lookup_id"
                                          [formControlName]="field.field_name"
                                          [pickerForm]="form"
                                          [placeholder]="field.placeholder">
                  </ca-lookup-model-picker>

                  <div [style.width.px]="field.width">
                    <ca-date-picker *ngIf="field.type_id === 'date' && field.category === category &&
                    ((field.is_required && onlyRequiredCustomFields1) ||
                    (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields1) || (!onlyRequiredCustomFields1))"
                                    caInput
                                    [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                    [minDate]="field.min_value"
                                    [maxDate]="field.max_value"
                                    [placeholder]="field.placeholder"
                                    [formControlName]="field.field_name">
                    </ca-date-picker>
                  </div>

                </div>
              </mat-expansion-panel>
            </div>

          </mat-accordion>
        </div>
      </div>

    </form>
  </div>

  <div [ngClass]="{'hidden': !tabGroup.isActive(3)}" class="tabs-body fixed" style="max-height: 80vh !important;">
    <form [formGroup]="form" class="ca-form dialog-content-no-margin" style="display: flex;">
      <div style="flex: 1 1 50%;">
        <div class="flex-container" fxLayout="row" fxLayout.sm="column">
          <div class="flex-item" fxFlex>
            <div formGroupName="inventory">
              <!-- Form Section 3: Configuration -->
              <div class="flex-container-3" fxLayout="row">
                <div class="flex-item caGap caGapRight" fxFlex="33.33%">
                  <ca-lookup-model-picker
                    caInput
                    [lookupModel]="INVENTORY_CKT_TYPE_LOOKUP_MODEL"
                    formControlName="ckt_type_id"
                    placeholder="Type"
                    [pickerForm]="form.controls.inventory"
                    [clearEnabled]="true"
                    [disabled]="passedRfa"
                    [required]="orderTypeRequiredField">
                  </ca-lookup-model-picker>
                </div>

                <div class="flex-item caGap caGapRight" fxFlex="33.33%">
                  <ca-technology-picker
                    caInput
                    [required]="orderTypeRequiredField"
                    name="technology_id"
                    formControlName="technology_id"
                    placeholder="Technology"
                    [lookupModel]="INVENTORY_TECHNOLOGY_LOOKUP_MODEL"
                    [pickerForm]="form.controls.inventory"
                    [clearEnabled]="true"
                    [query]="inventoryTechnologyQuery"
                    [initialItemId]="orderService?.inventory.technology_id"
                    [disabled]="passedRfa ">
                  </ca-technology-picker>
                </div>

                <div class="flex-item caGap" fxFlex="33.33%">
                  <ca-bandwidth-picker
                    caInput
                    name="cir_bw_id"
                    formControlName="cir_bw_id"
                    placeholder="Service Bandwidth"
                    [initialItem]="orderService?.inventory?.circuit_bandwidth"
                    [pickerForm]="form.controls.inventory"
                    [searchEnabled]="true"
                    [disabled]="passedRfa"
                    required="false">
                  </ca-bandwidth-picker>
                  <mat-hint class="ca-select-hint"
                            *ngIf="form.get(['inventory']).hasError('required') && form.get(['inventory']).touched">
                    {{VALIDATION.REQUIRED_ERROR}}
                  </mat-hint>
                </div>
              </div>

              <div class="flex-container-3" fxLayout="row">
                <div class="flex-item caGap caGapRight" fxFlex="33.33%">
                  <ca-lookup-model-picker
                    caInput
                    [lookupModel]="INVENTORY_CIRCUIT_USAGE_LOOKUP_MODEL"
                    [pickerForm]="form.controls.inventory"
                    formControlName="ckt_usage_id"
                    placeholder="Usage"
                    [clearEnabled]="true"
                    [disabled]="passedRfa">
                  </ca-lookup-model-picker>
                </div>
                <div class="flex-item caGap caGapRight" fxFlex="33.33%">
                  <ca-bandwidth-picker
                    caInput
                    name="bw_max_id"
                    formControlName="bw_max_id"
                    placeholder="Port Bandwidth"
                    [initialItem]="orderService?.inventory?.bandwidth_max"
                    [pickerForm]="form.controls.inventory"
                    [searchEnabled]="true"
                    [disabled]="passedRfa">
                  </ca-bandwidth-picker>
                </div>

                <div class="flex-item caGap" fxFlex="33.33%">
                  <ca-lookup-model-picker
                    caInput
                    [lookupModel]="INVENTORY_PROTECTED_LOOKUP_MODEL"
                    formControlName="protected_id"
                    placeholder="Protection"
                    [pickerForm]="form.controls.inventory"
                    [clearEnabled]="true"
                    [disabled]="passedRfa">
                  </ca-lookup-model-picker>
                </div>

              </div>

              <div class="flex-container-3" fxLayout="row">
                <div class="flex-item caGap caGapRight" fxFlex="33.33%">
                  <ca-lookup-model-picker
                    caInput
                    [lookupModel]="INVENTORY_BW_MODEL_LOOKUP_MODEL"
                    formControlName="bw_model_id"
                    placeholder="Bandwidth Model"
                    [pickerForm]="form.controls.inventory"
                    [clearEnabled]="true"
                    [disabled]="passedRfa">
                  </ca-lookup-model-picker>
                </div>
                <!-- <div class="flex-item caGap caGapRight" fxFlex="33%">
                  <ca-lookup-model-picker caInput [lookupModel]="INVENTORY_CKT_TYPE_LOOKUP_MODEL" formControlName="ckt_type_id"
                                          placeholder="Type" [pickerForm]="form.controls.inventory"
                                          [clearEnabled]="true" [disabled]="passedRfa">
                  </ca-lookup-model-picker>
                </div> -->
                <div class="flex-item caGap caGapRight" fxFlex="33.33%">
                  <ca-lookup-model-picker
                    caInput
                    [lookupModel]="INVENTORY_HANDOFF_TYPE_LOOKUP_MODEL"
                    [pickerForm]="form.controls.inventory"
                    formControlName="handoff_type_id"
                    placeholder="Handoff Type"
                    [clearEnabled]="true"
                    [disabled]="passedRfa">
                  </ca-lookup-model-picker>
                </div>

                <div class="flex-item caGap" fxFlex="33.33%">
                  <ca-lookup-model-picker
                    caInput
                    [lookupModel]="INVENTORY_PRIM_FAILOVER_LOOKUP_MODEL"
                    [pickerForm]="form.controls.inventory"
                    formControlName="prim_failover_id"
                    placeholder="Primary/Failover"
                    [clearEnabled]="true"
                    [disabled]="passedRfa">
                  </ca-lookup-model-picker>
                </div>
              </div>

              <div class="flex-container-3" fxLayout="row">
                <div class="flex-item caGap caGapRight" fxFlex="33.33%">
                  <mat-form-field caInput>
                    <input matInput type="text" placeholder="VLAN ID" formControlName="vlan_id">
                  </mat-form-field>
                </div>

                <div class="flex-item caGap caGapRight" fxFlex="33.33%">
                </div>

                <div class="flex-item caGap" fxFlex="33.33%"></div>
                <!-- <div class="flex-item caGap" fxFlex="34%">
                  <ca-lookup-model-picker caInput [lookupModel]="INVENTORY_PRIM_FAILOVER_LOOKUP_MODEL"
                                          [pickerForm]="form.controls.inventory"
                                          formControlName="prim_failover_id" placeholder="Primary/Failover"
                                          [clearEnabled]="true" [disabled]="passedRfa">
                  </ca-lookup-model-picker>
                </div> -->
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Custom Fields -->
      <div class="caGap caGapLeft" style="flex: 1 1 50%; justify-content: flex-end;">
        <mat-toolbar style="background-color: #303E4D !important;" color="accent">
          <div class="order-custom-fields-box-header">CUSTOM ATTRIBUTES</div>
          <button mat-icon-button type="button" [ngClass]="{'on' : onlyRequiredCustomFields2}" (click)="toggleReqFields2()" [disabled]="categories2 && categories2.length === 0">
            <ca-icon [key]="'REQUIRED_FIELDS'" [tooltip]="'Required Fields'"></ca-icon>
          </button>
        </mat-toolbar>

        <div class="order-custom-fields-box" style="float: left">
          <div *ngIf="categories2 && categories2.length === 0" class="order-custom-fields-no-data">No Data</div>
          <mat-accordion displayMode="flat" multi class="mat-table">
            <div [@.disabled]="disableAnimation" >
              <mat-expansion-panel *ngFor="let category of categories2" [expanded]="false" #mep="matExpansionPanel"
                                   class="custom-fields-expansion-panel" [ngClass]="{'expansion-panel-body-bottom-line': mep.expanded && category !== categories2[categories2.length-1]}">
                <mat-expansion-panel-header *ngIf="category" class="custom-fields-expansion-panel-header" [collapsedHeight]="'35px'" [expandedHeight]="'35px'">
                  {{category}}
                </mat-expansion-panel-header>
                <div *ngFor="let field of custom_fields2" class="caGap caGapRight order-custom-fields">
                  <mat-form-field caInput="" *ngIf="field.type_id === 'number_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields2) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields2) || (!onlyRequiredCustomFields2))"
                                  [style.width.px]="field.width">
                    <input matInput
                           [placeholder]="field.placeholder"
                           type="number"
                           [formControlName]="field.field_name"
                           [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                           [minlength]="field.min_value"
                           [maxlength]="field.max_value"
                           rows="1">
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'text_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields2) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields2) || (!onlyRequiredCustomFields2))"
                                  [style.width.px]="field.width">
                    <input matInput
                           [placeholder]="field.placeholder"
                           type="text"
                           [formControlName]="field.field_name"
                           [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                           [minlength]="field.min_value"
                           [maxlength]="field.max_value"
                           rows="1">
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'textarea_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields2) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields2) || (!onlyRequiredCustomFields2))"
                                  [style.width.px]="field.width">
                      <textarea matInput
                                [placeholder]="field.placeholder"
                                type="text"
                                [formControlName]="field.field_name"
                                [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                [minlength]="field.min_value"
                                [maxlength]="field.max_value"
                                rows="1">
                      </textarea>
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'email' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields2) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields2) || (!onlyRequiredCustomFields2))"
                                  [style.width.px]="field.width">
                    <input matInput
                           [placeholder]="field.placeholder"
                           [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                           class="form-control"
                           type="email"
                           [formControlName]="field.field_name"
                           [pattern]="field.pattern">
                    <mat-hint *ngIf="(form.hasError('pattern', [field.field_name]) || this.form.get(field.field_name).invalid) && form.controls[field.field_name].touched"
                              class="error-class">
                      {{VALIDATION.EMAIL_ERROR}}
                    </mat-hint>
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'custom_dropdown' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields2) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields2) || (!onlyRequiredCustomFields2))"
                                  [style.width.px]="field.width">
                    <mat-select
                        [placeholder]="field.placeholder"
                        [formControlName]="field.field_name"
                        [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)">
                      <mat-option *ngFor="let obj of field.dropdown_values" [value]="obj.label">
                        {{obj.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <ca-lookup-model-picker *ngIf="field.type_id === 'lookup_dropdown' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields2) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields2) || (!onlyRequiredCustomFields2))"
                                          [style.width.px]="field.width"
                                          clearEnabled="true"
                                          caInput
                                          #lookupPicker
                                          [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                          [searchEnabled]="false"
                                          [lookupModel]="field.lookup_table"
                                          name="lookup_id"
                                          [formControlName]="field.field_name"
                                          [pickerForm]="form"
                                          [placeholder]="field.placeholder">
                  </ca-lookup-model-picker>

                  <div [style.width.px]="field.width">
                    <ca-date-picker *ngIf="field.type_id === 'date' && field.category === category &&
                    ((field.is_required && onlyRequiredCustomFields2) ||
                    (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields2) || (!onlyRequiredCustomFields2))"
                                    caInput
                                    [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                    [minDate]="field.min_value"
                                    [maxDate]="field.max_value"
                                    [placeholder]="field.placeholder"
                                    [formControlName]="field.field_name">
                    </ca-date-picker>
                  </div>

                </div>
              </mat-expansion-panel>
            </div>

          </mat-accordion>
        </div>
      </div>

    </form>
  </div>

  <div [ngClass]="{'hidden': !tabGroup.isActive(4) }" class="tabs-body fixed" style="max-height: 90vh !important; height: 85%;">

    <div *ngIf="!form.controls['inventory']['controls'].topology_id.value" class="ca-empty-state-container">
      <span class="grid-message">Please select Topology on Configuration tab</span>
    </div>

    <form [formGroup]="form" class="ca-form dialog-content-no-margin" style="display: flex;"
          *ngIf="form.controls['inventory']['controls'].topology_id.value === INVENTORY_TOPOLOGY.POINT_TO_POINT || topology === INVENTORY_TOPOLOGY.POINT_TO_POINT">
      <div style="flex: 1 0 50%;">
        <div class="flex-container" fxLayout="row" fxLayout.sm="column">
          <div class="flex-item" fxFlex>
            <div formGroupName="inventory">

              <div class="flex-container" fxLayout="row">
                <div class="flex-item caGap caGapRight" fxFlex>
                  <ca-site-picker
                    [disabledSites]="disabledSites"
                    caInput
                    [pickerForm]="form.controls.inventory"
                    [initialItem]="orderService?.inventory?.siteA"
                    formControlName="site_a_id"
                    required="false"
                    placeholder="Site A (Carrier Location)"
                    [searchEnabled]="true"
                    [disabled]="passedRfa"
                    clearEnabled="true">
                  </ca-site-picker>
                </div>
                <div class="flex-item caGap" fxFlex>
                  <ca-site-picker
                    caInput
                    [disabledSites]="disabledSites"
                    [pickerForm]="form.controls.inventory"
                    formControlName="site_z_id"
                    required="false"
                    placeholder="Site Z (Customer Location)"
                    [initialItem]="orderService?.inventory?.siteZ"
                    [searchEnabled]="true"
                    [disabled]="passedRfa"
                    clearEnabled="true">
                  </ca-site-picker>
                </div>
              </div>

            <!--Single Demarc -->
            <div class="flex-container" fxLayout="row" *ngIf="singleDemarc">
              <!-- Site A Single Demarc -->
              <div class="flex-item caGap caGapRight" fxFlex>
                <div *ngIf="form['controls'].inventory['controls'].site_a_id.value" class="ca-form caGap" style="margin-top: 0;"
                      formGroupName="site_a_demarc">

                  <div class="flex-container-3" fxLayout="row">
                    <div class="flex-item caGap caGapRight" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="row_id" placeholder="Row">
                      </mat-form-field>
                    </div>
                    <div class="flex-item caGap caGapRight" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="rack_id" placeholder="Rack">
                      </mat-form-field>
                    </div>
                    <div class="flex-item caGap" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="shelf_id" placeholder="Shelf">
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="flex-container-3" fxLayout="row" fxLayoutAlign="start center">
                    <div class="flex-item caGap caGapRight" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="cpe_id" placeholder="CPE">
                      </mat-form-field>
                    </div>
                    <div class="flex-item caGap caGapRight" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="card_id" placeholder="Card">
                      </mat-form-field>
                    </div>
                    <div class="flex-item caGap" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="port_id" placeholder="Port">
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="flex-container-3" fxLayout fxLayoutAlign="center center">
                    <div class="flex-item caGap caGapRight" fxFlex="70%">
                      <mat-form-field caInput>
                        <textarea matInput formControlName="notes" placeholder="Notes" fxFlex></textarea>
                      </mat-form-field>
                    </div>
                    <mat-checkbox formControlName="extended" fxFlex>
                      Extended
                    </mat-checkbox>
                  </div>

                </div>
                <div *ngIf="!form['controls'].inventory['controls'].site_a_id.value" class="ca-empty-state-container"
                     style="min-height: 200px">
                  <p class="card-message">
                    {{demarc_messages.get('DEMARC_NO_SITE')}}
                  </p>
                </div>
              </div>
              <!-- /Site A Single Demarc -->

              <!-- Site Z Single Demarc -->
              <div class="flex-item caGap" fxFlex>
                <div class="ca-form caGap" style="margin-top: 0;" formGroupName="site_z_demarc"
                      *ngIf="form['controls'].inventory['controls'].site_z_id.value">

                  <div class="flex-container-3" fxLayout="row">
                    <div class="flex-item caGap caGapRight" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="row_id" placeholder="Row">
                      </mat-form-field>
                    </div>
                    <div class="flex-item caGap caGapRight" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="rack_id" placeholder="Rack">
                      </mat-form-field>
                    </div>
                    <div class="flex-item caGap" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="shelf_id" placeholder="Shelf">
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="flex-container-3" fxLayout="row" fxLayoutAlign="start center">
                    <div class="flex-item caGap caGapRight" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="cpe_id" placeholder="CPE">
                      </mat-form-field>
                    </div>
                    <div class="flex-item caGap caGapRight" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="card_id" placeholder="Card">
                      </mat-form-field>
                    </div>
                    <div class="flex-item caGap" fxFlex="1 1 33%">
                      <mat-form-field caInput>
                        <input matInput type="text" formControlName="port_id" placeholder="Port">
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="flex-container-3" fxLayout fxLayoutAlign="center center">
                    <div class="flex-item caGap caGapRight" fxFlex="70%">
                      <mat-form-field caInput>
                        <textarea matInput formControlName="notes" placeholder="Notes" fxFlex></textarea>
                      </mat-form-field>
                    </div>
                    <mat-checkbox formControlName="extended" fxFlex>
                      Extended
                    </mat-checkbox>
                  </div>

                </div>
                <div *ngIf="!form['controls'].inventory['controls'].site_z_id.value" class="ca-empty-state-container"
                     style="min-height: 200px">
                  <p class="card-message">
                    {{demarc_messages.get('DEMARC_NO_SITE')}}
                  </p>
                </div>
              </div>
              <!-- /Site Z Single Demarc -->
            </div>
            <div class="flex-container" fxLayout="row" *ngIf="singleDemarc">
              <!--<div class="flex-item hub-info" fxFlex="45%">
                <div class="flex-container-3" fxLayout="row" fxLayoutGap="24px" *ngIf="isUpdate">
                  <div fxFlex="33%">
                    <label class="hub-label">Network Hub</label>
                    <div>
                      <div *ngIf="this.orderService.inventory?.network_hub_map?.network_hub?.hub_name">
                        {{(this.orderService.inventory?.network_hub_map?.network_hub?.hub_name) || 'N/A'}}
                      </div>
                      <div *ngIf="!this.orderService.inventory?.network_hub_map?.network_hub?.hub_name" class="network-hub-placeholder">
                        N/A
                      </div>
                    </div>
                  </div>
                  <div fxFlex="33%" *ngIf="!level1 && !level2 && this.orderService.inventory?.network_hub_map?.network_hub?.hub_name">
                    <label class="hub-label">{{CAPACITY_TYPES[this.orderService.inventory?.network_hub_map?.network_hub?.hub_capacity_id]}}</label>
                    <div>
                      Host circuit
                    </div>
                  </div>
                  <div fxFlex="33%" *ngIf="level1">
                    <label class="hub-label">{{level1.name || 'E-NNI'}}</label>
                    <div *ngIf="level1.order">
                      Slot {{level1.order}}
                    </div>
                    <div *ngIf="!level1.order">
                      No order
                    </div>
                  </div>
                  <div fxFlex="33%" *ngIf="level2">
                    <label class="hub-label">{{level2?.name || 'N/A'}}</label>
                    <div *ngIf="level2.order">
                      Slot {{level2.order}}
                    </div>
                    <div *ngIf="!level1.order">
                      No order
                    </div>
                  </div>
                </div>
              </div>-->

<!--              <div class="flex-item hub-info caGap" fxFlex>-->
<!--                <div class="flex-container-3" fxLayout="row">-->
<!--                  <div class="flex-item caGap caGapRight" fxFlex="1 1 33%">-->
<!--                    <ca-network-hub-picker-->
<!--                      caInput-->
<!--                      #hubPickerA-->
<!--                      [site_id]="form['controls'].inventory['controls']?.site_a_id?.value"-->
<!--                      [vendor_id]="order?.vendor?.id"-->
<!--                      placeholder="Network hub"-->
<!--                      [pickerForm]="form.controls['inventory']"-->
<!--                      formControlName="network_hub_a"-->
<!--                      [type]="form['controls'].inventory['controls']?.technology_id?.value"-->
<!--                      [disabled]="passedRfa">-->
<!--                    </ca-network-hub-picker>-->
<!--                  </div>-->
<!--                  <div class="flex-item caGap caGapRight" fxFlex="1 1 33%"-->
<!--                       *ngIf="gethubType(form['controls']?.inventory['controls']?.network_hub_a?.value, hubPickerA)!=NETWORK_HUB_CAPACITY.DS_3">-->
<!--                    <ca-network-hub-circuit-picker-->
<!--                      caInput-->
<!--                      [disabled]="!isHubPickerVisible(form['controls'].inventory['controls']?.network_hub_a?.value, HUB_PICKER_LEVEL.LEVEL_1, hubPickerA)"-->
<!--                      [placeholder]="hub_labels.level_1"-->
<!--                      typeLabel="DS3"-->
<!--                      showInventory="true"-->
<!--                      [pickerForm]="form['controls']['inventory']"-->
<!--                      formControlName="network_hub_circuit_a"-->
<!--                      [parentId]="form['controls'].inventory['controls']?.network_hub_a?.value"-->
<!--                      [hubId]="form['controls'].inventory['controls']?.network_hub_a?.value"-->
<!--                      [disabled]="passedRfa"-->
<!--                      [searchEnabled]="false">-->
<!--                    </ca-network-hub-circuit-picker>-->
<!--                  </div>-->
<!--                  <div class="flex-item caGap" fxFlex="1 1 33%"-->
<!--                       *ngIf="gethubType(form['controls'].inventory['controls']?.network_hub_a?.value, hubPickerA)!=NETWORK_HUB_CAPACITY.DS_3">-->
<!--                    <ca-network-hub-circuit-picker-->
<!--                      caInput-->
<!--                      [disabled]="!isHubPickerVisible(form.controls.inventory['controls']?.network_hub_a?.value, HUB_PICKER_LEVEL.LEVEL_2, hubPickerA)-->
<!--                        &&!isHubPickerVisible(form.controls.inventory['controls']?.network_hub_a?.value, HUB_PICKER_LEVEL.LEVEL_1, hubPickerA)-->
<!--                              && !form.controls.inventory['controls']?.network_hub_circuit_a?.value"-->
<!--                      [placeholder]="hub_labels.level_2"-->
<!--                      typeLabel="DS1"-->
<!--                      [pickerForm]="form.controls['inventory']"-->
<!--                      formControlName="network_hub_circuit_child_a"-->
<!--                      [parentId]="form.controls.inventory['controls']?.network_hub_circuit_a?.value || form.controls.inventory['controls']?.network_hub_a?.value"-->
<!--                      [hubId]="form.controls.inventory['controls']?.network_hub_a?.value"-->
<!--                      [disabled]="passedRfa"-->
<!--                      [searchEnabled]="false">-->
<!--                    </ca-network-hub-circuit-picker>-->
<!--                  </div>-->

<!--                  <div class="flex-item caGap caGapRight" fxFlex="1 1 33%"-->
<!--                       *ngIf="gethubType(form.controls?.inventory['controls']?.network_hub_a?.value, hubPickerA)==NETWORK_HUB_CAPACITY.DS_3">-->
<!--                    <ca-network-hub-circuit-picker-->
<!--                      caInput-->
<!--                      [disabled]="!isHubPickerVisible(form.controls.inventory['controls']?.network_hub_a?.value, HUB_PICKER_LEVEL.LEVEL_2, hubPickerA)-->
<!--                            &&!isHubPickerVisible(form.controls.inventory['controls']?.network_hub_a?.value, HUB_PICKER_LEVEL.LEVEL_1, hubPickerA)-->
<!--                                  && !form.controls.inventory['controls']?.network_hub_circuit_a?.value"-->
<!--                      [placeholder]="hub_labels.level_1"-->
<!--                      typeLabel="DS1"-->
<!--                      [pickerForm]="form.controls['inventory']"-->
<!--                      formControlName="network_hub_circuit_child_a"-->
<!--                      [parentId]="form.controls.inventory['controls']?.network_hub_circuit_a?.value || form.controls.inventory['controls']?.network_hub_a?.value"-->
<!--                      [hubId]="form.controls.inventory['controls']?.network_hub_a?.value"-->
<!--                      [disabled]="passedRfa">-->
<!--                    </ca-network-hub-circuit-picker>-->
<!--                  </div>-->
<!--                  <div class="flex-item caGap caGapRight" fxFlex="1 1 33%"-->
<!--                       *ngIf="gethubType(form.controls?.inventory['controls']?.network_hub_a?.value, hubPickerA)==NETWORK_HUB_CAPACITY.DS_3">-->
<!--                    <ca-network-hub-circuit-picker-->
<!--                      caInput-->
<!--                      [disabled]="true"-->
<!--                      [placeholder]="hub_labels.level_2"-->
<!--                      [pickerForm]="form.controls['inventory']"-->
<!--                      formControlName="network_hub_circuit_a"-->
<!--                      [parentId]="form.controls.inventory['controls']?.network_hub_a?.value"-->
<!--                      [hubId]="form.controls.inventory['controls']?.network_hub_a?.value"-->
<!--                      [disabled]="passedRfa">-->
<!--                    </ca-network-hub-circuit-picker>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
            <!-- Demarc -->
            <div class="flex-container" fxLayout="row" *ngIf="!singleDemarc">
              <!-- Site A Demarc -->
              <div class="flex-item caGap caGapRight" fxFlex="45%">
                <ng-container *ngIf="tabGroup.isActive(4)">
                  <ca-demarc-list caInput style="width: 100%;" [demarcs]="siteADemarcs"
                                  [title]="'Site A demarcation point(s)'"
                                  [siteId]="form.controls['inventory']['controls'].site_a_id?.value"
                                  (addRequested)="onSiteADemarcAddRequested($event)"
                                  (editRequested)="onSiteADemarcEditRequested($event)"
                                  (deleteRequested)="onSiteADemarcDeleteRequested($event)"
                                  *ngIf="!siteADemarcFormMode"></ca-demarc-list>

                  <div *ngIf="form.controls['inventory']['controls'].site_a_id.value">
                    <ca-demarc-form caInput[siteId]="form.controls['inventory']['controls'].site_a_id.value"
                                    [demarc]="selectedSiteADemarc"
                                    (saveRequested)="onSiteADemarcSaveRequested($event)"
                                    (formCancelled)="onSiteADemarcFormCancelled($event)"
                                    *ngIf="siteADemarcFormMode"></ca-demarc-form>
                  </div>

                </ng-container>
              </div>
              <!-- /Site A Demarc -->

              <!-- Site Z Demarc -->
              <div class="flex-item" fxFlex="55%">
                <ng-container *ngIf="tabGroup.isActive(4)">
                  <ca-demarc-list caInput style="width: 100%;" [demarcs]="siteZDemarcs" [title]="'Site Z demarcation point(s)'"
                                  [siteId]="form.controls['inventory']['controls'].site_z_id?.value"
                                  (addRequested)="onSiteZDemarcAddRequested($event)"
                                  (editRequested)="onSiteZDemarcEditRequested($event)"
                                  (deleteRequested)="onSiteZDemarcDeleteRequested($event)"
                                  *ngIf="!siteZDemarcFormMode"></ca-demarc-list>

                  <div *ngIf="form.controls['inventory']['controls'].site_z_id?.value">
                    <ca-demarc-form caInput [siteId]="form.controls['inventory']['controls'].site_z_id.value"
                                    [demarc]="selectedSiteZDemarc"
                                    (saveRequested)="onSiteZDemarcSaveRequested($event)"
                                    (formCancelled)="onSiteZDemarcFormCancelled($event)"
                                    *ngIf="siteZDemarcFormMode"></ca-demarc-form>
                  </div>
                </ng-container>
              </div>
              <!-- /Site Z Demarc -->
            </div>

          </div>
          </div>

        </div>
      </div>

      <!-- Custom Fields -->
      <div class="caGap caGapLeft" style="flex: 1 1 50%; justify-content: flex-end;">
        <mat-toolbar style="background-color: #303E4D !important;" color="accent">
          <div class="order-custom-fields-box-header">CUSTOM ATTRIBUTES</div>
          <button mat-icon-button type="button" [ngClass]="{'on' : onlyRequiredCustomFields3}" (click)="toggleReqFields3()" [disabled]="categories3 && categories3.length === 0">
            <ca-icon [key]="'REQUIRED_FIELDS'" [tooltip]="'Required Fields'"></ca-icon>
          </button>
        </mat-toolbar>

        <div class="order-custom-fields-box" style="float: left">
          <div *ngIf="categories3 && categories3.length === 0" class="order-custom-fields-no-data">No Data</div>
          <mat-accordion displayMode="flat" multi class="mat-table">
            <div [@.disabled]="disableAnimation" >
              <mat-expansion-panel *ngFor="let category of categories3" [expanded]="false" #mep="matExpansionPanel"
                                   class="custom-fields-expansion-panel" [ngClass]="{'expansion-panel-body-bottom-line': mep.expanded && category !== categories3[categories3.length-1]}">
                <mat-expansion-panel-header *ngIf="category" class="custom-fields-expansion-panel-header" [collapsedHeight]="'35px'" [expandedHeight]="'35px'">
                  {{category}}
                </mat-expansion-panel-header>
                <div *ngFor="let field of custom_fields3" class="caGap caGapRight order-custom-fields">
                  <mat-form-field caInput="" *ngIf="field.type_id === 'number_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields3) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields3) || (!onlyRequiredCustomFields3))"
                                  [style.width.px]="field.width">
                    <input matInput
                           [placeholder]="field.placeholder"
                           type="number"
                           [formControlName]="field.field_name"
                           [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                           [minlength]="field.min_value"
                           [maxlength]="field.max_value"
                           rows="1">
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'text_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields3) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields3) || (!onlyRequiredCustomFields3))"
                                  [style.width.px]="field.width">
                    <input matInput
                           [placeholder]="field.placeholder"
                           type="text"
                           [formControlName]="field.field_name"
                           [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                           [minlength]="field.min_value"
                           [maxlength]="field.max_value"
                           rows="1">
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'textarea_field' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields3) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields3) || (!onlyRequiredCustomFields3))"
                                  [style.width.px]="field.width">
                      <textarea matInput
                                [placeholder]="field.placeholder"
                                type="text"
                                [formControlName]="field.field_name"
                                [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                [minlength]="field.min_value"
                                [maxlength]="field.max_value"
                                rows="1">
                      </textarea>
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'email' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields3) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields3) || (!onlyRequiredCustomFields3))"
                                  [style.width.px]="field.width">
                      <input matInput
                          [placeholder]="field.placeholder"
                          [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                          class="form-control"
                          type="email"
                          [formControlName]="field.field_name"
                          [pattern]="field.pattern">
                      <mat-hint *ngIf="(form.hasError('pattern', [field.field_name]) || this.form.get(field.field_name).invalid) && form.controls[field.field_name].touched"
                                class="error-class">
                        {{VALIDATION.EMAIL_ERROR}}
                      </mat-hint>
                  </mat-form-field>

                  <mat-form-field caInput="" *ngIf="field.type_id === 'custom_dropdown' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields3) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields3) || (!onlyRequiredCustomFields3))"
                                  [style.width.px]="field.width">
                    <mat-select
                        [placeholder]="field.placeholder"
                        [formControlName]="field.field_name"
                        [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)">
                      <mat-option *ngFor="let obj of field.dropdown_values" [value]="obj.label">
                        {{obj.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <ca-lookup-model-picker *ngIf="field.type_id === 'lookup_dropdown' && field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields3) ||
                  (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields3) || (!onlyRequiredCustomFields3))"
                                          [style.width.px]="field.width"
                                          clearEnabled="true"
                                          caInput
                                          #lookupPicker
                                          [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                          [searchEnabled]="false"
                                          [lookupModel]="field.lookup_table"
                                          name="lookup_id"
                                          [formControlName]="field.field_name"
                                          [pickerForm]="form"
                                          [placeholder]="field.placeholder">
                  </ca-lookup-model-picker>

                  <div [style.width.px]="field.width">
                    <ca-date-picker *ngIf="field.type_id === 'date' && field.category === category &&
                    ((field.is_required && onlyRequiredCustomFields3) ||
                    (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value && onlyRequiredCustomFields3) || (!onlyRequiredCustomFields3))"
                                    caInput
                                    [required]="field.is_required || (field.required_dependency_prop && getObjectProperty((field.required_dependency_obj === 'order' ? order : orderService), field.required_dependency_prop) === field.required_dependency_value)"
                                    [minDate]="field.min_value"
                                    [maxDate]="field.max_value"
                                    [placeholder]="field.placeholder"
                                    [formControlName]="field.field_name">
                    </ca-date-picker>
                  </div>

                </div>
              </mat-expansion-panel>
            </div>

          </mat-accordion>
        </div>
      </div>

    </form>
    <!--<div class="flex-item" fxFlex fxFlex.sm="100%"
         *ngIf="form.controls['inventory']['controls'].topology_id.value === INVENTORY_TOPOLOGY.RING || topology === INVENTORY_TOPOLOGY.RING">

      <div class="flex-container" fxLayout="row">
        <div class="flex-item" fxFlex>
          <form [formGroup]="form" class="ca-form dialog-content-no-margin">
            <div formGroupName="inventory">
              <div class="flex-container-3" fxLayout="row">
                <div class="flex-item caGap caGapRight" fxFlex="40%">
                  <ca-site-picker
                    caInput
                    formControlName="site_id"
                    placeholder="Site"
                    [pickerForm]="form.controls.inventory"
                    [searchEnabled]="true"
                    [disabled]="passedRfa">
                  </ca-site-picker>
                </div>
                <div class="flex-item caGap" fxFlex="50%">
                  <ca-person-picker
                  caInput
                    formControlName="site_lcontact_id"
                    [siteId]="orderService.inventory.site_id"
                    placeholder="Local Contact"
                    [pickerForm]="form.controls['inventory']"
                    [initialItem]="orderService?.site_lcontact"
                    type="{{ CONTACT_TYPE_ENUM.CUSTOMER }}"
                    [disabled]="passedRfa"
                    pickerType="contact">
                  </ca-person-picker>
                </div>
                <div class="flex-item caGap" fxFlex="10%" fxFlexAlign="center">
                  <button mat-icon-button tabindex="-1" [disabled]="!form.controls.inventory['controls'].site_id.value"
                          (click)="addSite(form.controls.inventory['controls'].site_id.value, form.controls.inventory['controls'].site_lcontact_id.value)">
                    <ca-icon [key]="'ADD_SITE'"></ca-icon>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="selected-ring-sites ca-default-border">
        <ul class="ca-list-group ca-inventory-sites-list" dnd-sortable-container
            [sortableData]="inventorySites">
          <li *ngFor="let invSite of inventorySites; let i = index"
              class="ca-list-group-item ca-default-border-color"
              dnd-sortable [sortableIndex]="i">
            <div fxLayout="row" fxLayoutGap="24px" class="vertical-center-container">
              <div fxFlex style="display: flex;">
                <i class="material-icons">reorder</i>
                <span class="ring-site-label">{{invSite?.site?.site_id}}</span>
              </div>
              <div fxFlex="10%" class="delete-button-container right" fxFlexAlign="center">
                <button mat-icon-button (click)="deleteSite(invSite?.site?.id, invSite?.site?.site_id)">
                  <ca-icon [key]="'DELETE_SITE'"></ca-icon>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>-->
  </div>

  <div [ngClass]="{'hidden': !tabGroup.isActive(5)}" class="tabs-body" style="height: 85%">
    <app-contact-section (contactsChanged)="onContactsChanged($event)" [orderContacts]="orderService.contacts" [isRequired]="false"></app-contact-section>
  </div>

</div>
<mat-dialog-actions class="ca-dialog-action">
    <span class="example-fill-remaining-space">
        <ca-confirm-delete-button #genericButton *ngIf="orderService && orderService.id" [disabled] = "disableDeleteButton()"
                                  (deleteRequested)="deleteOrderService($event)"></ca-confirm-delete-button>
    </span>
  <button mat-button #cancelButton type="submit" (click)="closeDialog()">
    Cancel
  </button>
  <button mat-button #saveButton type="submit"
          [disabled]="form?.invalid || termHasError
          || (form?.get(['inventory', 'sp_ckt_id']).hasError('required') && isUpdate)"
          (click)="onSubmit(form)">Save
  </button>
</mat-dialog-actions>


