import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';
import {
  UpdateInvoiceAlertQuery,
  UpdateInvoiceAlertSiderSection,
} from './invoice-alert.actions';
import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";

const INVOICE_ALERT_STATE_TOKEN = new StateToken<InvoiceAlertStateModel>('invoice_alert');

export interface InvoiceAlertStateModel extends QueryableStateModel<any> {

}

@State<InvoiceAlertStateModel>({
  name: INVOICE_ALERT_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InvoiceAlertState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  @Action(UpdateInvoiceAlertQuery)
  // @ts-ignore
  updateInvoiceAlertQuery(ctx: StateContext<InvoiceAlertStateModel>, {options}): void {
    return this.updateQuery(ctx, {options});
  }

  @Action(UpdateInvoiceAlertSiderSection)
  // @ts-ignore
  updateInvoiceAlertSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
