import {Injectable} from '@angular/core';
import {FilterService} from "../../core/filter/filter.service";
import {auditAlertRateFilterContext} from "./audit-alert-rate-filter.context";
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class AuditAlertRateFilterService extends FilterService {
  constructor(public userSettingsService: UserSettingsService) {
    super("app.alert.audit-alert-disconnect-audit-filter", auditAlertRateFilterContext, userSettingsService);


  }

}
