import {Inventory} from "../../inventory/core/inventory";
import {Customer} from "../../customer/core/customer";
import {Contact} from "../../contact/core/contact";

export class OrderService {
  id: number;
  created_at: Date;
  foc_rec_date: Date;
  des_due_date: Date;
  foc_date: Date;
  install_date: Date;
  expedited_order: boolean;
  service_test_vendor_date: Date;
  accepted_by: number;
  estimate_total_mrc: number;
  estimate_total_nrc: number;
  order_id: number;
  inventory_id: number;
  site_z_lcontact: any;
  site_a_lcontact: any;
  state: number;
  description: string;
  foc_date_history: JSON;
  active: boolean;
  service_id: number;
  install_date_history: JSON;
  des_due_date_history: JSON;
  service_test_date_history: JSON;
  full_service_id: string;
  custom_values: any;
  inventory: Inventory;
  test_passed: boolean;
  note: string;
  quote_service_id: number;
  customer: Customer;
  service_test_customer_date: Date;
  customer_test_passed: boolean;
  vendor_test_passed: boolean;
  disconnect_state: number;
  disconnect_date: Date;
  date_received: Date;
  stop_billing_date: Date;
  disconnect_complete_date: Date;
  order_number: string;
  purchase_number: string;
  ban:string;
  contacts: any;
  site_contact: Contact;
  secondary_site_contact: Contact;
  ready_for_approval : Date;
  record_only_complete_date:Date;
  order_service_complete_date:Date;
  required_fields_missing:Date;
  final_billing_date: Date;
}
