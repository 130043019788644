import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BaseService} from '../../core/base.service';
import {DictionaryQuery} from "./dictionary.query";
import {LookupModel} from "./lookup.model";
import {LOOKUP_MODELS_ENUM} from "./lookup-models.enum";
import {HttpClient} from "@angular/common/http";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import Query from 'app/core/query/query';
import {ApiService} from "../../core/api";
import {map} from "rxjs/operators";

@Injectable()
export class DictionaryService extends BaseService<LookupModel> {

  constructor(public restangular: Restangular,
              private http: HttpClient,
              private apiService: ApiService,
              private entityLock: EntityLockService) {
    super('dictionary', restangular, entityLock);
  }

  getByLookup(lookup: string, query?: DictionaryQuery, disableCache?): any {

    let concreteQuery = (query || new DictionaryQuery());
    let transformedQuery = concreteQuery.transform();
    return this.apiService.get(`dictionary/lookup/${lookup}`, transformedQuery)
  };


  findLookupById(lookup: string, id: number): any {
    return this.service().all(this.name).one('lookup', lookup).one('one', id).get();
  };

  findLookupByIdForEdit(lookup: string, id: number) {
    return this.service()
      .all(this.name)
      .one('lookup', lookup).one('one', id)
      .one('edit', null)
      .get()
  }

  findFromAllLookups(query?: Query) {
    return this.service().all(this.name).all('lookups').customGET(null, this.toFilter(query));
  }


  createLookup(lookup: string, item): any {
    return this.service().all(this.name).one('lookup', lookup).customPOST(item);
  }

  updateLookup(lookup: string, id: number, item): any {
    return this.service().one(this.name, id).one('lookup', lookup).customPUT(item).pipe(map((result) => {
      this.completeEdit();
      return result;
    }));
  }

  removeLookup(lookup: string, id: number) {
    return this.service().one(this.name, id).one('lookup', lookup).remove();
  }


  getLookupModelInfo(modelName: string) {
    let lookup;
    for (let i in LOOKUP_MODELS_ENUM) {
      if (LOOKUP_MODELS_ENUM[i].modelName === modelName) {
        lookup = LOOKUP_MODELS_ENUM[i]
      }
    }
    return lookup;
  }
}
