import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';
import {UpdateInventoryQuery,} from './inventory.actions';

import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../common/state/queryable.state';
import {QueryBuilder} from "../../common/query/query.builder";

const INVENTORY_STATE_TOKEN = new StateToken<InventoryStateModel>('inventory');

export interface InventoryStateModel extends QueryableStateModel<any> {

}

@State<InventoryStateModel>({
  name: INVENTORY_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
export class InventoryState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  @Action(UpdateInventoryQuery)
  updateInventoryQuery(ctx: StateContext<InventoryStateModel>, {options}): void {
    return this.updateQuery(ctx, {options});
  }

}
