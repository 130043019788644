import {UpdateQueryOptions} from "../../../../common/state/query.actions";

export class UpdateInvoiceNoteContactQuery {
  static readonly type = '[INVOICE_NOTE_CONTACT] Update Query';
  constructor(public options?: UpdateQueryOptions) {
  }
}

export class UpdateInvoiceNoteContactSiderSection {
  static readonly type = '[INVOICE_NOTE_CONTACT] Update active sider section';
  constructor(public name: string) {}
}
