import {ICsvInterceptor} from '../../core/csv/csv-interceptor';

export class ChargeCsvInterceptor implements ICsvInterceptor {
  transform(item: any) {
    let copy = Object.assign({}, item);
    if(copy.charge_dispute) {
      copy.charge_dispute.dispute_withheld = item.charge_dispute.dispute_withheld ? 'Yes' : 'No';
      copy.charge_dispute.disputed_amount = '$' + item.charge_dispute.disputed_amount;
      copy.charge_dispute.calculated_amount = '$' + item.charge_dispute.calculated_amount;
    }
    return copy;
  }
}
