
import {of as observableOf, Observable} from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../core/user.service';
import { UserManageDialogComponent } from '../shared/user-manage-dialog/user-manage-dialog.component';
import { User } from '../core/user';
import { DialogService } from '../../shared/dialog/dialog.service';
import { PageListComponent } from '../../core/page-list.component';
import { UserQuery } from '../core/user.query';
import { Router } from "@angular/router";
import { UserGridService } from "../core/user-grid.service";
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";

import { UserCsvInterceptor } from "../core/user-csv-interceptor";
import { PageContext } from "../../core/page.context";
import { UserSettingsService } from "../core/user-settings.service";
import { LoaderService } from "../../shared/loader/loader.service";
import {DataLockDialogService} from "../../core/data-lock/data-lock-dialog.service";
import { UserFilterService } from "../core/user-filter.service";
import {AlertService} from "../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../core/resources/resources.service";
import { GridService } from '../../shared/grid/grid.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
})
export class UserListComponent extends PageListComponent implements OnInit {

  @ViewChild('grid') grid;

  public users: Array<User>;
  public total: number;
  public selectedRowKeys: Array<any> = [];
  public selection: User;
  public query = new UserQuery({
    orderBy: [['first_name', 'ASC']]
  });
  public columns: Array<any>;
  public sorting: any[][];
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  readonly LOCALS = {
    USER_STATUS_UPDATED: 'User status updated'
  };
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "users";

  constructor(public userService: UserService,
              public userGridService: UserGridService,
              public settingsService: UserSettingsService,
              public userFilterService: UserFilterService,
              public dialog: DialogService,
              public router: Router,
              public loaderService: LoaderService,
              public alertService: AlertService,
              public dataLockDialogService: DataLockDialogService) {
    super(new PageContext({
      name: "app.user.user-list",
      settings: settingsService
    }));
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

  }

  loadData(query?: UserQuery) {

    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.userService.findAll(query)
      .subscribe(
        (result) => {
          this.users = result.items;
          query.total = this.total = result.total;

          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
          this.selectFirstRow();
        },
        (error) => {
          console.log('Error getting Users: List', error);
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        }
      );
  }

  showDetails() {
    let user = this.selection;
    if (user) {
      this.dialog.open(UserManageDialogComponent, {
        user: user,
        viewOnly: true
      }, {width: '900px'})
        .afterClosed()
        .subscribe((result) => {
          this.afterModalClosed(result, user);
        });
    }
  }

  afterModalClosed(result, user) {
    if (result) {
      Object.assign(user, result);
      this.refresh();
      this.alertService.success("", this.messages.get('UPDATE_SUCCESS'));
    }
  }

  refresh() {
    this.loadData(this.query);
  }

  filterData(query: UserQuery) {
    this.query = query;
    this.loadData(query);
  }

  onPageChange(query: UserQuery) {
    this.loadData(query);
  }

  clearFilter() {
    this.query.where = null;
    this.loadData(this.query);
  }

  newUser() {
    this.dialog.open(UserManageDialogComponent, {
      user: new User({})
    }, {width: '900px'})
      .afterClosed()
      .subscribe(result => {
        if (result) {
          // this.reset();
          this.refresh();
        }
      });
  }

  editUser(user) {

    this.dialog.edit(
      UserManageDialogComponent,
      {
        user: this.userService.findByIdForEdit(user.id),
        editMode: true
      }, {width: '900px'})
      .subscribe((items: any) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(mergeMap(x => x.afterClosed()))
            .subscribe((result) => {
              if (result) {
                this.refresh();
                this.alertService.success("", this.messages.get('UPDATE_SUCCESS'));
              }
            })
        }
      })
  }

  onSelectionChanged(row) {
    this.selection = <User>row.selectedRowsData[0];
    if (this.selection && !(this.selection.avatar === null))
      this.getUserAvatar(this.selection.id);
  }

  getUserAvatar(userId) {
    this.userService.findById(userId).subscribe(result => {
      if (this.selection) {
        this.selection.avatar = result.avatar;
      }
    })
  }

  csv() {

    const { userGridService, userService, query } = this;
    userGridService.csvMap().subscribe(fields => {
      const userTransformMiddleware = (items) => {
        const userCSVInterceptor = new UserCsvInterceptor();
        return items.map(item => userCSVInterceptor.transform(item));
      }

      userService.exportToCSV(
        'users',
        {
          fields,
          query,
          middleware: [userTransformMiddleware],
        }
      );
    });
  }

  ngAfterViewInit(): void {
    this.userGridService.create(this.dataGrid.instance, {});
    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  ngOnInit() {
    this._init();
  }

  gridSettings() {
    this.dialog.open(GridSettingsComponent, {
      service: this.userGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.userGridService;
  }

  getDefaultSort(): any {
    return  [['first_name', 'ASC']];
  }
}
