<div class="mat-dialog-top-spacer"></div>
<div class="ca-dialog-title">
    <h1 mat-dialog-title>Service Installed</h1>    
</div>

<div mat-dialog-content class="ca-dialog-content">     
  <form class="ca-form" [formGroup]="form">
    <div class="flex-container" fxLayout="row wrap" fxLayoutGap="24px">
      <div class="flex-item" fxFlex="50%">
        <ca-date-picker caInput placeholder="Date" formControlName="service_install_date" required="true"></ca-date-picker>
      </div>
    </div>
  </form>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="submit" (click)="cancel()">
      Cancel
  </button>
  <button mat-button [disabled]="form.invalid"
          type="button"
          (click)="submit(form)">
    Save
  </button>
</mat-dialog-actions>
