import { Injectable } from '@angular/core';
import { CustomerService } from 'app/customer/core/customer.service';
import { CurrencyLookupProvider } from 'app/location/core/currency-lookup.provider';
import { LocationService } from 'app/location/core/location.service';
import { AccountLookupProvider } from '../../account/core/account-lookup.provider';
import { AccountService } from '../../account/core/account.service';
import { SubclientLookupProvider } from "../../account/core/subclient-lookup.provider";
import { FilterService } from '../../core/filter/filter.service';
import Query from '../../core/query/query';
import { CustomerLookupProvider } from '../../customer/core/customer-lookup.provider';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { LookupModelLookupProvider } from '../../dictionary/core/lookup-model-lookup.provider';
import { LOOKUP_MODELS_ENUM } from '../../dictionary/core/lookup-models.enum';
import { SiteLookupProvider } from '../../site/core/site-lookup.provider';
import { SiteService } from '../../site/core/site.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { VendorLookupProvider } from '../../vendor/core/vendor-lookup.provider';
import { VendorService } from '../../vendor/core/vendor.service';
import { inventoryFilterContext } from './inventory-filter.context';
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class InventoryFilterService extends FilterService {
  customRanges: any = [
    {
      label: 'Last 2 years'
    },
    {
      label: 'Last 6 months'
    }
  ];

  excludeRanges: any = [
    {
      label: 'Prior month'
    },
    {
      label: 'Last week'
    }
  ];

  constructor(
    public dictionaryService: DictionaryService,
    public siteService: SiteService,
    public vendorService: VendorService,
    public userSettingsService: UserSettingsService,
    public accountService: AccountService,
    public customerService: CustomerService,
    public locationService: LocationService
  ) {
    super(
      'app.inventory.inventory-filter',
      inventoryFilterContext,
      userSettingsService
    );
    this.addLookupProvider(
      'vendor',
      new VendorLookupProvider(vendorService, true)
    );
    this.addLookupProvider('site', new SiteLookupProvider(siteService, false));
    this.addLookupProvider(
      'inventoryStatus',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.INVENTORY_STATUS.modelName
      )
    );
    this.addLookupProvider(
      'circuitType',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.INVENTORY_CKT_TYPE.modelName,
        true,
        true
      )
    );
    this.addLookupProvider(
      'circuitTech',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName,
        true,
        true
      )
    );
    this.addLookupProvider(
      'bandwidth',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.BANDWIDTH.modelName,
        true,
        true,
        new Query({ limit: 1000 })
      )
    );

    this.addLookupProvider(
      'account',
      new AccountLookupProvider(accountService)
    );

    this.addLookupProvider('customer', new CustomerLookupProvider(customerService));
    this.addLookupProvider(
      'defaultDateRanges',
      new DefaultDateRangesLookupProvider(
        this.customRanges,
        this.excludeRanges,
        true
      )
    );
    this.addLookupProvider(
      'networkType',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.INVENTORY_TYPE.modelName,
        true,
        true
      )
    );
    this.addLookupProvider('currency',new CurrencyLookupProvider(locationService))
    this.addLookupProvider('subclient', new SubclientLookupProvider(dictionaryService));
  }
}
