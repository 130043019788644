export const invoiceChargeFilterContext = {
  filters: [
    {
      field: 'chg_class',
      label: 'Charge Type',
      type: 'list',
      lookupProviderName: 'chargeType',
      index: 0
    },
    {
      field: 'direct_gl_stamp',
      label: 'Direct GL',
      type: 'boolean',
      index: 1
    },
    {
      field: 'charge_coded',
      label: 'GL Coded',
      type: 'boolean',
      trueLabel: 'Coded',
      falseLabel: 'Uncoded',
      index: 1
    },
    {
      field: 'chg_amt',
      label: 'Charge Amount',
      type: 'negativeNumber',
      index: 2
    }, {
      field: 'sp_serv_id',
      label: 'Service ID',
      type: 'string',
      index: 3
    },
    {
      field: 'rel_sp_fac_id',
      label: 'Related Service ID',
      type: 'string',
      index: 4,
    },
    {
      field: 'chg_desc_1',
      label: 'Charge Description 1',
      type: 'string',
      index: 5
    },
    {
      field: 'chg_desc_2',
      label: 'Charge Description 2',
      type: 'string',
      index: 6
    },
    {
      field: 'chg_code_1',
      label: 'Charge Code1',
      type: 'string',
      index: 7
    },
    {
      field: 'chg_code_2',
      label: 'Charge Code 2',
      type: 'string',
      index: 8
    },
    {
      field: 'svc_establish_date',
      label: 'Install Date',
      type: 'date',
      lookupProviderName: 'defaultDateRanges',
      index: 9
    },
    {
      field: 'beg_chg_date',
      label: 'Beg Charge Date ',
      type: 'date',
      lookupProviderName: 'defaultDateRanges',
      index: 10
    },
    {
      field: 'end_chg_date',
      label: 'End Charge Date ',
      type: 'date',
      lookupProviderName: 'defaultDateRanges',
      index: 11
    },
    {
      field: 'superusoc',
      label: 'Super Usoc',
      type: 'string',
      index: 12
    },
    {
      field: 'super_usoc_map.susoc_1',
      label: 'Segment Net Element',
      type: 'string',
      index: 13
    },
    {
      field: 'super_usoc_map.susoc_2',
      label: 'Segment Tech',
      type: 'string',
      index: 14
    },
    {
      field: 'super_usoc_map.susoc_3',
      label: 'Segment Qty',
      type: 'string',
      index: 15
    },
    {
      field: 'super_usoc_map.susoc_4',
      label: 'Segment Jur',
      type: 'string',
      index: 16
    },
    {
      field: 'super_usoc_map.susoc_5',
      label: 'Segment Primary/Feat',
      type: 'string',
      index: 17
    },
    {
      field: 'info_only_ind',
      label: 'Info Only',
      type: 'boolean',
      index: 18
    },
    {
      field: 'chg_qty1_used',
      label: 'Chg Qty Used',
      type: 'negativeNumber',
      index: 19
    },
    {
      field: 'chg_qty2_used',
      label: 'Chg Qty Used 2',
      type: 'negativeNumber',
      index: 20
    },
    {
      field: 'chg_qty1_allowed',
      label: 'Charge Qty Allowed',
      type: 'string',
      index: 21
    },
    {
      field: 'fac_bw',
      label: 'Facility Bandwidth',
      type: 'negativeNumber',
      index: 23
    },
    {
      field: 'site_a_addr_1',
      label: 'Address',
      type: 'string',
      index: 24
    },
    {
      field: 'site_a_addr_city',
      label: 'City',
      type: 'string',
      index: 25
    },
    {
      field: 'site_a_addr_st',
      label: 'State',
      type: 'string',
      index: 26
    },
    {
      field: 'btn',
      label: 'BTN',
      type: 'string',
      index: 27
    },
    {
      field: 'acct_level_2',
      label: 'Subaccount',
      type: 'string',
      index: 28
    },
    {
      field: 'prod_type',
      label: 'Prod Type',
      type: 'string',
      index: 29
    },
    {
      field: 'share_ind',
      label: 'Share Ind',
      type: 'string',
      index: 30
    },
    {
      field: 'chg_qty1_billed',
      label: 'Chg Qty',
      type: 'negativeNumber',
      index: 31,
      visible: false
    },
    {
      field: 'call_type',
      label: 'Call Type',
      type: 'string',
      index: 32
    },
    {
      field: 'dir_ind',
      label: 'Dir Ind',
      type: 'string',
      index: 33
    },
    {
      field: 'jur',
      label: 'Jurisdiction',
      type: 'string',
      index: 34
    },
    {
      field: 'curr_prir_ind',
      label: 'Curr Prir Ind',
      type: 'string',
      index: 35
    },
    {
      field: 'chg_qty1_type',
      label: 'Chg Qty Type',
      type: 'string',
      index: 36
    },
    {
      field: 'activity_comp_date',
      label: 'Activity Comp Date',
      type: 'date',
      lookupProviderName: 'defaultDateRanges',
      index: 37
    },
    {
      field: 'currency',
      label: 'Currency',
      type: 'string',
      index: 39
    },
    {
      field: 'rate_period',
      label: 'Rate Period',
      type: 'string',
      index: 40
    },
    {
      field: 'roam_ind',
      label: 'Roam Ind',
      type: 'string',
      index: 41
    },
    {
      field: 'fac_bw_unit_type',
      label: 'Fac Bw Unit Type',
      type: 'string',
      index: 42
    },
    {
      field: 'original_sp_serv_id',
      label: 'Original Sp Serv ID',
      type: 'string',
      index: 43
    },
    {
      field: 'site_a_addr_zip',
      label: 'Zip',
      type: 'string',
      index: 44,
      matchCase: false,
      exactMatch: false
    },
    {
      field: 'chg_rate',
      label: 'Charge Rate',
      type: 'negativeNumber',
      index: 45
    },
    {
      field: 'chg_qty2_type',
      label: 'Chg Qty Type 2',
      type: 'string',
      index: 46
    },
    {
      field: 'unset_adjustment.key',
      label: 'Unset Adjustments',
      type: 'list',
      lookupProviderName: 'unsetAdjustment',
      index: 47
    },{
      label: 'Dispute Calculated Amount',
      field: 'charge_dispute.calculated_amount',
      type: 'negativeNumber',
      index: 48
    },
    {
      label: 'Disputed Amount',
      field: 'charge_dispute.disputed_amount',
      type: 'negativeNumber',
      index: 49
    },
    {
      label: 'Withheld Status',
      field: 'charge_dispute.dispute_withheld',
      type: 'boolean',
      index: 50
    }
  ]
};
