
import {timer as observableTimer, Observable} from 'rxjs';
import {Type} from '@angular/core';
import {EntityLockData} from './entity-lock-data';
import {DialogService} from "../dialog/dialog.service";
import {Router} from "@angular/router";


const FACTOR = 60000;

export class EntityLock {

  static DATA_LOCK_CLOSE_STATUS = {
    CANCEL_BY_USER: 0,
    TIME_EXTEND: 1,
    CANCEL_BY_TIMER: 2
  };

  lockTimer: any;

  constructor(
    private lockData: EntityLockData,
    private dialogRef: any,
    private dialogService: DialogService,
    private component: Type<any>,
    private router: Router
  ) {
  }

  startTimer = (lockDuration: number) => {
    const component = this.component;
    const dialogRef = this.dialogRef;
    this.createTimer(lockDuration);
  }

  restartTimer(lockDuration: number) {
    this.killTimer();
    this.createTimer(lockDuration);
  }

  killTimer = () => {
    this.lockTimer.unsubscribe();
  };

  private createTimer(lockDuration: number) {
    const component = this.component;
    const dialogRef = this.dialogRef;

    this.lockTimer = observableTimer((lockDuration) * FACTOR)
      .subscribe(() => {
        let timeout = this.lockData['timeout'] ? this.lockData['timeout'] : null;
        this.dialogService.open(component, {closeCountdownSeconds: timeout})
          .afterClosed()
          .subscribe((data: any) => {

            if (this.dialogRef !== 'glRuleEdit') {
              if (data.status === EntityLock.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_USER) {
                dialogRef.close(data);
              }

              if (data.status === EntityLock.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_TIMER) {
                dialogRef.close(data);
              }

              if (data.status === EntityLock.DATA_LOCK_CLOSE_STATUS.TIME_EXTEND) {
                this.restartTimer(lockDuration);
              }
            }

            else {
              if (data.status === EntityLock.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_USER) {
                this.router.navigate(['/gl-rule']);
              }

              if (data.status === EntityLock.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_TIMER) {
                this.router.navigate(['/gl-rule']);
              }

              if (data.status === EntityLock.DATA_LOCK_CLOSE_STATUS.TIME_EXTEND) {
                this.restartTimer(lockDuration);
              }
            }
          });
      });
  }
}
