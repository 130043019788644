import { Injectable } from '@angular/core';
import {Restangular} from "ngx-restangular";
import {BaseService} from "../../base.service";
import {Tag} from "./tag";
import {EntityLockService} from "../../../shared/entity-lock/entity-lock.service";

@Injectable()
export class TagService extends BaseService<Tag> {

  constructor(public restangular: Restangular, private entityLock: EntityLockService) {
    super('tags', restangular, entityLock);
  }

}
