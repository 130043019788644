import {EventEmitter, Injectable, Type} from '@angular/core';
import {FilterTextComponent} from './filter-text/filter-text.component';
import {FilterBaseComponent} from './filter-base.component';
import {FilterEntry} from "../../core/filter/filter-entry";
import {FilterBooleanComponent} from "./filter-boolean/filter-boolean.component";
import {FilterNumberComponent} from "./filter-number/filter-number.component";
import {FilterNumberRangeComponent} from "./filter-number-range/filter-number-range.component";
import {FilterDateComponent} from "./filter-date/filter-date.component";
import {FilterCheckboxListComponent} from "./filter-checkbox-list/filter-checkbox-list.component";
import {FilterAutocompleteComponent} from "./filter-autocomplete/filter-autocomplete.component";
// import {FilterCheckboxListComponent} from './filter-checkbox-list/filter-checkbox-list.component';
// import {FilterAutocompleteComponent} from './filter-autocomplete/filter-autocomplete.component';
// import {FilterNumberRangeComponent} from './filter-number-range/filter-number-range.component';
// import {FilterDateComponent} from './filter-date/filter-date.component';
// import {FilterNumberComponent} from './filter-number/filter-number.component';
// import {FilterBooleanComponent} from './filter-boolean/filter-boolean.component';

@Injectable({
  providedIn: 'root'
})
export class FilterComponentRegistry {

  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  emit(key, value): void {
    return this.valueChanged.emit({key, value});
  }

  resolve(entry: FilterEntry): Type<FilterBaseComponent> {
    return this.resolveByType(entry);
  }

  resolveByType(entry: FilterEntry): Type<FilterBaseComponent> {
    const {type = 'string'} = entry;

    switch (type) {
      case 'string':
        return FilterTextComponent;
      case 'number':
        return FilterNumberComponent;
      case 'numberRange':
      case 'negativeNumber':
        return FilterNumberRangeComponent;
      case 'list':
        return FilterCheckboxListComponent;
      case 'lookup':
        return FilterAutocompleteComponent;
      case 'boolean':
        return FilterBooleanComponent;
      case 'date':
        return FilterDateComponent;
      default:
        return FilterTextComponent;
    }
  }
}
