import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class DictionaryGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.dictionary.dictionary-grid", userSettings);
  }

  getColumns() {
    return [{
      hidingPriority: 2,
      caption: 'Group',
      dataField: 'display_name',
      groupIndex: 0,
      groupCellTemplate: 'dictionaryGroupCellTemplate'
    }, {
      hidingPriority: 1,
      caption: 'Value',
      dataField: 'value',
    }];
  }
}
