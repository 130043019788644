export const inventoryRateFilterContext = {
  filters: [
    {
      field: 'rate_code',
      label: 'Rate Code',
      type: 'string',
      index: 1
    },
    {
      field: 'svc_chg_desc',
      label: 'Charge Desc',
      type: 'string',
      index: 2
    },
    {
      field: 'rate_category_obj.value',
      label: 'Rate Category',
      type: 'lookup',
      lookupProviderName: 'rateCategory',
      index: 3
    },
    {
      field: 'rate_qty',
      label: 'Rate Qty',
      type: 'negativeNumber',
      index: 4
    },
    {
      field: 'rate_qty_type',
      label: 'Rate Qty Type',
      type: 'string',
      index: 5,
      visible: true
    },
    {
      field: 'mrc',
      label: 'MRC',
      type: 'negativeNumber',
      index: 6
    },
    {
      field: 'country.alpha_2_code',
      label: 'Country ',
      type: 'lookup',
      editor: 'country',
      lookupProviderName: 'country',
      index: 1,
      visible: true
    },
    {
      field: 'rate_term_qty',
      label: 'Rate Term Qty',
      type: 'number',
      index: 8,
      visible: true
    }
    ]
}
