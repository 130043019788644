import {Injectable} from '@angular/core';
import {GridService} from "../../shared/grid/grid.service";
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class ContactGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.contact.contact-grid", userSettings);
  }

  defaultColumns = [{
    caption: "First Name",
    dataField: "first_name",
    width: 120
  }, {
    caption: "Last Name",
    dataField: "last_name",
    width: 130
  }, {
    caption: "Type",
    dataField: "contact_type.value",
    width: 130
  }, {
    caption: "Vendor",
    dataField: "vendor.name",
    width: 130
  }, {
    caption: "Customer",
    dataField: "customer.company",
    width: 130
  }, {
    caption: "Phone",
    dataField: "office_phone",
    cellTemplate: 'phoneTemplate',
    dataType: 'phone',
    csvDataType: 'phone',
    width: 130
  },
    {
      dataField: 'address.address1',
      caption: 'Address',
      width: 200
    },
    {
      dataField: 'address.city',
      caption: 'City',
      width: 160
    },
    {
      dataField: 'address.subdivision.abbreviation',
      caption: 'State',
      width: 160
    },
    {
      dataField: 'address.postal_code',
      caption: 'Postal Code',
      width: 140
    },
  ];


}
