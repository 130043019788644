import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NavigationService} from "./navigation.service";
import {NavigationModel} from "./navigation.model";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {Config} from "../../core/config/config";
import {ConfigService} from "../../core/config/config.service";
import {Observable, pipe} from 'rxjs';
import {mergeMap, map} from 'rxjs/operators';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnInit {

  entries: NavigationModel[] = [];
  @Output() onNavigationToggle = new EventEmitter();
  toShowWireless: any;

  public GROUP = {
    DASHBOARD: 'Dashboard',
    ADMIN: 'Admin',
    COST_ANALYSIS: 'Cost Analysis',
    APP_DATA: 'App Data',
    CONTRACTS: 'Contracts',
    GENERAL_LEDGER: 'GL',
    INVENTORY: 'Inventory',
    ORDERS: 'Orders',
    QUOTES: 'Quotes',
    WORK_MANAGEMENT: 'Work Management',
    USAGE_MANAGEMENT: 'Usage Management',
    REPORTING: 'Reports & Analysis',
    WIRELESS: 'Wireless'
  };

  constructor(public navigationService: NavigationService,
              public router: Router,
              public configService: ConfigService) {
  }

  loadConfig(): Observable<any> {
    return this.configService.findAll()
  }

  ngOnInit() {

    this.loadConfig().pipe(mergeMap((res) => {
        this.toShowWireless = res.show_wireless;
        return this.navigationService.load()
      }
    )).subscribe((entries: Array<any>) => {
      if (!this.toShowWireless) {
        let filteredEntryItems;
        let filteredEntries;
        if (entries) {
          filteredEntries = entries.filter((entry) => {
            if (entry && entry.items) {
              filteredEntryItems = entry.items.filter((e) => {
                return e && e.group !== this.GROUP.WIRELESS;
              });
              entry.items = filteredEntryItems;
              return entry;
            } else {
              return true;
            }
          });
          this.entries = filteredEntries;
        }
      } else {
        this.entries = entries;
      }
      this.navActivator();
    })


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.navActivator();
      }
    })


  }

  navActivator() {
    let [currentRoute] = this.router.url.split("/").filter(Boolean);
    let route = "/" + currentRoute;
    this.deselectItems();

    this.entries.map((entry) => {
      if (entry.value === route) {
        this.setLastClicked(entry);
        entry.selected = true;
      }

      entry.hasItems() && entry.items.map((item) => {
        if (item.value === route) {
          entry.open = true;
          this.setLastClicked(entry);
          item.selected = true;
        }
      })
    });
  }

  getVisibleEntries(entries = []) {
    return entries.filter(x => x.visible);
  }

  toggle(entry: NavigationModel) {
    entry.open = !entry.open;
    this.onNavigationToggle.emit();
    this.setLastClicked(entry);
  }

  closeAll() {
    this.navigationService.closeAll();
  }

  isSelected(entry: NavigationModel) {
    return entry.items ? entry.items.filter(item => item.selected).length > 0 : entry.selected;
  }

  deselectItems() {
    this.entries?.forEach(entry => {
      entry.selected = false;
      if (entry.items) {
        entry.items.forEach(item => item.selected = false);
      }
    });
  }

  setLastClicked(entry?: NavigationModel) {
    this.entries.forEach(entry => entry.focused = false);
    if (entry) {
      entry.focused = true;
    }
  }
}
