<div class="app-search">

  <button class="app-search-toggle" matTooltip="Search" mat-icon-button (click)="toggleSearch()">
    <ca-icon [key]="'SEARCH'"></ca-icon>
  </button>
  <div class="app-search-input ca-form" [ngClass]="{'in-focus': isInFocus }" [style.width]="searchBoxWidth">
    <mat-form-field floatPlaceholder="never" class="ca-form-input-inline">
      <input matInput #searchInput
             type="text"
             [(ngModel)]="searchValue"
             [focus]="searchFormControlFocus"
             (focusout)="closeForm()"
             (keydown)="onSearchKeydown($event)"
             [disabled]="isInFocus"
             [formControl]="searchFormControl"
             [matAutocomplete]="auto">
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" [class]="'app-search-dropdown'" mat-direction="right"  >
      <div class="scrollable-container" (scroll)="onScrollHandler($event)"  style="max-height: 350px; overflow-y: scroll">
        <mat-option class="search-result-entry" *ngFor="let result of searchResults;" (onSelectionChange)="resultSelect(result)"
                   mat-direction="right" [value]="result">

          <ng-container *ngIf="result?.type !== 'command'">
            <div fxLayout fxLayoutAlign="flex-start center">
              <ca-icon class="search-icon-display-flex" [key]="resultConfiguration[result?.group]?.icon"></ca-icon>
              <span>
                <b>{{resultConfiguration[result?.group]?.title}}</b>
                {{result.id}}
              </span>

              &nbsp; - &nbsp;

              <span [ngStyle]="{'text-overflow': 'ellipsis', 'max-width' : '100%','overflow': 'hidden'}" >
              {{result.field}}
              </span>
              <span *ngFor="let item of resultConfiguration[result?.type]?.text; let i = index">
                <b *ngIf="item.label">{{item.label}}:</b>
                <span *ngIf="!item.type">{{ result.entity[item.field] || 'N/A'}} </span>
<!--                <span *ngIf="item.type=='truncate' && result.entity[item.field]">{{ result.entity[item.field] | truncate: item.format : ''}} </span>-->
                <span
                  *ngIf="item.type=='date' && result.entity[item.field]">{{ result.entity[item.field] | date}} </span>
                <span *ngIf="i<resultConfiguration[result?.type]?.text?.length-1"> - </span>
              </span>
            </div>
          </ng-container>

          <ng-container *ngIf="result?.type === 'command'">
            {{result.text}}
          </ng-container>

        </mat-option>
      </div>
    </mat-autocomplete>
  </div>
</div>
