
  <div class="ca-form" style="margin-bottom: 1.85em;">
    <div fxFlex class="form-group">
      <ca-default-picker hiDensityInput>
        <mat-form-field>
          <mat-select
            placeholder="Capacity"
            [(ngModel)]="selectedCapacity"
            (ngModelChange)="handleHubCapacityChange($event)"
          >
            <mat-option *ngFor="let val of networkHubTypes" [value]="val"
            >{{ val.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ca-default-picker>
    </div>
  </div>
  <form [formGroup]="form">
  <div class="ca-form" style="margin-bottom: 1.85em;">
    <ng-container *ngIf="selectedCapacity?.id !== NETWORK_HUB_CAPACITY.DS_3">
      <ca-network-hub-circuit-picker
        [pickerForm]="form"
        formControlName="level_1"
        style="width: 50%;"
        caInput
        placeholder="Level 1"
        (onSelectionChange)="onLevel1Change($event)"
        showInventory="true"
        typeLabel="DS3"
        [parentId]="networkHubID"
        [disabled]="!selectedCapacity"
        [hubId]="networkHubID"
        (fullEntryListChanged)="level1FullEntryListChanged($event)"
      >
      </ca-network-hub-circuit-picker>

      <span fxFlex="10px"></span>

      <ca-network-hub-circuit-picker
        style="width: 50%;"
        caInput
        [pickerForm]="form"
        formControlName="level_2"
        placeholder="Level 2"
        (onSelectionChange)="onLevel2Change($event)"
        typeLabel="DS1"
        [parentId]="networkHubID"
        [disabled]="!selectedCapacity"
        [hubId]="networkHubID"
      >
      </ca-network-hub-circuit-picker>
    </ng-container>

    <ng-container *ngIf="selectedCapacity?.id === NETWORK_HUB_CAPACITY.DS_3">
      <ca-network-hub-circuit-picker
        style="width: 50%;"
        caInput
        [pickerForm]="form"
        formControlName="level_1"
        placeholder="Level 1"
        (onSelectionChange)="onLevel1Change($event)"
        showInventory="true"
        typeLabel="DS1"
        [parentId]="networkHubID"
        [disabled]="!selectedCapacity"
        [hubId]="networkHubID"
      >
      </ca-network-hub-circuit-picker>

      <span fxFlex="10px"></span>

      <ca-network-hub-circuit-picker
        style="width: 50%;"
        caInput
        [pickerForm]="form"
        formControlName="level_2"
        placeholder="Level 2"
        (onSelectionChange)="onLevel2Change($event)"
        [parentId]="networkHubID"
        [disabled]="!selectedCapacity"
        [hubId]="networkHubID"
      >
      </ca-network-hub-circuit-picker>
    </ng-container>
  </div>
</form>
