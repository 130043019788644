import { Injectable } from '@angular/core';
import { GridService } from "../../shared/grid/grid.service";
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class InventoryCodingGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.gl.gl-inventory-coding-grid", userSettings);
  }

  defaultColumns = [{
    caption: "Vendor",
    dataField: "vendor.name"
  },
  {
    caption: "BAN",
    dataField: "account_no"
  },
  {
    alignment: 'left',
    caption: "Status",
    dataField: "gl_inv_coding_enabled",
    cellTemplate: 'statusCellTemplate',
    width: 120
  }];

  /*columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
