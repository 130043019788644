<app-contract-details-toolbar
    [isInventoryTab]="true"
    [isUnlinkInventoryDisabled]="!selectedInventory.length"
    (addInventoryRequested)="addInventory()"
    (unlinkInventoryRequested)="removeInventory()"
    (csvRequested)="inventoryCsv()"
    (gridSettingsRequested)="inventoryGridSettings()"
    [isFilterDisabled]="false"
    [isFilterOn] = "this.sider.getActiveSection().name === this.SECTIONS.FILTER_SECTION_NAME"
    (toggleFilterRequested)="toggleFilter()"
    [notesCount]="notesCount"
    [isNotesOn] = "this.sider.getActiveSection().name === this.SECTIONS.NOTES_SECTION_NAME"
    (toggleNotesRequested)="toggleNotes()"
    [isHistoryOn] = "this.sider.getActiveSection().name === this.SECTIONS.HISTORY_SECTION_NAME"
    (toggleHistoryRequested)="toggleHistory()"
>

</app-contract-details-toolbar>

<mat-sidenav-container class="app-content app-content-list tabbed-content dx-nodata-absolute">
    <app-contract-details-tabs [activeTabIndex]="3" [contract]="contract"></app-contract-details-tabs>

    <div class="app-content app-content-list"
      style="top: 49px;">
      <dx-data-grid
        #inventoryGrid
        (onRowClick)="onRowClick($event)"
        (onSelectionChanged)="onSelectionInventoryChanged($event)"
        [columns]="inventoryColumns"
        [dataSource]="inventories"
        class="app-grid-with-pager first-column"
        [width]="'100%'"
        [height]="'100%'"
        [showRowLines]="true"
        [showColumnLines]="false"
        [allowColumnResizing]="true"
        [columnMinWidth]="50"
        [columnAutoWidth]="true"
        (onCellClick)="onInventoryCellClick($event)">

        <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
          <a class="selection-badge grid-badge" matTooltip="Selection" (click)="loadSelected()" [ngClass]="{'active':selectionActive}">
            {{selectedInventory.length}}
          </a>
        </div>
        <div *dxTemplate="let d of 'selectionBadgeCellTemplate'"></div>
        <div *dxTemplate="let d of 'installDateTemplate'">
          <div caGridTooltip>{{ d.value | caDate }}</div>
        </div>
        <div *dxTemplate="let d of 'disconnectDateTemplate'">
          <div caGridTooltip>{{ d.value | caDate }}</div>
        </div>
        <div *dxTemplate="let d of 'expDateTemplate'">
          <div caGridTooltip>{{ d.value | caDate }}</div>
        </div>
        <div *dxTemplate="let d of 'currencyTemplate'">
          <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.currency_obj?.currency }}</div>
        </div>

        <dxo-selection mode="multiple" [allowSelectAll]="'false'"></dxo-selection>
        <dxo-paging [enabled]="false" visible="false"></dxo-paging>
        <dxo-sorting mode="none"></dxo-sorting>
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
      </dx-data-grid>
      <ca-pager
        [gridPager]="defaultGridPager"
        class="app-pager ca-default-border-top"
        [pageContext]="inventoryGridService"
        [query]="inventoryQuery"
        (onChange)="loadInventories()">
      </ca-pager>
    </div>
    <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false" (close-start)="panelClose()">
      <new-app-filter
        [ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
        [query]="inventoryQuery"
        [filterService]="inventoryFilterService"
        (filterRequested)="loadInventories($event)"
        (clearFilterRequested)="clearInventoryFilter($event)"
      >
      </new-app-filter>

      <ca-notes [entityId]="contract?.id" [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                [entityType]="SYSTEM_MODULE.CONTRACT" (countChanged)="onNotesCountChanged($event)"></ca-notes>

      <ca-history #history
                  [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
                  [entityId]="contract?.id"
                  [entityType]="'contract'"
      ></ca-history>
    </mat-sidenav>
</mat-sidenav-container>
