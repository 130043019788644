import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {BaseService} from "../../core/base.service";
import {Restangular} from "ngx-restangular";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {ApiService} from "../../core/api";
import {Intuit} from "./intuit";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable()
export class IntuitService extends BaseService<Intuit> {

  constructor(
    public restangular: Restangular,
    private entityLock: EntityLockService,
    private apiService: ApiService,
    private http: HttpClient
  ) {
    super('intuit', restangular, entityLock);
  }

  public service() {
    return this.restangular;
  }

  destroyToken() {
    return this.apiService.delete([this.name, 'destroy-token']);
  }

  authorize() {
    const endPoint: string = [this.name, '/auth'].join('');
    return this.http.get(`${environment.API_URL}${endPoint}`);
  }

  sendAuthParamsAndGenerateToken(url: any) {
    return this.apiService.post([this.name, 'generate-token'], {url});
  }

  validateToken(): Observable<any> {
    return this.apiService.post([this.name, 'validate-token']);
  }

  checkIntuitBillStatus(id: number): any {
    return this.service().one(`${this.name}/check-bill-status`, id).get();
  }
}
