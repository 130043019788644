import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class InventoryContractTermGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.inventory.inventory-contract-term-grid", userSettings);
  }

  columns() {
    return [
      {
        caption: 'Contract Type',
        dataField: 'contract_type.value',
        width: 150
      },
      {
        caption: 'Contract',
        dataField: 'contract_shell.name',
        width: 150
      },
      {
        caption: 'Contract Schedule',
        dataField: 'contract_schedule.schedule_name',
        width: 150
      },
      {
        caption: 'Order',
        dataField: 'svc_header.name',
        width: 150
      },
      {
        caption: 'Svc Order Service',
        dataField: 'svc_service.description',
        width: 150
      },
      {
        caption: 'MRC',
        dataField: 'mrc',
        cellTemplate: 'currencyTemplate',
        width: 150
      },
      {
        caption: 'NRC',
        dataField: 'nrc',
        cellTemplate: 'currencyTemplate',
        width: 150
      },
      {
        caption: 'Term',
        dataField: 'term',
        width: 150
      },
      {
        caption: 'Currency',
        dataField: 'currency.currency',
        width: 150
      },
      {
        caption: 'Start Billing Date',
        dataField: 'start_billing_date',
        width: 150,
        dataType: 'date',
        cellTemplate: 'dateTemplate'
      },
      {
        caption: 'Expiration Date',
        dataField: 'expiration_date',
        width: 150,
        dataType: 'date',
        cellTemplate: 'dateTemplate'
      }
    ];
  }

}
