import { ViewChild, ViewChildren, QueryList, Directive } from '@angular/core';
import { MatButton } from '@angular/material/button';


@Directive()
export class DialogButtonsController {
  @ViewChild('saveButton') saveButton: MatButton;
  @ViewChild('cancelButton') cancelButton: MatButton;
  @ViewChildren('genericButton') genericButtons: QueryList <MatButton>;

  /**
   * Sets Save, Cancel and Delete button disabled property
   *
   * @protected
   * @param {boolean} [value=true]
   * @memberof DialogButtonsController
   */
  protected toggleDialogButtons(value = true): void {
    if (this.saveButton) {
      this.toggleSaveButton(value);
    }
    if (this.cancelButton) {
      this.toggleCancelButton(value);
    }
    if (this.genericButtons.length){
      this.toggleGenericButtons(value)
    }
  }

  /**
   * Sets Save button disabled property
   *
   * @private
   * @param {boolean} value
   * @memberof DialogButtonsController
   */
  private toggleSaveButton(value: boolean): void {
    this.saveButton.disabled = value;
  }

  /**
   * Sets Cancel button disabled property
   *
   * @private
   * @param {boolean} value
   * @memberof DialogButtonsController
   */
  private toggleCancelButton(value: boolean): void {
    this.cancelButton.disabled = value;
  }

  /**
   * Sets Generic buttons disabled property
   *
   * @private
   * @param {boolean} value
   * @memberof DialogButtonsController
   */

   private toggleGenericButtons(value: boolean): void {
     this.genericButtons.forEach(genericButton => genericButton.disabled = value)
   }


}
