import { DialogService } from '../../../shared/dialog/dialog.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-disconnect-complete-date',
  templateUrl: './disconnect-complete-date.component.html',
})
export class DisconnectCompleteDateComponent extends PageManageDialogComponent implements OnInit {

  public disconnect_complete_date: Date;

  constructor(public formBuilder: FormBuilder,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<DisconnectCompleteDateComponent>) {
    super(dialogService, dialogRef);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      disconnect_complete_date: [null, Validators.required],
    });
  }

  submit({ value, valid }: { value: any, valid: boolean }) {
    this.dialogRef.close({
      disconnect_complete_date: value.disconnect_complete_date,
    });
  }

  cancel() {
    this.closeDialog();
  }

}
