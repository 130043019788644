import {SortingBuilder} from './../shared/sorting/sorting.service';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import {Observable, Subject} from 'rxjs';
import {AfterViewInit, Directive, HostListener, OnInit, ViewChild} from '@angular/core';

import {PageContext} from './page.context';
import {CanComponentDeactivate} from './guard/can-deactivate-guard.searvice';
import {Sider, SiderSection, SiderSettings} from './sider/sider';
import {GridService} from '../shared/grid/grid.service';

export interface ListInfo {
  columns: Array<any>,
  sorting: Array<any>,
  query: any,
  limit: number
}

// @ts-ignore
@Directive({
  selector: 'page-list-component'
})
export class PageListComponent implements OnInit, AfterViewInit, CanComponentDeactivate {
  @ViewChild('panelSide') panelSide;

  public currentSortIndex = -1;
  public originalCaption;
  public settings: any;
  public hasUnsavedChanges = false;

  readonly DISCARD_CHANGES: string = 'Discard changes';

  public SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    HISTORY_SECTION_NAME: "history",
    NOTES_SECTION_NAME: 'notes',
  };

  public sider: Sider;

  public defaultGridPager: number;

  destroy$: Subject<boolean> = new Subject<boolean>();

  public detailsAvailable = false;
  public columns: Array<any>;
  public sorting: any;
  public query: any;
  private sortBuilder = new SortingBuilder();
  private readonly RestrictPageContextLoadData = ["app.cost.charge-grid"];

  static prepareList({gridService, defaultSort}): ListInfo {
    const columns = gridService.columns();
    const sorting = gridService.sorting();
    const query: any = {
      limit: gridService.getGridPager()
    };

    if (sorting.length) {
      query.orderBy = sorting;
    } else {
      query.orderBy = defaultSort;
    }

    return {
      columns,
      sorting,
      query,
      limit: query.limit
    };
  }

  resource(action) {
    return [this.context.resource, action].join(':')
  }

  // override this in child class
  loadData(query) {
  }

  getDefaultSort(): any {
    return [['id', 'DESC']];
  }

  getGridService(): GridService {
    throw new Error('getGridService not implemented');
  }

  constructor(
    public context: PageContext,
    public dataGrid?: DxDataGridComponent,
  ) {
    this.sider = new Sider(new SiderSettings([], this.panelSide));
  }


  ngOnInit(): void {
  }


  protected prepareList() {
    const gridService = this.getGridService();
    gridService.loadSettings()
      .subscribe((settings) => {
        this.sorting = settings ? settings.sorting : [];
        this.columns = gridService.getColumns(settings ? settings.columns : []);
        this.defaultGridPager = settings ? settings.gridPager : 20;
        this.query.limit = this.defaultGridPager;

        if (this.sorting && this.sorting.length) {
          this.query.orderBy = gridService.getOrderBy(this.sorting);
        }

        if (this.dataGrid && this.dataGrid.instance) {
          this.dataGrid.instance.option('columns', this.columns);
        }

        if (!this.RestrictPageContextLoadData.includes(gridService.name)) {
          this.loadData(this.query);
        }
      });
  }

  _init() {
    this.prepareList();
  }

  loadSettings() {
    this.context.loadSettings()
      .subscribe((data) => {
        this.settings = data;
        this.createSider();
        this.applySettings();
      })
  }

  createSider() {
    this.sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    this.sider.onChange((section: SiderSection) => {
      this.saveSettings({siderSection: section.name});
    });
  }


  ngAfterViewInit(): void {
    this.loadSettings();

    setTimeout(() => {
      if (this.panelSide) {
        this.panelSide.disableClose = true;
      }
    })
  }

  public resetDataPager() {
    this.defaultGridPager = 20;
  }

  public applySettings() {
    this.sider.toggle(this.settings['siderSection']);
  }

  saveSettings(data) {
    if (this.context && this.context.settings) {
      this.context.settings.save(this.context.name, data);
    }
  }

  public isVisibleSideSection(name): boolean {
    return this.sider ? this.sider.isActive(name) : false;
  }

  onSelectionChanged(row) {
    this['selection'] = row.selectedRowsData[0];
  }

  reset() {
    this['selection'] = null;
  }

  public showDetails(): void {

  };

  public onRowClick(row) {
    let component = row.component;
    let prevClickTime = component.lastClickTime;

    component.lastClickTime = new Date();

    if (prevClickTime && (component.lastClickTime - prevClickTime < 250)) {
      this.showDetails();
    } else {
      setTimeout(() => {
      }, 300);
    }
  }

  public onCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      const sorting = this.sortBuilder.buildSorting(event);
      this.sortColumn(sorting);
    }
  }


  sortColumn(sorting) {
    this['query'] = this['query'] || {};
    this['query']['orderBy'] = sorting;

    const self = this;

    if ('loadData' in this) {
      this['loadData'](this['query']);
      self.selectFirstRow();
    }
  };

  onPageChanged() {

    const self = this;

    if ('loadData' in this) {
      this['loadData'](this['query']);
      self.selectFirstRow();
    }
  }

  public selectFirstRow(data?) {
    setTimeout(() => {
      if (this.dataGrid) {
        let dataGridInstance = this.dataGrid.instance;
        if (dataGridInstance && dataGridInstance.totalCount()) {
          dataGridInstance.selectRowsByIndexes([0]);
          this['selection'] = dataGridInstance.getSelectedRowsData()[0];
        } else {
          this['selection'] = null;
        }
      }
    }, 500);
  }


  // CanDeactivateGuard
  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if no unsaved changes
    if (!this.hasUnsavedChanges) {
      return true;
    }

    // Otherwise ask the user with the dialog service and return its
    // Observable which resolves to true or false when the user decides
    return this['dialogService'].save({
      bodyText: this.DISCARD_CHANGES
    }).afterClosed();
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  windowBeforeUnload(): Observable<boolean> | boolean {
    return !this.hasUnsavedChanges;
  }
}
