import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {CommonAlertService} from "./common-alert.service";


@Injectable()
export class CommonAlertResolve implements Resolve<any> {
  constructor(public alertService: CommonAlertService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const alertId = route.params.id;
    return this.alertService.findById(alertId);

  }
}
