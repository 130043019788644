<div class="text-filter">
  <div class="ca-form ca-full-width" fxLayout="row" style="align-items: baseline">
    <mat-form-field hiDensityInput>
      <input
        [formControl]="input"
        matInput/>

      <div class="card-header-commands text-filter-icons"  fxFlex="1 0 auto">
        <a (click)="toggleExactMatch()"
           matTooltip="Exact match"
           [ngClass]="{'active': exactMatch}"
           class="input-command">
          <ca-icon [key]="'FILTER_EXACT'" [innerClass]="'iconForTextFilter'"></ca-icon>
        </a>
        <a (click)="toggleMatchCase()"
           matTooltip="Match case"
           class="input-command"
           [ngClass]="{'active': caseSensitive}"
        >
          <ca-icon [key]="'FILTER_CASE'" [innerClass]="'iconForTextFilter'"></ca-icon>
        </a>
      </div>
    </mat-form-field>
  </div>
</div>
