import {Component, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'ng2-validation';
import { of as observableOf } from 'rxjs';
import { Config } from '../../../core/config/config';
import { ConfigService } from '../../../core/config/config.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { IMessagesResourceService, ResourcesService } from '../../../core/resources/resources.service';
import { DictionaryService } from '../../../dictionary/core/dictionary.service';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { AlertService } from '../../../shared/alert/alert.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { EntityEditContext } from '../../../shared/entity-lock/entity-edit-context';
import { Account } from '../../core/account';
import { ACCOUNT_STATUS_ENUM } from '../../core/account-status.enum';
import { AccountService } from '../../core/account.service';
import Query from "../../../core/query/query";
import {LocationService} from "../../../location/core/location.service";
import {ApConfigurationService} from "../../core/ap-configuration.service";
import {debounceTime, flatMap} from "rxjs/internal/operators";
import {DisputeService} from "../../../dispute/core/dispute.service";
import {DisputeStatusLookup} from "../../../dispute/core/dispute-status.lookup";
import {DisputeWithheldStatusLookup} from "../../../dispute/core/dispute-witheld-status.lookup";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";

@Component({
  selector: 'app-account-manage-dialog',
  templateUrl: './account-manage-dialog.component.html',
  styleUrls: ['./account-manage-dialog.component.css']
})
export class AccountManageDialogComponent extends PageManageDialogComponent implements OnInit {
  account: any;
  private prepared_fields: {};

  ACCOUNT_STATUS = ACCOUNT_STATUS_ENUM;
  readonly MEDIA_TYPE_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.MEDIA_TYPE.modelName;
  readonly PAY_METH_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.PAY_METH.modelName;
  readonly SERVICE_PERIOD_MONTH: string = LOOKUP_MODELS_ENUM.SERVICE_PERIOD_MONTH.modelName;
  readonly SERVICE_PERIOD_RANGE: string = LOOKUP_MODELS_ENUM.SERVICE_PERIOD_RANGE.modelName;
  readonly SUBCLIENT_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.SUBCLIENT.modelName;
  readonly BILLING_FREQUENCY_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.BILLING_FREQUENCY.modelName;
  readonly SYSTEM_REGION_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.SYSTEM_REGION.modelName;
  config: Config;

  messages: IMessagesResourceService;
  commonMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'account';
  isGlEnabled: boolean;
  isOnHoldDescRequired: boolean;

  currencyQuery = new Query();
  currenciesQuery = new Query();
  selectedCountry: any;
  selectedCountryId: number;
  availableCurrencies: any = [];
  selectedAPConfig: any;
  selectedAPConfigId: number;
  allAPConfigs: any;
  isMultiRegion: any;
  countries:any;
  fields: Array<any>;
  categories: any = [];
  showCategory: boolean= false;
  statusDisabled: boolean = false;

  readonly DISPUTE_STATUS_ENUM = LOOKUP_ENUM.DISPUTE_STATUS;
  readonly DISPUTE_STATUS_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.DISPUTE_STATUS.modelName;
  readonly DISPUTE_WITHHELD_STATUS_ENUM = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS;
  readonly DISPUTE_WITHHELD_STATUS_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.DISPUTE_WITHHELD_STATUS.modelName;
  disputeStatusLookup: DisputeStatusLookup = new DisputeStatusLookup();
  disputeWithheldStatusLookup: DisputeWithheldStatusLookup = new DisputeWithheldStatusLookup();

  @Output('onSelectionChange') onSelectionChangeEmitter: EventEmitter<any> = new EventEmitter();
  searchFormControl = new FormControl();

  constructor(public formBuilder: FormBuilder,
              public alertService: AlertService,
              public accountService: AccountService,
              public dictionaryService: DictionaryService,
              public dialogService: DialogService,
              public locationService: LocationService,
              public dialogRef: MatDialogRef<AccountManageDialogComponent>,
              public configService: ConfigService,
              public apConfigurationService: ApConfigurationService,
              public disputeService: DisputeService) {
    super(dialogService, dialogRef);

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.commonMessages = ResourcesService.messages("common");

    let q = new Query({limit: 10000, orderBy:[['name', 'ASC']]});
    this.apConfigurationService.findAll(q)
      .subscribe(res => {
        this.allAPConfigs = res.items
      })
    //const countryQuery = this.queryHelperFunction(1000,'alpha_2_code', null , 'ASC', null)
    // this.locationService.findLookupCountries(countryQuery).subscribe(res => {
    //   this.countries = res.items
    // })
  }

  ngOnInit() {
    this.configService.get()
      .subscribe((config) => {
        this.isMultiRegion = config && config.gl_coding ? config.gl_coding.multi_region : false;
        this.isGlEnabled = config && config.invoice_flow_settings ? config.invoice_flow_settings.gl_coding_enabled : false;
        this.account = this.account || new Account();
        this.isUpdate = !!this.account.id;

        this.dictionaryService
          .getByLookup(this.DISPUTE_STATUS_LOOKUP_MODEL)
          .pipe(flatMap((results: any) => {
            this.disputeService.populateDisputeStatusLookup(results.items);

            return this.dictionaryService.getByLookup(
              this.DISPUTE_WITHHELD_STATUS_LOOKUP_MODEL
            );
          }))
          .subscribe(async (results) => {
            this.disputeService.populateDisputeWithheldStatusLookup(results.items);
          })


        if (this.account && this.account.ap_configuration_id) {
          this.apConfigurationService.findById(this.account.ap_configuration_id)
            .subscribe(res => {
              this.selectedAPConfig = res
            })
        }
        let account_no: string = this.account ? this.account.account_no : '';
        this.form = this.formBuilder.group({
          account_no: [{value: account_no, disabled: this.isUpdate}, Validators.required],
          vendor_id: [this.account ? this.account.vendor_id : null, Validators.required],
          vendor_entity: [this.account ? this.account.vendor_entity : null],
          late_bill_lag_days: [this.account ? this.account.late_bill_lag_days : '',
            Validators.compose([Validators.required, Validators.pattern('^(0|-?[1-9][0-9]*)$'), CustomValidators.range([0, 30])])],
          billing_cycle: [this.account ? this.account.billing_cycle : '',
            Validators.compose([Validators.required, CustomValidators.range([1, 30]), Validators.pattern('^(0|-?[1-9][0-9]*)$')])],
          vendor_location_id: [this.account ? this.account.vendor_location_id : null],
          pay_meth: [this.account ? this.account.pay_meth : null],
          media_typ: [this.account ? this.account.media_typ : null],
          status_id: [this.account && this.account.status_id ? true : false, Validators.required],
          acct_desc: [this.account ? this.account.acct_desc : ''],
          audit_threshold: [this.account && this.account.audit_threshold ? this.account.audit_threshold : 20],
          on_hold: [this.account && this.account.on_hold ? true : false],
          is_tax_exempt: [this.account && this.account.is_tax_exempt ? true : false],
          on_hold_desc: [{value: this.account ? this.account.on_hold_desc : '', disabled: true}],
          subclient_id: [this.account ? this.account.subclient_id : ''],
          billing_freq_id: [this.account ? this.account.billing_freq_id : ''],
          currency_id: [this.account ? this.account.currency_id : '', Validators.required],
          country_id: [this.account ? this.account.country_id : ''],
          is_vat_gl_output: [this.account ? this.account.is_vat_gl_output : ''],
          region_id: [this.account ? this.account.region_id : 1],
          ap_configuration_id: [true ? this.account.ap_configuration_id : '', this.isMultiRegion ? Validators.required : null],
          svc_pd_month_id: [this.account ? this.account.svc_pd_month_id : ''],
          svc_pd_range_id: [this.account ? this.account.svc_pd_range_id : '']
        });

        if (this.isUpdate) {
          this.isOnHoldDescRequired = this.form.get('on_hold').value;
          this.isOnHoldDescRequired ? this.form.controls['on_hold_desc'].enable() : this.form.controls['on_hold_desc'].disable();
          this.selectedCountryId = this.form.get('country_id').value;
          this.currenciesQuery.where = {country_id: this.selectedCountryId};
          this.locationService.findAllCurrencies(this.currenciesQuery)
            .subscribe(res => {
              this.availableCurrencies = res.items.map(item => item.currency_id);
            })
          this.statusDisabled = this.account['inventories'].some(inventory => inventory.inventory_status && inventory.inventory_status.id < 40);
        }

        this.formTitle = this.isUpdate ? 'Edit Account' : 'Create Account';

        this.form.controls['on_hold'].valueChanges
          .pipe(debounceTime(250))
          .subscribe((value) => {
              this.isOnHoldDescRequired = value;
              this.form.get('on_hold_desc').setValidators(this.setRequired(value));
              if (!value) {
                this.form.get('on_hold_desc').setValue('');
                this.form.controls['on_hold_desc'].disable()
              } else {
                this.form.controls['on_hold_desc'].enable()
              }
            }
          );
        this.form.controls['svc_pd_month_id'].valueChanges
          .pipe(debounceTime(250))
          .subscribe(() => {
            this.form.get('svc_pd_range_id').setValue(null);
          });

        this.configService.loadCustomFields()
          .subscribe(config => {
            this.fields = config?.custom_values?.account_configuration || [];
            let fieldsWithCategory = [];
            let cus_val = this.account.custom_values && this.isUpdate ? this.account.custom_values : {};
            for (let i = 0; i < this.fields.length; i++) {
              if(this.fields[i].category && this.fields[i].category.length > 0){
                fieldsWithCategory=[...fieldsWithCategory, this.fields[i].category];
              }
              if (!this.categories.includes(this.fields[i].category)) {
                this.categories.push(this.fields[i].category)
              }
              this.fields[i].value = cus_val[this.fields[i].field_name]
              this.form.addControl(this.fields[i].field_name, new FormControl(this.fields[i].value));
            }
            if(fieldsWithCategory.length === this.fields.length){
              this.showCategory= true;
            }
          });
        this.afterInit();
      })
  }

  // Workaround for angular component issue #13870
  disableAnimation = true;

  setRequired(value) {
    return value ? [Validators.required] : [];
  }

  onSubmit({value, valid}: { value: Account, valid: boolean }) {
    value.is_vat_gl_output = value.is_vat_gl_output == null ? false : value.is_vat_gl_output;

    if (valid) {
      value.status_id = value.status_id ? this.ACCOUNT_STATUS.ACTIVE : this.ACCOUNT_STATUS.INACTIVE;
      this.prepared_fields = {}
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].field_name in value) {
          this.prepared_fields[this.fields[i].field_name] = value[this.fields[i].field_name];
        }
      }
      value.custom_values = this.prepared_fields;

      if (this.account && this.account.id) {
        this.update(Object.assign({}, this.account, value));
      } else {
        this.create(value);
      }
    } else {
      this.alertService.error('', this.messages.get('FORM_INVALID'));
    }
  }

  init() {
    if (this.account && this.account.id) {
      return this.accountService.findByIdForEdit(this.account.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .pipe(flatMap((account) => {
          this.account = account;
          this.statusDisabled = this.account['inventories'].some(inventory => inventory.inventory_status && inventory.inventory_status.id < 40);
          return observableOf(account);
        }))
    }
    return observableOf(this.account);
  }

  create(account: Account) {
    this.toggleDialogButtons()
    this.accountService.create(account)
      .subscribe((result) => {
        this.closeDialog(result, true);
      }, (err) => {
        this.toggleDialogButtons(false)
        const message = (err.error && err.error['message'] == 'Duplicate Account') ? 'DUPLICATE_ACCOUNT' : 'CREATE_ERROR'
        this.alertService.error('', this.messages.get(message))
      });
  }

  update(account: Account) {
    this.toggleDialogButtons()
    this.accountService.update(account.id, account)
      .subscribe((result) => {
        this.closeDialog(result, true);
        if (result['disputesToUpdate']) {
          result['disputesToUpdate'].forEach(dispute => {

            dispute.dispute_charges.forEach(dc => {
              Object.assign(dc, this.disputeService.calculateDisputeChargeTotals(
                dc,
                this.disputeService.disputeStatusLookup
              ))
            })

            Object.assign(
              dispute,
              this.disputeService.calculateDisputeTotals(
                dispute.dispute_charges,
                this.disputeService.disputeStatusLookup,
                this.disputeService.disputeWithheldStatusLookup
              )
            );

            this.disputeService.update(dispute.id, dispute)
          })
        }
      }, (err) => {
        this.toggleDialogButtons(false)

        // TODO: create error parser - note: depending on error type err will be an object or a simple string
        // ref http://reactivex.io/documentation/operators/subscribe.htm)
        if (err.status === 403) {
          this.alertService.error('', this.messages.get('INSUFFICIENT_PERMISSIONS'));
        } else if (err.error && err.error['message'] == 'Duplicate Account') {
          this.alertService.error('', this.messages.get('DUPLICATE_ACCOUNT'));
        } else {
          this.alertService.error('', this.messages.get('UPDATE_ERROR'));
        }
      });
  }

  cancel() {
    this.accountService.cancelEdit();
    this.closeDialog();
  }

  onVatGlOutput(event) {
    const add_or_edit = this.isUpdate ? 'edit' : 'create';
    let acc_id;
    if (add_or_edit === 'edit') {
      acc_id = this.account.id;
      this.accountService.findAccountCustomRules(acc_id)
      .subscribe((res) => {
        if (event && event.checked && parseInt(res[0].count) === 0) {
          this.alertService.error('', 'Account needs to have Custom VAT rules');
        } else if (event && !event.checked && parseInt(res[0].count) > 0) {
          this.alertService.error('', 'Account has Custom VAT rules');
        }
    });
    } else if (add_or_edit === 'create') {
      if (this.form.get('account_no').value === undefined) {
        return;
      } else {
        if (this.form.get('account_no').value === "") {
          return;
        }
        let newAccount = new Account();
        newAccount.account_no = this.form.get('account_no').value;
        newAccount.vendor_id = this.form.get('vendor_id').value;
        newAccount.country_id = this.form.get('country_id').value;
        this.accountService.checkGLRules(newAccount).subscribe((res) => {
          if (event && event.checked && parseInt(res[1].rowCount) === 0) {
            this.alertService.error('', 'Account needs to have Custom VAT rules');
          } else if (event && !event.checked && parseInt(res[1].rowCount) > 0) {
            this.alertService.error('', 'Account has Custom VAT rules');
          }
        });
      }
    }
  }

  onAPConfigChange(event) {
     this.selectedAPConfigId = event.value;

    if (this.selectedAPConfigId) {
      this.apConfigurationService.findById(this.selectedAPConfigId)
        .subscribe(res => {
          this.selectedAPConfig = res
        })
    }
  }

  onCountryChange(event) {
    this.selectedCountry = event.selection;
    this.selectedCountryId = event.selection.id;
    this.currenciesQuery.where = {country_id: this.selectedCountryId};
    this.locationService.findAllCurrencies(this.currenciesQuery)
      .subscribe(res => {
        let selectedCurrency = null;

        this.availableCurrencies = res.items.map(item => {
          //TODO: When user create account
          if(item.currency.currency === event.selection.currency){
            selectedCurrency = item.currency.id
          }
          //TODO: When user edit account
          if(item.currency_id === this.form.controls.currency_id.value && this.form.controls.currency_id.value !== null) {
            selectedCurrency = item.currency_id;
          }

          return item.currency_id
        });

        if(!selectedCurrency){
          this.form.controls.currency_id.reset()
        }
        //TODO: When select country we have to trigger currency filed. This call only when create new account
        //if(Object.keys(this.account).length === 0) {
          this.form.controls['currency_id'].setValue(selectedCurrency);
        //}
      })
    let q = new Query({where: {default_country_id: this.selectedCountryId }})
    this.apConfigurationService.findAll(q)
      .subscribe(result => {
        if (result.items.length) {
          this.selectedAPConfig = result.items[0];
          this.selectedAPConfigId = result.items[0].id;
          this.form.controls['ap_configuration_id'].setValue(this.selectedAPConfigId);
        }else {
          this.form.controls['ap_configuration_id'].reset();
          this.selectedAPConfig = null
        }
      })
    this.form.controls['region_id'].setValue(event.selection.region_id);
  }
  // queryHelperFunction = (limit = 1000, orderByParam , whereParam , sort , value) => {
  //   let countryQuery =  new Query({limit ,orderBy : [[`${orderByParam}`, `${sort}`]]})
  //   if(whereParam){
  //     countryQuery.where[`${whereParam}`] = { '$ilike': `${value}%` }
  //   }
  //   return countryQuery
  // }
  // onSearchInputEnter = ($event) => {
  //     const value = $event.target.value
  //     const countryQuery = this.queryHelperFunction(1000,'alpha_2_code', 'alpha_2_code' , 'ASC',value)
  //     this.locationService.findLookupCountries(countryQuery).subscribe(res => {
  //       if(res.items.length > 0){
  //         this.countries = res.items
  //       }else {
  //         const countryNameQuery = this.queryHelperFunction(1000,'name', 'name' , 'ASC',value)
  //         this.locationService.findLookupCountries(countryNameQuery).subscribe(res => {
  //           this.countries = res.items
  //         })
  //       }
  //     });
  // }
  // openedChange(isOpened) {
  //   if (isOpened){
  //     const countryQuery = this.queryHelperFunction(1000,'alpha_2_code', null , 'ASC', null)
  //     this.locationService.findLookupCountries(countryQuery).subscribe(res => {
  //       this.countries = res.items
  //     })
  //   } else {
  //     this.searchFormControl.setValue(null);
  //     }
  //   }
}
