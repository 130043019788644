import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class ServiceLevelGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super('app.quoting.quoting-service-level-grid', userSettings);
  }

  defaultColumns = [
    {
      caption: 'Quote ID',
      dataField: 'quote.quote_id',
      width: 150
    },
    {
      caption: 'Requester',
      dataField: 'quote.requester.full_name',
      visible: false,
      width: 150
    },
    {
      caption: 'Customer',
      visible: false,
      dataField: 'quote.customer.company',
      width: 150
    },
    {
      caption: 'Request Date',
      visible: false,
      dataField: 'quote.request_date',
      dataType: 'date',
      format: this.formatService.date,
      width: 150
    },
    {
      caption: 'Opportunity Type',
      visible: false,
      dataField: 'quote.opportunity_type.value',
      width: 150
    },
    {
      caption: 'Sales Engineer',
      visible: false,
      dataField: 'quote.sales_engineer.full_name',
      width: 150
    },
    {
      caption: 'Sales Representative',
      visible: false,
      dataField: 'quote.sales_representative.full_name',
      width: 150
    },
    {
      caption: 'Quote Status',
      visible: false,
      dataField: 'quote.quote_status.value',
      width: 150
    },
    {
      caption: 'Service ID ',
      dataField: 'quote_service_id',
      width: 150
    },
    {
      caption: 'Vendor',
      alignment: 'left',
      dataField: 'selected_offer.vendor.name',
      cellTemplate: 'emptyCellTemplate',
      width: 150
    },

    {
      dataField: 'address.search_address',
      caption: 'Address',
      alignment: 'left',
      cellTemplate: "addresstemplate",
      width: 200
    },
    {
      caption: 'Service Type',
      alignment: 'left',
      dataField: 'quote_service_type.value',
      cellTemplate: 'emptyCellTemplate',
      width: 150
    },
    {
      caption: 'Service Bandwidth',
      alignment: 'left',
      dataField: 'circuit_bandwidth.value',
      cellTemplate: 'emptyCellTemplate',
      width: 150
    },

    {
      caption: 'Port Bandwidth',
      alignment: 'left',
      dataField: 'port_bandwidth.value',
      cellTemplate: 'emptyCellTemplate',
      width: 150
    },
    {
      caption: 'Create Date',
      dataType: 'date',
      format: this.formatService.date,
      width: 100,
      dataField: 'create_date',
      cellTemplate: 'emptyCellTemplate',
    },
    {
      caption: 'Close Date',
      dataType: 'date',
      format: this.formatService.date,
      width: 100,
      dataField: 'complete_date',
      cellTemplate: 'emptyCellTemplate',
    },
    {
      caption: 'Medium',
      alignment: 'left',
      width: 100,
      dataField: 'selected_offer.quote_service_medium.value',
      cellTemplate: 'emptyCellTemplate',
      visible: false,
    },
    {
      caption: 'Interval',
      width: 100,
      dataField: 'selected_offer.interval',
      cellTemplate: 'emptyCellTemplate',
      visible: false,
    },
    {
      caption: 'Sent Date',
      dataType: 'date',
      format: this.formatService.date,
      width: 100,
      dataField: 'selected_offer.sent_date',
      cellTemplate: 'emptyCellTemplate',
      visible: false,
    },
    {
      caption: 'Quote Service Status',
      width: 100,
      dataField: 'quote_service_status.value',
    },
    {
      caption: 'Term',
      alignment: 'left',
      width: 130,
      dataField: 'quote_selected_term.value',
      cellTemplate: 'emptyCellTemplate'
    },
  ];


  /*
  this function covers special case and it is used only for CSV not for grid
  */
  getServiceCSVColumns() {
    return [
      {
        label: 'Quote Service ID',
        type: 'text',
        value: 'quote_service_id'
      },
      {
        label: 'Quote Service Type',
        type: 'text',
        value: 'quote_service_type.value'
      },
      {
        label: 'Service Status',
        type: 'text',
        value: 'service_status'
      },
      {
        label: 'Details',
        type: 'text',
        value: 'service_details'
      },
      {
        label: 'Selected Term',
        type: 'text',
        value: 'selected_term'
      },
      {
        label: 'Selected Request',
        type: 'text',
        value: 'selected_offer.quote_service_offer_id'
      },
      {
        label: 'NPA',
        type: 'text',
        value: 'npa'
      },
      {
        label: 'NXX',
        type: 'text',
        value: 'nxx'
      },
      {
        label: 'Quote Service Status',
        type: 'text',
        value: 'quote_service_status.value'
      },
      {
        label: 'Diversity',
        type: 'text',
        value: 'diversity'
      },
      {
        label: 'Install Date',
        type: 'date',
        value: 'desired_install_date'
      },
      {
        label: 'Vendor',
        type: 'text',
        value: 'current_vendor.name'
      },
      {
        label: 'Bandwidth',
        type: 'text',
        value: 'bandwidth'
      },
      {
        label: 'Country',
        type: 'text',
        value: 'country'
      },
      {
        label: 'Subdivision',
        type: 'text',
        value: 'subdivision'
      },
      {
        label: 'Address',
        type: 'text',
        value: 'address'
      }
    ];
  }

}
