<mat-toolbar color="accent">
  <span class="sneak-peek-title">Notes</span>
  <span class="example-fill-remaining-space"></span>
</mat-toolbar>
<ng-content></ng-content>
<div class="ca-card ca-card-note">
  <mat-card class="note-card">
    <mat-card-content style="margin-bottom: 0">
      <form [formGroup]="noteForm" class="flex-container ca-form">
        <mat-form-field fxFlex caInput>
            <textarea matInput placeholder="Note" rows="3"
                      formControlName="content" required></textarea>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-footer class="note-action-container">
      <div fxLayout="row" class="note-action flex-container">
        <div *ngIf="charges?.length" class="charges-add-note">{{charges.length}} {{ charges.length === 1 ? 'charge' :
          'charges' }} selected
        </div>
        <div class="example-fill-remaining-space"></div>
        <button mat-button (click)="createNote()" [disabled]="!noteForm.valid || (chargesRequired && !charges.length)"
                class="note-button">
          Save
        </button>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
<div class="ca-card ca-card-note">
  <mat-card *ngFor="let note of notes" [ngClass]="{'selected': note.id == selectedId}">
    <mat-card-header>
      <div class="avatar avatar-md note-avatar" (click)="showUserModal(note.user)">
        <img *ngIf="note?.user?.avatar" [src]="note.user?.avatar" alt="User Image">
        <div class="replacement" *ngIf="!note?.user?.avatar">{{ getUserInitials(note) }}</div>
      </div>
      <div class="note-card-title" fxLayout="column">
        <div fxFlex class="note-card-user" matTooltip="">{{ note?.user?.full_name }}</div>
        <div fxFlex class="note-card-date muted">{{ note?.created_at | caDateTime }}</div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="note-card-body">
        <p *ngIf="note?.dnpCode">Do Not Process &#8226; {{note?.dnpCode }}</p>
        <p *ngIf="note?.dnpCode" [innerHtml]="sanitizeNoteContent(note?.message | newline)" ></p>
        <p *ngIf="!note?.dnpCode" [innerHtml]="sanitizeNoteContent(note?.content | newline)"></p>

        <p *ngIf="note.charges && note.charges.length">
          <a (click)="viewCharges(note.charges)" class="charges-link">View charges</a>
          <a (click)="viewCharges(note.charges)" class="selection-badge">{{note.charges.length}}</a></p>
        <p *ngIf="note.info_charges && note.info_charges.length">
          <a (click)="viewInfoCharges(note.info_charges)" class="charges-link">View charges</a>
          <a (click)="viewInfoCharges(note.info_charges)" class="selection-badge">{{note.info_charges.length}}</a></p>
      </div>
      <div class="note-card-comments" *ngFor="let child of note.notes">
        <div class="note-card-header" fxLayout="row">
          <div fxItem class="avatar avatar-md note-avatar" (click)="showUserModal(child.user)">
            <img *ngIf="child?.user?.avatar" [src]="child.user?.avatar" alt="User Image">
            <div class="replacement" *ngIf="!child?.user?.avatar">{{ getUserInitials(child) }}</div>
          </div>
          <div fxItem class="note-card-title" fxLayout="column">
            <div fxFlex class="note-card-user">{{ child?.user?.full_name }}</div>
            <div fxFlex class="note-card-date muted">{{ child?.created_at | caDateTime }}</div>
          </div>
        </div>
        <div class="note-card-body">
          <p [innerHtml]="sanitizeNoteContent(child?.content | newline)"></p>
        </div>
      </div>
    </mat-card-content>
    <form name="replyForm" class="note-card-reply flex-container ca-form"
          *ngIf="replyMode && selectedNote.id == note.id">
      <mat-form-field fxFlex caInput>
        <textarea matInput placeholder="Add a reply..." rows="3"
                  name="reply" type="text"
                  [(ngModel)]="note.reply"></textarea>
      </mat-form-field>
    </form>
    <mat-card-footer class="note-action-container">
      <div fxLayout="row" class="note-action flex-container">
        <div class="example-fill-remaining-space"></div>
        <button mat-button (click)="cancel(note)" *ngIf="replyMode && selectedNote.id == note.id" class="note-button">
          Cancel
        </button>
        <button mat-button (click)="reply(note)" [disabled]="!note.reply && replyMode" class="note-button">
          Reply
        </button>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
