import {Account} from '../../account/core/account'

export class Inventory {
  id: number;
  type_id: number;
  sp_ckt_id: string;
  internal_id: string;
  customer_circuit_id: number;
  customer_inventory: any;
  vendor_id: number;
  vendor: any;
  inventory_status: any;
  customer: any;
  contract: any;
  inventory_prim_failover: any;
  inventory_handoff_type: any;
  topology: any;
  technology: any;
  type: any;
  inventory_ckt_usage: any;
  inventory_bw_model: any;
  inventory_protected: any;
  cir_bw_id: number;
  bw_max_id: number;
  circuit_bandwidth: any;
  bandwidth_max: any;
  contract_id: number;
  est_mrc: string;
  est_nrc: string;
  term: string;
  int_ckt_id: string;
  status_id: number;
  ckt_type_id: number;
  technology_id: number;
  bw_model_id: number;
  ckt_usage_id: number;
  protected_id: number;
  prim_failover_id: number;
  disc_date: Date;
  exp_date: Date;
  eff_date: Date;
  install_date: Date;
  int_svc_id: string;
  fac_bw: string;
  site_z_lcontact: any;
  site_a_lcontact: any;
  site_a_id: number;
  site_z_id: number;
  siteA: any;
  siteZ: any;
  term_date: Date;
  ban: string;
  ban_object: any;
  site_id: number;
  acq_date: Date;
  upgrade_date: Date;
  telephone_number: string;
  btn: string;
  service: string;
  carrier: string;
  application: string;
  topology_id: number;
  unique_id: string;
  customer_id: number;
  order_service: any;
  site_a_lcontact_id: number;
  site_z_lcontact_id: number;
  site_lcontact_id: number;
  inventory_sites: any[];
  handoff_type_id: number;
  sites: Array<any>;
  custom_values: any;
  vlan_id: string;
  network_hub_a: number;
  network_hub_circuit_a: any;
  network_hub_circuit_child_a: any;
  hub_a: any;
  hub_circuit_a: any;
  hub_circuit_child_a: any;
  is_customer_provided_access: boolean;
  // This is for network-hub module, maybe temp before [Bojan] finds a better solution
  child_inventories: Array<any>;
  billing_aliases: Array<any>;
  network_hub_map: any;
  inventory_ckt_type: any;
  currency: any;
  currency_id: number;
  currency_obj: any;
  account: Account;
  initialAccountName: string;
  contract_type_id: number;
  contract_type: any;
  inventory_schedule_rates: Array<any>;
  svc_header: any;
  svc_order_id: number;
  svc_service: any;
  svc_order_service: any;
  enable_audit_run: any;
  stop_billing_date:any;
}
