export const orderServiceLevelFilterContext = {
  filters: [
    {
      field: 'inventory.vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 3,
      visible: true
    },
    {
      field: 'order.type.key',
      label: 'Type',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'order_type',
      index: 4,
      visible: true
    },
    {
      field: 'inventory.sp_ckt_id',
      label: 'SP Circuit ID',
      type: 'string',
      index: 5,
      visible: true
    },
    {
      field: 'inventory.id',
      label: 'Circuit System ID',
      type: 'number',
      index: 6,
      visible: false
    },
    {
      field: 'inventory.siteA.site_id',
      label: 'Site A',
      type: 'lookup',
      lookupProviderName: 'site',
      index: 9,
      visible: true
    },
    {
      field: 'inventory.siteZ.site_id',
      label: 'Site Z',
      type: 'lookup',
      lookupProviderName: 'site',
      index: 10,
      visible: true
    },
    {
      field: 'state',
      label: 'Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'orderServiceStatus',
      index: 11,
      visible: true
    },
    {
      field: 'des_due_date',
      label: 'Status Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 12,
      visible: true
    },{
      field: 'order.full_order_id',
      label: 'Order Number',
      type: 'string',
      index: 13,
      visible: true
    },{
      field: 'full_service_id',
      label: 'Service Number',
      type: 'string',
      index: 14,
      visible: true
    }
  ]
};
