import {Injectable} from "@angular/core";

const resources = [
  {
    key: "dictionary",
    name: "Dictionary",
  },
  {
    key: "accounts",
    name: "Accounts",
  },
  {
    key: "alerts",
    name: "Alerts",
  },
  {
    key: 'suspense',
    name: 'Suspense'
  },
  {
    key: "vendors",
    name: "Vendors",
  },
  {
    key: "invoices",
    name: "Invoices",
  },
  {
    id: 60,
    key: "buildings",
    name: "Buildings",
    actions: {
      VIEW: "View",
      MODIFY: "Modify",
      CREATE: "Create",
      DELETE: "Delete",
    }
  },
  {
    key: "contacts",
    name: "Contacts",
  },
  {
    hidden: true,
    key: "notifications",
    name: "Notifications",
  },
  {
    key: "users",
    name: "Users",
  },
  {
    key: "roles",
    name: "Roles",
  },
  {
    hidden: true,
    key: "permissions",
    name: "Permissions",
  },
  {
    key: "charges",
    name: "Charges",
  },
  {
    key: "sites",
    name: "Sites",
  },
  {
    key: "auto-approver",
    name: "Autoapprover"
  },
  {
    key: "orders",
    name: "Orders"
  },
  {
    key: "projects",
    name: "Projects"
  },
  {
    key: "customers",
    name: "Customers"
  },
  {
    key: "disputes",
    name: "Disputes"
  },
  {
    key: "gl",
    name: "GL"
  },
  {
    key: "inventory",
    name: "Inventory",
  },
  {
    key: "bulk-upload",
    name: "Bulk Upload"
  },
  {
    key: "customer-inventory",
    name: "Customer Inventory"
  },
  {
    key: "reports",
    name: "Reports"
  }
];

@Injectable()
export class ResourceRegistry {
  all() {
    return resources;
  }

  find(key: string) {
    const resource = resources.find((res) => key === res.key);
    if (!resource) {
      throw `Cannot find resource for key ${key}`
    }
    return resource;
  }
}
