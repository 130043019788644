import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ContactService } from 'app/contact/core/contact.service';
import { LOOKUP_MODELS_ENUM } from 'app/dictionary/core/lookup-models.enum';
import { Subject } from 'rxjs';
import { DictionaryService } from '../../../dictionary/core/dictionary.service';
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import { Contact } from '../../core/contact';


@Component({
  selector: 'app-contact-sneak-peek',
  templateUrl: './contact-sneak-peek.component.html'
})
export class ContactSneakPeekComponent implements OnChanges {
  @Input() contact: Contact;

  @Output() closeRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();

  readonly CONTACT_TYPE_ENUM: any = LOOKUP_ENUM.CONTACT_TYPE;
  public dictionaryFunctions: any;

  public contactFunctions: any;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dictionaryService: DictionaryService,
    private contactService: ContactService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['contact']) {
      if (this.contact) {
        const { id } = this.contact;
        if (id) {
          this.contactFunctions = this.getContactFunctions()
          this.destroy$.next(true);
          this.destroy$.complete();
        }
      }
    }
  }

  deleteContact() {
    this.deleteRequested.emit(this.contact);
  }

  getContactFunctions() {
    if (
      this.contact &&
      this.contact.contact_functions &&
      this.contact.contact_functions.length > 0
    ) {
      return this.contact.contact_functions.map(item => item.value).join(', ');
    } else {
      return 'N/A';
    }
  }

  closeSide() {
    this.closeRequested.emit();
  }
}
