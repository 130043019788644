import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ResourcesService } from '../../core/resources/resources.service';


@Component({
  // tslint:disable-next-line
  selector: 'ca-icon',
  templateUrl: './ca-icon.component.html'
})
export class CaIconComponent implements OnChanges {

  @Input('key') key;
  @Input('innerClass') innerClass;
  @Input('tooltip') tooltip;
  @Input('tooltipPosition') tooltipPosition = 'below';
  @Input('tooltipClass') tooltipClass;
  public icon: string;

  icons: any;
  undefinedIcon: string;

  constructor() {
    this.icons = ResourcesService.icons();
    this.undefinedIcon = this.icons.undefinedIcon;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['key'] && changes['key'].currentValue) {
      this.icon = this.icons.get(changes['key'].currentValue);
    }
  }

}
