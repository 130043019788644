import {Observable} from "rxjs";
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import {ContactService} from "./contact.service";
import Query from "../../core/query/query";

export class ContactLookupProvider extends IFilterLookupProvider {
  constructor(public contactService: ContactService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    let query = searchQuery? searchQuery : new Query();
    query.orderBy = [['full_name', 'ASC']];

    return this.contactService.findAll(query)
    .map((result) => {
        return {items: result.items.map(entry => {
            return entry.full_name;
          }), total: result.total};
      });
  }
}
