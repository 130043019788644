import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {pathOr} from 'ramda';

import {CaDatePipe} from '../format/ca-date.pipe';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {DateTimePipe} from '../format/date-time.pipe';
import {map} from "rxjs/operators";

@Injectable()
export class GridService {
  public noDataMessage = 'No data';
  public defaultColumns = [];
  public readonly settingsColumnProperties = ['dataField', 'width', 'sortDirection', 'fixed', 'index', 'visible', 'showInSettings'];
  private instance: any;

  stateStoring = {
    enabled: true,
    storageKey: 'some-grid-key',
    type: 'custom',
    savingTimeout: 120000,
    customSave: (gridState) => {
      this.settingsNew().pipe(map(setting => {
        const settingsColumns = (setting?.columns || []);
        let hasChanges = false;
        let columns = this.getColumns(settingsColumns);
        columns.forEach((column) => {
          let gridStateColumn = this.findColumnByDataField(gridState.columns, column.dataField);

          if (gridStateColumn && gridStateColumn.width) {
            if (column.width !== gridStateColumn.width) {
              column.width = gridStateColumn.width;
              hasChanges = true;
            }
          }
        });

        if (hasChanges) {
          this.sortingColumnsNew().pipe(
            map(res => {
              let settings = {columns: columns, sorting: res.sorting};
              this.saveSettings(settings)
                .subscribe(() => {
                });
            })
          ).subscribe()
          // let settings = {columns: columns, sorting: this.sortingColumns()};
          // this.saveSettings(settings)
          //   .subscribe(() => {
          //   });
        }

      })).subscribe(() => {});

    }
  };

  static defaults(stateStoring?) {
    return {
      height: '100%',
      width: '100%',
      columnAutoWidth: false,
      showColumnLines: false,
      showRowLines: true,
      showBorders: true,
      columnHidingEnabled: false,
      remoteOperations: false,
      rowAlternationEnabled: false,
      columnFixing: {
        enabled: true
      },
      loadPanel: {
        enabled: false
      },
      sorting: {
        mode: 'none'
      },
      searchPanel: {
        visible: false,
        width: 240,
        placeholder: 'Search'
      },
      selection: {
        mode: 'single',
        showCheckBoxesMode: 'none'
      },
      headerFilter: {visible: false},
      groupPanel: {visible: false},
      grouping: {autoExpandAll: false},
      scrolling: {
        mode: 'standard'
      },
      pager: {
        enabled: false,
        visible: false,
        showPageSizeSelector: false,
        showNavigationButtons: false,
        allowedPageSizes: [20, 50, 100],
        showInfo: false
      },
      paging: {
        enabled: false
      },
      noDataText: 'Loading...',
      columnChooser: {enabled: false},
      allowColumnReordering: false,
      allowColumnResizing: true,
      columnResizingMode: 'widget',
      columnMinWidth: 40,
      customizeColumns: (columns) => {
        columns.map((column) => {
          column.allowFixing = false
        })
      },
      stateStoring: stateStoring || {enabled: false}
    }
  }

  constructor(@Inject('name') public name: string, public userSettings: UserSettingsService) {
  }

  public getGridPager(defaultLimit?) {
    const settings = this.userSettings.settings();
    return pathOr(defaultLimit || 20, [this.name, 'gridPager'])(settings);
  }

  saveSettings(settings): Observable<any> {
    settings.columns = settings.columns.map((column) => {
      let result = {};
      this.settingsColumnProperties.forEach((property) => {
        result[property] = column[property];
      });

      return result;
    });

    return this.userSettings.saveGridFilterSettings(this.name, settings);
  }

  csvMap(): Observable<any> {
    try {
      return this.settingsNew().pipe(map(setting => {
        const settingsColumns = (setting?.columns || []);

        return this.getColumns(settingsColumns)
          .filter((column) => {
            return !!(column.dataField && column.caption && (column.visible !== false));
          })
          .map((column) => {
            return {
              value: column.dataField,
              label: column.caption.replace(' #', ''),
              type: column.csvDataType ? column.csvDataType : column.dataType,
              transformValue: column.transformValue,
              isExchangeRate: column.isExchangeRate,
              csvOutput: column.csvOutput === false ? column.csvOutput : true
            };
          });
      }))
    } catch (error) {
      console.log("error", error)
    }
  }

  create(instance: any, options?: any) {
    this.instance = instance;
    let defaults = GridService.defaults(this.stateStoring);
    let settings = Object.assign(defaults, options || {});
    instance.option(settings);

    instance.columnOption('command:select', 'width', 50);
    instance.updateDimensions();
  }

  public sorting() {
    let columns = this.sortingColumns() || [];
    return columns.map((column) => {
      return [column.dataField, column.sortDirection || 'ASC']
    })
  }

  public sortingNew(): Observable<any> {
    return this.sortingColumnsNew()
      .pipe(map(res => {
        return res.sorting?.map((column) => {
          return [column.dataField, column.sortDirection || 'ASC']
        })
      }));
  }

  sortingColumns(): Array<any> {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings) {
      return settings.sorting;
    }
    return [];
  }

  sortingColumnsNew(): Observable<any> {
    return this.userSettings.entrySettingsNew(this.name);
  }

  settings() {
    return this.userSettings.entrySettings(this.name);
  }

  settingsNew(): Observable<any> {
    return this.userSettings.entrySettingsNew(this.name);
  }


  loadSettings() {
    return this.userSettings.loadSettings(this.name);
  }

  getColumns(columns = []) {
    const defaultColumns = this.defaultColumns;
    if (!columns.length) {
      return defaultColumns.map((col) => {
        const colFromSettings = columns?.find(x => x.dataField === col.dataField)
        return Object.assign({}, col, colFromSettings || {})
      });
    } else {
      return columns.map((col) => {
        const colFromDefault = defaultColumns?.find(x => x.dataField === col.dataField)
        return Object.assign({}, colFromDefault, col || {})
      });
    }
  }

  getOrderBy(sortingColumns) {
    return (sortingColumns || []).map((column) => {
      return [column.dataField, column.sortDirection || 'ASC']
    })
  }

  findColumnByDataField(columns, dataField) {
    return columns.filter((column) => {
      return column.dataField === dataField;
    })[0];
  }

  getDefaultColumns() {
    return this.defaultColumns;
  }

  private getColumnFormatter(column) {
    const datePipe = new CaDatePipe();
    const dateTimePipe = new DateTimePipe();

    const formatters = {
      date: ({value}) => datePipe.transform(value),
      datetime: ({value}) => dateTimePipe.transform(value)
    };

    return formatters[column.dataType];
  }

  // this is will work only if settings data is already cached, otherwise it will not wait for settings to load,
  // this need to be refactored to return observable, but there are many place in code that are calling this funciton
  // so such refactoring would have big regression on app, so i will just leave comment for now (detected while working on GPX-5967)
  //
  // there are also many place i the code that have commented "columns() {" that needs to be cleaned up
  columns(resetToDefault?) {
    let settings = this.userSettings.entrySettings(this.name);
    let defaultColumns = JSON.parse(JSON.stringify(this.defaultColumns)); /// this.getDefaultColumns()
    if (settings && settings.columns) {
      defaultColumns.forEach((column) => {
        let settingsColumn = this.findColumnByDataField(settings.columns, column.dataField);
        if (settingsColumn) {
          this.settingsColumnProperties.forEach((property) => {
            if (settingsColumn[property] !== undefined) {
              column[property] = settingsColumn[property];
              column.index = settings.columns.indexOf(settingsColumn);
              if (column.index === -1) {
                column.index = defaultColumns.length;
              }
            }
          });
        }
      });
    }

    defaultColumns.sort((a, b) => {
      return a.index - b.index;
    });

    let visibleColumns = defaultColumns.filter((col) => {
      return col.visible !== false;
    });

    let lastColumn = visibleColumns[visibleColumns.length - 1];
    if (this.instance) {
      const visibleGridColumns: Array<any> = this.instance.getVisibleColumns();

      lastColumn = lastColumn || visibleGridColumns[visibleGridColumns.length - 1];

      let gridElement = this.instance.element()[0];
      let gridParentElement = gridElement && gridElement.parentElement;
      if (gridElement && gridParentElement && gridElement.clientWidth < gridParentElement.clientWidth) {
        let currentWidth = this.instance.columnOption(lastColumn.dataField, 'width');
        if (!resetToDefault) {
          this.instance.columnOption(
            lastColumn.dataField,
            'width',
            currentWidth + (gridParentElement.clientWidth - gridElement.clientWidth
            ));
        }
        this.instance.updateDimensions();
      }
    }

    defaultColumns = JSON.parse(JSON.stringify(defaultColumns));
    defaultColumns = defaultColumns.map(column => {
      const formatter = this.getColumnFormatter(column);
      return formatter && !column.customizeText ? ({
        ...column,
        customizeText: formatter
      }) : column;
    });

    defaultColumns = defaultColumns.map(column => column.dataType === 'datetime' && !column.customizeText ? ({
      ...column,
      customizeText: ({value}) => {
        return (new DateTimePipe()).transform(value);
      }
    }) : column);


    return defaultColumns;
  }

  observableColumns(resetToDefault?) {

    let defaultColumns = JSON.parse(JSON.stringify(this.defaultColumns));

    return new Observable<Array<any>>((subscriber) => {

      return this.userSettings.findByKey(this.name).subscribe((settings) => {
        if (settings && settings.columns) {
          defaultColumns.forEach((column) => {
            let settingsColumn = this.findColumnByDataField(settings.columns, column.dataField);
            if (settingsColumn) {
              this.settingsColumnProperties.forEach((property) => {
                if (settingsColumn[property] !== undefined) {
                  column[property] = settingsColumn[property];
                  column.index = settings.columns.indexOf(settingsColumn);
                  if (column.index === -1) {
                    column.index = defaultColumns.length;
                  }
                }
              });
            }
          });
        }

        defaultColumns.sort((a, b) => {
          return a.index - b.index;
        });

        let visibleColumns = defaultColumns.filter((col) => {
          return col.visible !== false;
        });

        let lastColumn = visibleColumns[visibleColumns.length - 1];
        if (this.instance) {
          const visibleGridColumns: Array<any> = this.instance.getVisibleColumns();

          lastColumn = lastColumn || visibleGridColumns[visibleGridColumns.length - 1];

          let gridElement = this.instance.element()[0];
          let gridParentElement = gridElement && gridElement.parentElement;
          if (gridElement && gridParentElement && gridElement.clientWidth < gridParentElement.clientWidth) {
            let currentWidth = this.instance.columnOption(lastColumn.dataField, 'width');
            if (!resetToDefault) {
              this.instance.columnOption(
                lastColumn.dataField,
                'width',
                currentWidth + (gridParentElement.clientWidth - gridElement.clientWidth
                ));
            }
            this.instance.updateDimensions();
          }
        }

        defaultColumns = JSON.parse(JSON.stringify(defaultColumns));
        defaultColumns = defaultColumns.map(column => {
          const formatter = this.getColumnFormatter(column);
          return formatter && !column.customizeText ? ({
            ...column,
            customizeText: formatter
          }) : column;
        });

        defaultColumns = defaultColumns.map(column => column.dataType === 'datetime' && !column.customizeText ? ({
          ...column,
          customizeText: ({value}) => {
            return (new DateTimePipe()).transform(value);
          }
        }) : column);

        subscriber.next(defaultColumns);
      });
    });

  }
}
