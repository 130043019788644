import {ICsvInterceptor} from "../../core/csv/csv-interceptor";

export class QuoteRequestsCsvInterceptor implements ICsvInterceptor {
  transform(item: any) {

    var formatCurrency = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    let copy = Object.assign({}, item);
    if(item.one_year_mrc && item.one_year_nrc) {
      copy.one_year_mrc = (item.one_year_mrc = formatCurrency.format(item.one_year_mrc)) + "/" + (item.one_year_nrc = formatCurrency.format(item.one_year_nrc));
    }
    if(item.two_year_mrc && item.two_year_nrc) {
      copy.two_year_mrc = (item.two_year_mrc = formatCurrency.format(item.two_year_mrc)) + "/" + (item.two_year_nrc = formatCurrency.format(item.two_year_nrc));
    }
    if(item.three_year_mrc && item.three_year_nrc) {
      copy.three_year_mrc = (item.three_year_mrc = formatCurrency.format(item.three_year_mrc)) + "/" + (item.three_year_nrc = formatCurrency.format(item.three_year_nrc));
    }
    if(item.quoteService && item.quoteService.quote_service_status) {
      copy.status = (item.quoteService.quote_service_status || {}).value;
    }
    return copy;
  }
}
