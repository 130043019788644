import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  IMessagesResourceService,
  ResourcesService
} from 'app/core/resources/resources.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { of as observableOf } from 'rxjs';

import { InvoiceFacepageService } from 'app/invoice/core/invoice-facepage.service';
import { flatMap } from "rxjs/internal/operators";
import { AccountService } from "../../../account/core/account.service";
import { DisputeChargeService } from '../../../charge/core/dispute-charge.service';
import { InvoiceCharge } from '../../../charge/core/invoice-charge';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import Query from "../../../core/query/query";
import { DictionaryService } from '../../../dictionary/core/dictionary.service';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import { LookupModel } from '../../../dictionary/core/lookup.model';
import { InvoiceFacepage } from '../../../invoice/core/invoice-facepage';
import { INVOICE_STATUS_ENUM } from '../../../invoice/core/invoice-status.enum';
import { LocationQuery } from "../../../location/core/location.query";
import { LocationService } from "../../../location/core/location.service";
import { AlertService } from '../../../shared/alert/alert.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { EntityEditContext } from '../../../shared/entity-lock/entity-edit-context';
import { UserService } from "../../../user/core/user.service";
import { Dispute } from '../../core/dispute';
import { DisputeChargesGridService } from '../../core/dispute-charges-grid.service';
import { DisputeStatusLookup } from '../../core/dispute-status.lookup';
import { DisputeWithheldStatusLookup } from '../../core/dispute-witheld-status.lookup';
import { DisputeService } from '../../core/dispute.service';

@Component({
  selector: 'app-dispute-manage-dialog',
  templateUrl: './dispute-manage-dialog.component.html',
  styles: ['.dx-revert-button { display: none !important; }']
})
export class DisputeManageDialogComponent extends PageManageDialogComponent
  implements OnInit {

  @ViewChild(DxDataGridComponent) chargesGrid: DxDataGridComponent;
  @ViewChild('dialogButtons') dialogButtons: ElementRef<HTMLElement>;
  
  isUpdate: boolean;
  formTitle: string;

  dispute: Dispute;
  charges: Array<InvoiceCharge>;
  invoice: InvoiceFacepage;
  disputeCharges: any;
  calculatedCharges: any
  resDate: any;
  dispDesc: any;
  disputeChargeList : any;
  missingCalculatedAmount: boolean;
  missingAmountAwarded: boolean;
  alertId: any;

  columns: Array<any>;

  readonly DISPUTE_STATUS_ENUM = LOOKUP_ENUM.DISPUTE_STATUS;

  readonly DISPUTE_STATUS_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.DISPUTE_STATUS.modelName;

  readonly DISPUTE_WITHHELD_STATUS_ENUM = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS;

  readonly DISPUTE_WITHHELD_STATUS_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.DISPUTE_WITHHELD_STATUS.modelName;

  disputeStatusLookup: DisputeStatusLookup = new DisputeStatusLookup();
  disputeWithheldStatusLookup: DisputeWithheldStatusLookup = new DisputeWithheldStatusLookup();

  public DISPUTE_CATEGORY_LOOKUP_MODEL =
    LOOKUP_MODELS_ENUM.DISPUTE_CATEGORY.modelName;

  readonly INVOICE_STATUS_COMPLETED = INVOICE_STATUS_ENUM.COMPLETED;
  invoiceCompleted = false;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'dispute';

  me: any;

  public originalCharges = []
  public chargesForInvoiceReset = []
  invoiceStatusEnum = INVOICE_STATUS_ENUM;

  public invoices = []
  public countries;
  public custom_symbol;

  constructor(
    public alertService: AlertService,
    public dialogService: DialogService,
    public disputeService: DisputeService,
    public disputeChargeService: DisputeChargeService,
    public disputeChargesGridService: DisputeChargesGridService,
    public dialogRef: MatDialogRef<DisputeManageDialogComponent>,
    public dictionaryService: DictionaryService,
    public userService: UserService,
    public locationService: LocationService,
    public invoiceService: InvoiceFacepageService,
    public accountService: AccountService
  ) {
    super(dialogService, dialogRef);

    this.dispute = this.dispute || new Dispute();
    this.dispute.status_obj = this.dispute.status_obj || new LookupModel();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.locationService.findAll(new LocationQuery({limit: 1000,where:{}}))
      .subscribe(result => {
        this.countries = result.items;
      })
  }

  ngOnInit() {
      this.userService.me().subscribe(user => {
        this.me = user;

        let dateFormat = this.me.country && this.me.country.date_format;
        this.dictionaryService
          .getByLookup(this.DISPUTE_STATUS_LOOKUP_MODEL)
          .pipe(flatMap((results: any) => {
            this.populateDisputeStatusLookup(results.items);

            return this.dictionaryService.getByLookup(
              this.DISPUTE_WITHHELD_STATUS_LOOKUP_MODEL
            );
          }))
          .subscribe(async (results) => {
            this.populateDisputeWithheldStatusLookup(results.items);

            this.isUpdate = !!(this.dispute && this.dispute.id);
            if (this.isUpdate) {
              if (!this.countries) {
                const countries = await this.locationService.findAll(new LocationQuery({limit: 1000,where:{}})).toPromise()
                if (countries && countries.items)
                  this.countries = [...countries.items]
              }
              for (let i = 0; i < this.countries.length; i++) {
                if (this.countries[i].currency === this.dispute.currency.currency) {
                  this.custom_symbol = this.countries[i].currency_display;
                  break;
                }
              }
              this.disputeCharges = this.dispute.dispute_charges.slice();
              this.disputeCharges.map(disputeCharge => {
                if(disputeCharge.charge.invoice.account?.subclient?.value){
                  disputeCharge.subclient = disputeCharge.charge.invoice.account.subclient.value
                }
                disputeCharge['disableEdit'] = disputeCharge.status_obj?.key === 'CLOSED_WON' || disputeCharge.status_obj?.key === 'CLOSED_LOST'
              })
            } else {
              let acctNos = this.charges.reduce((prev, curr) => {
                return (prev.indexOf(curr.acct_level_1) === -1) ? [...prev, curr.acct_level_1] : [...prev]
              }, [])
              let temp = new Query({
                limit: 100000,
                where: { account_no: {'$in': acctNos}, vendor_id: this.invoice.vendor_id }
              })
              let accounts = await this.accountService.findAccountsForDisputeVATCheck(temp).toPromise()
              if (accounts) {
                this.charges.map(chg => {
                  chg['account'] = accounts.items.find(acct => acct.account_no == chg['acct_level_1'])
                  return chg
                })
              }
              this.dispute.currency = this.invoice.currency;
              this.disputeCharges = this.disputeChargeService.mapFromCharges(
                this.charges,
                this.disputeStatusLookup,
              );
              this.dispute.total_amount = this.disputeCharges
                .map(item => parseFloat(item.charge.chg_amt))
                .reduce((accumulator, next) => accumulator + next);
            }

            if(this.dispDesc){
              this.disputeCharges.map((item, index) => {
                item.description = this.dispDesc[index];
              })
            }

            if(this.calculatedCharges && this.calculatedCharges.length){
              this.disputeCharges.map((item, index) => {
                item.calculated_amount = this.calculatedCharges[index]
                let self = this
                // Calculate totals and status for dispute charge
                return new Promise<void>(function(resolve, reject) {
                  Object.assign(
                    item,
                    self.disputeService.calculateDisputeChargeTotals(
                      item,
                      self.disputeStatusLookup
                    )
                  );
                  resolve(); // if the action succeeded
                }).then(() => {
                  // * Calculate totals and status for dispute */
                  Object.assign(
                    self.dispute,
                    self.disputeService.calculateDisputeTotals(
                      self.disputeCharges,
                      self.disputeStatusLookup,
                      self.disputeWithheldStatusLookup
                    )
                  );

                  // Refresh dataSource, this fixes a bug with grid not reloading and showing correct values
                  self.chargesGrid.dataSource = self.disputeCharges;
                  setTimeout(() => {
                    self.chargesGrid.instance.refresh();
                  });
                });

                });
            }

            // invoices list
            this.disputeCharges.forEach(disCharge => {
              let index = this.invoices.reduce((searchIndex, item, index) => {
                if(item.invoice_id === disCharge.charge.invoice_id) {
                  searchIndex = index;
                }
                return searchIndex;
              }, null);
              if (index == null)
                this.invoices.push(disCharge.charge.invoice)
            });

            if (!(this.disputeCharges && this.disputeCharges.length)) {
              this.alertService.error('', this.messages.get('CHARGES_INVALID'));
              this.close();
              return;
            }

            this.checkWithheldStatus()

            this.formTitle = this.isUpdate ? 'Edit Dispute' : 'Create Dispute';

            /* Load dispute charges columns once have dispute category lookup loaded */
            this.dictionaryService
              .getByLookup(this.DISPUTE_CATEGORY_LOOKUP_MODEL)
              .subscribe(result => {
                this.disputeChargesGridService.disputeCategories = result.items;

                this.columns = this.disputeChargesGridService.getChargeColumns(dateFormat);

                setTimeout(() => {
                  this.disputeChargesGridService.create(
                    this.chargesGrid.instance,
                    {}
                  );
                });
              });

            if (
              this.disputeCharges.filter(x => {
                return x.calculated_amount == null;
              }).length > 0
            ) {
              this.missingCalculatedAmount = true;
            } else {
              this.missingCalculatedAmount = false;
            }

            if (
              this.disputeCharges.filter(x => {
                return x.dispute_value_awarded === null && !(x.resolution_date === null || x.resolution_date === undefined);
              }).length > 0
            ) {
              !this.missingAmountAwarded && this.alertService.error('', this.messages.get('MISSING_AMOUNT_AWARDED'));
              this.missingAmountAwarded = true;
            } else {
              this.missingAmountAwarded = false;
            }
          })
      })
  }

  // Check dispute charges for the accounts with VAT enabled
  // Invoices with BANs with VAT enabled cannot be withheld as this is not allowed
  checkWithheldStatus() {
    if (this.isUpdate) {
      const withheldCharges = this.dispute.dispute_charges.filter(dc => dc.dispute_withheld)
      if (withheldCharges.length === 0) {
        this.dispute.withheld_status = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS.NOT_WITHHELD
        this.dispute.withheld_status_obj = this.disputeWithheldStatusLookup.NOT_WITHHELD
      } else if (withheldCharges.length === this.dispute.dispute_charges.length) {
        this.dispute.withheld_status = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS.FULL_WITHHELD
        this.dispute.withheld_status_obj = this.disputeWithheldStatusLookup.FULL_WITHHELD
      } else {
        this.dispute.withheld_status = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS.PARTIALLY_WITHHELD
        this.dispute.withheld_status_obj = this.disputeWithheldStatusLookup.PARTIALLY_WITHHELD
      }
    } else {
      this.dispute.withheld_status = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS.NOT_WITHHELD
      this.dispute.withheld_status_obj = this.disputeWithheldStatusLookup.NOT_WITHHELD
    }
  }

  init() {
    if (this.dispute && this.dispute.id) {
      return this.disputeService
        .findByIdForEdit(
          this.dispute.id,
          new EntityEditContext({
            dialogRef: this.dialogRef
          })
        )
        .pipe(flatMap((dispute: Dispute) => {
          this.dispute = dispute;
          return observableOf(dispute);
        }));
    }
    return observableOf(this.dispute);
  }

  // ** Validates before onRowUpdated */
  onRowUpdating(row) {
    if (row.newData.hasOwnProperty('calculated_amount')) {
      if (
        (row.newData.calculated_amount !== 0 &&
          !row.newData.calculated_amount) ||
        isNaN(row.newData.calculated_amount)
      ) {
        row.cancel = true;
      }
    }

    // can’t be negative or greater than ‘Disputed Amount’
    if (row.newData.hasOwnProperty('dispute_value_awarded')) {
      let dispute_value_awarded = row.newData.dispute_value_awarded;
      if (
        isNaN(dispute_value_awarded)
      ) {
        row.cancel = true;
      }
    }

    if (row.cancel) {
      this.chargesGrid.instance.cancelEditData();
    }
  }

  onRowUpdated(row) {
    const self = this;
    if (
      this.disputeCharges.filter(x => {
        return x.calculated_amount == null;
      }).length > 0
    ) {
      this.missingCalculatedAmount = true;
    } else {
      this.missingCalculatedAmount = false;
    }

    if (
      this.disputeCharges.filter(x => {
        return x.dispute_value_awarded === null && !(x.resolution_date === null || x.resolution_date === undefined);
      }).length > 0
    ) {
      !this.missingAmountAwarded && this.alertService.error('', this.messages.get('MISSING_AMOUNT_AWARDED'));
      this.missingAmountAwarded = true;
    } else {
      this.missingAmountAwarded = false;
    }

    // Calculate totals and status for dispute charge
    return new Promise<void>(function(resolve, reject) {
      Object.assign(
        row.key,
        self.disputeService.calculateDisputeChargeTotals(
          row.key,
          self.disputeStatusLookup
        )
      );
      resolve(); // if the action succeeded
    }).then(() => {
      // * Calculate totals and status for dispute */
      Object.assign(
        self.dispute,
        self.disputeService.calculateDisputeTotals(
          self.disputeCharges,
          self.disputeStatusLookup,
          self.disputeWithheldStatusLookup
        )
      );

      // Refresh dataSource, this fixes a bug with grid not reloading and showing correct values
      self.chargesGrid.dataSource = self.disputeCharges;
      setTimeout(() => {
        self.chargesGrid.instance.refresh();
      });
    });
  }

  close(result?: any) {
    this.toggleDialogButtons(false);
    this.dialogRef.close(result);
  }

  async onSubmit() {
    let dialogButtons: HTMLElement = this.dialogButtons.nativeElement
    dialogButtons.click();
    await new Promise(resolve => setTimeout(resolve, 100));

    if(this.disputeCharges.filter(x => {
      return x.dispute_value_awarded === null && !(x.resolution_date === null || x.resolution_date === undefined);
    }).length > 0) {
      !this.missingAmountAwarded && this.alertService.error('', this.messages.get('MISSING_AMOUNT_AWARDED'));
      this.missingAmountAwarded = true;
    }
    if(!this.missingAmountAwarded) {
      /**
           * reset invoice status
           */
      let statusReset = this.invoiceStatusEnum.NEW_RESET;
      let status = this.invoiceStatusEnum.NEW_PENDING;
      let statusNew = this.invoiceStatusEnum.NEW;
      let invoiceIdsForReset = [];
      this.chargesForInvoiceReset.forEach(item => {
        const invoiceId = item.invoice_id;
        const invoiceCodeStatus = item.invoice_code;
        if (invoiceIdsForReset.indexOf(invoiceId) === -1 && invoiceCodeStatus !== statusReset && invoiceCodeStatus !== statusNew && invoiceCodeStatus !== status )
          invoiceIdsForReset.push(invoiceId)
      })
      if(invoiceIdsForReset.length) {
        invoiceIdsForReset.forEach(invoice_id => {
          this.invoiceService.updateStatus(invoice_id, {status: status, statusReset: statusReset})
            .subscribe(() => {
              this.invoiceService.processMultipleInvoices(invoice_id);
            })
        })
      }
        this.dispute.dispute_charges = this.disputeCharges;
        this.toggleDialogButtons(false);
        if (this.isUpdate) {
          this.update();
        } else {
          if (
            this.disputeCharges.filter(x => {
              return x.calculated_amount == null;
            }).length > 0
          ) {
            this.alertService.error(
              '',
              this.messages.get('CALCULATED_CHARGES_INVALID')
            );
            return;
          }
          this.create();
        }
    }
  }

  create() {
    this.dispute.charges = this.disputeCharges;
    if(this.alertId){
      this.dispute['alert_id'] = this.alertId
    }
    //* Calculate totals and status for dispute */
    Object.assign(
      this.dispute,
      this.disputeService.calculateDisputeTotals(
        this.disputeCharges,
        this.disputeStatusLookup,
        this.disputeWithheldStatusLookup
      )
    );

    this.toggleDialogButtons();
    this.disputeService.create(this.dispute).subscribe(
      result => {
        this.close(result);
      },
      err => {
        this.toggleDialogButtons(false);
        this.alertService.error('', this.messages.get('CREATE_ERROR'));
      }
    );
  }

  update() {
    // removed unnecessary payload
    let disputeForSave = Object.assign({}, this.dispute);

    delete disputeForSave.invoice;
    delete disputeForSave.user.avatar;
    this.toggleDialogButtons();
    this.disputeService.update(this.dispute.id, disputeForSave).subscribe(
      result => {
        this.close(result);
      },
      err => {
        this.toggleDialogButtons(false);
        this.alertService.error('', this.messages.get('UPDATE_ERROR'));
      }
    );
  }

  /** populate disputeStatusLookup */
  populateDisputeStatusLookup(disputeStatuses: Array<LookupModel>): void {
    disputeStatuses.forEach(item => {
      if (item.id === this.DISPUTE_STATUS_ENUM.FILED) {
        this.disputeStatusLookup.FILED = item;
      }
      if (item.id === this.DISPUTE_STATUS_ENUM.CLOSED_WON) {
        this.disputeStatusLookup.CLOSED_WON = item;
      }
      if (item.id === this.DISPUTE_STATUS_ENUM.IN_PROGRESS) {
        this.disputeStatusLookup.IN_PROGRESS = item;
      }
      if (item.id === this.DISPUTE_STATUS_ENUM.CLOSED_LOST) {
        this.disputeStatusLookup.CLOSED_LOST = item;
      }
    });
  }

  /** populate disputeWithheldStatusLookup */
  populateDisputeWithheldStatusLookup(
    disputeWithheldStatuses: Array<LookupModel>
  ): void {
    disputeWithheldStatuses.forEach(item => {
      if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.NOT_WITHHELD) {
        this.disputeWithheldStatusLookup.NOT_WITHHELD = item;
      }
      if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.PARTIALLY_WITHHELD) {
        this.disputeWithheldStatusLookup.PARTIALLY_WITHHELD = item;
      }
      if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.FULL_WITHHELD) {
        this.disputeWithheldStatusLookup.FULL_WITHHELD = item;
      }
    });
  }

  cancel() {
    this.disputeService.cancelEdit();
    this.closeDialog();
  }

  test(d) {
  }

  onEditorPreparing(e: any) {
    /**
     * summarize invoices that needs to be reset
     * depending on the withheld change
     */
    e.editorOptions.onValueChanged = args => {

      if (e.dataField === "dispute_withheld") {
        const charge = {
          id: e.row.data.id || null,
          charge_id: e.row.data.charge.id,
          invoice_id: e.row.data.charge.invoice_id,
          dispute_withheld: e.row.data.dispute_withheld,
          invoice_code: e.row.data.charge.invoice.header.status_code
        }

        // charge with saved values
        let originalFound = this.originalCharges.find(item => item.charge_id === charge.charge_id)
        if(!originalFound) {
          originalFound = {...e.row.data}
          this.originalCharges.push(originalFound)
        }

        if(e.dataField === "dispute_withheld") {
          charge.dispute_withheld = args.value
        }

        let index = this.chargesForInvoiceReset.reduce((searchIndex, item, index) => {
          if(item.charge_id === charge.charge_id) {
            searchIndex = index;
          }
          return searchIndex;
        }, null);

        /**
         * invoice should be reset whenever dispute witheld status has been changed
         */
        if (
          (!this.isUpdate && charge.dispute_withheld) ||
          (this.isUpdate && charge.dispute_withheld !== originalFound.dispute_withheld)
        ) {
          if (index != null)
            this.chargesForInvoiceReset[index] = charge
          else
            this.chargesForInvoiceReset.push(charge)
        } else {
          this.chargesForInvoiceReset = this.chargesForInvoiceReset.filter(item => item.charge_id !== charge.charge_id)
        }
      }

      e.setValue(args.value);
    };
  }

  onCellPrepared(event) {
    if (event.rowType !== 'header') {
      const data = event.data
      const column = event.column.dataField;

      if (
        (
          data.charge.invoice.header.status_code >= INVOICE_STATUS_ENUM.RFA &&
          (
            !data.dispute_withheld && (column === "dispute_withheld")
            || data.dispute_withheld && (column === "category_id" || column === "description" || column === "calculated_amount" || column === "dispute_withheld")
          )
        )
      ) {
        event.cellElement.style.opacity = "0.5";
        event.cellElement.style.pointerEvents = 'none';
      }
      // if account has VAT included payment withheld field must remain locked as no
      if (
        (
          (!this.isUpdate && data.charge.account && data.charge.account.is_vat_gl_output) ||
          (this.isUpdate && data.charge.invoice.account.is_vat_gl_output) // && (data.charge.status === LOOKUP_ENUM.DISPUTE_STATUS.CLOSED_WON || data.charge.status === LOOKUP_ENUM.DISPUTE_STATUS.CLOSED_LOST)
        ) && (column === "dispute_withheld")
      ) {
        if (!this.isUpdate && data.charge.account && data.charge.account.is_vat_gl_output)
          data.dispute_withheld = false
        event.cellElement.style.opacity = "0.5";
        event.cellElement.style.pointerEvents = 'none';
      }
    }
  }

  onCellClick(event){
    const { dataField } = event.column ;
    const disabledFieldsCloseState =  (dataField === 'resolution_date'
      ||  dataField === 'dispute_withheld' || dataField === 'dispute_value_awarded' || dataField === 'calculated_amount'
      ||  dataField === 'category_id'  || dataField === 'description');
    if (this.isUpdate) {
      if (event.key) {
        this.disputeCharges.forEach((disputeCharge) =>{
          if(disputeCharge.id === event.key.id) {
            if (disabledFieldsCloseState) {
              event.column.allowEditing = !disputeCharge.disableEdit
            }
          }
        })
      }
    }
  }
}
