<mat-toolbar mat-scroll-shrink class="ca-second-level-toolbar" style="height: 60px !important; padding-top: 8px;">
  <form class="ca-form" [formGroup]="form$ | async">
    <div class="flex-item" fxFlex="0 0 159px" style="margin-bottom: -15px;">
      <ca-vendor-picker
        hiDensityInput
        formControlName="vendor_id"
        placeholder="Vendor"
        [pickerForm]="form$ | async"
        [clearEnabled]="true"
        [searchEnabled]="true"
        customMethod="findVendorsWithActiveBans"
        [required]="true"
        [initialItemId]="selectedVendor"
        (onClear)="onClearVendor()"
        class="gl-coding-picker">
      </ca-vendor-picker>
    </div>
  </form>
</mat-toolbar>

<mat-sidenav-container style="height: 100%" class="app-content-list">

  <dx-data-grid #grid (onRowClick)="onRowClick($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onCellClick)="onCellClick($event)"
    [columns]="columns" [dataSource]="bans">

    <div *dxTemplate="let d of 'statusCellTemplate'">
      <button mat-button matTooltip="Change Status" (click)="changeBANStatus(d.row.data)" [ngClass]="{'btn-disabled':!d.row.data.gl_coding_enabled, 'btn-enabled': d.row.data.gl_coding_enabled}"
        [disabled]="statusChangeDisabled(d.row.data)" class="switch-button">
          {{d.row.data.gl_coding_enabled?'Enabled':'Disabled'}}
        </button>
    </div>

    <div *dxTemplate="let d of 'methodCellTemplate'">
      <button mat-button [disabled]="permissionModify" matTooltip="Change Coding Method" (click)="changeMethod(d.row.data)" [ngClass]="{'btn-gl-method-static': d.row.data.is_static, 'btn-gl-method-dynamic': !d.row.data.is_static}"
        class="switch-button">
          {{d.row.data.is_static?'Static':'Dynamic'}}
        </button>
    </div>

    <div *dxTemplate="let d of 'banCellTemplate'">
      <div caGridTooltip>{{d.row.data.account_no}}</div>
    </div>

  </dx-data-grid>
</mat-sidenav-container>
