import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";

import { GridSettingsComponent } from "../../../core/grid/grid-settings.component";
import Query from "../../../core/query/query";
import { AlertService } from '../../../shared/alert/alert.service';
import { HistoryService } from "../../../shared/history/history.service";
import { LoaderService } from "../../../shared/loader/loader.service";
import { UserSettingsService } from "../../../user/core/user-settings.service";
import { Contract } from '../../core/contract';
import { ContractService } from '../../core/contract.service';

import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import { Observable } from 'rxjs';
import { PageDetailsComponent } from "../../../core/page-details.component";
import { PageContext } from "../../../core/page.context";
import { LOOKUP_ENUM } from "../../../dictionary/core/lookup.enum";
import { DialogService } from '../../../shared/dialog/dialog.service';
import { HistoryComponent } from "../../../shared/history/history.component";
import { SortingBuilder, SortingService } from '../../../shared/sorting/sorting.service';
import { UserService } from "../../../user/core/user.service";
import { ContractRateFilterService } from '../../core/contract-rate-filter.service';
import { ContractRateGridService } from '../../core/contract-rate-grid.service';
import { ManageRatesDialogComponent } from "../../shared/rates-audit-manage-dialog/rates-audit-manage-dialog";
import { CanComponentDeactivate } from 'app/core/guard/can-deactivate-guard.searvice';
import { ContractDetailsTabsComponent } from '../contract-details-tabs/contract-details-tabs.component';
import { PermissionService } from 'app/permissions/core/permission.service';

@Component({
    selector: 'app-contract-details-rates',
    templateUrl: './contract-details-rates.component.html',
    styleUrls: ['./contract-details-rates.component.scss']
  })
  export class ContractRatesComponent extends PageDetailsComponent implements OnInit, AfterViewInit, CanComponentDeactivate {

    @ViewChild('panelSide') panelSide;
    @ViewChild('history') history: HistoryComponent;
    @ViewChild('testNotes') testNotes;
    @ViewChild('rateGrid') rateGrid: DxDataGridComponent;
    @ViewChild('tabs' ) tabs: ContractDetailsTabsComponent ;

    messages: IMessagesResourceService;
    readonly MESSAGES_MODULE: string = "contract";
    readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

    public rateColumnsSorting: any[];
    public contract: Contract;
    public rates: Array<any> = [];
    public rateColumns: Array<any>;
    public rateQuery = new Query({
      'orderBy': [['schedule.schedule_name', 'ASC'], ['line_number', 'ASC']]
    });
    public selectedRates: Array<any> = [];
    public delaySelectionRateChange = false;
    public filter: any;
    public selectionRateActive = false;
    public selectionRate: any;
    public isRateDataValid: boolean = false;
    public ratesSaving: boolean = false;
    public rateRequiredFields = [
      'contract_schedule_id',
      'rate_code',
      'rate_category',
      'mrc',
      'currency_id'
    ];
    public ratesSortingBuilder: SortingBuilder;
    public defaultGridPager: number = 20;;
    public tempIdCounter: number = 0;
    public notesCount: number = 0;
    public rowIndex: number;
    public countryUSAId = 1;
    public JurITAId = 2;
    public hasUnsavedChanges = false;
    readonly activeTabIndex = 5;
    public previousTabIndex: number;
    public auditsEnabled: boolean = false;
    public containsSchedulesWithRates = false;

    constructor(
        public contractService: ContractService,
        private readonly route: ActivatedRoute,
        public settingsService: UserSettingsService,
        public rateGridService: ContractRateGridService,
        public contractRateFilterService: ContractRateFilterService,
        public dialog: DialogService,
        public alert: AlertService,
        public loaderService: LoaderService,
        public sortingService: SortingService,
        public userService: UserService,
        public historyService: HistoryService,
        public dialogService: DialogService,
        public permissionService: PermissionService,
    ) {
    super(new PageContext({
        name: 'app.contract.contract-details-rates',
        settings: settingsService
    }));
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.ratesSortingBuilder = this.sortingService.builder();
  }

  ngOnInit(): void {
    this.contract = this.route.parent.snapshot.data.contract;

    this.permissionService.package().subscribe(permission => {
      const auditPermission = permission.resources.filter(arr => arr.key == 'audits');
      this.auditsEnabled = auditPermission.length && auditPermission[0]['allowed'];

      this.rateQuery.where['schedule.contract_shell_id'] = this.contract.id;
      this.rateQuery.where['schedule.contains_rates'] = true;
      this.loadAllSchedules(this.contract.id);
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.hasUnsavedChanges) {
      return true;
    }

    return this.dialogService.save({
      bodyText: this.messages.get('CONTRACT_RATES_DISCARD_CHANGES')
    }).afterClosed()
  }

  displayCheckModal() {
    this.dialogService.save({
      bodyText: this.messages.get('CONTRACT_RATES_DISCARD_CHANGES')
    }).afterClosed().subscribe(result => {
      this.hasUnsavedChanges = !result;
      if (result) {
        this.tabs.onTabChange(null, this.activeTabIndex);
      }
    })
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  windowBeforeUnload(): Observable<boolean> | boolean {
    return !this.hasUnsavedChanges;
  }

  scheduleIdUpdated(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'contract_schedule_id') {
        e.editorOptions.onValueChanged = (args) => {
          const schedule = this.rateGridService?.rateSchedule.find(schedule => schedule.id === args.value);
          let editedRate = this.rates[this.rowIndex]
          editedRate.contract_schedule_id = args.value
          editedRate.rate_effective_date = schedule.effective_date;
          editedRate.rate_termination_date = schedule.termination_date;

          this.rates[this.rowIndex] = editedRate
          this.isRateDataValid = this.checkRateDataValidity(this.rates);
      }
    }
  }

  loadAllSchedules(id?) {
    const rateScheduleQuery = new Query({
      where: {
        contract_shell_id: id ? id : this.contract.id,
        contains_rates: true
      },
      orderBy: [['schedule_name', 'ASC']]
    })
    this.contractService.findSchedules(rateScheduleQuery)
            .subscribe(result => {
              this.containsSchedulesWithRates = !!result?.items.length;
              this.rateGridService.rateSchedule = result.items;
              this.rateGridService.loadSettings()
                .subscribe((settings) => {
                  this.initRateColumns(settings);
                });
            })
  }

  public loadRates(rateQuery?) {
    this.loaderService.displayLoader();

      this.delaySelectionRateChange = true;
      let rateTemps = this.selectedRates.filter(rate=>rate.temp);

      if(rateQuery){
        this.rateQuery = new Query({...rateQuery});
      }
      this.contractService.findAllRates(rateQuery ? rateQuery : this.rateQuery)
        .subscribe((result) => {
          this.loaderService.hideLoader();
          this.rates = rateTemps.concat(result.items);
          this.rateQuery.total = result.total;
          if (this.rates.length) {
            setTimeout(() => {
              this.delaySelectionRateChange = false;
            }, 500);
          }
        }, (err) => {
          this.loaderService.hideLoader();
          console.log('loadRate error', err);
        });
  }

  onSelectionChangedRate(event) {
      if (!this.delaySelectionRateChange && this.rates.length) {
        this.selectedRates = event.selectedRowsData;

        if (this.selectedRates.length > 1) {
          this.selectionRate = null;
        } else {
          this.selectionRate = event.selectedRowsData[0];
        }
        if (this.selectionRateActive && !this.selectedRates.length) {
          this.loadSelectedRates();
        }
      }
    }

  loadSelectedRates() {
      if (this.rateQuery && !this.rateQuery['where'].hasOwnProperty('id')) {
        this.filter = JSON.parse(JSON.stringify(this.rateQuery['where']));
      }
      if (this.selectionRateActive) {
        this.rateQuery.remove('id');
        this.rateQuery['where'] = this.filter;
      } else {
        this.rateQuery['where'] = {};
        this.rateQuery.set('id', {
          $in: this.selectedRates.map(x => {
            return x.id;
          })
        });
      }

      this.rateQuery.where['schedule.contract_shell_id'] = this.contract.id;
      this.rateQuery.where['schedule.contains_rates'] = true;
      this.rateQuery.offset = 0;
      this.rateQuery.page = 1;
      this.selectionRateActive = !this.selectionRateActive;
      this.loadRates(this.rateQuery);
  }


  async initRateColumns(settings?) {
      const user = await this.userService.me().toPromise()

      this.rateColumnsSorting = this.rateGridService.getOrderBy(settings && settings.sorting ? settings.sorting : []);
      this.rateColumns = this.rateGridService.getColumns(settings && settings.columns ? settings.columns : [], user.country.date_format, this.auditsEnabled);

      this.defaultGridPager = settings ? settings.gridPager : 20;
      this.rateQuery.limit = this.defaultGridPager;

      if (this.rateColumnsSorting.length) {
        this.rateQuery.orderBy = this.rateColumnsSorting;
      }

      if (this.rateGrid && this.rateGrid.instance) {
        this.rateGrid.instance.option('columns', this.rateColumns);
      }
      this.loadRates()
  }

  checkRateDataValidity(rates) {
      return !rates.some(rate => (rate.country_id && rate.jur === this.JurITAId && !rate.state_id) || this.rateRequiredFields.some(field => !rate[field])
    )
  }

  onRowUpdated(e) {
    this.hasUnsavedChanges = true;
    this.isRateDataValid = this.checkRateDataValidity(this.rates);
  }

  public onRatesCellClick(event) {
      this.rowIndex = event.rowIndex;
      const sortAlowed = !this.rates.some(rate => rate.tempId);
      if (event.rowType === 'header' && event.column.allowSorting && sortAlowed && !this.isRateDataValid) {
        this.ratesSortingBuilder.apply(event, this.rateQuery);
        this.loadRates(this.rateQuery);
      }
  }

  onRatesCellPrepared(event) {
      if (event.rowType !== 'header') {
        const data = event.data
        const column = event.column.dataField;

        if (column === 'jur' && !data.country_id) {
          this.disableField(event)
        }
        if (column === 'state_id' && (!data.country_id || !data.jur || data.jur === this.countryUSAId)) {
          this.disableField(event);
        }
        if(column === 'line_number') {
          event.cellElement.style.pointerEvents = 'none';
        }
      }
  }

  disableField(event){
    event.cellElement.style.pointerEvents = 'none';
    event.cellElement.style.opacity = "0.5";
    event.cellElement.style['background-color'] = 'rgba(0,0,0,0.05)';
  }

  addRateData() {
    let newRate = {};

    newRate = {
      contract_schedule_id: null,
      rate_code: null,
      tempId: this.getTempId(),
      temp: true,
      rate_effective_date: null,
      rate_termination_date: null
    }

    if(this.rates[0]?.contract_schedule_id || !this.rates.length){
      this.rates.unshift(newRate);
    }
    this.isRateDataValid = this.checkRateDataValidity(this.rates);
  }

  saveRates() {
    this.loaderService.displayLoader();
    this.clearTemp();
    if (this.ratesSaving) return;

    this.ratesSaving = true;
    this.hasUnsavedChanges = false;
    this.contractService.saveRates(this.contract.id, this.rates)
      .subscribe((result) => {
        this.loaderService.hideLoader();
        this.rateGrid.instance.clearSelection();
        this.ratesSaving = false;
        this.isRateDataValid = false;
        this.loadRates();
        this.history.refreshList();
        this.alert.error('', this.messages.get('UPDATE_RATE_SUCCESS'));
      }, (err) => {
        this.loaderService.hideLoader();
        this.ratesSaving = false;
      });
  }

  deleteRatesHelper(rateIds,rateTemps) {
    if (rateIds.length) {
      this.contractService.deleteRates(rateIds)
        .subscribe(() => {
          this.history.refreshList()
          this.loadRatesOnDelete();
            this.alert.error('', this.messages.get('RATE_DELETE_SUCCESS'));
          },
          error => {
            if(error.data === 'SequelizeForeignKeyConstraintError') {
              setTimeout(() => {
                this.alert.error('', this.messages.get('RATE_CONSTRAIN_DELETE_ERROR'));
              }, 250);
            }
          })
    }
    if (rateTemps.length) {
      rateTemps.forEach(rateTemp => {
        this.deleteTempRate(rateTemp);
      })
    }
  }

  deleteRates() {
    let rateIds = this.selectedRates.filter(rate=>!rate.temp).map(rate => rate.id);
    let rateTemps = this.selectedRates.filter(rate=>rate.temp).map(rate => rate.tempId);
    let audits;
    let newQueryRates = new Query();
    newQueryRates.where.rate_id = rateIds
    this.contractService.findAllAuditsRates(newQueryRates)
      .subscribe((res)=>{
        audits = res.items.map(item => item)
        if(audits.length && rateIds.length) {
          this.dialog.open(ManageRatesDialogComponent,
            {
              audits,
              actionText: 'delete',
              title: 'Delete rates',
              config: null
            },
            {width: '1400px'}
          )
            .afterClosed()
            .subscribe(result => {
                if (result.deleted) {
                  this.deleteRatesHelper(rateIds,rateTemps)
                }
              },
              (error) => {
                this.alert.error('', this.messages.get('RATE_DELETE_ERROR'));
              });
        }else{
          this.dialog.confirm({
            bodyText: `Are you sure sure you want to delete rates?`
          })
            .afterClosed()
            .subscribe(
              (result) => {
                if (result) {
                  this.deleteRatesHelper(rateIds,rateTemps)
                }
              },
              (error) => {
                this.alert.error('', this.messages.get('RATE_DELETE_ERROR'));
              }
            );
        }
      })
  }

  deleteTempRate(rateTemp){
    const index = this.rates.findIndex(item => item.tempId === rateTemp);
    this.rates.splice(index, 1);
  }

  getTempId(){
    this.tempIdCounter++;
    return `temp-${this.tempIdCounter}`;
  }

  public loadRatesOnDelete() {
    this.contractService.findAllRates(this.rateQuery)
      .subscribe((result) => {
        this.rates = [...this.rates.filter(item => item.temp), ...result.items];
        this.rateQuery.total = result.total;
        this.selectedRates = [];
      }, (err) => {
        console.log('loadRate error', err);
      });
  }

  clearTemp() {
    this.rates.forEach(rate => {
      if (rate.temp){
        delete rate.temp;
      } else if(rate.tempId) {
        delete rate.tempId;
      }
    })
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  toggleFilter() {
    this.toggleSider(this.SECTIONS.FILTER_SECTION_NAME);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME);
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME);
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
  }

  filterRatesData(rateQuery) {
    if(rateQuery){
      this.rateQuery = rateQuery;
    }
    this.loadRates(this.rateQuery);
  }

  clearRatesFilter(rateQuery) {
    this.loadRates(rateQuery)
  }

  rateCsv() {
    const { rateGridService, contractService, rateQuery: query } = this;
    rateGridService.csvMap().subscribe(fields => {

      fields.forEach(field => {
        if(field.value === 'contract_schedule_id') {
          field.value = 'schedule.schedule_name'
        }
        if(field.value === 'rate_qty_uom_id') {
          field.value = 'rate_qty_uom.value'
        }
        if(field.value === 'rate_type') {
          field.value = 'rate_type_obj.value'
        }
        if(field.value === 'rate_category') {
          field.value = 'rate_category_obj.value'
        }
        if(field.value === 'currency_id') {
          field.value = 'currency.currency'
        }
        if(field.value === 'country_id') {
          field.value = 'country.alpha_2_code'
        }
        if(field.value === 'state_id') {
          field.value = 'subdivision.abbreviation'
        }
        if(field.value === 'rate_term_uom_id') {
          field.value = 'unit_of_measure.value'
        }
      });
      contractService.exportToCSV(
        'contract_rates',
        {
          fields,
          query,
          settings: {currencyField: 'currency.currency'},
          fetchDataHandler: (query) => { return contractService.findAllRates(query)}
        })
    });
  }

  rateGridSettings() {
    this.dialog
      .open(GridSettingsComponent, {
        service: this.rateGridService,
        sliderMinimum: 1
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this.initRateColumns(settings);
        }
      });
  }
}
