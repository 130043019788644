<mat-card *ngIf="!isFilter" class="filter-group">
  <mat-card-header class="filter-group-header">{{entry?.label}}</mat-card-header>
  <mat-card-content class="ca-form">
    <mat-form-field hiDensityInput>
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let tag of selectedTags" [selectable]="selectable"
                  [removable]="removable" (removed)="onRemove(tag)">
          {{tag}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input #inputField
               [formControl]="matChipInput"
               [matChipInputFor]="chipList"
               [matAutocomplete]="auto"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="onAdd($event)"
               (focus)="searchAllOnInitialFocus()"
               (focusout)="clearQueryOnFocusOut()"/>
      </mat-chip-list>

    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <mat-option *ngFor="let option of lookupItems" [value]="option" [matTooltip]="option.length > 35 ? option : null">
        <span matTooltip="option">{{ option }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-card-content>
</mat-card>

<div *ngIf="isFilter" class="tag-filter">
  <mat-form-field hiDensityInput>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let tag of selectedTags" [selectable]="selectable"
                [removable]="removable" (removed)="onRemove(tag)">
        <span>{{(entry && entry.secondRowField) ? tag.first_row : tag}}</span>
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input #inputField
             [formControl]="matChipInput"
             [matChipInputFor]="chipList"
             [matAutocomplete]="auto"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"
             (matChipInputTokenEnd)="onAdd($event)"
             (focus)="searchAllOnInitialFocus()"
             (focusout)="clearQueryOnFocusOut()"/>
    </mat-chip-list>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <div class="scrollable-container" (scroll)="onScrollEvent($event)">
        <ng-container *ngIf="entry && !entry.secondRowField">
          <mat-option *ngFor="let option of lookupItems" [value]="option"
                      [matTooltip]="option.length > 35 ? option : null">
            {{ option }}
          </mat-option>

        </ng-container>
        <ng-container *ngIf="entry && entry.secondRowField">
          <mat-option *ngFor="let option of lookupItems" [value]="option"
                      [matTooltip]="option.first_row.length > 35 ? option.first_row : null"
                      class="two-line option-italic">
            {{ option.first_row }}
            <span class="second-line">
              {{option.second_row}}
            </span>
          </mat-option>
        </ng-container>
      </div>
    </mat-autocomplete>
  </mat-form-field>
</div>
