
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

import { ChargeTypeLookupProvider } from '../../charge/core/charge-type-lookup.provider';
import { IFilterLookupProvider } from '../../core/filter/filter-lookup.provider';
import { FilterService } from '../../core/filter/filter.service';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { LookupModelLookupProvider } from '../../dictionary/core/lookup-model-lookup.provider';
import { LOOKUP_MODELS_ENUM } from '../../dictionary/core/lookup-models.enum';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { invoiceChargeFilterContext } from './invoice-charge-filter.context';
import { InvoiceFacepageService } from './invoice-facepage.service';
import { SubAccountLookupProvider } from './sub-account-lookup.provider';
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

export class DirectGlTypeLookupProvider extends IFilterLookupProvider {
  findAll(searchQuery?): Observable<any> {
    return observableOf([
      {
        value: 'Yes',
        key: true
      },
      {
        value: 'No',
        key: false
      }
    ])
  }
}

@Injectable()
export class InvoiceChargeFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];

  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public userSettingsService: UserSettingsService,
              public dictionaryService: DictionaryService,
              public invoiceService: InvoiceFacepageService) {
    super('app.cost.invoice-charge-filter', invoiceChargeFilterContext, userSettingsService);

    this.addLookupProvider('unsetAdjustment',
      new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.UNSET_ADJUSTMENTS.modelName));
    this.addLookupProvider('chargeType', new ChargeTypeLookupProvider(dictionaryService));
    this.addLookupProvider('subAccount', new SubAccountLookupProvider(invoiceService));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
