<div class="mat-input-container">
  <tag-input #tagInputAsync
             caInput
             [(ngModel)]="value"
             (onTextChange)="onTagInputChange($event)"
             [onTextChangeDebounce]="350"
             [onlyFromAutocomplete]="false"
             [onAdding]="onAdding"
             [placeholder]="placeholder"
             [secondaryPlaceholder]="placeholder"
             (onSelect)="onSelect($event)"
             [clearOnBlur]="true">
    <tag-input-dropdown [appendToBody]="false"
                        [autocompleteItems]="autocompleteItems"></tag-input-dropdown>
  </tag-input>

</div>

<mat-hint class="ca-select-hint" *ngIf="showEmailInvalid">Email address is invalid</mat-hint>
