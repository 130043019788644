import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { VendorRemitAddress } from './vendor-remit-address';
import { Restangular } from 'ngx-restangular';
import Query from '../../core/query/query';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class VendorRemitAddressService extends BaseService<VendorRemitAddress> {

  constructor(public restangular: Restangular, private entityLock: EntityLockService) {
    super('vendor-remit-address', restangular, entityLock);
   }

   findOnlyVendorRemit(query?: Query): any {
    let concreteQuery = query || new Query();
    const transformedQuery = concreteQuery.transform()
    return this.service().all(this.name).all('/vendor/remit').customGET(null, this.toFilter(transformedQuery));;
  }

}
