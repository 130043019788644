<app-svc-order-details-toolbar
  [isAddServicesHidden] = "true"
  [isEditServicesHidden] = "true"
  [isAddTermsConditionHidden] = "true"
  [isEditTermsConditionHidden] = "true"
  [isDeleteTermsConditionHidden] = "true"
  [isDownloadAttachmentHidden] = "true"
  [isDeleteAttachmentHidden] = "true"
  (backRequested)="back()"
  [isFilterHidden] = "true"
  [svcOrderHeader] = svcOrderHeader
  [isDocumentAction] = "true"
  [documentSelection] = this.selection
  (uploadDocumentRequested) ="upload()"
  (deleteDocumentRequested) = "deleteDocument()"
  (downloadDocumentRequested) ="download()"
 >
</app-svc-order-details-toolbar>

<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-svc-order-details-tabs
    [activeTabIndex]="3"
    [svcOrderHeader] = svcOrderHeader
  ></app-svc-order-details-tabs>

  <div class="tabs-body grid-container grid-with-toolbar"
       style="top: -2px; z-index: -1; overflow: hidden;">
      <!--SECTION DOCUMENTS-->
      <app-document-manager
        #documentsManager
        [tabChanged]="tabChanged"
        [folder]="DOCUMENT_INFO.FOLDER"
        [parentType]="DOCUMENT_INFO.PARENT_TYPE"
        [entityType]="DOCUMENT_INFO.ENTITY_TYPE"
        [subfolder]="DOCUMENT_INFO.SUBFOLDER"
        [entity]="svcOrderServices"
        (docSelected)="docSelected($event)"
        [masterId]="svcOrderHeaderId"
        [masterType]="SYSTEM_MODULE.SERVICE_ORDERS"
        [documentQuery]="documentQuery"
      >
      </app-document-manager>
      <ca-pager
        class="app-pager ca-default-border-top"
        [gridPager]="defaultGridPager"
        [pageContext]="documentGridService"
        [query]="documentQuery"
        (onChange)="onPageDocumentChange($event)"
      ></ca-pager>
  </div>

</mat-sidenav-container>
