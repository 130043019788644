import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';

import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";
import {
  UpdateInventoryRelatedCircuitActiveSiderSection,
} from "./inventory-related-circuit.actions";

const INVENTORY_RELATED_CIRCUIT_STATE_TOKEN = new StateToken<InventoryRelatedCircuitStateModel>('inventory_related_circuit');

export interface InventoryRelatedCircuitStateModel extends QueryableStateModel<any> {

}

@State<InventoryRelatedCircuitStateModel>({
  name: INVENTORY_RELATED_CIRCUIT_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InventoryRelatedCircuitState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }


  // @ts-ignore
  @Action(UpdateInventoryRelatedCircuitActiveSiderSection)
  updateInventoryRelatedCircuitActiveSiderSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
