import { NgModule } from '@angular/core';
import {DictionaryListComponent} from "./dictionary-list/dictionary-list.component";
import {Routes, RouterModule} from "@angular/router";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: DictionaryListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: []
})
export class DictionaryRoutingModule { }
