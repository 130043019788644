import {Injectable} from '@angular/core';
import {ApiService} from "../api";

@Injectable()
export class ResourceService {

  constructor(private apiService: ApiService) {
  }

  cache() {
    return this.apiService.cache('resource');
  }

  public findAll() {
    const cache = this.cache();
    return this.apiService.get('resource', null, {cache});
  }
}
