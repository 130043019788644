import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserSessionService} from "./services/user-session.service";
import {LoggedUserGuardService} from "./services/logged-user-guard.service";

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    UserSessionService,
    LoggedUserGuardService
  ]
})
export class UserSessionModule { }
