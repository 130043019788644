import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import {ContractService} from '../core/contract.service';

@Injectable({
  providedIn: 'root'
})

// @ts-ignore
export class ContractScheduleResolver implements Resolve<boolean> {

  constructor(private contractService: ContractService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const id = route.params.scheduleId;
    return this.contractService.findScheduleById(id).toPromise();
  }
}