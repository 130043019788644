import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { GridService } from '../../shared/grid/grid.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { Contract } from '../core/contract';
import { ContractGridService } from '../core/contract-grid.service';
import { ContractQuery } from '../core/contract.query';
import { ContractService } from '../core/contract.service';
import { ContractManageDialogComponent } from '../shared/contract-manage-dialog/contract-manage-dialog.component';
import { ContractShellMenageDialogComponent } from '../shared/contract-shell-menage-dialog/contract-shell-menage-dialog.component';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.css']
})
export class ContractListComponent extends PageListComponent implements OnInit, AfterViewInit {
  @ViewChild('grid') dataGrid: DxDataGridComponent;
  @ViewChildren(DxDataGridComponent) dataGrids: QueryList<DxDataGridComponent>;

  vendorContracts: Array<any>;

  public contracts: Array<Contract>;
  public total: number;
  public selection: Contract;
  public columns: Array<any>;
  public columnDepth = 0;
  public sorting: any[][];

  public selectionRow;

  nestedSources = [];

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'contract';
  readonly CORE_MODULE: string = 'core';

  //This part of the code we need for badges. For now, we didn't implement this functionality
  query: ContractQuery = new ContractQuery({
    orderBy: [["vendor_name", "ASC"]]
    // where: {
      // termination_date: { '$gte': Date.now() }
    // }
  });

  constructor(public contractService: ContractService,
    public contractGridService: ContractGridService,
    public dialog: DialogService,
    public alert: AlertService,
    public router: Router,
    public route: ActivatedRoute,
    public settingsService: UserSettingsService,
    public loaderService: LoaderService) {
    super(new PageContext({
      name: 'app.contract.contract-list',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  loadData(query?: ContractQuery) {
    this.query = query;
    // if (this.query.orderBy && this.query.orderBy[0][0] == 'id')
    //   this.query.orderBy = [["vendor_name", "ASC"]]
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.contractService.findAllByVendor(this.query)
      .subscribe(
      (vendorContracts) => {
        this.vendorContracts = vendorContracts.items;
        this.query.total = vendorContracts.total;
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        },
        (error) => {
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        }
      );
  }
  getExpiryMessage(count){
    if(!count){
      return `Expiring today`
    }else{
      return `Expiring in ${count} ${count > 1 ? 'days' : 'day'}`
    }
  }

  onRowClick(row) {
    let component = row.component;
    let prevClickTime = component.lastClickTime;

    component.lastClickTime = new Date();

    if (prevClickTime && (component.lastClickTime - prevClickTime < 250)) {
      let selection = <Contract>row.data;
      // const navigationExtras: NavigationExtras = {state: {data: {row.data.commitment}}};
      let commitmentTotal = row.data.commitment
      this.router.navigate(['/contract', selection.vendor_id, 'info'], {state: {data: {commitmentTotal}}});
    }
  }

  refresh() {
    this.loadData(this.query);
  }

  newContract() {
    this.dialog.open(ContractShellMenageDialogComponent)
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.refresh();
        }
      });
  }

  // editContract(contract) {
  //   if (this.selection) {
  //     this.dialog.open(ContractManageDialogComponent, {
  //       contract: this.selection,
  //       isUpdate: true
  //     }, {width: '800px'})
  //       .afterClosed()
  //       .subscribe(result => {
  //         if (result) {
  //           if (result.cancelByTimer) {
  //             return this.alert.success('', this.coreMessages.get('TIMER_EXPIRED'));
  //           }
  //           if (result.deleted) {
  //             this.loadData(this.query);
  //             this.selectFirstRow();
  //             this.alert.success('', this.messages.get('DELETE_SUCCESS'));
  //           }
  //           else {
  //             this.selection = Object.assign(this.selection, result);
  //
  //             const lastGrid = this.dataGrids.last;
  //
  //             lastGrid.instance.repaint();
  //             lastGrid.instance.refresh();
  //
  //             this.alert.success('', this.messages.get('UPDATE_SUCCESS'));
  //           }
  //         }
  //       });
  //   }
  // }

  deleteContract(contract) {
    if (contract) {
      this.dialog.confirm({
        bodyText: `Delete contract ${contract.name}?`
      })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.contractService.delete(contract.id)
              .subscribe(() => {
                this.loadData(this.query);
                this.selectFirstRow();
                this.alert.success('', this.messages.get('DELETE_SUCCESS'));
              }, (err) => {
                this.alert.error('', this.messages.get('DELETE_ERROR'));
              });
          }
        });
    }
  }

  csv() {
    const { contractGridService, contractService, query } = this;
    contractGridService.csvMap().subscribe(fields => {
      contractService.exportToCSV(
        'contracts',
        {
          fields,
          query,
          fetchDataHandler: (query) => { return contractService.findAllByVendor(query)}
        })
    });
  }

  ngOnInit() {
    this._init();
  }

  getGridService(): GridService {
    return this.contractGridService;
  }

  ngAfterViewInit() {
    this.contractGridService.create(this.dataGrid.instance, null);
    super.ngAfterViewInit();
  }

  gridSettings() {
    this.dialog.open(GridSettingsComponent, {
      service: this.contractGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }
}
