import {Injectable} from '@angular/core';
import {CookieService} from "ngx-cookie-service";

const AUTH_KEY = 'authorization_token';

@Injectable()
export class JwtService {

  constructor(private cookieService: CookieService) {
  }

  getToken(): String {
    const token = this.cookieService.get(AUTH_KEY);
    if (token) {
      return token;
    }
    return null;
  }

  saveToken(token: string) {
    this.cookieService.set(AUTH_KEY, token);
  }

  destroyToken() {
    this.cookieService.delete(AUTH_KEY)
  }

}
