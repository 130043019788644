
import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";

import { GridSettingsComponent } from "../../../core/grid/grid-settings.component";
import Query from "../../../core/query/query";
import { AlertService } from '../../../shared/alert/alert.service';
import { UserSettingsService } from "../../../user/core/user-settings.service";
import { ContractService } from '../../core/contract.service';

import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import { Observable } from 'rxjs';
import { PageDetailsComponent } from "../../../core/page-details.component";
import { PageContext } from "../../../core/page.context";
import { LOOKUP_ENUM } from "../../../dictionary/core/lookup.enum";
import { DialogService } from '../../../shared/dialog/dialog.service';
import { SortingBuilder, SortingService } from '../../../shared/sorting/sorting.service';
import { UserService } from "../../../user/core/user.service";
import { ContractRateQuery } from '../../core/contract-rate.query';
import { ContractScheduleDetailRateFilterService } from "../../core/contract-schedule-detail-rate-filter.service";
import { ContractScheduleDetailRateGridService } from "../../core/contract-schedule-detail-rate-grid.service";
import { ManageRatesDialogComponent } from "../../shared/rates-audit-manage-dialog/rates-audit-manage-dialog";
import { ContractDetailsTabsComponent } from 'app/contract/contract-details/contract-details-tabs/contract-details-tabs.component';

@Component({
    selector: 'app-contract-schedule-rates',
    templateUrl: './contract-schedule-rates.component.html',
    styleUrls: ['./contract-schedule-rates.component.scss']
  })
  export class ContractScheduleRatesComponent extends PageDetailsComponent implements OnInit, AfterViewInit {

    @ViewChild('panelSide') panelSide;
    @ViewChild('rateGrid') rateGrid: DxDataGridComponent;
    @ViewChild('tabs' ) tabs: ContractDetailsTabsComponent;

    messages: IMessagesResourceService;
    readonly MESSAGES_MODULE: string = "contract";
    readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
    readonly LOCALS = {
      DISCARD_CHANGES: 'You have unsaved configuration changes! Do you want to discard them'
    };

    public rateColumnsSorting: any[];
    public rateRequiredFields = [
        'rate_code',
        'rate_category',
        'mrc',
        'currency_id'
      ];
    public schedule: any;
    public rateColumns: Array<any>;
    public rates: Array<any> = [];
    public selectedRates: Array<any> = [];
    public isRateDataValid: boolean = false;
    public ratesSortingBuilder: SortingBuilder;
    public ratesSaving: boolean = false;
    tempIdCounter: number = 0;

    queryRate: ContractRateQuery = new ContractRateQuery({});

    countryUSAId = 1;
    JurITAId = 2

    public notesCount: number = 0;
    public hasUnsavedChanges = false;
    readonly activeTabIndex = 3

    constructor(
        public contractService: ContractService,
        private readonly route: ActivatedRoute,
        public settingsService: UserSettingsService,
        public contractScheduleDetailRateGridService: ContractScheduleDetailRateGridService,
        public contractScheduleDetailRateFilterService: ContractScheduleDetailRateFilterService,
        public dialog: DialogService,
        public alert: AlertService,
        public sortingService: SortingService,
        public userService: UserService,
        public dialogService: DialogService
    ) {
    super(new PageContext({
        name: 'app.contract.contract-schedule-rates',
        settings: settingsService
    }));
    this.ratesSortingBuilder = this.sortingService.builder();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit(): void {
    this.schedule = this.route.parent.snapshot.data.schedule;
    this.contractScheduleDetailRateGridService.loadSettings()
      .subscribe((settings) => {
        this.initRateColumns(settings)
      });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.hasUnsavedChanges) {
      return true;
    }

    return this.dialogService.save({
      bodyText: this.messages.get('CONTRACT_RATES_DISCARD_CHANGES')
    }).afterClosed()
  }

  displayCheckModal() {
    this.dialogService.save({
      bodyText: this.messages.get('CONTRACT_RATES_DISCARD_CHANGES')
    }).afterClosed().subscribe(result => {
      this.hasUnsavedChanges = !result;
      if (result) {
        this.tabs.onTabChange(null, this.activeTabIndex);
      }
    })
  }


  async initRateColumns(settings?) {
    const user = await this.userService.me().toPromise()

    this.rateColumnsSorting = this.contractScheduleDetailRateGridService.getOrderBy(settings && settings.sorting ? settings.sorting : []);
    this.rateColumns = this.contractScheduleDetailRateGridService.getColumns(settings && settings.columns ? settings.columns : [], user.country.date_format);

    if (this.rateColumnsSorting.length) {
      this.queryRate.orderBy = this.rateColumnsSorting;
    }

    if (this.rateGrid && this.rateGrid.instance) {
      this.rateGrid.instance.option('columns', this.rateColumns);
    }
    this.loadRates()
  }

  public loadRates(event?: ContractRateQuery){
    if(event){
      this.queryRate = new ContractRateQuery({...event});
    }
    this.queryRate.where['contract_schedule_id'] = this.schedule.id;
    this.contractService.findAllRates(this.queryRate).subscribe((ratesData) =>{
      this.rates = ratesData.items;
      this.queryRate.total = ratesData.total;
    });
  }

  onSelectionChangedRate(event) {
    this.selectedRates = event.selectedRowsData;
  }

  public onRatesCellClick(event) {
    const sortAlowed = !this.rates.some(rate => rate.tempId);
    if (event.rowType === 'header' && event.column.allowSorting && sortAlowed && !this.isRateDataValid) {
      this.ratesSortingBuilder.apply(event, this.queryRate);
      this.loadRates(this.queryRate);
    }
  }

  onRatesCellPrepared(event) {
    if (event.rowType !== 'header') {
      const data = event.data
      const column = event.column.dataField;
      if (column === 'jur' && !data.country_id) {
        this.disableField(event)
      }
      if (column === 'state_id' && (!data.country_id || !data.jur || data.jur === this.countryUSAId)) {
        this.disableField(event);
      }
      if(column === 'line_number') {
        event.cellElement.style.pointerEvents = 'none';
      }
    }
  }

  disableField(event){
    event.cellElement.style.pointerEvents = 'none';
    event.cellElement.style.opacity = "0.5";
    event.cellElement.style['background-color'] = 'rgba(0,0,0,0.05)';
  }

  onRowUpdated(e) {
    this.hasUnsavedChanges = true;
    this.isRateDataValid = this.checkRateDataValidity(this.rates);
  }

  checkRateDataValidity(rates){
    return !rates.some(rate => (rate.country_id && rate.jur === this.JurITAId && !rate.state_id) || this.rateRequiredFields.some(field => !rate[field]))
  }

  addRateData() {
    let newRate = {};

    newRate = {
      contract_schedule_id: this.schedule.id,
      rate_termination_date: this.schedule.termination_date,
      rate_effective_date: this.schedule.effective_date,
      tempId: this.getTempId(),
      temp: true
    }
    if(!this.rates.length || this.rates[0].rate_code){
      this.rates.unshift(newRate);
    }
    this.isRateDataValid = this.checkRateDataValidity(this.rates);
  }

  saveRates() {
    this.clearTemp();
    if (this.ratesSaving) return;

    this.ratesSaving = true;
    this.hasUnsavedChanges = false;
    this.contractService.saveRates(this.schedule.contract_shell_id, this.rates)
      .subscribe((result) => {
        this.ratesSaving = false;
        this.isRateDataValid = false;
        this.loadRates();
        this.alert.error('', this.messages.get('UPDATE_RATE_SUCCESS'));
      }, (err) => {
        console.log('save rates error', err);
        this.ratesSaving = false;
      });
  }

  private  clearTemp() {
    this.rates.forEach(rate => {
      if (rate.temp){
        delete rate.temp;
      } else if(rate.tempId) {
        delete rate.tempId;
      }
    })
  }

  deleteRatesHelper(rateIds,rateTemps){
    if (rateIds.length) {
      this.contractService.deleteRates(rateIds)
        .subscribe(() => {
          this.loadRatesOnDelete();
          this.alert.error('', this.messages.get('RATE_DELETE_SUCCESS'));
        },
            error => {
            if(error.data === 'SequelizeForeignKeyConstraintError') {
              setTimeout(() => {
                this.alert.error('', this.messages.get('RATE_CONSTRAIN_DELETE_ERROR'));
              }, 250);
            }
          })
    }
    if (rateTemps.length) {
      rateTemps.forEach(rateTemp => {
        this.deleteTempRate(rateTemp);
      })
    }
  }

  public loadRatesOnDelete() {
    this.contractService.findAllRates(this.queryRate)
      .subscribe((result) => {
        this.rates = [...this.rates.filter(item => item.temp), ...result.items];
        this.queryRate.total = result.total;
      }, (err) => {
        console.log('loadRate error', err);
      });
  }

  deleteRates() {

    let rateIds = this.selectedRates.filter(rate=>!rate.temp).map(rate => rate.id);
    let rateTemps = this.selectedRates.filter(rate=>rate.temp).map(rate => rate.tempId);
    let audits;
    let newQueryRates = new Query();
    newQueryRates.where.rate_id = rateIds
    this.contractService.findAllAuditsRates(newQueryRates)
      .subscribe((res)=>{
        audits = res.items.map(item => item)
        if(audits.length && rateIds.length) {
          this.dialog.open(ManageRatesDialogComponent,
            {
              audits,
              actionText: 'delete',
              title: 'Delete rates',
              config: null
            },
            {width: '1400px'}
          )
            .afterClosed()
            .subscribe(result => {
                if (result.deleted) {
                  this.deleteRatesHelper(rateIds,rateTemps)
                }
              },
              (error) => {
                this.alert.error('', this.messages.get('RATE_DELETE_ERROR'));
              }
            );
        }else{
          this.dialog.confirm({
            bodyText: `Are you sure sure you want to delete rates?`
          })
            .afterClosed()
            .subscribe(
              (result) => {
                if (result) {
                  this.deleteRatesHelper(rateIds,rateTemps)
                }
              },
              (error) => {
                this.alert.error('', this.messages.get('RATE_DELETE_ERROR'));
              }
            );
        }
      })
  }

  deleteTempRate(rateTemp){
    const index = this.rates.findIndex(item => item.tempId === rateTemp);
    this.rates.splice(index, 1);
  }

  getTempId(){
    this.tempIdCounter++;
    return `temp-${this.tempIdCounter}`;
  }

  rateCsv() {
    const {contractScheduleDetailRateGridService, contractService, queryRate: query} = this;
    contractScheduleDetailRateGridService.csvMap().subscribe(fields => {

      fields.forEach(field => {
        if(field.value === 'contract_schedule_id') {
          field.value = 'schedule.schedule_name'
        }
        if(field.value === 'rate_qty_uom_id') {
          field.value = 'rate_qty_uom.value'
        }
        if(field.value === 'rate_type') {
          field.value = 'rate_type_obj.value'
        }
        if(field.value === 'rate_category') {
          field.value = 'rate_category_obj.value'
        }
        if(field.value === 'currency_id') {
          field.value = 'currency.currency'
        }
        if(field.value === 'country_id') {
          field.value = 'country.alpha_2_code'
        }
        if(field.value === 'state_id') {
          field.value = 'subdivision.abbreviation'
        }
        if(field.value === 'rate_term_uom_id') {
          field.value = 'unit_of_measure.value'
        }
      });

      contractService.exportToCSV(
        'schedule_rates',
        {
          fields,
          query,
          settings: {currencyField: 'currency.currency'},
          fetchDataHandler: (query) => { return contractService.findAllRates(query)}
        })
    });
  }

  rateGridSettings() {
    this.dialog
      .open(GridSettingsComponent, {
      service: this.contractScheduleDetailRateGridService,
      sliderMinimum: 1
      })
      .afterClosed()
      .subscribe(settings => {
      if (settings) {
          this.resetDataPager();
          this.initRateColumns(settings);
      }
      });
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  toggleFilter() {
    this.toggleSider(this.SECTIONS.FILTER_SECTION_NAME);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME);
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
  }
}
