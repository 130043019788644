<mat-toolbar color="accent" mat-scroll-shrink>

  <span class="sneak-peek-title">{{ project?.name }}</span>

  <span class="example-fill-remaining-space"></span>

  <button mat-icon-button (click)="closeSide()">
      <ca-icon [key]="'CLOSE'"></ca-icon>
  </button>
</mat-toolbar>
<div class="ca-card ca-card-sneak-peek">
  <mat-card caCard>
    <mat-card-content>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Project ID</label>
          <p>
            {{ project?.project_id }}
          </p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Project Name</label>
          <p>
            {{ project?.name }}
          </p>
        </div>
      </div>



      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Owner</label>
          <p>
            {{ project?.owner?.full_name || 'N/A'}}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Status</label>
          <p>
            {{ project?.status?.value || 'N/A'}}
          </p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div  fxFlex class="entry-list-item">
          <label>Start Date</label>
          <p>
            {{ (project?.start_date | caDate) || 'N/A' }}
          </p>
        </div>
        <div  fxFlex class="entry-list-item">
          <label>End Date</label>
          <p>
            {{ (project?.end_date | caDate) || 'N/A' }}
          </p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Description</label>
          <p>
            {{ project?.description || 'N/A'}}
          </p>
        </div>
      </div>



    </mat-card-content>
  </mat-card>
</div>

