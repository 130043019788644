import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class AuditRunGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super('app.cost.audit-run-grid', userSettings);
  }

  defaultColumns = [{
    caption: '',
    headerCellTemplate: 'selectionBadgeHeaderTemplate',
    cellTemplate: 'selectionBadgeCellTemplate',
    fixed: true,
    width: 40,
    allowSorting: false
  },
  {
    dataField: 'type.value',
    caption: 'Audit Type',
    alignment: 'left',
    width: 150
  },
  {
    dataField: 'name',
    caption: 'Audit Name',
  }];
}
