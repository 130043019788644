import {UpdateQueryOptions} from "../../../../common/state/query.actions";

export class UpdateInvoiceDocumentQuery {
  static readonly type = '[INVOICE_DOCUMENT] Update Query';

  constructor(public options?: UpdateQueryOptions) {
  }
}

export class UpdateInvoiceDocumentSiderSection {
  static readonly type = '[INVOICE_DOCUMENT] Update active sider section';

  constructor(public name: string) {
  }
}
