<div class="mat-dialog-top-spacer"></div>

<div mat-dialog-content class="ca-dialog-content">

  <p>{{deactivationMessage}}</p>

  <form class="ca-form dialog-content-no-margin">
    <div fxLayout="row wrap">
      <mat-form-field caInput class="caGap">
        <textarea matInput placeholder="Note" [formControl]="note" fxFlex="100%" rows="3" required></textarea>
      </mat-form-field>
    </div>
  </form>


</div>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
    <ca-confirm-delete-button #genericButton  [requestInput]="true" [snackBarDeleteInputMessage]="'Please type in '+ randomString + ' to ' +  actionText" [placeHolder]="deletePlacecholder" [entityName]="randomString"
                              (deleteRequested)="submit()" [deleteText]="actionText" [disabled]="note?.value === '' ">
    </ca-confirm-delete-button>
  </div>
  <button #cancelButton mat-button type="submit" (click)="cancel()">
    Cancel
  </button>
</mat-dialog-actions>
