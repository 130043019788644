import {
  Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FilterEntry} from '../filter-entry';
import Query from '../../query/query';
import * as moment from 'moment';
import {ConfigService} from '../../config/config.service';
import {Config} from '../../config/config';
import {DateRangeFilterService} from './core/date-range-filter.service';
import {ChargeFilterService} from '../../../charge/core/charge-filter.service';
import {path} from 'ramda';

@Component({
  selector: 'ca-date-range-filter',
  templateUrl: 'date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss']
})

export class RangeFilterComponent implements OnInit, OnChanges {
  @Output() queryChanged: EventEmitter<Query> = new EventEmitter();
  @Input('entry') entry: FilterEntry;
  @Input('query') query = new Query();
  @Input('range') range = true;
  @Input('rangesToExclude') rangesToExclude: any;
  @ViewChild('dateRangeSelect') dateRangeSelect;
  @Input('filter') isFilter: boolean = false;

  internalDateQuery: any = {
    from: '',
    to: '',
    customRange: false
  };

  internalDateRange = null;
  ranges: any[];
  max = null;

  readonly RANGE_TYPES = {
    PRIOR: 'Prior',
    LAST: 'Last'
  };

  constructor(private configService: ConfigService,
              private dateRangeService: DateRangeFilterService,
              public chargeFilterService: ChargeFilterService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['query'] && !changes['query'].firstChange) {
      this.clearInternalDateQueryRanges()
    }

    if (changes['entry']) {
      if (!this.entry.lookupProvider) {
        const dateRangeLookupProvider = this.chargeFilterService.getLookupProvider(this.entry.lookupProviderName).provider;
        this.entry.lookupProvider = dateRangeLookupProvider;
      }

    }
  }

  ngOnInit() {
    this.configService.findAll().subscribe((config: Config) => {
      this.ranges = config && config.date_filter_range ? this.createRangesFromConfig(config.date_filter_range) : this.dateRangeService.getDefaultRanges();
      if(this.entry.defaultValue){
        this.internalDateRange = this.ranges[this.entry.defaultValue[0]];
        this.internalDateQuery = this.internalDateRange;
      }
      if(this.entry.max == 'Prior 6 months')
        this.max = moment().subtract(6, 'months').startOf('month').toDate();

    });
  }

  createRangesFromConfig(value) {
    let items = path(['entry', 'lookupProvider', 'customRanges', 'length'])(this) ? value.concat(this.entry.lookupProvider.customRanges) : value;
    // TODO: Optimize this
    /* Remove items to be excluded */
    if (path(['entry', 'lookupProvider', 'excludeRanges', 'length'])(this)) {
      for (let i = 0, len = this.entry.lookupProvider.excludeRanges.length; i < len; i++) {
        for (let j = 0, len2 = items.length; j < len2; j++) {
          if (this.entry.lookupProvider.excludeRanges[i].label === items[j].label) {
            items.splice(j, 1);
            len2 = items.length;
          }
        }
      }
    }

    return this.dateRangeService.createRanges(items);
  }


  ngOnDateChange(event, name, range?) {
    let from, to;

    if (range) {
      from = range.from ? range.from : null;
      to = range.to ? range.to : null;

      if (range === 'customRange') {
        this.internalDateQuery = {
          from: null,
          to: null,
          customRange: true
        };
      } else {
        this.internalDateQuery['customRange'] = false;
      }
    } else {
      from = this.internalDateQuery['from'];
      to = this.internalDateQuery['to'];
    }

      if(this.max && moment(from).format('YYYY-MM-DD') < moment(this.max).format('YYYY-MM-DD'))
        from = moment(this.max).format('YYYY-MM-DD')

      from = from ? moment(from).format('YYYY-MM-DD') : (this.max ? moment(this.max).format('YYYY-MM-DD') : null);
      to = to ? moment(to).format('YYYY-MM-DD') : null;

      if (from != null && to != null) {
        this.query.where[this.entry['field']] = {'$between': [from, to]}
      } else if (from != null) {
        this.query.where[this.entry['field']] = {'$gte': from}
      } else if (to != null) {
        this.query.where[this.entry['field']] = {'$lte': to}
      } else {
        delete this.query.where[this.entry['field']];
      }

      this.queryChanged.emit(this.query)
  }

  clearInternalDateQueryRanges() {
    if (this.dateRangeSelect) {
      this.dateRangeSelect.close();
    }

    if(!this.ranges) {
      this.configService.findAll().subscribe((config: Config) => {
        this.ranges = config && config.date_filter_range ? this.createRangesFromConfig(config.date_filter_range) : this.dateRangeService.getDefaultRanges();
      });
    }
    if(this.entry.defaultValue) {
      this.internalDateRange = this.ranges[this.entry.defaultValue[0]];
      this.internalDateQuery = this.internalDateRange;
      if (this.internalDateQuery.from != null && this.internalDateQuery.to != null) {
        this.query.where[this.entry['field']] = {'$between': [this.internalDateQuery.from, this.internalDateQuery.to]}
      } else if (this.internalDateQuery.from != null) {
        this.query.where[this.entry['field']] = {'$gte': this.internalDateQuery.from}
      } else if (this.internalDateQuery.to != null) {
        this.query.where[this.entry['field']] = {'$lte': this.internalDateQuery.to}
      }
    }
    else {
      this.internalDateQuery = {
        from: null,
        to: null,
        customRange: false
      };
      this.internalDateRange = null;
      delete this.query.where[this.entry['field']];
    }
    this.queryChanged.emit(this.query)

  }
}
