export interface SharedFilterOptions {
  component?: string;
  query?: any
}

// export class UpdateInventoryDetailActiveTab {
//   static readonly type = '[INVENTORY_DETAILS] Update active tab';
//   constructor(public key: string) {}
// }

export class UpdateInventoryDetailActiveSiderSection {
  static readonly type = '[INVENTORY_DETAILS] Update active sider section';
  constructor(public name: string) {}
}

export class UpdateInventoryDetailsFilter {
  static readonly type = '[INVENTORY_DETAILS] Update shared filter';
  constructor(public options?: SharedFilterOptions) {}
}

export class LoadSingleInventory {
  static readonly type = '[INVENTORY_DETAILS] Load inventory details';
  constructor(public id: number) {}
}
