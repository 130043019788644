<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title" *ngIf="form">
  <h1 mat-dialog-title style="text-transform: capitalize">{{formTitle.split('_').join(' ')}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content app-order-manage-dialog" *ngIf="form">
  <div class="ca-tab tabbed-content padding-bottom">
    <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="activeTabIndex">
      <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs" [disabled]="tab.disabled">
      </mat-tab>
    </mat-tab-group>
  </div>

  <div [ngClass]="{'hidden': !tabGroup.isActive(1)}" class="tabs-body fixed tabs-content-padding">
    <form class="ca-form dialog-content-no-margin" [formGroup]="form" style="display: flex;">
      <div style="flex: 1 1 50%;">
        <div class="flex-container" fxLayout>
          <div fxFlex>
            <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
              <div class="flex-item caGap caGapRight" fxFlex="45%">
                <ca-vendor-picker
                  caInput
                  [disabled]="isInventory || orderAlreadyAccepted"
                  formControlName="vendor_id"
                  [pickerForm]="form"
                  placeholder="Vendor"
                  [initialItem]="order?.vendor"
                  [clearEnabled]="true"
                  [required]="true">
                  <!--                  [initialItem]="order?.vendor"-->
                </ca-vendor-picker>
                <mat-hint class="ca-select-hint"
                          *ngIf="form.hasError('required', ['vendor_id']) && form.controls.vendor_id.touched">
                  {{VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </div>

              <div class="flex-item caGap caGapRight" fxFlex="27%">
                <ca-date-picker [disabled]="orderAlreadyAccepted" caInput required="true" placeholder="Request Date" formControlName="request_date"></ca-date-picker>
              </div>

              <div class="flex-item caGap" fxFlex>
                <ca-currency-picker
                  clearEnabled="true"
                  caInput
                  [disabled]="isInventory"
                  #currencyPicker
                  [required]="true"
                  [searchEnabled]="true"
                  (onSelectionChange)="onCurrencyChange($event)"
                  name="currency_id"
                  formControlName="currency_id"
                  [pickerForm]="form"
                  placeholder="Currency">
                </ca-currency-picker>
              </div>

            </div>

            <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
                            <div class="flex-item caGap caGapRight" fxFlex="40%">
                              <ca-project-picker
                                caInput
                                placeholder="Project"
                                formControlName="project_id"
                                [pickerForm]="form"
                                [initialItem]="order?.project"
                                [clearEnabled]="true">
                              </ca-project-picker>
                            </div>

              <div class="flex-item caGap caGapRight" fxFlex="27%">
                <ca-currency caInput formControlName="total_est_mrc" placeholder="Total Est MRC" [currency]="selectedCurrency"></ca-currency>
              </div>
              <div class="flex-item caGap" fxFlex>
                <ca-currency caInput formControlName="total_est_nrc" placeholder="Total Est NRC" [currency]="selectedCurrency"></ca-currency>
              </div>
            </div>
            <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
              <div class="flex-item caGap" fxFlex>
                <mat-form-field caInput>
              <textarea matInput placeholder="Justification Description" type="text" style="height: 69px;"
                        formControlName="justification_description" rows="3"></textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
              <div class="flex-item caGap" fxFlex>
              <mat-form-field caInput>
              <textarea matInput placeholder="Additional Info" type="text" formControlName="additional_info" style="height: 70px;"
                        rows="3"></textarea>
                </mat-form-field>
              </div>
          </div>
            </div>
          </div>
        </div>

       <!--Custom Fields -->
      <div class="caGap caGapLeft"  fxLayout="column" style=" flex: 1 1 10%; position: relative">
        <ca-custom-fields
          [form] = "this.form"
          [customFieldService]="this.orderService"
          [customField]="this.order"
          [fields]="fields"
          [categories]="categories"
          [showCategory]="showCategory"
        ></ca-custom-fields>
      </div>
    </form>
  </div>

  <div [ngClass]="{'hidden': !tabGroup.isActive(2)}" class="tabs-body tabs-content-padding" style="height: 85%">
    <app-contact-section (contactsChanged)="onContactsChanged($event)" [orderContacts]="order.contacts"></app-contact-section>
  </div>
</div>
<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
    <ca-confirm-delete-button #genericButton *ngIf="order && order.id" [requestInput]="true"
                              [placeHolder]="'Order number'"
                              [entityName]="order?.full_order_id"
                              (deleteRequested)="deleteOrder($event)"></ca-confirm-delete-button>
  </div>

  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>

  <button mat-button #saveButton type="submit" (click)="onSubmit(form)" [disabled]="form?.invalid">
    Save
  </button>
</mat-dialog-actions>
