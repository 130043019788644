<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Sent to Vendor</h1>
</div>
<div mat-dialog-content class="ca-dialog-content">

  <form class="ca-form dialog-content-no-margin" [formGroup]="form">
    <div>
      <div class="flex-container" fxLayout="row wrap" fxLayoutGap="24px">
        <div class="flex-item" fxFlex="50%">
          <ca-date-picker caInput placeholder="Sent to Vendor" [minDate]="minAllowedDate"  formControlName="send_date" required="true"></ca-date-picker>
        </div>
      </div>
    </div>

  </form>
</div>
<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button (click)="cancel()">
    Cancel
  </button>
  <button mat-button
          [disabled]="form?.invalid || form.controls['send_date'].value < minAllowedDate"
          type="button"
          (click)="approve(form)">
    Save
  </button>
</mat-dialog-actions>
