import {QUOTE_SERVICE_STATUS} from './quote-service-status.enum';

export const serviceLevelFilterContext = {
  filters: [
    {
      field: 'quote_service_id',
      label: 'Service ID',
      type: 'string',
      index: 1,
      visible: true
    },
    {
      field: 'quote.quote_id',
      label: 'Quote ID',
      type: 'string',
      index: 2,
      visible: true
    },
    {
      field: 'service_status',
      label: 'Quote Service Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'quoteServiceStatus',
      index: 2,
      visible: true,
      defaultValue: [QUOTE_SERVICE_STATUS.OPEN]
    },
    {
      field: 'quote.quote_header_status',
      label: 'Quote Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'quoteStatus',
      index: 3,
      visible: true
    },
    {
      field: 'quote.id',
      label: 'Quote No ',
      type: 'number',
      index: 4,
      visible: true
    },
    {
      field: 'selected_offer.vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 5,
      visible: true
    },
    {
      field: 'address.search_address',
      label: 'Address',
      type: 'string',
      index: 6,
      visible: true
    },
    {
      field: 'create_date',
      label: 'Create date',
      type: 'date',
      index: 7,
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
    },
    {
      field: 'complete_date',
      label: 'Close date',
      type: 'date',
      index: 8,
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
    },
    {
      field: 'quote.customer.company',
      label: 'Customer',
      type: 'lookup',
      lookupProviderName: 'customer',
      index: 9,
      visible: true
    }
  ]
};
