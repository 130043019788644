<form class="ca-form ca-address-selection-form" [formGroup]="addressForm">

  <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">

    <div class="flex-item caGap caGapRight" fxFlex="180px">
      <ca-country-picker caInput formControlName="countrySearch_id" [initialItem]="countrySearch_id" showEntriesByAttributeName = 'is_address_enabled' [pickerForm]="addressForm" placeholder="Country" [clearEnabled]="true"
        [searchEnabled]="true" [disabled]="disabled" (onPickerChange)="onCountryChange()">
      </ca-country-picker>
    </div>

    <div class="flex-item caGap" fxFlex>
      <mat-form-field caInput class="autocomplete-container" [ngClass]="{ 'ca-address-input-invalid': (required && requiredValidator()) && !verifying && (!unverified)}">
        <input matInput placeholder="{{ componentTitle || 'Search Address'}}"
          formControlName="addressSearch"
          id="address-search"
          required="{{ required }}"
          [matAutocomplete]="auto" class="ca-address-search-input"
          attr.data-ca-required="{{ required }}"
          (change)="inputChange($event)">
        <mat-hint *ngIf="(required && requiredValidator()) && !verifying && (!unverified)"
          class="error-class">
          {{VALIDATION.REQUIRED_ERROR}}
        </mat-hint>
      </mat-form-field>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="suggestionSelected($event)">
        <div *ngFor="let entry of suggestions" on-mousedown="mouseDown()" on-mouseup="mouseUp()">
          <!-- Show if search DB and SmartyStreets -->
          <div *ngIf="!addressesSearchInternallyOnly">
            <!-- Show if from DB and has the same country id -->
            <mat-option *ngIf="entry.internal === true && entry.country_id === addressForm.get('countrySearch_id').value " [value]="entry.text"
              [ngClass]="{'italic': entry.addNew}">
              <span>{{ entry.text }}</span>
              <span *ngIf="entry.addNew">(Add new)</span>
            </mat-option>
            <!-- Show if from SmartyStreets -->
            <mat-option *ngIf="!addressesSearchInternallyOnly && entry.relevancy === 1" [value]="entry.text" [ngClass]="{'italic': entry.addNew}">
              <span>{{ entry.text }}</span>
              <span *ngIf="entry.addNew">(Add new)</span>
            </mat-option>
          </div>
          <!-- Show if searches only DB and has the same country ID -->
          <mat-option *ngIf="addressesSearchInternallyOnly && entry.country_id === addressForm.get('countrySearch_id').value || entry.addNew"
            [value]="entry.text" [ngClass]="{'italic': entry.addNew}">
            <span>{{ entry.text }}</span>
            <span *ngIf="entry.addNew">(Add new)</span>
          </mat-option>
        </div>

      </mat-autocomplete>
    </div>

  </div>


  <div fxLayout="row" *ngIf="!formMode" class="ca-address-container" [ngClass]="{'empty-state': (!address) || (address && !address.internal && !address.is_validated)}">

    <div class="ca-empty-state-container" *ngIf="!address && !verifying">
      <p class="card-message">
        {{messages.get('NO_ADDRESS_SELECTED')}}
      </p>
    </div>

    <div class="ca-empty-state-container" *ngIf="!address && verifying">
      <p class="card-message verifying">
        {{messages.get('VERIFYING')}}
      </p>
    </div>

    <div class="entry-list" *ngIf="address && (address.internal || address.is_validated)">
      <div class="entry-list-item">
        <p>
          <span style="display: block">{{address.address1}}</span>
          <span style="display: block">{{address.city}}, {{address.stateAbbreviation ? address.stateAbbreviation : address.subdivision?.abbreviation}}
            {{address.postal_code}}
          </span>
        </p>
      </div>
    </div>

    <div class="ca-empty-state-container" *ngIf="address && !address.internal && !address.is_validated">
      <p class="card-message">
        {{messages.get('NO_VERIFIED_ADDRESS')}}
      </p>
    </div>
  </div>
  <div class="ca-address-container" *ngIf="formMode">
    <!-- On small elements, we should set this to true so address and city are in the same line -->
    <!-- This displays when its false. Its false by default -->
    <ng-container *ngIf="!addressCityInlineFlex">
    <!-- Address container -->
    <div class="flex-container address-form-container" fxLayout="row" *ngIf="formMode">

      <div class="flex-item column" fxFlex>
        <mat-form-field caInput>
          <input matInput placeholder="Address" required formControlName="address1" type="text">
          <mat-hint
            *ngIf="addressForm.hasError('required', ['address1']) && addressForm.controls['address1'].touched"
            class="error-class">
            {{VALIDATION.REQUIRED_ERROR}}
          </mat-hint>
        </mat-form-field>
      </div>

    </div>

    <!-- City and state container -->
    <div class="flex-container address-form-container" fxLayoutAlign="flex-start flex-end" fxLayout="row" *ngIf="formMode">
      <div class="flex-item-multiple" fxFlex="45%">
        <mat-form-field caInput>
          <input matInput placeholder="City" required formControlName="city" type="text">
          <mat-hint *ngIf="addressForm.hasError('required', ['city']) && addressForm.controls['city'].touched"
                   class="error-class">
            {{VALIDATION.REQUIRED_ERROR}}
          </mat-hint>
        </mat-form-field>

      </div>

      <span class="example-fill-remaining-space"></span>
    </div>
    </ng-container>

    <!-- On small elements, we should set this to true so address and city are in the same line -->
    <!-- This displays when its true -->
    <ng-container *ngIf="addressCityInlineFlex">
        <!-- Address container -->
    <div class="flex-container address-form-container" fxLayout="row" *ngIf="formMode">

        <div class="flex-item colum caGap caGapRight" fxFlex>
          <mat-form-field caInput>
            <input matInput placeholder="Address" required formControlName="address1" type="text">
            <mat-hint caInput *ngIf="addressForm.controls['address1'].hasError('required') && addressForm.controls['address1'].touched"
              class="error-class">
              {{VALIDATION.REQUIRED_ERROR}}
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="flex-item-multiple caGap" fxFlex="45%">
            <mat-form-field caInput>
              <input matInput placeholder="City" required formControlName="city" type="text">
              <mat-hint *ngIf="addressForm.controls['city'].hasError('required') && addressForm.controls['city'].touched" class="error-class">
                {{VALIDATION.REQUIRED_ERROR}}
              </mat-hint>
            </mat-form-field>

          </div>

      </div>
      </ng-container>


    <!-- Postal code, country and save button container -->
    <div class="flex-container address-form-container" fxLayout="row" fxLayoutAlign="center" *ngIf="formMode">

      <div class="flex-item-multiple caGapRight" fxFlex="30%">
        <mat-form-field caInput>
          <input matInput formControlName="postal_code" type="text"
                 [placeholder]="postalCodePlaceholder"
                 [required]="zipPattern && zipPattern != '0'">
          <mat-hint
            *ngIf="addressForm.hasError('pattern', ['postal_code']) && addressForm.controls['postal_code'].touched && !addressForm.controls['postal_code'].hasError('required')"
            class="error-class">{{VALIDATION.ZIP_INVALID}}
          </mat-hint>
          <mat-hint class="error-class"
                   *ngIf="addressForm.hasError('required', ['postal_code']) && addressForm.controls['postal_code'].touched">
            {{VALIDATION.REQUIRED_ERROR}}
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="flex-item-multiple form-group ca-margin" fxFlex="35%" style="position: relative;">
        <ca-subdivision-picker
          #subdivisionPicker
          caInput
          [placeholder]="subdivisionPlaceholder"
          formControlName="subdivision_id"
          [pickerForm]="addressForm"
          [clearEnabled]="true"
          [countryCode]="countryCode"
          [required]="subdivisionPicker?.entries?.length > 0"
          class="ca-subdivision-picker"></ca-subdivision-picker>
        <mat-hint class="ca-select-hint"
                 *ngIf="addressForm.hasError('required', ['subdivision_id']) && addressForm.controls['subdivision_id'].touched">
          Field is required
        </mat-hint>
      </div>

      <span class="example-fill-remaining-space"></span>

      <div class="flex-item-multiple form-group ca-margin" style="align-self: center; margin-bottom: 12px;">
        <button mat-button mat-raised-button class="button" type="submit" (click)="onSubmit(addressForm)"
                [disabled]="addressForm.invalid">
          ADD
        </button>
      </div>
    </div>
    <!-- End of form -->

  </div>
</form>
