import {Injectable} from "@angular/core";
import { Observable } from "rxjs";
import {ThemeService} from "./theme.service";
@Injectable()
export class ThemeSchemaService {

  constructor(public themeService: ThemeService) {

  }

  public _schemes = [];

  themes() {
    return this._schemes;
  }

  customTheme(): Observable<any> {
    return this.themeService.customTheme();
  }

}
