import {Injectable} from '@angular/core';
import {FilterService} from "../../core/filter/filter.service";
import {userFilterContext} from "./user-filter.context";
import {UserSettingsService} from "./user-settings.service";

@Injectable()
export class UserFilterService extends FilterService {

  constructor(public userSettingsService: UserSettingsService) {
    super("app.appdata.user-filter", userFilterContext, userSettingsService);
  }

}
