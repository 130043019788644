<div fxLayout fxLayoutAlign="center center" *ngIf="!gridOnly && siteId">
  <div fxFlex>{{title}}</div>
  <div fxFlex="100px" style="text-align: right;" fxLayoutAlign="center center">
    <button mat-icon-button (click)="requestAdd()" tabindex="-1">
      <ca-icon [key]="'DEMARC_ADD'" [tooltip]="'Add Demarc'"></ca-icon>
    </button>

    <button mat-icon-button (click)="requestEdit(selection)"
            [disabled]="!selection"
            tabindex="-1">
      <ca-icon [key]="'UPDATE_DEMARC'" [tooltip]="'Update Selected Demarc'"></ca-icon>
    </button>

    <button mat-icon-button (click)="requestDelete(selection)"
            [disabled]="!selection"
            tabindex="-1">
      <ca-icon [key]="'DELETE_DEMARC'" [tooltip]="'Delete Selected Demarc'"></ca-icon>
    </button>
  </div>
</div>
<div class="demarc-grid-container" [ngClass]="{'margin-top-5': gridOnly }" *ngIf="siteId">
  <div class="absolute-position">
    <dx-data-grid class="demarc-grid" #grid [columns]="columns"
                  [width]="'100%'" [height]="'100%'"
                  [dataSource]="demarcs" (onSelectionChanged)="onSelectionChanged($event)">

      <div *dxTemplate="let d of 'demarcExtended'">
        <div caGridTooltip>{{ d.value ? 'Yes' : 'No' }}</div>
      </div>

    </dx-data-grid>
  </div>
</div>
<div *ngIf="!siteId" class="ca-empty-state-container" style="min-height: 200px">
  <p class="card-message">
    {{messages.get('DEMARC_NO_SITE')}}
  </p>
</div>