import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


export class TabGroup {
  public onActivate: EventEmitter<any>;
  activeTab$ = new BehaviorSubject(null);

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }

    this.onActivate = new EventEmitter();
  }

  tabs = [];
  activeKey: number;

  addTab(tab) {
    this.tabs.push(tab);
  }

  activate(key) {
    let tab = this.find(key);
    if (tab && !tab.disabled) {
      this.activeKey = key;
      this.onActivate.emit(tab);
      this.activeTab$.next(tab);
    }
  }

  isActive(key) {
    return key === this.activeKey;
  }

  find(key) {
    return this.tabs.filter((tab) => {
      return tab.key === key;
    })[0];
  }

  disable(tab: any, disabled: boolean) {
    if (tab) {
      tab.disabled = disabled;
    }
  }

  update(tab: any, data: any) {
    Object.assign(tab, data);
  }

  forceRerender() {
    const activeTabKey = this.activeKey;
    const activeTab = this.find(activeTabKey);
    const activeTabTitle = activeTab.title;

    setTimeout(() => {
      activeTab.title = `${activeTab.title} `;

      setTimeout(() => {
        activeTab.title = activeTabTitle;
      }, 350);
    }, 350);
  }

  deleteTab(tab) {
    const tabIndex = this.tabs.findIndex(e => e.key === tab.key);
    tabIndex >= 0 && this.tabs.splice(tabIndex, 1);
  }
}

@Injectable()
export class TabService {

  constructor() {
  }

  create(data?: any) {
    return new TabGroup(data);
  }

}
