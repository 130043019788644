import {Observable, throwError as observableThrowError} from 'rxjs';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {InvoiceFacepage} from './invoice-facepage';
import {Restangular} from 'ngx-restangular';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';


import {environment} from '../../../environments/environment';
import Query from '../../core/query/query';
import Auth from '../../shared/user-session/auth';
import {EntityLockService} from '../../shared/entity-lock/entity-lock.service';
import {LOOKUP_ENUM} from '../../dictionary/core/lookup.enum';
import {DialogService} from 'app/shared/dialog/dialog.service';
import {ManageInvoiceDialogComponent} from '../shared/invoice-manage-dialog/invoice-manage-dialog.component';
import {INVOICE_STATUS_ENUM} from './invoice-status.enum';
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';
import {AlertService} from 'app/shared/alert/alert.service';
import {ApiService} from "../../core/api";
import 'rxjs/add/operator/do';
import {map} from "rxjs/operators";
import * as io from 'socket.io-client';
import {ServiceLocator} from "../../core/locator.servce";


@Injectable()
export class InvoiceFacepageService extends BaseService<InvoiceFacepage> {
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'invoice';
  invoiceStatusEnum = INVOICE_STATUS_ENUM;

  @Output() invoiceChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() toolbarAction: EventEmitter<any> = new EventEmitter<{
    action: any,
    params: any
  }>();
  private url = environment.BASE_API_URL;
  private socket;

  constructor(public restangular: Restangular,
              private dialogService: DialogService,
              public http: HttpClient,
              private entityLock: EntityLockService,
              private apiService: ApiService,
              private alertService: AlertService
  ) {
    super('invoice', restangular, entityLock);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }


  update(id: number, item: any): any {
    return this.service().one(this.name, id).customPUT(item);
  }

  updateApprove(id: number, item: any): any {
    return this.service().one(`${this.name}/approve/`, id).customPUT(item);
  }

  updateStatus(id: number, item: any): any {
    return this.service().one(this.name, id).all('status').customPUT(item);
  }

  updateStatusApproved(id: number, item: any): any {
    return this.service().one(this.name, id).all('statusApproved').customPUT(item);
  }

  approveMultiple(ids: any): any {
    return this.service().all('invoice/approve/multiple').post({ids});
  }
  updateDocumentTypeStatus(id:number,item:any):any{
    return this.service().one(this.name, id).all('updateDocumentTypeStatus').customPUT(item);
  }

  findByIdForApprovedEdit(id: number, config?: any) {
    return this.service()
      .one(this.name, id)
      .one('editApproved', null)
      .get();
  }

  loadInvoiceNotesAndContacts(id: number) {
    return this.service().one(this.name, id.toString()).all('notesContacts').customGET();
  }

  codableAdjustments = ['adjad', 'adjbf'];

  findAllUncodedCharges(invoiceId: number) {
    let query = {
      scope: 'count',
      where: {
        invoice_id: invoiceId,
        chg_amt: {$ne: 0},
        $or: [{
          charge_coded: false,
          chg_class: {
            $notIn: this.codableAdjustments
          }
        }, {
          charge_coded: false,
          chg_class: {
            $in: this.codableAdjustments
          },
          include_in_amount_due_status: {
            $eq: LOOKUP_ENUM.UNSET_ADJUSTMENTS.INCLUDED
          }
        }]
      }
    };

    return this.apiService.get(["charge", "coded"], query);
    //return this.service().all('charge/countCodedCharges').customGET(null, {filter: query});
  }

  findAllInvoices(query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('all').customGET(null, {filter: transformedQuery});
  }

  checkInvoiceStatus(query) {
    return this.apiService.post([this.name, 'checkInvoiceStatus'], query);
  }

  isInvoiceOnHold(query?) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.apiService.post([this.name, 'isInvoiceOnHold'], transformedQuery);
  }

  getGLChgAmt(query?) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.apiService.post([this.name, 'getGLChgAmt'], transformedQuery);
  }

  findAllSimple(query) {
    return this.apiService.get([this.name, 'simple'], query);
  }

  facepagePdf(id: number): Observable<any> {
    const endPoint: string = [this.name, '/', id.toString(), '/facepagePdf'].join('');
    let cookie = Auth.getSession();

    try {
      return this.http.get(`${environment.API_URL}${endPoint}`, {
          responseType: 'blob', headers: {
            authorization: `Bearer ${cookie.token}`
          }, params: {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}
        }
      )
        .pipe(map(response => this.extractPdfBlob(response)))
    } catch(error) {
      this.handleError(error)
    }
  }

  public extractPdfBlob(data) {
    let blob = new Blob([data], {type: 'application/pdf'});
    return blob || {};
  }

  public handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      const body = error || '';
      const err = body['error'] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  findDistinctSubAccounts(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().one(this.name, 'subaccounts').all('distinct').customGET(null, this.toFilter(transformedQuery));
  }

  public downloadContainer(id: number) {
    let cookie = Auth.getSession();
    let params: HttpParams = new HttpParams();
    params.set('authorization', cookie.token);

    let endPoint: string = [environment.API_URL, this.name, '/', id.toString(), '/download'].join('');

    try {
      return this.http.get(endPoint, {
          responseType: 'blob',
          headers: {
            authorization: `Bearer ${cookie.token}`
          }
        }
      )
        .pipe(map(this.extractPdfBlob))
    } catch(error) {
      this.handleError(error)
    }
}


  public downloadSource(id: number) {
    let cookie = Auth.getSession();
    let params: HttpParams = new HttpParams();
    params.set('authorization', cookie.token);

    let endPoint: string = [environment.API_URL, this.name, '/', id.toString(), '/download/source'].join('');

    try {
      return this.http.get(endPoint, {
          responseType: 'blob',
          headers: {
            authorization: `Bearer ${cookie.token}`
          }
        }
      )
        .pipe(map(this.extractPdfBlob))
    } catch(error) {
      this.handleError(error)
    }
  }

  downloadSingularFileSourceName(id: number):any{
    let cookie = Auth.getSession();
    let params: HttpParams = new HttpParams();
    params.set('authorization', cookie.token);

    let endPoint: string = [environment.API_URL, this.name, '/', id.toString(), '/download/singularSource'].join('');
    try {
      return this.http.get(endPoint, {
          headers: {
            authorization: `Bearer ${cookie.token}`
          }
        }
      )
    } catch(error) {
      this.handleError(error)
    }
  }

  invoiceDatesDistinct(): any {
    return this.service().one(this.name, 'dates').get();
  }

  invoiceReceivedDatesDistinct(): any {
    return this.service().one(this.name, 'dates').get();
  }

  findChargesWithoutAdj(id: number): any {
    return this.service().one(this.name, id).all('chargesWithoutAdj').customGET();
  }

  processMultipleInvoices(invoice_ids: any, proceed_to_gl?) {
    proceed_to_gl = proceed_to_gl || false;
    return this.service()
      .all('gl_rules/processMultipleInvoices')
      .post({invoice_ids, proceed_to_gl});
  }

  getNonBalancedInvoices(query: any) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.apiService.post([this.name, 'getNonBalancedInvoices'], transformedQuery);
  }

  reload(id: number): any {
    return this.service().one(this.name, id).all('reload').customGET();
  }

  destroyMany(ids, note) {
    return this.service().all(this.name).remove({
      ids, note
    });
  }

  openConfirmationDialog({invoices, onDeleteConfirmed, actionText, title, config}) {
    invoices = Array.isArray(invoices) ? invoices : [invoices];
    return this.dialogService.open(ManageInvoiceDialogComponent, {
      invoices,
      actionText,
      title,
      onDeleteConfirmed,
      config
    }, {
      width: !config ? '850px' : '600px',

    });
  }

  deleteWithConfirmation(invoices: Array<InvoiceFacepage> | InvoiceFacepage) {
    invoices = Array.isArray(invoices) ? invoices : [invoices];
    return new Observable((subscriber) => {
      const dialogRef = this.openConfirmationDialog({
        invoices,
        actionText: 'delete',
        title: 'Delete invoices',
        config: null,
        onDeleteConfirmed: (input) => {
          this.destroyMany((invoices as Array<any>).map((invoice: any) => invoice.invoice_id), input.note).do(() => {
            this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
            dialogRef.close();
          })
            .subscribe(() => {
              subscriber.next();
              subscriber.complete();
            });
        }
      });
    });
  }

  isDeleteEligible(invoices: Array<InvoiceFacepage> | InvoiceFacepage) {
    if (invoices != null) {
      invoices = Array.isArray(invoices) ? invoices : [invoices];

      let res = invoices.length && invoices.every(
        invoice =>
          invoice && invoice.header && invoice.header.status_code && invoice.header.status_code < INVOICE_STATUS_ENUM.RFA);
      return res
    }

    return false;
  }

  invoicesByAuditId(query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('findAllInvoicesByAuditId').customGET(null, this.toFilter(transformedQuery));
  }

  invoicesForRateAudit(query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('findAllInvoicesForRateAudit').customGET(null, this.toFilter(transformedQuery));
  }

  findLatestInvoicesByAccount(accountId, accountNo) {
    let concreteQuery = new Query();
    concreteQuery.where.accountId = accountId;
    concreteQuery.where.accountNo = accountNo;
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('latest-by-account').customGET(null, this.toFilter(transformedQuery));
  }
  isDoNotProcessDisabled(invoice: InvoiceFacepage) {
    if (invoice && invoice.header && invoice.header.status_code) {
      return invoice.header.status_code > this.invoiceStatusEnum.APPROVED;
    } else {
      return true;
    }
  }

  fetchInvoiceStatuses(): any {
    return this.apiService.get('invoice/status');
  }

  onChangeInvoiceDocumentTypeRequested({invoice, params}) {
    let documentInvoiceTypeId;
    for (const property in LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE){
      if (property === params) {
        documentInvoiceTypeId = LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE[property]
      }
    }
    if (
      documentInvoiceTypeId === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.CREDIT_MEMO &&
      invoice.header.document_type_id === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.INVOICE
    ) {
      this.updateDocumentTypeStatus(invoice.invoice_id,{
        document_type_id:documentInvoiceTypeId
      })
        .subscribe(async (res) => {
          const invoice = await this.findById(res.invoice_id).toPromise()
          this.invoiceChange.emit(invoice)
        });
    } else if (
      documentInvoiceTypeId === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.INVOICE &&
      invoice.header.document_type_id === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.CREDIT_MEMO
    ) {
      this.updateDocumentTypeStatus(invoice.invoice_id,{
        document_type_id:documentInvoiceTypeId
      })
        .subscribe(async (res) => {
          const invoice = await this.findById(res.invoice_id).toPromise()
          this.invoiceChange.emit(invoice)
        });
    }
  }
  listen() {
    return new Observable(observer => {
      this.socket = io(this.url);
      this.socket.on('invoice_status', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }


  findAllAuditInvoice(query?: Query){
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('findAllAuditInvoices').customGET(null, this.toFilter(transformedQuery));
  }
}
