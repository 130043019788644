import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NewRuleBUilderService} from './dx-rule-builder.service';
import {FilterConverterHelper} from './filter-converter';

let inOperation = {
  name: "in",
  caption: "In",
  icon: "check",
  editorTemplate: "tagBoxTemplate",
  calculateFilterExpression(filterValue: any, field: any) {
    return [field.dataField, "in", filterValue]
  }
};

let notInOperation = {
  name: "notIn",
  caption: "Not In",
  icon: "check",
  editorTemplate: "tagBoxTemplate",
  calculateFilterExpression(filterValue: any, field: any) {
    return [field.dataField, "notIn", filterValue]
  }
};

@Component({
  selector: 'app-new-rule-builder',
  templateUrl: './dx-rule-builder.component.html',
  styleUrls: ['./dx-rule-builder.component.css']
})

export class NewRuleBuilderComponent {
  @Input() ruleTypeState;
  @Output() filterValueChanged = new EventEmitter();
  @Output() valueChanged = new EventEmitter();
  @Input() fields: Array<any>;
  @Input() filter: any;
  gridFilterValue: any;
  customOperations: Array<any>;
  groupOperations: string[] = ["and", "or"];

  charge: any;
  prevFilterModel: any;

  filterText;
  dataSourceText;
  myFormatText;

  constructor(private service: NewRuleBUilderService) {

  }

  ngOnInit() {
    this.fields = this.service.getFields();// fix issue with changeind category this.ruleTypeState['chargeTypeCategory']);
    if (!this.filter || !this.filter.length) {
      this.filter = this.service.getFilter();
    }
    this.customOperations = [inOperation, notInOperation];
    this.gridFilterValue = this.filter;
    // this.filterValueChanged.emit(FilterConverterHelper.dxFilterFormatToStandard(this.filter));
  }

  populateRuleForm = (value) => {
    this.charge = value.charge || null;
    if (value.clearForm) {
      this.filter = this.service.getFilter();
      this.filterValueChanged.emit(FilterConverterHelper.dxFilterFormatToStandard(this.filter));
    } else this.filter = FilterConverterHelper.standardFilterFormatToDx(value.data.value);
  }

  detectAndPopulateNewField = (e) => {
    if (!this.charge) return;
    if (!this.prevFilterModel) {
      this.prevFilterModel = [...e.component._model];
    }
    const prevModelStr = JSON.stringify(this.prevFilterModel);
    const modelStr = JSON.stringify(e.component._model);

    if (prevModelStr != modelStr) {
      this.prevFilterModel = [...e.component._model];
      this.filter = e.component._model = FilterConverterHelper.populateEmptyFields(e.component._model, this.charge);
    }
  }

  onContentReady(e) {
    this.detectAndPopulateNewField(e);
  }

  updateTexts(e) {
    this.valueChanged.emit(this.filter);
    const expression = e.component.getFilterExpression();
    if (expression) {
      const value = FilterConverterHelper.dxFilterFormatToStandard(expression);
      this.filterValueChanged.emit(value);

      this.filterText = NewRuleBuilderComponent.formatValue(e.component.option("value"));
      this.dataSourceText = NewRuleBuilderComponent.formatValue(e.component.getFilterExpression());
      this.myFormatText = NewRuleBuilderComponent.adaptFormatValue(e.component.getFilterExpression());
    }
  }

  // TODO make this functions to adapt JSON form for saving the rule
  private static adaptFormatValue(value) {
    const newValue = FilterConverterHelper.dxFilterFormatToStandard(value);

    return JSON.stringify(newValue);
  }

  private static formatValue(value, spaces = 0) {
    if (value && Array.isArray(value[0])) {
      var TAB_SIZE = 4;
      spaces = spaces || TAB_SIZE;
      return "[" + NewRuleBuilderComponent.getLineBreak(spaces) + value.map(function (item) {
        return Array.isArray(item[0]) ? NewRuleBuilderComponent.formatValue(item, spaces + TAB_SIZE) : JSON.stringify(item);
      }).join("," + NewRuleBuilderComponent.getLineBreak(spaces)) + NewRuleBuilderComponent.getLineBreak(spaces - TAB_SIZE) + "]";
    }
    return JSON.stringify(value);
  }

  private static getLineBreak(spaces) {
    return "\r\n" + new Array(spaces + 1).join(" ");
  }
}
