import {Observable} from 'rxjs';
import {IFilterLookupProvider} from '../../core/filter/filter-lookup.provider';
import Query from '../../core/query/query';
import {map} from "rxjs/operators";
import { VendorRemitAddressService } from './vendor-remit-address.service';

export class VendorRemitAddressLookupProvider extends IFilterLookupProvider {
  constructor(public vendorRemitAddressService: VendorRemitAddressService, public allVendors?: boolean) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    let query = new Query();
    if (typeof searchQuery === 'string') {
      query = new Query({where: {vendor_location_id: {$ilike: `%${searchQuery}%`}}});
    } else if (searchQuery) {
      query = new Query(searchQuery);
    }
    return this.getAll(query);
  }

  map(items: any) {
    return items.map((item) => ({key: item.id, value: item.vendor_location_id}))
  }

  private getAll(searchQuery?): Observable<any> {
    let query = searchQuery? searchQuery : new Query();
    query.orderBy = [['vendor_location_id', 'ASC']];

    return this.vendorRemitAddressService.findOnlyVendorRemit(query)
      .pipe(map((result: any) => {
          return {items: result.items.map(entry => {
              return entry.vendor_location_id;
            }), total: result.total};
        }));
      }
}
