import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class SvcOrderServiceGridService extends GridService {


  constructor(public userSettings: UserSettingsService) {
    super("app.contract.svc-order-service-grid", userSettings);
  }

  defaultColumns = [
    {
      caption: 'Line Number',
      dataField: 'line_number',
      width: 100
    },
    {
        dataField: 'description',
        caption: 'Description',
        width: 200
      },
      {
        dataField: 'technology.value',
        caption: 'Service Type Tech',
        width: 150
      },
      {
        dataField: 'inventory_ckt_type.value',
        caption: 'Svc Type',
        width: 120
      },
      {
        cellTemplate: "dateTimeTemplate",
        caption: "Rate Eff Date",
        dataField: "rate_eff_date",
        dataType: 'date',
        alignment: 'left',
        width: 120
      },
      {
        cellTemplate: "dateTimeTemplate",
        caption: "Rate Term Date",
        dataField: "rate_term_date",
        dataType: 'date',
        alignment: 'left',
        width: 120
      },
      {
        dataField: 'spid',
        caption: 'SPID',
        cellTemplate: 'linkTemplate',
        width: 120
      },
      {
        dataField: 'location_a.site_id',
        caption: 'Location A',
        width: 150
      },
      {
        dataField: 'location_z.site_id',
        caption: 'Location Z',
        width: 150
      },
      {
        dataField: 'int_bandwidth.value',
        caption: 'Int Bandwidth',
        width: 120
      },
      {
        dataField: 'ckt_bandwidth.value',
        caption: 'Svc Bandwidth',
        width: 120
      },
      {
        dataField: 'port_bandwidth.value',
        caption: 'Port Bandwidth',
        width: 120
      },
      {
        dataField: 'currency.currency',
        caption: 'Currency',
        width: 120
      },
      {
        dataField: 'term',
        caption: 'Term',
        width: 100
      },
      {
        dataField: 'unit_of_measure_term.value',
        caption: 'Term Qty UOM',
        width: 120
      },
      {
        dataField: 'service_qty',
        caption: 'Service Qty',
        width: 120
      },
      {
        dataField: 'unit_of_measure_service.value',
        caption: 'Service Qty UOM',
        width: 120
      },
      {
        dataField: 'mrc',
        caption: 'MRC',
        cellTemplate: 'currencyTemplate',
        dataType: 'currency',
        width: 120
      },
      {
        dataField: 'nrc',
        caption: 'NRC',
        cellTemplate: 'currencyTemplate',
        dataType: 'currency',
        width: 120
      },
    {
      dataField: 'at_exp_event.value',
      caption: 'At Expiration Event',
      width: 150
    },
    {
      dataField: 'cancel_notice_pd',
      caption: 'Cancel Notice (Days)',
      width: 150
    },
    {
      cellTemplate: "dateTimeTemplate",
      caption: "Cancel Notice Date",
      dataField: "cancel_notice_dt",
      dataType: 'date',
      alignment: 'left',
      width: 130
    },
      {
        dataField: 'service_details',
        caption: 'Service Details',
        cellTemplate: 'detailsTextCellTemplate',
        width: 400
      },
    ];

}
