import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HistoryService } from './history.service';
import { OrderService } from '../../order/core/order.service';
import { Router } from '@angular/router';
import { DialogService } from '../dialog/dialog.service';
import { HistoryCompareDialogComponent } from './history-compare-dialog/history-compare-dialog.component';
import { UserModalComponent } from '../user-modal/user-modal.component';
import {InvoiceFacepage} from "../../invoice/core/invoice-facepage";
import {Order} from "../../order/core/order";
import { INVOICE_STATUS_ENUM } from '../../invoice/core/invoice-status.enum';

@Component({
  selector: 'ca-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit, OnChanges {

  @Input() entityId: number;
  @Input() entityType: string;
  @Input() invoice: InvoiceFacepage;
  @Input() order: Order;
  public history;


  invoiceStatusEnum = INVOICE_STATUS_ENUM;

  constructor(
    public historyService: HistoryService,
    public orderService: OrderService,
    public router: Router,
    public dialog: DialogService
  ) { }

  ngOnInit() { }

  public navigateToDispute(disputeId: number) {
    this.router.navigate(['dispute', disputeId, 'show']);
  }

  public navigateToInvoice(invoiceId: number) {
    this.router.navigate(['invoice', invoiceId, 'show']);
  }

  public formatOrderId(orderId: number, entry?) {
    if (this.order) {
      return this.order.full_order_id;
    } else {
      let idString = orderId.toString();
      while (idString.length < 7) {
        idString = '0' + idString;
      }
      return idString;
    }
  }

  public showUserModal(user) {
    this.dialog.open(UserModalComponent, { user: user }, { width: '400px' });
  }

  public showHistoryCompare(entry) {
    if(entry.entity_type == 'order' && entry.action_key == 'create'){
      this.orderService.findById(entry.entity_id)
        .subscribe(result => {
          entry.full_order_id = result.full_order_id

          if (this.history) {
            this.dialog.open(HistoryCompareDialogComponent, {
              historyItem: entry,
              historyAll: this.history.filter(item => item.entity_type === entry.entity_type),
              invoice: {...this.invoice}
            }, { width: '60%' });
          }
        })
    } else {
      if (this.history) {
        this.dialog.open(HistoryCompareDialogComponent, {
          historyItem: entry,
          historyAll: this.history.filter(item => item.entity_type === entry.entity_type),
          invoice: {...this.invoice}
        }, { width: '60%' });
      }
    }
  }

  refreshList() {
    setTimeout(() => {
      this.historyService.findAllForEntity(this.entityType, this.entityId)
        .subscribe((history: any) => {
          this.history = history;
        });
    }, 500);

  }

  ngOnChanges(changes: SimpleChanges): void {
    let entityId = changes['entityId'];

    if (entityId && entityId.currentValue) {
      this.historyService.findAllForEntity(this.entityType, entityId.currentValue)
        .subscribe((history: any) => {
          this.history = history;
        });
    }
  }

  getUserInitials(user): string {
    if (user) {
      return user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0];
    }
  }

  /**
   * This method will decide if invoice specific status is reverted or updated.
   * Decision is done by comparing the status code values of current and previous history record.
   * */
   getStatusUpdateAction(entry, entity_type) {
    const entryIndex = this.history.indexOf(entry);
    const historyLength = this.history.length;
    let toCompare = null;

    if (entryIndex < historyLength - 1) {
      for (let i = entryIndex + 1; i < historyLength; i++) {
        if (this.history[i].entity_id === entry.entity_id && this.history[i].entity_type === entity_type) {
          toCompare = this.history[i];
          break;
        }
      }
    }

    if (toCompare) {
      if (entry.meta_data && toCompare.meta_data && entry.meta_data.status_code && toCompare.meta_data.status_code) {
        if (entry.meta_data.status_code.value < toCompare.meta_data.status_code.value) {
          return 'reverted';
        }
      }
    }

    return 'updated';
  }

  getInventoryStatus(entity){
      switch(entity.meta_data.status_id.value){
        case 10:
          return "New";
        case 20:
          return "Active";
        case 30:
          return "In Process";
        case 40:
          return "Disconnected";
        case 50:
          return "Pending Disconnect";
      }
  }

  getDisputeChargeStatus(entity) {
    switch(entity.meta_data.status_id.value){
      case 10:
        return "Filed";
      case 20:
        return "Closed - Lost";
      case 30:
        return "In Progress";
      case 40:
        return "Closed - Won";
    }
  }

  getDisputeStatusLabel(key) {
    switch(key){
      case 'withheld_status_change':
        return 'dispute withholding status';
      case 'status_change':
        return 'dispute status';
      case 'charge_status_change':
        return 'status';
      case 'payment_withheld_status_change':
        return 'Payment Withheld';
      case '':
        return '';
    }
  }

  /**
   * This method will decide if status equal to older .
   * Decision is done by comparing the status code values of current and previous history record.
   * */
  isStatusEqual(entry, entity_type) {
    const entryIndex = this.history.indexOf(entry);
    const historyLength = this.history.length;
    let toCompare = null;

    if (entryIndex < historyLength - 1) {
      for (let i = entryIndex + 1; i < historyLength; i++) {
        if (this.history[i].entity_id === entry.entity_id && this.history[i].entity_type === entity_type) {
          toCompare = this.history[i];
          break;
        }
      }
    }

    if (toCompare) {
      if (entry.meta_data && toCompare.meta_data && entry.meta_data.status_code && toCompare.meta_data.status_code) {
        if (entry.meta_data.status_code.value === toCompare.meta_data.status_code.value) {
          return true
        }
      }
    }
    return false;
  }
}
