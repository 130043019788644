export const HISTORY_MESSAGE_ENUM = {
  CREATE: {
    message: "created"
  },
  DELETE: {
    message: "deleted"
  },
  UPDATE: {
    message: "updated"
  },
  AUTO_APPROVER_ENABLED: {
    message: "enabled auto approver on"
  },
  AUTO_APPROVER_DISABLED: {
    message: "disabled auto approver on"
  },
  AUTO_APPROVER_UPDATE: {
    message: "updated auto approver "
  },
  STATUS_UPDATE: {
    message: "updated "
  },
  SERVICE_DELETED: {
    message: "delete "
  }
};
