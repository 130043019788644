import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class InventoryPricingGridService extends GridService {

  defaultColumns = [
    {
      caption: 'File Name',
      dataField: 'inventory_document.path',
      width: 250
    },
    {
      caption: 'Description',
      dataField: 'inventory_document.description',
      width: 250
    },
    {
      caption: 'Contract Type',
      dataField: 'inventory_document.contract.value',
      width: 250
    },
    {
      caption: 'Effective Date',
      dataField: 'inventory_document.effective_date',
      width: 120,
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'right',
    },
    {
      caption: 'Term (Months)',
      dataField: 'inventory_document.term',
      width: 150
    },
    {
      caption: 'Expiration Date',
      dataField: 'inventory_document.exp_date',
      width: 120,
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'right',
    },
    {
      caption: 'MRC',
      dataField: 'inventory_document.mrc',
      width: 100,
      dataType: 'currency',
      alignment: 'right',
      cellTemplate: 'currencyTemplate',
    },
    {
      caption: 'NRC',
      dataField: 'inventory_document.nrc',
      width: 100,
      dataType: 'currency',
      alignment: 'right',
      cellTemplate: 'currencyTemplate',
    }, {
      caption: 'Currency',
      dataField: 'inventory.currency_obj.currency',
      visible: false,
      width: 120
    }
  ]

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.inventory.inventory-pricing-grid", userSettings);
  }

  // columns() {
  //   return [
  //     {
  //       caption: 'File Name',
  //       dataField: 'path',
  //       width: 250
  //     },
  //     {
  //       caption: 'Description',
  //       dataField: 'description',
  //       width: 250
  //     },
  //     {
  //       caption: 'Contract Type',
  //       dataField: 'contract.value',
  //       width: 250
  //     },
  //     {
  //       caption: 'Effective Date',
  //       dataField: 'effective_date',
  //       width: 120,
  //       dataType: 'date',
  //       cellTemplate: 'dateTemplate',
  //       alignment: 'right',
  //     },
  //     {
  //       caption: 'Term (Months)',
  //       dataField: 'term',
  //       width: 150
  //     },
  //     {
  //       caption: 'Expiration Date',
  //       dataField: 'exp_date',
  //       width: 120,
  //       dataType: 'date',
  //       cellTemplate: 'dateTemplate',
  //       alignment: 'right',
  //     },
  //     {
  //       caption: 'MRC',
  //       dataField: 'mrc',
  //       width: 100,
  //       dataType: 'currency',
  //       alignment: 'right',
  //       cellTemplate: 'currencyTemplate',
  //     },
  //     {
  //       caption: 'NRC',
  //       dataField: 'nrc',
  //       width: 100,
  //       dataType: 'currency',
  //       alignment: 'right',
  //       cellTemplate: 'currencyTemplate',
  //     },
  //   ];
  // }

}
