import {of as observableOf} from 'rxjs';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageListComponent} from '../../core/page-list.component';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {PageContext} from '../../core/page.context';
import {ChargeQuery} from '../../charge/core/charge.query';
import {CommonAlertService} from '../core/common-alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

import {AlertChargesGridService} from '../core/alert-charges-grid.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {GridSettingsComponent} from '../../core/grid/grid-settings.component';
import {AlertService} from '../../shared/alert/alert.service';
import {DisputeService} from '../../dispute/core/dispute.service';
import {DisputeManageDialogComponent} from '../../dispute/shared/dispute-manage-dialog/dispute-manage-dialog.component';
import {InvoiceFacepageService} from '../../invoice/core/invoice-facepage.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {IMessagesResourceService, ResourcesService} from '../../core/resources/resources.service';
import {TabGroup, TabService} from '../../shared/tabs/tab.service';
import {AlertChargesFilterService} from '../core/alert-charges-filter.service';
import {GridService} from '../../shared/grid/grid.service';
import {flatMap} from "rxjs/internal/operators";

@Component({
  selector: 'app-alert-charges',
  templateUrl: './alert-charges.component.html',
  styleUrls: ['./alert-charges.component.css']
})

export class AlertChargesComponent extends PageListComponent implements OnInit, AfterViewInit, OnDestroy {
  alert;
  public summary: any;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTab: number = 0;
  public query: ChargeQuery = new ChargeQuery({orderBy: [['id', 'ASC']]});
  public charges: Array<any> = [];
  public columns: any;
  public selectedCharges = [];
  public selection;
  public addDisputeDisabled: boolean = true;
  public delayChargesSelectionChange: boolean = false;
  public chargesSelectionActive: boolean = false;
  public sorting: any[][];
  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @ViewChild('panelSide') panelSide;

  readonly sideSections = {
    none: null,
    info: 'details',
    filter: 'filter',
  };

  _isSidePanelOpen = false;
  activeTabIndex: number = 0;
  isGridSettingsDisabled = true;

  public alertId: number;

  readonly PLACEHOLDERS = {
    OWNER_UNASSIGNED: 'Unassigned'
  }

  // TODO: remove after confirmed as obsolete
  /*THRESHOLD_COLORS = {
    color_10: '#f33a0a',
    color_20: '#ac2b09',
    color_30: '#621804',
    color_40: '#d810a9',
    color_50: '#66044f',
    color_60: '#3e2edb',
    color_70: '#171062',
    color_80: '#2df751',
    color_90: '#127924',
    color_100: '#5e5819'
  };

  getThresholdColor() {
    if (this.summary && this.summary['threshold_value'].toString()) {
      let color = this.THRESHOLD_COLORS['color_' + this.summary['threshold_value'].toString()];
      return color ? color : '#f33a0a';
    } else {
      return '#f33a0a';
    }
  }*/


  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'common-alert';

  constructor(public settingsService: UserSettingsService,
              public route: ActivatedRoute,
              public alertService: CommonAlertService,
              public alertChargeGridService: AlertChargesGridService,
              public router: Router,
              public dialogService: DialogService,
              public toastAlertService: AlertService,
              public disputeService: DisputeService,
              public loaderService: LoaderService,
              public alertChargesFilterService: AlertChargesFilterService,
              public invoiceFacepageService: InvoiceFacepageService,
              public tabService: TabService) {
    super(new PageContext({
      name: 'app.alert.alert-charges',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    if (tab.key === 2 && this.sider.getActiveSection().name === this.sideSections.filter) {
      this._isSidePanelOpen = true;

      observableOf(true)
        .subscribe(
          () => {
            this._isSidePanelOpen ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME) : this.sider.closeComponent();

            this.tabGroup.forceRerender();
          }
        );
    }

    if (tab.key !== 2 && this.sider.getActiveSection().name === this.sideSections.filter) {
      this._isSidePanelOpen = false;

      observableOf(true)
        .subscribe(
          () => {
            this._isSidePanelOpen ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME) : this.sider.closeComponent();

            this.tabGroup.forceRerender();
          }
        );
    }

    if (tab.key !== 2 && this.sider.getActiveSection().name === this.sideSections.info) {
      this._isSidePanelOpen = false;

      observableOf(true)
        .subscribe(
          () => {
            this._isSidePanelOpen ? this.sider.open(this.SECTIONS.DETAILS_SECTION_NAME) : this.sider.closeComponent();

            this.tabGroup.forceRerender();
          }
        );
    }

    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }

    /* Settings grid disabled */
    if (this.tabGroup.isActive(2) || this.tabGroup.isActive(3) || this.tabGroup.isActive(4)) {
      this.isGridSettingsDisabled = false;
    } else {
      this.isGridSettingsDisabled = true;
    }
  }

  public loadTabs() {

    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({key: 1, title: 'Summary'});
    this.tabGroup.addTab({key: 2, title: 'Details'});

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe((tab) => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }


  loadData(alertId: number) {
    this.alertService.findById(this.route.params['_value'].id)
      .subscribe(
        (alert) => {
          this.alert = alert;
          this.loadCharges(this.query);
        },
        () => {
          console.log('Error fetching alert info');
        }
      );
  }

  private getCharges(alertId, query) {
    return this.alertService.findCharges(alertId, query)
    // .map((result) => {
    //   return {
    //     ...result,
    //     items: result.items.map(charge => Object.assign(charge, {
    //       message: pipe(
    //         find(whereEq({
    //           charge_id: charge.id
    //         })),
    //         pathOr('', ['message'])
    //       )(this.alert.charge_details)
    //     })),
    //   };
    // });
  }

  // -----------------------------------------------------------
  loadCharges(query: ChargeQuery) {
    this.delayChargesSelectionChange = true;
    this.charges = [];

    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);
    this.getCharges(this.alertId, query)
      .subscribe((results) => {
        this.charges = results.items;
        query.total = results.total;

        if (this.charges.length) {
          setTimeout(() => {
            this.delayChargesSelectionChange = false;
            this.dataGrid.instance.selectRows(this.selectedCharges, false);
          }, 500);
        }

        setTimeout(() => {
          this.dataGrid.instance.repaint();
          this.dataGrid.instance.refresh();
        }, 600);

        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      }, () => {
        this.toastAlertService.error('', this.messages.get('ERROR_LOADING_CHARGES'));
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      });
  }

  /** Selection badge active */
  loadSelectedCharges() {
    if (this.chargesSelectionActive) {
      this.query.remove('id');
    } else {
      this.query['where'] = {};
      this.query.set('id', {
        $in: this.selectedCharges.map((x) => {
          return x.id;
        })
      });
    }

    this.query.offset = 0;
    this.query.page = 1;

    this.chargesSelectionActive = !this.chargesSelectionActive;
    this.loadCharges(this.query);

    if (this.chargesSelectionActive) {
      this.panelSide.close();
    }
  }

  findIndexInSelectedCharges(chargeId): number {
    for (var i = 0, l = this.selectedCharges.length; i < l; i++) {
      if (this.selectedCharges[i].id === chargeId) {
        return i;
      }
    }
    return -1;
  }

  onSelectionChanged(event) {

    if (!this.delayChargesSelectionChange && this.charges.length) {

      //** Deselected rows */
      if (event.currentDeselectedRowKeys.length) {
        event.currentDeselectedRowKeys.forEach((item) => {
          let index = this.findIndexInSelectedCharges(item.id);
          if (index > -1) {
            this.selectedCharges.splice(index, 1);
          }
        });
      }

      //** Selected rows */
      if (event.currentSelectedRowKeys.length) {
        event.currentSelectedRowKeys.forEach((item) => {
          let index = this.findIndexInSelectedCharges(item.id);
          if (index === -1) {
            this.selectedCharges.push(item);
          }
        });
      }

      /* Dispute Add enabled/disabled */
      if (this.selectedCharges.length) {
        this.addDisputeDisabled = false;
        this.selectedCharges.forEach((charge) => {
          if (charge.charge_dispute) {
            this.addDisputeDisabled = true;
          }
        });
      } else {
        this.addDisputeDisabled = true;
      }


      if (this.selectedCharges.length > 1) {
        //
      } else {
        this.selection = event.selectedRowsData[0];
      }

      /** grid  showing only selected charges and no more selected charges */
      if (this.chargesSelectionActive && !this.selectedCharges.length) {
        this.loadSelectedCharges();
      }

    }
  }

  showDispute(id: any) {
    this.router.navigate(['/dispute', id, 'show']);
  }

  refresh() {
    this.loadCharges(this.query);
  }

  onPageChange(query: ChargeQuery) {
    this.loadCharges(query);
  }

  filterData(query: ChargeQuery) {
    this.query = query;
    this.loadCharges(query);
  }

  clearFilter(query: ChargeQuery) {
    this.query = query;
    this.loadCharges(query);
  }


  addDispute() {
    if (!this.selectedCharges.length || this.addDisputeDisabled) {
      return;
    }

    if (this.disputeService.validateSelectionForDispute(this.selectedCharges)) {

      this.invoiceFacepageService.findById(this.selectedCharges[0].invoice_id)
        .pipe(flatMap(
          (invoice) => {

            return this.dialogService.open(DisputeManageDialogComponent, {
              charges: this.selectedCharges,
              invoice: invoice
            }, {width: '60%'})
              .afterClosed();

          }))
        .subscribe((result) => {
          if (result) {
            if (result) {

              // display disputes icon on included charges
              this.selectedCharges.forEach((charge) => {
                charge.charge_dispute = {dispute_id: result.id};
              });
              // clear selection
              this.selectedCharges = [];
              this.dataGrid.instance.refresh();

              this.toastAlertService.success('', this.messages.get('DISPUTE_CREATE_SUCCESS'));
            }
          }
        });

    } else {
      this.toastAlertService.error('', this.messages.get('INVALID_CHARGES_SELECTION_FOR_DISPUTE'));

    }
  }

  // -------------------------------------------------------------


  ngOnInit() {
    this.loadTabs();
    this._init();
  }

  gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.alertChargeGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.alertChargeGridService;
  }

  getDefaultSort(): any {
    return [['id', 'ASC']];
  }

  _init() {
    super.prepareList();
    // this.alert = this.route.data['alert'];
    this.alert = this.route.snapshot.data.alert;
    this.alertId = this.alert.id;
    this.loadCharges(this.query);
  }

  ngAfterViewInit(): void {
    this.alertChargeGridService.create(this.dataGrid.instance, {
      selection: {
        mode: 'multiple',
        selectAllMode: 'page'
      },
      noDataText: this.alertChargeGridService.noDataMessage
    });

    super.ngAfterViewInit();
  }

  sortColumn(sorting) {
    this.query['orderBy'] = sorting;

    this.loadCharges(this.query);
  };

  back(): void {
    if (document.referrer.indexOf(window.location.host) >= 0) {
      history.back();
    } else {
      this.router.navigate(['/alert']);
    }
  }

  csv() {
    const {alertChargeGridService, alertService, query, alertId} = this;
    alertChargeGridService.csvMap().subscribe(fields => {
      alertService.exportToCSV(
        `alert-charges`,
        {
          query: query,
          fields,
          fetchDataHandler: (csvQuery) => {
            return this.getCharges(alertId, csvQuery);
          },
        }
      );
    });
  }

  selectFirstRow(data?) {
    // just to override base component behaviour
  }

  calculateChargeAmount(charges) {
    /*const sum = charges.reduce((accumulator, item) => {
      return accumulator += +item.chg_amt;
    }, 0);

    return sum;*/
    return 0;
  }

  calculateCalculatedCharge(charges) {
    /*const sum = charges.reduce((accumulator, item) => {
      return accumulator += +item.calc_charge;
    }, 0);

    return sum;*/
    return 0;
  }

  calculateVariance(charges) {
    /* const sum = charges.reduce((accumulator, item) => {
       return accumulator += (+item.chg_amt) - (+item.calc_charge);
     }, 0);

     return sum;*/
    return 0;
  }

  toggleFilter() {
    this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
    this.tabGroup.forceRerender();
  }

  ngOnDestroy() {
    if (this.sider.getActiveSection().name === this.sideSections.filter) {
      this.toggleFilter()
    }
    this.tabGroup.onActivate.unsubscribe();
  }

  public goToInvoiceDetails() {
    if (this.alert && this.alert.invoice && this.alert.invoice.invoice_id) {
      this.router.navigate(['/invoice', this.alert.invoice.invoice_id, 'show', 'overview']);
    }
  }

}
