import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {PageManageDialogComponent} from "../../../core/page-manage-dialog.component";
import {ShortInvoiceChargeGridService} from '../../core/short-invoice-charges-grid.service';
import {DialogButtonsController} from "../../../core/dialog-buttons-controller.class";
import {MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {InvoiceChargeService} from "../../../charge/core/invoice-charge.service";
import {RandomService} from "../../../shared/random/random.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {DxDataGridComponent} from "devextreme-angular";


@Component({
  selector: 'app-invoice-delete-charge-dialog',
  templateUrl: './invoice-delete-charge-dialog.component.html',
})
export class InvoiceDeleteChargeDialogComponent extends PageManageDialogComponent implements OnInit, AfterViewInit {

  public title: string = "Test";
  public columns: Array<any>;
  public charges: Array<any>;
  public form: FormGroup;
  public actionText: string = 'delete';
  public randomString: string = this.randomService.getRandomCheckString();
  public deletePlaceholder: string = 'Type in generated string shown below';
  readonly MESSAGES_MODULE: string = "charge";
  private messages: IMessagesResourceService;
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  constructor(
    public shortInvoiceChargeGridService: ShortInvoiceChargeGridService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<InvoiceDeleteChargeDialogComponent>,
    public formBuilder: FormBuilder,
    public invoiceChargeService: InvoiceChargeService,
    public randomService: RandomService,
    public alert: AlertService,

  ) {
    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

  }

  ngOnInit(): void {
    this.columns = this.shortInvoiceChargeGridService.columns();
    this.form = this.formBuilder.group({
      note: new FormControl('', Validators.required),
    })

  }
  ngAfterViewInit(): void {
    this.setDialogContent();
  }

  close() {
    this.dialogRef.close();
  }

  delete() {
    this.toggleDialogButtons();
    const chargeIds = this.charges.map(charge => charge.id);
    let payload = {...this.form.value, chargeIds};
    this.invoiceChargeService.deleteCharges(payload).subscribe(data => {
      if (data) {
        this.toggleDialogButtons(false);
        this.dialogRef.close({deleted: true});
      }
    }, error => {
        this.toggleDialogButtons(false);
        this.alert.error("", this.messages.get("DELETE_ERROR"));
    });
  }

  setDialogContent() {
      this.shortInvoiceChargeGridService.create(this.dataGrid.instance, {
        noDataText: 'No data'
      });
  }
}
