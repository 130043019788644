<ng-container *ngIf="building">
  <mat-toolbar color="accent" mat-scroll-shrink>

    <span class="sneak-peek-title">{{ building?.name }}</span>

    <span class="example-fill-remaining-space"></span>

    <button mat-icon-button (click)="closeSide()">
      <ca-icon [key]="'CLOSE'"></ca-icon>
    </button>
  </mat-toolbar>
  <div class="ca-card ca-card-sneak-peek">
    <mat-card caCard="">
      <mat-card-content>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Name</label>
            <p>
              {{ building?.name || 'N/A'}}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Address</label>
            <div>
              <p *ngIf="building?.address?.address1">
                {{ building?.address?.address1}}
              </p>
              <p>
                {{ building?.address?.city}}&nbsp;{{ building?.address?.subdivision?.abbreviation}}&nbsp;{{ building?.address?.postal_code}}
              </p>
              <p *ngIf="building?.address?.country">
                {{ building?.address?.country?.name}}
              </p>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="!building">
  <div class="ca-empty-state-container">
    <p class="card-message">
      No building selected
    </p>
  </div>
</ng-container>