<ng-container *ngIf="quote">
  <mat-toolbar color="accent" mat-scroll-shrink>
    <a [routerLink]="['/quotes', id, 'show']" class="sneak-peek-title">{{
      quote?.quote_id || "N/A"
    }}</a>

    <span class="example-fill-remaining-space"></span>

    <button mat-icon-button (click)="closeSide()">
      <ca-icon [key]="'CLOSE'"></ca-icon>
    </button>
  </mat-toolbar>
  <div class="ca-card ca-card-sneak-peek">
    <mat-card caCard>
      <mat-card-content>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Requester</label>
            <p>
              {{ quote?.requester?.full_name || "N/A" }}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Customer</label>
            <p>
              {{ quote?.customer?.company || "N/A" }}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Status</label>
            <p>
              {{ quote?.quote_status?.value || "N/A" }}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Request Date</label>
            <p>
              {{ (quote?.request_date | date) || "N/A" }}
            </p>
          </div>
        </div>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Description</label>
            <p>
              {{ quote?.quote_description || "N/A" }}
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div
    *ngIf="!serviceLevel && quoteServices?.length"
    class="ca-card ca-card-sneak-peek"
  >
    <mat-card caCard>
      <mat-card-content>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Quote Service</label>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Status</label>
          </div>
        </div>
        <div
          fxLayout="row"
          class="entry-list"
          *ngFor="let service of quoteServices"
        >
          <div fxFlex class="entry-list-item quote-list-item">
            <p>
              {{ service?.quote_service_id || "N/A" }}
            </p>
          </div>
          <div fxFlex class="entry-list-item quote-list-item">
            <p>
              {{ service?.quote_service_status?.value || "Open" }}
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="!quote">
  <div class="ca-empty-state-container">
    <p class="card-message">
      No quote selected
    </p>
  </div>
</ng-container>

<ng-content></ng-content>
