import { Injectable } from "@angular/core";
import { AccountLookupProvider } from "../../account/core/account-lookup.provider";
import { AccountService } from "../../account/core/account.service";
import { ChargeTypeLookupProvider } from "../../charge/core/charge-type-lookup.provider";
import { FacilityBandwidthLookupProvider } from "../../charge/core/facility-bandwidth-lookup.provider";
import { InvoiceChargeService } from "../../charge/core/invoice-charge.service";
import { FilterService } from "../../core/filter/filter.service";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { InvoiceFacepageService } from "../../invoice/core/invoice-facepage.service";
import { SubAccountLookupProvider } from "../../invoice/core/sub-account-lookup.provider";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { VendorLookupProvider } from "../../vendor/core/vendor-lookup.provider";
import { VendorService } from "../../vendor/core/vendor.service";
import { inventoryBillingFilterContext } from "./inventory-billing-filter.conext";
import { DefaultDateRangesLookupProvider } from "app/core/filter/default-date-ranges-lookup.provider";

@Injectable()
export class InventoryBillingFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];
  
  constructor(public userSettingsService: UserSettingsService,
              public dictionaryService: DictionaryService,
              public invoiceService: InvoiceFacepageService,
              public chargeService: InvoiceChargeService,
              public vendorService: VendorService,
              public accountService: AccountService) {
    super("app.inventory.billing-filter", inventoryBillingFilterContext, userSettingsService);

    this.addLookupProvider('chargeType', new ChargeTypeLookupProvider(dictionaryService));
    this.addLookupProvider('subAccount', new SubAccountLookupProvider(invoiceService));
    this.addLookupProvider('facilityBandwidth', new FacilityBandwidthLookupProvider(chargeService));
    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('account', new AccountLookupProvider(accountService));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
