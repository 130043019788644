<ng-container *ngIf="site">
  <mat-toolbar color="accent" mat-scroll-shrink>

    <span class="sneak-peek-title">{{ site?.site_id }}</span>

    <span class="example-fill-remaining-space"></span>

    <button mat-icon-button (click)="closeSide()">
      <ca-icon [key]="'CLOSE'"></ca-icon>
    </button>
  </mat-toolbar>

  <div class="ca-card ca-card-sneak-peek">
    <mat-card caCard>
      <mat-card-content>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Site Name</label>
            <p>
              {{ site?.site_id || 'N/A'}}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Vendor</label>
            <p>
              {{ site?.vendor?.name || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Type</label>
            <p>
              {{ site?.type?.value || 'N/A'}}
            </p>
          </div>

        </div>
        <div fxLayout="row" class="entry-list" *ngIf="site?.building">
          <div fxFlex class="entry-list-item">
            <label>Building</label>
            <div>
              <p>
                {{ site?.building?.name}}
              </p>
              <p *ngIf="site?.building?.address?.address1">
                {{ site?.building?.address?.address1}}
              </p>
              <p>
                {{ site?.building?.address?.city}},&nbsp; {{ site?.building?.address?.subdivision?.name}}&nbsp; {{
              site?.building?.address?.postal_code}}
              </p>
              <p *ngIf="site?.building?.address?.country">
                {{ site?.building?.address?.country?.name}}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Floor</label>
            <p>
              {{ site?.floor || 'N/A' }}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Room</label>
            <p>{{ site?.room || 'N/A' }}</p>
          </div>
        </div>

      </mat-card-content>
    </mat-card>
  </div>

  <div class="ca-card ca-card-sneak-peek" *ngFor="let demarc of allSiteDemarcs">
    <mat-card>
      <mat-card-header>
        Demarc
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Rack</label>
            <p>
              {{ demarc?.rack_id || 'N/A' }}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Shelf</label>
            <p>{{ demarc?.shelf_id || 'N/A' }}</p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>CPE</label>
            <p>{{ demarc?.cpe_id || 'N/A' }}</p>
          </div>
        </div>
        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Card</label>
            <p>
              {{ demarc?.card_id || 'N/A' }}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Port</label>
            <p>{{ demarc?.port_id || 'N/A' }}</p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>CPE</label>
            <p>{{ demarc?.extended ? 'Yes' : 'No' || 'N/A' }}</p>
          </div>
        </div>
        <div fxLayout="row" class="entry-list" *ngIf="demarc?.notes">
          <div fxFlex class="entry-list-item">
            <label>Notes</label>
            <p>{{ demarc?.notes }}</p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="!site">
  <div class="ca-empty-state-container">
    <p class="card-message">
      No site selected
    </p>
  </div>
</ng-container>
