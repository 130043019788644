
import {mergeMap} from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import { DataLockService } from '../../core/data-lock/data-lock.service';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { LOOKUP_MODELS_ENUM } from '../../dictionary/core/lookup-models.enum';
import { LOOKUP_ENUM } from '../../dictionary/core/lookup.enum';
import { LookupModel } from '../../dictionary/core/lookup.model';
import { INVENTORY_STATUS_ENUM } from '../../inventory/core/inventory-status.enum';
import { InventoryService } from '../../inventory/core/inventory.service';
import { OrderFlowService } from '../../order/core/order-flow.service';
import { ORDER_STATUS } from '../../order/core/order-status.enum';
import { ORDER_TYPE } from '../../order/core/order-type.enum';
import { OrderService as OrderSvc } from '../../order/core/order.service';
import { AlertService } from '../../shared/alert/alert.service';
import { ConfirmDialogComponent } from '../../shared/dialog/confirm-dialog/confirm-dialog.component';
import { DialogService } from '../../shared/dialog/dialog.service';
import { Flow } from '../../shared/flow/flow';
import { FlowStep } from '../../shared/flow/flow-step';
import { FlowService } from '../../shared/flow/flow.service';
import { Notes } from '../../shared/notes/core/notes';
import { NotesService } from '../../shared/notes/core/notes.service';
import { FocReceiveComponent } from '../flow/foc-receive/foc-receive.component';
import { ServiceInstallComponent } from '../flow/service-install/service-install.component';
import { ServiceTestComponent } from '../flow/service-test/service-test.component';
import { OrderService } from './order-service';
import { ORDER_SERVICE_STATUS } from './order-service-status.enum';
import { OrderServiceService } from './order-service.service';

@Injectable()
export class OrderServiceFlowService extends FlowService {
  public orderService: OrderService;

  readonly ORDER_SERVICE_STATUS_ENUM = ORDER_SERVICE_STATUS;
  readonly ORDER_STATUS_ENUM = ORDER_STATUS;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  readonly INVENTORY_STATUS = INVENTORY_STATUS_ENUM;

  readonly ICONS = {
    INVOICE_FLOW_WARNING: 'INVOICE_FLOW_WARNING',
    INVOICE_FLOW_ERROR: 'INVOICE_FLOW_ERROR',
    APPROVE_INVOICES:'APPROVE_INVOICES'
  };

  public statuses: any = {};

  readonly DATA_LOCK_CLOSE_STATUS = {
    CANCEL_BY_USER: 0,
    TIME_EXTEND: 1,
    CANCEL_BY_TIMER: 2
  };


  public orderServiceFlow;
  public onStatusChange: EventEmitter<any> = new EventEmitter();
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'order';

  public orderServiceStatuses: Array<LookupModel>;

  constructor(public dialogService: DialogService,
              public orderServiceService: OrderServiceService,
              public orderFlowService: OrderFlowService,
              public orderSvc: OrderSvc,
              public notesService: NotesService,
              public alertService: AlertService,
              public inventoryService: InventoryService,
              public dataLockService: DataLockService,
              public dictionaryService: DictionaryService) {
    super();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.ORDER_SERVICE_STATUS.modelName).subscribe((res: any) => {
      this.orderServiceStatuses = res.items;
    })
  }

  // Gets step name, and returns value from dictionary
  generateName(uid: number) {
    if (this.orderServiceStatuses && this.orderServiceStatuses.length && this.orderServiceStatuses.find(i => i.id === uid)) {
      return this.orderServiceStatuses.find(i => i.id === uid).value;
    }

    return null;
  }

  elapsedTimeModal(status:any){
    return status !== this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_USER && status !== this.DATA_LOCK_CLOSE_STATUS.TIME_EXTEND && status !== this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_TIMER
  }

  createFlow(orderService: OrderService): Flow {
    this.orderService = orderService;
    let testFailStatus: number = this.ORDER_SERVICE_STATUS_ENUM.TEST_FAILED;
    this.orderServiceFlow = super.create({currentStep: 1});

    const statuses = {
      'NEW': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW) || 'New',
      'NEW_RESET': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET) || 'New (Reset)',
      'RFA': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.RFA) || 'Ready for Approval',
      'VENDOR_ACCEPTED': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.VENDOR_ACCEPTED) || 'Vendor Accepted',
      'FOC_DATE': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.FOC_DATE) || 'FOC Date',
      'SERVICE_INSTALL': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.SERVICE_INSTALL) || 'Service Install',
      'SERVICE_TEST': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.SERVICE_TEST) || 'Service Test',
      'COMPLETE': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.COMPLETED) || 'Complete',
      'CANCELLED': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.CANCELLED) || 'Cancelled',
      'NEW_PENDING': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING) || 'New (Pending)'
    };

    this.statuses = statuses;
    let status: number = this.orderService.state;
    if (!status && status !== this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING) {
      status = this.ORDER_SERVICE_STATUS_ENUM.VENDOR_ACCEPTED;
    }
    if (this.orderServiceStatuses) {
      const new_or_reset_name = status === this.ORDER_SERVICE_STATUS_ENUM.NEW ? statuses.NEW : statuses.NEW_RESET;
        this.orderServiceFlow.steps.push(new FlowStep({
          name: new_or_reset_name ,
          code: 1,
          revertStatusAlert: `To reset to ${new_or_reset_name}, item needs to be in ${statuses.RFA} state before.`
        }));
      this.orderServiceFlow.steps.push(new FlowStep({
        name: statuses.RFA, code: 2,
        revertStatusAlert: `To reset to ${statuses.RFA}, item needs to be in ${statuses.VENDOR_ACCEPTED} state before.`
      }));
      this.orderServiceFlow.steps.push(new FlowStep({
        name: statuses.VENDOR_ACCEPTED, code: 3,
        revertStatusAlert: `To reset to ${statuses.VENDOR_ACCEPTED}, item needs to be in ${statuses.FOC_DATE} state before.`,
        progressStatusAlert: `Item needs to be in ${statuses.RFA} state before.`
      }));
      this.orderServiceFlow.steps.push(new FlowStep({
        name: statuses.FOC_DATE, code: 4,
        revertStatusAlert: `To reset to ${statuses.FOC_DATE}, item needs to be in ${statuses.SERVICE_INSTALL} state before.`,
        progressStatusAlert: `Item needs to be in ${statuses.VENDOR_ACCEPTED} state before.`
      }));
      this.orderServiceFlow.steps.push(new FlowStep({
        name: statuses.SERVICE_INSTALL, code: 5,
        revertStatusAlert: `To reset to ${statuses.SERVICE_INSTALL}, item needs to be in ${statuses.SERVICE_TEST} state before.`,
        progressStatusAlert: `Item needs to be in ${statuses.FOC_DATE} state before.`
      }));
      this.orderServiceFlow.steps.push(new FlowStep({
        name: statuses.SERVICE_TEST, code: 6,
        revertStatusAlert: `To reset to ${statuses.SERVICE_TEST}, item needs to be in ${statuses.COMPLETE} state before.`,
        progressStatusAlert: `Item needs to be in ${statuses.SERVICE_INSTALL} state before.`,
        className: (orderService.state == testFailStatus ? 'mat-error' : null),
        icon: (orderService.state == testFailStatus ? 'warning' : null)
      }));
      this.orderServiceFlow.steps.push(new FlowStep({
        name: statuses.COMPLETE, code: 7,
        progressStatusAlert: `Item needs to be in ${statuses.SERVICE_TEST} state before.`
      }));
      this.setStepDates(this.orderServiceFlow, orderService);
      this.updateSteps(this.orderServiceFlow, status);
    }

    return this.orderServiceFlow;
  }
  removeTestFailStepFormat(flow) {
    flow.steps.forEach((step) => {
      if (step.code == 6) {
        step.icon = null;
        step.className = null;
      }
    });
  }
  //Check for required fields for RfA step for Install and Change SO commented out required fields
  //in case they decide to change requirement
  checkRfAFields(orderSvc) {
    if(this.orderFlowService.order.type_id === ORDER_TYPE.INSTALL){
      return orderSvc.inventory
      && orderSvc.inventory.ban
      && orderSvc.inventory.term
      && orderSvc.inventory.topology_id
      && orderSvc.inventory.est_mrc
      && orderSvc.inventory.est_nrc
      && orderSvc.inventory.technology_id
      // && orderSvc.inventory.cir_bw_id
      && orderSvc.des_due_date;
    }else if (this.orderFlowService.order.type_id === ORDER_TYPE.CHANGE){
      return orderSvc.inventory
      && orderSvc.inventory.ban
      && orderSvc.inventory.topology_id
      // && orderSvc.inventory.term
      // && orderSvc.inventory.est_mrc
      // && orderSvc.inventory.est_nrc
      // && orderSvc.inventory.technology_id
      // && orderSvc.inventory.cir_bw_id
      && orderSvc.des_due_date;
    }
  }

  public setStepToNewReset(): FlowStep {
    return new FlowStep({
      name: this.statuses.NEW_RESET, code: 1,
      revertStatusAlert: `To reset to ${this.statuses.NEW_RESET}, item needs to be in ${this.statuses.RFA} state before.`
    })
  }

  ;

  handleStepSelection(flow: Flow, orderSvc: OrderService, step) {
    let {code} = step;
    let orderService = Object.assign({}, orderSvc);

    let focDateModalWidth = orderService.foc_date_history ? '900px' : '550px';
    // let serviceTestModalWidth = orderService.service_test_date_history ? "900px" : "650px";

    const serviceTestModalWidth = '800px';

    if (orderSvc.state === this.ORDER_SERVICE_STATUS_ENUM.CANCELLED) {
      return;
    }
    //-------------------------------------------------------------------------------------------------------------------
    if (code === 1 && orderSvc.state === this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING) {
        this.alertService.error('', this.messages.get('ORDER_SERVICE_REQUIRED'));
    }
    else if (code == 2) {
      if (this.checkRfAFields(orderSvc)) {
        this.dialogService.edit(
          ConfirmDialogComponent,
          {
            order: this.orderServiceService.findByIdForEdit(this.orderService.id),
            bodyText: 'Order Service is ready for approval?'
          },
          {
            width: '350px'
          }).pipe(
          mergeMap(x => x.afterClosed()))
          .subscribe(result => {
            if (result && this.elapsedTimeModal(result.status)) {
              let status: number = this.ORDER_SERVICE_STATUS_ENUM.RFA;
              orderService.state = status;
              orderService.ready_for_approval = new Date();

              this.orderServiceService.update(orderService.id, orderService)
                .subscribe((result) => {
                  this.orderService = result;
                  //Object.assign(this.orderService, result);
                  this.updateOrderFlow(orderService.order_id, this.ORDER_SERVICE_STATUS_ENUM.RFA,
                    this.ORDER_STATUS_ENUM.RFA, null);
                  this.setStepDates(flow, result);
                  this.updateSteps(flow, status);
                });
            }
          })
      } else {
        this.alertService.error('', this.messages.get('ORDER_SERVICE_REQUIRED'));
      }
    } else if (code == 3 && (ORDER_STATUS.VENDOR_ACCEPT_RECEIVED === this.orderFlowService.order.status.id)) {
      const dialogMessage = (flow.currentStep - code) === 1 ? 'Are you sure you want to?' : 'Vendor has accepted?'
      this.dialogService.edit(
        ConfirmDialogComponent,
        {
          order: this.orderServiceService.findByIdForEdit(this.orderService.id),
          bodyText: dialogMessage
        },
        {
          width: '350px'
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result && this.elapsedTimeModal(result.status)) {
            if (result.cancelByTimer) {
              return this.dataLockService.cancelByTimerAlert();
            }

            let status: number = this.ORDER_SERVICE_STATUS_ENUM.VENDOR_ACCEPTED;
            orderService.state = status;

            this.orderServiceService.update(orderService.id, orderService).subscribe((result) => {
              //Object.assign(this.orderService, result);
              this.orderService = result;
              this.updateSteps(flow, status);
            });
          }
        })
    } else if (code === 4) {
      this.dialogService.edit(
        FocReceiveComponent,
        {
          order: this.orderServiceService.findByIdForEdit(this.orderService.id),
          data: orderService.foc_date_history
        }, {width: focDateModalWidth}).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result && this.elapsedTimeModal(result.status)) {
            this.removeTestFailStepFormat(flow);
            if (flow.currentStep >= code) {
              orderService.foc_date = orderService.foc_rec_date = orderService.service_test_vendor_date = orderService.install_date = null;
            }
            let status: number = this.ORDER_SERVICE_STATUS_ENUM.FOC_DATE;
            orderService.state = status;
            orderService.foc_rec_date = result.foc_rec_date;
            orderService.foc_date = result.foc_date;
            orderService.note = result.note;
            orderService['reason'] = result.reason; //util field processed on api
            orderService.foc_date_history = result.focDateHistory;

            this.orderServiceService.update(orderService.id, orderService)
              .subscribe((ord) => {
                Object.assign(orderSvc, ord);
                this.setStepDates(flow, orderSvc);
                if (result.note && result.note.length > 0) {
                  let message = `FOC Receive - FOC Date: ${result.note}`;
                  this.addNote(message);
                }
                this.updateSteps(flow, status);
              });
          }
        })
    } else if (code === 5 && orderService.state !== this.ORDER_SERVICE_STATUS_ENUM.TEST_PASSED) {
      this.dialogService.edit(
        ServiceInstallComponent,
        {
          order: this.orderServiceService.findByIdForEdit(this.orderService.id)
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result && this.elapsedTimeModal(result.status)) {
            this.removeTestFailStepFormat(flow);
            if (flow.currentStep >= code) {
              orderService.install_date = orderService.service_test_vendor_date = null;
            }

            let status: number = this.ORDER_SERVICE_STATUS_ENUM.SERVICE_INSTALL;
            orderService.state = status;
            orderService.install_date = result.service_install_date;
            orderService.inventory.install_date = result.service_install_date;

            this.orderServiceService.update(orderService.id, orderService)
              .subscribe((ord) => {
                Object.assign(orderSvc, ord);
                this.setStepDates(flow, orderSvc);
                this.updateSteps(flow, status);
              });
          }
        })
      } else if (code === 6 && orderService.state !== this.ORDER_SERVICE_STATUS_ENUM.TEST_PASSED) {
      this.dialogService.edit(
        ServiceTestComponent,
        {
          order: this.orderServiceService.findByIdForEdit(this.orderService.id),
          mrc: orderService.inventory.est_mrc,
          nrc: orderService.inventory.est_nrc,
          orderService: orderService,
          service_test_date_history: orderService.service_test_date_history
        }, {width: serviceTestModalWidth}).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result && this.elapsedTimeModal(result.status)) {
            if (result.cancelByTimer) {
              return this.dataLockService.cancelByTimerAlert();
            }

            Object.assign(orderSvc, result);
            this.setStepDates(flow, orderSvc);
            if ((result.vendor_test_passed && result.customer_test_passed)
              || (!result.service_test_vendor_date && !result.service_test_customer_date)) {
              step.className = null;
              step.icon = null;
            } else {
              step.className = 'mat-error';
              step.icon = 'warning';
            }
            this.updateOrderFlow(orderService.order_id, this.ORDER_SERVICE_STATUS_ENUM.COMPLETED,
              this.ORDER_STATUS_ENUM.COMPLETED, null);
            this.updateSteps(flow, result.state);
          }
        })
    } else if (code === 7) {
      if (orderService.inventory.ban.indexOf('TEMP') === 0 || orderService.inventory.sp_ckt_id.indexOf('TEMP') === 0)
        this.alertService.error('', this.messages.get('ORDER_SERVICE_TEMP_BAN_CKT'));
      else if(orderService.state === ORDER_SERVICE_STATUS.TEST_FAILED)
        this.alertService.error('',  this.messages.get('ORDER_SERVICE_COMPLETE_NOT_ALLOWED'));
      else {
        let status: number = this.ORDER_SERVICE_STATUS_ENUM.COMPLETED;
        orderService.state = status;
        orderService.order_service_complete_date = new Date();
        this.orderServiceService.update(orderService.id, orderService)
        .subscribe((ord) => {
          Object.assign(orderSvc, ord);
          this.setStepDates(flow, orderSvc);
          this.updateOrderFlow(orderService.order_id, this.ORDER_SERVICE_STATUS_ENUM.COMPLETED,
            this.ORDER_STATUS_ENUM.COMPLETED, null);
          this.updateSteps(flow, status);
        });
      }
    }
  }

  public updateOrderFlow(orderId, orderServiceState, orderNewState, orderRevertState) {
    // try this with emiters
    // update Order status to complete
    this.orderSvc.findById(orderId)
      .subscribe((order) => {
        let updateOrder = true;
        //if one service is not completed order is not completed
        order.services.forEach((service) => {
          if (!(service.state == orderServiceState))
            updateOrder = false;
        });

        if (updateOrder == true) {
          order.status_id = orderNewState;

          if (order.status_id === this.ORDER_STATUS_ENUM.COMPLETED && !order.complete_date) {
            order.complete_date = new Date().toUTCString();
          }

          if (orderNewState === this.ORDER_STATUS_ENUM.RFA) {
            order.ready_for_approval = new Date().toString();
          }

          this.orderSvc.update(order.id, order)
            .subscribe((res) => {
              this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
              this.orderFlowService.updateSteps(order.status_id)
              this.orderFlowService.setStepDates(order)
            });
        } else if (order.status_id == orderNewState && orderRevertState) {
          order.status_id = orderRevertState;
          this.orderSvc.update(order.id, order)
            .subscribe((res) => {
              this.orderFlowService.updateSteps(order.status_id)
            });
        }
      });
  }

  public resetOrderFlow(orderId) {
    this.orderFlowService.revertOrderStateToNew(orderId)
  }

  public setStepDates(flow: Flow, orderService: OrderService) {
    let orderSvc = orderService ? orderService : this.orderService
    flow.steps.forEach((item) => {
      switch (item.code) {
      case(1): {
        item.dateInfo = (this.orderFlowService.order.vendor_reject_date || orderSvc.created_at ) || null;
        break;
      }
      case (2): {
        item.dateInfo = (this.orderFlowService.order.ready_for_approval || orderSvc.ready_for_approval ) || null;
        break;
      }
      case (3): {
        item.dateInfo = this.orderFlowService.order.vendor_accept_date || null;
        break;
      }
      case (4): {
        item.dateInfo = orderService ? orderService.foc_date : null;
        break;
      }
      case (5): {
        item.dateInfo = orderService ? orderService.install_date : null;
        break;
      }
      case (6): {
        item.dateInfo = orderService ? orderService.service_test_vendor_date : null;
        item.secondDateInfo = orderService ? orderService.service_test_customer_date : null;

        break;
      }
      case (7): {
        item.dateInfo = orderService ? orderService.order_service_complete_date : null;
        break;
      }
      }
    });
  }

  public updateSteps(flow, status: number) {
    if(status === this.ORDER_SERVICE_STATUS_ENUM.NEW_REJECTED){
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_REJECTED) || 'New (Reject)';
          s.revertStatusAlert =
            `To reject to ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_REJECTED)},
          item needs to be in ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.RFA)} state before.`
        }
      });
    } else if (status === this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING) {
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING) || 'NEW_PENDING';
          s.icon = this.ICONS.INVOICE_FLOW_ERROR
        }
      });
    } else if (status === this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET){
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET) || 'New (Reset)';
          s.revertStatusAlert =
          `To reset to ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET)},
          item needs to be in ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.RFA)} state before.`
        }
      });
    } else {
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW) || 'New';
          s.icon = this.ICONS.APPROVE_INVOICES
          s.revertStatusAlert =
          `To reset to ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW)},
          item needs to be in ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.RFA)} state before.`
        }
      });
    }

    if (status === this.ORDER_SERVICE_STATUS_ENUM.NEW) {
      flow.currentStep = 1;
    }else if(status === this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING) {
      this.updateStep(1,'NEW_PENDING','mat-error', this.ICONS.INVOICE_FLOW_ERROR)
      flow.currentStep = 1;
    }
    else if (status === this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET) {
      this.updateStep(1, this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET) || 'New (Reset)');
      flow.currentStep = 1;
    }
    else if (status === this.ORDER_SERVICE_STATUS_ENUM.NEW_REJECTED) {
      this.updateStep(1, this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_REJECTED) || 'New (Rejected)');
      flow.currentStep = 1;
    }
    else if (status === this.ORDER_SERVICE_STATUS_ENUM.CANCELLED) {
      this.updateServiceStep(1, this.statuses.CANCELLED);
      flow.currentStep = 1;
    }
    else if (status === this.ORDER_SERVICE_STATUS_ENUM.RFA) {
      flow.currentStep = 2;
    }
    else if (status === this.ORDER_SERVICE_STATUS_ENUM.VENDOR_ACCEPTED) {
      flow.currentStep = 3;
    }
    else if (status === this.ORDER_SERVICE_STATUS_ENUM.FOC_DATE) {
      flow.currentStep = 4;
    }
    else if (status === this.ORDER_SERVICE_STATUS_ENUM.SERVICE_INSTALL) {
      flow.currentStep = 5;
    }
    else if (status === this.ORDER_SERVICE_STATUS_ENUM.TEST_FAILED || status === this.ORDER_SERVICE_STATUS_ENUM.TEST_PASSED) {
      flow.currentStep = 6;
    }
    else if (status === this.ORDER_SERVICE_STATUS_ENUM.COMPLETED) {
      this.updateStep(6, 'Service Test');
      flow.currentStep = 7;
    }
    this.onStatusChange.emit(status);
    this.refreshOrderFlowOrder();
  }

  public setOrderServiceToCancel() {
    this.updateStep(1, this.generateName(this.ORDER_SERVICE_STATUS_ENUM.CANCELLED) || 'Cancelled');
    this.flow.currentStep = 1;
    this.onStatusChange.emit(1);
  }

  public addNote(noteMessage) {
    let note = new Notes({
      entity_id: this.orderService.order_id,
      entity_type: this.SYSTEM_MODULE.ORDER,
      content: noteMessage
    });

    this.notesService.create(note);
  }

  private refreshOrderFlowOrder() {
    this.orderFlowService.refreshOrder();
  }

  private updateServiceStep(code: number, name: string, className?: string, icon?: string) {
    let step = this.findServiceStep(code);

    if (step) {
      step.name = name;
      step.className = className;
      step.icon = icon;
    }
  }

  private findServiceStep(code) {
    return this.orderServiceFlow.steps.filter((step) => {
      return step.code === code;
    })[0]
  }
}
