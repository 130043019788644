import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import * as moment from "moment";

import {UserSettingsService} from "../../../user/core/user-settings.service";
import {HistoryService} from "../../../shared/history/history.service";
import Query from "../../../core/query/query";
import { SortingService, SortingBuilder } from '../../../shared/sorting/sorting.service';
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {DialogService} from '../../../shared/dialog/dialog.service';
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {HistoryComponent} from "../../../shared/history/history.component";

import {ContractService} from '../../core/contract.service';
import {Contract} from '../../core/contract';
import {ContractScheduleCommitmentGridService} from '../../core/contract-schedule-commitment-grid.service';
import {ContractScheduleCommitment} from "../../core/contract-schedule-commitment";
import {ContractScheduleTermCondition} from "../../core/contract-schedule-term-condition";
import {ContractScheduleTermConditionGridService} from '../../core/contract-schedule-term-condition-grid.service';
import {ContractShellMenageDialogComponent} from "../../shared/contract-shell-menage-dialog/contract-shell-menage-dialog.component";

@Component({
    selector: 'app-contract-details-summary',
    templateUrl: './contract-details-summary.component.html',
    styleUrls: ['./contract-details-summary.component.scss']
  })
  export class ContractSummaryComponent extends PageDetailsComponent implements OnInit, AfterViewInit {

    @ViewChild('panelSide') panelSide;
    @ViewChild('history') history: HistoryComponent;
    @ViewChild('testNotes') testNotes;

    messages: IMessagesResourceService;
    readonly MESSAGES_MODULE: string = "contract";
    readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

    public contract: Contract;
    public commitmentSorting: SortingBuilder;
    public commitmentQuery: Query = new Query({
        orderBy: [['termination_date', 'DESC']]
      });
    public commitmentColumns: Array<any>;
    schedulesCommitment: Array<ContractScheduleCommitment>;
    schedulesTermCondition: Array<ContractScheduleTermCondition>;
    public termConditionColumns: Array<any>;
    public termConditionSorting: SortingBuilder;
    public termConditionQuery: Query = new Query({
        orderBy: [['termination_date', 'DESC']]
      });
    public notesCount: number = 0;

    constructor(
        public contractService: ContractService,
        private readonly route: ActivatedRoute,
        public settingsService: UserSettingsService,
        public sortingService: SortingService,
        public dialog: DialogService,
        public contractScheduleCommitmentGridService: ContractScheduleCommitmentGridService,
        public contractScheduleTermConditionGridService: ContractScheduleTermConditionGridService,
        public alert: AlertService,
        public historyService: HistoryService
    ) {
    super(new PageContext({
        name: 'app.contract.contract-details-summary',
        settings: settingsService
    }));

    this.commitmentSorting = this.sortingService.builder();
    this.termConditionSorting = this.sortingService.builder();

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    }

    ngOnInit(): void {
        this.contract = this.route.parent.snapshot.data.contract;

        this.commitmentColumns = this.contractScheduleCommitmentGridService.columns();
        this.termConditionColumns = this.contractScheduleTermConditionGridService.columns();

        this.loadTermConditionData(this.termConditionQuery);
        this.loadCommitmentData(this.commitmentQuery);
    }

    public onCommitmentCellClick(event) {
        if (event.rowType === 'header' && event.column.allowSorting) {
          this.commitmentSorting.apply(event, this.commitmentQuery);
          this.loadCommitmentData(this.commitmentQuery);
        }
    }

    public onTermAndConditionCellClick(event) {
        if (event.rowType === 'header' && event.column.allowSorting) {
          this.termConditionSorting.apply(event, this.termConditionQuery);
          this.loadTermConditionData(this.termConditionQuery);
        }
      }

    loadCommitmentData(commitmentQuery) {
        this.contractService.findAllScheduleCommitment(this.contract.id, commitmentQuery)
            .subscribe(
            (result) => {
                this.schedulesCommitment = result.items;
                this.commitmentQuery.total = result.total;
            },
            (error) => {
                console.log('Error fetching commitments', error);
            }
            );
    }

    loadTermConditionData(termConditionQuery) {
        this.contractService.findAllScheduleTermCondition(this.contract.id, termConditionQuery)
          .subscribe(
            (result) => {
              this.schedulesTermCondition = result.items;
              this.termConditionQuery.total = result.total;
            },
            (error) => {
              console.log('Error fetching terms', error);
            }
          );
    }

    isTerminated(date){
        if (moment(date).isAfter(moment(new Date().setDate(new Date().getDate() - 1)).format('YYYY-MM-DD'))) {
            return true;
        } else {
            return false;
        }
    }

    editContractShell() {
      this.dialog.open(ContractShellMenageDialogComponent,
        {
          contract: new Contract(
            {id: this.contract.id,
              name: this.contract.name,
              description: this.contract.description,
              vendor_id: this.contract.vendor_id,
              company_name: this.contract.company_name,
              disableVendorPicker: true})
        },{width: '500px'}
      )
        .afterClosed()
            .subscribe((result: any) => {
          if (result) {
            this.contractService.findById(this.contract.id)
              .subscribe(res => {
                let contract = {id: res.id,
                                name: res.name,
                                description: res.description,
                                vendor_id: this.contract.vendor_id,
                                company_name: res.company_name}
                Object.assign(this.contract, contract);
              });
            this.history.refreshList();
            this.alert.error('', this.messages.get('UPDATE_SUCCESS'));
          }
        }, (err) => {
          this.alert.error('', this.messages.get('UPDATE_ERROR'));
        })
    }
  
    onNotesCountChanged(count: number) {
      this.notesCount = count;
    }

    toggleNotes() {
      this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME);
    }
  
    toggleHistory() {
      this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME);
    }
  
    toggleSider(sectionName: string) {
      this.sider.toggle(sectionName);
    }
  }