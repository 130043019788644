import { Injectable } from '@angular/core';
import { FilterService } from "../../core/filter/filter.service";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { UserLookupProvider } from "../../user/core/user-lookup.provider";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { UserService } from "../../user/core/user.service";
import { VendorLookupProvider } from "../../vendor/core/vendor-lookup.provider";
import { VendorService } from "../../vendor/core/vendor.service";
import { commonAlertFilterContext } from "./common-alert-filter.context";
//This needs to be checked do we really need it, because it does not work
//Instead of this, we are now using AccountLookupProvider
import { AccountLookupProvider } from "../../account/core/account-lookup.provider";
import { AccountService } from "../../account/core/account.service";
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class CommonAlertFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];
  
  constructor(public vendorService: VendorService,
              public userSettingsService: UserSettingsService,
              userService: UserService,
              dictionaryService: DictionaryService,
              public accountService: AccountService) {
    super("app.alert.alert-filter", commonAlertFilterContext, userSettingsService);

    this.addLookupProvider('type', new LookupModelLookupProvider(dictionaryService,
      LOOKUP_MODELS_ENUM.ALERT_TYPE.modelName));
    this.addLookupProvider('category', new LookupModelLookupProvider(dictionaryService,
      LOOKUP_MODELS_ENUM.ALERT_CATEGORY.modelName));
    this.addLookupProvider('creator', new UserLookupProvider(userService));
    this.addLookupProvider('priority', new LookupModelLookupProvider(dictionaryService,
      LOOKUP_MODELS_ENUM.ALERT_PRIORITY.modelName));
    this.addLookupProvider('owner', new UserLookupProvider(userService));
    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('status', new LookupModelLookupProvider(dictionaryService,
      LOOKUP_MODELS_ENUM.ALERT_STATUS.modelName));
    this.addLookupProvider('account', new AccountLookupProvider(accountService));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
