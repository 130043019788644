
import {timer as observableTimer, interval as observableInterval,  Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { DataLockService } from "../data-lock.service";
import DataLock from "../data-lock";

@Component({
  selector: 'app-data-lock-extend-prompt',
  templateUrl: './data-lock-extend-prompt.component.html',
})
export class DataLockExtendPromptComponent implements OnInit, OnDestroy {

  entityDataLock: DataLock;
  editDialogRef;


  public closeCountdown;
  public closeCountdownSeconds = 60;

  constructor(
    public dataLockService: DataLockService,
    public dialogRef: MatDialogRef<DataLockExtendPromptComponent>
  ) { }

  ngOnInit() {

    this.closeCountdown = observableInterval(1000).subscribe(
      result => {
        this.closeCountdownSeconds--;
      }
    )

    observableTimer(60000).subscribe(result => {
      this.dialogRef.close({ closeStatus: this.dataLockService.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_TIMER });
    });
  }

  cancelEdit() {
    this.dialogRef.close({ closeStatus: this.dataLockService.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_USER });
  }

  extendLockDuration() {
    const dataLockInfo = { entityType: this.entityDataLock.entity_type, entityId: this.entityDataLock.entity_id };

    this.dataLockService.createEntityDataLock(dataLockInfo, this.entityDataLock.lock_duration)
      .subscribe(
        newEntityLock => {
          this.entityDataLock = newEntityLock;
          this.dataLockService.startWarningTimer(newEntityLock.lock_duration, this.editDialogRef);

          this.dialogRef.close({ closeStatus: this.dataLockService.DATA_LOCK_CLOSE_STATUS.TIME_EXTEND });
        }
      );
  }

  ngOnDestroy() {
    this.closeCountdown.unsubscribe();
  }

}
