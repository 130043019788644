import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';
import {UpdateInvoicesQuery,} from './invoice.actions';

import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../common/state/queryable.state';
import {QueryBuilder} from "../../common/query/query.builder";

const INVOICES_STATE_TOKEN = new StateToken<InvoicesStateModel>('invoices');

export interface InvoicesStateModel extends QueryableStateModel<any> {

}

@State<InvoicesStateModel>({
  name: INVOICES_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
export class InvoicesState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  @Action(UpdateInvoicesQuery)
  updateInvoicesQuery(ctx: StateContext<InvoicesStateModel>, {options}): void {
    return this.updateQuery(ctx, {options});
  }

}
