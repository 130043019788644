import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ContactLookupProvider } from "../../contact/core/contact-lookup.provider";
import { ContactService } from "../../contact/core/contact.service";
import { ContractLookupProvider } from "../../contract/core/contract-lookup.provider";
import { ContractService } from "../../contract/core/contract.service";
import { IFilterLookupProvider } from "../../core/filter/filter-lookup.provider";
import { FilterService } from '../../core/filter/filter.service';
import Query from "../../core/query/query";
import { CustomerLookupProvider } from "../../customer/core/customer-lookup.provider";
import { CustomerService } from "../../customer/core/customer.service";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { VendorLookupProvider } from "../../vendor/core/vendor-lookup.provider";
import { VendorService } from "../../vendor/core/vendor.service";
import { serviceLevelFilterContext } from "./service-level-filter.context";
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

export class QuoteStatusLookupProvider extends IFilterLookupProvider {
  lookupModel: string = LOOKUP_MODELS_ENUM.QUOTE_HEADER_STATUS.modelName;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    return this.dictionaryService.getByLookup(this.lookupModel, searchQuery)
      .map((result) => {
        return result.items.map((entry) => {
          return {
            key: entry.id,
            value: entry.value
          };
        });
      });
  }
}

export class QuoteServiceStatusLookupProvider extends IFilterLookupProvider {
  lookupModel: string = LOOKUP_MODELS_ENUM.QUOTE_SERVICE_STATUS.modelName;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    return this.dictionaryService.getByLookup(this.lookupModel, searchQuery)
      .map((result) => {
        return result.items.map((entry) => {
          return {
            key: entry.id,
            value: entry.value
          };
        });
      });
  }
}

@Injectable()
export class ServiceLevelFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public dictionaryService: DictionaryService,
              public customerService: CustomerService,
              public employeeService: ContractService,
              public vendorService: VendorService,
              public userSettingsService: UserSettingsService,
              public contactService: ContactService) {
    super(
      "app.quoting.service-level-quoting-filter", serviceLevelFilterContext, userSettingsService
    );

    this.addLookupProvider('employee', new ContractLookupProvider(employeeService));
    this.addLookupProvider('customer', new CustomerLookupProvider(customerService));
    this.addLookupProvider('quoteStatus', new QuoteStatusLookupProvider(dictionaryService));
    this.addLookupProvider('quoteServiceStatus', new QuoteServiceStatusLookupProvider(dictionaryService));
    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('sales_engineer', new ContactLookupProvider(contactService));
    this.addLookupProvider('sales_representative', new ContactLookupProvider(contactService));
    this.addLookupProvider('bandwidth', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.BANDWIDTH.modelName, true, true, new Query({limit: 1000})));
    this.addLookupProvider('requester', new ContactLookupProvider(contactService));
    this.addLookupProvider('opportunity_type', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.QUOTE_OPPORTUNITY_TYPE.modelName, true, true, new Query({limit: 1000})));
    this.addLookupProvider('quote_service_medium', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.QUOTE_SERVICE_MEDIUM.modelName, true, true, new Query({limit: 1000})));
    this.addLookupProvider('quote_selected_term', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.QUOTE_SERVICE_TERM.modelName, true, true, new Query({limit: 1000})));
    this.addLookupProvider('quote_service_type', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName, true, true, new Query({limit: 1000})));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
