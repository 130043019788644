import { DialogService } from '../../../shared/dialog/dialog.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-disconnect-date',
  templateUrl: './disconnect-date.component.html',
})
export class DisconnectDateComponent extends PageManageDialogComponent implements OnInit {

  public note: string;
  public disconnect_date: Date;
  public date_received: Date;
  labels: Array<any> = [];
  public formFields;

  constructor(public formBuilder: FormBuilder,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<DisconnectDateComponent>) {
    super(dialogService, dialogRef);
  }

  ngOnInit() {
    this.formFields = {
      disconnect_date: [null, Validators.required],
      date_received: [null, Validators.required]
    };
    this.form = this.formBuilder.group(this.formFields);
  }

  submit({ value, valid }: { value: any, valid: boolean }) {
    this.dialogRef.close({
      disconnect_date: value.disconnect_date,
      date_received: value.date_received
    });
  }

  cancel() {
    this.closeDialog();
  }
}
