import 'rxjs/Rx';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {ContractService} from '../core/contract.service';
import {Contract} from '../core/contract';
import {PageContext} from "../../core/page.context";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {PageDetailsComponent} from "../../core/page-details.component";

@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss']
})
export class ContractDetailsComponent extends PageDetailsComponent implements OnInit, OnDestroy {

  public contract: Contract;
  public contractId: number;

  constructor(public contractService: ContractService,
              public route: ActivatedRoute,
              public router: Router,
              public settingsService: UserSettingsService,
  ) {
    super(new PageContext({
      name: "app.contract.contract-details",
      settings: settingsService
    }));
  }

  ngOnInit() {
    this.contractId = this.route.snapshot.params.id;

    this.contractService.findById(this.contractId)
        .subscribe(res => {
          this.contract = res;
          this.contractService.toolbarAction.subscribe(data => {
            if (typeof this.contractService[data.action] === 'function') {
              this.contractService[data.action]({
                contract: this.contract,
                params: data.params
              })
            }
          })
        })

  }

  ngOnDestroy() {
  }
}
