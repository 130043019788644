import { Injectable } from '@angular/core';
import { FilterService } from '../../../core/filter/filter.service';
import Query from '../../../core/query/query';
import { DictionaryService } from '../../../dictionary/core/dictionary.service';
import { LookupModelLookupProvider } from '../../../dictionary/core/lookup-model-lookup.provider';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { SiteLookupProvider } from '../../../site/core/site-lookup.provider';
import { SiteService } from '../../../site/core/site.service';
import { UserSettingsService } from '../../../user/core/user-settings.service';
import { VendorLookupProvider } from '../../../vendor/core/vendor-lookup.provider';
import { VendorService } from '../../../vendor/core/vendor.service';
import { alertInventoryFilterContext } from './alert-inventory-filter.context';
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class AlertInventoryFilterService extends FilterService {
  customRanges: any = [
    {
      label: 'Last 2 years'
    },
    {
      label: 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      label: 'Prior month'
    },
    {
      label: 'Last week'
    }
  ];
  
  constructor(
    public dictionaryService: DictionaryService,
    public siteService: SiteService,
    public vendorService: VendorService,
    public userSettingsService: UserSettingsService
  ) {
    super(
      'app.inventory.alert-inventory-filter',
      alertInventoryFilterContext,
      userSettingsService
    );
    this.addLookupProvider(
      'defaultDateRanges',
      new DefaultDateRangesLookupProvider(
        this.customRanges,
        this.excludeRanges,
        true
      )
    );
    this.addLookupProvider(
      'vendor',
      new VendorLookupProvider(vendorService, true)
    );
    this.addLookupProvider('site', new SiteLookupProvider(siteService));
    this.addLookupProvider(
      'inventoryStatus',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.INVENTORY_STATUS.modelName
      )
    );
    this.addLookupProvider(
      'circuitType',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.INVENTORY_CKT_TYPE.modelName,
        true,
        true
      )
    );
    this.addLookupProvider(
      'circuitTech',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName,
        true,
        true
      )
    );
    this.addLookupProvider(
      'bandwidth',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.BANDWIDTH.modelName,
        true,
        true,
        new Query({ limit: 1000 })
      )
    );
  }
}
