import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {InventoryDetailsComponent} from './inventory-details/inventory-details.component';
import {InventoryListComponent} from './inventory-list/inventory-list.component';
import {InventorySummaryComponent} from "./inventory-details/inventory-summary/inventory-summary.component";
import {InventorySiteComponent} from "./inventory-details/inventory-site/inventory-site.component";
import {InventoryBillingComponent} from "./inventory-details/inventory-billing/inventory-billing.component";
import {InventoryCodeComponent} from "./inventory-details/inventory-code/inventory-code.component";
import {InventoryPricingComponent} from "./inventory-details/inventory-pricing/inventory-pricing.component";
import {
  InventoryRelatedCircuitComponent
} from "./inventory-details/inventory-related-circuit/inventory-related-circuit.component";
import {InventoryDocumentComponent} from "./inventory-details/inventory-document/inventory-document.component";
import {InventoryDetailsResolver} from "./core/inventory-details.resolver";
import {InventoryOrderComponent} from "./inventory-details/inventory-order/inventory-order.component";
import { InventoryContractTermsComponent } from './inventory-details/inventory-contract-terms/inventory-contract-terms.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':id/show',
        component: InventoryDetailsComponent,
        resolve: {
          inventory: InventoryDetailsResolver,
        },
        children: [
          {path: 'summary', component: InventorySummaryComponent},
          {path: 'site', component: InventorySiteComponent},
          {path: 'billing', component: InventoryBillingComponent},
          {path: 'order', component: InventoryOrderComponent},
          {path: 'code', component: InventoryCodeComponent},
          {path: 'pricing', component: InventoryPricingComponent},
          {path: 'related-circuit', component: InventoryRelatedCircuitComponent},
          {path: 'document', component: InventoryDocumentComponent},
          {path: 'contract-terms', component: InventoryContractTermsComponent},
          {
            path: "**",
            redirectTo: "summary"
          }
        ]
      },
      {
        path: '',
        component: InventoryListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class InventoryRoutingModule {
}
