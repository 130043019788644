import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, NavigationExtras} from '@angular/router';
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';
import {Observable} from 'rxjs/Observable';

import {GridSettingsComponent} from '../../core/grid/grid-settings.component';
import {InvoiceChargeService} from '../core/invoice-charge.service';
import {InvoiceCharge} from '../core/invoice-charge';
import {DisputeService} from '../../dispute/core/dispute.service';
import {AlertService} from '../../shared/alert/alert.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {DisputeManageDialogComponent} from '../../dispute/shared/dispute-manage-dialog/dispute-manage-dialog.component';
import {InvoiceFacepageService} from '../../invoice/core/invoice-facepage.service';
import {InvoiceFacepage} from 'app/invoice/core/invoice-facepage';
import {ChargeQuery} from '../core/charge.query';

import {ChargeGridService} from '../core/charge-grid.service';
import {PageListComponent} from '../../core/page-list.component';
import {PageContext} from '../../core/page.context';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {LOOKUP_ENUM} from '../../dictionary/core/lookup.enum';
import {ChargeFilterService} from '../core/charge-filter.service';
import {GridService} from '../../shared/grid/grid.service';
import {GlDirectSelectGridDialogComponent} from '../../gl-system-rules/shared/gl-direct-select-grid-dialog/gl-direct-select-grid-dialog.component';
import {forkJoin} from "rxjs";
import {PermissionService} from "../../permissions/core/permission.service";
import {map} from "rxjs/operators";
import {flatMap} from "rxjs/internal/operators";
import {ChargeCsvInterceptor} from "../core/charge-csv-interceptor";

@Component({
  selector: 'app-charge-list',
  templateUrl: './charge-list.component.html',
  styleUrls: ['./charge-list.component.scss']
})
export class ChargeListComponent extends PageListComponent
  implements OnInit, AfterViewInit {
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  invoice: InvoiceFacepage;

  @ViewChild('panelMore') panelMore;

  public query: ChargeQuery = new ChargeQuery({
    orderBy: [['id', 'ASC'], ['acct_level_1', 'ASC']]
  });

  public charges: Array<InvoiceCharge> = [];
  public selection: InvoiceCharge;
  public total: number;
  public selectedRows: Array<InvoiceCharge> = [];
  public delaySelectionChange = false;
  public selectionActive = false;
  public chargesNote = [];
  public noDataMessage = 'No Data ';
  public filter: any;
  public sameVendorsSelected = false;


  public notesCount = 0;
  selectedNoteId: number;
  disputeCreationDisabled: boolean = true;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild('notesComponent') notesComponent;

  public columns: Array<any>;
  public sorting: any[][];

  public error: boolean;

  permissionDisputeView: boolean;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'charge';
  comparedCurrencies: boolean;

  constructor(
    public invoiceChargeService: InvoiceChargeService,
    public disputeService: DisputeService,
    public alertService: AlertService,
    public dialogService: DialogService,
    public invoiceFacepageService: InvoiceFacepageService,
    public chargeGridService: ChargeGridService,
    public settingsService: UserSettingsService,
    public route: ActivatedRoute,
    public router: Router,
    public chargeFilterService: ChargeFilterService,
    public permissionService: PermissionService,
    public loaderService: LoaderService
  ) {
    super(
      new PageContext({
        name: 'app.charge.charge-list',
        data: {
          siderSection: 'filter'
        },
        settings: settingsService
      })
    );

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  clearFilter(q) {
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);
    setTimeout(() => {
      this.charges = [];
      this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      this.query = q;
      this.query.total = 0;
    }, 500);
  }

  disputeCreationAvailability() {
    let res = !this.selectedRows.length
      || this.isInfoOnlyChargeSelected(this.selectedRows)
      || !this.disputeService.validateSelectionForDispute(this.selectedRows)

    this.disputeCreationDisabled = res
  }

  submit() {
    this.query.remove('charge_ids');
    this.selectedRows = [];
    this.disputeCreationAvailability()
    this.selectionActive = false;
    this.loadData(this.query);
  }

  loadData(query?: ChargeQuery) {
    this.query = query;
    this.delaySelectionChange = true;
    this.charges = [];
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.invoiceChargeService.findAllLargeRequest(query).subscribe(
      result => {
        this.charges = result.items;
        query.total = this.total = result.total;

        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);

        this.notesComponent.fetchNotes();

        if (this.charges.length) {
          setTimeout(() => {
            this.delaySelectionChange = false;
            this.dataGrid.instance.selectRows(this.selectedRows, false);
          }, 2000);
        }

        this.disputeCreationAvailability()
      },
      error => {
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      }
    );

  }

  hasDispute(rows) {
    return this.disputeService.validateSelectionForDispute(rows);
  }

  compareCurrency (array): void {
    let curr ;
    if(array.length){
      curr = array[0].currency;
    }

    for(let e of array) {
      if (!(curr === e.currency)) {
        this.comparedCurrencies = true;
        break;
      } else {
        this.comparedCurrencies = false;
      }
    }

  }

  loadSelectedCharges(query?: ChargeQuery) {
    this.delaySelectionChange = true;

    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.invoiceChargeService.filters(query).subscribe(
      result => {
        this.selectedRows = result.items;
        this.charges = result.items;
        query.total = this.total = result.total;
        this.selectionActive = true; // activate selection mode

        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);

        if (this.charges.length) {
          setTimeout(() => {
            this.delaySelectionChange = false;
            this.dataGrid.instance.selectRows(this.selectedRows, false);
          }, 750);
        }
      },
      error => {
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      }
    );
  }

  public applySettings() {
    let siderSection = this.settings['siderSection'];

    if (siderSection === undefined) {
      siderSection = true;
    }

    this.sider.toggle(siderSection);
  }

  loadSelected() {
    if (this.query && !this.query['where'].hasOwnProperty('id')) {
      this.filter = JSON.parse(JSON.stringify(this.query['where']));
    }
    if (this.selectionActive) {
      this.query.remove('id');
      this.query['where'] = this.filter;
    } else {
      this.query['where'] = {};
      this.query.set('id', {
        $in: this.selectedRows.map(x => {
          return x.id;
        })
      });
    }
    this.query.offset = 0;
    this.query.page = 1;
    this.selectionActive = !this.selectionActive;
    this.loadData(this.query);
  }

  onPageChange(query: ChargeQuery) {
    this.loadData(query);
  }

  findIndexInSelectedCharges(chargeId): number {
    for (let i = 0, l = this.selectedRows.length; i < l; i++) {
      if (this.selectedRows[i].id === chargeId) {
        return i;
      }
    }
    return -1;
  }

  onSelectionChanged(event) {
    this.sameVendorsSelected = false;
    if (!this.delaySelectionChange && this.charges.length) {
      // ** Deselected rows */
      if (event.currentDeselectedRowKeys.length) {
        event.currentDeselectedRowKeys.forEach(item => {
          let index = this.findIndexInSelectedCharges(item.id);
          if (index > -1) {
            this.selectedRows.splice(index, 1);
          }
        });
      }

      // ** Selected rows */
      if (event.currentSelectedRowKeys.length) {
        event.currentSelectedRowKeys.forEach(item => {
          let index = this.findIndexInSelectedCharges(item.id);
          if (index === -1) {
            this.selectedRows.push(item);
          }
        });
      }

      if (this.selectedRows.length > 1) {
        this.selection = null;
      } else {
        this.selection = <InvoiceCharge>event.selectedRowsData[0];
      }

      /** grid  showing only selected charges */
      if (this.selectionActive && !this.selectedRows.length) {
        this.loadSelected();
      }

      this.compareCurrency(this.selectedRows);

      if(this.selectedRows.length){
        this.loadInvoice(this.selectedRows[0].invoice_id).subscribe(
          (invoice: InvoiceFacepage) => {
            this.invoice = invoice;
          }
        );
        const vendorIds = this.selectedRows.map(item => item.vendor_id);
        this.sameVendorsSelected = vendorIds.every(item => item === vendorIds[0]);
      }
    }

    this.disputeCreationAvailability()
  }

  addDirectGL(isUpdate = false) {
    this.invoiceFacepageService
      .findById(this.selectedRows[0].invoice_id).pipe(
      flatMap(invoice => {
        return this.dialogService
          .open(
            GlDirectSelectGridDialogComponent,
            {
              charges: this.selectedRows,
              invoice: invoice,
              isUpdate
            },
            {width: '60%'}
          )
          .afterClosed();
      }))
      .subscribe(result => {
        if (result) {
          if (result.data && result.data.length > 0) {
            this.alertService.success(
              '',
              this.messages.get(result.deleted ? 'MANUAL_GL_ASSIGN_REMOVED' : 'MANUAL_GL_ASSIGN_SUCCESS')
            );
          } else if(result.data) {
            this.alertService.success(
              '',
              this.messages.get('MANUAL_GL_ASSIGN_REMOVED')
            );
          } else {
            this.alertService.success(
              '',
              this.messages.get('MANUAL_GL_ASSIGN_CANCELLED'));
          }
          this.selectedRows.length = 0;
          this.loadData(this.query);
        }
      });
  }

  addDispute() {
    if (this.disputeService.validateSelectionForDispute(this.selectedRows)) {
      this.invoiceFacepageService
        .findById(this.selectedRows[0].invoice_id).pipe(
        flatMap(invoice => {
          return this.dialogService
            .open(
              DisputeManageDialogComponent,
              {
                charges: this.selectedRows,
                invoice: invoice
              },
              {width: '60%'}
            )
            .afterClosed();
        }))
        .subscribe(result => {
          if (result) {
            this.alertService.success(
              '',
              this.messages.get('DISPUTE_CREATE_SUCCESS')
            );
            this.selectedRows.length = 0;
            this.loadData(this.query);
          }
        });
    } else {
      this.alertService.error(
        '',
        this.messages.get('INVALID_CHARGES_SELECTION_FOR_DISPUTE')
      );
    }
  }

  ngOnInit() {
    this.loadPermissions()
      .subscribe(() => {
        this.query.where['info_only_ind'] = {$in: ['false']};
        this.route.queryParams.subscribe(queryParams => {
          let chargeIds = queryParams['chargeids'];

          if (chargeIds) {
            chargeIds = chargeIds.split(',');

            this.query.where = {
              id: {$in: chargeIds}
            };

            this.loadData(this.query);
          }

          this._init();
        });
      })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.chargeGridService.create(this.dataGrid.instance, {
        selection: {
          mode: 'multiple',
          selectAllMode: 'page'
        },
        noDataText: this.noDataMessage
      });
      super.ngAfterViewInit();
    });
  }

  csv() {
    const chargeTransformMiddleware = (items) => {
      const chargeCSVInterceptor = new ChargeCsvInterceptor();
      return items.map(item => chargeCSVInterceptor.transform(item));
    };
    const {query, chargeGridService, invoiceChargeService} = this;

    chargeGridService.csvMap().subscribe(fields => {
      invoiceChargeService.exportToCSV('Charges', {
        fields: fields,
        query: query,
        middleware: [chargeTransformMiddleware]
      });
    });
  }

  gridSettings() {
    this.dialogService
      .open(GridSettingsComponent, {
        service: this.chargeGridService,
        sliderMinimum: 1
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.chargeGridService;
  }

  getDefaultSort(): any {
    return [['id', 'ASC'], ['acct_level_1', 'ASC']];
  }

  _init() {
    this.prepareList();
    if (this.dataGrid && this.dataGrid.instance && this.charges.length) {
      this.loadData(this.query);
    }
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  onViewNoteCharges(charges) {
    if (charges && charges.length) {
      let chargeIds: Array<number> = charges.map(item => {
        return item.id;
      });

      this.query['where'] = {};
      if (this.isInfoOnlyChargeSelected(charges)) {
        this.query.where['info_only_ind'] = {$in: ['true']};
      } else {
        this.query.where['info_only_ind'] = {$in: ['false']};
      }
      this.query.set('id', {$in: chargeIds});
      this.query.offset = 0;
      this.query.page = 1;

      this.loadSelectedCharges(this.query);
    }
  }

  refresh() {
    this.loadData(this.query);
  }

  onNoteCreated() {
    this.selectionActive
      ? this.loadSelectedCharges(this.query)
      : this.loadData(this.query);

    this.selectedRows.length = 0;
  }

  toggleNotes(chargesNote?: boolean, selectedNoteId?: number) {
    this.selectedNoteId = selectedNoteId;

    if (!this.sider.isActive(this.SECTIONS.NOTES_SECTION_NAME)) {
      this.sider.toggle(this.SECTIONS.NOTES_SECTION_NAME);
    }
  }

  selectCharge(charge: any) {
    this.selectedRows = [charge];
    this.dataGrid.instance.selectRows(this.selectedRows, false);
  }

  showDispute(id: any) {
    if (this.permissionDisputeView) {
      this.router.navigate(['/dispute', id, 'show']);
    }
  }

  // Disabled adding dispute if info only charge is selected
  isInfoOnlyChargeSelected(rows: Array<InvoiceCharge>) {
    return rows.some(r => r.info_only_ind === 'Y');
  }


  directGLAddEnabledForSelection(rows: Array<InvoiceCharge>) {
    if (!rows.length) {
      return false;
    }
    return !rows.some(r => !!r.direct_gl_stamp || this.isUnsetAdjustment(r))
  }

  directGLEditEnabledForSelection(rows: Array<InvoiceCharge>) {
    if (!rows.length) {
      return false;
    }
    return rows.some(r => !!r.direct_gl_stamp)
  }

  loadInvoice(id: number): Observable<InvoiceFacepage> {
    return this.invoiceFacepageService.findById(id);
  }

  isGLBatchStatus() {
    if (this.invoice && this.invoice.header && this.invoice.header.status_code) {
      return this.invoice.header.status_code >= LOOKUP_ENUM.INVOICE_STATUS.GL_BATCH_OUTPUT
    }
  }

  isUnsetAdjustment(row) {
    return (row.chg_class === 'adjad' || row.chg_class === 'adjbf') &&
      row.include_in_amount_due_status !== LOOKUP_ENUM.UNSET_ADJUSTMENTS.INCLUDED
  }

  loadPermissions() {
    const disputeView$ = this.permissionService.isAllowed("disputes", "VIEW");
    return forkJoin(
      disputeView$,
    ).pipe(map(([disputeView]) => {
      this.permissionDisputeView = disputeView;
    }));
  }
  createNewRateAudit() {
    const chargeIds = [];
    const invoiceIds = [];
    const vendorId = this.selectedRows[0].vendor_id;
    this.selectedRows.forEach(charge => {
      chargeIds.push(charge.id);
      if(!invoiceIds.includes(charge.invoice_id)) {
        invoiceIds.push(charge.invoice_id);
      }
    });
    const navigationExtras: NavigationExtras = {state: {chargeIds, invoiceIds, vendorId, fromPlace: 'charge', selectContractIfOne:true}};
    this.router.navigate(['audit', 'rate-audit'], navigationExtras);

  }
}
