
    <ca-picker [textField]="TEXT_FIELD"
               [secondTextField]="SECOND_TEXT_FIELD"
               [customFormating]="CUSTOM_FORMATING"
               [customFormatingDate]="CUSTOM_FORMATING_DATE"
               [keyField]="KEY_FIELD"
               [pickerIcon]="pickerIcon"
               [control]="control"
               [entries]="entries"
               [initialItem]="_initialItem"
               (onScrollEnd)="onScrollEnd($event)"
               (onSearchChange)="onSearchChange($event)"
               (onSelectionChange)="onSelectionChange($event)"
               [disabled]="disabled"
               [required]="required"
               [multiple]="multiple"
               [searchEnabled]="searchEnabled"
               [placeholder]="placeholder"
               [clearEnabled]="clearEnabled"
               [italicStyleOptionPredicate]="italicStyleOptionPredicate"
               #caPicker
               (onClear)="handleClear()"
               [secondLineTextField]="SECOND_LINE_TEXT_FIELD"
               [pickerType]="pickerType"></ca-picker>
  