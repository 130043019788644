import {Address} from "../../address/core/address.model";
import {Vendor} from "../../vendor/core/vendor";

export class VendorRemitAddress {
  id: number;
  vendor_location_id: string;
  vendor_id: number;
  vendor: Vendor;

  address_id: number;
  address: Address;
}
