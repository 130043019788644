
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Observable, zip as observableZip } from 'rxjs';
import { filter, flatMap } from "rxjs/internal/operators";
import { ChargeQuery } from "../../charge/core/charge.query";
import { BaseService } from '../../core/base.service';
import Query from "../../core/query/query";
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { InventoryQuery } from "../../inventory/core/inventory.query";
import { UserSettingsService } from '../../user/core/user-settings.service';
import { CommonAlert } from './common-alert';
import {CommonAlertQuery} from "./common-alert.query";

@Injectable()
export class CommonAlertService extends BaseService<CommonAlert> {

  readonly ALERT_DICTIONARY = {
    COMPLETE: 'Complete'
  };

  @Output() alertReloading = new EventEmitter();

  constructor(public restangular: Restangular,
              public dictionaryService: DictionaryService,
              public userSettings: UserSettingsService) {
    super('alert', restangular);
  }

  findAlertAuditResults(alertId: number, query) {
    return this.service()
      .one(this.name, alertId)
      .all('audit')
      .customGET(null, this.toFilter(query));
  }

  findChargesSummary(alertId: number) {
    return this.service()
      .one(this.name, alertId)
      .all('summary')
      .customGET();
  }

  findCharges(alertId: number, query: ChargeQuery) {

    let concreteQuery = (query || new ChargeQuery());
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .one(this.name, alertId)
      .all('charges')
      .customGET(null, this.toFilter(transformedQuery));
  }

  createAlertAccount(data: any): any {
    return this.service().one(this.name, 'alert-account').customPOST(data);
  }

  findAccountsForTreeData(data: any): any {
    return this.service().one(this.name, 'accounts-from-tree').customPOST(data);
  }

  getAlertsForInvoice(query: CommonAlertQuery): any {
    let concreteQuery = (query || new InventoryQuery());
    let transformedQuery = concreteQuery.transform();
    return this.service().one(this.name, 'alerts-for-invoice').customGET(null, this.toFilter(transformedQuery));
  }

  completeAlerts(selectedAlerts, user): Observable<any> {
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.ALERT_STATUS.modelName)
      .pipe(flatMap((alertDictionaries: any) => alertDictionaries.items),
        filter((alertDicItems: any) => alertDicItems.value === this.ALERT_DICTIONARY.COMPLETE))
      .pipe(flatMap((alertDicComplete: any) => {

        return observableZip(
          ...selectedAlerts.map((alert: CommonAlert) => {
            alert.closed_at = new Date();
            alert.status = alertDicComplete;
            alert.status_id = alertDicComplete.id;
            alert.owner = user;
            alert.owner_id = user.id;
            return this.update(alert.id, alert);
          })
        );

      }));
  }

  changeOwners(alerts: CommonAlert[], owner_id: number, status_id: number){
    const alert_ids = alerts.map(alert => alert.id)
    return this.service().one(this.name,'change-owners').customPOST({alert_ids, owner_id, status_id})
  }


  findInventory(alertId: Number, query: InventoryQuery) {
    let concreteQuery = (query || new InventoryQuery());
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .one(this.name, alertId)
      .all('inventory')
      .customGET(null, this.toFilter(transformedQuery));
  }

  findAccount(alertId: Number, query: InventoryQuery) {
    let concreteQuery = (query || new InventoryQuery());
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .one(this.name, alertId)
      .all('account')
      .customGET(null, this.toFilter(transformedQuery));
  }

  findInventoryMRCs(alertId: Number, query: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service()
      .one(this.name, alertId)
      .all('inventory-mrc')
      .customGET(null, this.toFilter(transformedQuery));
  }

  findChargesByMRCandSPID(alertId: Number, query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .one(this.name, alertId)
      .all('inventory-mrc-show')
      .customGET(null, this.toFilter(transformedQuery));
  }

  findPreGLValidationAlerts(alertId: number, query: Query) {

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .one(this.name, alertId)
      .all('gl-validation')
      .customGET(null, this.toFilter(transformedQuery));
  }

  findAlertForInvoice(invoiceId: number) {
    return this.service()
      .one(this.name, invoiceId)
      .all('invoice-alert')
      .customGET(null, null);
  }

  excludeAlertItems(alertId: number, items, note) {
    return this.service().one(this.name,'exclude-alert-items').customPOST({alertId, items, note})
  }

  findChargeTotal(alertId: Number, query?: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .one(this.name, alertId)
      .all('charge-total')
      .customGET(null, this.toFilter(transformedQuery));
  }

  findDisconnect(alertId: Number, query?: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .one(this.name, alertId)
      .all('disconnect')
      .customGET(null, this.toFilter(transformedQuery));
  }

  findMissingInventory(alertId: Number, query?: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .one(this.name, alertId)
      .all('missing-inventory')
      .customGET(null, this.toFilter(transformedQuery));
  }
  findMissingInventorySpid(alertId: Number, query?: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .one(this.name, alertId)
      .all('missing-inventory-spid')
      .customGET(null, this.toFilter(transformedQuery));
  }

  reloadAlerts() {
    this.alertReloading.emit("");
  }

  findBatch(alertId: Number, query: InventoryQuery) {
    let concreteQuery = (query || new InventoryQuery());
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .one(this.name, alertId)
      .all('batch')
      .customGET(null, this.toFilter(transformedQuery));
  }

  findAlertsByInvoiceId(query: InventoryQuery) {
    let concreteQuery = (query || new InventoryQuery());
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .one(this.name)
      .all('batch-invoice-alerts')
      .customGET(null, this.toFilter(transformedQuery));
  }
}
