import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { Account } from '../../core/account';
import { ACCOUNT_STATUS_ENUM } from '../../core/account-status.enum';

@Component({
  selector: 'app-account-sneak-peek',
  templateUrl: './account-sneak-peek.component.html',
  styleUrls: ['./account-sneak-peek.component.css']
})
export class AccountSneakPeekComponent implements OnInit, OnChanges {
  static isDouble = 0;

  @Input() account: Account;
  @Input() visible: boolean;
  @Output() closeRequested = new EventEmitter();
  @Output() editRequested = new EventEmitter();
  @Output() deletedRequested = new EventEmitter();

  id: number;
  readonly ACCOUNT_STATUS_ENUM = ACCOUNT_STATUS_ENUM;

  constructor(public router: Router) {}

  ngOnInit() {}

  editAccount() {
    this.editRequested.emit(this.account);
  }

  deleteAccount() {
    this.deletedRequested.emit(this.account);
  }

  closeSide() {
    this.closeRequested.emit();
  }

  ngOnChanges(): void {
    if (this.account) {
      this.id = this.account.id;
    }
  }

  onSeriesClick(timeClicked: any) {
    AccountSneakPeekComponent.isDouble++;
    let r = this.router;
    let accountId = this.id;

    setTimeout(function() {
      if (AccountSneakPeekComponent.isDouble === 2) {
        r.navigate(['/account', accountId, 'show']);
        AccountSneakPeekComponent.isDouble = 0;
      }
    }, 250);
  }
}
