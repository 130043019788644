import { Injectable } from '@angular/core';
import { LocationQuery } from './location.query';
import { LocationService } from './location.service';
import { of } from 'rxjs';
import { ConfigService } from 'app/core/config/config.service';
import { Config } from 'app/core/config/config';
@Injectable()
export class ExchangeRateService {

  private static excahgeRates = null;
  private static currencies = null;
  private static configCurrencyes = null;
  private static configCurrency = null;
  private static currencyes = {};
  private static configCountryLocale = null;

  constructor(public locationService: LocationService, public configService:ConfigService) {
    this.loadData();
  }

  loadData(){
    this.locationService.findAllExchangeRates(new LocationQuery({limit: 1000, where:{}})).subscribe(data=>{
      ExchangeRateService.excahgeRates = data.items;
    });

    this.locationService.findAllUniqueCurrencies(new LocationQuery({limit: 1000, where:{}})).subscribe(data=>{
      ExchangeRateService.currencies = data.items;
    });

    this.configService.findAll().subscribe((config: Config) => {

      this.locationService.findAll(new LocationQuery({where:{alpha_2_code:config.location.country}}))
        .subscribe((locations)=>{
        const {items} = locations;
          if(items.length) ExchangeRateService.configCurrency = items[0];
        });
      });

      this.configService.findAll().subscribe((config: Config) => {

        this.locationService.findAll(new LocationQuery({limit: 1000,where:{}}))
          .subscribe((locations)=>{
            const {items} = locations;
            ExchangeRateService.configCurrencyes = items;
          });
        });

    this.configService.findAll().subscribe((config: Config) => {

      this.locationService.findAll(new LocationQuery({where:{alpha_2_code:config.location.country}}))
        .subscribe((locale)=>{
          const {items} = locale;
          ExchangeRateService.configCountryLocale = items[0];
        });

    });
  }

  getConfigCurrency(){
    if(!!ExchangeRateService.configCurrency) return ExchangeRateService.configCurrency.currency;
  }

  getRateForCurrency(currency){
    if(!!ExchangeRateService.currencyes[currency]) return ExchangeRateService.currencyes[currency];

    if(!!ExchangeRateService.excahgeRates && !!ExchangeRateService.configCurrencyes && currency){
      const country = ExchangeRateService.configCurrencyes.filter(country=>currency === country.currency);
      const currID = ExchangeRateService.currencies.filter(c => currency === c.currency);
      const tpm = country.length ? ExchangeRateService.excahgeRates.filter(rate=>rate.currency_id === currID[0].id):[];
      const tmpC = tpm.length ? tpm[0].exchange_rate : 0;
      ExchangeRateService.currencyes[currency] = tmpC;
      return tmpC
    }
  }
}
