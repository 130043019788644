import { Directive, HostListener, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[caGridTooltip]'
})

export class CaGridTooltipDirective {

  @HostBinding('style.text-overflow') text_overflow = 'ellipsis';
  @HostBinding('style.overflow') overflow = 'hidden';
  @HostBinding('attr.title') title;
  tooltipTitle;

  constructor(private elRef: ElementRef) {}

  @HostListener('mouseenter', ['$event']) public mouseEnter($event) {


    if ($event.target.offsetWidth < $event.target.scrollWidth) {
      this.tooltipTitle = $event.target.textContent;

    } else {
      this.tooltipTitle = '';
    }

    this.title = this.tooltipTitle;
  }

}
