import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class BuildingGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.building.building-grid", userSettings);
  }

  defaultColumns = [
      {
        dataField: 'name',
        caption: 'Name',
        width: 200
      },
      {
        dataField: 'address.address1',
        caption: 'Address',
        width: 200
      },
      {
        dataField: 'address.city',
        caption: 'City',
        width: 160
      },
      {
        dataField: 'address.subdivision.abbreviation',
        caption: 'Subdivision',
        width: 160
      },
      {
        dataField: 'address.postal_code',
        caption: 'Postal Code',
        width: 140
      },
      {
        dataField: 'address.country.alpha_2_code',
        caption: 'Country',
        width: 140
      }
    ];

  /*columns() {

    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/

}
