<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{modalTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <form class="ca-form dialog-content-no-margin" [formGroup]="documentForm">

    <div class="flex-container" fxLayout="column" *ngIf="!this.edit">
      <input class="ca-document-file-input" #fileInput placeholder="Document" type="file"
             (change)="onFileSelect($event)">
      <div class="ca-dropzone ca-dropzone-height ca-default-border-color" caDropzone (dropped)="onDrop($event)" fxLayout="column">
        <div class="text-wrapper" fxLayoutAlign="center center">
          <ca-icon [key]="'FILE_CLOUD_UPLOAD'" [innerClass]="'upload-icon'"></ca-icon>
          <span class="document-upload-text"> Drop file to upload or</span>
          <button type="button" class="document-upload-button flat-button" mat-button (click)="fileInput.click()">
            Browse
          </button>
        </div>
        <div [ngClass]="{'ca-file-name-taken-error-text': ((fileNameTaken !== null && fileNameTaken) || fileSizeMessage !== null && fileSizeMessage)}"
             class="text-wrapper selected-file" fxLayoutAlign="center center">
          <i>{{ fileName }}
            <span *ngIf="fileNameTaken !== null && fileNameTaken">already exists</span>
            <span *ngIf="fileSizeMessage !== null && fileSizeMessage">
              <span *ngIf="fileNameTaken !== null && fileNameTaken"> and </span>
              {{fileSizeMessage}}
            </span>
          </i>
        </div>
      </div>
    </div>

    <div class="flex-container" fxLayout="row" fxLayoutGap="24px" style="margin-top: 24px">
      <div class="flex-item" fxFlex>

        <ca-lookup-model-picker caInput [clearEnabled]="true" [pickerForm]="documentForm" [required]="true"
                                [lookupModel]="INVOICE_ATTACHMENT_TYPE_LOOKUP_MODEL" formControlName="attachment_type_id"
                                placeholder="Attachment Type">
        </ca-lookup-model-picker>

      </div>
    </div>

    <div class="flex-container" fxLayout="row" fxLayoutGap="24px">

      <div class="flex-item" fxFlex>
        <mat-form-field class="input-container" caInput>
          <textarea matInput placeholder="Description" formControlName="description" rows="2"></textarea>
        </mat-form-field>
      </div>

    </div>

  </form>
</div>


<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
  </div>

  <button #cancelButton mat-button type="submit" (click)="close()">
    Cancel
  </button>

  <button *ngIf="!this.edit"
    #saveButton
    mat-button
    type="submit"
    (click)="onSubmit(documentForm)"
    [disabled]="documentForm?.invalid || isUploading || !files.length || (fileNameTaken > 0) || (fileSizeMessage && fileSizeMessage.length)">
    Save
  </button>
  <button *ngIf="this.edit"
          #saveButton
          mat-button
          type="submit"
          (click)="onSubmit(documentForm)"
          [disabled]="documentForm?.invalid || isUploading">
    Save
  </button>
</mat-dialog-actions>
