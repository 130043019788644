<app-inventory-details-toolbar
  [inventory]="inventory"
  [isAddGLHidden]="true"
  [isAddGLDisabled]="true"
  [isContractTermsHidden]="false"
  [isAddContractTermsDisabled]="false"
  [isEditContractTermsDisabled]="!this.contractTermsSelected?.id"
  [isDeleteContractTermsDisabled]="!this.contractTermsSelected?.id"
  [isAddPricingHidden]="true"
  [isAddPricingDisabled]="true"
  [isCreateOrderHidden]="true"
  [isCreateOrderDisabled]="true"
  [isEditInventoryDisabled]="true"
  [isEditInventoryHidden]="true"
  [isDocumentAction]="false"
  [isPricingDocumentAction]="false"
  [isFilterDisabled]="true"
  [isNotesOn]="($activeSiderSection | async) == SECTIONS.NOTES_SECTION_NAME"
  [isHistoryOn]="($activeSiderSection | async) == SECTIONS.HISTORY_SECTION_NAME"
  
  (toggleNotesRequested)="toggleNotes()"
  (toggleHistoryRequested)="toggleHistory()"
  (createContractTermRequested)="addContractTerm()"
  (editContractTermRequested)="editContractTerm()"
  (deleteContractTermRequested)="deleteContractTerm()"
  [notesCount]="notesCount"

  (backRequested)="back()">
</app-inventory-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-inventory-details-tabs [activeTabIndex]="8"></app-inventory-details-tabs>
  <div class="app-content app-content-list dx-nodata-absolute" style="height: 100%">
    <dx-data-grid
      #contractTermGrid
      [columns]="contractTermsColumns"
      [allowColumnResizing]="true"
      [dataSource]="contractTerms"
      (onSelectionChanged)="onContractTermSelectionChange($event)"
      [showColumnLines]="false"
      [showRowLines]="true"
      style="height: 100%"
      (onCellClick)="onContractTermCellClick($event)"
    >
      <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>

      <div *dxTemplate="let d of 'dateTemplate'">
        <div caGridTooltip>{{ d.value | caDate }}</div>
      </div>

      <div *dxTemplate="let d of 'currencyTemplate'">
        <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.currency.currency }}</div>
      </div>
      <dxo-sorting mode="none"></dxo-sorting>
    </dx-data-grid>
  </div>
  <ca-pager class="app-pager ca-default-border-top" [pageContext]="inventoryContractTermGridService" [query]="contractTermsQuery" (onChange)="loadContractTerms()"></ca-pager>

  <mat-sidenav #panelSide
               [mode]="'side'"
               position="end"
               [opened]="false">
    <ca-history
      #history
      [entityId]="inventory?.id"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
      [entityType]="'inventory'">
    </ca-history>

    <ca-notes
      [entityId]="inventory?.id"
      [entityType]="SYSTEM_MODULE.INVENTORY"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
      (countChanged)="onNotesCountChanged($event)">
    </ca-notes>
  </mat-sidenav>
</mat-sidenav-container>
