export const alertAccountFilterContext = {
    filters: [
      {
        field: 'account.account_no',
        label: 'Account',
        type: 'lookup',
        lookupProviderName: 'account',
        fieldToFilterWith: 'account.account_no',
        index: 1
      },
      {
        field: 'account.vendor.name',
        label: 'Vendor',
        type: 'lookup',
        lookupProviderName: 'vendor',
        index: 2,
        visible: true
      },
      {
        field: 'account.entity.name',
        label: 'Entity',
        type: 'lookup',
        lookupProviderName: 'entity',
        index: 3
      },
      {
        field: 'account.status_id',
        label: 'Status',
        type: 'lookup',
        editor: 'checkboxList',
        lookupProviderName: 'accountStatus',
        index: 4
      },
      {
        field: 'account.country.alpha_2_code',
        label: 'Country',
        type: 'lookup',
        editor: 'country',
        lookupProviderName: 'country',
        index: 5,
        visible: true
      },
      {
        label: 'GL Rule Name',
        field: 'rule_name',
        type: 'string',
        index: 1
      }
    ]
  };
