import {Injectable} from '@angular/core';
import {Restangular} from "ngx-restangular";
import {BaseService} from "../../core/base.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ReportingService extends BaseService<any> {

  readonly reportType: any = {
    TEMPLATE: 'template',
    PRIVATE: 'private',
    PUBLIC: 'public'
  };

  readonly dataTypes: any = {
    string: 'string',
    currency: 'currency',
    numeric: 'numeric',
    date: 'date',
    datetime: 'datetime'
  };

  constructor(public restangular: Restangular,
              public http: HttpClient) {
    super('report', restangular);
  }

  findByType(type) {
    return this.service().one(this.name).one(type, 'find').customGET('', null);
  }

  findByReportType(type) {
    return this.service().one(this.name).one(type, 'findByReportType').customGET('', null);
  }

  loadDataView(key) {
    return this.service().one(this.name).one(key, 'dataview').customGET('', null);
  }

  findByKey(key: string) {
    return this.service().one(this.name).one(key, 'report').customGET('', null);
  }

  execute(report) {
    return this.service().one(this.name).one('execute').post(report);
  }

  clone(report) {
    return this.service().one(this.name).one('execute').post(report);
  }

  getConfig() {
    return this.service().one(this.name).one('config').customGET('', null);
  }

}
