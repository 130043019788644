import { Injectable } from '@angular/core';

import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { QuoteServiceAddressAPipe, QuoteServiceAddressZPipe } from '../shared/quote-address/quote-address.pipe';

@Injectable()
export class QuotingOverviewGridService extends GridService {
  defaultColumns = [
    {
      caption: 'Service ID',
      dataType: 'text',
      dataField: 'quoteService.quote_service_id',
      width: 120,
      index: 0
    },
    {
      caption: 'Vendor',
      dataField: 'vendor.name',
      width: 100
    },
    {
      caption: 'Quote Type',
      alignment: 'left',
      dataType: 'text',
      dataField: 'quoteService.quoting_type.value',
      width: 120
    },
    {
      caption: 'Service Type',
      dataType: 'text',
      dataField: 'quoteService.quote_service_type.value',
      width: 100,
      index: 1
    },
    {
      caption: 'Bandwidth',
      dataType: 'text',
      dataField: 'quoteService.circuit_bandwidth.value',
      width: 100,
      index: 2
    },
    {
      caption: 'Offer ID',
      dataType: 'text',
      dataField: 'quote_service_offer_id',
      width: 60,
      index: 3
    },
    {
      caption: 'Method',
      dataField: 'quote_type.value',
      width: 100,
      index: 4
    },
    {
      caption: 'Medium',
      dataField: 'quote_service_medium.value',
      cellTemplate: 'emptyCellTemplate',
      width: 100
    },
    {
      caption: 'On/Off Net',
      dataField: 'net_type.value',
      cellTemplate: 'emptyCellTemplate',
      width: 100
    },
    {
      id: 'addressA',
      dataField: 'quoteService.address.search_address',
      caption: 'Address A',
      alignment: 'left',
      cellTemplate: 'addressAtemplate',
      width: 200
    },
    {
      id: 'addressZ',
      dataField: 'quoteService.secondary_address.search_address',
      caption: 'Address Z',
      alignment: 'left',
      cellTemplate: 'addressZtemplate',
      width: 200
    },
    {
      caption: 'Off Net Vendor',
      dataField: 'off_net_vendor.name',
      cellTemplate: 'emptyCellTemplate',
      width: 120
    },
    {
      caption: 'Install Interval',
      cellTemplate: 'emptyCellTemplate',
      dataField: 'interval',
      width: 150
    },
    {
      caption: 'Port Bandwidth',
      dataField: 'quoteService.port_bandwidth.value',
      width: 120
    },
    {
      caption: 'Status',
      dataField: 'status',
      width: 100,
      cellTemplate: 'quoteRequestStatusTemplate',
    },
    {
      dataField: 'sent_date',
      caption: 'Sent Date',
      dataType: 'date',
      format: 'shortDate' + 'shortTime',
      width: 170
    },
    {
      dataField: 'received_date',
      caption: 'Received Date',
      dataType: 'date',
      format: 'shortDate' + 'shortTime',
      width: 160
    },
    {
      dataField: 'expiration_date',
      caption: 'Expiration Date',
      dataType: 'date',
      format: 'shortDate',
      width: 160
    },
    {
      termNumber: 4,
      caption: 'MTM',
      alignment: 'center',
      width: 150,
      dataField: 'mtm_mrc',
      cellTemplate: 'termMTMTemplate'
    },
    {
      termNumber: 1,
      caption: '1 Year',
      alignment: 'center',
      width: 150,
      dataField: 'one_year_mrc',
      cellTemplate: 'term1YTemplate'
    },
    {
      termNumber: 2,
      // important: true,
      caption: '2 Years',
      alignment: 'center',
      width: 150,
      dataField: 'two_year_mrc',
      cellTemplate: 'term2YTemplate'

    },
    {
      termNumber: 3,
      // important: true,
      caption: '3 Years',
      alignment: 'center',
      width: 150,
      dataField: 'three_year_mrc',
      cellTemplate: 'term3YTemplate'

    },
    {
      termNumber: 5,
      // important: true,
      caption: '5 Years',
      alignment: 'center',
      width: 150,
      dataField: 'five_year_mrc',
      cellTemplate: 'term5YTemplate'

    },
  ];

  constructor(public userSettings: UserSettingsService, public formatService: FormatService,
              private quoteServiceAddressAPipe: QuoteServiceAddressAPipe,
              private quoteServiceAddressZPipe: QuoteServiceAddressZPipe) {
    super('app.quoting.quoting-overview-grid', userSettings);
  }

  columns() {
    return super.columns().map(column => ['addressA', 'addressB'].includes(column.id) ? ({
      ...column,
      calculateSortValue: (rowData) => {
        const pipe = column.id === 'addressA' ? this.quoteServiceAddressAPipe : this.quoteServiceAddressZPipe;
        return pipe.transform(rowData.quoteService.quoting_type, rowData).toLowerCase();
      }
    }) : column)
  }
}
