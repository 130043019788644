import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BaseService} from '../../../core/base.service';
import {EntityLockService} from '../../../shared/entity-lock/entity-lock.service';
import Query from 'app/core/query/query';


@Injectable()
export class RateAuditRuleService extends BaseService<any> {
  constructor(public restangular: Restangular, private entityLock: EntityLockService) {
    super('audit-rate-rule', restangular, entityLock);
  }

  getRules(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('rules').customGET(null, this.toFilter(transformedQuery));
  }

  update(auditObject){
    return this.service().all(this.name).all('update').customPUT(auditObject);
  }

  updateBulk(auditId, rules:any){
    return this.service().all(this.name).all('updateBulk').customPUT({auditId, rules});
  }

}
