export const orderFilterContext = {
  filters: [
    {
      field: 'full_order_id',
      label: 'Order No ',
      type: 'string',
      index: 1,
      visible: true
    },
    {
      field: 'vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 2,
      visible: true
    },
    {
      field: 'processor.full_name',
      label: 'Processor',
      type: 'lookup',
      lookupProviderName: 'processor',
      index: 3,
      visible: true
    },
    {
      field: 'status.key',
      label: 'Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'orderStatus',
      index: 6,
      visible: true
    },
    {
      field: 'created_at',
      label: 'Create Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 7,
      visible: true
    },
    {
      field: 'request_date',
      label: 'Request Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 8,
      visible: true
    },
    {
      field: 'send_date',
      label: 'Sent Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 9,
      visible: true
    },
    {
      field: 'type.key',
      label: 'Type',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'order_type',
      index: 10,
      visible: true
    }
  ]
};
