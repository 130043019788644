import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { path, pathOr } from 'ramda';

const TYPES = {
  ACCESS_CIRCUIT: 10,
  POINT_TO_POINT: 20,
  OTHER: 30
};

@Pipe({
  name: 'quoteServiceAddressA'
})
@Injectable()
export class QuoteServiceAddressAPipe implements PipeTransform {
  transform(quotingType, data): any {
    switch (quotingType.id) {
    case TYPES.POINT_TO_POINT: {
      return data.quoteService.address.search_address;
    }

    case TYPES.ACCESS_CIRCUIT: {
      return path(['network_hub', 'hub_name'], data) === 'NO HUB SELECTED' ? 'No Hub' :
        pathOr('N/A', ['network_hub', 'site', 'building', 'address', 'search_address'], data);
    }

    default: {
      return 'N/A'
    }
    }
  }
}

@Pipe({
  name: 'quoteServiceAddressZ'
})
@Injectable()
export class QuoteServiceAddressZPipe implements PipeTransform {
  transform(quotingType, data): any {
    switch (quotingType.id) {
    case TYPES.POINT_TO_POINT: {
      return data.quoteService.secondary_address.search_address;
    }

    default: {
      return data.quoteService.address.search_address;
    }
    }
  }
}
