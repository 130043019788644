import {LOOKUP_ENUM} from "../../dictionary/core/lookup.enum";

export const auditAlertInventoryMRCFilterContext = {
  filters: [
    {
      field: 'charge_id',
      label: 'Charge ID',
      type: 'number',
      index: 0,
      matchCase: false,
      exactMatch: false
    },
    {
      field: 'sp_ckt',
      label: "SPID",
      type: 'string',
      index: 1
    },
    {
      field: "billed_mrc",
      label: "Billed MRC",
      type: 'negativeNumber',
      index: 2
    },
    {
      field: "inventory_mrc",
      label: "Inventory MRC",
      type: 'negativeNumber',
      index: 3
    },
    {
      field: "variance",
      label: "Variance",
      type: 'negativeNumber',
      index: 4
    }
  ]
};
