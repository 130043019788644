import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GLSystemRuleRoutingModule } from './gl-system-rule-routing.module';
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule, } from 'devextreme-angular/ui/data-grid';
import { DxChartModule, } from 'devextreme-angular/ui/chart';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { GLSystemRuleService } from './core/gl-system-rule.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { GLSystemRuleListComponent } from './gl-system-rule-list/gl-system-rule-list.component';
import { GLSystemRuleComponent } from './gl-system-rule.component';
import { BANCodingComponent } from './ban-coding/ban-coding.component';
import { BANCodingGridService } from './core/ban-coding-grid.service';
import { SubAccountCodingComponent } from './sub-account-coding/sub-account-coding.component';
import { SubAccountCodingGridService } from './core/sub-account-coding-grid.service';
import { SPIDCodingComponent } from './spid-coding/spid-coding.component';
import { SPIDCodingGridService } from './core/spid-coding-grid.service';
import { InventoryCodingComponent } from './inventory-coding/inventory-coding.component';
import { InventoryCodingGridService } from './core/inventory-coding-grid.service';
import { VendorModule } from '../vendor/vendor.module';
import { AccountModule } from '../account/account.module';
import { GLStringSelectGridService } from './core/gl-string-select-grid.service';
import { GlStringSelectGridComponent } from './shared/gl-string-select-grid/gl-string-select-grid.component';
import { GlStringSelectGridDialogComponent } from './shared/gl-string-select-grid-dialog/gl-string-select-grid-dialog.component';
import { GLCodingSneakPeekComponent } from './shared/gl-coding-sneak-peek/gl-coding-sneak-peek.component';
import { GLStringGridFilterService } from './shared/gl-string-filter/gl-string-filter.service';
import { CoreModule } from '../core/core.module';
import { GLStringService } from '../gl-string/core/gl-string.service';
import { SpidCodingFilterService } from './core/spid-coding-filter.service';
import { InventoryCodingFilterService } from './core/inventory-coding-filter.service';
import { SystemRuleStore } from './core/system-rule.store';
import { SystemRuleSelectors } from './core/system-rule.selectors';
import { GlDirectSelectGridDialogComponent } from './shared/gl-direct-select-grid-dialog/gl-direct-select-grid-dialog.component';
import { GlDirectService } from './core/gl-direct.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxDatatableModule,
    DxButtonModule,
    DxDataGridModule,
    DxChartModule,
    DxLoadPanelModule,
    FlexLayoutModule,
    CustomFormsModule,
    MaterialModule,
    GLSystemRuleRoutingModule,
    VendorModule,
    AccountModule,
    CoreModule
  ],
  exports: [
    GlStringSelectGridComponent,
    GlStringSelectGridDialogComponent
  ],
  providers: [
    GLSystemRuleService,
    BANCodingGridService,
    SubAccountCodingGridService,
    SPIDCodingGridService,
    InventoryCodingGridService,
    GLStringGridFilterService,
    GLStringSelectGridService,
    GLStringService,
    SpidCodingFilterService,
    InventoryCodingFilterService,
    SystemRuleStore,
    SystemRuleSelectors,
    GlDirectService
  ],
  declarations: [
    GLSystemRuleComponent,
    GLSystemRuleListComponent,
    BANCodingComponent,
    GlStringSelectGridComponent,
    GlStringSelectGridDialogComponent,
    GlDirectSelectGridDialogComponent,
    SubAccountCodingComponent,
    SPIDCodingComponent,
    InventoryCodingComponent,
    GLCodingSneakPeekComponent,
  ],
  entryComponents: [
    GlStringSelectGridDialogComponent,
    GlDirectSelectGridDialogComponent
  ]
})
export class GLSystemRuleModule {

}
