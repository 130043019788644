import {Injectable} from '@angular/core';
import {GridService} from "../../shared/grid/grid.service";
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class InvoiceNoteGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.cost.invoice-note-grid", userSettings);
  }

  columns() {
    return [{
      caption: "Bill Message",
      dataField: "note",
    }]
  }

}
