import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class SvcOrderServiceInventoryGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.contract.svc-order-service-inventory-grid", userSettings);
  }

  defaultColumns = [
    {
      caption: "",
      fixed: true,
      width: 40,
      allowSorting: false,
      allowResizing: false,
    },
    {
      caption: 'Line Number',
      dataField: 'line_number',
      width: 100
    },
    {
      caption: 'Svc Order Service',
      dataField: 'description',
      width: 200
    },
    {
      cellTemplate: "dateTemplate",
      caption: "Rate Eff Date",
      dataField: "rate_eff_date",
      dataType: 'date',
      alignment: 'right',
      width: 120
    },
    {
      cellTemplate: "dateTemplate",
      caption: "Rate Term Date",
      dataField: "rate_term_date",
      dataType: 'date',
      alignment: 'right',
      width: 120
    },
    {
      dataField: 'term',
      caption: 'Term',
      width: 100
    },
    {
      caption: 'MRC',
      dataField: 'mrc',
      dataType: 'currency',
      cellTemplate: 'svcOrderServiceCurrencyTemplate',
      alignment: 'right',
      width: 100
    },
    {
      caption: 'NRC',
      dataField: 'nrc',
      dataType: 'currency',
      cellTemplate: 'svcOrderServiceCurrencyTemplate',
      alignment: 'right',
      width: 100
    }];

}
