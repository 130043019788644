<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{title}}</h1>
</div>
<div mat-dialog-content class="ca-dialog-content">
  <div class="grid-container">
    <div style="height: 220px" class="grid-body app-content-list app-content-list-no-pager dx-nodata-absolute">
      <dx-data-grid
        #chargesGrid
        class="grid-with-checkboxes"
        [columns]="columns"
        [dataSource]="charges"
      >
        <div *dxTemplate="let d of 'eligible'" style="text-align: left;">
          <span>{{
            d?.data?.contract_commitment?.is_commitment_eligible ? "Yes" : "No"
            }}</span>
        </div>

        <div *dxTemplate="let d of 'dateTemplate'" style="text-align: left;">
          <span>{{ d.value | caDate }}</span>
        </div>
        <div *dxTemplate="let d of 'withheldCellTemplate'">
          <div *ngIf="d.value != null" caGridTooltip>{{ d.value ? "Yes" : "No" }}</div>
        </div>

        <div *dxTemplate="let d of 'currencyTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caCurrency:d.data.currency }}
          </div>
        </div>
        <div *dxTemplate="let d of 'chargeRateTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caNumber }}
          </div>
        </div>

        <div *dxTemplate="let d of 'chargeCodedTemplate'">
          <div *ngIf="d.value != null" caGridTooltip>{{ d.value ? "Coded" : "Uncoded" }}</div>
        </div>
      </dx-data-grid>
    </div>
  </div>
  <form class="ca-form" style="padding-top: 8px" [formGroup]="form">
    <div fxLayout="row wrap">
      <mat-form-field caInput class="caGap">
        <textarea matInput [placeholder]="'Note'" formControlName="note" fxFlex="100%" rows="2" [required]="'true'"></textarea>
      </mat-form-field>
    </div>
  </form>
</div>



<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
    <ca-confirm-delete-button
      #genericButton [requestInput]="true" (deleteRequested)="delete()" [deleteText]="actionText"
      [snackBarDeleteInputMessage]="'Please type in '+ randomString + ' to ' +  actionText"
      [placeHolder]="deletePlaceholder" [entityName]="randomString" [disabled]="form.invalid">
    </ca-confirm-delete-button>
  </div>
  <button #cancelButton mat-button type="submit" (click)="close()">
    Cancel
  </button>
</mat-dialog-actions>
