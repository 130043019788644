import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonAlert } from '../../core/common-alert';
import { CommonAlertService } from '../../core/common-alert.service';
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import { DialogButtonsController } from '../../../core/dialog-buttons-controller.class'

@Component({
  selector: 'app-alert-assign-dialog',
  templateUrl: './alert-assign-dialog.component.html',
})
export class AlertAssignDialogComponent extends DialogButtonsController implements OnInit {

  readonly VALIDATION = {
    REQUIRED_ERROR: 'Field is required',
  }

  public alertAssignForm: FormGroup;
  public alerts: CommonAlert[];

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AlertAssignDialogComponent>,
    public alertService: CommonAlertService
  ) { 
    super();
  }

  ngOnInit() {
    this.alertAssignForm = this.formBuilder.group({
      owner_id: new FormControl(this.alerts.length > 1 ?  '' : this.alerts[0].owner_id, Validators.required)

    });
  }

  onSubmit({ value, valid }: { value: CommonAlert, valid: boolean }) {
    if (valid) {      
      this.changeOwners(this.alerts, value.owner_id)
    }
  }

  changeOwners(alerts, owner_id){
    const status_id = LOOKUP_ENUM.ALERT_STATUS.IN_PROGRESS;
    this.toggleDialogButtons();
    this.alertService.changeOwners(alerts, owner_id, status_id)
      .subscribe(
        (result) => {
          this.toggleDialogButtons(false);
          this.close(result);
        },
        (error) => {
          this.toggleDialogButtons(false);
          console.log('error assigning alert owner', error);
        }
      )

  }

  // update(alert: CommonAlert) {
  //   this.alert.status_id = LOOKUP_ENUM.ALERT_STATUS.IN_PROGRESS;
  //   this.toggleDialogButtons();
  //   this.alertService.update(alert.id, alert)
  //     .subscribe(
  //     (result) => {
  //       this.toggleDialogButtons(false);
  //       this.close(result);
  //     },
  //     (error) => {
  //       this.toggleDialogButtons(false);
  //       console.log('error assigning alert owner', error);
  //     }
  //     );
  // }

  close(result?: any) {
    this.dialogRef.close(result);
  }

}
