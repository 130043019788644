import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Restangular} from "ngx-restangular";
import {GLSystemRule} from "./gl-system-rule";
import {AccountService} from "../../account/core/account.service";
import Query from "../../core/query/query";

@Injectable()
export class GLSystemRuleService extends BaseService<GLSystemRule> {

  public BANCoding = "gl_ban_coding";
  public subAccountCoding = "gl_subaccount_coding";
  public SPIDCoding = "gl_spid_coding";

  constructor(restangular: Restangular, public accountService: AccountService) {
    super('GLSystemRule', restangular);
  }

  //BAN Coding methods

  loadBANs(vendorId, query?: Query) {

    let params = {params: query || {}};
    return this.service().one(this.BANCoding).one('bans', vendorId).customGET("", params);
  }

  loadBANCodings(vendorId, BAN) {
    let query = new Query({
      where: {
        id: {$ne: new Date().getMilliseconds() * -1},
        ban: BAN,
        vendor_id: vendorId
      },
      order: [["ban", "ASC"]]
    })
    // reset query
    query.limit = 1000000;
    delete query.offset;
    delete query.page;

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().one(this.BANCoding).customGET(null, this.toFilter(transformedQuery));
  }

  saveBANCodings(account, createCodings, updateCodings, deleteCodings) {
    return new Promise((resolve, reject) => {
      let operations = [
        ...createCodings.map(element => {
          let object = {
            ban: element.ban,
            vendor_id: element.vendor_id,
            gl_string_id: element.gl_string_id,
            gl_apportion_pct: element.gl_apportion_pct
          }
          return this.service().all(this.BANCoding).post(object).toPromise();
        }),

        ...updateCodings.map(element => {
          return this.service().one(this.BANCoding, element.id).customPUT(element).toPromise();
        }),

        ...deleteCodings.map(element => {
          return this.service().one(this.BANCoding, element).remove().toPromise();
        })
      ];



      return Promise.all(operations)
        .then(() => {
          let reset = (createCodings.length == 0) && (updateCodings.length == 0);
          if (reset) {
            account.pct_total = 0;
            account.gl_coding_enabled = false;
          } else {
            account.pct_total = 100;
            account.gl_coding_enabled = true;
          }

          return this.accountService.codingCreateUpdate(account.id, account)
            .subscribe((account) => {
              resolve(account);
            }, reject);
        });
    });
  }

  deleteAllBANCodings(vendor_id, ban) {
    return new Promise<void>((resolve, reject) => {
      let path = ['deleteAll', vendor_id, ban].join('/');
      this.service().one(this.BANCoding).one(path).remove()
        .subscribe((res) => {
          resolve();
        }, (err) => {
          resolve();
        });
    })

  }

  codingDeleteAllBANCodings(vendor_id, ban) {
    return new Promise<void>((resolve, reject) => {
      let path = ['coding/deleteAll', vendor_id, ban].join('/');
      this.service().one(this.BANCoding).one(path).remove()
        .subscribe((res) => {
          resolve();
        }, (err) => {
          resolve();
        });
    })

  }

  //SUB ACCOUNT Coding methods
  loadSubAccounts(vendorId, ban, query?: Query) {
    let path = ["subaccounts", vendorId, ban].join('/');

    let params = {params: query || {}};

    return this.service().one(this.subAccountCoding).one(path).customGET("", params);
  }

  loadSubAccountCodings(ban, sub_account) {
    let query = new Query({
      where: {
        id: {$ne: new Date().getMilliseconds() * -1},
        ban: ban,
        sub_account: sub_account
      },
      order: [["sub_account", "ASC"]]
    })
    // reset query
    query.limit = 1000000;
    delete query.offset;
    delete query.page;

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().one(this.subAccountCoding).customGET(null, this.toFilter(transformedQuery));
  }

  changeSubAccountStatus(data) {
    return this.service().one(this.subAccountCoding).all("update_gl_coding_enabled").post(data);
  }

  codingChangeSubAccountStatus(data) {
    return this.service().one(this.subAccountCoding).all("coding_update_gl_coding_enabled").post(data);
  }

  saveSubAccountCodings(subAccount, createCodings = [], updateCodings = [], deleteCodings = []) {
      const operations = [
        ...createCodings.map(element => {
          const object = {
            ban: element.ban,
            sub_account: element.sub_account,
            vendor_id: element.vendor_id,
            gl_string_id: element.gl_string_id,
            gl_apportion_pct: element.gl_apportion_pct,
            gl_coding_enabled: subAccount.gl_coding_enabled || true
          };
          return this.service().all(this.subAccountCoding).post(object).toPromise();
        }),

        ...updateCodings.map(element => {
          return this.service().one(this.subAccountCoding, element.id).customPUT(element).toPromise();
        }),

        ...deleteCodings.map(element => {
          return this.service().one(this.subAccountCoding, element).remove().toPromise();
        })
      ];


      return Promise.all(operations)
        .then(() => {
          let reset = (createCodings.length == 0) && (updateCodings.length == 0);

          if (!subAccount.gl_coding_enabled && !reset) {
            subAccount.gl_coding_enabled = true;
          }

          if (reset) {
            subAccount.pct_total = 0;
            subAccount.gl_coding_enabled = false;
          }
          return subAccount;
        });
  }

  //SPID Coding Methods
  loadSPIDs(vendorId, ban, query?: Query) {
    let path = ["spids", vendorId, ban].join('/');
    if (query && query.where && query.where.gl_coding_enabled) {
      query.where["COALESCE(gl_coding_enabled, FALSE)"] = query.where.gl_coding_enabled;
      delete query.where.gl_coding_enabled;
    }
    let params = {params: JSON.stringify(query) || {}};
    return this.service().one(this.SPIDCoding).one(path).customGET("", params);
  }

  loadSPIDCodings(ban, spid) {
    let query = new Query({
      where: {
        id: {$ne: new Date().getMilliseconds() * -1},
        ban: ban,
        spid: spid
      },
      order: [["spid", "ASC"]]
    })
    // reset query
    query.limit = 1000000;
    delete query.offset;
    delete query.page;

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().one(this.SPIDCoding).customGET(null, this.toFilter(transformedQuery));
  }

  changeSPIDStatus(data) {
    return this.service().one(this.SPIDCoding).all("update_gl_coding_enabled").post(data);
  }

  codingChangeSPIDStatus(data) {
    return this.service().one(this.SPIDCoding).all("coding_update_gl_coding_enabled").post(data);
  }

  saveSPIDCodings(spid, createCodings, updateCodings, deleteCodings) {
      let operations = [
        ...createCodings.map(element => {
        let obj = {
          ban: element.ban,
          spid: element.spid,
          gl_string_id: element.gl_string_id,
          gl_apportion_pct: element.gl_apportion_pct,
          gl_coding_enabled: spid.gl_coding_enabled || true,
          vendor_id:element.vendor_id
        };
        return this.service().all(this.SPIDCoding).post(obj).toPromise();
      }),

      ...updateCodings.map(element => {
        return this.service().one(this.SPIDCoding, element.id).customPUT(element).toPromise();
      }),

      ...deleteCodings.map(element => {
        return this.service().one(this.SPIDCoding, element).remove().toPromise();
      })
      ];

      return Promise.all(operations)
        .then(() => {
          let reset = (createCodings.length == 0) && (updateCodings.length == 0);

          if (!spid.gl_coding_enabled && !reset) {
            spid.gl_coding_enabled = true;
          }

          if (reset) {
            spid.pct_total = 0;
            spid.gl_coding_enabled = false;
          }
          return spid;
        });
  }
}
