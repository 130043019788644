import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-alert-dialog',
  templateUrl: './simple-alert-dialog.component.html',
  styleUrls: ['./simple-alert-dialog.component.css']
})
export class SimpleAlertDialogComponent implements OnInit {

  bodyText: string = '';

  constructor(public dialogRef: MatDialogRef<SimpleAlertDialogComponent>) {
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
