import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LocationService} from "../../../location/core/location.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {LoaderService} from "../../../shared/loader/loader.service";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {SingleContactManageDialogComponent} from "../single-contact-manage-dialog/single-contact-manage-dialog.component";
import {ContactFormService} from "../../core/contact-form.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {DictionaryService} from "../../../dictionary/core/dictionary.service";
import {LOOKUP_MODELS_ENUM} from "../../../dictionary/core/lookup-models.enum";
import Query from "../../../core/query/query";

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.css']
})
export class ContactSectionComponent implements OnInit, OnDestroy {
  readonly CONTACT_TYPE_ENUM: any = LOOKUP_ENUM.CONTACT_TYPE;
  readonly CONTACT_FUNCTION_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.CONTACT_FUNCTION.modelName;

  public contactForm: FormGroup;
  public contactFormSub: Subscription;
  public contacts: FormArray;

  @Input() contactFunctionsUI: Array<any>;
  @Input() isRequired = true;

  @Output() contactsChanged = new EventEmitter();
  @Input() orderContacts: Array<any>;
  allCountries: Array<any> = [];
  query = new Query();
  phoneRegexMask = '(000) 000-0000';

  constructor(
    public formBuilder: FormBuilder,
    public locationService: LocationService,
    public alertService: AlertService,
    public loaderService: LoaderService,
    public dialogService: DialogService,
    public contactFormService: ContactFormService,
    public dictionaryService: DictionaryService,
  ) {
    this.contactFunctionsUI = [];
  }

  ngOnInit() {
    this.loadCountries();
    this.initForm();
    this.initContactFunctionsUI();
  }

  addNewContact() {
    this.dialogService.open(
      SingleContactManageDialogComponent,
      {
        contactFunctionsUI: this.contactFunctionsUI
      },
      { width: '500px' }
    )
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.contactFormService.addContact(result);
          this.emitContacts();
        }
      });
  }

  private async initForm() {
    if (this.isRequired) {
      // Required from config file
      this.contactFormService.initRequiredForm();
    }
    this.contactFormSub = this.contactFormService.contactForm$
      .subscribe(contact => {
        let tContacts = this.orderContacts;
        this.contactForm = contact;
        this.contacts = this.contactForm.get('contacts') as FormArray;
        // Check if already exist in json
        for (let group of this.contacts.controls) {
          let required = false;
          if (group instanceof FormGroup) {
            if (tContacts) {
              tContacts.forEach((oContact, index) => {
                if (oContact.contact_function === group.get('contact_function').value && required === false) {
                  required = true;
                  if (oContact.country_id == undefined) {
                    oContact.country_id = 1;
                  }
                  group.setValue(oContact);
                  tContacts.splice(index, 1);
                }
              });
            }
          }
        }

        // Check if any additional contact needs to be added
        if (tContacts) {
          tContacts.forEach(oContact => {
            this.contacts.push(this.formBuilder.group(oContact));
          });
        }
        this.emitContacts();
      });
  }

  editForm(contact: FormGroup) {
    const data = contact.getRawValue();
    this.dialogService.open(
        SingleContactManageDialogComponent,
        {
          data: data,
          contactFunctionsUI: this.contactFunctionsUI
        },
        { width: '500px' }
      )
      .afterClosed()
      .subscribe(result => {
        if (result) {
          contact.patchValue(result);
          this.emitContacts();
        }
      });
  }

  deleteForm(i: number) {
    // this.contactFormService.deleteContact(i);
    if (this.contacts) {
      this.contacts.controls.splice(i, 1);
    }
    this.emitContacts();
  }

  private initContactFunctionsUI() {
    this.dictionaryService
      .getByLookup(this.CONTACT_FUNCTION_LOOKUP_MODEL)
      .subscribe(result => {
        this.contactFunctionsUI = result.items;
    });
  }

  public getFunctioName(contact) {
    let contactFunction = null;
    this.contactFunctionsUI.forEach(item => {
      if (item.key == contact.get('contact_function').value) {
       contactFunction = item.value;
      }
    });

    return contactFunction;
  }

  emitContacts() {
    if (this.contacts.valid) {
      this.contactsChanged.emit(this.contacts.getRawValue());
    }
  }

  ngOnDestroy() {
    this.contactFormService.emptyContactForm();
    this.contactFormSub.unsubscribe();
  }

  loadCountries() {
    const cloned = this.query.clone();
    cloned.limit = 1000;
    cloned.orderBy = [['name', 'ASC']];
    if (!cloned.where.is_enabled) {
      cloned.where = {is_enabled: true};
    }
    return this.locationService.findAll(cloned)
      .subscribe(result => {
        this.allCountries = result.items;
      })
  }

  getCountryPhoneRegexMask(value: any) {
    if (this.allCountries.length > 0) {
      this.allCountries.filter(country => {
        if (country.id === value) {
          return country.phone_regex_mask;
        }
      });
    }

    return this.phoneRegexMask;
  }
}
