import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FilterBaseComponent} from './filter-base.component';
import {FilterHostDirective} from './filter-host.directive';
import {FilterComponentRegistry} from './filter-component.registry';
import {FilterEntry} from "../../core/filter/filter-entry";
import {FilterService} from "../../core/filter/filter.service";

@Component({
  selector: 'cas-filter-entry',
  template: `
    <ng-template casFilterHost></ng-template>`,
})
export class FilterEntryComponent implements AfterViewInit, OnChanges {
  private componentRef: FilterBaseComponent;
  @Input() value: any;
  @Input() entry: FilterEntry;
  @Input() filterService: FilterService;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private filterComponentRegistry: FilterComponentRegistry) {
  }

  @ViewChild(FilterHostDirective, {static: false}) host: FilterHostDirective;


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadComponent();
    }, 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.componentRef) {
      this.componentRef.value = changes.value;
    }
  }

  private loadComponent(): void {
    const {entry, value, filterService} = this;
    const type = this.filterComponentRegistry.resolve(entry);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(type);
    const viewContainerRef = this.host.viewContainerRef;
    viewContainerRef.clear();

    const tmpRef = viewContainerRef.createComponent(componentFactory);

    const componentRef: FilterBaseComponent = tmpRef.instance as FilterBaseComponent;
    componentRef.entry = entry;
    componentRef.value = value;
    componentRef.filterService = filterService;
    componentRef.change.subscribe((val) => {
      this.change.emit({
        [entry.field]: val
      });
    });
    this.filterComponentRegistry.emit(entry.field, value);
    this.componentRef = componentRef;
  }

}
