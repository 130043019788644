<div style="width:100%; display:flex !important; justify-content: flex-end" #item>
  <button mat-button #confirmButton
    (click)="delete()"
    [disabled]="(confirm && requestInput && userInput !== entityName) || disabled"
    (focusout)="(confirm && !requestInput) && closeConfirm()">{{confirm? 'Confirm': deleteText}}
  </button>

  <div class="confirm-input ca-form"
       [ngStyle]="confirmStageWidth()"
       [ngClass]="{'visible': confirm && requestInput}"
       *ngIf="requestInput"

       >
    <mat-form-field hiDensityInput class="cofirm-delete-input" style="width: 50%;">
      <input matInput
             #confirmInput
             [placeholder]="placeHolder"
             id="confirm-input"
             [(ngModel)]="userInput"
             (focusout)="closeConfirm()"
             type="text">
    </mat-form-field>
  </div>

  <div
    class="confirm-message"
    [ngStyle]="confirmStageWidth()"
    [ngClass]="{'visible': confirm && !requestInput}"
    *ngIf="!requestInput">
  </div>

</div>

