
import {of as observableOf,  Observable } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import * as moment from 'moment';

import { DialogService } from '../../../shared/dialog/dialog.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { OrderServiceAcceptDateGridService } from '../../core/order-service-accept-date-grid.service';
import { INVENTORY_STATUS_ENUM } from '../../../inventory/core/inventory-status.enum';
import { ORDER_SERVICE_STATUS } from '../../../order-service/core/order-service-status.enum';
import { ORDER_STATUS } from '../../../order/core/order-status.enum';
import { OrderServiceService } from '../../../order-service/core/order-service.service';
import { NotesService } from '../../../shared/notes/core/notes.service';
import { Notes } from '../../../shared/notes/core/notes';
import { InventoryService } from '../../../inventory/core/inventory.service';
import { UserService } from '../../../user/core/user.service';
import {flatMap} from "rxjs/internal/operators";

@Component({
  selector: 'app-service-test',
  templateUrl: './service-test.component.html',
  styleUrls: ['./service-test.component.css']
})
export class ServiceTestComponent extends PageManageDialogComponent implements OnInit {
  readonly ORDER_SERVICE_STATUS_ENUM = ORDER_SERVICE_STATUS;
  readonly ORDER_STATUS_ENUM = ORDER_STATUS;
  readonly INVENTORY_STATUS = INVENTORY_STATUS_ENUM;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  columns = [];

  readonly dateFormat = 'MM/DD/YYYY';

  note: string;
  public test_date: Date;
  private saveBuffer: any;

  public orderService;
  public minAllowedDate;

  hasMrcNrc: boolean;
  mrc: number;
  nrc: number;
  service_test_date_history: any;
  isServiceTestChanged: boolean = false;

  NO_MRC_NRC_MSG = 'In order to pass, MRC and NRC has to be specified.';

  readonly TEST_TYPE: any = {
    VENDOR: 'Vendor',
    CUSTOMER: 'Customer'
  };

  constructor(
    public formBuilder: FormBuilder,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<ServiceTestComponent>,
    public orderServiceService: OrderServiceService,
    public inventoryService: InventoryService,
    public notesService: NotesService,
    public orderServiceAcceptDateGridService: OrderServiceAcceptDateGridService,
    public userService: UserService,
  ) {
    super(dialogService, dialogRef);
  }

  ngOnInit() {
    this.getMinDate()
    this.form = this.formBuilder.group({
      note: [null, Validators.minLength(20)],
      note_customer: [null, Validators.minLength(20)],
      customer_test_date: [null],
      test_date: [null]
    });

    this.hasMrcNrc = !!(this.mrc != null && this.nrc != null);

    if (this.service_test_date_history && this.service_test_date_history.length) {
      this.initGrid();
    }
  }

  initGrid() {
    this.columns = this.orderServiceAcceptDateGridService.columns();
    setTimeout(() => {
      this.orderServiceAcceptDateGridService.create(this.grid.instance, {
        height: '250px',
        allowColumnResizing: false
      });
    });
  }

  approve({ value, valid }: { value: any, valid: boolean }, testType) {
    if (testType === this.TEST_TYPE.VENDOR) {
      this.saveBuffer = {
        approved: true,
        type: this.TEST_TYPE.VENDOR,
        test_date: value.test_date ? moment(value.test_date).format(this.dateFormat) : null,
        note: value.note,
      };
    } else if (testType === this.TEST_TYPE.CUSTOMER) {
      this.saveBuffer = {
        approved: true,
        type: this.TEST_TYPE.CUSTOMER,
        note: value.note_customer,
        customer_test_date: value.customer_test_date ? moment(value.customer_test_date).format(this.dateFormat) : null,
      };
    }
    this.save();
  }

  reject({ value, valid }: { value: any, valid: boolean }, testType) {
    if (testType === this.TEST_TYPE.VENDOR) {
      this.saveBuffer = {
        rejected: true,
        type: this.TEST_TYPE.VENDOR,
        test_date: moment(value.test_date).format(this.dateFormat),
        note: value.note,
      };
    } else if (testType === this.TEST_TYPE.CUSTOMER) {
      this.saveBuffer = {
        rejected: true,
        type: this.TEST_TYPE.CUSTOMER,
        note: value.note_customer,
        customer_test_date: moment(value.customer_test_date).format(this.dateFormat),
      };
    }
    this.save();
  }

  save() {
    if (this.saveBuffer.test_date) {
      this.orderService.test_passed = (this.saveBuffer.approved === true);
      this.orderService.service_test_vendor_date = moment(this.saveBuffer.test_date).format(this.dateFormat);
      this.orderService.vendor_test_passed = (this.saveBuffer.approved === true);
      this.orderService.vendor_changed = true;
    }
    if (this.saveBuffer.customer_test_date) {
      this.orderService.service_test_customer_date = moment(this.saveBuffer.customer_test_date).format(this.dateFormat);
      this.orderService.test_passed = (this.saveBuffer.approved === true);
      this.orderService.customer_test_passed = (this.saveBuffer.approved === true);
      this.orderService.vendor_changed = false;
    }

    let status: number = this.ORDER_SERVICE_STATUS_ENUM.SERVICE_INSTALL;

    if (this.orderService.vendor_test_passed && this.orderService.customer_test_passed){
      status = this.ORDER_SERVICE_STATUS_ENUM.TEST_PASSED;
    }else{
      status = this.ORDER_SERVICE_STATUS_ENUM.TEST_FAILED
    }
    this.orderService.state = status;

    this.orderService.note = this.saveBuffer.note;

    this.orderService.service_test_date_history = this.orderService.service_test_date_history || [];

    this
      .generateServiceTestHistoryEntry(this.orderService)
      .subscribe(
        newServiceTestHistoryEntry => {
          this.isServiceTestChanged = true;

          this.orderService.service_test_date_history = this.service_test_date_history && this.service_test_date_history.length
            ? [newServiceTestHistoryEntry, ...this.service_test_date_history]
            : [newServiceTestHistoryEntry]

           this.orderServiceService.update(this.orderService.id, this.orderService)
             .subscribe((ord) => {
                  if (this.saveBuffer.note && this.saveBuffer.note.length > 0) {
                    let msg = this.orderService.test_passed ? 'Service Test - Test Passed' : 'Service Test - Test Failed';
                    let message = `${msg}: ${this.saveBuffer.note}`;
                    this.addNote(message);
                  }
                  this.service_test_date_history = this.orderService.service_test_date_history;
                  this.orderService = this.orderService;
                  this.initGrid();

                  if (this.grid) {
                    this.grid.instance.getScrollable().scrollTo(0);
                  }
             });
          this.form.reset();
        }
      );
  }

  public generateServiceTestHistoryEntry(orderService): Observable<any> {
    const newServiceTestHistoryEntry = {
      date: null,
      pass: orderService.test_passed,
      type: this.saveBuffer.type,
      changed_at: moment.utc(),
      note: this.saveBuffer.note,
      changed_by: ``,
    };

    if (this.saveBuffer.test_date) {
      newServiceTestHistoryEntry.date = moment(orderService.service_test_vendor_date).format(this.dateFormat);
    }

    if (this.saveBuffer.customer_test_date) {
      newServiceTestHistoryEntry.date = moment(orderService.service_test_customer_date).format(this.dateFormat);
    }

    return this
      .userService
      .me()
      .pipe(flatMap(
        user => {
          newServiceTestHistoryEntry.changed_by = user.full_name;

          return observableOf(newServiceTestHistoryEntry);
        }
      ));
  }

  public isPassDisabled(field) {
    let result = !this.hasMrcNrc || !this.form.controls[field].value || !this.form.controls[field].valid;
    return result;
  }

  public isFailDisabled(field, noteField) {
    let result = !this.form.controls[field].value || !this.form.controls[field].valid
      || !this.form.controls[noteField].value || !this.form.controls[noteField].valid;
    return result;
  }

  public addNote(noteMessage) {
    let note = new Notes({
      entity_id: this.orderService.order_id,
      entity_type: 20,
      content: noteMessage
    });

    this.notesService.create(note);
  }

  cancel() {
    if (!this.orderService.service_test_date_history) {
      this.closeDialog(this.orderService)
      return
    }
    // set test dates
    let vendorDateArray = this.orderService.service_test_date_history.filter(item => item.type == 'Vendor')
    let customerDateArray = this.orderService.service_test_date_history.filter(item => item.type == 'Customer')
    const vendorDate = vendorDateArray.length ? vendorDateArray[0].changed_at : null
    const customerDate = customerDateArray.length ? customerDateArray[0].changed_at : null

    if (!this.orderService.service_test_vendor_date && vendorDate)
      this.orderService.service_test_vendor_date = vendorDate.slice(0, 10)
    if (!this.orderService.service_test_customer_date && customerDate)
      this.orderService.service_test_customer_date = customerDate.slice(0, 10)

    if (this.orderService.customer_test_passed && this.orderService.vendor_test_passed)
      this.orderService.state = this.orderService.status = this.ORDER_SERVICE_STATUS_ENUM.TEST_PASSED
    else
      this.orderService.state = this.orderService.status = this.ORDER_SERVICE_STATUS_ENUM.TEST_FAILED

    if(!this.isServiceTestChanged) {
      this.closeDialog(this.orderService)
    } else {
        this.closeDialog(this.orderService)
    }

  }
  getMinDate(){
    this.minAllowedDate = moment(this.orderService.install_date).format('YYYY-MM-DD');
  }
}
