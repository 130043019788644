export const accountFilterContext = {
  filters: [
    {
      field: 'vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 1,
      visible: true
    },
    {
      field: 'entity.name',
      label: 'Entity',
      type: 'lookup',
      lookupProviderName: 'entity',
      index: 2
    },
    {
      field: 'vendor_remit_address.vendor_location_id',
      label: 'Vendor Remit Code',
      type: 'string',
      index: 3
    },
    {
      field: 'account_no',
      label: 'Account',
      type: 'lookup',
      lookupProviderName: 'account',
      index: 4
    },
    {
      field: 'status.id',
      label: 'Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'accountStatus',
      index: 5
    },
    {
      field: 'billing_cycle',
      label: 'Billing Cycle ',
      type: 'number',
      index: 6,
      visible: false,
      max: 30
    },
    {
      field: 'acct_desc',
      label: 'Description',
      type: 'string',
      width: 160,
      index: 7
    },
    {
      field: 'on_hold',
      label: 'On Hold',
      type: 'boolean',
      trueLabel: 'Yes',
      falseLabel: 'No',
      index: 8,
    },
    {
      field: 'billing_freq_id',
      label: 'Billing Frequency',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'billingFrequency',
      index: 9
    },
    {
      field: 'country.alpha_2_code',
      label: 'Country',
      type: 'lookup',
      editor: 'country',
      lookupProviderName: 'country',
      index: 10,
      visible: true
    },
    {
      field: 'currency_obj.currency',
      label: 'Currency',
      type: 'lookup',
      lookupProviderName: 'currency',
      index: 11
    },
    {
      field: 'region_id',
      label: 'Region',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'region',
      index: 12
    },
    {
      field: 'is_tax_exempt',
      label: 'Tax Exempt',
      type: 'boolean',
      trueLabel: 'Yes',
      falseLabel: 'No',
      index: 13,
    },
    {
      field: 'subclient.value',
      label: 'Subclient',
      type: 'lookup',
      lookupProviderName: 'subclient',
      index: 14
    },
  ]
};
