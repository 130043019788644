import {from, Observable} from 'rxjs';

import {IFilterLookupProvider} from '../../core/filter/filter-lookup.provider';
import {AccountService} from './account.service';
import Query from '../../core/query/query';
import {map} from "rxjs/operators";

export class AccountLookupProvider extends IFilterLookupProvider {
  constructor(public accountService: AccountService) {
    super();
  }

  findAll(searchQuery?, options?): Observable<any> {
    let query = new Query();
    if (typeof searchQuery === 'string') {
      query = new Query({where: {acct_level_1: {$ilike: `%${searchQuery}%`}}});
    } else if (searchQuery) {
      query = new Query(searchQuery);
    }

    if(options) {
      query.limit = options.limit ?? query.limit;
      query.offset = options.offset ?? query.offset;
    }

    query.set('is_temporary', false);
    if (query.where.ban) {
      query.where.account_no = query.where.ban;
      delete query.where.ban;
    }
    if (query.where.acct_level_1) {
      query.where.account_no = query.where.acct_level_1;
      delete query.where.acct_level_1;
    }
    query.orderBy = [['account_no', 'ASC']];
    query.scope = 'light';
    return from(this.accountService.findAll(query)).pipe(
      map((result: any) => {
        return {
          items: result.items.map(entry => {
            return entry.account_no;
          }),
          total: result.total
        };
      }));
  }
}
