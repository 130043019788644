<div fxFlex class="ca-form">
  <ca-default-picker hiDensityInput>
    <mat-form-field>
      <mat-select placeholder="Vendor" [(ngModel)]="selectedVendor" #vendorPicker (selectionChange)="vendorChange()">
        <button *ngIf="selectedVendor" (click)="onVendorPickerClear(vendorPicker)" mat-button
                class="clear-option">
          Clear
        </button>
        <mat-option [value]="vendor" *ngFor="let vendor of vendors">
          {{vendor}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ca-default-picker>
</div>
<div fxFlex class="ca-form" style="margin-bottom: 1.85em;">
  <ca-default-picker hiDensityInput>
    <mat-form-field>
      <mat-select placeholder="Grouping" [(ngModel)]="selectedReportType" class="picker-placeholder" (selectionChange)="reportChange()">
        <mat-option [value]="reportType.value" *ngFor="let reportType of reportTypes">
          {{reportType.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ca-default-picker>
</div>
