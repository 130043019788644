import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {SubAccountGridService} from "../../core/invoice-subaccount-grid.service";
import {InvoiceSubaccountFilterService} from "../../core/invoice-subaccount-filter.service";
import {SortingBuilder, SortingService} from "../../../shared/sorting/sorting.service";
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {InvoiceQuery} from "../../core/invoice.query";
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {Select, Store} from "@ngxs/store";
import {QueryBuilder} from "../../../common/query/query.builder";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {InvoiceChargeService} from "../../../charge/core/invoice-charge.service";
import {
  UpdateInvoiceSubAccountsQuery,
  UpdateInvoiceSubAccountsSiderSection
} from "./state/invoice-sub-accounts.actions";
import {Observable, Subject} from "rxjs";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {INVOICE_STATUS_ENUM} from "../../core/invoice-status.enum";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {InvoiceFlowService} from "../../core/invoice-flow.service";
import {HistoryService} from "../../../shared/history/history.service";
import {HistoryComponent} from "../../../shared/history/history.component";
import Query from "../../../core/query/query";
import {GridSettingsComponent} from "../../../core/grid/grid-settings.component";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {UserService} from "../../../user/core/user.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-invoice-sub-accounts',
  templateUrl: './invoice-sub-accounts.component.html',
  styleUrls: ['./invoice-sub-accounts.component.css']
})
export class InvoiceSubAccountsComponent implements OnInit, AfterViewInit, OnDestroy {

  private subAccountColumnsSorting: any[];
  private subAccountSorting: SortingBuilder;
  private gridSettings: any;

  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    HISTORY_SECTION_NAME: 'history',
    DETAILS_SECTION_NAME: 'details',
    NOTES_SECTION_NAME: 'notes',
  };

  invoice: InvoiceFacepage;
  invoiceId: number;
  pagerSize: any;
  pagerSizeString: any;
  subAccountQuery: InvoiceQuery = new InvoiceQuery({
    orderBy: [['id', 'ASC']]
  });
  subAccountSummary: Array<InvoiceFacepage> = [];
  subAccountSummaryColumns: any;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'invoice';
  readonly COMPONENT_NAME = 'invoice-sub-accounts';

  defaultGridPager: any;

  dnpInfo: any = null
  onHoldInfo: any = null
  notesCount = 0;
  invoiceStatusEnum = INVOICE_STATUS_ENUM;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  gridLimit: number = 20;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Output() changeEmitter = new EventEmitter<any>();

  @ViewChild('subAccountSummaryGrid') subAccountSummaryGrid: DxDataGridComponent;
  @ViewChild('panelSide') panelSide: any = '';
  @ViewChild('history') history: HistoryComponent;
  @ViewChild('testNotes') testNotes;
  sider: Sider = new Sider(new SiderSettings([], this.panelSide));

  @Select(state => state.invoice_sub_accounts.activeSiderSection) $activeSiderSection: Observable<any>;
  @Select(state => state.invoice_sub_accounts.query) $query: Observable<Query>;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly queryBuilder: QueryBuilder,
    private readonly chargeService: InvoiceChargeService,
    private readonly sortingService: SortingService,
    private readonly dialogService: DialogService,
    public route: ActivatedRoute,
    public alertService: AlertService,
    public invoiceService: InvoiceFacepageService,
    public subaccountGridService: SubAccountGridService,
    public subaccountFilterService: InvoiceSubaccountFilterService,
    public settingsService: UserSettingsService,
    public invoiceFlowService: InvoiceFlowService,
    public historyService: HistoryService,
    public userService: UserService
  ) {
    this.subAccountSorting = this.sortingService.builder();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.invoiceService.invoiceChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(invoice => {
      this.invoice = invoice;
      this.history.refreshList();
    })
  }

  ngOnInit(): void {
    this.invoice = this.getInvoice();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => this.onQueryParamsChange(params));
    this.$query.pipe(takeUntil(this.destroy$)).subscribe((query) => this.onQueryChange(query));

    this.getOnHoldInfo()
    this.testNotes && this.testNotes.emitCount();
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();

    setTimeout(() => {
      //** Charges grid */
      this.subaccountGridService.create(this.subAccountSummaryGrid.instance, {
        noDataText: this.subaccountGridService.noDataMessage,
        selection: {
          mode: 'multiple',
          selectAllMode: 'page'
        }
      });
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onFilterToggle() {
    // this.sider?.toggle(this.SECTIONS.FILTER_SECTION_NAME)
    this.toggleSider(this.SECTIONS.FILTER_SECTION_NAME)
  }

  onHistoryToggle() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME)
  }

  onNoteToggle() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME)
  }

  private getInvoice() {
    return this.activatedRoute.parent.snapshot.data.invoice;
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  initSubAccountColumns(settings) {
    this.subAccountColumnsSorting = settings ? settings.sorting : [];
    this.subAccountSummaryColumns = this.subaccountGridService.getColumns(settings && settings.columns ? settings.columns : []);

    if (this.subAccountColumnsSorting && this.subAccountColumnsSorting.length) {
      this.subAccountQuery.orderBy = this.subaccountGridService.getOrderBy(this.subAccountColumnsSorting);
    }

    if (this.subAccountSummaryGrid && this.subAccountSummaryGrid.instance) {
      this.subAccountSummaryGrid.instance.option(
        'columns',
        this.subAccountSummaryColumns
      );
    }

    this.defaultGridPager = settings ? settings.gridPager : 20;
    this.subAccountQuery.limit = this.defaultGridPager;

    if (this.invoice.invoice_id) {
      this.loadSubAccountSummary(this.invoice.invoice_id);
    }
  }

  loadSubAccountSummary(invoiceId: number, subAccountQuery?: any) {
    this.subAccountQuery = subAccountQuery || this.subAccountQuery;

    this.invoiceService.findAll(this.subAccountQuery).subscribe(
      result => {
        this.subAccountSummary = result.items;
        this.subAccountQuery.total = result.total;
      },
      () => {
        this.alertService.error('', this.messages.get('SUBACCOUNT_LOAD_ERROR'));
      }
    );
  }

  private onQueryParamsChange(params: Params) {
    // to make sure query is empty when no params in the url
    if (Object.keys(params).length === 0) {
      this.subAccountQuery = new InvoiceQuery({
        invoice_id: this.invoice.invoice_id,
        acct_level: 2
      })
    }
    const query = this.queryBuilder.build(this.subAccountQuery, null, this.COMPONENT_NAME).getQuery()
    this.store.dispatch([
      new UpdateInvoiceSubAccountsQuery({query, key: params.key})
    ]);
  }

  private onQueryChange(query: Query) {
    this.subAccountQuery = new InvoiceQuery(query);
    this.subAccountQuery.where = {
      ...this.subAccountQuery.where,
      invoice_id: this.invoice.invoice_id,
      acct_level: 2
    }
    this.prepareList()
  }

  public prepareList() {
    this.subaccountGridService.loadSettings()
      .subscribe(settings => {
        this.initSubAccountColumns(settings)
      })
  }

  public filterData(query: InvoiceQuery) {
    // delete necessary to make filter and clear hash equal if no filter params are present
    delete query.where['invoice_id']
    delete query.where['acct_level']
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query, route: this.route, queryBuilder: InvoiceQuery, queryToSet: 'subAccountQuery'
    })
  }

  public clearFilter() {
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query: new InvoiceQuery(), route: this.route, queryBuilder: InvoiceQuery, queryToSet: 'subAccountQuery'
    })
  }

  public onSubAccountCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.subAccountSorting.apply(event, this.subAccountQuery);
      this.loadSubAccountSummary(this.invoice.invoice_id);
    }
  }

  onPageSubAccountChange(invoice) {
    this.loadSubAccountSummary(invoice);
  }

  onOpenGridSettingsRequested() {
    this.dialogService
      .open(GridSettingsComponent, {
        service: this.subaccountGridService,
        settings: this.gridSettings,
        sliderMinimum: 1
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this.initSubAccountColumns(settings);
        }
      });
  }

  resetDataPager() {
    this.defaultGridPager = 20;
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();

    this.store.dispatch([
      new UpdateInvoiceSubAccountsSiderSection(activeSection.name)
    ]);
  }

  async getOnHoldInfo() {
    if (!this.invoice.account.on_hold) {
      this.onHoldInfo = null;
      return;
    }
    let accountHistory = await this.historyService.findAllForEntity('account', this.invoice.account.id).toPromise()
    let onHoldFound = accountHistory.filter(account => (account.meta_data.on_hold && account.meta_data.on_hold.value === true))
    this.onHoldInfo = (onHoldFound && onHoldFound.length > 0) ? onHoldFound[0] : null
  }

  getDNPInfo(notes: any, forceEmpty: boolean = false) {
    const dnpStatus = this.invoiceFlowService.LOCALS.DO_NOT_PROCESS

    if (forceEmpty || this.invoice.header.status_code !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
      this.dnpInfo = null
    } else {
      const dnpNotes = notes.filter(note => note.content.startsWith(dnpStatus));
      this.dnpInfo = (dnpNotes.length) ? dnpNotes[0] : {};
      this.dnpInfo.status = dnpStatus;
      this.dnpInfo.message = this.dnpInfo.content ? this.dnpInfo.content.substring(dnpStatus.length + 2) : this.messages.get('DNP_FALLBACK_MESSAGE');
    }
  }

  /* Notes count changed */
  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }


  onNoteCreated() {}

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/invoice']);
    }
  }

  onDownloadCSVRequested() {
    const {
      subaccountGridService,
      invoice: {sp_inv_num},
      invoiceService,
      subAccountQuery,
    } = this;

    subaccountGridService.csvMap().subscribe(fieldsSubaccount => {
      invoiceService.exportToCSV(`${sp_inv_num}_subaccount`, {
        fields: fieldsSubaccount,
        query: subAccountQuery
      });
    });
  }
}
