<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{formTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">
  <form class="ca-form" [formGroup]="form">
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column" fxLayoutGap="24px">
      <div class="flex-item" fxFlex>
        <mat-form-field caInput>
          <input matInput placeholder="Name" formControlName="name" required>
           <mat-hint *ngIf="form.hasError('required', ['name']) && form.controls.name.touched" class="error-class">
            {{VALIDATION.REQUIRED_ERROR}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>

  <ng-container *ngIf="selectedRows.length">

    <span style="font-size: 10px; opacity: .5">Selected Tags</span>
    <div style="height: 200px; overflow: hidden;">
      <div class="tag-items-container ca-default-border">
        <ng-container *ngFor="let tag of selectedRows">
          <div class="tag-item ca-default-border-bottom" (click)="selectTag(tag)" [ngClass]="{'selected': tag.isSelected}">
            {{ tag.name }}
          </div>
        </ng-container>
      </div>
    </div>


  </ng-container>


</div>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
      <ca-confirm-delete-button #genericButton *ngIf="isUpdate && tag.entities?.length == 0" [confirmText]="'Delete?'" (deleteRequested)="deleteTag($event)"></ca-confirm-delete-button>
  </div>
  <button #cancelButton mat-button type="submit" (click)="cancel()">
      Cancel
  </button>
  <button #saveButton *ngIf="isUpdate || !selectedRows.length" mat-button type="submit" (click)="onSubmit(form)" [disabled]="form?.invalid">
    Save
  </button>

  <button #genericButton *ngIf="!isUpdate && selectedRows.length" mat-button type="submit" (click)="onSubmit(form)" [disabled]="form?.invalid">
    Merge
  </button>
</mat-dialog-actions>
