import {LookupModel} from "../../dictionary/core/lookup.model";
export class DisputeStatusLookup {
  FILED: LookupModel;
  CLOSED_LOST: LookupModel;
  IN_PROGRESS: LookupModel;
  CLOSED_WON: LookupModel;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
