<div class="notes-view notes-list-view" *ngIf="notes && notes.length > 0">
  <mat-accordion id="accordion" [multi]="false">

    <mat-expansion-panel class="ca-card ca-card-note ca-card-note-list-group">
      <!-- Expandable header -->
      <mat-expansion-panel-header class="ca-expansion-panel-header-title" [expandedHeight]="'48px'" [collapsedHeight]="'48px'">
        <span class="note-group-title">{{ entityName }}&nbsp;({{ notes.length }})</span>
      </mat-expansion-panel-header>

      <!-- Notes list -->
      <mat-card class="note-card notes-list-card" *ngFor="let note of notes" [ngClass]="{'selected': note.id == selectedId}">
        <hr class="notes-horizontal-divider">
        <mat-card-header>
          <div class="avatar avatar-md note-avatar" (click)="showUserModal(note.user)">
            <img *ngIf="note?.user?.avatar" [src]="note.user?.avatar" alt="User Image">
            <div class="replacement" *ngIf="!note?.user?.avatar">{{ getUserInitials(note) }}</div>
          </div>
          <div class="note-card-title" fxLayout="column">
            <div fxFlex class="note-card-user" matTooltip="">{{ note?.user?.full_name }}</div>
            <div fxFlex class="note-card-date muted">{{ note?.created_at | caDateTime }}</div>
          </div>
        </mat-card-header>
        <mat-card-content>
          <div class="note-card-body">
            <p [innerHtml]="sanitizeNoteContent(note?.content | newline)"></p>
          </div>
          <div class="note-card-comments" *ngFor="let child of note.notes">
            <div class="note-card-header" fxLayout="row">
              <div fxItem class="avatar avatar-md note-avatar" (click)="showUserModal(child.user)">
                <img *ngIf="child?.user?.avatar" [src]="child.user?.avatar" alt="User Image">
                <div class="replacement" *ngIf="!child?.user?.avatar">{{ getUserInitials(child) }}</div>
              </div>
              <div fxItem class="note-card-title" fxLayout="column">
                <div fxFlex class="note-card-user">{{ child?.user?.full_name }}</div>
                <div fxFlex class="note-card-date muted">{{ child?.created_at | caDateTime  }}</div>
              </div>
            </div>
            <div class="note-card-body">
              <p [innerHtml]="sanitizeNoteContent(child?.content | newline)"></p>
            </div>
          </div>
        </mat-card-content>
        <form name="replyForm" class="note-card-reply flex-container ca-form"
              *ngIf="enableReply && replyMode && selectedNote.id == note.id">
          <mat-form-field fxFlex caInput>
          <textarea matInput placeholder="Add a reply..." rows="3"
                    name="reply" type="text"
                    [(ngModel)]="note.reply"></textarea>
          </mat-form-field>
        </form>
        <mat-card-footer class="note-action-container" *ngIf="enableReply">
          <div fxLayout="row" class="note-action flex-container">
            <div class="example-fill-remaining-space"></div>
            <button mat-button (click)="cancel(note)" *ngIf="replyMode && selectedNote.id == note.id" class="note-button">
              Cancel
            </button>
            <button mat-button (click)="reply(note)" [disabled]="!note.reply && replyMode" class="note-button">
              Reply
            </button>
          </div>
        </mat-card-footer>
      </mat-card>
    </mat-expansion-panel>

  </mat-accordion>
</div>
