<app-inventory-details-toolbar
  [inventory]="inventory"
  [isAddPricingHidden]="true"
  [isAddPricingDisabled]="true"
  [isCreateOrderHidden]="false"
  [isCreateOrderDisabled]="true"
  [isAddGLHidden]="false"
  [isAddGLDisabled]="true"
  [isEditInventoryDisabled]="false"
  [isDocumentAction]="false"
  [isPricingDocumentAction]="false"
  [isFilterDisabled]="true"
  (editInventoryRequested)="edit()"
  (toggleNotesRequested)="toggleNotes()"
  (toggleHistoryRequested)="toggleHistory()"
  [isNotesOn]="($activeSiderSection | async) === SECTIONS.NOTES_SECTION_NAME"
  [isHistoryOn]="($activeSiderSection | async) === SECTIONS.HISTORY_SECTION_NAME"
  [notesCount]="notesCount"
  (backRequested)="back()"
  >
</app-inventory-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-inventory-details-tabs [activeTabIndex]="0"></app-inventory-details-tabs>
  <div class="flex-container ca-card custom-mat-card-container-large" fxLayout="row">
        <mat-card fxFlex caCard>
          <mat-card-header>
            Info
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Vendor</label>
                <p>
                  {{ inventory?.vendor?.name || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>BAN</label>
                <p>
                  {{ inventory?.ban || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Status</label>
                <p>
                  {{ inventory?.inventory_status?.value || 'N/A'}}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Customer</label>
                <p>
                  {{ inventory?.customer?.company || 'N/A'}}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Install Date</label>
                <p>
                  {{ (inventory?.install_date | caDate) || 'N/A'}}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Disconnect Date</label>
                <p>
                  {{ (inventory?.disc_date | caDate) || 'N/A'}}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>SP Svc ID</label>
                <p>
                  {{ inventory?.sp_ckt_id || 'N/A'}}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Int Svc ID</label>
                <p>
                  {{ inventory?.internal_id || 'N/A'}}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Customer Service ID</label>
                <div fxLayout fxLayoutAlign="flex-start center">
                  <p [ngClass]="{'ca-field-with-icon': inventory?.customer_circuit_id}">
                    {{ inventory?.customer_circuit_id ? inventory?.customer_inventory?.customer_circuit_id : 'N/A'}}
                  </p>
                </div>
              </div>

              <div fxFlex class="entry-list-item">
                  <label>Billing Aliases</label>
                  <p *ngIf="!inventory?.billing_aliases.length">N/A</p>
                  <ng-container *ngIf="inventory?.billing_aliases">
                    <p *ngFor="let billingAlias of inventory?.billing_aliases | slice:0:3; let i = index">
                      {{ billingAlias.alias }}  <span *ngIf="inventory?.billing_aliases.length > 3 && i === 2" matTooltip={{billingAliasesTooltip}} matTooltipClass="tooltip-break-line">...</span>
                    </p>
                  </ng-container>
                </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Subclient</label>
                <p>
                  {{ inventory?.ban_object?.subclient?.value || 'N/A'}}
                </p>
                </div>

              <div fxFlex class="entry-list-item">
                <label>Stop Billing Date</label>
                <p>
                  {{ (stopBillingDate | caDate) || 'N/A'}}
                </p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card fxFlex class="flex-item" caCard>
          <mat-card-header>
            Financial / Contract
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Agreement Type</label>

                <div fxLayout fxLayoutAlign="flex-start center">
                  <p *ngIf="inventory?.contract_type_id; else noContract" class="inventory-contract-names">
                    <span>{{inventory.contract_type.value}}</span>
                 </p>
                  <ng-template #noContract>
                    <p>N/A</p>
                  </ng-template>
                </div>

              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label *ngIf="inventory?.contract_type_id === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT" >Contract / Schedule / Rate Line</label>
                <label *ngIf="inventory?.contract_type_id === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER" >Svc Order / Svc Line</label>

                <div *ngIf="inventory?.contract_type_id === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT" class="inventory-svc-order-id-description">
                  <a class="a-link-tag link-style" *ngIf="inventory?.inventory_schedule_rates[0]?.contract?.name; else noContractName"
                     [routerLink]="['/contract', inventory?.inventory_schedule_rates[0]?.contract_id, 'show', 'summary']">
                    {{inventory?.inventory_schedule_rates[0]?.contract?.name || 'N/A'}}</a>
                    <ng-template #noContractName>
                      <span>N/A</span>
                    </ng-template>
                  <span *ngIf="inventory?.inventory_schedule_rates[0]?.schedule?.schedule_name"> / </span>
                  <a class="a-link-tag link-style" *ngIf="inventory?.inventory_schedule_rates[0]?.schedule?.schedule_name"
                     [routerLink]="['/contract', inventory?.inventory_schedule_rates[0]?.contract_id, 'schedule', inventory?.inventory_schedule_rates[0]?.contract_schedule_id, 'show', 'summary']">
                    {{inventory?.inventory_schedule_rates[0]?.schedule?.schedule_name}}</a>
                  <span *ngIf="ratesLineNo.length">{{' / ' + ratesLineNo}}</span>
                </div>

                <div *ngIf="inventory?.contract_type_id === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER" class="inventory-svc-order-id-description">
                  <a class="a-link-tag link-style" *ngIf="inventory?.svc_header?.id; else noSvcHeader"
                     [routerLink]="['/contract', inventory?.svc_header?.id, 'order', 'show', 'summary']">
                    {{inventory?.svc_header?.vendor_so_number}}</a>
                    <ng-template #noSvcHeader>
                      <p>N/A</p>
                    </ng-template>
                  <span *ngIf="servicesLineNo.length"> / {{this.inventory.svc_order_service.length > 1 ? 'Svc lines' : 'Svc line'}}</span>
                  <a class="a-link-tag link-style" *ngIf="servicesLineNo.length"
                     [routerLink]="['/contract', inventory?.svc_header?.id, 'order', 'show', 'services']">
                    {{servicesLineNo}}</a>
                </div>

              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Eff Date</label>
                <p>
                  {{ (inventory?.eff_date | caDate) || 'N/A'}}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Exp Date</label>
                <p>
                  {{ (inventory?.exp_date | caDate) || 'N/A'}}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>MRC</label>
                <p>
                  {{ (inventory?.est_mrc | caCurrency: inventory?.currency_obj?.currency) || 'N/A'}}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>NRC</label>
                <p>
                  {{ (inventory?.est_nrc | caCurrency: inventory?.currency_obj?.currency) || 'N/A'}}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Term</label>
                <p>
                  {{ inventory?.term || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Currency</label>
                <p>
                  {{ inventory?.svc_service?.currency?.currency || inventory?.currency_obj?.currency || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Customer Provided Access</label>
                <p>
                  {{ inventory?.is_customer_provided_access ? 'Yes' : 'No'}}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list" *permission="{'action':'Modify', 'key':'audits'}">
              <div fxFlex class="entry-list-item" >
                <p class="switch-button status-badge entry-list-item audit-button-badge" [ngClass]="{ 'btn-enabled' : (inventory?.enable_audit_run == AUDIT_RUN_STATUS_ENUM.ACTIVE_AUDIT_RUN),
                              'btn-disabled' : (inventory?.enable_audit_run == AUDIT_RUN_STATUS_ENUM.INACTIVE_AUDIT_RUN) }">
                  {{ inventory?.enable_audit_run ? "Audit Enabled": "Audit Disabled"}}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card fxFlex class="flex-item" caCard>
          <mat-card-header>
            Service Configuration
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Topology</label>
                <p>
                  {{ inventory?.topology?.value || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Service Bandwidth</label>
                <p>
                  {{ inventory?.circuit_bandwidth?.value || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Port Bandwidth</label>
                <p>
                  {{ inventory?.bandwidth_max?.value || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Svc Type / Technology</label>
                <p>
                  {{ inventory?.technology?.value || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">

              <div fxFlex class="entry-list-item">
                <label>Service Type</label>
                <p>
                  {{ inventory?.inventory_ckt_type?.value || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Usage</label>
                <p>
                  {{ inventory?.inventory_ckt_usage?.value || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Bandwidth Model</label>
                <p>
                  {{ inventory?.inventory_bw_model?.value || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Protection</label>
                <p>
                  {{ inventory?.inventory_protected?.value || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Primary/Failover</label>
                <p>
                  {{ inventory?.inventory_prim_failover?.value || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Handoff type</label>
                <p>
                  {{ inventory?.inventory_handoff_type?.value || 'N/A'}}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>VLAN ID</label>
                <p>
                  {{ inventory?.vlan_id || 'N/A' }}
                </p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

  <mat-sidenav #panelSide
               [mode]="'side'"
               position="end"
               [opened]="false">
    <ca-history
      #history
      [entityId]="inventory?.id"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
      [entityType]="'inventory'">
    </ca-history>

    <ca-notes
      [entityId]="inventory?.id"
      [entityType]="SYSTEM_MODULE.INVENTORY"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
      (countChanged)="onNotesCountChanged($event)">
    </ca-notes>
  </mat-sidenav>
</mat-sidenav-container>
