<div style="width: 100%;height: 100%;" fxFlex class="no-overflow flex-container ca-card">
    <div class="ca-empty-state-container" *ngIf="!invoiceTrendData.length">
      <p class="grid-message">
        No data
      </p>
    </div>

  <dx-chart
    fxFlex fxFlexFill
    id="chart"
    *ngIf="invoiceTrendData.length"
    #chartComponent
    [style.height]="height"
    [dataSource]="invoiceTrendData"
    [commonSeriesSettings]="chartOptions.commonSeriesSettings"
    [series]="chartOptions.series"
    (onSeriesClick)="onSeriesClick()"
    [palette]="chartOptions.palette"
    [argumentAxis]="chartOptions.argumentAxis"
    [valueAxis]="chartOptions.valueAxis"
    [legend]="chartOptions.legend"
    [tooltip]="chartOptions.tooltip">
  </dx-chart>
</div>
