export const glRuleExecutionFlatFilterContext = {
  filters: [
    {
      field: 'charge_coded',
      label: 'Coded Status',
      editor: 'booleanSelect',
      trueLabel: 'Coded',
      falseLabel: 'Uncoded',
      /*defaultValue: ['true'],*/
      index: 1
    },
    {
      field: 'acct_level_2',
      label: 'Subaccount',
      type: 'string',
      index: 2
    },
    {
      field: 'chg_desc_1',
      label: 'Charge Description 1',
      type: 'string',
      index: 3
    },
    {
      field: 'chg_desc_2',
      label: 'Charge Description 2',
      type: 'string',
      index: 4
    },
    {
      field: 'chg_amt',
      label: 'Charge Amount',
      type: 'negativeNumber',
      index: 5
    },
    {
      field: 'sp_serv_id',
      label: 'Service ID',
      type: 'string',
      index: 8
    },
    {
      field: 'chg_class',
      label: 'Charge Type',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'chargeType',
      index: 6
    },
    {
      field: 'full_string_formatted',
      label: 'GL String  ',
      type: 'string',
      index: 7
    },
    {
      field: 'proc_typ',
      label: 'GL Rule ',
      type: 'string',
      index: 8
    },
    {
      field: 'charge_id',
      label: 'ID',
      type: 'number',
      index: 9,
      matchCase: false,
      exactMatch: false
    },
    {
      field: 'vendor_name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      fieldToFilterWith: 'vendor.name',
      index: 10,
    },
    {
      field: 'acct_level_1',
      label: 'Account',
      type: 'lookup',
      lookupProviderName: 'account',
      fieldToFilterWith: 'account_no',
      index: 11
    },
    {
      field: 'sp_inv_num',
      label: 'Invoice Number',
      type: 'string',
      index: 12
    },
    {
      field: 'inv_date',
      label: 'Invoice Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 13
    },
    {
      field: 'chg_code_1',
      label: 'Charge Code1',
      type: 'string',
      index: 14
    },
    {
      field: 'chg_code_2',
      label: 'Charge Code2',
      type: 'string',
      index: 15
    },
    {
      field: 'chg_qty1_billed',
      label: 'Chg Qty',
      type: 'number',
      index: 16
    },
    {
      field: 'chg_rate',
      label: 'Charge Rate',
      type: 'number',
      index: 17
    },
    {
      field: 'svc_establish_date',
      label: 'Install Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 28
    },
    {
      field: 'beg_chg_date',
      label: 'Beg Charge Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 19
    },
    {
      field: 'end_chg_date',
      label: 'End Charge Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 20
    },
    {
      label: 'Info Only Ind',
      field: 'info_only_ind',
      type: 'boolean',
      index: 21
    },
    {
      label: 'Facility Bandwidth',
      field: 'fac_bw',
      type: 'number',
      index: 22
    },
    {
      label: 'Call Type',
      field: 'call_type',
      type: 'string',
      index: 23
    },
    {
      label: 'Dir Ind',
      field: 'dir_ind',
      type: 'string',
      index: 24
    },
    {
      label: 'Jurisdiction',
      field: 'jur',
      type: 'string',
      index: 25
    },
    {
      label: 'Chg Qty Type',
      field: 'chg_qty1_type',
      type: 'string',
      index: 26
    },
    {
      label: 'Chg Qty Used',
      field: 'chg_qty1_used',
      type: 'number',
      index: 27
    },
    {
      field: 'chg_qty1_allowed',
      label: 'Charge Qty Allowed',
      type: 'string',
      index: 28,
    },
    {
      field: 'gl_apportion_pct',
      label: 'Apportion %',
      type: 'negativeNumber',
      index: 29,
    },
    {
      field: 'gl_chg_amt',
      label: 'GL Amount',
      type: 'negativeNumber',
      index: 30,
    },
  ]
};

