import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class OrderGridService extends GridService {


  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.orders.order-grid", userSettings);
  }

  defaultColumns = [
    {
      dataField: 'full_order_id',
      caption: 'Order No',
      alignment: 'left',
      width: 130
    },
    {
      caption: 'Vendor',
      dataField: 'vendor.name',
      width: 180
    },
    {
      caption: 'Type',
      dataField: 'type.value',
      width: 120
    },
    {
      caption: 'Status',
      dataField: 'status.value',
      width: 180
    },
    {
      caption: 'Processor',
      dataField: 'processor.full_name',
      width: 160,
      visible: false
    },
    {
      caption: 'Requester-Initiator',
      dataField: 'requesters',
      width: 180,
      visible: false,
      allowSorting: false
    },
    {
      caption: 'Approver',
      dataField: 'approver.full_name',
      width: 160,
      visible: false
    },
    {
      caption: 'Project',
      dataField: 'project.name',
      width: 180,
      visible: false
    },
    {
      caption: 'Sent Date',
      cellTemplate: "sentDate",
      dataField: 'send_date',
      dataType: 'date',
      format: "dateTemplate",
      alignment: 'left',
      width: 130
    },
    {
      caption: 'Create Date',
      dataField: 'created_at',
      cellTemplate: "createDate",
      dataType: 'datetime',
      format: "dateTemplate",
      alignment: 'left',
      width: 130
    },
    {
      caption: 'Request Date',
      dataField: 'request_date',
      cellTemplate: "requestDate",
      dataType: 'date',
      format: "dateTemplate",
      alignment: 'left',
      width: 130
    },
    {
      dataField: 'services',
      cellTemplate: "serviceCount",
      caption: 'Service Count',
      width: 100,
      allowSorting: false
    }
  ];
}
