import {Action, State, StateContext, Store} from "@ngxs/store";

import {
  LoadSingleInventory,
  UpdateInventoryDetailActiveSiderSection,
  // UpdateInventoryDetailActiveTab,
  UpdateInventoryDetailsFilter
} from './inventory-details.actions';
import {Injectable} from "@angular/core";
import {InventoryService} from "../../core/inventory.service";

@State<any>({
  name: 'inventory_details',
  defaults: {
    activeTab: 'summary'
  },
})
@Injectable()
export class InventoryDetailsState {

  constructor(public store: Store,
              private inventoryService: InventoryService) {
  }

  @Action(LoadSingleInventory)
  loadSingleInventory(ctx: StateContext<any>, action: any): any {
    return this.inventoryService.findById(action.id)
      .toPromise()
      .then((inventory) => ctx.patchState({inventory}));
  }

  // @Action(UpdateInventoryDetailActiveTab)
  // updateInventoryDetailActiveTab(ctx: StateContext<any>, action: any): void {
  //   ctx.patchState({
  //     activeTab: action.key || undefined
  //   })
  // }

  @Action(UpdateInventoryDetailActiveSiderSection)
  updateInventoryDetailActiveSiderSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }

  @Action(UpdateInventoryDetailsFilter)
  updateInventoryDetailsFilter(ctx: StateContext<any>, {options}): void {
    ctx.patchState({
      filter: options
    })
  }
}
