import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class ShortDisputeGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.cost.dispute-grid", userSettings);
  }

  defaultColumns = [{
    caption: 'Dispute ID',
    dataField: 'dispute_id',
    width: 150
  },
  {
    caption: 'Dispute Status',
    dataField: 'status_obj.value',
    width: 140
  },
  {
    caption: 'Vendor',
    dataField: 'vendor.name',
    width: 130
  },
  {
    caption: 'Total Dispute',
    dataField: 'disputed_amount',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 130
  }];

}
