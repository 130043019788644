import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { pathOr } from "ramda";
import { InvoiceFacepage } from "../../core/invoice-facepage";

@Component({
  selector: "app-invoice-sneak-peek",
  templateUrl: "./invoice-sneak-peek.component.html",
})
export class InvoiceSneakPeekComponent implements OnChanges {
  @Input() invoice: InvoiceFacepage;
  @Output() closeRequested = new EventEmitter();

  id: number;

  constructor() {}

  closeSide() {
    this.closeRequested.emit();
  }

  ngOnChanges(): void {
    if (this.invoice) {
      this.id = this.invoice.id;
    }
  }

  public getVendorEntityName(invoice: InvoiceFacepage): string {
    return pathOr("N/A", ["account", "entity", "name"])(invoice);
  }
}
