import { Pipe, PipeTransform, Injectable } from '@angular/core';
import * as moment from 'moment';

import { isOfTypeDateWithoutTime } from './is-date-without-time';

@Injectable()
@Pipe({
  name: 'datetime'
})
export class DateTimePipe implements PipeTransform {
  constructor() { }

  transform(value: any): any {
    if (!value) {
      return value;
    }

    const dateString = value.toJSON ? value.toJSON() : value;
    const isWithoutTime = isOfTypeDateWithoutTime(dateString);
    const pattern = isWithoutTime ? 'MM/DD/YYYY' : 'MM/DD/YYYY hh:mm:ss a';
    const momentDate = isWithoutTime ? moment.utc(value) : moment(value);

    return momentDate.format(pattern);
  }
}
