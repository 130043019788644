import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { DialogService } from '../../../shared/dialog/dialog.service';
import * as moment from 'moment';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {OrderService} from "../../core/order.service";

@Component({
  selector: 'app-order-sent-to-vendor',
  templateUrl: './order-sent-to-vendor.component.html',
  providers: [OrderService]
})
export class OrderSentToVendor extends PageManageDialogComponent implements OnInit {

  public send_date: Date;
  public order ;
  public minAllowedDate ;

  constructor(
    public formBuilder: FormBuilder,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<OrderSentToVendor>,
    public orderService: OrderService
  ) {
    super(dialogService, dialogRef);
  }

  ngOnInit() {
    this.getMinDate()
    this.form = this.formBuilder.group({
      send_date: [this.send_date ? new Date(this.send_date) : null, Validators.required]
    });
  }

  approve({value, valid}: {value: any, valid: boolean}) {
    this.dialogRef.close({
      approved: true,
      send_date: value.send_date
    });
  }

  cancel() {
    this.orderService.cancelEdit();
    this.closeDialog();
  }

  getMinDate(){
    this.minAllowedDate = moment(this.order.approve_date).format('YYYY-MM-DD');
  }
}

