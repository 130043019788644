<app-contract-schedule-toolbar
    [isAttachmentsTab]="true"
    [documentNotSelected]="!selection"
    (deleteDocumentRequested)="delete()"
    (uploadDocumentRequested)="upload()"
    (downloadDocumentRequested)="download()"
    [isFilterDisabled]="true"
    [notesCount]="notesCount"
    [isNotesOn] = "this.sider.getActiveSection()?.name === this.SECTIONS.NOTES_SECTION_NAME"
    (toggleNotesRequested)="toggleNotes()"
></app-contract-schedule-toolbar>
<mat-sidenav-container class="app-content app-content-list tabbed-content dx-nodata-absolute">
    <app-contract-schedule-tabs [activeTabIndex]="2" [schedule]="schedule"></app-contract-schedule-tabs>
    <div class="tabs-body grid-container grid-with-toolbar"
         style="top: -2px; z-index: -1; overflow-y: hidden;">
      <app-contracts-schedule-document-manager #documentsManager *ngIf="DOCUMENT_INFO.SUBFOLDER"
                                      [folder]="DOCUMENT_INFO.FOLDER" [subfolder]="DOCUMENT_INFO.SUBFOLDER" [parentType]="DOCUMENT_INFO.PARENT_TYPE"
                                      [entityType]="DOCUMENT_INFO.ENTITY_TYPE" [entity]="schedule" [contractDocument]="schedule?.document"
                                      (docAdd)="history?.refreshList()" (docDelete)="history?.refreshList()" (docSelected)="docSelected($event)">
      </app-contracts-schedule-document-manager>
    </div>
    <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false" (close-start)="panelClose()">
    <ca-notes [entityId]="schedule.id" [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
              [entityType]="SYSTEM_MODULE.CONTRACT" (countChanged)="onNotesCountChanged($event)"></ca-notes>
  </mat-sidenav>
</mat-sidenav-container>