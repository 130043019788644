import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';
import {
  UpdateInvoiceGLChargeQuery, UpdateInvoiceGLChargeSiderSection,
} from './invoice-gl-charge.actions';
import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";

const INVOICE_GL_CHARGE_STATE_TOKEN = new StateToken<InvoiceGLChargeStateModel>('invoice_gl_charge');

export interface InvoiceGLChargeStateModel extends QueryableStateModel<any> {

}

@State<InvoiceGLChargeStateModel>({
  name: INVOICE_GL_CHARGE_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InvoiceGlChargeState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  @Action(UpdateInvoiceGLChargeQuery)
  // @ts-ignore
  updateInvoiceGLChargeQuery(ctx: StateContext<InvoiceGLChargeStateModel>, {options}): void {
    return this.updateQuery(ctx, {options});
  }

  @Action(UpdateInvoiceGLChargeSiderSection)
  // @ts-ignore
  updateInvoiceGLChargeSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
