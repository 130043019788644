import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class RateContractScheduleInventoryGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.contract.rate-contract-schedule-inventory-grid", userSettings);
  }

  defaultColumns = [
    {
      caption: "",
      fixed: true,
      width: 40,
      allowSorting: false,
      allowResizing: false,
    },
    {
      caption: 'Line Number',
      dataField: 'line_number',
      width: 100
    },
    {
      caption: 'Rate Code',
      dataField: 'rate_code',
      width: 160
    },
    {
      caption: 'Charge Desc',
      dataField: 'svc_chg_desc',
      width: 160
    },
    {
      caption: 'Rate Category',
      dataField: 'rate_category_obj.value',
      width: 160
    },
    {
      caption: 'Rate Qty',
      dataField: 'rate_qty',
      width: 100
    },
    {
      caption: 'Rate Qty Type',
      dataField: 'rate_qty_type',
      width: 160
    },
    {
      caption: 'MRC',
      dataField: 'mrc',
      dataType: 'currency',
      cellTemplate: 'scheduleRateCurrencyTemplate',
      width: 100
    },
    {
      caption: 'NRC',
      dataField: 'nrc',
      dataType: 'currency',
      cellTemplate: 'scheduleRateCurrencyTemplate',
      width: 100
    },
    {
      caption: 'Country',
      dataField: 'country.alpha_2_code',
      width: 100
    },
    {
      caption: 'Rate Term Qty',
      dataField: 'rate_term_qty',
      width: 140
    }
    ];

}
