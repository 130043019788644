import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';
import {UpdateInvoiceChargesQuery,} from './invoice-charges.actions';

import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";
import {UpdateActiveSiderSection} from "./invoice-charges.actions";

const INVOICE_CHARGES_STATE_TOKEN = new StateToken<InvoiceChargesStateModel>('invoice_charges');

export interface InvoiceChargesStateModel extends QueryableStateModel<any> {

}

@State<InvoiceChargesStateModel>({
  name: INVOICE_CHARGES_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InvoiceChargesState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  @Action(UpdateInvoiceChargesQuery)
  // @ts-ignore
  updateInvoiceChargesQuery(ctx: StateContext<InvoiceChargesStateModel>, {options}): void {
    return this.updateQuery(ctx, {options});
  }

  @Action(UpdateActiveSiderSection)
  updateActiveSiderSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
