import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ContractSection} from "../../core/contract-section";
import {SortingBuilder, SortingService} from "../../../shared/sorting/sorting.service";
import Query from "../../../core/query/query";
import {SvcOrderService} from "../../core/svc-order.service";
import {Subject} from "rxjs";
import {SvcOrderServiceGridService} from "../../core/svc-order-service-grid.service";
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {SvcOrderServiceManageDialogComponent} from "../../shared/svc-order-service-manage-dialog/svc-order-service-manage-dialog.component";
import {SvcOrderServices} from "../../core/svc-order-service";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {GridSettingsComponent} from "../../../core/grid/grid-settings.component";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {SvcOrderServiceFilterService} from "../../core/svc-order-service-filter.service";
import {InvoiceQuery} from "../../../invoice/core/invoice.query";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {InventoryService} from "../../../inventory/core/inventory.service";
import { LoaderService } from '../../../shared/loader/loader.service';

@Component({
  selector: 'app-svc-order-details-services',
  templateUrl: './svc-order-details-services.component.html',
  styleUrls: ['./svc-order-details-services.component.css']
})
export class SvcOrderDetailsServicesComponent extends PageDetailsComponent implements OnInit {

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

  public serviceColumns: Array<any>;
  svcOrderServices: any;
  selection: any;
  public svcServiceSorting: SortingBuilder;
  servicesQuery: any = new Query();
  destroy$: Subject<boolean> = new Subject<boolean>();
  svcOrderHeader: any;
  public serviceColumnsSorting: any[];
  isEditDisabled: boolean = true;
  svcOrderHeaderId: any

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "svc-order";
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public svcOrderService: SvcOrderService,
              public svcOrderServiceGridService: SvcOrderServiceGridService,
              public inventoryService: InventoryService,
              public loaderService: LoaderService,
              public dialog: DialogService,
              public alert: AlertService,
              public sortingService: SortingService,
              public settingsService: UserSettingsService,
              public svcOrderServiceFilterService: SvcOrderServiceFilterService,

  ) {
    super(new PageContext({
      name: 'app.svc-oder.svc-order-details-services',
      settings: settingsService
    }))
    this.svcServiceSorting = this.sortingService.builder();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit(): void {
    this.svcOrderHeader = this.getSvcOrder();
    this.svcOrderHeaderId = this.svcOrderHeader.id
    this.svcOrderServiceGridService.loadSettings()
      .subscribe((settings) => {
        this.initSvcColumns(settings);
      });
  }

  private getSvcOrder() {
    return this.route.parent.snapshot.data.svc_order;
  }

  public async loadData(id: number) {
    this.svcOrderHeader = await this.loadSvcOrder(id).toPromise();
  }

  loadSvcOrder(svcId) {
    return this.svcOrderService.findById(svcId)
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/contract']);
    }
  }

  onSelectionChanged(row) {
    this.selection = <ContractSection>row.selectedRowsData[0];
    this.isEditDisabled = !this.selection;
  }

  public onSvcServiceCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.svcServiceSorting.apply(event, this.servicesQuery);
      this.loadServices(this.svcOrderHeader.id, this.servicesQuery);
    }
  }

  loadServices(id, query?) {
    if (!query) {
      query = new Query();
    }
    if(query){
      this.servicesQuery= new Query({...query});
    }
    if (!query.where) query.where = {};
    query.where.svc_order_header_id = id;
    query.orderBy = [['line_number', 'ASC']];
    this.svcOrderService.findAllServices(id, query)
      .subscribe((results) => {
        this.svcOrderServices = results.items;
        this.servicesQuery.total = results.total;
      });
  }

  initSvcColumns(settings) {
    this.serviceColumnsSorting = this.svcOrderServiceGridService.getOrderBy(settings && settings.sorting ? settings.sorting : []);
    this.serviceColumns = this.svcOrderServiceGridService.getColumns(settings && settings.columns ? settings.columns : [])
    if (this.serviceColumnsSorting.length) {
      this.servicesQuery.orderBy = this.serviceColumnsSorting;
    }

    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.option('columns', this.serviceColumns);
    }
    this.defaultGridPager = settings ? settings.gridPager : 20;;
    this.servicesQuery.limit = this.defaultGridPager;

    this.loadServices(this.svcOrderHeader.id, this.servicesQuery);
  }

  newService() {
    this.dialog.open(SvcOrderServiceManageDialogComponent, {
      svcOrderService: new SvcOrderServices({}),
      svcOrderHeaderId: this.svcOrderHeaderId,
      svcOrderHeader: this.svcOrderHeader
    }, {width: '800px'})
      .afterClosed()
      .subscribe(result => {
          if (result) {
            this.loadServices(this.svcOrderHeaderId, this.servicesQuery);
            this.loadSvcOrder(this.svcOrderHeaderId);
            this.alert.success('', this.messages.get('CREATE_SUCCESS'));
          }
        },
        (error) => {
          this.alert.error('', this.messages.get('CREATE_ERROR'));
        });
  }

  updateService() {
    this.dialog.open(SvcOrderServiceManageDialogComponent, {
      svcOrderService: this.selection,
      svcOrderHeader: this.svcOrderHeader
    }, {width: '800px'})
      .afterClosed()
      .subscribe(result => {
          if (result) {
            this.loadServices(this.svcOrderHeaderId, this.servicesQuery);
            this.loadSvcOrder(this.svcOrderHeaderId);
            this.alert.success('', this.messages.get('UPDATE_SUCCESS'));
          }
        },
        (error) => {
          this.alert.error('', this.messages.get('UPDATE_ERROR'));
        });
  }

  gridSettings() {
    this.servicesGridSettings()
  }

  servicesGridSettings() {
    this.dialog
      .open(GridSettingsComponent, {
        service: this.svcOrderServiceGridService,
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this.initSvcColumns(settings);
        }
      });
  }

  csv() {
    const { svcOrderServiceGridService, svcOrderService, servicesQuery: query } = this;
    svcOrderServiceGridService.csvMap().subscribe(fields => {
      svcOrderService.exportToCSV(
        'svc_services',
        {
          fields,
          query,
          settings: {currencyField: 'currency.currency'},
          fetchDataHandler: (query) => { return svcOrderService.findAllServices(this.svcOrderHeaderId, query)}        })
    });
  }

  toggleFilter() {
    this.toggleSider(this.SECTIONS.FILTER_SECTION_NAME)
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
  }

  clearFilterServices(query?) {
    if (query) {
      this.servicesQuery = query;
    }
    delete this.servicesQuery.where;
    this.loadServices(this.svcOrderHeaderId, this.servicesQuery);
  }

  filterServices(query: InvoiceQuery) {
    this.query = query;
    this.loadServices(this.svcOrderHeaderId, query);
  }

  linkToSpid(event) {
    this.loaderService.displayLoader(true);
    if(event.data && event.data.inventory && event.data.inventory.length) {
      this.inventoryService.findById(event.data.inventory[0].id)
            .subscribe(res => {
              this.loaderService.hideLoader(true);
              this.router.navigate(['inventory', res.id, 'show', 'summary']);
            },
            (error) => {
              this.loaderService.hideLoader(true);
              this.alert.success('', this.messages.get('INVENTORY_GET_ERROR'));
            }
            );
    } else {
      const query = new Query({
        where: {
          sp_ckt_id: event.data.spid,
          vendor_id: this.svcOrderHeader.vendor_id
        }
      });
      this.inventoryService.findAll(query)
            .subscribe(res => {
              this.loaderService.hideLoader(true);
              if(res.items && res.items.length) {
                this.router.navigate(['inventory', res.items[0].id, 'show', 'summary']);
              } else {
                this.alert.success('', this.messages.get('INVENTORY_NOT_FOUND'));
              }
            },
            (error) => {
              this.loaderService.hideLoader(true);
              this.alert.success('', this.messages.get('INVENTORY_GET_ERROR'));
            });
    }
  }

}
