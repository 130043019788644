import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class ContractScheduleTermConditionGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.contract.contract-schedule-term-condition-grid", userSettings);
  }

  defaultColumns = [
    {
      caption: 'Schedule Name',
      dataField: 'schedule_name',
      alignment: 'left',
      width: 160
    },
    {
      caption: 'Schedule Series',
      dataField: 'schedule_series.value',
      alignment: 'left',
      width: 200
    },
    {
      caption: 'Effective Date',
      dataField: 'effective_date',
      dataType: 'date',
      cellTemplate: "effectiveDateTemplate",
      alignment: 'left',
      width: 150
    },
    {
      caption: 'End Date',
      dataField: 'termination_date',
      dataType: 'date',
      cellTemplate: "terminationDateTemplate",
      alignment: 'left',
      width: 150
    },
    {
      caption: 'Term (Months)',
      dataField: 'rate_term',
      dataType: 'number',
      alignment: 'left',
      width: 120
    },
    {
      caption: 'Status',
      dataField: 'termination_date',
      cellTemplate: 'isTerminated',
      alignment: 'left',
      width: 150
    }];

  /*columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
