<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
    <h1 mat-dialog-title>Filter Settings</h1>
</div>
<div mat-dialog-content style="overflow-y: hidden" class="ca-dialog-content filter-settings">
  <form class="form ca-tab tabbed-content settings-form centered-tabs">
    <mat-tab-group (selectedIndexChange)="onSettingsTabChange($event)" [(selectedIndex)]="selectedIndex">
      <mat-tab label="Filters">
        <div class="modal-content-dialog grid-columns-form content-height flex-container" fxLayout="row">
          <div fxFlex="100%">
            <ul class="list-group ca-default-border" dnd-sortable-container
                [sortableData]="contextValues">
              <div *ngFor="let filter of contextValues; let i = index" class="card-container">
                <mat-card
                  class="list-group-item ca-default-border-bottom" dnd-sortable [sortableIndex]="i">
                  <div fxLayout="row">
                    <div fxFlex>
                      <i class="material-icons center-icon">reorder</i><span class="grid-settings-caption vertical-center">{{filter.label}}</span>
                    </div>
                    <div fxFlex="10%">
                      <mat-slide-toggle [name]="filter.field" [(ngModel)]="filter.visible">
                      </mat-slide-toggle>
                    </div>
                  </div>
                </mat-card>
              </div>
            </ul>
          </div>
        </div>
    </mat-tab>
    <mat-tab label="Reset">
        <div fxLayout="column" class="modal-content-dialog content-height reset-style reset-settings-text">
          <p>A reset will remove your personalized changes and restore the original default settings for this control.</p>
          <p class="reset-settings-text-second-line">To reset to factory defaults, select the 'Restore defaults' checkbox and click the SAVE button.</p>
          <mat-checkbox [(ngModel)]="resetConfirmValue" [ngModelOptions]="{standalone: true}">
            <span class="reset-settings-checkbox-text">Restore defaults</span>
          </mat-checkbox>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>


<mat-dialog-actions class="ca-align-baseline ca-dialog-action">
  <div class="example-fill-remaining-space">
  </div>

  <button mat-button type="submit" (click)="close()">
    Cancel
  </button>

  <button mat-button type="submit" (click)="save()" [disabled]="!resetConfirmValue && selectedIndex==1">
    Save
  </button>
</mat-dialog-actions>
