import {ICsvInterceptor} from "../../core/csv/csv-interceptor";

export class UserCsvInterceptor implements ICsvInterceptor {
  transform(item: any) {

    let copy = Object.assign({}, item);
    copy.is_active = item.is_active ? 'Active' : 'Inactive';
    return copy;
  }
}
