<div #accordion class="filters-accordion-container">
  <div class="dictionary-accordion">

    <mat-accordion id="accordion" #accordion [multi]="true">
      <mat-expansion-panel #group *ngFor="let entry of filterCards; let index = index; " [expanded]='entry.expanded'
        draggable="true" (dragstart)="handleDragStart(entry, index)" (dragend)="handleDrop($event, entry, index)"
        (dragover)="handleDragOver(entry, index)" [ngStyle]="{opacity : index === dragOverIndex ? '0.4' : '1'}">
        <mat-expansion-panel-header (click)="switchExpand(entry)" class="ca-expansion-panel-header-title"
          [expandedHeight]="'48px'" [collapsedHeight]="'48px'" style="align-content: center">
          <span style="align-self: center;">{{entry?.label}}</span>
        </mat-expansion-panel-header>

        <div fxLayout="column">
          <ca-tag-filter-input *ngIf="entry?.getEditor() === 'inputLookup'"
                               [filter]="true"
                               [entry]="entry"
                               [query]="query"
                               (queryChanged)="onTagFilterQueryChanged($event)"
          ></ca-tag-filter-input>

          <div *ngIf="entry?.getEditor() === 'inputText'" class="text-filter">
            <div class="ca-form ca-full-width" fxLayout="row" style="align-items: baseline">
              <mat-form-field hiDensityInput>
                <input [(ngModel)]="internalStringQuery[entry.field]" (ngModelChange)="ngOnStringChange(entry)"
                  type="text" matInput>

                <div class="card-header-commands text-filter-icons"  fxFlex="1 0 auto">
                  <a *ngIf="isExactVisible(entry)" (click)="toggleExact(entry, index)" matTooltip="Exact match"
                    class="input-command" [ngClass]="{'active': entry.exact}">
                    <ca-icon [key]="'FILTER_EXACT'" [innerClass]="'iconForTextFilter'"></ca-icon>
                  </a>
                  <a *ngIf="isMatchCaseVisible(entry)" (click)="toggleCase(entry, index)" matTooltip="Match case"
                    class="input-command" [ngClass]="{'active': entry.caseSensitive}">
                    <ca-icon [key]="'FILTER_CASE'" [innerClass]="'iconForTextFilter'"></ca-icon>
                  </a>
                </div>
              </mat-form-field>

            </div>
          </div>

          <div *ngIf="entry?.getEditor() === 'simpleInputNumber'" class="text-filter">
            <div class="ca-form ca-full-width" fxLayout="row" style="align-items: baseline">
              <mat-form-field hiDensityInput>
                <input [(ngModel)]="internalStringQuery[entry.field]" (ngModelChange)="ngOnNumberChange(entry)"
                       type="text" matInput>
              </mat-form-field>

            </div>
          </div>

          <div class="ca-form ca-full-width" *ngIf="entry?.getEditor() === 'inputNumber'">
            <number-range-filter-input [entry]="entry" [query]="query"></number-range-filter-input>
          </div>

          <div class="ca-form ca-full-width" *ngIf="entry?.getEditor() === 'inputNegativeNumber'">
            <number-range-negative-filter-input [entry]="entry" [query]="query"></number-range-negative-filter-input>
          </div>

          <div *ngIf="entry?.getEditor() === 'vendorGrouping'">
            <vendor-grouping-filter style="flex-direction: column;" [entry]="entry" [query]="query">
            </vendor-grouping-filter>
          </div>

          <div *ngIf="entry?.getEditor() === 'inputDate'">
            <div fxFlex class="ca-form">
              <ca-date-picker [(ngModel)]="internalDateQuery['from']" placeholder="From"
                (selectedChanged)="ngOnDateChange($event)"></ca-date-picker>
            </div>
            <span fxFlex="10px"></span>
            <div fxFlex class="ca-form">
              <ca-date-picker [(ngModel)]="internalDateQuery['to']" placeholder="To"
                (selectedChanged)="ngOnDateChange($event)"></ca-date-picker>
            </div>
          </div>

          <div  *ngIf="entry?.getEditor() === 'topUs§age'">
            <ca-top-usage-filter
            [entry]="entry"
            (changed)="handleTopUsageChange($event)"
            [query]="query">
          </ca-top-usage-filter>
          </div>


          <div style="box-sizing: border-box !important;
    display: flex !important;
    flex-flow: row wrap !important;" *ngIf="entry?.getEditor() === 'checkboxList'">
            <multi-select-filter-input style="flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    width: 100%;" [query]="query" [entry]="entry" [entryInterceptor]="entryInterceptor"></multi-select-filter-input>
          </div>

          <div *ngIf="entry?.getEditor() === 'dictionaryList'">
          </div>

          <ca-date-range-filter [filter]="true" *ngIf="entry?.getEditor() === 'dateRanges'" [entry]="entry"
            [query]="query"></ca-date-range-filter>

          <ca-address-filter *ngIf="entry?.getEditor() === 'address'"
            [entry]="entry"
            (queryChanged)="onAddressQueryChange($event)"
            [query]="query">
          </ca-address-filter>

          <ca-country-filter *ngIf="entry?.getEditor() === 'country' || entry?.getEditor() === 'subdivision'"
            [entry]="entry"
            (queryChanged)="onCountryQueryChange($event)"
            [query]="query">
          </ca-country-filter>

          <ca-sites-filter *ngIf="entry?.getEditor() === 'sites'" [entry]="entry" [query]="query"
            (sitesFilterChanged)="onSitesFilterChanged($event)"></ca-sites-filter>

          <ca-vendor-account-filter *ngIf="entry?.getEditor() === 'vendorAccountFilter'"
                           [entry]="entry"
                           [query]="query"></ca-vendor-account-filter>

          <ca-vendor-account-all-filter *ngIf="entry?.getEditor() === 'vendorAccountAllFilter'"
                                    [entry]="entry"
                                    [query]="query"></ca-vendor-account-all-filter>

            <ca-boolean-filter
              *ngIf="entry?.getEditor() === 'booleanSelect'"
              [entry]="entry"
              [query]="query">
            </ca-boolean-filter>

          <div *ngIf="entry?.getEditor() === 'contractActiveFilter'">
            <div fxFlex class="ca-form">
              <mat-checkbox [name]="entry?.label" (ngModelChange)="ngOnContractActiveChange()"
                [(ngModel)]="internalContractActiveQuery['active']">
                Active
              </mat-checkbox>
            </div>
            <div fxFlex class="ca-form">
              <mat-checkbox [name]="entry?.label" (ngModelChange)="ngOnContractActiveChange()"
                [(ngModel)]="internalContractActiveQuery['expired']">
                Expired
              </mat-checkbox>
            </div>
          </div>

          <div style="flex-direction: column;" *ngIf="entry?.getEditor() === 'reconVendorAndAccountPicker'">
            <div fxFlex class="ca-form">
              <ca-default-picker hiDensityInput>
                <mat-form-field>
                  <mat-select placeholder="Vendor" (ngModelChange)="ngOnReconVendorChange($event)"
                    [(ngModel)]="internalReconExclusionVendor">
                    <mat-option *ngFor="let val of internalReconExclusionVendorList" [value]="val.id">
                      {{ val.name || val }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ca-default-picker>
            </div>
            <div fxFlex class="ca-form" style="margin-bottom: 1.85em;">

              <ca-default-picker hiDensityInput>
                <mat-form-field>
                  <mat-select placeholder="Account" (ngModelChange)="ngOnReconAccountChange($event)"
                    [(ngModel)]="internalReconAccount">

                    <mat-option style="padding-left: 0 !important; padding-right: 0 !important;"
                      *ngIf="internalReconAccount">
                      <button mat-button class="clear-option">Clear</button>
                    </mat-option>
                    <mat-option *ngFor="let val of internalReconAccountList" [value]="val.acct_level_1">
                      {{ val.acct_level_1 || val }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ca-default-picker>


            </div>
          </div>

          <div style="flex-direction: column;" *ngIf="entry?.getEditor() === 'reconUnlinkedVendorAndAccountPicker'">
            <div fxFlex class="ca-form">

              <ca-default-picker hiDensityInput>
                <mat-form-field>
                  <mat-select placeholder="Vendor" (ngModelChange)="ngOnReconUnlinkedVendorChange($event)"
                    [(ngModel)]="internalReconUnlinkedVendor">
                    <mat-option *ngFor="let val of internalReconUnlinkedVendorList" [value]="val.vendor_id">
                      {{ val.name || val }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ca-default-picker>


            </div>
            <div fxFlex class="ca-form" style="margin-bottom: 1.85em;">

              <ca-default-picker hiDensityInput>
                <mat-form-field>
                  <mat-select placeholder="Account" (ngModelChange)="ngOnReconUnlinkedAccountChange($event)"
                    [(ngModel)]="internalReconUnlinkedAccount">

                    <mat-option style="padding-left: 0 !important; padding-right: 0 !important;"
                      *ngIf="internalReconUnlinkedAccount">
                      <button mat-button class="clear-option">Clear</button>
                    </mat-option>
                    <mat-option *ngFor="let val of internalReconUnlinkedAccountList" [value]="val.acct_level_1">
                      {{ val.acct_level_1 || val }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ca-default-picker>
            </div>
          </div>

          <div class="ca-form ca-full-width" *ngIf="entry?.getEditor() === 'orderNoServiceNoCombination'">
            <ca-order-no-service-no-filter #orderNoServiceNoComboFilter [entry]="entry" [query]="query">
            </ca-order-no-service-no-filter>
          </div>

          <div style="flex-direction: column;" *ngIf="entry?.getEditor() === 'networkHubLevel1Level2'">
            <ca-network-hub-l1-l2-filter #networkhubLevel1Level2Filter [entry]="entry" [query]="query">
            </ca-network-hub-l1-l2-filter>
          </div>

        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="empty-filter-input" [hidden]="filterCards && filterCards.length > 0">
  <div class="message-holder">
    <p>Empty Set</p>
  </div>
</div>
