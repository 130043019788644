<app-contract-details-toolbar
    [isTermsConditionsTab]="true"
    [isEditTCDisabled]="!selection"
    (addNewTCRequested)="addNewTC()"
    (editTCRequested)="editTC()"
    (deleteTCRequested)="deleteTC()"
    (csvRequested)="termAndConditionCsv()"
    (gridSettingsRequested)="termsAndConditionsGridSettings()"
    [isFilterDisabled]="false"
    [isFilterOn] = "this.sider.getActiveSection().name === this.SECTIONS.FILTER_SECTION_NAME"
    (toggleFilterRequested)="toggleFilter()"
    [notesCount]="notesCount"
    [isNotesOn] = "this.sider.getActiveSection().name === this.SECTIONS.NOTES_SECTION_NAME"
    (toggleNotesRequested)="toggleNotes()"
    [isHistoryOn] = "this.sider.getActiveSection().name === this.SECTIONS.HISTORY_SECTION_NAME"
    (toggleHistoryRequested)="toggleHistory()"
>
</app-contract-details-toolbar>

<mat-sidenav-container class="app-content app-content-list tabbed-content dx-nodata-absolute">
    <app-contract-details-tabs [activeTabIndex]="2" [contract]="contract"></app-contract-details-tabs>
    <div class="tabs-body grid-container dx-nodata-absolute"
    style="z-index: -1; overflow: hidden;">
        <div class="grid-body app-content-list">
          <dx-data-grid #termsAndConditionGrid
                        class="app-grid-with-pager dx-sort-disabled sort-arrows"
                        [columns]="termsConditionColumns"
                        [dataSource]="termsConditions"
                        [height]="'100%'"
                        [width]="'100%'"
                        [showColumnLines]="false"
                        [showRowLines]="true"
                        [allowColumnResizing]="true"
                        [columnMinWidth]="50"
                        [columnAutoWidth]="true"
                        (onSelectionChanged)="onSelectionChanged($event)"
                        (onCellClick)="onTermsAndConditionCellClick($event)">
            <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>
            <dxo-paging [enabled]="false" visible="false"></dxo-paging>
            <dxo-sorting mode="none"></dxo-sorting>
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-remote-operations [sorting]="true"></dxo-remote-operations>
    
            <div *dxTemplate="let d of 'effDateTemplate'">
              <div>{{ d.value | caDate }}</div>
            </div>

            <div *dxTemplate="let d of 'termDateTemplate'">
              <div>{{ d.value | caDate }}</div>
            </div>

            <div class="expanded-cell" *dxTemplate="let d of 'contractTextCellTemplate'">
              <span class="expanded-text" matTooltip="{{d.value}}">
                {{ d.value }}
              </span>
            </div>
    
          </dx-data-grid>
        </div>
        <ca-pager class="app-pager ca-default-border-top"
                  [gridPager]="defaultGridPager"
                  [pageContext]="contractTermConditionGridService"
                  [query]="termsConditionQuery"
                  (onChange)="loadTermsAndConditions(this.termsConditionQuery)"></ca-pager>
    </div>

    <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false" (close-start)="panelClose()">
      <new-app-filter
        [ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
        [query]="termsConditionQuery"
        [filterService]="contractTermAndConditionFilterService"
        (filterRequested)="loadTermsAndConditions($event)"
        (clearFilterRequested)="clearTermsAndConditionFilter($event)"
      >
      </new-app-filter>
  
      <ca-notes [entityId]="contract?.id" [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                [entityType]="SYSTEM_MODULE.CONTRACT" (countChanged)="onNotesCountChanged($event)"></ca-notes>
  
      <ca-history #history
                  [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
                  [entityId]="contract?.id"
                  [entityType]="'contract'"
      ></ca-history>
    </mat-sidenav>
</mat-sidenav-container>