import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { DialogButtonsController } from '../../../core/dialog-buttons-controller.class';
import { IMessagesResourceService, ResourcesService } from '../../../core/resources/resources.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { VendorService } from '../../../vendor/core/vendor.service';
import { VendorEntity } from '../../core/vendor-entity';

@Component({
  selector: 'app-vendor-entity-manage-dialog',
  templateUrl: './vendor-entity-manage-dialog.component.html',
  styleUrls: ['./vendor-entity-manage-dialog.component.css']
})
export class VendorEntityManageDialogComponent extends DialogButtonsController implements OnInit {
  vendorEntityForm: FormGroup;
  vendor;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'vendor';

  constructor(
    public formBuilder: FormBuilder,
    public alert: AlertService,
    public vendorService: VendorService,
    public dialogRef: MatDialogRef<VendorEntityManageDialogComponent>
  ) {
    super();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.vendorEntityForm = this.formBuilder.group({
      name: new FormControl('', Validators.required)
    });
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  onSubmit({value, valid}: { value: VendorEntity, valid: boolean }) {
    if (valid) {
      this.toggleDialogButtons();
      this.close(value);
    } else {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }
}
