import {Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output} from '@angular/core';

import {Flow} from './flow';

@Component({
  selector: 'ca-flow',
  templateUrl: './flow.component.html',
  styleUrls: ['./flow.component.css']
})
export class FlowComponent implements OnInit, OnChanges {
  @Input() flow: Flow = new Flow();
  @Input() step = 0;
  @Input() readonly = false;
// tslint:disable-next-line: no-input-rename
  @Input('disabled') isDisabled: boolean;
  @Output() onStepSelection = new EventEmitter();
  @Input() notAudit = true;

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    let step = changes['step'];
    if (step && step.currentValue) {
      this.loadData();
    }
  }

  onSelect(step) {
    if (this.readonly || step.disabled || step.name == 'Cancelled' || this.flow.steps[0].name == 'Cancelled') {
      return;
    }

    this.onStepSelection.emit(step);
  }

  stepChange(e: any) {
    this.flow.steps.map((step) => {
      if (step.code === e.selectedIndex + 1) {
        this.onSelect(step)
      }
    })
  }

  loadData() {
  }

  calculateWidth() {
    let percent = (100 / this.flow.steps.length);
    return `${percent}%`;
  }
}
