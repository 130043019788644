
import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Quote} from './quote';
import {Restangular} from 'ngx-restangular';


import {DictionaryService} from '../../dictionary/core/dictionary.service';
import {NetworkHubService} from '../../network-hub/core/network-hub.service';
import {LOOKUP_MODELS_ENUM} from "../../dictionary/core/lookup-models.enum";
import {Bandwidth} from "../../bandwidth/core/bandwidth";
import {ConfigService} from "../../core/config/config.service";
import Query from "../../core/query/query";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {EntityEditContext} from "../../shared/entity-lock/entity-edit-context";
import {HttpClient} from "@angular/common/http";
import {flatMap} from "rxjs/internal/operators";


@Injectable()
export class QuotingService extends BaseService<Quote> {

  quotes: Quote[] = [];
  bandwidth: Bandwidth[] = [];
  service_type: Array<any>;

  readonly QUOTE_SERVICE_TERM_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.QUOTE_SERVICE_TERM.modelName;

  constructor(public restangular: Restangular,
              public http: HttpClient,
              public networkHubService: NetworkHubService,
              public dictionaryService: DictionaryService,
              public configService: ConfigService,
              private entityLock: EntityLockService) {
    super('quotes', restangular, entityLock);
  }

  findAllServiceTypes() {
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName)
  }

  findAllServiceTerms() {
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.QUOTE_SERVICE_TERM.modelName);
  }

  findAllClientServiceTypes() {
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.QUOTING_CLIENT_SERVICE_TYPE.modelName)
  }

  findAllQuotingTypes() {
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.QUOTING_SERVICE_TYPE.modelName)
  }

  findByServiceId(id: number) {

    return new Promise((resolve, reject) => {
      this.findAll().subscribe((quotes) => {
        if (quotes && quotes[0] && quotes[0].services) {
          resolve(quotes[0].services[0]);
        }
        if (quotes && quotes.services && quotes.services.offers) {
          resolve(quotes.services.offers[0]) //TODO
        }
      });
    });
    // return this.getAll().map(quotes => quotes.find(quote => quote.id == id))
  }

  findByServiceIdForEdit(id: number, idService: number) {
    return this.service()
      .one(this.name, id)
      .one('service', idService)
      .one('edit', null)
      .get()
  }

  findAllServices(query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().all(this.name).all('service').all('services').customGET(null, this.toFilter(transformedQuery));
  }

  findServices(quote_id: number, query) {
    query.where.quote_id = quote_id;
    return this.service().one(this.name, quote_id).all('service').customGET(null, {filter: query});
  }

  findRequests(quote_id: number, quote_service_id: number, query) {
    query.where.quote_service_id = quote_service_id;
    return this.service().one(this.name, quote_id).one('service', quote_service_id).all('offers').customGET(null, {filter: query});
  }

  findAllRequests(quote_id: number, query) {
    return this.service().one(this.name, quote_id).one('service').one('offers').all('all').customGET(null, {filter: query});
  }

  findQuoteHeaderRequests(quote_id: number, query) {
    return this.service().one(this.name, quote_id).all('offers').customGET(null, {filter: query});
  }

  addService(quote_id: number, data): any {
    return this.service().one(this.name, quote_id).all('service').post(data)
  }

  updateService(quoteServiceId: number, item: any): any {
    return this.service().one(this.name, quoteServiceId).all('service').customPUT(item);
  }

  findRequestForEdit(id: number, idService: number, idOffer: number) {
    return this.service()
      .one(this.name, id)
      .one('service', idService)
      .one('offers', idOffer)
      .one('edit', null)
      .get()
  }

  getRequests(quote_id: number, quoteServiceId: number) {
    return this.service().one(this.name, quote_id).one('service', quoteServiceId).one('offers').get()
  }

  addRequest(quote_id: number, quoteServiceId: number, item) {
    return this.service().one(this.name, quote_id).one('service', quoteServiceId).one('offers').post(item);
  }

  updateRequest(quote_id: number, quoteServiceId: number, quoteRequestId: number, item) {
    return this.service().one(this.name, quote_id).one('service', quoteServiceId).one('offers', quoteRequestId).customPUT(item).map((result) => {
      this.completeEdit();
      return result;
    });
  }

  confirmQuote(quote_id: number, quoteServiceId: number, quoteRequestId: number, term: number) {
    return this.service().one(this.name, quote_id).one('service', quoteServiceId).one('offers', quoteRequestId).one('complete', term).customPOST(null);
  }

  createRequest(quote_id: number, quoteServiceId: number, quoteRequestId: number, item) {
    return this.service().one(this.name, quote_id).one('service', quoteServiceId).one('offers', quoteRequestId).customPOST(item);
  }

  getAllHubs(query) {
    return this.networkHubService.findAll(query)
      .pipe(flatMap((hubs: any) => {
        return observableOf(hubs.items.map((hub) => {
          return {
            id: hub.id,
            name: hub.hub_name,
            vendor_id: hub.vendor_id,
            vendor: hub.vendor
          }
        }))
      }))
  }

  getAllNpa(query) {
    return this.service().all('equipments').one('lerg').one('npa').get(query);
  }

  getAllNxx(query) {
    let npa = query.where.npa;
    return this.service().all('equipments').one('lerg').one('nxx', npa).get(query);
  }

  updateQuote(quote_id: number, item) {
    return this.service().one(this.name, quote_id).customPUT(item)
  }

  sendEmailToVendor(object: any) {
    return this.service().one(this.name).one('email', 'vendor').all('send').customPOST(object);
  }

  submitAPIRequest(quote_id: number, quote_service_id: number, vendor_id: number) {
    return this.service().one(this.name, quote_id).one('service', quote_service_id).all('api').customPOST({
      vendor_id
    });
  }

  submitContractRequest(quote_id: number, quote_service_id: number, vendor_id: number) {
    return this.service().one(this.name, quote_id).one('service', quote_service_id).all('api').customPOST({
      vendor_id
    });
  }

  fetchQuoteServiceCount(quoteId: number) {
    return this.service().one(this.name, quoteId).one('service').one('count').get();
  }

  csvMap(columns: any) {
    return columns
      .filter((column) => {
        return !!(column.dataField && column.caption);
      })
      .map((column) => {
        if (column.caption.indexOf('\u2191') !== -1) {
          column.caption = column.caption.replace('\u2191', '')
        }
        if (column.caption.indexOf('\u2193') !== -1) {
          column.caption = column.caption.replace('\u2193', '')
        }

        return {
          value: column.dataField,
          label: column.caption,
          type: column.dataType
        };
      });
  }


  sendBulk(quoteId: number, quoteRequests: Array<any>, email: any) {
    return this.service().one(this.name, quoteId).all('bulk').customPOST({
      quote_requests: quoteRequests,
      email: email
    });
  }
}
