<div fxLayout="row" class="ca-form">
  <mat-form-field fxFlex="80%" caInput class="form-group caGap caGapRight">
    <mat-label>{{entry?.typePicker?.label}}</mat-label>
    <mat-select [value]="query?.filterMeta?.topUsage?.type" (selectionChange)="handleTypePickerChange($event)">
      <mat-option
         *ngFor="let option of entry?.typePicker?.options"
         [value]="option.value">
        {{option.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div fxFlex="20%" class="form-group" caInput>
    <mat-form-field>
      <mat-label>{{entry?.count?.label}}</mat-label>
      <input [value]="query?.filterMeta?.topUsage?.count" type="number" (input)="handleCountChange($event)" matInput />
    </mat-form-field>
  </div>
</div>


<div fxLayout="row" style="margin-bottom: -16px;" class="ca-form">
  <div fxFlex="100%" class="form-group ca-form">
    <ca-date-range-filter fxFlex="100%" [filter]="true" [entry]="entry?.dateRangePicker" [query]="query"
      (queryChanged)="handleDateRangeChange($event)">
    </ca-date-range-filter>
  </div>
</div>