import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Contact} from "./contact";
import {Restangular} from "ngx-restangular";
import Query from "../../core/query/query";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import { Observable } from 'rxjs';
import {ApiService} from "../../core/api";

@Injectable()
export class ContactService extends BaseService<Contact> {

  constructor(public restangular: Restangular,
              private apiService: ApiService,
              private entityLock: EntityLockService) {
    super('contacts', restangular, entityLock);
  }

  findAllList(query?: Query): any {
    return this.apiService.get(this.name, query);
    //return this.service().all(this.name).all('findAllList').customGET(null, this.toFilter(transformedQuery));
  }

  findOnlyNecessary(query?: Query): any {
    return this.apiService.get([this.name, 'findOnlyNecessary'], query);
  }

  public findAllContactFunctions(contact_id: number): Observable<any> {
    return this.service().all(this.name).one('functions', contact_id).get();
  }
  getContactsForFunction(query?: Query): any {
    return this.apiService.get([this.name, 'getContactsForFunction'], query);
  }

}
