import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Restangular} from 'ngx-restangular';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {ApiService} from "../../core/api";

@Injectable()
export class VendorEntityService extends BaseService<any> {

  constructor(public restangular: Restangular,
              private entityLock: EntityLockService,
              private apiService: ApiService) {
    super('vendor-entity', restangular, entityLock);
  }

  public service() {
    return this.restangular;
  }
}
