import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {RandomService} from "../../random/random.service";
import {FormControl} from "@angular/forms";
import { PageListComponent } from 'app/core/page-list.component';
import { OrderServiceLevelGridService } from 'app/order/core/order-service-level/order-service-level.grid.service';
import { OrderServiceGridService } from 'app/order/core/order-service.grid.service';

@Component({
  selector: 'app-confirm-cancel-dialog',
  templateUrl: './confirm-cancel-dialog.component.html',
  styleUrls: ['./confirm-cancel-dialog.component.css']
})
export class ConfirmCancelDialogComponent implements OnInit {
  public onConfirmed: Function;
  title = 'Please confirm';
  bodyText = 'Are you sure you want to?';
  hideTitle = false;
  confirmButtonLabel = 'Confirm';
  cancelPlaceholder = 'Type in generated string shown below';
  actionText = 'delete';
  randomString = this.randomService.getRandomCheckString();
  placeHolder = 'Note';
  config : any ;
  public note: FormControl;

  public columns: Array<any>;
  public order: any

  constructor(
    public dialogRef: MatDialogRef<ConfirmCancelDialogComponent>,
    public randomService: RandomService,
    public orderServiceLevelGridService: OrderServiceLevelGridService,
    public orderServiceGridService: OrderServiceGridService
  ) {
  }

  ngOnInit() {
    const { columns, sorting, query, limit } = PageListComponent.prepareList({
      gridService: this.orderServiceGridService,
      defaultSort: [['service_number', 'ASC']]
    });

    this.columns = columns;
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
