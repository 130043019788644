import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { ContractRate } from './contract-rate';
import { Restangular } from 'ngx-restangular';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ContractInstanceRateService extends BaseService<ContractRate> {

  constructor(public restangular: Restangular,
              public http: HttpClient,
              private entityLock: EntityLockService) {
    super('contract-instance-rate', restangular, entityLock);
  }


}
