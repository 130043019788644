import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NewFilterInputComponent } from '../new-filter-input/new-filter-input.component';

@Component({
  selector: 'new-filter-picker',
  templateUrl: './new-filter-picker.component.html'
})
export class NewFilterPickerComponent implements OnInit {
  @Input('filters') availableFilters = [];
  @Input() filterInput: NewFilterInputComponent;
  @Output() removeFilterCard: EventEmitter<any> = new EventEmitter();

  isDropzoneHovered: boolean;

  ngOnInit() {
    this.filterInput.isSorting = false;
  }

  // outputs event when user drops on remove filter element
  removeZone(event) {
    this.isDropzoneHovered = this.filterInput.isSorting = false;
    this.removeFilterCard.emit(event);
  }

  allowDrop(event) {
    this.isDropzoneHovered = this.filterInput.isSorting = true;
    event.preventDefault();
  }

  dragLeave() {
    this.isDropzoneHovered = false;
  }

  clearUsedFilters() {
    if (
      this.filterInput &&
      this.filterInput.filterCards &&
      this.availableFilters
    ) {
      for (let t = 0; t < this.availableFilters.length; t++) {
        this.availableFilters[t].isActiveCard = false;
      }

      for (let k = 0; k < this.availableFilters.length; k++) {
        for (let i = 0; i < this.filterInput.filterCards.length; i++) {
          if (
            this.availableFilters[k].field ===
            this.filterInput.filterCards[i].field
          ) {
            this.availableFilters[k].isActiveCard = true;
          }
        }
      }
    }
  }

  isPickerEmpty() {
    this.clearUsedFilters();
    if (!this.availableFilters || !this.availableFilters.length) {
      return true;
    }

    return this.availableFilters.every(filter => filter.isActiveCard);

    let allActive = true;
    for (let i = 0; i < this.availableFilters.length; i++) {
      if (!this.availableFilters[i].isActiveCard) {
        allActive = false;
      }
    }
    return allActive;
  }

  onFilterSelected(filterCard) {
    this.filterInput.AddFilterCard(filterCard);
  }
}
