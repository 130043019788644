
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap, takeUntil} from 'rxjs/operators';
import { InvoiceAccountGridService } from '../../invoice/core/invoice-account-grid.service';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AccountService } from '../core/account.service';
import { Account } from '../core/account';
import { InvoiceFacepage } from '../../invoice/core/invoice-facepage';
import { InvoiceFacepageService } from '../../invoice/core/invoice-facepage.service';
import { InvoiceQuery } from '../../invoice/core/invoice.query';
import { DialogService } from '../../shared/dialog/dialog.service';
import { AlertService } from '../../shared/alert/alert.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import {
  SortingService,
  SortingBuilder
} from '../../shared/sorting/sorting.service';
import { HistoryComponent } from '../../shared/history/history.component';
import { ACCOUNT_STATUS_ENUM } from '../core/account-status.enum';
import { PageContext } from '../../core/page.context';
import { PageDetailsComponent } from '../../core/page-details.component';
import { UserSettingsService } from '../../user/core/user-settings.service';
import {
  IMessagesResourceService,
  ResourcesService
} from '../../core/resources/resources.service';
import { LOOKUP_ENUM } from 'app/dictionary/core/lookup.enum';
import { TabGroup, TabService } from '../../shared/tabs/tab.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { DataLockDialogService } from '../../core/data-lock/data-lock-dialog.service';
import { AccountManageDialogComponent } from '../shared/account-manage-dialog/account-manage-dialog.component';
import { AccountDetailsFilterService } from '../core/account-details-filter.service';
import { GridService } from '../../shared/grid/grid.service';
import {ConfigService} from "../../core/config/config.service";

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent extends PageDetailsComponent
  implements OnInit, OnDestroy {
  public summary: any;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTab = 0;
  public activeSiderChanged: any;
  readonly sideSections = {
    none: null,
    info: 'info',
    filter: 'filter'
  };

  _isSidePanelOpen = false;
  activeTabIndex = 0;

  public account: Account;
  public accountId: number;
  public query: InvoiceQuery = new InvoiceQuery({
    orderBy: [
      ['date_issued', 'DESC'],
      ['vendor_id', 'ASC'],
      ['sp_inv_num', 'ASC']
    ]
  });
  public invoices: Array<any>;
  public columns: any;
  public ready = false;
  @ViewChild('panelMore') panelMore;
  @ViewChild('panelMoreTabs') panelMoreTabs;
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild('history') history: HistoryComponent;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'account';
  readonly custom_values_config_key = 'account_configuration';

  public sortingBuilder: SortingBuilder;
  public sorting: any[][];
  public notesCount = 0;

  readonly ACCOUNT_STATUS_ENUM = ACCOUNT_STATUS_ENUM;

  isGridSettingsDisabled = true;

  constructor(
    public accountService: AccountService,
    public invoiceService: InvoiceFacepageService,
    public invoiceAccountGridService: InvoiceAccountGridService,
    public sortingService: SortingService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: DialogService,
    public settingsService: UserSettingsService,
    public alert: AlertService,
    public dataLockDialogService: DataLockDialogService,
    public tabService: TabService,
    public accountDetailsFilterService: AccountDetailsFilterService,
    public loaderService: LoaderService,
    public configService: ConfigService
  ) {
    super(
      new PageContext({
        resource: 'accounts',
        name: 'app.accounts.account-details',
        settings: settingsService
      })
    );

    this.sortingBuilder = this.sortingService.builder();

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.columns = this.invoiceAccountGridService.columns();
    this.accountId = +this.route.snapshot.paramMap.get('id')

    this._init();
    this.loadTabs();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.invoiceAccountGridService.create(this.dataGrid.instance, {});
    setTimeout(() => {
      this.ready = true;
    }, 1000);
  }

  onTabChange(index) {
    this.activeSiderChanged = null;

    let tab = this.tabGroup.tabs[index];
    if (
      tab.key === 2 &&
      this.sider.getActiveSection().name === this.sideSections.filter
    ) {
      this._isSidePanelOpen = true;

      observableOf(true).subscribe(() => {
        this._isSidePanelOpen
          ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME)
          : this.sider.closeComponent();

        this.tabGroup.forceRerender();
      });
    }

    if (
      tab.key !== 2 &&
      this.sider.getActiveSection().name === this.sideSections.filter
    ) {
      this._isSidePanelOpen = false;

      observableOf(true).subscribe(() => {
        this._isSidePanelOpen
          ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME)
          : this.sider.closeComponent();

        this.tabGroup.forceRerender();
      });
    }

    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }

    /* Settings grid disabled */
    if (this.tabGroup.isActive(2)) {
      this.isGridSettingsDisabled = false;
    } else {
      this.isGridSettingsDisabled = true;
    }
  }

  public loadTabs() {
    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({ key: 1, title: 'Summary' });
    this.tabGroup.addTab({ key: 2, title: 'Details' });

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe(() => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }

  back() {
    if (
      document.referrer.indexOf(window.location.host) >= 0 ||
      document.referrer === ''
    ) {
      history.back();
    } else {
      this.router.navigate(['/account']);
    }
  }

  async edit() {
    let account = this.account;
    if (account) {
      const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key);

      this.dialog
        .edit(AccountManageDialogComponent, {
          account: this.accountService.findByIdForEdit(account.id)
        }, {
          width: hasCustomFields ? '1200px' : '820px'
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result) {
            const {changeDisputes = []} = result;
            let activateSetTimeOut = false;
            if((account.is_vat_gl_output !== result.is_vat_gl_output) && result.is_vat_gl_output){
              if (changeDisputes.length > 0) {
                let message = this.messages.get('IS_VAT_GL_OUTPUT_ENABLED').replace('<number>', changeDisputes.length)
                message += `. Dispute IDs: ${changeDisputes.join(', ')}.`
                this.alert.success('', message);
              }
              activateSetTimeOut=true;
            }
            if(activateSetTimeOut){
              setTimeout(()=> {
                this.getAccountMessage(account)
              },5000);
            }else {
              this.getAccountMessage(account)
            }

            Object.assign(this.account, result);
            this.history.refreshList();
          }
        });
    }
  }

  getAccountMessage(account?){
    this.alert.success('', this.messages.get('UPDATE_SUCCESS'));
  }

  filterInvoices(query: InvoiceQuery) {
    query.where['acct_level_1'] = {
      $eq: this.account.account_no
    };

    this.query = query;
    this.loadInvoices(query);
  }

  clearInvoiceFilter(query: InvoiceQuery) {
    query.where.acct_level_1 = {
      $eq: this.account.account_no
    };
    this.loadInvoices(query);
  }

  public onRowClick(row) {
    let component = row.component;
    let prevClickTime = component.lastClickTime;

    component.lastClickTime = new Date();

    if (prevClickTime && component.lastClickTime - prevClickTime < 250) {
      let selection = <InvoiceFacepage>row.data;
      this.router.navigate(['/invoice', selection.invoice_id, 'show', 'overview']);
    }
  }

  public loadData(id?: number) {
    const  queryId = typeof id === 'number' ? id : this.accountId;

    this.accountService.findById(queryId).subscribe((account: Account) => {
      this.account = account;
      this.query.where.account_id = {
        $eq: this.account.id
      };
      this.loadInvoices(this.query);
    });
  }

  private interceptAndChangeQuery(query: any[]): any[] {
    const BATCH_KEYS = {
      grid_field: 'batch.batch_key',
      sorting_field: 'header.batch.batch_key'
    };

    query.map((i: any[]) => {
      i.map((el: string) => {
        if (el === BATCH_KEYS.grid_field) {
          i[0] = BATCH_KEYS.sorting_field;
        }
      });
    });

    return query;
  }

  public loadInvoices(query: InvoiceQuery) {
    if (query && this.account) {
      query.orderBy = this.interceptAndChangeQuery(query.orderBy);
      this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);
      this.invoiceService.findAll(query).subscribe(
        result => {
          this.invoices = result.items;
          this.query.total = result.total;
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        },
        () => {
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        }
      );
    }
  }

  public onCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.sortingBuilder.apply(event, this.query);
      this.loadInvoices(this.query);
    }
  }

  gridSettings() {
    this.dialog
      .open(GridSettingsComponent, {
        service: this.invoiceAccountGridService
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getDefaultSort(): any {
    return [
      ['date_issued', 'DESC'],
      ['vendor_id', 'ASC'],
      ['sp_inv_num', 'ASC']
    ];
  }

  getGridService(): GridService {
    return this.invoiceAccountGridService;
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  toggleFilter() {
    this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
    this.tabGroup.forceRerender();
  }

  ngOnDestroy() {
    if (this.sider.getActiveSection().name === this.sideSections.filter) {
      this.toggleFilter();
    }
    this.destroy$.next(true);
    this.destroy$.complete();
    this.tabGroup.onActivate.unsubscribe();
  }

  csv() {
    const { invoiceAccountGridService, invoiceService, query } = this;
    invoiceAccountGridService.csvMap().subscribe(fields => {
      invoiceService.exportToCSV(`account-${this.account.account_no}-details`, {
        fields,
        query: query
      });
    });
  }
}
