
import { Injectable } from '@angular/core';
import { GridService } from "../../shared/grid/grid.service";
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class SubAccountGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.cost.subaccount-grid", userSettings);
  }

  defaultColumns = [
    {
      caption: "Sub Account",
      dataField: "acct_level_2",
      width: 150
    }, {
      caption: "Prev Bill Amount",
      dataField: "prev_bill_amt",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 150
    }, {
      caption: "Payments Received",
      dataField: "pmts_rcvd",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 150
    }, {
      caption: "Balance Forward Adj",
      dataField: "bal_fwd_adj",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 150
    }, {
      caption: "Balance Forward",
      dataField: "bal_fwd",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 150
    }, {
      caption: "Total MRCs",
      dataField: "tot_mrc_chgs",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: "Total OCCs",
      dataField: "tot_occ_chgs",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: "Total Usage",
      dataField: "tot_usage_chgs",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: "Total Taxes / Surcharges",
      dataField: "tot_taxsur",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 170
    }, {
      caption: "Total Discounts",
      dataField: "tot_disc_amt",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: "New Charge Adj",
      dataField: "tot_new_chg_adj",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 130
    }, {
      caption: "Total New Charges",
      dataField: "tot_new_chgs",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 150
    }, {
      caption: "Total Amount Due Adj",
      dataField: "tot_amt_due_adj",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 160
    }, {
      caption: "Total Amount Due",
      dataField: "tot_amt_due",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 160
    }];

}
