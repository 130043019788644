import { InvoiceFacepage } from '../../invoice/core/invoice-facepage';
import { User } from '../../user/core/user';

export class GLBatch {
  id: number;
  file_name: string;
  creator_id: number;
  created_at: Date;
  batch_key: string;
  invoice_qty: number;
  tot_chg_amt: number;
  active: boolean;
  currency: string;
  currency_id: number;
  currency_obj: any;
  invoice_status: number;

  user: User;
  creator: User;
  invoices: Array<InvoiceFacepage>;

  deactivation_note: string;
  is_ap_output_error:boolean;
  message:string;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
