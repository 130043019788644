<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{ formTitle }}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <form [formGroup]="form" class="ca-form">

    <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column">

      <div class="caGap flex-item caGapRight" fxFlex>
        <ca-person-picker
          caInput
          formControlName="requestor_id"
          placeholder="Requestor"
          [initialItem]="quote?.requester"
          [pickerForm]="form"
          required="true"
          pickerType="contact">
        </ca-person-picker>
        <mat-hint class="ca-select-hint" *ngIf="form.hasError('required', ['requestor_id']) && form.controls.requestor_id.touched">{{VALIDATION.REQUIRED_ERROR}}
        </mat-hint>
      </div>

      <div fxFlex="35%" class="caGap flex-item">
        <ca-date-picker caInput required="true" placeholder="Request Date" formControlName="request_date"></ca-date-picker>
      </div>

    </div>

    <div fxLayout="row wrap">
      <div fxFlex="65%" class="caGap caGapRight">
        <ca-customer-picker
          caInput
          formControlName="customer_id"
          placeholder="Customer"
          [searchEnabled]="true"
          [initialItem]="quote.customer"
          required="true"
          [pickerForm]="form">
        </ca-customer-picker>
        <mat-hint *ngIf="form.hasError('required', ['customer_id']) && form.controls.customer_id.touched"
                  class="ca-select-hint">{{VALIDATION.REQUIRED_ERROR}}</mat-hint>
      </div>
      <div fxFlex class="caGap">
        <ca-date-picker caInput placeholder="Due Date" formControlName="due_date"></ca-date-picker>
      </div>
    </div>

    <div fxLayout="row wrap">
      <div fxFlex class="caGap caGapRight">
        <ca-lookup-model-picker caInput [clearEnabled]="true" [pickerForm]="form" [lookupModel]="QUOTE_OPPORTUNITY_TYPE_LOOKUP_MODEL" formControlName="opportunity_type_id" placeholder="Opportunity Type">
        </ca-lookup-model-picker>
      </div>
       <div fxFlex class="caGap">
        <mat-form-field caInput>
          <input matInput type="text" formControlName="crm_opportunity" placeholder="CRM Opportunity ID">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row wrap">
      <div class="caGap caGapRight" fxFlex>
        <ca-person-picker
          caInput
          formControlName="sales_representative_id"
          placeholder="Sales Representative"
          [pickerForm]="form"
          [initialItem]="quote?.sales_representative"
          contact_function="{{ CONTACT_FUNCTION_ENUM.SALES_REP }}"
          pickerType="contact">
        </ca-person-picker>
      </div>
      <div class="caGap" fxFlex>
        <ca-person-picker
          caInput
          formControlName="sales_engineer_id"
          placeholder="Sales Engineer"
          [pickerForm]="form"
          [initialItem]="quote?.sales_engineer"
          contact_function="{{ CONTACT_FUNCTION_ENUM.SALES_ENGINEER }}"
          pickerType="contact">
        </ca-person-picker>
      </div>
    </div>

    <div fxLayout="row wrap">
        <mat-form-field caInput class="caGap">
          <textarea matInput placeholder="Description" formControlName="quote_description" fxFlex="100%" rows="2"></textarea>
        </mat-form-field>
    </div>

  </form>
</div>

<mat-dialog-actions class="ca-dialog-action">

  <div class="example-fill-remaining-space">
      <ca-confirm-delete-button #genericButton *ngIf="isUpdate" [confirmText]="'Delete?'" (deleteRequested)="deleteQuote($event)"></ca-confirm-delete-button>
  </div>
  <button mat-button #cancelButton type="submit" (click)="closeDialog()">
      Cancel
  </button>
  <button mat-button #saveButton [disabled]="form.invalid" type="submit" (click)="onSubmit(form)">Save</button>

</mat-dialog-actions>
