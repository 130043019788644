import { Pipe, PipeTransform, Directive } from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {UserService} from "../../user/core/user.service";
import {LocationService} from "../../location/core/location.service";
import {LocationQuery} from "../../location/core/location.query";

// @Directive()
@Pipe({
  name: 'caCurrencyId'
})
export class CaCurrencyIdPipe extends CurrencyPipe implements PipeTransform {
  private __currencyDisplay: any = JSON.parse(localStorage.getItem('__currencyDisplay'));
  private __currDigit: any = JSON.parse(localStorage.getItem('__currDigit'));
  private __currLocale: any = JSON.parse(localStorage.getItem('__currLocale'));
  private __negativeValue: any = JSON.parse(localStorage.getItem('__negativeValue'));
  private __currencies: any = JSON.parse(localStorage.getItem('__currencies'));
  private countries: any = [];
  private currencies: any = [];
  public currency = localStorage.getItem('currency');


  constructor(public userService: UserService,
              public locationService: LocationService) {
    super(localStorage.getItem('__localeId'));

    if (!this.__currencyDisplay || !this.__currDigit || !this.__currLocale || !this.__negativeValue) {
      this.userService.me().subscribe(user => {
        localStorage.setItem('__currencyDisplay', JSON.stringify(user.country.currency_display));
        localStorage.setItem('__currDigit', JSON.stringify(user.country.curr_num_digit));
        localStorage.setItem('__currLocale', JSON.stringify(user.country.curr_num_locale));
        localStorage.setItem('__negativeValue', JSON.stringify(user.country.negative_value));
      });
    }

    if (!this.__currencies.items.length) {
      this.locationService.findAllUniqueCurrencies(new LocationQuery({limit: 1000,where:{}}))
        .subscribe(result => {
          localStorage.setItem('__currencies', JSON.stringify(result));
        })
    }
  }

  transform(value: any, currencyCode: string = this.currency, symbolDisplay?: string, digit?: string, locale?: string, currencyId?:number): any {
    let custom_symbol;

    this.countries = JSON.parse(localStorage.getItem('__countries'));
    this.currencies = JSON.parse(localStorage.getItem('__currencies'));
    for (let i = 0; i < this.countries.items.length; i++) {
      if (this.countries.items[i].currency === currencyCode) {
        custom_symbol = this.countries.items[i].currency_display;
        break;
      }
    }

    for (let i = 0; i < this.currencies.items.length; i++) {
      if (this.currencies.items[i].id === currencyId) {
        currencyCode = this.currencies.items[i].currency;
        break;
      }
    }
    let symbol = custom_symbol ? custom_symbol : this.__currencyDisplay ? this.__currencyDisplay : 'symbol-narrow';
    let dig = digit || (this.__currDigit ? this.__currDigit : '1.2-2');
    let loc = this.__currLocale ? this.__currLocale : 'en';
    let neg = this.__negativeValue ? this.__negativeValue : '-';

    let formattedByCurrencyPipe = super.transform(value, currencyCode, symbol,  dig, loc);
    if (formattedByCurrencyPipe == null)
        return null;

    let retValue = formattedByCurrencyPipe.charAt(0) === '-' && neg === '()'?
    '(' + formattedByCurrencyPipe.substr(1) + ')' :
    formattedByCurrencyPipe;

    return retValue;
  }

}
