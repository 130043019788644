import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class QuoteServiceVendorGridService extends GridService {

    constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
        super('app.quoting.quote-service-vendor-grid', userSettings);
    }

    defaultColumns = [
        {
            caption: 'Name',
            dataField: 'name',
            width: 250
        }
    ];

    /*columns() {

        let settings = this.userSettings.entrySettings(this.name);
        if (settings && settings.columns) {
            return settings.columns;
        }

        return this.defaultColumns;
    }*/
}
