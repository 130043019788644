import {Injectable} from '@angular/core';
import {ApiService} from '../api';


@Injectable()
export class ConfigService {

  constructor(private apiService: ApiService) {
  }

  cache() {
    return this.apiService.cache('config');
  }

  findAll(): any {
    const cache = this.cache();
    return this.apiService.get('config', null, {cache});
  }

  get(): any {
    return this.findAll();
  }

  loadCustomFields(): any {
    return this.apiService.get('custom-fields');
  }

  getCustomFieldsFromConfig(config, custom_values_key) {
    const custom_values = config?.custom_values
    return (custom_values && typeof custom_values === 'object' && custom_values_key in custom_values) ?
      custom_values[custom_values_key] : [];
  }

  async hasCustomFields(property: string) {
    const config = await this.loadCustomFields().toPromise();
    const custom_values = config?.custom_values
    const fields = (custom_values && typeof custom_values === 'object' && property in custom_values) ?
      custom_values[property] : [];
    return !!fields.length
  }

  getCustomFieldValue(fields, value) {
    const prepared_fields = {};
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].field_name in value) {
        prepared_fields[fields[i].field_name] = value[fields[i].field_name];
      }
    }
    return prepared_fields;
  }
}
