import {InvoiceChargeService} from "./invoice-charge.service";
import Query from "../../core/query/query";
import {Observable} from "rxjs";
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
export class FacilityBandwidthLookupProvider extends IFilterLookupProvider {

  constructor(public chargeService: InvoiceChargeService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    let query = searchQuery? searchQuery : new Query();
    query.orderBy = [['full_name', 'ASC',]];

    return this.chargeService.findDistinctFacilityBandwidths(query)
      .map((result) => {
        return {items: result.map((entry) => {
          return entry.fac_bw;
        }), total: result.total};
      });
  }
}
