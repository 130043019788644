<div class="mdl-card mdl-shadow--2dp">

  <div class="mdl-card__supporting-text">

    <div class="mdl-stepper-horizontal-alternative">
      <div class="mdl-stepper-step"
           *ngFor="let step of flow?.steps;let i = index;"
           (click)="((flow?.canGoNextStep(step?.code) || flow?.isNextStepDoNotProcess(step.code))
              && flow?.isCurrentStepDoNotProcess(step.code)) ? onSelect(step) : null"
           [ngClass]="{
            'active-step': (flow?.isCurrentOrGreaterStep(step.code)
            || (!flow.linear && step.completed)
            || flow?.canGoToDoNotProcess(step.code)),
            'available-as-next': (flow?.isStepAvailableAsNext(step.code) && !flow?.isStepNameCancelled(step)),
            'disabled': step.disabled,
            'current-step' : flow?.isCurrentStep(step.code)}"
           matRipple
      >

        <div class="mdl-stepper-circle" [ngClass]="{'background-icon ': (step.icon && (step?.icon === 'INVOICE_FLOW_WARNING' || step?.icon === 'INVOICE_FLOW_ERROR' || step?.icon === 'INVOICE_FLOW_PENDING'))}">
          <div [ngClass]="((step?.icon === 'INVOICE_FLOW_WARNING' || step?.icon === 'INVOICE_FLOW_PENDING') ? 'step-warning' : step?.icon === 'INVOICE_FLOW_ERROR' ? 'step-error' : '')"
               class="mdl-stepper-icon"
               *ngIf="step.icon && flow?.isCurrentOrGreaterStep(step.code)">
            <ca-icon [key]="step.icon"></ca-icon>
          </div>
          <div class="mdl-stepper-icon mdl-stepper-icon-text"
               *ngIf="!step.icon || !flow?.isCurrentOrGreaterStep(step.code)">
            <ca-icon *ngIf="flow?.isCompletedStep(step.code)" [key]="'FLOW_STEP_COMPLETED'"></ca-icon>
            {{ (!flow?.isCompletedStep(step.code) || !flow?.linear) ? (i + 1) : ''}}
          </div>
        </div>
        <div class="stepper-titles">
            <span class="mdl-stepper-title flow-title">
            {{step?.name}}
            </span>
          <span class="mdl-stepper-optional"><br/>
            {{ (step?.dateInfo | caDate) }}
            <span *ngIf="step?.dateInfo && step?.secondDateInfo">&#9679; </span><span
              *ngIf="step?.secondDateInfo">{{ (step?.secondDateInfo | caDate) }}</span>
            </span>
          <span class="mdl-stepper-optional" *ngIf="step?.optional">
              <br/>
              Optional</span>
        </div>
        <div class="mdl-stepper-bar-left"></div>
        <div class="mdl-stepper-bar-right"></div>
      </div>
    </div>

  </div>

</div>
