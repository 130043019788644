import {Injectable} from '@angular/core';
import {FilterService} from "../../core/filter/filter.service";
import {auditAlertMissingInventoryFilterContext} from "./audit-alert-missing-inventory-filter.context";
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class AuditAlertMissingInventoryFilterService extends FilterService {
  constructor(public userSettingsService: UserSettingsService) {
    super("app.alert.audit-alert-missing-inventory-filter", auditAlertMissingInventoryFilterContext, userSettingsService);


  }

}
