import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";
import { OrderServiceDateGridService } from '../../core/order-service-date-grid.service';
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";
import { DictionaryService } from "../../../dictionary/core/dictionary.service";
import { UserService } from '../../../user/core/user.service';

import * as moment from 'moment';

@Component({
  selector: 'app-foc-receive',
  templateUrl: './foc-receive.component.html',
})
export class FocReceiveComponent extends PageManageDialogComponent implements OnInit {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  public note: string;
  public foc_rec_date: Date;
  public foc_date: Date;
  labels: Array<any> = [];
  public columns: Array<any> = [];
  public data: Array<any> = [];
  public formFields;
  public reasonInvalid = false;
  public allReasons = [];

  readonly NO_TIMESTAMP_DATE_FORMAT = `MM/DD/YYYY`;

  readonly ORDER_SERVICE_MISSED_REASON_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.ORDER_SERVICE_MISSED_REASON.modelName;

  constructor(
    public formBuilder: FormBuilder,
    public dialogService: DialogService,
    public userService: UserService,
    public dictionaryService: DictionaryService,
    public orderServiceDateGridService: OrderServiceDateGridService,
    public dialogRef: MatDialogRef<FocReceiveComponent>,
  ) {
    super(dialogService, dialogRef);
  }

  ngOnInit() {
    let reasonRequired = (this.data && this.data.length > 0);
    this.formFields = {
      note: ["", Validators.minLength(20)],
      foc_rec_date: [null, Validators.required],
      foc_date: [null, Validators.required],
      reason: [""]
    };
    if (reasonRequired) {
      this.formFields.reason = ["", Validators.required];
    }
    this.form = this.formBuilder.group(this.formFields);


    if (this.data && this.data.length) {
      this.columns = this.orderServiceDateGridService.columns();
      setTimeout(() => {
        this.orderServiceDateGridService.create(this.grid.instance, {
          height: '250px',
          allowColumnResizing: false
        });
      });
    }

    this.form.valueChanges.subscribe((data) => {
      this.onReasonChange(data);
    });

    this.initializeLabels();

    this.dictionaryService.getByLookup(this.ORDER_SERVICE_MISSED_REASON_LOOKUP_MODEL)
      .subscribe((result) => {
        this.allReasons = result.items;
      })
  }

  onReasonChange(event) {
    if (event.reason === 'Other' && event.note == '') {
      this.reasonInvalid = true;
    } else {
      this.reasonInvalid = false;
    }
  }

  submit({ value, valid }: { value: any, valid: boolean }) {
    let reason = (this.allReasons.filter(reason => reason.id == value.reason)[0] || {}).value;

    this.data = this.data || [];

    this
      .userService
      .me()
      .subscribe(
        user => {

          const newFocDateHistoryEntry = {
            foc_date: moment(value.foc_date).format(this.NO_TIMESTAMP_DATE_FORMAT),
            foc_rec_date: moment(value.foc_rec_date).format(this.NO_TIMESTAMP_DATE_FORMAT),
            reason: reason,
            note: value.note,
            changed_at: moment.utc(),
            changed_by: user.full_name
          }

          this.dialogRef.close({
            foc_date: moment(value.foc_date).format(this.NO_TIMESTAMP_DATE_FORMAT),
            foc_rec_date: moment(value.foc_rec_date).format(this.NO_TIMESTAMP_DATE_FORMAT),
            note: value.note,
            reason: reason,
            focDateHistory: [newFocDateHistoryEntry, ...this.data],
          });
        }
      )


  }

  cancel() {
    this.closeDialog();
  }

  showLabel(date: string) {
    if (this.labels.indexOf(date) > -1)
      return true;
  }

  // TODO optimize this
  public toggleLabel(date: string) {
    if (this.form.controls[date].value) {
      if (this.labels.indexOf(date) == -1) {
        this.labels.push(date);
      }
    }
    else {
      if (this.labels.indexOf(date) == -1) {
        this.labels.push(date);
      }
      else {
        var index = this.labels.indexOf(date);
        this.labels.splice(index, 1);
      }
    }
  }

  public initializeLabels() {
    let dates = ['foc_rec_date', 'foc_date'];
    dates.forEach((item) => {
      if (this.form.controls[item].value)
        this.labels.push(item);
    });
  }
}
