import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { CustomFormsModule } from "ng2-validation";
// import {RlTagInputModule} from "angular2-tag-input/dist";
import { TagInputModule } from 'ngx-chips';
import { BuildingModule } from "../building/building.module";
import { CoreModule } from "../core/core.module";
import { DictionaryModule } from "../dictionary/dictionary.module";
import { InvoiceModule } from "../invoice/invoice.module";
import { LocationModule } from '../location/location.module';
import { ReportModule } from "../report/report.module";
import { SharedModule } from "../shared/shared.module";
import { VendorRemitAddressModule } from "../vendor-remit-address/vendor-remit-address.module";
import { VendorModule } from "../vendor/vendor.module";
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AccountListComponent } from './account-list/account-list.component';
import { AccountRoutingModule } from "./account-routing.module";
import { AccountDetailsFilterService } from "./core/account-details-filter.service";
import { AccountDialogService } from './core/account-dialog.service';
import { AccountFilterService } from "./core/account-filter.service";
import { AccountGridService } from "./core/account-grid.service";
import { AccountStatService } from "./core/account-stat.service";
import { AccountService } from "./core/account.service";
import { AccountManageDialogComponent } from './shared/account-manage-dialog/account-manage-dialog.component';
import { CaAccountPickerComponent } from './shared/account-picker/ca-account-picker.component';
import { AccountSneakPeekComponent } from './shared/account-sneak-peek/account-sneak-peek.component';
import { InvoiceTrendComponent } from './shared/invoice-trend/invoice-trend.component';
import {ApConfigurationService} from "./core/ap-configuration.service";


@NgModule({
  imports: [
    AccountRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    VendorModule,
    VendorRemitAddressModule,
    NgxDatatableModule,
    DxButtonModule,
    DxDataGridModule,
    DxChartModule,
    MaterialModule,
    FlexLayoutModule,
    CustomFormsModule,
    ReportModule,
    DictionaryModule,
    InvoiceModule,
    TagInputModule,
    LocationModule,
    BuildingModule
  ],
  exports: [
    CaAccountPickerComponent,
    AccountManageDialogComponent
  ],
  providers: [
    AccountService,
    ApConfigurationService,
    AccountGridService,
    AccountStatService,
    AccountFilterService,
    AccountDetailsFilterService,
    CurrencyPipe,
    AccountDialogService
  ],
  declarations: [
    AccountManageDialogComponent,
    AccountListComponent,
    AccountSneakPeekComponent,
    AccountDetailsComponent,
    CaAccountPickerComponent,
    InvoiceTrendComponent
  ],
  entryComponents: [
    AccountManageDialogComponent
  ]
})
export class AccountModule {

}
