
import {of as observableOf, Observable} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AlertService } from "../../../shared/alert/alert.service";
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import {PageManageDialogComponent} from "../../../core/page-manage-dialog.component";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";
import {init} from "protractor/built/launcher";
import {EntityEditContext} from "../../../shared/entity-lock/entity-edit-context";
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {flatMap} from "rxjs/internal/operators";
import Query from "../../../core/query/query";
import {InvoiceBalanceErrorDialogComponent} from "../invoice-balance-error-dialog/invoice-balance-error-dialog.component";


@Component({
  selector: 'app-invoice-approval-manage',
  templateUrl: './invoice-approval-manage.component.html',
})
export class InvoiceApprovalManageComponent extends PageManageDialogComponent implements OnInit {

  note: string;
  public form: FormGroup;
  invoice: any;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "invoice";

  constructor(public formBuilder: FormBuilder,
              public invoiceService: InvoiceFacepageService,
              public dialog: DialogService,
              public dialogRef: MatDialogRef<InvoiceApprovalManageComponent>,
              public dialogService: DialogService,
              public alertService: AlertService) {
    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
      this.form = this.formBuilder.group({
        note: ["", Validators.minLength(20)]
      });
  }

  init() {
    if (this.invoice && this.invoice.id) {
      return this.invoiceService.findByIdForApprovedEdit(this.invoice.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .pipe(flatMap((invoice) => {
          this.invoice = invoice;
          return observableOf(invoice);
        }))
    }
  }

  async approve() {
    let newQuery = new Query({where: {invoice_id: {$in: [this.invoice.invoice_id]}}})
    const isInvoiceValid = await this.invoiceService.getNonBalancedInvoices(newQuery).toPromise()
    if(!isInvoiceValid[0]){
      this.dialogRef.close({
        approved: true
      });
      this.invoiceService.completeEdit();
    }else {
      this.dialog.open(InvoiceBalanceErrorDialogComponent,
        {
          invoice: this.invoice
        })
    }
  }


  reject({ value, valid }: { value: any, valid: boolean }) {
    if (valid) {
      this.dialogRef.close({
        rejected: true,
        note: value.note
      });
      this.invoiceService.completeEdit();
    } else {
      this.alertService.error('', this.messages.get('CHARACTER_LENGTH_ERROR'));
    }
  }


  cancel() {
    this.invoiceService.cancelEdit();
    this.closeDialog();
  }

}
