<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Upload Document</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">

  <form class="ca-form dialog-content-no-margin" fxLayout="column">
    <div class="flex-container" fxLayout="column">
      <input class="ca-document-file-input" #fileInput placeholder="Document" type="file"
             (change)="onFileSelect($event)">
      <div class="ca-dropzone ca-default-border-color" caDropzone (dropped)="onDrop($event)" fxLayout="column">
        <div class="text-wrapper" fxLayoutAlign="center center">
          <ca-icon [key]="'FILE_CLOUD_UPLOAD'" [innerClass]="'upload-icon'"></ca-icon>
          <span class="document-upload-text"> Drop file to upload or</span>
          <button type="button" class="document-upload-button flat-button" mat-button (click)="fileInput.click()">
            Browse
          </button>
        </div>
        <div [ngClass]="{'ca-file-name-taken-error-text': (fileNameTaken !== null && fileNameTaken)}" class="text-wrapper selected-file" fxLayoutAlign="center center" *ngIf="fileNameTaken !== null">
          <i>{{ fileName }} <span *ngIf="fileNameTaken !== null && fileNameTaken">already exists</span></i>
        </div>
      </div>
    </div>

    <div class="flex-container ca-margin-top-15" fxLayout="row wrap" *ngIf="entities.length" fxLayoutGap="24px">

      <div class="flex-item" fxFlex="40%">
        <ca-default-picker caInput>
          <mat-form-field>
              <mat-select placeholder="Entity"
              [(ngModel)]="internalEntityId"
              [required]="true"
              [ngModelOptions]="{standalone: true}"
              (selectionChange)="onEntitySelectionChange($event)"
              (change)="onEntitySelectionChange($event)">
               <mat-option *ngFor="let entity of entities" [value]="entity.id">{{entity.title}}</mat-option>
             </mat-select>
          </mat-form-field>
        </ca-default-picker>
      </div>

      <!-- <div class="flex-item" fxFlex>
          <ca-default-picker caInput>
            <mat-form-field>
                <mat-select placeholder="Line Item"
                class="ca-picker"
                [(ngModel)]="lineItem"
                [ngModelOptions]="{standalone: true}"
                (selectionChange)="onLineItemSelectionChange($event, selectedEntity)"
                (change)="onLineItemSelectionChange($event, selectedEntity)"
                [disabled]="!selectedEntity || (selectedEntity && !selectedEntity.entities.length)">
                <ng-container *ngIf="selectedEntity && selectedEntity.entities.length">
                  <mat-option class="two-line" *ngFor="let lineItem of selectedEntity.entities" [value]="lineItem.id">
                    {{lineItem.title}}
                    <span *ngIf="lineItem.subtitle" class="second-line">{{lineItem.subtitle || 'N/A'}}</span>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </ca-default-picker>
        </div> -->

    </div>

    <div class="flex-container" fxLayout="row" [ngStyle]="{'margin-top': !entities.length ? '24px' : '0'}">
      <div class="flex-item" fxFlex>
        <mat-form-field caInput>
          <textarea [(ngModel)]="description" matInput name="description" placeholder="Description" rows="2"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>

</div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="submit" (click)="close()">
    Cancel
  </button>
  <button mat-button type="submit" [disabled]="isSaveButtonDisabled()"
          (click)="onSubmit(files, description)">Save
  </button>
</mat-dialog-actions>
