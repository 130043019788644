<app-svc-order-details-toolbar
  [isEditSvcOrderDisabled]="false"
  [isEditSvcOrderHidden]="false"
  [isAddServicesHidden]="true"
  [isEditServicesHidden]="true"
  [isAddTermsConditionHidden]="true"
  [isEditTermsConditionHidden]="true"
  [isDeleteTermsConditionHidden]="true"
  [isDownloadAttachmentHidden]="true"
  [isDeleteAttachmentHidden]="true"
  [svcOrderHeader]="svcOrderHeader"
  [isFilterHidden]="true"
  (backRequested)="back()"
  (editSvcOrderRequested)="updateSvcOrder()"
>
</app-svc-order-details-toolbar>

<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-svc-order-details-tabs
    [activeTabIndex]="0"
    [svcOrderHeader]="svcOrderHeader"
  ></app-svc-order-details-tabs>
  <div class="tabs-body">
    <div class="flex-container ca-card" fxLayout="row" fxLayout.sm="column">
      <mat-card fxFlex="40%" class="flex-item" fxFlex.sm="70%" caCard>
        <mat-card-header> Svc Order Info </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Svc Order Name</label>
              <p>
                {{ svcOrderHeader?.name || "N/A" }}
              </p>
            </div>
            <div fxFlex class="entry-list-item">
              <label>Vendor</label>
              <p>
                {{ svcOrderHeader?.vendor?.name || "N/A" }}
              </p>
            </div>
            <div fxFlex class="entry-list-item">
              <label>Vendor SO Number </label>
              <p>
                {{ svcOrderHeader?.vendor_so_number || "N/A" }}
              </p>
            </div>
          </div>
          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Total MRC</label>
              <p>{{ svcOrderHeader?.total_mrc || 0 | caCurrency : "USD" }}</p>
            </div>
            <div fxFlex class="entry-list-item">
              <label>Total NRC</label>
              <p>{{ svcOrderHeader?.total_nrc || 0 | caCurrency : "USD" }}</p>
            </div>
            <div fxFlex class="entry-list-item">
              <label>Internal SO Number </label>
              <p>
                {{ svcOrderHeader?.internal_so_number || "N/A" }}
              </p>
            </div>
          </div>
          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Description</label>
              <p>{{ svcOrderHeader?.description || "N/A" }}</p>
            </div>
            <div fxFlex class="entry-list-item">
              <label>So Date </label>
              <p>
                {{ (svcOrderHeader?.so_date | caDate) || "N/A" }}
              </p>
            </div>
            <div fxFlex class="entry-list-item"></div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-sidenav-container>
