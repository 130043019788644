import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FilterBaseComponent} from '../filter-base.component';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FilterEntry} from "../../../core/filter/filter-entry";
import {FilterService} from "../../../core/filter/filter.service";
import {map} from "rxjs/operators";

const OPERATORS = {
  IN: '$in',
};

@Component({
  selector: 'app-filter-checkbox-list',
  templateUrl: './filter-checkbox-list.component.html',
  styleUrls: ['./filter-checkbox-list.component.scss']
})
export class FilterCheckboxListComponent implements FilterBaseComponent, OnInit {

  private _value: any;

  get value(): any {
    return this._value;
  }

  @Input() set value(value: any) {
   // const val = value?.currentValue ?? value;
    const currentValue = value?.hasOwnProperty('currentValue') ? value?.currentValue : value;
    if (currentValue !== this._value) {
      this._value = currentValue;
      this.updateSelection(currentValue);
    }
  }

  @Input() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() entry: FilterEntry;
  @Input() filterService: FilterService;

  options: any;
  output: any;

  ngOnInit(): void {
    if (this.entry?.lookupProviderName) {
      const provider = this.filterService.getLookupProvider(this.entry?.lookupProviderName).provider;
      provider.findAll()
        .pipe(map((x) => provider.map ? provider.map(x) : x))
        .subscribe((options) => {
          this.options = options.filter(x => !!x.key);
          this.updateSelection(this.value);
        });
    }
  }

  onSelectionChange(change: MatCheckboxChange): void {
    const option = this.options.find(o => o.key === change.source.value);
    option.checked = change.checked;
    this.update();
  }

  private updateSelection(value: any): void {
    if (this.options) {
      const keys = this.getSimpleValue(value) || [];
      this.options.forEach((option) => {
        option.checked = keys.indexOf(option.key) > -1;
      });
    }
  }

  private getSimpleValue(value): any {
    if (typeof value === 'object') {
      return Object.values(value)[0];
    }
    return value;
  }

  private update(): void {
    const result = this.getValue();
    this.output = result;
    this.change.emit(result);
  }

  private getValue(): any {
    const selection = this.options
      .filter(o => o.checked)
      .map(x => x.key);
    const operator = OPERATORS.IN;
    if (!selection || !selection.length) {
      return null;
    } else {
      return {[operator]: selection};
    }
  }
}
