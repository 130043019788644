import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class InventoryOrderGridService extends GridService {

  defaultColumns = [
    {
      caption: 'Full Service Id',
      dataField: 'full_service_id',
      width: 250
    },
    {
      caption: 'SP Ckt ID',
      dataField: 'inventory.sp_ckt_id',
      width: 250
    },
    {
      caption: 'BAN',
      dataField: 'inventory.ban',
      width: 250
    },
    {
      caption: 'Desired Due Date',
      dataField: 'des_due_date',
      width: 120,
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'right',
    },
    {
      caption: 'Expedited Order',
      dataField: 'expedited_order',
      cellTemplate: 'expeditedOrderTemplate',
      width: 120,
      transformValue: {
        true: 'Yes',
        false: 'No'
      }
    },
    {
      caption: 'Description',
      dataField: 'description',
      width: 120
    },
    {
      caption: 'Type',
      dataField: 'order.type.value',
      width: 100
    }
  ];

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.inventory.inventory-order-grid", userSettings);
  }

  // columns() {
  //   return [
  //     {
  //       caption: 'Full Service Id',
  //       dataField: 'full_service_id',
  //       width: 250
  //     },
  //     {
  //       caption: 'SP Ckt ID',
  //       dataField: 'sp_ckt_id',
  //       width: 250
  //     },
  //     {
  //       caption: 'BAN',
  //       dataField: 'ban',
  //       width: 250
  //     },
  //     {
  //       caption: 'Desired Due Date',
  //       dataField: 'des_due_date',
  //       width: 120,
  //       dataType: 'date',
  //       cellTemplate: 'dateTemplate',
  //       alignment: 'right',
  //     },
  //     {
  //       caption: 'Expedited Order',
  //       dataField: 'expedited_order',
  //       cellTemplate: 'expeditedOrderTemplate',
  //       width: 120,
  //       transformValue: {
  //         true: 'Yes',
  //         false: 'No'
  //       }
  //     },
  //     {
  //       caption: 'Description',
  //       dataField: 'description',
  //       width: 120
  //     },
  //     {
  //       caption: 'Type',
  //       dataField: 'type',
  //       width: 100
  //     }
  //   ];
  // }

}
