import { Injectable, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AlertService {
  public onAlertClosed = new EventEmitter();

  constructor(
    public snackBar: MatSnackBar) {
  }

  success(title, body, duration?) {
    this.snackBar.open(body, null, { duration: duration || 5000 ,panelClass: ['success-snackbar']},);
  }

  error(title, body) {
    this.snackBar.open(body, null, { duration: 5000 });
  }

  info(title, body) {
    this.snackBar.open(body, null, { duration: 5000 });
  }

  warning(title, body) {
    this.snackBar.open(body, null, { duration: 5000 });
  }

  confirm(body, action) {
    return this.snackBar.open(body, action);
  }

  openAndClose(type: string, title, body) {
    const TYPES = {
      'OPEN': 'open',
      'CLOSE': 'close'
    };

    const open = () => {
      this.snackBar.open(body, null);
    }

    const close = () => {
      this.snackBar.open(body, null, { duration: 2000 });
    }

    if (type === TYPES.OPEN) {
      open();
    } else if (type === TYPES.CLOSE) {
      close();
    }
  }

  actionError(title, body) {
    if (!this.snackBarRef) {
      this.snackBarRef = this.snackBar.open(body, 'OK', {panelClass: ['error-with-action']});
      this.snackBarRef.onAction().subscribe(() => {
        this.snackBarRef = null;
        this.onAlertClosed.emit(true)
      });
    } else {
      this.snackBarRef.afterDismissed().subscribe(() => {
        this.snackBarRef = this.snackBar.open(body, 'OK', {panelClass: ['error-with-action']});
        this.snackBarRef.onAction().subscribe(() => {
          this.snackBarRef = null;
          this.onAlertClosed.emit(true)
        });
      });
      this.snackBarRef.dismiss();
    }
  }

  snackBarRef;
}
