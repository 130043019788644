import {Injectable} from '@angular/core';
import {UserSettingsService} from "../../user/core/user-settings.service";
import {GridService} from "../../shared/grid/grid.service";
import {FormatService} from "../../shared/format/format.service";

@Injectable()
export class AlertItemExclusionGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.alert.item-exclusion-grid", userSettings);
  }

  defaultColumns = [
    {
      caption: "ID",
      dataField: "item_id",
      width: 230
    },
    {
      caption: "Vendor",
      dataField: "vendor_name",
      width: 150
    },
    {
      caption: "Account",
      dataField: "account_no",
      width: 200
    }
  ];

}
