import {QUOTE_HEADER_STATUS} from './quote-header-status.enum';

export const quotingFilterContext = {
  filters: [
    {
      field: 'quote_header_status',
      label: 'Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'quoteStatus',
      defaultValue: [QUOTE_HEADER_STATUS.OPEN],
      index: 1
    },
    {
      field: 'id',
      label: 'Quote No',
      type: 'number',
      index: 2,
      visible: true
    },
    {
      field: 'requester.full_name',
      label: 'Requester',
      type: 'lookup',
      lookupProviderName: 'employee',
      index: 3,
      visible: true
    },
    {
      field: 'customer.company',
      label: 'Customer',
      type: 'lookup',
      lookupProviderName: 'customer',
      index: 4,
      visible: true
    },
    {
      field: 'quote_description',
      label: 'Description',
      type: 'string',
      index: 5,
      visible: true
    },
    {
      field: 'request_date',
      label: 'Request Date',
      index: 6,
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      visible: true
    },
    {
      field: 'opportunity_type.value',
      label: 'Opportunity Type',
      type: 'lookup',
      lookupProviderName: 'opportunity_type',
      index: 7,
      visible: true
    },
    {
      field: 'sales_engineer.full_name',
      label: 'Sales Engineer',
      type: 'lookup',
      lookupProviderName: 'sales_engineer',
      index: 8,
      visible: true
    },
    {
      field: 'sales_representative.full_name',
      label: 'Sales Representative',
      type: 'lookup',
      lookupProviderName: 'sales_representative',
      index: 9,
      visible: true
    },
    {
      field: 'due_date',
      label: 'Due Date',
      index: 10,
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      visible: true
    },
  ]
};
