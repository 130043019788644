import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";

import {Observable, of, Subject} from "rxjs";
import {Inventory} from "../../core/inventory";
import {InventoryService} from "../../core/inventory.service";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {InventoryContractTermGridService} from '../../core/inventory-contract-term-grid-service';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

import Query from "../../../core/query/query";
import {AlertService} from "../../../shared/alert/alert.service";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {Select, Store} from "@ngxs/store";
import {UpdateInventoryContractTermsActiveSiderSection} from "./state/inventory-contract-terms.actions";
import {InventoryContractTermManageDialogComponent} from "../../shared/inventory-contract-term-manage-dialog/inventory-contract-term-manage-dialog.component";
import {InventoryContractTerm} from "../../core/inventory-contract-term";
import {LoaderService} from '../../../shared/loader/loader.service';
import {IMessagesResourceService, ResourcesService} from '../../../core/resources/resources.service';
import {SortingBuilder, SortingService} from '../../../shared/sorting/sorting.service';

@Component({
  selector: 'app-inventory-contract-terms',
  templateUrl: './inventory-contract-terms.component.html',
  // styleUrls:['./inventory-contract-terms.component.scss']
})
export class InventoryContractTermsComponent extends PageDetailsComponent  implements OnInit {
  inventory: Inventory;

  destroy$: Subject<boolean> = new Subject<boolean>();

  public contractTerms: Array<any> = [];
  public contractTermsColumns;
  public contractTermsQuery: Query = new Query();
  public contractTermsSelected: InventoryContractTerm;

  messages: IMessagesResourceService;
  contractTermsSorting: SortingBuilder;

  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  readonly MESSAGES_MODULE: string = 'inventory';
  sider: Sider;
  notesCount: number;
  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    NOTES_SECTION_NAME: 'notes',
    HISTORY_SECTION_NAME: 'history'
  };

  @ViewChild('panelSide') panelSide;
  @ViewChild('contractTermGrid') contractTermGrid: DxDataGridComponent;
  @Select(state => state.inventory_contract_terms.activeSiderSection) $activeSiderSection: Observable<any>;

  constructor(
    public inventoryService: InventoryService,
    public route: ActivatedRoute,
    public router: Router,
    public settingsService: UserSettingsService,
    public inventoryContractTermGridService: InventoryContractTermGridService,
    public alertService: AlertService,
    public loaderService: LoaderService,
    public dialogService: DialogService,
    public sortingService: SortingService,
    private readonly store: Store,
  ) {
    super(new PageContext({
      name: 'app.inventory.inventory-contract-terms',
      settings: settingsService
    }))
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.contractTermsSorting = this.sortingService.builder();

  }

  loadPermissions() {
    return of({});
  }


  private getInventory() {
    return this.route.parent.snapshot.data.inventory;
  }

  ngOnInit(): void {

    this.inventory = this.getInventory();

    // this.loadGlString();
    this.loadContractTerms(this.inventory.id);
    this.loadInventory(this.inventory.id)
      .subscribe(result => {
        this.inventory = result
      })
    // this.glRuleStringGridService.generateColumns('_obj')
    //   .then((columns: Array<any>) => {
    //     this.previewGridColumns = columns;
    //   });
    this.contractTermsColumns = this.inventoryContractTermGridService.columns();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.sider = this.createSider();

    this.inventoryContractTermGridService.create(this.contractTermGrid.instance, {
      noDataText: this.inventoryContractTermGridService.noDataMessage
    });
  }

  loadContractTerms(id?, query?) {
    this.loaderService.displayLoaderAndManageGrid([this.contractTermGrid]);

    if (!query) {
      query = new Query({where:{}});
    }
    query.where.inventory_id = this.inventory.id;
    this.contractTermsQuery = query;
    this.inventoryService.findAllContractTerms(this.inventory.id, query)
      .subscribe(
        result => {
          this.loaderService.hideLoaderAndManageGrid([this.contractTermGrid]);
          this.contractTerms = result.items;
          this.contractTermsQuery.total = result.total;
        },
        error => {
          this.loaderService.displayLoaderAndManageGrid([this.contractTermGrid]);
          this.alertService.error('', this.messages.get('FETCH_CIRCUITS_ERROR'));
        }
      );
  }

  loadInventory(id: number): any {
    return this.inventoryService.findById(id);
  }

  addContractTerm() {
    this.dialogService.open(InventoryContractTermManageDialogComponent, {
      contractTerm: new InventoryContractTerm(),
      inventoryId: this.inventory.id,
      vendorId: this.inventory.vendor_id,
      inventory: this.inventory
    }, {width: '600px'})
      .afterClosed()
      .subscribe(result => {
          if (result) {
            this.loadContractTerms(this.inventory.id);
          }
        },
        (error) => {
          this.alertService.error('', this.messages.get('CREATE_CONTRACT_TERM_ERROR'));
        });
  }

  editContractTerm() {
    let contractTerm = this.contractTermsSelected;

    this.dialogService.open(InventoryContractTermManageDialogComponent, {
      contractTerm: contractTerm,
      inventoryId: this.inventory.id,
      vendorId: this.inventory.vendor_id,
      inventory: this.inventory
    }, {width: '600px'})
      .afterClosed()
      .subscribe(result => {
          if (result) {
            this.loadContractTerms(this.inventory.id);
          }
        },
        (error) => {
          this.alertService.error('', this.messages.get('CREATE_CONTRACT_TERM_ERROR'));
        });
  }

  deleteContractTerm() {
    let contractTerm = this.contractTermsSelected;

    this.dialogService.confirm({
      bodyText: `Are you sure you want to delete Contract Term?`
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.inventoryService.deleteContractTerm(this.inventory.id, contractTerm.id)
              .subscribe(() => {
                this.alertService.error('', this.messages.get('DELETE_CONTRACT_TERM_SUCCESS'));
                this.loadContractTerms(this.inventory.id);
              })
          }
        },
        (error) => {
          this.alertService.error('', this.messages.get('DELETE_CONTRACT_TERM_ERROR'));
        }
      );
  }

  onContractTermSelectionChange(row) {
    this.contractTermsSelected = <InventoryContractTerm>row.selectedRowsData[0];
  }

  public onContractTermCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.contractTermsSorting.apply(event, this.contractTermsQuery);
      this.loadContractTerms(this.inventory.id, this.contractTermsQuery);
    }
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    // TODO: delete this / used $activeSiderSection to reopen sider when back button is used
    // sider.onChange((section: SiderSection) => {
    //   // TODO: Save to state
    //   // this.saveSettings({siderSection: section.name});
    // });

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInventoryContractTermsActiveSiderSection(activeSection.name)
    ]);
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/inventory']);
    }
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME)
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME)
  }
}
