export const disputeChargeFilterContext = {
    filters: [
        {
            field: 'dispute_charge_id.disp_chg_id',
            label: 'Dispute Charge ID',
            type: 'string'
        },
        {
            field: 'charge.acct_level_1',
            label: 'Account',
            type: 'lookup',
            lookupProviderName: 'account'
        },
        {
            field: 'charge.invoice.account.subclient.value',
            label: 'Subclient',
            type: 'lookup',
            lookupProviderName: 'subclient',
        },
        {
            field: 'charge.invoice.sp_inv_num',
            label: 'Invoice #',
            type: 'string'
        },
        {
            field: 'charge.invoice.inv_date',
            label: 'Invoice Date',
            type: 'lookup',
            editor: 'dateRanges',
            lookupProviderName: 'defaultDateRanges'
        },
        {
            field: 'charge.invoice.header.updated_at',
            label: 'Last Update',
            type: 'lookup',
            editor: 'dateRanges',
            lookupProviderName: 'defaultDateRanges'
        },
        {
            field: 'charge.chg_class',
            label: 'Charge Type',
            type: 'lookup',
            lookupProviderName: 'chargeType',
            editor: 'checkboxList'
        },
        {
            field: 'charge.sp_serv_id',
            label: 'SPID',
            type: 'string'
        },
        {
            field: 'charge.chg_desc_1',
            label: 'Charge Desc 1',
            type: 'string'
        },
        {
            field: 'charge.chg_desc_2',
            label: 'Charge Desc 2',
            type: 'string'
        },
        {
            field: 'charge.beg_chg_date',
            label: 'Beg Chg Date',
            type: 'lookup',
            editor: 'dateRanges',
            lookupProviderName: 'defaultDateRanges'
        },
        {
            field: 'charge.chg_qty1_billed',
            label: 'Charge Qty',
            type: 'number'
        },
        {
            field: 'charge.chg_rate',
            label: 'Charge Rate',
            type: 'number'
        },
        {
            field: 'charge.chg_amt',
            label: 'Billed Amount',
            type: 'number'
        },
        {
            field: 'category.value',
            label: 'Dispute Category',
            type: 'string'
        },
        {
            field: 'description',
            label: 'Dispute Description',
            type: 'string'
        },
        {
            field: 'calculated_amount',
            label: 'Calculated Charges',
            type: 'number'
        },
        {
            field: 'disputed_amount',
            label: 'Dispute Amount',
            type: 'number'
        },
        {
            field: 'dispute_value_awarded',
            label: 'Dispute Amount Awarded',
            type: 'number'
        },
        {
            field: 'payback_amount',
            label: 'Payback Amount',
            type: 'number'
        },
        {
            field: 'dispute_withheld',
            label: 'Withheld',
            type: 'boolean'
        },
        {
            field: 'resolution_date',
            label: 'Resolution Date',
            type: 'lookup',
            editor: 'dateRanges',
            lookupProviderName: 'defaultDateRanges'
        },
        {
            field: 'status_obj.value',
            label: 'Status',
            type: 'string'
        },
    ]
  };
