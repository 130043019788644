import {Component, OnInit} from '@angular/core';
import {LoaderService} from "./loader.service";

@Component({
  selector: 'ca-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  public loaderVisible: boolean = false;
  public navigationLoaderVisible: boolean = false;

  constructor(public loaderService: LoaderService) {
    this.loaderService.subscribe((data) => {
      this.loaderVisible = data.visible;
    })
  }

  ngOnInit() {

    this.loaderVisible = this.loaderService.isLoaderVisible();
    this.navigationLoaderVisible = this.loaderService.isLoaderVisible(true);

  }

}
