import { Component, Input } from '@angular/core';
import { FilterEntry } from '../filter-entry';
import Query from 'app/core/query/query';

@Component({
  selector: 'ca-order-no-service-no-filter',
  templateUrl: 'order-no-service-no-filter.component.html'
})
export class OrderNoServiceNoFilterComponent {
  @Input('entry') entry: FilterEntry;
  @Input('query') query: Query;

  serviceNoQuery: any = {
    from: null,
    to: null,
    field: 'service_id'
  };

  ordereNoQuery: any = {
    from: null,
    to: null,
    field: 'order_id'
  };

  public readonly FOCUS = {
    FROM: 'from',
    TO: 'to'
  };
  constructor() {}

  serviceNoChange() {
    const { from, to, field } = this.serviceNoQuery;

    this.changeQuery(from, to, field);
  }

  orderNoChange() {
    const { from, to, field } = this.ordereNoQuery;

    this.changeQuery(from, to, field);
  }

  private changeQuery(from: any, to: any, field: any) {
    if (from !== null && to !== null) {
      this.query.where[field] = { $between: [from, to] };
    } else if (from !== null) {
      this.query.where[field] = { $gte: from };
    } else if (to !== null) {
      this.query.where[field] = { $lte: to };
    } else {
      delete this.query.where[field];
    }
  }

  public isServiceNoDisalbled(): boolean {
    const { from, to } = this.ordereNoQuery;
    return !(from || to);
  }

  public clearFilter() {
    this.resetFilters();
  }

  private resetFilters(): void {
    const empty = { from: null, to: null }
    Object.assign(this.ordereNoQuery, empty);
    Object.assign(this.serviceNoQuery, empty);
  }
}
