import {Injectable} from "@angular/core";
import {FilterService} from "../../core/filter/filter.service";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {DictionaryService} from "../../dictionary/core/dictionary.service";
import {alertInventoryMrcFilterContext} from "./alert-inventory-mrc-filter.context";

@Injectable()
export class AlertInventoryMrcFilterService extends FilterService {

  constructor(public userSettingsService: UserSettingsService,
              public dictionaryService: DictionaryService) {
    super("app.cost.invoice-inventory-mrc-filter", alertInventoryMrcFilterContext, userSettingsService);

  }

}
