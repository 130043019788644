<app-invoice-details-toolbar
  (toggleNotesRequested)="toggleSider(SECTIONS.NOTES_SECTION_NAME)"
  [isNotesOn]="($activeSiderSection | async) == SECTIONS.NOTES_SECTION_NAME"
  (toggleHistoryRequested)="toggleSider(SECTIONS.HISTORY_SECTION_NAME)"
  [isHistoryOn]="($activeSiderSection | async) == SECTIONS.HISTORY_SECTION_NAME"

  [isCSVDisabled]="true"
  [isDoNotProcessHidden]="true"
  [isGoToRuleExecutionPageHidden]="false"
  [isDocumentTypeHidden]="true"
  [isAddDisputeHidden]="true"
  [isDeleteInvoiceHidden]="false"
  [isOnHoldHidden]="false"
  [isOnHoldDisabled]="true"
  [isChangeAdjustmentHidden]="true"
  [isGLChargeViewSwitchHidden]="true"
  [isAddDirectGLHidden]="true"
  [isEditDirectGLHidden]="true"
  [isGridSettingsHidden]="false"
  [isGridSettingsDisabled]="true"
  [isFilterDisabled]="true"
  [isFilterHidden]="false"

  [isDropDownFileHidden] = "false"
  [isDropDownCsvHidden] = "false"
  [isDropDownCsvDisabled] = "true"
  [isDropDownUploadDocumentDisabled] = "true"

  [isDownloadContainerHidden]="true"
  [isDownloadPDFHidden]="true"
  [isCSVHidden]="true"
  [notesCount]="notesCount"

  (backRequested)="back()"
></app-invoice-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-invoice-details-tabs [activeTabIndex]="5" [invoice]="invoice"></app-invoice-details-tabs>
  <div class="app-content app-content-list tabbed-content tabbed-content-no-scroll-except-first-ch" style="top: 0;">
    <!-- Tabs Body -->
    <div class="tabs-body tabs-disable-scroll" fxLayout="column">
      <div class="ca-card" fxLayout="column" style="height: 100%">
        <div
          fxFlex="0 0 270px"
          class="flex-container"
          style="position: relative;"
        >
          <mat-card class="card-table">
            <mat-card-header>Notes</mat-card-header>
            <mat-card-content>
              <dx-data-grid
                class="app-grid-with-pager invoice-notes-content"
                #invoiceNotesGrid
                [columns]="invoiceNotesColumns"
                [dataSource]="notes"
              >
              </dx-data-grid>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="1 0 auto" class="flex-container" style="position: relative;">
          <mat-card class="card-table">
            <mat-card-header>Contacts</mat-card-header>
            <mat-card-content>
              <dx-data-grid
                class="app-grid-with-pager invoice-contacts-content"
                #invoiceContactsGrid
                [columns]="invoiceContactsColumns"
                [dataSource]="contacts"
              >
                <div *dxTemplate="let d of 'phoneTemplate'">
                  <div caGridTooltip class="right">{{ d?.value | caPhone }}</div>
                </div>
              </dx-data-grid>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <mat-sidenav #panelSide
               [mode]="'side'"
               position="end"
               [opened]="false">
    <ca-history
      #history
      [entityId]="invoice?.invoice_id"
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME) }"
      [entityType]="'invoice'"
      [invoice]="invoice"
    ></ca-history>
    <ca-notes
      #testNotes
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
      [entityId]="invoice?.invoice_id"
      [entityType]="SYSTEM_MODULE.INVOICE"
      (countChanged)="onNotesCountChanged($event)"
      (notesCountChanged)="getDNPInfo($event)"
    >
      <ca-account-notes [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                        [entityId]="invoice?.account?.id"
                        [entityType]="SYSTEM_MODULE.ACCOUNT"
                        [entityName]="'Account'"
                        [enableReply]="false"></ca-account-notes>
    </ca-notes>
  </mat-sidenav>
</mat-sidenav-container>
