import { Component, Input, Output, EventEmitter } from "@angular/core";
import Query from "app/core/query/query";
import {path} from 'ramda';

export interface Count {
  label: string,
  field: string
}


export interface TypePickerOption {
  label: string,
  value: any,
  field: string
}

export interface TypePicker {
  label: string,
  options: Array<TypePickerOption>,
  field: string
}

export interface TopUsageFilterEntry {
  count: Count,
  dateRangePicker: any,
  typePicker: TypePicker,
  label: string
}

@Component({
  selector: 'ca-top-usage-filter',
  templateUrl: './top-usage-filter.component.html',
})
export class TopUsageFilterComponent {
  private _entry: any = {};
  @Input() set entry(value) {
    this._entry = {
      label: value.label,
      ...value.topUsage
    };
  };
  get entry() {
    return this._entry;
  }
  @Input() query: Query;
  @Output() changed: EventEmitter<Query> = new EventEmitter();

  private emit(name, value) {
    const query = this.query;
    Object.assign(query, {
      filterMeta: {
        ...query.filterMeta,
        topUsage: {
          ...query.filterMeta.topUsage,
          [name]: value
        }
      }
    });
    this.changed.emit(query);
  }

  handleTypePickerChange(evt) {
    this.emit(this.entry.typePicker.field, evt.value); 
  }


  handleCountChange(evt) {
    this.emit(this.entry.count.field, evt.target.value);
  }

  handleDateRangeChange(query) {
    const value = query.where[this.entry.dateRangePicker.field];
    delete query.where[this.entry.dateRangePicker.field];
    this.emit(this.entry.dateRangePicker.field, value);
  }
}
