import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from 'app/material-barrel/material-barrel.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AddressSelectionComponent} from './shared/address-selection/address-selection.component';
import {CoreModule} from '../core/core.module';
import {SharedModule} from '../shared/shared.module';
import {LocationModule} from '../location/location.module';
import {AddressService} from './core/address.service';
import {AddressComponent} from './address.component';
import {AddressRoutingModule} from './address-routing.module';

@NgModule({
  imports: [
    CommonModule,
    AddressRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    LocationModule,
    FlexLayoutModule,
    MaterialModule,
    CoreModule
  ],
  exports: [
    AddressSelectionComponent
  ],
  declarations: [
    AddressComponent,
    AddressSelectionComponent
  ],
  providers: [AddressService]
})
export class AddressModule {
}
