
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { clone, pluck } from 'ramda';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { dropdownEnabledOptions } from 'app/dictionary/core/dropdown-enabled-options';

import { PageListComponent } from '../../core/page-list.component';
import { DictionaryService } from '../core/dictionary.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { AlertService } from '../../shared/alert/alert.service';
import { DictionaryGridService } from '../core/dictionary-grid.service';
import { DictionaryReorderComponent } from '../shared/dictionary-reorder/dictionary-reorder.component';
import { DictionaryManageDialogComponent } from '../shared/dictionary-manage-dialog/dictionary-manage-dialog.component';
import { PageContext } from '../../core/page.context';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { LookupModel } from '../core/lookup.model';
import { DataLockService } from '../../core/data-lock/data-lock.service';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { LOOKUP_MODELS_ENUM } from '../core/lookup-models.enum';
import { LOOKUP_ENUM } from '../core/lookup.enum'
import {LoaderService} from "../../shared/loader/loader.service";
import {ConfigService} from "../../core/config/config.service";
import {Config} from "../../core/config/config";

@Component({
  selector: 'app-dictionary-list',
  templateUrl: './dictionary-list.component.html',
  styleUrls: ['./dictionary-list.component.css']
})
export class DictionaryListComponent extends PageListComponent implements OnInit, AfterViewInit {
  reorderingItem;
  public selection: LookupModel;
  public lookupItems: Array<LookupModel>;
  public lookupGroups = [];
  lookupGroupsWithParent = [];
  public selectedIndex = -1;
  public selectedGroup: LookupModel;
  public selectedChild = { value: 'all' };
  @ViewChild('accordion') accordion: ElementRef;

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'dictionary';
  readonly CORE_MODULE: string = "core";

  readonly SITE_TYPES_ENUM = {
    1: 'Customer',
    2: 'Vendor',
    3: 'Internal'
  };

  readonly NETWORK_HUB_TYPE_ENUM = {
    10: 'TDM',
    20: 'Ethernet'
  };

  CONTRACT_SCHEDULE_CATEGORY_ENUM = {};

  readonly INVENTORY_CKT_TYPE = LOOKUP_ENUM.INVENTORY_CKT_TYPE;

  LOOKUP_NETWORK_HUB_CAPACITY_DISPLAY_NAME = LOOKUP_MODELS_ENUM.NETWORK_HUB_CAPACITY.displayName;
  LOOKUP_BANDWIDTH_DISPLAY_NAME = LOOKUP_MODELS_ENUM.BANDWIDTH.displayName;
  LOOKUP_INVENTORY_TECHNOLOGY_DISPLAY_NAME = LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.displayName;
  CONTRACT_SCHEDULE_SERIES_NAME = LOOKUP_MODELS_ENUM.CONTRACT_SCHEDULE_SERIES.displayName;
  CONTRACT_SCHEDULE_CATEGORY_NAME = LOOKUP_MODELS_ENUM.CONTRACT_SCHEDULE_CATEGORY.displayName;
  ACCOUNT_SUBCLIENT_NAME = LOOKUP_MODELS_ENUM.SUBCLIENT.displayName;

  public filteredDropdownOptions = JSON.parse(JSON.stringify(dropdownEnabledOptions));
  totalItems: number;

  usingSubclientEntity = false;
  usingSubclientCurrency = false;

  constructor(public dictionaryService: DictionaryService,
              public dialogService: DialogService,
              public alertService: AlertService,
              public settingsService: UserSettingsService,
              public dictionaryGridService: DictionaryGridService,
              public loaderService: LoaderService,
              public dataLockService: DataLockService,
              private configService: ConfigService) {
    super(new PageContext({
      name: 'app.dictionary.dictionary-list',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  loadData(onlyRefresh?: boolean) {
    if (!onlyRefresh) {
      this.selectedGroup = null;
      this.selectedIndex = -1;
      this.selection = null;
      this.reorderingItem = null;
    }

    this.configService.findAll().subscribe((config: Config) => {
      this.usingSubclientEntity = config?.subclient?.using_entity_id;
      this.usingSubclientCurrency = config?.subclient?.using_base_currency;
    })

    this.dictionaryService.findFromAllLookups()
      .subscribe((results) => {
        this.lookupItems = results.items;
        this.totalItems = results.total;
        let distinctGroups = {};
        this.lookupItems.forEach((item) => {
          if (distinctGroups[item.lookup_model]) {
            distinctGroups[item.lookup_model].items.push(item);
          } else {
            distinctGroups[item.lookup_model] = {
              items: [item],
              name: item.display_name
            }
          }
        });
        this.lookupGroups = Object.keys(distinctGroups).map(it => distinctGroups[it]);
        this.filteredDropdownOptions = JSON.parse(JSON.stringify(dropdownEnabledOptions));

        this.selectedChild = { value: 'all' };

        this.lookupGroupsWithParent = this.lookupGroups.filter(item => {
          if (item.items[0].parent) {
            this.hasChildren(item);
            return item;
          }
        });

        if (!onlyRefresh) {
          // scroll to top
          this.accordion.nativeElement.scrollTo(0, 0);
        }

        this.lookupGroups
          .filter(item => item.name === this.CONTRACT_SCHEDULE_CATEGORY_NAME)[0].items.map(item => {
          this.CONTRACT_SCHEDULE_CATEGORY_ENUM[item.id] = item.value;
        });
      });
  }

  selectGroup(group, clear) {
    if (this.selectedGroup === group) {
      this.selectedGroup = null;
    }
    if (clear) {
      this.selectedGroup = group;
    }

    this.selectedChild['value'] = 'all';
  }

  hasChildren(item) {
    this.lookupGroups.map(dictionary => {
      if (item.items[0].parent === dictionary.name) {
        dictionary.items.forEach((dictionaryItem) => {
          if (dictionaryItem.site_type_id === 1) {
            this.filteredDropdownOptions[0]['enabled'] = true;
          }

          if (dictionaryItem.site_type_id === 2) {
            this.filteredDropdownOptions[1]['enabled'] = true;
          }

          if (dictionaryItem.site_type_id === 3) {
            this.filteredDropdownOptions[2]['enabled'] = true;
          }
        })
      }
    })
  }

  public dictionaryHasChildren(parent) {
    let hasChildren = false;
    this.lookupGroupsWithParent.filter(item => {
      hasChildren = item.items[0].parent === parent;
    });
    return hasChildren;
  }

  onItemSelect(lookupItem, group) {
    this.selection = lookupItem;
  }

  typeChange() {
    this.selection = null;
  }

  refresh() {
    this.loadData();
  }

  newDictionary() {
    this.dialogService.open(DictionaryManageDialogComponent, {
      dictionary: {}
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.alertService.success('', this.messages.get('CREATE_SUCCESS'));
          this.loadData(true);
        }
      });
  }

  afterModalClosed(result) {
    if (result) {
      this.refresh();

      if (result.cancelByTimer || result.status == 2) {
        return this.alertService.success('', this.coreMessages.get('TIMER_EXPIRED'));
      } else if (result.deleted) {
        setTimeout(() => {
          this.alertService.snackBar.dismiss();
          this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
        }, 255);
      } else if (result.id) {
        this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
      }
    }
  }

  editDictionary(dictionary) {
    this.dictionaryService
      .findLookupByIdForEdit(dictionary.lookup_model, dictionary.id)
      .subscribe((data) => {
        if (data) {
          this.dialogService
            .edit(DictionaryManageDialogComponent, {
              dictionary: data,
              isEdit: true,
            })
            .subscribe((modalData) => {
              observableOf(modalData)
                .pipe(mergeMap((x) => x.afterClosed()))
                .subscribe((result) => {
                  this.afterModalClosed(result)
                });
            });
        }
      });
  }

  csv() {
    this.dictionaryGridService.csvMap().subscribe(fields => {
      this.dictionaryService.exportToCSV(
        'dictionary',
        {
          fields,
          fetchDataHandler: (csvQuery) => {
            csvQuery.total = this.totalItems;
            return  this.dictionaryService.findFromAllLookups(csvQuery);
          },
        }
      );
    });
  }

  reorder(event, data: any) {
    this.reorderingItem = data;
    event.stopPropagation();

    let lookup: LookupModel;

    if (data && data.items && data.items[0]) {
      lookup = new LookupModel(data.items[0])
    } else if (data && data.collapsedItems && data.collapsedItems[0]) {
      lookup = new LookupModel(data.collapsedItems[0])
    }

    if (lookup) {
      this.dialogService.open(DictionaryReorderComponent, { lookup: lookup, lookupItems: clone(data.items) })
        .afterClosed()
        .subscribe((result) => {
          this.loaderService.displayLoader();
          if (result) {
            setTimeout(() => {
              this.loadData(true);
              this.loaderService.hideLoader();
              this.alertService.success('', this.messages.get('REORDER_SUCCESS'));
            }, 1000);
          }
        });
    }
  }

  // Used to re-render only changed lookup groups, not everything in the DOM
  trackByFn(index, item) {
    return item.items ? item.items.map(childItem => `${item.name}-${childItem.id}`).join(':') : item.name;
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  public isSystem(data: any) {
    let isSys = false;
    if (data && data.items && data.items[0]) {
      isSys = !!data.items[0].system;
    } else if (data && data.collapsedItems && data.collapsedItems[0]) {
      isSys = !!data.collapsedItems[0].system;
    }
    return isSys;
  }
}
