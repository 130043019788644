import { Injectable } from '@angular/core';
import { Restangular } from "ngx-restangular";
import { BaseService } from '../../core/base.service';
import Query from "../../core/query/query";

@Injectable()
export class LocationService extends BaseService<any> {

  public name = 'country';

  constructor(public restangular: Restangular) {
    super('location', restangular);
  }

  public service() {
    return this.restangular;
  }

  // public toFilter(query: any): any {
  //   return { filter: encodeURIComponent(JSON.stringify(query)) };
  // }

  findAllEnabled(query?: Query): any {

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().all(this.name).customGET(null, this.toFilter(transformedQuery));
  }

  findLookupCountries(query?: Query): any {

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().all(this.name).all('countries').customGET(null, this.toFilter(transformedQuery));
  }

  findCountryById(id: number): any {
    return this.service().one(this.name, id).get();
  }

  findAllExchangeRates(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().all(this.name).all('exchangeRates').customGET(null, this.toFilter(transformedQuery));
  }

  findAll(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().all(this.name).all('all').customGET(null, this.toFilter(transformedQuery));
  }

  findAllSubdivision(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('subdivision').customGET(null, this.toFilter(transformedQuery));
  }

  findByIdSubdivision(id: number) {
    return this.service().all(this.name).one('subdivision', id).customGET(null, null);
  }

  findAllCountrySubdivisions(countryCode: string, query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).one(countryCode, 'states').customGET(null, this.toFilter(transformedQuery));
  }

  findAllStateSubdivisions(countryCode: string, query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().all(this.name).all(countryCode + '/stateslist').customGET(null, this.toFilter(transformedQuery));
  }

  findAllCurrencies(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('currencies').customGET(null,this.toFilter(transformedQuery));
  }

  findAllActiveCountriesCurrencies(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('active-countries-currencies').customGET(null,this.toFilter(transformedQuery));
  }

  findAllUniqueCurrencies(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('uniquecurrencies').customGET(null,this.toFilter(transformedQuery));
  }

  findAllCurrenciesByCountry(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('currenciesCountry').customGET(null,this.toFilter(transformedQuery));
  }

  findCurrencyById(id) {
    return this.service().all(this.name).one('currency', id).customGET(null, null);
  }
}
