<mat-toolbar color="accent" fxFlex="48px">
  <span class="sneak-peek-title">Notes</span>
  <!--<span class="example-fill-remaining-space"></span>-->
</mat-toolbar>
<div fxFlex>
  <div [ngClass]="{ 'visibility-hidden': !createNoteAllowed  || !_charges.length }" class="ca-card ca-card-note">
    <mat-card class="note-card">
      <mat-card-content style="margin-bottom: 0">
        <form [formGroup]="noteForm" class="flex-container ca-form">
          <mat-form-field fxFlex caInput>
              <textarea matInput placeholder="Note" rows="3"
                        formControlName="content" required></textarea>
          </mat-form-field>
        </form>
      </mat-card-content>
      <mat-card-footer class="note-action-container">
        <div fxLayout="row" class="note-action flex-container">
          <div *ngIf="_charges?.length" class="charges-add-note">{{_charges.length}} {{ _charges.length === 1 ? 'charge' :
            'charges' }} selected
          </div>
          <div class="example-fill-remaining-space"></div>
          <button mat-button (click)="createNote()" [disabled]="!noteForm.valid || (chargesRequired && !_charges.length)"
                  class="note-button">
            Save
          </button>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>
  <div [ngClass]="{ 'visibility-hidden': (!notes.length) }" class="ca-card ca-card-note">
    <mat-card *ngFor="let note of notes" [ngClass]="{'selected': note.note ? note?.note?.id == selectedId : note.id == selectedId}">
      <mat-card-header>
        <div class="avatar avatar-md note-avatar" (click)="showUserModal(note.note ? note?.note?.user : note.user)">
          <img *ngIf="note.note ? note?.note?.user?.avatar : note?.user?.avatar" [src]="note.note ? note?.note?.user?.avatar : note.user?.avatar" alt="User Image">
          <div class="replacement" *ngIf="!(note.note ? note?.note?.user?.avatar : note?.user?.avatar)">{{ getUserInitials(note.note ? note?.note : note) }}</div>
        </div>
        <div class="note-card-title" fxLayout="column">
          <div fxFlex class="note-card-user" matTooltip="">{{ note.note ? note?.note?.user?.full_name : note?.user?.full_name }}</div>
          <div fxFlex class="note-card-date muted">{{note.note ? (note?.note?.created_at | caDateTime ): (note?.created_at | datetime)  }}</div>
        </div>
      </mat-card-header>
      <mat-card-content>
        <p [innerHtml]="sanitizeNoteContent(note.note ? note?.note?.content : note?.content | newline)"></p>
        <p *ngIf="note.note ? note?.note?.note_charges_copy : note.note_charges_copy && (note?.note?.note_charges_copy.length || note.note_charges_copy.length)">
          <a (click)="viewCharges(note.note ? note?.note?.note_charges_copy : note.note_charges_copy)" class="charges-link">View charges</a>
          <a (click)="viewCharges(note.note ? note?.note?.note_charges_copy : note.note_charges_copy)" class="selection-badge">{{note.note ? note?.note?.note_charges_copy.length : note.note_charges_copy.length}}</a></p>

        <div class="note-card-comments" *ngFor="let child of (note.note ? note?.note?.notes : note.notes)">
          <div class="note-card-header" fxLayout="row">
            <div fxItem class="avatar avatar-md note-avatar" (click)="showUserModal(child.user)">
              <img *ngIf="child?.user?.avatar" [src]="child.user?.avatar" alt="User Image">
              <div class="replacement" *ngIf="!child?.user?.avatar">{{ getUserInitials(child) }}</div>
            </div>
            <div fxItem class="note-card-title" fxLayout="column">
              <div fxFlex class="note-card-user">{{ child?.user?.full_name }}</div>
              <div fxFlex class="note-card-date muted">{{ child?.created_at | caDateTime }}</div>
            </div>
          </div>
          <div class="note-card-body">
            <p [innerHtml]="sanitizeNoteContent(child?.content | newline)"></p>
          </div>
        </div>
      </mat-card-content>
      <form name="replyForm" class="note-card-reply flex-container ca-form" *ngIf="replyMode && selectedNote.id == note.id">
        <mat-form-field fxFlex caInput>
          <textarea matInput placeholder="Add a reply..." rows="3"
                    name="reply" type="text"
                    [(ngModel)]="note.reply"></textarea>
        </mat-form-field>
      </form>
      <mat-card-footer class="note-action-container">
        <div fxLayout="row" class="note-action flex-container">
          <div class="example-fill-remaining-space"></div>
          <button mat-button (click)="cancel(note)" *ngIf="replyMode && selectedNote.id == note.id" class="note-button">
            Cancel
          </button>
          <button mat-button (click)="reply(note)" [disabled]="!note.reply && replyMode" class="note-button">
            Reply
          </button>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>
</div>

<div *ngIf="!notes.length" class="ca-empty-state-container">
  <p class="small-div-message">No notes available</p>
</div>

<!-- <div *ngIf="isFetchingNotes" class="ca-empty-state-container">
  <p class="small-div-message">Loading</p>
</div> -->
