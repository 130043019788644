import {Injectable, OnInit} from '@angular/core';

import { AlertAuditNavigationModel } from './alert-audit-navigation.model';
import { PermissionService } from '../../permissions/core/permission.service';
import {ConfigService} from "../../core/config/config.service";
import {Config} from "../../core/config/config";

@Injectable()
export class AlertAuditNavigationService {
  readonly MODE_STANDARD = 'standard';
  mode: string = this.MODE_STANDARD;

  public toShowWireless;

  constructor(public permissionService: PermissionService) {
  }

  public GROUP = {
    RATE_AUDIT: 'Rate Audit',
    INVENTORY_MRC: 'Inventory MRC',
    MISSING_INVENTORY: 'Missing Inventory'
  };

  readonly entries = [
    new AlertAuditNavigationModel({
      group: this.GROUP.RATE_AUDIT,
      title: 'Rate Audit',
      value: '/rate-audit',
    }),
    new AlertAuditNavigationModel({
      group: this.GROUP.INVENTORY_MRC,
      title: 'Inventory MRC',
      value: '/inventory-mrc'
    }),
    new AlertAuditNavigationModel({
      group: this.GROUP.MISSING_INVENTORY,
      title: 'Missing Inventory',
      value: '/inventory-charge-total'
    })
  ];

  load() {
    return this.entries;
  }
}
