import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import {PageManageDialogComponent} from '../../../core/page-manage-dialog.component';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {QuotingService} from '../../core/quoting.service';
import {Request} from '../../core/offer';
import {LOOKUP_MODELS_ENUM} from '../../../dictionary/core/lookup-models.enum';
import {QUOTE_SERVICE_TERM} from '../../core/quote-term.enum';
import {IMessagesResourceService, ResourcesService} from '../../../core/resources/resources.service';

@Component({
  selector: 'app-qouting-offer-manage-dialog',
  templateUrl: './qouting-offer-manage-dialog.component.html',
  styleUrls: ['./qouting-offer-manage-dialog.component.css']
})
export class QoutingRequestManageDialogComponent extends PageManageDialogComponent implements OnInit {
  @ViewChild('fileInput') fileInput;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'quote';

  offer: Request;
  isManual = false;
  public quote;
  public service;
  formTitle: string;
  labels: Array<any> = [];

  readonly QUOTE_SERVICE_MEDIUM_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.QUOTE_SERVICE_MEDIUM.modelName;
  readonly NET_TYPE_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.NET_TYPE.modelName;
  readonly QUOTE_SERVICE_TERM = QUOTE_SERVICE_TERM;

  availableTerms: Array<any> = [];

  constructor(public alert: AlertService,
              public formBuilder: FormBuilder,
              public quotingService: QuotingService,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<QoutingRequestManageDialogComponent>) {
    super(dialogService, dialogRef);
    this.offer = (this.offer) ? this.offer : new Request();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.isUpdate = !!this.offer.id;

    this.form = this.formBuilder.group({
      status: 10,
      sent_date: new FormControl(this.offer.sent_date ? new Date(this.offer.sent_date) : null, Validators.required),
      received_date: new FormControl(this.offer.received_date ? new Date(this.offer.received_date) : null),
      expiration_date: new FormControl(this.offer.expiration_date ? new Date(this.offer.expiration_date) : null),
      mtm_mrc: new FormControl({
        value: this.offer.mtm_mrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.MTM)
      }, Validators.required),
      mtm_nrc: new FormControl({
        value: this.offer.mtm_nrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.MTM)
      }, Validators.required),
      one_year_mrc: new FormControl({
        value: this.offer.one_year_mrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.ONE_YEAR)
      }, Validators.required),
      one_year_nrc: new FormControl({
        value: this.offer.one_year_nrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.ONE_YEAR)
      }, Validators.required),
      two_year_mrc: new FormControl({
        value: this.offer.two_year_mrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.TWO_YEAR)
      }, Validators.required),
      two_year_nrc: new FormControl({
        value: this.offer.two_year_nrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.TWO_YEAR)
      }, Validators.required),
      three_year_mrc: new FormControl({
        value: this.offer.three_year_mrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.THREE_YEAR)
      }, Validators.required),
      three_year_nrc: new FormControl({
        value: this.offer.three_year_nrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.THREE_YEAR)
      }, Validators.required),
      five_year_mrc: new FormControl({
        value: this.offer.five_year_mrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.FIVE_YEAR)
      }, Validators.required),
      five_year_nrc: new FormControl({
        value: this.offer.five_year_nrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.FIVE_YEAR)
      }, Validators.required),
      seven_year_mrc: new FormControl({
        value: this.offer.seven_year_mrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.SEVEN_YEAR)
      }, Validators.required),
      seven_year_nrc: new FormControl({
        value: this.offer.seven_year_nrc,
        disabled: this.isInputDisabled(QUOTE_SERVICE_TERM.SEVEN_YEAR)
      }, Validators.required),
      interval: new FormControl(this.offer.interval),
      medium_id: new FormControl(this.offer.medium_id),
      off_net_vendor_id: new FormControl(this.offer.off_net_vendor_id),
      net_type_id: new FormControl(this.offer.net_type_id),
      manual: new FormControl(this.isManual),
      notes: new FormControl(this.offer.notes),
      circuit_bandwidth_id: new FormControl(this.offer.circuit_bandwidth_id),
      port_bandwidth_id: new FormControl(this.offer.port_bandwidth_id),
    });

    this.quotingService.findAllServiceTerms()
      .subscribe((result) => {
        this.availableTerms = result.items;
      });

    this.formTitle = 'Update Quote';
    this.afterInit();
  }

  /*init() {
    if (this.offer && this.offer.id) {
      return this.quotingService.findRequestForEdit(this.quote.id, this.service.id, this.offer.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .flatMap((offer) => {
          this.offer = offer;
          return Observable.of(offer);
        })
    }
    return Observable.of(this.offer);
  }*/

  resetFields() {
    let resetValues = {
      mtm_mrc: null,
      mtm_nrc: null,
      one_year_mrc: null,
      one_year_nrc: null,
      two_year_mrc: null,
      two_year_nrc: null,
      three_year_mrc: null,
      three_year_nrc: null,
      five_year_mrc: null,
      five_year_nrc: null,
      seven_year_mrc: null,
      seven_year_nrc: null,
      interval: null,
      medium_id: null,
      notes: null,
      circuit_bandwidth_id: null,
      port_bandwidth_id: null
    };
    // Object.assign(this.offer, resetValues);
    let newValues = Object.assign(this.form.value, resetValues);
    this.form.setValue(newValues);
  }

  onSubmit({value, valid}: { value: Request, valid: boolean }) {
    if (valid) {
      let manual = this.isManual; // this.form.get("manual").value;
      if (manual) {
        this.createRequest(value);
      } else {
        this.updateRequest(value);
      }
    } else {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }

  updateRequest(offer: Request) {
    offer.one_year_mrc = this.unmask(offer.one_year_mrc);
    offer.one_year_nrc = this.unmask(offer.one_year_nrc);
    offer.two_year_mrc = this.unmask(offer.two_year_mrc);
    offer.two_year_nrc = this.unmask(offer.two_year_nrc);
    offer.three_year_mrc = this.unmask(offer.three_year_mrc);
    offer.three_year_nrc = this.unmask(offer.three_year_nrc);

    offer.mtm_mrc = this.unmask(offer.mtm_mrc);
    offer.mtm_nrc = this.unmask(offer.mtm_nrc);
    offer.five_year_mrc = this.unmask(offer.five_year_mrc);
    offer.five_year_nrc = this.unmask(offer.five_year_nrc);
    offer.seven_year_mrc = this.unmask(offer.seven_year_mrc);
    offer.seven_year_nrc = this.unmask(offer.seven_year_nrc);

    offer.responded = !!(
      offer.one_year_mrc ||
      offer.one_year_nrc ||
      offer.two_year_mrc ||
      offer.two_year_nrc ||
      offer.three_year_mrc ||
      offer.three_year_nrc ||
      offer.mtm_mrc ||
      offer.mtm_nrc ||
      offer.five_year_mrc ||
      offer.five_year_nrc ||
      offer.seven_year_mrc ||
      offer.seven_year_nrc);

    this.toggleDialogButtons()
    this.quotingService.updateRequest(this.quote.id, this.service.id, this.offer.id, offer)
      .subscribe(
        (result) => {
          this.closeDialog(result, true);
          this.alert.success('', this.messages.get('UPDATE_SUCCESS'));
        },
        (error) => {
          this.toggleDialogButtons(false)
          this.alert.error('', this.messages.get('UPDATE_FAIL'));
        });
  }

  createRequest(offer: Request) {
    offer.one_year_mrc = this.unmask(offer.one_year_mrc);
    offer.one_year_nrc = this.unmask(offer.one_year_nrc);
    offer.two_year_mrc = this.unmask(offer.two_year_mrc);
    offer.two_year_nrc = this.unmask(offer.two_year_nrc);
    offer.three_year_mrc = this.unmask(offer.three_year_mrc);
    offer.three_year_nrc = this.unmask(offer.three_year_nrc);

    offer.mtm_mrc = this.unmask(offer.mtm_mrc);
    offer.mtm_nrc = this.unmask(offer.mtm_nrc);
    offer.five_year_mrc = this.unmask(offer.five_year_mrc);
    offer.five_year_nrc = this.unmask(offer.five_year_nrc);
    offer.seven_year_mrc = this.unmask(offer.seven_year_mrc);
    offer.seven_year_nrc = this.unmask(offer.seven_year_nrc);

    offer.responded = !!(
      offer.one_year_mrc ||
      offer.one_year_nrc ||
      offer.two_year_mrc ||
      offer.two_year_nrc ||
      offer.three_year_mrc ||
      offer.three_year_nrc ||
      offer.mtm_mrc ||
      offer.mtm_nrc ||
      offer.five_year_mrc ||
      offer.five_year_nrc ||
      offer.seven_year_mrc ||
      offer.seven_year_nrc);

    this.toggleDialogButtons()
    this.quotingService.createRequest(this.quote.id, this.service.id, this.offer.id, offer)
      .subscribe(
        (result) => {
          this.closeDialog(result, true);
          this.alert.success('', this.messages.get('CREATE_SUCCESS'));
        },
        (error) => {
          this.toggleDialogButtons(false)
          this.alert.error('', this.messages.get('CREATE_FAIL'));
        });
  }

  public unmask(value) {
    if (value) {
      // http://stackoverflow.com/questions/559112
      return value.replace(/[^0-9\.]+/g, '');
    }
  }

  isInputDisabled(termId) {
    if (this.service && this.service.service_term_id) {
      return this.service.service_term_id.indexOf(termId) < 0;
    } else {
      return false;
    }
  }

  cancel() {
    this.quotingService.cancelEdit();
    this.closeDialog();
  }
}
