import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {ConfigService} from "./config.service";
import {Title} from "@angular/platform-browser";
import {map} from "rxjs/operators";
import {InvoiceDetailsState} from "../../invoice/invoice-details/state/invoice-details.state";

@Injectable()
export class ConfigResolve implements Resolve<any> {
  constructor(
    public configService: ConfigService,
    private titleService: Title
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.configService.findAll()
      .subscribe(result => {
        this.titleService.setTitle(result.application_info.name);
        return result;
      });
  }
}

@Injectable({
  providedIn: 'root'
})
export class SmartConfigResolve implements Resolve<any> {
  constructor(
    public configService: ConfigService,
    private titleService: Title
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.configService.findAll()
      .pipe(
        map((result: any) => {
          this.titleService.setTitle(result.application_info.name);
          return result
        })
      ).toPromise()
  }
}
