import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class OrderServiceAcceptDateGridService extends GridService {

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super("app.orders.order-service-accept-date-grid", userSettings);
  }

  columns() {
    return [
      {
        caption: 'Date',
        dataField: 'date',
        dataType: 'date',
        //alignment: 'left',
        format: this.formatService.date,
        cellTemplate: "dateCellTemplate",
        width: 110
      },      
      {
        caption: 'Type',
        dataField: 'type',
        alignment: 'left',
        width: 110
      },
      {
        caption: 'Status',
        dataField: 'pass',
        alignment: 'left',
        cellTemplate: "statusCellTemplate",
        width: 80
      },
      {
        caption: 'Note',
        dataField: 'note',
        width: '*'
      },
      {
        caption: 'Changed By',
        dataField: 'changed_by',
        width: 140
      },
      {
        caption: 'Changed At',
        dataField: 'changed_at',
        dataType: 'date',
        format: this.formatService.date,
        cellTemplate: "changedDateCellTemplate",
        width: 200
      }
    ];
  }
}
