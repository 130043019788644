import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {UserSettingsService} from "../../user/core/user-settings.service";
import {ThemeService} from "../../theme/core/theme.service";
import {mergeMap} from 'rxjs/operators';

@Injectable()
export class ChartService {

  public static defaultTheme;

  public static chartThemes = [];
  public static activeChartTheme = {};

  static setChartThemes(chartThemes) {
    this.chartThemes = chartThemes;
  }

  static setActiveChartTheme(chart) {
    this.activeChartTheme = chart;
  }

  static setDefaultTheme(resolvedTheme) {
    this.defaultTheme = resolvedTheme;
  }

  readonly key = "app.charts.palette";
  readonly selectedKey = "app.charts.selected";

  readonly THEME_NAMES = {
    DEFAULT: 'Default',
    CUSTOM_THEME: 'Custom Theme',
  };

  readonly DEFAULT_THEME_NAME = 'Default';

  constructor(
    public userSettingsService: UserSettingsService,
    public themeService: ThemeService
  ) {
  }

  getSelectedPalette() {
    return this.userSettingsService.entrySettingsNew(this.selectedKey);
  }

  customPalette() {
    return this.userSettingsService.entrySettingsNew(this.key);
  }

  saveSettings(palette) {

    palette.settings['selected'] = palette.name;
    return this.userSettingsService.saveUserSettings(this.selectedKey, {name: palette.name})
        .pipe(mergeMap(() => {
          if (palette.name === 'Custom Theme') {
            return this.userSettingsService.saveUserSettings(this.key, palette);
          } else {
            return observableOf(true);
          }
        }))

  }

  getActiveChartTheme(selChart, userSettings) {
    let selectedChart;

    if (!selChart) {
      selectedChart = ChartService.chartThemes.filter(
        item => item.name === this.THEME_NAMES.DEFAULT
      )[0];
    } else {
      if (selChart.name !== this.themeService.THEME_NAMES.CUSTOM_THEME) {
        selectedChart = ChartService.chartThemes.filter(
          item => item.name === selChart.name
        )[0];
      } else {
        selectedChart = userSettings[this.key];
      }
    }

    return selectedChart;
  }

  getChartColorsAsArray(palette) {
    if (palette && palette.settings) {
      const {
        color1,
        color2,
        color3,
        color4,
        color5,
        color6,
        color7,
        color8,
      } = palette.settings;

      const customThemeStyleTag: any = document.querySelector('style[name="custom-chart-style"]');


      let dynCss = `
    .dxc-tooltip path {
      fill: ${palette.settings.tooltip_background} !important;
      stroke: ${palette.settings.tooltip_font_color} !important;
    }
    .dxc-tooltip text {
      fill: ${palette.settings.tooltip_font_color} !important;
    }
    dx-chart text {
      fill: ${palette.settings.font_color} !important;
    }
    .dxc-labels-group text {
      fill: ${palette.settings.inner_font} !important;
    }
    .chart-container .mat-card-header:not(.filter-group-header) {
      color: ${palette.settings.font_color} !important;
    }
    .chart-container {
      background-color: ${palette.settings.background} !important;
    }
    .dxc-val-grid path, .dxc-labels rect {
      stroke: ${this.hexToRGB(palette.settings.font_color, 0.4)} !important;
    }
    `;

      if (customThemeStyleTag) {
        customThemeStyleTag.innerText = dynCss;
      } else {
        let head = document.head;
        let style = document.createElement('style');
        style.setAttribute('name', 'custom-chart-style');

        style.innerText = dynCss;
        head.appendChild(style);
      }
      return [
        color1,
        color2,
        color3,
        color4,
        color5,
        color6,
        color7,
        color8,
      ];
    }
  }

  hexToRGB = (hex, opacity?) => {

    hex = hex.replace('#', '');

    if (hex.length === 3) {
      hex = [hex[0], hex[0], hex[1], hex[1], hex[2], hex[2]].join('');
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity || .4})`;
  }
}
