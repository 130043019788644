import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class AlertRateAuditGridService extends GridService {
  defaultColumns = [
    {
        caption: 'Rule',
        dataField: 'name',
    }
  ];

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.alert.rate-audit-grid', userSettings);
  }

}
