export const inventoryBillingFilterContext = {
  filters: [
    {
      field: 'chg_class',
      label: 'Charge Type',
      type: 'list',
      lookupProviderName: 'chargeType',
      index: 1
    },
    {
      field: "invoice.sp_inv_num",
      label: "Invoice Number",
      type: 'string',
      index: 2
    },
    {
      field: "invoice.inv_date",
      label: "Invoice Date",
      type: 'date',
      index: 3
    },
    {
      field: "chg_amt",
      label: "Charge Amount",
      type: 'negativeNumber',
      index: 5
    },
    {
      field: "chg_desc_1",
      label: "Charge Description 1 ",
      type: 'string',
      index: 7
    },
    {
      field: "chg_desc_2",
      label: "Charge Description 2",
      type: 'string',
      index: 8
    },
    {
      field: "chg_code_1",
      label: "Charge Code 1",
      type: 'string',
      index: 9
    },
    {
      field: "chg_code_2",
      label: "Charge Code 2",
      type: 'string',
      index: 10
    },
    {
      field: "fac_bw",
      label: "Facility Bandwidth",
      type: 'negativeNumber',
      index: 11

    },
    {
      field: "svc_establish_date",
      label: "Install Date",
      type: 'date',
      index: 12
    },
    {
      field: "beg_chg_date",
      label: "Beg Charge Date",
      type: 'date',
      index: 13
    },
    {
      field: "end_chg_date",
      label: "End Charge Date",
      type: 'date',
      index: 14
    },
    {
      field: 'chg_qty1_billed',
      label: 'Chg Qty',
      type: 'negativeNumber',
      index: 16,
      visible: true
    },
    {
      field: 'chg_rate',
      label: 'Charge Rate',
      type: 'negativeNumber',
      index: 17,
      visible: true
    },
    // {
    //   field: 'info_only_ind',
    //   label: 'Info Only',
    //   type: 'boolean',
    //   index: 18,
    //   visible: true
    // },
    {
      field: 'call_type',
      label: 'Call Type',
      type: 'string',
      index: 19,
      visible: true
    },
    {
      field: 'dir_ind',
      label: 'Dir Ind',
      type: 'string',
      index: 20,
      visible: true
    },
    {
      field: 'jur',
      label: 'Jurisdiction',
      type: 'string',
      index: 21,
      visible: true
    },
    {
      field: 'chg_qty1_type',
      label: 'Chg Qty Type',
      type: 'string',
      index: 22,
      visible: true
    },
    {
      field: 'chg_qty1_used',
      label: 'Chg Qty Used',
      type: 'negativeNumber',
      index: 23,
      visible: true
    },
    {
      field: 'chg_qty1_allowed',
      label: 'Chg Qty Allowed ',
      type: 'string',
      index: 24,
      visible: true
    }
  ]
};
