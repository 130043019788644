<mat-toolbar mat-scroll-shrink class="ca-second-level-toolbar" style="height: 60px !important; padding-top: 8px;">

  <div class="flex-item" fxFlex="0 0 159px" style="margin-bottom: -15px;">
    <form class="ca-form" [formGroup]="form$ | async">
      <ca-vendor-picker
        hiDensityInput
        formControlName="vendor_id"
        placeholder="Vendor"
        [pickerForm]="form$ | async"
        [clearEnabled]="true"
        [initialItemId]="selectedVendorID"
        [required]="true"
        class="gl-coding-picker">
      </ca-vendor-picker>
    </form>
  </div>
  <div class="flex-item ca-default-spacing-left">
    <button mat-button *ngIf="selectedVendor"
            [disabled]="permissionModify"
            matTooltip="If Vendor Level is disabled, all BAN level setting will be ignored for Invendory GL Coding."
            (click)="changeVendorStatus()" class="switch-button"
            [ngClass]="{'btn-disabled':!selectedVendor.gl_inv_coding_enabled, 'btn-enabled': selectedVendor.gl_inv_coding_enabled}">
      {{selectedVendor.gl_inv_coding_enabled?'Enabled':'Disabled'}}
    </button>
  </div>
</mat-toolbar>

<mat-sidenav-container style="height: 100%" class="app-content-list">
  <dx-data-grid #banGrid (onRowClick)="onRowClick($event)"
                (onCellClick)="onAccountsCellClick($event)"
                [columns]="columns" [width]="'100%'"
                [height]="'100%'" [dataSource]="bans" class="app-grid-with-pager">

    <div *dxTemplate="let d of 'statusCellTemplate'">
      <button mat-button matTooltip="Change Status" (click)="changeBANStatus(d.row.data)"
              [ngClass]="{'btn-disabled':!d.row.data.gl_inv_coding_enabled, 'btn-enabled': d.row.data.gl_inv_coding_enabled}"
              [disabled]="!d.row.data.vendor.gl_inv_coding_enabled || permissionModify" class="switch-button">
        {{d.row.data.gl_inv_coding_enabled?'Enabled':'Disabled'}}
      </button>
    </div>

  </dx-data-grid>

</mat-sidenav-container>
