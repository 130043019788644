import {Contract} from "./contract";
import {LookupModel} from "../../dictionary/core/lookup.model";
import {ContractScheduleCommitment} from "./contract-schedule-commitment";

export class ContractSchedule {
  id: number;
  contract_shell_id: number;
  contract_shell: Contract;
  schedule_series_id: number;
  schedule_series: LookupModel;
  schedule_name: string;
  schedule_description: string;
  contains_msa: boolean; // remove
  contains_commitment: boolean; // remove
  contains_rates: boolean;
  customer_sign_date: Date;
  vendor_sign_date: Date;
  effective_date: Date;
  termination_date: Date;
  rate_term: number;
  rate_term_unit_of_measure_id: number;
  rate_term_unit_of_measure: LookupModel;
  prior_schedule_id: number;
  prior_schedule: ContractSchedule;
  contract_schedule_commitment: ContractScheduleCommitment;
  at_exp_event_id: number;
  at_exp_event: LookupModel;
  cancel_notice_pd: number;
  cancel_notice_dt: Date;

  // name: string;
  // description: string;
  // company_sign_date: Date;
  // contract_schedule_series_id: number;
  // type: number;
  // document_id: number;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
