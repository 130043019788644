import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class ContractTermConditionGridService extends GridService {


  constructor(public userSettings: UserSettingsService) {
    super("app.contract.contract-term-condition-grid", userSettings);
  }

  defaultColumns = [
      {
        dataField: 'contractSchedule.schedule_name',
        caption: 'Contract Schedule Name',
        alignment: 'left',
        width: 200
      },
      {
        dataField: 'contractSchedule.effective_date',
        caption: 'Effective Date',
        dataType: 'date',
        cellTemplate: "effDateTemplate",
        alignment: 'left',
        width: 200
      },
      {
        dataField: 'contractSchedule.termination_date',
        caption: 'Term date',
        dataType: 'date',
        cellTemplate: "termDateTemplate",
        alignment: 'left',
        width: 200
      },
      {
        dataField: 'name',
        caption: 'Name',
        alignment: 'left',
        width: 200
      },
      {
        dataField: 'section_id',
        caption: 'Section ID',
        alignment: 'left',
        width: 160
      },
      {
        dataField: 'text',
        caption: 'Contract Text',
        alignment: 'left',
        cellTemplate: 'contractTextCellTemplate',
        width: 400,
      },
      {
        dataField: 'abstract',
        caption: 'Abstract',
        alignment: 'left',
        cellTemplate: 'contractTextCellTemplate',
        width: 400
      },
    ];

}
