import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {takeUntil, mergeMap} from "rxjs/operators";
import {Observable, of, Subject} from "rxjs";
import {Inventory} from "../../core/inventory";
import {InventoryService} from "../../core/inventory.service";
import {InventoryManageDialogComponent} from "../../shared/inventory-manage-dialog/inventory-manage-dialog.component";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {Select, Store} from "@ngxs/store";
import {UpdateInventorySummaryActiveSiderSection} from "./state/inventory-summary.actions";
import { HistoryComponent } from 'app/shared/history/history.component';
import {ConfigService} from "../../../core/config/config.service";
import {PermissionService} from "../../../permissions/core/permission.service";

@Component({
  selector: 'app-inventory-summary',
  templateUrl: './inventory-summary.component.html',
  styleUrls: ['./inventory-summary.component.scss']
})
export class InventorySummaryComponent implements OnInit {

  inventory: Inventory;
  billingAliasesTooltip: string;
  billingAliases: any;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  readonly INVENTORY_CONTRACT_TYPE_ENUM = LOOKUP_ENUM.INVENTORY_CONTRACT_TYPE;

  sider: Sider;
  destroy$: Subject<boolean> = new Subject<boolean>();
  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  notesCount: number;
  public contractNames: Array<any> = [];
  public svcOrderServiceNames: Array<any> = [];
  ratesLineNo: string = '';
  servicesLineNo: string = '';
  ordersAllowed:boolean;
  stopBillingDate:any;

  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    NOTES_SECTION_NAME: 'notes',
    HISTORY_SECTION_NAME: 'history'
  };
  readonly custom_values_config_key = 'inventory_detail';
  @ViewChild('panelSide') panelSide;
  @ViewChild('history') history: HistoryComponent;
  @Select(state => state.inventory_summary.activeSiderSection) $activeSiderSection: Observable<any>;

  readonly MESSAGES_MODULE: string = 'inventory';
  readonly CORE_MODULE: string = 'core';

  readonly AUDIT_RUN_STATUS_ENUM = {
    ACTIVE_AUDIT_RUN: true,
    INACTIVE_AUDIT_RUN: false
  }

  constructor(
    public inventoryService: InventoryService,
    public route: ActivatedRoute,
    public dialogService: DialogService,
    public alertService: AlertService,
    public router: Router,
    public configService: ConfigService,
    private readonly store: Store,
    public permissionService: PermissionService,
  ) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  loadPermissions() {
    return of({});
  }

  public async loadData(id: number) {
    this.inventory = await this.loadInventory(id).toPromise();

    this.setSvcLineNo();
    this.setRateLineNo();

    this.billingAliasesTooltip = this.inventory.billing_aliases.reduce((accumulator, billingAlias, index) => {
      return `${accumulator} ${index > 0 ? '|' : ''} ${billingAlias.alias}`;
    }, ``);
  }

  loadInventory(id: number): Observable<Inventory> {
    return this.inventoryService.findById(id);
  }

  ngOnInit(): void {
    this.loadPermissions()
      .subscribe(() => {
        this.route.parent.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
          this.loadData(params['id']).then((res)=>{
            this.permissionService.package().subscribe((perm) => {
              const filtered = perm.resources.filter(arr => arr.key == 'orders');
              let lastOrderService;
              if(this.inventory && this.inventory.order_service && this.inventory.order_service.length > 0){
                const sortedOrderServices = this.inventory.order_service.sort((a,b)=> a.id - b.id);
                lastOrderService = sortedOrderServices[sortedOrderServices.length - 1];
              }
              this.stopBillingDate = filtered.length && filtered[0]['allowed']
                ? lastOrderService?.stop_billing_date
                : this.inventory?.stop_billing_date;
            })
          });
        });
      })
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/inventory']);
    }
  }

  async edit() {
    let inventory = this.inventory;
    const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key);
    const permission = await this.permissionService.package().toPromise();
    const filtered = permission.resources.filter(arr => arr.key == 'orders');
    this.ordersAllowed = filtered.length > 0 && filtered[0]['allowed'];

    if (inventory) {
      this.dialogService.edit(
        InventoryManageDialogComponent,
        {
          inventory: this.inventoryService.findByIdForEdit(inventory.id)
        }, {
          width: hasCustomFields ? '1200px' : '880px',
          height: '600px'
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe((result: any) => {
          if (result) {
            if (result.cancelByTimer || result.status == 2) {
              return this.alertService.success('', this.coreMessages.get('TIMER_EXPIRED'));
            } else if (result.deleted) {
              setTimeout(() => {
                this.alertService.snackBar.dismiss();
                this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
              }, 255);
              this.router.navigate(['/inventory']);
            } else if (result.id) {
              result.billing_aliases = result.billing_aliases.sort((prev, next) => {
                if (prev.id < next.id) {
                  return -1;
                }

                if (prev.id > next.id) {
                  return 1;
                }

                return 0;
              });

              this.stopBillingDate = !this.ordersAllowed
                ? result?.stop_billing_date
                : result?.order_service[this.inventory?.order_service.length - 1]?.stop_billing_date;

              this.billingAliases = [];

              this.inventory.billing_aliases.forEach((alias) => {
                this.billingAliases.push(alias.alias)
              }, this.billingAliases.push(this.inventory.sp_ckt_id));

              this.billingAliasesTooltip = this.inventory.billing_aliases.reduce((accumulator, billingAlias, index) => {
                return `${accumulator} ${index > 0 ? '|' : ''} ${billingAlias.alias}`;
              }, ``);

              this.setSvcLineNo();
              this.setRateLineNo();

              this.inventory.sites = result.inventory_sites.map(item => item.site);
              this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
              this.inventory = result;
              this.history.refreshList();
            }
          }
        });
    }
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });
    return sider;
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInventorySummaryActiveSiderSection(activeSection.name)
    ]);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME)
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME)
  }

  setSvcLineNo() {
    this.servicesLineNo = this.inventory.svc_order_service.map(service => {
      return service.line_number
    }).sort((a, b) => a - b).join(', ');
  }

  setRateLineNo() {
    const rateLines = this.inventory.inventory_schedule_rates.filter(rate => rate.rate_id);
    const rateLinesMaped = rateLines.map(schedule => schedule.rates.line_number).sort((a, b) => a - b).join(', ');
    this.ratesLineNo = rateLines.length ? `${rateLines.length > 1 ? 'Rate lines' : 'Rate line'} ${rateLinesMaped}` : '';
  }
}
