import {of as observableOf, throwError as observableThrowError} from 'rxjs';

import {catchError, map, mergeMap, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {path} from 'ramda';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Customer} from 'app/customer/core/customer';
import {CustomerService} from 'app/customer/core/customer.service';
import {LoaderService} from 'app/shared/loader/loader.service';
import {DataLockDialogService} from '../../core/data-lock/data-lock-dialog.service';
import Entity from '../../core/entity.model';
import {PageDetailsComponent} from '../../core/page-details.component';
import {PageContext} from '../../core/page.context';
import {IMessagesResourceService, ResourcesService} from '../../core/resources/resources.service';
import {DemarcService} from '../../demarc/core/demarc.service';
import {LOOKUP_ENUM} from '../../dictionary/core/lookup.enum';
import {INVENTORY_TOPOLOGY_ENUM} from '../../inventory/core/inventory-topology.enum';
import {InventoryService} from '../../inventory/core/inventory.service';
import {NetworkHubService} from '../../network-hub/core/network-hub.service';
import {OrderServiceDisconnectFlowService} from '../../order-service/core/order-service-disconnect-flow.service';
import {OrderServicRecordOnlyFlowService} from '../../order-service/core/order-service-record-only-flow.service'
import {ORDER_SERVICE_DISCONNECT_STATUS} from '../../order-service/core/order-service-disconnect-status.enum';
import {OrderServiceFlowService} from '../../order-service/core/order-service-flow.service';
import {OrderServiceService} from '../../order-service/core/order-service.service';
// tslint:disable-next-line:max-line-length
import {OrderServiceManageDialogComponent} from '../../order-service/shared/order-service-manage-dialog/order-service-manage-dialog.component';
import {ReportService} from '../../report/core/report.service';
import {AlertService} from '../../shared/alert/alert.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {DocumentService} from "../../shared/document/core/document.service";
import {Flow} from '../../shared/flow/flow';
import {HistoryComponent} from '../../shared/history/history.component';
import {NotesComponent} from '../../shared/notes/notes.component';
import {TabGroup, TabService} from '../../shared/tabs/tab.service';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {Order} from '../core/order';
import {OrderFlowService} from '../core/order-flow.service';
import {ORDER_STATUS} from '../core/order-status.enum';
import {ORDER_TYPE} from '../core/order-type.enum';
import {OrderService} from '../core/order.service';
import {OrderManageDialogComponent} from '../shared/order-manage-dialog/order-manage-dialog.component';
import {ORDER_SERVICE_STATUS} from 'app/order-service/core/order-service-record-only-status.enum';
import {ConfigService} from "../../core/config/config.service";
import {DictionaryService} from "../../dictionary/core/dictionary.service";
import {LOOKUP_MODELS_ENUM} from "../../dictionary/core/lookup-models.enum";
import Query from 'app/core/query/query';
import { GLRuleStringGridService } from 'app/gl-rule/core/gl-rule-string-grid.service';
import { Inventory } from 'app/inventory/core/inventory';
import { GlStringSelectGridDialogComponent } from 'app/gl-system-rules/shared/gl-string-select-grid-dialog/gl-string-select-grid-dialog.component';

enum TABGROUP {
  SUMMARY = 1,
  SERVICES = 2,
  DOCUMENTS = 3,
}

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent extends PageDetailsComponent implements OnInit, OnDestroy {
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  readonly CONTACT_FUNCTION_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.CONTACT_FUNCTION.modelName;

  public order: Order;
  public editedOrder: Order;
  public selectedOrderSvc: any;
  @ViewChild('panelMore') panelMore;
  @ViewChild('accordion') accordion;
  @ViewChild('panelMoreTabs') panelMoreTabs;
  @ViewChild('history') history: HistoryComponent;
  @ViewChild('notes') notes: NotesComponent;
  @ViewChild('documentsManager') documentsManager;

  public notesCount = 0;

  public selection: any;
  public editFunctions;
  public isCancelButtonDisabled;

  tabs = TABGROUP;

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'order';
  readonly CORE_MODULE: string = 'core';
  readonly ORDER_SERVICE_STATUS_ENUM = ORDER_SERVICE_STATUS;
  readonly ORDER_STATUS_ENUM = ORDER_STATUS;
  readonly ORDER_SERVICE_DISCONNECT_STATUS = ORDER_SERVICE_DISCONNECT_STATUS;
  readonly ORDER_SERVICE_STATUS = ORDER_SERVICE_STATUS;
  readonly ORDER_TYPE = ORDER_TYPE;
  public INVENTORY_TOPOLOGY = INVENTORY_TOPOLOGY_ENUM;
  readonly SITE_TYPES_ENUM = LOOKUP_ENUM.SITE_TYPES_ENUM;

  testTab: any = 1;
  navExpand = true;

  DOCUMENT_INFO = {
    FOLDER: 'orders',
    SUBFOLDER: '',
    PARENT_TYPE: this.SYSTEM_MODULE.ORDER,
    ENTITY_TYPE: this.SYSTEM_MODULE.ORDER
  };

  public flow: Flow;
  public orderServiceFlows: any = [];
  public orderServiceDisconnectFlows: any = [];
  public orderServiceRecordOnlyFlows: any = [];
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  activeTabIndex = 0;
  dataReady = false;
  selectedMaster: Entity;
  selectedEntity: Entity;
  groups: Array<any> = [];
  entities: Array<Entity> = [];
  isCancelDisabled: boolean;
  public previewGridColumns: Array<any> = [];
  public selectedCodings: Array<any> = [];
  public glQuery: Query = new Query();
  public inventory: Inventory;

  public fields;
  public categories = [];
  customFieldsExist = false;
  customFieldsExist1 = false;
  customFieldsExist2 = false;
  customFieldsExist3 = false;
  customFieldsExist4 = false;
  isDocumentTab = false;

  paramId: number;

  public groupedContacts = []

  cancelButtonTitle = 'Cancel Order';
  contactFunctionsUI: Array<any>;

  readonly DOCUMENT_MANAGER_SETTINGS = {
    DELETE_DOCUMENT_ACTION: this.deleteDocument.bind(this),
    UPLOAD_DOCUMENT_ACTION: this.upload.bind(this),
    DOWNLOAD_DOCUMENT_ACTION: this.download.bind(this)
  };

  routerState: any;

  constructor(public alertService: AlertService,
              public dialogService: DialogService,
              public orderService: OrderService,
              public inventoryService: InventoryService,
              public orderServiceService: OrderServiceService,
              public route: ActivatedRoute,
              public documentService: DocumentService,
              public router: Router,
              public flowService: OrderFlowService,
              public orderServiceFlowService: OrderServiceFlowService,
              public orderServiceDisconnectFlowService: OrderServiceDisconnectFlowService,
              public orderServiceRecorOnlyFlowService: OrderServicRecordOnlyFlowService,
              public tabService: TabService,
              public settingsService: UserSettingsService,
              public reportService: ReportService,
              public networkHubService: NetworkHubService,
              public demarcService: DemarcService,
              public dataLockDialogService: DataLockDialogService,
              private customerService: CustomerService,
              private loaderService: LoaderService,
              public configService: ConfigService,
              public dictionaryService: DictionaryService,
              public glRuleStringGridService: GLRuleStringGridService,
  ) {
    super(new PageContext({
      name: 'app.orders.order-details',
      settings: settingsService
    }));
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
    this.contactFunctionsUI = [];

    this.flowService.onOrderChange.pipe(takeUntil(this.destroy$)).subscribe(order => {
      if (order.services.length == 0)
        this.onTabChange(0);
      this.order.services = order.services;
      if (this.selectedOrderSvc) {
        let selected = this.order.services.filter(service => service.id === this.selectedOrderSvc.id);
        if (selected.length && (selected[0].state !== this.selectedOrderSvc.state || selected[0].disconnect_state !== this.selectedOrderSvc.disconnect_state)) {
          this.selectedOrderSvc = selected[0]
        }
      }
    })

    let currentNavigation = this.router.getCurrentNavigation();
    if (currentNavigation && currentNavigation.extras.state)
      this.routerState = currentNavigation.extras.state;
  }

  ngOnInit() {
    this.initContactFunctionsUI();
    this.tabGroup = this.tabService.create();

    this.editFunctions = {
      SERVICES: this.updateService.bind(this),
      SUMMARY: this.editOrder.bind(this)
    };

    this.glRuleStringGridService.generateColumns('_obj')
      .then((columns: Array<any>) => {
        this.previewGridColumns = columns;
      });

    this.configService.loadCustomFields()
      .toPromise().then(config => {
      this.fields = config.custom_values;

      if (this.fields.order_header.length > 0) {
        this.customFieldsExist = true;
      }
      if (this.fields.order_service_summary.length > 0) {
        this.customFieldsExist1 = true;
      }
      if (this.fields.order_service_financial_contract.length > 0) {
        this.customFieldsExist2 = true;
      }
      if (this.fields.order_service_configuration.length > 0) {
        this.customFieldsExist3 = true;
      }
      if (this.fields.order_service_site_a_z.length > 0) {
        this.customFieldsExist4 = true;
      }
      for (let i = 0; i < this.fields.length; i++) {
        if (!this.categories.includes(this.fields[i].category)) {
          this.categories.push(this.fields[i].category)
        }
      }
    });

    this.route.params.pipe(
      takeUntil(this.destroy$)).pipe(takeUntil(this.destroy$))
      .subscribe((params: Params) => {
        this.paramId = params['id'];
        this.loadData(this.paramId);
      });

    this.isCancelButtonDisabled = this.isCancelOrderDisabled();
  }

  public loadData(id: number) {
    this.orderService.findById(id).pipe(takeUntil(this.destroy$))
      .subscribe((order: Order) => {
        this.order = order;

        this.inventory = order.services[0]?.inventory;
        this.loadGlString();
        this.groupedContacts = this.groupToXItemsPerRow(this.order.contacts)
        this.isCancelDisabled = (![this.ORDER_STATUS_ENUM.SENT_TO_VENDOR, this.ORDER_STATUS_ENUM.VENDOR_ACK_DATE, this.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED].includes(this.order.status_id));
        this.loadDemarcs(order);
        this.DOCUMENT_INFO.SUBFOLDER = `${order.id}/`;
        this.createOrderFlows();
        this.loadTabs();
        let master = new Entity(order.id, this.SYSTEM_MODULE.ORDER, order.full_order_id);
        this.selectedMaster = master;
        this.entities.push(master);
        // tslint:disable-next-line:max-line-length
        this.entities = this.entities.concat(order.services.map((service) => new Entity(service.id, this.SYSTEM_MODULE.ORDER_SERVICE, service.full_service_id)));
        this.buildGroups(order);
        this.dataReady = true;

        if (this.order.services.length) {
          if (this.routerState && this.routerState.services) {
            this.selectOrderService(true, [...this.order.services].sort((a,b) => a.id - b.id).shift())
            this.onTabChange(1)
          } else this.selectOrderService(true, [...this.order.services].sort((previous, next): any => previous.id < next.id).pop())
        }
      });
  }

  loadGlString() {
    if (this.inventory) {
      this.glQuery.where = {
        inv_id: this.inventory.id
      };
      this.inventoryService.getGLStrings(this.glQuery).subscribe((result: any) => {
        this.selectedCodings = result.items;
        this.glQuery.total = result.total;
        this.glRuleStringGridService.generateColumns('_obj')
          .then((columns: Array<any>) => {
            this.previewGridColumns = columns;
          });
      })
    }
  }

  addString() {
    const query = new Query();
    query.where = {
      inv_id: this.inventory.id
    };
    query.limit = 1000000;
    delete query.offset;
    delete query.page;

    this.inventoryService.getGLStrings(query).subscribe((result: any) => {
      this.dialogService.open(GlStringSelectGridDialogComponent, {
        selectedStrings: result.items,
        getLoadData: () => ({
          ruleType: 'inventory',
          ruleMeta: {
            inv_id: this.inventory.id
          },
        }),
      }, {width: '80%'})
        .afterClosed()
        .subscribe((result) => {
          if (result.data) {
            this.inventoryService.saveGLStrings(this.inventory.id, result.data).subscribe((res: any) => {
              if (res) {
                this.loadGlString()
              }
              this.history.refreshList();
            });
          }
        });
    })
  }

  private refreshJustServices(showLoader?: boolean) {
    if (showLoader) {
      this.loaderService.displayLoader();
    }
    this.orderService.findById(this.order.id).pipe(
      takeUntil(this.destroy$))
      .subscribe((order: Order) => {
        if (showLoader) {
          this.loaderService.hideLoader();
        }
        
        if(!this.inventory) {
          this.inventory = order.services[0]?.inventory;
        }

        this.order.services = order.services;
        this.loadDemarcs(order);

        let master = new Entity(order.id, this.SYSTEM_MODULE.ORDER, order.full_order_id);
        this.selectedMaster = master;
        // Clear entities array so no duplicates are present
        this.entities = [];
        this.entities.push(master);
        // tslint:disable-next-line:max-line-length
        this.entities = this.entities.concat(order.services.map((service) => new Entity(service.id, this.SYSTEM_MODULE.ORDER_SERVICE, service.full_service_id)));

        if (this.order.services.length) {
          if(this.selectedOrderSvc)
            this.selectOrderService(true,order.services.filter(service => service.id === this.selectedOrderSvc.id)[0]);
          else
            this.selectOrderService(true, [...this.order.services].sort((a,b) => a.id - b.id).shift())
        }
        this.createOrderFlows();
      });
  }

  public buildGroups(order) {
    this.groups.push({id: order.id, type: this.SYSTEM_MODULE.ORDER, label: order.full_order_id});
    this.order.services.forEach((svc) => {
      if (svc) {
        this.groups.push({id: svc.id, type: this.SYSTEM_MODULE.ORDER_SERVICE, label: svc.full_service_id});
      }
    });
  }

  mapDocumentsMeta(entity: any) {
    return {
      title: entity.title || entity.label,
      type: entity.type
    };
  }

  public createOrderFlows() {
    this.flow = this.flowService.createFlow(this.order);
    if (this.order.type_id === this.ORDER_TYPE.DISCONNECT) {
      this.createOrderServiceDisconnectFlows();
    } else if (this.order.type_id === this.ORDER_TYPE.RECORD_ONLY) {
      this.createOrderServiceRecordOnlyFlows()
    } else {
      this.orderServiceFlows = [];
      let sortedOrderServices = this.order.services.sort((a,b)=>a.service_id - b.service_id)
      sortedOrderServices.forEach((svc) => {
        if (svc) {
          this.orderServiceFlows.push({id: svc.id, flow: this.orderServiceFlowService.createFlow(svc)});
        }
      });

      this.orderServiceFlowService.onStatusChange.pipe(
        takeUntil(this.destroy$))
        .subscribe((status) => {
          this.notes.loadNotes();
          this.history.refreshList();
        });
    }

    this.flowService.onStatusChange.pipe(
      takeUntil(this.destroy$))
      .subscribe((obj) => {
        let status, order;
        if (typeof obj == 'object') {
          status = obj.status ? obj.status : null;
          order = obj.order ? obj.order : null;
        } else status = order = null;
        if(order) {
          this.order = {...order};
          this.groupedContacts = this.groupToXItemsPerRow(this.order.contacts)
        }
        this.isCancelDisabled = (status !== this.ORDER_STATUS_ENUM.SENT_TO_VENDOR);
        this.isCancelOrderDisabled();
        if (this.order.type_id === this.ORDER_TYPE.INSTALL || this.order.type_id === this.ORDER_TYPE.CHANGE) {
          if (status === this.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED) {
            this.orderServiceFlows.forEach(item => {
              if (item.flow.currentStep > 1) { // NEW state
                this.orderServiceFlowService.updateSteps(item.flow, this.ORDER_SERVICE_STATUS_ENUM.VENDOR_ACCEPTED);
                this.orderServiceFlowService.setStepDates(item.flow, null);
              }
            });
          } else if (status===this.ORDER_STATUS_ENUM.VENDOR_ACK_DATE){
            this.orderServiceFlows.forEach(item => {
              if (item.flow.currentStep > 1) { // NEW state
                this.orderServiceFlowService.updateSteps(item.flow, this.ORDER_SERVICE_STATUS_ENUM.RFA);
                this.orderServiceFlowService.setStepDates(item.flow, null);
              }
            });
          } else if (status === this.ORDER_STATUS_ENUM.NEW_REJECTED) {
            this.orderServiceFlows.forEach(item => {
              if (item.flow.currentStep > 1) {
                this.orderServiceFlowService.updateSteps(item.flow, this.ORDER_SERVICE_STATUS_ENUM.NEW_REJECTED);
              }
            });
          } else if (status === this.ORDER_STATUS_ENUM.NEW_RESET) {
            this.orderServiceFlows.forEach(osf => {
              osf.flow.currentStep = 1;
              osf.flow.steps[0] = this.orderServiceFlowService.setStepToNewReset();
              this.setServiceStepDates(osf)
            })
          }
        } else if (this.order.type_id === this.ORDER_TYPE.DISCONNECT) {
          if (status === this.ORDER_STATUS_ENUM.VENDOR_ACK_DATE) {
            this.orderServiceDisconnectFlows.forEach(item => {
              if (item.flow.currentStep > 1) {
                this.orderServiceDisconnectFlowService.updateSteps(item.flow, this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_RESET);
                this.setServiceStepDates(item)
              }
            })
          } else if (status === this.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED) {
            this.orderServiceDisconnectFlows.forEach(item => {
              this.orderServiceDisconnectFlowService.updateSteps(item.flow, this.ORDER_SERVICE_DISCONNECT_STATUS.VENDOR_ACCEPTED);
              this.setServiceStepDates(item)
            });
          } else if (status === this.ORDER_STATUS_ENUM.NEW_REJECTED) {
            this.orderServiceDisconnectFlows.forEach(item => {
              this.orderServiceDisconnectFlowService.updateSteps(item.flow, this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_REJECTED);
            });
          }
        } else if (this.order.type_id === this.ORDER_TYPE.RECORD_ONLY) {
          if (status === this.ORDER_STATUS_ENUM.APPROVED) {
          } else if (status === this.ORDER_STATUS_ENUM.NEW_RESET) {
            this.orderServiceRecordOnlyFlows.forEach(item => {
              this.orderServiceRecorOnlyFlowService.updateSteps(item.flow, this.ORDER_SERVICE_STATUS.NEW_RESET);
              this.setServiceStepDates(item)
            });
          } else if (status === this.ORDER_STATUS_ENUM.NEW_REJECTED) {
            this.orderServiceRecordOnlyFlows.forEach(item => {
              this.orderServiceRecorOnlyFlowService.updateSteps(item.flow, this.ORDER_SERVICE_STATUS.NEW_REJECTED);
            });
          }
        }
        this.notes.loadNotes();
        this.history.refreshList();
        this.refreshJustOrder(this.order.id);
      });
  }

  public createOrderServiceDisconnectFlows() {
    this.orderServiceDisconnectFlows = [];
    let sortedOrderServices = this.order.services.sort((a,b)=>a.service_id - b.service_id)
    sortedOrderServices.forEach((svc) => {
      if (svc) {
        this.orderServiceDisconnectFlows.push({
          id: svc.id,
          flow: this.orderServiceDisconnectFlowService.createFlow(svc)
        });
      }
    });
    this.orderServiceDisconnectFlowService.onStatusChange.pipe(
      takeUntil(this.destroy$))
      .subscribe((result) => {
        // this.notes.loadNotes();
        this.history.refreshList();
        if(result.status === this.ORDER_STATUS_ENUM.COMPLETED) {
          let orderToFilter = Object.assign({}, this.order);
          orderToFilter.services.forEach(service => {
            if(service.id === result.orderService.id) {
              service.disconnect_state = result.orderService.disconnect_state
            }
          })
          if(orderToFilter.services.every(service => service.disconnect_state === this.ORDER_SERVICE_DISCONNECT_STATUS.COMPLETED)) {
            const status = this.ORDER_STATUS_ENUM.COMPLETED;
            orderToFilter.status_id = status;
            this.orderService.update(this.order.id, orderToFilter)
                  .subscribe(result => {
                    this.refreshJustOrder(this.order.id);
                    this.flowService.updateSteps(this.ORDER_STATUS_ENUM.COMPLETED);
                  })
          }
        }
      });
  }

  setServiceStepDates(flowItem) {
    const service = this.order.services.filter(service => service.id == flowItem.id)
    if (service && service.length) {
      if (this.order.type_id === this.ORDER_TYPE.INSTALL || this.order.type_id === this.ORDER_TYPE.CHANGE) {
        this.orderServiceFlowService.setStepDates(flowItem.flow, service[0]);
      } else if (this.order.type_id === this.ORDER_TYPE.DISCONNECT) {
        this.orderServiceDisconnectFlowService.setStepDates(flowItem.flow, service[0]);
      } else if (this.order.type_id === this.ORDER_TYPE.RECORD_ONLY) {
        this.orderServiceRecorOnlyFlowService.setStepDates(flowItem.flow, service[0]);
      }
    }
  }

  public createOrderServiceRecordOnlyFlows() {
    this.orderServiceRecordOnlyFlows = [];
    //Sorted Order Services by service_id because showing the right state of services on steper
    let sortedOrderServices = this.order.services.sort((a,b)=>a.service_id - b.service_id)
    sortedOrderServices.forEach((svc) => {
      if (svc) {
        this.orderServiceRecordOnlyFlows.push({
          id: svc.id,
          flow: this.orderServiceRecorOnlyFlowService.createFlow(svc)
        });
      }
    });
    this.orderServiceRecorOnlyFlowService.onStatusChange.pipe(
      takeUntil(this.destroy$))
      .subscribe((status) => {
        // this.notes.loadNotes();
        this.history.refreshList();
      });
  }

  public loadTabs() {
    this.tabGroup.addTab({key: 1, title: 'Summary'});
    this.tabGroup.addTab({key: 2, title: 'test'});
    this.tabGroup.addTab({key: 3, title: 'Documents'});

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.pipe(takeUntil(this.destroy$)).subscribe((tab) => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
      // update order details
      if (tab.key === 1) {
        if (this.editedOrder) {
          Object.assign(this.order, this.editedOrder);
          this.groupedContacts = this.groupToXItemsPerRow(this.order.contacts)
          this.editedOrder = null;
        }
      } else if (tab.key === 2 && this.order.services.length > 0) {
        // this.selectOrderService(true, this.order.services[0]);
      }
    });
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/order']);
    }
  }

  public isEditDisabled(selection: any) {
    if (selection && selection.state === this.ORDER_SERVICE_STATUS_ENUM.CANCELLED) {
      return true
    };

    return !this.tabGroup.isActive(TABGROUP.SUMMARY) && (!selection || !this.tabGroup.isActive(TABGROUP.SERVICES));
  }

  edit(tabKey) {
    this.editFunctions[TABGROUP[tabKey]]();
  }

  editOrder() {
    let order = this.order;
    if (order) {
      this.dialogService.edit(
        OrderManageDialogComponent,
        {
          order: this.orderService.findByIdForEdit(order.id)
        }, {width: '1200px'}).pipe(takeUntil(this.destroy$)).subscribe((items) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(takeUntil(this.destroy$), mergeMap(x => x.afterClosed()))
            .subscribe((result: any) => {
              this.afterModalClosed(result, order)
            });
        }
      })
    }
  }

  afterModalClosed(result, item) {
    if (result) {
      if (result.route == 'order')
        this.groupedContacts = this.groupToXItemsPerRow(result.contacts)

      if (result.cancelByTimer || result.status == 2) {
        return this.alertService.success('', this.coreMessages.get('TIMER_EXPIRED'));
      } else if (result.deleted) {
        setTimeout(() => {
          this.alertService.snackBar.dismiss();
          this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
        });
        this.router.navigate(['/order']);
      } else if (result.id) {
        if (!this.tabGroup.isActive(2)) {
          Object.assign(item, result);
        } else {
          this.editedOrder = result;
        }
        this.history.refreshList();
        this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
      }
    }
  }

  public onTabChange(index) {
    this.isDocumentTab = index === 2;
    if (index === 0) {
      this.cancelButtonTitle = 'Cancel Order';
    } else if (index === 1) {
      this.cancelButtonTitle = 'Cancel Order Service';
    } else {
      this.cancelButtonTitle = 'Cancel Order';
    }

    this.selectedEntity = null;

    if (this.accordion && index + 1 === TABGROUP.SERVICES) {
    } else {
      this.selectedEntity = null;
    }

    let tab = this.tabGroup.tabs[index];
    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }
  }

  onStepSelection(step) {
    let diff = Math.abs(this.flow.currentStep - step.code);
    if (diff < 2) {
      if (diff !== 0) {
        if (step.code === 2 && this.order.services.length < 1) {
          this.dialogService.simpleAlert({
            title: '',
            bodyText: step.progressStatusAlert
          });
        } else {
          if (this.order.status_id < this.ORDER_STATUS_ENUM.COMPLETED) {
            this.flowService.handleStepSelection(step, this.order);
          }
        }
      }
    }

    this.refreshJustOrder(this.order.id);
  }

  private refreshJustOrder(id: number) {
    this.orderService.findById(id).pipe(takeUntil(this.destroy$))
      .subscribe((order: Order) => {
        const orderServices = this.order.services
        this.order = order;
        this.groupedContacts = this.groupToXItemsPerRow(this.order.contacts)
        if (orderServices)
          this.order.services = orderServices
        this.isCancelButtonDisabled = this.isCancelOrderDisabled();
        this.isCancelDisabled = (![this.ORDER_STATUS_ENUM.SENT_TO_VENDOR, this.ORDER_STATUS_ENUM.VENDOR_ACK_DATE, this.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED].includes(this.order.status_id));
      });
  }

  onStepSelectionOrderService(orderSvc, step) {
    let flow = this.getOrderServiceFlow(orderSvc.id).flow;
    if (orderSvc.state >= this.ORDER_SERVICE_STATUS_ENUM.COMPLETED) {
      return;
    }

    let diff = Math.abs(flow.currentStep - step.code);
    if (orderSvc.state >= this.ORDER_SERVICE_STATUS_ENUM.VENDOR_ACCEPTED && step.code < 3) {
      return; // prevent reverting to RfA state
    }
    if(orderSvc.state === this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING){
      this.orderServiceFlowService.handleStepSelection(flow, orderSvc, step);
    }
    if (diff < 2) {
      if (diff !== 0 || step.code === 4 || step.code === 5 || step.code === 6) {
          this.orderServiceFlowService.handleStepSelection(flow, orderSvc, step);
      }
    }
  }

  newService() {
    let order = this.order;
    if (order) {
      this.dialogService.open(OrderServiceManageDialogComponent, {
        order: order
      }, {width: '1200px', height: '600px'})
        .afterClosed().pipe(
        takeUntil(this.destroy$))
        .subscribe((result) => {
          if (result) {
            order.services.push(result);
            this.refreshJustServices();
            this.getCustomerSitesForService(result, result);
            this.createOrderFlows();
            this.history.refreshList();
            this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
          }
        });
    }
  }

  updateOrderFlowToRfa() {
    if (this.order.status_id >= this.ORDER_STATUS_ENUM.RFA) {
      return;
    }

    let allRfa = this.order.services.length > 0;
    this.order.services.forEach((service) => {
      allRfa = allRfa && (service.state >= this.ORDER_SERVICE_STATUS_ENUM.RFA);
    });
    let status = allRfa ? this.ORDER_STATUS_ENUM.RFA : this.ORDER_STATUS_ENUM.NEW;
    this.order.status_id = status;
    this.orderService.update(this.order.id, this.order).pipe(
      takeUntil(this.destroy$))
      .subscribe(result => {
        this.flowService.updateSteps(status);
      });
  }


  updateService() {
    let order = <any>this.order;
    let orderSvc = order.services.filter((item) => {
      return item.id === this.selectedOrderSvc.id;
    })[0];

    if (orderSvc) {
      this.dialogService.edit(OrderServiceManageDialogComponent,
        {
          order: order,
          orderService: this.orderServiceService.findByIdForEdit(orderSvc.id)
        }, {width: '1200px', height: '600px'}).pipe(takeUntil(this.destroy$)).subscribe((items) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(takeUntil(this.destroy$), mergeMap(x => x.afterClosed()))
            .subscribe((result: any) => {
              if (result) {
                if (result.deleted) {
                  this.alertService.success('', this.messages.get('DELETE_SERVICE_SUCCESS'));

                  let services = this.order.services.filter((item) => {
                    return !(item.id === orderSvc.id);
                  });

                  this.order.services = services;
                  this.createOrderFlows();

                  if (!this.order.services.length) {
                    const SUMMARY_TAB_INDEX = 0;
                    this.onTabChange(SUMMARY_TAB_INDEX)
                  }

                  this.history.refreshList();
                  this.updateOrderFlowToRfa();
                } else {
                  this.orderServiceService.findById(orderSvc.id).pipe(takeUntil(this.destroy$)).subscribe((res) => {
                    Object.assign(orderSvc, res);
                    this.getCustomerSitesForService(orderSvc, res);

                    this.loadDemarcs(order);
                  });

                  this.history.refreshList();
                  this.refreshJustServices();
                  this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
                  let flow;
                  if (this.order.type_id === this.ORDER_TYPE.DISCONNECT) {
                    flow = this.orderServiceDisconnectFlows.filter(f => f.id === orderSvc.id)[0];
                    this.orderServiceDisconnectFlowService.updateSteps(flow, result.state);
                  } else if (this.order.type_id === this.ORDER_TYPE.RECORD_ONLY) {
                    flow = this.orderServiceRecordOnlyFlows.filter(f => f.id === orderSvc.id)[0];
                    this.orderServiceRecorOnlyFlowService.updateSteps(flow, result.state);
                  } else {
                    flow = this.orderServiceFlows.filter(f => f.id === orderSvc.id)[0].flow;
                    this.orderServiceFlowService.updateSteps(flow, result.state);
                  }
                }
              }
            });
        }
      })
    }
  }

  private getCustomerForSite = (customer_id: number) => {
    return this.customerService.findById(customer_id).toPromise();
  };

  private getCustomerSitesForService = async (orderSvc: any, res: any) => {
    const customerIdSiteA = path(['inventory', 'siteA', 'customer_id'])(res);
    const customerIdSiteZ = path(['inventory', 'siteZ', 'customer_id'])(res);
    if (customerIdSiteA) {
      orderSvc.inventory.siteA.customer = await this.getCustomerForSite(customerIdSiteA as number);
    } else if (path(['inventory', 'siteA'], orderSvc)) {
      orderSvc.inventory.siteA.customer = new Customer();
    }

    if (customerIdSiteZ) {
      orderSvc.inventory.siteZ.customer = this.getCustomerForSite(customerIdSiteZ as number);
    } else if (path(['inventory', 'siteZ'], orderSvc)) {
      orderSvc.inventory.siteZ.customer = new Customer();
    }
  }

  refresh() {
    this.loadData(this.order.id);
  }

  toggleHistory() {
    if (this.panelMoreTabs.selectedIndex !== 1) {
      this.panelMoreTabs.selectedIndex = 1;
      this.panelMore.open();
    } else {
      this.panelMore.toggle();
    }
  }

  toggleNotes() {
    if (this.panelMoreTabs.selectedIndex !== 0) {
      this.panelMoreTabs.selectedIndex = 0;
      this.panelMore.open();
    } else {
      this.panelMore.toggle();
    }
  }

  public getOrderServiceFlow(id: number) {
    let serviceFlow = null;
    for (let flow of this.orderServiceFlows) {
      if (flow.id === id) {
        serviceFlow = flow;
        break;
      }
    }
    return serviceFlow;
  }

  public getOrderServiceDisconnectFlow(id: number) {
    return this.orderServiceDisconnectFlows[id];
  }

  public getOrderServiceRecordOnlyFlow(id: number) {
    return this.orderServiceRecordOnlyFlows[id]
  }

  public extractBlob(data, type) {
    let blob = new Blob([data], {type: type});
    return blob || {};
  }

  isDownloadDisabled(selection: any) {
    return !(this.tabGroup.isActive(3) && selection);
  }

  isDeleteDisabled(selection: any) {
    return !(this.tabGroup.isActive(3) && selection);
  }

  docSelected(doc: Document) {
    this.selection = doc;
  }

  upload() {
    this.documentsManager.addEntityDocument();
  }

  download() {
    this.documentService.downloadDocument(this.selection.id, this.DOCUMENT_INFO.FOLDER).pipe(
      map(response => this.extractBlob(response, this.selection.type)),
      catchError(error => observableThrowError(error)))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: Blob) => {
        const name = this.selection.file_name;
        this.documentService.initiateDownload(name, response);
      });
  }

  deleteDocument() {
    this.documentsManager.deleteEntityDocument(this.selection);
  }

  public selectOrderService(toggleState, service) {
    if (!this.selectedOrderSvc || (this.selectedOrderSvc && this.selectedOrderSvc.id !== service.id)) {
      this.selectedOrderSvc = service;
    }else if (this.selectedOrderSvc && this.selectedOrderSvc.id === service.id) {
      this.selectedOrderSvc = service;
    }else {
      this.selectedOrderSvc = null;
    }

    this.selectedEntity = new Entity(service.id, this.SYSTEM_MODULE.ORDER_SERVICE, service.full_service_id)
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  loadDemarcs(order) {
    if (order && order.services && order.services.length) {
      order.services.forEach((item) => {
        if (item.inventory && item.inventory.topology_id === this.INVENTORY_TOPOLOGY.POINT_TO_POINT) {
          if (item.inventory.site_a_id) {
            this.demarcService.getDemarcs(item.inventory.site_a_id, item.inventory.id).pipe(takeUntil(this.destroy$))
              .subscribe(result => {
                item.siteADemarcs = result.items;
              });
          }

          if (item.inventory.site_z_id) {
            this.demarcService.getDemarcs(item.inventory.site_z_id, item.inventory.id).pipe(takeUntil(this.destroy$))
              .subscribe(result => {
                item.siteZDemarcs = result.items;
              });
          }
        }
      });
    }
  }

  onStepSelectionOrderServiceDisconnect(flowId: number, orderSvc, step) {
    let flow = this.orderServiceDisconnectFlows[flowId].flow;
    let diff = Math.abs(flow.currentStep - step.code);
    if(orderSvc.disconnect_state === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_PENDING){
      this.orderServiceDisconnectFlowService.handleStepSelection(flow, orderSvc, step);
    }
    if (diff == 0 && this.ORDER_SERVICE_DISCONNECT_STATUS.COMPLETED == orderSvc.disconnect_state) {
      return
    } else if (diff < 2 && diff !== 0) {
      this.orderServiceDisconnectFlowService.handleStepSelection(flow, orderSvc, step);
    } else if(orderSvc.disconnect_state === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_PENDING){
      return
    } else {
      let alertMessage = this.flow.currentStep < step.code ? step.progressStatusAlert : step.revertStatusAlert;
      this.dialogService.alert({
        title: this.messages.get('ORDER_SERVICE_STATUS_CHANGE_ALERT_TITLE'),
        bodyText: alertMessage
      });
    }
  }

  onStepSelectionOrderServiceRecordOnly(flowId: number, orderSvc, step) {
    let flow = this.orderServiceRecordOnlyFlows[flowId].flow;
    if(orderSvc.state === this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING){
      this.orderServiceRecorOnlyFlowService.handleStepSelection(flow, orderSvc, step);
    }
    this.orderServiceRecorOnlyFlowService.handleStepSelection(flow, orderSvc, step);
  }

  public isSiteTypeOf(siteType, type) {
    return siteType === type;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.tabGroup.onActivate.unsubscribe();
  }

  public isCancelOrderDisabled(): boolean {
    let allow = [this.ORDER_SERVICE_STATUS_ENUM.VENDOR_ACCEPTED,
      this.ORDER_SERVICE_STATUS_ENUM.FOC_DATE,
      this.ORDER_SERVICE_STATUS_ENUM.SERVICE_INSTALL,
      this.ORDER_SERVICE_STATUS_ENUM.SERVICE_TEST,
      this.ORDER_SERVICE_STATUS_ENUM.TEST_FAILED,
      this.ORDER_SERVICE_STATUS_ENUM.TEST_PASSED];
    let allowDisconnect = [this.ORDER_SERVICE_DISCONNECT_STATUS.VENDOR_ACCEPTED,
      this.ORDER_SERVICE_DISCONNECT_STATUS.DISCONNECT_DATE,
      this.ORDER_SERVICE_DISCONNECT_STATUS.STOP_BILLING_DATE];

    if (this.tabGroup.isActive(1)) {
      if ([this.ORDER_STATUS_ENUM.SENT_TO_VENDOR, this.ORDER_STATUS_ENUM.VENDOR_ACK_DATE, this.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED].includes(this.order.status_id))
        return false
    } else if (this.tabGroup.isActive(2) && this.selectedOrderSvc) {
      if (this.order.type_id == ORDER_TYPE.DISCONNECT) {
        if (allowDisconnect.includes(this.selectedOrderSvc.disconnect_state))
          return false
      } else {
        if (allow.includes(this.selectedOrderSvc.state))
          return false
      }
    }
    return true
  }

  public async cancel() {
    const isFirstTabActive = this.tabGroup.isActive(1);
    const { id } = this.order
    let dbOrder = await this.orderService.findById(id).pipe(takeUntil(this.destroy$)).toPromise()

      const cancelOrder = () => {
      this.orderService.openConfirmationDialog({
        title: 'Cancel Order',
        bodyText: 'Canceling an order, will also cancel it\'s services.',
        order: dbOrder
      })
      .afterClosed().pipe(
        takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.order.status_id = this.ORDER_STATUS_ENUM.CANCELLED;
            // cancel services before canceling an order
            let promises = []
            this.order.services.map(service => {
              if (service.disconnect_state !== null) {
                service.disconnect_state = this.ORDER_SERVICE_STATUS_ENUM.CANCELLED;
                service.inventory.status_id = LOOKUP_ENUM.INVENTORY_STATUS.ACTIVE;
                this.inventoryService.update(service.inventory.id, service.inventory)
              } else {
                service.state = this.ORDER_SERVICE_STATUS_ENUM.CANCELLED;
              }
            })
            this.order.services.forEach(service => {
              promises.push(this.orderServiceService.update(service.id, service).toPromise())
            });
            Promise.all(promises)
            .then(result => {
              this.orderServiceFlowService.setOrderServiceToCancel();
              this.createOrderFlows();
            })
            .then(() => {
              // cancel an order
              this.orderService.update(this.order.id, this.order).pipe(takeUntil(this.destroy$)).subscribe(res => {
                this.flowService.updateSteps(this.ORDER_STATUS_ENUM.CANCELLED);
              })
            })
            .catch(err => {
              console.log('An error occured', err)
            })
          }
        })
    }

    const cancelOrderService = () => {
      this.orderService.openConfirmationDialog({
        title: '',
        bodyText: 'Cancel Order Service?',
        order: false
      })
      .afterClosed().pipe(
        takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            if (this.selectedOrderSvc.disconnect_state !== null) {
              this.selectedOrderSvc.disconnect_state = this.ORDER_SERVICE_DISCONNECT_STATUS.CANCELLED;
              this.selectedOrderSvc.inventory.status_id = LOOKUP_ENUM.INVENTORY_STATUS.ACTIVE;
              this.inventoryService.update(this.selectedOrderSvc.inventory.id, this.selectedOrderSvc.inventory)
            } else {
              this.selectedOrderSvc.state = this.ORDER_SERVICE_STATUS_ENUM.CANCELLED;
            }
            this.orderServiceService.update(this.selectedOrderSvc.id, this.selectedOrderSvc).pipe(takeUntil(this.destroy$)).subscribe(res => {
              this.orderServiceFlowService.setOrderServiceToCancel();
              if (!this.isEveryOrderServiceCanceled(this.order.services)?.length) {
                this.order.status_id = this.ORDER_STATUS_ENUM.CANCELLED;
                this.orderService.update(this.order.id, this.order).pipe(takeUntil(this.destroy$)).subscribe(res => {
                  this.flowService.updateSteps(this.ORDER_STATUS_ENUM.CANCELLED);
                });
              }
              this.createOrderFlows();
            }); 
          }
        })
    }

    if (isFirstTabActive) {
      cancelOrder();
    } else if (this.selectedOrderSvc) {
      cancelOrderService();
    }
  }

  isEveryOrderServiceCanceled(orderServices) {
    return orderServices.filter(service => {
      if (service.disconnect_state) {
        return service.disconnect_state !== this.ORDER_SERVICE_STATUS_ENUM.CANCELLED
      }
      return service.state !== this.ORDER_SERVICE_STATUS_ENUM.CANCELLED
    })
  }

  changeDetail(num) {
    this.testTab = num;
  }

  toggleNavigation() {
    this.navExpand = !this.navExpand
  }

  private initContactFunctionsUI() {
    this.dictionaryService
      .getByLookup(this.CONTACT_FUNCTION_LOOKUP_MODEL)
      .subscribe(result => {
        this.contactFunctionsUI = result.items;
      });
  }

  public getFunctionName(name) {
    let contactFunction = null;
    this.contactFunctionsUI.forEach(item => {
      if (item.key == name) {
        contactFunction = item.value;
      }
    });

    return contactFunction;
  }

  public groupToXItemsPerRow(arr, x: number = 2) {
    let map = []
    let count = 0
    let mapIndex = 0
    let tmp = []

    for(let i=0; i < arr.length; i++) {
      if (count < x) {
        tmp.push(arr[i])
        count++
      }

      if (count == x || i == arr.length-1) {
        map[mapIndex] = tmp
        mapIndex++
        count = 0
        tmp = []
      }
    }

    return map
  }
}
