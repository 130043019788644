import {MESSAGES_ENUM} from "./messages.enum";
import {ICONS_ENUM} from "./icons.enum";

export class ResourcesService {

  static messages(moduleName: string): IMessagesResourceService {
    return new MessagesResourceService(moduleName);
  }

  static icons() {
    return IconsResourcesService;
  }
}

/* --- */

export interface IMessagesResourceService {
  get(key: string): string;

  getMessages(): any;
}

class MessagesResourceService implements IMessagesResourceService {

  private _MESSAGES_ENUM = MESSAGES_ENUM;
  private _moduleMessages;

  constructor(private moduleName: string) {
    if (!this._MESSAGES_ENUM || !this._MESSAGES_ENUM[moduleName]) {
      throw Error(`Unknown module name ${moduleName}`);
    }
    this._moduleMessages = this._MESSAGES_ENUM[moduleName];
  }

  get(key: string): string {
    return this._moduleMessages[key];
  }

  getMessages(): any {
    return this._moduleMessages;
  }
}

/* --- */

class IconsResourcesService {

  private static _ICONS_ENUM = ICONS_ENUM;
  public static undefinedIcon: string = IconsResourcesService._ICONS_ENUM["UNDEFINED"];

  static get(key: string): string {
    return this._ICONS_ENUM[key] || this.undefinedIcon;
  }
}

