import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {
  static readonly  MESSAGES = {
    REQUIRED_ERROR: 'Field is required',
    EMAIL_ERROR: 'Email is invalid',
    PHONE_INVALID: '(###) ###-####',
    ZIP_INVALID: '##### or #####-####',
    DATE_INVALID: 'mm/dd/yyyy',
    INTEGER_INVALID: 'Invalid value',
    PATTERN_INVALID: 'Pattern is invalid',
    EMAIL_ALREADY_USED: 'Email is already in use',
    USERNAME_ALREADY_USED: 'Username is already in use',
    SUBCLIENT_ALREADY_USED: 'Subclient is already in use',
    PASSWORD_INVALID: 'Password has to contain at least one uppercase letter, one lowercase letter, one number and one special character.',
    SUBCLIENT_INCORRECT_FORMAT: "Subclient format must be 'name_of_the_subclient (id)'",
  };

  static readonly PATTERNS = {
    PHONE_PATTERN : '^[0-9 ()-]$',
    ZIP_PATTERN: '\\d{5}(?:[-\\s]\\d{4})?',
    PASSWORD_PATTERN: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,30}$'
  };

  static getRangeErrorMessage(from: string, to: string) {
    return `From ${from} to ${to}`;
  }
}
