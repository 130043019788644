import { Injectable } from '@angular/core';

import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { QuoteRequestGridStatusPipePipe } from '../shared/quote-request-status/quote-request-status.pipe';
import { path } from 'ramda';

const getRateDisplay = (mrc, nrc) => {
  mrc = parseFloat(mrc);
  if (isNaN(mrc)) {
    mrc = 0;
  }

  nrc = parseFloat(nrc);
  if (isNaN(nrc)) {
    nrc = 0;
  }

  if (!mrc && !nrc) {
    return 'Not Recd.';
  }

  return `${mrc}/${nrc}`;
}

@Injectable()
export class QuotingDetailsGridService extends GridService {
  defaultColumns = [
    {
      caption: 'ID',
      dataType: 'text',
      dataField: 'quote_service_offer_id',
      width: 60
    },
    {
      caption: 'Vendor',
      dataField: 'vendor.name',
      width: 100
    },
    {
      caption: 'Medium',
      dataField: 'quote_service_medium.value',
      cellTemplate: 'emptyCellTemplate',
      width: 100
    },
    {
      caption: 'On/Off Net',
      dataField: 'net_type.value',
      cellTemplate: 'emptyCellTemplate',
      width: 100
    },
    {
      caption: 'Off Net Vendor',
      dataField: 'off_net_vendor.name',
      cellTemplate: 'emptyCellTemplate',
      width: 120
    },
    {
      caption: 'Hub Location',
      dataField: 'network_hub.hub_name',
      cellTemplate: 'networkHub',
      width: 200
    },
    {
      caption: 'Install Interval',
      cellTemplate: 'emptyCellTemplate',
      dataField: 'interval',
      width: 150
    },
    {
      caption: 'Method',
      dataField: 'quote_type.value',
      width: 100
    },
    {
      caption: 'Bandwidth',
      dataField: 'circuit_bandwidth.value',
      width: 100
    },
    {
      caption: 'Port Bandwidth',
      dataField: 'port_bandwidth.value',
      width: 120
    },
    {
      caption: 'Status',
      dataField: 'status',
      width: 100,
      cellTemplate: 'quoteRequestStatusTemplate',
    },
    // {
    //   caption: 'Failure Reason',
    //   dataField: 'failure_reason',
    //   width: 120,
    //   visible: false,
    //   cellTemplate: 'contractQuoteFailureReason',
    // },
    {
      dataField: 'sent_date',
      caption: 'Sent Date',
      dataType: 'date',
      format: 'shortDate' + 'shortTime',
      width: 170
    },
    {
      dataField: 'received_date',
      caption: 'Received Date',
      dataType: 'date',
      format: 'shortDate' + 'shortTime',
      width: 160
    },
    {
      dataField: 'expiration_date',
      caption: 'Expiration Date',
      dataType: 'date',
      format: 'shortDate',
      width: 160
    },
    {
      termNumber: 4,
      // important: true,
      caption: 'MTM',
      alignment: 'center',
      width: 150,
      dataField: 'mtm_mrc',
      cellTemplate: 'termMTMTemplate'
    },
    {
      termNumber: 1,
      // important: true,
      caption: '1 Year',
      alignment: 'center',
      width: 150,
      dataField: 'one_year_mrc',
      cellTemplate: 'term1YTemplate'
    },
    {
      termNumber: 2,
      // important: true,
      caption: '2 Years',
      alignment: 'center',
      width: 150,
      dataField: 'two_year_mrc',
      cellTemplate: 'term2YTemplate'

    },
    {
      termNumber: 3,
      // important: true,
      caption: '3 Years',
      alignment: 'center',
      width: 150,
      dataField: 'three_year_mrc',
      cellTemplate: 'term3YTemplate'

    },
    {
      termNumber: 5,
      // important: true,
      caption: '5 Years',
      alignment: 'center',
      width: 150,
      dataField: 'five_year_mrc',
      cellTemplate: 'term5YTemplate'

    },
    {
      termNumber: 6,
      // important: true,
      caption: '7 Years',
      alignment: 'center',
      width: 150,
      dataField: 'seven_year_mrc',
      cellTemplate: 'term7YTemplate'
    }
  ];

  constructor(
    public userSettings: UserSettingsService,
    public formatService: FormatService,
    private quoteRequestGridStatusPipe: QuoteRequestGridStatusPipePipe) {
    super('app.quoting.quoting-details-grids', userSettings);
  }

  columns() {
    return super.columns().map(column => column.dataField === 'status' ? ({
      ...column,
      calculateSortValue: (rowData) => {
        const method = path(['quote_type', 'id'])(rowData);
        const failureReason = path(['failureReason'])(rowData);
        return this.quoteRequestGridStatusPipe.transform(rowData.status, method, failureReason);
      }
    }) : column);
  }
}
