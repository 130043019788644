import { Injectable } from '@angular/core';
import { GridService } from "../../shared/grid/grid.service";
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class VendorGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.vendor.vendor-grid", userSettings);
  }

  defaultColumns = [
    {
      hidingPriority: 2,
      caption: "Name",
      dataField: "name"
    }
  ];
  
}
