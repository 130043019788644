<div class="order-section-edit" fxFlex="100%">
  <form *ngIf="form"
    class="ca-form ca-contact-selection-form" [formGroup]="form" fxLayout="column" fxFlex="100%">
    <div class="mat-dialog-top-spacer"></div>

    <div class="ca-dialog-title">
      <h1 mat-dialog-title>{{this.formTitle}}</h1>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column" fxLayoutGap="24px">
      <ca-default-picker caInput fxFlex="100%">
        <mat-form-field>
          <mat-select placeholder="Contact Function"
                      formControlName="contact_function"
                      required
                      [disabled]="form?.get('required')?.value == true">
            <mat-option
              [value]="entry.key"
              *ngFor="let entry of contactFunctionsUI" (click)="contactFunctionChange($event)">
              {{entry.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ca-default-picker>
    </div>

    <div class="flex-container wrapper"  fxLayoutGap="24px">

      <ca-person-picker fxFlex="90%"
        [disabled]="!form?.get('contact_function').value"
        formControlName="id"
        (onSelectionChange)="onSelectionChange($event)"
        [pickerIcon]="true"
        caInput
        [clearEnabled]="true"
        placeholder="Contact Name"
        [initialItem]=""
        [pickerForm]="form"
        pickerType="contact"
        type="{{ CONTACT_TYPE_ENUM.INTERNAL }}"
        contactFunctionId="{{contactFunctionId}}"
      >
      </ca-person-picker>

      <div class="card-header-commands " >
        <a matTooltip="{{showAll? 'Show all' : 'Filter'}}"
           [ngClass]="{'active': showAll}"
           class="input-command">
          <!-- <ca-icon [key]="showAll ? 'FILTERED_CONTACTS' : 'SHOW_ALL_CONTACTS'" (click)="toggleShowAllContacts(showAll)"></ca-icon> -->
          <ca-icon [key]="'ORDER_CONTACT_FILTER'" (click)="toggleShowAllContacts(showAll)"></ca-icon>
        </a>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column" fxLayoutGap="24px">
      <div class="flex-item" fxFlex>
        <mat-form-field caInput>
          <input matInput type="text" formControlName="first_name" placeholder="First Name*">
        </mat-form-field>
      </div>
      <div class="flex-item" fxFlex>
        <mat-form-field caInput>
          <input matInput type="text" formControlName="last_name" placeholder="Last Name*">
        </mat-form-field>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column" fxLayoutGap="24px">
      <div class="flex-item" fxFlex>
        <ca-country-picker
          caInput
          formControlName="country_id"
          placeholder="Country"
          [pickerForm]="form"
          [showAllEntries]="true"
          [clearEnabled]="false"
          [required]="true"
          [searchEnabled]="true"
          [disabled]="viewOnly"
          (onSelectionChange)="onCountryChange($event)">
        </ca-country-picker>
      </div>

      <div class="flex-item" fxFlex="77%">
        <mat-form-field caInput>
          <input matInput type="text" formControlName="mobile_number" placeholder="Contact Number" [mask] = "phoneRegexMask">
          <mat-hint *ngIf="(form.hasError('pattern', ['mobile_number']) || this.form.get('mobile_number').invalid) && form.controls.mobile_number.touched"
                    class="error-class">{{phoneRegexMask}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column" fxLayoutGap="24px">
      <div class="flex-item" fxFlex>
        <mat-form-field caInput>
          <input matInput type="email" formControlName="email" placeholder="Email*">
          <mat-hint *ngIf="(form.hasError('pattern', ['email']) || this.form.get('email').invalid) && form.controls.email.touched"
                    class="error-class">{{VALIDATION?.EMAIL_ERROR}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <mat-dialog-actions class="ca-dialog-action">
      <div class="example-fill-remaining-space"></div>

      <button mat-button type="cancel" (click)="closeDialog()">
        Cancel
      </button>

      <button mat-button [disabled]="form?.invalid" type="submit" (click)="onSubmit()">
        Save
      </button>
    </mat-dialog-actions>
  </form>
</div>
