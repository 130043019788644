<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Approve/Reject</h1>
</div>
<div mat-dialog-content class="ca-dialog-content" *ngIf="form">

  <form [formGroup]="form" class="ca-form dialog-content-no-margin">
    <div>

      <div class="flex-container" fxLayout="row wrap">
        <div class="flex-item caGap" fxFlex>
          <ca-lookup-model-picker #reason caInput
                                  [clearEnabled]="true"
                                  [lookupModel]="ORDER_REJECT_REASON_LOOKUP_MODEL"
                                  formControlName="reason_id"
                                  placeholder="Rejection Reason"
                                  [pickerForm]="form"
                                  (onSelectionChange)="onRejectResonChange($event)">
          </ca-lookup-model-picker>
        </div>
      </div>

      <div class="flex-container" fxLayout="row wrap">
        <div class="flex-item caGap" fxFlex>
          <mat-form-field caInput>
            <textarea formControlName="note" name="note" rows="3" matInput placeholder="Note" #rejectNote></textarea>

            <mat-hint *ngIf="form.hasError('required', ['note']) && form.controls.note.touched && noteRequired"
                     class="error-class">{{VALIDATION.REQUIRED_ERROR}}
            </mat-hint>
            <mat-hint *ngIf="form.hasError('minlength', ['note']) && form.controls.note.touched && noteRequired"
                     class="error-class">{{LOCALS.NOTE_VALIDATION_MSG}}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>


  </form>
</div>
<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="submit" (click)="cancel()">
    Cancel
  </button>
  <button mat-button type="button" [disabled]="!canReject" (click)="reject(form)">
    Reject
  </button>
  <button mat-button type="button" (click)="approve(form)" style="margin-right: 15px;">
    Approve
  </button>
</mat-dialog-actions>
