import {Injectable} from "@angular/core";
import {BaseService} from "../../core/base.service";
import {InvoiceDocument} from "./invoice-document";
import {Restangular} from "ngx-restangular";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {DocumentQuery} from "../../shared/document/core/document.query";
import {Observable, throwError as observableThrowError} from "rxjs";
import Auth from "../../shared/user-session/auth";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable()
export class InvoiceDocumentService extends BaseService<InvoiceDocument> {

  constructor(public restangular: Restangular,
              private entityLock: EntityLockService,
              public http: HttpClient
  ) {
    super('invoice', restangular, entityLock);
  }

  getDocuments(query: DocumentQuery) {
    let transformedQuery = query.transform();
    return this.service().one(this.name).all('documents').customGET(null, this.toFilter(transformedQuery));
  }

  addDocument(invoice_id: number, data) {
    return this.service().one(this.name, invoice_id).all('document').post(data);
  }
  editDocument(document_id: number, data, invoice_id: string) {
    return this.service().one(this.name, invoice_id).all(`document/${document_id}`).customPUT(data);
  }

  isFileNameTaken(entityId: number, fileName: string): Observable<any> {
    return this.service().one(this.name, entityId).one(`documents`, `${fileName}`).customGET(null);
  }
  
  deleteDocument(invoice_id: number, document_id: number) {
    return this.service().one(this.name, invoice_id).one('document', document_id).remove();
  }

  public downloadSource(id: number, documentId: number) {
    let cookie = Auth.getSession();
    let params: HttpParams = new HttpParams();
    params.set('authorization', cookie.token);

    let endPoint: string = [environment.API_URL, this.name, '/', id.toString(), '/download/documentSource','/', documentId].join('');

    try {
      return this.http.get(endPoint, {
          responseType: 'blob',
          headers: {
            authorization: `Bearer ${cookie.token}`
          }
        }
      )
    } catch(error) {
      this.handleError(error)
    }
  }

  public handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      const body = error || '';
      const err = body['error'] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
