import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';

import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";
import {
  UpdateInventoryPricingActiveSiderSection,
} from "./inventory-pricing.actions";

const INVENTORY_PRICING_STATE_TOKEN = new StateToken<InventoryPricingStateModel>('inventory_pricing');

export interface InventoryPricingStateModel extends QueryableStateModel<any> {

}

@State<InventoryPricingStateModel>({
  name: INVENTORY_PRICING_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InventoryPricingState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  // @ts-ignore
  @Action(UpdateInventoryPricingActiveSiderSection)
  updateInventoryPricingActiveSiderSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
