
import Query from "../../core/query/query";
export class AccountQuery extends Query {

  // transform() {
  //   let query = {
  //     limit: this.limit,
  //     offset: this.offset,
  //     orderBy: this.orderBy,
  //     where: Object.assign({}, this.where || {})
  //   };
  //
  //   if (this.where['account_no']) {
  //     query.where['account_no'] = {'$ilike': this.where['account_no'] + '%'}
  //   }
  //
  //   return query;
  // }

}
