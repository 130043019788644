import {ContactModule} from '../contact/contact.module';
import {DndModule} from 'ng2-dnd';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreModule} from '../core/core.module';
import {InventoryRoutingModule} from './inventory-routing.module';
import {InventoryManageDialogComponent} from './shared/inventory-manage-dialog/inventory-manage-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from 'app/material-barrel/material-barrel.module';
import {DxDataGridModule} from 'devextreme-angular/ui/data-grid';
import {DxDateBoxModule} from 'devextreme-angular/ui/date-box';

import {InventoryService} from './core/inventory.service';
import {InventoryGridService} from './core/inventory-grid.service';
import {InventoryFilterService} from './core/inventory-filter.service';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SharedModule} from '../shared/shared.module';
import {DictionaryModule} from '../dictionary/dictionary.module';
import {VendorModule} from '../vendor/vendor.module';
import {CustomerModule} from '../customer/customer.module';
import {ContractModule} from '../contract/contract.module';
import {SiteModule} from '../site/site.module';
import {AccountModule} from '../account/account.module';
import {InventoryListComponent} from './inventory-list/inventory-list.component';
import {InventorySneakPeekComponent} from './shared/inventory-sneak-peek/inventory-sneak-peek.component';
import {InventoryDetailsComponent} from './inventory-details/inventory-details.component';
import {
  InventorySiteManageDialogComponent
} from './shared/inventory-site-manage-dialog/inventory-site-manage-dialog.component';
import {InventoryPricingGridService} from './core/inventory-pricing-grid.service';
import {InventoryPricingDialogComponent} from './shared/inventory-pricing-dialog/inventory-pricing-dialog.component';
import {GLSystemRuleModule} from '../gl-system-rules/gl-system-rule.module';
import {GLRuleStringGridService} from '../gl-rule/core/gl-rule-string-grid.service';
import {GLStringService} from '../gl-string/core/gl-string.service';
import {QuotingModule} from "../quoting/quoting.module";
import {InventoryCircuitsGridService} from "./core/inventory-circuits-grid.service";
import {InventoryBillingFilterService} from "./core/inventory-billing-filter.service";
import {CaInventoryPickerComponent} from './shared/inventory-picker/ca-inventory-picker.component';
import {CaInventoryRelationshipTypePickerComponent} from './shared/inventory-relationship-type-picker/inventory-relationship-type-picker'
import {InventoryBillingGridService} from "./core/inventory-billing-grid.service";
import {DemarcModule} from "../demarc/demarc.module";
import { LocationModule } from 'app/location/location.module';
import { InventoryOrderGridService } from "./core/inventory-order-grid-service";
import {InventoryContractTermManageDialogComponent} from "./shared/inventory-contract-term-manage-dialog/inventory-contract-term-manage-dialog.component";
import {InventoryContractTermGridService} from "./core/inventory-contract-term-grid-service";
import {SvcOrderServiceInventoryGridService} from "./core/svc-order-service-inventory-grid.service";
import {NgxsModule} from "@ngxs/store";
import {InventoryState} from "./state/inventory.state";
import {FilterFeatureModule} from "../common/filter/filter-feature.module";
import {InventorySummaryComponent} from "./inventory-details/inventory-summary/inventory-summary.component";
import {InventoryOrderComponent} from "./inventory-details/inventory-order/inventory-order.component";
import {InventorySiteComponent} from "./inventory-details/inventory-site/inventory-site.component";
import {InventoryBillingComponent} from "./inventory-details/inventory-billing/inventory-billing.component";
import {InventoryCodeComponent} from "./inventory-details/inventory-code/inventory-code.component";
import { InventoryContractTermsComponent } from './inventory-details/inventory-contract-terms/inventory-contract-terms.component';
import {InventoryPricingComponent} from "./inventory-details/inventory-pricing/inventory-pricing.component";
import {
  InventoryRelatedCircuitComponent
} from "./inventory-details/inventory-related-circuit/inventory-related-circuit.component";
import {InventoryDocumentComponent} from "./inventory-details/inventory-document/inventory-document.component";
import {
  InventoryDetailsTabsComponent
} from "./inventory-details/inventory-details-tabs/inventory-details-tabs.component";
import {
  InventoryDetailsToolbarComponent
} from "./inventory-details/inventory-details-toolbar/inventory-details-toolbar.component";
import {InventoryDetailsState} from "./inventory-details/state/inventory-details.state";
import {UpdateInventoryBillingQuery} from "./inventory-details/inventory-billing/state/inventory-billing.actions";
import {InventoryBillingState} from "./inventory-details/inventory-billing/state/inventory-billing.state";
import {InventoryCodeState} from "./inventory-details/inventory-code/state/inventory-code.state";
import {InventoryContractTermsState} from './inventory-details/inventory-contract-terms/state/inventory-contract-terms.state';
import {InventoryPricingState} from "./inventory-details/inventory-pricing/state/inventory-pricing.state";
import {InventorySummaryState} from "./inventory-details/inventory-summary/state/inventory-summary.state";
import {
  InventoryRelatedCircuitState
} from "./inventory-details/inventory-related-circuit/state/inventory-related-circuit.state";
import {InventorySiteState} from "./inventory-details/inventory-site/state/inventory-site.state";
import {InventoryDocumentState} from "./inventory-details/inventory-document/state/inventory-document.state";
import {InventoryOrderState} from "./inventory-details/inventory-order/state/inventory-order.state";
import {RateContractScheduleInventoryGridService} from "./core/rate-contract-schedule-inventory-grid-service";
import {InventoryRateFilterService} from "./core/inventory-rate-filter.service";


@NgModule({
  imports: [

    CommonModule,
    InventoryRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    DxDataGridModule,
    DxDateBoxModule,
    SharedModule,
    DictionaryModule,
    AccountModule,
    VendorModule,
    CustomerModule,
    ContractModule,
    CoreModule,
    SiteModule,
    GLSystemRuleModule,
    ContactModule,
    DndModule,
   // QuotingModule,
    DemarcModule,
    LocationModule,
    NgxsModule.forFeature([
      InventoryState,
      InventoryDetailsState,
      InventoryBillingState,
      InventoryCodeState,
      InventoryContractTermsState,
      InventorySiteState,
      InventorySummaryState,
      InventoryOrderState,
      InventoryDocumentState,
      InventoryOrderState,
      InventoryRelatedCircuitState,
      InventoryPricingState,
    ]),
    FilterFeatureModule,
  ],
  declarations: [
    InventoryManageDialogComponent,
    InventoryListComponent,
    InventorySneakPeekComponent,
    InventoryDetailsComponent,
    InventorySiteManageDialogComponent,
    InventoryContractTermManageDialogComponent,
    InventoryPricingDialogComponent,
    CaInventoryPickerComponent,
    CaInventoryRelationshipTypePickerComponent,
    InventorySummaryComponent,
    InventorySiteComponent,
    InventoryOrderComponent,
    InventoryBillingComponent,
    InventoryCodeComponent,
    InventoryContractTermsComponent,
    InventoryPricingComponent,
    InventoryRelatedCircuitComponent,
    InventoryDocumentComponent,
    InventoryDetailsTabsComponent,
    InventoryDetailsToolbarComponent
  ],
  providers: [
    InventoryService,
    InventoryGridService,
    InventoryFilterService,
    InventoryPricingGridService,
    GLRuleStringGridService,
    GLStringService,
    InventoryCircuitsGridService,
    InventoryBillingFilterService,
    InventoryBillingGridService,
    InventoryOrderGridService,
    InventoryContractTermGridService,
    SvcOrderServiceInventoryGridService,
    RateContractScheduleInventoryGridService,
    InventoryRateFilterService
  ],
  entryComponents: [
    InventoryManageDialogComponent,
    InventorySiteManageDialogComponent,
    InventoryPricingDialogComponent,
    InventoryContractTermManageDialogComponent
  ],
  exports: [
    InventorySneakPeekComponent,
    CaInventoryPickerComponent,
    CaInventoryRelationshipTypePickerComponent
  ]
})
export class InventoryModule {
}
