import {HostListener, Input, Directive, Inject} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormGroup, FormControl, Validator, FormBuilder } from '@angular/forms';

import { DialogService } from '../shared/dialog/dialog.service';
import { CanComponentDeactivate } from './guard/can-deactivate-guard.searvice';
import { ValidationService } from '../shared/validation/validation.service';
import { DataLockService } from './data-lock/data-lock.service';
import { DialogButtonsController } from './dialog-buttons-controller.class';
import 'rxjs/add/operator/do';
import {takeUntil} from "rxjs/operators";

export interface DialogFormField {
  name: string;
  validator?: Function,
  getter?: (any) => any
}

export interface DialogFormBuilder {
  group: (any) => FormGroup
}

export interface DialogFormCreator {
  fields: (() => Array<DialogFormField>) | Array<DialogFormField>;
  formBuilder: DialogFormBuilder
}

// @ts-ignore
@Directive({
  selector: 'page-manage-dialog'
})
export class PageManageDialogComponent extends DialogButtonsController implements CanComponentDeactivate {
  form: FormGroup;
  formTitle: string;
  isUpdate: boolean;
  @Input() viewOnly = false;
  itemId: number;
  viewTitle: string;
  editTitle: string;
  formLockType: any;
  pristineForm: any = {};
  formChanges = false;

  phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  extensionMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];

  readonly unsavedChanges: string = `Discard changes`;
  readonly VALIDATION = ValidationService.MESSAGES;
  readonly DEFAULTS = ValidationService.PATTERNS;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public dialogService: DialogService,
              @Inject("") public dialogRef?: any,
    public dataLockService?: DataLockService,
    public formBuilder?: FormBuilder
  ) {
    super();
  }

  ngAfterViewInit(): void {

  }

  getForm(item, fields) {
    fields = typeof fields === 'function' ? fields() : fields;
    return this.formBuilder.group({
      ...fields.reduce((acc: any, field: any) => {
        acc[field.name] = new FormControl(field.getter ? field.getter(item) : item[field.name], field.validator);
        return acc;
      }, {})
    });
  }

  afterInit() {
    Object.assign(this.pristineForm, this.form.value);

    this.form.valueChanges.subscribe(data => {
      let dataCopy = JSON.parse(JSON.stringify(data));
      let formCopy = JSON.parse(JSON.stringify(this.pristineForm));

      // TODO: what does this thing do inside of this module ???
      if (!this.isUpdate) {
        // fix for preselecting country on add
        delete dataCopy['country_id'];
        delete formCopy['country_id'];
      }

      let newData = JSON.stringify(dataCopy);
      let oldData = JSON.stringify(formCopy);

      this.formChanges = !(newData === oldData);
    });
  }

  // Yes, this method does exactly the same thing as the one above
  // but with the main difference of returning the observable and not being coupled with some country preselection logic
  trackFormChanges(form) {
    const changed$ = form.valueChanges.map(data => {
      let dataCopy = JSON.parse(JSON.stringify(data));
      let formCopy = JSON.parse(JSON.stringify(this.pristineForm));

      let newData = JSON.stringify(dataCopy);
      let oldData = JSON.stringify(formCopy);

      return newData !== oldData;
    }).do((changed) => {
      this.formChanges = changed;
    });

    return this.destroy$ ? changed$.pipe(takeUntil(this.destroy$)) : changed$;
  }

  closeDialog(closeValue?: any, skipForChanges?: boolean): void {
    if (!skipForChanges && this.formChanges) {
      this.dialogService.save({
        bodyText: this.unsavedChanges
      }).afterClosed()
        .subscribe((result) => {
          if (result) {
            this.toggleDialogButtons(false);
            this.dialogRef.close(closeValue);
          }
        });
    } else {
      this.toggleDialogButtons(false);
      this.dialogRef.close(closeValue);
    }
  }

  public RangeErrorMessage(from: any, to: any) {
    return ValidationService.getRangeErrorMessage(from, to);
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.formChanges;
  }

  public editForm() {
    this.form.enable();
    this.viewOnly = false;
    this.formTitle = this.editTitle;
  }

  public initViewOnlyForm(itemId: number,
    viewTitle: string,
    editTitle: string,
    formLockType: any) {
    this.itemId = itemId;
    this.viewTitle = viewTitle;
    this.editTitle = editTitle;
    this.formLockType = formLockType;

    if (this.viewOnly) {
      this.form.disable();
      this.formTitle = this.viewTitle;
    }
  }
}
