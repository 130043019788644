
import {of as observableOf,  Observable } from 'rxjs';

// TODO: Put this in abstract class
export class IFilterLookupProvider {
  customRanges: any;
  excludeRanges: any;
  customRange: boolean;

  findAll(text?: any): Observable<any> {
    return observableOf({});
  }
}
