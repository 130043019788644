import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable()
export class BrandService {

  constructor(public restangular: Restangular) {
  }

  get(id){
    return this.restangular.one('brand').one(id).customGET('', null);
  }


}
