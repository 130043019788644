<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-details-title">{{ dispute?.dispute_id }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button  *ngIf="tabGroup.isActive(3)" [disabled]="!selectedWithheld" mat-icon-button matTooltip="Revert Withheld" (click)="revertWithheld()">
    <ca-icon [key]="'APPLY_FILTER'"></ca-icon>
  </button>
  <button *ngIf="this.alertId" mat-icon-button (click)="getAlertDetailPage()">
    <ca-icon [key]="'VIEW_ALERT_DETAILS'" [tooltip]="'View Alert'"></ca-icon>
  </button>
  <button
    *permission="'Modify'"
    mat-icon-button
    matTooltip="Edit Dispute"
    (click)="edit()"
    [disabled]="dispute && (
        dispute.status_id == DISPUTE_STATUS_ENUM.CLOSED_LOST ||
        dispute.status_id == DISPUTE_STATUS_ENUM.CLOSED_WON
      )">
    <ca-icon [key]="'EDIT'"></ca-icon>
  </button>
  <button *permission="'Delete'" mat-icon-button matTooltip="Delete" [disabled]="isChargesDeleteDisabled()" (click)="deleteSelectedCharges()">
    <ca-icon [key]="'DELETE'"></ca-icon>
  </button>
  <div *ngIf="tabGroup.isActive(2)" class="vertical-divider"></div>

  <app-document-manager-commands
    *ngIf="tabGroup.isActive(2)"

    [deleteDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DELETE_DOCUMENT_ACTION"
    [deleteDocumentDisablePredicate]="isDownloadDisabled(selection)"

    [uploadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.UPLOAD_DOCUMENT_ACTION"
    [uploadDocumentDisablePredicate]="false"

    [downloadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DOWNLOAD_DOCUMENT_ACTION"
    [downloadDocumentDisablePredicate]="isDownloadDisabled(selection)"
  >
  </app-document-manager-commands>


  <div *ngIf="tabGroup.isActive(3)" class="vertical-divider"></div>

  <button *ngIf="tabGroup.isActive(3)" mat-icon-button matTooltip="Refresh List" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>

  <button mat-icon-button *ngIf="tabGroup.isActive(3)" (click)="gridSettings()">
    <ca-icon [key]="'GRID_SETTINGS'" [tooltip]="'Settings'"></ca-icon>
  </button>

  <button *ngIf="tabGroup.isActive(3)" mat-icon-button type="button" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'" [tooltip]="'Download CSV'"></ca-icon>
  </button>

  <div class="vertical-divider"></div>

  <button
    mat-icon-button
    [ngClass]="{
      on: sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(3)
    }"
    (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)"
    *ngIf="tabGroup.isActive(3)"
  >
    <ca-icon [key]="'FILTER'" [tooltip]="'Filter'"></ca-icon>
  </button>

  <button matTooltip="Notes"
          [ngClass]="{'on': sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.NOTES_SECTION_NAME)" mat-icon-button>
          <ca-icon [key]="notesCount > 0 ? 'NOTES' : 'NOTES_EMPTY'"></ca-icon>
  </button>
  <button matTooltip="History"
          [ngClass]="{'on': sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.HISTORY_SECTION_NAME)" mat-icon-button>
          <ca-icon [key]="'HISTORY'"></ca-icon>
  </button>
</mat-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
    <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="activeTabIndex">
        <mat-tab [label]="tab.title" [disabled]="tab.disabled" *ngFor="let tab of tabGroup.tabs">
        </mat-tab>
    </mat-tab-group>

  <div [ngClass]="{'hidden': !tabGroup.isActive(1)}" class="tabs-body" fxLayout="column">
    <div class="flex-container ca-card custom-mat-card-container" fxLayout="row">
      <div fxFlex>
        <mat-card fxFlex fxFlexFill caCard>
          <mat-card-header>
            Dispute Info
          </mat-card-header>
          <mat-card-content class="inner-content">
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Vendor</label>
                <p>
                  {{ dispute?.vendor?.name || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Withholding Status</label>
                <p>
                  {{ dispute?.withheld_status_obj?.value || 'N/A' }} </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Filed By</label>
                <p>
                  {{ dispute?.user?.full_name || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Filed Date</label>
                <p>
                  {{ (dispute?.created_at | caDate) || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Status</label>
                <p>
                  {{ dispute?.status_obj?.value || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Resolution Date</label>
                <p>
                  {{ (dispute?.resolution_date | caDate) || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Currency</label>
                <p>
                  {{ dispute?.currency?.currency || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item"> </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex>
        <mat-card fxFlex="500px" fxFlexFill class="dispute-summary-card" caCard>
          <mat-card-header>Dispute Financial Summary</mat-card-header>
          <mat-card-content class="inner-content">

            <div fxLayout class="card-list-entry">
              <div fxFlex><span>Billed Charges</span></div>
              <div fxFlex="120px" class="right">{{ dispute?.total_amount | caCurrency: dispute?.currency?.currency }}</div>
            </div>
            <div fxLayout class="card-list-entry">
              <div fxFlex><span>Calculated Charges</span></div>
              <div fxFlex="120px" class="right"> {{ dispute?.calculated_amount | caCurrency: dispute?.currency?.currency }}
              </div>
            </div>
            <div fxLayout class="card-list-entry-bgr">
              <div fxFlex><span>Dispute Amount</span></div>
              <div fxFlex="120px" class="right"> {{ dispute?.disputed_amount | caCurrency: dispute?.currency?.currency }}
              </div>
            </div>
            <div fxLayout class="card-list-entry">
              <div fxFlex><span>Dispute Awarded</span></div>
              <div fxFlex="120px" class="right"> {{ dispute?.dispute_value_awarded || 0 | caCurrency: dispute?.currency?.currency }}
              </div>
            </div>
            <div fxLayout class="card-list-entry-bgr">
              <div fxFlex><span>Over/(Unrealized) Portion of Disputed Amount</span></div>
              <div fxFlex="120px" class="right"> {{ dispute?.payback_amount | caCurrency: dispute?.currency?.currency }}
              </div>
            </div>
            <div fxLayout class="card-list-entry">
              <div fxFlex><span>Portion of Dispute Amount Withheld</span></div>
              <div fxFlex="120px" class="right"> {{ paymentWithheld | caCurrency: dispute?.currency?.currency }}
              </div>
            </div>

          </mat-card-content>
        </mat-card>
      </div>
    </div>

  </div>

  <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(3)}" class="tabs-body grid-container">
    <div class="grid-body app-content-list"><!--app-content-list-no-pager-->
      <dx-data-grid
            class="wrapper-content"
            [dataSource]="disputeCharges"
            [columns]="columns"
            (onSelectionChanged)="onSelectionChanged($event)"
            (onCellClick)="onChargesCellClick($event)"
            #chargesGrid>

            <div *dxTemplate="let d of 'selectedChargesHeaderTemplate'"
                 class="grid-selection-badge-container">
              <a class="selection-badge grid-badge"
                 matTooltip="Selection"
                 (click)="loadSelected()"
                [ngClass]="{ 'active': chargesSelectionActive }">
                {{selectedCharges.length}}
              </a>
            </div>
        <div *dxTemplate="let d of 'dateTemplate'">
          <div caGridTooltip>
            {{ d.value | caDate}}
          </div>
        </div>

            <div *dxTemplate="let d of 'dateTimeTemplate'">
              <div caGridTooltip class="right">
                {{ d.value | caDateTime}}
              </div>
            </div>

            <div *dxTemplate="let d of 'numberTemplate'">
              <div caGridTooltip class="right">
                {{ d.value | caNumber}}
              </div>
            </div>

            <div *dxTemplate="let d of 'chargeNoteTemplate'" style="position: relative;">

              <a
                class="grid-link"
                [ngClass]="d.data?.charge?.note_charges?.length ? 'icon-accent' : 'disabled'"
                (click)="d.data?.charge?.note_charges?.length && openNotesPanel(false, d.data.charge.note_charges[0].note_id,d.data)"
                class="charge-grid-icons note-charge-grid-icon note-icon">

                <ca-icon [key]="d.data?.charge?.note_charges?.length ? 'NOTES' : 'NOTES_EMPTY'" [innerClass]="'charge-grid-icon'" [tooltip]="'Note'"></ca-icon>


              </a>
            </div>

            <div *dxTemplate="let d of 'disputeInvoiceCellTemplate'">
              <div caGridTooltip>{{ d.data.charge?.invoice?.sp_inv_num }}</div>
            </div>

            <div *dxTemplate="let d of 'withheldCellTemplate'">
              <div caGridTooltip>{{ d.value ? 'Yes' : 'No' }}</div>
            </div>

            <div *dxTemplate="let d of 'disputeChargesHeaderTemplate'">
              <div caGridTooltip>{{ d.column.caption}}</div>
            </div>

            <div *dxTemplate="let d of 'disputeChargesStatusCellTemplate'">
              <div caGridTooltip>{{ d.data.status_item?.value }}</div>
            </div>

            <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
              <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.charge.currency }}</div>
            </div>

            <div *dxTemplate="let d of 'statusTemplate'">
              <div caGridTooltip>{{ d.value ? d.value : 'Filed' }}</div>
            </div>

          </dx-data-grid>
          <ca-pager
          class="app-pager ca-default-border-top"
          [gridPager]="defaultGridPager"
          [pageContext]="disputeChargesGridService"
          [query]="disputeChargeQuery"
          (onChange)="onPageChange()"
        ></ca-pager>
    </div>
  </div>

  <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}" class="tabs-body grid-container" style="overflow-y: hidden;">
    <!--SECTION DOCUMENTS-->
    <app-document-manager
      #documentsManager
      [tabChanged]="tabChanged"
      [folder]="DOCUMENT_INFO.FOLDER"
      [parentType]="DOCUMENT_INFO.PARENT_TYPE"
      [entityType]="DOCUMENT_INFO.ENTITY_TYPE"
      [subfolder]="DOCUMENT_INFO.SUBFOLDER"
      [entity]="dispute"
      (docSelected)="docSelected($event)"
      (docAdd)="history.refreshList()"
      (docDelete)="history.refreshList()"
      [masterId]="disputeId"
      [masterType]="SYSTEM_MODULE.DISPUTE"
      [documentQuery]="documentQuery"
    >
    </app-document-manager>
    <ca-pager
      class="app-pager ca-default-border-top"
      [gridPager]="defaultGridPager"
      [pageContext]="disputeChargesGridService"
      [query]="documentQuery"
      (onChange)="onPageDocumentChange($event)"
    ></ca-pager>
  </div>

  <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false">
    <new-app-filter
      *ngIf="tabGroup.isActive(3)"
      #appFilter
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
      [query]="disputeChargeQuery"
      [filterService]="disputeChargeFilterService"
      (filterRequested)="loadCharges(disputeId, $event)"
      (clearFilterRequested)="clearChargeFilter()"
    >
    </new-app-filter>

    <ng-container *ngIf="!tabGroup.isActive(3)">
      <ca-notes
          fxFlex fxLayout="column"
          [entityId]="dispute?.id"
          [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
          [entityType]="SYSTEM_MODULE.DISPUTE"
          [selectedId]="selectedNoteId"
          (countChanged)="onNotesCountChanged($event)"
          (viewNoteCharges)="onViewNoteCharges($event)"
          (noteCreated)="onNoteCreated()"
          #notesComponent>
      </ca-notes>
    </ng-container>
    <ng-container *ngIf="tabGroup.isActive(3)">
      <ca-charge-notes
          fxFlex fxLayout="column"
          [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
          [entityType]="SYSTEM_MODULE.CHARGE"
          [charges]="selectedChargesForNotesComponent"
          [chargesRequired]="true"
          [chargesNote]="chargesNote"
          [selectedId]="selectedNoteId"
          (countChanged)="onNotesCountChanged($event)"
          (viewNoteCharges)="onViewNoteCharges($event)"
          (noteCreated)="onNoteCreated()"
          [query]="notesQuery"
          #notesComponent>
      </ca-charge-notes>
    </ng-container>
    <ca-history
        #history
        [entityId]="dispute?.id"
        [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
        [entityType]="'dispute'">
    </ca-history>
  </mat-sidenav>

</mat-sidenav-container>
