export const contractFilterContext = {
  filters: [
    {
      field: 'name',
      label: 'Name',
      type: 'string',
      index: 1
    },
    {
      field: 'description',
      label: 'Description',
      type: 'string',
      index: 2
    },
    {
      field: 'company_name',
      label: 'Company Name',
      type: 'string',
      index: 3
    }
    // {
    //   field: 'commitment',
    //   label: 'Committed Value',
    //   type: 'negativeNumber',
    //   index: 3
    // },
  ]
};
