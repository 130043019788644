import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { LOOKUP_ENUM } from '../../dictionary/core/lookup.enum';
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { GridService } from '../../shared/grid/grid.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { UserService } from '../../user/core/user.service';
import { CommonAlert } from '../core/common-alert';
import { CommonAlertFilterService } from '../core/common-alert-filter.service';
import { CommonAlertGridService } from '../core/common-alert-grid.service';
import { CommonAlertQuery } from '../core/common-alert.query';
import { CommonAlertService } from '../core/common-alert.service';
import { AlertAssignDialogComponent } from '../shared/alert-assign-dialog/alert-assign-dialog.component';
import {LOOKUP_MODELS_ENUM} from "../../dictionary/core/lookup-models.enum";



@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.css']
})
export class AlertListComponent extends PageListComponent
  implements AfterViewInit {
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'common-alert';

  public query: CommonAlertQuery = new CommonAlertQuery({
    where: {
      "status.key": {
        $in: [
          LOOKUP_ENUM.ALERT_STATUS_STRING.NEW,
          LOOKUP_ENUM.ALERT_STATUS_STRING.IN_PROGRESS
        ]
      }
    },
    orderBy: [['created_at', 'DESC']]
  });

  public alerts: Array<CommonAlert>;
  public totalAlerts: number;
  public selection: CommonAlert;
  public selectedRows: Array<CommonAlert> = [];
  public delaySelectionChange = false;
  public selectionActive = false;
  public filter: any;

  readonly AUDIT_RULE_NAMES = {
    'GL_UNCODED_CHARGES': 'GL_UNCODED_CHARGES',
    'CHARGE': 10,
    'INVENTORY_MRC': 25,
    'GL_VALIDATION': 70,
    'ACCOUNT': 50,
    'RATE_AUDIT': 80,
    'BATCH': 90,
    'MULTIPLE_AUDITS': 100,
    'DISCONNECT_AUDIT': 110,
    'MISSING_INVENTORY': 120
  }

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public sorting: any[][];

  constructor(
    public commonAlertGridService: CommonAlertGridService,
    public commonAlertService: CommonAlertService,
    public alert: AlertService,
    public router: Router,
    public dialog: DialogService,
    public settingsService: UserSettingsService,
    public userService: UserService,
    public commonAlertFilterService: CommonAlertFilterService,
    public loaderService: LoaderService
  ) {
    super(
      new PageContext({
        name: 'app.alert.alert-list',
        settings: settingsService
      })
    );

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  loadData(query?: CommonAlertQuery) {
    this.delaySelectionChange = true;

    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.commonAlertService.findAllLargeRequest(query).subscribe(
      result => {
        this.alerts = result.items;
        this.query.total = this.totalAlerts = result.total;

        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);

        if (this.alerts.length) {
          setTimeout(() => {
            this.delaySelectionChange = false;
            this.dataGrid.instance.selectRows(this.selectedRows, false);
          }, 500);
        }
      },
      error => {
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      }
    );
  }

  /** Selection badge active */
  loadSelected() {
    if (this.query && !this.query['where'].hasOwnProperty('id')) {
      this.filter = JSON.parse(JSON.stringify(this.query['where']));
    }
    if (this.selectionActive) {
      this.query.remove('id');
      this.query['where'] = this.filter;
    } else {
      this.query['where'] = {};
      this.query.set('id', {
        $in: this.selectedRows.map(x => {
          return x.id;
        })
      });
    }

    this.query.offset = 0;
    this.query.page = 1;
    this.selectionActive = !this.selectionActive;
    this.loadData(this.query);
  }

  onPageChange(query: CommonAlertQuery) {
    this.loadData(query);
  }

  findIndexInSelectedRows(id): number {
    for (let i = 0, l = this.selectedRows.length; i < l; i++) {
      if (this.selectedRows[i].id === id) {
        return i;
      }
    }
    return -1;
  }

  onSelectionChanged(event) {
    if (!this.delaySelectionChange && this.alerts.length) {
      //** Deselected rows */
      if (event.currentDeselectedRowKeys.length) {
        event.currentDeselectedRowKeys.forEach(item => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index > -1) {
            this.selectedRows.splice(index, 1);
          }
        });
      }

      //** Selected rows */
      if (event.currentSelectedRowKeys.length) {
        event.currentSelectedRowKeys.forEach(item => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index === -1) {
            this.selectedRows.push(item);
          }
        });
      }

      if (this.selectedRows.length > 1) {
        this.selection = null;
      } else {
        this.selection = <CommonAlert>event.selectedRowsData[0];
      }

      if (this.selectionActive && !this.selectedRows.length) {
        this.loadSelected();
      }
    }
  }

  refresh() {
    this.loadData(this.query);
  }

  filterData(query: CommonAlertQuery) {
    this.query = query;
    this.loadData(query);
  }

  clearFilter() {
    this.query = new CommonAlertQuery({
      where: {
        "status.key": {
          $in: [
            LOOKUP_ENUM.ALERT_STATUS_STRING.NEW,
            LOOKUP_ENUM.ALERT_STATUS_STRING.IN_PROGRESS
          ]
        }
      },
      orderBy: [['created_at', 'DESC']]
    });
    this.loadData(this.query);
  }

  csv() {
    const {commonAlertGridService, commonAlertService, query} = this;
    commonAlertGridService.csvMap().subscribe(fields => {
      commonAlertService.exportToCSV(
        'alerts',
        {
          fields,
          query
        }
      );
    });

  }

  completeAlerts(selectedRows): void {
    this.userService.me().subscribe(me => {
      if (me) {
        this.commonAlertService.completeAlerts(selectedRows, me).subscribe(
          result => {
            this.alert.success('', this.messages.get('COMPLETE_SUCCESS'));
            this.selectedRows = [];
            this.refresh();
          },
          error => {
            this.alert.error('', this.messages.get('COMPLETE_FAIL'));
          }
        );
      }
    });
  }

  assignAlert(selectedRows) {
    this.userService.me().subscribe(
      me => {
        const isAdmin =
          me.roles.filter(role => role.name === 'Administrator').length > 0;

        if (isAdmin) {
          this.assignAlertToUser(selectedRows);
        } else {
          this.assignAlertToSelf(selectedRows, me);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  assignAlertToUser(selectedRows) {
    this.dialog
      .open(AlertAssignDialogComponent, {
        alerts: selectedRows
      }, {width: '400px'})
      .afterClosed()
      .subscribe(
        result => {
          if (result) {
            this.selectedRows.length = 0;
            this.refresh();
            this.alert.success("", this.messages.get("ASSIGN_SUCCESS"));
          }
        },
        error => {
          this.alert.error('', this.messages.get('ASSIGN_FAIL'));
        }
      );
  }

  assignAlertToSelf(alerts, me) {
    const status_id = LOOKUP_ENUM.ALERT_STATUS.IN_PROGRESS
    this.commonAlertService.changeOwners(alerts, me.id, status_id).subscribe(
      result => {
        this.selectedRows.length = 0;
        this.refresh();
        this.alert.error("", this.messages.get("ASSIGN_SUCCESS"));
      },
      error => {
        this.alert.error('', this.messages.get('ASSIGN_FAIL'));
      }
    );
  }

  viewAlertCharges(selectedRows) {
    this.selection = selectedRows[0];
    if (this.selection.rule_name === this.AUDIT_RULE_NAMES.GL_UNCODED_CHARGES && this.selection.vendor_id && this.selection.account_id) {
      this.router.navigate(['gl-rule-execution', this.selection.vendor_id, this.selection.account_id, 'charges']);
    } else if (this.selection.type_id === this.AUDIT_RULE_NAMES.CHARGE) {
      this.router.navigate(['alert', this.selection.id, 'charges']);
    } else if (this.selection.type_id === this.AUDIT_RULE_NAMES.INVENTORY_MRC) {
      this.router.navigate(['alert', this.selection.id, 'inventory-mrc']);
    } else if (this.selection.type_id === this.AUDIT_RULE_NAMES.GL_VALIDATION) {
      this.router.navigate(['alert', this.selection.id, 'gl-validation']);
    } else if (this.selection.type_id === this.AUDIT_RULE_NAMES.ACCOUNT) {
      this.router.navigate(['alert', this.selection.id, 'account']);
    }else if (this.selection.type_id === this.AUDIT_RULE_NAMES.BATCH) {
      this.router.navigate(['alert', this.selection.id, 'batch']);
    }else if (this.selection.type_id === this.AUDIT_RULE_NAMES.RATE_AUDIT) {
      this.router.navigate(['alert', this.selection.id, 'rate-audit']);
    } else if (this.selection.type_id === this.AUDIT_RULE_NAMES.MULTIPLE_AUDITS) {
      this.router.navigate(['alert', this.selection.id, 'audit']);
    } else if (this.selection.type_id === this.AUDIT_RULE_NAMES.DISCONNECT_AUDIT) {
      this.router.navigate(['alert', this.selection.id, 'disconnect']);
    } else if (this.selection.type_id === this.AUDIT_RULE_NAMES.MISSING_INVENTORY) {
      this.router.navigate(['alert', this.selection.id, 'missing-inventory']);
    } else {
      this.router.navigate(['alert', this.selection.id, 'inventory']);
    }
  }

  selectedAlertsSameVendor(selectedRows) {
    const selectedRowsVendors = selectedRows.map(item => {
      if (item.invoice) {
        return item.invoice.vendor_id;
      }
    });

    const selectedRowsAccounts = selectedRows.map(item => {
      if (item.invoice) {
        return item.invoice.acct_level_1;
      }
    });

    let distinctAccounts = Array.from(new Set(selectedRowsAccounts));
    let distinctVendors = Array.from(new Set(selectedRowsVendors));

    return distinctVendors.length !== 1 || distinctAccounts.length !== 1;
  }

  public onRowClick(row) {
    let component = row.component;
    let prevClickTime = component.lastClickTime;

    component.lastClickTime = new Date();

    if (
      prevClickTime &&
      component.lastClickTime - prevClickTime < 250 &&
      !this.selectedAlertsSameVendor(this.selectedRows)
    ) {
      this.viewAlertCharges(this.selectedRows);
    }
  }

  ngOnInit() {
    this._init();
  }

  ngAfterViewInit(): void {
    this.commonAlertGridService.create(this.dataGrid.instance, {
      selection: {
        mode: 'multiple',
        selectAllMode: 'page'
      }
    });
    super.ngAfterViewInit();

    //this.dataGrid.instance.columnOption("command:select", "width", 65);
  }

  gridSettings() {
    this.dialog
      .open(GridSettingsComponent, {
        service: this.commonAlertGridService,
        sliderMinimum: 1
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.commonAlertGridService;
  }

  getDefaultSort(): any {
    return [['created_at', 'DESC']];
  }

  isCompleteDisabled(selectedRows) {
    let isDisabled: any;
    isDisabled = selectedRows.filter(row => row.status_id === LOOKUP_ENUM.ALERT_STATUS.COMPLETE)
    return !!isDisabled.length;
  }

  isChangeOwnerDisabled(selectedRows = []) {
    return !selectedRows.length || this.isCompleteDisabled(selectedRows);
  }



  sortColumn(sorting) {
    this.query = this.query || new CommonAlertQuery();
    this.query.orderBy = sorting;

    this.loadData(this.query);
  }

  public goToInvoiceDetails() {
    if (this.selection && this.selection.invoice_id) {
      this.router.navigate(['/invoice', this.selection.invoice_id, 'show', 'overview']);
    }
  }
}
