import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { UserSettingsService } from "./user-settings.service";

@Injectable()
export class UserGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.user.user-grid", userSettings);
  }

  defaultColumns = [
    {
      dataField: 'first_name',
      caption: 'First Name',
      width: 150
    },
    {
      dataField: 'last_name',
      caption: 'Last Name',
      width: 160
    },
    {
      dataField: 'username',
      caption: 'Username',
      width: 150
    },
    {
      dataField: 'email',
      caption: 'Email',
      width: 220
    },
    {
      dataField: 'phone_number',
      cellTemplate: 'phoneCellTemplate',
      caption: 'Phone Number',
      width: 150
    },
    {
      dataField: 'mobile_number',
      caption: 'Mobile Number',
      cellTemplate: 'mobileCellTemplate',
      width: 150
    },
    {
      alignment: 'left',
      dataField: 'is_active',
      caption: 'Status',
      cellTemplate: "statusCellTemplate",
      width: 110,
      transformValue: {true: 'Active', false: 'Inactive'}
    },
    {
      dataField: 'last_activity_date',
      cellTemplate: 'lastActivityDateTemplate',
      caption: 'Last Activity Date',
      width: 150
    }
  ];

  /*columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
