import {filter, map, takeUntil} from 'rxjs/operators';
import {AfterViewInit, Component, OnInit, ViewChild, HostListener} from '@angular/core';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {IMessagesResourceService, ResourcesService} from '../../core/resources/resources.service';
import {GridSettingsComponent} from '../../core/grid/grid-settings.component';
import {PageListComponent} from '../../core/page-list.component';
import {InvoiceFacepage} from '../core/invoice-facepage';
import {InvoiceQuery} from '../core/invoice.query';
import {InvoiceGridService} from '../core/invoice-grid.service';
import {InvoiceFacepageService} from '../core/invoice-facepage.service';
import {AlertService} from '../../shared/alert/alert.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {InvoiceApproveDialogComponent} from '../shared/invoice-approve-dialog/invoice-approve-dialog.component';
import {GLBatchCreateDialogComponent} from '../gl-batch-create-dialog/gl-batch-create-dialog.component';
import {PageContext} from '../../core/page.context';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {BATCH_OUTPUT_FORMAT_ENUM, INVOICE_STATUS_ENUM} from '../core/invoice-status.enum';
import {LoaderService} from '../../shared/loader/loader.service';
import {InvoiceFilterService} from '../core/invoice-filter.service';
import {GridService} from '../../shared/grid/grid.service';
import {ACCOUNT_STATUS_ENUM} from '../../account/core/account-status.enum';
import {path} from 'ramda';
import {InvoiceFlowService} from '../core/invoice-flow.service';
import {AccountDialogService} from 'app/account/core/account-dialog.service';
import {InvoiceCsvInterceptor} from '../core/invoice-csv-interceptor';
import {forkJoin, Observable} from 'rxjs';
import {PermissionService} from '../../permissions/core/permission.service';
import {NotificationService} from 'app/shared/notification/notification.service';
import {ExchangeRateService} from 'app/location/core/exchange-rate.service';
import {UserService} from "../../user/core/user.service";
import {AuditRunComponent} from "../shared/audit-run/audit-run.component";
import {ConfigService} from "../../core/config/config.service";
import {QueryBuilder} from "../../common/query/query.builder";
import Query from "../../core/query/query";
import {Select, Store} from "@ngxs/store";
import {UpdateInvoicesQuery} from "../state/invoice.actions";
import {FilterContext} from "../../core/filter/filter-context";
import {InvoiceChargeService} from "../../charge/core/invoice-charge.service";
import {MESSAGES_ENUM} from "../../core/resources/messages.enum";
import {Location} from "@angular/common";
import {InvoiceFlowHandleService} from "../core/invoice-flow-handle.service";

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent extends PageListComponent implements AfterViewInit, OnInit {
  public readonly MESSAGES_MODULE: string = 'invoice';
  public readonly ACCOUNT_STATUS = ACCOUNT_STATUS_ENUM;
  public readonly INVOICE_HAS_DISPUTE = MESSAGES_ENUM.invoice.INVOICE_HAS_DISPUTE
  public readonly COMPONENT_NAME = 'invoice-list';

  public readonly DEFAULT_INVOICE_LIMIT = 100;
  @Select(state => state.invoices.query) $query: Observable<Query>;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  public query: InvoiceQuery = new InvoiceQuery();
  public disputeQuery: Query = new Query({
    limit: 10000
  });
  public invoices: Array<InvoiceFacepage>;
  public totalInvoices: number;
  public selection: InvoiceFacepage;
  public selectedRows: Array<InvoiceFacepage> = [];
  public delaySelectionChange = false;
  public selectionActive = false;
  public reprocessable = false;
  public processingInProgress = false;
  public columns: Array<any>;
  public sorting: any[][];
  public bulkApproveDisabled = true;
  public isInvoicesOnHold = true;
  public glOutputDisabled = true;
  public invoiceStatusEnum = INVOICE_STATUS_ENUM;
  public messages: IMessagesResourceService;
  public filter: any;
  public deleteDisabled = true;
  public isProcessButtonDisabled: boolean;
  public sameVendorsSelected = false;
  public isOverLimit: boolean = false;
  public isOverLimitGlOutput: boolean = false;
  public invoiceFilterContext: FilterContext;
  public hasDispute = false;
  public batchPaymentIconEnabled = false;
  public usingSubclients = false
  public runInvoiceLimit: number = this.DEFAULT_INVOICE_LIMIT;
  public glOutputLimit: number;
  public glOutputLimitMessage: string;

  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
    public invoiceService: InvoiceFacepageService,
    public invoiceGridService: InvoiceGridService,
    public dialogService: DialogService,
    public alertService: AlertService,
    public settingsService: UserSettingsService,
    public router: Router,
    public loaderService: LoaderService,
    public invoiceFilterService: InvoiceFilterService,
    private permissionService: PermissionService,
    private invoiceFlowService: InvoiceFlowService,
    private accountDialogService: AccountDialogService,
    public route: ActivatedRoute,
    public userService: UserService,
    public exchangeRateService: ExchangeRateService,
    public configService: ConfigService,
    public chargeService: InvoiceChargeService,
    public notificationService: NotificationService,
    public invoiceFlowHandleService: InvoiceFlowHandleService,
    public location: Location
  ) {
    super(new PageContext({
      name: 'app.invoice.invoice-list',
      settings: settingsService
    }));
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.checkIfPageWasRefresh()
    this.invoiceFlowService.loadFlowPermissions().subscribe()
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.onQueryParamsChange(params)

    });
    this.$query.pipe(takeUntil(this.destroy$)).subscribe((query) => {
      this.onQueryChange(query)

    });
    this.configService.get()
      .subscribe((config) => {
        const includeVendorPdf = config.ap_output_config.include_vendor_pdf;
        const includeProxyPdf = config.ap_output_config.include_proxy_pdf;
        const limitWithIncludedPdf = config.ap_output.limit_with_included_pdf;
        const limitWithoutIncludedPdf = config.ap_output.limit_without_included_pdf;
        const isBoolean = typeof includeVendorPdf == 'boolean';

        this.batchPaymentIconEnabled = config.invoice_flow_settings?.merge_batch_into_one_step && (
          config.gl_coding?.format === 'tailwind' || config.quickbooks
        )
        this.usingSubclients = config.ap_output?.using_subclients;

        if (config.audit && config.audit.run_invoice_limit) {
          this.runInvoiceLimit = config.audit.run_invoice_limit;
        }

        if (includeVendorPdf === null || includeProxyPdf === null) {
          return;
        } else if ((isBoolean && includeVendorPdf == true) || ['VENDOR_OR_PROXY', 'VENDOR_ONLY'].includes(includeVendorPdf) || includeProxyPdf) {
          this.glOutputLimit = limitWithIncludedPdf
        } else {
          this.glOutputLimit = limitWithoutIncludedPdf
        }

        if (this.glOutputLimit) {
          this.glOutputLimitMessage = this.messages.get('GL_OUTPUT_INVOICE_LIMIT').replace('<number>', this.glOutputLimit.toString())
        }
      });

    this.notificationService.listen().pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        let statusesToIgnore = [this.invoiceStatusEnum.NEW_PENDING, this.invoiceStatusEnum.GL_CODING_IN_PROCESS]; // , this.invoiceStatusEnum.GL_BATCH_PROCESSING
        if (
          data && data.id && this.invoices.some(inv => inv.invoice_id === data.id)
          && statusesToIgnore.indexOf(data.status_code) === -1
        ) {
          this.refresh()
          this.selectedRows = [];
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.invoiceGridService.create(this.dataGrid.instance, {
      selection: {
        mode: 'multiple',
        selectAllMode: 'page'
      }
    });

    super.ngAfterViewInit();
  }

  @HostListener("window:beforeunload", ["$event"]) HostListenerHostListener(event: Event) {
    localStorage.setItem("invoice-refresh", "true")
  }

  checkIfPageWasRefresh() {
    const key = localStorage.getItem('invoice-refresh');
    if (key) {
      const urlWithoutQueryParams = this.location.path().split('?')[0];
      this.location.replaceState(urlWithoutQueryParams);
      localStorage.removeItem('invoice-refresh');
    }
  }

  public loadData(query?: InvoiceQuery) {
    this.delaySelectionChange = true;
    query.orderBy = this.interceptAndChangeQuery(query.orderBy);
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);
    this.invoiceService.findAllLargeRequest(query)
      .subscribe((result) => {
        this.invoices = result.items;
        this.query.total = this.totalInvoices = result.total;
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);

        if (this.invoices.length) {
          setTimeout(() => {
            this.delaySelectionChange = false;
            this.dataGrid.instance.selectRows(this.selectedRows, false);
            this.reprocessable = this.isReprocessible();
          }, 500);
        }
      }, (err) => {
        this.alertService.error('', this.messages.get('INVOICES_LOAD_ERROR'));
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      });
  }

  public invoiceOtherCurrency(d) {
    return d.value * this.exchangeRateService.getRateForCurrency(d.data.currency);
  }

  /** Selection badge active */
  public loadSelected() {
    if (this.query && !this.query['where'].hasOwnProperty('id')) {
      this.filter = JSON.parse(JSON.stringify(this.query['where']));
    }
    if (this.selectionActive) {
      this.query.remove('id');
      this.query['where'] = this.filter;
    } else {
      this.query['where'] = {};
      this.query.set('id', {
        $in: this.selectedRows.map((x) => {
          return x.id;
        })
      });
    }
    this.query.offset = 0;
    this.query.page = 1;
    this.query.total = this.selectedRows.length;
    this.selectionActive = !this.selectionActive;
    this.loadData(this.query);
    this.reprocessable = this.isReprocessible();
  }

  public onPageChange(query: InvoiceQuery) {
    this.loadData(query);
  }

  public findIndexInSelectedRows(id): number {
    for (let i = 0, l = this.selectedRows.length; i < l; i++) {
      if (this.selectedRows[i].id === id) {
        return i;
      }
    }
    return -1;
  }

  public onSelectionChanged(event) {
    if (!this.delaySelectionChange && this.invoices.length) {
      // ** Deselected rows */
      if (event.currentDeselectedRowKeys.length) {
        event.currentDeselectedRowKeys.forEach((item) => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index > -1) {
            this.selectedRows.splice(index, 1);
          }
        });
      }
      // ** Selected rows */
      if (event.currentSelectedRowKeys.length) {
        event.currentSelectedRowKeys.forEach((item) => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index === -1) {
            this.selectedRows.push(item);
          }
        });
      }
      /* bulkApproveDisabled **/
      let rfaId = this.invoiceStatusEnum.RFA;
      this.bulkApproveDisabled = !(rfaId && this.selectedRows.length) || this.selectedRows.filter(function (x) {
        const statusId = x.header.status ? x.header.status.id : x.header.status_code;
        return statusId !== rfaId;
      }).length > 0;
      /* glOutputDisabled **/
      let approvedId = this.invoiceStatusEnum.APPROVED;

      this.glOutputDisabled = !(approvedId && this.selectedRows.length)
        || this.selectedRows.filter((x) => x.header.status_code !== approvedId).length > 0
        || !this.isAccountActive(this.selectedRows)
        || (this.usingSubclients && !this.isSameSubclient(this.selectedRows));

      if (this.selectedRows.length > 1) {
        this.selection = null;
      } else {
        this.selection = <InvoiceFacepage>event.selectedRowsData[0];
      }
      if (this.selectionActive && !this.selectedRows.length) {
        this.loadSelected();
      }
      this.deleteDisabled = this.calcIsDeleteDisabled();
      this.reprocessable = this.isReprocessible();
      let invoiceIds = this.selectedRows.map((row) => {
        return row.invoice_id
      })

      this.disputeQuery.set('invoice_id', {"$in": invoiceIds})

      this.chargeService.hasDispute(this.disputeQuery)
        .subscribe(res => {
          if (res.length) {
            this.hasDispute = true;
          } else {
            this.hasDispute = false;
          }
        })

      this.sameVendorsSelected = false;

      if (this.selectedRows.length) {
        const vendorIds = this.selectedRows.map(item => item.vendor_id);
        this.sameVendorsSelected = vendorIds.every(item => item === vendorIds[0]);
      }

      this.isOverLimit = this.selectedRows.length > this.runInvoiceLimit;

      this.isOverLimitGlOutput = this.selectedRows.length > this.glOutputLimit;

      /* Checking is invoices in onHold status */
      this.isInvoicesOnHold = this.selectedRows.filter(invoice => {
        if (invoice.account.on_hold) {
          return invoice
        }
      }).length > 0;
    }
  }

  public refresh() {
    this.loadData(this.query);
  }

  public filterData(query: InvoiceQuery) {
    query.total = 0;
    this.query = query;
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query, route: this.route, queryBuilder: InvoiceQuery
    })
  }

  public clearFilter() {
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query: new InvoiceQuery(), route: this.route, queryBuilder: InvoiceQuery
    })
  }

  public showDetails() {
    if (this.selection) {
      this.router.navigate(['/invoice', this.selection.invoice_id, 'show', 'overview']);
    }
  }

  csv() {
    const UI_BATCH_KEY_VALUE = 'batch.batch_key';
    const CSV_BATCH_KEY_VALUE = 'batch.batch_key';
    const {invoiceGridService, invoiceService, query} = this;
    invoiceGridService.csvMap().subscribe((fields) => {
      const invoiceTransformMiddleware = (items) => {
        const invoiceCSVInterceptor = new InvoiceCsvInterceptor(this.exchangeRateService, this.userService);
        return items.map(item => invoiceCSVInterceptor.transform(item));
      };
      fields.forEach(field => {
        if (field.value === UI_BATCH_KEY_VALUE) {
          field.value = CSV_BATCH_KEY_VALUE
        }
      });
      invoiceService.exportToCSV(
        'invoices',
        {
          fields,
          query,
          settings: {currencyField: 'currency'},
          middleware: [invoiceTransformMiddleware]
        }
      );

    });
  }


  public approveInvoices() {
    let newQuery: InvoiceQuery = new InvoiceQuery();
    newQuery.set('id', {
      $in: this.selectedRows.map((x) => {
        return x.id;
      })
    });

    this.invoiceService.isInvoiceOnHold(newQuery)
      .subscribe(res => {
        let newArray = []
        this.isInvoicesOnHold = res.filter(invoice => {
          if (invoice.account.on_hold) {
            newArray.push(invoice.invoice_id)
            return invoice
          }
        }).length > 0;

        if (this.isInvoicesOnHold) {
          this.alertService.error('', `${this.messages.get('INVOICE_ON_HOLD_MESSAGE')}${newArray}`)
        } else {
          if (this.bulkApproveDisabled) {
            return;
          }
          let invoiceIds = this.selectedRows.map((row) => {
            return row.invoice_id
          })

          this.invoiceService.checkInvoiceStatus({
            invoiceIds,
            currentStatus: this.invoiceStatusEnum.RFA
          }).subscribe(invoiceStatus => {
            if (invoiceStatus.error) {
              let invoiceIds = invoiceStatus.invoices.map(invoice => {
                return invoice.invoice_id
              });
              if (invoiceStatus.invoices.length > 1) {
                this.alertService.success('', `${this.messages.get('APPROVED_INCORRECT_STATUS_ONE_INVOICE')}${invoiceIds}`);
              }
              this.alertService.success('', `${this.messages.get('APPROVED_INCORRECT_STATUS')}${invoiceIds}`);
              return
            }
            this.dialogService.open(InvoiceApproveDialogComponent, {
              invoices: this.selectedRows
            }, {width: '650px'})
              .afterClosed()
              .subscribe((result) => {
                if (result && result.success) {
                  if (this.selectionActive) {
                    this.loadSelected();
                  } else {
                    this.loadData(this.query);
                  }
                  this.reprocessable = this.isReprocessible();
                  this.alertService.success('', this.messages.get('APPROVE_SUCCESS'));
                  this.bulkApproveDisabled = true
                  this.selectedRows = [];
                } else if (result?.success === false) {
                  this.isInvoicesOnHold = true
                }
              });
          });
        }
      })
  }

  public glOutput() {
    let newQuery: InvoiceQuery = new InvoiceQuery({
      limit: 10000
    });
    newQuery.set('id', {
      $in: this.selectedRows.map((x) => {
        return x.id;
      })
    });

    let glChgAmtQuery: InvoiceQuery = new InvoiceQuery({
      limit: 10000
    })
    glChgAmtQuery.set('invoice_id', {
      $in: this.selectedRows.map((x) => {
        return x.invoice_id;
      })
    });

    forkJoin(
      this.invoiceService.isInvoiceOnHold(newQuery),
      this.invoiceService.getGLChgAmt(glChgAmtQuery)
    ).subscribe(([onHold, glChgAmt]) => {
      let newArray = []
      this.isInvoicesOnHold = onHold.filter(invoice => {
        if (invoice.account.on_hold) {
          newArray.push(invoice.invoice_id)
          return invoice
        }
      }).length > 0;

      if (this.isInvoicesOnHold) {
        this.alertService.error('', `${this.messages.get('INVOICE_ON_HOLD_MESSAGE')}${newArray}`)
      } else {
        if (this.glOutputDisabled) {
          return;
        }

        let invoices = [...this.selectedRows]
        invoices.map(invoice => {
          let found = glChgAmt.find(e => e.invoice_id === invoice.invoice_id)
          if (found) {
            invoice['gl_chg_amt'] = found['gl_chg_amt']
          }
          return invoice
        })

        this.dialogService.open(GLBatchCreateDialogComponent, {invoices}, {width: '650px'})
          .afterClosed()
          .subscribe((result) => {
            if (result && result.id) {
              this.glBatchOutputBehaviour(result)
            } else if (result?.success === false) {
              this.isInvoicesOnHold = true
            }
          });
      }
    })
  }

  public glBatchOutputBehaviour(data) {
    this.configService.get()
      .subscribe((config) => {
        const batchOutputFormat = config.gl_coding.format.toLowerCase();

        switch (batchOutputFormat) {
          case BATCH_OUTPUT_FORMAT_ENUM.DXC:
            this.alertService.success('', this.messages.get('GL_OUTPUT_SUCCESS_DXC'));
            this.router.navigate(['gl-batch', data.id, 'show']);
            break;
          case BATCH_OUTPUT_FORMAT_ENUM.DXC_CSC:
            this.alertService.success('', this.messages.get('GL_OUTPUT_IN_PROCESS'));
            break;
          case BATCH_OUTPUT_FORMAT_ENUM.DXC_MF:
            this.router.navigate(['gl-batch', data.id, 'show']);
            break;
          case BATCH_OUTPUT_FORMAT_ENUM.TAILWIND:
          case BATCH_OUTPUT_FORMAT_ENUM.BCM_ONE:
          case BATCH_OUTPUT_FORMAT_ENUM.RING_SQUARED:
            this.alertService.success('', this.messages.get('GL_OUTPUT_SUCCESS_TAILWIND'));
            this.selectedRows = [];
            this.loadData(this.query);
            break;
          default:
            this.alertService.success('', this.messages.get('GL_OUTPUT_WRONG_FORMAT'));
        }
      })
  }

  public gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.invoiceGridService,
      sliderMinimum: 1
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  public getGridService(): GridService {
    return this.invoiceGridService;
  }

  public getDefaultSort(): any {
    return [['date_issued', 'DESC'], ['vendor_id', 'ASC'], ['sp_inv_num', 'ASC']];
  }

  public processMultipleInvoices() {
    this.processingInProgress = true;
    const invoice_ids = this.selectedRows.map(row => row.invoice_id);
    this.invoiceService.processMultipleInvoices(invoice_ids).subscribe((response: any, err: any) => {
      if (response.status === -1) {
        this.alertService.openAndClose('close', '', this.messages.get('INACTIVE_ACCOUNT_CANNOT_RUN_RULES'));
        this.selectedRows = [];
        this._init();
        this.reprocessable = this.isReprocessible();
        this.processingInProgress = false;
      } else if (response.status === -2) {
        this.alertService.openAndClose('close', '', this.messages.get('ACTIVE_ACCOUNT_CANNOT_RUN_WITHOUT_RULES'));
        this.selectedRows = [];
        this._init();
        this.reprocessable = this.isReprocessible();
        this.processingInProgress = false;
      } else {
        // update invoice status without refreshing the list
        let tempQuery = new InvoiceQuery({
          limit: 10000,
          where: {invoice_id: {$in: [...invoice_ids]}}
        });
        this.invoiceService.findAllLargeRequest(tempQuery).subscribe((result) => {
          if (result && result.items) {
            result.items.forEach(inv => {
              const index = this.invoices.findIndex(invoice => invoice.invoice_id === inv.invoice_id)
              if (index !== -1) {
                this.invoices[index]['header']['status_code'] = inv['header']['status_code']
                this.invoices[index]['header']['status'] = inv['header']['status']
              }
            })
          }
        })
        this.alertService.openAndClose('close', '', this.messages.get('GL_CODING_INVOICE'));
        this.selectedRows = [];
        this.dataGrid.instance.selectRows(this.selectedRows, false);
        this.reprocessable = this.isReprocessible();
        this.processingInProgress = false;
      }
    }, (err: any) => {
      if (err.status === 409) {
        let invoiceIds = [];
        if (err.data && err.data.failedInvoices) {
          invoiceIds = err.data.failedInvoices.map((invoice) => {
            return invoice.id
          })
        }
        if (invoiceIds.length > 1) {
          this.alertService.actionError('', `${this.messages.get('GL_CODING_ERROR')} ${this.messages.get('GL_CODING_INCORRECT_STATUS')} ${invoiceIds}`);
        }
        if (invoiceIds.length === 1) {
          this.alertService.actionError('', `${this.messages.get('GL_CODING_ERROR')} ${this.messages.get('GL_CODING_INCORRECT_STATUS_ONE_INVOICE')} ${invoiceIds}`);
        }
        this.reprocessable = this.isReprocessible();
        this.processingInProgress = true;
        return
      }
      this.alertService.actionError('', `${this.messages.get('GL_CODING_ERROR')} ${err.statusText}`);
      this.reprocessable = this.isReprocessible();
      this.processingInProgress = false;
    });
  }

  public isReprocessible() {
    return this.selectedRows.length && this.selectedRows.every((invoice) => {
      const isNotApproved = invoice.header.status ?
        (invoice.header.status.id !== this.invoiceStatusEnum.APPROVED) : (invoice.header.status_code !== this.invoiceStatusEnum.APPROVED);
      const isNotInBatchOutput = invoice.header.status ?
        (invoice.header.status.id < this.invoiceStatusEnum.GL_BATCH_OUTPUT) :
        (invoice.header.status_code < this.invoiceStatusEnum.GL_BATCH_OUTPUT)
      const isNotInGLCodingInProgress = invoice.header.status ?
        (invoice.header.status.id !== this.invoiceStatusEnum.GL_CODING_IN_PROCESS) :
        (invoice.header.status_code !== this.invoiceStatusEnum.GL_CODING_IN_PROCESS)
      return (isNotApproved &&
        isNotInBatchOutput && !path(['account', 'on_hold'], invoice)
        && isNotInGLCodingInProgress);
    })
  }

  public isAccountActive(invoices: Array<InvoiceFacepage>): boolean {
    if (invoices && invoices.length) {
      const hasInactiveAccount = invoices.map((invoice) => invoice.account ? invoice.account.status_id : ACCOUNT_STATUS_ENUM.INACTIVE)
        .find(status => status === ACCOUNT_STATUS_ENUM.INACTIVE);
      return !(hasInactiveAccount === ACCOUNT_STATUS_ENUM.INACTIVE);
    }
    return false
  }

  public isSameSubclient(invoices: Array<InvoiceFacepage>): boolean {

    if (invoices && invoices.length) {
      const subclients = invoices.map((invoice) => invoice.account?.subclient || {
        value: null
      });
      const subclient = subclients.find(sbc => !!sbc);
      return subclient ?
        subclients.every(sbc => sbc.value === subclient.value) :
        true;
    }

    return false;
  }

  public calcIsDeleteDisabled() {
    return !this.invoiceService.isDeleteEligible(this.selectedRows);
  }

  public delete() {
    this.chargeService.hasDispute(this.disputeQuery)
      .subscribe(res => {
        if (res.length) {
          return this.alertService.success('', this.INVOICE_HAS_DISPUTE);
        } else {
          this.invoiceService.deleteWithConfirmation(this.selectedRows).subscribe(() => {
            this.selectedRows = [];
            this.loadData(this.query);
          });
        }
      })
  }

  public async putOnHold() {
    const [invoice] = this.selectedRows;
    const hasCustomFields = await this.configService.hasCustomFields('account_configuration');

    this.accountDialogService.openEditAccountDialog(invoice.account, hasCustomFields).pipe(
      filter(account => !!account))
      .subscribe((account) => {
        const message = this.invoiceFlowService.getAccountUpdatedMessage(invoice.account, account);
        this.refresh();
        this.alertService.success('', this.messages.get(message));
      })
  }

  public get isOnHoldEnabled() {
    const status = path([0, 'header', 'status_code'], this.selectedRows);
    return this.selectedRows.length === 1 && this.invoiceFlowHandleService.isOnHoldEligible(status);
  }

  runAudit() {
    this.dialogService.open(AuditRunComponent, {
      service: this.invoiceGridService,
      data: {selectedInvoices: this.selectedRows}
    }, {
      width: '720px',
      height: '560px'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.isSuccess) {
          this.selectedRows = [];
          this._init();
          this.alertService.info('', 'Run successful.');
        } else if (result && !result.isSuccess) {
          const messages = result.messages || false
          if (messages) {
            return this.alertService.info('', `${messages.length > 1 ? 'Selected audits expired' : messages[0]}`);
          }
        }
      });
  }

  private interceptAndChangeQuery(query: any[]): any[] {
    const BATCH_KEYS = {
      grid_field: 'batch.batch_key',
      sorting_field: 'header.batch.batch_key'
    };
    query.map((i: any[]) => {
      i.map((el: string) => {
        if (el === BATCH_KEYS.grid_field) {
          i[0] = BATCH_KEYS.sorting_field;
        }
      });
    });
    return query;
  }

  private loadPermissions() {
    const glView$ = this.permissionService.isAllowed("gl", "VIEW");
    const glModify$ = this.permissionService.isAllowed("gl", "MODIFY");
    const glCreate$ = this.permissionService.isAllowed("gl", "CREATE");
    return forkJoin(
      glView$,
      glModify$,
      glCreate$,
    ).pipe(map(([glView, glModify, glCreate]) => {
      this.isProcessButtonDisabled = !glView || !glModify || !glCreate;
    }));
  }

  private onQueryParamsChange(params: Params) {
    // to make sure query is empty when no params in the url
    if (Object.keys(params).length === 0) {
      this.query = new InvoiceQuery()
    }
    const query = this.queryBuilder.build(this.query, null, this.COMPONENT_NAME).getQuery()
    this.store.dispatch([
      new UpdateInvoicesQuery({query, key: params.key})
    ]);
  }

  private onQueryChange(query: Query) {
    this.query = new InvoiceQuery(query);
    this._init();
  }
}
