<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Change Alert Owner</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <form class="ca-form" [formGroup]="alertAssignForm"> 
      <div class="flex-container" fxLayout="row">
        <div class="flex-item" fxFlex="100%">
          <ca-person-picker
            caInput
            placeholder="Owner"
            formControlName="owner_id"
            [pickerForm]="alertAssignForm"
            [clearEnabled]="true"
            [required]="true"
            pickerType="user">
          </ca-person-picker>
          <mat-hint *ngIf="alertAssignForm.hasError('required', ['owner_id']) && alertAssignForm.controls['owner_id'].touched" class="ca-select-hint">
            {{VALIDATION.REQUIRED_ERROR}}
          </mat-hint>
      </div>    
    </div>
  </form>

</div>

<mat-dialog-actions class="ca-align-baseline ca-dialog-action">
  <div class="example-fill-remaining-space">
  </div>

  <button mat-button #cancelButton type="submit" (click)="close()">
    Cancel
  </button>

  <button mat-button #saveButton type="submit" (click)="onSubmit(alertAssignForm)" [disabled]="alertAssignForm.invalid">
    Save
  </button>
</mat-dialog-actions>
