import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class AlertChargesGridService extends GridService {
  defaultColumns = [
    {
      caption: '',
      headerCellTemplate: 'selectedChargesHeaderTemplate',
      cellTemplate: 'chargeDisputeTemplate',
      fixed: true,
      width: 40,
      allowSorting: false
    },
    {
      caption: 'Charge Type',
      dataField: 'chg_class',
      width: 140
    },
    {
      caption: 'Message',
      dataField: 'alert_details.message',
      width: 300 
    },
    {
      caption: 'Subaccount',
      dataField: 'acct_level_2',
      width: 120
    }, {
      caption: 'Service ID',
      dataField: 'sp_serv_id',
      width: 170
    }, {
      caption: 'Beg Charge Date',
      dataField: 'beg_chg_date',
      dataType: 'date',
      format: this.formatService.date,
      alignment: 'left',
      width: 150
    }, {
      caption: 'Charge Amount',
      dataField: 'chg_amt',
      cellTemplate: 'invoiceCurrencyTemplate',
      width: 120
    }, {
      caption: 'Charge Code 1',
      dataField: 'chg_code_1',
      width: 110
    }, {
      caption: 'Charge Description 1',
      dataField: 'chg_desc_1',
      width: 200
    }, {
      caption: 'Charge Code 2',
      dataField: 'chg_code_2',
      width: 110
    }, {
      caption: 'Charge Description 2',
      dataField: 'chg_desc_2',
      width: 200
    }, {
      alignment: 'right',
      caption: 'Chg Qty',
      dataField: 'chg_qty1_billed',
      dataType: 'number',
      width: 100
    }, {
      caption: 'Charge Rate',
      dataField: 'chg_rate',
      cellTemplate: 'chargeRateTemplate',
      width: 100
    }, {
      caption: 'Info Only Ind',
      dataField: 'info_only_ind',
      width: 100
    }, {
      caption: 'Facility Bandwidth',
      dataField: 'fac_bw',
      width: 140
    }, {
      caption: 'Call Type',
      dataField: 'call_type',
      width: 100
    }, {
      caption: 'Dir Ind',
      dataField: 'dir_ind',
      width: 100
    }, {
      caption: 'Jurisdiction',
      dataField: 'jur',
      width: 100
    }, {
      caption: 'Chg Qty Type',
      dataField: 'chg_qty1_type',
      width: 120
    }, {
      alignment: 'right',
      caption: 'Chg Qty Used',
      dataField: 'chg_qty1_used',
      dataType: 'number',
      width: 120
    }, {
      caption: 'Chg Qty Allowed',
      dataField: 'chg_qty1_allowed',
      width: 140
    }
  ];

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.alert.charge-grid', userSettings);
  }

}
