<mat-card class="filter-group" *ngIf="!isFilter">
  <mat-card-header class="filter-group-header">{{entry?.label}}</mat-card-header>
  <mat-card-content fxLayout="column">
    <div fxFlex class="ca-form" [ngStyle]="{'margin-bottom': internalDateQuery['customRange'] ? '0' : '2.85em'}">
      <ca-default-picker hiDensityInput>
        <mat-form-field>
          <mat-select [placeholder]="internalDateRange ? '' : 'Date selection'"
                      #dateRangeSelect
                      [(ngModel)]="internalDateRange"
                      (ngModelChange)="ngOnDateChange(null, null, $event)">
            <button *ngIf="internalDateRange" (click)="clearInternalDateQueryRanges()" mat-button
                    class="clear-option">Clear
            </button>
            <mat-option *ngFor="let range of ranges"
                        [value]="range">{{ range.label }}
            </mat-option>
            <mat-option [value]="'customRange'" *ngIf="entry?.lookupProvider.customRange">Range</mat-option>
          </mat-select>
        </mat-form-field>
      </ca-default-picker>


    </div>
    <div fxLayout="row" *ngIf="internalDateQuery['customRange'] && range" fxLayoutGap="16px">
      <div fxFlex class="ca-form">
        <ca-date-picker hiDensityInput [(ngModel)]="internalDateQuery['from']" placeholder="From"
                        (selectedChanged)="ngOnDateChange($event, 'from')"
                        [maxDate]="internalDateQuery['to']"></ca-date-picker>
      </div>
      <div fxFlex class="ca-form">
        <ca-date-picker hiDensityInput [(ngModel)]="internalDateQuery['to']" placeholder="To"
                        (selectedChanged)="ngOnDateChange($event, 'to')"
                        [minDate]="internalDateQuery['from']"></ca-date-picker>
      </div>


    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="isFilter" class="ca-form date-filter" fxLayout="column">
  <div fxFlex class="flex-item">
    <ca-default-picker hiDensityInput>
      <mat-form-field style="padding-top: 0">
        <mat-select [placeholder]="internalDateRange ? '' : entry.label || 'Date Selection'"
                    #dateRangeSelect
                    [(ngModel)]="internalDateRange"
                    (ngModelChange)="ngOnDateChange(null, null, $event)">
          <button *ngIf="internalDateRange" (click)="clearInternalDateQueryRanges()" mat-button
                  class="clear-option">Clear
          </button>
          <mat-option *ngFor="let range of ranges"
                      [value]="range">{{ range.label }}
          </mat-option>
          <mat-option [value]="'customRange'" *ngIf="entry?.lookupProvider?.customRange">Range</mat-option>
        </mat-select>
      </mat-form-field>
    </ca-default-picker>
  </div>

  <div fxLayout="row" *ngIf="internalDateQuery['customRange'] && range" fxLayoutGap="16px" class="date-range">
    <div fxFlex class="ca-form">
      <ca-date-picker hiDensityInput [(ngModel)]="internalDateQuery['from']" placeholder="From"
                      (selectedChanged)="ngOnDateChange($event, 'from')"
                      [maxDate]="internalDateQuery['to']"
                      [minDate]="max? max : undefined"></ca-date-picker>
    </div>
    <div fxFlex class="ca-form">
      <ca-date-picker hiDensityInput [(ngModel)]="internalDateQuery['to']" placeholder="To"
                      (selectedChanged)="ngOnDateChange($event, 'to')"
                      [minDate]="internalDateQuery['from']"></ca-date-picker>
    </div>
  </div>
</div>
