
import {debounceTime} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchService} from "./search.service";
import {FormControl} from "@angular/forms";
import Query from "../core/query/query";
import {Router} from "@angular/router";
import {DialogService} from "../shared/dialog/dialog.service";
import {DataLockService} from "../core/data-lock/data-lock.service";
import {SearchResultService} from "./search-result.service";

@Component({
  selector: 'ca-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  @ViewChild('searchInput') searchInput;
  @ViewChild('auto') autocomplete;

  public searchResults: Array<any> = [];
  public searchFormControl = new FormControl();
  public query = new Query();
  public searchBoxWidth = '0';
  public searchFormControlFocus = false;
  public isInFocus: boolean = false;
  private allCommands: Array<any> = [];
  public searchValue: any = "";
  public resultConfiguration: any;
  public offset: number;
  public inputValue: string = '';
  public skipSearch = false;


  private searchResultsType: any = "flat";

  constructor(public searchService: SearchService,
              public dialogService: DialogService,
              public dataLockService: DataLockService,
              public searchResultService: SearchResultService,
              public router: Router) {

    this.resultConfiguration = this.searchResultService.getResultFormats();
  }

  public groupBy(array, prop) {

    let groups = [];

    array.forEach((item) => {
      let group = groups.filter((group) => {
        return group.name === item[prop];
      })[0];

      if (!group) {
        group = {name: item[prop], items: []};
        groups.push(group)
      }

      group.items.push(item);
    });


    return groups;
  }

  public ngAfterViewInit() {
    //complete selection on TAB
    this.autocomplete._keyManager.tabOut.subscribe((e) => {
      let selection = (this.autocomplete._keyManager.activeItem || {}).value;
      if (selection && selection.type == 'command') {
        selection.value = selection.value.trim() + " ";
        this.resultSelect(selection);
        this.searchValue = selection.value;
        return false;
      }
    });
  }

  public ngOnInit() {
    //
    // this.searchService.search("/")
    //   .subscribe((result) => {
    //     this.allCommands = result;
    //   });

    this.offset = 0;

    this.searchFormControl.valueChanges.pipe(
      debounceTime(250))
      .subscribe(
        inputValue => {
          if (inputValue) {
            let val = inputValue.value ? inputValue.value : inputValue;
            this.inputValue = inputValue;
            this.search(inputValue, this.offset, false);
          }
        }
      );
  }

  public onSearchKeydown(event) {
    //prevent losing focus from search input if user press tab after entering command
    let wordsCount = this.searchValue.toString().trim().split(' ').length;
    if (((event.code == "Tab" || event.code == "Enter" ) && wordsCount === 1)) {
      this.searchResults = []
      return false;
    }
    this.offset = 0;
  }

  public resultSelect(result) {
    let type = result.type;
    let group = result.group;
    let config = this.resultConfiguration[group];

    if (config) {
      if (config.action == 'navigate') {
        let navigateConfig = config.action_config;
        this.router.navigate([navigateConfig.path, result.id, navigateConfig.view])
          .then(() => {
          window.location.reload();
        });
      } else if (config.action == 'open') {
        this.openModal(result.id, config);
      }
      this.closeForm();
    }

    if (type !== "command") {
      this.searchFormControl.setValue("");
      this.closeForm();
    }
    else {

      let val = result.value.endsWith(':') ? [result.value] : [result.value, ""];
      this.searchValue = val.join('');
      this.searchFormControl.setValue(this.searchValue);
    }

    // if (group === 'Qualifiers') {
    //   this.search(this.searchValue);
    // }

    this.searchResults = [];
  }

  public openModal(id, config) {
    let actionConfig = config.action_config;
    let service = actionConfig.service;
    let entityName = actionConfig.openParams;
    let itemId = actionConfig['entityId'];
    let componentType = actionConfig.componentType;
    if (id) {
      if (config.path) {
        this.router.navigate([config.path]);
      }
      if(config.path === '/user'){
        service.search(id)
          .subscribe(detailItem => {
            let openParams = {};
            openParams[entityName] = detailItem;
            openParams['viewOnly'] = true;
            this.dialogService.open(componentType, openParams, {width: '800px'});
          });
      }
      else {
        service.findById(id)
          .subscribe(detailItem => {
            let openParams = {};
            openParams[entityName] = detailItem;
            openParams['viewOnly'] = true;
            if(config.path === '/contact')
              this.dialogService.open(componentType, openParams, {width: '1150px'});
            else
              this.dialogService.open(componentType, openParams, {width: '800px'});
          });
      }
    }
  }

  public search(q: string, offset:number, loadMore: boolean) {
    let searchValue = q['value'] || q || "";
    let searchValueLength = 0;
    if (!searchValue['entity']) {
      searchValueLength = searchValue.split(' ').length;
    } else {
      return;
    }

    if(!loadMore) {
      this.searchResults = []
      this.offset = 0;
    }

    this.searchService.search(q,offset)
      .subscribe((result) => {

        let searchResults = [];

        if (!result.items) {
          searchResults = result.map((item) => {
            return Object.assign({}, item, {group: 'Qualifiers'})
          });
        }
        else if (result.type === 'group') {
          searchResults = result.items.map((item) => {
            return Object.assign({}, item, {group: item.group})
          });
        }
        else {
          searchResults = result.items.map((item) => {
            return Object.assign({}, item, {group:  item.group})
          });
        }

        let commandsResults = searchResults.filter((res) => {
          return res.type === 'command';
        });

        let otherResults = searchResults.filter((res) => {
          return res.type !== 'command';
        });

        if(!loadMore)
          this.searchResults = commandsResults.concat(otherResults);
        else{
          let grouped = commandsResults.concat(otherResults);
          grouped.forEach((item) =>{
            this.searchResults.push(item);
          })
        }


        if (this.searchResults[0] && this.searchResults[0].value &&  searchResults[0].type)
        {
          this.searchResults.forEach(item =>{
            if(item.value == q.trim())
              this.searchResults = [];
          })
        }

        setTimeout(() => {
          this.autocomplete._keyManager.setFirstItemActive();
        }, 250);
      });

  }

  onScrollHandler($event) {
    let element = $event.srcElement;
     if (Math.ceil(element.scrollTop) >= (element.scrollHeight - element.clientHeight - 50) && this.searchResults[0] && this.searchResults[0].total  > this.searchResults.length ) {
      this.offset += 100
       this.search(this.inputValue,this.offset, true);
    }
  }

  public closeForm() {
    setTimeout(() => {
      if (this.searchValue
        && this.searchValue.type
        && this.searchValue.type === 'command') {
        this.searchResults = [];
        this.offset = 0;
        return;
      }

      this.closeSearchBox();
      this.searchResults = [];
      this.offset = 0;
    }, 250)
  }

  public openForm() {
    setTimeout(() => {
      this.openSearchBox();

    }, 250)
  }

  public toggleSearch() {
    if (this.searchBoxWidth === '0') {
      this.openSearchBox();
    }
    else {
      this.closeSearchBox();
    }
  }

  private closeSearchBox() {
    this.searchResults = [];
    this.offset = 0;
    this.searchBoxWidth = '0';
    this.searchFormControlFocus = false;
    this.isInFocus = false;
    this.searchValue = "";
  }

  private openSearchBox() {
    this.searchBoxWidth = '400px';
    this.searchFormControlFocus = true;
    this.isInFocus = true;
    this.searchValue = "";
  }

}
