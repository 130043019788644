import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'ca-order-service-level-sneak-peek',
  templateUrl: './order-service-level-sneak-peek.component.html',
})
export class OrderServiceLevelSneakPeekComponent implements OnInit {

  @Input() orderService: any;

  constructor(
  ) {}


  ngOnInit() {
  }

}
