import { Injectable } from '@angular/core';
import { GridService } from "../../shared/grid/grid.service";
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class ChargeStringsGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.cost.charge-strings-grid", userSettings);
  }

  columns() {
    return [{
      caption: "GL String",
      dataField: "gl_string.full_string_formatted",
      allowSorting: false,
      width: '50%',
    },
    {
      caption: "Apportion %",
      dataField: "gl_apportion_pct",
      dataType: 'number',
      width: '25%',
      allowSorting: false,
    },
    {
      caption: "GL Amount",
      dataField: "gl_chg_amt",
      cellTemplate: "amountCurrencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: '25%',
      allowSorting: false,
    }]
  }

  flatColumns(){
    return [{
        caption: "GL String",
        dataField: "full_string_formatted",
        width: 200,
        allowSorting: false,
      },
      {
        caption: "Apportion %",
        dataField: "gl_apportion_pct",
        dataType: 'number',
        width: 100,
        allowSorting: false,
      },
      {
        caption: "GL Amount",
        dataField: "gl_chg_amt",
        dataType: 'currency',
        alignment: 'right',
        cellTemplate: "glOutputAmountTemplate",
        width: 100,
        allowSorting: false,
      }];
  }
}
