export class User {
  id: number;
  username: string;
  password: string;
  password_confirm: string;
  email: string;
  token: string;
  token_expires: Date;
  reset_pwd_token: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  is_active: boolean;
  avatar: string;
  theme_id: string;
  force_password_change: boolean;
  phone_number: string;
  roles: any[];
  //In sequelize we can't have two associations with same name. This is reason why we added userRoles.
  userRoles: any[];
  country_id: number;
  country: any;

  // this is here because everything wired for create
  // expects ONLY user object and we need to pass this non-user object
  resetPasswordUrl: string;
  last_activity_date: Date;

  get full_name() {
    return `${this.first_name} ${this.last_name}`
  }

  get initials() {
    let firstName = this.first_name || '';
    let lastName = this.last_name || '';

    return firstName.toUpperCase()[0] + lastName.toUpperCase()[0];
  }

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
