export const userFilterContext = {
  filters: [
    {
      field: 'last_name',
      label: 'Last Name',
      type: 'string',
      index: 1
    },
    {
      field: 'first_name',
      label: 'First Name',
      type: 'string',
      index: 2
    },
    {
      field: 'username',
      label: 'Username',
      type: 'string',
      index: 3
    },
    {
      field: 'email',
      label: 'Email',
      type: 'string',
      index: 4
    },
    {
      field: 'is_active',
      label: 'Status',
      type: 'boolean',
      trueLabel: 'Active',
      falseLabel: 'Inactive',
      index: 5
    },
    {
      field: 'mobile_number',
      label: 'Mobile Number',
      type: 'string',
      exactMatch: false,
      matchCase: false,
      visible: true,
      index: 6
    },
    {
      field: 'phone_number',
      label: 'Phone Number',
      type: 'string',
      exactMatch: false,
      matchCase: false,
      visible: true,
      index: 7
    },
    {
      field: 'last_activity_date',
      label: 'Last Activity Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 3,
    }
  ]
};
