import {of as observableOf} from 'rxjs';

import {map, switchMap, distinctUntilChanged, debounceTime, takeUntil} from 'rxjs/operators';
import { CustomValidators } from 'ng2-validation';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { DialogService } from '../../../shared/dialog/dialog.service';
import {Component, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { User } from '../../core/user';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../core/user.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { RolesService } from '../../../roles/core/roles.service';
import Query from '../../../core/query/query';
import {CurrencyPipe, DecimalPipe, Location, registerLocaleData} from '@angular/common';
import { IMessagesResourceService, ResourcesService } from '../../../core/resources/resources.service';
import { DataLockService } from '../../../core/data-lock/data-lock.service';
import {EntityEditContext} from '../../../shared/entity-lock/entity-edit-context';
import {LocationService} from "../../../location/core/location.service";
import {ConfigService} from "../../../core/config/config.service";
import {flatMap} from "rxjs/internal/operators";
import {Router} from '@angular/router';

@Component({
  selector: 'app-profile-manage-dialog',
  templateUrl: './profile-manage-dialog.component.html',
  styleUrls: ['./profile-manage-dialog.component.scss']
})
export class ProfileManageDialogComponent extends PageManageDialogComponent implements OnInit, OnDestroy {
  user: User;
  me: User;
  selectedRoles: Object = {};
  roles: Array<any> = [];
  roleList: Array<any> = [];
  userCopy: User;
  query = new Query();
  selectedCountryId: any;
  selectedCountry: any;

  currencyFormatExample: any;
  currencyNegativeFormatExample: any;
  currencyExample: any;
  currencyNegativeExample: any;

  numberFormatExample: any;
  numberNegativeFormatExample: any;
  numberExample: any;
  numberNegativeExample: any;

  defaultCountryCode: any;
  defaultCountryId: any;

  phoneRegexMask = '(000) 000-0000';
  phoneRegex;
  phoneCountryCode: string = '';

  emailUnique = true;

  public isFormEnabled = false;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'users';

  allCountries: Array<any> = [];

  todayDate: Date = new Date();

  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    public alert: AlertService,
    public userService: UserService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<ProfileManageDialogComponent>,
    public roleService: RolesService,
    public location: Location,
    public locationService: LocationService,
    public configService: ConfigService,
    public dataLockService?: DataLockService
  ) {
    super(dialogService, dialogRef, dataLockService); // ,entityLockService

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    alert.onAlertClosed.subscribe((res) => {
      if (res) {
        this.closeDialog(ProfileManageDialogComponent, true)
      }
    })
  }

  loadRoles() {
    const cloned = this.query.clone();
    cloned.limit = 20;
    this.roleList = this.me.roles;
  }

  loadCountries() {
    const cloned = this.query.clone();
    cloned.limit = 1000;
    cloned.orderBy = [['name', 'ASC']];
    if (!cloned.where.is_enabled) {
      cloned.where = {is_enabled: true};
    }
    return this.locationService.findAll(cloned)
      .subscribe(result => {
        this.allCountries = result.items;
      })
  }

  hasAnyUser(field) {
    return this.form.controls[field].valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((value) => {
        if (value && !this.form.controls[field].hasError(field)) {
          const query = new Query({ limit: 1 });
          query.set(field, {'$ilike': value});
          query.set('id', {'$ne': (this.user && this.user.id) ? this.user.id : 0});
          return this.userService.findAll(query);
        }
        return observableOf({total: 0});
      }),
      map((result: any) => !!!result.total),);
  }

  ngOnInit() {
    this.loadCountries();

    let isUpdate = this.user ? this.user.id : false;

    this.userService.me()
      .subscribe((result) => {
        this.me = result;
        this.loadRoles();
        this.getSelectedCountry(result.country_id);
        this.getExample(123456.78);
        this.getNegativeExample(-123456.78);
        this.isFormEnabled = result.roles.filter(role=>role.name === "Administrator").length === 1;
        this.phoneCountryCode = result.country.phone_country_code || '';
        let replacedCode = this.phoneCountryCode.replace(/\d/g, '0');
        this.phoneRegexMask = this.user.country.phone_regex_mask.replace(replacedCode, '').trim();
        this.phoneRegex = this.user.country.phone_regex;

        let userCopy = this.userCopy = Object.assign({}, this.user);
        this.form = this.formBuilder.group({
          email: [{value: userCopy ? userCopy.email : '', disabled: true}, Validators.compose([Validators.required, CustomValidators.email])],
          first_name: [{value: userCopy ? userCopy.first_name : '', disabled:!this.isFormEnabled},Validators.required],
          last_name: [{value: userCopy ? userCopy.last_name : '',disabled:!this.isFormEnabled}, Validators.required],
          phone_number: [{value: userCopy && userCopy.phone_number ? userCopy.phone_number.replace(this.phoneCountryCode, '') : '',disabled:!this.isFormEnabled}, Validators.pattern(new RegExp(this.phoneRegex))],
          mobile_number: [{value: userCopy && userCopy.mobile_number ? userCopy.mobile_number.replace(this.phoneCountryCode, '') : '',disabled:!this.isFormEnabled}, Validators.pattern(this.phoneRegex)],
          is_active: [{value: isUpdate ? userCopy.is_active : true, disabled:!this.isFormEnabled},Validators.required],
          avatar: [userCopy ? userCopy.avatar : ''],
          country_id: [ userCopy ? userCopy.country_id : '']
        });

        this.hasAnyUser('email').subscribe(result => {
          this.emailUnique = result;
        });

        this.initViewOnlyForm(this.user.id, 'View User', 'Edit User', 'users');
        this.afterInit();
      });
  }

  init() {
    if (this.user && this.user.id) {
      return this.userService.findByIdForEdit(this.user.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .pipe(flatMap((user: any) => {
          this.user = user;
          return observableOf(user);
        }))
    }
    return observableOf(this.user);
  }

  onSubmit({ value, valid }: { value: User, valid: boolean }) {
    if (valid) {
      this.user.roles = this.roleList
        .map((item) => {
          return item.id.toString();
        });

      value.avatar = this.userCopy.avatar;

      if (value.phone_number && !value.phone_number.includes(this.phoneCountryCode)) {
        value.phone_number = value.phone_number && value.phone_number.length ? this.phoneCountryCode + value.phone_number : null;
      }

      if (value.mobile_number && !value.mobile_number.includes(this.phoneCountryCode)) {
        value.mobile_number = value.mobile_number && value.mobile_number.length ? this.phoneCountryCode + value.mobile_number : null;
      }

      if (this.user && this.user.id) {
        this.user['ignoreRoles'] = true;
        this.update(Object.assign({}, this.user, value));
      } else {
        //value.roles = this.user.roles;
        this.create(value);
      }
    } else {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }

  create(user: User) {
    this.toggleDialogButtons();
    user.resetPasswordUrl = `${window.location.host}/auth/reset-password`;
    this.userService.create(user)
      .subscribe((result) => {
        this.alert.success('', this.messages.get('CREATE_SUCCESS'));
        this.closeDialog(result, true);
      }, (err) => {
        this.toggleDialogButtons(false)
        if (err.data && err.data.message) {
          this.alert.actionError('', err.data.message);
        } else {
          this.alert.error('', this.messages.get('CREATE_ERROR'));
        }});
  }

  update(user: User) {
    this.toggleDialogButtons()
    this.userService.update(user.id, user)
      .subscribe((result) => {
        localStorage.setItem('date_format', result.country.date_format);
        localStorage.setItem('date_time_format', result.country.date_time_format);
        localStorage.setItem('__localeId', result.country.curr_num_locale);

        localStorage.setItem('__currencyDisplay', JSON.stringify(result.country.currency_display));
        localStorage.setItem('__currDigit', JSON.stringify(result.country.curr_num_digit));
        localStorage.setItem('__currLocale', JSON.stringify(result.country.curr_num_locale));
        localStorage.setItem('__negativeValue', JSON.stringify(result.country.negative_value));

        import(
          /* webpackExclude: /\.d\.ts$/ */
          /* webpackMode: "lazy-once" */
          /* webpackChunkName: "i18n-base" */
          `@angular/common/locales/${result.country.curr_num_locale}.js`).then(locale => {
          registerLocaleData(locale.default);
        });
        this.userService.userChanged.emit(result);
        this.alert.success('', this.messages.get('UPDATE_SUCCESS'));
        this.closeDialog(result, true);

        /// MERGE // from audit release
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigateByUrl(currentUrl, {skipLocationChange: true})
        });

      }, (err) => {
        this.toggleDialogButtons(false)
        if (err.data && err.data.message) {
          this.alert.actionError('', err.data.message);
        } else {
          this.alert.error('', this.messages.get('UPDATE_ERROR'));
        }
      });
  }

  public editForm() {
    this.form.enable();
    this.viewOnly = false;
    this.formTitle = this.editTitle;

    super.editForm();

    let emailControl = this.form.get('email');
    emailControl.disable();
  }

  handleAvatarUpload(avatarDataUrl) {
    this.userCopy.avatar = avatarDataUrl;
  }

  getUserInitials(): string {
    if (this.user && this.user.first_name && this.user.last_name) {
      return this.user.first_name.toUpperCase()[0] + this.user.last_name.toUpperCase()[0];
    } else {
      return 'NN';
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  cancel() {
    this.userService.cancelEdit();
    this.closeDialog();
  }

  onRegionChange(event) {
    this.selectedCountryId = event.value;
    this.locationService.findCountryById(this.selectedCountryId)
      .subscribe(result => {
        this.phoneCountryCode = result.phone_country_code || '';
        const replacedCode = this.phoneCountryCode.replace(/\d/g, '0');
        this.phoneRegexMask = result.phone_regex_mask.replace(replacedCode, '').trim();
        this.phoneRegex = result.phone_regex;
        this.selectedCountry = result;
        this.form.get('phone_number').markAsTouched();
        this.form.get('mobile_number').markAsTouched();
        this.getNegativeExample(-123456.78);
        this.getExample(123456.78);
      })
  }


  getSelectedCountry(id) {
    this.locationService.findCountryById(id)
      .subscribe(result => {
        this.selectedCountry = result;
      })
  }

  getExample(value) {
    let currencyValueTransformed = this.transformValue(value, true);
    let currencyReplacedValue = currencyValueTransformed.replace(/[0-9]/g, "#");
    let numberValueTransformed = this.transformValue(value, false);
    let numberReplacedValue = numberValueTransformed.replace(/[0-9]/g, "#");
    this.currencyFormatExample =  currencyReplacedValue;
    this.currencyExample = currencyValueTransformed;
    this.numberFormatExample =  numberReplacedValue;
    this.numberExample = numberValueTransformed;
  }

  getNegativeExample(value) {
    let currencyValueTransformed = this.transformValue(value, true);
    let currencyReplacedValue = currencyValueTransformed.replace(/[0-9]/g, "#");
    let numberValueTransformed = this.transformValue(value, false);
    let numberReplacedValue = numberValueTransformed.replace(/[0-9]/g, "#");
    let negativeSign = (this.selectedCountry && this.selectedCountry.negative_value) || this.me.country.negative_value;
    if (negativeSign === '()') {
      currencyValueTransformed = '(' + currencyValueTransformed.substr(1) + ')';
      currencyReplacedValue = '(' + currencyReplacedValue.substr(1) + ')';
      numberValueTransformed = '(' + numberValueTransformed.substr(1) + ')';
      numberReplacedValue = '(' + numberReplacedValue.substr(1) + ')';
    }
    this.currencyNegativeFormatExample = currencyReplacedValue;
    this.currencyNegativeExample = currencyValueTransformed;
    this.numberNegativeFormatExample = numberReplacedValue;
    this.numberNegativeExample = numberValueTransformed;
  }

  transformValue(value, isCurrency) {
    let formatterCurrency = new CurrencyPipe('en-US');
    let formatterNumber = new DecimalPipe('en-US');
    if (isCurrency) {
      return formatterCurrency.transform(
        value,
        'USD',
        (this.selectedCountry && this.selectedCountry.currency_display) || this.me.country.currency_display,
        (this.selectedCountry && this.selectedCountry.curr_num_digit) || this.me.country.curr_num_digit);
    } else {
      return formatterNumber.transform(
        value,
        (this.selectedCountry && this.selectedCountry.curr_num_digit) || this.me.country.curr_num_digit);
    }
  }

}
