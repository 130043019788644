import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Observable, Subject} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import {LoaderService} from "../../shared/loader/loader.service";
import {InvoiceFacepage} from "../core/invoice-facepage";
import {InvoiceFacepageService} from "../core/invoice-facepage.service";
import {InvoiceFlowHandleService} from "../core/invoice-flow-handle.service";
import {PaymentService} from "../core/payment.service";
import {IntuitService} from "../../intuit/core/intuit.service";
import {BillService} from "../core/bill.service";
import {ConfigService} from "../../core/config/config.service";
import {Config} from "../../core/config/config";
import {AlertService} from "../../shared/alert/alert.service";

@Component({
  selector: "app-invoice-details",
  templateUrl: "./invoice-details.component.html",
  styleUrls: ["./invoice-details.component.scss"],
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {
  invoice: InvoiceFacepage;
  invoiceId: number;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly invoiceFacepageService: InvoiceFacepageService,
    public loaderService: LoaderService,
    public invoiceFlowHandleService: InvoiceFlowHandleService,
    public paymentService: PaymentService,
    public billService: BillService,
    public intuitService: IntuitService,
    public alertService: AlertService,
    public configService: ConfigService
  ) {
  }

  ngOnInit(): void {
    this.invoiceId = this.activatedRoute.snapshot.data.invoice.invoice_id;
    this.invoiceFacepageService.findById(this.invoiceId).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res) => {
      this.invoice = res;

      // get config before checking the status
      this.configService.findAll().pipe(
        takeUntil(this.destroy$)
      ).subscribe((config: Config) => {
        if (config && config.gl_coding.format == "tailwind")
          this.checkPaymentStatus(res);

        if (config && config.gl_coding.format == "bcm-one")
          this.checkBillStatus(res);

        if (config && config.quickbooks)
          this.checkIntuitBillStatus(res).subscribe(res => {
          }, err => {
            if (err && err.error && ['No token found; QuickBooks may be disconnected', 'Authentication Error'].includes(err.error.message))
              this.alertService.error('', 'Failed to check bill status; QuickBooks may be disconnected');
          })
      });

      // calling toolbar action from the invoice service dynamically
      this.invoiceFacepageService.toolbarAction.pipe(
        takeUntil(this.destroy$)
      ).subscribe((data) => {
        if (typeof this.invoiceFacepageService[data.action] === "function") {
          this.invoiceFacepageService[data.action]({
            invoice: this.invoice,
            params: data.params,
          });
        }
      });
    });

    this.invoiceFacepageService
      .listen()
      .pipe(
        takeUntil(this.destroy$),
        filter((data: any) => {
          return !!(
            data &&
            data.id &&
            data.status_code &&
            this.invoice &&
            this.invoice.invoice_id &&
            this.invoice.invoice_id === data.id
          );
        })
      )
      .subscribe(async () => {
        this.loaderService.displayLoader();
        this.invoice = await this.loadInvoice(
          this.invoice.invoice_id
        ).toPromise();
        this.activatedRoute.snapshot.data.invoice = this.invoice;
        this.invoiceFacepageService.invoiceChange.emit(this.invoice);
        this.loaderService.hideLoader();
      });

    this.invoiceFlowHandleService.onStatusChange
      .pipe(
        takeUntil(this.destroy$),
        filter((status) => status != null)
      )
      .subscribe(async (status) => {
        if (
          this.invoiceFlowHandleService &&
          this.invoiceFlowHandleService.invoice &&
          this.invoiceFlowHandleService.invoice.header
        ) {
          this.invoiceFlowHandleService.invoice.header.status_code = status;
        }
        this.loaderService.displayLoader();
        this.invoice = await this.loadInvoice(
          this.invoice.invoice_id
        ).toPromise();
        this.activatedRoute.snapshot.data.invoice = this.invoice;
        this.invoiceFacepageService.invoiceChange.emit(this.invoice);
        this.loaderService.hideLoader();
      });
  }

  loadInvoice(id: number): Observable<InvoiceFacepage> {
    return this.invoiceFacepageService.findById(id);
  }

  checkPaymentStatus(invoice: InvoiceFacepage) {
    const guid = invoice.header.client_guid;
    const invoiceId = invoice.invoice_id;
    if (!guid) {
      return;
    }
    this.paymentService.checkPaymentStatus(invoiceId);
  }

  checkBillStatus(invoice: InvoiceFacepage) {
    const client_guid = invoice.header.client_guid;
    if (!client_guid) return;

    this.billService.checkBillStatus(invoice.invoice_id);
  }

  checkIntuitBillStatus(invoice: InvoiceFacepage) {
    // commented out due to .subscribe()
    // const client_guid = invoice.header.client_guid;
    // if (!client_guid) return;
    return this.intuitService.checkIntuitBillStatus(invoice.invoice_id);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
