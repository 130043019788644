import {Address} from '../../address/core/address.model';

export class Contact {
  id: number;
  first_name: string;
  last_name: string;
  title: string;
  vendor_id: number;
  vendor: any;
  site_id: number;
  email: string;
  office_phone: string;
  office_phone_extension: number;
  mobile_number: string;
  contact_type_id: number;
  customer_id: number;
  customer: any;
  contact_type: any;
  contact_functions: Array<any>;
  site: any;
  address2: string;
  address3: string;

  address_id: number;
  address: Address;

  full_name; //= `${this.first_name} ${this.last_name}`;
  full_name_with_title ;//= `${this.title} ${this.first_name} ${this.last_name}`;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
