import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class CommonAlertGridNoSelectionService extends GridService {


  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super("app.common.alerts-grid-no-selection", userSettings);
  }

  defaultColumns = [
    {
      caption: 'Type',
      dataField: 'type_value',
      width: 120
    },
    {
      caption: 'Category',
      dataField: 'category_value',
      width: 120
    },
    {
      caption: 'Create Time',
      dataField: 'created_at',
      dataType: 'datetime',
      cellTemplate: 'dateTimeTemplate',
      alignment: 'left',
      width: 160
    },
    {
      caption: 'Created by',
      dataField: 'creator_full_name',
      width: 130
    },
    {
      caption: 'Rule Name',
      dataField: 'rule_name',
      width: 130
    },
    {
      caption: 'Message',
      dataField: 'message',
      width: 220
    },
    {
      caption: 'Priority',
      dataField: 'priority_value',
      width: 120
    },
    {
      caption: 'Owner',
      dataField: 'owner_full_name',
      width: 120
    },
    // {
    //   caption: 'Invoice Number',
    //   dataField: 'invoice.sp_inv_num',
    //   width: 150
    // },
    // {
    //   caption: 'Vendor',
    //   dataField: 'vendor.name',
    //   width: 120
    // },
    // {
    //   caption: 'Account No',
    //   dataField: 'account_no',
    //   width: 150
    // },
    {
      caption: 'Last Modification Time',
      dataField: 'last_modified_at',
      dataType: 'datetime',
      cellTemplate: 'dateTimeTemplate',
      alignment: 'left',
      width: 200
    },
    {
      caption: 'Status',
      dataField: 'status_value',
      width: 120
    },
    {
      caption: 'Closed Time',
      dataField: 'closed_at',
      dataType: 'datetime',
      cellTemplate: 'dateTimeTemplate',
      alignment: 'left',
      width: 140
    },
    {
      caption: 'Run ID',
      dataField: 'run_id',
      width: 120
    },
  ];

  /*columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/

}
