import { DialogService } from '../../../shared/dialog/dialog.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { Component, OnInit} from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import {FormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-service-install',
  templateUrl: './service-install.component.html',
})
export class ServiceInstallComponent extends PageManageDialogComponent implements OnInit {

  public service_install_date: Date;

  constructor(public formBuilder: FormBuilder,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<ServiceInstallComponent>) {
                super(dialogService, dialogRef);
              }

  ngOnInit() {
    this.form = this.formBuilder.group({
      service_install_date: [null, Validators.required],
    });
  }

  submit({value, valid}: {value: any, valid: boolean}) {
    this.dialogRef.close({
      service_install_date: value.service_install_date,
    });
  }

  cancel() {
    this.closeDialog();
  }
}
