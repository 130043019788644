<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Continue editing?</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <p>
    Time remaining 0:{{ closeCountdownSeconds < 10 ? '0' + closeCountdownSeconds : closeCountdownSeconds }}
  </p>
</div>


<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
  </div>

  <button mat-button type="submit" (click)="cancelEdit()">
    Cancel
  </button>

  <button mat-button type="submit" (click)="extendLockDuration()">
    Continue
  </button>
</mat-dialog-actions>
