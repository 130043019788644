import {Contact} from "../../contact/core/contact";

export class Order {
  id: number;
  vendor_id: number;
  status_id: number;
  processor_id: number;
  requester_id: number;
  approve_date: Date;
  created_at: Date;
  send_date: Date;
  approver_id: number;
  request_date: Date;
  project_id: number;
  vendor_ack_date: Date;
  vendor_reject_date: Date;
  justification_description: string;
  additional_info: string;
  tot_svc_items: number;
  order_number: string;
  purchase_number: string;
  complete_date: Date;
  ready_for_approval: Date;
  vendor_accept_date: Date;
  total_est_mrc: number;
  total_est_nrc: number;
  vendor: any;
  full_order_id: string;
  services: any;
  processor: any;
  requester: any;
  approver: any;
  project: any;
  currency_obj: {currency: string, currency_id: number};
  currency_id: any;
  custom_values: any;
  contacts: any;

  sales_representative_id: number;
  sales_engineer: Contact;
  sales_engineer_id: number;
  sales_representative: Contact;
  type_id: number;
  type: any;
  status: any;
}
