import { FilterEntry } from './filter-entry';
import { FilterService } from './filter.service';

export class FilterContext {
  filters: FilterEntry[] = [];
  filterSet: FilterEntry[] = [];
  name: string;
  service: FilterService;
  defaultContext: any;

  get visibleFilters() {
    let filters = this.filters.filter(filter => {
      return filter.visible || filter.visible === null;
    });
    filters.sort((a, b) => {
      return a.index - b.index
    });
    return filters;
  }

  get sortedFilters() {
    this.filters.sort((a, b) => {
      return a.index - b.index
    });
    return this.filters;
  }

  sortAfterReset() {
    this.filters.sort((a, b) => {
      return a.label.localeCompare(b.label)
    });
    this.filters.forEach((filterItem, index) => filterItem.index = index );
    return this.filters;
  }
}
