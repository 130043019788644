<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{ formTitle }}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">


  <div class="flex-container" fxLayout="row" fxFlexFill fxLayoutGap="15px">
    <div fxFlex="55%" fxLayout="column" fxFlexFill fxLayoutGap="15px">
      <div fxFlex="380px" style="overflow-y: auto">
        <table class="ca-table ca-table-stripped" *ngIf="quoteRequests.length > 0">
          <thead>
          <tr>
            <th>Vendor</th>
            <th>Service</th>
            <th>Offer ID</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let request of quoteRequests">
            <td>
              {{request.vendor.name}}
            </td>
            <td>
              {{request.quoteService?.quote_service_id}}
            </td>
            <td>
              {{request.quote_service_offer_id}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div fxFlex="45%" fxFlexFill fxLayout="column">
      <mat-card fxFlex="100%" class="details-main">
        <mat-card-content>

          <div class="ca-tab ca-form tabbed-content">
            <mat-tab-group dynamicHeight="true"
                           [selectedIndex]="selectedEmailTabIndex"
                           (selectedIndexChange)="onEmailsTabChange($event)">
              <mat-tab label="To"></mat-tab>
              <mat-tab label="Cc"></mat-tab>
              <mat-tab label="Bcc"></mat-tab>
            </mat-tab-group>
          </div>

          <div class="ca-form" fxLayout="column">
            <div fxFlex="40px" class="flex-item" *ngIf="selectedEmailTabIndex === 0" style="margin-top: 8px; overflow-y: auto;">
              <mat-chip-list>
                <mat-chip *ngFor="let vendor of getUniqueVendors()">{{vendor.name}}</mat-chip>
              </mat-chip-list>
            </div>
            <div fxFlex="40px" class="flex-item" *ngIf="selectedEmailTabIndex > 0" style="margin-top: 8px; overflow-y: auto;">

              <app-email-tag [form]="form"
                             caInput
                             [functionKey]="'QUOTING'"
                             [placeholder]="email.cc.length ? '' : 'Type to add email'"
                             *ngIf="selectedEmailTabIndex === 1"
                             [(value)]="email.cc"
                             (onPickerChange)="onEmailChange($event, 'cc')"></app-email-tag>
              <app-email-tag [form]="form"
                             caInput
                             [functionKey]="'QUOTING'"
                             [placeholder]="email.bcc.length ? '' : 'Type to add email'"
                             *ngIf="selectedEmailTabIndex === 2"
                             [(value)]="email.bcc"
                             (onPickerChange)="onEmailChange($event, 'bcc')"></app-email-tag>
            </div>
            <div fxFlex="60px" class="flex-item">
              <mat-form-field caInput style="width: 100%">
                <input matInput type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="email.subject"
                       placeholder="Subject">
              </mat-form-field>
            </div>
            <div fxFlex="180px" class="flex-item" style="padding-left: 0;">
              <mat-form-field caInput style="width: 100%">
                      <textarea matInput
                                type="email"
                                [(ngModel)]="email.header"
                                [ngModelOptions]="{standalone: true}"
                                placeholder="Message"
                                maxlength="1050" rows="8">
                      </textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

</div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="button" (click)="closeDialog()">
    Close
  </button>
  <button (click)="send()" type="button" mat-button>Send
  </button>
</mat-dialog-actions>
