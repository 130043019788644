import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class DisputeGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.cost.invoice-dispute-grid", userSettings);
  }

  defaultColumns = [{
    caption: 'Dispute ID',
    dataField: 'dispute_id',
    width: 150
  },
  {
    caption: 'Dispute Status',
    dataField: 'dispute.status_obj.value',
    width: 150
  },
  {
    caption: 'Subclient',
    dataField: 'dispute.subclient_ids',
    cellTemplate: 'subclientTemplate',
    csvOutput: false,
    width: 130,
    allowSorting: false
  },
  {
    caption: 'Vendor',
    dataField: 'dispute.vendor.name',
    width: 130
  },
  {
    caption: 'Last Update',
    dataField: 'dispute.disp_stat_dt',
    dataType: 'date',
    cellTemplate: "dateTimeTemplate",
    width: 130
  },
  /*{
    caption: 'Filed By',
    dataField: 'user.username',
    width: 130
  },*/
  {
    caption: 'Filed Date',
    dataField: 'dispute.created_at',
    cellTemplate: "dateTimeTemplate",
    dataType: 'date',
    width: 130
  },
  /*{
   caption: 'Dispute Category',
   dataField: 'category_id',
   width: 130
   },*/
  {
    caption: 'Billed Charges',
    dataField: 'dispute.total_amount',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 130
  },
  {
    caption: 'Calculated Charges',
    dataField: 'dispute.calculated_amount',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 180
  },
  {
    caption: 'Total Dispute',
    dataField: 'dispute.disputed_amount',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 130
  },
  {
    caption: 'Payment Withheld Amount',
    dataField: 'dispute.payment_withheld_amt',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 180,
    allowSorting: false
  },
  {
    caption: 'Dispute Awarded',
    dataField: 'dispute.dispute_value_awarded',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 130
  },
  {
    caption: 'Payback Amount',
    dataField: 'dispute.payback_amount',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 130
  },
  {
    caption: 'Currency',
    dataField: 'dispute.currency.currency',
    width: 130
  },
  {
    //cellTemplate: 'withheldCellTemplate',
    //alignment: 'right',
    caption: 'Withheld Status',
    dataField: 'dispute.withheld_status_obj.value',
    width: 160,
    transformValue: {
      true: 'Yes',
      false: 'No'
    }
  }];

  /*columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
