import {Vendor} from '../../vendor/core/vendor';

export class Request {
  id: number;
  quote_service_id: number;
  vendor_id: number;
  status: number;
  vendors: Vendor[];
  vendor: Vendor;
  medium_id: number;
  interval: string;
  quote_type: string;
  failure_reason: string;
  sent_date: Date;
  expiration_date: Date;
  received_date: Date;
  term_1_year: number;
  term_2_year: number;
  term_3_year: number;
  install_interval: number;
  seven_year_nrc: number;
  seven_year_mrc: number;
  five_year_nrc: number;
  five_year_mrc: number;
  three_year_nrc: number;
  three_year_mrc: number;
  two_year_nrc: number;
  two_year_mrc: number;
  one_year_nrc: number;
  one_year_mrc: number;
  mtm_nrc: number;
  mtm_mrc: number;
  notes: string;
  manual: boolean;
  net_type_id: string;
  off_net_vendor_id: string;
  network_hub: any;
  network_hub_id: number;
  responded: boolean;
  circuit_bandwidth_id: number;
  port_bandwidth_id: number;
  circuit_bandwidth: any;
  port_bandwidth: any;
}
