import {UpdateQueryOptions} from "../../../../common/state/query.actions";

export class UpdateInvoiceGLChargeQuery {
  static readonly type = '[INVOICE_GL_CHARGE] Update Query';
  constructor(public options?: UpdateQueryOptions) {
  }
}

export class UpdateInvoiceGLChargeSiderSection {
  static readonly type = '[INVOICE_GL_CHARGE] Update active sider section';
  constructor(public name: string) {}
}
