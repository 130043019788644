export class LookupModel {
  id: number;
  value: string;
  order_number: number;
  parent: string;
  site_type_id: number;

  key: string;
  lookup_model: string;
  display_name: string;
  system: boolean;
  is_system_row: boolean;
  category_id: number;
  entity_id: number;
  currency_id: number;

  standardized_value: number;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
