import {UpdateQueryOptions} from "../../../../common/state/query.actions";

export class UpdateInvoiceDisputesQuery {
  static readonly type = '[INVOICE_DISPUTES] Update Query';

  constructor(public options?: UpdateQueryOptions) {
  }
}

export class UpdateInvoiceDisputesSiderSection {
  static readonly type = '[INVOICE_DISPUTES] Update active sider section';
  constructor(public name: string) {}
}
