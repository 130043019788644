<ng-container *ngIf="vendor">
<mat-toolbar color="accent" mat-scroll-shrink>

  <span class="sneak-peek-title">{{ vendor?.name }}</span>

  <span class="example-fill-remaining-space"></span>

  <button mat-icon-button (click)="closeSide()">
    <ca-icon [key]="'CLOSE'"></ca-icon>
  </button>
</mat-toolbar>

<div class="ca-card ca-card-sneak-peek">
  <mat-card caCard="">
    <mat-card-content>
      <ca-avatar fxLayout="row"
                [uploadEnabled]="false"
                fxLayoutAlign="center center"
                class="details-item vendor-avatar"
                [text]="getVendorInitials()" [size]="'m'" [source]="vendor?.logo"></ca-avatar>
    </mat-card-content>
  </mat-card>
</div>

<div class="ca-card ca-card-sneak-peek">
  <mat-card caCard *ngIf="vendor?.entities?.length > 0">
    <mat-card-header>
      <label>Entities</label>
    </mat-card-header>
    <mat-card-content>
      <mat-list>
        <mat-list-item *ngFor="let entity of vendor?.entities" fxLayout="row" class="flex-container">
          <p class="flex-item" mat-line fxFlex="100%"> {{ entity.name }}</p>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
</ng-container>

<ng-container *ngIf="!vendor">
	<div class="ca-empty-state-container">
	<p class="card-message">
	No vendor selected
	</p>
	</div>
</ng-container>