<div class="grid-body">
  <dx-data-grid
    #dataGrid
    (onSelectionChanged)="onSelectionChanged($event)"
    (onRowClick)="onRowClick($event)"
    (onCellClick)="onCellClick($event)"
    [columns]="columns"
    [dataSource]="documents">
<!--      <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>-->

<!--    <div *dxTemplate="let d of 'documentTypeTemplate'">-->
<!--        <div caGridTooltip>{{ d.data.documentType ? d.data.documentType.value : 'N/A' }}</div>-->
<!--    </div>-->

<!--    <tbody *dxTemplate="let d of 'documentRowTemplate'">-->
<!--      <tr class="dx-row ca-default-border-bottom" [ngClass]="{ 'has-contract-document': isMainDoc(d.data.type_id) }">-->
<!--        <td><div caGridTooltip>{{ d.data.file_name }}</div></td>-->
<!--        <td><div caGridTooltip>{{ d.data.file_name }}</div></td>-->
<!--        <td><div caGridTooltip>{{ d.data.documentType ? d.data.documentType.value : d.data.type }}</div></td>-->
<!--        <td><div caGridTooltip>{{ d.data.description }}</div></td>-->
<!--      </tr>-->
<!--    </tbody>-->

  </dx-data-grid>
  <ca-pager [gridPager]="defaultGridPager" [pageContext]="documentScheduleDetailGridService" [query]="query"
            class="app-pager ca-default-border-top" (onChange)="onPageChange(query)"
  ></ca-pager>
</div>
