import {Injectable} from '@angular/core';
import {GridService} from "../../shared/grid/grid.service";
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class ContractRateEditGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.contract.contract-rate-edit", userSettings);
  }

  defaultColumns = [
    {
      caption: "Rate ID",
      dataField: "rate_id",
      alignment: 'left',
      width: 150
    },
    {
      caption: "Rate Description",
      dataField: "rate_description",
      width: 300
    },
    {
      caption: "Unit of Measure",
      dataField: "unit_of_measure_id",
      alignment: 'left',
      width: 150
    },
    {
      caption: "Rate Type",
      dataField: "rate_type_id",
      alignment: 'left',
      width: 150
    },
    {
      caption: "Contract",
      dataField: "contract_id",
      alignment: 'left',
      width: 150
    },
    {
      caption: "Contract Section",
      dataField: "contract_section",
      alignment: 'left',
      width: 150
    },
    {
      caption: "Rate",
      dataField: "rate",
      cellTemplate: 'rateCurrencyTemplate',
      dataType: 'currency',
      alignment: 'left',
      width: 150
    },
    {
      caption: "Audit Name",
      dataField: "rule.audit.name",
      width: 150
    },
  ];

}
