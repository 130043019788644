import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import { ContractQuoteFailureReason } from '../../../shared/quote/failure-reason.pipe';

const QUOTE_SERVICE_OFFER_METHOD = LOOKUP_ENUM.QUOTE_SERVICE_OFFER_METHOD;
const QUOTE_SERVICE_OFFER_STATUS = LOOKUP_ENUM.QUOTE_SERVICE_OFFER_STATUS;


@Pipe({ name: 'quoteRequestStatus' })
@Injectable()
export class QuoteRequestStatusPipe implements PipeTransform {
  transform(status: number, method: number): any {
    if (method === QUOTE_SERVICE_OFFER_METHOD.EMAIL || method === QUOTE_SERVICE_OFFER_METHOD.MANUAL) {
      switch (status) {
      case QUOTE_SERVICE_OFFER_STATUS.COMPLETE:
        return 'Received';
      case QUOTE_SERVICE_OFFER_STATUS.FAILED:
        return 'Not Found';
      case QUOTE_SERVICE_OFFER_STATUS.SENT:
        return 'Sent';
      default:
        return 'Not Sent';
    }
    } else {
      if (status === QUOTE_SERVICE_OFFER_STATUS.UNKNOWN) {
        return 'Pending';
      }

      return status === QUOTE_SERVICE_OFFER_STATUS.COMPLETE ? 'Complete' : 'Failed';
    }
  }
}

@Pipe({
  name: 'quoteRequestGridStatus'
})
@Injectable()
export class QuoteRequestGridStatusPipePipe implements PipeTransform {
  constructor(
    private quoteRequestStatusPipe: QuoteRequestStatusPipe,
    private contractQuoteFailureReasonPipe: ContractQuoteFailureReason) {
  }

  transform(status: any, method: any, failureReason: any): string {
    switch (method) {
    case QUOTE_SERVICE_OFFER_METHOD.CONTRACT: {
      return status === QUOTE_SERVICE_OFFER_STATUS.FAILED ?
        this.contractQuoteFailureReasonPipe.transform(failureReason) :
        this.quoteRequestStatusPipe.transform(status, method);
    }

    default: {
      return this.quoteRequestStatusPipe.transform(status, method)
    }
    }
  }
}
