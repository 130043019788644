import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {FilterBaseComponent} from '../filter-base.component';
import {ICONS_ENUM} from "../../../core/resources/icons.enum";
import {FilterEntry} from "../../../core/filter/filter-entry";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

const OPERATORS = {
  EQ: '$eq',
};

@Component({
  selector: 'cas-filter-text',
  templateUrl: './filter-number.component.html',
  styleUrls: ['./filter-number.component.scss']
})
export class FilterNumberComponent implements FilterBaseComponent, OnInit, OnChanges {
  private _value: any;

  ICONS = ICONS_ENUM;

  get value(): any {
    return this._value;
  }

  @Input() set value(value: any) {
    const currentValue = value?.hasOwnProperty('currentValue') ? value?.currentValue : value;
    if (currentValue !== this._value) {
      this._value = currentValue;
      this.setValue(currentValue);
    }
  }

  @Input() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() entry: FilterEntry;

  input: FormControl = new FormControl('');

  ngOnInit(): void {
    this.input.valueChanges
      .pipe(
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe((value: any) => {
        this.update(value);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setValue(changes.value);
  }

  private setValue(value: any): void {
    this.input.setValue(this.getSimpleValue(value));
  }

  private update(value): void {
    const result = this.getOutputValue(value);
    this.change.emit(result);
  }

  private getSimpleValue(value): any {
    if (value && typeof value === 'object') {
      return Object.values(value)[0];
    }
    return value;
  }

  private getOutputValue(inputValue): any {
    const operator = OPERATORS.EQ;
    if (!inputValue) {
      return null;
    } else {
      return {[operator]: inputValue};
    }
  }

}
