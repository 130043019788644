import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class InvoiceGridService extends GridService {

  currency = localStorage.getItem('currency');

  defaultColumns = [
    {
      caption: '',
      headerCellTemplate: 'selectionBadgeHeaderTemplate',
      cellTemplate: 'selectionBadgeCellTemplate',
      fixed: true,
      width: 40,
      allowSorting: false
    },
    {
      caption: 'Invoice ID',
      dataField: 'invoice_id',
      width: 100
    },
    {
      caption: 'Invoice Number',
      headerCellTemplate: 'invNumTemplate',
      dataField: 'sp_inv_num',
      width: 220
    },
    {
      caption: 'AP Invoice Number',
      dataField: 'batch_item.ap_invoice_number',
      width: 160
    },
    {
      caption: 'Status',
      dataField: 'header.status.value',
      width: 160
    },
    {
      caption: 'Document Type',
      dataField: 'header.type.value',
      width: 160
    },
    {
      caption: 'Batch ID',
      dataField: 'batch.batch_key',
      width: 120
    },
    {
      caption: 'Vendor',
      dataField: 'vendor.name',
      width: 130
    },
    {
      caption: 'Account',
      dataField: 'acct_level_1',
      width: 150
    },
    {
      caption: 'Vendor Location ID',
      dataField: 'account.vendor_remit_address.vendor_location_id',
      width: 130
    },
    {
      caption: 'On Hold',
      dataField: 'account.on_hold',
      dataType: 'boolean',
      cellTemplate: 'onHoldCellTemplate',
      width: 150,
      transformValue: {
        true: 'Yes',
        false: 'No'
      }
    },
    {
      caption: 'Vendor Entity',
      dataField: 'account.entity.name',
      width: 160,
      visible: false
    },
    {
      caption: 'Tax Exempt',
      dataField: 'account.is_tax_exempt',
      dataType: 'boolean',
      cellTemplate: 'taxExemptCellTemplate',
      width: 150,
      transformValue: {
        true: 'Yes',
        false: 'No'
      },
      visible: false
    },
    {
      caption: 'Invoice Date',
      dataField: 'inv_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      width: 120
    },
    {
      caption: 'Received Date',
      dataField: 'header.received_at',
      dataType: 'datetime',
      cellTemplate: 'receivedAtTemplate',
      alignment: 'left',
      width: 170
    },
    {
      caption: 'Due Date',
      dataField: 'due_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      width: 120
    }, {
      caption: 'Balance Forward',
      dataField: 'bal_fwd',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 150
    }, {
      caption: 'Total New Charges Adj',
      dataField: 'tot_new_chg_adj',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 170
    }, {
      caption: 'Total Due',
      dataField: 'tot_amt_due',
      cellTemplate: 'invoiceCurrencySymbolTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: 'Total Amount to be Paid',
      dataField: 'total_amt_to_be_paid',
      cellTemplate: 'invoiceTotalAmttobePaid',
      dataType: 'currency',
      alignment: 'right',
      width: 160,
      allowSorting: false
    }, {
      caption: 'Total Amount to be Booked to AP',
      dataField: 'gl_amt',
      cellTemplate: 'invoiceCurrencySymbolTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 220,
      allowSorting: false
    },
    {
      caption: 'Currency Code',
      dataField: 'currency',
      width: 120
    },
    {
      caption: 'Total Due ('+ this.currency + ')',
      dataField: 'tot_amt_due_norm',
      cellTemplate: 'invoiceOtherCurrencyTemplate',
      dataType: 'currency_exchange',
      alignment: 'right',
      width: 120,
      isExchangeRate: true,
      allowSorting: false
    }, {
      caption: 'Total MRC',
      dataField: 'tot_mrc_chgs',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: 'Total OCC',
      dataField: 'tot_occ_chgs',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: 'Total Usage',
      dataField: 'tot_usage_chgs',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: 'Total Taxes / Surcharge',
      dataField: 'tot_taxsur',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 170
    }, {
      caption: 'Total Discount',
      dataField: 'tot_disc_amt',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: 'Total New Charges',
      dataField: 'tot_new_chgs',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 150
    },
    {
      caption: 'Total New Charge ('+ this.currency + ' )',
      dataField: 'tot_new_chgs_norm',
      cellTemplate: 'invoiceOtherCurrencyTemplate',
      dataType: 'currency_exchange',
      alignment: 'right',
      width: 120,
      isExchangeRate: true,
      allowSorting: false
    },{
      caption: 'New Charges Diff',
      dataField: 'header.tot_new_chg_diff',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 160
    }, {
      alignment: 'right',
      caption: 'New Charges Diff %',
      dataField: 'header.tot_new_chg_diff_pct',
      dataType: 'number',
      csvDataType: 'percentage',
      cellTemplate: 'numberTemplate',
      precision: 2,
      width: 170
    }, {
      caption: 'Subclient ',
      dataField: 'account.subclient.value',
      width: 160
    }, {
      caption: 'Approved By',
      dataField: 'header.approver.full_name',
      width: 160,
      visibility: false
    }, {
      caption: 'Approved At',
      dataField: 'header.approved_at',
      dataType: 'datetime',
      cellTemplate: 'approvedAtTemplate',
      width: 160,
      visibility: false
    },{
      caption: 'DNP Reason Code',
      dataField: 'header.dnp_reason.value',
      width: 250
    }];

  subAccountsSummaryColumns() {
    return [{
      caption: 'Sub Account',
      dataField: 'acct_level_2',
      width: 150
    }, {
      caption: 'Prev Bill Amount',
      dataField: 'prev_bill_amt',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 150
    }, {
      caption: 'Payments Received',
      dataField: 'pmts_rcvd',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 150
    }, {
      caption: 'Balance Forward Adj',
      dataField: 'bal_fwd_adj',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 150
    }, {
      caption: 'Balance Forward',
      dataField: 'bal_fwd',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 150
    }, {
      caption: 'Total MRCs',
      dataField: 'tot_mrc_chgs',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: 'Total OCCs',
      dataField: 'tot_occ_chgs',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: 'Total Usage',
      dataField: 'tot_usage_chgs',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: 'Total Taxes / Surcharges',
      dataField: 'tot_taxsur',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 170
    }, {
      caption: 'Total Discounts',
      dataField: 'tot_disc_amt',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 120
    }, {
      caption: 'New Charge Adj',
      dataField: 'tot_new_chg_adj',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 130
    }, {
      caption: 'Total New Charges',
      dataField: 'tot_new_chgs',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 150
    }, {
      caption: 'Total Amount Due Adj',
      dataField: 'tot_amt_due_adj',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 160
    }, {
      caption: 'Total Amount Due',
      dataField: 'tot_amt_due',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 160
    }]
  }

  constructor(public userSettings: UserSettingsService,
    public formatService: FormatService) {
    super('app.cost.invoice-grid', userSettings);
  }
}
