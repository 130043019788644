import {Injectable} from '@angular/core';
import {FilterService} from '../../core/filter/filter.service';
import {accountFilterContext} from './account-filter.context';
import {Observable} from "rxjs";
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import {VendorService} from "../../vendor/core/vendor.service";
import {DictionaryService} from "../../dictionary/core/dictionary.service";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {VendorLookupProvider} from "../../vendor/core/vendor-lookup.provider";
import {AccountService} from "./account.service";
import {AccountLookupProvider} from "./account-lookup.provider";
import {VendorEntityLookupProvider} from "../../vendor/core/vendor-entity-lookup.provider";
import {LOOKUP_MODELS_ENUM} from "../../dictionary/core/lookup-models.enum";
import {SubclientLookupProvider} from "./subclient-lookup.provider";
import {CountryLookupProvider} from "../../location/core/country-lookup.provider";
import {LocationService} from "../../location/core/location.service";
import {CurrencyLookupProvider} from "../../location/core/currency-lookup.provider";
import {map} from "rxjs/operators";

export class RegionLookupProvider extends IFilterLookupProvider {

  lookupModel: string = LOOKUP_MODELS_ENUM.SYSTEM_REGION.modelName;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  public entries : any;

  findAll(searchQuery?): Observable<any> {
    return this.dictionaryService.getByLookup(this.lookupModel).pipe(
      map((result: any) => {
        this.entries = result.items;
        return this.entries.map((entry) => {
          return {
            key: entry.id,
            value: entry.value
          };
        });
      }));
  }
}

export class AccountStatusLookupProvider extends IFilterLookupProvider {

  lookupModel: string = LOOKUP_MODELS_ENUM.ACCOUNT_STATUS.modelName;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  public entries : any;

  findAll(searchQuery?): Observable<any> {
    return this.dictionaryService.getByLookup(this.lookupModel).pipe(
      map((result: any) => {
        this.entries = result.items;
        return this.entries.map((entry) => {
          return {
            value: entry.value,
            key: entry.id
          };
        });
      }));
  }
}

export class BillingFrequencyLookupProvider extends IFilterLookupProvider {

  lookupModel: string = LOOKUP_MODELS_ENUM.BILLING_FREQUENCY.modelName;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  public entries : any;

  findAll(searchQuery?): Observable<any> {
    return this.dictionaryService.getByLookup(this.lookupModel).pipe(
      map((result: any) => {
        let lookupOptions = result.items.map((entry) => {
          return {
            key: entry.id,
            value: entry.value
          };
        });
        lookupOptions.push({
          key: 'null',
          value: 'Unassigned'
        })
        this.entries = lookupOptions;
        return this.entries;
      }));
  }
}

@Injectable()
export class AccountFilterService extends FilterService {

  constructor(public vendorService: VendorService,
              public dictionaryService: DictionaryService,
              public accountService: AccountService,
              public userSettingsService: UserSettingsService,
              public locationService: LocationService) {
    super("app.accounts.account-filter", accountFilterContext, userSettingsService);

    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('entity', new VendorEntityLookupProvider(vendorService));
    this.addLookupProvider('account', new AccountLookupProvider(accountService));
    this.addLookupProvider('subclient', new SubclientLookupProvider(dictionaryService));
    this.addLookupProvider('accountStatus', new AccountStatusLookupProvider(dictionaryService));
    this.addLookupProvider('billingFrequency', new BillingFrequencyLookupProvider(dictionaryService));
    this.addLookupProvider('country', new CountryLookupProvider(locationService, true));
    this.addLookupProvider('region', new RegionLookupProvider(dictionaryService));
    this.addLookupProvider('currency',new CurrencyLookupProvider(locationService))
  }

}
