import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {QuotingDetailsComponent} from './quoting-details/quoting-details.component';
import {QuotingDetailsRequestsComponent} from './quoting-details-offers/quoting-details-offers.component';
import {QuotingListComponent} from './quoting-list/quoting-list.component';
import {QuotingReportComponent} from "./quoting-report/quoting-report.component";
import {QuoteDetailsComponent} from "./quote-details/quote-details.component";
import {QuoteResolver} from "./core/quote.resolver";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':id/show',
        component: QuotingDetailsComponent,
        resolve: { quote: QuoteResolver }
      },
      {
        path: ':id/show/:serviceId',
        component: QuotingDetailsComponent,
        //resolve: { quote: QuoteResolver }
      },
      {
        path: '',
        component: QuotingListComponent
      },
      {
        path: 'service/:serviceId/show',
        component: QuotingDetailsRequestsComponent
      },
      {
        path: ':id/report/:serviceId/show',
        component: QuotingReportComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class QuotingRoutingModule {
}
