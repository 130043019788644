import {Injectable} from '@angular/core';

import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class InvoiceDocumentGridService extends GridService {
  defaultColumns = [{
    caption: 'Name',
    dataField: 'name',
    visible: true,
  }, {
    caption: 'Description',
    dataField: 'description',
    visible: true,
  }, {
    caption: 'Created at',
    dataField: 'created_at',
    dataType: 'datetime',
    alignment: 'left',
  }, {
    caption: 'Updated at',
    dataField: 'updated_at',
    dataType: 'datetime',
    alignment: 'left',
  }, {
    caption: 'Attachment type',
    dataField: 'invoice_attachment_type.value',
    visible: true,
  },
  {
    caption: 'System file',
    dataType: 'boolean',
    dataField: 'is_system_file',
    cellTemplate: 'onHoldCellTemplate',
    width: 150,
    transformValue: {
      true: 'Yes',
      false: 'No'
    }
  }
  ];

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.cost.invoice-documents-grid', userSettings);
  }
}




