import {UpdateQueryOptions} from "../../../../common/state/query.actions";

export class UpdateInvoiceAlertQuery {
  static readonly type = '[INVOICE_ALERT] Update Query';
  constructor(public options?: UpdateQueryOptions) {
  }
}

export class UpdateInvoiceAlertSiderSection {
  static readonly type = '[INVOICE_ALERT] Update active sider section';
  constructor(public name: string) {}
}
