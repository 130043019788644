<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span
    class="toolbar-title">{{ 'Audits: ' + (alert?.vendor?.name) + ' • ' + (alert?.account_no) + ' • ' + (alert?.invoice?.inv_date | date : 'M/D/YYYY') }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button *permission="{'action':'View', 'key':'invoice'}"
          [disabled]="!(alert && alert.invoice && alert.invoice.invoice_id)" (click)="goToInvoiceDetails()"
          mat-icon-button>
    <ca-icon [key]="'VIEW_INVOICE_DETAILS'" [tooltip]="'View Invoice Details'"></ca-icon>
  </button>
  <button
    *permission="{'action':'Create', 'key':'disputes'}"
    mat-icon-button
    [disabled]="addDisputeDisabled"
    (click)="addDispute()"
    [hidden]="!tabGroup.isActive(2)"
  >
    <ca-icon [key]="'ADD_DISPUTE'" [tooltip]="'Create Dispute'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [disabled]="!tabGroup.isActive(2) || filterDisable" matTooltip="Filter"
          [ngClass]="{'on': (sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(2))}"
          (click)="clickFilterButton()" mat-icon-button>
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content tabbed-content">
  <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="currentTabIndex">
    <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs" [disabled]="tab.disabled">
    </mat-tab>
  </mat-tab-group>

  <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(1)}" class="tabs-body" fxLayout="column">
    <div class="flex-container ca-card" fxLayout="row">
      <mat-card fxFlex="60%" fxFlexFill caCard>

        <mat-card-header>
          Audit Info
        </mat-card-header>

        <mat-card-content>
          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Category</label>
              <p>
                {{ alert?.category?.value || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Created At</label>
              <p>
                {{ alert?.created_at | date : 'M/D/YYYY h:mm:ss a' || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Created By</label>
              <p>
                {{ alert?.creator?.full_name || 'System' }}
              </p>
            </div>
          </div>

          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Owner</label>
              <p>
                {{ alert?.owner?.full_name || PLACEHOLDERS.OWNER_UNASSIGNED }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Priority</label>
              <p>
                {{ alert?.priority.value || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Status</label>
              <p>
                {{ alert?.status.value || 'N/A' }}
              </p>
            </div>
          </div>

          <div fxLayout="row" class="entry-list">

            <div fxFlex class="entry-list-item">
              <label>Last Modified At</label>
              <p>
                {{ (alert?.last_modified_at | date) || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Closed At</label>
              <p>
                {{ (alert?.closed_at | date) || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Message</label>
              <p>
                {{ alert?.message || 'N/A' }}
              </p>
            </div>
          </div>

          <div fxLayout="row" class="entry-list" *ngIf="alert.type_id === MULTIPLE_ALERT">
            <div fxFlex class="entry-list-item">
              <label>Audits</label>
              <p>
                {{auditNames?.join(', ')}}
              </p>
            </div>
          </div>

        </mat-card-content>
      </mat-card>
      <mat-card fxFlex fxFlexFill caCard>
        <mat-card-header>
          Invoice Info
        </mat-card-header>

        <mat-card-content>
          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>BAN</label>
              <p>
                {{ (alert?.account_no) || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Vendor</label>
              <p>
                {{ (alert?.vendor?.name) || 'N/A' }}
              </p>
            </div>
          </div>

          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Invoice Number</label>
              <p>
                {{ (alert?.invoice?.sp_inv_num) || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Invoice ID</label>
              <p>
                {{ (alert?.invoice?.invoice_id) || 'N/A' }}
              </p>
            </div>
          </div>

          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Invoice Date</label>
              <p>
                {{ (alert?.invoice?.inv_date | date) || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!----------------------------- DETAILS TAB ------------------------------------>

  <mat-sidenav-container autosize [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}"
                         class="tabs-body master-detail-arrow-color menu-view multiple-audit-navigation-container"
                         [class.mini]="navigationModeMini">
    <mat-sidenav
      *ngIf="tabGroup.isActive(2)"
      disableClose="true"
      mode="side"
      class="mat-drawer multiple-audit-navigation audit-nav"
      [opened]="navigationOpened"
      [class.mini-navigation-hover]="miniNavigationHovered"
      on-mouseover='navigationMouseOver()' on-mouseleave="navigationMouseLeave()">
      <perfect-scrollbar class="container"
                         [config]="{suppressScrollX: true}"

                         class="container" style="height: 100%;">
        <div class="content" style="height: 100%;">
          <nav>
            <ul class="navigation-list">
              <button class="close-button" (click)="toggleNavigation()">
                <ca-icon [key]="'NAV_TOGGLE'"></ca-icon>
              </button>
              <li *ngFor="let entry of entries">
                <div *ngIf="entry.child.length > 0" class="menu-item">
                  <button mat-button class="title-button" type="button" (click)="toggle(entry)">
                    <div class="indicator">
                      <ca-icon [key]="entry.open ? 'NAV_ARROW_UP' : 'NAV_ARROW_DOWN'"></ca-icon>
                    </div>
                    <span class="label">{{entry.value}}</span>

                    <span
                      class="badge badge-danger badge-number">{{entry.key === 'system' ? systemTotal : rateAuditTotal}}</span>
                  </button>

                  <ul *ngIf="entry.open">
                    <li *ngFor="let child of entry.child" class="submenu-item"
                        [ngClass]="{'menu-item-focused': selectedAudit.title === child.title}">
                      <button mat-button type="button" class="submenu"
                              (click)="selectAudit(child)">
                        <span class="submenu-title">{{child.title}}</span>
                        <span class="badge-number">{{child.issues_found}}</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="horizontal-line"
                  *ngIf="goodEntries[0].child.length + goodEntries[1].child.length > 0 && entries[0].child.length + entries[1].child.length > 0">
                <div class="horizontal-line-view"></div>
              </li>

              <li *ngFor="let goodEntry of goodEntries">
                <div *ngIf="goodEntry.child.length > 0" class="menu-item">
                  <button mat-button class="title-button" type="button" (click)="toggle(goodEntry)">
                    <div class="indicator">
                      <ca-icon [key]="goodEntry.open ? 'NAV_ARROW_UP' : 'NAV_ARROW_DOWN'"></ca-icon>
                    </div>
                    <span class="label">{{goodEntry.value}}</span>
                  </button>

                  <ul *ngIf="goodEntry.open">
                    <li *ngFor="let child of goodEntry.child" class="submenu-item"
                        [ngClass]="{'menu-item-focused': selectedAudit.title === child.title}">
                      <div class="submenu">
                        <span class="submenu-title" matTooltip="{{child.title}}">{{child.title}}</span>
                        <ca-icon class="check-good" [key]="'APPLY'"></ca-icon>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

            </ul>
          </nav>
        </div>
      </perfect-scrollbar>
    </mat-sidenav>

    <mat-sidenav-container *ngIf="tabGroup.isActive(2) && selectedAudit" class="rate-audit-content-container">

      <!----------------------------- RATE AUDIT --------------------------------------------->

      <ng-container *ngIf="selectedAudit.key === 'RATE_AUDIT'">
        <dx-data-grid
          #masterGrid class="app-grid-with-pager app-content-list data-table-view master-details-enabled"
          [columns]="masterColumns"
          [height]="'100%'"
          [showColumnLines]="false"
          [allowColumnResizing]="true"
          [dataSource]="selectedAudit.rules"
          [masterDetail]="{ enabled: true, template: 'ruleResultDetails' }"
          (onRowExpanded)="onRateRuleExpanded($event)"
          (onRowCollapsed)="onRateRuleCollapsed($event)"
          keyExpr="id"
        >
          <div *dxTemplate="let d of 'rateCellTemplate'">
            <div caGridTooltip class="right">
              {{ (d.value | caInvoiceCurrency:"USD":"symbol-narrow":"1.2-2") || '$0.00' }}
            </div>
          </div>
          <div *dxTemplate="let ruleResult of 'ruleResultDetails'"
               (click)="onChargesDataGridClick($event)"
          >
            <mat-card fxFlex="100%" class="flex-item result-card" caCard>
              <mat-card-content>
                <dx-data-grid
                  #detailsRategrid
                  class="details-grid rate-details-grid"
                  [ngClass]="{'pager-grid': ruleResult.data.resultQuery && ruleResult.data.resultQuery.total < 10}"
                  [columns]="selectedAudit.detailColumns"
                  [dataSource]="ruleResult.data.charges"
                  [showBorders]="true"
                  [showRowLines]="true"
                  [showColumnLines]="false"
                  [allowColumnResizing]="true"
                  [columnMinWidth]="70"
                  (onSelectionChanged)="onChargesSelectionChanged($event, ruleResult.data)"
                  keyExpr="id"
                  [id]="'charges-grid-' + ruleResult.data.id"
                >
                  <dxo-selection mode="multiple"></dxo-selection>

                  <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
                    <a class="selection-badge grid-badge"
                       matTooltip="Selection"
                       (click)="loadSelectedRuleResults(ruleResult.data)"
                       [ngClass]="{'active':ruleResult.data.selectionActive}">
                      {{ruleResult.data.selectedRows ? ruleResult.data.selectedRows.length : '0'}}
                    </a>
                  </div>
                  <div
                    *dxTemplate="let d of 'chargeDisputeNoteTemplate'"
                    style="position: relative;"
                    class="icon-td"
                  >
                    <a [ngClass]="
                        d.data.cost_invoice_charge?.charge_dispute ? 'icon-danger' : 'disabled'
                      "
                       (click)="showDispute(d.data.cost_invoice_charge?.charge_dispute?.dispute_id)"
                       class="charge-grid-icons"
                    >
                      <ca-icon
                        [key]="'DISPUTE'"
                        [innerClass]="'charge-grid-icon'"
                        [tooltip]="'Dispute'"
                      ></ca-icon>
                    </a>
                  </div>
                  <div *dxTemplate="let d of 'rateCellTemplate'">
                    <div caGridTooltip class="right">
                      {{ (d.value | caInvoiceCurrency:"USD":"symbol-narrow":"1.2-2") || '$0.00' }}
                    </div>
                  </div>
                  <dxo-paging [enabled]="false" visible="false"></dxo-paging>
                  <dxo-load-panel [enabled]="false"></dxo-load-panel>
                </dx-data-grid>
                <ca-pager
                  *ngIf="ruleResult.data.resultQuery && ruleResult.data.resultQuery.total >= 10"
                  [query]="ruleResult.data.resultQuery || query"
                  class="app-pager ca-default-border-top"
                  [sizes]="sizes"
                  (onChange)="onResultPageChange(ruleResult.data.resultQuery || query, ruleResult.data)"
                  [gridPager]="ruleResult.data.resultQuery.limit || 20"
                >
                </ca-pager>
              </mat-card-content>
            </mat-card>
          </div>
          <dxo-paging [enabled]="false" visible="false"></dxo-paging>
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
        </dx-data-grid>
        <ca-pager [gridPager]="defaultGridPager"
                  [pageContext]="alertRateAuditGridService"
                  [query]="rateQuery" class="app-pager ca-default-border-top"
                  (onChange)="onPageChange(rateQuery, selectedAudit)"></ca-pager>
      </ng-container>

      <!----------------------------- INVENTORY MRC AUDIT --------------------------------------------->

      <ng-container *ngIf="selectedAudit.key === 'MRC_INVENTORY'">
        <dx-data-grid #dataGrid *ngIf="selectedAudit.key === 'MRC_INVENTORY'"
                      class="master-detail app-content-list data-table-view alert-inventory-grid-with-pager dx-sort-disabled  master-details-enabled"
                      (onSelectionChanged)="onSelectionChanged($event)"
                      [masterDetail]="{ enabled: true, template: 'detail' }"
                      (onRowExpanded)="onRowExpanded($event)"
                      (onRowCollapsed)="onRowCollapsed($event)"
                      keyExpr="id"
                      [height]="'100%'"
                      [showColumnLines]="false"
                      [allowColumnResizing]="true"
                      [columns]="columns"
                      [dataSource]="inventoryMrcAudits"
                      (onCellClick)="onCellClick($event)">

          <div *dxTemplate="let d of 'auditCurrencyTemplate'">
            <div caGridTooltip class="right">{{ d.value | caCurrency:"USD":"symbol-narrow":"1.2-2" }}</div>
          </div>
          <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
            <div caGridTooltip class="right">{{ d.value | caCurrency:"USD":"symbol-narrow":"1.2-2" }}</div>
          </div>

          <div *dxTemplate="let d of 'rateStatusTemplate'">
            <div caGridTooltip class="left"
                 [ngClass]="(d.value && d.value === 'Expired') ? 'batch_inactive demi-bold' : (d.value && d.value === 'Active') ? 'batch_active demi-bold' : ''"
            >{{ d.value || 'N/A' }}</div>
          </div>

          <div *dxTemplate="let d of 'chargeRateTemplate'">
            <div caGridTooltip
                 class="right">{{ (d.value < 0) ? ("(" + (d.value * -1 | number:"1.2-2") + ")") : d.value |
              number:"1.2-2"}}
            </div>
          </div>
          <div *dxTemplate="let audit of 'detail'" class="detail-table"
               (click)="onChargesDataGridClick($event)"
          >
            <mat-card fxFlex="100%" class="flex-item result-card" caCard>
              <mat-card-content>
                <dx-data-grid
                  class="charge-table details-grid"
                  #chargesDataGrid
                  [ngClass]="{'pager-grid': audit.data.resultQuery && audit.data.resultQuery.total < 10}"
                  [columns]="chargeColumns"
                  [allowColumnResizing]="true"
                  [dataSource]="audit.data.charges"
                  (onSelectionChanged)="onChargesSelectionChanged($event, audit.data)"
                  [showBorders]="true"
                  [showRowLines]="true"
                  [showColumnLines]="false"
                  keyExpr="id"
                  [id]="'charges-grid-' + audit.data.id">
                  <dxo-selection mode="multiple"></dxo-selection>

                  <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
                    <a class="selection-badge grid-badge"
                       matTooltip="Selection"
                       (click)="loadSelectedAuditResults(audit.data)"
                       [ngClass]="{'active':audit.data.selectionActive}">
                      {{audit.data.selectedRows ? audit.data.selectedRows.length : '0'}}
                    </a>
                  </div>
                  <div
                    *dxTemplate="let d of 'chargeDisputeNoteTemplate'"
                    style="position: relative;"
                    class="icon-td"
                  >
                    <a [ngClass]="
                            d.data.charge_dispute_id ? 'icon-danger' : 'disabled'
                          "
                       (click)="showDispute(d.data.charge_dispute_id)"
                       class="charge-grid-icons"
                    >
                      <ca-icon
                        [key]="'DISPUTE'"
                        [innerClass]="'charge-grid-icon'"
                        [tooltip]="'Dispute'"
                      ></ca-icon>
                    </a>
                  </div>
                  <div *dxTemplate="let d of 'amountCurrencyTemplate'">
                    <div caGridTooltip class="right">{{ d.value | caCurrency:"USD":"symbol-narrow":"1.2-2" }}</div>
                  </div>
                  <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
                    <div caGridTooltip class="center">{{ d.value | caCurrency:"USD":"symbol-narrow":"1.2-2" }}</div>
                  </div>

                  <div *dxTemplate="let d of 'chargeRateTemplate'">
                    <div caGridTooltip
                         class="right">{{ (d.value < 0) ? ("(" + (d.value * -1 | number:"1.2-2") + ")") : d.value |
                      number:"1.2-2"}}
                    </div>
                  </div>
                  <dxo-paging [enabled]="false" visible="false"></dxo-paging>
                  <dxo-load-panel [enabled]="false"></dxo-load-panel>
                </dx-data-grid>
                <ca-pager
                  *ngIf="audit.data.resultQuery && audit.data.resultQuery.total >= 10"
                  [query]="audit.data.resultQuery || query"
                  class="app-pager ca-default-border-top"
                  [sizes]="sizes"
                  (onChange)="onAuditResultPageChange(audit.data.resultQuery || query, audit.data)"
                  [gridPager]="audit.data.resultQuery.limit || 20"
                >
                </ca-pager>
              </mat-card-content>
            </mat-card>
          </div>
          <dxo-paging [enabled]="false" visible="false"></dxo-paging>
        </dx-data-grid>
        <ca-pager [gridPager]="defaultGridPager"
                  [pageContext]="alertInventoryMrcGridService"
                  [query]="queryInventoryMrc" class="app-pager ca-default-border-top"
                  (onChange)="onPageChange(queryInventoryMrc, selectedAudit)"></ca-pager>
      </ng-container>

      <!----------------------------- CHARGE TOTAL AUDIT --------------------------------------------->

      <ng-container *ngIf="selectedAudit.key === 'CHARGE_TOTAL'">
        <dx-data-grid
          #chargeGrid class="app-grid-with-pager app-content-list data-table-view"
          [columns]="chargeTotalColumns"
          [allowColumnResizing]="true"
          [dataSource]="chargesTotal"
          [height]="'100%'"
          [showColumnLines]="false"
        >

          <dxo-paging [enabled]="false" visible="false"></dxo-paging>
        </dx-data-grid>
        <ca-pager [gridPager]="defaultGridPager"
                  [pageContext]="alertInventoryChargeTotalGridService"
                  [query]="queryChargesTotal" class="app-pager ca-default-border-top"
                  (onChange)="onPageChange(queryChargesTotal, selectedAudit)"></ca-pager>
      </ng-container>


      <!-------------------------MISSING INVENTORY--------------------------->

      <ng-container *ngIf="selectedAudit.key === 'MISSING_INVENTORY'">
        <dx-data-grid
          #missingInventoryGrid class="app-content-list data-table-view missing-inventory-grid"
          [columns]="missingInventoryColumns"
          [allowColumnResizing]="true"
          [dataSource]="missingInvenory"
          [height]="'100%'"
          [showColumnLines]="false"
          (onSelectionChanged)="onChargesSelectionChanged($event)"
          keyExpr="id"
          (click)="onChargesDataGridClick($event)"
        >

          <dxo-selection mode="multiple" ></dxo-selection>

          <div *dxTemplate="let d of 'selectedChargesHeaderTemplate'" class="grid-selection-badge-container">
            <a class="selection-badge grid-badge"
               matTooltip="Selection"
               (click)="loadSelected()"
               [ngClass]="{'active': selectionActive}">
              {{selectedCharges.length}}
            </a>
          </div>
          <div
            *dxTemplate="let d of 'chargeDisputeNoteTemplate'"
            style="position: relative;"
            class="icon-td"
          >
            <a [ngClass]="
                    d.data.charge.charge_dispute ? 'icon-danger' : 'disabled'
                  "
               (click)="showDispute(d.data.charge.charge_dispute?.dispute_id)"
               class="charge-grid-icons"
            >
              <ca-icon
                [key]="'DISPUTE'"
                [innerClass]="'charge-grid-icon'"
                [tooltip]="'Dispute'"
              ></ca-icon>
            </a>
          </div>

          <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
            <div caGridTooltip class="right">{{ d.value | caCurrency:"USD":"symbol-narrow":"1.2-2" }}</div>
          </div>

          <div *dxTemplate="let d of 'numTemplate'">
            <div caGridTooltip class="right">{{ d.value | number : "1.2-2" }}</div>
          </div>
          <dxo-paging [enabled]="false" visible="false"></dxo-paging>

        </dx-data-grid>
        <ca-pager [gridPager]="defaultGridPager"
                  [pageContext]="alertMissingInventoryDetailsGridService"
                  [query]="queryMissingInventory" class="app-pager ca-default-border-top"
                  (onChange)="onPageChange(queryMissingInventory, selectedAudit)"></ca-pager>
      </ng-container>

      <!-------------------------DISCONNECTED ORDER--------------------------->

      <ng-container *ngIf="selectedAudit.key === 'DISCONNECT_AUDIT'">
        <dx-data-grid
          #chargeGrid class="app-grid-with-pager app-content-list data-table-view disconnect-grid"
          [columns]="disconnectColumns"
          [allowColumnResizing]="true"
          [dataSource]="disconnectData"
          [height]="'100%'"
          [showColumnLines]="false"
          (onSelectionChanged)="onChargesSelectionChanged($event)"
          keyExpr="id"
          (click)="onChargesDataGridClick($event)"
        >
          <dxo-selection mode="multiple"></dxo-selection>
          <div *dxTemplate="let d of 'selectedChargesHeaderTemplate'">
            <a class="selection-badge grid-badge"
               matTooltip="Selection"
               (click)="loadSelected()"
               [ngClass]="{'active': selectionActive}">
              {{selectedCharges.length}}
            </a>
          </div>
          <div
            *dxTemplate="let d of 'chargeDisputeNoteTemplate'"
            style="position: relative;"
            class="icon-td"
          >
            <a [ngClass]="
                  d.data.charge?.charge_dispute ? 'icon-danger' : 'disabled'
                "
               (click)="showDispute(d.data.charge?.charge_dispute?.dispute_id)"
               class="charge-grid-icons"
            >
              <ca-icon
                [key]="'DISPUTE'"
                [innerClass]="'charge-grid-icon'"
                [tooltip]="'Dispute'"
              ></ca-icon>
            </a>
          </div>
          <div *dxTemplate="let d of 'rateCellTemplate'">
            <div caGridTooltip class="right">
              {{ (d.value | caInvoiceCurrency:"USD":"symbol-narrow":"1.2-2") || '$0.00' }}
            </div>
          </div>
          <dxo-paging [enabled]="false" visible="false"></dxo-paging>
        </dx-data-grid>
        <ca-pager [gridPager]="defaultGridPager" [pageContext]="alertDisconnectGridService"
                  [query]="queryDisconnectOrder" class="app-pager ca-default-border-top"
                  (onChange)="onPageChange(queryDisconnectOrder, selectedAudit)"></ca-pager>
      </ng-container>
    </mat-sidenav-container>
    <mat-sidenav #panelSide mode="side" position="end" opened='false'
                 [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}">
      <new-app-filter
        [ngClass]="{'hidden': (!sider?.isActive(SECTIONS.FILTER_SECTION_NAME) || !tabGroup.isActive(2) || !(selectedAudit && selectedAudit.key === 'MISSING_INVENTORY'))}"
        [query]="queryMissingInventory"
        [filterService]="auditAlertMissingInventoryFilterService"
        (filterRequested)="filterMissingInventory($event)"
        (clearFilterRequested)="clearMissingInventory()">
      </new-app-filter>

      <new-app-filter
        [ngClass]="{'hidden': (!sider?.isActive(SECTIONS.FILTER_SECTION_NAME) || !tabGroup.isActive(2) || !(selectedAudit && selectedAudit.key === 'MRC_INVENTORY'))}"
        [query]="queryInventoryMrc"
        [filterService]="auditAlertInventoryMRCFilterService"
        (filterRequested)="filterInventoryMRC($event)"
        (clearFilterRequested)="clearInventoryMRC()">
      </new-app-filter>

      <new-app-filter
        [ngClass]="{'hidden': (!sider?.isActive(SECTIONS.FILTER_SECTION_NAME) || !tabGroup.isActive(2) || !(selectedAudit && selectedAudit.key === 'DISCONNECT_AUDIT'))}"
        [query]="queryDisconnectOrder"
        [filterService]="auditAlertDisconnectAuditFilterService"
        (filterRequested)="filterDisconnectAudit($event)"
        (clearFilterRequested)="clearDisconnectAudit()">
      </new-app-filter>

      <new-app-filter
        [ngClass]="{'hidden': (!sider?.isActive(SECTIONS.FILTER_SECTION_NAME) || !tabGroup.isActive(2) || !(selectedAudit && selectedAudit.key === 'RATE_AUDIT'))}"
        [query]="queryRateFilter"
        [filterService]="auditAlertRateFilterService"
        (filterRequested)="filterRateAudit($event)"
        (clearFilterRequested)="clearRateAudit()">
      </new-app-filter>

    </mat-sidenav>
  </mat-sidenav-container>
</mat-sidenav-container>
