// Simple 'focus' Directive
import {Input, Directive, ElementRef} from "@angular/core";

@Directive({
  selector: '[focus]'
})
export class FocusDirective {
  @Input()
  focus: boolean;

  constructor(public element: ElementRef) {
  }

  public ngOnChanges() {
    setTimeout(() => {
      if (this.focus) {
        this.element.nativeElement.focus();
      }
      else {
        this.element.nativeElement.blur();
      }
    }, 50)
  }
}
