export const spidCodingFilterContext = {
  filters: [
    {
      field: 'spid',
      label: 'Service Provider ID',
      type: 'string',
      index: 1
    },
    {
      field: 'gl_coding_enabled',
      label: 'Status',
      type: 'boolean',
      trueLabel: "Enabled",
      falseLabel: "Disabled",
      index: 2
    }
  ]
};
