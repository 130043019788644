import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RateAuditResultTmpGridService } from 'app/audit/rate-audits/core/rate-audit-result-tmp-grid.service';
import { RateAuditResultService } from 'app/audit/rate-audits/core/rate-audit-result.service';
import { RateAuditRuleService } from 'app/audit/rate-audits/core/rate-audit-rule.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ChargeQuery } from "../../charge/core/charge.query";
import { InvoiceChargeService } from "../../charge/core/invoice-charge.service";
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import Query from "../../core/query/query";
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { DisputeManageDialogComponent } from '../../dispute/shared/dispute-manage-dialog/dispute-manage-dialog.component';
import { LocationService } from "../../location/core/location.service";
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { TabGroup, TabService } from '../../shared/tabs/tab.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { UserService } from "../../user/core/user.service";
import { AlertRateAuditFilterService } from '../core/alert-rate-audit-filter.service';
import { AlertRateAuditGridService } from '../core/alert-rate-audit-grid.service';

@Component({
  selector: 'app-alert-rate-audit',
  templateUrl: './alert-rate-audit.component.html',
  styleUrls: ['./alert-rate-audit.component.css']
})

export class AlertRateAuditComponent extends PageListComponent implements OnInit, AfterViewInit, OnDestroy {
  alert;
  public summary: any;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTab: number = 0;
  public query: Query = new Query({ orderBy: [['id', 'ASC']] });
  public ruleFilterQuery: Query = new Query({where:{}});
  public rules: Array<any> = [];
  public columns: any;
  masterColumns = [];
  detailColumns = [];

  @ViewChild('masterGrid', {static: false}) masterGrid: DxDataGridComponent;
  @ViewChild('detailsGrid', {static: false}) detailsRategrid: DxDataGridComponent;
  @ViewChildren(DxDataGridComponent) dataGrids: QueryList<DxDataGridComponent>

  activeTabIndex: number = 0;

  public alertId: number;

  expandedRows: Array<any> = [];

  addDisputeDisabled: boolean = true;
  charges: Array<any> = [];
  chargeQuery: ChargeQuery = new ChargeQuery({orderBy: [['id', 'ASC']]});
  public dictionaryQuery: Query = new Query({ orderBy: [['id', 'ASC']] });
  resultQuery: Query = new Query({total: 0, orderBy: [['id', 'ASC']]});
  delayChargesSelectionChange: boolean = false;
  public sizes = [5, 10, 20];
  public DISPUTE_CATEGORY_LOOKUP_MODEL = LOOKUP_MODELS_ENUM.DISPUTE_CATEGORY.modelName;

  readonly PLACEHOLDERS = {
    OWNER_UNASSIGNED: 'Unassigned'
  };

  public rulesDefinition: any;

  selectColumns = {
    chg_class: 'Charge Class',
    chg_code_1: 'Charge Code 1',
    chg_code_2: 'Charge Code 2',
    chg_code_3: 'Charge Code 3',
    chg_desc_1: 'Charge Description 1',
    chg_desc_2: 'Charge Description 2',
    chg_desc_3: 'Charge Description 3',
    fac_bw: 'Facility Bandwidth'
  };

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'common-alert';
  public currentTabIndex = 0;

  public  selectedCharges: Array<any> = [];
  public selectionActive = false;

  constructor(public settingsService: UserSettingsService,
              public route: ActivatedRoute,
              public router: Router,
              public userService: UserService,
              public locationService: LocationService,
              public toastAlertService: AlertService,
              public loaderService: LoaderService,
              public tabService: TabService,
              public alertRateAuditGridService: AlertRateAuditGridService,
              public rateAuditResultService: RateAuditResultService,
              public rateAuditResultTmpGridService: RateAuditResultTmpGridService,
              public rateAuditRuleService: RateAuditRuleService,
              public alertRateAuditFilterService: AlertRateAuditFilterService,
              public dialogService: DialogService,
              public chargeService: InvoiceChargeService,
              public dictionaryService: DictionaryService
  ) {
    super(new PageContext({
      name: 'app.alert.alert-rate-audit',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    this.currentTabIndex = index;

    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }
  }

  public loadTabs() {
    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({ key: 1, title: 'Summary' });
    this.tabGroup.addTab({ key: 2, title: 'Details' });

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe((tab) => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }

  loadRules(query: Query) {
    this.rules = [];
    this.loaderService.displayLoader(true)
    this.query.where = {
      run_id: this.alert.run_id,
      invoice_id: this.alert.invoice_id,
      audit_id: this.alert.audit_id
    }

    if (this.ruleFilterQuery && this.ruleFilterQuery.where) {
      this.query.where = {
        ...this.query.where,
        ...this.ruleFilterQuery.where
      }
    }

    this.rateAuditResultService.grouping(query)
      .subscribe((result) => {
        this.rules = result.items.map(rule => {
          const ruleDefiniton = this.findRuleDefinition(rule.id)
          rule.name = this.generateRuleTitle(ruleDefiniton);
          return rule;
        });
        query.total = result.total;

        this.expandedRows.map(item => {
          this.onRowExpanded(item, true);
        });

        this.loaderService.hideLoader(true)
      }, () => {
        this.toastAlertService.error('', this.messages.get('ERROR_LOADING_GL_VALIDATION'));
        this.loaderService.hideLoader(true)
      });
  }

  generateRuleTitle(ruleDefiniton) {
    let title = `Rule ${ruleDefiniton.rule_id}: `;
    ruleDefiniton.settings.fields.forEach(field => {
      title += `${this.selectColumns[field.name]} ${field.operator.toUpperCase()} ${field.value}. `;
    })
    return title;
  }

  findRuleDefinition(id){
    return this.rulesDefinition.find(definition=> definition.audit_rate_rule_id === id);
  }

  refresh() {
    this.loadRules(this.query);
  }

  filterData(query: Query) {
    this.ruleFilterQuery = query;
    this.query.offset = 0
    this.query.page = 1
    this.loadRules(this.query)
  }

  clearFilter() {
    this.ruleFilterQuery = new Query({where:{}});
    this.filterData(new Query({where:{}}));
  }

  onPageChange(query: Query) {
    this.loadRules(query);
  }

  onResultPageChange(query: Query, rule) {
    let ruleOne = this.rules.find(x => x.id === rule.data.id);
    this.loadRuleResult(query, ruleOne, true);
  }

  loadSelectedRuleResults(rule) {
    if (rule.selectionActive) {
      if (this.ruleFilterQuery.where.charge_id)
        rule.resultQuery.where.charge_id = {...this.ruleFilterQuery.where.charge_id}
      else
        rule.resultQuery.remove('charge_id')
    } else {
      const chargeIds = this.selectedCharges.filter(chg => rule.selectedRows.includes(chg.id)).reduce((acc, item) => [...acc, item.charge_id], [])
      rule.resultQuery.set('charge_id', {
        $in: chargeIds
      });
    }
    rule.resultQuery.offset = 0;
    rule.resultQuery.page = 1;
    rule.selectionActive = !rule.selectionActive;
    this.loadRuleResult(rule.resultQuery, rule)
  }

  ngOnInit() {
    this.loadTabs();
    this.alert = this.route.snapshot.data.alert;
    this.alertId = this.alert.id;
    this.masterColumns = this.alertRateAuditGridService.columns();
    if(this.alert.audit_id) {
      let detailQuery = new Query({
        where: {
          audit_id: this.alert.audit_id,
        }
      })
      this.rateAuditRuleService.getRules(detailQuery)
        .subscribe(async (result) => {
          if(result && result.items) {
            this.rulesDefinition = result.items;
            let attributesList = this.rulesDefinition[0].settings.fields.map(field => field.name);
            this.detailColumns = this.rateAuditResultTmpGridService.generateColumns(attributesList);
            setTimeout(() => {
              this.detailsRategrid && this.rateAuditResultTmpGridService.create(this.detailsRategrid.instance, {
                noDataText: this.rateAuditResultTmpGridService.noDataMessage,
                showColumnLines: true,
                allowColumnResizing: true,
              })
            }, 100);

            await this.prepareList();
            this.loadRules(this.query);
          }
        })
    } else {
      this.tabGroup.tabs[1].disabled = true;
    }
  }

  async prepareList() {
    const gridService = this.alertRateAuditGridService;
    let settings = await gridService.loadSettings().toPromise();
    this.sorting = settings ? settings.sorting : [];
    this.columns = gridService.getColumns(settings ? settings.columns : []);
    this.defaultGridPager = settings ? settings.gridPager : 20;
    this.query.limit = this.defaultGridPager;

    if (this.sorting && this.sorting.length) {
      this.query.orderBy = gridService.getOrderBy(this.sorting);
    }

    if (this.masterGrid && this.masterGrid.instance) {
      this.masterGrid.instance.option('columns', this.columns);
    }
  }

  ngAfterViewInit(): void {
    this.alertRateAuditGridService.create(this.masterGrid.instance, {
      noDataText: this.alertRateAuditGridService.noDataMessage
    });

    this.detailsRategrid && this.detailsRategrid.instance.repaint();

    super.ngAfterViewInit();
  }

  back(): void {
    if (document.referrer.indexOf(window.location.host) >= 0) {
      history.back();
    } else {
      this.router.navigate(['/alert']);
    }
  }

  ngOnDestroy() {
    this.tabGroup.onActivate.unsubscribe();
  }

  onRowExpanded(row, manual?: boolean) {
    let rule = this.rules.find((x) => {
      return manual ? x.id === row : x.id === row.key;
    });
    if (!rule) return;

    if (!rule.charges) {
      this.loaderService.displayLoader(true);
      rule.resultQuery = new Query({
        where: {
          rule_id: rule.id,
          run_id: this.alert.run_id,
          invoice_id: this.alert.invoice_id
        },
        orderBy: [["id", "ASC"]]
      });

      if (this.ruleFilterQuery && this.ruleFilterQuery.where) {
        rule.resultQuery.where = {
          ...rule.resultQuery.where,
          ...this.ruleFilterQuery.where
        };
      }

      this.rateAuditResultService.findAll(rule.resultQuery)
        .subscribe(result => {
          this.loaderService.hideLoader(true)
          Object.assign(rule, {charges: result.items});
          rule.resultQuery.total = result.total;
          // add selected charge ids into audit.selectedRows
          const charges = this.selectedCharges.filter(chg => rule.id === chg['rule_id'])
          rule.selectedRows = charges.length ? charges.reduce((acc, item) => ([...acc, item.id]), []) : []
          rule.selectionActive = false;
          this.delayChargesSelectionChange = true;
          setTimeout(() => {
            this.expandedRows.forEach(rowId => {
              this.selectRowsWithinDetailsGrid(rowId)
            })
          }, 100)
        })
    }

    const foundInExpanded = this.expandedRows.findIndex(obj => row.key === obj)
    if (!manual && foundInExpanded === -1) {
      this.expandedRows.push(row.key)
    }
  }

  loadRuleResult(query, rule, fromPager?) {
    this.delayChargesSelectionChange = true;
    this.loaderService.displayLoader(true)

    this.rateAuditResultService.findAll(query)
    .subscribe(result => {
      rule.charges = result.items;
      rule.resultQuery.total = result.total;
      this.loaderService.hideLoader(true)
      setTimeout(() => {
        this.selectRowsWithinDetailsGrid(rule.id)
      }, 100)
    })
  }

  onRowCollapsed(row) {
    this.expandedRows.splice(this.expandedRows.indexOf(row.key), 1)
  }

  public goToInvoiceDetails() {
    if (this.alert && this.alert.invoice && this.alert.invoice.invoice_id) {
      this.router.navigate(['/invoice', this.alert.invoice.invoice_id, 'show']);
    }
  }

  getCalculatedCharges(value){
    let newArray = [];
    value.sort((a,b) => (a.charge_id > b.charge_id) ? 1 : ((b.charge_id > a.charge_id) ? -1 : 0))
    value.forEach(i  => {
      newArray.push(i['calc_chg_amt'])
    })
    return newArray;
  }

  getDispDesc(value){
    let disputeDescriptionArray = []
    value.forEach(i => {
      const descString = `Charges billed do not equal calculated charges.`;
      disputeDescriptionArray.push(descString);
    })
    return disputeDescriptionArray;
  }

  addDispute() {
    this.chargeQuery['where'] = {};
    this.chargeQuery.set('id', {
      $in: this.selectedCharges.map(x => {
        return x['charge_id'];
      })
    });

    this.chargeQuery.offset = 0;
    this.chargeQuery.page = 1;
    this.chargeQuery.set('invoice_id', this.alert.invoice_id);
    this.chargeService.filters(this.chargeQuery)
      .subscribe(async (result) => {
        this.charges = result.items;
        let arrayOfCalculatedCharges = this.getCalculatedCharges(this.selectedCharges)
        let invoice = this.alert.invoice;
        let resDate = this.alert.invoice.inv_date;
        let dispDesc = this.getDispDesc(this.selectedCharges)
        if (!this.selectedCharges.length || this.addDisputeDisabled) {
          return;
        }

        this.dialogService
          .open(
            DisputeManageDialogComponent,
            {
              charges: this.charges,
              invoice: invoice,
              calculatedCharges: arrayOfCalculatedCharges,
              // resDate: resDate,
              dispDesc: dispDesc,
              alertId: this.alertId
            },
            {width: '60%'}
          )
          .afterClosed()
          .subscribe(async result => {
            if (result) {
              this.selectedCharges.forEach(i => {
                i['cost_invoice_charge']['charge_dispute'] = {dispute_id: result.id};
              });
              this.selectedCharges = [];
              this.loadRules(this.query)
              this.detailsRategrid.instance.refresh();
              this.toastAlertService.success(
                '',
                this.messages.get('DISPUTE_CREATE_SUCCESS')
              );
            }
          });
      })

  }

  showDispute(id: any) {
    this.router.navigate(['/dispute', id, 'show']);
  }

  findIndexInSelectedCharges(chargeId): number {
    for (let i = 0, l = this.selectedCharges.length; i < l; i++) {
      if (this.selectedCharges[i].id === chargeId) {
        return i;
      }
    }
    return -1;
  }
  findIndexInResultCharges(chargeId, rule): number {
    for (let i = 0, l = rule.selectedRows.length; i < l; i++) {
      if (rule.selectedRows[i] === chargeId) {
        return i;
      }
    }
    return -1;
  }

  onChargesSelectionChanged(event, rule) {
    setTimeout(() => {
      if (!this.delayChargesSelectionChange && rule.charges.length) {
        /** Selected rows */
        if (event.selectedRowKeys.length) {
          event.selectedRowsData.forEach(item => {
            let index = this.findIndexInSelectedCharges(item.id);
            if (index === -1) {
              item['rule_id'] = rule.id || null
              this.selectedCharges.push(item);
            }
            let resultIndex = this.findIndexInResultCharges(item.id, rule);
            if (resultIndex === -1 && !rule.selectedRows.includes(item.id)) {
              rule.selectedRows.push(item.id);
            }
          });
        }
        /** Deselected rows */
        if (event.currentDeselectedRowKeys.length) {
          event.currentDeselectedRowKeys.forEach(id => {
            let index = this.findIndexInSelectedCharges(id);
            if (index > -1) {
              this.selectedCharges.splice(index, 1);
            }
            let resultIndex = this.findIndexInResultCharges(id, rule);
            if (resultIndex >= 0) {
              rule.selectedRows = [...rule.selectedRows.filter(rowId => id !== rowId)]
            }
          });
        }
        /** Dispute Add enabled/disabled */
        if (this.selectedCharges.length) {
          this.addDisputeDisabled = false;
          this.selectedCharges.forEach(item => {
            if (item['cost_invoice_charge']['charge_dispute']) {
              this.addDisputeDisabled = true;
            }
          });
        } else {
          this.addDisputeDisabled = true;
        }

        this.delayChargesSelectionChange = false
      }
    }, 10)
  }

  onChargesDataGridClick(event) {
    if (!event.target.classList.contains('selection-badge')) {
      this.delayChargesSelectionChange = false;
    }
  }

  selectRowsWithinDetailsGrid(ruleId) {
    this.delayChargesSelectionChange = true
    const rule = this.rules.find(item => item.id === ruleId)
    if (rule && rule.charges && rule.charges.length) {
      this.dataGrids.forEach(grid => {
        const id = grid['element'].nativeElement.id
        if (id === 'charges-grid-' + rule.id) {
          let results = []
          rule.charges.forEach(chg => {
            if (this.selectedCharges.length && this.selectedCharges.reduce((acc, item) => ([...acc, item.id]), []).includes(chg.id))
              results.push(chg.id)
          })
          if (results.length)
            grid.instance.selectRows(results, false);
        }
      })
    }
  }
}
