import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

import { AccountLookupProvider } from 'app/account/core/account-lookup.provider';
import { AccountService } from 'app/account/core/account.service';
import { ChargeTypeLookupProvider } from 'app/charge/core/charge-type-lookup.provider';
import { IFilterLookupProvider } from 'app/core/filter/filter-lookup.provider';
import { FilterService } from 'app/core/filter/filter.service';
import { DictionaryService } from 'app/dictionary/core/dictionary.service';
import { UserSettingsService } from 'app/user/core/user-settings.service';
import { SubclientLookupProvider } from "../../account/core/subclient-lookup.provider";
import { disputeChargeFilterContext } from './dispute-charge-filter.context';
import { DisputeService } from './dispute.service';
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

export class DirectGlTypeLookupProvider extends IFilterLookupProvider {
    findAll(searchQuery?): Observable<any> {
        return observableOf([
        {
            value: 'Yes',
            key: true
        },
        {
            value: 'No',
            key: false
        }
        ])
    }
}

@Injectable()
export class DisputeChargeFilterService extends FilterService {
  customRanges: any = [
    {
        'label': 'Last 2 years'
    },
    {
        'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public userSettingsService: UserSettingsService,
            public dictionaryService: DictionaryService,
            public disputeService: DisputeService,
            public accountService: AccountService) {
    super('app.cost.dispute-charge-filter', disputeChargeFilterContext, userSettingsService);

    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
    this.addLookupProvider('account', new AccountLookupProvider(accountService));
    this.addLookupProvider('chargeType', new ChargeTypeLookupProvider(dictionaryService));
    this.addLookupProvider('subclient', new SubclientLookupProvider(this.dictionaryService));
  }
}
