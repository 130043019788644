<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Vendor Ack Date</h1>
</div>
<div mat-dialog-content class="ca-dialog-content">     
    
  <form class="ca-form dialog-content-no-margin" [formGroup]="form">
  <div>
    <div class="flex-container" fxLayout="row wrap" fxLayoutGap="24px">
      <div class="flex-item" fxFlex="50%">
        <ca-date-picker caInput placeholder="Vendor Ack Date" [minDate]="minAllowedDate"  formControlName="vendor_ack_date" required="true"></ca-date-picker>
      </div>
    </div>
  </div>

</form>
</div>
  <mat-dialog-actions class="ca-dialog-action">
    <span class="example-fill-remaining-space"></span>
    <button mat-button (click)="reject()">
      Cancel
    </button>
    <button mat-button 
      [disabled]="form?.invalid || form.controls['vendor_ack_date'].value < minAllowedDate"
      type="button" 
      (click)="approve(form)">
      Save
    </button>
  </mat-dialog-actions>
