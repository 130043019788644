import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import * as moment from "moment";

import {UserSettingsService} from "../../../user/core/user-settings.service";
import {HistoryService} from "../../../shared/history/history.service";
import {AlertService} from '../../../shared/alert/alert.service';
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {HistoryComponent} from "../../../shared/history/history.component";
import {DialogService} from '../../../shared/dialog/dialog.service';
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";

import {ContractService} from '../../core/contract.service';
import {Contract} from '../../core/contract';
import {ContractSchedule} from "../../core/contract-schedule";
import {ContractScheduleManageDialogComponent} from "../../shared/contract-schedule-manage-dialog/contract-schedule-manage-dialog.component";

@Component({
    selector: 'app-contract-details-schedules',
    templateUrl: './contract-details-schedules.component.html',
    styleUrls: ['./contract-details-schedules.component.scss']
  })
  export class ContractSchedulesComponent extends PageDetailsComponent implements OnInit, AfterViewInit {

    @ViewChild('panelSide') panelSide;
    @ViewChild('history') history: HistoryComponent;
    @ViewChild('testNotes') testNotes;

    public contract: Contract;

    schedules: Array<ContractSchedule>;
    scheduleSeries: Array<any>;
    selection: any;
    public isSelected: string;
    public scheduleID: number;
    public seriesID: 2;
    scheduleMessages: IMessagesResourceService;

    readonly SCHEDULE_MODULE: string = 'schedule';
    readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
    moment: any = moment;
    public notesCount: number = 0;

    constructor(
        public contractService: ContractService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        public settingsService: UserSettingsService,
        public dialog: DialogService,
        public alert: AlertService,
        public historyService: HistoryService
    ) {
    super(new PageContext({
        name: 'app.contract.contract-details-schedules',
        settings: settingsService
    }));

    this.scheduleMessages = ResourcesService.messages(this.SCHEDULE_MODULE);
  }

  ngOnInit(): void {
    this.contract = this.route.parent.snapshot.data.contract;
    this.loadContractSchedules(this.contract.id)
  }

  loadContractSchedules(id) {
    this.contractService.findAllSchedulesByShell(id)
      .subscribe((results) => {
        this.schedules = results.items;
        let seriesArr = [];
        results.items.forEach(item => {
          if(!seriesArr.some(e => e.id === item.schedule_series_id)){
            if(item.schedule_series_id === LOOKUP_ENUM.SYSTEM_SCHEDULE_SERIES.MSA) {
              seriesArr.unshift({
                id: item.schedule_series_id,
                name: item.schedule_series.value
              })
            } else {
              seriesArr.push({
                id: item.schedule_series_id,
                name: item.schedule_series.value
              })
            }
          }
        })
        this.scheduleSeries = seriesArr;
      });
  }
  createSchedule() {
    this.dialog.open(ContractScheduleManageDialogComponent, {
      contractSchedule: new ContractSchedule({}),
      contract: this.contract
    },{width: 'auto'})
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.loadContractSchedules(this.contract.id);
          this.history.refreshList();
          this.alert.success('', this.scheduleMessages.get('CREATE_SUCCESS'));
        }
      },
      (error) => {
        this.alert.error('', this.scheduleMessages.get('CREATE_ERROR'));
      });
  }

  editSchedule() {
    this.dialog.open(ContractScheduleManageDialogComponent, {
      contract: this.contract,
      contractSchedule: new ContractSchedule(
        {...this.selection}),
      isUpdate: true
    }, {width: 'auto'})
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.selection = null;
          this.scheduleID = null;
          this.isSelected = '';
          this.loadContractSchedules(this.contract.id);
          this.history.refreshList();
          this.alert.success('', this.scheduleMessages.get('UPDATE_SUCCESS'));
        }
      },
      (error) => {
        this.alert.error('', this.scheduleMessages.get('UPDATE_ERROR'));
      });
  }

  deleteSchedule() {
    this.dialog.confirm({
      bodyText: `Are you sure you want to delete selected Schedule? If yes, T/C and Rates connected to the Schedule will be deleted.`
    })
      .afterClosed()
      .subscribe((result) => {
          if(result){
            this.contractService.deleteSchedule(this.selection.id)
              .subscribe(() => {
                this.selection = null;
                this.scheduleID = null;
                this.isSelected = '';
                this.loadContractSchedules(this.contract.id);
                this.history.refreshList();
                this.alert.success('', this.scheduleMessages.get('DELETE_SUCCESS'));
              }, (err) => {
                if(err.status === 409) {
                  this.alert.error('', this.scheduleMessages.get('DELETE_USED_ERROR'));
                } else {
                  this.alert.error('', this.scheduleMessages.get('DELETE_ERROR'));
                }
              });
          }
        },
        (error) => {
          this.alert.error('', this.scheduleMessages.get('DELETE_ERROR'));
        }
      )
  }

  selectSchedule(value){
    if (this.scheduleID === value.id) {
      this.selection = null;
      this.scheduleID = null;
      this.isSelected = '';
    } else {
      this.selection = value;
      this.scheduleID = value.id;
      this.isSelected = 'select-schedule';
    }
  }

  isTerminated(date){
    if (moment(date).isAfter(moment(new Date().setDate(new Date().getDate() - 1)).format('YYYY-MM-DD'))) {
        return true;
    } else {
        return false;
    }
  }

  openScheduleDetails(schedule) {
    this.router.navigate(['/contract', schedule.contract_shell_id, 'schedule', schedule.id, 'show']);
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME);
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME);
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
  }
}
