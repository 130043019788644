import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {DictionaryService} from '../../dictionary/core/dictionary.service';
import {GLString} from "./gl-string";
import {Restangular} from "ngx-restangular";
import Query from "../../core/query/query";
import {LOOKUP_MODELS_ENUM} from "../../dictionary/core/lookup-models.enum";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {map} from "rxjs/operators";

@Injectable()
export class GLStringService extends BaseService<GLString> {

  constructor(public restangular: Restangular,
              public dictionaryService: DictionaryService,
              private entityLock: EntityLockService) {
    super('gl_string', restangular, entityLock);
  }

  findAllWithCharges(query?: Query): any {

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    transformedQuery = this.removeSpecialParams(transformedQuery);

    return this.service().all(this.name).all('string_invoice').customGET(null, this.toFilter(transformedQuery));
  }

  removeSpecialParams(query) {
    const ignored = ['$gpx'];
    if(!query.where) query.where = {};
    let where = {};
    Object.keys(query.where)
      .forEach((key) => {
        const param = query.where[key];
        if (typeof param !== 'object' || param === null) {
          where[key] = param;
        } else {
          const reduced = {};
          Object.keys(param).forEach((k) => {
            if (ignored.indexOf(k) === -1) {
              reduced[k] = param[k]
            }
          })
          where[key] = reduced;
        }
      });

    return Object.assign({}, query, {where});
  }


  public findGLSegments() {
    return new Promise((resolve, reject) => {
      this.service().one(this.name).one("string_count").customGET()
        .subscribe((data) => {

          let lookupModel: string = LOOKUP_MODELS_ENUM.GL_CODE_SEGMENT.modelName;

          let query = new Query({
            'limit': data.total,
            'orderBy': ['id']
          });

          this.dictionaryService.getByLookup(lookupModel, query)
            .subscribe((values) => {
              resolve(this.convert(values.items));
            });
        });
    });

  }

  update(id: number, item: GLString): any {
    return this.service().one(this.name).one('status').customPUT({object: item}).pipe(map((result) => {
      this.completeEdit();
      return result;
    }));
  }


  public convert(values) {
    values.forEach((element) => {
      try {
        element.system_key = `segment${element.id}`;
      } catch (err) {
      }
    });
    return values;
  };

  findIsStringInRule(id: number) {
    return this.service().one(this.name, id).one('inRule').get();
  }

  findAllWithApportions({ ruleMeta, query, ruleType }) {
    query.ruleMeta = ruleMeta;
    query.orderBy = query.orderBy ? query.orderBy.map(order => {
      if (order[0] === 'allocation') {
        return [order[0], order[1], 'NULLS LAST']
      }
      if(order[0] === 'apportion_pct'){
        return [order[0], order[1], 'NULLS LAST']
      }

      return order;
    }): null;
    return this.service().all(this.name,).all(`apportion/${ruleType}`).customGET(null, this.toFilter(query));
  }

  replace(selectedStrings: any, selectedRules: any) {
    return this.service().one(this.name, 'replace').customPOST({ selectedStrings: selectedStrings, selectedRules: selectedRules});
  }

  countReplace(selectedStrings: any) {
    return this.service().one(this.name, 'replace-count').customPOST({ selectedStrings: selectedStrings});
  }

  generateDefaultGridColumns(numberOfDigits: number, isBanStatic: boolean) {
    return [
      {
        caption: 'Apportion %',
        dataField: 'apportion_pct',
        alignment: 'right',
        dataType: 'number',
        validationRules: !isBanStatic ? [
            {type: 'required', message:'Enter value'},
            {type: 'range', min: 0, max: 100, message: 'From 0 to 100'},
            {type: 'pattern', pattern: `^\\d+\\.?\\d{0,${numberOfDigits}}$|\\d{0,9}e-${numberOfDigits}`, message: `Do not use more than ${numberOfDigits} digits after decimal point.` }
          ] :
          [
            {type: 'required', message:'Enter value'},
            {type: 'pattern', pattern: `^-?\\d+\\.?\\d{0,${numberOfDigits}}$|\\d{0,9}e-${numberOfDigits}`, message: `Do not use more than ${numberOfDigits} digits after decimal point.` }
          ],
        width: '120px',
        headerCellTemplate: 'apportionHeaderTemplate',
        format: {
          type: 'fixedPoint',
          precision: numberOfDigits
        }
      },
      {
        caption: 'Full GL String',
        dataField: 'full_string_formatted',
        allowEditing: false,
        width: '200px'
      },
      {
        caption: 'GL String Desc',
        dataField: 'full_string_text',
        allowEditing: false,
        width: '200px'
      }
    ]
  }

  calculateTotalApportion(strings: Array<any>, glApportionFieldName: string, numberOfDigits: number, cleared: boolean = false) {
    let totalApportion = 0.00
    if (!cleared && strings) {
      totalApportion = parseFloat(strings.reduce((sum, item) => {
        return sum + (+item[glApportionFieldName]);
      }, 0));
    }
    return totalApportion.toFixed(numberOfDigits);
  }
}
