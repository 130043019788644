import {ICsvInterceptor} from '../../core/csv/csv-interceptor';

export class InventoryPricingCsvInterceptor implements ICsvInterceptor {
  transform(item: any) {
    let copy = Object.assign({}, item);
    copy.currency = copy.inventory.currency_obj.currency
    delete copy.inventory.currency_obj.currency
    return copy;
  }
}
