import {LOOKUP_ENUM} from "../../dictionary/core/lookup.enum";

export const auditAlertMissingInventoryFilterContext = {
  filters: [

    {
      field: 'charge_id',
      label: 'Charge ID',
      type: 'number',
      index: 0,
      matchCase: false,
      exactMatch: false
    },
  ]
};
