import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

import {UserSettingsService} from "../../../user/core/user-settings.service";
import {AlertService} from '../../../shared/alert/alert.service';
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";

import {ContractService} from '../../core/contract.service';
import {DocumentQuery} from "../../core/contract-document.query";
import {DocumentFilterService} from "../../../shared/document/core/document-filter.service";

@Component({
    selector: 'app-contract-schedule-attachments',
    templateUrl: './contract-schedule-attachments.component.html',
    styleUrls: ['./contract-schedule-attachments.component.scss']
  })
  export class ContractScheduleAttachmentsComponent extends PageDetailsComponent implements OnInit, AfterViewInit {

    @ViewChild('panelSide') panelSide;
    @ViewChild('documentsManager') documentsManager;
    
    public schedule: any;
    
    readonly CONTRACT_DOCUMENT_FOLDER_NAME: string = 'contracts';
    readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
    
    selection: any;
    public documentQuery = new DocumentQuery();
    public notesCount: number = 0;

    DOCUMENT_INFO = {
        FOLDER: 'contracts',
        SUBFOLDER: '',
        PARENT_TYPE: this.SYSTEM_MODULE.CONTRACT,
        ENTITY_TYPE: this.SYSTEM_MODULE.CONTRACT
      };

    constructor(
        public contractService: ContractService,
        private readonly route: ActivatedRoute,
        public settingsService: UserSettingsService,
        public documentFilterService: DocumentFilterService,
        public alert: AlertService
    ) {
    super(new PageContext({
        name: 'app.contract.contract-schedule-attachments',
        settings: settingsService
    }));

    }

    ngOnInit(): void {
        this.schedule = this.route.parent.snapshot.data.schedule;
        this.DOCUMENT_INFO.SUBFOLDER = `/${this.schedule.contract_shell_id}/${this.schedule.id}/`;
    }

    docSelected(doc: Document) {
        this.selection = doc;
    }

    upload() {
      this.documentsManager.addEntityDocument({id: this.schedule.contract_shell_id, contract_schedule_id: this.schedule.id, disableContractSchedulePicker: true});
    }

    download() {
      const selectedDocument = this.selection;
      this.contractService.downloadDocument(selectedDocument.id, this.schedule.contract_shell_id, this.CONTRACT_DOCUMENT_FOLDER_NAME)
        .subscribe((response) => {
          let objectUrl = URL.createObjectURL(response);
  
          let save = document.createElement('a');
          save.href = objectUrl;
          save.target = '_blank';
          save.download = `${selectedDocument.document_name}`;
          let event = document.createEvent('MouseEvents');
          event.initMouseEvent(
            'click',
            true,
            false,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          save.dispatchEvent(event);
        });
    }

    public delete() {
      this.documentsManager.deleteEntityDocument(this.selection)
    }
  
    filterDocumentData(documentQuery) {
      this.documentsManager.loadData(documentQuery);
    }
  
    clearDocumentFilter(documentQuery) {
      this.documentsManager.loadData(documentQuery);
    }

    onNotesCountChanged(count: number) {
      this.notesCount = count;
    }
  
    toggleNotes() {
      this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME);
    }
  
    toggleSider(sectionName: string) {
      this.sider.toggle(sectionName);
    }
  }