import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class GlBatchCreateInvoiceGridService extends GridService {
  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.cost.batch-output-create-invoice-charges-grid', userSettings);
  }

  defaultColumns = [
    {
      caption: 'Invoice ID',
      dataField: 'header.id',
      width: 100
    },
    {
      caption: 'Invoice Number',
      dataField: 'sp_inv_num',
      cellTemplate: 'invoiceNumberTemplate',
      width: 150
    },
    {
      caption: 'Vendor',
      dataField: 'vendor.name',
      width: 150
    },
    {
      caption: 'BAN',
      dataField: 'acct_level_1',
      width: 150
    },
    {
      caption: 'Invoice Date',
      dataField: 'inv_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      width: 150
    },
    {
      caption: 'Due Date',
      dataField: 'due_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      width: 150
    },
    {
      alignment: 'right',
      caption: 'Amount Processed',
      dataField: 'gl_chg_amt',
      dataType: 'number',
      csvDataType: 'currency',
      cellTemplate: 'invoiceCurrencyTemplate',
      format: {
        type: 'currency',
        precision: 2
      },
      width: 150
    },
    {
      alignment: 'right',
      caption: 'Charge Qty',
      dataField: 'header.charges_count',
      dataType: 'number',
      width: 150,

    }
  ];
}




