import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';
import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";
import {UpdateInvoiceDisputesQuery, UpdateInvoiceDisputesSiderSection} from "./invoice-disputes.actions";

const INVOICE_DISPUTE_STATE_TOKEN = new StateToken<InvoiceDisputesStateModel>('invoice_disputes');

export interface InvoiceDisputesStateModel extends QueryableStateModel<any> {

}

@State<InvoiceDisputesStateModel>({
  name: INVOICE_DISPUTE_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InvoiceDisputeState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  @Action(UpdateInvoiceDisputesQuery)
  // @ts-ignore
  updateInvoiceDisputesQuery(ctx: StateContext<InvoiceDisputesStateModel>, {options}): void {
    return this.updateQuery(ctx, {options});
  }

  @Action(UpdateInvoiceDisputesSiderSection)
  // @ts-ignore
  updateInvoiceDisputesSiderSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
