import { Injectable } from '@angular/core';

@Injectable()
export class ChartReloadService {

  charts: Array<any> = [];

  registerChart(chartObject: { id: string, chart: any }): void {
    const chartInArray = this.charts.filter(item => item.id === chartObject.id);

    if ( chartInArray.length ) {
      this.charts = this.charts.map(item => {
        if (item.id === chartObject.id) {
          return item = chartObject;
        }

        return item;
      });
    }
    else {
      this.charts = this.charts.concat(chartObject);
    }
  };

  repaintCharts(chartPalette: any): void {
    this.charts.forEach((item) => {
      if (item.chart) {
        item.chart.instance.option('palette', chartPalette);
      }
    })
  }

  clearChart(chartId: string): void {
    this.charts = this.charts.filter(item => item.id !== chartId);
  }

}
