import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';
import {
  UpdateInvoiceNoteContactQuery, UpdateInvoiceNoteContactSiderSection,
} from './invoice-note-contact.actions';
import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";

const INVOICE_NOTE_CONTACT_STATE_TOKEN = new StateToken<InvoiceNoteContactStateModel>('invoice_note_contact');

export interface InvoiceNoteContactStateModel extends QueryableStateModel<any> {

}

@State<InvoiceNoteContactStateModel>({
  name: INVOICE_NOTE_CONTACT_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InvoiceNoteContactState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  @Action(UpdateInvoiceNoteContactQuery)
  // @ts-ignore
  updateInvoiceNoteContactQuery(ctx: StateContext<InvoiceNoteContactStateModel>, {options}): void {
    return this.updateQuery(ctx, {options});
  }

  @Action(UpdateInvoiceNoteContactSiderSection)
  // @ts-ignore
  updateInvoiceNoteContactSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
