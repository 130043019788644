import { Injectable } from '@angular/core';
import { Range } from '../range';
import * as moment from 'moment';

@Injectable()
export class DateRangeFilterService {
  readonly RANGE_TYPES = {
    PRIOR: 'Prior',
    LAST: 'Last'
  };
  constructor() { }

  createRanges(items) {
    let rangesCreated = [];

    let current = new Date();

    items.map(item => {
      let current = new Date();
      let range = new Range();
      let parts = item.label.split(" ");
      let type = parts[0];
      let rangeValue = parts[1];
      let mon = parts[2];

      /* Ranges suppored */
      /* Prior or last. Number is optional. If there is no number, then its day, week or year.
      If there is number, then it is days, weeks or years. */
      /* Prior|Last (<number> days|weeks|months|years | day|week|month|year) */

      if (type === this.RANGE_TYPES.PRIOR) {
        if (!isNaN(rangeValue) && mon === 'months') {
          // Prior 3 months
          range.label = `${type} ${rangeValue} ${mon}`;
          range.from = moment().subtract(rangeValue, 'months').startOf('month').toDate();
          range.to = moment().subtract(1, 'months').endOf('month').toDate()
        } else if (rangeValue === 'month') {
          // Prior month
          range.label = `${type} ${rangeValue}`;
          range.from = moment().subtract(1, 'months').startOf('month').toDate(),
            range.to = moment().subtract(1, 'months').endOf('month').toDate()
        } else if (rangeValue === 'year') {
          // Prior year
          range.label = `${type} ${rangeValue}`;
          range.from = moment().subtract(1, 'years').startOf('year').toDate(),
            range.to = moment().subtract(1, 'years').endOf('year').toDate()
        } else if (!isNaN(rangeValue) && mon === 'years') {
          // Prior 2 years
          range.label = `${type} ${rangeValue} ${mon}`;
          range.from = moment().subtract(rangeValue, 'years').startOf('year').toDate();
          range.to = moment().subtract(1, 'years').endOf('year').toDate()
        }
      }

      if (type === this.RANGE_TYPES.LAST) {
        range.to = current;
        if (!isNaN(rangeValue) && mon === 'days') {
          // Last 30 days
          range.label = `${type} ${rangeValue} ${mon}`;
          range.from = moment().subtract(rangeValue, 'days').toDate();
        } else if (rangeValue === 'week') {
          // Last week
          range.label = `${type} ${rangeValue}`;
          range.from = moment().subtract(7, 'days').toDate();
        } else if (!isNaN(rangeValue) && mon === 'weeks') {
          // Last 3 weeks
          range.label = `${type} ${rangeValue} ${mon}`;
          range.from = moment().subtract(rangeValue, 'week').toDate();
        } else if (!isNaN(rangeValue) && mon === 'months') {
          // Last 2 months
          range.label = `${type} ${rangeValue} ${mon}`;
          range.from = moment().subtract(rangeValue, 'month').toDate();
        } else if (!isNaN(rangeValue) && mon === 'years') {
          // Last 2 years
          range.label = `${type} ${rangeValue} ${mon}`;
          range.from = moment().subtract(rangeValue, 'year').toDate();
        } else if (rangeValue === 'year') {
          // Last year
          range.label = `${type} ${rangeValue}`;
          range.from = moment().subtract(1, 'year').toDate();
        }
      }
      rangesCreated.push(range)
    })

    return rangesCreated;
  }

  getDefaultRanges() {
    return [
      {
        label: 'Today',
        from: moment().startOf('day').toDate(),
        to: moment().startOf('day').add(1, 'days').toDate()
      },
      {
        label: 'This week',
        from: moment().startOf('week').toDate(),
        to: moment().endOf('week').toDate()
      },
      {
        label: 'Previous Week',
        from: moment().subtract(1, 'weeks').startOf('week').toDate(),
        to: moment().subtract(1, 'weeks').endOf('week').add(1, 'days').toDate()
      },
      {
        label: 'Last 30 Days',
        from: moment().subtract(30, 'days').startOf('day').toDate(),
        to: moment().endOf('day').toDate()
      },
      {
        label: 'This Month',
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate()
      },
      {
        label: 'Previous Month',
        from: moment().subtract(1, 'months').startOf('month').toDate(),
        to: moment().subtract(1, 'months').endOf('month').toDate()
      },
      {
        label: 'Previous 3 months',
        from: moment().subtract(3, 'months').startOf('month').toDate(),
        to: moment().subtract(1, 'months').endOf('month').toDate()
      },
      {
        label: 'Previous 6 months',
        from: moment().subtract(6, 'months').startOf('month').toDate(),
        to: moment().subtract(1, 'months').endOf('month').toDate()
      },
      {
        label: 'This Year',
        from: moment().startOf('year').toDate(),
        to: moment().endOf('year').toDate()
      },
      {
        label: 'Previous Year',
        from: moment().subtract(1, 'years').startOf('year').toDate(),
        to: moment().subtract(1, 'years').endOf('year').toDate()
      }
    ];
  }
}
