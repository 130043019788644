import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContractListComponent } from "./contract-list/contract-list.component";
import { ContractDetailsComponent } from "./contract-details/contract-details.component";
import { ContractScheduleDetailsComponent } from "./contract-schedule-details/contract-schedule-details.component";
import { ContractVendorInfoComponent } from "./contract-vendor-info/contract-vendor-info.component";
import { SvcOrderDetailsComponent } from "./svc-order-details/svc-order-details.component";
import { ContractSummaryComponent } from "./contract-details/contract-details-summary/contract-details-summary.component";
import { ContractSchedulesComponent } from "./contract-details/contract-details-schedules/contract-details-schedules.component";
import { ContractDetailsResolver } from "./core/contract-details.resolver";
import { ContractTermsConditionsComponent } from "./contract-details/contract-details-terms-conditions/contract-details-terms-conditions.component";
import { ContractInventoryComponent } from "./contract-details/contract-details-inventory/contract-details-inventory.component";
import { ContractAttachmentsComponent } from "./contract-details/contract-details-attachments/contract-details-attachments.component";
import { ContractRatesComponent } from "./contract-details/contract-details-rates/contract-details-rates.component";
import { SvcOrderDetailsSummaryComponent } from "./svc-order-details/svc-order-details-summary/svc-order-details-summary.component";
import { SvcOrderDetailsServicesComponent } from "./svc-order-details/svc-order-details-services/svc-order-details-services.component";
import { SvcOrderDetailsTermsConditionsComponent } from "./svc-order-details/svc-order-details-terms-conditions/svc-order-details-terms-conditions.component";
import { SvcOrderDetailsAttachmentsComponent } from "./svc-order-details/svc-order-details-attachments/svc-order-details-attachments.component";
import { SvcOrderDetailsResolver } from "./core/svc-order-details.resolver";
import { ContractScheduleResolver } from "./core/contract-schedule.resolver";
import { ContractScheduleSummaryComponent } from "./contract-schedule-details/contract-schedule-summary/contract-schedule-summary.component";
import { ContractScheduleTermsConditionsComponent } from "./contract-schedule-details/contract-schedule-terms-conditions/contract-schedule-terms-conditions.component";
import { ContractScheduleAttachmentsComponent } from "./contract-schedule-details/contract-schedule-attachments/contract-schedule-attachments.component";
import { ContractScheduleRatesComponent } from "./contract-schedule-details/contract-schedule-rates/contract-schedule-rates.component";
import { CanDeactivateGuard } from "../core/guard/can-deactivate-guard.searvice";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: ContractListComponent,
      },
      {
        path: ":id/info",
        component: ContractVendorInfoComponent,
      },
      {
        path: ":id/show",
        component: ContractDetailsComponent,
        resolve: {
          contract: ContractDetailsResolver,
        },
        children: [
          { path: "summary", component: ContractSummaryComponent },
          { path: "schedules", component: ContractSchedulesComponent },
          {
            path: "terms-conditions",
            component: ContractTermsConditionsComponent,
          },
          { path: "inventory", component: ContractInventoryComponent },
          { path: "attachments", component: ContractAttachmentsComponent },
          {
            path: "rates",
            component: ContractRatesComponent,
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: "**",
            redirectTo: "summary",
          },
        ],
      },
      {
        path: ":id/schedule/:scheduleId/show",
        component: ContractScheduleDetailsComponent,
        runGuardsAndResolvers: "always",
        resolve: {
          schedule: ContractScheduleResolver,
        },
        children: [
          { path: "summary", component: ContractScheduleSummaryComponent },
          {
            path: "terms-conditions",
            component: ContractScheduleTermsConditionsComponent,
          },
          {
            path: "attachments",
            component: ContractScheduleAttachmentsComponent,
          },
          { 
            path: "rates", 
            component: ContractScheduleRatesComponent,
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: "**",
            redirectTo: "summary",
          },
        ],
      },
      {
        path: ":id/order/show",
        component: SvcOrderDetailsComponent,
        resolve: {
          svc_order: SvcOrderDetailsResolver,
        },
        children: [
          { path: "summary", component: SvcOrderDetailsSummaryComponent },
          { path: "services", component: SvcOrderDetailsServicesComponent },
          {
            path: "terms-conditions",
            component: SvcOrderDetailsTermsConditionsComponent,
          },
          {
            path: "attachments",
            component: SvcOrderDetailsAttachmentsComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ContractRoutingModule {}
