
import {of as observableOf, Observable} from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import {Component, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { ContactService } from '../core/contact.service';
import { ContactManageDialogComponent } from '../shared/contact-manage-dialog/contact-manage-dialog.component';
import { Contact } from '../core/contact';
import { DialogService } from '../../shared/dialog/dialog.service';
import { AlertService } from '../../shared/alert/alert.service';
import { ContactQuery } from '../core/contact.query';
import { PageListComponent } from '../../core/page-list.component';
import { ContactGridService } from '../core/contact-grid.service';
import { PageContext } from '../../core/page.context';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { DataLockDialogService } from '../../core/data-lock/data-lock-dialog.service';
import { ContactFilterService } from '../core/contact-filter.service';
import { GridService } from '../../shared/grid/grid.service';


@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
})
export class ContactListComponent extends PageListComponent implements AfterViewInit, OnInit {
  public query: ContactQuery = new ContactQuery({
    orderBy: [['last_name', 'ASC'], ['first_name', 'ASC']]
  });

  public contacts: Array<Contact>;
  public totalContacts: number;
  public selection: Contact;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public sorting: any[][];

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'contact';
  readonly CORE_MODULE: string = 'core';

  constructor(public contactService: ContactService,
    public dialogService: DialogService,
    public alertService: AlertService,
    public settingsService: UserSettingsService,
    public contactGridService: ContactGridService,
    public loaderService: LoaderService,
    public dataLockDialogService: DataLockDialogService,
    public contactFilterService: ContactFilterService) {
    super(new PageContext({
      name: 'app.contact.contact-list',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  loadData(query?: ContactQuery) {
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.contactService.findAllList(query)
      .subscribe((result) => {
        this.contacts = result.items;
        this.query.total = result.total;
        this.selectFirstRow();
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      },
        (error) => {
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        });
  }

  showDetails() {
    this.contactService.findById(this.selection.id).subscribe((contact) => {
      if (contact) {
        this.dialogService.open(ContactManageDialogComponent, {
          contact: contact,
          viewOnly: true
        }, { width: '1150px' })
          .afterClosed()
          .subscribe((result) => {
            this.afterModalClosed(result, contact);
          });
      }
    });
  }

  afterModalClosed(result, item) {
    if (result) {
      if (result.cancelByTimer || result.status == 2) {
        return this.alertService.success('', this.coreMessages.get('TIMER_EXPIRED'));
      } else if (result.deleted) {
        this.loadData(this.query);
        this.selectFirstRow();
        setTimeout(() => {
          this.alertService.snackBar.dismiss();
          this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
        }, 255);
      } else if (result.id) {
        Object.assign(item, result);
        this.refresh();
        this.dataGrid.instance.repaint();
        this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
      }
    }
  }

  refresh() {
    this.loadData(this.query);
  }

  filterData(query: ContactQuery) {
    this.query = query;
    this.loadData(query);
  }

  clearFilter() {
    this.query.where = null;
    this.loadData(this.query);
  }

  csv() {
    const {
      contactService,
      query
    } = this;

    const fields = this.contactGridService.csvMap();

    contactService.exportToCSV(
      'contacts',
      {
        fields,
        query
      }
    );
  }

  newContact() {
    this.dialogService.open(ContactManageDialogComponent, {
      contact: new Contact({})
    }, { width: '1150px' })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.refresh();
          this.alertService.success('', this.messages.get('CREATE_SUCCESS'));
        }
      });
  }

  editContact(contact) {
    this.dialogService.edit(
      ContactManageDialogComponent,
      {
        contact: this.contactService.findByIdForEdit(contact.id)
      }, { width: '1150px' })
      .subscribe((items: any) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(mergeMap(x => x.afterClosed() ))
            .subscribe((result) => {
              this.afterModalClosed(result, contact);
            })
        }
      })
  }

  onSelectionChanged(row) {
    this.selection = <Contact>row.selectedRowsData[0];
  }

  ngOnInit() {
    this._init();
  }

  ngAfterViewInit(): void {
    this.contactGridService.create(this.dataGrid.instance, {});
    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.contactGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.contactGridService;
  }

  getDefaultSort(): any {
    return [['last_name', 'ASC'], ['first_name', 'ASC']];
  }
}
