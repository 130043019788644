<div class="custom-fields-container">
  <mat-toolbar class="site-navigation">
    <div class="custom-fields-box-header">CUSTOM ATTRIBUTES</div>
    <button
      mat-icon-button
      type="button"
      *ngIf="showStar"
      [ngClass]="{ on: onlyRequiredCustomFields }"
      (click)="toggleReqFields()"
      [disabled]="categories && categories.length === 0"
    >
      <ca-icon
        [key]="'REQUIRED_FIELDS'"
        [innerClass]="'custom-icon'"
        [tooltip]="'Required Fields'"
      ></ca-icon>
    </button>
  </mat-toolbar>

  <div class="custom-fields-box" style="float: left" [formGroup]="form">
    <div
      *ngIf="categories && categories.length === 0"
      class="custom-fields-no-data"
    >
      No Data
    </div>
    <mat-accordion displayMode="flat" multi class="mat-table">
      <div [@.disabled]="disableAnimation">
        <div *ngIf="showCategory">
          <mat-expansion-panel
            *ngFor="let category of categories"
            [expanded]="false"
            #mep="matExpansionPanel"
            class="custom-fields-expansion-panel"
            [ngClass]="{
              'expansion-panel-body-bottom-line':
                mep.expanded && category !== categories[categories.length - 1]
            }"
          >
            <mat-expansion-panel-header
              *ngIf="category"
              class="custom-fields-expansion-panel-header"
              [collapsedHeight]="'35px'"
              [expandedHeight]="'35px'"
            >
              {{ category }}
            </mat-expansion-panel-header>
            <div
              *ngFor="let field of fields"
              class="caGap caGapRight custom-fields"
            >
              <mat-form-field
                caInput=""
                *ngIf="
                  field.type_id === 'number_field' &&
                  field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value &&
                      onlyRequiredCustomFields) ||
                    !onlyRequiredCustomFields)
                "
                [style.width.px]="field.width"
              >
                <input
                  matInput
                  [placeholder]="field.placeholder"
                  type="number"
                  [formControlName]="field.field_name"
                  [required]="
                    field.is_required ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value)
                  "
                  [minlength]="field.min_value"
                  [maxlength]="field.max_value"
                  rows="1"
                />
              </mat-form-field>

              <mat-form-field
                caInput=""
                *ngIf="
                  field.type_id === 'text_field' &&
                  field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value &&
                      onlyRequiredCustomFields) ||
                    !onlyRequiredCustomFields)
                "
                [style.width.px]="field.width"
              >
                <input
                  matInput
                  [placeholder]="field.placeholder"
                  type="text"
                  [formControlName]="field.field_name"
                  [required]="
                    field.is_required ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value)
                  "
                  [minlength]="field.min_value"
                  [maxlength]="field.max_value"
                  [pattern]="field.pattern"
                  rows="1"
                />
              </mat-form-field>

              <mat-form-field
                caInput=""
                *ngIf="
                  field.type_id === 'textarea_field' &&
                  field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value &&
                      onlyRequiredCustomFields) ||
                    !onlyRequiredCustomFields)
                "
                [style.width.px]="field.width"
              >
                <textarea
                  matInput
                  [placeholder]="field.placeholder"
                  type="text"
                  [formControlName]="field.field_name"
                  [required]="
                    field.is_required ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value)
                  "
                  [minlength]="field.min_value"
                  [maxlength]="field.max_value"
                  rows="1"
                >
                </textarea>
              </mat-form-field>

              <mat-form-field
                caInput=""
                *ngIf="
                  field.type_id === 'email' &&
                  field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value &&
                      onlyRequiredCustomFields) ||
                    !onlyRequiredCustomFields)
                "
                [style.width.px]="field.width"
              >
                <input
                  matInput
                  [placeholder]="field.placeholder"
                  [required]="
                    field.is_required ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value)
                  "
                  class="form-control"
                  type="email"
                  [formControlName]="field.field_name"
                  [pattern]="field.pattern"
                />
                <mat-hint
                  *ngIf="
                    (form.hasError('pattern', [field.field_name]) ||
                      this.form.get(field.field_name).invalid) &&
                    form.controls[field.field_name].touched
                  "
                  class="error-class"
                >
                  {{ VALIDATION.EMAIL_ERROR }}
                </mat-hint>
              </mat-form-field>

              <mat-form-field
                caInput=""
                *ngIf="
                  field.type_id === 'custom_dropdown' &&
                  field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value &&
                      onlyRequiredCustomFields) ||
                    !onlyRequiredCustomFields)
                "
                [style.width.px]="field.width"
              >
                <mat-select
                  [placeholder]="field.placeholder"
                  [formControlName]="field.field_name"
                  [required]="
                    field.is_required ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value)
                  "
                >
                  <mat-option
                    *ngIf="clearEnabled && form.get(field.field_name).value"
                    class="custom-mat-select-option"
                    (click)="handleClear(field.field_name)"
                  >
                    CLEAR
                  </mat-option>
                  <mat-option
                    *ngFor="let obj of field.dropdown_values"
                    [value]="obj.label"
                  >
                    {{ obj.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <ca-lookup-model-picker
                *ngIf="
                  field.type_id === 'lookup_dropdown' &&
                  field.category === category &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value &&
                      onlyRequiredCustomFields) ||
                    !onlyRequiredCustomFields)
                "
                [style.width.px]="field.width"
                clearEnabled="true"
                caInput
                #lookupPicker
                [required]="
                  field.is_required ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value)
                "
                [searchEnabled]="false"
                [lookupModel]="field.lookup_table"
                name="lookup_id"
                [formControlName]="field.field_name"
                [pickerForm]="form"
                [placeholder]="field.placeholder"
              >
              </ca-lookup-model-picker>

              <div [style.width.px]="field.width">
                <ca-date-picker
                  *ngIf="
                    field.type_id === 'date' &&
                    field.category === category &&
                    ((field.is_required && onlyRequiredCustomFields) ||
                      (field.required_dependency_prop &&
                        getObjectProperty(
                          field.required_dependency_obj === 'order'
                            ? customField
                            : customFieldService,
                          field.required_dependency_prop
                        ) === field.required_dependency_value &&
                        onlyRequiredCustomFields) ||
                      !onlyRequiredCustomFields)
                  "
                  caInput
                  [required]="
                    field.is_required ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value)
                  "
                  [minDate]="field.min_value"
                  [maxDate]="field.max_value"
                  [placeholder]="field.placeholder"
                  [formControlName]="field.field_name"
                >
                </ca-date-picker>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
        <div
          *ngIf="!showCategory"
          class="custom-fields-section"
          fxLayout="column"
        >
          <div *ngFor="let field of fields" class="caGap custom-fields">
            <mat-form-field
              caInput=""
              *ngIf="
                field.type_id === 'number_field' &&
                ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value &&
                    onlyRequiredCustomFields) ||
                  !onlyRequiredCustomFields)
              "
              [style.width.%]="field.width"
            >
              <input
                matInput
                [placeholder]="field.placeholder"
                type="number"
                [formControlName]="field.field_name"
                [required]="
                  field.is_required ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value)
                "
                [minlength]="field.min_value"
                [maxlength]="field.max_value"
                rows="1"
              />
            </mat-form-field>

            <mat-form-field
              caInput=""
              *ngIf="
                field.type_id === 'text_field' &&
                ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value &&
                    onlyRequiredCustomFields) ||
                  !onlyRequiredCustomFields)
              "
              [style.width.%]="field.width"
            >
              <input
                matInput
                [placeholder]="field.placeholder"
                type="text"
                [formControlName]="field.field_name"
                [required]="
                  field.is_required ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value)
                "
                [minlength]="field.min_value"
                [maxlength]="field.max_value"
                [pattern]="field.pattern"
                rows="1"
              />
            </mat-form-field>

            <mat-form-field
              caInput=""
              *ngIf="
                field.type_id === 'textarea_field' &&
                ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value &&
                    onlyRequiredCustomFields) ||
                  !onlyRequiredCustomFields)
              "
              [style.width.%]="field.width"
            >
              <textarea
                matInput
                [placeholder]="field.placeholder"
                type="text"
                [formControlName]="field.field_name"
                [required]="
                  field.is_required ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value)
                "
                [minlength]="field.min_value"
                [maxlength]="field.max_value"
                rows="1"
              >
              </textarea>
            </mat-form-field>

            <mat-form-field
              caInput=""
              *ngIf="
                field.type_id === 'email' &&
                ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value &&
                    onlyRequiredCustomFields) ||
                  !onlyRequiredCustomFields)
              "
              [style.width.%]="field.width"
            >
              <input
                matInput
                [placeholder]="field.placeholder"
                [required]="
                  field.is_required ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value)
                "
                class="form-control"
                type="email"
                [formControlName]="field.field_name"
                [pattern]="field.pattern"
              />
              <mat-hint
                *ngIf="
                  (form.hasError('pattern', [field.field_name]) ||
                    this.form.get(field.field_name).invalid) &&
                  form.controls[field.field_name].touched
                "
                class="error-class"
              >
                {{ VALIDATION.EMAIL_ERROR }}
              </mat-hint>
            </mat-form-field>

            <mat-form-field
              caInput=""
              *ngIf="
                field.type_id === 'custom_dropdown' &&
                ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value &&
                    onlyRequiredCustomFields) ||
                  !onlyRequiredCustomFields)
              "
              [style.width.%]="field.width"
            >
              <mat-select
                [placeholder]="field.placeholder"
                [formControlName]="field.field_name"
                [required]="
                  field.is_required ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value)
                "
              >
                <mat-option
                  *ngIf="clearEnabled && form.get(field.field_name).value"
                  class="custom-mat-select-option"
                  (click)="handleClear(field.field_name)"
                >
                  CLEAR
                </mat-option>
                <mat-option
                  *ngFor="let obj of field.dropdown_values"
                  [value]="obj.label"
                >
                  {{ obj.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <ca-lookup-model-picker
              *ngIf="
                field.type_id === 'lookup_dropdown' &&
                ((field.is_required && onlyRequiredCustomFields) ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value &&
                    onlyRequiredCustomFields) ||
                  !onlyRequiredCustomFields)
              "
              [style.width.%]="field.width"
              clearEnabled="true"
              caInput
              #lookupPicker
              [required]="
                field.is_required ||
                (field.required_dependency_prop &&
                  getObjectProperty(
                    field.required_dependency_obj === 'order'
                      ? customField
                      : customFieldService,
                    field.required_dependency_prop
                  ) === field.required_dependency_value)
              "
              [searchEnabled]="false"
              [lookupModel]="field.lookup_table"
              name="lookup_id"
              [formControlName]="field.field_name"
              [pickerForm]="form"
              [placeholder]="field.placeholder"
            >
            </ca-lookup-model-picker>

            <div [style.width.%]="field.width">
              <ca-date-picker
                *ngIf="
                  field.type_id === 'date' &&
                  ((field.is_required && onlyRequiredCustomFields) ||
                    (field.required_dependency_prop &&
                      getObjectProperty(
                        field.required_dependency_obj === 'order'
                          ? customField
                          : customFieldService,
                        field.required_dependency_prop
                      ) === field.required_dependency_value &&
                      onlyRequiredCustomFields) ||
                    !onlyRequiredCustomFields)
                "
                caInput
                [required]="
                  field.is_required ||
                  (field.required_dependency_prop &&
                    getObjectProperty(
                      field.required_dependency_obj === 'order'
                        ? customField
                        : customFieldService,
                      field.required_dependency_prop
                    ) === field.required_dependency_value)
                "
                [minDate]="field.min_value"
                [maxDate]="field.max_value"
                [placeholder]="field.placeholder"
                [formControlName]="field.field_name"
                [disabled]= "this.form.controls[field.field_name].disabled"
              >
              </ca-date-picker>
            </div>
          </div>
        </div>
      </div>
    </mat-accordion>
  </div>
</div>
