<app-inventory-details-toolbar
  [inventory]="inventory"
  [isDocumentAction]="true"
  [isAddPricingDisabled]="true"
  [isAddPricingHidden]="true"
  [isPricingDocumentAction]="false"
  [documentSelection]="documentSelection"
  [isAddGLHidden]="false"
  [isAddGLDisabled]="true"
  [isCreateOrderHidden]="false"
  [isCreateOrderDisabled]="true"
  [isEditInventoryDisabled]="true"
  [isFilterDisabled]="true"
  [isNotesOn]="($activeSiderSection | async) === SECTIONS.NOTES_SECTION_NAME"
  [isHistoryOn]="($activeSiderSection | async) === SECTIONS.HISTORY_SECTION_NAME"
  [notesCount]="notesCount"
  
  (uploadRequested)="upload()"
  (toggleNotesRequested)="toggleNotes()"
  (toggleHistoryRequested)="toggleHistory()"
  (downloadRequested)="download()"
  (deleteDocumentRequested)="deleteDocument()"
  (backRequested)="back()">
</app-inventory-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-inventory-details-tabs [activeTabIndex]="7"></app-inventory-details-tabs>
  <!-- TODO: Add tabChanged property -->
  <div class="tabs-body grid-container">
    <app-document-manager
                          [folder]="DOCUMENT_INFO.FOLDER"
                          [parentType]="selectedEntity?.type"
                          [subfolder]="DOCUMENT_INFO.SUBFOLDER"
                          [masterId]="selectedMaster?.id"
                          [masterType]="selectedMaster?.type"
                          [entityType]="selectedEntity?.type"
                          [entityId]="selectedEntity?.id"
                          (docAdd)="history.refreshList()"
                          (docSelected)="docSelected($event)"
                          (docDelete)="history.refreshList()"
                          #documentsManager
                          style="height: 100%">
    </app-document-manager>
  </div>
  <mat-sidenav #panelSide
               [mode]="'side'"
               position="end"
               [opened]="false">
    <ca-history
      #history
      [entityId]="inventory?.id"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
      [entityType]="'inventory'">
    </ca-history>

    <ca-notes
      [entityId]="inventory?.id"
      [entityType]="SYSTEM_MODULE.INVENTORY"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
      (countChanged)="onNotesCountChanged($event)">
    </ca-notes>  </mat-sidenav>
</mat-sidenav-container>
