export const contactFilterContext = {
  filters: [
    {
      field: 'first_name',
      label: 'First Name',
      type: 'string',
      index: 1,
      visible: true
    },
    {
      field: 'last_name',
      label: 'Last Name',
      type: 'string',
      index: 2,
      visible: true
    },
    {
      field: 'contact_type.value',
      label: 'Type',
      type: 'lookup',
      lookupProviderName: 'contactType',
      index: 3,
      visible: true
    },
    {
      field: 'vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 5,
      visible: true
    },
    {
      field: 'customer.company',
      label: 'Customer ',
      type: 'lookup',
      lookupProviderName: 'customer',
      index: 6,
      visible: true
    },
    {
      field: 'office_phone',
      label: 'Phone',
      type: 'string',
      exactMatch: false,
      matchCase: false,
      index: 7,
      visible: true
    },
    {
      field: 'address',
      label: 'Address',
      editor: 'address',
      addressFields: {
        address1: {
          label: 'Address',
          field: 'address.address1'
        },
        city: {field: 'address.city'},
        subdivision: {
          field: 'address.subdivision_id',
          lookupProviderName: 'subdivision',
        },
        postal_code: {field: 'address.postal_code'}
      },
      index: 8,
      visible: true
    },
  ]
};
