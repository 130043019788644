import {Injectable, EventEmitter} from '@angular/core';

@Injectable()
export class ThemeSettingsService extends EventEmitter<any> {

  constructor() {
    super();
  }

}
