
import {takeUntil} from 'rxjs/operators';
import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { QuotingService } from '../core/quoting.service';
import { QoutingRequestManageDialogComponent } from '../shared/qouting-offer-manage-dialog/qouting-offer-manage-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";
import { QuotingDetailsRequestsGridService } from '../core/quoting-details-offers-grid.service';
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { DialogService } from '../../shared/dialog/dialog.service';

@Component({
  selector: 'app-quoting-details-offers',
  templateUrl: './quoting-details-offers.component.html',
})

export class QuotingDetailsRequestsComponent extends PageListComponent implements AfterViewInit, OnDestroy {

  public columns: Array<any>;
  public service: any;
  readonly LOCALS = {
    DELETE_SUCCESS: 'Request has been deleted successfully.',
    UPDATE_SUCCESS: 'Request has been updated successfully.',
    CREATE_SUCCESS: 'Request has been created successfully.',
    DELETE_FAIL: 'Failed to delete offer'
  };

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  constructor(
    public quotingDetailsRequestsGridService: QuotingDetailsRequestsGridService,
    public quotingService: QuotingService,
    public route: ActivatedRoute,
    public router: Router,
    public sanitizer: DomSanitizer,
    public settingsService: UserSettingsService,
    public dialogService: DialogService
  ) {
    super(new PageContext({
      name: 'app.quoting.quoting-details-offers',
      settings: settingsService
    }));
  }

  ngOnInit() {
    this.route.params.pipe(
      takeUntil(this.destroy$))
      .subscribe((params: Params) => {
        let quoteServiceId = params['serviceId'];
        this.loadData(quoteServiceId);
      });
    this._init();
  }

  addRequest() {
    this.dialogService.open(QoutingRequestManageDialogComponent, {
      offer: {}
    });
  }

  updateRequest() {
    this.dialogService.open(QoutingRequestManageDialogComponent, {
      quote: {}
    });
    /* .afterClosed()
     .subscribe((result) => {
       this.refresh();
       if (result) {
         this.loadData(null);
         this.alertService.success("", this.LOCALS.CREATE_SUCCESS);
       }
     });*/
  }

  _init() {
    this.columns = this.quotingDetailsRequestsGridService.columns();
    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.option('columns', this.columns);
    }
  }

  ngAfterViewInit(): void {
    this.quotingDetailsRequestsGridService.create(this.dataGrid.instance, {});
    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  public loadData(id: number) {
    this.quotingService.findByServiceId(id)
      .then((service: any) => {
        this.service = service;
      });
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer == '') {
      history.back();
    }
    else {
      this.router.navigate(['/quotes']);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
