import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { ContractInstance } from './contract-instance';
import { Restangular } from 'ngx-restangular';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ContractInstanceService extends BaseService<ContractInstance> {
  section: string = 'section';

  constructor(public restangular: Restangular,
              public http: HttpClient,
              private entityLock: EntityLockService) {
    super('contract-instance', restangular, entityLock);
  }

  
}
