import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class WizardAllVendorsGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super('app.quoting.wizard-all-vendors-grid', userSettings);
  }

  columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return [
      {
        caption: 'Vendor',
        dataField: 'name',
        allowEditing: false,
        //width: 150
      },
      {
        caption: 'Method',
        headerCellTemplate: 'methodHeaderTemplate',
        cellTemplate: 'methodCellTemplate',
        dataField: 'method',
        alignment: 'left',
        editCellTemplate: 'methodEditCellTemplate'
        //width: 150
      },
      {
        caption: 'Hub Location',
        headerCellTemplate: 'hubLocationHeaderTemplate',
        cellTemplate: 'hubLocationTemplate',
        dataField: 'hub.name',
        editCellTemplate: 'hubEditCellTemplate'
        //width: 150
      },
    ];
  }
}
