import { HISTORY_MESSAGE_ENUM } from './history-message.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caHistoryAction'
})
export class HistoryPipe implements PipeTransform {

  constructor() {

  }

  transform(value: any, sign?: string): any {
    if (!value) {
      return value;
    }

    switch (value) {
      case 'update':
        return HISTORY_MESSAGE_ENUM.UPDATE.message;
      case 'create':
        return HISTORY_MESSAGE_ENUM.CREATE.message;
      case 'destroy':
        return HISTORY_MESSAGE_ENUM.DELETE.message;
      case 'auto_approver_enabled':
        return HISTORY_MESSAGE_ENUM.AUTO_APPROVER_ENABLED.message;
      case 'auto_approver_disabled':
        return HISTORY_MESSAGE_ENUM.AUTO_APPROVER_DISABLED.message;
      case 'auto_approver_update':
        return HISTORY_MESSAGE_ENUM.AUTO_APPROVER_UPDATE.message;
      case 'status_update':
        return HISTORY_MESSAGE_ENUM.STATUS_UPDATE.message;
      case 'delete':
        return HISTORY_MESSAGE_ENUM.SERVICE_DELETED.message;
    }
  }

}
