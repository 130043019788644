const versionCompare = (version1, version2) =>
{
  // vnum stores each numeric
  // part of version
  let vnum1: any = 0, vnum2:any = 0;

  // loop until both string are
  // processed
  for (let i = 0, j = 0; (i < version1.length
    || j < version2.length);) {
    // storing numeric part of
    // version 1 in vnum1
    while (i < version1.length && version1[i] != '.') {
      vnum1 = vnum1 * 10 + (version1[i] - 0);
      i++;
    }

    // storing numeric part of
    // version 2 in vnum2
    while (j < version2.length && version2[j] != '.') {
      vnum2 = vnum2 * 10 + (version2[j] - 0);
      j++;
    }

    if (vnum1 > vnum2)
      return 1;
    if (vnum2 > vnum1)
      return -1;

    // if equal, reset variables and
    // go for next numeric part
    vnum1 = vnum2 = 0;
    i++;
    j++;
  }
  return 0;
};
const isReleaseVersionGreaterThenAvailableVersion = (currentVersion, availableVersion) => {
  return versionCompare(currentVersion, availableVersion) >= 0;

};

const getReleaseNumberOnly = (release) => {
  return release.replace(/[^0-9.]/g, '');
};

export {versionCompare, isReleaseVersionGreaterThenAvailableVersion, getReleaseNumberOnly};
