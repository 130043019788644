import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import {DialogService} from "../../../shared/dialog/dialog.service";
import {PageManageDialogComponent} from "../../../core/page-manage-dialog.component";
import { AlertService } from 'app/shared/alert/alert.service';

@Component({
  selector: 'app-order-reset-to-new-manage',
  templateUrl: './order-reset-to-new-manage.component.html',
})
export class OrderResetToNewManageComponent extends PageManageDialogComponent implements OnInit {
  public form: FormGroup;
  note: string;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "order";

  constructor(public formBuilder: FormBuilder,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<OrderResetToNewManageComponent>,
              public alertService: AlertService) {
    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
      this.form = this.formBuilder.group({
        note: ["", Validators.minLength(20)]
      });
  }

  reset({ value, valid }: { value: any, valid: boolean }) {
    if (valid) {
      this.dialogRef.close({
        reset: true,
        note: value.note
      });
    } else {
      this.alertService.error('', this.messages.get('CHARACTER_LENGTH_ERROR'));
    }
  }

  cancel() {
    this.closeDialog();
  }
}
