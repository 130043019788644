import { Injectable } from '@angular/core';

@Injectable()
export class NavStateService {

  public navState: boolean = true;

  constructor() { }

  setNavState = (navState: boolean) => {
    this.navState = navState;
  }

  isNavOpen = () => {
    return this.navState;
  }

}
