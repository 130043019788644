import {Pipe, PipeTransform} from "@angular/core";
@Pipe({
  name: 'columnCaption'
})
export class ColumnCaptionPipe implements PipeTransform {
  transform(value: string, args?: string[]): string {
    if (!value) return value;

    if (value.indexOf('\u2191') !== -1) {
      value = value.replace('\u2191', '');
    }
    if (value.indexOf('\u2193') !== -1) {
      value = value.replace('\u2193', '');
    }

    return value;
  }
}
