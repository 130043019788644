import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ThemeService } from './theme.service';
import { ChartService } from '../../shared/chart/chart.service';

@Injectable()
export class ThemesResolve implements Resolve<any> {

  constructor(
    public themeService: ThemeService,
  ) { }

  resolve(route: ActivatedRouteSnapshot) {

    return new Promise<void>((resolve, reject) => {

      return this.themeService.findAll()
        .subscribe(
          result => {
            const appThemes = result.items.filter(item => item.is_app);
            const chartThemes = result.items.filter(item => !item.is_app);

            ThemeService.setAppThemes(appThemes);
            ChartService.setChartThemes(chartThemes);

            resolve();
          }
        );

    });


  }
}
