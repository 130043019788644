<div *ngIf="lookup">
  <mat-toolbar color="accent" mat-scroll-shrink>

    <span class="sneak-peek-title">{{ lookup?.value }}</span>

    <span class="example-fill-remaining-space"></span>
    <button mat-icon-button (click)="closeSide()">
      <ca-icon [key]="'CLOSE'"></ca-icon>
    </button>
  </mat-toolbar>
  <div class="ca-card ca-card-sneak-peek">
    <mat-card caCard>
      <mat-card-content>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Group</label>
            <p>
              {{ lookup?.display_name }}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Value</label>
            <p>
              {{ lookup?.value }}
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div *ngIf="!lookup" class="ca-empty-state-container">
    <p class="small-div-message">No item selected.</p>
</div>


