import { FlexLayoutModule } from '@angular/flex-layout';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "app/material-barrel/material-barrel.module";
import { CoreModule } from "../core/core.module";
import {VendorService} from "./core/vendor.service";
import {VendorEntityService} from "./core/vendor-entity.service";
import {VendorManageDialogComponent} from './shared/vendor-manage-dialog/vendor-manage-dialog.component';
import {VendorListComponent} from './vendor-list/vendor-list.component';
import {VendorComponent} from './vendor.component';
import {VendorRoutingModule} from "./vendor-routing.module";
import {ReactiveFormsModule, FormsModule} from "@angular/forms";
import {VendorGridService} from "./core/vendor-grid.service";
import {VendorFilterService} from "./core/vendor-filter.service";
import { VendorSneakPeekComponent } from './shared/vendor-sneak-peek/vendor-sneak-peek.component';
import {DxDataGridModule} from "devextreme-angular/ui/data-grid";
import {SharedModule} from "../shared/shared.module";
import { VendorEntityPickerComponent } from './shared/vendor-entity-picker/vendor-entity-picker.component';
import { VendorEntityManageDialogComponent } from './shared/vendor-entity-manage-dialog/vendor-entity-manage-dialog.component';
import { MoveEntityDialogComponent } from './shared/move-entity-dialog/move-entity-dialog.component';
import { DictionaryService } from '../dictionary/core/dictionary.service';
import { CaVendorPickerComponent } from './shared/vendor-picker/ca-vendor-picker.component';

@NgModule({
  imports: [
    CommonModule,
    VendorRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DxDataGridModule,
    SharedModule,
    CoreModule,
    FlexLayoutModule
  ],
  exports: [
    CaVendorPickerComponent,
    VendorEntityPickerComponent
  ],
  declarations: [
    VendorManageDialogComponent,
    VendorListComponent,
    VendorComponent,
    VendorSneakPeekComponent,
    VendorEntityPickerComponent,
    VendorEntityManageDialogComponent,
    MoveEntityDialogComponent,
    CaVendorPickerComponent
  ],
  entryComponents: [
    VendorManageDialogComponent,
    VendorEntityManageDialogComponent,
    MoveEntityDialogComponent
  ],
  providers: [
    VendorService,
    VendorEntityService,
    VendorGridService,
    VendorFilterService,
    DictionaryService
  ]
})
export class VendorModule {
}
