import {Component, EventEmitter, Input, Output} from '@angular/core';

import {InvoiceCharge} from '../../core/invoice-charge';

@Component({
  selector: 'app-charge-sneak-peek',
  templateUrl: './charge-sneak-peek.component.html',
})
export class ChargeSneakPeekComponent {
  @Input() charge: InvoiceCharge;
  @Output() closeRequested = new EventEmitter();

  id: number;

  closeSide() {
    this.closeRequested.emit();
  }

  constructor() {
  }
}
