import {Injectable} from '@angular/core';

import {NavigationModel} from './navigation.model';
import {PermissionService} from '../../permissions/core/permission.service';
import {zip} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class NavigationService {
  readonly MODE_STANDARD = 'standard';
  mode: string = this.MODE_STANDARD;

  public toShowWireless;

  constructor(public permissionService: PermissionService) {
  }

  public GROUP = {
    DASHBOARD: 'Dashboard',
    ADMIN: {key: "admin", name: 'Admin'},
    COST_ANALYSIS: {key: 'cost_analysis', name: 'Cost Analysis'},
    APP_DATA: {key: 'app_data', name: 'App Data'},
    CONTRACTS: {key: 'contracts', name: 'Contracts'},
    GENERAL_LEDGER: {key: 'gl', name: 'GL'},
    INVENTORY: {key: 'inventory', name: 'Inventory'},
    ORDERS: {key: 'orders', name: 'Orders'},
    QUOTES: {key: 'quotes', name: 'Quotes'},
    REPORTING: {key: 'reports', name: 'Reports & Analysis'},
    WIRELESS: {key: 'wireless', name: 'Wireless'}
  };

  readonly entries = [
    new NavigationModel({
      title: 'Dashboard',
      icon: 'NAV_HOME',
      value: '/dash',
      unprotected: true,
      key: 'dashboard'
    }),
    new NavigationModel({
      group: this.GROUP.ADMIN,
      title: 'Admin',
      icon: 'NAV_ADMIN',
      key: "admin",
      items: [
        new NavigationModel({title: 'Users', value: '/user', key: 'users'}),
        new NavigationModel({title: 'Roles', value: '/roles', key: 'roles'}),
        new NavigationModel({title: 'Accounts', value: '/account', key: 'accounts'}),
        new NavigationModel({title: 'Dictionary', value: '/dictionary', key: 'dictionary'}),
        new NavigationModel({title: 'Auto Approver', value: '/auto-approver', key: 'auto-approver'}),
        new NavigationModel({title: 'Tax', value: '/taxes', key: 'taxes'}),
        new NavigationModel({title: 'Bulk Uploads', value: '/bulk-upload', key: 'bulk-upload'}),
        new NavigationModel({title: 'Intuit', value: '/intuit', key: 'intuit'}) // TODO: uncomment when logic is implemented
      ]
    }),
    new NavigationModel({
      group: this.GROUP.QUOTES,
      title: 'Quotes',
      icon: 'NAV_QUOTES',
      value: '/quotes',
      key: 'quotes'

    }),
    new NavigationModel({
      group: this.GROUP.ORDERS,
      title: 'Orders',
      icon: 'NAV_ORDERS',
      key: 'orders',
      items: [
        new NavigationModel({title: 'Orders', value: '/order', key: 'orders'}),
        new NavigationModel({title: 'Project', value: '/project', key: 'projects'})
      ]
    }),
    new NavigationModel({
      group: this.GROUP.INVENTORY,
      title: 'Inventory',
      icon: 'NAV_INVENTORY',
      key: "inventory",
      items: [
        new NavigationModel({title: 'Network', value: '/inventory', key: 'inventory'}),
        new NavigationModel({
          title: 'Customer',
          value: '/customer-inventory',
          key: "customer-inventory"
        }),
        new NavigationModel({title: 'DID/TFN', value: '/did-tfn', key: 'did-tfn'}),
        new NavigationModel({title: 'Recon', value: '/recon', key: 'recon'}),
        new NavigationModel({
          title: 'Customer Equipment',
          value: '/customer-equipment',
          key: 'customer-equipment',
        }),
        new NavigationModel({title: 'Network Hub', value: '/network-hub', key: 'network-hub'}),
        new NavigationModel({
          title: 'Mobile Devices',
          value: '/mobile-devices',
          key: 'mobile-devices',
          group: this.GROUP.WIRELESS
        }),
        new NavigationModel({
          title: 'Mobile Users',
          value: '/mobile-users',
          key: 'mobile-users',
          group: this.GROUP.WIRELESS
        }),
        new NavigationModel({
          title: 'Mobile Plans',
          value: '/mobile-plans',
          key: 'mobile-plans',
          group: this.GROUP.WIRELESS
        })
      ]
    }),
    new NavigationModel({
      group: this.GROUP.COST_ANALYSIS,
      title: 'Cost Analysis',
      icon: 'NAV_COST_ANALYSIS',
      key: "cost_analysis",
      items: [
        new NavigationModel({title: 'Invoices', value: '/invoice', key: 'invoices'}),
        new NavigationModel({title: 'Disputes', value: '/dispute', key: 'disputes'}),
        new NavigationModel({title: 'Charge Browser', value: '/charge', key: 'charges'}),
        new NavigationModel({title: 'Audits', value: '/audit/list', key: 'audits'}),
      ]
    }),
    new NavigationModel({
      group: this.GROUP.GENERAL_LEDGER,
      title: 'General Ledger',
      icon: 'NAV_GL',
      key:'general_ledger',
      items: [
        new NavigationModel({title: 'Codes', value: '/gl-code', key: 'gl'}),
        new NavigationModel({title: 'Strings', value: '/gl-string', key: 'gl'}),
        new NavigationModel({
          title: 'System Rules',
          value: '/gl-system-rules',
          key: 'gl'
        }),
        new NavigationModel({title: 'Custom Rules', value: '/gl-rule', key: 'gl'}),
        new NavigationModel({
          title: 'Rule Execution',
          value: '/gl-rule-execution',
          key: 'gl'
        }),
        new NavigationModel({title: 'Batch Output', value: '/gl-batch', key: 'gl'}),
        new NavigationModel({title: 'Batch Browser', value: '/batch-browser', key: 'gl'}),
        new NavigationModel({title: 'Subaccount Exclusion', value: '/subaccount-exclusion', key: 'gl'})
      ]
    }),
    new NavigationModel({
      group: this.GROUP.CONTRACTS,
      title: 'Contracts',
      icon: 'NAV_CONTRACTS',
      value: '/contract',
      key: 'contracts',
    }),
    new NavigationModel({
      title: 'Alerts',
      icon: 'NAV_ALERTS',
      value: '/alert',
      key: 'alerts'
    }),
    new NavigationModel({
      title: 'Suspense',
      icon: 'SUSPENSE_RECEIPT',
      value: '/suspense',
      key: 'suspense'
    }),
    new NavigationModel({
      group: this.GROUP.REPORTING,
      title: 'Reports & Analysis',
      icon: 'NAV_REPORTS',
      value: '/reports',
      key: 'reports'
    }),
    new NavigationModel({
      group: this.GROUP.APP_DATA,
      title: 'App Data',
      icon: 'NAV_APP_DATA',
      key: 'app_data',
      items: [
        new NavigationModel({title: 'Building', value: '/building', key: 'buildings'}),
        new NavigationModel({title: 'Contact', value: '/contact', key: 'contacts'}),
        new NavigationModel({title: 'Customer', value: '/customer', key: 'customers'}),
        new NavigationModel({title: 'Site', value: '/site', key: 'sites'}),
        new NavigationModel({title: 'Vendor', value: '/vendor', key: 'vendors'}),
        new NavigationModel({
          title: 'Vendor Remit Address',
          value: '/vendor-remit-address',
          key: 'vendor-remit-address'
        })
      ]
    })
  ];

  loadData() {
    return zip(
      this.permissionService.me(),
      this.permissionService.package()
    )
  }

  load() {
    return this.loadData().pipe(
      map((result: any) => {
        const permissions = result[0];
        const resources = result[1].resources;
        this.entries.forEach((entry) => {

          const resource = resources.find((res) => {
            return res.key === entry.key
          });

            entry.allowed = resource && Object.keys(resource).includes("allowed") ? resource.allowed : true;
            entry.visible = resource && Object.keys(resource).includes("visible") ? resource.visible : true;
          if(!entry.visible){
            return;
          }


          if (entry.items) {
            let hasVisibleChildModules= [];
            entry.items.forEach((item) => {
              const resource = resources.find((res) => {
                return res.key === item.key
              });

              item.allowed = resource && Object.keys(resource).includes("allowed") ? resource.allowed : true;
              item.visible = resource && Object.keys(resource).includes("visible") ? resource.visible : true;
              if(!item.visible){
                return;
              }
              item.visible = item.unprotected
                || this.permissionService.canPerformAction(
                  item,
                  'View',
                  permissions
                );
              // Only Admin can see Codes and Strings
              if ((permissions[0] !== '*') && (item.title === 'Codes' || item.title === 'Strings' || item.title === 'Custom Rules')) {
                item.visible = false;
              }

              hasVisibleChildModules.push(item.visible);
            });
            //Related to Inventory RECON enabling and disabling GPX-8089 Roles issue
            if(entry.group.key === 'inventory' && entry.items[0].visible === true){
              entry.items[3].visible = true
            }

            if(hasVisibleChildModules.every(v => v === false)){
              entry.visible = false;
            }

          } else {
            entry.visible = entry.unprotected
              || this.permissionService.canPerformAction(
                entry,
                'View',
                permissions
              );
            if (entry.allowed && entry.title === 'Reports & Analysis') {
              const filteredAdmin = permissions[0] === '*' && permissions.length > 1
                                      ? permissions.slice(1, permissions.length+1)
                                      : permissions[0] === '*'
                                      ? []
                                      : permissions;
              const canPerform = this.permissionService.canPerformAction(
                                      entry,
                                      'View',
                                      filteredAdmin);
              entry.allowed = canPerform;
            }
          }
        });

        return this.entries;
      }));
  }

  closeAll() {
    this.entries.forEach(entry => {
      entry.open = false;
    });
  }
}
