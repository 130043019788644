import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class SvcOrderHeaderGridService extends GridService {


  constructor(public userSettings: UserSettingsService) {
    super("app.contract.svc-order-header-grid", userSettings);
  }

  defaultColumns = [
    {
      dataField: 'vendor_so_number',
      caption: 'Vendor SO Number',
      width: 200
    },
    {
      dataField: 'internal_so_number',
      caption: 'Internal SO Number',
      width: 200
    },
    {
      dataField: 'description',
      caption: 'Svc Order Description',
      width: 400
    },
    {
      dataField: 'name',
      caption: 'Name',
      width: 200
    },
    {
      dataField: 'so_date',
      caption: 'SO Date',
      dataType: 'date',
      cellTemplate: "soDateTemplate",
      width: 160
    },
    {
      dataField: 'total_mrc',
      caption: 'Total MRC',
      cellTemplate: 'currencyTemplate',
      dataType: 'currency',
      width: 100,
      allowSorting: false
    },
    {
      dataField: 'total_nrc',
      caption: 'Total NRC',
      cellTemplate: 'currencyTemplate',
      dataType: 'currency',
      width: 100,
      allowSorting: false
    }
  ];

}
