import {Injectable} from '@angular/core';
import {BaseService} from "../core/base.service";
import {Restangular} from "ngx-restangular";

@Injectable()
export class SearchService extends BaseService<any> {

  constructor(public restangular: Restangular) {
    super('search', restangular);
  }

  search(q:string, offset:number) {
    return this.service().all("search").customGET(null, {
      q: q,
      offset: offset
    });
  }

}
