import { Injectable, OnDestroy } from "@angular/core";
import { AlertService } from "../../shared/alert/alert.service";
import { ConfigService } from "../../core/config/config.service";
import { Config } from "../../core/config/config";
import { MatSnackBar } from "@angular/material/snack-bar";
import {IMessagesResourceService, ResourcesService} from "../../core/resources/resources.service";

@Injectable()
export class SmartyStreetsReportingService implements OnDestroy {
  errorMessage: any;
  public config: Config;
  snackBarRef;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "address";

  constructor(
    public alertService: AlertService,
    public configService: ConfigService,
    public snackBar: MatSnackBar
  ) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  /* Finds if array has error item */
  errorCheck(array) {
    let errorItem = array.find(element => {
      return element.smarty_streets_error === true;
    });

    /* If there is an error, show snackbar */
    if (errorItem !== undefined) {
      this.showSnackbar();
    }

    /* If snackbar is active, and there is no error, remove snackbar */
    setTimeout(() => {
      if (errorItem === undefined && this.snackBarRef) {
        this.snackBarRef.dismiss();
        this.snackBarRef = null;
      }
    }, 1000);
  }

  showSnackbar() {
    if (!this.snackBarRef) {
      this.snackBarRef = this.snackBar.open(this.messages.get('VALIDATION_ERROR'));
    }
  }

  ngOnDestroy() {
    /* Close snackbar when changing component */
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    }
  }
}
