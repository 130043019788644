import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { ContractRate } from './contract-rate';
import { Restangular } from 'ngx-restangular';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class RateService extends BaseService<ContractRate> {

  constructor(public restangular: Restangular,
              public http: HttpClient,
              private entityLock: EntityLockService) {
    super('contract-rate', restangular, entityLock);
  }

  save(dataToSave): any {
    return this.service().all(this.name).all('save').post(dataToSave);
  }

  public createRate(contractId, item) {
    return this.service().one(this.name, contractId).one('rate').customPOST(item);
  }

  createInstance(item) {
    return this.service().all(this.name).one('rate').customPOST(item);
  }
  
  deleteRates(rateIds, contractInstanceId) {
    return this.service().all(this.name).remove({
      rateIds, contractInstanceId
    });
  }

  findCategorizedRates(query): any {
    return this.service().all(this.name).all('categorized-rates').customGET(null, this.toFilter(query));
  }

}
