
import {of as observableOf, Observable} from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { AlertService } from "../../../shared/alert/alert.service";
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {PageManageDialogComponent} from "../../../core/page-manage-dialog.component";
import {EntityEditContext} from "../../../shared/entity-lock/entity-edit-context";

@Component({
  selector: 'app-invoice-reset-to-new-manage',
  templateUrl: './invoice-reset-to-new-manage.component.html',
})
export class InvoiceResetToNewManageComponent extends PageManageDialogComponent implements OnInit {

  invoice: InvoiceFacepage
  note: string;
  public form: FormGroup;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "invoice";

  constructor(public formBuilder: FormBuilder,
              public invoiceService: InvoiceFacepageService,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<InvoiceResetToNewManageComponent>,
    public alertService: AlertService) {
    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

  }

  ngOnInit() {

      this.form = this.formBuilder.group({
        note: ["", Validators.minLength(20)]
      });
  }

  init() {
    if (this.invoice && this.invoice.id) {
      return this.invoiceService.findByIdForEdit(this.invoice.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .subscribe((items: any) => {
          if (items) {
            let obs = observableOf(items);
            obs.pipe(mergeMap(x => x.afterClosed() ))
              .subscribe((result) => {
                if (result) {
                  return observableOf(result);
                }
              })
          }
        })
    }
  }


  reset({ value, valid }: { value: any, valid: boolean }) {
    if (valid) {
      this.dialogRef.close({
        reset: true,
        note: value.note
      });
    } else {
      this.alertService.error('', this.messages.get('CHARACTER_LENGTH_ERROR'));
    }
  }

  cancel() {
    this.invoiceService.cancelEdit();
    this.closeDialog();
  }

}
