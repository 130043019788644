import { Injectable } from '@angular/core';
import { FilterService } from "../../core/filter/filter.service";
import Query from "../../core/query/query";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { LOOKUP_ENUM } from "../../dictionary/core/lookup.enum";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { accountDetailsFilterContext } from "./account-details-filter.context";
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class AccountDetailsFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];
  constructor(public userSettingsService: UserSettingsService,
              dictionaryService: DictionaryService) {
    super("app.accounts.account-details-filter", accountDetailsFilterContext, userSettingsService);

    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
    this.addLookupProvider('invoiceStatus', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.INVOICE_STATUS.modelName, false, false,
      new Query({
        where: { id: { $notIn: [LOOKUP_ENUM.INVOICE_STATUS.NEW_PENDING, LOOKUP_ENUM.INVOICE_STATUS.GL_PENDING, LOOKUP_ENUM.INVOICE_STATUS.GL_BATCH_PROCESSING], $gte: [0]  }},
        orderBy: [["value", "ASC"]],
        limit:100
      })));
  }
}
