import { DialogService } from '../../../shared/dialog/dialog.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-stop-billing-date',
  templateUrl: './stop-billing-date.component.html',
})
export class StopBillingDateComponent extends PageManageDialogComponent implements OnInit {

  public stop_billing_date: Date;

  constructor(public formBuilder: FormBuilder,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<StopBillingDateComponent>) {
    super(dialogService, dialogRef);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      stop_billing_date: [null, Validators.required],
    });
  }

  submit({ value, valid }: { value: any, valid: boolean }) {
    this.dialogRef.close({
      stop_billing_date: value.stop_billing_date,
    });
  }

  cancel() {
    this.closeDialog();
  }

}
