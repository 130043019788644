<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{formTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content app-account-manage-dialog"
     [ngClass]="isMultiRegion ? 'multi-region' : 'not-multi-region'" *ngIf="form">
  <form class="ca-form" [formGroup]="form">
    <div class="flex-container" fxLayoutAlign="start center">
      <div fxFlex="28%" class="caGap caGapRight">
        <mat-form-field caInput>
          <input matInput placeholder="Account" required formControlName="account_no" type="text" autocomplete="off">
          <mat-hint *ngIf="form.hasError('required', ['account_no']) && form.controls.account_no.touched"
                    class="error-class">{{VALIDATION.REQUIRED_ERROR}}
          </mat-hint>
        </mat-form-field>
      </div>
      <mat-slide-toggle formControlName="status_id" [disabled]="statusDisabled" >
        Active
      </mat-slide-toggle>
      <mat-slide-toggle formControlName="on_hold">
        On Hold
      </mat-slide-toggle>
      <mat-slide-toggle formControlName="is_vat_gl_output" (change)="onVatGlOutput($event)">
        VAT
      </mat-slide-toggle>
      <mat-slide-toggle formControlName="is_tax_exempt">
        Tax Exempt
      </mat-slide-toggle>
    </div>
    <div style="display: flex">
      <div fxFlex class="caGap">
        <div class="flex-container" fxLayout>
          <div class="flex-item caGap caGapRight" fxFlex="22%">
            <ca-vendor-picker
              caInput
              [disabled]="isUpdate"
              formControlName="vendor_id"
              [pickerForm]="form"
              placeholder="Vendor"
              [required]="true"
              [initialItem]="account?.vendor"
              [clearEnabled]="true"
              [customMethod]="'findOnlyVendors'">
            </ca-vendor-picker>
            <mat-hint class="ca-select-hint"
                      *ngIf="form.hasError('required', ['vendor_id']) && form.controls.vendor_id.touched">
              {{VALIDATION.REQUIRED_ERROR}}
            </mat-hint>
          </div>
          <div class="flex-item caGap caGapRight" fxFlex="22%">
            <ca-vendor-entity-picker
              caInput
              [vendorId]="form.controls.vendor_id.value"
              placeholder="Vendor entity"
              formControlName="vendor_entity"
              [pickerForm]="form"
              [disabled]="!form.controls.vendor_id.value"
              [initialItem]="account?.entity"
              [clearEnabled]="true">
            </ca-vendor-entity-picker>
          </div>
          <div class="flex-item caGap caGapRight" fxFlex>
            <ca-vendor-remit-address-picker
              [required]="isGlEnabled"
              [clearEnabled]="true"
              caInput
              [vendorId]="form.controls.vendor_id.value"
              placeholder="Remit address"
              formControlName="vendor_location_id"
              [pickerForm]="form"
              [disabled]="!form.controls.vendor_id.value"
              [initialItem]="account?.vendor_remit_address"
              [initialItemId]="account?.vendor_location_id">
            </ca-vendor-remit-address-picker>
          </div>
          <div class="flex-item caGap" fxFlex="22%">
            <ca-lookup-model-picker
              caInput
              [pickerForm]="form"
              [disabled]="viewOnly"
              [lookupModel]="SUBCLIENT_LOOKUP_MODEL"
              [clearEnabled]="true"
              formControlName="subclient_id"
              required="false"
              placeholder="Subclient">
            </ca-lookup-model-picker>
          </div>
        </div>

        <div class="flex-container">
          <div class="flex-item caGap caGapRight" fxFlex>
            <mat-form-field class="input-container-number" caInput>
              <input matInput placeholder="Late bill lag days" formControlName="late_bill_lag_days" required min="0" max="30"
                     type="number">
              <mat-hint *ngIf="form.hasError('required', ['late_bill_lag_days']) && form.controls.late_bill_lag_days.touched"
                        class="error-class">{{VALIDATION.REQUIRED_ERROR}}
              </mat-hint>
              <mat-hint *ngIf="form.hasError('range', ['late_bill_lag_days']) && form.controls.late_bill_lag_days.touched"
                        class="error-class">{{RangeErrorMessage('0','30')}}
              </mat-hint>
            </mat-form-field>
          </div>
          <div miv class="flex-item caGap caGapRight" fxFlex>
            <mat-form-field class="" caInput>
              <input matInput placeholder="Bill cycle" formControlName="billing_cycle" required min="0" max="30"
                     type="number">
              <mat-hint *ngIf="form.hasError('required', ['billing_cycle']) && form.controls.billing_cycle.touched"
                        class="error-class">{{VALIDATION.REQUIRED_ERROR}}
              </mat-hint>
              <mat-hint *ngIf="form.hasError('range', ['billing_cycle']) && form.controls.billing_cycle.touched"
                        class="error-class">{{RangeErrorMessage('1','30')}}
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="flex-item caGap" fxFlex>
            <ca-lookup-model-picker
              caInput
              [pickerForm]="form"
              [disabled]="viewOnly"
              [lookupModel]="BILLING_FREQUENCY_LOOKUP_MODEL"
              [clearEnabled]="true"
              formControlName="billing_freq_id"
              required="false"
              placeholder="Billing Frequency">
            </ca-lookup-model-picker>
          </div>
        </div>

        <div class="flex-container" fxLayout fxLayoutAlign="left">

          <div class="flex-item caGap caGapRight" fxFlex>
            <ca-lookup-model-picker
              caInput
              [disabled]="true"
              [lookupModel]="SYSTEM_REGION_LOOKUP_MODEL"
              formControlName="region_id"
              [pickerForm]="form"
              [clearEnabled]="false"
              placeholder="Region">
            </ca-lookup-model-picker>
          </div>

          <div class="flex-item caGap caGapRight" fxFlex>
            <ca-country-picker
              caInput
              formControlName="country_id"
              placeholder="Country"
              [pickerForm]="form"
              [showAllEntries]="true"
              [clearEnabled]="true"
              [required]="true"
              [searchEnabled]="true"
              [disabled]="viewOnly"
              (onSelectionChange)="onCountryChange($event)">
            </ca-country-picker>
          </div>

          <div class="flex-item caGap" fxFlex>
            <ca-currency-picker
              clearEnabled="true"
              [countryId]="selectedCountryId"
              [availableCurrencies]="availableCurrencies"
              [disabled]="!selectedCountry?.id && !isUpdate"
              caInput
              #currencyPicker
              required
              [searchEnabled]="true"
              name="currency_id"
              formControlName="currency_id"
              [pickerForm]="form"
              [query]="currencyQuery"
              placeholder="Currency">
            </ca-currency-picker>
          </div>
        </div>

        <div class="flex-container" fxLayout fxLayoutAlign="left">

          <div miv class="flex-item caGap caGapRight" fxFlex>
            <mat-form-field caInput>
              <input
                matInput
                placeholder="Audit threshold"
                formControlName="audit_threshold"
                type="number"
              >
              <span matSuffix>%</span>
            </mat-form-field>
          </div>

          <div class="flex-item caGap caGapRight" fxFlex>
            <ca-lookup-model-picker
              caInput
              [lookupModel]="SERVICE_PERIOD_MONTH"
              formControlName="svc_pd_month_id"
              [pickerForm]="form"
              [clearEnabled]="true"
              placeholder="Service Period Month">
            </ca-lookup-model-picker>
          </div>

          <div class="flex-item caGap" fxFlex>
            <ca-lookup-model-picker
              caInput
              [lookupModel]="SERVICE_PERIOD_RANGE"
              formControlName="svc_pd_range_id"
              [pickerForm]="form"
              [clearEnabled]="true"
              placeholder="Service Period Range">
            </ca-lookup-model-picker>
          </div>
        </div>

        <div class="flex-container" fxLayout fxLayoutAlign="left">
          <div class="flex-item caGap caGapRight" fxFlex>
            <ca-lookup-model-picker
              caInput
              [lookupModel]="MEDIA_TYPE_LOOKUP_MODEL"
              formControlName="media_typ"
              [pickerForm]="form"
              [clearEnabled]="true"
              placeholder="Media type">
            </ca-lookup-model-picker>
          </div>

          <div class="flex-item caGap" fxFlex>
            <ca-lookup-model-picker
              caInput
              [lookupModel]="PAY_METH_LOOKUP_MODEL"
              formControlName="pay_meth"
              [pickerForm]="form"
              [clearEnabled]="true"
              placeholder="Payment method">
            </ca-lookup-model-picker>
          </div>
        </div>

        <div class="flex-container" fxLayout fxLayoutAlign="left">
          <div class="flex-item caGap caGapRight" fxFlex *ngIf="isMultiRegion">
            <div class="flex-container ap-config-container" fxLayout="column" fxLayoutAlign="center">
              <div class="flex-item caGap" fxFlex>
                <ca-default-picker caInput>
                  <mat-form-field>
                    <mat-select
                      matInput
                      class="ca-mat-select-inline"
                      placeholder="AP Configuration"
                      [required]="isMultiRegion"
                      formControlName="ap_configuration_id"
                      (selectionChange)="onAPConfigChange($event)">
                      <mat-option
                        *ngFor="let c of allAPConfigs"
                        [value]="c.id">
                        {{"[" + c.region.value + "] "}} {{c.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ca-default-picker>
                <mat-hint class="ca-select-hint" *ngIf="form.hasError('required', ['ap_configuration_id']) && form.controls.ap_configuration_id.touched">
                  {{VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </div>
              <!--<div *ngIf="selectedAPConfig" class="flex-container" class="ap-config-card" fxLayout="column">
                <span class="ap-config-section padding-b-8">Email To: {{selectedAPConfig.email_to.join(', ')}}</span>
                <span class="ap-config-section">Email CC: {{selectedAPConfig.email_cc.join(', ')}}</span>
              </div>
              <div *ngIf="!selectedAPConfig" class="flex-container" class="ap-config-card" fxLayout="column">
                <span class="card-message-ap">AP Configuration is not selected</span>
              </div>-->
            </div>
          </div>

          <!--<div class="flex-container description-container" fxLayout fxLayoutAlign="left">-->
            <div class="flex-item caGap caGapRight account-description" fxFlex>
              <mat-form-field caInput>
                <textarea class="textarea" matInput placeholder="Description" formControlName="acct_desc" fxFlex="100%" rows="2"></textarea>
              </mat-form-field>
            </div>

            <div class="flex-item caGap account-description" fxFlex>
              <mat-form-field caInput>
                <textarea class="textarea" matInput placeholder="{{'On Hold Description' + (isOnHoldDescRequired ? '*' : '')}}" formControlName="on_hold_desc" fxFlex="100%" rows="2"></textarea>
              </mat-form-field>
            </div>
          <!--</div>-->
        </div>

        <div class="flex-container" fxLayout fxLayoutAlign="left" *ngIf="isMultiRegion">
          <div class="flex-item caGap caGapRight ap-config-card-container" fxFlex="70%"><!--*ngIf="isMultiRegion"-->
            <div *ngIf="selectedAPConfig" class="flex-container" class="ap-config-card" fxLayout="column">
              <span class="ap-config-section padding-b-8">Email To: {{selectedAPConfig.email_to.join(', ')}}</span>
              <span class="ap-config-section">Email CC: {{selectedAPConfig.email_cc.join(', ')}}</span>
            </div>
            <div *ngIf="!selectedAPConfig" class="flex-container" class="ap-config-card" fxLayout="column">
              <span class="card-message-ap">AP Configuration is not selected</span>
            </div>
          </div>
          <div class="flex-item caGap" fxFlex="30%"></div>
        </div>
      </div>

      <div *ngIf="fields && fields.length"
           class="flex-container custom-fields-wrapper"
           fxLayout fxLayoutAlign="left" fxFlex="300px"
      >
        <div class="flex-item caGap caGapLeft" fxFlex="100%">
          <div class="flex-container" fxLayout="column" fxLayoutAlign="center">
            <!-- Custom Fields -->
            <ca-custom-fields
              [form] = "this.form"
              [customFieldService]="this.accountService"
              [customField]="this.account"
              [fields]="fields"
              [categories]="categories"
              [showCategory]="showCategory"
              [clearEnabled]="true"
            ></ca-custom-fields>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<mat-dialog-actions class="ca-align-baseline ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>

  <button mat-button #saveButton type="submit" (click)="onSubmit(form)" [disabled]="form?.invalid || (isOnHoldDescRequired && !form.get('on_hold_desc').value)">
    Save
  </button>
</mat-dialog-actions>
