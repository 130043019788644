<button
  (click)="clearUsedFilters()"
  mat-icon-button
  [matMenuTriggerFor]="filtersMenu"
  matTooltip="Add Filter Card"
  [disabled]="isPickerEmpty()"
  type="button"
>
  <ca-icon [key]="'SRM_CUSTOM_FIELDS_ADD'"></ca-icon>
</button>

<div [hidden]="!filterInput.isSorting" class="filter-remove-area-bg"></div>

<div
  [ngClass]="{ 'dnd-drag-over': isDropzoneHovered }"
  [hidden]="!filterInput.isSorting"
  class="filter-remove-area"
  (drop)="removeZone($event)"
  (dragover)="allowDrop($event)"
  (dragleave)="dragLeave()"
>
  <ca-icon [key]="'SRM_CUSTOM_FIELDS_DELETE'"></ca-icon>
</div>

<mat-menu #filtersMenu="matMenu" class="filters-menu">
  <div
    *ngFor="let entry of availableFilters"
    [hidden]="entry?.isActiveCard || !entry?.visible"
    (click)="onFilterSelected(entry)"
  >
    <div mat-menu-item>
      {{ entry?.label }}
    </div>
  </div>
</mat-menu>
