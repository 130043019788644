import {Injectable} from '@angular/core';
import {FilterService} from "../../core/filter/filter.service";
import {invoiceSubaccountFilterContext} from "./invoice-subaccount-filter.context";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {SubAccountLookupProvider} from '../../invoice/core/sub-account-lookup.provider';
import { InvoiceFacepageService } from './invoice-facepage.service';

@Injectable()
export class InvoiceSubaccountFilterService extends FilterService {
  constructor(public userSettingsService: UserSettingsService,
              public invoiceService: InvoiceFacepageService) {
    super("app.cost.invoice-subaccount-filter", invoiceSubaccountFilterContext, userSettingsService);

    this.addLookupProvider('subAccount', new SubAccountLookupProvider(invoiceService));
  }

}
