<div fxFlex class="ca-form">
  <mat-checkbox [name]="entry?.label"
                (ngModelChange)="ngOnBooleanChange(entry)"
                [(ngModel)]="internalBooleanQuery['true']">
    {{entry?.trueLabel || 'Yes'}}
  </mat-checkbox>
</div>
<div fxFlex class="ca-form">
  <mat-checkbox [name]="entry?.label"
                (ngModelChange)="ngOnBooleanChange(entry)"
                [(ngModel)]="internalBooleanQuery['false']">
    {{entry?.falseLabel || 'No'}}
  </mat-checkbox>
</div>
