<app-contract-details-toolbar
    [isSummaryTab]="true"
    (editContractShellRequested)="editContractShell()"
    [isFilterDisabled]="true"
    [notesCount]="notesCount"
    [isNotesOn] = "this.sider.getActiveSection().name === this.SECTIONS.NOTES_SECTION_NAME"
    (toggleNotesRequested)="toggleNotes()"
    [isHistoryOn] = "this.sider.getActiveSection().name === this.SECTIONS.HISTORY_SECTION_NAME"
    (toggleHistoryRequested)="toggleHistory()"
>

</app-contract-details-toolbar>

<mat-sidenav-container class="app-content app-content-list tabbed-content dx-nodata-absolute">
    <app-contract-details-tabs [activeTabIndex]="0" [contract]="contract"></app-contract-details-tabs>
    <div class="tabs-body media-screen">
        <div class="flex-container ca-card summary-screen-size" fxLayout="column" fxLayout.sm="column">
          <div fxLayout="row" fxFlex="50%" class = "summary-screen-size-first-grid">
            <mat-card fxFlex="20%" class="flex-item" fxFlex.sm="30%" caCard>
              <mat-card-header class="summary-header-padding">
                <div style="margin-left: -16px;">Contract Info</div>
              </mat-card-header>
              <mat-card-content class="summary-content-padding">
                <div fxLayout="column">
                  <div fxLayout="row">
                    <div fxFlex class="custom-list-item entry-list-item" style="height: 45px;">
                      <label>Contract</label>
                      <p class="only-one-line-shown">
                        {{ contract?.name || 'N/A' }}
                      </p>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxFlex class="custom-list-item entry-list-item" style="height: 45px;">
                      <label>Vendor</label>
                      <p class="only-one-line-shown">
                        {{ contract?.vendor?.name || 'N/A' }}
                      </p>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxFlex class="custom-list-item entry-list-item" style="height: 45px;">
                      <label>Company Name</label>
                      <p class="only-one-line-shown" matTooltip="{{contract?.company_name}}">
                        {{ contract?.company_name || 'N/A' }}
                      </p>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxFlex class="custom-list-item entry-list-item" style="height: 45px;">
                      <label>Description</label>
                      <p class="only-one-line-shown" matTooltip="{{contract?.description}}"> {{ contract?.description || 'N/A' }} </p>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card fxFlex="80%" class="flex-item instances-card" fxFlex.sm="70%" caCard>
              <mat-card-header class="header-without-padding">
                <div>Schedule Term Summary</div>
              </mat-card-header>
                <mat-card-content class = "grid-card-content">
                  <dx-data-grid #termConditionGrid
                                class="sort-arrows grid-size-screen"
                                [columns]="termConditionColumns"
                                [allowColumnResizing]="true"
                                [columnMinWidth]="50"
                                [columnAutoWidth]="true"
                                [showColumnLines]="false" [showRowLines]="true" [showBorders]="true"
                                [dataSource]="schedulesTermCondition"
                                (onCellClick)="onTermAndConditionCellClick($event)">
    
                    <div *dxTemplate="let d of 'effectiveDateTemplate'">
                      <div caGridTooltip>{{ d.value | caDate }}</div>
                    </div>
                    <div *dxTemplate="let d of 'terminationDateTemplate'">
                      <div caGridTooltip>{{ d.value | caDate }}</div>
                    </div>
                    <div *dxTemplate="let d of 'isTerminated'">
                      <div caGridTooltip>{{ isTerminated(d.value) ? 'Active' : 'Expired' }}</div>
                    </div>
                    <dxo-paging [enabled]="false" visible="false"></dxo-paging>
                    <dxo-sorting mode="none"></dxo-sorting>
                    <dxo-load-panel [enabled]="false"></dxo-load-panel>
                  </dx-data-grid>
                  <ca-pager [gridPager]="defaultGridPager" [pageContext]="contractScheduleTermConditionGridService"
                            [query]="termConditionQuery" class="app-pager ca-default-border-top"
                            (onChange)="loadTermConditionData(termConditionQuery)"></ca-pager>
                </mat-card-content>
            </mat-card>
          </div>
          <div fxLayout="column" fxFlex="50%" class = "summary-screen-size-second-grid">
            <mat-card fxFlex="100%" class="flex-item instances-card" caCard>
              <mat-card-header class="header-without-padding">
                <div>Commitment Summary</div>
              </mat-card-header>
                <mat-card-content class = "grid-card-content">
                  <dx-data-grid #commitmentGrid class="sort-arrows grid-size-screen"
                                [columns]="commitmentColumns" 
                                [allowColumnResizing]="true"
                                [columnMinWidth]="50"
                                [columnAutoWidth]="true"
                                [showColumnLines]="false" 
                                [showRowLines]="true" 
                                [showBorders]="true"
                                [dataSource]="schedulesCommitment" 
                                (onCellClick)="onCommitmentCellClick($event)">
                    <div *dxTemplate="let d of 'commitAmountTemplate'">
                      <div caGridTooltip>{{ d.value | caCurrency: d.data?.currency?.currency }}</div>
                    </div>
                    <div *dxTemplate="let d of 'effectiveDateTemplate'">
                      <div caGridTooltip>{{ d.value | caDate }}</div>
                    </div>
                    <div *dxTemplate="let d of 'terminationDateTemplate'">
                      <div caGridTooltip>{{ d.value | caDate }}</div>
                    </div>
                    <div *dxTemplate="let d of 'totalCommitTemplate'">
                      <div caGridTooltip>{{ d.value | caCurrency: d.data?.currency?.currency }}</div>
                    </div>
                    <dxo-paging [enabled]="false" visible="false"></dxo-paging>
                    <dxo-sorting mode="none"></dxo-sorting>
                    <dxo-load-panel [enabled]="false"></dxo-load-panel>
                  </dx-data-grid>
                  <ca-pager [gridPager]="defaultGridPager" [pageContext]="contractScheduleCommitmentGridService"
                            [query]="commitmentQuery" class="app-pager ca-default-border-top"
                            (onChange)="loadCommitmentData(commitmentQuery)"></ca-pager>
                </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>

      <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false" (close-start)="panelClose()">
        <ca-notes [entityId]="contract?.id" [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                  [entityType]="SYSTEM_MODULE.CONTRACT" (countChanged)="onNotesCountChanged($event)"></ca-notes>
    
        <ca-history #history
                    [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
                    [entityId]="contract?.id"
                    [entityType]="'contract'"
        ></ca-history>
      </mat-sidenav>

</mat-sidenav-container>