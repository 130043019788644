<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Charge Browser</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button mat-icon-button *permission="{'action':'Create', 'key':'audits'}" [disabled]="!selectedRows.length || !sameVendorsSelected"
          (click)="createNewRateAudit()">
    <ca-icon [key]="'RATE_AUDIT_ADD_RULE'" [tooltip]="'Add Audits'"></ca-icon>
  </button>
  <button mat-icon-button type="button" [disabled]="disputeCreationDisabled"
          (click)="addDispute()" *permission="{'action':'Create', 'key':'disputes'}">
          <ca-icon [key]="'DISPUTE'" [tooltip]="'Add Dispute'"></ca-icon>
  </button>
  <button *permission="{'action':'Create', 'key':'gl'}" mat-icon-button type="button" [disabled]="isInfoOnlyChargeSelected(selectedRows) || !directGLAddEnabledForSelection(selectedRows) || (selectedRows && this.selectedRows.length > 1) || isGLBatchStatus()"
          (click)="addDirectGL()">
          <ca-icon [key]="'GL_BATCH_ADD_INVOICE'" [tooltip]="'Add Direct GL Codes'"></ca-icon>
  </button>

  <button *permission="{'action':'Modify', 'key':'gl'}" mat-icon-button type="button" [disabled]="isInfoOnlyChargeSelected(selectedRows) || !directGLEditEnabledForSelection(selectedRows) || (selectedRows && this.selectedRows.length > 1) || isGLBatchStatus() || this.isUnsetAdjustment(selectedRows[0])"
          (click)="addDirectGL(true)">
    <ca-icon [key]="'GL_EDIT_STRING'" [tooltip]="'Edit Direct GL'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Refresh List" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button [disabled]="!charges.length" type="button" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'" [tooltip]="'Download CSV'"></ca-icon>
  </button>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button type="button" [disabled]="selectionActive" [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"  (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)">
    <ca-icon [key]="'FILTER'" [tooltip]="'Filter'"></ca-icon>
  </button>
  <button mat-icon-button #toggleDetailsEnableButton [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}" [disabled]="!selection || selectedRows.length > 1"
          (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)">
   <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>
  </button>
  <button matTooltip="Notes"
    [ngClass]="{'on': sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
    (click)="sider?.toggle(SECTIONS.NOTES_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="notesCount > 0 ? 'NOTES' : 'NOTES_EMPTY'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">
  <dx-data-grid #grid class="grid-with-selection-badge wrapper-content"
                [columns]="columns"
                [dataSource]="charges"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onCellClick)="charges && charges?.length ? onCellClick($event) : null">

    <div *dxTemplate="let d of 'selectedChargesHeaderTemplate'" class="grid-selection-badge-container">
      <a class="selection-badge grid-badge"
         matTooltip="Selection"
         (click)="loadSelected()"
         [ngClass]="{'active':selectionActive}">
        {{selectedRows.length}}
      </a>
    </div>

    <div class="icon-td" *dxTemplate="let d of 'chargeDisputeNoteTemplate'" style="position: relative;">
      <a [ngClass]="d.data.charge_dispute
        ? 'icon-accent'
        : 'disabled'"
         (click)="d.data.charge_dispute?.dispute_id ? showDispute(d.data.charge_dispute.dispute_id) : null"
         class="charge-grid-icons" disabled="true"
         >
         <ca-icon [key]="'DISPUTE'" [innerClass]="'charge-grid-icon'" [tooltip]="'Dispute'"></ca-icon>
      </a>

      <a class="grid-link" [ngClass]="d.data.note_charges?.length ||
      d.data.note_charges_info_only?.length
        ? 'icon-accent'
        : 'disabled'"
         (click)="d.data.note_charges.length && selectCharge(d.data)"
         class="charge-grid-icons note-charge-grid-icon">

         <ca-icon [key]="d.data.note_charges?.length ||
         d.data.note_charges_info_only?.length ? 'NOTES' : 'NOTES_EMPTY'" [innerClass]="'charge-grid-icon'" [tooltip]="'Note'"></ca-icon>
      </a>
    </div>

    <div *dxTemplate="let d of 'eligible'" style="text-align: left;">
      <span>{{ d?.data?.contract_commitment?.is_commitment_eligible ? 'Yes' : 'No' }}</span>
    </div>
    <div *dxTemplate="let d of 'withheldCellTemplate'">
      <div *ngIf="d.value != null" caGridTooltip>{{ d.value ? "Yes" : "No" }}</div>
    </div>

    <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
      <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.currency }}</div>
    </div>

    <div *dxTemplate="let d of 'dateTemplate'">
      <div caGridTooltip>{{ d.value | caDate }}</div>
    </div>

    <!--TODO - OPTIMIZE THIS TO METHOD OR PIPE-->
    <div *dxTemplate="let d of 'chargeRateTemplate'">
      <div caGridTooltip class="right">{{d.value | caNumber}}</div>
    </div>

    <div *dxTemplate="let d of 'chargeCodedTemplate'">
      <div *ngIf="d.value != null" caGridTooltip>{{ d.value ? "Coded" : "Uncoded" }}</div>
    </div>
  </dx-data-grid>
  <ca-pager [doNotEmitQueryOnInitialLoad]="true" [gridPager]="defaultGridPager" [pageContext]="chargeGridService"  [query]="query" class="app-pager ca-default-border-top" (onChange)="onPageChange(query)"></ca-pager>

  <mat-sidenav #panelSide mode="side" position="end" [opened]="false">

    <ca-charge-notes
      fxFlex
      fxLayout="column"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
      [entityType]="SYSTEM_MODULE.CHARGE"
      [charges]="selectedRows"
      [chargesRequired]="true"
      [chargesNote]="chargesNote"
      [selectedId]="selectedNoteId"
      (countChanged)="onNotesCountChanged($event)"
      (viewNoteCharges)="onViewNoteCharges($event)"
      (noteCreated)="onNoteCreated()"
      [query]="query"
      #notesComponent>
    </ca-charge-notes>
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                [query]="query"
                [filterService]="chargeFilterService"
                (filterRequested)="loadData($event)"
                (clearFilterRequested)="clearFilter($event)"
                style="width: 100%">
    </new-app-filter>
    <app-charge-sneak-peek [charge]="selection"
                           (closeRequested)="sider?.close()"
                           [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}" style="width: 100%"></app-charge-sneak-peek>
  </mat-sidenav>


</mat-sidenav-container>
