<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{ batchPaymentIconEnabled ? 'Create Multiple Payment Requests' : 'Create GL Batch Output' }}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">

  <div fxLayout="row" class="entry-list">
    <div fxFlex class="entry-list-item">
      <label>Batch ID</label>
      <p>
        New
      </p>
    </div>
    <div fxFlex class="entry-list-item">
      <label>Create Date</label>
      <p>
        {{ createDate | caDate }}
      </p>
    </div>

    <div fxFlex class="entry-list-item">
      <label>Creator</label>
      <p>
        {{ creator }}
      </p>
    </div>
    <!--<div fxFlex class="entry-list-item">
      <label>File Name</label>
      <p>
        N/A
      </p>
    </div>-->
  </div>

  <div style="height: 300px; margin-top: 10px; padding-bottom: 20px; overflow: hidden">
    <!--<span class="title">Invoices</span>-->
    <dx-data-grid #invoicesGrid
                  [columns]="columns"
                  [dataSource]="invoices">
      <div *dxTemplate="let d of 'invoiceNumberTemplate'">
        <div caGridTooltip [ngClass]="{'bold': d.data.$new, 'text-removed': d.data.$removed }">{{ d.value }}</div>
      </div>
      <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
        <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency}}</div>
      </div>
      <div *dxTemplate="let d of 'deactivatedAtTemplate'">
        <div caGridTooltip>{{ d.data.batch_item?.deactivated_at | caDateTime }}</div>
      </div>
      <div *dxTemplate="let d of 'deactivatedNote'">
        <div caGridTooltip>{{ d.value }}</div>
      </div>
      <div *dxTemplate="let d of 'dateTemplate'">
        <div caGridTooltip>{{ d.value | caDate }}</div>
      </div>
    </dx-data-grid>
  </div>

</div>

<mat-dialog-actions class="ca-dialog-action">
    <div class="example-fill-remaining-space">
    </div>

    <button mat-button type="submit" [disabled]="isProcessing" (click)="close()">
      Cancel
    </button>

    <button mat-button [disabled]="isProcessing" type="submit" (click)="onSubmit()" data-spinner-color="black">
      Process
    </button>
  </mat-dialog-actions>
