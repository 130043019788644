import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Restangular} from 'ngx-restangular';
import {ReportingService} from '../../srm/core/srm.service';
import {AppService} from '../../app.service';
import {CUSTOM_REPORTS} from 'app/report-gallery/custom-reports.config';
import {path, identity, map} from 'ramda';
import {ConfigService} from "../../core/config/config.service";
import {ApiService} from "../../core/api";
import {flatMap} from "rxjs/internal/operators";
import Query from '../../core/query/query';

const filterFactory = (operatorParsers) => (query) => {
  if (!query) {
    return identity;
  }
  const [operator, value] = Object.entries(query)[0];

  return operatorParsers(value, query)[operator] || (() => {
    throw new Error('Not supported operator');
  });
}

const getNameFilter = filterFactory((value) => {
  const filter = (regexFlags?: string) => (report) => {
    const regexString = (value as string).replace(/%/g, '.*')
    const regex = new RegExp(`^${regexString}$`, regexFlags);
    return regex.test(report.display_name);
  }
  return {
    '$like': filter(),
    '$ilike': filter('i')
  };
})


const getTypeFilter = filterFactory((value) => {
  return {
    '$in': (report) => (value as Array<string>).includes(report.report_type)
  };
});

@Injectable()
export class ReportService extends BaseService<any> {
  constructor(public restangular: Restangular,
              private configService: ConfigService,
              private apiService: ApiService,
              public srmReportingService: ReportingService) {
    super('report', restangular);
  }


  filterCustomReports(query, customReports) {
    const nameQuery = path(['where', 'display_name'])(query);
    const nameFilter = getNameFilter(nameQuery);
    const typeQuery = path(['where', 'report_type'])(query);
    const typeFilter = getTypeFilter(typeQuery);
    return customReports
      .filter(report => typeFilter ? typeFilter(report) : report)
      .filter(report => nameFilter ? nameFilter(report) : report)
  }


  find(type: string, query: any) {

    // return this.configService.get()
    //   .pipe(flatMap((config: any) => {
    //     const custom_report_identifiers = config.custom_reports;
    //     const custom_reports = CUSTOM_REPORTS.filter((report) => {
    //       if (custom_report_identifiers) {
    //         return custom_report_identifiers.indexOf(report.uid) > -1
    //       }
    //     });
    //     return this.service().all(this.name).one(type, 'find').customGET(null, this.toFilter(query))
    //       .pipe(map(reports => {
    //         return type === 'public' ? this.filterCustomReports(query, custom_reports).concat(reports) : reports;
    //       }))
    //   }));

    return this.service().all(this.name).one(type, 'find').customGET(null, this.toFilter(query));
  }

  findById(reportId: number) {
    return this.service().all(this.name).one(reportId).get();
  }

  execute(query) {
    return this.service().all(this.name).all('execute').post(query);
  }

  ;

  glSegmentReport(query) {
    return this.service().all(this.name).all('gl/segment').customGET(null, query);
  }

  ;

  chargesByVendorReport() {
    return this.apiService.get([this.name, 'charge/vendor']);
    //return this.service().all(this.name).all('charges/vendor').getList();
  }

  ;

  ordersByVendorReport() {
    return this.apiService.get([this.name, 'orders/vendor']);
  //  return this.service().all(this.name).all('orders/vendor').getList();
  }

  ;

  disputeAgingReport() {
    return this.apiService.get([this.name, 'disputes/aging']);
    //return this.service().all(this.name).all('disputes/aging').getList();
  }

  ;

  invoiceTrendReport(accountNo: string, vendorId: number) {
    return this.apiService.get([this.name, 'invoice/trend', accountNo, vendorId]);
    //return this.service().all(this.name).all('invoice/trend').one(accountNo, vendorId).getList();
  }

  public mapCSVfields(fields) {
    return fields
      .map((column) => {
        return {
          value: column,
          label: column,
        };
      });
  }


  public loadByIdOrKey(idOrKey) {
    if (!isNaN(idOrKey)) {
      return this.srmReportingService.findById(idOrKey);
    } else {
      return this.srmReportingService.findByKey(idOrKey);
    }
  }

  public loadSRM() {
    return this.service().all(this.name).one('findSRM').customGET(null, null);
  }

  private executeCustomReport(url, displayName) {
    return (query?) => {
      return this.service()
        .all(this.name)
        .all(url).post({
          filter: query,
          display_name: displayName
        });
    };
  }

  getEmbedURL(dashboardId) {
    let concreteQuery = new Query();
    concreteQuery.where.dashboardId = dashboardId;
    let transformedQuery = concreteQuery.transform();
    return this.apiService.get([this.name, 'embedURL'], this.toFilter(transformedQuery));
  }

  getEmbedURLForAuthor() {
    return this.apiService.get([this.name, 'embedURLAuthor']);
  }

  getAllDashboards() {
    return this.apiService.get([this.name, 'listDashboards']);
  }

  createReport(report) {
    return this.apiService.post(this.name, report)
  }

  executeMonthlyDetails(query?) {
    return this.executeCustomReport('execute/custom/monthly-details', 'Monthly Details Report')(query);
  }

  executeInvoiceItemCost(query?) {
    return this.executeCustomReport('execute/custom/invoice-item-cost', 'Invoice Item Cost Report')(query);
  }

  executeGlobalSpendTrend(query?) {
    return this.executeCustomReport('execute/custom/global-spend-trend', 'Global Spend Trend Report')(query)
  }

  executeThreshold(query?) {
    return this.executeCustomReport('execute/custom/threshold', 'Treshold Report')(query);
  }

  executeAccrual(query?) {
    return this.executeCustomReport('execute/custom/accrual', 'Accrual Report')(query);
  }

  executeMonthlySpend(query?) {
    return this.executeCustomReport('execute/custom/monthly-spend', 'Monthly Spend Report')(query);
  }

  executeSPIDWithoutGLCodes(query?) {
    return this.executeCustomReport('execute/custom/spid-without-gl-codes', 'SPID without GL codes')(query);
  }

  executeMonthlyInvoiceReceived(query?) {
    return this.executeCustomReport('execute/custom/monthly-invoice-received', 'Montly Invoice Received')(query);
  }

  executeMonthlyInvoiceSpidCost(query?) {
    return this.executeCustomReport('execute/custom/monthly-invoice-spid-cost', 'Montly Invoice Spid Cost')(query);
  }

  executeMonthlyInvoiceProcessingSpid(query?) {
    return this.executeCustomReport('execute/custom/monthly-invoice-processing-spid',
      'Monthly Invoice Processing by SPID')(query);
  }
}
