  <mat-toolbar fxLayoutAlign="end center" class="pager-toolbar" *ngIf="!stackedPager">
    <div class="pager-size-picker" fxLayout="row" fxLayoutAlign="start center">
      <p>Rows per page:</p>
      <ca-pager-size [gridPager]="gridPager" [sizes]="sizes" (onChange)="onPageSizeChange($event)"></ca-pager-size>
    </div>
    <div class="pager-counter">
      {{query?.from() | number}} to {{query?.to() | number}} of {{query?.total | number}}
    </div>
    <button class="pager-previous-btn" mat-icon-button (click)="prev()" [disabled]="!query.canPrev()">
        <ca-icon [key]="'PAGER_LEFT'"></ca-icon>
    </button>
    <button mat-icon-button (click)="next()" [disabled]="!query.canNext()">
        <ca-icon [key]="'PAGER_RIGHT'"></ca-icon>
    </button>
  </mat-toolbar>

  <mat-toolbar *ngIf="stackedPager" fxLayoutAlign="end center" fxLayout="column" class="pager-toolbar column" style="height: 99px!important;">
    <div class="pager-size-picker center" fxLayout="row" fxLayoutAlign="start center">
      <p>Rows per page:</p>
      <ca-pager-size [gridPager]="gridPager" [sizes]="sizes" (onChange)="onPageSizeChange($event)"></ca-pager-size>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="pager-counter" [style.padding-right]="'0'">
        {{query?.from() | number}} to {{query?.to() | number}} of {{query?.total | number}}
      </div>
        <button class="pager-previous-btn" mat-icon-button (click)="prev()" [disabled]="!query.canPrev()">
          <ca-icon [key]="'PAGER_LEFT'"></ca-icon>
      </button>
      <button mat-icon-button (click)="next()" [disabled]="!query.canNext()">
          <ca-icon [key]="'PAGER_RIGHT'"></ca-icon>
      </button>
    </div>
  </mat-toolbar>
