import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class OrderServiceGridService extends GridService {
  constructor(
    public userSettings: UserSettingsService,
    public formatService: FormatService
  ) {
    super('app.orders.order-service-grid', userSettings);
  }

  defaultColumns = [
    {
      caption: 'Service Number',
      dataField: 'full_service_id',
      width: 180
    },
    {
      caption: 'Status',
      dataField: 'status.value',
      width: 180,
      cellTemplate: 'statusType',
    },
    {
      caption: 'Status Date',
      cellTemplate: 'statusDate',
      dataField: 'des_due_date',
      dataType: 'date',
      format: "dateTemplate",
      width: 100
    },
    {
      caption: 'Vendor',
      dataField: 'inventory.vendor.name',
      width: 120
    },
    {
      caption: 'Customer',
      dataField: 'inventory.customer.company',
      width: 150
    },
    {
      caption: 'Service ID',
      dataField: 'inventory.id',
      width: 120,
      alignment: 'left',
    },
    {
      caption: 'Service Type',
      dataField: 'inventory.type.value',
      width: 120
    },
    {
      caption: 'Service Bandwidth',
      dataField: 'inventory.circuit_bandwidth.value',
      width: 150
    },
    {
      caption: 'Site A',
      dataField: 'inventory.siteA.site_id',
      width: 180
    },
    {
      caption: 'Site Z',
      dataField: 'inventory.siteZ.site_id',
      width: 180
    },
  ];
}
