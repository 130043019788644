export const invoiceGlRuleExecutionFilterContext = {
  filters: [
    {
      field: 'charge_coded',
      label: 'Coded Status',
      type: 'boolean',
      trueLabel: 'Coded',
      falseLabel: 'Uncoded',
      /*defaultValue: ['true'],*/
      index: 1
    },
    {
      field: 'acct_level_2',
      label: 'Subaccount',
      type: 'string',
      index: 2
    },
    {
      field: 'chg_desc_1',
      label: 'Charge Description 1',
      type: 'string',
      index: 3
    },
    {
      field: 'chg_desc_2',
      label: 'Charge Description 2',
      type: 'string',
      index: 4
    },
    {
      field: 'sp_serv_id',
      label: 'Service ID',
      type: 'string',
      index: 8
    },
    {
      field: 'chg_class',
      label: 'Charge Type ',
      type: 'list',
      lookupProviderName: 'chargeType',
      index: 6
    },
    {
      field: 'id',
      label: 'ID ',
      type: 'negativeNumber',
      index: 9
    },
    {
      field: 'gl_rule_type',
      label: 'Rule Name',
      type: 'string',
      index: 12
    },
    {
      field: 'sp_inv_num',
      label: 'Invoice Number ',
      type: 'string',
      index: 13
    },
    {
      field: 'inv_date',
      label: 'Invoice Date ',
      type: 'date',
      lookupProviderName: 'defaultDateRanges',
      index: 14
    },
    {
      field: 'chg_code_1',
      label: 'Charge Code1',
      type: 'string',
      index: 15
    },
    {
      field: 'chg_code_2',
      label: 'Charge Code2',
      type: 'string',
      index: 16
    },
    {
      field: 'chg_qty1_billed',
      label: 'Chg Qty ',
      type: 'negativeNumber',
      index: 17
    },
    {
      field: 'chg_rate',
      label: 'Charge Rate ',
      type: 'negativeNumber',
      index: 18
    },
    {
      field: 'chg_amt',
      label: 'Charge Amount ',
      type: 'negativeNumber',
      index: 19,
    },
    {
      field: 'svc_establish_date',
      label: 'Install Date ',
      type: 'date',
      lookupProviderName: 'defaultDateRanges',
      index: 20
    },
    {
      field: 'beg_chg_date',
      label: 'Beg Charge Date ',
      type: 'date',
      lookupProviderName: 'defaultDateRanges',
      index: 22
    },
    {
      field: 'end_chg_date',
      label: 'End Charge Date ',
      type: 'date',
      lookupProviderName: 'defaultDateRanges',
      index: 23
    },
    {
      label: 'Info Only Ind',
      field: 'info_only_ind',
      type: 'boolean',
      index: 24
    },
    {
      label: 'Facility Bandwidth ',
      field: 'fac_bw',
      type: 'negativeNumber',
      index: 25
    },
    {
      label: 'Call Type',
      field: 'call_type',
      type: 'string',
      index: 26
    },
    {
      label: 'Dir Ind',
      field: 'dir_ind',
      type: 'string',
      index: 27
    },
    {
      label: 'Jurisdiction',
      field: 'jur',
      type: 'string',
      index: 28
    },
    {
      label: 'Chg Qty Type',
      field: 'chg_qty1_type',
      type: 'string',
      index: 29
    },
    {
      label: 'Chg Qty Used ',
      field: 'chg_qty1_used',
      type: 'negativeNumber',
      index: 30
    },
    {
      field: 'chg_qty1_allowed',
      label: 'Charge Qty Allowed',
      type: 'string',
      index: 31,
    }
  ]
};
