import {Injectable} from '@angular/core';
import {GridService} from "../../shared/grid/grid.service";
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class DemarcGridService extends GridService {
  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.demarc.demarc-grid", userSettings);
  }

  defaultColumns = [{
    caption: "Row",
    dataField: "row_id",
    width: 80,
  }, {
    caption: "Rack",
    dataField: "rack_id",
    width: 80,
  }, {
    caption: "Shelf",
    dataField: "shelf_id",
    width: 80,
  }, {
    caption: "CPE",
    dataField: "cpe_id",
    width: 80,
  }, {
    caption: "Card",
    dataField: "card_id",
    width: 80,
  }, {
    caption: "Port",
    dataField: "port_id",
    width: 80,
  }, {
    caption: "Extended",
    dataField: "extended",
    cellTemplate: 'demarcExtended',
    dataType: "boolean",
    width: 80,
  }, {
    caption: "Notes",
    dataField: "notes",
    width: 120,
  }];
}
