import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';

import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";
import {UpdateInventoryBillingActiveSiderSection, UpdateInventoryBillingQuery} from "./inventory-billing.actions";

const INVENTORY_BILLING_STATE_TOKEN = new StateToken<InventoryBillingStateModel>('inventory_billing');

export interface InventoryBillingStateModel extends QueryableStateModel<any> {

}

@State<InventoryBillingStateModel>({
  name: INVENTORY_BILLING_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InventoryBillingState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  @Action(UpdateInventoryBillingQuery)
  // @ts-ignore
  updateInventoryBillingQuery(ctx: StateContext<any>, {options}): void {
    return this.updateQuery(ctx, {options});
  }

  // @ts-ignore
  @Action(UpdateInventoryBillingActiveSiderSection)
  updateInventoryBillingActiveSiderSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
