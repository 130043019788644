import { Injectable } from "@angular/core";
import { FormatService } from "../../shared/format/format.service";
import { GridService } from "../../shared/grid/grid.service";
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class ShortInvoiceGridService extends GridService {
  defaultColumns = [
    {
      caption: "Invoice Number",
      headerCellTemplate: "invNumTemplate",
      dataField: "sp_inv_num",
      width: 220,
    },
    {
      caption: "Vendor",
      dataField: "account.vendor.name",
      width: 130,
    },
    {
      caption: "BAN",
      dataField: "acct_level_1",
      width: 150,
    },
    {
      caption: "Invoice Date",
      dataField: "inv_date",
      cellTemplate: 'dateTemplate',
      alignment: "left",
      width: 130,
    },
    {
      caption: "Total New Charges",
      dataField: "tot_new_chgs",
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: "currency",
      alignment: 'right',
      width: 130,
    },
  ];

  constructor(
    public userSettings: UserSettingsService,
    public formatService: FormatService
  ) {
    super("app.invoice.short-invoices-grid", userSettings);
  }

  // columns() {
  //   let settings = this.userSettings.entrySettings(this.name);
  //   if (settings && settings.columns) {
  //     return settings.columns;
  //   }
  //   return this.defaultColumns;
  // }
}
