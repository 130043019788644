
import {of as observableOf, Observable } from 'rxjs';

import {takeUntil, debounceTime} from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import { CustomValidators } from 'ng2-validation';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { DialogService } from '../../../shared/dialog/dialog.service';
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';

import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { Contact } from '../../core/contact';
import { ContactService } from '../../core/contact.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { Address } from '../../../address/core/address.model';
import { AddressService } from '../../../address/core/address.service';
import { DictionaryService } from '../../../dictionary/core/dictionary.service';
import Query from '../../../core/query/query';
import {
  IMessagesResourceService,
  ResourcesService
} from '../../../core/resources/resources.service';
import { DataLockService } from '../../../core/data-lock/data-lock.service';
import { EntityEditContext } from '../../../shared/entity-lock/entity-edit-context';
import { EntityLockData } from '../../../shared/entity-lock/entity-lock-data';
import {User} from "../../../user/core/user";
import {UserService} from "../../../user/core/user.service";
import {LocationService} from "../../../location/core/location.service";
import {Country} from "../../../location/core/country";
import {flatMap} from "rxjs/internal/operators";

@Component({
  selector: 'app-contact-manage-dialog',
  templateUrl: './contact-manage-dialog.component.html',
  styleUrls: ['./contact-manage-dialog.component.scss']
})
export class ContactManageDialogComponent extends PageManageDialogComponent
  implements OnInit, OnDestroy {
  contact: any;
  selectedFunction: any;
// contact functions we get from API
  contactFunctions: Array<any> = [];
// contact functions used in UI which are mutated
  contactFunctionsUI: Array<any>;
  address: Address;
  formModeEnabled = false;

  emailUnique = true;
  emailQuery: Query = new Query({ limit: 1 });

  messages: IMessagesResourceService;
  messagesCore: IMessagesResourceService;

  phoneRegexMask = '(000) 000-0000';
  phoneRegex;
  phoneCountryCode: string = '';
  user: User;
  me: User;

  public isContactInternal: boolean;

  readonly MESSAGES_MODULE: string = 'contact';
  readonly MESSAGES_MODULE_CORE: string = 'core';

  readonly CONTACT_FUNCTION_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.CONTACT_FUNCTION.modelName;

  readonly CONTACT_TYPE_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.CONTACT_TYPE.modelName;

  readonly CONTACT_TYPE_ENUM: any = LOOKUP_ENUM.CONTACT_TYPE;

  readonly COMPONENT_VALIDATION = {
    REQUIRED_ERROR: 'Field is required',
    EMAIL_ERROR: 'Email is invalid',
    EMAIL_ALREADY_USED: 'Email is already in use',
  };
  phonecountryDisabled: boolean = true;

  constructor(
    private cdr: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    public alert: AlertService,
    public contactService: ContactService,
    public userService: UserService,
    public locationService: LocationService,
    public dialogService: DialogService,
    public dictionaryService: DictionaryService,
    public dialogRef: MatDialogRef<ContactManageDialogComponent>,
    public addressService: AddressService,
    public dataLockService?: DataLockService
  ) {
    super(dialogService, dialogRef, dataLockService);

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.messagesCore = ResourcesService.messages(this.MESSAGES_MODULE_CORE);
  }

  ngOnInit() {
    this.contact = this.contact || new Contact();
    this.isUpdate = !!this.contact.id;
    this.address = this.isUpdate && this.contact.address ? this.contact.address : null;

    this.phoneCountryCode = this.address ? this.address.country.phone_country_code : '';
    let replacedCode;
    if (this.phoneCountryCode && this.phoneCountryCode.length) replacedCode = this.phoneCountryCode.replace(/\d/g, '0');
    if (this.address) this.phoneRegexMask = this.address.country.phone_regex_mask.replace(replacedCode, '').trim();
    if (this.address) this.phoneRegex = this.address.country.phone_regex;

    this.form = this.formBuilder.group({
      first_name: [
        this.contact ? this.contact.first_name : '',
        Validators.required
      ],
      last_name: [
        this.contact ? this.contact.last_name : '',
        Validators.required
      ],
      title: [this.contact ? this.contact.title : ''],
      vendor_id: [this.contact ? this.contact.vendor_id : ''],
      site_id: [this.contact ? this.contact.site_id : ''],
      email: [
        this.contact ? this.contact.email : '',
        Validators.compose([Validators.required, CustomValidators.email])
      ],
      office_phone: [
        this.contact && this.contact.office_phone ? this.contact.office_phone.replace(this.phoneCountryCode, '') : '', Validators.pattern(this.phoneRegex)
      ],
      office_phone_extension: [
        this.contact ? this.contact.office_phone_extension : ''
      ],
      mobile_number: [
        this.contact && this.contact.mobile_number ? this.contact.mobile_number.replace(this.phoneCountryCode, '') : '', Validators.pattern(this.phoneRegex)
      ],
      contact_type_id: [this.contact ? this.contact.contact_type_id : ''],
      customer_id: [this.contact ? this.contact.customer_id : ''],
      address2: [this.contact.address2 ? this.contact.address2 : ''],
      address3: [this.contact.address3 ? this.contact.address3 : '']
    });

    this.formTitle = this.isUpdate ? 'Edit Contact' : 'Create Contact';

    this.form.controls['email'].valueChanges.pipe(
      debounceTime(250),
      takeUntil(this.destroy$),)
      .subscribe(value => {
        this.emailUnique = true;
        if (value) {
          if (!this.form.controls['email'].hasError('email')) {
            this.emailQuery.set('email', { $ilike: value });
            // this.emailQuery.set('id', { $ne: this.contact.id || -Date.now() });
            this.contactService.findAll(this.emailQuery).subscribe(
              result => {
                if (
                    (result.items.length && this.isUpdate && this.contact.id === result.items[0].id) ||
                    !result.items.length
                  ) {
                  this.emailUnique = true
                } else {
                  this.emailUnique = false
                }
                // this.emailUnique = !result.total;
              },
              err => {
                this.emailUnique = false;
              }
            );
          }
        }
      });

    this.form.controls.contact_type_id.valueChanges.pipe(
      takeUntil(this.destroy$))
      .subscribe(type_id => {
        const { contact_type_id } = this.contact;

        if (contact_type_id !== type_id) {
          this.form.controls.vendor_id.reset();
          this.form.controls.customer_id.reset();
        }

        this.isContactInternalCheck(type_id);
      });

    this.dictionaryService
      .getByLookup(this.CONTACT_FUNCTION_LOOKUP_MODEL).pipe(
      takeUntil(this.destroy$))
      .subscribe(result => {
        this.contactFunctions = result.items;
        if (this.contact.contact_functions) {
          let functionIds = this.contact.contact_functions.map(item => item.id);
          this.contactFunctionsUI = this.contactFunctions.filter(item => {
            return functionIds.indexOf(item.id) < 0;
          });

          this.contactFunctions = this.contactFunctionsUI;

          if (this.contact && this.isUpdate) {
            const { contact_type_id } = this.contact;
            this.isContactInternalCheck(contact_type_id);
          }
        }
      });

    this.initViewOnlyForm(
      this.contact.id,
      'View Contact',
      'Edit Contact',
      this.dataLockService.DATA_LOCK_TYPES.CONTACT
    );
    this.afterInit();
  }

  init() {
    if (this.contact && this.contact.id) {
      return this.contactService
        .findByIdForEdit(
          this.contact.id,
          new EntityEditContext({
            dialogRef: this.dialogRef
          })
        ).pipe(
        flatMap(contact => {
          this.contact = contact;
          return observableOf(contact);
        }));
    }
  }

  addFunction() {
    if (!this.contact.contact_functions) {
      this.contact.contact_functions = [];
    }
    this.contact.contact_functions.push(
      Object.assign({}, this.selectedFunction)
    );

    let index = this.contactFunctionsUI
      .map(obj => obj.id)
      .indexOf(this.selectedFunction.id);
    this.contactFunctionsUI.splice(index, 1);
    this.selectedFunction = null;
  }

  removeFunction(functionObject) {
    let index = this.contact.contact_functions
      .map(obj => obj.id)
      .indexOf(functionObject.id);
    this.contact.contact_functions.splice(index, 1);
    this.contactFunctionsUI.push(functionObject);
  }

  onAddressChanged(address: Address) {
    if (address) {
      if (address.internal || address.is_validated) {
        this.address = new Address(address);
      } else {
        this.address = null;
      }
    } else {
      this.address = null;
    }

    let countryId = address ? address.country_id : 1;
    this.address && this.setPhoneRegex(countryId, true);
  }

  onCountryChanged(country: Country) {
    this.phoneCountryCode = country.phone_country_code || '';
    const replacedCode = this.phoneCountryCode.replace(/\d/g, '0');
    this.phoneRegexMask = country.phone_regex_mask.replace(replacedCode, '').trim();
    this.phoneRegex = country.phone_regex;
    this.form.get('office_phone').markAsTouched();
    this.form.get('mobile_number').markAsTouched();
    this.cdr.markForCheck();
  }

  onFormModeChanged(isFormMode: boolean) {
    this.formModeEnabled = isFormMode;
  }

  onSubmit({ value, valid }: { value: Contact; valid: boolean }) {
    if (valid && this.address && this.validatePhoneNumbers(value)) {
      value.first_name = value?.first_name?.trim();
      value.last_name = value?.last_name?.trim();
      this.contact = Object.assign({}, this.contact, value);

      this.addressCheck(this.address).subscribe(
        (address: Address) => {
          this.contact.address_id = address.id;

          if (this.contact.id) {
            this.update(this.contact);
          } else {
            this.create(this.contact);
          }
        },
        err => {
          this.alert.error('', this.messages.get('CREATE_ADDRESS_ERROR'));
        }
      );
    } else {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }

  validatePhoneNumbers(value: Contact): boolean {
    value.office_phone = value.office_phone ? this.normalizePhoneNumber(value.office_phone) : null;
    value.mobile_number = value.mobile_number ? this.normalizePhoneNumber(value.mobile_number) : null;
    return true;
  }

  normalizePhoneNumber(phone: string): string {
    return phone.replace(/[^\d]/g, '');
  }

  isNumber(val: string) {
    const pattern = /^\d+$/;
    return pattern.test(val); // returns a boolean
  }

  addressCheck(address: Address): Observable<Address> {
    if (address.internal && address.id) {
      return observableOf(address);
    } else {
      return this.addressService.createOrUpdate(address);
    }
  }

  create(contact: Contact) {
    this.clearFields(contact);

    if (this.phoneCountryCode) {
      if (contact.office_phone && !contact.office_phone.includes(this.phoneCountryCode)) {
        contact.office_phone = contact.office_phone && contact.office_phone.length ? this.phoneCountryCode + contact.office_phone : null;
      }

      if (contact.mobile_number && !contact.mobile_number.includes(this.phoneCountryCode)) {
        contact.mobile_number = contact.mobile_number && contact.mobile_number.length ? this.phoneCountryCode + contact.mobile_number : null;
      }
    }

    this.toggleDialogButtons();
    this.contactService.create(contact).subscribe(
      result => {
        this.closeDialog(result, true);
      },
      err => {
        this.toggleDialogButtons(false);
        const message = (err.error && err.error['message'] == 'Duplicate error') ? 'DUPLICATE_CONTACT' : 'CREATE_ERROR';
        this.alert.error('', this.messages.get(message));
      }
    );
  }

  update(contact: Contact) {
    this.clearFields(contact);

    if (this.phoneCountryCode) {
      if (contact.office_phone && !contact.office_phone.includes(this.phoneCountryCode)) {
        contact.office_phone = contact.office_phone && contact.office_phone.length ? this.phoneCountryCode + contact.office_phone : null;
      }

      if (contact.mobile_number && !contact.mobile_number.includes(this.phoneCountryCode)) {
        contact.mobile_number = contact.mobile_number && contact.mobile_number.length ? this.phoneCountryCode + contact.mobile_number : null;
      }
    }

    this.toggleDialogButtons();
    this.contactService.update(contact.id, contact).subscribe(
      result => {
        this.closeDialog(result, true);
      },
      err => {
        this.toggleDialogButtons(false);
        const message = (err.error && err.error['message'] == 'Duplicate error') ? 'DUPLICATE_CONTACT' : 'UPDATE_ERROR';
        this.alert.error('', this.messages.get(message));
      }
    );
  }

  deleteContact(event) {
    if (this.isUpdate && event) {
      this.toggleDialogButtons();
      this.contactService.delete(this.contact.id).subscribe(
        deleteResult => {
          if (deleteResult) {
            this.dialogRef.close({ deleted: true });
          }
        },
        err => {
          this.toggleDialogButtons(false);
          if (err.data === 'SequelizeForeignKeyConstraintError') {
            setTimeout(() => {
              this.alert.error('', this.messages.get('CONSTRAIN_DELETE_ERROR'));
            }, 250);
          } else {
            setTimeout(() => {
              this.alert.error('', this.messages.get('DELETE_ERROR'));
            }, 250);
          }
        }
      );
    }
  }

  public clearFields(contact) {
    switch (contact.contact_type_id) {
      case this.CONTACT_TYPE_ENUM.INTERNAL: {
        contact.vendor_id = null;
        contact.customer_id = null;
        break;
      }
      case this.CONTACT_TYPE_ENUM.VENDOR: {
        contact.customer_id = null;
        break;
      }
      case this.CONTACT_TYPE_ENUM.CUSTOMER: {
        contact.vendor_id = null;
        break;
      }
    }
  }

  editForm() {
    this.contactService
      .findByIdForEdit(this.contact.id)
      .subscribe((result: EntityLockData) => {
        if (result['$lock'] && result['$lock']['status'] === 403) {
          this.alert.error(
            '',
            `${this.messagesCore.get('DATA_LOCKED')}${result['$lock']['user']}`
          );
        } else {
          this.form.enable();
          this.viewOnly = false;
          this.formTitle = this.editTitle;
        }
      });
  }

  public isContactInternalCheck(value: number) {
    this.isContactInternal = value === this.CONTACT_TYPE_ENUM.INTERNAL;
    if (!this.isContactInternal) {
      this.selectedFunction = null;
      this.contact.contact_functions = [];
    } else {
      this.contactFunctionsUI = [...this.contactFunctions];
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  cancel() {
    this.contactService.cancelEdit();
    this.closeDialog();
  }

  private setPhoneRegex(countryId: number, touched: boolean = false) {
    if (countryId) {
      this.locationService.findCountryById(countryId)
        .subscribe(result => {
          this.phoneCountryCode = result.phone_country_code || '';
          const replacedCode = this.phoneCountryCode.replace(/\d/g, '0');
          this.phoneRegexMask = result.phone_regex_mask.replace(replacedCode, '').trim();
          this.phoneRegex = result.phone_regex;

          if (touched) {
            this.form.get('office_phone').markAsTouched();
            this.form.get('mobile_number').markAsTouched();
          }
        });
    }
  }
}
