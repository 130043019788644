<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Projects</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button  *permission="'Create'" matTooltip="New Project" mat-icon-button (click)="newProject()">
      <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>
  <button  *permission="'Modify'" mat-icon-button [disabled]="!selection" (click)="editProject(selection)">
      <ca-icon [key]="'EDIT'" [tooltip]="'Edit Project'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh List" (click)="refresh()">
      <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
      <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
      <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button matTooltip="Filter" [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"  (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button>
      <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button #toggleDetailsEnableButton [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}" [disabled]="!selection"
          (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" mat-icon-button>
          <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">

  <dx-data-grid #grid (onSelectionChanged)="onSelectionChanged($event)"
                [columns]="columns"
                (onCellClick)="onCellClick($event)"
                class="app-grid-with-pager"
                [width]="'100%'"
                [height]="'100%'"
                [dataSource]="projects">

    <div *dxTemplate="let d of 'dateTemplate'">
      <div caGridTooltip>{{ d.value | caDate }}</div>
    </div>

  </dx-data-grid>
  <ca-pager  [gridPager]="defaultGridPager" [pageContext]="projectGridService"  [query]="query" class="app-pager ca-default-border-top" (onChange)="loadData(query)"></ca-pager>


  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                [query]="query"
                [filterService]="projectFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter()">
    </new-app-filter>

    <app-project-sneak-peek [project]="selection"
                            (closeRequested)="sider?.close()"
                            [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"></app-project-sneak-peek>
  </mat-sidenav>

</mat-sidenav-container>
