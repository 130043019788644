import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Demarc} from "./demarc.model";
import {Restangular} from "ngx-restangular";

@Injectable()
export class DemarcService extends BaseService<Demarc> {

  constructor(public restangular: Restangular) {
    super('demarc', restangular);
  }

  getDemarcs(siteId: number, networkCircuitId?: number): any {
    return this.service().all(this.name).one('site', siteId).one('circuit', networkCircuitId).customGET(null, null);
  }

}
