<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
    <h1 mat-dialog-title>Grid Settings</h1>
</div>

<div mat-dialog-content class="hide-overflow" class="ca-dialog-content grid-settings">
  <form class="flex-container ca-tab tabbed-content settings-form centered-tabs" fxLayout="row" fxLayoutAlign="center">
    <div fxFlex="100%">
      <mat-tab-group (selectedIndexChange)="onSettingsTabChange($event)" [(selectedIndex)]="selectedIndex">
        <mat-tab label="Columns">
          <div class="modal-content-dialog ca-default-border grid-columns-form content-height">
            <ul class="list-group" dnd-sortable-container [sortableData]="columns">
              <div *ngFor="let column of columns; let i = index" class="card-container">
                <mat-card fxFlex class="list-group-item ca-default-border-bottom" dnd-sortable [sortableIndex]="i"
                    (onDropSuccess)="onSortDone($event)"
                    *ngIf="column.dataField" caCard>
                  <div fxLayout="row" fxLayoutAlign="center">
                    <div fxFlex="30px" class="center vertical-center">
                      <i class="material-icons center-icon">reorder</i>
                    </div>
                    <div fxFlex class="grid-settings-caption vertical-center">
                      {{column.caption | columnCaption}}
                    </div>
                    <div fxFlex="80px" class="center vertical-center" *ngIf="column.fixed">
                      <i style="font-size: 20px" class="material-icons">lock</i>
                    </div>
                    <mat-slide-toggle [name]="column.dataField" [(ngModel)]="column.visible"
                                     [disabled]="column.important"
                                     [ngModelOptions]="{standalone: true}">
                    </mat-slide-toggle>
                  </div>
                </mat-card>
              </div>
            </ul>
          </div>
        </mat-tab>
        <mat-tab label="Sorting / Fixed">
          <div class="modal-content-dialog ca-default-border grid-sort-form content-height">
            <ul class="list-group" dnd-sortable-container [sortableData]="selectedSortingColumns" style="margin-bottom: 14px !important;">
              <div class="card-container" dnd-sortable [sortableIndex]="i"
                  *ngFor="let sortColumn of selectedSortingColumns; let i = index">
                <mat-card class="ca-form list-group-item ca-default-border-bottom card-4" fxLayout="row" fxLayoutAlign="start space-between" fxLayoutGap="24px">
                  <div fxFlex="30px" class="vertical-center">
                    <i class="material-icons" >reorder</i>
                  </div>
                  <div fxFlex class="grid-settings-caption">

                    <ca-default-picker hiDensityInput>
                      <mat-form-field class="grid-settings-fields">
                          <mat-select placeholder="Select column"
                                      [ngModelOptions]="{standalone: true}"
                                      (selectionChange)="sortingColumnAdd($event, i)"
                                      [(ngModel)]="sortColumn.column.dataField">
                            <ng-container *ngFor="let column of columns">
                                <mat-option *ngIf="column.dataField"
                                  [value]="column.dataField"
                                  [disabled]="disableColumn(column)">
                                  {{ column.caption | columnCaption }}
                                </mat-option>
                            </ng-container>
                          </mat-select>
                      </mat-form-field>
                    </ca-default-picker>


                  </div>
                  <div fxFlex="25%" class="vertical-center">

                    <ca-default-picker hiDensityInput>
                      <mat-form-field class="grid-settings-fields">
                          <mat-select name="sortDirection" placeholder="Sort order" [ngModelOptions]="{standalone: true}" [(ngModel)]="sortColumn.sortDirection">
                              <mat-option [value]="'ASC'">ASC</mat-option>
                              <mat-option [value]="'DESC'">DESC</mat-option>
                            </mat-select>
                      </mat-form-field>
                    </ca-default-picker>


                  </div>
                  <div fxFlex="40px" class="right">
                    <button mat-icon-button (click)="removeSortingColumn(sortColumn, i)"
                            [disabled]="!sortColumn?.column?.dataField">
                            <ca-icon [tooltip]="'Clear Column Sorting'" [key]="'GRID_SETTINGS_CLEAR'"></ca-icon>
                    </button>
                  </div>
                </mat-card>
              </div>
            </ul>
            <div>
              <span class="fixed-column-label">Fixed columns</span>
              <mat-slider (change)="fixedColumnsChange()" [min]="sliderMinimum" [max]="sliderMaximum" thumbLabel="true" [(ngModel)]="fixedColumns" [ngModelOptions]="{standalone: true}"></mat-slider>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Reset">
          <div fxLayout="column" class="modal-content-dialog content-height reset-style reset-settings-text" style="margin: 1px 0">
            <p>A reset will remove your personalized changes and restore the original default settings for this control.</p>
            <p class="reset-settings-text-second-line">To reset to factory defaults, select the 'Restore defaults' checkbox and click the SAVE button.</p>
            <mat-checkbox [(ngModel)]="resetConfirmValue" [ngModelOptions]="{standalone: true}">
              <span class="reset-settings-checkbox-text">Restore defaults</span>
            </mat-checkbox>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </form>
</div>
<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="submit" (click)="close()">
    Cancel
  </button>
  <button mat-button type="submit" (click)="save()" [disabled]="!resetConfirmValue && selectedIndex==2">
    Save
  </button>
</mat-dialog-actions>
