export const contractScheduleDetailRateFilterContext = {
    filters: [
      {
        field: 'section_name',
        label: 'Section Name',
        type: 'string',
        index: 1
      },
      {
        field: 'rate_code',
        label: 'Rate Code',
        type: 'string',
        index: 2
      },
      {
        field: 'svc_chg_desc',
        label: 'Charge Description',
        type: 'string',
        index: 4
      },
      {
        field: 'rate_qty',
        label: 'Rate Qty',
        type: 'negativeNumber',
        index: 5
      },
      {
        field: 'rate_qty_uom.value',
        label: 'Rate Qty Uom',
        type: 'lookup',
        // editor: 'checkboxList',
        lookupProviderName: 'rateQtyUom',
        index: 6
      },
      {
        field: 'rate_qty_type',
        label: 'Rate Qty Type',
        type: 'string',
        index: 7
      },
      {
        field: 'rate_type_obj.value',
        label: 'Rate Type',
        type: 'lookup',
        lookupProviderName: 'rateType',
        index: 8
      },
      {
        field: 'rate_category_obj.value',
        label: 'Rate Category',
        type: 'lookup',
        lookupProviderName: 'rateCategory',
        index: 9
      },
      {
        field: 'mrc',
        label: 'MRC',
        type: 'negativeNumber',
        index: 10
      },
      {
        field: 'currency.currency',
        label: 'Currency',
        type: 'lookup',
        lookupProviderName: 'currency',
        index: 11
      },
      {
        field: 'jur',
        label: 'Jur',
        type: 'string',
        index: 12
      },
      {
        field: 'subdivision.abbreviation',
        label: 'State',
        type: 'lookup',
        lookupProviderName: 'state',
        index: 13
      },
      {
        field: 'country.alpha_2_code',
        label: 'Country',
        type: 'lookup',
        lookupProviderName: 'country',
        index: 14,
        visible: true
      },
      {
        field: 'rate_term_qty',
        label: 'Rate Term Qty',
        type: 'number',
        index: 15
      },
      {
        field: 'unit_of_measure.value',
        label: 'Rate Term Uom',
        type: 'lookup',
        lookupProviderName: 'rateTermUom',
        index: 15
      },
      {
        field: 'rate_effective_date',
        label: 'Effective Date',
        type: 'lookup',
        editor: 'dateRanges',
        lookupProviderName: 'defaultDateRanges',
        index: 16
      },
      {
        field: 'rate_termination_date',
        label: 'Termination Date',
        type: 'lookup',
        editor: 'dateRanges',
        lookupProviderName: 'defaultDateRanges',
        index: 17
      },
      {
        field: 'rate_note',
        label: 'Rate Note',
        type: 'string',
        index: 18
      }
    ]
  };
