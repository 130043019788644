import {AfterContentInit, Component, EventEmitter, Input} from '@angular/core';
import * as moment from 'moment';
import {FilterBaseComponent} from '../filter-base.component';
import {FormControl, FormGroup} from '@angular/forms';
import {FilterEntry} from "../../../core/filter/filter-entry";
import {MatSelectChange} from "@angular/material/select";

const OPERATORS = {
  GTE: '$gte',
  LTE: '$lte',
  BETWEEN: '$between',
};

enum Ranges {
  PRIOR_MONTH = 'PRIOR_MONTH',
  PRIOR_3_MONTHS = 'PRIOR_3_MONTHS',
  PRIOR_6_MONTHS = 'PRIOR_6_MONTHS',
  PRIOR_YEAR = 'PRIOR_YEAR',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  LAST_WEEK = 'LAST_WEEK',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  CUSTOM = 'CUSTOM'
}

@Component({
  selector: 'cas-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss']
})
export class FilterDateComponent implements AfterContentInit, FilterBaseComponent {
  private _value: any;
  readonly Ranges = Ranges;
  readonly DATE_FORMAT = 'YYYY-MM-DD'
  range: string = '';

  ranges = [
    {key: [Ranges.PRIOR_MONTH], label: 'Prior month'},
    {key: [Ranges.PRIOR_3_MONTHS], label: 'Prior 3 months'},
    {key: [Ranges.PRIOR_6_MONTHS], label: 'Prior 6 months'},
    {key: [Ranges.PRIOR_YEAR], label: 'Prior year'},
    {key: [Ranges.LAST_30_DAYS], label: 'Last 30 days'},
    {key: [Ranges.LAST_90_DAYS], label: 'Last 90 days'},
    {key: [Ranges.LAST_WEEK], label: 'Last week'},
    {key: [Ranges.YEAR_TO_DATE], label: 'Year To Date'},
    {key: [Ranges.CUSTOM], label: 'Range'},
  ];

  get value(): any {
    return this._value;
  }

  @Input() set value(value: any) {
    const currentValue = value?.hasOwnProperty('currentValue') ? value?.currentValue : value;
    if (!FilterEntry.isEqual(currentValue, this._value)) {
      this._value = currentValue;
      this.setValues(currentValue);
    }
  }

  @Input() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() entry: FilterEntry;

  form: FormGroup = new FormGroup({
    from: new FormControl('', []),
    to: new FormControl('', []),
  });

  ngAfterContentInit(): void {
    this.form.valueChanges.subscribe(() => {
      this.update();
    });
  }

  clearInternalDateQueryRanges() {
    this.range = '';
  }

  rangeChange($event: MatSelectChange) {
    this.range = $event.value;
    if ($event.value === Ranges.CUSTOM) {
      this.form.patchValue({
        from: '',
        to: '',
      })
    }
    this.update();
  }

  private setValues(value: any): void {
    if (!value) {
      this.range = '';
      this.form.patchValue({
        from: '',
        to: '',
      })
      return;
    }


    const gpx = value && value['$gpx'];
    if (gpx) {
      setTimeout(() => {
        this.range = gpx;
      }, 100);
    }

    const values = this.getSimpleValues(value);
    this.form.patchValue({
      from: values?.from,
      to: values?.to,
    })
  }

  private update(): void {
    const result = this.getValue();
    this._value = result;
    this.change.emit(result);
  }

  private getSimpleValues(value): any {
    if (!value) {
      return undefined;
    }
    if (value[OPERATORS.BETWEEN]) {
      const values = value[OPERATORS.BETWEEN];
      return {from: values[0], to: values[1]};
    } else if (value[OPERATORS.GTE]) {
      const values = value[OPERATORS.GTE];
      return {from: values};
    } else if (value[OPERATORS.LTE]) {
      const values = value[OPERATORS.LTE];
      return {to: values};
    }
  }

  private getValue(): any {
    let operator = OPERATORS.GTE;
    const {from, to} = this.getRangeValues();
    let result;

    if (!this.valueExists(from)
      && !this.valueExists(to)) {
      return null;
    }

    if (this.valueExists(from)
      && this.valueExists(to)) {
      operator = OPERATORS.BETWEEN;
      result = [from, to];
    } else if (this.valueExists(from)) {
      operator = OPERATORS.GTE;
      result = from;
    } else if (this.valueExists(to)) {
      operator = OPERATORS.LTE;
      result = to;
    }

    return {
      [operator]: result,
      $gpx: Array.isArray(this.range) ? this.range[0] : this.range
    };
  }

  private getRangeValues(): { from: Date | null, to: Date | null } {

    let from;
    let to;
    const range = this.range?.toString();

    switch (range) {
      case Ranges.CUSTOM:
        from = this.form.get('from').value ? moment(this.form.get('from').value).format(this.DATE_FORMAT) : null;
        to = this.form.get('to').value ? moment(this.form.get('to').value).format(this.DATE_FORMAT) : null;
        break;
      case Ranges.PRIOR_MONTH:
        const {from: fp, to: tp} = this.getPriorMonthRange();
        from = fp;
        to = tp;
        break;
      case Ranges.PRIOR_3_MONTHS:
        const {from: fp3, to: tp3} = this.getPriorMonthRange(3);
        from = fp3;
        to = tp3;
        break;
      case Ranges.PRIOR_6_MONTHS:
        const {from: fp6, to: tp6} = this.getPriorMonthRange(6);
        from = fp6;
        to = tp6;
        break;
      case Ranges.LAST_30_DAYS:
        from = moment().subtract(30, 'days').format(this.DATE_FORMAT)
        break;
      case Ranges.LAST_90_DAYS:
        from = moment().subtract(90, 'days').format(this.DATE_FORMAT)
        break;
      case Ranges.LAST_WEEK:
        const {from: fl, to: tl} = this.getLastWeekDateRange();
        from = fl;
        to = tl;
        break;
      case Ranges.PRIOR_YEAR:
        const {from: fy, to: ty} = this.getPriorYearRange();
        from = fy;
        to = ty;
        break;
      case Ranges.YEAR_TO_DATE:
        const {from: ys, to: td} = this.getYearToDateRange();
        from = ys;
        to = td;
        break;
      default:
        break;
    }

    return {from, to};
  }

  private valueExists(val: any): boolean {
    return val !== undefined
      && val !== null
      && val !== '';
  }

  private getLastWeekDateRange() {
    return {
      from: moment().subtract(1, 'weeks').startOf('week').format(this.DATE_FORMAT),
      to: moment().subtract(1, 'weeks').endOf('week').format(this.DATE_FORMAT),
    }
  }

  private getYearToDateRange() {
    const from = moment().startOf('year').format(this.DATE_FORMAT);
    const to = moment().endOf('year').format(this.DATE_FORMAT);
    return {
      from,
      to
    }
  }

  private getPriorMonthRange(months = 1) {
    const from = moment().subtract(months, 'months').startOf('month').format(this.DATE_FORMAT);
    const to = moment().startOf('month').format(this.DATE_FORMAT);
    return {
      from,
      to
    }
  }

  private getPriorYearRange() {
    return {
      from: moment().subtract(1, 'years').startOf('year').format(this.DATE_FORMAT),
      to: moment().subtract(1, 'years').endOf('year').format(this.DATE_FORMAT),
    }
  }
}
