export const alertBatchInvoicesFilterContext = {
  filters: [
    {
      field: 'invoice.invoice_id',
      label: 'Invoice ID',
      type: 'number',
      index: 1
    },
    {
      label: 'Invoice Number',
      field: 'invoice.sp_inv_num',
      type: 'string',
      index: 2
    },
    {
      field: 'message',
      label: 'Message',
      type: 'string',
      index: 3
    },
    {
      field: 'invoice.vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 4
    },
    {
      field: 'invoice.acct_level_1',
      label: 'Account',
      type: 'lookup',
      lookupProviderName: 'account',
      index: 5
    },
    {
      field: 'invoice.inv_date',
      label: 'Invoice Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 6
    },
  ]
};
