<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>

  <span class="toolbar-details-title">{{ invoice?.sp_inv_num }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>

  <button mat-icon-button matTooltip="Download as PDF"
          (click)="downloadPDF()"
          [hidden]="isDownloadPDFHidden"
  >
    <ca-icon [key]="'PDF_DOWNLOAD'"></ca-icon>
  </button>

  <button
    mat-icon-button
    (click)="csv()"
    [disabled]="isCSVDisabled"
    [hidden]="isCSVHidden"
  >
    <ca-icon [key]="'DOWNLOAD'" [tooltip]="'Download CSV'"></ca-icon>
  </button>

  <button
    *ngIf="!invoice?.header?.is_singular_feed"
    mat-icon-button
    matTooltip="Download the container file"
    (click)="downloadContainer()"
    [hidden]="isDownloadContainerHidden"
  >
    <ca-icon [key]="'FILE_SOURCE_DOWNLOAD'"></ca-icon>
  </button>

  <button
    *ngIf="invoice?.header?.is_singular_feed"
    mat-icon-button
    matTooltip="Download the source or container file"
    [matMenuTriggerFor]="sourceMenu"
    [hidden]="isDownloadContainerHidden"
  >
    <ca-icon [key]="'FILE_SOURCE_DOWNLOAD'"></ca-icon>
  </button>
  <mat-menu #sourceMenu="matMenu">
    <button mat-menu-item (click)="downloadSingularSource()">
      <span> Vendor Source </span>
    </button>
    <button mat-menu-item (click)="downloadContainer()">
      <span> Invoice File </span>
    </button>
  </mat-menu>

  <!-- 
  *ngIf="invoice?.header?.is_singular_feed" 

  [disabled]="!selection || (selection && (selection.status_id == DISPUTE_STATUS_ENUM.CLOSED_LOST ||  selection.status_id == DISPUTE_STATUS_ENUM.CLOSED_WON))
   
  -->
  <button mat-icon-button *permission="{'action':'Delete', 'key':'charges'}"
          (click)="deleteCharges()"
          [disabled]="isDeleteChargesDisabled"
          [hidden]="isDeleteChargesHidden"
  >
    <ca-icon [key]="'DELETE_MANUAL_CHARGE'" [tooltip]="'Delete charges'"></ca-icon>
  </button>

  <button *permission="{'action':'CREATE', 'key':'charges'}" mat-icon-button (click)="addManualAdjustment()"
          matTooltip="Add Manual Adjustment"
          [disabled]="isManualAdjustmentDisabled"
          [hidden]="isManualAdjustmentHidden"
  >
    <ca-icon [key]="'ADD_MANUAL_CHARGE'"></ca-icon>

  </button>

  <button
    mat-icon-button
    matTooltip="Choose option"
    [matMenuTriggerFor]="dropDownMenu"
    [hidden]="isDropDownFileHidden"
    (click)="documentDropDownCheck()"
  >
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
    <mat-menu #dropDownMenu="matMenu">
      <button mat-menu-item
              (click)="downloadPDF()"
              [hidden]="isDropDownDownloadPDFHidden"
      > Download Invoice Face Page
      </button>
      <button mat-menu-item
              (click)="csv()"
              [disabled]="isDropDownCsvDisabled"
              [hidden]="isDropDownCsvHidden">
        <span> Download Grid Data</span>
      </button>
      <button [hidden]="!invoice?.header?.is_singular_feed" mat-menu-item (click)="downloadSingularSource()">
        <span> Vendor Source </span>
      </button>
      <button mat-menu-item (click)="downloadContainer()">
        <span> Download Container </span>
      </button>
      <button mat-menu-item (click)="downloadDocument()" [disabled]="!isSelectedDocument">
        <span> Download Selected </span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="uploadDocument()" [disabled]="isDropDownUploadDocumentDisabled">
        <span> Upload Document </span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="editDocument()"
              [disabled]="!isSelectedDocument || (isSelectedDocument && isSelectedSystemFile)">
        <span> Edit Document Info</span>
      </button>
      <button mat-menu-item (click)="deleteDocument()"
              [disabled]="!isSelectedDocument || (isSelectedDocument && isSelectedSystemFile)">
        <span> Delete Document </span>
      </button>
    </mat-menu>
  </button>

  <div *permission="{'action':'Modify', 'key':'invoices'}" class="vertical-divider"></div>
  <button mat-icon-button *permission="{'action':'Modify', 'key':'invoices'}"
          (click)="moveInvoiceToDoNotProcess()"
          [disabled]="isDoNotProcessDisabled"
          [hidden]="isDoNotProcessHidden"
  >
    <ca-icon [key]="'QUOTE_CLOSE'" [tooltip]="'Do Not Process'"></ca-icon>
  </button>
  <button *permission="{'action':'View', 'key':'gl'}"
          mat-icon-button
          (click)="goToRuleExecutionPage()"
          [hidden]="isGoToRuleExecutionPageHidden"
  >
    <ca-icon [key]="'VIEW_INVOICE_DETAILS'" [tooltip]="'Rule Execution'"></ca-icon>
  </button>

  <button mat-icon-button *permission="{'action':'Modify', 'key':'invoices'}"
          [matMenuTriggerFor]="documentMenu"
          [hidden]="isDocumentTypeHidden">
    <ca-icon [key]="'DOCUMENT_TYPE'" [tooltip]="'Document Type'"></ca-icon>
  </button>

  <button
    *permission="{'action':'Create', 'key':'disputes'}"
    mat-icon-button
    [disabled]="isAddDisputeDisabled"
    (click)="addDispute()"
    [hidden]="isAddDisputeHidden"
  >
    <ca-icon [key]="'ADD_DISPUTE'" [tooltip]="'Create Dispute'"></ca-icon>
  </button>

  <button mat-icon-button *permission="{'action':'Delete', 'key':'invoices'}"
          (click)="deleteInvoice()"
          [disabled]="isDeleteInvoiceDisabled || hasDispute"
          [hidden]="isDeleteInvoiceHidden"
  >
    <ca-icon [key]="'DELETE'" [tooltip]="'Delete Invoice'"></ca-icon>
  </button>


  <button *permission="{'action':'Modify', 'key':'accounts'}" mat-icon-button
          (click)="putOnHold()"
          [disabled]="isOnHoldDisabled"
          [hidden]="isOnHoldHidden"
  >
    <ca-icon key="ON_HOLD" [tooltip]="'On Hold'"></ca-icon>
  </button>

  <button mat-icon-button
          [disabled]="isChangeAdjustmentDisabled"
          [hidden]="isChangeAdjustmentHidden"
          *permission="{'action':'Modify', 'key':'invoices'}"
          [matMenuTriggerFor]="menu">
    <ca-icon [key]="'ADJUSTMENT'" [tooltip]="'Change Adjustments'"></ca-icon>
  </button>

  <button
    *permission="{'action':'Modify', 'key':'invoices'}"
    [disabled]="isGLChargeViewSwitchDisabled"
    [ngClass]="{on: isGLChargeViewSwitchOn}"
    (click)="glChargeViewSwitch()"
    mat-icon-button
    [hidden]="isGLChargeViewSwitchHidden"
  >
    <ca-icon [key]="'FORMAT_DECREASE'" [tooltip]="'Grouping'"></ca-icon>
  </button>

  <div class="vertical-divider"
       *ngIf="(!isAddDirectGLHidden || !isEditDirectGLHidden)"
  ></div><!--*permission="{'action':'Modify', 'key':'gl'}"-->

  <button
    mat-icon-button
    *permission="{'action':'Create', 'key':'audits'}"
    [disabled]="isAddAuditDisabled"
    (click)="createNewRateAudit()"
    [hidden]="isAddAuditHidden">
    <ca-icon [key]="'RATE_AUDIT_ADD_RULE'" [tooltip]="'Add Audits'"></ca-icon>
  </button>

  <button
    *permission="{'action':'Create', 'key':'gl'}"
    mat-icon-button
    type="button"
    [disabled]="isAddDirectGLDisabled"
    [hidden]="isAddDirectGLHidden"
    (click)="createDirectGL()"
  >
    <ca-icon
      [key]="'GL_BATCH_ADD_INVOICE'"
      [tooltip]="'Add Direct GL Codes'"
    ></ca-icon>
  </button>

  <button
    *permission="{'action':'Modify', 'key':'gl'}"
    mat-icon-button
    type="button"
    [disabled]="isEditDirectGLDisabled"
    [hidden]="isEditDirectGLHidden"
    (click)="editDirectGL()"
  >
    <ca-icon [key]="'GL_EDIT_STRING'" [tooltip]="'Edit Direct GL'"></ca-icon>
  </button>
  <div class="vertical-divider"
       *permission="{'action':'Modify', 'key':'gl'}"
  ></div>

  <mat-menu class="ca-user-menu" #menu="matMenu">
    <ng-container *ngFor="let key of unsetAdjustmentsKeys">
      <button mat-menu-item (click)="changeAdjustment(unsetAdjustments[key])">
        {{ adjustmentOptions[key] }}
      </button>
    </ng-container>
  </mat-menu>
  <mat-menu class="ca-user-menu" #documentMenu="matMenu">
    <ng-container *ngFor="let item of documentInvoiceType">
      <button
        mat-menu-item
        style="text-transform: capitalize"
        (click)="changeInvoiceDocumentType(item)">
        {{ item.split('_').join(' ').toLowerCase() }}
      </button>
    </ng-container>
  </mat-menu>

  <!--<mat-menu class="ca-user-menu" #documentMenu="matMenu">-->
  <!--<ng-container *ngFor="let item of invoiceActions">-->
  <!--<button-->
  <!--mat-menu-item-->
  <!--style="text-transform: capitalize"-->
  <!--(click)="changeInvoiceDocumentType(item)">-->
  <!--{{ item.split('_').join(' ').toLowerCase() }}-->
  <!--</button>-->
  <!--</ng-container>-->
  <!--</mat-menu>-->

  <button
    mat-icon-button
    (click)="openGridSettings()"
    [disabled]="isGridSettingsDisabled"
    [hidden]="isGridSettingsHidden"
  >
    <ca-icon [key]="'GRID_SETTINGS'" [tooltip]="'Settings'"></ca-icon>
  </button>
  <div class="vertical-divider" [hidden]="isGridSettingsHidden"></div>

  <button
    mat-icon-button
    [ngClass]="{ on: isFilterOn }"
    [disabled]="isFilterDisabled"
    [hidden]="isFilterHidden"
    (click)="toggleFilter()">
    <ca-icon [key]="'FILTER'" [tooltip]="'Filter'"></ca-icon>
  </button>

  <button
    matTooltip="Notes"
    [ngClass]="{ on: isNotesOn }"
    (click)="toggleNotes()"
    mat-icon-button
  >
    <ca-icon [key]="notesCount > 0 ? 'NOTES' : 'NOTES_EMPTY'"></ca-icon>
  </button>

  <button
    [ngClass]="{ on: isHistoryOn }"
    (click)="toggleHistory()"
    mat-icon-button
    [disabled]="isHistoryDisabled"
  >
    <ca-icon [key]="'HISTORY'" [tooltip]="'History'"></ca-icon>
  </button>
</mat-toolbar>
