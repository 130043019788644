export class QueryParamType {

  static String = "string";
  static Number = "number";
  static NegativeNumber = "negativeNumber";
  static Date = "date";
  static Boolean = "boolean";
  static Lookup = "lookup";
  static List = "list";


  static find(name: string) {
    switch (name) {
      case "string":
        return QueryParamType.String;
      case "number":
        return QueryParamType.Number;
      case "negativeNumber":
        return QueryParamType.NegativeNumber;
      case "date":
        return QueryParamType.Date;
      case "boolean":
        return QueryParamType.Boolean;
      case "lookup":
        return QueryParamType.Lookup;
      case "list":
        return QueryParamType.List;
    }
  }

}
