<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Inventory</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button
    *permission="{'action':'Create', 'key':'inventory'}"
    matTooltip="New Inventory" mat-icon-button (click)="newInventory()">
    <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>
  <button
    *permission="{'action':'Modify', 'key':'inventory'}"
    mat-icon-button
    (click)="editInventory(selection)"
    [disabled]="!selection"
  >
    <ca-icon [key]="'EDIT'" [tooltip]="'Edit Inventory'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh List" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <button
    *ngIf="permissionOrderCreate"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    [disabled]="!isCreateOrderEnabled()"
  >
    <ca-icon
      [key]="'ORDER_TYPE'"
      [tooltip]="'Create Order'"
    ></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button
    matTooltip="Filter"
    [ngClass]="{ on: sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
    (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)"
    mat-icon-button
  >
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button
    #toggleDetailsEnableButton
    [ngClass]="{ on: sider?.isActive(SECTIONS.DETAILS_SECTION_NAME) }"
    [disabled]="!selection"
    (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)"
    mat-icon-button
  >
    <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">
  <dx-data-grid
    #grid
    keyExpr="id"
    (onRowClick)="onRowClick($event)"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onCellClick)="onCellClick($event)"
    [columns]="columns"
    [height]="'100%'"
    [width]="'100%'"
    class="grid-with-selection-badge"
    [dataSource]="inventories"
  >
    <div
      *dxTemplate="let d of 'selectionBadgeHeaderTemplate'"
      class="grid-selection-badge-container"
    >
      <a
        class="selection-badge grid-badge"
        matTooltip="Selection"
        (click)="loadSelected()"
        [ngClass]="{ active: selectionActive }"
      >
        {{ selectedRows.length }}
      </a>
    </div>

    <div *dxTemplate="let d of 'selectionBadgeCellTemplate'"></div>

    <div *dxTemplate="let d of 'customerProvidedAccessTemplate'">
      <div caGridTooltip>
        {{ d.value === true ? "Yes" : d.value === false ? "No" : null }}
      </div>
    </div>

    <div *dxTemplate="let d of 'siteAtemplate'" style="text-align: left;" class="customer-inv-grid-site-template">
      <div class="customer-inv-grid-sites-rows-wraper">
        <div caGridTooltip class="customer-inv-grid-site-name">{{d.data.siteA?.site_id}}</div>
        <div caGridTooltip
             class="customer-inv-grid-site-address">{{d.data.siteA?.building?.address?.search_address}}</div>
      </div>
    </div>

    <div *dxTemplate="let d of 'siteZtemplate'" style="text-align: left;" class="customer-inv-grid-site-template">
      <div class="customer-inv-grid-sites-rows-wraper">
        <div caGridTooltip class="customer-inv-grid-site-name">{{d.data.siteZ?.site_id}}</div>
        <div caGridTooltip
             class="customer-inv-grid-site-address">{{d.data.siteZ?.building?.address?.search_address}}</div>
      </div>
    </div>

    <div *dxTemplate="let d of 'inventoryCurrencyTemplate'">
      {{d.data.currency}}
      <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency_obj.currency }}</div>
    </div>
    
    <div *dxTemplate="let d of 'dateTemplate'">
      <div caGridTooltip>{{ d.value | caDate }}</div>
    </div>
  </dx-data-grid>

  <ca-pager
    [gridPager]="defaultGridPager"
    [pageContext]="inventoryGridService"
    [query]="query"
    class="app-pager ca-default-border-top"
    (onChange)="loadData(query)"
  ></ca-pager>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <app-filter-container [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                          [query]="query"
                          [filterService]="inventoryFilterService"
                          (filterRequested)="filterData($event)"
                          (clearFilterRequested)="clearFilter()"></app-filter-container>

    <ca-inventory-sneak-peek
      [inventory]="selection"
      (closeRequested)="sider?.close()"
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME) }"
    ></ca-inventory-sneak-peek>
  </mat-sidenav>

  <mat-menu class="ca-user-menu" #menu="matMenu">
    <ng-container *ngFor="let item of adjustmentsKeys">
      <button mat-menu-item (click)="createOrder(item)" style="text-transform: capitalize">
        {{ item.split('_').join(' ').toLowerCase() }}
      </button>
    </ng-container>
  </mat-menu>

</mat-sidenav-container>
