<ca-picker [textField]="TEXT_FIELD"
           [customFormating]="CUSTOM_FORMATING"
           [keyField]="KEY_FIELD"
           [returnWholeEntry]="returnWholeEntry"
           [control]="control"
           [entries]="entries"
           [initialItem]="initialItem"
           (onScrollEnd)="onScrollEnd($event)"
           (onSearchChange)="onSearchChange($event)"
           (onSelectionChange)="onSelectionChange($event)"
           [disabled]="disabled"
           [required]="required"
           [multiple]="multiple"
           [searchEnabled]="searchEnabled"
           [placeholder]="placeholder"
           [clearEnabled]="clearEnabled"
           [italicStyleOptionPredicate]="italicStyleOptionPredicate"
           #caPicker
           [secondLineTextField]="SECOND_LINE_TEXT_FIELD"
           pickerType="pickerType"></ca-picker>

