export const LOOKUP_ENUM = {
  SYSTEM_MODULE: {
    QUOTE: 10,
    QUOTE_SERVICE: 11,
    QUOTE_SERVICE_REQUEST: 12,
    ORDER: 20,
    ORDER_SERVICE: 21,
    ACCOUNT: 30,
    CHARGE: 40,
    CONTRACT: 50,
    CUSTOMER_INVENTORY: 60,
    DISPUTE: 70,
    INVENTORY: 80,
    INVOICE: 90,
    AUDIT_RATE: 100,
    CUSTOMER_EQUIPMENT: 110,
    NETWORK_HUB: 120,
    TELEPHONE_NUMBER: 130,
    SERVICE_ORDERS:140
  },
  ACCOUNT_STATUS: {
    ACTIVE: 1,
    INACTIVE: 0,
  },
  INVOICE_STATUS: {
    NEW: 100,
    NEW_REJECTED: 101,
    NEW_RESET: 102,
    NEW_PENDING: 103,
    UNSET_ADJUSTMENTS: 140,
    GL_CODING_IN_PROCESS: 150,
    GL_PENDING: 151,
    GL_CODED_PARTIAL: 154,
    GL_CODED_FULL: 155,
    GL_CODING_MISMATCH: 156,
    GL_CODING_FAILED: 157,
    PRE_GL_WARNING: 158,
    VAT_GL_WARNING: 159,
    VAT_GL_MISMATCH: 160,
    RFA: 200,
    APPROVED: 300,
    GL_BATCH_PROCESSING: 310,
    GL_BATCH_OUTPUT: 400,
    GL_BATCH_OUTPUT_SEND: 450,
    GL_BOOKING: 455,
    GL_BATCH_ERROR: 456,
    GL_BATCH_IN_PROCESS: 457,
    GL_BATCH_SENT: 458,
    PAYMENT_SENT: 460,
    GL_POSTED: 461,
    BOOKING_CANCELED: 462,
    PAYMENT_CONFIRMED: 463,
    PAYMENT_ERROR: 464,
    INVOICE_DELETED: 466,
    INVOICE_REVERSED: 467,
    REVERSE_AP_FEED_RECEIVED: 480,
    DO_NOT_PROCESS: 490,
    COMPLETED: 500,
  },
  DOCUMENT_INVOICE_TYPE: {
    INVOICE: 10,
    CREDIT_MEMO: 20,
  },
  UNSET_ADJUSTMENTS: {
    NONE: 0,
    INCLUDED: 10,
    EXCLUDED: 20,
  },
  ALERT_STATUS: {
    NEW: 10,
    IN_PROGRESS: 20,
    COMPLETE: 30,
  },
  ALERT_PRIORITY: {
    INFO: 10,
    SEVERE: 20,
    WARNING: 30,
  },
  ALERT_TYPE: {
    CHARGE: 10,
    INVENTORY: 20,
    INVENTORY_MRC: 25,
    RATE_AUDIT: 80,
    MULTIPLE_AUDITS: 100,
    DISCONNECT_AUDIT: 110,
    MISSING_INVENTORY: 120
  },
  AUDIT_TYPE: {
    SYSTEM: 10,
    RATE_AUDIT: 20
  },
  RATE_AUDIT_OPERATOR_FIELD: {
    EQUALS: 10,
    CONTAINS: 20
  },
  ALERT_CATEGORY: {
    AUDIT: 10,
    ERROR: 20,
  },
  ALERT_LEVELS: {
    EMERGENCY: 0,
    ALERT: 1,
    CRITICAL: 2,
    ERROR: 3,
    WARNING: 4,
    NOTICE: 5,
    INFO: 6,
    DEBUG: 7,
    TRACE: 8,
  },
  INVENTORY_STATUS: {
    NEW: 10,
    ACTIVE: 20,
    IN_PROCESS: 30,
    DISCONNECTED: 40,
    PENDING_DISCONNECT: 50,
    CANCELLED: 60,
    INACTIVE: 70,
  },
  INVENTORY_TOPOLOGY: {
    /*RING: 10,*/
    POINT_TO_POINT: 20,
  },
  INVENTORY_TYPE: {
    CIRCUIT: 10,
  },
  CHARGE_TYPE: {
    ADJNC: 10,
    ADJAD: 11,
    ADJBF: 12,
    ADJMAN: 13,
    MRC: 20,
    OCC: 30,
    DISCOUNTS: 40,
    USAGE: 50,
    TAX: 60,
    SUR: 70,
  },
  CHARGE_TYPE_KEY: {
    ADJNC: "ADJNC",
    ADJAD: "ADJAD",
    ADJBF: 'ADJBF',
    ADJMAN: "ADJMAN",
    MRC: "MRC",
    OCC: "OCC",
    DISCOUNTS: "DISCOUNTS",
    USAGE: "USAGE",
    TAX: "TAX",
    SUR: "SUR",
  },
  CONTACT_FUNCTION: {
    SALES_ENGINEER: 10,
    SALES_REP: 20,
    WAREHOUSE: 40,
    TECHNICIAN: 50,
    RESOURCE_MANAGER: 60,
    BILLING_CONTACT: 70,
  },
  CONTRACT_TYPE: {
    SUPPORTING_DOC: 10,
    SETTLEMENT_AGREEMENT: 20,
    MSA: 30,
    QUOTE: 40,
    SVC_ORDER: 50,
    SS_AMENDMENT: 60,
    SVC_SCHEDULE: 70,
  },
  PROJECT_STATUS: {
    ACTIVE: 1,
    INACTIVE: 0,
  },
  DISPUTE_STATUS: {
    FILED: 10,
    CLOSED_LOST: 20,
    IN_PROGRESS: 30,
    CLOSED_WON: 40,
  },
  DISPUTE_WITHHELD_STATUS: {
    NOT_WITHHELD: 10,
    PARTIALLY_WITHHELD: 20,
    FULL_WITHHELD: 30,
  },
  GL_CODE_SEGMENT: {
    SEGMENT_1: 1,
    SEGMENT_2: 2,
    SEGMENT_3: 3,
    SEGMENT_4: 4,
    SEGMENT_5: 5,
    SEGMENT_6: 6,
    SEGMENT_7: 7,
    SEGMENT_8: 8,
    SEGMENT_9: 9,
    SEGMENT_10: 10,
  },
  GL_RULE_FIELD: {
    SPID: 10,
    SUBACCOUNT: 20,
    CHARGE_CODE_1: 30,
    CHARGE_DESCRIPTION_1: 40,
    CHARGE_CODE_2: 50,
    CHARGE_DESCRIPTION_2: 60,
    CHARGE_CODE_3: 70,
    CHARGE_DESCRIPTION_3: 80,
  },
  ORDER_STATUS: {
    NEW: 10,
    NEW_REJECTED: 11,
    NEW_RESET: 12,
    RFA: 20,
    RFA_PRIOR_REJECT: 21,
    APPROVED: 30,
    SENT_TO_VENDOR: 40,
    SENT_TO_VENDOR_REWORK: 41,
    VENDOR_ACK_DATE: 50,
    VENDOR_REJECT: 60,
    VENDOR_ACCEPT_RECEIVED: 61,
    CANCELLED: 70,
    COMPLETED: 80,
  },
  ORDER_SERVICE_STATUS: {
    NEW_PENDING: 10,
    NEW: 11,
    NEW_RESET: 12,
    NEW_REJECTED: 13,
    RFA: 20,
    VENDOR_ACCEPTED: 30,
    FOC_DATE: 40,
    SERVICE_INSTALL: 50,
    SERVICE_TEST: 60,
    TEST_FAILED: 61,
    TEST_PASSED: 62,
    CANCELLED: 70,
    COMPLETED: 80,
  },
  ORDER_SERVICE_DISCONNECT_STATUS: {
    NEW_PENDING: 10,
    NEW: 11,
    NEW_RESET: 12,
    NEW_REJECTED: 13,
    VENDOR_ACCEPTED: 20,
    DISCONNECT_DATE: 30,
    STOP_BILLING_DATE: 40,
    DISCONNECT_COMPLETE_DATE: 50,
    FINAL_BILLING_DATE: 60,
    CANCELLED: 70,
    COMPLETED: 80,
  },
  ORDER_TYPE: {
    INSTALL: 10,
    DISCONNECT: 20,
    RECORD_ONLY: 30,
    CHANGE: 40,
  },
  CONTACT_TYPE: {
    VENDOR: 10,
    CUSTOMER: 20,
    INTERNAL: 30,
  },
  QUOTE_STATUS: {
    DRAFT: 10,
    SENT: 20,
    ARCHIVED: 30,
  },
  INVENTORY_TECHNOLOGY: {
    ETHERNET: 1,
    TDM: 2,
    INTERNAL: 3,
  },
  QUOTING_SERVICE_TYPE: {
    ACCESS_CIRCUIT: 10,
    P2P: 20,
    OTHER: 30,
  },
  QUOTE_SERVICE_TERM: {
    MTM: 4,
    ONE_YEAR: 1,
    TWO_YEAR: 2,
    THREE_YEAR: 3,
    FIVE_YEAR: 5,
    SEVEN_YEAR: 6,
  },
  QUOTE_SERVICE_MEDIUM: {
    FIBER: 10,
    COOPER: 20,
    EOC: 30,
    UBL: 40,
  },
  QUOTE_SERVICE_STATUS: {
    OPEN: 10,
    CLOSED: 20,
    CANCELED: 30,
    NA: 40,
  },
  CONTRACT_QUOTE_FAILURE_REASON: {
    NO_BUILDING: 10,
    NO_QUOTE_BUILDING: 20,
    NO_RATE: 30,
  },
  DOCUMENT_TYPE: {
    MSA: 10,
    SERVICE_SCHEDULE: 20,
    SUPPORTING_DOCUMENT: 30,
    AMENDMENT: 40,
  },
  QUOTE_HEADER_STATUS: {
    OPEN: 10,
    CLOSED: 20,
  },
  QUOTE_SERVICE_OFFER_METHOD: {
    EMAIL: 10,
    CONTRACT: 30,
    API: 20,
    MANUAL: 40,
  },
  QUOTE_SERVICE_OFFER_STATUS: {
    UNKNOWN: 0,
    SENT: 5,
    COMPLETE: 10,
    FAILED: 20,
  },
  INVENTORY_HANDOFF_TYPE: {
    TEST1: 10,
    TEST2: 20,
  },
  QUOTE_OPPORTUNITY_TYPE: {
    TEST_1: 10,
    TEST_2: 20,
    TEST_3: 30,
    TEST_4: 40,
  },
  INVENTORY_PRIMARY_FAILOVER: {
    PRIMARY: 1,
    FAILOVER: 2,
  },
  NET_TYPE: {
    ON_NET: 1,
    OFF_NET: 2,
  },
  NETWORK_HUB_TYPE: {
    TDM: 10,
    ETHERNET_NNI: 20,
  },
  NETWORK_HUB_STATUS: {
    ACTIVE: 10,
    DISABLED: 20,
  },
  NETWORK_HUB_CAPACITY: {
    OC_12: 10,
    DS_3: 20,
    "1_GB": 30,
    "10_GB": 40,
    "600_MBPS": 50,
  },
  NETWORK_HUB_CAPACITY_TYPES: {
    10: "OC12",
    20: "DS3",
    30: "E-NNI",
    40: "E-NNI",
  },
  INVENTORY_CKT_TYPE: {
    100: "Data",
    110: "Voice",
    120: "Voice/Data",
  },
  TECHNOLOGY_TYPE_ENUM: {
    TDM: 5,
    ETHERNET: 2,
  },
  CUSTOMER_EQUIPMENT_STATE: {
    NEW: 0,
    NEW_NOT_INSTALLED: 10,
    USED_INSTALLED: 20,
    USED_NOT_INSTALLED: 30,
    USED_IN_REPAIR: 40,
    DISPOSED: 50,
  },
  CUSTOMER_EQUIPMENT_TRANSACTION_TYPE: {
    NEW_TO_WAREHOUSE: 10,
    WAREHOUSE_TO_INSTALLER: 20,
    CUSTOMER_INSTALL: 30,
    CUSTOMER_DEINSTALL: 40,
    USED_TO_WAREHOUSE: 50,
    WAREHOUSE_TO_REPAIR: 60,
    REPAIR_TO_WAREHOUSE: 70,
    WAREHOUSE_TO_DISPOSAL: 80,
  },
  CUSTOMER_EQUIPMENT_TYPE: {
    ROUTER: 1,
    PHONE: 2,
  },
  TN_TNF_STATUS: {
    SPARE: 10,
    IN_SERVICE: 20,
    NOT_ACTIVE: 30,
  },
  TN_TNF_TYPE: {
    DID: 10,
    TFN: 20,
  },
  TN_TRANSACTION_TYPE: {
    ADD_NUMBERS: 10,
    ASSIGN_TO_CUSTOMER: 20,
    ADDING_FROM_PORT_INS: 30,
    DEACTIVATE_FROM_PORT_OUTS: 40,
  },
  SITE_TYPES_ENUM: {
    CUSTOMER: 1,
    VENDOR: 2,
    INTERNAL: 3,
  },
  GL_VALIDATION_RULES: {
    INVENTORY: 10,
    INVENTORY_SPID: 20,
    SPID: 30,
    SUBACCOUNT: 40,
  },
  TAX_TYPE: {
    VAT: 10,
  },
  RULE_TYPE: {
    GL_CODING: 1,
    VAT_RULE: 2,
    VAT_EXEMPT_RULE: 3,
    GL_CODING_VAT_RULE: 4,
    GL_CODING_VAT_EXEMPT_RULE: 5,
  },
  SYSTEM_REGION: {
    NAM: 1,
    ASIA_UK: 2,
    EMEA: 3,
  },
  HISTORY_ENTITY_TYPE:{
    inventory:'inventory',
    account:'account',
    dispute:'dispute',
    dispute_charge: 'dispute_charge',
    customer_inventory: 'customerinventory'
  },
  ALERT_STATUS_STRING: {
    NEW: "NEW",
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETE: "COMPLETE",
  },
  UNIT_OF_MEASURE: {
    modelName: "UnitOfMeasure",
    displayName: "Contract Rate UoM"
  },
  RATE_TYPE: {
    modelName: "RateType",
    displayName: "Rate Type"
  },
  RATE_CATEGORY: {
    modelName: "RateCategory",
    displayName: "Rate Category"
  },
  BATCH_OUTPUT_FORMAT:{
    DXC: "dxc",
    DXC_CSC: "dxc-csc",
    DXC_MF: "dxc-mf",
    TAILWIND: "tailwind",
    BCM_ONE: "bcm-one",
    RING_SQUARED: "ring-squared"
  },
  CONTRACT_SCHEDULE_SERIES: {
    modelName: "ContractScheduleSeries",
    displayName: "Contracts Schedule Series"
  },
  CONTRACT_SCHEDULE_CATEGORY: {
    modelName: "ContractScheduleCategory",
    displayName: "Contracts Schedule Category"
  },
  CONTRACT_RATE_QTY_UOM: {
    modelName: 'RateQtyUom',
    displayName: 'Rate Qty Unit of Measure'
  },
  CONTRACT_DOCUMENT_TYPE: {
    modelName: "ContractDocumentType",
    displayName: "Contract Document Type"
  },
  SYSTEM_SCHEDULE_SERIES:{
    MSA: 1,
    REVENUE_COMMITMENT:2
  },
  INVENTORY_CONTRACT_TYPE: {
    CONTRACT: 10,
    SVC_ORDER: 20,
    UNKNOWN: 30
  },
  CONTRACT_SCHEDULE_COMMITMENT_TYPE: {
    MONTHLY: 1,
    ANNUAL: 2,
    TERM: 3
  },
  INVOICE_ATTACHMENT_TYPE: {
    PROXY_INVOICE: 1,
    VENDOR_INVOICE: 2,
    UNI_BILL: 3,
    ELECTRONIC: 4,
    OTHER: 5
  },
  BULK_UPLOAD_TRANSACTION_TYPE:{
    BULK_UPLOAD_TRANSACTION_TYPE_UPLOAD: 10,
    BULK_UPLOAD_TRANSACTION_TYPE_VALIDATION: 20
  },
};
