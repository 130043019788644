import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({
  name: 'caInvoiceCurrency'
})
export class CaInvoiceCurrencyPipe extends CurrencyPipe implements PipeTransform {
// :"USD":true:"1.2-2"
  transform(value: any, currencyCode = 'USD', symbolDisplay: any = 'symbol-arrow', digits = '1.2-2'): any {
    let formatedByCurrencyPipe = super.transform(value, currencyCode, symbolDisplay, digits);
    if (formatedByCurrencyPipe == null) {
      return null;
    }

    return formatedByCurrencyPipe.charAt(0) === '-' ?
      '(' + formatedByCurrencyPipe.substr(1) + ')' :
      formatedByCurrencyPipe;
  }
}
