import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { Restangular } from 'ngx-restangular';

import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import Query from 'app/core/query/query';
import {HttpClient} from "@angular/common/http";
import { ApiService } from 'app/core/api';
import {SvcOrderHeader} from "./svc-order-header";
import {SvcOrderServices} from "./svc-order-service";

@Injectable()
export class SvcOrderService extends BaseService<SvcOrderHeader | SvcOrderServices> {
  section: string = 'service';

  constructor(public restangular: Restangular,
              public http: HttpClient,
              private entityLock: EntityLockService,
              private apiService: ApiService) {
    super('svc-order', restangular, entityLock);
  }

  findAllByVendor(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.apiService.get([this.name, 'findAllByVendorID'], transformedQuery);
  }


  findAllServices(id, query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return id ?
      this.apiService.get([this.name, id, 'services'], transformedQuery) :
      this.apiService.get([this.name, 'services'], transformedQuery);
  }

  findServiceById(id): any {
    return this.service().one(this.name).one('service', id).customGET();
  }

  public createService(item) {
      return this.service().one(this.name).one(this.section).customPOST(item);
    }

  public updateService(id, item) {
    return this.service().one(this.name).one(this.section, id).customPUT(item);
  }

  public deleteService(headerId, serviceId) {
    return this.service().one(this.name).one(this.section, serviceId).remove();
  }
}

