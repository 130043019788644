export class Role {
  id: number;
  name: string;
  is_system: boolean;
  type: string;
  description: string;
  settings: RoleSettings;
  permissions?:any;

  constructor(data?: any) {
    Object.assign(this, data);
  }
}

export class RoleSettings {
  accounts: Array<any> = [];
  sites: Array<any> = [];
  permissions: Array<any> = [];
  role: any;
  vendors: Array<any> = [];
  resources: any;
  filters: any;

  constructor(data?: any) {
    Object.assign(this, data);
  }
}
