<mat-tab-group
  #invoiceTabGroup
  (selectedIndexChange)="onTabChange($event)"
  [selectedIndex]="activeTabIndex">
  <mat-tab
    *ngFor="let tab of tabs"
    [label]="tab.label"
    [disabled]="tab.key === 'alerts' && !alertCount">
    <ng-template mat-tab-label *ngIf="tab.showBadge && alertCount" style="display: flex; align-items: center;">
      <span class="tab-title">{{tab.label}}</span>
      <span *ngIf="alertCount > 9" class="badge badge-danger badge-number indented">9+</span>
      <span *ngIf="alertCount <= 9" class="badge badge-danger badge-number ">{{alertCount}}</span>
    </ng-template>
  </mat-tab>
</mat-tab-group>
