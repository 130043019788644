<div>
    <dx-filter-builder [fields]="fields" [(value)]="filter"
        [customOperations]="customOperations" [groupOperations]="groupOperations"
        (onContentReady)="onContentReady($event)"
        (onValueChanged)="updateTexts($event)"
        (onInitialized)="updateTexts($event)">
        <dx-tag-box *dxTemplate="let condition of 'tagBoxTemplate'" [value]="condition.value" [items]="condition.field.lookup.dataSource"
            (onValueChanged)="condition.setValue($event.value)" width="150px"></dx-tag-box>
    </dx-filter-builder>
    <div class="dx-clearfix"></div>
    <!--&lt;!&ndash; this is used for formating JSON data from dx to query format span>-->
    <!--<span>{{filterText}}</span>-->
    <!--<br>-->
    <!--<br>-->
    <!--<span>{{myFormatText}}</span>-->

</div>
