import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Project} from "../../core/project";

@Component({
  selector: 'app-project-sneak-peek',
  templateUrl: './project-sneak-peek.component.html',
})
export class ProjectSneakPeekComponent {

  constructor() { }

  @Input() project: Project;

  @Output() closeRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();

  deleteProject() {
    this.deleteRequested.emit(this.project);
  }

  closeSide() {
    this.closeRequested.emit();
  }
}
