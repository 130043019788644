<div class="ca-card ca-card-sneak-peek">
  <mat-card caCard>
    <mat-card-content>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Service ID</label>
          <p>{{ quoteService?.quote_service_id || "N/A" }}</p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Service Type</label>
          <p>{{ quoteService.quote_service_type?.value || "N/A" }}</p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Quote Status</label>
          <p>{{ quoteService?.quote_service_status?.value || "Open" }}</p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Port Bandwidth</label>
          <p>{{ quoteService.port_bandwidth?.value || "N/A" }}</p>
        </div>

        <div fxFlex class="entry-list-item">
          <label>Bandwidth</label>
          <p>{{ quoteService.circuit_bandwidth?.value || "N/A" }}</p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Desired Install Date</label>
          <p>{{ (quoteService?.desired_install_date | date) || "N/A" }}</p>
        </div>

        <div fxFlex class="entry-list-item">
          <label>Create Date</label>
          <p>{{ (quoteService?.create_date | date) || "N/A" }}</p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
