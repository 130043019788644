import {Injectable} from '@angular/core';
import {FilterService} from "../../../core/filter/filter.service";
import {DictionaryService} from "../../../dictionary/core/dictionary.service";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {LOOKUP_MODELS_ENUM} from "../../../dictionary/core/lookup-models.enum";
import {LookupModelLookupProvider} from "../../../dictionary/core/lookup-model-lookup.provider";
import { DocumentFilterContext } from "./document-filter.context";

@Injectable()
export class DocumentFilterService extends FilterService {

  constructor(public dictionaryService: DictionaryService,
              public userSettingsService: UserSettingsService) {

    super("app.cost.document-filter", DocumentFilterContext, userSettingsService);

    // this.addLookupProvider('documentType', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.DOCUMENT_TYPE.modelName));

  }

  addFilteredLookupProvider(query) {
    let name = 'documentType';
    let existing = this.getLookupProvider(name);
    if (existing) {
      return;
    }
    this.addLookupProvider(name, new LookupModelLookupProvider(this.dictionaryService, LOOKUP_MODELS_ENUM.DOCUMENT_TYPE.modelName, true ,false, query));

    //this.addLookupProvider(name, new LookupModelLookupProvider(this.dictionaryService, LOOKUP_MODELS_ENUM.DOCUMENT_TYPE.modelName, false, query));
  }

}
