import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";

enum ESortDirection {
  ASC = 'ASC',
  DSC = 'DSC'
}

interface IColumn {
  dataField?: string
}

interface ISortingColumn {
  column: IColumn;
  sortDirection: ESortDirection;
}

class SelectedSortingColumn {
  column: IColumn = {};
  sortDirection: ESortDirection = ESortDirection.ASC;
}

@Component({
  selector: 'app-filter-settings',
  templateUrl: 'grid-settings.component.html',
  styleUrls: ['grid-settings.component.scss']
})
export class GridSettingsComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<GridSettingsComponent>) {
  }

  availableColumns: any[] = [];
  sliderMinimum: number;
  sliderMaximum: number = 3;
  resetConfirmValue: boolean;
  selectedIndex: number;
  service: any;
  settings: any;
  columns = [];
  sortColumns = [];
  fixedColumns = 0;
  fixedColumnsList = [];
  selectedSortingColumns: ISortingColumn[] = this.getDefaultSortingColumns();

  private lockedDataFields: string[] = [];

  ngOnInit() {
    this.sliderMinimum = this.sliderMinimum ? this.sliderMinimum : 0;
    this.sliderMaximum = this.sliderMinimum + 3;
    let columns = [];
    let sortingColumns = [];
    let excludedColumns = [];

      this.service.loadSettings()
        .subscribe((settings) => {
          columns = this.service.getColumns(settings ? settings.columns : []);
          sortingColumns = settings && settings.sorting ? settings.sorting : [];
          columns.forEach((column) => {
            if (!isNotNullOrUndefined(column.visible)) {
              column.visible = true;
            }
            if (isNotNullOrUndefined(column.showInSettings) && !column.showInSettings) {
              excludedColumns.push(column)
            }
            if (column.fixed) {
              this.fixedColumns++;
            }
          });

          excludedColumns.forEach(col => {
            columns.splice(columns.indexOf(col), 1);
          })

          this.columns = columns;

          let i = 0;
          sortingColumns.forEach((column) => {
            if (i < 3) {
              this.selectedSortingColumns[i].column = this.getSelectedColumn(column);

              if (!isNotNullOrUndefined(column.sortDirection)) {
                column.sortDirection = Object.assign({}, column, {sortDirection: ESortDirection.ASC});
              }

              this.selectedSortingColumns[i].sortDirection = column.sortDirection;
              i++;
            }
          });

          this.sortColumns = sortingColumns;
          this.availableColumns = this.columns.filter((column) => {
            return !this.findByField(this.sortColumns, column.dataField);
          });

          this.updateLockedFields();
        });
  }

  resetToDefault() {
    this.columns = [];
    this.selectedSortingColumns = this.getDefaultSortingColumns();
    this.availableColumns = [];
    this.lockedDataFields = [];

    this.service.userSettings.save(this.service.name);
  }

  onSettingsTabChange(event) {
    this.resetConfirmValue = false;
  }

  onSortDone(event) {
    setTimeout(() => {
      this.columns.forEach((column, index) => {
        column.fixed = index < this.fixedColumns;
      });
    })
  }

  fixedColumnsChange() {
    this.columns.forEach((column, index) => {
      column.fixed = index < this.fixedColumns;
    });
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  sortingColumnAdd(event, i) {
    let column = event.value;

    if (this.sortColumns.indexOf(column) === -1 && this.sortColumns.length < 3) {
      this.setSortColumns();
      this.filterAvailableColumns();
    }

    this.updateLockedFields();
  }

  removeSortingColumn(column, index) {
    this.selectedSortingColumns[index] = new SelectedSortingColumn();
    this.setSortColumns();
    this.filterAvailableColumns();
    this.updateLockedFields();
  }

  filterAvailableColumns() {
    this.availableColumns = this.columns.filter(column => {
      return !this.findByField(this.sortColumns, column.dataField)
    });
  }

  save() {

    if (this.resetConfirmValue) {
      this.resetToDefault();
    }

    this.columns.forEach((column, index) => {
      column.fixed = index < this.fixedColumns;
    });

    this.setSortColumns();
    let settings = {columns: this.columns, sorting: this.sortColumns};

    this.service.saveSettings(settings)
      .subscribe((setting) => {
        this.dialogRef.close(setting);
      });
  }

  public disableColumn(column): boolean {
    return this.lockedDataFields.indexOf(column.dataField) >= 0;
  }

  private updateLockedFields() {
    this.lockedDataFields = this.selectedSortingColumns.map(colData => {
      return colData.column.dataField
    }).filter(colDataField => !!colDataField);
  }

  private getSelectedColumn(column) {
    let columnFound = this.columns.find(obj => obj.dataField == column.dataField);
    return {dataField: columnFound.dataField};
  }

  private setSortColumns() {
    this.sortColumns = [];

    this.sortColumns = this.selectedSortingColumns.reduce((accumulator, item) => {
      if (isNotNullOrUndefined((<any>item.column).dataField)) {
        (<any>item.column).sortDirection = item.sortDirection;

        return [
          ...accumulator,
          item.column
        ];
      }

      return accumulator;
    }, []);

  }

  private findByField(list, field) {
    return list.filter((column) => {
      return column.dataField === field;
    })[0];
  }

  private getDefaultSortingColumns(): SelectedSortingColumn[] {
    return [
      new SelectedSortingColumn(),
      new SelectedSortingColumn(),
      new SelectedSortingColumn()
    ]
  }
}
