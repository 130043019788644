import {Injectable} from "@angular/core";
import {FilterService} from "../../core/filter/filter.service";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {alertAccountFilterContext} from "./alert-account-filter.context";
import {VendorService} from "../../vendor/core/vendor.service";
import {VendorLookupProvider} from "../../vendor/core/vendor-lookup.provider";
import {VendorEntityLookupProvider} from "../../vendor/core/vendor-entity-lookup.provider";
import {AccountService} from "../../account/core/account.service";
import {AccountLookupProvider} from "../../account/core/account-lookup.provider";
import {DictionaryService} from "../../dictionary/core/dictionary.service";
import {LOOKUP_MODELS_ENUM} from "../../dictionary/core/lookup-models.enum";
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import {Observable} from "rxjs";
import {CountryLookupProvider} from "../../location/core/country-lookup.provider";
import {LocationService} from "../../location/core/location.service";

export class AccountStatusLookupProvider extends IFilterLookupProvider {

  lookupModel: string = LOOKUP_MODELS_ENUM.ACCOUNT_STATUS.modelName;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    return this.dictionaryService.getByLookup(this.lookupModel, searchQuery)
      .map((result) => {
        return result.items.map((entry) => {
          return {
            key: entry.id,
            value: entry.value
          };
        });
      });
  }
}

@Injectable()
export class AlertAccountFilterService extends FilterService {

  constructor(public userSettingsService: UserSettingsService,
              public vendorService: VendorService,
              public accountService: AccountService,
              public dictionaryService: DictionaryService,
              public locationService: LocationService
    ) {
    super("app.alert.alert-account-filter", alertAccountFilterContext, userSettingsService);

    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('entity', new VendorEntityLookupProvider(vendorService));
    this.addLookupProvider('account', new AccountLookupProvider(accountService));
    this.addLookupProvider('accountStatus', new AccountStatusLookupProvider(dictionaryService));
    this.addLookupProvider('country', new CountryLookupProvider(locationService, true));
  }

}
