import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { MatDialogRef } from '@angular/material/dialog';

import { DialogService } from '../../../shared/dialog/dialog.service';
import { GLStringService } from '../../../gl-string/core/gl-string.service';
import { GLStringSelectGridService } from '../../core/gl-string-select-grid.service';
import { SortingBuilder, SortingService } from '../../../shared/sorting/sorting.service';
import Query from '../../../core/query/query';
import { GLStringGridFilterService } from '../gl-string-filter/gl-string-filter.service';
import { DialogButtonsController } from '../../../core/dialog-buttons-controller.class'
import { GlDirectService } from '../../core/gl-direct.service';
import { InvoiceFacepage } from '../../../invoice/core/invoice-facepage';
import { PermissionService } from 'app/permissions/core/permission.service';
import { AlertService } from 'app/shared/alert/alert.service';
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import { FormControl } from '@angular/forms';

import {Config} from "../../../core/config/config";
import {ConfigService} from "../../../core/config/config.service";


@Component({
  selector: 'app-gl-direct-select-grid-dialog',
  templateUrl: './gl-direct-select-grid-dialog.component.html',
  styleUrls: ['./gl-direct-select-grid-dialog.component.css']
})
export class GlDirectSelectGridDialogComponent extends DialogButtonsController implements OnInit {

  public loadedStrings: Array<any> = [];
  // original / previously assigned strings
  public assignedStrings: Array<any> = [];

  // This name is different in modes, but most common name is default
  public glApportionFieldName = 'allocation';
  protected ruleType = "directGl";
  public valid = false;
  public isEdited = false;

  public isUpdate = false;
  public dynamicIncluded = new FormControl();

  public charges = [];
  public invoice: InvoiceFacepage;

  public messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'gl';

  @Output() validChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  public columns: Array<any> = [];
  public totalApportion: any = "0";

  public isFilterOpen = false;
  public numberOfDigits: any;

  public query = new Query({
    orderBy: [['allocation', 'DESC', 'NULLS LAST'], ['full_string_formatted', 'ASC']]
  });

  public stringsSorting: SortingBuilder;

  public selectedStringsDefaultColumns: any;
  public isCleared = false;

  constructor(public glStringService: GLStringService,
              public gridService: GLStringSelectGridService,
              public dialog: DialogService,
              public sortingService: SortingService,
              public glStringGridFilterService: GLStringGridFilterService,
              public permissionService: PermissionService,
              public alertService: AlertService,
              private glDirectService: GlDirectService,
              public dialogRef: MatDialogRef<GlDirectSelectGridDialogComponent>,
              public configService: ConfigService) {
    super();
    this.stringsSorting = this.sortingService.builder();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.configService.findAll()
      .subscribe((config: Config) => {
        this.numberOfDigits = config.gl_coding.apportion_pct_decimal_places;
        this.selectedStringsDefaultColumns = this.glStringService.generateDefaultGridColumns(this.numberOfDigits, true)

        // find gl segments based on config file / expand each segment with gl_string_obj / generate grid columns
        this.glStringService.findGLSegments()
          .then((data: Array<any>) => {
            let apportionStringCols = [];

            data.forEach((o) => {
              o.caption = o.value;
              o.dataField = [o.system_key, '_obj.segment_value'].join('');
              o.allowEditing = false;
              o.width = '150px';

              let objDesc = Object.assign({}, o);
              objDesc.caption = o.value + ' Description';
              objDesc.dataField = [o.system_key, '_obj.segment_desc'].join('');

              apportionStringCols.push(o);
              apportionStringCols.push(objDesc);
            });

            this.selectedStringsDefaultColumns[0].dataField = this.glApportionFieldName;
            this.columns = this.selectedStringsDefaultColumns.concat(apportionStringCols);

            this.gridService.create(this.grid.instance, {
              noDataText: 'No Data'
            });

            this.loadDirectGL();
          });

      })
  }

  calculateTotalApportion() {
    this.totalApportion = this.glStringService.calculateTotalApportion(this.assignedStrings, this.glApportionFieldName, this.numberOfDigits, this.isCleared)
    this.valid = (parseFloat(this.totalApportion) === 100) || this.isCleared;
  }

  clearApportions() {
    this.permissionService.isAllowed("gl", "DELETE")
      .subscribe(result => {
        if (result) {
          this.isCleared = true;
          this.assignedStrings = []
          this.processGLStrings(this.loadedStrings, this.query.total, true);
        } else {
          this.alertService.error('', this.messages.get('GL_NO_PERMISSION_DELETE'));
        }
      });
  }

  // stores apportion string within (original) assignedStrings
  storeApportionStrings(object) {
    this.isEdited = true;
    this.isCleared = false;
    const id = this.findGLStringId(object.full_string_formatted, object.full_string_text);
    if (id) object['gl_string_id'] = id;
    const apportion = object[this.glApportionFieldName];
    object[this.glApportionFieldName] = apportion

    const ids = this.assignedStrings.map((o) => {
      return o.gl_string_id;
    }).filter(item => item);

    const index = ids.indexOf(id);
    // assign new value if exist / or add into assignedStrings
    if (index !== -1) {
      if (apportion && apportion !== 0) {
        this.assignedStrings[index][this.glApportionFieldName] = apportion;
      } else {
        this.assignedStrings.splice(index, 1);
      }
    } else if(!this.assignedStrings.some(item => (item.full_string_formatted === object.full_string_formatted && item.full_string_text === object.full_string_text) )) {
      this.assignedStrings.push(object);
    }

    this.calculateTotalApportion();
  }

  public queryBuilder(params) {
    return Object.assign({
      status: true
    }, params);
  }

  // get previously assigned strings / no limit
  public loadDirectGL() {
    let stamp = this.charges[0].direct_gl_stamp;
    this.glDirectService.findAll(new Query({
      where: {
        stamp: {'$eq': stamp}
      },
      limit: 10000
    }))
      .subscribe(async (result) => {
        this.assignedStrings = [...result.items]
        this.dynamicIncluded.patchValue(this.assignedStrings.length === 0 ? true : this.assignedStrings[0].dynamic_included)
        await this.loadGLStrings(this.query);
      })
  }

  public loadGLStrings(query) {
    if (query.orderBy.length === 0 || (query.orderBy.length > 0 && !query.orderBy[0].includes('allocation')))
      query.orderBy.unshift(['allocation', 'DESC', 'NULLS LAST'])
    let ruleMeta = {invoice_id: this.invoice.invoice_id, charge_id: this.charges[0].id, status: true};
    query.where.status = true;

    // get strings for grid, apportion on top
    this.glStringService.findAllWithApportions({ruleMeta, query, ruleType: this.ruleType})
      .subscribe((result) => {
        this.loadedStrings = JSON.parse(JSON.stringify(result.items))

        let total = result.total;
        this.processGLStrings(this.loadedStrings, total, false);
      });
  }

  public processGLStrings(resultset, total, isReset) {
    this.query.total = total;

    // get assigned ids to update loadedStrings with apportion value
    let ids = this.assignedStrings.map((o) => {
      return o.gl_string_id;
    });

    this.loadedStrings = resultset
      .filter((o) => {
        let index = ids.indexOf(o.gl_string_id);
        // set the apportion from the assigned (original) strings
        if (index >= 0) {
          o[this.glApportionFieldName] = this.assignedStrings[index][this.glApportionFieldName];
        } else {
          o[this.glApportionFieldName] = 0;
          o.id = null;
        }
        if (isReset) {
          o[this.glApportionFieldName] = 0;
          o.allocation = 0;
        }
        return o;
      });
    this.calculateTotalApportion()
  }

  onSelectedStringsRowEdit(event) {
    this.storeApportionStrings(event.key);
  }

  onSelectedStringsRowValidating(event) {}

  toggleFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  filterData(query) {
    this.loadGLStrings(query);
  }

  clearFilter() {
    this.query.where = { status: true }
    this.loadGLStrings(this.query);
  }

  public onCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      // this code will fix object structure that is different form table columns on API side
      let fieldParts: Array<string> = event.column.dataField.split('.');
      let field = fieldParts[1];
      if (fieldParts.length > 2) {
        field = [fieldParts[1], '.', fieldParts[2]].join('');
      }
      if (field) {
        event.column.dataField = field;
      }

      this.stringsSorting.apply(event, this.query);
      this.loadGLStrings(this.query);
    }
  }

  public refresh() {
    this.query.where = { status: true }
    this.loadGLStrings(this.query);
  }

  private findGLStringId(fullStringFormatted: string, fullStringText: string) {
    const observedStringObject = this.loadedStrings.find(glStringItem =>
      glStringItem.full_string_formatted === fullStringFormatted && glStringItem.full_string_text === fullStringText
    );
    return observedStringObject ? observedStringObject.id : null ;
  }

  close() {
    if (this.isEdited) {
      this.dialog.confirm({
        bodyText: `WARNING: You have unsaved changes. Changes will be lost without saving.`
      }).afterClosed()
        .subscribe((result) => {
          if (result) {
            this.dialogRef.close({valid: this.valid, cancel: true});
          }
        });
    } else {
      this.dialogRef.close({valid: this.valid, cancel: true});
    }
  }

  confirm() {
    this.toggleDialogButtons();
    if (parseFloat(this.totalApportion) === 100) {
      if (this.isUpdate) {
        this.update()
      } else {
        this.create()
      }
    } else {
      this.delete()
    }
  }

  create() {
    const items = this.assignedStrings.filter((o) => {
      return o[this.glApportionFieldName] !== 0;
    }).map((item) => {
      return {...item, dynamic_included:!!this.dynamicIncluded.value};
    })

    this.glDirectService.createDirectGL(items, this.charges, this.invoice)
      .subscribe(() => {
        this.dialogRef.close(
          {data: items}
        );
      })
  }

  update() {
    const items = this.assignedStrings.filter((o) => {
      return o[this.glApportionFieldName] != 0;
    }).map((item) => {
      return {...item, dynamic_included:!!this.dynamicIncluded.value};
    })
    this.glDirectService.update(this.invoice.invoice_id, {apportions: items, charges: this.charges, invoice: this.invoice})
      .subscribe(() => {
        this.dialogRef.close(
          {data: items}
        );
      })
  }

  delete() {
    const items = this.assignedStrings.filter((o) => {
      return o[this.glApportionFieldName] !== 0;
    })

    this.glDirectService.delete(this.charges.map(x => x.id)[0])
      .subscribe(() => {
        this.dialogRef.close(
          {data: items,
            deleted: true}
        );
      })
  }
}
