import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { isOfTypeDateWithoutTime } from './is-date-without-time';
import {DatePipe} from "@angular/common";

@Injectable()
@Pipe({
  name: 'date'
})
export class CaDatePipe implements PipeTransform {
  constructor() {
  }

  transform(value: Date, pattern?: string): any {
    if (!value) {
      return value;
    }

    const dateString = value.toJSON ? value.toJSON() : value;

    pattern = pattern || 'MM/DD/YYYY';

    const momentDate = isOfTypeDateWithoutTime(dateString) ? moment.utc(value) : moment(value);
    return momentDate.format(pattern);
  }
}


@Injectable()
@Pipe({
  name: 'caDate'
})
export class CasDatePipe extends DatePipe {

  constructor() {
    super('en-US');
  }

  transform(value: Date, format?: string, timezone?: string, locale?: string): any {

    format = format ? format : localStorage.getItem('date_format');
    return super.transform(value, format, timezone, locale)
  }
}

@Injectable()
@Pipe({
  name: 'caDateTime'
})
export class CasDateTimePipe extends DatePipe {

  constructor() {
    super('en-US');
  }

  transform(value: Date, format?: string, timezone?: string, locale?: string): any {

    format = format ? format : localStorage.getItem('date_time_format');
    return super.transform(value, format, timezone, locale)
  }
}
