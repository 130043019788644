import {ICsvInterceptor} from '../../core/csv/csv-interceptor';

export class AccountCsvInterceptor implements ICsvInterceptor {
  transform(item: any) {
    let copy = Object.assign({}, item);
    copy.on_hold = item.on_hold ? 'Yes' : (item.on_hold === false ? 'No' : '');
    copy.is_tax_exempt = item.is_tax_exempt ? 'Yes' : (item.is_tax_exempt === false ? 'No' : '');
    return copy;
  }
}
