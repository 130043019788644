import { Injectable } from '@angular/core';
import { GridService } from "../../shared/grid/grid.service";
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class SubAccountCodingGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.gl.gl-subaccount-coding-grid", userSettings);
  }

  defaultColumns = [
      {
        caption: "Sub Account",
        dataField: "sub_account",
        cellTemplate: 'subAccountCellTemplate'
      },
      {
        alignment: 'left',
        caption: "Status",
        dataField: "gl_coding_enabled",
        cellTemplate: 'statusCellTemplate',
        width: '120px'
      }];

  /*columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
