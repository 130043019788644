export const chargeFilterContext = {
  filters: [
    {
      field: 'invoice_id',
      label: 'Invoice ID',
      type: 'number',
      index: 1
    },
    {
      field: 'vendor_and_account',
      vendorFields: {
        vendor: {label: 'Vendor', field: 'vendor.name'},
        account: {label: 'Account', field: 'acct_level_1'},
      },
      label: 'Vendor / Account',
      editor: 'vendorAccountFilter',
      expanded: true,
      index: 1
    },
    {
      field: 'invoice.inv_date',
      label: 'Invoice Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 2
    },
    {
      field: 'direct_gl_stamp',
      label: 'Direct GL',
      type: 'boolean',
      index: 26
    },
    {
      field: 'charge_coded',
      label: 'GL Coded',
      type: 'boolean',
      trueLabel: 'Coded',
      falseLabel: 'Uncoded',
      index: 1
    },
    {
      field: 'chg_class',
      label: 'Charge Type',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'chargeType',
      index: 5
    },
    {
      field: 'unset_adjustment.key',
      label: 'Unset Adjustments',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'unsetAdjustment',
      index: 15
    },
    {
      field: 'invoice.sp_inv_num',
      label: 'Invoice Number',
      type: 'string',
      index: 6
    },
    {
      field: 'acct_level_2',
      label: 'Subaccount',
      type: 'lookup',
      lookupProviderName: 'subAccount',
      index: 7
    },
    {
      field: 'chg_amt',
      label: 'Charge Amount',
      type: 'negativeNumber',
      index: 8,
    }, {
      field: 'sp_serv_id',
      label: 'Service ID',
      type: 'string',
      index: 9,
    },
    {
      field: 'rel_sp_fac_id',
      label: 'Related Service ID',
      type: 'string',
      index: 10,
    },
    {
      field: 'superusoc',
      label: 'Super USOC',
      type: 'string',
      index: 11
    },
    {
      field: 'btn',
      label: 'BTN',
      type: 'string',
      index: 11
    },
    {
      field: 'chg_desc_1',
      label: 'Charge Description 1',
      type: 'string',
      index: 11,
    },
    {
      field: 'chg_desc_2',
      label: 'Charge Description 2',
      type: 'string',
      index: 12
    },
    {
      field: 'chg_code_1',
      label: 'Charge Code1',
      type: 'string',
      index: 13
    },
    {
      field: 'chg_code_2',
      label: 'Charge Code2',
      type: 'string',
      index: 14
    },
    {
      field: 'svc_establish_date',
      label: 'Install Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 15
    },
    {
      field: 'beg_chg_date',
      label: 'Beg Charge Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 16
    },
    {
      field: 'end_chg_date',
      label: 'End Charge Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 17
    },
    {
      field: 'chg_qty1_used',
      label: 'Charge Qty Used',
      type: 'number',
      index: 18
    },
    {
      field: 'chg_qty1_allowed',
      label: 'Charge Qty Allowed',
      type: 'string',
      index: 19,
    },
    {
      field: 'chg_qty1_billed',
      label: 'Charged Qty Billed',
      type: 'number',
      index: 20
    },
    {
      field: 'chg_qty2_used',
      label: 'Charge Qty Used 2',
      type: 'number',
      index: 21
    },
    {
      field: 'super_usoc_map.susoc_1',
      label: 'Segment Net Element',
      type: 'string',
      index: 18
    },
    {
      field: 'super_usoc_map.susoc_2',
      label: 'Segment Tech',
      type: 'string',
      index: 19
    },
    {
      field: 'super_usoc_map.susoc_3',
      label: 'Segment Qty',
      type: 'string',
      index: 19
    },
    {
      field: 'super_usoc_map.susoc_4',
      label: 'Segment Jur',
      type: 'string',
      index: 20
    },
    {
      field: 'super_usoc_map.susoc_5',
      label: 'Segment Primary/Feat',
      type: 'string',
      index: 21
    },
    {
      field: 'info_only_ind',
      label: 'Info Only',
      type: 'boolean',
      index: 22
    },
    {
      field: 'site_a_addr_1',
      label: 'Address',
      type: 'string',
      index: 23
    },
    {
      field: 'site_a_addr_city',
      label: 'City',
      type: 'string',
      index: 24
    },
    {
      field: 'site_a_addr_st',
      label: 'State',
      type: 'string',
      index: 25
    },
    {
      field: 'fac_bw',
      label: 'Facility Bandwidth',
      type: 'number',
      index: 23
    },
    {
      label: 'AP Invoice Number ',
      field: 'invoice.batch_item.ap_invoice_number',
      type: 'string',
      index: 24
    },
    {
      label: 'Calculated Amount',
      field: 'charge_dispute.calculated_amount',
      type: 'negativeNumber',
      index: 25
    },
    {
      label: 'Disputed Amount',
      field: 'charge_dispute.disputed_amount',
      type: 'negativeNumber',
      index: 26
    },
    {
      label: 'Withheld Status',
      field: 'charge_dispute.dispute_withheld',
      type: 'boolean',
    },
    {
      label: 'Vendor Location Id',
      field: 'account.vendor_remit_address.vendor_location_id',
      type: 'lookup',
      lookupProviderName: 'vendorRemitAddress',
      index: 50
    },
    {
      label: 'Subclient',
      field: 'account.subclient.value',
      type: 'lookup',
      lookupProviderName: 'subclient',
      index: 51
    }
  ],
  filterSet: [
    {
      field: 'vendor_and_account',
      vendorFields: {
        vendor: {label: 'Vendor', field: 'vendor.name'},
        account: {label: 'Account', field: 'acct_level_1'},
      },
      label: 'Vendor / Account',
      editor: 'vendorAccountFilter',
      expanded: true,
      index: 1
    },
    {
      field: 'invoice.inv_date',
      label: 'Invoice Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 2
    },
    {
      field: 'chg_class',
      label: 'Charge Type',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'chargeType',
      index: 3
    },
    {
      field: 'invoice.sp_inv_num',
      label: 'Invoice Number',
      type: 'string',
      index: 4
    },
    {
      field: 'info_only_ind',
      label: 'Info Only',
      type: 'boolean',
      defaultValue: ['false'],
      index: 22
    }
  ]
};
