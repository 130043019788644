import {of as observableOf,  Observable, combineLatest } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import * as moment from 'moment';
import {LOOKUP_ENUM} from '../../../dictionary/core/lookup.enum';


import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { AlertService } from '../../../shared/alert/alert.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { DocumentService } from '../../../shared/document/core/document.service';
import { EntityEditContext } from '../../../shared/entity-lock/entity-edit-context';
import { LoaderService } from '../../../shared/loader/loader.service';
import {flatMap, startWith, takeUntil} from "rxjs/operators";
import { TabService} from "../../../shared/tabs/tab.service";
import {LocationService} from "../../../location/core/location.service";
import {ContractService} from "../../../contract/core/contract.service";
import {SvcOrderServiceManageDialogComponent} from "../../../contract/shared/svc-order-service-manage-dialog/svc-order-service-manage-dialog.component";
import {SvcOrderService} from "../../../contract/core/svc-order.service";
import {InventoryService} from "../../core/inventory.service";
import {InventoryContractTerm} from "../../core/inventory-contract-term";
import Query from "../../../core/query/query";

const SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

@Component({
  selector: 'app-contract-manage-dialog',
  templateUrl: './inventory-contract-term-manage-dialog.component.html',
  styleUrls: ['./inventory-contract-term-manage-dialog.component.scss']
})
export class InventoryContractTermManageDialogComponent extends PageManageDialogComponent implements OnInit {
  @ViewChild('fileInput') fileInput;

  contractTerm: any;
  svcOrderHeaderId: number;
  inventoryId: number;
  selectedRowKeys: Array<any>;
  inventory: any;
  contractSchedule;
  svcOrderSvc;

  selectedCurrency: any;
  vendorId: number;

  isUpdate: boolean;
  contractQuery: Query = new Query({where:{}});
  svcOrderServiceQuery: Query = new Query({where:{}});
  contractScheduleQuery: Query = new Query({where:{}});

  readonly INVENTORY_CONTRACT_TYPE: string = LOOKUP_MODELS_ENUM.INVENTORY_CONTRACT_TYPE.modelName;
  INVENTORY_CONTRACT_TYPE_ENUM = LOOKUP_ENUM.INVENTORY_CONTRACT_TYPE;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'contract';

  constructor(
    public dialog: DialogService,
    public formBuilder: FormBuilder, public alert: AlertService,
    public contractService: ContractService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<SvcOrderServiceManageDialogComponent>,
    public loaderService: LoaderService,
    public documentService: DocumentService,
    public svcOrderHeaderService: SvcOrderService,
    public tabService: TabService,
    public locationService: LocationService,
    public inventoryService: InventoryService
  ) {
    super(dialogService, dialogRef);

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.isUpdate = !!this.contractTerm.id;
    if (this.contractTerm.contract_schedule_id) {
      this.contractService.findSchedule(this.contractTerm.contract_schedule_id)
        .subscribe(schedule => {
          this.contractSchedule = schedule;
        })
    };

    this.form = this.formBuilder.group({
      contract_type_id: [this.contractTerm ? this.contractTerm.contract_type_id : '', Validators.required],
      currency_id: [this.contractTerm ? this.contractTerm.currency_id : '', Validators.required],
      contract_id: [this.contractTerm ? this.contractTerm.contract_id : ''],
      contract_schedule_id: [this.contractTerm ? this.contractTerm.contract_schedule_id : ''],
      svc_order_service_id: [this.contractTerm ? this.contractTerm.svc_order_service_id : ''],
      svc_order_id: [this.contractTerm ? this.contractTerm.svc_order_id : ''],
      start_billing_date: [this.contractTerm ? this.contractTerm.start_billing_date : ''],
      expiration_date: [this.contractTerm ? this.contractTerm.expiration_date : ''],
      mrc: [this.contractTerm ? this.contractTerm.mrc : ''],
      nrc: [this.contractTerm ? this.contractTerm.nrc : ''],
      term: [this.contractTerm ? this.contractTerm.term : null],

    });

    if (this.isUpdate) {
      this.locationService.findCurrencyById(this.contractTerm.currency_id)
        .subscribe(res => {
          this.selectedCurrency = res.currency;
        });
        if (this.contractTerm.contract_type_id === this.INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT || this.contractTerm.contract_type_id === this.INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER) {
          this.form.get('term').disable();
        }
    }

    this.form.get('contract_schedule_id').valueChanges.subscribe((schedule_id) => {
      if (schedule_id) {
        this.contractService.findSchedule(schedule_id)
          .subscribe(schedule => {
            this.contractSchedule = schedule;
            // let termDate = moment(schedule.termination_date);
            let effDate = moment(schedule.effective_date);
            // let term = termDate.diff(effDate, 'month');
            // const termResult = (terminationDate && effectiveDate) ? Math.ceil(moment(terminationDate).diff(moment(effectiveDate), 'month', true)) : 0
            // this.form.get('term').setValue(term);
            this.getExpirationDate();
          })
      }
    });


    this.form.get('svc_order_service_id').valueChanges.subscribe((service_id) => {

      if (service_id) {
        this.svcOrderHeaderService.findServiceById(service_id)
          .subscribe(service => {
            this.svcOrderSvc = service;
            this.getCurrency();
            this.getMrc();
            this.getNrc();
            this.getTerms();
            this.getExpirationDate(true);

          })
      }
    });

    this.form.get('svc_order_id').valueChanges.subscribe((svc_order_id) => {
      this.form.get('svc_order_service_id').setValue(null);
      this.form.get('expiration_date').setValue(null);
    });

    this.form.get('contract_id').valueChanges.subscribe((svc_order_id) => {
      this.form.get('contract_schedule_id').setValue(null);
      this.form.get('expiration_date').setValue(null);
    });

    this.form.get('contract_type_id').valueChanges.subscribe((contract_type_id) => {
      if (contract_type_id === this.INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT) {
        this.form.get('svc_order_id').setValue(null);
        this.form.get('svc_order_service_id').setValue(null);
        this.form.get('expiration_date').setValue(null);
        this.form.get('term').disable();
      } else if (contract_type_id === this.INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER) {
        this.form.get('contract_id').setValue(null);
        this.form.get('contract_schedule_id').setValue(null);
        this.form.get('expiration_date').setValue(null);
        this.form.get('term').disable();
      } else {
        this.form.get('contract_id').setValue(null);
        this.form.get('contract_schedule_id').setValue(null);
        this.form.get('svc_order_id').setValue(null);
        this.form.get('svc_order_service_id').setValue(null);
        this.form.get('expiration_date').setValue(null);
        this.form.get('term').enable();
      }
    });

    this.form.get('start_billing_date').valueChanges.subscribe((res) => {
      this.getExpirationDate();
    });

    if (this.form.get('contract_type_id').value === LOOKUP_ENUM.INVENTORY_CONTRACT_TYPE.UNKNOWN) {
      this.getExpirationDate();
    }

    this.form.get('term').valueChanges.subscribe((res) => {
      this.getExpirationDate();
    });

    this.afterInit();
  }

  getExpirationDate(isOrderPicker: boolean = false) {
    let startBillingDate = this.form.get('start_billing_date').value;
    let term = isOrderPicker ? this.svcOrderSvc?.term :
       this.form.get('term').value;
    let expirationDate;
    if (startBillingDate) {
      expirationDate = moment.utc(startBillingDate).add(term, 'month');
    } else {
      expirationDate = moment.utc(this.inventory.install_date).add(term, 'month');
    }
    let maxExpirationDate = isOrderPicker || this.form.get('contract_type_id').value === LOOKUP_ENUM.INVENTORY_CONTRACT_TYPE.UNKNOWN
    || this.form.get('contract_type_id').value === LOOKUP_ENUM.INVENTORY_CONTRACT_TYPE.SVC_ORDER ?
      moment.max([expirationDate]) :
      moment.max([expirationDate, moment.utc(this.contractSchedule?.termination_date)]);

    this.form.get('expiration_date').setValue(maxExpirationDate);
  }

  getCurrency() {
    let currencyId = this.svcOrderSvc.currency_id;
    this.form.get('currency_id').setValue(currencyId);
  }

  getMrc() {
    let mrc = this.svcOrderSvc.mrc;
    this.form.get('mrc').setValue(mrc);
  }

  getNrc() {
    let nrc = this.svcOrderSvc.nrc;
    this.form.get('nrc').setValue(nrc);
  }

  getTerms() {
    let term = this.svcOrderSvc.term;
    this.form.get('term').setValue(term);
  }

  onSubmit({ value, valid }: { value: InventoryContractTerm, valid: boolean }) {
    if (!value.term) {
      value.term = this.form.get('term').value;
    }
    if (!valid) {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
    if (this.contractTerm && this.contractTerm.id) {

      this.update(Object.assign({}, this.contractTerm, value));
    } else {

      this.create(value);
    }
  }

  create(contractTerm: InventoryContractTerm) {

    this.loaderService.displayLoader();

    this.toggleDialogButtons();
    contractTerm.inventory_id = this.inventoryId;
    this.inventoryService.createContractTerm(this.inventoryId, contractTerm)
      .subscribe((result) => {

        this.loaderService.hideLoader();
        this.alert.success('', this.messages.get('CREATE_SUCCESS'));
        this.closeDialog(result, true);

      }, (err) => {
        this.toggleDialogButtons(false);
        this.loaderService.hideLoader();
        this.alert.success('', this.messages.get('CREATE_ERROR'));
      });
  }

  update(contractTerm: InventoryContractTerm) {
    this.toggleDialogButtons();
    this.inventoryService.updateContractTerm(this.inventoryId, contractTerm.id, contractTerm)
      .subscribe((result) => {
        this.alert.success('', this.messages.get('UPDATE_SUCCESS'));
        this.closeDialog(result, true);
        this.loaderService.hideLoader();

      }, (err) => {
        this.toggleDialogButtons(false);
        this.alert.error('', this.messages.get('UPDATE_ERROR'));
      });
  }

  cancel() {
    //this.contractService.cancelEdit();
    this.closeDialog();
  }

  onCurrencyChange(event) {
    this.selectedCurrency = event.selection.currency;
  }

  deleteOrderService(event) {
    // if (this.isUpdate && event) {
    //   this.toggleDialogButtons();
    //   console.log('this.svcOrderService.id', this.contractTerm.id);
    //   this.svcOrderHeaderService.deleteService(this.svcOrderHeaderId, this.contractTerm.id)
    //     .subscribe((deleteResult) => {
    //       if (deleteResult) {
    //         console.log('deleteResult', deleteResult)
    //         this.dialogRef.close({ deleted: true });
    //       }
    //     }, (err) => {
    //       this.toggleDialogButtons(false);
    //       if (err.data.name === 'SequelizeForeignKeyConstraintError') {
    //         setTimeout(() => {
    //           this.alert.error('', this.messages.get('CONSTRAIN_DELETE_ERROR'));
    //         }, 600);
    //       } else {
    //         this.alert.error('', this.messages.get('DELETE_ERROR'));
    //       }
    //     });
    // }
  }
}
