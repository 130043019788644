import { Injectable } from '@angular/core';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';

@Injectable()
export class AlertInventoryGridService extends GridService {
  constructor(
    public userSettings: UserSettingsService,
    public formatService: FormatService
  ) {
    super('app.alert-inventory.inventory-grid', userSettings);
  }

  // tslint:disable-next-line:member-ordering
  defaultColumns = [
    {
      caption: 'ID',
      dataField: 'unique_id',
      width: 250
    },
    {
      caption: 'Type',
      dataField: 'type.value',
      width: 130
    },
    {
      caption: 'Message',
      dataField: 'message',
      width: 300
    },
    {
      caption: 'Vendor',
      dataField: 'vendor.name',
      width: 130
    },
    {
      caption: 'Install Date',
      dataField: 'install_date',
      dataType: 'date',
      format: this.formatService.date,
      alignment: 'left',
      width: 130
    },
    {
      caption: 'Site A (Carrier Location)',
      dataField: 'siteA.site_id',
      width: 180
    },
    {
      caption: 'Site Z (Customer Location)',
      dataField: 'siteZ.site_id',
      width: 190
    },
    {
      caption: 'Status',
      dataField: 'inventory_status.value',
      width: 130
    },
    {
      caption: 'Internal ID',
      dataField: 'internal_id',
      width: 200
    },
    {
      caption: 'Customer Provided Access',
      dataField: 'is_customer_provided_access',
      width: 200
    },
    {
      caption: 'VLAN ID',
      dataField: 'vlan_id',
      width: 200
    },
    {
      caption: 'Port Bandwidth',
      dataField: 'bandwidth_max.value',
      width: 200
    },
    {
      caption: 'Service Bandwidth',
      dataField: 'circuit_bandwidth.value',
      width: 200
    },
    {
      caption: 'Svc Tech',
      dataField: 'technology.value',
      width: 200
    },
    {
      caption: 'Service Type',
      dataField: 'inventory_ckt_type.value',
      width: 200
    },
    {
      caption: 'Customer Name',
      dataField: 'customer.company',
      width: 200
    }
  ];

  /*columns() {

    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
