import { AlertService } from '../alert/alert.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';
import { CaImageCropperComponent } from "../ca-image-cropper-dialog/ca-image-cropper-dialog.component";

@Component({
  selector: 'ca-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent implements OnInit {

  @Input() size: string;
  @Input() source: string;
  @Input() text: string;
  @Input() uploadEnabled: boolean;
  @Input() isModalOpen: boolean;
  editEnabled: boolean = false;
  @Output() onAvatarUpload = new EventEmitter();
  @Input() avatarCropperClass: string;
  @Input('disabled') disabled: boolean;

  public avatarStyle = {};

  readonly SIZES = {
    s: { 'width': '44px', 'height': '44px', 'line-height': '44px', 'font-size': '13px' },
    m: { 'width': '100px', 'height': '100px', 'line-height': '100px', 'font-size': '30px' },
    l: { 'width': '200px', 'height': '200px', 'line-height': '200px', 'font-size': '60px' },
  };

  constructor(public alert: AlertService, private dialogService: DialogService) {
  }

  ngOnInit() {
    this.avatarStyle = this.SIZES[this.size];
  }

  getSourceUrl() {
    if (this.source) {
      return `url(${this.source})`;
    }
  }

  editImage() {
    if (this.uploadEnabled && !this.disabled) {
      this.openCropper(this.source, this.text)
    }
  }

  openCropper(image, text?) {
    this.dialogService.open(CaImageCropperComponent, {
      image: image,
      text: text,
      avatarCropperClass: this.avatarCropperClass
    }, { height: '628px' })
      .afterClosed()
      .subscribe((result) => {
        this.source = result ? result : null;

        this.avatarStyle['background-image'] = `url(${this.source})`;
        this.onAvatarUpload.emit(this.source);
      });
  }

}
