import {Injectable} from '@angular/core';
import {GridService} from "../../shared/grid/grid.service";
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class ContractInventoryGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.contract.contract-inventory-grid", userSettings);
  }

  defaultColumns = [
    {
      caption: "",
      headerCellTemplate: 'selectionBadgeHeaderTemplate',
      cellTemplate: "selectionBadgeCellTemplate",
      fixed: true,
      width: 40,
      allowSorting: false,
      allowResizing: false,
    },
    // {
    //   caption: "ID",
    //   dataField: "unique_id",
    //   width: 250
    // },
    // {
    //   caption: "Type",
    //   dataField: "type.value",
    //   width: 130
    // },
    {
      caption: "Vendor",
      dataField: "vendor.name",
      width: 130
    },
    {
      caption: "BAN",
      dataField: "ban",
      width: 130
    },
    {
      caption: "SPID",
      dataField: "unique_id",
      width: 130
    },
    // {
    //   caption: "SPID",
    //   dataField: "customer_inventory.customer_circuit_id",
    //   width: 130
    // },
    {
      caption: "Status",
      dataField: "inventory_status.value",
      width: 130
    },
    {
      caption: "Svc Type / Technology ",
      dataField: "technology.value",
      width: 130
    },
    {
      caption: "Service Type",
      dataField: "inventory_ckt_type.value",
      width: 130
    },
    {
      caption: "Service Bandwidth",
      dataField: "circuit_bandwidth.value",
      width: 130
    },
    {
      cellTemplate: "installDateTemplate",
      caption: "Install Date",
      dataField: "install_date",
      dataType: "date",
      format: this.formatService.date,
      alignment: 'left',
      width: 130
    },
    {
      caption: "Disconnect Date",
      cellTemplate: "disconnectDateTemplate",
      dataField: "disc_date",
      dataType: "date",
      width: 130
    },
    {
      caption: "Exp Date",
      cellTemplate: "expDateTemplate",
      dataField: "exp_date",
      dataType: "date",
      width: 130
    },
    {
      caption: "Term",
      dataField: "term",
      width: 100,
      alignment: "left"
    },
    {
      caption: "MRC",
      cellTemplate: "currencyTemplate",
      dataField: "est_mrc",
      dataType: 'currency',
      width: 130
    },
    {
      caption: "NRC",
      cellTemplate: "currencyTemplate",
      dataField: "est_nrc",
      dataType: 'currency',
      width: 130
    },
    {
      caption: "Site A (Carrier Location)",
      dataField: "siteA.site_id",
      width: 180
    },
    {
      caption: "Site Z (Customer Location)",
      dataField: "siteZ.site_id",
      width: 190
    },
  ];
}
