import Query from '../../core/query/query';

export class UserQuery extends Query {
  /*constructor(data?: any) {
    super();
    Object.assign(this, data || {});
  }

  transform() {
    let query = {
      limit: this.limit,
      offset: this.offset,
      orderBy: this.orderBy,
      where: Object.assign({}, this.where || {})
    };

    if (this.where['first_name']) {
      query.where['first_name'] = {'$ilike': this.where['first_name'] + '%'}
    }

    if (this.where['last_name']) {
      query.where['last_name'] = {'$ilike': this.where['last_name'] + '%'}
    }

    return query;
  }*/
}
