<app-svc-order-details-toolbar
  [svcOrderHeader] = svcOrderHeader
  [isAddServicesHidden] = "false"
  [isEditServicesHidden] = "false"
  [isEditServicesDisabled] = this.isEditDisabled
  [isAddTermsConditionHidden] = "true"
  [isEditTermsConditionHidden] = "true"
  [isDeleteTermsConditionHidden] = "true"
  [isDownloadAttachmentHidden] = "true"
  [isDeleteAttachmentHidden] = "true"
  [isFilterHidden] = "false"
  [isFilterDisabled] = "false"
  [isGridSettingsHidden] = "false"
  [isCsvHidden] = "false"
  [isFilterOn] = "this.sider.getActiveSection().name === this.SECTIONS.FILTER_SECTION_NAME"
  (backRequested)="back()"
  (newServiceRequested) = "newService()"
  (editServiceRequested) = "updateService()"
  (gridSettingsRequested) = "gridSettings()"
  (csvRequested) = "csv()"
  (toggleFilterRequested)="toggleFilter()"
>
</app-svc-order-details-toolbar>

<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-svc-order-details-tabs
    [activeTabIndex]="1"
    [svcOrderHeader] = svcOrderHeader
  ></app-svc-order-details-tabs>

   <div class="tabs-body grid-container grid-with-toolbar"
       style="top: -2px; z-index: -1; overflow: hidden;">
    <div class="grid-body app-content-list no-padding-app-content-list">
      <dx-data-grid
        #dataGrid
        style="height: 100%"
        [columns]="serviceColumns"
        [dataSource]="svcOrderServices"
        (onSelectionChanged)="onSelectionChanged($event)"
        [showColumnLines]="false"
        [showRowLines]="true"
        [allowColumnResizing]="true"
        [columnMinWidth]="50"
        [columnAutoWidth]="true"
        (onCellClick)="onSvcServiceCellClick($event)"
      >

        <div *dxTemplate="let d of 'linkTemplate'">
          <span class="grid-link-style" matTooltip="{{d.value}}" (click)="linkToSpid(d)">
            {{ d.value }}
          </span>
        </div>

        <div *dxTemplate="let d of 'currencyTemplate'">
          <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.currency.currency }}</div>
        </div>

        <div *dxTemplate="let d of 'detailsTextCellTemplate'">
          <span matTooltip="{{d.value}}">
            {{ d.value }}
          </span>
        </div>

        <div *dxTemplate="let d of 'dateTimeTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caDate}}
          </div>
        </div>

        <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>
        <dxo-sorting mode="none"></dxo-sorting>
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-load-panel [enabled]="false"></dxo-load-panel>

      </dx-data-grid>
      <ca-pager
        [gridPager]="defaultGridPager"
        [pageContext]="svcOrderServiceGridService"
        [query]="servicesQuery"
        class="app-pager ca-default-border-top"
        (onChange)="loadServices(this.svcOrderHeader.id, this.servicesQuery)"
      ></ca-pager>
    </div>
  </div>

  <mat-sidenav #panelMore [mode]="'side'" position="end" >
        <new-app-filter
          [query]="servicesQuery"
          [filterService]="svcOrderServiceFilterService"
          (filterRequested)="filterServices($event)"
          (clearFilterRequested)="clearFilterServices($event)">
        </new-app-filter>
      </mat-sidenav>
</mat-sidenav-container>
