import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { QuotingService } from '../../core/quoting.service';
import { Quote } from '../../core/quote';
import { QUOTE_HEADER_STATUS } from '../../core/quote-header-status.enum';
import {LOOKUP_MODELS_ENUM} from '../../../dictionary/core/lookup-models.enum';
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import {IMessagesResourceService, ResourcesService} from '../../../core/resources/resources.service';

@Component({
  selector: 'app-quoting-manage-dialog',
  templateUrl: './quoting-manage-dialog.component.html',
  styleUrls: ['./quoting-manage-dialog.component.css']
})
export class QuotingManageDialogComponent extends PageManageDialogComponent implements OnInit {
  quote: Quote;
  formTitle: string;
  labels: Array<any> = [];

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'quote';

  public QUOTE_OPPORTUNITY_TYPE_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.QUOTE_OPPORTUNITY_TYPE.modelName;
  readonly CONTACT_FUNCTION_ENUM: any = LOOKUP_ENUM.CONTACT_FUNCTION;
  public QUOTE_HEADER_STATUS = QUOTE_HEADER_STATUS;

  constructor(
    public alert: AlertService,
    public formBuilder: FormBuilder,
    public quotingService: QuotingService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<QuotingManageDialogComponent>
  ) {
    super(dialogService, dialogRef);
    this.quote = (this.quote) ? this.quote : new Quote();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.isUpdate = !!this.quote.id;

    this.form = this.formBuilder.group({
      request_date: new FormControl((this.quote && this.quote.request_date) ?
        new Date(this.quote.request_date) : null, Validators.required),
      requestor_id: new FormControl(this.quote.requestor_id, Validators.required),
      customer_id: new FormControl(this.quote.customer_id, Validators.required),
      opportunity_type_id: new FormControl(this.quote.opportunity_type_id),
      sales_representative_id: new FormControl(this.quote.sales_representative_id),
      sales_engineer_id: new FormControl(this.quote.sales_engineer_id),
      crm_opportunity: new FormControl(this.quote.crm_opportunity),
      quote_description: new FormControl(this.quote.quote_description),
      due_date: new FormControl(this.quote.due_date)
    });

    this.formTitle = this.isUpdate ? 'Edit Quote' : 'Create Quote';
    this.quote = this.quote || new Quote();
    this.initializeLabels();
  }

  onSubmit({ value, valid }: { value: Quote, valid: boolean }) {
    if (valid) {
      if (this.quote.id) {
        this.updateQuote(value)
      } else {
        this.createQuote(value);
      }
    } else {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }

  updateQuote(quote: Quote) {
    this.toggleDialogButtons()
    this.quotingService.update(this.quote.id, quote)
      .subscribe(
      (result) => {
        this.closeDialog(result, true);
      },
      (error) => {
        this.toggleDialogButtons(false)
        this.alert.error('', this.messages.get('UPDATE_ERROR'));
      }
      );
  }

  createQuote(quote: Quote) {
    this.toggleDialogButtons()
    this.quotingService.create(quote)
      .subscribe((result) => {
        this.closeDialog(result, true);
      },
      (error) => {
        this.toggleDialogButtons(false)
        this.alert.error('', this.messages.get('CREATE_ERROR'));
      }
      );
  }

  deleteQuote(event) {
    // TODO: check is it possibile to delete quote???
    /*if (this.quote && this.quote.id && event) {
      this.quotingService.delete(this.quote.id)
        .subscribe((deleteResult) => {
          if (deleteResult) {
            this.dialogRef.close({ deleted: true });
          }
        }, (err) => {
          this.alert.error('', this.messages.get('DELETE_ERROR'));
        });
    }*/
  }

  showLabel(date: string) {
    if (this.labels.indexOf(date) > -1) {
      return true;
    }
  }

  public toggleLabel(date: string) {
    if (this.form.controls[date].value) {
      if (this.labels.indexOf(date) === -1) {
        this.labels.push(date);
      }
    } else {
      if (this.labels.indexOf(date) === -1) {
        this.labels.push(date);
      } else {
        let index = this.labels.indexOf(date);
        this.labels.splice(index, 1);
      }
    }
  }

  public initializeLabels() {
    let dates = ['request_date'];
    dates.forEach((item) => {
      if (this.form.controls[item].value) {
        this.labels.push(item);
      }
    });
  }

  public unmask(value) {
    if (value) {
      // http://stackoverflow.com/questions/559112
      return Number(value.replace(/[^0-9\.]+/g, ''));
    }
  }
}
