import {ICsvInterceptor} from "../../core/csv/csv-interceptor";
import {ExchangeRateService} from "../../location/core/exchange-rate.service";
import {UserService} from "../../user/core/user.service";

export class InvoiceCsvInterceptor implements ICsvInterceptor {
  private me: any = {};
  constructor(public exchangeRateService: ExchangeRateService,
              public userService: UserService) {
    this.userService.me().subscribe(user => {
      this.me = user;
    });
  }
  transform(item: any) {
    let copy = Object.assign({}, item);

    copy.account.on_hold = item.account && item.account.on_hold ? 'Yes' : 'No';
    copy.account.is_tax_exempt = item.account && item.account.is_tax_exempt ? 'Yes' : 'No';

    // calculate here, but do formatting on backed
    copy.tot_amt_due_norm = this.setNormalizedValue(copy.tot_amt_due, copy.currency)
    copy.tot_new_chgs_norm = this.setNormalizedValue(copy.tot_new_chgs, copy.currency)

    //TODO This value formatting needs to be refactored (on server side)

    // calculated on backend
    // copy.header.tot_new_chg_diff_pct = (Math.round(parseFloat(copy.header.tot_new_chg_diff_pct) * 100)/100).toFixed(2) + '%'

    return copy;
  }

  public setNormalizedValue(value, currency): any {
    return value * this.exchangeRateService.getRateForCurrency(currency);
  }
}
