import {Action, State, StateContext, Store} from "@ngxs/store";

import {
  DownloadInvoiceContainer,
  DownloadInvoicePDF, DownloadInvoiceSource,
  LoadSingleInvoice,
  UpdateActiveSiderSection,
  UpdateInvoiceDetailsFilter,
} from './invoice-details.actions';
import {Injectable} from "@angular/core";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {AlertService} from "../../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {InvoiceFlowService} from "../../core/invoice-flow.service";

@State<any>({
  name: 'invoice_details',
  defaults: {
    invoice: InvoiceFacepage
  },
})
@Injectable()
export class InvoiceDetailsState {

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'invoice';

  constructor(
    private store: Store,
    private invoiceFacepageService: InvoiceFacepageService,
    public invoiceFlowService: InvoiceFlowService,
    public alertService: AlertService,
  ) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  @Action(LoadSingleInvoice)
  loadSingleInvoice(ctx: StateContext<any>, action: any): any {
    return this.invoiceFacepageService.findById(action.id)
      .toPromise()
      .then((invoice) => ctx.patchState({invoice}));
  }

  @Action(DownloadInvoicePDF)
  downloadInvoicePDF(ctx: StateContext<any>, action: any): any {
    const {invoice} = action
    return this.invoiceFacepageService.facepagePdf(invoice.invoice_id)
      .subscribe((response: Blob) => {
        let objectUrl = URL.createObjectURL(response);

        let save = document.createElement('a');
        save.href = objectUrl;
        save.target = '_blank';
        if (invoice.vendor) {
          save.download =
            invoice.vendor.name + '_' + invoice.sp_inv_num;
        } else {
          save.download = invoice.sp_inv_num;
        }

        let event = document.createEvent('MouseEvents');
        event.initMouseEvent(
          'click',
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        save.dispatchEvent(event);
      });
  }

  // @Action(DownloadInvoiceContainer)
  // downloadInvoiceContainer(ctx: StateContext<any>, action: any): any {
  //   const {invoice} = action
  //   return this.invoiceFacepageService.downloadContainer(invoice.invoice_id).subscribe(
  //     (response: Blob) => {
  //         let objectUrl = URL.createObjectURL(response);
  //
  //         let save = document.createElement('a');
  //         save.href = objectUrl;
  //         save.target = '_blank';
  //         save.download = invoice.header.file_source;
  //
  //         let event = document.createEvent('MouseEvents');
  //         event.initMouseEvent(
  //           'click',
  //           true,
  //           false,
  //           window,
  //           0,
  //           0,
  //           0,
  //           0,
  //           0,
  //           false,
  //           false,
  //           false,
  //           false,
  //           0,
  //           null
  //         );
  //         save.dispatchEvent(event);
  //       },
  //       Error => {
  //         this.alertService.error('', this.messages.get('NO_FILE_FOUND'));
  //       }
  //     );
  // }

  // @Action(DownloadInvoiceSource)
  // downloadInvoiceSource(ctx: StateContext<any>, action: any): any {
  //   const {invoice} = action
  //   return this.invoiceFacepageService.downloadSingularFileSourceName(invoice.invoice_id)
  //     .subscribe((response) => {
  //       if (response.key) {
  //         const folderName = response.key.substr(response.key.indexOf('/')+1)
  //         this.invoiceFacepageService.downloadSource(invoice.invoice_id).subscribe(
  //           (sourceResponse: Blob) => {
  //             let objectUrl = URL.createObjectURL(sourceResponse);
  //
  //             let save = document.createElement('a');
  //             save.href = objectUrl;
  //             save.target = '_blank';
  //             save.download = `${folderName}`;
  //             let event = document.createEvent('MouseEvents');
  //             event.initMouseEvent(
  //               'click',
  //               true,
  //               false,
  //               window,
  //               0,
  //               0,
  //               0,
  //               0,
  //               0,
  //               false,
  //               false,
  //               false,
  //               false,
  //               0,
  //               null
  //             );
  //             save.dispatchEvent(event);
  //           },
  //           Error => {
  //             this.alertService.error('', this.messages.get('NO_VENDOR_SOURCE_FILE'));
  //           }
  //         )
  //       } else {
  //         this.alertService.error('', this.messages.get('NO_VENDOR_SOURCE_FILE'))
  //       }
  //     }
  //   )
  // }

  @Action(UpdateActiveSiderSection)
  updateActiveSiderSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }

  @Action(UpdateInvoiceDetailsFilter)
  updateInvoiceDetailsFilter(ctx: StateContext<any>, {options}): void {
    ctx.patchState({
      filter: options
    })
  }
}
