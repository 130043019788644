export const ICONS_ENUM = {
  'UNDEFINED': 'error',

  /*Navigation*/
  'NAV_HOME': 'home',
  'NAV_ADMIN': 'apps',
  'NAV_ORDERS': 'shopping_cart',
  'NAV_INVENTORY': 'label',
  'NAV_COST_ANALYSIS': 'widgets',
  'NAV_GL': 'list',
  'NAV_CONTRACTS': 'insert_drive_file',
  'NAV_ALERTS': 'games',
  'NAV_REPORTS': 'pie_chart',
  'NAV_USAGE_MANAGEMENT': 'show_chart',
  'NAV_APP_DATA': 'settings',
  'NAV_ARROW_DOWN': 'keyboard_arrow_down',
  'NAV_ARROW_UP': 'keyboard_arrow_up',
  'NAV_TOGGLE': 'menu',
  'NAV_QUOTES': 'assignment_turned_in',
  'WIRELESS': 'wifi',
  'FINANCIAL': 'request_quote',
  'CIRCUIT': 'cable',
  'SITE': 'settings_input_hdmi',


  /* General */
  'ADD_NEW': 'note_add',
  'APPLY': 'done',
  'ADD': 'add',
  'APPLY_FILTER': 'autorenew',
  'BACK': 'keyboard_arrow_left',
  'CLEAR_FILTER': 'clear_all',
  'CLOSE': 'close',
  'DOWNLOAD': 'file_download',
  'FILE_SOURCE_DOWNLOAD': 'library_books',
  'DELETE': 'delete',
  'EDIT': 'mode_edit',
  'EXCEL_DOWNLOAD': 'view_quilt',
  'FILE_UPLOAD': 'file_upload',
  'FILE_DOWNLOAD': 'file_download',
  'FILTER': 'filter_list',
  'FILTER_SETTINGS': 'format_line_spacing',
  'FILTER_EXACT': 'my_location',
  'FILTER_CASE': 'font_download',
  'GRID_SETTINGS': 'settings',
  'GRID_SETTINGS_CLEAR': 'delete',
  'HISTORY': 'alarm',
  'INFO': 'info',
  'MORE_VERTICAL': 'more_vert',
  'NOTES': 'insert_comment',
  'NOTES_EMPTY': 'mode_comment',
  'NAVIGATE_TO_LINK': 'keyboard_arrow_right',
  'OFFLINE': 'cloud_off',
  'PDF_DOWNLOAD': 'picture_as_pdf',
  'PAGER_LEFT': 'chevron_left',
  'PAGER_RIGHT': 'chevron_right',
  'REFRESH': 'refresh',
  'AUTO_RENEW': 'autorenew',
  'REORDER': 'reorder',
  'SWAP_HORIZONTAL': 'swap_horiz',
  'SYNC_ALT':'sync_alt',
  'TEXT_FORMAT': 'text_format',
  'LOCATION': 'location_on',
  'LOCK': 'lock',
  'NOTIFICATION': 'notifications',
  'IS_READ': 'visibility',
  'IS_UNREAD': 'visibility_off',
  'MENU_MORE': 'more_vert',
  'SHOW_ALL_CONTACTS':'visibility',
  'FILTERED_CONTACTS':'visibility_off',
  'REQUIRED_FIELDS':'emergency',
  'CHECK':'check',
  'ADD_MANUAL_CHARGE': 'add_task',
  'DELETE_MANUAL_CHARGE': 'remove_circle_outline',

  /* Alerts */
  'CHANGE_OWNER': 'person',
  'EXCLUDE': 'remove_circle_outline',

  /* Customer/Network Inventory */
  'CUSTOMER_INVENTORY_DELETE_DOC': 'delete',
  'ADD_SITE': 'add',
  'DELETE_SITE': 'delete',
  'ADD_PRICING': 'add',
  'UPDATE_PRICING': 'done',
  'DELETE_PRICING': 'delete',
  'CLEAR_FORM': 'clear_all',
  'INVENTORY_DOWNLOAD_PRICING': 'file_download',
  'INVENTORY_DELETE_PRICING': 'delete',
  'DEMARC_ADD': 'add',
  'DEMARC_DELETE': 'delete',
  'CIRCUIT_ADD': 'add',
  'CIRCUIT_DELETE': 'delete',
  'ADD_DEMARC': 'add',
  'UPDATE_DEMARC': 'done',
  'DELETE_DEMARC': 'delete',
  'DISCONNECT_ORDER': 'do_not_disturb',
  'ORDER_TYPE':'content_paste',
  'DEMARC_CANCEL': 'clear_all',

  /* Contracts */
  'ADD_CONTRACT_SECTION': 'note_add',
  'CONTRACT_TYPE_MSA': 'assignment',
  'CONTRACT_TYPE_SVC_SCHEDULE': 'event_note',
  'LINK': 'link',
  'UNLINK': 'link_off',

  /* Disputes */
  'DISPUTE': 'warning',
  'ADD_DISPUTE': 'warning',

  /* Documents */
  'VIEW_DOCUMENT': 'visibility',
  'DELETE_DOCUMENT': 'delete',
  'FILE_CLOUD_UPLOAD': 'cloud_upload',
  'FILE_VIEW': 'visibility',
  'FILE_DELETE': 'delete',
  'DOCUMENT_TYPE':'swap_horizontal_circle',

  /* GL */
  'GL_REPROCESS': 'done',
  'GL_DEACTIVATE_BATCH': 'highlight_off',
  'GL_BATCH_ADD_INVOICE': 'note_add',
  'GL_BATCH_REMOVE': 'delete',
  'GL_ADD_RULE': 'note_add',
  'GL_REMOVE_RULE': 'delete',
  'GL_EDIT_STRING': 'mode_edit',
  'GL_ADD_STRING': 'note_add',
  'CANCEL_CHANGES': 'clear',
  'GL_SAVE_RULES': 'done',
  'GL_PROCESS_RULES': 'play_arrow',
  'GL_STRING_SHOW_GRID': 'keyboard_arrow_right',
  'GL_STRING_HIDE_GRID': 'keyboard_arrow_down',
  'GL_STRING_ADD': 'note_add',
  'GL_STRING_DELETE': 'delete',
  'GL_STRING_SAVE': 'save',
  'GL_STRING_CLEAR_ALL_APPORTIONS': 'clear_all',
  'FORMAT_INCREASE': 'format_indent_increase',
  'FORMAT_DECREASE': 'format_indent_decrease',
  'FIND_REPLACE': 'find_replace',

  /* Info */
  'MENU_USER': 'person',
  'MENU_THEME': 'palette',
  'MENU_SUPPORT': 'live_help',
  'MENU_INFO': 'info',
  'MENU_SIGN_OUT': 'settings_power',
  'MENU_DASHBOARD': 'dashboard',

  /* Invoices */
  'APPROVE_INVOICES': 'done',
  'GL_OUTPUT': 'list',
  'CREATE_PAYMENT': 'list',
  'FLOW_STEP_COMPLETED': 'done',
  'INVOICE_FLOW_WARNING': 'warning',
  'INVOICE_FLOW_ERROR': 'error',
  'INVOICE_FLOW_PENDING': 'pending',
  'ADJUSTMENT': 'thumbs_up_down',
  'ON_HOLD': 'pause',
  'ADD_CHARGE': 'add',
  'UPDATE_CHARGE': 'done',
  'DELETE_CHARGE': 'delete',
  'CLEAR_CHARGE_FORM': 'clear_all',

  /* Quote */
  'QUOTE_ADD_SERVICE': 'note_add',
  'QUOTE_ADD_REQUEST': 'note_add',
  'QUOTE_EDIT_REQUEST': 'edit_mode',
  'QUOTE_ADD_SERVCE': 'note_add',
  'QUOTE_ADD': 'queue',
  'QUOTE_UPDATE': 'edit',
  'QUOTE_ADD_MANUAL': 'pan_tool',
  'QUOTE_SELECT': 'done',
  'QUOTE_CLOSE': 'clear',
  'QUOTE_CHART': 'bubble_chart',
  'QUOTE_SRM_REPORT': 'grid_on',
  'EMAIL': 'email',
  'SERVICE_LEVEL': 'filter_1',
  'ORDER_CONTACT_FILTER': 'filter_alt',

  /* Report gallery */
  'REPORT_TYPE_TABLE': 'grid_on',
  'REPORT_TYPE_BAR': 'insert_chart',
  'REPORT_TYPE_STACKED_BAR': 'insert_chart',
  'REPORT_TYPES_PIE': 'pie_chart',
  'REPORT_TYPES_LINE': 'show_chart',
  'REPORT_TYPES_PIVOT': 'view_quilt',
  'REPORT_TYPES_COUNTER': 'all_inclusive',

  /* Rate audit */
  'RATE_AUDIT_REMOVE_RULE': 'delete',
  'RATE_AUDIT_SUBMIT': 'done',
  'RATE_AUDIT_ADD_RULE': 'loupe',
  'RATE_AUDIT_SAVE': 'save',
  'TEST_AUDIT_FINISHED': 'done',

  /* Site Search */
  'SEARCH': 'search',
  'SEARCH_ACCOUNT': 'assignment_ind',
  'SEARCH_USER': 'group',
  'SEARCH_CONTACT': 'contacts',
  'SEARCH_BUILDING': 'account_balance',
  'SEARCH_SITE': 'perm_media',
  'SEARCH_INVOICE': 'receipt',
  'SEARCH_ORDER': 'move_to_inbox',
  'SEARCH_INVENTORY': 'device_hub',
  'SEARCH_CUSTOMER': 'portrait',
  'SEARCH_DISPUTE': 'record_voice_over',
  'SEARCH_QUOTE': 'monetization_on',
  'SEARCH_CUSTOMER_INVENTORY': 'contact_phone',
  'SEARCH_CONTRACT': 'description',

  /* Srm */
  'SRM_SORT': 'sort',
  'SRM_AGGREGATE': 'functions',
  'SRM_REMOVE_SORTING_COLUMN': 'delete',
  'SRM_CUSTOM_FIELDS_ADD': 'add_box',
  'SRM_CUSTOM_FIELDS_CLEAR': 'clear_all',
  'SRM_CUSTOM_FIELDS_ADVANCED': 'developer_mode',
  'SRM_CUSTOM_FIELDS_DELETE': 'delete',
  'SRM_CUSTOM_FIELDS_SHOW': 'visibility',
  'SRM_CUSTOM_FIELDS_HIDE': 'visibility_off',
  'SRM_GRID_FORMATTING': 'text_format',
  'SRM_INTERACTIVE_FILTER_REMOVE': 'delete',
  'SRM_PIVOT_SETTINGS_COLUMN_REMOVE': 'delete',
  'SRM_PIVOT_SETTINGS_ROW_REMOVE': 'delete',
  'SRM_PIVOT_SETTINGS_DATA_REMOVE': 'delete',
  'SRM_FILTER_ADD': 'add_box',
  'SRM_FILTER_DELETE': 'delete',
  'SRM_TABLE_SETTINGS_SORT_REMOVE': 'delete',
  'SRM_TABLE_SETTINGS_GROUPBY_ACTIVATE': 'blur_on',
  'SRM_TABLE_SETTINGS_GROUPBY_DISABLE': 'blur_off',
  'SRM_SAVE': 'done',
  'SRM_SAVE_AS': 'content_copy',

  /* Tags */
  'MERGE_TAGS': 'compare_arrows',

  /* Vendor */
  'VENDOR_ADD_ENTITY': 'note_add',
  'VENDOR_DELETE_ENTITY': 'delete',

  /* Network hub */
  'NETWORK_HUB_DELETE_CIRCUIT': 'delete',
  'NETWORK_HUB_LINK_ICON': 'chevron_right',
  'PENDING_DISCONNECT': 'warning',

  /* Recon */
  'RECON_ADD_BILLING_ALIAS': 'link',
  'RECON_EXCLUDE': 'remove_circle_outline',

  /* TN/TFN */
  'TN_TFN_ASSIGN_TO_CUSTOMER': 'assignment_ind',
  'TN_TFN_DEACTIVATE': 'remove_circle',
  /* General Ledger */
  'VIEW_INVOICE_DETAILS': 'link',

  /* Alert */

  'VIEW_ALERT_DETAILS': 'link',

  /*Wireless*/
  'ADD_NEW_INSTANCE': 'filter_none',

  /*Wireless*/
  'SUSPENSE_RECEIPT': 'receipt',
};
