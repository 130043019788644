import { Injectable } from '@angular/core';
import { SubclientLookupProvider } from "../../account/core/subclient-lookup.provider";
import { FilterService } from "../../core/filter/filter.service";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { CurrencyLookupProvider } from '../../location/core/currency-lookup.provider';
import { LocationService } from '../../location/core/location.service';
import { UserSettingsService } from "../../user/core/user-settings.service";
import { VendorLookupProvider } from "../../vendor/core/vendor-lookup.provider";
import { VendorService } from "../../vendor/core/vendor.service";
import { disputeFilterContext } from "./dispute-filter.context";
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class DisputeFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public dictionaryService: DictionaryService,
              public vendorService: VendorService,
              public userSettingsService: UserSettingsService,
              public locationService: LocationService
  ) {
    super("app.cost.dispute-filter", disputeFilterContext, userSettingsService);

    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('disputeStatus', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.DISPUTE_STATUS.modelName));
    this.addLookupProvider('disputeWithheldStatus', new LookupModelLookupProvider(dictionaryService,
      LOOKUP_MODELS_ENUM.DISPUTE_WITHHELD_STATUS.modelName));
    this.addLookupProvider('currency', new CurrencyLookupProvider(this.locationService));
    this.addLookupProvider('subclient', new SubclientLookupProvider(this.dictionaryService));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
