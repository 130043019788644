import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Building} from "./building";
import {Restangular} from "ngx-restangular";
import Query from "../../core/query/query";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class BuildingService extends BaseService<Building> {

  constructor(public restangular: Restangular, private entityLock: EntityLockService) {
    super('building', restangular, entityLock);
  }

  advancedSearch(query?: Query): any {

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().all(this.name).all('advancedSearch').customGET(null, this.toFilter(transformedQuery));
  }

}
