
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import { DataLockDialogService } from '../../core/data-lock/data-lock-dialog.service';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { VendorRemitAddress } from '../core/vendor-remit-address';
import { VendorRemitAddressFilterService } from '../core/vendor-remit-address-filter.service';
import { VendorRemitAddressGridService } from '../core/vendor-remit-address-grid.service';
import { VendorRemitAddressQuery } from '../core/vendor-remit-address.query';
import { VendorRemitAddressService } from '../core/vendor-remit-address.service';
import { VendorRemitAddressManageDialogComponent } from '../shared/vendor-remit-address-manage-dialog/vendor-remit-address-manage-dialog.component';
import { GridService } from '../../shared/grid/grid.service';


@Component({
  selector: 'app-vendor-remit-address-list',
  templateUrl: './vendor-remit-address-list.component.html',
})
export class VendorRemitAddressListComponent extends PageListComponent implements OnInit, AfterViewInit {
  public query: VendorRemitAddressQuery = new VendorRemitAddressQuery({
    orderBy: [['vendor_location_id', 'ASC']]
  });

  public vendorRemitAddresses: Array<VendorRemitAddress>;
  public totalVendorRemitAddresses: number;
  public selection: VendorRemitAddress;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public sorting: any[][];

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'vendor-remit-address';
  readonly CORE_MODULE: string = 'core';

  constructor(public vendorRemitAddressService: VendorRemitAddressService,
              public dialogService: DialogService,
              public alertService: AlertService,
              public settingsService: UserSettingsService,
              public vendorRemitAddressGridService: VendorRemitAddressGridService,
              public loaderService: LoaderService,
              public dataLockDialogService: DataLockDialogService,
              public vendorRemitAddressFilterService: VendorRemitAddressFilterService) {
    super(new PageContext({
      name: 'app.vendor.vendor-remit-address-list',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  loadData(query?: VendorRemitAddressQuery) {
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.vendorRemitAddressService.findAll(query)
      .subscribe((result) => {
          this.vendorRemitAddresses = result.items;
          this.query.total = this.totalVendorRemitAddresses = result.total;

          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        },
        (error) => {
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        });
  }

  filterData(query: VendorRemitAddressQuery) {
    this.query = query;
    this.loadData(query);
  }

  refresh() {
    this.loadData(this.query);
  }

  clearFilter() {
    this.query.where = null;
    this.loadData(this.query);
  }

  newVendorRemitAddress() {
    this.dialogService.open(VendorRemitAddressManageDialogComponent, {
      vendorRemitAddress: {}
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.refresh();
          this.alertService.success('', this.messages.get('CREATE_SUCCESS'));
        }
      });
  }

  showDetails() {
    let vendorRemitAddress = this.selection;
    if (vendorRemitAddress) {
      this.dialogService.open(VendorRemitAddressManageDialogComponent, {
        vendorRemitAddress: vendorRemitAddress,
        viewOnly: true
      })
        .afterClosed()
        .subscribe((result) => {
          this.afterModalClosed(result, vendorRemitAddress);
          this.dataGrid.instance.refresh();
        });
    }
  }

  afterModalClosed(result, item) {
    if (result) {
      if (result.cancelByTimer || result.status == 2) {
        return this.alertService.success('', this.coreMessages.get('TIMER_EXPIRED'));
      } else if (result.deleted) {
        this.loadData(this.query);
        this.selectFirstRow();
        setTimeout(() => {
          this.alertService.snackBar.dismiss();
          this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
        }, 255);
      } else if (result.id) {
        Object.assign(item, result);
        this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
      }
      this.refresh();
      //this.dataGrid.instance.refresh();
    }
  }

  editVendorRemitAddress(vendorRemitAddress) {
    this.dialogService.edit(
      VendorRemitAddressManageDialogComponent,
      {
        vendorRemitAddress: this.vendorRemitAddressService.findByIdForEdit(vendorRemitAddress.id)
      }).subscribe((items: any) => {
      if (items) {
        let obs = observableOf(items);
        obs.pipe(mergeMap(x => x.afterClosed()))
          .subscribe((result) => {
            if (result) {
              this.afterModalClosed(result, vendorRemitAddress)
              this.refresh();
            }
          })
      }
    })
  }

  csv() {
    const { vendorRemitAddressGridService, vendorRemitAddressService, query } = this;
    vendorRemitAddressGridService.csvMap().subscribe(fields => {
      vendorRemitAddressService.exportToCSV(
        'remit_addresses',
        {
          fields,
          query
        }
      );
    });
  }

  onSelectionChanged(row) {
    this.selection = <VendorRemitAddress>row.selectedRowsData[0];
  }

  ngOnInit() {
    this._init();
  }

  ngAfterViewInit(): void {
    this.vendorRemitAddressGridService.create(this.dataGrid.instance, {});
    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.vendorRemitAddressGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.vendorRemitAddressGridService;
  }

  getDefaultSort(): any {
    return [['vendor_location_id', 'ASC']];
  }
}
