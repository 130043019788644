import {Injectable} from '@angular/core';
import {FilterService} from '../../core/filter/filter.service';
import {reconAccrualsFilterContext} from './recon-accruals-filter.context';
import {VendorService} from '../../vendor/core/vendor.service';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {VendorLookupProvider} from '../../vendor/core/vendor-lookup.provider';
import {SiteLookupProvider} from 'app/site/core/site-lookup.provider';
import {SiteService} from 'app/site/core/site.service';
import {DefaultDateRangesLookupProvider} from 'app/core/filter/default-date-ranges-lookup.provider';
import {CurrencyLookupProvider} from '../../location/core/currency-lookup.provider';
import {LocationService} from '../../location/core/location.service';
import {AccountLookupProvider} from "../../account/core/account-lookup.provider";
import {AccountService} from "../../account/core/account.service";

@Injectable()
export class ReconAccrualsFilterService extends FilterService {
  customRanges: any = [
    {
      label: 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      label: 'Prior month'
    },
    {
      label: 'Last week'
    },
    {
      label: 'Prior year'
    },
  ];

  constructor(
    public vendorService: VendorService,
    public userSettingsService: UserSettingsService,
    public siteService: SiteService,
    public locationService: LocationService,
    public accountService: AccountService
  ) {
    super(
      'app.recon.accruals-filter',
      reconAccrualsFilterContext,
      userSettingsService
    );

    this.addLookupProvider(
      'vendor',
      new VendorLookupProvider(vendorService, true)
    );
    this.addLookupProvider('site', new SiteLookupProvider(siteService));
    this.addLookupProvider('currency', new CurrencyLookupProvider(locationService));
    this.addLookupProvider('account', new AccountLookupProvider(accountService));
    this.addLookupProvider(
      'defaultDateRanges',
      new DefaultDateRangesLookupProvider(
        this.customRanges,
        this.excludeRanges,
        true
      )
    );
  }
}
