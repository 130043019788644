<app-inventory-details-toolbar
  [inventory]="inventory"
  [isAddPricingDisabled]="false"
  [isAddPricingHidden]="false"
  [pricingSelection]="pricingSelection"
  [isPricingDocumentAction]="true"
  [isAddGLHidden]="true"
  [isAddGLDisabled]="true"
  [isCreateOrderHidden]="false"
  [isCreateOrderDisabled]="true"
  [isEditInventoryDisabled]="true"
  [isDocumentAction]="false"
  [isFilterDisabled]="true"
  [isNotesOn]="($activeSiderSection | async) === SECTIONS.NOTES_SECTION_NAME"
  [isHistoryOn]="($activeSiderSection | async) === SECTIONS.HISTORY_SECTION_NAME"
  [isCSVDisabled]="false"
  [notesCount]="notesCount"
  
  (downloadCSVRequested)="onDownloadCSVRequested()"
  (addPricingRequested)="addPricing()"
  (invokeDeletePricingRequested)="invokeDeletePricing()"
  (invokeDownloadPricingRequested)="invokeDownloadPricing()"
  (toggleNotesRequested)="toggleNotes()"
  (toggleHistoryRequested)="toggleHistory()"
  (backRequested)="back()"
></app-inventory-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-inventory-details-tabs [activeTabIndex]="5"></app-inventory-details-tabs>
  <div class="app-content app-content-list dx-nodata-absolute" style="height: 100%">
  <dx-data-grid #pricingsGrid
                style="height: 100%"
                (onSelectionChanged)="onPricingSelectionChange($event)"
                [columns]="pricingColumns"
                [dataSource]="pricing"
                [showRowLines]="true"
                [showColumnLines]="false">
    <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>
    <dxo-sorting mode="none"></dxo-sorting>
                <div *dxTemplate="let d of 'dateTemplate'">
                  <div caGridTooltip>{{ d.value | caDate }}</div>
                </div>
                <div *dxTemplate="let d of 'currencyTemplate'">
                  <div caGridTooltip class="right">{{ d.value | caCurrency: inventory?.currency_obj?.currency}}</div>
                </div>
  </dx-data-grid>
</div>
  <mat-sidenav #panelSide
               [mode]="'side'"
               position="end"
               [opened]="false">
    <ca-history
      #history
      [entityId]="inventory?.id"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
      [entityType]="'inventory'">
    </ca-history>

    <ca-notes
      [entityId]="inventory?.id"
      [entityType]="SYSTEM_MODULE.INVENTORY"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
      (countChanged)="onNotesCountChanged($event)">
    </ca-notes>
  </mat-sidenav>
</mat-sidenav-container>
