
import {of as observableOf, Observable} from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { TagManageDialogComponent } from '../shared/tag-manage-dialog/tag-manage-dialog.component';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { TagAdministrationGridService } from '../core/tag-administration-grid.service';
import { TagAdministrationQuery } from '../core/tag-administration.query';
import { TagAdministrationService } from '../core/tag-administration.service';
import { UserSettingsService } from "../../user/core/user-settings.service";
import { PageContext } from "../../core/page.context";
import { LoaderService } from '../../shared/loader/loader.service';
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";
import { Component, OnInit, ViewChild } from '@angular/core';
import { PageListComponent } from "../../core/page-list.component";
import { Tag } from "../core/tag";
import {IMessagesResourceService, ResourcesService} from "../../core/resources/resources.service";
import {DataLockDialogService} from "../../core/data-lock/data-lock-dialog.service";
import { TagAdministrationFilterService } from '../core/tag-administration-filter.service';
import { GridService } from '../../shared/grid/grid.service';


@Component({
  selector: 'app-tag-administration-list',
  templateUrl: './tag-administration-list.component.html',
  styleUrls: ['./tag-administration-list.component.css']
})
export class TagAdministrationListComponent extends PageListComponent implements OnInit {

  public query: TagAdministrationQuery = new TagAdministrationQuery({
    orderBy: [["id", "DESC"]]
  });

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "tag";
  readonly CORE_MODULE: string = "core";

  public columns: Array<any>;
  public sorting: any[][];

  public tags: Array<Tag>;
  public selection: Tag;
  public selectedRows: Array<any> = [];
  public delaySelectionChange: boolean = false;
  public selectionActive: boolean = false;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  constructor(public dialogService: DialogService,
    public alertService: AlertService,
    public tagAdministrationGridService: TagAdministrationGridService,
    public tagAdministrationService: TagAdministrationService,
    public settingsService: UserSettingsService,
    public dataLockDialogService: DataLockDialogService,
    public tagAdministrationFilterService: TagAdministrationFilterService,
    public loaderService: LoaderService) {
    super(new PageContext({
      name: "app.tag-administration.tag-list",
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);

  }

  loadData(query?: TagAdministrationQuery) {

    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.tagAdministrationService.findAll(query)
      .subscribe((result) => {
        this.tags = result.items;
        this.query.total = result.total;
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      },
      (error) => {
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      });
  }

  public newTag() {
    this.dialogService.open(TagManageDialogComponent, {
      tag: {}
    }, { width: '400px'})
      .afterClosed()
      .subscribe((result) => {
        this.refresh();
        if (result) {
          this.alertService.success("", this.messages.get('CREATE_SUCCESS'));
        }
      });
  }

  editTag(tag) {
    this.dialogService.edit(
      TagManageDialogComponent,
      {
        tag: this.tagAdministrationService.findByIdForEdit(tag.id)
      }).subscribe((items: any) => {
      if (items) {
        let obs = observableOf(items);
        obs.pipe(mergeMap(x => x.afterClosed() ))
          .subscribe((result) => {
            if (result) {
              this.refresh();
              this.alertService.success("", this.messages.get('UPDATE_SUCCESS'));
            }
          })
      }
    })
  }

  ngOnInit() {
    this._init();
  }

  ngAfterViewInit(): void {
    this.tagAdministrationGridService.create(this.dataGrid.instance, {
      selection: {
        mode: 'multiple',
        selectAllMode: 'page'
      }
    });

    //this.dataGrid.instance.columnOption("command:select", "width", 65);

    super.ngAfterViewInit();
  }

  onSelectionChanged(event) {
    if (!this.delaySelectionChange && this.tags.length) {

      //** Deselected rows */
      if (event.currentDeselectedRowKeys.length) {
        event.currentDeselectedRowKeys.forEach((item) => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index > -1) {
            this.selectedRows.splice(index, 1);
          }
        });
      }

      //** Selected rows */
      if (event.currentSelectedRowKeys.length) {
        event.currentSelectedRowKeys.forEach((item) => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index === -1) {
            this.selectedRows.push(item);
          }
        });
      }

      if (this.selectedRows.length > 1) {
        //
      } else {
        this.selection = <Tag>event.selectedRowsData[0];
      }

      if (this.selectionActive && !this.selectedRows.length) {
        this.loadSelected();
      }
    }
  }

  public mergeTags(selectedRows) {
    this.dialogService.open(TagManageDialogComponent, {
      selectedRows: selectedRows
    }, { height: '400px' })
      .afterClosed()
      .subscribe((result) => {
        this.refresh();
        if (result) {
          this.alertService.success("", this.messages.get('CREATE_SUCCESS'));
        }
      });
  }

  /** Selection badge active */
  public loadSelected() {
    if (this.selectionActive) {
      this.query.remove('id');
    } else {
      this.query['where'] = {};
      this.query.set('id', {
        $in: this.selectedRows.map((x) => {
          return x.id;
        })
      });
    }

    this.query.offset = 0;
    this.query.page = 1;

    this.selectionActive = !this.selectionActive;
    this.loadData(this.query);

    if (this.selectionActive) {
      this.panelSide.close();
    }
  }

  findIndexInSelectedRows(id): number {
    for (var i = 0, l = this.selectedRows.length; i < l; i++) {
      if (this.selectedRows[i].id === id) {
        return i;
      }
    }
    return -1;
  }

  refresh() {
    this.loadData(this.query);
  }

  gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.tagAdministrationGridService,
      sliderMinimum: 1
    }, { width: '550px' })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.tagAdministrationGridService;
  }

  getDefaultSort(): any {
    return [['id', 'DESC']];
  }

  filterData(query: TagAdministrationQuery) {
    this.query = query;
    this.loadData(query);
  }

  clearFilter() {
    this.loadData(null);
  }

  sortColumn(sorting) {
    this.query = this.query || new TagAdministrationQuery();
    this.query.orderBy = sorting;

    this.loadData(this.query);
  };

}
