import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChargeRoutingModule } from './charge-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { DisputeModule } from '../dispute/dispute.module';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChargeComponent } from './charge.component';
import { ChargeListComponent } from './charge-list/charge-list.component';
import { InvoiceChargeService } from './core/invoice-charge.service';
import { ChargeGridService } from './core/charge-grid.service';
import { DisputeChargeService } from './core/dispute-charge.service';
import { CoreModule } from '../core/core.module';
import { ChargeFilterService } from './core/charge-filter.service';
import { AccountService } from '../account/core/account.service';
import { ChargeSneakPeekComponent } from './shared/charge-sneak-peek/charge-sneak-peek.component';
import { InvoiceFacepageService } from '../invoice/core/invoice-facepage.service';

import { MatMomentDateModule } from '@angular/material-moment-adapter';


@NgModule({
  imports: [
    CommonModule,
    ChargeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DisputeModule,
    DxButtonModule,
    DxDataGridModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxDateBoxModule,
    MaterialModule,
    FlexLayoutModule,
    CoreModule,
    MatMomentDateModule,
  ],
  exports: [],
  declarations: [
    ChargeComponent,
    ChargeListComponent,
    ChargeSneakPeekComponent,
  ],
  providers: [
    InvoiceChargeService,
    DisputeChargeService,
    ChargeGridService,
    ChargeFilterService,
    AccountService,
    InvoiceFacepageService
  ]
})

export class ChargeModule {
}
