import {LOOKUP_ENUM} from "../../dictionary/core/lookup.enum";

export const commonAlertFilterContext = {
  filters: [

    {
      field: 'status.key',
      label: 'Status',
      type: 'lookup',
      lookupProviderName: 'status',
      editor: 'checkboxList',
      index: 0,
      defaultValue: [LOOKUP_ENUM.ALERT_STATUS_STRING.NEW, LOOKUP_ENUM.ALERT_STATUS_STRING.IN_PROGRESS]
    },
    {
      field: 'type.key',
      label: 'Type',
      type: 'lookup',
      lookupProviderName: 'type',
      editor: 'checkboxList',
      index: 1
    },
    {
      field: 'category.key',
      label: 'Category',
      type: 'lookup',
      lookupProviderName: 'category',
      editor: 'checkboxList',
      index: 2
    },
    {
      field: 'created_at',
      label: 'Create Time',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 3,
    },
    {
      field: 'creator.full_name',
      label: 'Created By',
      type: 'lookup',
      lookupProviderName: 'creator',
      index: 4,
    },
    {
      field: 'rule_name',
      label: 'Rule Name',
      type: 'string',
      index: 5
    },
    {
      field: 'message',
      label: 'Message',
      type: 'string',
      index: 6
    },
    {
      field: 'priority.key',
      label: 'Priority',
      type: 'lookup',
      lookupProviderName: 'priority',
      editor: 'checkboxList',
      index: 7
    },
    {
      field: 'owner.full_name',
      label: 'Owner',
      type: 'lookup',
      lookupProviderName: 'owner',
      index: 8,
    },
    {
      field: 'invoice.sp_inv_num',
      label: 'Invoice Number',
      type: 'string',
      index: 9
    },
    {
      field: 'vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 10
    },
    {
      field: 'account_no',
      label: 'Account',
      type: 'lookup',
      lookupProviderName: 'account',
      index: 11
    },
    {
      field: 'last_modified_at',
      label: 'Last Modification Time',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 12,
    },
    {
      field: 'closed_at',
      label: 'Closed Time',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 14,
    },
    {
      field: 'id',
      label: 'Alert ID',
      type: 'number',
      index: 15,
    }
  ]
};
