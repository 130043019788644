import { Injectable } from '@angular/core';
import { GridService } from "../../shared/grid/grid.service";
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class GLStringSelectGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.gl.gl-string-select-grid", userSettings);
  }

  columns() {
    //this is generated in the code. depends on parameters
    return [
      
   ]
  }
}
