<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Orders</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button  *permission="'Create'" matTooltip="New Order" mat-icon-button [matMenuTriggerFor]="menu">
    <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>
  <button
    *permission="'Modify'"
    mat-icon-button
    [disabled]="isEditDisabled()"
    (click)="editOrder(selection)"
  >
    <ca-icon [key]="'EDIT'" [tooltip]="'Edit Order'"></ca-icon>
  </button>
  <button
    mat-icon-button
    [ngClass]="{ on: serviceLevel }"
    (click)="serviceLevelToggle()"
    matTooltip="Service Level"
  >
    <ca-icon [key]="'SERVICE_LEVEL'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh List" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button
    matTooltip="Filter"
    [ngClass]="{ on: sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
    (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)"
    mat-icon-button
  >
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button
    #toggleDetailsEnableButton
    [ngClass]="{ on: sider?.isActive(SECTIONS.DETAILS_SECTION_NAME) }"
    [disabled]="!selection"
    (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)"
    mat-icon-button
  >
    <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">
  <dx-data-grid
    [ngClass]="{ hidden: serviceLevel }"
    #grid
    (onRowClick)="onRowClick($event)"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onCellClick)="onCellClick($event)"
    [columns]="columns"
    [height]="'100%'"
    [width]="'100%'"
    class="app-grid-with-pager"
    [dataSource]="orders"
  >
    <div *dxTemplate="let d of 'serviceCount'">
      <div caGridTooltip>{{ d.value.length || 0 }}</div>
    </div>
    <div *dxTemplate="let d of 'sentDate'">
      <div caGridTooltip>{{ d.value | caDate }}</div>
    </div>
    <div *dxTemplate="let d of 'createDate'">
      <div caGridTooltip>{{ d.value | caDateTime }}</div>
    </div>
    <div *dxTemplate="let d of 'requestDate'">
      <div caGridTooltip>{{ d.value | caDate }}</div>
    </div>
    <!--<div
      *dxTemplate="let d of 'orderNoHeaderTemplate'"
      style="text-align: left;"
    >
      Order No
    </div>-->
  </dx-data-grid>
  <ca-pager
    [ngClass]="{ hidden: serviceLevel }"
    [gridPager]="defaultGridPager"
    [pageContext]="orderGridService"
    [query]="query"
    class="app-pager ca-default-border-top"
    (onChange)="loadData(query)"
  ></ca-pager>

  <dx-data-grid
    [ngClass]="{ hidden: !serviceLevel }"
    #orderServiceLevelGrid
    (onRowClick)="onRowClick($event)"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onCellClick)="onCellClick($event)"
    [columns]="orderServiceLevelColumns"
    [height]="'100%'"
    [width]="'100%'"
    class="app-grid-with-pager"
    [dataSource]="orderServiceLevelItems"
  >
    <div *dxTemplate="let d of 'statusDate'">
      <div caGridTooltip>{{ d.value | caDate }}</div>
    </div>

    <div *dxTemplate="let d of 'statusType'">
      <div caGridTooltip>{{ d && d.data.order.type_id === ORDER_TYPE_ENUM.DISCONNECT && d.data.disconnect_status ? d.data.disconnect_status.value : (d && d.value ? d.value : 'N/A') }}</div>
    </div>
    <!--<div *dxTemplate="let d of 'orderNoTemplate'" style="text-align: left;">
      <div caGridTooltip>{{ d.data.order.full_order_id }}</div>
    </div>-->
  </dx-data-grid>

  <ca-pager
    [ngClass]="{ hidden: !serviceLevel }"
    [gridPager]="defaultServiceGridPager"
    [pageContext]="orderServiceLevelGridService"
    [query]="orderServiceLevelQuery"
    class="app-pager ca-default-border-top"
    (onChange)="loadOrderServiceLevelItems(query)"
  ></ca-pager>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter
      *ngIf="!serviceLevel"
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
      [query]="query"
      [filterService]="orderFilterService"
      (filterRequested)="filterData($event)"
      (clearFilterRequested)="clearFilter($event)"
    >
    </new-app-filter>

    <new-app-filter
      *ngIf="serviceLevel"
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
      [query]="orderServiceLevelQuery"
      [filterService]="orderServiceLevelFilterService"
      (filterRequested)="filterData($event)"
      (clearFilterRequested)="clearFilter($event)"
    >
    </new-app-filter>

    <ca-order-sneak-peek
      [order]="selection"
      (closeRequested)="sider?.close()"
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME) }"
      [isServiceLevel]="serviceLevel"
    >
      <ca-order-service-level-sneak-peek
        *ngIf="serviceLevel"
        [orderService]="orderServiceSelection"
      >
      </ca-order-service-level-sneak-peek>
    </ca-order-sneak-peek>
  </mat-sidenav>
  <mat-menu class="ca-user-menu" #menu="matMenu">
    <ng-container *ngFor="let item of adjustmentsKeys">
      <button mat-menu-item (click)="newOrder(item)" style="text-transform: capitalize">
        {{ item.split('_').join(' ').toLowerCase() }}
      </button>
    </ng-container>
  </mat-menu>
</mat-sidenav-container>
