import {Injectable} from '@angular/core';
import {GridService} from "../../shared/grid/grid.service";
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class InvoiceContactGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.accounts.invoice-contact-grid", userSettings);
  }

  defaultColumns =
    [{
      caption: "Contact Name",
      dataField: "cont_name",
      width: 150
    }, {
      caption: "Contact Type",
      dataField: "cont_type",
      width: 150
    }, {
      caption: "Contact Email",
      dataField: "cont_email",
      width: 150
    }, {
      caption: "Contact Phone",
      dataField: "cont_number",
      cellTemplate: 'phoneTemplate'
    }];

  /*columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/

}
