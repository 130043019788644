import { Injectable } from '@angular/core';
import { FormatService } from '../../shared/format/format.service';
import { GridService } from '../../shared/grid/grid.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class GlRuleExecutionChargesGridService extends GridService {
  private readonly defaultColumnsWhenRestoring = [] ;

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.cost.gl-rule-invoice-charges-grid', userSettings);
    this.defaultColumns = [
      {
        caption: 'ID',
        dataField: 'id',
        alignment: 'left',
        width: 120
      },
      {
        caption: 'Vendor',
        dataField: 'vendor_name',
        width: 130
      },
      {
        caption: 'Account',
        dataField: 'acct_level_1',
        width: 150
      },
      {
        caption: 'Rule Name',
        dataField: 'gl_rule_type',
        width: 150
      },
      {
        caption: 'Invoice Number',
        dataField: 'sp_inv_num',
        width: 170
      },
      {
        caption: 'Invoice Date',
        dataField: 'inv_date',
        dataType: 'date',
        cellTemplate: 'dateTemplate',
        alignment: 'left',
        width: 120
      }, {
        caption: 'Subaccount',
        dataField: 'acct_level_2',
        width: 120
      }, {
        alignment: 'left',
        caption: 'Coded Status',
        dataField: 'charge_coded',
        cellTemplate: 'chargeCodedTemplate',
        transformValue: {
          true: 'Coded',
          false: 'Uncoded'
        },
        width: 120
      }, {
        caption: 'Charge Type',
        dataField: 'chg_class',
        width: 120
      }, {
        caption: 'Service ID',
        dataField: 'sp_serv_id',
        width: 120
      }, {
        caption: 'Charge Code1',
        dataField: 'chg_code_1',
        width: 120
      }, {
        caption: 'Charge Description 1',
        dataField: 'chg_desc_1',
        width: 170
      }, {
        caption: 'Charge Code2',
        dataField: 'chg_code_2',
        width: 120
      }, {
        caption: 'Charge Description 2',
        dataField: 'chg_desc_2',
        width: 170
      }, {
        alignment: 'right',
        caption: 'Chg Qty',
        dataField: 'chg_qty1_billed',
        dataType: 'number',
        cellTemplate: 'numberTemplate',
        csvDataType: 'integer',
        width: 120
      }, {
        caption: 'Charge Rate',
        dataField: 'chg_rate',
        cellTemplate: 'chargeRateTemplate',
        csvDataType: 'float',
        width: 120
      }, {
        caption: 'Charge Amount',
        dataField: 'chg_amt',
        cellTemplate: 'invoiceCurrencyTemplate',
        dataType: 'currency',
        alignment: 'right',
        width: 120
      }, {
        caption: 'Currency',
        dataField: 'currency',
        width: 120
      }, {
        caption: 'Install Date',
        dataField: 'svc_establish_date',
        cellTemplate: 'dateTemplate',
        dataType: 'date',
        alignment: 'left',
        width: 120
      }, {
        caption: 'Beg Charge Date',
        dataField: 'beg_chg_date',
        cellTemplate: 'dateTemplate',
        dataType: 'date',
        alignment: 'left',
        width: 150
      }, {
        caption: 'End Charge Date',
        dataField: 'end_chg_date',
        cellTemplate: 'dateTemplate',
        dataType: 'date',
        alignment: 'left',
        width: 150
      }, {
        caption: 'Info Only Ind',
        dataField: 'info_only_ind',
        width: 150
      }, {
        caption: 'Facility Bandwidth',
        dataField: 'fac_bw',
        width: 150
      }, {
        caption: 'Call Type',
        dataField: 'call_type',
        width: 120
      }, {
        caption: 'Dir Ind',
        dataField: 'dir_ind',
        width: 120
      }, {
        caption: 'Jurisdiction',
        dataField: 'jur',
        width: 120
      }, {
        caption: 'Chg Qty Type',
        dataField: 'chg_qty1_type',
        width: 130
      }, {
        alignment: 'right',
        caption: 'Chg Qty Used',
        dataField: 'chg_qty1_used',
        dataType: 'number',
        csvDataType: 'integer',
        width: 120
      }, {
        caption: 'Chg Qty Allowed',
        dataField: 'chg_qty1_allowed',
        width: 140
      }, {
        caption: 'Rel Sp Fac ID',
        dataField: 'rel_sp_fac_id',
        width: 140
      }, {
        caption: 'VAT Type',
        dataField: 'vat_type',
        width: 100
      }
    ];

    this.defaultColumnsWhenRestoring = [
      {
        caption: ' ID',
        dataField: 'charge_id',
        alignment: 'left',
        width: 120
      },
      {
        caption: 'Vendor',
        dataField: 'vendor_name',
        width: 130
      },
      {
        caption: 'Account',
        dataField: 'acct_level_1',
        width: 150
      },
      {
        caption: 'Rule Name ',
        dataField: 'gl_rule_type',
        width: 150
      },
      {
        caption: 'Invoice Number',
        dataField: 'sp_inv_num',
        width: 170
      },
      {
        caption: 'Invoice Date',
        dataField: 'inv_date',
        cellTemplate: 'dateTemplate',
        dataType: 'date',
        alignment: 'left',
        width: 120
      }, {
        caption: 'Subaccount',
        dataField: 'acct_level_2',
        width: 120
      }, {
        alignment: 'left',
        caption: 'Coded Status',
        dataField: 'charge_coded',
        transformValue: {
          true: 'Coded',
          false: 'Uncoded'
        },
        cellTemplate: 'chargeCodedTemplate',
        width: 120
      }, {
        caption: 'Charge Type',
        dataField: 'chg_class',
        width: 120
      }, {
        caption: 'Service ID',
        dataField: 'sp_serv_id',
        width: 120
      }, {
        caption: 'Charge Code1',
        dataField: 'chg_code_1',
        width: 120
      }, {
        caption: 'Charge Description 1',
        dataField: 'chg_desc_1',
        width: 170
      }, {
        caption: 'Charge Code2',
        dataField: 'chg_code_2',
        width: 120
      }, {
        caption: 'Charge Description 2',
        dataField: 'chg_desc_2',
        width: 170
      }, {
        alignment: 'right',
        caption: 'Chg Qty',
        dataField: 'chg_qty1_billed',
        dataType: 'number',
        csvDataType: 'integer',
        width: 120
      }, {
        caption: 'Charge Rate',
        dataField: 'chg_rate',
        cellTemplate: 'chargeRateTemplate',
        csvDataType: 'float',
        width: 120
      }, {
        caption: 'Charge Amount',
        dataField: 'chg_amt',
        cellTemplate: 'invoiceCurrencyTemplate',
        dataType: 'currency',
        alignment: 'right',
        width: 120
      },{
        caption: 'Currency',
        dataField: 'currency',
        width: 120
      }, {
        caption: 'Install Date',
        dataField: 'svc_establish_date',
        cellTemplate: 'dateTemplate',
        dataType: 'date',
        alignment: 'left',
        width: 120
      }, {
        caption: 'Beg Charge Date',
        dataField: 'beg_chg_date',
        cellTemplate: 'dateTemplate',
        dataType: 'date',
        alignment: 'left',
        width: 150
      }, {
        caption: 'End Charge Date',
        dataField: 'end_chg_date',
        cellTemplate: 'dateTemplate',
        dataType: 'date',
        alignment: 'left',
        width: 150
      }, {
        caption: 'Info Only Ind',
        dataField: 'info_only_ind',
        width: 150
      }, {
        caption: 'Facility Bandwidth',
        dataField: 'fac_bw',
        width: 150
      }, {
        caption: 'Call Type',
        dataField: 'call_type',
        width: 120
      }, {
        caption: 'Dir Ind',
        dataField: 'dir_ind',
        width: 120
      }, {
        caption: 'Jurisdiction',
        dataField: 'jur',
        width: 120
      }, {
        caption: 'Chg Qty Type',
        dataField: 'chg_qty1_type',
        width: 130
      }, {
        alignment: 'right',
        caption: 'Chg Qty Used',
        dataField: 'chg_qty1_used',
        dataType: 'number',
        csvDataType: 'integer',
        width: 120
      }, {
        caption: 'Chg Qty Allowed',
        dataField: 'chg_qty1_allowed',
        width: 140
      }, {
        caption: 'Rel Sp Fac ID',
        dataField: 'rel_sp_fac_id',
        width: 140
      }, {
        caption: 'VAT Type',
        dataField: 'vat_type',
        width: 100
      }
    ];
  }

  public restoreColumns() {
    this.defaultColumns = this.defaultColumnsWhenRestoring;
  }
}




