import {Injectable} from '@angular/core';
import {FilterService} from '../../core/filter/filter.service';
import {buildingFilterContext} from './building-filter.context';
import {LocationService} from "../../location/core/location.service";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {SubdivisionLookupProvider} from "../../location/core/subdivision-lookup.provider";
import { CountryLookupProvider } from 'app/location/core/country-lookup.provider';

@Injectable()
export class BuildingFilterService extends FilterService {

  constructor(public locationService: LocationService,
              public userSettingsService: UserSettingsService) {
    super("app.appdata.building-filter", buildingFilterContext, userSettingsService);
    this.addLookupProvider('subdivision', new SubdivisionLookupProvider(locationService, true));
    this.addLookupProvider('country', new CountryLookupProvider(locationService, true));
  }
}
