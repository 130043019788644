<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Customer</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button *permission="'Create'" matTooltip="New Customer" mat-icon-button (click)="newCustomer()">
    <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>
  <button *permission="'Modify'" mat-icon-button (click)="editCustomer(selection)" matTooltip="Edit Customer">
    <ca-icon [key]="'EDIT'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh List" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button matTooltip="Filter" [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button
  >
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button #toggleDetailsEnableButton [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
          [disabled]="!selection"
          (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" mat-icon-button
  >
    <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">

  <dx-data-grid #grid
                (onRowClick)="onRowClick($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onCellClick)="onCellClick($event)"
                [columns]="columns"
                [width]="'100%'"
                [height]="'100%'"
                class="app-grid-with-pager"
                [dataSource]="customers"
  >
    <div *dxTemplate="let d of 'phoneTemplate'">
      <div *ngIf="d.data?.corporate_address?.country?.phone_regex_mask" caGridTooltip>{{ d.value | mask: d.data?.corporate_address?.country?.phone_regex_mask }}</div>
      <div *ngIf="!d.data?.corporate_address?.country?.phone_regex_mask" caGridTooltip>{{ d.value }}</div>
    </div>
  </dx-data-grid>
  <ca-pager
    [doNotEmitQueryOnInitialLoad]="true"
    [gridPager]="defaultGridPager"
    [pageContext]="customerGridService"
    [query]="query"
    class="app-pager ca-default-border-top" (onChange)="loadData(query)"
  >
  </ca-pager>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                    [query]="query"
                    [filterService]="customerFilterService"
                    (filterRequested)="filterData($event)"
                    (clearFilterRequested)="clearFilter()"
    >
    </new-app-filter>

    <ca-customer-sneak-peek [customer]="selection"
                            (closeRequested)="sider?.close()"
                            (editRequested)="editCustomer($event)"
                            [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
    ></ca-customer-sneak-peek>
  </mat-sidenav>

</mat-sidenav-container>
