import {Injectable} from '@angular/core';

import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';


@Injectable()
export class ChargeGridService extends GridService {
  defaultColumns = [
    {
      caption: '',
      headerCellTemplate: 'selectedChargesHeaderTemplate',
      cellTemplate: 'chargeDisputeNoteTemplate',
      fixed: true,
      width: 70,
      allowResizing: false,
      allowSorting: false
    },
    {
      caption: 'Invoice ID',
      dataField: 'invoice_id',
      width: 100
    },
    {
      caption: 'Vendor',
      dataField: 'vendor.name',
      visible: true,
      width: 130
    },
    {
      caption: 'Account',
      dataField: 'acct_level_1',
      visible: true,
      width: 150
    },
    {
      caption: 'Vendor Location ID',
      dataField: 'account.vendor_remit_address.vendor_location_id',
      visible: true,
      width: 150
    },
    {
      caption: 'Subclient',
      dataField: 'account.subclient.value',
      visible: true,
      width: 130
    },
    {
      caption: 'Invoice Number',
      dataField: 'invoice.sp_inv_num',
      visible: true,
      width: 170
    },
    {
      caption: 'AP Invoice Number',
      dataField: 'invoice.batch_item.ap_invoice_number',
      visible: true,
      width: 170
    },
    {
      caption: 'Invoice Date',
      dataField: 'invoice.inv_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      visible: true,
      width: 120
    },
    {
      caption: 'Subaccount',
      dataField: 'acct_level_2',
      visible: true,
      width: 120
    }, {
      caption: 'Direct GL',
      dataField: 'direct_gl_stamp',
      width: 120
    }, {
      caption: 'Charge Type',
      dataField: 'chg_class',
      visible: true,
      width: 120
    }, {
      caption: 'Service ID',
      dataField: 'sp_serv_id',
      visible: true,
      width: 150
    }, {
      caption: 'Related Service ID',
      dataField: 'rel_sp_fac_id',
      visible: true,
      width: 150
    }, {
      caption: 'BTN',
      dataField: 'btn',
      visible: true,
      width: 150
    }, {
      caption: 'Super USOC',
      dataField: 'superusoc',
      visible: true,
      width: 120
    }, {
      caption: 'Charge Code1',
      dataField: 'chg_code_1',
      visible: true,
      width: 120
    }, {
      caption: 'Charge Description 1',
      dataField: 'chg_desc_1',
      visible: true,
      width: 170
    }, {
      caption: 'Charge Code2',
      dataField: 'chg_code_2',
      visible: true,
      width: 120
    }, {
      caption: 'Charge Description 2',
      dataField: 'chg_desc_2',
      visible: true,
      width: 170
    }, {
      caption: 'Activity Comp Date',
      dataField: 'activity_comp_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      visible: false,
      width: 120
    }, {
      alignment: 'right',
      caption: 'Chg Qty',
      dataField: 'chg_qty1_billed',
      visible: false,
      dataType: 'number',
      csvDataType: 'integer',
      width: 120
    }, {
      caption: 'Chg Qty Type 2',
      dataField: 'chg_qty2_type',
      visible: false,
      width: 120
    }, {
      caption: 'Charge Rate',
      dataField: 'chg_rate',
      cellTemplate: 'chargeRateTemplate',
      visible: true,
      csvDataType: 'float',
      width: 120
    }, {
      caption: 'Charge Amount',
      dataField: 'chg_amt',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      visible: true,
      width: 130
    }, {
      caption: 'Install Date',
      dataField: 'svc_establish_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      visible: true,
      width: 120
    }, {
      caption: 'Beg Charge Date',
      dataField: 'beg_chg_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      visible: true,
      alignment: 'left',
      width: 150
    }, {
      caption: 'End Charge Date',
      dataField: 'end_chg_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      visible: true,
      width: 150
    }, {
      caption: 'Currency',
      dataField: 'currency',
      visible: true,
      width: 120
    }, {
      caption: 'Info Only Ind',
      dataField: 'info_only_ind',
      visible: true,
      width: 150
    }, {
      alignment: 'right',
      caption: 'Chg Qty Used 2',
      dataField: 'chg_qty2_used',
      dataType: 'number',
      visible: false,
      width: 120
    }, {
      caption: 'Address',
      dataField: 'site_a_addr_1',
      visible: true,
      width: 150
    }, {
      caption: 'City',
      dataField: 'site_a_addr_city',
      visible: true,
      width: 120
    }, {
      caption: 'State',
      dataField: 'site_a_addr_st',
      visible: true,
      width: 120
    }, {
      caption: 'Zip',
      dataField: 'site_a_addr_zip',
      visible: true,
      width: 120
    }, {
      caption: 'Facility Bandwidth',
      dataField: 'fac_bw',
      visible: true,
      width: 150
    }, {
      caption: 'Facility Bandwidth Unit Type',
      dataField: 'fac_bw_unit_type',
      visible: true,
      width: 150
    }, {
      caption: 'Original Service ID',
      dataField: 'original_sp_serv_id',
      visible: false,
      width: 120
    }, {
      caption: 'Call Type',
      dataField: 'call_type',
      visible: false,
      width: 120
    }, {
      caption: 'Prod Type',
      dataField: 'prod_type',
      visible: false,
      width: 120
    }, {
      caption: 'Dir Ind',
      dataField: 'dir_ind',
      visible: false,
      width: 120
    }, {
      caption: 'Share Ind',
      dataField: 'share_ind',
      visible: false,
      width: 120
    }, {
      caption: 'Curr Prir Ind',
      dataField: 'curr_prir_ind',
      visible: false,
      width: 120
    }, {
      caption: 'Rate Period',
      dataField: 'rate_period',
      visible: false,
      width: 120
    }, {
      caption: 'Roam Ind',
      dataField: 'roam_ind',
      visible: false,
      width: 120
    }, {
      caption: 'Jurisdiction',
      dataField: 'jur',
      width: 100
    }, {
      caption: 'Chg Qty Type',
      dataField: 'chg_qty1_type',
      visible: true,
      width: 120
    }, {
      alignment: 'right',
      caption: 'Chg Qty Used',
      dataField: 'chg_qty1_used',
      dataType: 'number',
      visible: true,
      width: 120
    }, {
      caption: 'Chg Qty Allowed',
      dataField: 'chg_qty1_allowed',
      visible: true,
      width: 140
    },
    {
      caption: 'Segment Net Element',
      dataField: 'super_usoc_map.susoc_1',
      visible: false,
      width: 150
    },
    {
      caption: 'Segment Tech',
      dataField: 'super_usoc_map.susoc_2',
      visible: false,
      width: 150
    },
    {
      caption: 'Segment Qty',
      dataField: 'super_usoc_map.susoc_3',
      visible: false,
      width: 150
    },
    {
      caption: 'Segment Jur',
      dataField: 'super_usoc_map.susoc_4',
      visible: false,
      width: 150
    },
    {
      caption: 'Segment Primary/Feat',
      dataField: 'super_usoc_map.susoc_5',
      visible: false,
      width: 150
    },
    {
      caption: 'AP Withheld Total ',
      dataField: 'ap_withheld',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      visible: true,
      width: 150,
      allowSorting: false
    },
    {
      caption: 'Adjusted Total Charges',
      dataField: 'adj_total_charges',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      visible: true,
      width: 170,
      allowSorting: false
    },
    {
      caption: 'Calculated Amount',
      dataField: 'charge_dispute.calculated_amount',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      visible: true,
      width: 150
    },
    {
      caption: 'Disputed Amount',
      dataField: 'charge_dispute.disputed_amount',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      visible: true,
      width: 150
    },
    {
      caption: 'Withheld Status',
      dataField: 'charge_dispute.dispute_withheld',
      cellTemplate: 'withheldCellTemplate',
      width: 160,
      allowEditing: false,
      transformValue: {
        true: 'Yes',
        false: 'No'
      }
    },
    {
      caption: 'GL Coded',
      visible: false,
      dataField: 'charge_coded',
      cellTemplate: 'chargeCodedTemplate',
      width: 100,
    },
  ];

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.cost.charge-grid', userSettings);
  }
}
