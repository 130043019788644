<div class="mat-dialog-top-spacer"></div>

<div mat-dialog-content class="ca-dialog-content">
  {{bodyText}}
</div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button (click)="close()">Ok</button>
</mat-dialog-actions>
