import { Component, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../core/user';
import { UserService } from '../../core/user.service';

@Component({
  selector: 'app-user-sneak-peek',
  templateUrl: './user-sneak-peek.component.html',
})
export class UserSneakPeekComponent {

  @Input() user: User;
  @Output() closeRequested = new EventEmitter();
  @Output() updateUserStatusRequested = new EventEmitter();

  constructor( public userService: UserService ) { }

  updateUserStatus(event) {
    event.preventDefault();
    this.updateUserStatusRequested.emit(this.user);
  }

  closeSide() {
    this.closeRequested.emit();
  }

  getUserInitials(): string {
    if (this.user) {
      return this.user.first_name.toUpperCase()[0] + this.user.last_name.toUpperCase()[0];
    }
  }

}
