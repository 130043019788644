import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterContainerComponent} from './filter-container/filter-container.component';
import {SharedModule} from "../../shared/shared.module";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatToolbarModule} from "@angular/material/toolbar";
import {FlexModule} from "@angular/flex-layout";
import {MatMenuModule} from "@angular/material/menu";
import {MatExpansionModule} from "@angular/material/expansion";
import {FilterHostDirective} from "./filter-host.directive";
import {FilterEntryComponent} from "./filter-entry.component";
import {FilterTextComponent} from "./filter-text/filter-text.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {FilterBooleanComponent} from "./filter-boolean/filter-boolean.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FilterDateComponent} from "./filter-date/filter-date.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSelectModule} from "@angular/material/select";
import {FilterNumberComponent} from "./filter-number/filter-number.component";
import {FilterNumberRangeComponent} from "./filter-number-range/filter-number-range.component";
import {FilterCheckboxListComponent} from "./filter-checkbox-list/filter-checkbox-list.component";
import {FilterAutocompleteComponent} from "./filter-autocomplete/filter-autocomplete.component";
import {MatChipsModule} from "@angular/material/chips";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatIconModule} from "@angular/material/icon";
import {MatAutocompleteOptionsScrollDirective} from "./utils/options-scroll.directive";


@NgModule({
  declarations: [
    FilterContainerComponent,
    FilterHostDirective,
    FilterEntryComponent,
    FilterTextComponent,
    FilterBooleanComponent,
    FilterDateComponent,
    FilterNumberComponent,
    FilterNumberRangeComponent,
    FilterCheckboxListComponent,
    FilterAutocompleteComponent,
    MatAutocompleteOptionsScrollDirective,
  ],
  exports: [
    FilterContainerComponent,
    FilterHostDirective,
    FilterEntryComponent,
    FilterTextComponent,
    FilterBooleanComponent,
    FilterDateComponent,
    FilterNumberComponent,
    FilterNumberRangeComponent,
    FilterCheckboxListComponent,
    FilterAutocompleteComponent,
    MatAutocompleteOptionsScrollDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatButtonModule,
    MatTooltipModule,
    MatToolbarModule,
    FlexModule,
    MatMenuModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    DragDropModule,
    MatIconModule
  ]
})
export class FilterFeatureModule {
}
