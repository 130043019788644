<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <span class="toolbar-title">System Rules</span>
  <span class="example-fill-remaining-space">&nbsp;</span>

 <div>
    <button *ngIf="actions.edit" mat-icon-button matTooltip="Edit GL Strings" (click)="editStrings()" [disabled]="isEditDisabled()">
        <ca-icon [key]="'EDIT'"></ca-icon>
    </button>
    <button *ngIf="actions.add" mat-icon-button matTooltip="Add GL Strings" (click)="editStrings()" [disabled]="isNewDisabled()">
        <ca-icon [key]="'ADD_NEW'"></ca-icon>
    </button>
  </div>
</mat-toolbar>

<div class="app-content gl-config-content app-content-list-no-pager tabbed-content" style="height: calc(100% - 60px)">
    <mat-tab-group class="ca-tab padding-bottom" (selectedIndexChange)="onTabChange($event)" [selectedIndex]="activeTabIndex">
        <mat-tab [label]="tab.title" [disabled]="tab.disabled" *ngFor="let tab of tabGroup.tabs">
        </mat-tab>
    </mat-tab-group>

  <div [ngClass]="{'hidden': !tabGroup.isActive(1)}" class="tabs-body dx-nodata-absolute ap" style="overflow-y: unset">
    <app-spid-coding (onSaveSpid)="onSaveSpid($event)" #spidCodingComponent *ngIf="tabs == 1" (onActionsLoaded)="onActionsLoaded($event)" (onSelection)="onSelection($event)" (onClearSelection)="onClear($event)" [(triggerReload)]="triggerReload"></app-spid-coding>
  </div>

  <div [ngClass]="{'hidden': !tabGroup.isActive(2)}" class="tabs-body grid-container app-grid-with-toolbar dx-nodata-absolute" style="overflow-y: unset">
    <app-inventory-coding #inventoryCodingComponent (onActionsLoaded)="onActionsLoaded($event)" *ngIf="tabs == 2"></app-inventory-coding>
  </div>

  <div [ngClass]="{'hidden': !tabGroup.isActive(3)}" class="tabs-body dx-nodata-absolute" style="overflow-y: unset">
    <app-sub-account-coding *ngIf="tabs == 3" (onActionsLoaded)="onActionsLoaded($event)" (onSelection)="onSelection($event)" (onClearSelection)="onClear($event)" [(triggerReload)]="triggerReload"></app-sub-account-coding>
  </div>

  <div [ngClass]="{'hidden': !tabGroup.isActive(4)}" class="tabs-body dx-nodata-absolute" style="overflow-y: unset">
    <app-ban-coding *ngIf="tabs == 4" (onSelection)="onSelection($event)" (onActionsLoaded)="onActionsLoaded($event)" (onClearSelection)="onClear($event)" [(triggerReload)]="triggerReload"></app-ban-coding>
  </div>
</div>
