export const accountDetailsFilterContext = {
  filters: [
    {
      field: 'sp_inv_num',
      label: 'Invoice Number',
      type: 'string',
      index: 1
    },
    {
      field: 'header.status.key',
      label: 'Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'invoiceStatus',
      index: 2
    },
    {
      field: 'date_issued',
      label: 'Issue Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 4,
    },
    {
      field: 'due_date',
      label: 'Due Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 5,
    },
    {
      field: 'bal_fwd',
      label: 'Balance Forward',
      type: 'negativeNumber',
      index: 6
    },
    {
      field: 'tot_new_chg_adj',
      label: 'Total New Charges Adj',
      type: 'negativeNumber',
      index: 7
    },
    {
      field: 'tot_amt_due',
      label: 'Total Due',
      type: 'negativeNumber',
      index: 8
    },
    {
      field: 'tot_mrc_chgs',
      label: 'Total MRC ',
      type: 'negativeNumber',
      index: 9
    },
    {
      field: 'tot_occ_chgs',
      label: 'Total OCC',
      type: 'negativeNumber',
      index: 10
    },
    {
      field: 'tot_usage_chgs',
      label: 'Total Usage',
      type: 'negativeNumber',
      index: 11
    },
    {
      field: 'tot_taxsur',
      label: 'Total Taxes / Surcharge',
      type: 'negativeNumber',
      index: 12
    },
    {
      field: 'tot_disc_amt',
      label: 'Total Discount',
      type: 'negativeNumber',
      index: 13
    },
    {
      field: 'tot_new_chgs',
      label: 'Total New Charges',
      type: 'negativeNumber',
      index: 14
    },
    {
      field: 'header.tot_new_chg_diff',
      label: 'New Charges Diff',
      type: 'negativeNumber',
      index: 15
    },
    {
      field: 'header.tot_new_chg_diff_pct',
      label: 'New Charges Diff %',
      type: 'negativeNumber',
      index: 16
    },
    {
      label: 'Batch ID',
      field: 'header.batch.batch_key',
      type: 'string',
      index: 17
    },{
      field: 'inv_date',
      label: 'Invoice Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 18,
    },
  ]
};
