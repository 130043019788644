<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Dictionary</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button *permission="'Create'" matTooltip="New Dictionary" mat-icon-button (click)="newDictionary()">
    <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>
  <button *permission="'Modify'" mat-icon-button (click)="editDictionary(selection)" [disabled]="!selection || selection.is_system_row" >
    <ca-icon [key]="'EDIT'" [tooltip]="'Edit Dictionary'"></ca-icon>
  </button>
  <button *permission="'Modify'" mat-icon-button [disabled]="!selectedGroup" (click)="reorder($event, selectedGroup)">
    <ca-icon [key]="'REORDER'" [tooltip]="'Reorder'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh List" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button #toggleDetailsEnableButton [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
          [disabled]="!selection"
          (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content">
  <div #accordion class="dictionary-accordion-container">
    <mat-card class="dictionary-accordion">

      <mat-accordion id="accordion" #accordion [multi]="false">
        <ng-container *ngFor="let lookupGroup of lookupGroups; let i = index;">
          <mat-expansion-panel
          [expanded]="reorderingItem?.name === lookupGroup.name"
          #group
          (closed)="selection = null; selectGroup(lookupGroup, false);"
          (opened)="selectGroup(lookupGroup, true)"
          [ngClass]="{'relative' : (dictionaryHasChildren(lookupGroup.name) && selectedGroup === lookupGroup)}">
            <mat-expansion-panel-header
              class="ca-expansion-panel-header-title"
              [expandedHeight]="'48px'"
              [collapsedHeight]="'48px'"  style="font-weight: 500">
              {{lookupGroup.name}} <span *ngIf="isSystem(lookupGroup)">&nbsp;- (System)</span>
            </mat-expansion-panel-header>
            <div *ngIf="(dictionaryHasChildren(lookupGroup.name) && selectedGroup === lookupGroup)" class="ca-form ca-child-picker">
              <ng-container *ngFor="let children of lookupGroupsWithParent">
                <ca-default-picker>
                  <mat-form-field floatPlaceholder="never">
                    <mat-select placeholder="Site Type" [(ngModel)]="selectedChild.value" (selectionChange)="typeChange()">
                      <mat-option [value]="'all'">All</mat-option>
                      <div *ngFor="let item of filteredDropdownOptions">
                        <mat-option *ngIf="item.enabled" [value]="item.id">{{ item.value }}</mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </ca-default-picker>
              </ng-container>
            </div>
            <mat-list flex class="dictionary-item" *ngIf="!dictionaryHasChildren(lookupGroup.name)">
              <!-- Hide if lookupGroup is Network Hub Capacity or Bandwidth -->
              <ng-container *ngIf="lookupGroup.name !== LOOKUP_NETWORK_HUB_CAPACITY_DISPLAY_NAME
                                  && lookupGroup.name !== LOOKUP_BANDWIDTH_DISPLAY_NAME
                                  && lookupGroup.name !== LOOKUP_INVENTORY_TECHNOLOGY_DISPLAY_NAME
                                  && lookupGroup.name !== CONTRACT_SCHEDULE_SERIES_NAME
                                  && lookupGroup.name !== ACCOUNT_SUBCLIENT_NAME">
                <mat-list-item *ngFor="let lookupItem of lookupGroup.items; let i = index;"
                               (click)="onItemSelect(lookupItem, lookupGroup); selectedIndex=(lookupGroup.name+i);"
                               class="custom-accorion-list-item"
                               [ngClass]="{'ca-theme-background': selectedIndex==(lookupGroup.name+i)}">
                  <span>{{lookupItem.value}}</span>
                  <span *ngIf="lookupItem.is_order">&nbsp;&#8226;&nbsp;Can be ordered</span>
                </mat-list-item>
              </ng-container>

               <!-- Show if lookupGroup is Bandwidth-->
              <ng-container *ngIf="lookupGroup.name === LOOKUP_BANDWIDTH_DISPLAY_NAME">
                <mat-list fxFlex class="no-overflow">
                <div fxLayout="row" class="child-table-headers">
                  <label fxFlex="30%">Value</label>
                  <label fxFlex="20%" class="right-align">Standarized Value</label>
                </div>
                <div *ngFor="let lookupItem of lookupGroup.items; let i = index;" fxLayout="row">
                  <mat-list-item
                  fxFlex="100%"
                  (click)="onItemSelect(lookupItem, lookupGroup); selectedIndex=(lookupGroup.name+i);"
                  class="custom-accorion-list-item"
                  [ngClass]="{'ca-theme-background': selectedIndex==(lookupGroup.name+i)}">
                    <span fxFlex="30%">{{lookupItem.value}}</span>
                    <span fxFlex="20%" class="right-align">{{lookupItem.standardized_value | number : '1.3-3'}} Mbps</span>
                  </mat-list-item>
                </div>
              </mat-list>
              </ng-container>


              <!-- Show if lookupGroup is Network Hub Capacity -->
              <mat-list *ngIf="lookupGroup.name === LOOKUP_NETWORK_HUB_CAPACITY_DISPLAY_NAME"
              fxFlex class="no-overflow">
                <div fxLayout="row" class="child-table-headers">
                  <label fxFlex="30%">Value</label>
                  <label fxFlex="30%">Network Hub Type</label>
                </div>
                <div *ngFor="let lookupItem of lookupGroup.items; let i = index;" fxLayout="row">
                  <mat-list-item
                  fxFlex="100%"
                  (click)="onItemSelect(lookupItem, lookupGroup); selectedIndex=(lookupGroup.name+i);"
                  class="custom-accorion-list-item"
                  [ngClass]="{'ca-theme-background': selectedIndex==(lookupGroup.name+i)}">
                    <span fxFlex="30%">{{lookupItem.value}}</span>
                    <span fxFlex="30%">{{NETWORK_HUB_TYPE_ENUM[lookupItem.network_hub_type_id]}}</span>
                  </mat-list-item>
                </div>
              </mat-list>

              <!-- Show if lookupGroup is Contract Schedule Series -->
              <mat-list *ngIf="lookupGroup.name === CONTRACT_SCHEDULE_SERIES_NAME"
                        fxFlex class="no-overflow">
                <div fxLayout="row" class="child-table-headers">
                  <label fxFlex="40%">Value</label>
                  <label fxFlex="40%">Category</label>
                </div>
                <div *ngFor="let lookupItem of lookupGroup.items; let i = index;" fxLayout="row">
                  <mat-list-item
                    fxFlex="100%"
                    (click)="onItemSelect(lookupItem, lookupGroup); selectedIndex=(lookupGroup.name+i);"
                    class="custom-accorion-list-item"
                    [ngClass]="{'ca-theme-background': selectedIndex==(lookupGroup.name+i)}">
                    <span fxFlex="40%">{{lookupItem.value}}</span>
                    <span fxFlex="40%">{{CONTRACT_SCHEDULE_CATEGORY_ENUM[lookupItem.category_id]}}</span>
                  </mat-list-item>
                </div>
              </mat-list>

              <!-- Show if lookupGroup is Account Subclient -->
              <mat-list *ngIf="lookupGroup.name === ACCOUNT_SUBCLIENT_NAME" fxFlex class="no-overflow">

                <ng-container *ngIf="usingSubclientEntity || usingSubclientCurrency; else account_subclient_else">
                  <div fxLayout="row" class="child-table-headers">
                    <label [ngStyle]="{'flex': usingSubclientEntity && usingSubclientCurrency ? '40%' : '50%'}">Value</label>
                    <label *ngIf="usingSubclientEntity"
                           [ngStyle]="{'flex': usingSubclientEntity && usingSubclientCurrency ? '30%' : '50%'}">Entity Id</label>
                    <label *ngIf="usingSubclientCurrency"
                           [ngStyle]="{'flex': usingSubclientEntity && usingSubclientCurrency ? '30%' : '50%'}">Base Currency</label>
                  </div>
                  <div *ngFor="let lookupItem of lookupGroup.items; let i = index;" fxLayout="row">
                    <mat-list-item
                      fxFlex="100%"
                      (click)="onItemSelect(lookupItem, lookupGroup); selectedIndex=(lookupGroup.name+i);"
                      class="custom-accorion-list-item"
                      [ngClass]="{'ca-theme-background': selectedIndex==(lookupGroup.name+i)}">
                      <span [ngStyle]="{'flex': usingSubclientEntity && usingSubclientCurrency ? '40%' : '50%'}">{{lookupItem.value}}</span>
                      <span *ngIf="usingSubclientEntity"
                            [ngStyle]="{'flex': usingSubclientEntity && usingSubclientCurrency ? '30%' : '50%'}">{{ lookupItem.entity_id }}</span>
                      <span *ngIf="usingSubclientCurrency"
                            [ngStyle]="{'flex': usingSubclientEntity && usingSubclientCurrency ? '30%' : '50%'}">{{lookupItem.currency}}</span>
                    </mat-list-item>
                  </div>
                </ng-container>

                <ng-template #account_subclient_else>
                  <mat-list-item *ngFor="let lookupItem of lookupGroup.items; let i = index;"
                                 (click)="onItemSelect(lookupItem, lookupGroup); selectedIndex=(lookupGroup.name+i);"
                                 class="custom-accorion-list-item"
                                 [ngClass]="{'ca-theme-background': selectedIndex==(lookupGroup.name+i)}">
                    <span>{{lookupItem.value}}</span>
                    <span *ngIf="lookupItem.is_order">&nbsp;&#8226;&nbsp;Can be ordered</span>
                  </mat-list-item>
                </ng-template>

              </mat-list>

              <!-- Show if lookupGroup is Inventory Technology -->
              <mat-list *ngIf="lookupGroup.name === LOOKUP_INVENTORY_TECHNOLOGY_DISPLAY_NAME"
              fxFlex class="no-overflow">
                <div fxLayout="row" class="child-table-headers">
                  <label fxFlex="50%">Value</label>
                  <label fxFlex="30%">Inventory Service Type</label>
                </div>
                <div *ngFor="let lookupItem of lookupGroup.items; let i = index;" fxLayout="row">
                  <mat-list-item
                  fxFlex="100%"
                  (click)="onItemSelect(lookupItem, lookupGroup); selectedIndex=(lookupGroup.name+i);"
                  class="custom-accorion-list-item"
                  [ngClass]="{'ca-theme-background': selectedIndex==(lookupGroup.name+i)}">
                    <span fxFlex="50%">{{lookupItem.value}}<ng-container *ngIf="lookupItem.is_order">&nbsp;&#8226;&nbsp;Can be ordered</ng-container></span>
                    <span fxFlex="30%">{{INVENTORY_CKT_TYPE[lookupItem.ckt_type_id]}}</span>
                  </mat-list-item>
                </div>
              </mat-list>

            </mat-list>
            <mat-list flex class="dictionary-item dictionary-site-type" *ngIf="dictionaryHasChildren(lookupGroup.name)">
              <div fxLayout="row" class="child-table-headers">
                  <label fxFlex="30%">Value</label>
                  <label fxFlex="30%">Site type</label>
              </div>
              <div *ngFor="let lookupItem of lookupGroup.items; let i = index;" fxLayout="row">
                <mat-list-item *ngIf="lookupItem.site_type_id === selectedChild.value" fxFlex="100%"
                  (click)="onItemSelect(lookupItem, lookupGroup); selectedIndex=(lookupGroup.name+i);"
                  class="custom-accorion-list-item"
                  [ngClass]="{'ca-theme-background': selectedIndex==(lookupGroup.name+i)}">
                  <span fxFlex="30%">{{lookupItem.value}}</span>
                  <span fxFlex="30%">{{SITE_TYPES_ENUM[lookupItem.site_type_id]}}</span>
                </mat-list-item>
              </div>
              <div *ngFor="let lookupItem of lookupGroup.items; let i = index;" fxLayout="row">
                <mat-list-item *ngIf="selectedChild.value === 'all'" fxFlex="100%"
                               (click)="onItemSelect(lookupItem, lookupGroup); selectedIndex=(lookupGroup.name+i);"
                               class="custom-accorion-list-item"
                               [ngClass]="{'ca-theme-background': selectedIndex==(lookupGroup.name+i)}">
                  <span fxFlex="30%">{{lookupItem.value}}</span>
                  <span fxFlex="30%">{{SITE_TYPES_ENUM[lookupItem.site_type_id]}}</span>
                </mat-list-item>
              </div>
            </mat-list>
          </mat-expansion-panel>
        </ng-container>

      </mat-accordion>

    </mat-card>
  </div>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <app-dictionary-sneak-peek [lookup]="selection"
                               (closeRequested)="sider?.close()"
                               [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"></app-dictionary-sneak-peek>
  </mat-sidenav>

</mat-sidenav-container>

