  <form class="ca-form ca-demarc-form" style="margin-top: 0;" [formGroup]="form">

  <div class="flex-container-3" fxLayout>
    <div class="flex-item" fxFlex>
      <mat-form-field>
        <input matInput type="text" required formControlName="rack_id" placeholder="Rack">
        <mat-hint *ngIf="form.hasError('required', ['rack_id']) && form.controls.rack_id.touched" class="error-class">{{VALIDATION.REQUIRED_ERROR}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="flex-item" fxFlex>
      <mat-form-field>
        <input matInput type="text" formControlName="shelf_id" placeholder="Shelf">
      </mat-form-field>
    </div>
    <div class="flex-item" fxFlex>
      <mat-form-field>
        <input matInput type="text" formControlName="cpe_id" placeholder="CPE">
      </mat-form-field>
    </div>
  </div>

  <div class="flex-container-3" fxLayout>
    <div class="flex-item" fxFlex>
      <mat-form-field>
        <input matInput type="text"formControlName="card_id" placeholder="Card">
      </mat-form-field>
    </div>
    <div class="flex-item" fxFlex>
      <mat-form-field>
        <input matInput type="text" formControlName="port_id" placeholder="Port">
      </mat-form-field>
    </div>
    <div class="flex-item" fxFlex="94px">
      <mat-checkbox formControlName="extended">
        Extended
      </mat-checkbox>
    </div>
  </div>

  <div class="flex-container-3" fxLayout fxLayoutAlign="center center">
    <div class="flex-item" fxFlex>
      <mat-form-field>
        <textarea matInput formControlName="notes" placeholder="Notes" fxFlex="100%"></textarea>
      </mat-form-field>
    </div>

    <div class="flex-item demarc-form-action" fxFlex="170px">
      <button mat-button type="submit" class="flat-button" (click)="cancel()" tabindex="-1">
        Cancel
      </button>
      <button mat-button type="submit" class="flat-button" (click)="onSubmit(form)" [disabled]="form.invalid" tabindex="-1">
        Save
      </button>
    </div>
  </div>

  </form>
