import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

import { BaseService } from '../../core/base.service';
import { Customer } from './customer';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';
import Query from '../..//core/query/query';

@Injectable()
export class CustomerService extends BaseService<Customer> {
  constructor(
    public restangular: Restangular,
    public entityLock: EntityLockService
  ) {
    super('customers', restangular, entityLock);
  }

  findAllList(query?: Query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .all(this.name)
      .one('list')
      .customGET(null, this.toFilter(transformedQuery));
  }
  findCustomersOnly(query?: Query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .all(this.name)
      .one('filtered')
      .customGET(null, this.toFilter(transformedQuery));
  }
}
