import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';


import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { AlertService } from '../../../shared/alert/alert.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { DocumentService } from '../../../shared/document/core/document.service';
import { LoaderService } from '../../../shared/loader/loader.service';
import { ContractService } from '../../core/contract.service';
import {SvcOrderHeader} from "../../core/svc-order-header";
import {SvcOrderService} from "../../core/svc-order.service";


@Component({
  selector: 'app-contract-manage-dialog',
  templateUrl: './svc-order-header-manage-dialog.component.html',
  styleUrls: ['./svc-order-header-manage-dialog.component.css']
})
export class SvcOrderHeaderManageDialogComponent extends PageManageDialogComponent implements OnInit {
  @ViewChild('fileInput') fileInput;

  svcOrderHeader: any;
  selectedRowKeys: Array<any>;

  isUpdate: boolean;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'svc-order';

  constructor(
    public dialog: DialogService,
    public formBuilder: FormBuilder, public alert: AlertService,
    public contractService: ContractService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<SvcOrderHeaderManageDialogComponent>,
    public loaderService: LoaderService,
    public documentService: DocumentService,
    public svcOrderHeaderService: SvcOrderService
  ) {
    super(dialogService, dialogRef);

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.isUpdate = !!this.svcOrderHeader.id;

    this.form = this.formBuilder.group({
      name: [this.svcOrderHeader ? this.svcOrderHeader.name : ''],
      vendor_id: [this.svcOrderHeader ? this.svcOrderHeader.vendor_id : '', Validators.required],
      description: [this.svcOrderHeader ? this.svcOrderHeader.description : '', Validators.required],
      vendor_so_number: [this.svcOrderHeader ? this.svcOrderHeader.vendor_so_number : '', Validators.required],
      internal_so_number: [this.svcOrderHeader ? this.svcOrderHeader.internal_so_number : '', Validators.required],
      so_date: [this.svcOrderHeader ? this.svcOrderHeader.so_date : '', Validators.required]
    });

    this.afterInit();
  }

  onSubmit({ value, valid }: { value: SvcOrderHeader, valid: boolean }) {
    if (!valid) {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
    if (this.svcOrderHeader && this.svcOrderHeader.id) {
      this.update(Object.assign({}, this.svcOrderHeader, value));
    } else {
      this.create(value);
    }
  }

  create(svcOrderHeader: SvcOrderHeader) {

    this.loaderService.displayLoader();
    this.toggleDialogButtons();

    this.svcOrderHeaderService.create(svcOrderHeader)
      .subscribe((result) => {

        this.loaderService.hideLoader();
      //  this.alert.success('', this.messages.get('CREATE_SUCCESS'));
        this.closeDialog(result, true);

      }, (err) => {
        this.toggleDialogButtons(false);
        this.loaderService.hideLoader();
        this.alert.success('', this.messages.get('CREATE_ERROR'));
      });
  }

  update(svcOrderHeader: SvcOrderHeader) {
    this.toggleDialogButtons();
    this.svcOrderHeaderService.update(svcOrderHeader.id, svcOrderHeader)
      .subscribe((result) => {

        //this.alert.success('', this.messages.get('UPDATE_SUCCESS'));
        this.closeDialog(result, true);
        this.loaderService.hideLoader();

      }, (err) => {
        this.toggleDialogButtons(false);
        this.alert.error('', this.messages.get('UPDATE_ERROR'));
      });
  }

  cancel() {
    this.svcOrderHeaderService.cancelEdit();
    this.closeDialog();
  }

  deleteOrderHeader(event) {
    if (this.isUpdate && event) {
      this.toggleDialogButtons();
      this.svcOrderHeaderService.delete(this.svcOrderHeader.id)
        .subscribe((deleteResult) => {
          if (deleteResult) {
            this.dialogRef.close({ deleted: true });
          }
        }, (err) => {
          this.toggleDialogButtons(false);
          if (err.data.name === 'SequelizeForeignKeyConstraintError') {
            setTimeout(() => {
              this.alert.error('', this.messages.get('CONSTRAIN_DELETE_ERROR'));
            }, 600);
          } else {
            this.alert.error('', this.messages.get('DELETE_ERROR'));
          }
        });
    }
  }
}
