import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BaseService} from '../../core/base.service';
import {Tag} from './tag';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class TagAdministrationService extends BaseService<Tag> {

  constructor(public restangular: Restangular, private entityLock: EntityLockService) {
    super('tags', restangular, entityLock);
  }

  public merge(tag_ids:number [], tag: string): any {
    return this.service().all(this.name).all('merge').customPOST({ tag_ids: tag_ids, tag: tag });
  }
}
