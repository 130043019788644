import {Injectable, Optional} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BaseService} from '../../core/base.service';
import {UserSetting} from "./user-setting";
import {AppService} from "../../app.service";
import {ApiService} from "../../core/api";
import {map, publishReplay, refCount, mergeMap} from "rxjs/operators";
import {ConnectableObservable, from, Observable, of} from "rxjs";

@Injectable()
export class UserSettingsService extends BaseService<UserSetting> {


  constructor(@Optional() public restangular: Restangular,
              private apiService: ApiService) {
    super('setting', restangular);
  }

  cache() {
    return this.apiService.cache(this.name);
  }

  findByKey(key) {
    return this.me().pipe(
      map((settings) => {
        return settings[key];
      }));
  }

  // this is will work only if settings data is already cached, otherwise it will not wait for settings to load,
  // this need to be refactored to return observable, but there are many place in code that are calling this funciton
  // so such refactoring would have big regression on app, so i will just leave comment for now (detected while working on GPX-5967)
  //
  // there are also many place i the code that have commented "columns() {" that needs to be cleaned up
  entrySettings(name) {
    let settings;
    this.settings().subscribe(setting => {
                        if (!setting[name]) {
                          setting[name] = {};
                        }
                        settings = Object.assign({}, setting[name]);
                      })

    return settings || {};
  }

  /**
   * This function has business logic like entrySettings, but this logic here is implemented in a different way. We had a problem with downloading the CSV. EntrySettings function isn't waiting for settings from the backend
   * @param name string
   */
  entrySettingsNew(name): Observable<any> {
    return this.settings()
    .pipe(map(setting => {
      return setting[name] || {}
    }
    ))
  }

  loadSettings(name) {
    return this.settings()
      .pipe(
        map(s => s[name])
      )
  }

  me() {
    const cache = this.cache();
    return this.apiService.get('user/me/settings', null, {cache})
  }

  settings() {
    return this.me();
  }

  save(name: string, data: any) {
    const cache = this.cache();

    return this.settings()
      .subscribe(settings => {
        if (!settings[name]) {
          settings[name] = {};
        }

        settings[name] = {...settings[name],...data};
        cache.put('user/me/settings', settings);
        const observable = this.apiService.put('user/me/settings', {settings, page: name}).pipe(
          publishReplay(),
        ) as ConnectableObservable<any>
        observable.connect()
        return observable;
      })

  }

  // We had a problem when hiding attributes on a grid and restart filters. We had a situation where the app get settings from the database before our changes save in the database. We have to rename this function later
  saveGridFilterSettings(name: string, data: any) {
    const cache = this.cache();
    return this.settings().pipe(
      mergeMap(settings => {
        if (!settings[name]) {
          settings[name] = {};
        }
        settings[name] = {...settings[name],...data}
        cache.put('user/me/settings', settings);
        const observable = this.apiService.put('user/me/settings', {settings, page: name}).pipe(map(x => x[name]),
          publishReplay(),
        ) as ConnectableObservable<any>
        observable.connect()
        return observable;
      })
    )
  }

  saveUserSettings(name: string, data: any) {
    const cache = this.cache();
    return this.settings().pipe(
      mergeMap(settings => {
        if (!settings[name]) {
          settings[name] = {};
        }
        settings[name] = {...settings[name],...data}
        cache.put('user/me/settings', settings);
        const observable = this.apiService.put('user/me/settings', {settings, page: name}).pipe(map(x => x[name]),
          publishReplay(),
        ) as ConnectableObservable<any>
        observable.connect()
        return observable;
      })
    )
  }
}
