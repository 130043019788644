import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { Select, Store } from "@ngxs/store";
import { Observable, of, Subject, throwError as observableThrowError } from "rxjs";
import { map } from "rxjs/internal/operators";
import { InvoiceChargeService } from "../../../charge/core/invoice-charge.service";
import Entity from "../../../core/entity.model";
import { PageDetailsComponent } from "../../../core/page-details.component";
import { PageContext } from "../../../core/page.context";
import { Sider, SiderSection, SiderSettings } from "../../../core/sider/sider";
import { LOOKUP_ENUM } from "../../../dictionary/core/lookup.enum";
import { DocumentService } from "../../../shared/document/core/document.service";
import { SortingService } from "../../../shared/sorting/sorting.service";
import { UserSettingsService } from "../../../user/core/user-settings.service";
import { Inventory } from "../../core/inventory";
import { InventoryBillingGridService } from "../../core/inventory-billing-grid.service";
import { InventoryService } from "../../core/inventory.service";
import { UpdateInventoryDocumentActiveSiderSection } from "./state/inventory-document.actions";


@Component({
  selector: 'app-inventory-document',
  templateUrl: './inventory-document.component.html',
})
export class InventoryDocumentComponent extends PageDetailsComponent  implements OnInit {
  inventory: Inventory;

  destroy$: Subject<boolean> = new Subject<boolean>();

  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  DOCUMENT_INFO = {
    FOLDER: 'inventories',
    SUBFOLDER: '',
    PARENT_TYPE: this.SYSTEM_MODULE.INVENTORY,
    ENTITY_TYPE: this.SYSTEM_MODULE.INVENTORY
  };
  selectedEntity: Entity;
  selectedMaster: Entity;
  entities: Array<Entity> = [];
  public documentSelection: any;

  public DOCUMENT = {
    entity: null,
    folder: 'inventories',
    subfolder: ''
  };

  @ViewChild('documentsManager') documentsManager;
  sider: Sider;
  notesCount: number;
  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    NOTES_SECTION_NAME: 'notes',
    HISTORY_SECTION_NAME: 'history'
  };

  @ViewChild('panelSide') panelSide;
  @Select(state => state.inventory_document.activeSiderSection) $activeSiderSection: Observable<any>;

  constructor(
    public inventoryService: InventoryService,
    public route: ActivatedRoute,
    public router: Router,
    public billingGridService: InventoryBillingGridService,
    public settingsService: UserSettingsService,
    public chargeService: InvoiceChargeService,
    public sortingService: SortingService,
    public documentService: DocumentService,
    private readonly store: Store,
  ) {
    super(new PageContext({
      name: 'app.inventory.inventory-document',
      settings: settingsService
    }))
  }

  loadPermissions() {
    return of({});
  }

  private getInventory() {
    return this.route.parent.snapshot.data.inventory;
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/inventory']);
    }
  }

  ngOnInit(): void {
    this.inventory = this.getInventory();

    this.DOCUMENT_INFO.SUBFOLDER = `${this.inventory.id}`;
    let master = new Entity(this.inventory.id, this.SYSTEM_MODULE.INVENTORY, this.inventory.unique_id);
    this.selectedMaster = master;
    this.entities.push(master);
    this.DOCUMENT.entity = Object.assign({}, this.inventory, {parent_type: 'inventory'});
    this.DOCUMENT.subfolder = `${this.inventory.id}`;
  }

  docSelected(doc: Document) {
    this.documentSelection = doc;
  }

  upload() {
    this.documentsManager.addEntityDocument();
  }

  download() {
    // this.documentsManager.downloadDocument(this.documentSelection, this.DOCUMENT_INFO.FOLDER);
    try {
      this.documentService.downloadDocument(this.documentSelection.id, this.DOCUMENT_INFO.FOLDER)
        .pipe(map(response => this.extractBlob(response, this.documentSelection.type)))
        .subscribe((response: Blob) => {
          const name = this.documentSelection.file_name;
          this.documentService.initiateDownload(name, response);
        });
    } catch(err) {
      observableThrowError(err)
    }
  }

  deleteDocument() {
    this.documentsManager.deleteEntityDocument(this.documentSelection);
  }

  isDownloadDisabled(selection: any) {
    return !(this.documentSelection);
  }

  isDeleteDisabled(selection: any) {
    return !(this.documentSelection);
  }

  public extractBlob(data, type) {
    let blob = new Blob([data], {type: type});
    return blob || {};
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    // TODO: delete this / used $activeSiderSection to reopen sider when back button is used
    // sider.onChange((section: SiderSection) => {
    //   // TODO: Save to state
    //   // this.saveSettings({siderSection: section.name});
    // });

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInventoryDocumentActiveSiderSection(activeSection.name)
    ]);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME)
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME)
  }
}
