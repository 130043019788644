import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Restangular} from "ngx-restangular";
import {Account} from "./account";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {ApiService} from "../../core/api";
import Query from "../../core/query/query";
import {ServiceLocator} from "../../core/locator.servce";


@Injectable()
export class ApConfigurationService extends BaseService<Account> {

  constructor(restangular: Restangular,
              private entityLock: EntityLockService,
              private apiService: ApiService,
  ) {
    super('batch_gl', restangular, entityLock);
  }

  findAll(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.apiService.get([this.name, 'apconfig'], transformedQuery);
  }

  findById(id): any {
    return this.apiService.get([this.name, 'apconfig', id]);
  }
}
