<app-inventory-details-toolbar
  [inventory]="inventory"
  [isAddPricingHidden]="true"
  [isAddPricingDisabled]="true"
  [isCreateOrderHidden]="false"
  [isCreateOrderDisabled]="true"
  [isAddGLHidden]="false"
  [isAddGLDisabled]="true"
  [isEditInventoryDisabled]="true"
  [isDocumentAction]="false"
  [isPricingDocumentAction]="false"
  [isFilterDisabled]="true"
  [isNotesOn]="($activeSiderSection | async) === SECTIONS.NOTES_SECTION_NAME"
  [isHistoryOn]="($activeSiderSection | async) === SECTIONS.HISTORY_SECTION_NAME"
  [isCSVDisabled]="false"
  [notesCount]="notesCount"
  
  (toggleNotesRequested)="toggleNotes()"
  (toggleHistoryRequested)="toggleHistory()"
  (downloadCSVRequested)="onDownloadCSVRequested()"
  (backRequested)="back()"
>
</app-inventory-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-inventory-details-tabs [activeTabIndex]="6"></app-inventory-details-tabs>
  <div class="app-content app-content-list dx-nodata-absolute" style="height: 100%">
  <dx-data-grid
    #circuitsGrid
    [columns]="circuitsColumns"
    [dataSource]="circuits"
    style="height: 100%"
    [showRowLines]="true"
    [showColumnLines]="false">
    <div *dxTemplate="let d of 'dateTemplate'">
      <div caGridTooltip>{{ d.value | caDate }}</div>
    </div>
  </dx-data-grid>
</div>
  <mat-sidenav #panelSide
               [mode]="'side'"
               position="end"
               [opened]="false">
    <ca-history
      #history
      [entityId]="inventory?.id"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
      [entityType]="'inventory'">
    </ca-history>

    <ca-notes
      [entityId]="inventory?.id"
      [entityType]="SYSTEM_MODULE.INVENTORY"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
      (countChanged)="onNotesCountChanged($event)">
    </ca-notes>
  </mat-sidenav>
</mat-sidenav-container>
