import {Routes, RouterModule} from "@angular/router";
import {NgModule} from "@angular/core";
import {GLSystemRuleListComponent} from "./gl-system-rule-list/gl-system-rule-list.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: GLSystemRuleListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class GLSystemRuleRoutingModule {
}
