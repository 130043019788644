import { Injectable } from "@angular/core";
import { FilterService } from "../../core/filter/filter.service";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { projectFilterContext } from "./project-filter.context";
import { DefaultDateRangesLookupProvider } from "app/core/filter/default-date-ranges-lookup.provider";

@Injectable()
export class ProjectFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public dictionaryService: DictionaryService,
    public userSettingsService: UserSettingsService) {
    super("app.project.project-filter", projectFilterContext, userSettingsService);
    this.addLookupProvider('projectStatus', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.PROJECT_STATUS.modelName));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
