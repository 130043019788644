import {LOOKUP_ENUM} from "../../dictionary/core/lookup.enum";

export const auditAlertDisconnectAuditFilterContext = {
  filters: [
    {
      field: 'charge_id',
      label: 'Charge ID',
      type: 'number',
      index: 0,
      matchCase: false,
      exactMatch: false
    },
    {
      field: 'charge.invoice_id',
      label: 'Invoice ID',
      type: 'number',
      index: 0,
      matchCase: false,
      exactMatch: false
    },
    {
      field: 'charge.sp_serv_id',
      label: 'SPID',
      type: 'string',
      index: 0,
    },
    {
      field: 'chg_amt',
      label: 'Charge Amount',
      type: 'number',
      index: 0,
      matchCase: false,
      exactMatch: false
    },
    {
      field: 'calc_amt',
      label: 'Calculated Amount',
      type: 'number',
      index: 0,
      matchCase: false,
      exactMatch: false
    },
    {
      field: 'diff',
      label: 'Difference',
      type: 'string',
      matchCase: false,
      exactMatch: false
    },
    {
      field: 'message',
      label: 'Message',
      type: 'string',
      index: 1
    }
  ]
};
