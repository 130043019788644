import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {InvoiceDocument} from '../../core/invoice-document';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {InvoiceDocumentService} from '../../core/invoice-document.service';
import {HttpHeaders, HttpRequest} from '@angular/common/http';
import {AlertService} from '../../../shared/alert/alert.service';
import {LOOKUP_MODELS_ENUM} from "../../../dictionary/core/lookup-models.enum";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {DialogButtonsController} from '../../../core/dialog-buttons-controller.class'

@Component({
  selector: 'app-invoice-document-dialog',
  templateUrl: './invoice-document-dialog.component.html',
  styleUrls: ['./invoice-document-dialog.component.css']
})
export class InvoiceDocumentDialogComponent extends DialogButtonsController implements OnInit {

  @ViewChild('fileInput') fileInput;

  fileName: string = '';
  progressMode: string = 'determinate';
  isUploading: boolean = false;
  public files = [];

  public fileNameTaken: any = null;
  public fileSizeMessage: string = null;


  public documentForm: FormGroup;
  public invoice;
  public folder;
  public subfolder;
  public edit: boolean = false;
  public document: InvoiceDocument;

  public modalTitle;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "invoice";
  readonly FILE_SIZE = {
    size: 100000000,
    alias: '100 Mb'
  };

  readonly FILE_BIGGER_THEN_MESSAGE = `file is larger than ${this.FILE_SIZE.alias}.`;

  readonly INVOICE_ATTACHMENT_TYPE_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.INVOICE_ATTACHMENT_TYPE.modelName;


  constructor(
    public dialogRef: MatDialogRef<InvoiceDocumentDialogComponent>,
    public formBuilder: FormBuilder,
    public invoiceDocumentService: InvoiceDocumentService,
    public alert: AlertService
  ) {
    super();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

  }

  ngOnInit() {
    if (this.edit) {
      this.documentForm = this.formBuilder.group({
        attachment_type_id: new FormControl(this.document.attachment_type, Validators.required),
        description: new FormControl(this.document.description)
      });
    } else {
      this.documentForm = this.formBuilder.group({
        attachment_type_id: new FormControl(null, Validators.required),
        description: new FormControl('')
      });
    }
  }

  fileNameCheckHandler = (result) => this.fileNameTaken = result.fileExists;

  checkIsFileBiggerThen(fileSize, maxAllowSize) {
    this.fileSizeMessage = null;
    return fileSize > maxAllowSize ? this.FILE_BIGGER_THEN_MESSAGE : null
  }

  onFileSelect(event) {
    if (event.target && event.target.files.length) {
      this.fileName = event.target.files[0].name;
      this.files = this.fileInput.nativeElement.files;
      this.fileSizeMessage = this.checkIsFileBiggerThen(this.files[0].size, this.FILE_SIZE.size);
      this
        .isFileNameTaken(this.fileName)
        .subscribe(this.fileNameCheckHandler);
    } else {
      this.fileName = '';
      this.files = [];
    }
  }

  onDrop(files) {
    if (files && files.length) {
      this.fileName = files[0].name;
      this.files = files;
      this.fileSizeMessage = this.checkIsFileBiggerThen(this.files[0].size, this.FILE_SIZE.size);
      this
        .isFileNameTaken(this.fileName)
        .subscribe(this.fileNameCheckHandler);
    } else {
      this.fileName = '';
      this.files = [];
    }
  }

  isFileNameTaken(fileName: string) {

    this.fileNameTaken = null;

    return this
      .invoiceDocumentService
      .isFileNameTaken(this.invoice.invoice_id, fileName);
  }

  onSubmit(documentForm: FormGroup) {
    let value = documentForm.value;

    let file = null;
    if (this.files.length) {
      file = this.files[0];
    }
    /*if (this.fileInput.nativeElement.files.length) {
      file = this.fileInput.nativeElement.files[0];
    }*/
    this.edit ? this.editDocument(value) : this.create(value, file);
  }

  editDocument(document: InvoiceDocument) {
    this.isUploading = true;
    this.progressMode = 'indeterminate';

    this.toggleDialogButtons();
    this.invoiceDocumentService.editDocument(this.document.id, document, this.invoice.invoice_id)
      .subscribe(
        (result) => {
          this.toggleDialogButtons(false);
          this.close(result);
        },
        (error) => {
          this.toggleDialogButtons(false);
          this.alert.error('', this.messages.get('ADD_DOCUMENT_FAIL'));
          this.isUploading = false;
          this.progressMode = 'determinate';
        }
      );


  }

  create(document: InvoiceDocument, file?: File) {

    this.isUploading = true;
    this.progressMode = 'indeterminate';

    let formData: FormData = new FormData();
    formData.append('files', file, file.name);
    formData.append('description', document.description);
    formData.append('attachment_type_id', (document.attachment_type_id || '').toString());
    formData.append('entity', JSON.stringify(this.invoice));
    formData.append('folder', `${this.folder}/${this.subfolder}`);
    formData.append('file_type', LOOKUP_ENUM.DOCUMENT_TYPE.SUPPORTING_DOCUMENT.toString());


    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    let options = new HttpRequest('PUT', '/', {headers: headers});

    this.toggleDialogButtons();
    this.invoiceDocumentService.addDocument(this.invoice.invoice_id, formData)
      .subscribe(
        (result) => {
          this.toggleDialogButtons(false);
          this.close(result);
        },
        (error) => {
          console.log(">>>.error", error);
          this.toggleDialogButtons(false);
          this.alert.error('', this.messages.get('ADD_DOCUMENT_FAIL'));
          this.isUploading = false;
          this.progressMode = 'determinate';
        }
      );

  }

  public unmask(value) {
    if (value)
    // http://stackoverflow.com/questions/559112
      return Number(value.replace(/[^0-9\.]+/g, ''));
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

}
