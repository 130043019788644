import {InvoiceCharge} from './invoice-charge';
import {Dispute} from '../../dispute/core/dispute';
import {LookupModel} from '../../dictionary/core/lookup.model';

export class DisputeCharge {
  id: number;
  dispute_id: number;
  charge_id: number;
  disputed_amount: number;
  status: number;
  description: string;
  dispute_withheld: boolean;
  final_charge: number;
  resolution_date: Date;
  dispute_value_awarded: number;
  calculated_amount: number;
  category_id: number;
  updated_at: Date;

  charge: InvoiceCharge;
  dispute: Dispute;

  payback_amount: number;

  status_obj: LookupModel;
  currency: any;
  currency_id: number;
  subclient:string;
  reason: string;

  disputeChargeId(): string {
    return this.dispute.invoice.sp_inv_num + '_' + this.charge.id;
  }

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    } else {
      this.dispute_withheld = false;
      this.dispute_value_awarded = null;
      this.disputed_amount = 0;
      this.payback_amount = 0;
      this.calculated_amount = null;
      this.resolution_date = null;
      this.subclient = null;
    }
  }
}
