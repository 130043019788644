<app-inventory-details-toolbar
  [inventory]="inventory"
  [isAddPricingHidden]="true"
  [isAddPricingDisabled]="true"
  [isCreateOrderHidden]="false"
  [isCreateOrderDisabled]="false"
  [isAddGLHidden]="false"
  [isAddGLDisabled]="true"
  [isEditInventoryDisabled]="true"
  [isDocumentAction]="false"
  [isPricingDocumentAction]="false"
  [isFilterDisabled]="true"
  [isNotesOn]="($activeSiderSection | async) === SECTIONS.NOTES_SECTION_NAME"
  [isHistoryOn]="($activeSiderSection | async) === SECTIONS.HISTORY_SECTION_NAME"
  [isCSVDisabled]="false"
  [notesCount]="notesCount"
  
  (downloadCSVRequested)="onDownloadCSVRequested()"
  (toggleNotesRequested)="toggleNotes()"
  (toggleHistoryRequested)="toggleHistory()"
  (backRequested)="back()"
>
</app-inventory-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-inventory-details-tabs [activeTabIndex]="2"></app-inventory-details-tabs>
  <div class="grid-body app-content-list no-padding-app-content-list" style="height: 100%">
  <dx-data-grid #ordersGrid
                class="app-grid-with-pager charge-browser-grid"
                style="height: 100%"
                [columns]="ordersColumns"
                [dataSource]="orders"
                (onRowClick)="onRowClick($event)"
                [showRowLines]="true"
                [showColumnLines]="false"
  >
    <div *dxTemplate="let d of 'expeditedOrderTemplate'">
      <div caGridTooltip>
        {{ d.value === true ? "Yes" : d.value === false ? "No" : null }}
      </div>
    </div>
    <div *dxTemplate="let d of 'dateTemplate'">
      <div caGridTooltip>{{ d.value | caDate }}</div>
    </div>
  </dx-data-grid>
</div>
  <ca-pager class="app-pager ca-default-border-top" [pageContext]="inventoryOrderGridService" [query]="orderQuery" (onChange)="loadOrders(this.inventory.id)"></ca-pager>
  <mat-sidenav #panelSide
               [mode]="'side'"
               position="end"
               [opened]="false">
    <ca-history
      #history
      [entityId]="inventory?.id"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
      [entityType]="'inventory'">
    </ca-history>

    <ca-notes
      [entityId]="inventory?.id"
      [entityType]="SYSTEM_MODULE.INVENTORY"
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
      (countChanged)="onNotesCountChanged($event)">
    </ca-notes>
  </mat-sidenav>
</mat-sidenav-container>
