import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngxs/store';
import {LoadSingleInvoice} from "../invoice-details/state/invoice-details.actions";
import {map, tap} from "rxjs/operators";
import {InvoiceDetailsState} from "../invoice-details/state/invoice-details.state";

@Injectable({
  providedIn: 'root'
})
export class InvoiceDetailsResolver implements Resolve<any> {

  constructor(private store: Store) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const id = route.params.id;
    return this.store.dispatch(new LoadSingleInvoice(id))
      .pipe(
        map(() => this.store.selectSnapshot(InvoiceDetailsState).invoice)
      )
      .toPromise();
  }
}
