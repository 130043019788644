import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class AuditRatesGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super('app.audits.audit-grid', userSettings);
  }

  defaultColumns = [
    {
      caption: 'Audit Name',
      dataField: 'audit.name',
      width: 200
    },
    {
      caption: 'Rate Code',
      dataField: 'contract_schedule_rate.rate_code',
      width: 150,
      alignment: 'left'
    },
    {
      caption: "Rate",
      dataField: "contract_schedule_rate.rate",
      dataType: 'number',
      cellTemplate: 'rateCurrencyTemplate',
      alignment: 'left',
      width: 150
    },
    {
      caption: 'Vendor',
      dataField: 'audit.vendor.name',
      width: 150
    },
    {
      caption: 'Contract',
      dataField: 'audit.contract.name',
      width: 150
    },
    {
      caption: 'Description',
      dataField: 'audit.description',
      width: 200
    }, {
      caption: 'Audit Type',
      dataField: 'audit.type.value',
      width: 150,
      alignment: 'left'
    }, {
      caption: 'Create Date',
      dataField: 'audit.created_at',
      dataType: 'date',
      cellTemplate: 'createdAtTemplate',
      alignment: 'left',
      width: 180
    }, {
      caption: 'Created By',
      dataField: 'audit.created_by.full_name',
      alignment: 'left',
      width: 180
    }
  ];
}
