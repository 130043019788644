import {Address} from "../../address/core/address.model";
export class Building {
  id: number;
  name: string;
  address_id: number;

  address: Address;

  custom_values?: any;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
