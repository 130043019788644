import {UpdateQueryOptions} from "../../../../common/state/query.actions";

export class UpdateInvoiceGLStringQuery {
  static readonly type = '[INVOICE_GL_STRING] Update Query';
  constructor(public options?: UpdateQueryOptions) {
  }
}

export class UpdateInvoiceGLStringSiderSection {
  static readonly type = '[INVOICE_GL_STRING] Update active sider section';
  constructor(public name: string) {}
}
