<mat-toolbar color="accent">
  <span class="sneak-peek-title">Notes</span>
  <span class="example-fill-remaining-space"></span>
</mat-toolbar>
<div class="ca-card ca-card-note">
  <mat-card class="note-card notes-add">
    <mat-card-content style="margin-bottom: 0">
      <form [formGroup]="noteForm" class="flex-container ca-form">
        <mat-form-field fxFlex caInput>
            <textarea matInput [placeholder]="entity?.title || master?.title || 'Note'" rows="3"
                      formControlName="content" required></textarea>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-footer class="note-action-container">
      <div fxLayout="row" class="note-action ca-form flex-container">
        <div *ngIf="multiple && entities?.length" class="entities-add-note">{{entities.length}} {{ entities.length === 1
          ? 'charge'
          : 'entities' }} selected
        </div>
        <div *ngIf="options?.length" class="flex-item line-number-wrapper" fxFlex="0 0 110px">
          <ca-default-picker caInput>
            <mat-form-field class="ca-note-line-item-form-field">
                <mat-select placeholder="Line item"
                (selectionChange)="onLineItemChange($event)"
                (change)="onLineItemChange($event)">
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let option of options" [value]="option.id">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
          </ca-default-picker>

        </div>
        <div class="example-fill-remaining-space"></div>
        <button mat-button (click)="createNote()" [disabled]="!noteForm.valid" class="note-button">
          Add
        </button>
      </div>
    </mat-card-footer>
  </mat-card>
</div>

<div class="notes-view">

  <mat-accordion id="accordion" [multi]="false">

    <mat-expansion-panel *ngFor="let group of groupedNotes; let i = index;" class="ca-card ca-card-note ca-card-note-list-group">
      <mat-expansion-panel-header class="ca-expansion-panel-header-title" (click)="selectGroup(group)" [expandedHeight]="'48px'" [collapsedHeight]="'48px'">
          <span class="note-group-title">
              {{group.label}}
            </span>
      </mat-expansion-panel-header>


      <mat-card class="note-card notes-list-card" *ngFor="let note of group.notes"
               [ngClass]="{'selected': note.id == selectedId}">

               <hr class="notes-horizontal-divider" />


        <mat-card-header>
          <div class="avatar avatar-md note-avatar" (click)="showUserModal(note.user)">
            <img *ngIf="note?.user?.avatar" [src]="note.user?.avatar" alt="User Image">
            <div class="replacement" *ngIf="!note?.user?.avatar">{{ getUserInitials(note) }}</div>
          </div>
          <div class="note-card-title" fxLayout="column">
            <div fxFlex class="note-card-user" matTooltip="">{{ note?.user?.full_name }}</div>
            <div fxFlex class="note-card-date muted">{{ note?.created_at | caDateTime }}</div>
          </div>
        </mat-card-header>
        <mat-card-content>
          <p [innerHtml]="sanitizeNoteContent(note?.content | newline)"></p>
          <p *ngIf="note.entities && note.entities.length">
            <a *ngIf="multiple" (click)="viewEntities(note.entities)" class="entities-link">View entities</a>
            <a *ngIf="multiple" (click)="viewEntities(note.entities)"
               class="selection-badge">{{note.entities.length}}</a>
          </p>
          <p *ngIf="!multiple && showViewLink(note)">
            <a (click)="viewEntity(note)" class="selection-badge">Line Item: {{note.entity_meta?.title}}</a>
          </p>
          <div class="note-card-comments" *ngFor="let child of note.notes">
            <div class="note-card-header" fxLayout="row">
              <div fxItem class="avatar avatar-md note-avatar" (click)="showUserModal(child.user)">
                <img *ngIf="child?.user?.avatar" [src]="child.user?.avatar" alt="User Image">
                <div class="replacement" *ngIf="!child?.user?.avatar">{{ getUserInitials(child) }}</div>
              </div>
              <div fxItem class="note-card-title" fxLayout="column">
                <div fxFlex class="note-card-user">{{ child?.user?.full_name }}</div>
                <div fxFlex class="note-card-date muted">{{ child?.created_at | caDateTime }}</div>
              </div>
            </div>
            <div class="note-card-body">
              <p [innerHtml]="sanitizeNoteContent(child?.content | newline)"></p>
            </div>
          </div>
        </mat-card-content>
        <form name="replyForm" class="note-card-reply flex-container ca-form" *ngIf="replyMode && selectedNote.id == note.id">
          <mat-form-field fxFlex caInput>
            <textarea matInput placeholder="Add a reply..." rows="3"
                      name="reply" type="text"
                      [(ngModel)]="note.reply"></textarea>
          </mat-form-field>
        </form>
        <mat-card-footer class="note-action-container">
          <div fxLayout="row" class="note-action flex-container">
            <div class="example-fill-remaining-space"></div>
            <button mat-button (click)="cancel(note)" *ngIf="replyMode && selectedNote.id == note.id"
                    class="note-button">
              Cancel
            </button>
            <button mat-button (click)="reply(note)" [disabled]="!note.reply && replyMode" class="note-button">
              Reply
            </button>
          </div>
        </mat-card-footer>
      </mat-card>

    </mat-expansion-panel>

  </mat-accordion>

</div>
