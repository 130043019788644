<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>New Pricing</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <form class="ca-form dialog-content-no-margin" [formGroup]="pricingForm">

    <div class="flex-container" fxLayout="column">
      <input class="ca-document-file-input" #fileInput placeholder="Document" type="file"
             (change)="onFileSelect($event)">
      <div class="ca-dropzone ca-default-border-color" caDropzone (dropped)="onDrop($event)" fxLayout="column">
        <div class="text-wrapper" fxLayoutAlign="center center">
            <ca-icon [key]="'FILE_CLOUD_UPLOAD'" [innerClass]="'upload-icon'"></ca-icon>
            <span class="document-upload-text"> Drop file to upload or</span>
            <button type="button" class="document-upload-button flat-button" mat-button (click)="fileInput.click()">Browse</button>
          </div>
          <div [ngClass]="{'ca-file-name-taken-error-text': (fileNameTaken !== null && fileNameTaken)}" class="text-wrapper selected-file" fxLayoutAlign="center center" *ngIf="fileNameTaken !== null">
            <i>{{ fileName }} <span *ngIf="fileNameTaken !== null && fileNameTaken">already exists</span></i>
          </div>
      </div>
    </div>

    <div class="flex-container" fxLayout="row" fxLayoutGap="24px" style="margin-top: 24px">
      <div class="flex-item" fxFlex>

        <ca-lookup-model-picker caInput [clearEnabled]="true" [pickerForm]="pricingForm" [lookupModel]="CONTRACT_TYPE_LOOKUP_MODEL" formControlName="contract_type_id" placeholder="Contract Type">
        </ca-lookup-model-picker>

      </div>

      <div class="flex-item" fxFlex>
        <mat-form-field class="input-container" caInput>
          <input matInput placeholder="Term" min="0" max="30" type="number" formControlName="term">
        </mat-form-field>
      </div>
    </div>

    <div class="flex-container" fxLayout="row" fxLayoutGap="24px">
      <div class="flex-item"  fxFlex>
        <ca-date-picker caInput placeholder="Effective Date" formControlName="effective_date"></ca-date-picker>
      </div>

      <div class="flex-item"  fxFlex>
        <ca-date-picker caInput placeholder="Expiration Date" formControlName="exp_date"></ca-date-picker>
      </div>
    </div>

    <div class="flex-container" fxLayout="row" fxLayoutGap="24px">
      <div class="flex-item"  fxFlex>
          <ca-currency caInput formControlName="mrc" placeholder="MRC" [currency]="inventory?.currency_obj?.currency"></ca-currency>
      </div>

      <div class="flex-item"  fxFlex>
          <ca-currency caInput formControlName="nrc" placeholder="NRC" [currency]="inventory?.currency_obj?.currency"></ca-currency>
      </div>
    </div>

    <div class="flex-container" fxLayout="row" fxLayoutGap="24px">

      <div class="flex-item" fxFlex>
        <mat-form-field class="input-container" caInput>
          <textarea matInput placeholder="Description" formControlName="description" rows="2"></textarea>
        </mat-form-field>
      </div>

    </div>

  </form>
</div>


<mat-dialog-actions class="ca-dialog-action">
    <div class="example-fill-remaining-space">
    </div>

    <button #cancelButton mat-button type="submit" (click)="close()">
      Cancel
    </button>

    <button
      #saveButton
      mat-button
      type="submit"
      (click)="onSubmit(pricingForm)"
      [disabled]="fileNameTaken == null || fileNameTaken || isUploading || !files.length">
      Save
    </button>
  </mat-dialog-actions>
