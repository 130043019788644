import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Output, EventEmitter
} from '@angular/core';
import { CaCurrencyPipe } from 'app/shared/format/ca-currency.pipe';
import {UserService} from "../../../user/core/user.service";
import {LocationService} from "../../../location/core/location.service";

@Component({
  selector: 'ca-currency',
  templateUrl: './ca-currency.component.html',
  styleUrls: ['./ca-currency.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaCurrencyComponent),
      multi: true
    }
  ]
})
export class CaCurrencyComponent extends CaCurrencyPipe implements OnChanges, OnInit, ControlValueAccessor {

  constructor(public userService: UserService,
              public locationService: LocationService) {
    super(userService, locationService);
  }

  @Input('value') _value: any;
  @Input('required') isRequired = false;
  @Input('disabled') isDisabled = false;
  @Input('readonly') isReadonly = false;
  @Input('allowNegative') allowNegative: boolean;
  @Input('totalMrc') totalMrc;
  @Input('currency') currency = null;
  @Input() placeholder = 'Currency';

  @ViewChild('curr') currInput;
  @ViewChild('currencyInput') currencyInput: NgModel;

  isFocused: boolean;
  showInput: boolean;
  deciamlSeparator = '.';
  public entries: Array<any>;

  numberMask;

  @Output() focusEvent = new EventEmitter<boolean>();

  onTouched: any = () => {
  };

  ngOnChanges(changes: SimpleChanges) {
    let transformedValue = super.transform(0,this.currency,null,'1.1-1',null);
    this.deciamlSeparator = transformedValue.substr(transformedValue.indexOf('0') + 1,1);
  }

  get value() {
    return this._value;
  }

  set value(val) {

    if(!val) {
      this._value = val;
      this.propagateChange(val);
      if(val === undefined) {
        this.currencyInput.reset();
      }
      return;
    }
    let v = val;

    if (typeof v !== 'string') {
      return
    }

    if(this.deciamlSeparator != '.'){
      v = val.replaceAll(this.deciamlSeparator,'.');
    }

    this._value = v.replace(/[^0-9-\.]+/g, "");
    this.propagateChange(v.replace(/[^0-9-\.]+/g, ""));
    setTimeout(()=>{
      this.currInput.nativeElement.value = this.transform(this._value,this.currency,null,null,null);
    }, 50);
  }

  propagateChange = (_: any) => {
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  onChange(event) {
    this.value = event.target.value;
  }

  ngOnInit() {
  }


  toggleFocus(flag: boolean) {
    this.isFocused = flag;
  }

  toggleInput(flag: boolean) {
    this.showInput = flag;
    if (flag == true)
      setTimeout(() => {
        this.currInput.nativeElement.focus();
      });
  }

  unmask(value) {
    if (value)
    // http://stackoverflow.com/questions/559112
      this.value =this.value;// Number(value.replace(/[^0-9\.]+/g, ""));
  }

  sendFocus() {
    this.isFocused = true;
    this.focusEvent.emit(this.isFocused)
  }

  sendFocusout() {
    this.isFocused = false;
    this.focusEvent.emit(this.isFocused)
  }
}
