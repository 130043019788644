<div class="mat-dialog-top-spacer"></div>
<div mat-dialog-content class="ca-dialog-content">
  <p>
    Error:  Invoice {{invoice ? invoice.sp_inv_num : inv_num}} does not balance.
  </p>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
  </div>

  <button mat-button type="submit" (click)="close()">
    Cancel
  </button>

</mat-dialog-actions>
