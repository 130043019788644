import { Injectable } from '@angular/core';
import { GridService } from "../../shared/grid/grid.service";
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class CustomerGridService extends GridService {


  constructor(public userSettings: UserSettingsService) {
    super("app.customer.customer-grid", userSettings);
  }

  defaultColumns = [{
    caption: "Company",
    dataField: "company",
    width: 200
  },
  {
    caption: "Account Number",
    dataField: "account_number",
    width: 140
  },
  {
    caption: "Master Number",
    dataField: "master_customer_number",
    width: 120
  },
  /* Corporate address columns with 'corporate prefix' */
  {
    dataField: 'corporate_address.address1',
    caption: 'Corporate Address',
    width: 200
  },
  {
    dataField: 'corporate_address.city',
    caption: 'Corporate City',
    width: 160
  },
  {
    dataField: 'corporate_address.subdivision.abbreviation',
    caption: 'Corporate State',
    width: 160
  },
  {
    dataField: 'corporate_address.postal_code',
    caption: 'Corporate Postal Code',
    width: 140
  },
  /* Billing address columns */
  {
    dataField: 'billing_address.address1',
    caption: 'Billing Address',
    width: 200
  },
  {
    dataField: 'billing_address.city',
    caption: 'Billing City',
    width: 160
  },
  {
    dataField: 'billing_address.subdivision.abbreviation',
    caption: 'Billing State',
    width: 160
  },
  {
    dataField: 'billing_address.postal_code',
    caption: 'Billing Postal Code',
    width: 140
  },
  {
    caption: "Phone",
    cellTemplate: 'phoneTemplate',
    dataField: "office_phone",
    width: 150
  }];

 /* columns() {

    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/

}
