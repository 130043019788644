import { Injectable } from '@angular/core';
import { FilterService } from "../../core/filter/filter.service";
import Query from "../../core/query/query";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LOOKUP_ENUM } from "../../dictionary/core/lookup.enum";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { VendorLookupProvider } from "../../vendor/core/vendor-lookup.provider";
import { VendorService } from "../../vendor/core/vendor.service";
import { svcOrderHeaderFilterContext } from "./svc-order-header-filter.context";
import { DefaultDateRangesLookupProvider } from '../../core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class SvcOrderHeaderFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  public documentTypeQuery = new Query({
    where: { id: { $in: [LOOKUP_ENUM.DOCUMENT_TYPE.MSA, LOOKUP_ENUM.DOCUMENT_TYPE.SERVICE_SCHEDULE]  } }
  });

  constructor(public vendorService: VendorService,
              public userSettingsService: UserSettingsService,
              public dictionaryService: DictionaryService
              ) {
    super("app.contract.svc-order-header-filter", svcOrderHeaderFilterContext, userSettingsService);
    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService));
  }
}
