<div fxLayout="column">
  <mat-toolbar color="accent" fxFlex="48px">
    <a [routerLink]="['/account', id, 'show']" class="sneak-peek-title">{{
      account?.account_no
    }}</a>

    <span class="example-fill-remaining-space">&nbsp;</span>
    <button mat-icon-button (click)="closeSide()">
      <ca-icon [key]="'CLOSE'"></ca-icon>
    </button>
  </mat-toolbar>

  <div fxFlex>
    <div class="ca-card ca-card-sneak-peek">
      <mat-card caCard>
        <mat-card-content>
          <div
            *ngIf="account?.on_hold"
            fxLayout="row"
            class="entry-list">
            <div
              *ngIf="account?.on_hold"
              fxFlex="1 1"
              class="entry-list-item"
              class="account-badge"
              style="justify-content: left;padding-left: 10px"
            >
              <p
                *ngIf="account?.on_hold"
                class="switch-button status-badge"
                [ngClass]="{
                  'btn-disabled':
                    account?.on_hold
                }"
              >
                {{"ON HOLD"}}
              </p>
            </div>
          </div>
          <div
            fxLayout="row"
            class="entry-list"
            fx-layout-align="center center"
          >
            <div fxFlex="50%" class="entry-list-item">
              <label>Vendor</label>
              <p>
                {{ account?.vendor?.name }}
              </p>
            </div>
            <div
              fxFlex="0 0 auto"
              class="entry-list-item"
              class="account-badge"
            >
              <p
                class="switch-button status-badge"
                [ngClass]="{
                  'btn-enabled':
                    account?.status_id == ACCOUNT_STATUS_ENUM.ACTIVE,
                  'btn-disabled':
                    account?.status_id == ACCOUNT_STATUS_ENUM.INACTIVE
                }"
              >
                {{ account?.status?.value }}
              </p>
            </div>
          </div>

          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Entity</label>
              <p>
                {{ account?.entity?.name || "N/A" }}
              </p>
            </div>
          </div>
          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Remit Address</label>
              <div>
                <p *ngIf="!account?.vendor_remit_address">N/A</p>
                <p *ngIf="account?.vendor_remit_address?.address?.address1">
                  {{ account?.vendor_remit_address?.address?.address1 }}
                </p>
                <p>
                  {{ account?.vendor_remit_address?.address?.city }}&nbsp;
                  {{
                    account?.vendor_remit_address?.address?.subdivision?.abbreviation
                  }}&nbsp;
                  {{ account?.vendor_remit_address?.address?.postal_code }}
                </p>
                <p>
                  {{ account?.vendor_remit_address?.address?.country?.name }}
                </p>
              </div>
            </div>
          </div>
          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Subclient</label>
              <p *ngIf="!account?.subclient">N/A</p>
              <p *ngIf="account?.subclient?.value">
                {{account?.subclient?.value}}
              </p>
            </div>
          </div>
          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Region</label>
              <p *ngIf="!account?.region">N/A</p>
              <p *ngIf="account?.region?.value">
                {{account?.region?.value}}
              </p>
            </div>
          </div>
          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Country</label>
              <p *ngIf="!account?.region">N/A</p>
              <p *ngIf="account?.region?.value">
                {{account?.country?.alpha_2_code || account?.country?.name}}
              </p>
            </div>
            <div fxFlex class="entry-list-item">
              <label>Region</label>
              <p *ngIf="!account?.region">N/A</p>
              <p *ngIf="account?.region?.value">
                {{account?.region?.value}}
              </p>
            </div>
          </div>
          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Currency</label>
              <p>
                {{ account?.currency_obj?.currency || 'N/A' }}
              </p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="ca-card ca-card-sneak-peek">
      <mat-card class="chart-container">
        <mat-card-content>
          <app-invoice-trend
            (seriesClicked)="onSeriesClick($event)"
            [account]="account"
            *ngIf="visible"
          ></app-invoice-trend>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
