import { Injectable } from '@angular/core';
import { FilterService } from "../../core/filter/filter.service";
import { UserLookupProvider } from "../../user/core/user-lookup.provider";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { UserService } from "../../user/core/user.service";
import { glStringFilterContext } from "./gl-string-filter.context";
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class GLStringFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];
  
  constructor(public userSettingsService: UserSettingsService,
              userService: UserService) {
    super("app.gl.rule-execution-gl-string-filter", glStringFilterContext, userSettingsService);
    this.addLookupProvider('createdBy', new UserLookupProvider(userService));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
