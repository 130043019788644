import {LookupModel} from "../../dictionary/core/lookup.model";

export class Contract {
  id: number;
  master_id: number;
  vendor_id: number;
  vendor: any;
  name: string;
  description: string;
  company_name: string;
  company_sign_date: Date;
  vendor_sign_date: Date;
  effective_date: Date;
  termination_date: Date;
  term_months: number;
  committed_value: number;
  status: number;
  type: number;
  document_id: number;
  document: any;
  children: Array<any>;
  parent: Contract;
  contract_type: LookupModel;
  currency: string;
  instance: any;
  disableVendorPicker: boolean;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
