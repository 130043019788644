<app-svc-order-details-toolbar
  [svcOrderHeader] = svcOrderHeader
  [isAddServicesHidden] = "true"
  [isEditServicesHidden] = "true"
  [isAddTermsConditionHidden] = "false"
  [isEditTermsConditionHidden] = "false"
  [isEditTermsConditionDisabled] = "isEditTermsDisabled"
  [isDeleteTermsConditionHidden] = "false"
  [isDeleteTermsConditionDisabled] = "isDeleteTermsDisabled"
  [isDownloadAttachmentHidden] = "true"
  [isDeleteAttachmentHidden] = "true"
  (backRequested)="back()"
  [isFilterHidden] = "false"
  [isFilterDisabled] = "false"
  [isGridSettingsHidden] = "false"
  [isCsvHidden] = "false"
  (addNewSvcOrderTCRequested) = "addNewSvcOrderTC()"
  (editSvcOrderTCRequested) = "editSvcOrderTC()"
  (deleteSvcOrderTCRequested)="deleteSvcOrderTC()"
  [isFilterOn] = "this.sider.getActiveSection().name === this.SECTIONS.FILTER_SECTION_NAME"
  (gridSettingsRequested) = "gridSettings()"
  (csvRequested) = "csv()"
  (toggleFilterRequested)="toggleFilter()"

>
</app-svc-order-details-toolbar>

<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-svc-order-details-tabs
    [activeTabIndex]="2"
    [svcOrderHeader] = svcOrderHeader
  ></app-svc-order-details-tabs>

  <div class="tabs-body grid-container grid-with-toolbar"
       style="top: -2px; z-index: -1; overflow: hidden;">
    <div class="grid-body app-content-list no-padding-app-content-list">
      <dx-data-grid #dataGrid
                    class="app-grid-with-pager dx-sort-disabled sort-arrows"
                    [columns]="svcOrderTermConditionColumns"
                    [dataSource]="svcOrderTermsConditions"
                    [height]="'100%'"
                    [width]="'100%'"
                    [showColumnLines]="false"
                    [showRowLines]="true"
                    [allowColumnResizing]="true"
                    [columnMinWidth]="50"
                    [columnAutoWidth]="true"
                    (onSelectionChanged)="onSelectionChanged($event)"
                    (onCellClick)="onSvcOrderTermsAndConditionCellClick($event)">
        <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>
        <dxo-paging [enabled]="false" visible="false"></dxo-paging>
        <dxo-sorting mode="none"></dxo-sorting>
        <dxo-load-panel [enabled]="false"></dxo-load-panel>

        <div class="expanded-cell" *dxTemplate="let d of 'contractTextCellTemplate'">
            <span class="expanded-text" matTooltip="{{d.value}}">
              {{ d.value }}
            </span>
        </div>

      </dx-data-grid>
      <ca-pager class="app-pager ca-default-border-top"
                [gridPager]="defaultGridPager"
                [pageContext]="svcOrderContractTermConditionGridService"
                [query]="svcOrderTermsConditionQuery"
                (onChange)="loadSvcOrderTermsAndConditions(this.svcOrderTermsConditionQuery)"></ca-pager>
    </div>
  </div>

  <mat-sidenav #panelMore [mode]="'side'" position="end">
        <new-app-filter
          [ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
          [query]="svcOrderTermsConditionQuery"
          [filterService]="svcOrderContractTermAndConditionFilterService"
          (filterRequested)="loadSvcOrderTermsAndConditions($event)"
          (clearFilterRequested)="clearSvcOrderTermsAndConditionFilter($event)"
        >
        </new-app-filter>
      </mat-sidenav>
</mat-sidenav-container>
