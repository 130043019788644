import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class AlertPreGlValidationGridService extends GridService {
  defaultColumns = [
    {
      caption: '',
      headerCellTemplate: 'selectionBadgeHeaderTemplate',
      cellTemplate: 'selectionBadgeCellTemplate',
      fixed: true,
      width: 40,
      allowSorting: false
    },
    {
      caption: 'Message',
      dataField: 'message',
      width: 440
    },
    {
      caption: 'Validation Rule',
      dataField: 'validation_rule_value',
      width: 150
    },
    {
      caption: 'ID',
      dataField: 'item_id',
      width: 150
    },
    {
      caption: 'Status',
      dataField: 'status',
      width: 150,
      cellTemplate: 'statusCellTemplate',
      transformValue: {
        true: 'Excluded',
        false: 'Included'
      }
    }
  ];

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.alert.pre-gl-validation-grid', userSettings);
  }

}
