import {Injectable} from '@angular/core';
import {UserSettingsService} from "../../user/core/user-settings.service";
import {GridService} from "../../shared/grid/grid.service";
import {FormatService} from "../../shared/format/format.service";

@Injectable()
export class AlertInventoryMrcGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.alert-inventory.inventory-mrc-grid", userSettings);
  }

  defaultColumns = [
    {
      caption: "SPID",
      dataField: "sp_ckt",
      width: 170
    },
    {
      caption: "Billed MRC",
      alignment: 'right',
      dataField: "billed_mrc",
      dataType: 'currency',
      cellTemplate: 'auditCurrencyTemplate',
      width: 100
    },
    {
      caption: "Inventory MRC",
      alignment: 'right',
      dataField: "inventory_mrc",
      dataType: 'currency',
      cellTemplate: 'auditCurrencyTemplate',
      width: 130
    },
    {
      caption: "Variance",
      alignment: 'right',
      cellTemplate: 'auditCurrencyTemplate',
      dataField: "variance",
      dataType: 'currency',
      width: 130
    },
    {
      caption: "Type",
      dataField: "result_type",
    },
    {
      caption: "Status",
      dataField: "inventory_status_value",
    },
    {
      caption: "Rate Status",
      cellTemplate: 'rateStatusTemplate',
      dataField: "rate_status_value",
    }
  ];

}
