import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageListComponent} from '../../core/page-list.component';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {PageContext} from '../../core/page.context';
import {CommonAlertService} from '../core/common-alert.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

import {DialogService} from '../../shared/dialog/dialog.service';
import {GridSettingsComponent} from '../../core/grid/grid-settings.component';
import {AlertService} from '../../shared/alert/alert.service';
import {DisputeService} from '../../dispute/core/dispute.service';
import {InvoiceFacepageService} from '../../invoice/core/invoice-facepage.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {IMessagesResourceService, ResourcesService} from '../../core/resources/resources.service';
import {TabGroup, TabService} from '../../shared/tabs/tab.service';
import {Observable} from 'rxjs/Observable';
import {AlertChargesFilterService} from '../core/alert-charges-filter.service';
import {GridService} from '../../shared/grid/grid.service';
import Query from "../../core/query/query";
import {AlertPreGlValidationGridService} from "../core/alert-pre-gl-validation-grid.service";
import {AlertPreGLValidationService} from '../core/alert-pre-gl-validation.service';
import {AlertPreGlValidationCsvInterceptor} from "../core/alert-pre-gl-validation-csv-interceptor";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-alert-pre-gl-validation',
  templateUrl: './alert-pre-gl-validation.component.html',
  styleUrls: ['./alert-pre-gl-validation.component.css']
})

export class AlertPreGlValidationComponent extends PageListComponent implements OnInit, AfterViewInit, OnDestroy {
  alert;
  public summary: any;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTab: number = 0;
  public query: Query = new Query({orderBy: [['id', 'ASC']]});
  public validationAlerts: Array<any> = [];
  public columns: any;
  public selectedCharges = [];
  public selection;
  public filter: any;
  public addDisputeDisabled: boolean = true;
  public delayChargesSelectionChange: boolean = false;
  public chargesSelectionActive: boolean = false;
  public sorting: any[][];
  public isSelectionValid = false;

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @ViewChild('panelSide') panelSide;

  readonly sideSections = {
    none: null,
    info: 'details',
    filter: 'filter',
  };

  _isSidePanelOpen = false;
  activeTabIndex: number = 0;
  isGridSettingsDisabled = true;

  public alertId: number;

  readonly PLACEHOLDERS = {
    OWNER_UNASSIGNED: 'Unassigned'
  };

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'common-alert';
  public currentTabIndex = 0;

  constructor(public settingsService: UserSettingsService,
              public route: ActivatedRoute,
              public alertService: CommonAlertService,
              public alertPreGlValidationGridService: AlertPreGlValidationGridService,
              public router: Router,
              public dialogService: DialogService,
              public toastAlertService: AlertService,
              public disputeService: DisputeService,
              public loaderService: LoaderService,
              public alertChargesFilterService: AlertChargesFilterService,
              public invoiceFacepageService: InvoiceFacepageService,
              public tabService: TabService,
              public alertPreGLValidationService: AlertPreGLValidationService
  ) {
    super(new PageContext({
      name: 'app.alert.alert-charges',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    this.currentTabIndex = index;
    if (tab.key === 2 && this.sider.getActiveSection().name === this.sideSections.filter) {
      this._isSidePanelOpen = true;

      Observable
        .of(true)
        .subscribe(
          () => {
            this._isSidePanelOpen ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME) : this.sider.closeComponent();

            this.tabGroup.forceRerender();
          }
        );
    }

    if (tab.key !== 2 && this.sider.getActiveSection().name === this.sideSections.filter) {
      this._isSidePanelOpen = false;

      Observable
        .of(true)
        .subscribe(
          () => {
            this._isSidePanelOpen ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME) : this.sider.closeComponent();

            this.tabGroup.forceRerender();
          }
        );
    }

    if (tab.key !== 2 && this.sider.getActiveSection().name === this.sideSections.info) {
      this._isSidePanelOpen = false;

      Observable
        .of(true)
        .subscribe(
          () => {
            this._isSidePanelOpen ? this.sider.open(this.SECTIONS.DETAILS_SECTION_NAME) : this.sider.closeComponent();

            this.tabGroup.forceRerender();
          }
        );
    }

    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }

    /* Settings grid disabled */
    if (this.tabGroup.isActive(2) || this.tabGroup.isActive(3) || this.tabGroup.isActive(4)) {
      this.isGridSettingsDisabled = false;
    } else {
      this.isGridSettingsDisabled = true;
    }
  }

  public loadTabs() {

    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({key: 1, title: 'Summary'});
    this.tabGroup.addTab({key: 2, title: 'Details'});

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe((tab) => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }


  loadData(alertId: number) {
    this.alertService.findById(this.route.params['_value'].id)
      .subscribe(
        (alert) => {
          this.alert = alert;
          this.loadPreGLValidationAlerts(this.query);
        },
        () => {
          console.log('Error fetching alert info');
        }
      );
  }

  private getPreGLValidationAlerts(alertId, query) {
    return this.alertService.findPreGLValidationAlerts(alertId, query);
  }

  // -----------------------------------------------------------
  loadPreGLValidationAlerts(query: Query) {
    this.delayChargesSelectionChange = true;
    this.validationAlerts = [];

    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.getPreGLValidationAlerts(this.alertId, query)
      .subscribe((results) => {
        this.validationAlerts = results.items;
        query.total = results.total;

        if (this.validationAlerts.length) {
          setTimeout(() => {
            this.delayChargesSelectionChange = false;
            this.dataGrid.instance.selectRows(this.selectedCharges, false);
          }, 500);
        }

        setTimeout(() => {
          this.dataGrid.instance.repaint();
          this.dataGrid.instance.refresh();
        }, 600);

        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      }, () => {
        this.toastAlertService.error('', this.messages.get('ERROR_LOADING_GL_VALIDATION'));
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      });
  }

  findIndexInSelectedRows(id): number {
    for (let i = 0, l = this.selectedCharges.length; i < l; i++) {
      if (this.selectedCharges[i].id === id) {
        return i;
      }
    }
    return -1;
  }

  onSelectionChanged(event) {
    if (!this.delayChargesSelectionChange && this.validationAlerts.length) {
      // ** Deselected rows */
      if (event.currentDeselectedRowKeys.length) {
        event.currentDeselectedRowKeys.forEach((item) => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index > -1) {
            this.selectedCharges.splice(index, 1);
          }
        });
      }

      // ** Selected rows */
      if (event.currentSelectedRowKeys.length) {
        event.currentSelectedRowKeys.forEach((item) => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index === -1) {
            this.selectedCharges.push(item);
          }
        });
      }


      if (this.selectedCharges.length > 1) {
        this.selection = null;
      } else {
        this.selection = event.selectedRowsData[0];
      }

      this.isSelectionValid = !this.selectedCharges.map(item => item.status).includes(true);

      if (this.chargesSelectionActive && !this.selectedCharges.length) {
        this.loadSelected();
      }
    }
  }

  excludeItems() {
    if (this.selectedCharges.length) {
      this.alertPreGLValidationService.openConfirmationDialog(
        {
          items: this.selectedCharges,
          actionText: 'exclude',
          title: 'Exclude items',
          config: null,
          onExclusionConfirmed: (result) => {
            this.alertService.excludeAlertItems(this.alertId, this.selectedCharges, result.note)
              .subscribe((response => {
                  this.selectedCharges = [];
                  this.isSelectionValid = false;
                  this.loadData(response.alertId);
                  result.closeDialog();
                  setTimeout(() => {
                    this.toastAlertService.success('', this.messages.get('ITEMS_EXCLUDED'));
                  }, 200)
                }),
                (error) => console.log(error))
          }
        },
      )
    } else {
      this.dialogService
        .simpleAlert({
          bodyText: this.messages.get('NO_INVOICES')
        })
    }
  }

  /** Selection badge active */
  loadSelected() {
    if (this.query && !this.query['where'].hasOwnProperty('id')) {
      this.filter = JSON.parse(JSON.stringify(this.query['where']));
    }
    if (this.chargesSelectionActive) {
      this.query.remove('id');
      this.query['where'] = this.filter;
    } else {
      this.query['where'] = {};
      this.query.set('id', {
        $in: this.selectedCharges.map((x) => {
          return x.id;
        })
      });
    }
    this.query.set('alert_id', this.alertId);
    this.query.offset = 0;
    this.query.page = 1;
    this.chargesSelectionActive = !this.chargesSelectionActive;
    this.loadPreGLValidationAlerts(this.query);
  }

  refresh() {
    this.loadPreGLValidationAlerts(this.query);
  }

  onPageChange(query: Query) {
    this.loadPreGLValidationAlerts(query);
  }

  filterData(query: Query) {
    this.query = query;
    this.loadPreGLValidationAlerts(query);
  }

  clearFilter(query: Query) {
    this.query = query;
    this.loadPreGLValidationAlerts(query);
  }

  // -------------------------------------------------------------

  ngOnInit() {
    this.loadTabs();
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      try {
        if (params.key) {
          this.tabGroup.activate(parseInt(params.key, 10));
          this.currentTabIndex = 1;
        }
      } catch (err) {
        console.log(err);
      }
    });
    this._init();
  }

  gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.alertPreGlValidationGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.alertPreGlValidationGridService;
  }

  getDefaultSort(): any {
    return [['id', 'ASC']];
  }

  _init() {
    super.prepareList();
    this.route.data.subscribe((data) => {
      this.alert = data.alert;
      this.alertId = this.alert.id;
      this.loadPreGLValidationAlerts(this.query);
    })
  }

  ngAfterViewInit(): void {
    this.alertPreGlValidationGridService.create(this.dataGrid.instance, {
      selection: {
        mode: 'multiple',
        selectAllMode: 'page'
      },
      noDataText: this.alertPreGlValidationGridService.noDataMessage
    });

    super.ngAfterViewInit();
  }

  sortColumn(sorting) {
    this.query['orderBy'] = sorting;

    this.loadPreGLValidationAlerts(this.query);
  };

  back(): void {
    if (document.referrer.indexOf(window.location.host) >= 0) {
      history.back();
    } else {
      this.router.navigate(['/alert']);
    }
  }

  csv() {
    const {alertPreGlValidationGridService, alertService, query, alertId} = this;
    alertPreGlValidationGridService.csvMap().subscribe(fields => {
      const alertPreGlValidationTransformMiddleware = (items) => {
        const alertPreGlValidationCSVInterceptor = new AlertPreGlValidationCsvInterceptor();

        return items.map(item => alertPreGlValidationCSVInterceptor.transform(item));
      }

      alertService.exportToCSV(
        `alert-gl-validation`,
        {
          query: query,
          fields,
          middleware: [alertPreGlValidationTransformMiddleware],
          fetchDataHandler: (csvQuery) => {
            return this.getPreGLValidationAlerts(alertId, csvQuery);
          },
        }
      );
    });
  }

  selectFirstRow(data?) {
    // just to override base component behaviour
  }

  toggleFilter() {
    this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
    this.tabGroup.forceRerender();
  }

  ngOnDestroy() {
    if (this.sider.getActiveSection().name === this.sideSections.filter) {
      this.toggleFilter()
    }
    this.tabGroup.onActivate.unsubscribe();
  }

  public goToInvoiceDetails() {
    if (this.alert && this.alert.invoice && this.alert.invoice.invoice_id) {
      this.router.navigate(['/invoice', this.alert.invoice.invoice_id, 'show', 'overview']);
    }
  }

  goToRuleExecution() {
    this.router.navigate(['gl-rule-execution', this.alert.invoice.invoice_id, this.alert.vendor.id, this.alert.account_id]);
  }
}
