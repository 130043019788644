import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../core/base.service';
import { Restangular } from 'ngx-restangular';

class Notification {
  id: number
}

@Injectable()
export class NotificationService extends BaseService<Notification> {
  private url = environment.BASE_API_URL;
  private socket;

  constructor(public restangular: Restangular) {
    super('notifications', restangular);
  }

  read(notificationId, isRead) {
    this.service().one(this.name, notificationId).all('read').post({is_read: isRead})
  }

  readAll(isRead = true) {
    this.service().all(this.name).all('read/all').post({is_read: isRead})
  }

  archive(notificationId: number) {
    this.service().one(this.name, notificationId).all('archive').post()
  }

  archiveMany(list: Array<number>) {
    this.service().all(this.name).all('archive/all').post({list: list})
  }

  sendNotification(notification) {
    this.socket.emit('notification', notification);
  }

  listen() {
    return new Observable(observer => {
      this.socket = io(this.url);
      this.socket.on('notification', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }

  listenLocked() {
    return new Observable(observer => {
      this.socket = io(this.url);
      this.socket.on('locked-invoice-notification', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }
}
