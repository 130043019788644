<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-title">{{ (alert?.type?.value || '') + ' Alert ' }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button mat-icon-button matTooltip="Refresh" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [disabled]="!tabGroup.isActive(2)" mat-icon-button (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'" [tooltip]="'Download CSV'"></ca-icon>
  </button>
  <button [disabled]="!tabGroup.isActive(2) || isGridSettingsDisabled" mat-icon-button (click)="gridSettings()">
    <ca-icon [key]="'GRID_SETTINGS'" [tooltip]="'Settings'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [disabled]="!tabGroup.isActive(2)" [disabled]="selection" [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(2)}"  (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="'FILTER'" [tooltip]="'Filter'"></ca-icon>
  </button>
  <button [disabled]="!tabGroup.isActive(2)" #toggleDetailsEnableButton [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
          [disabled]="!selection"
          (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content tabbed-content">


    <mat-tab-group (selectedIndexChange)="onTabChange($event)">
      <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs">
      </mat-tab>
    </mat-tab-group>

    <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(1)}" class="tabs-body" fxLayout="column">
      <div class="flex-container ca-card custom-mat-card-container" fxLayout="row">
        <div fxFlex>
          <mat-card fxFlex fxFlexFill caCard>

        <mat-card-header>
          Alert Info
        </mat-card-header>

        <mat-card-content>
          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Category</label>
              <p>
                {{ alert?.category?.value || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Created At</label>
              <p>
                {{ (alert?.created_at | caDateTime) || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Created By</label>
              <p>
                {{ alert?.creator?.full_name || 'System'}}
              </p>
            </div>
          </div>

          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Owner</label>
              <p>
                {{ alert?.owner?.full_name || PLACEHOLDERS.OWNER_UNASSIGNED }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Priority</label>
              <p>
                {{ alert?.priority?.value || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Status</label>
              <p>
                {{ alert?.status?.value || 'N/A' }}
              </p>
            </div>
          </div>

          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Last Modified At</label>
              <p>
                {{ (alert?.last_modified_at | caDate) || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Closed At</label>
              <p>
                {{ (alert?.closed_at | caDate) || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Message</label>
              <p>
                {{ alert?.message || 'N/A' }}
              </p>
            </div>
          </div>

        </mat-card-content>

      </mat-card>
    </div>
  </div>
</div>

    <mat-sidenav-container [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}" class="tabs-body app-content app-content-list dx-nodata-absolute">

          <dx-data-grid #dataGrid
                        (onSelectionChanged)="onSelectionChanged($event)"
                        [columns]="columns"
                        [dataSource]="accounts"
                        (onCellClick)="onCellClick($event)"
                        [width]="'100%'"
                        [height]="'100%'">

            <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
              <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.currency }}</div>
            </div>

            <div *dxTemplate="let d of 'chargeRateTemplate'">
              <div caGridTooltip class="right">{{  d.value | caNumber }}</div>
            </div>
          </dx-data-grid>

          <ca-pager [gridPager]="defaultGridPager" [pageContext]="alertAccountGridService" [query]="query" class="app-pager ca-default-border-top" (onChange)="onPageChange(query)"></ca-pager>


    </mat-sidenav-container>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                [query]="query"
                [filterService]="alertAccountFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter($event)">
    </new-app-filter>
    <app-alert-account-sneak-peek [account]="selection"
                                 (closeRequested)="sider?.close()"
                                 [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"></app-alert-account-sneak-peek>
  </mat-sidenav>

</mat-sidenav-container>
