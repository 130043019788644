<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Invoices</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button mat-icon-button *permission="{'action':'Run', 'key':'audits'}" (click)="runAudit()" [disabled]="!selectedRows.length || !sameVendorsSelected || isOverLimit">
    <ca-icon [key]="'FIND_REPLACE'" [tooltip]="'Run Audit'"></ca-icon>
  </button>
  <button mat-icon-button
          *permission="'Approve'"
          (click)="approveInvoices()"
          [disabled]="bulkApproveDisabled || isInvoicesOnHold"
  >
    <ca-icon [key]="'APPROVE_INVOICES'" [tooltip]="'Approve Invoices'"></ca-icon>
  </button>
  <button *permission="{'action':'Create', 'key':'gl'}" mat-icon-button (click)="processMultipleInvoices()"
          [disabled]="isProcessButtonDisabled || !reprocessable || processingInProgress">
    <ca-icon [key]="'GL_PROCESS_RULES'" [tooltip]="'Process'"></ca-icon>
  </button>
  <button *permission="{'action':'Modify', 'key':'accounts'}" mat-icon-button (click)="putOnHold()"
          [disabled]="!isOnHoldEnabled"
  >
    <ca-icon key="ON_HOLD" [tooltip]="'On Hold'"></ca-icon>
  </button>
  <button mat-icon-button
        (click)="glOutput()"
        [disabled]="glOutputDisabled || isInvoicesOnHold || isOverLimitGlOutput"
        *permission="{'action':'Create', 'key':'gl'}"
  >
    <ca-icon *ngIf="isOverLimitGlOutput" [key]="'GL_OUTPUT'" [tooltip]="glOutputLimitMessage"></ca-icon>
    <ca-icon *ngIf="!isOverLimitGlOutput" [key]="'GL_OUTPUT'" [tooltip]="batchPaymentIconEnabled ? 'Create Batch Payment' : 'GL Output'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button *permission="'Delete'" mat-icon-button matTooltip="Delete" [disabled]="deleteDisabled || hasDispute" (click)="delete()">
    <ca-icon [key]="'DELETE'"></ca-icon>
  </button>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [disabled]="selectionActive" [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button
  >
    <ca-icon [key]="'FILTER'" [tooltip]="'Filter'"></ca-icon>
  </button>
  <button
    [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
    [disabled]="!selection && !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)"
    (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" mat-icon-button
  >
    <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>
  </button>
</mat-toolbar>
<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">
  <dx-data-grid #grid class="grid-with-selection-badge"
                [columns]="columns"
                [dataSource]="invoices"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onRowClick)="onRowClick($event)"
                (onCellClick)="onCellClick($event)"
  >
    <dxo-selection
      mode="multiple"
      [allowSelectAll]="invoices?.length">
    </dxo-selection>
    <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
      <a class="selection-badge grid-badge"
         matTooltip="Selection"
         (click)="loadSelected()"
         [ngClass]="{'active':selectionActive}"
      >
        {{selectedRows.length}}
      </a>
    </div>
    <div *dxTemplate="let d of 'selectionBadgeCellTemplate'">
    </div>
    <div *dxTemplate="let d of 'onHoldCellTemplate'">
      <div caGridTooltip>{{ d.value ? 'Yes' : 'No' }}</div>
    </div>
    <div *dxTemplate="let d of 'taxExemptCellTemplate'">
      <div caGridTooltip>{{ d.value ? 'Yes' : 'No' }}</div>
    </div>
    <div *dxTemplate="let d of 'numberTemplate'">
      <div caGridTooltip class="right">{{ d.value | caPercent}}</div>
    </div>
    <div *dxTemplate="let d of 'invNumTemplate'">
      <div caGridTooltip>{{d.column.caption}}</div>
    </div>
    <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
      <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency }}</div>
    </div>
    <div *dxTemplate="let d of 'invoiceCurrencySymbolTemplate'">
      <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency }}</div>
    </div>
    <div *dxTemplate="let d of 'dateTemplate'">
      <div caGridTooltip class="right">{{ d.value | caDate }}</div>
    </div>
    <div *dxTemplate="let d of 'invoiceOtherCurrencyTemplate'">
      <div caGridTooltip class="right">{{ invoiceOtherCurrency(d) | caCurrency:exchangeRateService.getConfigCurrency() }}</div>
    </div>
    <div *dxTemplate="let d of 'invoiceTotalAmttobePaid'">
      <div caGridTooltip class="right">{{ (d.data?.total_amt_to_be_paid - +d.data?.withheld_disputes_amount + +d.data?.tot_adjman) | caCurrency:exchangeRateService.getConfigCurrency() }}</div>
    </div>
    <div *dxTemplate="let d of 'receivedAtTemplate'">
      <div caGridTooltip>{{ d.data.header?.received_at | caDateTime }}</div>
    </div>
    <div *dxTemplate="let d of 'batchKeyTemplate'">
      <div caGridTooltip>{{ d.data.batch?.batch_key || ""}}</div>
    </div>
    <div *dxTemplate="let d of 'approvedAtTemplate'">
      <div caGridTooltip>{{ d.data.header?.approved_at | caDateTime }}</div>
    </div>
  </dx-data-grid>
  <ca-pager [gridPager]="defaultGridPager" [pageContext]="invoiceGridService" [query]="query"
            class="app-pager ca-default-border-top" (onChange)="onPageChange(query)"
  ></ca-pager>
  <mat-sidenav #panelSide mode="side" position="end" opened="true">
    <app-filter-container [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                          [query]="query"
                          [filterService]="invoiceFilterService"
                          (filterRequested)="filterData($event)"
                          (clearFilterRequested)="clearFilter()"></app-filter-container>

    <app-invoice-sneak-peek [invoice]="selection"
                            (closeRequested)="sider?.close()"
                            [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
    ></app-invoice-sneak-peek>
  </mat-sidenav>
</mat-sidenav-container>
