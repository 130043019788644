<button
  *permission="{'action':'Create', 'key':'inventory'}"
  mat-icon-button
  [disabled]="uploadDocumentDisablePredicate"  
  (click)="uploadDocumentActionHandler()"
>
    <ca-icon [key]="'FILE_UPLOAD'" [tooltip]="'Upload'"></ca-icon>
</button>

<button
  mat-icon-button
  [disabled]="downloadDocumentDisablePredicate"
  (click)="downloadDocumentActionHandler()"
>
    <ca-icon [key]="'FILE_DOWNLOAD'" [tooltip]="'Download'"></ca-icon>
</button>

<button
  *permission="{'action':'Create', 'key':'inventory'}"
  mat-icon-button
  [disabled]="deleteDocumentDisablePredicate"
  (click)="deleteDocumentActionHandler()"
>
    <ca-icon [key]="'FILE_DELETE'" [tooltip]="'Delete'"></ca-icon>
</button>
