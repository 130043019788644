import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';
import {
  UpdateInvoiceGLStringQuery, UpdateInvoiceGLStringSiderSection,
} from './invoice-gl-string.actions';
import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";

const INVOICE_GL_STRING_STATE_TOKEN = new StateToken<InvoiceGLStringStateModel>('invoice_gl_string');

export interface InvoiceGLStringStateModel extends QueryableStateModel<any> {

}

@State<InvoiceGLStringStateModel>({
  name: INVOICE_GL_STRING_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InvoiceGlStringState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  @Action(UpdateInvoiceGLStringQuery)
  // @ts-ignore
  updateInvoiceGLStringQuery(ctx: StateContext<InvoiceGLStringStateModel>, {options}): void {
    return this.updateQuery(ctx, {options});
  }

  @Action(UpdateInvoiceGLStringSiderSection)
  // @ts-ignore
  updateInvoiceGLStringSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
