import { Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { clone } from 'ramda';

import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import {
  GlStringSelectGridDialogComponent
} from '../../gl-system-rules/shared/gl-string-select-grid-dialog/gl-string-select-grid-dialog.component';
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { TabGroup, TabService } from '../../shared/tabs/tab.service';
import { SystemRuleStore } from '../core/system-rule.store';
import { InventoryCodingComponent } from '../inventory-coding/inventory-coding.component';
import { IActions } from '../shared/actions.interface';
import { SPIDCodingComponent } from '../spid-coding/spid-coding.component';

@Component({
  selector: 'app-gl-system-rule-list',
  templateUrl: './gl-system-rule-list.component.html',
  styleUrls: ['./gl-system-rule-list.component.css']
})
export class GLSystemRuleListComponent implements OnDestroy, OnInit, OnChanges {
  public tabs = 1;
  public tabGroup: TabGroup;
  public triggerReload;

  show = true;
  coding: any;
  strings: Array<any> = [];
  ruleInfo: any;
  saveSpidPending: boolean = false;
  actions: IActions = {
    add: false,
    edit: false,
  };

  public validGLSelection = false;
  activeTabIndex = 0;

  @ViewChild('spidCodingComponent') spidCodingComponent: SPIDCodingComponent;
  @ViewChild('inventoryCodingComponent') inventoryCodingComponent: InventoryCodingComponent;
 
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'gl';

  constructor(public dialogService: DialogService,
              public alertService: AlertService,
              public tabService: TabService,
              private systemRuleStore: SystemRuleStore
  ) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.tabGroup = this.tabService.create();
    this.tabGroup.addTab({ key: 1, title: 'SPID' });
    this.tabGroup.addTab({ key: 2, title: 'Inventory' });
    this.tabGroup.addTab({ key: 3, title: 'Sub Account' });
    this.tabGroup.addTab({ key: 4, title: 'BAN' });
    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe((tab) => {
      this.tabs = tab.key;
      this.triggerReload = null;
      this.coding = null;
      this.strings = [];
      this.ruleInfo = undefined;
      this.show = true;
    });
  }

  onActionsLoaded(actionsPermissions: IActions){
    this.actions = actionsPermissions;
  }

  onSelection(values) {
    this.triggerReload = null;
    this.coding = values.coding;
    this.strings = clone(values.strings);
    this.ruleInfo = values.ruleInfo;
    this.show = values.show;
  }

  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }
  }

  onClear(event) {
    this.show = true;
    this.strings = [];
  }

  ngOnChanges(changes) {
    if (changes.strings && changes.strings.currentValue) {
      this.validGLSelection = this.strings.reduce((sum, item) => {
        return sum + (+item.gl_apportion_pct);
      }, 0) === 100;
    }
  }

  editStrings() {
    this.dialogService.open(GlStringSelectGridDialogComponent, {
      glApportionFieldName: 'gl_apportion_pct',
      selectedStrings: this.strings.map(string => ({
        ...string,
        gl_apportion_pct: string.gl_apportion_pct || 0
      })),
      getLoadData: () => {
        return {
          ruleMeta: this.ruleInfo.meta,
          ruleType: this.ruleInfo.type
        };
      },
    }, { width: '1000px' })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.validGLSelection = result.valid;
          if (result.data) {
            if (this.validGLSelection) {
              this.triggerReload = clone(result.data);
            } else {
              this.alertService.warning('', this.messages.get('SYSTEM_RULE_ERROR'));
            }
          }
        }
      });
  }

  toggleFilter() {
    if (this.tabGroup.isActive(3)) {
      this.spidCodingComponent.toggleFilter();
    } else if (this.tabGroup.isActive(4)) {
      this.inventoryCodingComponent.toggleFilter();
    }
  }

  ngOnDestroy() {
    this.tabGroup.onActivate.unsubscribe();
    this.systemRuleStore.resetState();
  }

  public isEditDisabled(): boolean {
    if(this.saveSpidPending)
      return true;
    
    if (!this.show) {
      if (this.strings.length > 0) {
        return false
      }
    }

    return true
  }

  public onSaveSpid(payload) {
    this.saveSpidPending = payload.pending;
  }

  public isNewDisabled(): boolean {
      if (!this.show) {
        if (this.strings.length === 0) {
          return false
        }
      }
    return true
  }
}
