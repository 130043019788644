import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class DisputeGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.cost.dispute-grid", userSettings);
  }

  defaultColumns = [{
    caption: 'Dispute ID',
    dataField: 'dispute_id',
    width: 150
  },
  {
    caption: 'Dispute Status',
    dataField: 'status_obj.value',
    width: 150
  },
  {
    caption: 'Subclient',
    dataField: 'subclient_ids',
    cellTemplate: "subclientTemplate",
    width: 130,
    csvOutput: false,
    allowSorting: false
  },
  {
    caption: 'Vendor',
    dataField: 'vendor.name',
    width: 130
  },
  {
    caption: 'Last Update',
    dataField: 'disp_stat_dt',
    dataType: 'datetime',
    cellTemplate: "dateTimeTemplate",
    width: 130
  },
  /*{
    caption: 'Filed By',
    dataField: 'user.username',
    width: 130
  },*/
  {
    caption: 'Filed Date',
    dataField: 'created_at',
    cellTemplate: "dateTimeTemplate",
    dataType: 'datetime',
    width: 130
  },
  /*{
   caption: 'Dispute Category',
   dataField: 'category_id',
   width: 130
   },*/
  {
    caption: 'Billed Charges',
    dataField: 'total_amount',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 130
  },
  {
    caption: 'Calculated Charges',
    dataField: 'calculated_amount',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 180
  },
  {
    caption: 'Total Dispute',
    dataField: 'disputed_amount',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 130
  },
  {
    caption: 'Payment Withheld Amount',
    dataField: 'payment_withheld_amt',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    allowSorting: false,
    width: 180
  },
  {
    caption: 'Dispute Awarded',
    dataField: 'dispute_value_awarded',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 130
  },
  {
    caption: 'Payback Amount',
    dataField: 'payback_amount',
    cellTemplate: "invoiceCurrencyTemplate",
    dataType: 'currency',
    alignment: 'right',
    width: 130
  },
  {
    caption: 'Currency',
    dataField: 'currency.currency',
    width: 130
  },
  {
    //cellTemplate: 'withheldCellTemplate',
    //alignment: 'right',
    caption: 'Withheld Status',
    dataField: 'withheld_status_obj.value',
    width: 160,
    transformValue: {
      true: 'Yes',
      false: 'No'
    }
  }];

  /*columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
