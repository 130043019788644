import { Component } from '@angular/core';
import { Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import { DataLockExtendPromptComponent } from './core/data-lock/data-lock-extend-prompt/data-lock-extend-prompt.component';
import { DataLockService } from './core/data-lock/data-lock.service';
import { AlertService } from './shared/alert/alert.service';
import { DialogService } from './shared/dialog/dialog.service';
import { LoaderService } from './shared/loader/loader.service';
import { UserSessionService } from './shared/user-session/services/user-session.service';
import { UserService } from './user/core/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  loading = true;

  constructor(public userService: UserService,
              public router: Router,
              public restangular: Restangular,
              public userSessionService: UserSessionService,
              public loaderService: LoaderService,
              private alert: AlertService) {
    DataLockService.component = DataLockExtendPromptComponent;
    DialogService.component = DataLockExtendPromptComponent;

    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    this.restangular.provider.setErrorInterceptor((response) => {
      if (response.status === 401) {
        this.userSessionService.removeSession();
      }
    });

    function formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    (<any>window).csvAlertProcessingStarted = () => {
      this.alert.info('', 'Download submitted for offline processing');
    };

    (<any>window).csvAlertProcessingFailed = ( maxNumberOfRecords) => {


      this.alert.info('', `${formatNumber(maxNumberOfRecords)} limit exceeded. Filter data or contact support.`);
    };
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loaderService.displayLoader(true);
    }
    if (event instanceof NavigationEnd) {
      this.loaderService.hideLoader(true);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loaderService.hideLoader(true);
    }
    if (event instanceof NavigationError) {
      this.loaderService.hideLoader(true);
    }
  }
}
