import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';


import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { AlertService } from '../../../shared/alert/alert.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { DocumentService } from '../../../shared/document/core/document.service';
import { LoaderService } from '../../../shared/loader/loader.service';
import { ContractService } from '../../core/contract.service';
import {SvcOrderHeader} from "../../core/svc-order-header";
import {ContractInventoryGridService} from '../../core/contract-inventory-grid.service';
import {SvcOrderService} from "../../core/svc-order.service";
import {Inventory} from "../../../inventory/core/inventory";
import {InventoryService} from "../../../inventory/core/inventory.service";
import {InventoryQuery} from "../../../inventory/core/inventory.query";
import Query from "../../../core/query/query";
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";

@Component({
  selector: 'app-contract-manage-dialog',
  templateUrl: './contract-inventory-manage-dialog.component.html',
  styleUrls: ['./contract-inventory-manage-dialog.component.css']
})
export class ContractInventoryManageDialogComponent extends PageManageDialogComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  @ViewChild('inventoryGrid') inventoryGrid: DxDataGridComponent;

  contract;
  inventoryIds;

  isUpdate: boolean;

  public inventoryQuery: InventoryQuery = new InventoryQuery();
  public inventories: Array<Inventory>;
  public columns: Array<any>;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'svc-order';
  public selectedRowsData: Array<object> = [];
  delaySelectionChange: boolean = false;
  public selection = {};
  selectedRows: Array<any> = [];

  constructor(
    public dialog: DialogService,
    public formBuilder: FormBuilder, public alert: AlertService,
    public contractService: ContractService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<ContractInventoryManageDialogComponent>,
    public loaderService: LoaderService,
    public documentService: DocumentService,
    public svcOrderHeaderService: SvcOrderService,
    public inventoryService: InventoryService,
    public inventoryGridService: ContractInventoryGridService,
  ) {
    super(dialogService, dialogRef);

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.isUpdate = !!this.contract.id;

    this.form = this.formBuilder.group({
      vendor_id: [this.contract ? this.contract.vendor_id : '', Validators.required],
      // inventory_id: [this.contract ? '' : '', Validators.required],
      ban: [this.contract ? '' : ''],
      contract_type_id: [this.contract ? '' : ''],
    });

    this.form.controls.ban.valueChanges.subscribe(value => {
      this.selectedRows = [];
    })

    this.columns = this.inventoryGridService.columns();
    this.afterInit();
  }
  findIndexInSelectedRows(id): number {
    for (let i = 0, l = this.selectedRows.length; i < l; i++) {
      if (this.selectedRows[i]['id'] === id) {
        return i;
      }
    }
    return -1;
  }

  onPageChange(query: InventoryQuery) {
    this.loadInventories(query);
  }

  onSelectionChanged(event) {
    if (!this.delaySelectionChange && this.inventories.length) {
      if (event.currentDeselectedRowKeys.length) {
        event.currentDeselectedRowKeys.forEach(item => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index > -1) {
            this.selectedRows.splice(index, 1);
          }
        });
      }

      //** Selected rows */
      if (event.currentSelectedRowKeys.length) {
        event.currentSelectedRowKeys.forEach(item => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index === -1) {
            this.selectedRows.push(item);
          }
        });
      }
    }
  }

  onSubmit({ value, valid }: { value: Inventory, valid: boolean }) {
    let selectedInventoryIds = this.selectedRows.map(inventory => inventory.id)
    if (!valid) {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
    this.create(value,selectedInventoryIds);
  }

  create(value,selectedInventoryIds) {

    this.loaderService.displayLoader();
    this.toggleDialogButtons();

    this.inventoryService.updateContract(selectedInventoryIds,this.contract.id)
      .subscribe((result) => {

        this.loaderService.hideLoader();
      //  this.alert.success('', this.messages.get('CREATE_SUCCESS'));
        this.closeDialog(result, true);

      }, (err) => {
        this.toggleDialogButtons(false);
        this.loaderService.hideLoader();
        this.alert.success('', this.messages.get('CREATE_ERROR'));
      });
  }

  public loadInventories(event) {
    this.delaySelectionChange = true;
    if(event.value || event.where.ban){
      this.inventoryQuery.orderBy =  [['sp_ckt_id', 'ASC']]
      if (this.contract.vendor_id !== null) {
        this.inventoryQuery.where['vendor_id'] = this.contract.vendor_id
      }
      if (event.value) {
        this.inventoryQuery.where['ban'] = event.value;
      }
      let inventoryIds = [];

      this.inventoryService.findAllInventoriesForContract(this.contract.id)
        .subscribe(res => {
          res.forEach(i => {
            inventoryIds.push(i.inventory_id)
          })

          this.inventoryQuery.where['id']= {'$notIn': inventoryIds}

          this.inventoryService.findAllForContract(this.inventoryQuery, this.contract.id)
            .subscribe((result) => {
              let contractsIncluded = [];
              result.items.forEach((inv) => {
                if (inv.contract.length) {
                  inv.contract.forEach(contract => {
                    contractsIncluded.push(contract.id)
                  })
                }
              });
              this.inventories = result.items;
              this.inventoryQuery.total = result.total;
              setTimeout(() => {
                this.delaySelectionChange = false;
                this.inventoryGrid.instance.selectRows(this.selectedRows, false);
              }, 500);
            }, (err) => {
              console.log('inventoryService.findAllForContract error', err);
            });
        })
    }
  }

  cancel() {
    this.svcOrderHeaderService.cancelEdit();
    this.closeDialog();
  }
}
