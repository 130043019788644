import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class ContractDocumentGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super("app.contract-documents.documents-grid", userSettings);
  }

  defaultColumns = [
    {
      dataField: 'contract_schedule.schedule_name',
      caption: 'Contract Schedule Name',
      width: 200,
    },
    {
      dataField: 'document_name',
      caption: 'Doc Name',
      width: 200
    },
    {
      dataField: 'document_type.value',
      caption: 'Type',
      width: 200,
    },
    {
      dataField: 'document_description',
      caption: 'Description',
    }
  ];

}
