import {Observable} from "rxjs";
import {CustomerService} from "./customer.service";
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import Query from "../../core/query/query";
import {map} from "rxjs/operators";

export class CustomerLookupProvider extends IFilterLookupProvider {
  constructor(public customerService: CustomerService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    if (searchQuery && searchQuery.where && searchQuery.where.company && searchQuery.where.company.$ilike) {
      searchQuery.where.company.$ilike = '%' + searchQuery.where.company.$ilike;
    }
    let query = searchQuery? searchQuery : new Query();
    query.orderBy = [['company', 'ASC']];

    return this.customerService.findAll(query).pipe(
    map((result: any) => {
        return {items: result.items.map(entry => {
            return entry.company;
          }), total: result.total};
      }));
  }

  sortEntriesInAsc(entries) {
    return entries.sort((a, b) => {
      let textA = a.company.toUpperCase();
      let textB = b.company.toUpperCase();
      if (textA < textB) {
        return -1;
      } else if (textA > textB) {
        return 1
      }
      return 0;
    });
  }
}
