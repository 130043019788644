import { Injectable } from '@angular/core';
import { ContactLookupProvider } from "../../contact/core/contact-lookup.provider";
import { ContactService } from "../../contact/core/contact.service";
import { ContractService } from "../../contract/core/contract.service";
import { FilterService } from '../../core/filter/filter.service';
import { CustomerService } from "../../customer/core/customer.service";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { UserLookupProvider } from "../../user/core/user-lookup.provider";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { UserService } from "../../user/core/user.service";
import { VendorLookupProvider } from "../../vendor/core/vendor-lookup.provider";
import { VendorService } from "../../vendor/core/vendor.service";
import { orderFilterContext } from './order-filter.context';
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class OrderFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];
  
  constructor(public dictionaryService: DictionaryService,
    public userSettingsService: UserSettingsService,
    public vendorService: VendorService,
    public contractService: ContractService,
    public userService: UserService,
    public customerService: CustomerService,
    public contactService: ContactService) {
    super("app.order.order-filter", orderFilterContext, userSettingsService);
    
    this.addLookupProvider('orderStatus', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.ORDER_STATUS.modelName));
    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('processor', new UserLookupProvider(userService));
    this.addLookupProvider('sales_representative', new ContactLookupProvider(contactService));
    this.addLookupProvider('sales_engineer', new ContactLookupProvider(contactService));
    this.addLookupProvider('approver', new ContactLookupProvider(contactService));
    this.addLookupProvider('requester', new ContactLookupProvider(contactService));
    this.addLookupProvider('order_type', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.ORDER_TYPE.modelName));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
