
import {of as observableOf, Observable} from 'rxjs';
import {IFilterLookupProvider} from "./filter-lookup.provider";

export class DefaultDateRangesLookupProvider extends IFilterLookupProvider {
  constructor(public customRanges: any, public excludeRanges: any, public customRange: boolean) {
    super();
  }

  findAll(): Observable<any> {
    return observableOf(this.customRanges, this.excludeRanges, this.customRange);
  }
}
