<div>
  <mat-form-field fxFlex class="flex-item" hiDensityInput>
    <input
      [(ngModel)]="ordereNoQuery['from']"
      (focusout)="orderNoChange()"
      type="number"
      [min]="0"
      [placeholder]="'From'"
      matInput
      (keydown.enter)="orderNoChange()"
    />
  </mat-form-field>
  <span fxFlex="10px"></span>
  <mat-form-field fxFlex class="flex-item" hiDensityInput>
    <input
      [(ngModel)]="ordereNoQuery['to']"
      (focusout)="orderNoChange()"
      type="number"
      [placeholder]="'To'"
      matInput
      [min]="ordereNoQuery['from']"
      (keydown.enter)="orderNoChange()"
    />
  </mat-form-field>
</div>

<div style="margin-top: -14px !important">
  <span
    class="ca-expansion-panel-header-title"
    style="line-height: 48px; padding-left: 0px !important"
    >Service Number</span
  >
  <div>
    <mat-form-field fxFlex class="flex-item" hiDensityInput>
      <input
        [(ngModel)]="serviceNoQuery['from']"
        (focusout)="serviceNoChange()"
        type="number"
        [min]="0"
        [placeholder]="'From'"
        matInput
        (keydown.enter)="serviceNoChange()"
        [disabled]="isServiceNoDisalbled()"
      />
    </mat-form-field>
    <span fxFlex="10px"></span>
    <mat-form-field fxFlex class="flex-item" hiDensityInput>
      <input
        [(ngModel)]="serviceNoQuery['to']"
        (focusout)="serviceNoChange()"
        type="number"
        [placeholder]="'To'"
        matInput
        [min]="serviceNoQuery['from']"
        (keydown.enter)="serviceNoChange()"
        [disabled]="isServiceNoDisalbled()"
      />
    </mat-form-field>
  </div>
</div>
