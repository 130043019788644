import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class ContractGridService extends GridService {

  constructor(public userSettings: UserSettingsService) {
    super('app.contract.contract-grid', userSettings);
  }

  defaultColumns = [
    {
      dataField: 'vendor_name',
      caption: 'Vendor',
      alignment: 'left',
      width: 300,
      cellTemplate: 'nameTemplate',
      allowSorting: true
    },
    {
      dataField: 'contract_count',
      caption: 'Contracts',
      width: 120,
      alignment: 'right',
      cellTemplate: 'numberTemplate',
      allowSorting: true
    },
    {
      dataField: 'commitment',
      caption: 'Committed Value',
      width: 160,
      dataType: 'currency',
      alignment: 'right',
      cellTemplate: 'currencyTemplate',
      allowSorting: true
    },
    {
      dataField: '',
      caption: '',
      width: 240,
      alignment: 'right',
      allowSorting: false
    }
    /*{
      dataField: 'attainmentSum',
      caption: 'Attained Value',
      width: 300,
      dataType: 'currency',
      cellTemplate: 'currencyTemplate',
    }*/
  ];

}
