import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'ca-pager-size',
  templateUrl: './pager-size.component.html',
})
export class PagerSizeComponent implements OnInit, OnChanges {

  @Output() onChange = new EventEmitter();
  @Input() sizes;
  @Input() gridPager: number;

  public pageSize: number;
  public allowedPageSizes = [20, 50, 100];

  constructor() {
  }

  ngOnInit() {
    this.pageSize = this.gridPager || 20;
    if (this.sizes && this.sizes.length) {
      this.allowedPageSizes = this.sizes;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['gridPager']) {
      this.pageSize = this.gridPager || 20;
    }
  }

  pageSizeChange(event) {
    this.onChange.emit(event.value);
  }

}
