import { Injectable } from '@angular/core';

import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class QuotingGridService extends GridService {
  defaultColumns = [
    {
      caption: 'Quote ID',
      dataField: 'quote_id',
      width: 150
    },
    {
      caption: 'Description',
      dataField: 'quote_description',
      width: 150
    },
    {
      caption: 'Requester',
      dataField: 'requester.full_name',
      width: 150
    },
    {
      caption: 'Customer',
      dataField: 'customer.company',
      width: 150
    },
    {
      caption: 'Request Date',
      dataField: 'request_date',
      dataType: 'date',
      format: this.formatService.date,
      width: 150
    },
    {
      caption: 'Opportunity Type',
      dataField: 'opportunity_type.value',
      width: 150
    },
    {
      caption: 'Sales Engineer',
      dataField: 'sales_engineer.full_name',
      width: 150
    },
    {
      caption: 'Sales Representative',
      dataField: 'sales_representative.full_name',
      width: 150
    },
    {
      caption: 'Status',
      dataField: 'quote_status.value',
      width: 100
    },
    {
      caption: 'Due Date',
      dataField: 'due_date',
      dataType: 'date',
      format: this.formatService.date,
      width: 150
    }
  ];

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super('app.quoting.quoting-grid', userSettings);
  }
  /*columns() {
      let settings = this.userSettings.entrySettings(this.name);
      if (settings && settings.columns) {
          return settings.columns;
      }
      return this.defaultColumns;
  }*/
}
