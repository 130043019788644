import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {Site} from './site';
import {BaseService} from '../../core/base.service';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import Query from '../../core/query/query';

@Injectable()
export class SiteService extends BaseService<Site> {

  constructor(public restangular: Restangular, private entityLock: EntityLockService) {
    super('site', restangular, entityLock);
  }

  findSitesOnly(query?: Query): any {
    let concreteQuery = query || new Query();
    const transformedQuery = concreteQuery.transform()
    return this.service().all(this.name).all('/sites').customGET(null,this.toFilter(transformedQuery));;
  }

  findAllList(query?: Query): any {
    let concreteQuery = query || new Query();
    const transformedQuery = concreteQuery.transform()
    return this.service().all(this.name).all('/list').customGET(null,this.toFilter(transformedQuery));;
  }
}
