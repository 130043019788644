<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Alerts</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button mat-icon-button
          *permission="'Modify'"
          (click)="completeAlerts(selectedRows)"
          [disabled]="!selectedRows.length || isCompleteDisabled(selectedRows)">
    <ca-icon [key]="'APPLY'" [tooltip]="'Complete Selected Alerts'"></ca-icon>
  </button>
  <button mat-icon-button
          *permission="'Modify'"
          (click)="assignAlert(selectedRows)"
          [disabled]="isChangeOwnerDisabled(selectedRows)">
    <ca-icon [key]="'CHANGE_OWNER'" [tooltip]="'Change Owner'"></ca-icon>
  </button>

  <button *permission="{'action':'Create', 'key':'invoice'}"  [disabled]="!(selection && selection.invoice_id)" (click)="goToInvoiceDetails()" mat-icon-button>
    <ca-icon [key]="'VIEW_INVOICE_DETAILS'" [tooltip]="'View Invoice Details'"></ca-icon>
  </button>

  <button mat-icon-button matTooltip="Refresh" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button matTooltip="Filter"
          [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"  (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button #toggleDetailsEnableButton
          [ngClass]="{'on' : sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
          [disabled]="!selection"
          (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">

  <dx-data-grid #grid class="grid-with-selection-badge"
                [columns]="columns"
                [dataSource]="alerts"
                (onRowClick)="onRowClick($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onCellClick)="onCellClick($event)">

    <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
      <a class="selection-badge grid-badge"
         matTooltip="Selection"
         (click)="loadSelected()"
         [ngClass]="{'active':selectionActive}">
        {{selectedRows.length}}
      </a>
    </div>
    <div *dxTemplate="let d of 'selectionBadgeCellTemplate'">
    </div>

    <div *dxTemplate="let d of 'invoiceNumberTemplate'">
      <a [routerLink]="['/invoice', d.data.invoice_id, 'show']"
         class="sneak-peek-title">
        {{ d.data.invoice?.sp_inv_num }}
      </a>
    </div>

    <div *dxTemplate="let d of 'createdAtTemplate'">
      <div caGridTooltip>{{ d.data.created_at | caDateTime }}</div>
    </div>

    <div *dxTemplate="let d of 'lastModifiedAtTemplate'">
      <div caGridTooltip>{{ d.data.last_modified_at | caDateTime }}</div>
    </div>

    <div *dxTemplate="let d of 'closedAtTemplate'">
      <div caGridTooltip>{{ d.data.closed_at | caDateTime }}</div>
    </div>

  </dx-data-grid>

  <ca-pager  [gridPager]="defaultGridPager" [pageContext]="commonAlertGridService"  [query]="query" class="app-pager ca-default-border-top" (onChange)="onPageChange(query)"></ca-pager>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                [query]="query"
                [filterService]="commonAlertFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter()">
    </new-app-filter>
    <ca-common-alert-sneek-peek [alert]="selection"
                                (redirectToChargesRequested)="viewAlertCharges(selectedRows)"
                                (closeRequested)="sider?.close()"
                                [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"></ca-common-alert-sneek-peek>
  </mat-sidenav>

</mat-sidenav-container>
