import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import { PermissionService } from 'app/permissions/core/permission.service';
import { AlertService } from 'app/shared/alert/alert.service';
import { DialogButtonsController } from '../../../core/dialog-buttons-controller.class';
import Query from '../../../core/query/query';
import { GLStringService } from '../../../gl-string/core/gl-string.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { SortingBuilder, SortingService } from '../../../shared/sorting/sorting.service';
import { GLStringSelectGridService } from '../../core/gl-string-select-grid.service';
import { GLStringGridFilterService } from '../gl-string-filter/gl-string-filter.service';

import { FormControl } from "@angular/forms";
import { ConfigService } from "../../../core/config/config.service";

@Component({
  selector: 'app-gl-string-select-grid-dialog',
  templateUrl: './gl-string-select-grid-dialog.component.html',
  styleUrls: ['./gl-string-select-grid-dialog.component.css']
})
export class GlStringSelectGridDialogComponent extends DialogButtonsController implements OnInit {
  public glStrings: Array<any> = [];
  // original / previously assigned strings
  public selectedStrings: Array<any> = [];

  public getLoadData: Function;
  // new strings when user enter apportion
  public assignedGLStrings: Array<any> = [];
  // This name is different in modes, but most common name is default
  public glApportionFieldName = 'apportion_pct';
  public valid = false;
  public isEdited = false;
  public isLoading = false;
  public isObject: any;
  public enabledDynamicAllocationChange: boolean = false;
  public dynamicIncluded = new FormControl();

  public messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'gl';

  @Output() validChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  public columns: Array<any> = [];
  public totalApportion: any = "0";

  public isFilterOpen = false;

  public query = new Query({
    orderBy: [['apportion_pct', 'DESC', 'NULLS LAST'], ['full_string_formatted', 'ASC']]
  });

  public stringsSorting: SortingBuilder;
  public routeConfig: any;
  public editCell;
  public isCleared = false;
  public numberOfDigits: any;

  constructor(public glStringService: GLStringService,
              public gridService: GLStringSelectGridService,
              public dialog: DialogService,
              public sortingService: SortingService,
              public permissionService: PermissionService,
              public alertService: AlertService,
              public glStringGridFilterService: GLStringGridFilterService,
              public dialogRef: MatDialogRef<GlStringSelectGridDialogComponent>,
              public route: ActivatedRoute,
              public configService: ConfigService) {

    super();
    this.stringsSorting = this.sortingService.builder();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

  }

  ngOnInit() {
    if(localStorage.getItem('__numberOfDigits')) {
      this.numberOfDigits = parseInt(localStorage.getItem('__numberOfDigits'));
    }
    this.isLoading = true;

    this.glStringService.findGLSegments()
      .then((data: Array<any>) => {
        let apportionStringCols = [];

        data.forEach((o) => {
          o.caption = o.value;
          o.dataField = [o.system_key, '_obj.segment_value'].join('');
          o.allowEditing = false;
          o.width = '150px';

          let objDesc = Object.assign({}, o);
          objDesc.caption = o.value + ' Description';
          objDesc.dataField = [o.system_key, '_obj.segment_desc'].join('');

          apportionStringCols.push(o);
          apportionStringCols.push(objDesc);
        });

        const loadData = this.getLoadData();
        const isBan = loadData.ruleType === 'ban' ;
        this.columns = this.glStringService.generateDefaultGridColumns(this.numberOfDigits, isBan).concat(apportionStringCols);

        this.gridService.create(this.grid.instance, {
          noDataText: 'No Data'
        });
        this.calculateTotalApportion();
        this.loadGLStrings(this.query);
      });

    if (!this.routeConfig) {
      this.routeConfig = {
        path: ''
      };
    }
  }

  calculateTotalApportion() {
    this.totalApportion = this.glStringService.calculateTotalApportion(this.selectedStrings, this.glApportionFieldName, this.numberOfDigits, this.isCleared)
    this.valid = (parseFloat(this.totalApportion) === 100) || this.isCleared;
  }

  clearApportions() {
    this.permissionService.isAllowed("gl", "DELETE")
      .subscribe(result => {
        if (result) {
          this.isCleared = true ;
          this.processGLStrings(this.glStrings, this.query.total, true);
        } else {
          this.alertService.error('', this.messages.get('GL_NO_PERMISSION_DELETE'));
        }
      });
  }

  // stores apportion string within (original) selectedStrings
  storeApportionStrings(object) {
    this.isEdited = true;
    const id = object.gl_string_id;
    const apportion = object.apportion_pct;
    object[this.glApportionFieldName] = apportion;

    const ids = this.selectedStrings.map((o) => {
      return o.gl_string_id;
    }).filter(item => item);

    const index = ids.indexOf(id);
    // assign new value if exist / or add into selectedStrings
    if (index !== -1) {
      if (apportion && apportion !== 0) {
        this.selectedStrings[index][this.glApportionFieldName] = apportion;
      } else {
        this.selectedStrings.splice(index, 1);
      }
    } else if(!this.selectedStrings.some(item => (item.full_string_formatted === object.full_string_formatted && item.full_string_text === object.full_string_text) )) {
      this.selectedStrings.push(object);
    }
    this.isCleared = false;
    this.selectedStrings = this.selectedStrings.filter(string => string.apportion_pct !== '0');
    this.calculateTotalApportion();
  }

  public processGLStrings(resultset, total, isReset) {
    this.query.total = total;

    let ids = this.selectedStrings.map((o) => {
      return o.gl_string_id;
    });

    if (isReset) {
      this.selectedStrings = [];
    }

    this.glStrings = resultset
      .filter((o) => {
        if (!isReset) {
          o.gl_string_id = o.id;
        }
        let index = ids.indexOf(o.id);
        // set the apportion from the (original) selectedStrings
        if (index >= 0) {
          o[this.glApportionFieldName] = this.selectedStrings[index][this.glApportionFieldName];
          o['apportion_pct'] = this.selectedStrings[index][this.glApportionFieldName];
        } else {
          o[this.glApportionFieldName] = 0;
          o.apportion_pct = 0;
        }
        if (isReset) {
          o[this.glApportionFieldName] = '0.00';
          o.apportion_pct = 0;
        }
        o.id = null;
        return o;
      });

    this.calculateTotalApportion()
  }

  public loadGLStrings(query) {
    query.where = {
      ...query.where,
      ...{status: true}
    }
    const loadData = this.getLoadData(query);
    this.isLoading = true;
    // get strings for grid, apportion on top
    this.glStringService.findAllWithApportions({
      ...loadData,
      query
    }).subscribe((result) => {
      this.isLoading = false;
      this.enabledDynamicAllocationChange && this.dynamicIncluded.patchValue(this.selectedStrings.length === 0 ? true : this.selectedStrings[0].dynamic_included);
      this.processGLStrings(result.items, result.total, false);
    });
  }

  onSelectedStringsRowEdit(event) {
    this.storeApportionStrings(event.key);
  }

  onSelectedStringsRowValidating(event) {}

  toggleFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  filterData(query) {
    this.loadGLStrings(query);
    this.query = query;
  }

  clearFilter() {
    this.query.where = { status: true }
    this.loadGLStrings(this.query);
  }

  public onCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.stringsSorting.apply(event, this.query);
      this.loadGLStrings(this.query);
    }
  }

  public refresh() {
    this.loadGLStrings(this.query);
  }

  private isEditCellBlured(event) {
    return this.editCell &&
      !event.isEditing &&
      this.editCell.columnIndex === event.columnIndex &&
      this.editCell.rowIndex === event.rowIndex;
  }

  handleCellPrepared(event) {
    if (event.isEditing) {
      this.editCell = {
        rowIndex: event.rowIndex,
        columnIndex: event.columnIndex
      };
    } else if (this.isEditCellBlured(event)) {
      this.editCell = undefined;
    }
  }

  close() {
    this.toggleDialogButtons(false);
    if (this.isEdited) {
      this.dialog.save({
        bodyText: `Discard changes`
      }).afterClosed()
        .subscribe((result) => {
          if (result) {
            this.toggleDialogButtons(false);
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close({valid: this.valid});
    }
  }

  confirm() {
    this.toggleDialogButtons();
    const items = this.selectedStrings.filter((o) => {
      return o[this.glApportionFieldName] !== 0;
    }).map((item) => {
      return this.enabledDynamicAllocationChange ? {...item, dynamic_included: !!this.dynamicIncluded.value} : item
    })
    this.dialogRef.close(
      {data: items, valid: this.valid}
    );
  }
}
