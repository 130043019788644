import {Injectable} from '@angular/core';
import {GridService} from "../../shared/grid/grid.service";
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class InventoryBillingGridService extends GridService {

  defaultColumns = [
    {
      caption: "",
      cellTemplate: "chargeDisputeTemplate",
      fixed: true,
      width: 57,
      allowSorting: false
    },
    {
      caption: "Vendor",
      dataField: "vendor.name",
      width: 120,
      index: 1
    }, {
      caption: "Account",
      dataField: "acct_level_1",
      width: 150,
      index: 2
    }, {
      caption: "Invoice Number",
      dataField: "invoice.sp_inv_num",
      width: 170,
      index: 3
    },
    {
      caption: "Invoice Date",
      dataField: "invoice.inv_date",
      dataType: "date",
      cellTemplate: "dateTemplate",
      alignment: 'left',
      width: 120,
      index: 4
    },
    {
      caption: "Charge Type",
      dataField: "chg_class",
      width: 120,
      index: 5
    },
    {
      caption: "Service ID",
      dataField: "sp_serv_id",
      width: 150,
      index: 6
    },
    {
      caption: "Charge Code 1",
      dataField: "chg_code_1",
      width: 120,
      index: 7
    },
    {
      caption: "Charge Description 1",
      dataField: "chg_desc_1",
      width: 170,
      index: 8
    },
    {
      caption: "Charge Code 2",
      dataField: "chg_code_2",
      width: 120,
      index: 9
    },
    {
      caption: "Charge Description 2",
      dataField: "chg_desc_2",
      width: 170,
      index: 10
    },
    {
      alignment: 'right',
      caption: "Chg Qty",
      dataField: "chg_qty1_billed",
      // dataType: 'number',
      cellTemplate: 'chargeRateTemplate',
      csvDataType: 'float',
      width: 120,
      index: 11
    }, {
      caption: "Charge Rate",
      dataField: "chg_rate",
      cellTemplate: 'chargeRateTemplate',
      csvDataType: 'float',
      alignment: 'right',
      width: 120,
      index: 12
    }, {
      caption: "Charge Amount",
      dataField: "chg_amt",
      cellTemplate: "currencyTemplate",
      dataType: 'currency',
      alignment: 'right',
      width: 130,
      index: 13
    }, {
      caption: "Install Date",
      dataField: "svc_establish_date",
      dataType: 'date',
      cellTemplate: "dateTemplate",
      alignment: 'left',
      width: 120,
      index: 14
    }, {
      caption: "Beg Charge Date",
      dataField: "beg_chg_date",
      dataType: 'date',
      cellTemplate: "dateTemplate",
      alignment: 'left',
      width: 150,
      index: 15
    }, {
      caption: "End Charge Date",
      dataField: "end_chg_date",
      dataType: 'date',
      cellTemplate: "dateTemplate",
      alignment: 'left',
      width: 150,
      index: 16
    }, {
      caption: "Info Only Ind",
      dataField: "info_only_ind",
      width: 150,
      index: 17
    }, {
      caption: "Facility Bandwidth",
      dataField: "fac_bw",
      width: 150,
      index: 18
    }, {
      caption: "Call Type",
      dataField: "call_type",
      width: 120,
      index: 19
    }, {
      caption: "Dir Ind",
      dataField: "dir_ind",
      width: 120,
      index: 20
    }, {
      caption: "Jurisdiction",
      dataField: "jur",
      width: 100,
      index: 21
    }, {
      caption: "Chg Qty Type",
      dataField: "chg_qty1_type",
      width: 120,
      index: 22
    }, {
      alignment: 'right',
      caption: "Chg Qty Used",
      dataField: "chg_qty1_used",
      cellTemplate: 'chargeRateTemplate',
      // dataType: 'number',
      csvDataType: 'float',
      width: 120,
      index: 23
    }, {
      caption: "Chg Qty Allowed",
      dataField: "chg_qty1_allowed",
      dataType: 'string',
      width: 140,
      index: 24
    }
    ];

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super("app.inventory.inventory-billing-grid", userSettings);
  }
}
