import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SortableComponent} from 'ng2-dnd';

import {AccountService} from '../../../account/core/account.service';
import {AppService} from '../../../app.service';
import {ReconExcludedService} from '../../../recon/core/recon-excluded.service';
import {ReconService} from '../../../recon/core/recon.service';
import {User} from '../../../user/core/user';
import {UserSettingsService} from '../../../user/core/user-settings.service';
import {VendorService} from '../../../vendor/core/vendor.service';
import Query from '../../query/query';
import {QueryParamType} from '../../query/query-param-type';
import {FilterEntry} from '../filter-entry';
import {NetworkHubLevel1Level2Component} from '../network-hub-l1-l2-filter/network-hub-l1-l2-filter.component';
import {OrderNoServiceNoFilterComponent} from '../order-no-service-no-filter/order-no-service-no-filter.component';

@Component({
  // tslint:disable-next-line
  selector: "new-filter-input",
  templateUrl: './new-filter-input.component.html',
  styleUrls: ['./new-filter-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewFilterInputComponent),
      multi: true
    },
    ReconService,
    ReconExcludedService
  ]
})
export class NewFilterInputComponent
  implements OnInit, OnChanges, ControlValueAccessor, AfterViewInit {
  @Input() query: Query;
  @Input() context: any;
  @Input() initialDateRange: any;
  @Input() entryInterceptor: any;
  @Input() fetchReconQuery: boolean;
  @ViewChild('dateRangeSelect') dateRangeSelect;
  @ViewChild('tagInputAsync') tagInputAsync;

  // filters
  @ViewChild('orderNoServiceNoComboFilter')
  orderNoServiceNoComboFilter: OrderNoServiceNoFilterComponent;

  @ViewChild('networkhubLevel1Level2Filter')
  networkhubLevel1Level2Filter: NetworkHubLevel1Level2Component;

  @ViewChildren(SortableComponent) draggables: QueryList<SortableComponent>;

  @Output() numbersDidNotPassValidation: EventEmitter<boolean> = new EventEmitter();

  private clone: HTMLElement;

  dragOverIndex: number;
  draggingFilter: any;
  draggingIndex: any;

  internalStringQuery = [];
  internalBooleanQuery = {};
  internalLookupInput = [];

  internalMultiselectInput = {
    All: true
  };

  internalNumberQuery: any = {
    from: null,
    to: null
  };

  internalDateQuery: any = {
    from: '',
    to: '',
    customRange: true
  };

  internalDateRange = null;

  public isSorting = false;

  internalDropdownInput = null;

  internalAddressQuery: any = {
    address1: '',
    city: '',
    subdivision: null,
    postal_code: ''
  };

  readonly ADDRESS_FIELDS = {
    ADDRESS1: 'address1',
    CITY: 'city',
    SUBDIVISION: 'subdivision',
    POSTAL_CODE: 'postal_code'
  };

  internalContractActiveQuery: any = {active: true, expired: false};

  internalChargeVendor = null;
  internalChargeAccount = null;

  internalChargeAccountList = [];
  tagInputValue: any;

  internalReconVendor = null;
  internalReconAccount = null;

  internalReconAccountList = [];

  internalReconExclusionVendor = null;

  internalReconExclusionVendorList = [];
  internalReconExclusionAccountList = [];

  internalReconUnlinkedVendor = null;
  internalReconUnlinkedAccount = null;

  internalReconUnlinkedVendorList = [];
  internalReconUnlinkedAccountList = [];

  fromNumberError: boolean;
  fromNumberErrorText: string;

  toNumberError: boolean;
  toNumberErrorText: string;

  filterCards: FilterEntry[] = [];
  elementBeingDragged: any;
  fullelement: any;
  elementToDrop: any;

  constructor(
    public vendorService: VendorService,
    public accountService: AccountService,
    public reconService: ReconService,
    public reconExclusionService: ReconExcludedService,
    public userSettings: UserSettingsService
  ) {
    this.filterCards = [];
  }

  dragStart() {
    this.isSorting = true;
  }

  ngAfterViewInit() {
    this.draggables.changes.subscribe(() => {
      this.draggables.forEach(ref => {
        ref._elem.addEventListener(
          'dragstart',
          event => {
            this.isSorting = true;
            this.clone = <HTMLElement>((<HTMLElement>event.srcElement).children[0].cloneNode(true));
            this.clone.id = 'clone';
            this.clone.classList.remove('dnd-sortable-drag');
            this.clone.classList.add('clone');
            document.body.appendChild(this.clone);
            event.dataTransfer.setDragImage(this.clone, 0, 0);
          },
          false
        );
        ref._elem.addEventListener(
          'dragend',
          () => {
            this.isSorting = false;
            let t = document.getElementById('clone');
            if (t) {
              t.remove();
            }
          },
          false
        );
      });
    });
  }

  public AddFilterCard(filterCard) {
    if (this.filterCards.indexOf(filterCard) === -1) {
      filterCard.isActiveCard = true;
      filterCard.expanded = true;
      this.filterCards.unshift(filterCard);
      this.saveFilters();
      this.initEntry([filterCard]);
    }
  }

  public RemoveFilterCard(filterCard) {
    let index: number = this.filterCards.indexOf(filterCard);
    if (index >= 0) {
      filterCard.isActiveCard = false;
      this.filterCards.splice(index, 1);
      this.RemoveFilterFromQuery(filterCard.field);
      this.saveFilters();
    }
  }

  public RemoveFilterFromQuery(field) {
    // tslint:disable-next-line
    for (let property in this.query.where) {
      if (property === field) {
        delete this.query.where[field];
      }

      // TODO Put special cases here when field name is not same as property name in where object.
      if (property === 'vendor_id' || property === 'invoice.acct_level_1') {
        if (field === 'vendor_and_account') {
          delete this.query.where[property];
        }
      }
    }
    this.isSorting = false;
  }

  sortChange() {
    this.saveFilters();
  }

  saveFilters() {
    let filterSet = [];
    for (let i = 0; i < this.filterCards.length; i++) {
      filterSet.push({
        index: this.filterCards[i].index,
        field: this.filterCards[i].field,
        fieldToFilterWith: this.filterCards[i].fieldToFilterWith,
        secondRowField: this.filterCards[i].secondRowField,
        expanded: this.filterCards[i].expanded,
        caseSensitive: this.filterCards[i].caseSensitive,
        editor: this.filterCards[i].editor,
        exact: this.filterCards[i].exact,
        isActiveCard: this.filterCards[i].isActiveCard,
        label: this.filterCards[i].label,
        lookupProviderName: this.filterCards[i].lookupProviderName,
        exactMatch: this.filterCards[i].exactMatch,
        matchCase: this.filterCards[i].matchCase,
        trueLabel: this.filterCards[i].trueLabel,
        falseLabel: this.filterCards[i].falseLabel,
        max: this.filterCards[i].max,
        defaultValue: this.filterCards[i].defaultValue,
        type: this.filterCards[i].type,
        visible: this.filterCards[i].visible,
        vendorFields: this.filterCards[i].vendorFields ? {
          vendor: {
            label: this.filterCards[i].vendorFields.vendor.label,
            field: this.filterCards[i].vendorFields.vendor.field,
          },
          account: {
            label: this.filterCards[i].vendorFields.account.label,
            field: this.filterCards[i].vendorFields.account.field,
          }
        } : null,
        sitesFields: this.filterCards[i].sitesFields
          ? {
            site: {
              label: this.filterCards[i].sitesFields.site.label,
              field: this.filterCards[i].sitesFields.site.field
            },
            building: {
              label: this.filterCards[i].sitesFields.building.label,
              field: this.filterCards[i].sitesFields.building.field
            },
            address1: {
              label: this.filterCards[i].sitesFields.address1.label,
              field: this.filterCards[i].sitesFields.address1.field
            },
            city: {
              label: this.filterCards[i].sitesFields.city.label,
              field: this.filterCards[i].sitesFields.city.field
            },
            subdivision: {
              label: this.filterCards[i].sitesFields.subdivision.label,
              field: this.filterCards[i].sitesFields.subdivision.field,
              lookupProviderName: this.filterCards[i].sitesFields.subdivision
                .lookupProviderName
            },
            postal_code: {
              label: this.filterCards[i].sitesFields.postal_code.label,
              field: this.filterCards[i].sitesFields.postal_code.field
            },
            vendor: {
              label: this.filterCards[i].sitesFields.vendor.label,
              field: this.filterCards[i].sitesFields.vendor.field
            }
          }
          : null,

        addressFields: this.filterCards[i].addressFields
          ? {
            address1: {
              label: this.filterCards[i].addressFields.address1.label,
              field: this.filterCards[i].addressFields.address1.field
            },
            city: {
              field: this.filterCards[i].addressFields.city.field,
              label: this.filterCards[i].addressFields.city.label
            },
            subdivision: {
              field: this.filterCards[i].addressFields.subdivision.field,
              label: this.filterCards[i].addressFields.subdivision.label,
              lookupProviderName: this.filterCards[i].addressFields
                .subdivision.lookupProviderName
            },
            postal_code: {
              field: this.filterCards[i].addressFields.postal_code.field,
              label: this.filterCards[i].addressFields.postal_code.label
            }
          }
          : null,
        topUsage: this.filterCards[i].topUsage
      });
    }

    this.context.service
      .saveSettings({filterSet: filterSet}).subscribe(() => {});
  }

  switchExpand(entry) {
    entry.expanded = !entry.expanded;
    this.saveFilters();
  }

  LoadFilter() {
    setTimeout(() => {
      if (this.context) {
        this.context.service.create().subscribe(result => {
          if (result && result.filterSet) {
            this.filterCards = result.filterSet;
            this.initEntry(this.filterCards);
          }
        });
      }
    });
  }

  propagateChange = (_: any) => {
  };

  writeValue(value: any): void {
    this.query = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  initEntry(filterCards) {
    if (filterCards) {
      filterCards.forEach(entry => {
        if (entry.lookupProvider && entry.type === QueryParamType.Lookup) {
          entry.lookupProvider.findAll().subscribe((result: Array<any>) => {
            entry.lookupItems = result;
          });
        }

        /* address and subdivision lookup provider */
        if (
          entry.addressFields &&
          entry.addressFields.subdivision &&
          entry.addressFields.subdivision.lookupProvider
        ) {
          entry.addressFields.subdivision.lookupProvider
            .findAll()
            .subscribe((result: Array<any>) => {
              entry.addressFields.subdivision.lookupItems = result;
            });
        }

        /* sitesFields and subdivision lookup provider */
        if (
          entry.sitesFields &&
          entry.sitesFields.subdivision &&
          entry.sitesFields.subdivision.lookupProvider
        ) {
          entry.sitesFields.subdivision.lookupProvider
            .findAll()
            .subscribe((result: Array<any>) => {
              entry.sitesFields.subdivision.lookupItems = result;
            });
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entry']) {
      let entry: FilterEntry = <FilterEntry>changes['entry'].currentValue;

      if (entry.lookupProvider && entry.type === QueryParamType.Lookup) {
        entry.lookupProvider.findAll().subscribe((result: Array<any>) => {
          entry.lookupItems = result;
        });
      }

      /* address and subdivision lookup provider */
      if (
        entry.addressFields &&
        entry.addressFields.subdivision &&
        entry.addressFields.subdivision.lookupProvider
      ) {
        entry.addressFields.subdivision.lookupProvider
          .findAll()
          .subscribe((result: Array<any>) => {
            entry.addressFields.subdivision.lookupItems = result;
          });
      }

      /* sitesFields and subdivision lookup provider */
      if (
        entry.sitesFields &&
        entry.sitesFields.subdivision &&
        entry.sitesFields.subdivision.lookupProvider
      ) {
        entry.sitesFields.subdivision.lookupProvider
          .findAll()
          .subscribe((result: Array<any>) => {
            entry.sitesFields.subdivision.lookupItems = result;
          });
      }
    }

    if (changes['query'] && changes['query'].currentValue) {
      let query = changes['query'].currentValue;
      query['where'] = query['where'] || {};

      this.internalStringQuery = [];
      this.tagInputValue = '';
      this.internalLookupInput = [];
      this.internalNumberQuery = {
        from: null,
        to: null
      };
      this.internalMultiselectInput = {
        All: true
      };

      // TODO: implement for other 'types' - solution for initial value for filter entry - implemented only for text input

      this.filterCards.forEach(entry => {
        Object.keys(query.where).forEach(key => {
          if (entry['field'] === key) {
            if (entry['type'] === QueryParamType.String) {
              this.internalStringQuery[entry.field] = query.where[key];
            } else if (entry['type'] === QueryParamType.Lookup) {
              // TODO refactor this
              let value = query.where[key];
              let operator;
              if (typeof value === 'object') {
                operator = Object.keys(value)[0];
                value = value[operator];
              }
              if (operator === '$in') {
                value.forEach(val => {
                  this.internalMultiselectInput[val] = true;
                });
              } else {
                this.internalMultiselectInput[value] = value != null;
              }
              this.internalMultiselectInput['All'] =
                Object.keys(this.internalMultiselectInput).length === 0;
              if (entry && entry.defaultValue) {
                entry.defaultValue.forEach(val => {
                  this.internalMultiselectInput[val] = true;
                });
              }
            } else if (entry['type'] === QueryParamType.Boolean) {
              if (entry && entry.defaultValue) {
                entry.defaultValue.forEach(val => {
                  this.internalBooleanQuery[val] = true;
                });
              }
            }
          }
        });
      });

      this.clearInternalDateQuery();
      if (Object.keys(query.where).length === 0) {
        this.internalMultiselectInput = {
          All: true
        };
      }
      // resets default value to No
      this.internalBooleanQuery = {
        true: false,
        false: false
      };

      this.internalDropdownInput = null;
      this.internalAddressQuery = {
        address1: '',
        city: '',
        subdivision: null,
        postal_code: ''
      };
      this.internalContractActiveQuery = {active: true, expired: false};
      this.internalChargeVendor = null;
      this.internalChargeAccount = null;

      this.internalReconVendor = null;
      this.internalReconAccount = null;

      this.internalReconExclusionVendor = null;

      this.internalReconUnlinkedVendor = null;
      this.internalReconUnlinkedAccount = null;

      this.toNumberError = false;
      this.toNumberErrorText = '';

      this.fromNumberError = false;
      this.fromNumberErrorText = '';

      this.numbersDidNotPassValidation.emit(false);

      // this.loadAccounts();
      // this.loadReconAccounts();
      // this.loadReconUnlinkedAccounts();
    }

    if (
      changes['context'] &&
      changes['context'].currentValue &&
      !changes['context'].firstChange &&
      !changes['query'] &&
      !changes['entry']
    ) {
      if (changes['context'].currentValue.filterSet) {
        this.filterCards = changes['context'].currentValue.filterSet;
        this.initEntry(this.filterCards);
      }
    }

    if (changes['initialDateRange']) {
      this.internalDateQuery = changes['initialDateRange'].currentValue;
    }
  }

  toggleExact(entry, index, addressEntry?: boolean) {
    entry.exact = !entry.exact;
    addressEntry ? this.ngOnAddressChange(entry) : this.ngOnStringChange(entry);
  }

  toggleCase(entry, index, addressEntry?: boolean) {
    entry.caseSensitive = !entry.caseSensitive;
    addressEntry ? this.ngOnAddressChange(entry) : this.ngOnStringChange(entry);
  }

  ngOnStringChange(entry) {
    this.setStringQuery(
      this.internalStringQuery[entry.field],
      entry['field'],
      entry.caseSensitive,
      entry.exact
    );
  }

  ngOnNumberChange(entry) {
    this.query.where[entry.field] = this.internalStringQuery[entry.field];
  }

  onAddressQueryChange(query: Query) {
    if (query) {
      this.query.where = {
        ...this.query.where,
        ...query.where
      }
    }
  }

  onCountryQueryChange(query: Query) {
    if (query) {
      this.query.where = {
        ...this.query.where,
        ...query.where
      }
    }
  }

  ngOnAddressChange(entry) {
    // tslint:disable-next-line
    for (let i in this.ADDRESS_FIELDS) {
      let addressField = this.ADDRESS_FIELDS[i];
      let value = this.internalAddressQuery[addressField];
      if (value) {
        if (addressField === this.ADDRESS_FIELDS.SUBDIVISION) {
          this.query.where[entry.addressFields[addressField]['field']] = value;
        } else {
          this.setStringQuery(
            value,
            entry.addressFields[addressField]['field'],
            entry.caseSensitive,
            entry.exact
          );
        }
      } else {
        delete this.query.where[entry.addressFields[addressField]['field']];
      }
    }
  }

  handleTopUsageChange(query) {
    //this.query = query;
  }

  setStringQuery(
    value: string,
    field: string,
    caseSensitive: boolean,
    exactMatch
  ) {
    let operator = caseSensitive ? '$like' : '$ilike';
    let suffix = exactMatch ? '' : '%';
    let prefix = exactMatch ? '' : '%';

    if (value) {
      value = value.trim();
      this.query.where[field] = this.query.where[field] || {};
      delete this.query.where[field]['$ilike'];
      delete this.query.where[field]['$like'];
      this.query.where[field][operator] = prefix + value + suffix;
    } else {
      delete this.query.where[field];
    }
  }

  ngOnDateChange(event) {
    this.query = event;
  }

  clearInternalDateQuery() {
    this.internalDateQuery = {
      from: null,
      to: null,
      customRange: false
    };
    this.internalDateRange = null;
  }

  ngOnContractActiveChange() {
    setTimeout(() => {
      this.query.where['is_active'] = this.internalContractActiveQuery;
    }, 50);
  }

  ngOnInit() {
    if(this.fetchReconQuery) {
      // this.loadAccounts();
      // this.prepareDataForReconFilter();
      // this.loadReconUnlinkedAccounts();
      this.LoadFilter();
    } else {
      this.LoadFilter();
    }
  }


  prepareDataForReconFilter() {
    if (this.query && this.query.where && this.query.where['info_only_ind']) {
      if (this.query.where['info_only_ind']['$in']) {
        this.query.where['info_only_ind']['$in'].forEach(bool => {
          this.internalBooleanQuery[bool] = true;
        });
      }
    }
    const vendorQuery = new Query({
      limit: 100000,
    });
    this.reconExclusionService.findAll(vendorQuery).subscribe(result => {
      let vendors = [];
      let accounts = [];

      result.items.forEach(item => {
        let existing = vendors.filter(vendor => {
          return vendor.id === item.vendor_id;
        })[0];

        if (existing) {
          return;
        }
        vendors.push({
          id: item.vendor_id,
          name: item.vendor.name,
          acct_level_1: item.acct_level_1
        });
      });

      result.items.forEach(item => {
        let existing = accounts.filter(account => {
          return account.acct_level_1 === item.acct_level_1;
        })[0];

        if (existing) {
          return;
        }
        accounts.push({
          id: item.vendor_id,
          acct_level_1: item.acct_level_1
        });
      });
      this.internalReconExclusionVendorList = vendors;
      this.internalReconExclusionAccountList = accounts;

      this.loadReconAccounts();
    });
    this.reconService
      .fetchUnlinkedInventoryVendors(100000)
      .subscribe(result => {
        this.internalReconUnlinkedVendorList = result.items;
      });
  }

  ngOnChargeVendorChange(event) {
    this.query.where['vendor_id'] = event;

    this.internalChargeAccountList = [];

    this.loadAccounts(event);
  }

  ngOnReconVendorChange(event) {
    this.query.where['vendor_id'] = event;

    this.internalReconAccountList = [];

    this.loadReconAccounts(event);
  }

  ngOnReconUnlinkedVendorChange(event) {
    this.query.where['c.vendor_id'] = event;

    this.internalReconUnlinkedAccountList = [];

    this.loadReconUnlinkedAccounts(event);
  }

  ngOnChargeAccountChange(event) {
    this.query.where['invoice.acct_level_1'] = event;
  }

  ngOnReconAccountChange(event) {
    this.query.where['acct_level_1'] = event;
  }

  ngOnReconUnlinkedAccountChange(event) {
    this.query.where['c.acct_level_1'] = event;
  }

  loadAccounts(vendorId?: number) {
    let query = new Query({
      vendor_ids: []
    });
    if (vendorId) {
      query.where = query.where || {};
      query['vendor_ids'] = [vendorId];
    }
    this.accountService.findAccountsWithInvoices(query).subscribe(results => {
      this.internalChargeAccountList = results;
    });
  }

  loadReconAccounts(vendorId?: number) {
    if (vendorId) {
      this.internalReconAccountList = this.internalReconExclusionAccountList.filter(
        vendor => vendor.id === vendorId
      );
    } else {
      this.internalReconAccountList = [
        ...this.internalReconExclusionAccountList
      ];
    }
  }

  loadReconUnlinkedAccounts(vendorId?: number) {
    if (vendorId) {
      this.reconService
        .fetchUnlinkedInventoryAccounts(100000, vendorId)
        .subscribe(result => {
          this.internalReconUnlinkedAccountList = result.items;
        });
    }
  }

  /* ***** */
  onSitesFilterChanged(query: Query) {
    this.query = query;
  }

  // TODO: unify into internalFilterInputQueryChanged

  onTagFilterQueryChanged(query: Query) {
    this.query = query;
  }

  public isExactVisible(entry: FilterEntry) {
    return (
      entry.exactMatch === true ||
      entry.exactMatch === null ||
      entry.exactMatch === undefined
    );
  }

  public isMatchCaseVisible(entry: FilterEntry) {
    return (
      entry.matchCase === true ||
      entry.matchCase === null ||
      entry.matchCase === undefined
    );
  }

  public mustClearFilters(): void {
    if (this.orderNoServiceNoComboFilter) {
      this.orderNoServiceNoComboFilter.clearFilter();
    }

    if (this.networkhubLevel1Level2Filter) {
      this.networkhubLevel1Level2Filter.clearFilter();
    }
  }

  // runs when user starts to drag
  public handleDragStart(entry, index) {
    this.draggingFilter = entry;
    this.draggingIndex = index;
    this.isSorting = true;
  }

  // handles when user drops filter card over another filter card
  public handleDrop(evt, entry, index) {
    if (evt.stopPropagation) {
      evt.stopPropagation(); // Stops some browsers from redirecting.
    }
    this.dragOverIndex = null;
    this.draggingIndex = null;
    this.isSorting = false;
    this.saveFilters();
  }

  // runs while user is dragging
  public handleDragOver(entry, index) {
    if (this.dragOverIndex === index) {
      return;
    }
    this.dragOverIndex = index;
    const insertPoint = index + (this.draggingIndex < index ? 1 : 0);
    this.filterCards.splice(insertPoint, 0, this.draggingFilter);
    const oldPlace = this.filterCards.findIndex(
      (filter, filterIndex) =>
        index !== filterIndex && filter.field === this.draggingFilter.field
    );
    this.filterCards.splice(oldPlace, 1);
    this.draggingIndex = index;
  }

  // runs when user drops filter card onto drop element
  // drop element is contained in new-filter-picker
  public removeFilterCard(e: any) {
    const panelDragged = this.filterCards.find(
      card => card.field === this.draggingFilter.field
    );
    this.RemoveFilterCard(panelDragged);
    this.isSorting = false;
    this.dragOverIndex = null;
  }
}
