import { Injectable } from '@angular/core';

import { GridService } from '../../shared/grid/grid.service';
import { UserSettingsService } from '../../user/core/user-settings.service';


@Injectable()
export class SiteGridService extends GridService {
  defaultColumns = [{
    caption: 'Site Name',
    dataField: 'site_id',
    width: 220
  }, {
    caption: 'Type',
    dataField: 'type.site_type.value',
    width: 150
  }, {
    caption: 'Entity',
    width: 150,
    dataField: 'entity'
  }, {
    caption: 'Site Owner Type',
    dataField: 'type.value',
    width: 170
  },{
    dataField: 'building.address.search_address',
    caption: 'Building',
    alignment: 'left',
    cellTemplate: 'buildingTemplate',
    width: 200
  }, {
    caption: 'Address',
    dataField: 'building.address.address1',
    width: 200
  }, {
    caption: 'City',
    dataField: 'building.address.city',
    width: 150
  }, {
    caption: 'Subdivision',
    dataField: 'building.address.subdivision.abbreviation',
    width: 150
  }, {
    caption: 'Postal Code',
    dataField: 'building.address.postal_code',
    width: 100
  }, {
    caption: 'Country',
    dataField: 'building.address.country.alpha_2_code',
    width: 100
  }, {
    alignment: 'right',
    caption: 'Floor',
    dataField: 'floor',
    width: 80
  }, {
    alignment: 'right',
    caption: 'Room',
    dataField: 'room',
    width: 80
  },
  ];

  constructor(public userSettings: UserSettingsService) {
    super('app.site.site-grid', userSettings);
  }
}
