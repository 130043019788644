import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisputeRoutingModule } from './dispute-routing.module';
import { DisputeComponent } from './dispute.component';
import { DisputeListComponent } from './dispute-list/dispute-list.component';
import { DisputeService } from './core/dispute.service';
import { DxDataGridModule} from 'devextreme-angular/ui/data-grid';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTemplateModule } from "devextreme-angular";

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DisputeManageDialogComponent } from './shared/dispute-manage-dialog/dispute-manage-dialog.component';
import { DisputeSneakPeekComponent } from './shared/dispute-sneak-peek/dispute-sneak-peek.component';
import { DisputeGridService } from './core/dispute-grid.service';
import { DisputeDetailsComponent } from './dispute-details/dispute-details.component';
import { DisputeChargesGridService } from './core/dispute-charges-grid.service';
import { SharedModule } from '../shared/shared.module';
import { CoreModule} from "../core/core.module";
import { DisputeFilterService } from "./core/dispute-filter.service";
import { InvoiceChargeService } from '../charge/core/invoice-charge.service';
import { DictionaryService } from '../dictionary/core/dictionary.service';
import { InvoiceFacepageService } from '../invoice/core/invoice-facepage.service';
import { VendorService } from '../vendor/core/vendor.service';
import { DisputeChargeService } from '../charge/core/dispute-charge.service';
import { DocumentService } from '../shared/document/core/document.service';
import { DocumentManageDialogComponent } from '../shared/document/document-manage-dialog/document-manage-dialog.component';
import { DisputeChargesStaticGridService } from 'app/dispute/core/dispute-charges-static-grid.service';
import { DisputeChargeFilterService } from 'app/dispute/core/dispute-charge-filter.service';
import { RevertWithheldManageDialogComponent } from './shared/revert-withheld-manage-dialog/revert-withheld-manage-dialog.component';
import { DeleteDisputeManageDialog } from './shared/dispute-delete-manage-dialog/dispute-delete-manage-dialog.component';
import { ShortDisputeGridService } from './core/short-dispute-grid.service';
import {
  DeleteDisputeChargesManageDialog
} from "./shared/dispute-delete-charges-manage-dialog/dispute-delete-charges-manage-dialog.component";

@NgModule({
  imports: [
    DisputeRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    DxButtonModule,
    DxDataGridModule,
    DxTemplateModule,
    SharedModule,
    CoreModule
  ],
  exports: [],
  declarations: [
    DisputeComponent,
    DisputeListComponent,
    DisputeManageDialogComponent,
    DisputeSneakPeekComponent,
    DisputeDetailsComponent,
    RevertWithheldManageDialogComponent,
    DeleteDisputeManageDialog,
    DeleteDisputeChargesManageDialog
  ],
  providers: [
    DisputeService,
    DisputeGridService,
    DisputeChargesGridService,
    DisputeFilterService,
    InvoiceChargeService,
    DictionaryService,
    InvoiceFacepageService,
    VendorService,
    DisputeChargeService,
    DocumentService,
    DisputeChargesStaticGridService,
    DisputeChargeFilterService,
    ShortDisputeGridService
  ],
  entryComponents: [
    DisputeManageDialogComponent,
    DocumentManageDialogComponent,
    RevertWithheldManageDialogComponent,
    DeleteDisputeManageDialog,
    DeleteDisputeChargesManageDialog
  ]
})
export class DisputeModule { }
