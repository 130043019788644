<div id="cropper-container">
  <div class="mat-dialog-top-spacer"></div>

  <div class="ca-dialog-title">
    <h1 mat-dialog-title>Edit image</h1>
  </div>

  <div mat-dialog-content class="ca-dialog-content" style="max-height:480px !important">

      <div class="cropper-loader" *ngIf="!cropperReady">
        <p>{{cropperPreviewMessage}}</p>
      </div>


      <div *ngIf="!hasImage" class="circle-text avatar" [ngClass]="vendorNoImagePlaceholderStyle">
      <div>{{text}}</div>
      </div>

      <!-- Load image as Base64 -->
      <image-cropper [class]="avatarCropperClass" *ngIf="image"
      [imageBase64]="croppedImage"
      [maintainAspectRatio]="true"
      [aspectRatio]="1 / 1"
      [resizeToWidth]="400"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
      [style.display]="cropperReady ? null : 'none'"></image-cropper>

      </div>

   <input #upload (change)="fileChangeEvent($event)" class="avatar-input" accept="image/*" type="file">

  <mat-dialog-actions class="ca-dialog-action">

      <div class="example-fill-remaining-space"></div>

      <button mat-button type="submit" (click)="close()">
          Cancel
      </button>

      <button mat-button type="submit" (click)="clear()">
          Clear
      </button>

      <button mat-button type="submit" (click)="browse()">
          Browse
      </button>

      <button mat-button type="submit" (click)="save()">
          Save
      </button>
  </mat-dialog-actions>
</div>
