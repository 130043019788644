import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import {Select, Store} from "@ngxs/store";
import { Observable, of, Subject } from "rxjs";
import { PageDetailsComponent } from "../../../core/page-details.component";
import { PageContext } from "../../../core/page.context";
import Query from "../../../core/query/query";
import { LOOKUP_ENUM } from "../../../dictionary/core/lookup.enum";
import { DialogService } from "../../../shared/dialog/dialog.service";
import { UserSettingsService } from "../../../user/core/user-settings.service";
import { Inventory } from "../../core/inventory";
import { InventoryOrderGridService } from "../../core/inventory-order-grid-service";
import { InventoryService } from "../../core/inventory.service";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {InventoryOrderCsvInterceptor} from "../../core/inventory-order-csv-interceptop";
import {UpdateInventoryOrderActiveSiderSection} from "./state/inventory-order.actions";

@Component({
  selector: 'app-inventory-order',
  templateUrl: './inventory-order.component.html',
})
export class InventoryOrderComponent extends PageDetailsComponent  implements OnInit, AfterViewInit {
  inventory: Inventory;

  destroy$: Subject<boolean> = new Subject<boolean>();
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    NOTES_SECTION_NAME: 'notes',
    HISTORY_SECTION_NAME: 'history'
  };
  notesCount: number;

  public ordersColumns: any;
  public orders: Array<any> = [];
  public orderQuery: Query = new Query();

  @ViewChild('panelSide') panelSide;

  @Select(state => state.inventory_order.activeSiderSection) $activeSiderSection: Observable<any>;
  // @Select(state => state.inventory_order.query) $query: Observable<Query>;

  constructor(
    public inventoryService: InventoryService,
    public route: ActivatedRoute,
    public router: Router,
    public inventoryOrderGridService: InventoryOrderGridService,
    public settingsService: UserSettingsService,
    public dialogService: DialogService,
    private readonly store: Store,
  ) {
    super(new PageContext({
      name: 'app.inventory.inventory-order',
      settings: settingsService
    }))
  }

  loadPermissions() {
    return of({});
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/inventory']);
    }
  }

  refresh(){
    this.route.parent.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.loadData(params['id']);
      this.loadOrders(params['id']);
      this.ordersColumns = this.inventoryOrderGridService.columns();
    });
  }

  public async loadData(id: number) {
    this.inventory = await this.loadInventory(id).toPromise();
  }

  loadInventory(id: number): Observable<Inventory> {
    return this.inventoryService.findById(id);
  }

  ngOnInit(): void {
    this.loadPermissions()
      .subscribe(() => {
        this.route.parent.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
          this.loadData(params['id']);
          this.loadOrders(params['id']);
          this.ordersColumns = this.inventoryOrderGridService.columns();
        });
      });
  }

  loadOrders(inventoryId: number) {
    let inventory_id = inventoryId || this.inventory.id;
    this.orderQuery.where = {
      id: inventory_id
    };
    this.inventoryService.getOrders(this.orderQuery).subscribe((result: any) => {
      this.orderQuery.total = result.total;
      /// MERGE // 4.9.12.2 - 8009 - WAS result.order_services
      this.orders = result.items;
    })
  }


  showDetails(data) {
    if(data.order_id) {
      this.router.navigate(['/order', data.order_id, 'show']);
    }
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    // TODO: delete this / used $activeSiderSection to reopen sider when back button is used
    // sider.onChange((section: SiderSection) => {
    //   // TODO: Save to state
    //   // this.saveSettings({siderSection: section.name});
    // });

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }


  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInventoryOrderActiveSiderSection(activeSection.name)
    ]);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME)
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME)
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  /** Toolbar actions */
  onDownloadCSVRequested() {
    const orderTransformMiddleware = (items) => {
      const orderCsvInterceptor = new InventoryOrderCsvInterceptor()
      return items.map((item) => orderCsvInterceptor.transform(item));
    };
    this.inventoryOrderGridService.csvMap().subscribe(fields => {
      this.inventoryService.exportToCSV('inventory-order', {
        fields,
        query: this.orderQuery,
        fetchDataHandler: (query) => {
          return this.inventoryService.getOrders(query)
        },
        middleware: [orderTransformMiddleware]
      })
    })
  }
}
