import {Injectable} from '@angular/core';
import {FilterService} from "../../core/filter/filter.service";
import {auditAlertInventoryMRCFilterContext} from "./audit-alert-inventory-mrc-filter.context";
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class AuditAlertInventoryMRCFilterService extends FilterService {
  constructor(public userSettingsService: UserSettingsService) {
    super("app.alert.audit-alert-inventory-mrc-filter", auditAlertInventoryMRCFilterContext, userSettingsService);


  }

}
