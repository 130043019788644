export class SvcOrderServices {
  id: number;
  description: string;
  svc_order_header_id: number;
  spid: string;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
