import { CommonAlert } from '../../core/common-alert';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ca-common-alert-sneek-peek',
  templateUrl: './common-alert-sneek-peek.component.html',
  styleUrls: ['./common-alert-sneak-peek.component.css']
})
export class CommonAlertSneekPeekComponent implements OnInit {

  constructor() { }
  
  @Input() alert: CommonAlert;
  @Output() closeRequested = new EventEmitter();
  @Output() redirectToChargesRequested = new EventEmitter();

  id: number;

  ngOnInit() {
  }

  closeSide() {
    this.closeRequested.emit();
  }

  redirectToCharges() {
    this.redirectToChargesRequested.emit();
  }

  ngOnChanges(): void {
    if (this.alert) {
      this.id = this.alert.id;
    }
  }

}
