import {Routes, RouterModule} from "@angular/router";
import {NgModule} from "@angular/core";
import {AccountListComponent} from "./account-list/account-list.component";
import {AccountDetailsComponent} from "./account-details/account-details.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':id/show',
        component: AccountDetailsComponent
      },
      {
        path: '',
        component: AccountListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AccountRoutingModule {
}
