import { Injectable } from '@angular/core';
import {Resolve} from '@angular/router';

import { ConfigService } from '../../core/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsResolver implements Resolve<any> {

  constructor(private configService: ConfigService) {
  }

  resolve(): any {
    return this.configService.loadCustomFields();
  }
}
