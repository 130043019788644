import { LookupModelLookupProvider } from '../../dictionary/core/lookup-model-lookup.provider';
import { LOOKUP_MODELS_ENUM } from '../../dictionary/core/lookup-models.enum';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { Injectable } from '@angular/core';
import { FilterService } from '../../core/filter/filter.service';
import { VendorService } from "../../vendor/core/vendor.service";
import { VendorLookupProvider } from "../../vendor/core/vendor-lookup.provider";
import { contactFilterContext } from './contact-filter.context';
import {UserSettingsService} from "../../user/core/user-settings.service";
import {SubdivisionLookupProvider} from "../../location/core/subdivision-lookup.provider";
import {LocationService} from "../../location/core/location.service";
import {CustomerLookupProvider} from "../../customer/core/customer-lookup.provider";
import {CustomerService} from "../../customer/core/customer.service";

@Injectable()
export class ContactFilterService extends FilterService{

  constructor(public vendorService: VendorService,
              public dictionaryService: DictionaryService,
              public userSettingsService: UserSettingsService,
              public locationService: LocationService,
              public customerService: CustomerService) {
    super("app.appdata.contact-filter", contactFilterContext, userSettingsService);

    this.addLookupProvider('customer', new CustomerLookupProvider(customerService));
    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('contactType', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.CONTACT_TYPE.modelName, true, true));
    this.addLookupProvider('subdivision', new SubdivisionLookupProvider(locationService, true));

  }
}

