import { Injectable } from '@angular/core';
import { SiteLookupProvider } from 'app/site/core/site-lookup.provider';
import { SiteService } from 'app/site/core/site.service';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ContactService } from '../../../contact/core/contact.service';
import { IFilterLookupProvider } from "../../../core/filter/filter-lookup.provider";
import { FilterService } from '../../../core/filter/filter.service';
import { CustomerLookupProvider } from '../../../customer/core/customer-lookup.provider';
import { CustomerService } from '../../../customer/core/customer.service';
import { DictionaryService } from '../../../dictionary/core/dictionary.service';
import { LookupModelLookupProvider } from '../../../dictionary/core/lookup-model-lookup.provider';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { UserSettingsService } from '../../../user/core/user-settings.service';
import { UserService } from '../../../user/core/user.service';
import { VendorLookupProvider } from '../../../vendor/core/vendor-lookup.provider';
import { VendorService } from '../../../vendor/core/vendor.service';
import { orderServiceLevelFilterContext } from './order-service-level-filter.context';
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

export class OrderServiceStatusLookupProvider extends IFilterLookupProvider {

  lookupModel: string = LOOKUP_MODELS_ENUM.ORDER_SERVICE_STATUS.modelName;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    return this.dictionaryService.getByLookup(this.lookupModel).pipe(
      map((result: any) => {
        return result.items.map((entry) => {
          return {
            key: entry.id,
            value: entry.value
          };
        });
      })
    );
  }
}

@Injectable()
export class OrderServiceLevelFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public dictionaryService: DictionaryService,
    public userSettingsService: UserSettingsService,
    public vendorService: VendorService,
    public siteService: SiteService,
    public userService: UserService,
    public customerService: CustomerService,
    public contactService: ContactService) {

    super('app.order.order-service-level-filter', orderServiceLevelFilterContext, userSettingsService);

    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('circuit_bandwidth', new LookupModelLookupProvider(dictionaryService,
      LOOKUP_MODELS_ENUM.BANDWIDTH.modelName, true, true));
    this.addLookupProvider('inventory_type', new LookupModelLookupProvider(dictionaryService,
      LOOKUP_MODELS_ENUM.INVENTORY_CKT_TYPE.modelName, true, true));
    this.addLookupProvider('order_type', new LookupModelLookupProvider(dictionaryService,
      LOOKUP_MODELS_ENUM.ORDER_TYPE.modelName));
    this.addLookupProvider('site', new SiteLookupProvider(siteService));
    this.addLookupProvider('customer', new CustomerLookupProvider(customerService));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
    this.addLookupProvider('orderServiceStatus', new OrderServiceStatusLookupProvider(dictionaryService));
  }
}
