<app-contract-details-toolbar
    [isRatesTab]="true"
    [isSaveDisabled]="ratesSaving || !isRateDataValid"
    [isDeleteRateDisabled]="!selectedRates.length"
    [hasSchedulesWithRates]="containsSchedulesWithRates"
    (addRateRequested)="addRateData()"
    (saveRatesRequested)="saveRates()"
    (deleteRateRequested)="deleteRates()"
    (csvRequested)="rateCsv()"
    (gridSettingsRequested)="rateGridSettings()"
    [isFilterDisabled]="false"
    [isFilterOn] = "this.sider.getActiveSection().name === this.SECTIONS.FILTER_SECTION_NAME"
    (toggleFilterRequested)="toggleFilter()"
    [notesCount]="notesCount"
    [isNotesOn] = "this.sider.getActiveSection().name === this.SECTIONS.NOTES_SECTION_NAME"
    (toggleNotesRequested)="toggleNotes()"
    [isHistoryOn] = "this.sider.getActiveSection().name === this.SECTIONS.HISTORY_SECTION_NAME"
    (toggleHistoryRequested)="toggleHistory()"
>

</app-contract-details-toolbar>

<mat-sidenav-container class="app-content app-content-list tabbed-content dx-nodata-absolute">
    <app-contract-details-tabs #tabs [activeTabIndex]="activeTabIndex" [contract]="contract" [disabled]="hasUnsavedChanges" (displayPreventChangeModal)="displayCheckModal()"></app-contract-details-tabs>

    <div class="tabs-body grid-container grid-with-toolbar dx-nodata-absolute"
      style="top: -2px; z-index: -1; overflow: hidden;">
      <div class="grid-body app-content-list">
        <dx-data-grid #rateGrid
                      class="grid-with-selection-badge first-column app-grid-with-pager dx-sort-disabled"
                      [height]="'100%'"
                      [columns]="rateColumns"
                      [dataSource]="rates"
                      [showColumnLines]="false"
                      [showRowLines]="true"
                      [allowColumnResizing]="true"
                      [columnMinWidth]="50"
                      [columnAutoWidth]="true"
                      (onRowUpdated)="onRowUpdated($event)"
                      (onCellClick)="onRatesCellClick($event)"
                      (onCellPrepared)="onRatesCellPrepared($event)"
                      (onSelectionChanged)="onSelectionChangedRate($event)"
                      (onEditorPreparing)="scheduleIdUpdated($event)"
        >
                      <dxo-selection mode="multiple"></dxo-selection>
                      <dxo-paging [enabled]="false" visible="false"></dxo-paging>
                      <ng-container *permission ="'Modify'">
                        <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
                      </ng-container>
                      <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
                        <a class="selection-badge grid-badge" matTooltip="Selection" (click)="loadSelectedRates()" [ngClass]="{'active':selectionRateActive}">
                          {{selectedRates.length}}
                        </a>
                      </div>
                      <div *dxTemplate="let d of 'selectionBadgeCellTemplate'"></div>
  
                      <div *dxTemplate="let d of 'dateTimeTemplate'">
                        <div caGridTooltip>{{ d.value | caDate }}</div>
                      </div>
  
                      <div *dxTemplate="let d of 'rateCurrencyTemplate'">
                        <div caGridTooltip class="right">{{ d.data.currency_id ? (d.value | caCurrencyId:'USD': 'symbol-narrow': '1.2-8': 'en': d.data.currency_id) : (d.value | caCurrency:'USD': 'symbol-narrow': '1.2-8': 'en') }}</div>
                      </div>
                      <i *dxTemplate="let d of 'contractRateHeaderTemplate'">
                        {{ d.column.caption }}
                      </i>
                      <ng-template *dxTemplate="let d of 'auditsHeaderTemplate'" [visible]="auditsEnabled">
                        <div caGridTooltip class="right">{{ d.column.caption }}</div>
                      </ng-template>
                     
          <dxo-sorting mode="none"></dxo-sorting>
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
        </dx-data-grid>
      </div>
      <ca-pager [gridPager]="defaultGridPager" class="app-pager ca-default-border-top"  [pageContext]="rateGridService" [query]="rateQuery" (onChange)="loadRates(rateQuery)"></ca-pager>
    </div>

    <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false" (close-start)="panelClose()">
      <new-app-filter *ngIf="contract" [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                      [query]="rateQuery" [filterService]="contractRateFilterService"
                      (filterRequested)="filterRatesData($event)" (clearFilterRequested)="clearRatesFilter($event)">
      </new-app-filter>
      <ca-notes [entityId]="contract?.id" [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                [entityType]="SYSTEM_MODULE.CONTRACT" (countChanged)="onNotesCountChanged($event)"></ca-notes>
  
      <ca-history #history
                  [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
                  [entityId]="contract?.id"
                  [entityType]="'contract'"
      ></ca-history>
    </mat-sidenav>
</mat-sidenav-container>