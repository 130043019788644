import { Injectable } from "@angular/core";
import { FilterService } from "../../core/filter/filter.service";
import Query from "../../core/query/query";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { VendorLookupProvider } from "../../vendor/core/vendor-lookup.provider";
import { VendorService } from "../../vendor/core/vendor.service";
import { quotingReportFilterContext } from "./quoting-report-filter.context";
import { DefaultDateRangesLookupProvider } from "app/core/filter/default-date-ranges-lookup.provider";

@Injectable()
export class QuotingReportFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public userSettingsService: UserSettingsService,
              public vendorService: VendorService,
              public dictionaryService: DictionaryService) {
    super("app.quoting.report-filter", quotingReportFilterContext, userSettingsService);

    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService));
    this.addLookupProvider('serviceTerm', new LookupModelLookupProvider(this.dictionaryService, LOOKUP_MODELS_ENUM.QUOTE_SERVICE_TERM.modelName));
    this.addLookupProvider('bandwidth', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.BANDWIDTH.modelName, true, true, new Query({limit: 1000})));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
