export const alertChargeFilterContext = {
  filters: [
    {
      field: 'chg_class',
      label: 'Charge Type',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'chargeType',
      index: 1
    },
    {
      field: "chg_amt",
      label: "Charge Amount",
      type: 'negativeNumber',
      index: 2
    }, {
      field: "sp_serv_id",
      label: "Service ID",
      type: 'string',
      index: 3
    },
    {
      field: "chg_desc_1",
      label: "Charge Description 1",
      type: 'string',
      index: 4
    },
    {
      field: "chg_code_1",
      label: "Charge Code1",
      type: 'string',
      index: 5
    },
    {
      field: "beg_chg_date",
      label: "Beg Charge Date ",
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 7
    },
    {
      field: 'chg_code_2',
      label: 'Charge Code 2',
      type: 'string',
      index: 11
    },
    {
      field: 'chg_desc_2',
      label: 'Charge Description 2',
      type: 'string',
      index: 12
    },
    {
      field: 'chg_qty1_billed',
      label: 'Chg Qty',
      type: 'negativeNumber',
      index: 13
    },
    {
      field: 'chg_rate',
      label: 'Charge Rate',
      type: 'negativeNumber',
      index: 14
    },
    {
      field: 'info_only_ind',
      label: 'Info Only Ind',
      type: 'boolean',
      index: 15
    },
    {
      field: 'fac_bw',
      label: 'Facility Bandwidth',
      type: 'lookup',
      lookupProviderName: 'facilityBandwidth',
      index: 16
    },
    {
      field: 'call_type',
      label: 'Call Type',
      type: 'string',
      index: 17
    },
    {
      field: 'dir_ind',
      label: 'Dir Ind',
      type: 'string',
      index: 18
    },
    {
      field: 'jur',
      label: 'Jurisdiction',
      type: 'string',
      index: 19
    },
    {
      field: 'chg_qty1_type',
      label: 'Chg Qty Type',
      type: 'string',
      index: 20
    },
    {
      field: 'chg_qty1_used',
      label: 'Chg Qty Used',
      type: 'number',
      index: 21
    },
    {
      field: 'chg_qty1_allowed',
      label: 'Chg Qty Allowed',
      type: 'string',
      index: 22
    },
    {
      field: 'acct_level_2',
      label: 'Subaccount',
      type: 'lookup',
      lookupProviderName: 'subAccount',
      index: 24
    },
    {
      field: 'alert_details.message',
      label: 'Message',
      type: 'string',
      index: 37
    }
    /*{
      field: "calc_rate",
      label: "Calculated Rate",
      type: 'number',
      index: 1
    },
    {
      field: "calc_charge",
      label: "Calculated Charge",
      type: 'number',
      index: 2
    },
    {
      field: "calc_prorate_fact",
      label: "Calculated Proration Factor",
      type: 'number',
      index: 3
    },
    {
      field: "calc_var",
      label: "Calculated Variance",
      type: 'number',
      index: 4
    },
    {
      field: "audit_result_message",
      label: "Audit Result Message",
      type: 'string',
      index: 5
    },
    {
      field: 'invoice_charge.chg_class',
      label: 'Charge Type',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'chargeType',
      index: 6
    },
    {
      field: "invoice_charge.chg_amt",
      label: "Charge Amount",
      type: 'number',
      index: 7
    },
    {
      field: "invoice_charge.sp_serv_id",
      label: "SPID",
      type: 'string',
      index: 8
    },
    {
      field: "invoice_charge.chg_desc_1",
      label: "Charge Description 1",
      type: 'string',
      index: 9
    },
    {
      field: "invoice_charge.chg_code_1",
      label: "Charge Code1",
      type: 'string',
      index: 10
    },
    {
      field: "invoice_charge.svc_establish_date",
      label: "Install Date",
      type: 'date',
      index: 11
    },
    {
      field: "invoice_charge.beg_chg_date",
      label: "Beg Charge Date",
      type: 'date',
      index: 12
    },
    {
      field: "invoice_charge.end_chg_date",
      label: "End Charge Date",
      type: 'date',
      index: 13
    }*/
  ]
};
