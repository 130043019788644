import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Pricing } from '../../core/pricing';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { InventoryService } from '../../core/inventory.service';
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { AlertService } from '../../../shared/alert/alert.service';
import {LOOKUP_MODELS_ENUM} from "../../../dictionary/core/lookup-models.enum";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import Query from '../../../core/query/query';
import {DialogButtonsController} from '../../../core/dialog-buttons-controller.class'
import {LoaderService} from "../../../shared/loader/loader.service";

@Component({
  selector: 'app-inventory-pricing-dialog',
  templateUrl: './inventory-pricing-dialog.component.html',
  styleUrls: ['./inventory-pricing-dialog.component.css']
})
export class InventoryPricingDialogComponent extends DialogButtonsController implements OnInit {

  @ViewChild('fileInput') fileInput;

  fileName: string = '';
  progressMode: string = 'determinate';
  isUploading: boolean = false;
  public files = [];

  public fileNameTaken: any = null;


  public pricingForm: FormGroup;
  public inventory;
  public folder;
  public subfolder;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "inventory";

  readonly CONTRACT_TYPE_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.CONTRACT_TYPE.modelName;


  constructor(
    public dialogRef: MatDialogRef<InventoryPricingDialogComponent>,
    public formBuilder: FormBuilder,
    public inventoryService: InventoryService,
    public alert: AlertService,
    public loaderService: LoaderService
  ) {
    super();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

  }

  ngOnInit() {
    this.pricingForm = this.formBuilder.group({
      contract_type_id: new FormControl(null),
      effective_date: new FormControl(null),
      exp_date: new FormControl(null),
      term: new FormControl(null),
      mrc: new FormControl(''),
      nrc: new FormControl(''),
      description: new FormControl('')
    });
  }

  fileNameCheckHandler = (result) => this.fileNameTaken = result.fileExists;

  onFileSelect(event) {
    if (event.target && event.target.files.length) {
      this.fileName = event.target.files[0].name;
      this.files = this.fileInput.nativeElement.files;

      this
          .isFileNameTaken(this.fileName)
          .subscribe(this.fileNameCheckHandler);
    } else {
      this.fileName = '';
      this.files = [];
    }
  }

  onDrop(files) {
    if (files && files.length) {
      this.fileName = files[0].name;
      this.files = files;

      this
          .isFileNameTaken(this.fileName)
          .subscribe(this.fileNameCheckHandler);
    } else {
      this.fileName = '';
      this.files = [];
    }
  }

  isFileNameTaken(fileName: string) {

    this.fileNameTaken = null;

    const query = new Query({
      where: {
        path: fileName,
      },
    });

    return this
      .inventoryService
      .isFileNameTaken(this.inventory.id, query);
  }

  onSubmit(pricingForm: FormGroup) {

    let value = pricingForm.value;
    value.mrc = this.unmask(value.mrc);
    value.nrc = this.unmask(value.nrc);

    let file = null;
    if(this.files.length) {
      file = this.files[0];
    }
    /*if (this.fileInput.nativeElement.files.length) {
      file = this.fileInput.nativeElement.files[0];
    }*/

    this.create(value, file);
  }

  create(pricing: Pricing, file?: File) {

    this.isUploading = true;
    this.progressMode = 'indeterminate';
    this.loaderService.displayLoader()

    let formData: FormData = new FormData();
    formData.append('files', file, file.name);
    formData.append('description', pricing.description);
    formData.append('term', (pricing.term !== null ? pricing.term : '').toString());
    formData.append('effective_date', (pricing.effective_date || '').toString());
    formData.append('exp_date', (pricing.exp_date || '').toString());
    formData.append('mrc', (pricing.mrc != null ? pricing.mrc : '').toString());
    formData.append('nrc', (pricing.nrc != null ? pricing.nrc : '').toString());
    formData.append('contract_type_id', (pricing.contract_type_id || '').toString());
    formData.append('entity', JSON.stringify(this.inventory));
    formData.append('folder', `${this.folder}/${this.subfolder}`);
    formData.append('file_type', LOOKUP_ENUM.DOCUMENT_TYPE.SUPPORTING_DOCUMENT.toString());

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    let options = new HttpRequest('PUT', '/', { headers: headers });

    this.toggleDialogButtons();
    this.inventoryService.addPricing(this.inventory.id, formData)
      .subscribe(
      (result) => {
        this.toggleDialogButtons(false);
        this.loaderService.hideLoader();
        this.close(result);
      },
      (error) => {
        this.toggleDialogButtons(false);
        this.loaderService.hideLoader();
        this.alert.error('', this.messages.get('ADD_PRICING_FAIL'));
        this.isUploading = false;
        this.progressMode = 'determinate';
      }
      );

  }

  public unmask(value) {
    if (value)
      // http://stackoverflow.com/questions/559112
      return Number(value.replace(/[^0-9\.]+/g, ''));
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

}
