import { Injectable } from '@angular/core';
import { ConfigService } from "../../core/config/config.service";
import { FilterService } from "../../core/filter/filter.service";
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { CountryLookupProvider } from "../../location/core/country-lookup.provider";
import { CurrencyLookupProvider } from "../../location/core/currency-lookup.provider";
import { LocationService } from "../../location/core/location.service";
import { StateLookupProvider } from "../../location/core/state-lookup.provider";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { contractScheduleDetailRateFilterContext } from "./contract-schedule-detail-rate-filter.context";
import { ContractScheduleLookupProvider } from "./contract-schedule-lookup.provider";
import { ContractService } from "./contract.service";
import { RateCategoryLookupProvider } from "./rate-category.provider";
import { RateQtyUomLookupProvider } from './rate-qty-uom.provider';
import { RateTermUomLookupProvider } from './rate-term-unit-of-measure.provider';
import { RateTypeLookupProvider } from "./rate-type.provider";
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class ContractScheduleDetailRateFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public userSettingsService: UserSettingsService,
              public dictionaryService: DictionaryService,
              public locationService: LocationService,
              public contractService: ContractService,
              public configService: ConfigService
              ) {
    super("app.contract.contract-schedule-rate-filter", contractScheduleDetailRateFilterContext, userSettingsService);

    this.addLookupProvider('rateQtyUom', new RateQtyUomLookupProvider(dictionaryService));
    this.addLookupProvider('rateCategory', new RateCategoryLookupProvider(dictionaryService));
    this.addLookupProvider('rateType', new RateTypeLookupProvider(dictionaryService));
    this.addLookupProvider('currency', new CurrencyLookupProvider(locationService));
    this.addLookupProvider('state', new StateLookupProvider(locationService));
    this.addLookupProvider('country', new CountryLookupProvider(locationService, true));
    this.addLookupProvider('rateTermUom', new RateTermUomLookupProvider(dictionaryService));
    this.addLookupProvider('contractSchedule', new ContractScheduleLookupProvider(contractService));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
  }
}
