import {environment} from "../environments/environment";
import Auth from "./shared/user-session/auth";

export function setupRestangular(RestangularProvider) {
  RestangularProvider.setBaseUrl(environment.API_URL);

  RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {
    let cookie = Auth.getSession();
    if (cookie && cookie.token) {
      return {
        headers: Object.assign({}, headers,
          {
            Authorization: `Bearer ${cookie.token}`,
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          })
      };
    }
  });
}
