import { Injectable } from '@angular/core';
import { GridService } from "../../shared/grid/grid.service";
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class InventoryCircuitsGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.inventory.inventory-circuits-grid", userSettings);
  }

  defaultColumns = [
    {
      caption: "ID",
      dataField: "circuits.unique_id",
       width: 250
    },
    {
      caption: "Type",
      dataField: "circuits.type.value",
      width: 130
    },
    {
      caption: "Vendor",
      dataField: "circuits.vendor.name",
      width: 130
    },
    {
      caption: "Install Date",
      dataField: "circuits.install_date",
      dataType: "date",
      cellTemplate: "dateTemplate",
      alignment: 'left',
      width: 130
    },
    {
      caption: "Site A",
      dataField: "circuits.siteA.site_id",
      width: 160
    },
    {
      caption: "Site Z",
      dataField: "circuits.siteZ.site_id",
      width: 160
    },
    {
      caption: "Status",
      dataField: "circuits.inventory_status.value",
      width: 130
    },
    {
      caption: "Internal ID",
      dataField: "circuits.internal_id",
      width: 200
    },
    {
      caption: "Relationship Type",
      dataField: "relationship_type.value",
      width: 150
    },
  ];

  /*columns() {

    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
