  <mat-form-field fxFlex class="flex-item" hiDensityInput>
    <input [(ngModel)]="internalNumberQuery['from']"
           (focusout)="ngOnNumberChange($event, entry, FOCUS.FROM)"
           type="number"
           [min]="0"
           [placeholder]="'From'"
           matInput
           [max]="internalNumberQuery['max']"
           (keydown.enter)="ngOnNumberChange($event, entry, FOCUS.FROM)">
    <mat-hint style="line-height: 12px !important" class="error-class">
      {{fromNumberErrorText}}
    </mat-hint>
  </mat-form-field>
  <span fxFlex="10px"></span>
  <mat-form-field fxFlex class="flex-item" hiDensityInput>
    <input  [(ngModel)]="internalNumberQuery['to']"
            (focusout)="ngOnNumberChange($event, entry, FOCUS.TO)"
            type="number"
            [placeholder]="'To'"
            matInput
            [min]="internalNumberQuery['from']"
            [max]="internalNumberQuery['max']"
            (keydown.enter)="ngOnNumberChange($event, entry, FOCUS.TO)">
    <mat-hint style="line-height: 12px !important" class="error-class">
      {{toNumberErrorText}}
    </mat-hint>
  </mat-form-field>
