import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import {InvoiceFacepageService} from "./invoice-facepage.service";
import {Observable} from "rxjs";
import Query from "../../core/query/query";
import {map} from "rxjs/operators";
export class SubAccountLookupProvider extends IFilterLookupProvider {

  constructor(public invoiceService: InvoiceFacepageService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    let query = searchQuery? searchQuery : new Query();
    query.orderBy = [['acct_level_2', 'ASC']];

    return this.invoiceService.findDistinctSubAccounts(query)
      .pipe(map((result: any) => {
        return {items: result.map((entry: any) => {
          return entry.acct_level_2;
        }), total: result.total};
    }));
   }

}
