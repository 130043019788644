import { Injectable } from '@angular/core';
import { AccountLookupProvider } from 'app/account/core/account-lookup.provider';
import { AccountService } from 'app/account/core/account.service';
import { VendorLookupProvider } from 'app/vendor/core/vendor-lookup.provider';
import { VendorService } from 'app/vendor/core/vendor.service';
import { ChargeTypeLookupProvider } from '../../charge/core/charge-type-lookup.provider';
import { FilterService } from '../../core/filter/filter.service';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { invoiceGlRuleExecutionFlatFilterContext } from './invoice-gl-rule-execution-flat-filter.context';
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class InvoiceGlRuleExecutionFlatFilterService extends FilterService {
  customRanges: any = [
    {
      label: 'Last 2 years'
    },
    {
      label: 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      label: 'Prior month'
    },
    {
      label: 'Last week'
    }
  ];
  
  constructor(
    public userSettingsService: UserSettingsService,
    public dictionaryService: DictionaryService,
    public vendorService: VendorService,
    public accountService: AccountService
  ) {
    super(
      'app.gl.control-center-filter-flat',
      invoiceGlRuleExecutionFlatFilterContext,
      userSettingsService
    );

    this.addLookupProvider(
      'chargeType',
      new ChargeTypeLookupProvider(dictionaryService)
    );

    this.addLookupProvider(
      'vendor',
      new VendorLookupProvider(vendorService, true)
    );
    
    this.addLookupProvider(
      'account',
      new AccountLookupProvider(accountService)
    );

    this.addLookupProvider(
      'defaultDateRanges',
      new DefaultDateRangesLookupProvider(
        this.customRanges,
        this.excludeRanges,
        true
      )
    );
  }
}
