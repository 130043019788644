import {
  Component, EventEmitter, Input, OnInit, Output, SimpleChanges,
  ViewChild
} from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";
import {Demarc} from "../../core/demarc.model";
import {DemarcGridService} from "../../core/demarc-grid.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";

@Component({
  selector: 'ca-demarc-list',
  templateUrl: './demarc-list.component.html',
  styleUrls: ['./demarc-list.component.css']
})

export class DemarcListComponent implements OnInit {

  @Input() demarcs: Array<Demarc>;
  @Input() title: string;
  @Input() gridOnly: boolean;
  @Input() siteId: any;
  @Output() addRequested = new EventEmitter();
  @Output() editRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();


  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public selection: Demarc;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "demarc";
  public isGridInit = false;

  constructor(public demarcGridService: DemarcGridService) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.columns = this.demarcGridService.columns();
  }

  showGrid() {
    setTimeout(() => {
      if (this.dataGrid && this.dataGrid.instance && !this.isGridInit) {
        this.isGridInit = true;
        this.dataGrid.instance.option('columns', this.columns);
        this.demarcGridService.create(this.dataGrid.instance, {
          allowColumnResizing: false,
          height: '164px',
          noDataText: this.demarcGridService.noDataMessage
        });
      }
    });
  }

  onSelectionChanged(row) {
    this.selection = <Demarc>row.selectedRowsData[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.siteId && changes.siteId.currentValue){
      this.showGrid();
    }
  }

  requestAdd() {
    this.addRequested.emit(true);
  }

  requestEdit(demarc: Demarc) {
    this.editRequested.emit(demarc);
  }

  requestDelete(demarc: Demarc) {
    this.deleteRequested.emit(demarc);
  }
}
