<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{ formTitle }}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">
  <form [formGroup]="form" class="ca-form">

    <div class="flex-container" fxLayout="row" fxLayoutGap="24px">
      <div fxFlex="50%">

        <div class="flex-container">
          <div class="flex-item" fxFlex="50%">
            <ca-date-picker caInput [required]="true" placeholder="Quote Date" formControlName="sent_date"></ca-date-picker>
          </div>
        </div>

        <div class="flex-container" fxLayout="row" fxLayoutGap="24px">

          <div class="flex-item" fxFlex="50%">
            <ca-lookup-model-picker caInput [lookupModel]="QUOTE_SERVICE_MEDIUM_LOOKUP_MODEL" formControlName="medium_id"
                                    placeholder="Medium" [pickerForm]="form" [clearEnabled]="true">
            </ca-lookup-model-picker>
          </div>
          <div class="flex-item" fxFlex="50%">
            <mat-form-field caInput>
              <input matInput type="text" formControlName="interval" placeholder="Install Interval (days)"/>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-container" fxLayout="row"fxLayoutGap="24px">
            <div class="flex-item" fxFlex="50%">
              <ca-lookup-model-picker caInput [lookupModel]="NET_TYPE_LOOKUP_MODEL" formControlName="net_type_id"
                                      placeholder="On/Off Net" [pickerForm]="form" [clearEnabled]="true">
              </ca-lookup-model-picker>
            </div>
            <div class="flex-item" fxFlex="50%">
              <ca-vendor-picker caInput
                [pickerForm]="form"
                customMethod="findAll"
                formControlName="off_net_vendor_id"
                [initialItem]="offer?.vendor"
                placeholder="Off Net Vendor"
                [clearEnabled]="true">
              </ca-vendor-picker>
            </div>
        </div>
        <div class="flex-container" fxLayout="row" fxLayoutGap="24px">
          <div class="flex-item" fxFlex="50%">
            <ca-date-picker caInput placeholder="Received Date" formControlName="received_date"></ca-date-picker>
          </div>
          <div class="flex-item" fxFlex="50%">
            <ca-date-picker caInput placeholder="Expiration Date" formControlName="expiration_date"></ca-date-picker>
          </div>
        </div>

        <div class="flex-container" fxLayout="row" fxLayoutGap="24px">
          <div class="flex-item" fxFlex="50%">
            <ca-bandwidth-picker caInput
                  name="circuit_bandwidth_id"
                  formControlName="circuit_bandwidth_id"
                  placeholder="Bandwidth"
                  [pickerForm]="form"
                  [searchEnabled]="true"
                >
                </ca-bandwidth-picker>
          </div>
          <div class="flex-item" fxFlex="50%">
            <ca-bandwidth-picker caInput
                  name="port_bandwidth_id"
                  formControlName="port_bandwidth_id"
                  placeholder="Port Bandwidth"
                  [pickerForm]="form"
                  [searchEnabled]="true"
                >
                </ca-bandwidth-picker>
          </div>
        </div>

      </div>
      <div fxFlex="50%" style="padding-left: 15px">

        <table class="ca-table ca-table-stripped ca-table-cozy" style="margin-top: 8px;">
          <thead>
          <tr>
            <th style="width: 50px"></th>
            <th>MRC</th>
            <th>NRC</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let term of this.availableTerms">
            <td>
              {{term.value}}
            </td>
            <td class="table-input-fields">
              <ca-currency hiDensityInput formControlName="{{term.key}}_mrc"
                           placeholder=""
                           [disabled]="isInputDisabled(term.id)"
                           [required]="true"
                            class="currency-without-label"></ca-currency>
              <mat-hint
                *ngIf="form.hasError('required', [term.key + '_mrc']) && form.controls[term.key + '_mrc'].touched"
                class="ca-select-hint">
                {{VALIDATION.REQUIRED_ERROR}}
              </mat-hint>
            </td>
            <td class="table-input-fields">
              <ca-currency hiDensityInput formControlName="{{term.key}}_nrc" placeholder=""
                          [disabled]="isInputDisabled(term.id)"
                          [required]="true"
                           class="currency-without-label"></ca-currency>
              <mat-hint
                *ngIf="form.hasError('required', [term.key + '_nrc']) && form.controls[term.key + '_nrc'].touched"
                class="ca-select-hint">
                {{VALIDATION.REQUIRED_ERROR}}
              </mat-hint>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</div>
<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>
  <button mat-button #genericButton [disabled]="form?.invalid" type="submit" (click)="onSubmit(form)">Update</button>

</mat-dialog-actions>
