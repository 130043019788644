<div class="ca-form date-filter" fxLayout="column">
  <div fxFlex class="flex-item">
    <ca-default-picker hiDensityInput>
      <mat-form-field hiDensityInput>
        <mat-select [value]="range"
                    (selectionChange)="rangeChange($event)"
                    [placeholder]="range ? '' : entry.label || 'Date Selection'">
          <mat-option (click)="clearInternalDateQueryRanges()"
                      class="clear-option">CLEAR
          </mat-option>
          <mat-option value="PRIOR_MONTH">Prior Month</mat-option>
          <mat-option value="PRIOR_3_MONTHS">Prior 3 Months</mat-option>
          <mat-option value="PRIOR_6_MONTHS">Prior 6 Months</mat-option>
          <mat-option value="PRIOR_YEAR">Prior year</mat-option>
          <mat-option value="LAST_30_DAYS">Last 30 days</mat-option>
          <mat-option value="LAST_90_DAYS">Last 90 days</mat-option>
          <mat-option value="LAST_WEEK">Last Week</mat-option>
          <mat-option value="YEAR_TO_DATE">Year to Date</mat-option>
          <mat-option value="CUSTOM">Custom</mat-option>
          <!--<mat-option *ngFor="let range of ranges"
                      [value]="range.key">{{range.label}}</mat-option>-->
        </mat-select>
      </mat-form-field>
    </ca-default-picker>
  </div>
  <div class="ca-form" *ngIf="range.toString() === Ranges.CUSTOM">
    <form [formGroup]="form">
      <div class="ca-form ca-date-form" fxFlex>
        <mat-form-field dateFormat class="ca-date-picker" hiDensityInput>
          <input formControlName="from" matInput [matDatepicker]="from" placeholder="From">
          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
          <mat-datepicker #from></mat-datepicker>
        </mat-form-field>
      </div>
      <span fxFlex="10px"></span>
      <div class="ca-form ca-date-form" fxFlex>
        <mat-form-field dateFormat class="ca-date-picker" hiDensityInput>
          <input formControlName="to" matInput [matDatepicker]="to" placeholder="To">
          <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
          <mat-datepicker #to></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
