<div class="ca-card ca-card-sneak-peek">
  <mat-card caCard>
    <mat-card-content>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Service ID</label>
          <p>{{ orderService?.full_service_id || "N/A" }}</p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>SP Service ID</label>
          <p>{{ orderService?.inventory?.sp_ckt_id || "N/A" }}</p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Vendor</label>
          <p>{{ orderService?.inventory?.vendor?.name || "N/A" }}</p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Customer Service ID</label>
          <p>
            {{
              orderService?.inventory?.customer_inventory
                ?.customer_circuit_id || "N/A"
            }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Customer</label>
          <p>{{ orderService?.inventory?.customer?.company || "N/A" }}</p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Desired Due Date</label>
          <p>{{ (orderService?.des_due_date | caDate) || "N/A" }}</p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Expedite</label>
          <p>{{ orderService?.expedited_order === true ? "Yes" : "No" }}</p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
