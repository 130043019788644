import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ChargeListComponent} from "./charge-list/charge-list.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ChargeListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ChargeRoutingModule {
}
