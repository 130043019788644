import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BaseService} from '../../../core/base.service';
import {EntityLockService} from '../../../shared/entity-lock/entity-lock.service';
import Query from 'app/core/query/query';

@Injectable()
export class RateAuditResultService extends BaseService<any> {
  constructor(public restangular: Restangular, private entityLock: EntityLockService) {
    super('audit-rate-result', restangular, entityLock);
  }

  grouping(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().one(this.name, 'grouping').customGET(null, this.toFilter(transformedQuery));
  }

}
