

import {BandwidthService} from "./core/bandwidth.service";
import {NgModule} from "@angular/core";
@NgModule({
  imports: [

  ],
  exports: [

  ],
  declarations: [

  ],
  entryComponents: [

  ],
  providers: [
    BandwidthService
  ]
})
export class BandwidthModule { }
