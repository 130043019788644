export const buildingFilterContext = {
  filters: [
    {
      field: 'name',
      label: 'Name',
      type: 'string',
      index: 1,
      visible: true
    },
    {
      field: 'address',
      label: 'Address',
      editor: 'address',
      addressFields: {
        address1: {
          label: 'Address',
          field: 'address.address1'
        },
        city: {field: 'address.city'},
        subdivision: {
          field: 'address.subdivision_id',
          label: 'Subdivision',
          lookupProviderName: 'subdivision',
        },
        postal_code: {field: 'address.postal_code'}
      },
      index: 2,
      visible: true
    },
    {
      field: "address.country.alpha_2_code",
      label: "Country",
      type: "lookup",
      editor: 'country',
      lookupProviderName: 'country',
      index: 3,
      visible: true,
    }
  ]
};
