import { DictionaryModule } from '../dictionary/dictionary.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "app/material-barrel/material-barrel.module";
import { CoreModule } from "../core/core.module";
import {ContactService} from "./core/contact.service";
import {ContactManageDialogComponent} from './shared/contact-manage-dialog/contact-manage-dialog.component';
import {ContactListComponent} from './contact-list/contact-list.component';
import {ContactPickerComponent} from "./shared/contact-picker/contact-picker.component";
import {ContactComponent} from './contact.component';
import {ContactRoutingModule} from "./contact-routing.module";
import {ReactiveFormsModule, FormsModule} from "@angular/forms";
import {DxButtonModule} from 'devextreme-angular/ui/button';
import {DxDataGridModule} from 'devextreme-angular/ui/data-grid';
import {FlexLayoutModule} from "@angular/flex-layout";
import { ContactSneakPeekComponent } from './shared/contact-sneak-peek/contact-sneak-peek.component';
import {SharedModule} from "../shared/shared.module";
import {ContactGridService} from "./core/contact-grid.service";
import {ContactFilterService} from "./core/contact-filter.service";
import {LocationModule} from "../location/location.module";
import {VendorModule} from "../vendor/vendor.module";
import {SiteModule} from "../site/site.module";
import { CustomerModule } from "../customer/customer.module";
import {AddressModule} from "../address/address.module";
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ContactRoutingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    DxButtonModule,
    DxDataGridModule,
    MaterialModule,
    SharedModule,
    VendorModule,
    LocationModule,
    SiteModule,
    CoreModule,
    DictionaryModule,
    CustomerModule,
    AddressModule,
    NgxMaskModule
  ],
  exports: [
    ContactPickerComponent
  ],
  declarations: [
    ContactPickerComponent,
    ContactManageDialogComponent,
    ContactListComponent,
    ContactComponent,
    ContactSneakPeekComponent
  ],
  entryComponents: [
    ContactManageDialogComponent
  ],
  providers: [
    ContactService,
    ContactGridService,
    ContactFilterService
  ]
})
export class ContactModule { }
