import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import {Observable, of, Subject} from "rxjs";
import {Inventory} from "../../core/inventory";
import {InventoryService} from "../../core/inventory.service";
import {DemarcService} from "../../../demarc/core/demarc.service";
import {INVENTORY_TOPOLOGY_ENUM} from "../../core/inventory-topology.enum";
import {Demarc} from "../../../demarc/core/demarc.model";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {Select, Store} from "@ngxs/store";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {UpdateInventorySiteActiveSiderSection} from "./state/inventory-site.actions";

@Component({
  selector: 'app-inventory-site',
  templateUrl: './inventory-site.component.html',
})
export class InventorySiteComponent implements OnInit {
  inventory: Inventory;
  siteADemarcs: Array<Demarc> = [];
  siteZDemarcs: Array<Demarc> = [];

  destroy$: Subject<boolean> = new Subject<boolean>();

  public INVENTORY_TOPOLOGY = INVENTORY_TOPOLOGY_ENUM;

  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  sider: Sider;
  notesCount: number;
  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    NOTES_SECTION_NAME: 'notes',
    HISTORY_SECTION_NAME: 'history'
  };

  @ViewChild('panelSide') panelSide;

  @Select(state => state.inventory_site.activeSiderSection) $activeSiderSection: Observable<any>;

  constructor(
    public inventoryService: InventoryService,
    public route: ActivatedRoute,
    public router: Router,
    public demarcService: DemarcService,
    private readonly store: Store,
  ) {}

  loadPermissions() {
    return of({});
  }

  private getInventory() {
    return this.route.parent.snapshot.data.inventory;
  }

  ngOnInit(): void {
    const snapshotInventory = this.getInventory();
    this.inventoryService.findById(snapshotInventory.id)
      .subscribe((res)=>{
        this.inventory = res;
      })
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/inventory']);
    }
  }

  loadDemarcs(inventory: Inventory) {
    if (inventory.topology_id === this.INVENTORY_TOPOLOGY.POINT_TO_POINT) {
      if (inventory.site_a_id) {
        this.demarcService.getDemarcs(inventory.site_a_id, this.inventory.id)
          .subscribe((results) => {
            this.siteADemarcs = results.items;
          }, (err) => {
            console.log('Error loading Site A Demarcs')
          })
      }

      if (inventory.site_z_id) {
        this.demarcService.getDemarcs(inventory.site_z_id, this.inventory.id)
          .subscribe((results) => {
            this.siteZDemarcs = results.items;
          }, (err) => {
            console.log('Error loading Site Z Demarcs')
          })
      }
    }
  }


  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    // TODO: delete this / used $activeSiderSection to reopen sider when back button is used
    // sider.onChange((section: SiderSection) => {
    //   // TODO: Save to state
    //   // this.saveSettings({siderSection: section.name});
    // });

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInventorySiteActiveSiderSection(activeSection.name)
    ]);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME)
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME)
  }
}
