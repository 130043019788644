<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button
    mat-icon-button
    matTooltip="Back"
    (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>

  <span class="toolbar-details-title">{{ svcOrderHeader?.vendor_so_number }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>

  <button
    mat-icon-button
    [disabled]="isEditSvcOrderDisabled"
    (click)="updateSvcOrder()"
    [hidden] = "isEditSvcOrderHidden">
    <ca-icon [key]="'EDIT'" [tooltip]="'Edit Svc Order'"></ca-icon>
  </button>

  <button
    mat-icon-button
    [disabled]="isAddServicesDisabled"
    (click)="newService()"
    [hidden] = "isAddServicesHidden">
    <ca-icon [key]="'ADD_CONTRACT_SECTION'" [tooltip]="'New Service'"></ca-icon>
  </button>

  <button
    mat-icon-button
    [disabled]="isEditServicesDisabled"
    (click)="updateService()"
    [hidden] = "isEditServicesHidden">
    <ca-icon [key]="'EDIT'" [tooltip]="'Edit Service'"></ca-icon>
  </button>

  <button
    mat-icon-button
    [disabled]="isAddTermsConditionDisabled"
    (click) = "addNewSvcOrderTC()"
    [hidden] = "isAddTermsConditionHidden">
    <ca-icon [key]="'ADD_CONTRACT_SECTION'" [tooltip]="'Add T/C'"></ca-icon>
  </button>

  <button
    mat-icon-button
    [hidden]="isEditTermsConditionHidden"
    (click)="editSvcOrderTC()"
    [disabled]="isEditTermsConditionDisabled">
    <ca-icon [key]="'EDIT'" [tooltip]="'Edit SVC Order T/C'"></ca-icon>
  </button>

  <button
    mat-icon-button
    [hidden]="isDeleteTermsConditionHidden"
    (click)="deleteSvcOrderTC()"
    [disabled]="isDeleteTermsConditionDisabled">
    <ca-icon [key]="'DELETE'" [tooltip]="'Delete Svc Order T/C'"></ca-icon>
  </button>


  <app-document-manager-commands
    *ngIf="isDocumentAction"

    [deleteDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DELETE_DOCUMENT_ACTION"
    [deleteDocumentDisablePredicate]="!documentSelection"

    [uploadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.UPLOAD_DOCUMENT_ACTION"
    [uploadDocumentDisablePredicate]="false"

    [downloadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DOWNLOAD_DOCUMENT_ACTION"
    [downloadDocumentDisablePredicate]="!documentSelection"
  >
  </app-document-manager-commands>

  <div [hidden] = "isFilterHidden" class="vertical-divider"></div>

  <button
    mat-icon-button
    matTooltip="Download CSV"
    [disabled]="isCsvDisabled"
    [hidden]="isCsvHidden"
    (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>

  <button
    [hidden] = "isGridSettingsHidden"
    mat-icon-button
    (click)="gridSettings()"
    [disabled]="isGridSettingsDisabled"
  >
    <ca-icon [key]="'GRID_SETTINGS'" [tooltip]="'Settings'"></ca-icon>
  </button>

  <div [hidden] = "isFilterHidden" class="vertical-divider"></div>


  <button
    [hidden] = "isFilterHidden"
    [disabled]="isFilterDisabled"
    [ngClass]="{'on' : isFilterOn}"
    (click)="toggleFilter()"
    mat-icon-button>
    <ca-icon [key]="'FILTER'" [tooltip]="'Filter'"></ca-icon>
  </button>



</mat-toolbar>
