<div
  fxLayout="column"
  fxLayoutGap="16px"
  class="address-filter"
>
  <div fxFlex class="form-group address-entry-container ca-form">
    <ca-default-picker
       hiDensityInput>
    <mat-form-field hiDensityInput>
        <mat-select
            #picker
            class="ca-picker-searchable ca-picker"
            [panelClass]="{'ca-picker-searchable' : true, 'ca-picker': true}"
            (selectionChange)="onSelectionChange($event)"
            (openedChange)="openedChange($event)"
            [formControl]="selectFormControl"
        >
            <mat-form-field
                class="ca-picker-search-container"
                floatPlaceholder="never" color="accent"
                >
                <input title="Search"
                    #searchInput type="text"
                    matInput [formControl]="searchFormControl"
                >
            </mat-form-field>
            <div class="ca-picker-entries"
                #entriesContainer
            >
                <button *ngIf="selectFormControl.value"
                    (click)="clearCountry()"
                    mat-button class="clear-option"
                >Clear
                </button>

                <mat-option
                    *ngFor="let val of entry.lookupItems"
                    [value]="val?.value"
                    >{{ val?.value }} &bull; {{ val?.name }}
                </mat-option>
            </div>
        </mat-select>
    </mat-form-field>
</ca-default-picker>
  </div>
</div>
