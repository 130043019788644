<mat-form-field>
  <input #curr #currencyInput="ngModel" matInput (blur)="onTouched($event)"
    [placeholder]="placeholder" type="text"
    [ngModel]="value | caCurrency: currency"
    [required]="isRequired"
    [readonly]="isReadonly"
    [disabled]="isDisabled"
    [ngModelOptions]="{updateOn:'blur'}"
    (change)="onChange($event)">
</mat-form-field>
