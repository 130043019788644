<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{ user.full_name }}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">

  <div fxFlex fxLayout fxLayoutGap="24px">
    <div fxFlex="0 0 auto">
      <ca-avatar class="details-item avatar-height-adjustment"
                 [uploadEnabled]="false"
                 [text]="getUserInitials()"
                 [size]="'m'"
                 [source]="user.avatar"></ca-avatar>
    </div>

    <div class="entry-list" fxFlex fxLayout="column">

      <div fxFlex class="entry-list-item">
        <label>Email</label>
        <p>
          {{ user?.email }}
        </p>
      </div>

      <div fxFlex class="entry-list-item">
        <label>Username</label>
        <p>
          {{ user?.username }}
        </p>
      </div>

      <div fxFlex class="entry-list-item">
        <label>Mobile Number</label>
        <p>
          {{ user?.mobile_number || 'N/A' }}
        </p>
      </div>

      <div fxFlex class="entry-list-item">
        <label>Status</label>
        <p>
          {{ user?.is_active ? 'Active' : 'Inactive' }}
        </p>
      </div>

      <div fxFlex class="entry-list-item">
        <label>Last Activity Date</label>
        <p>
          {{ user?.last_activity_date ? (user.last_activity_date | caDateTime) : 'N/A' }}
        </p>
      </div>

    </div>

  </div>

</div>
<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
  </div>
  <button mat-button type="submit" (click)="close()">
      Close
  </button>
</mat-dialog-actions>
