
import {debounceTime} from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FilterEntry } from "../filter-entry";
import Query from "../../query/query";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SiteService } from "../../../site/core/site.service";
import { BuildingService } from "../../../building/core/building.service";
import { VendorService } from "../../../vendor/core/vendor.service";
import { CustomerInventoryFilterService } from 'app/customer-inventory/core/customer-inventory-filter.service';

@Component({
  selector: 'ca-sites-filter',
  templateUrl: './sites-filter.component.html',
  providers: [
    SiteService,
    BuildingService,
    VendorService
  ]
})

export class SitesFilterComponent implements OnInit, OnChanges {

  @Input('entry') entry: FilterEntry;
  @Input('query') query: Query;
  @Output() sitesFilterChanged = new EventEmitter();

  sitesFilterForm: FormGroup;

  internalQuery: any;

  readonly FIELDS = {
    SITE: 'site',
    BUILDING: 'building',
    ADDRESS1: 'address1',
    CITY: 'city',
    SUBDIVISION: 'subdivision',
    POSTAL_CODE: 'postal_code',
    VENDOR: 'vendor'
  };

  sites = [];
  siteQuery: Query = new Query({ orderBy: [["site_id", "ASC"]] });
  buildings = [];
  buildingQuery = new Query();
  allVendors = [];
  vendors = [];

  constructor(
    public formBuilder: FormBuilder,
    public siteService: SiteService,
    public buildingService: BuildingService,
    public vendorService: VendorService,
    public customerInventoryFilterService: CustomerInventoryFilterService,
  ) {
  }

  ngOnInit() {
    this.sitesFilterForm.controls['site'].valueChanges.pipe(
      debounceTime(250))
      .subscribe((value) => {
        this.internalQuery[this.FIELDS.SITE] = value;
        this.setQuery();
        this.suggestSites(value);
      }
      );

    this.sitesFilterForm.controls['building'].valueChanges.pipe(
      debounceTime(250))
      .subscribe((value) => {
        this.internalQuery[this.FIELDS.BUILDING] = value;
        this.setQuery();
        this.suggestBuildings(value);
      }
      );

    this.sitesFilterForm.controls['vendor'].valueChanges.pipe(
      debounceTime(250))
      .subscribe((value) => {
        this.internalQuery[this.FIELDS.VENDOR] = value;
        this.setQuery();
        this.suggestVendors(value);
      }
      );

    this.vendorService.findAllWithActiveBANsAndInvoices()
      .subscribe((response) => {
        let items = response.items ? response.items : response;
        this.allVendors = items.map((entry) => {
          return {
            value: entry.name,
            text: entry.name
          }
        });
      });
  }

  suggestSites(q) {
    if (q && q.length) {
      this.siteQuery['where']['site_id'] = { '$ilike': `%${q}%` };
      this.siteService.findAll(this.siteQuery)
        .subscribe((response) => {
          this.sites = response.items.map((entry) => {
            return {
              value: entry.site_id,
              text: entry.site_id
            }
          });
        });
    } else {
      this.sites = [];
    }
  }

  suggestBuildings(q) {
    if (q && q.length) {
      this.buildingQuery.where['q'] = q;
      this.buildingService.advancedSearch(this.buildingQuery)
        .subscribe((response) => {
          this.buildings = response.items.map((entry) => {
            return {
              value: entry.name,
              text: entry.name
            }
          });
        });
    } else {
      this.buildings = [];
    }
  }

  suggestVendors(q) {
    if (q && q.length) {
      this.vendors = this.allVendors.filter((entry) => {
        return entry.value.toLowerCase().includes(q.toLowerCase());
      });
    } else {
      this.vendors = [];
    }
  }

  toggleExact(entry) {
    entry.exact = !entry.exact;
    this.setQuery();
  }

  toggleCase(entry) {
    entry.caseSensitive = !entry.caseSensitive;
    this.setQuery();
  }

  setQuery() {
    let operator = this.entry.caseSensitive ? '$like' : '$ilike';
    let suffix = this.entry.exact ? '' : '%';
    let prefix = this.entry.exact ? '' : '%';

    for (let i in this.FIELDS) {
      let filterField = this.FIELDS[i];
      let queryField = this.entry.sitesFields[filterField]['field'];
      let value = this.internalQuery[filterField];

      if (value) {
        if (filterField === this.FIELDS.SUBDIVISION) {
          this.query.where[queryField] = value;
        } else {
          value = value.trim();
          this.query.where[queryField] = this.query.where[queryField] || {};
          delete this.query.where[queryField]['$ilike'];
          delete this.query.where[queryField]['$like'];
          this.query.where[queryField][operator] = prefix + value + suffix;
        }
      }
      else {
        delete this.query.where[queryField];
      }
    }

    this.sitesFilterChanged.emit(this.query);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['query']) {

      if (!this.sitesFilterForm || !this.sitesFilterForm.controls) {
        this.sitesFilterForm = this.formBuilder.group({
          site: [''],
          building: [''],
          vendor: [''],
        });
      }

      this.setInitialInternalQuery();
    }

    if (changes['entry']) {

      if (!this.entry.sitesFields.subdivision.lookupItems) {
        const subdivisionLookupProvider = this.customerInventoryFilterService.getLookupProvider(this.entry.sitesFields.subdivision.lookupProviderName).provider;

        subdivisionLookupProvider
          .findAll()
          .subscribe(
            result => {
              this.entry.sitesFields.subdivision['lookupProvider'] = subdivisionLookupProvider;
              this.entry.sitesFields.subdivision['lookupItems'] = result;
            }
          );
      }

    }
  }

  setInitialInternalQuery() {
    this.internalQuery = {
      site: '',
      building: '',
      address1: '',
      city: '',
      subdivision: null,
      postal_code: '',
      vendor: null
    };

    this.sitesFilterForm.controls['site'].reset('');
    this.sitesFilterForm.controls['building'].reset('');
    this.sitesFilterForm.controls['vendor'].reset('');
  }
}
