import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TextMaskModule } from 'angular2-text-mask';
import { DxDataGridModule, DxPopoverModule, DxTemplateModule } from 'devextreme-angular';
import { AccordionModule } from 'ngx-accordion';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ConfigService } from '../core/config/config.service';
import { BooleanFilterComponent } from '../core/filter/boolean-filter/boolean-filter.component';
import { MultiSelectComponent } from '../core/filter/multi-select-filter/multi-select-filter.component';
import { NetworkHubLevel1Level2Component } from '../core/filter/network-hub-l1-l2-filter/network-hub-l1-l2-filter.component';
import { OrderNoServiceNoFilterComponent } from '../core/filter/order-no-service-no-filter/order-no-service-no-filter.component';
import { RangeFilterBaseComponent } from '../core/filter/range-filter-base/range-filter-base.component';
import { RangeFilterInputComponent } from '../core/filter/range-filter/range-filter.component';
import { RangeNegativeFilterInputComponent } from '../core/filter/range-negative-filter/range-negative-filter.component';
import { VendorGroupingFilterComponent } from '../core/filter/vendor-grouping-filter/vendor-grouping-filter.component';
import { MaterialModule } from '../material-barrel/material-barrel.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
// tslint:disable-next-line: max-line-length
import { CaNetworkHubCircuitPickerComponent } from '../network-hub/shared/ca-network-hub-circuit-picker/ca-network-hub-circuit-picker.component';
import { CaNetworkHubPickerComponent } from '../network-hub/shared/ca-network-hub-picker/ca-network-hub-picker.component';
import { PermissionsModule } from '../permissions/permissions.module';
import { AlertService } from './alert/alert.service';
import { AvatarComponent } from './avatar/avatar.component';
import { BandwidthPickerComponent } from './bandwidth/bandwidth-picker/bandwidth-picker.component';
import { BrandComponent } from './brand/brand.component';
import { BrandService } from './brand/brand.service';
import { CaDatePickerComponent } from './ca-date-picker/ca-date-picker.component';
import { CaDefaultPickerComponent } from './ca-default-picker/ca-default-picker.component';
import { CaGridTooltipDirective } from './ca-grid-tooltip/ca-grid-tooltip.directive';
import { CaIconComponent } from './ca-icon/ca-icon.component';
import { CaImageCropperComponent } from './ca-image-cropper-dialog/ca-image-cropper-dialog.component';
import { CaPersonPickerComponent } from './ca-person-picker/person-picker.component';
import { TrimDirective } from './ca-trim/trim';
import { ChargeNotesComponent } from './charge-notes/charge-notes.component';
import { ChartService } from './chart/chart.service';
import { ConfirmDeleteButtonComponent } from './confirm-delete-button/confirm-delete-button.component';
import { CaCurrencyComponent } from './currency/ca-currency/ca-currency.component';
import { CustomerInventoryPickerComponent } from './customer-inventory/customer-inventory-picker/customer-inventory-picker.component';
import { AlertDialogComponent } from './dialog/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import { ConfirmVATDialogComponent } from '../invoice/shared/invoice-vat-warning-dialog/confirm-dialog-vat-warning.component';
import { DialogService } from './dialog/dialog.service';
import { PromptDialogComponent } from './dialog/prompt-dialog/prompt-dialog.component';
import { SaveDialogComponent } from './dialog/save-dialog/save-dialog.component';
import { SimpleAlertDialogComponent } from './dialog/simple-alert-dialog/simple-alert-dialog.component';
import { DocumentFilterService } from './document/core/document-filter.service';
import { DocumentGridService } from './document/core/document-grid.service';
import { DocumentManageDialogComponent } from './document/document-manage-dialog/document-manage-dialog.component';
import { DocumentManagerCommandsComponent } from './document/document-manager-commands/document-manager-commands.component';
import { DocumentManagerComponent } from './document/document-manager/document-manager.component';
import { DropzoneDirective } from './dropzone/dropzone.directive';
import { EntityLockAlertComponent } from './entity-lock/entity-lock-alert.component';
import { EntityLockService } from './entity-lock/entity-lock.service';
import { FileService } from './file/file.service';
import { FlowComponent } from './flow/flow.component';
import { FlowService } from './flow/flow.service';
import { FocusDirective } from './focus/focus.directive';
import { CaCurrencyPipe } from './format/ca-currency.pipe';
import { CaCurrencyIdPipe } from './format/ca-currency-id.pipe';
import {CaDatePipe, CasDatePipe, CasDateTimePipe} from './format/ca-date.pipe';
import { CaInvoiceCurrencyPipe } from './format/ca-invoice-currency.pipe';
import { DateTimePipe } from './format/date-time.pipe';
import { FormatService } from './format/format.service';
import { PhonePipe } from './format/phone.pipe';
import { HistoryCompareDialogComponent } from './history/history-compare-dialog/history-compare-dialog.component';
import { HistoryComponent } from './history/history.component';
import { HistoryPipe } from './history/history.pipe';
import { HistoryService } from './history/history.service';
import { LoaderComponent } from './loader/loader.component';
import { NavigationService } from './navigation/navigation.service';
import { NewlinePipe } from './new-line/new-line.pipe';
import { NotesService } from './notes/core/notes.service';
import { NotesListComponent } from './notes/notes-list.component';
import { NotesComponent } from './notes/notes.component';
import { AccountNotesListComponent } from "./notes/account-notes-list/account-notes-list.component";
import { NotificationComponent } from './notification/notification-component.component';
import { NotificationService } from './notification/notification.service';
import { PageInfoService } from './page-info/core/page-info.service';
import { PageInfoComponent } from './page-info/page-info.component';
import { PagerSizeComponent } from './pager-size/pager-size.component';
import { PagerComponent } from './pager/pager.component';
import { CaPickerComponent } from './picker/ca-picker.component';
import { ContractQuoteFailureReason } from './quote/failure-reason.pipe';
import { SortingService } from './sorting/sorting.service';
import { SubdivisionTitlePipe } from './subdivision/subdivision-title.pipe';
import { TabService } from './tabs/tab.service';
import { ThemeSettingsService } from './theme/theme-settings.service';
import { ToolbarCommandComponent } from './toolbar-command/toolbar-command.component';
import { TruncatePipe } from './truncate/truncate.pipe';
import { UserModalComponent } from './user-modal/user-modal.component';
import { UserSessionModule } from './user-session/user-session.module';
import { ValidationService } from './validation/validation.service';
import { RandomService } from './random/random.service';
import {CasActionDirective} from "./permissions/cas-action.directive";
import {CaNumberPipe} from "./format/ca-number.pipe";
import { CaCurrencyPickerComponent } from 'app/location/shared/currency-picker/ca-currency-picker.component';
import {DatePickerFormatDirective} from "./ca-date-picker/directives/date-picker-directive";
import { CaContactPickerComponent } from './ca-contact-picker/ca-contact-picker.component';
import {ContactSectionComponent} from "../order/shared/contact-section/contact-section.component";
import {SingleContactManageDialogComponent} from "../order/shared/single-contact-manage-dialog/single-contact-manage-dialog.component";
import { ConfirmCancelButtonComponent } from './confirm-cancel-button/confirm-cancel-button.component';
import { ConfirmCancelDialogComponent } from './dialog/confirm-cancel-dialog/confirm-cancel-dialog.component';
import {CaCountryPickerComponent} from "../location/shared/country-picker/ca-country-picker.component";
import { CaYesNoPipe } from './format/ca-yesno.pipe';
import { CustomerService } from 'app/customer/core/customer.service';
import { CaCustomFieldsComponent } from './ca-custom-fields/ca-custom-fields.component';
import { CaLookupModelPickerComponent } from './lookup-model-picker/ca-lookup-model-picker.component';
import {CaPercentagePipe} from "./format/ca-percentage.pipe";
import {SuspenseStatusPickerComponent} from "./ca-suspense-status-picker/ca-suspense-status-picker.component";

@NgModule({
  imports: [
    MatSnackBarModule,
    MatBadgeModule,
    CommonModule,
    UserSessionModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    DxDataGridModule,
    DxPopoverModule,
    DxTemplateModule,
    TextMaskModule,
    PermissionsModule,
    AccordionModule,
    ImageCropperModule,
    NgxMaskModule,
  ],
  exports: [
    MatBadgeModule,
    CaPickerComponent,
    HistoryComponent,
    NotesComponent,
    NotesListComponent,
    AccountNotesListComponent,
    AvatarComponent,
    FocusDirective,
    SubdivisionTitlePipe,
    CaDatePipe,
    CasDatePipe,
    CasDateTimePipe,
    ContractQuoteFailureReason,
    CaCurrencyPipe,
    CaCurrencyIdPipe,
    CaNumberPipe,
    CaPercentagePipe,
    CaInvoiceCurrencyPipe,
    PagerComponent,
    PagerSizeComponent,
    PhonePipe,
    HistoryPipe,
    TruncatePipe,
    FlowComponent,
    DocumentManagerComponent,
    DocumentManagerCommandsComponent,
    ConfirmDeleteButtonComponent,
    CaCurrencyComponent,
    CaCurrencyPickerComponent,
    BrandComponent,
    TextMaskModule,
    LoaderComponent,
    DropzoneDirective,
    BandwidthPickerComponent,
    CaDatePickerComponent,
    RangeFilterInputComponent,
    RangeNegativeFilterInputComponent,
    VendorGroupingFilterComponent,
    MultiSelectComponent,
    BooleanFilterComponent,
    NewlinePipe,
    CustomerInventoryPickerComponent,
    CaIconComponent,
    CaDefaultPickerComponent,
    CaGridTooltipDirective,
    CaNetworkHubPickerComponent,
    CaNetworkHubCircuitPickerComponent,
    CaImageCropperComponent,
    TrimDirective,
    ToolbarCommandComponent,
    RangeFilterBaseComponent,
    NotificationComponent,
    CaPersonPickerComponent,
    RangeFilterBaseComponent,
    ChargeNotesComponent,
    OrderNoServiceNoFilterComponent,
    NetworkHubLevel1Level2Component,
    CasActionDirective,
    ContactSectionComponent,
    SingleContactManageDialogComponent,
    ConfirmCancelButtonComponent,
    CaCountryPickerComponent,
    CaLookupModelPickerComponent,
    CaCustomFieldsComponent,
    SuspenseStatusPickerComponent
  ],
  providers: [
    DialogService,
    AlertService,
    NotesService,
    NavigationService,
    HistoryService,
    ThemeSettingsService,
    FormatService,
    ChartService,
    SortingService,
    DocumentGridService,
    ConfigService,
    FlowService,
    TabService,
    DatePipe,
    TruncatePipe,
    BrandService,
    PageInfoService,
    DocumentFilterService,
    NewlinePipe,
    CaDatePipe,
    CasDatePipe,
    CasDateTimePipe,
    DateTimePipe,
    ContractQuoteFailureReason,
    NotificationService,
    ValidationService,
    EntityLockService,
    FileService,
    RandomService,
    DateTimePipe,
    CustomerService,
    CaCurrencyComponent
  ],
  declarations: [
    HistoryComponent,
    CaPickerComponent,
    NotesComponent,
    NotesListComponent,
    AccountNotesListComponent,
    AvatarComponent,
    FocusDirective,
    ConfirmDialogComponent,
    ConfirmVATDialogComponent,
    SubdivisionTitlePipe,
    TruncatePipe,
    CaDatePipe,
    CasDatePipe,
    CasDateTimePipe,
    ContractQuoteFailureReason,
    CaCurrencyPipe,
    CaCurrencyIdPipe,
    CaNumberPipe,
    CaPercentagePipe,
    CaInvoiceCurrencyPipe,
    HistoryPipe,
    PagerComponent,
    PagerSizeComponent,
    PhonePipe,
    FlowComponent,
    DocumentManageDialogComponent,
    DocumentManagerComponent,
    DocumentManagerCommandsComponent,
    PageInfoComponent,
    AlertDialogComponent,
    HistoryCompareDialogComponent,
    UserModalComponent,
    DateTimePipe,
    ConfirmDeleteButtonComponent,
    CaCurrencyComponent,
    CaCurrencyPickerComponent,
    PromptDialogComponent,
    BrandComponent,
    SaveDialogComponent,
    SimpleAlertDialogComponent,
    LoaderComponent,
    DropzoneDirective,
    BandwidthPickerComponent,
    CaDatePickerComponent,
    RangeFilterInputComponent,
    RangeNegativeFilterInputComponent,
    VendorGroupingFilterComponent,
    MultiSelectComponent,
    BooleanFilterComponent,
    NewlinePipe,
    CustomerInventoryPickerComponent,
    CaIconComponent,
    CaDefaultPickerComponent,
    CaGridTooltipDirective,
    CaNetworkHubCircuitPickerComponent,
    CaNetworkHubPickerComponent,
    CaImageCropperComponent,
    TrimDirective,
    ToolbarCommandComponent,
    RangeFilterBaseComponent,
    NotificationComponent,
    CaPersonPickerComponent,
    EntityLockAlertComponent,
    ChargeNotesComponent,
    OrderNoServiceNoFilterComponent,
    NetworkHubLevel1Level2Component,
    CasActionDirective,
    DatePickerFormatDirective,
    CaContactPickerComponent,
    ContactSectionComponent,
    SingleContactManageDialogComponent,
    ConfirmCancelButtonComponent,
    ConfirmCancelDialogComponent,
    CaCountryPickerComponent,
    CaYesNoPipe,
    CaLookupModelPickerComponent,
    CaCustomFieldsComponent,
    SuspenseStatusPickerComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ConfirmVATDialogComponent,
    SaveDialogComponent,
    AlertDialogComponent,
    PromptDialogComponent,
    SimpleAlertDialogComponent,
    HistoryCompareDialogComponent,
    UserModalComponent,
    CaDefaultPickerComponent,
    CaImageCropperComponent,
    EntityLockAlertComponent,
    SingleContactManageDialogComponent,
    ConfirmCancelDialogComponent,
    CaCountryPickerComponent,
    CaLookupModelPickerComponent,
    CaCustomFieldsComponent
  ]
})
export class SharedModule {
}
