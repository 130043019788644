import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Bandwidth} from "./bandwidth";
import {Restangular} from "ngx-restangular";

@Injectable()
export class BandwidthService extends BaseService<Bandwidth> {

  constructor(public restangular: Restangular) {
    super('bandwidth', restangular);
  }

  findAllTypes(query?) {
    return this.service().all('bandwidth').all('types').customGET(null, this.toFilter(query));
  }

  findAllByType(type:any) {
    return this.service().all('bandwidth').one('types', type).customGET(null);
  }

}
