import {Observable} from "rxjs";
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import {VendorService} from "./vendor.service";
import Query from "../../core/query/query";
import {map} from "rxjs/operators";

export class VendorEntityLookupProvider extends IFilterLookupProvider {
  constructor(public vendorService: VendorService) {
    super();
  }

  findAll(searchQuery): Observable<any> {
    let query = searchQuery? searchQuery : new Query();

    query.orderBy = [['name', 'ASC']];
    query.limit = 100000
    return this.vendorService.findAllinEntityList(query)
      .pipe(map((result: any) => {
        if(result.items) {
          return {
            items: result.items.map(entry =>  entry.name),
            total: result.total
          };
        } else {
          return {
            items: result.map(entry => entry.name),
            total: result.length
          };
        }
      }));
  }
}
