export const quotingReportFilterContext = {
  filters: [
    {
      field: 'vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 1,
      visible: true
    },
    /* {
      field: 'quoteService.service_type',
      label: 'Service Type',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'serviceType',
      index: 2
    }, */
    /* {
      field: 'quoteService.bandwidth',
      label: 'Circuit Bandwidth',
      type: 'lookup',
      lookupProviderName: 'bandwidth',
      index: 3
    }, */
    /*{
      field: 'tmp_service_term',
      label: 'Term',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'serviceTerm',
      index: 4
    },*/
    /*{
      field: 'quoteService.quote.close_date',
      label: 'Close Date',
      type: 'date',
      index: 5,
    },*/
    {
      field: 'received_date',
      label: 'Received Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 6,
    },
    {
      field: 'quoteService.create_date',
      label: 'Create Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 7,
    },
  ]
};
