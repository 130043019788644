import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[caDropzone]'
})
export class DropzoneDirective {

  @HostBinding('style.background') public background = '#fafafa';
  @HostBinding('class.ca-no-drag') public caNoDrag = true;
  @HostBinding('class.ca-drag-over') public caDragOver = false;

  @Output() dropped = new EventEmitter();

  constructor() { }

  @HostListener('dragover', ['$event']) onDragOver(evt){
    evt.preventDefault();
    evt.stopPropagation();
    this.caDragOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt){
    evt.preventDefault();
    evt.stopPropagation();
    this.caDragOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop(evt){
    evt.preventDefault();
    evt.stopPropagation();
    let files = evt.dataTransfer.files;
    if(files.length > 0){
      this.dropped.emit(files);
    this.caDragOver = false;
    }
  }

}
