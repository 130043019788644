<div style="box-sizing: border-box !important;
    display: flex !important;
    flex-flow: row wrap !important;" fxFlex="50%" class="ca-form"
     *ngFor="let val of (entry?.lookupItems.items ? toArray(entry?.lookupItems.items) : toArray(entry?.lookupItems))">
  <mat-checkbox [name]="entry?.label" class="filter-checkbox"
                (change)="ngOnMultiselectChange(entry, val, $event, entry.label)"
                [checked]="internalMultiselectInput[val.key != null ? val.key : val]">
    <span>{{val.value ? val.value : val}}</span>
  </mat-checkbox>
</div>
