import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {Router} from "@angular/router";

@Component({
  selector: 'app-vat-warning-dialog',
  templateUrl: './confirm-dialog-vat-warning.component.html',
})
export class ConfirmVATDialogComponent implements OnInit {

  title = 'VAT GL Warning';
  bodyText = '';
  hideTitle = false;
  getButtonLabel = 'Rule Execution';
  proceedButtonLabel = 'Proceed';
  invoiceID = '';
  vendorID = '';
  accountID = '';

  constructor(public dialogRef: MatDialogRef<ConfirmVATDialogComponent>,
              public router: Router) {
  }

  ngOnInit() {
  }

  proceed() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
