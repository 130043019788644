import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {VendorRemitAddressListComponent} from "./vendor-remit-address-list/vendor-remit-address-list.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: VendorRemitAddressListComponent
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class VendorRemitAddressRoutingModule { }
