import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class ContractShellVendorGridService extends GridService {


  constructor(public userSettings: UserSettingsService) {
    super("app.contract.contract-shell-vendor-grid", userSettings);
  }


  defaultColumns = [
      {
        dataField: 'name',
        caption: 'Name',
        width: 150,
        columnAutoWidth: false
      },
      {
        dataField: 'description',
        caption: 'Description',
        width: 150,
        columnAutoWidth: false
      },
      {
        dataField: 'company_name',
        caption: 'Company Name',
        width: 150,
        columnAutoWidth: false
      },
      {
        dataField: 'total_schedules',
        caption: 'Total Schedules',
        width: 150,
        allowSorting: false,
        columnAutoWidth: false
      },
      {
        dataField: 'active_schedules',
        caption: 'Active Schedules',
        width: 150,
        allowSorting: false,
        columnAutoWidth: false
      },
    {
      dataField: 'commitment',
      caption: 'Commit Totals',
      cellTemplate: "currencyCommitmentTemplate",
      width: 150,
      allowSorting: false,
      columnAutoWidth: false
    }
    ];

}
