<app-invoice-details-toolbar
  (toggleFilterRequested)="toggleSider(SECTIONS.FILTER_SECTION_NAME)"
  [isFilterOn]="($activeSiderSection | async) == SECTIONS.FILTER_SECTION_NAME"
  (toggleNotesRequested)="toggleSider(SECTIONS.NOTES_SECTION_NAME)"
  [isNotesOn]="($activeSiderSection | async) == SECTIONS.NOTES_SECTION_NAME"
  (toggleHistoryRequested)="toggleSider(SECTIONS.HISTORY_SECTION_NAME)"
  [isHistoryOn]="($activeSiderSection | async) == SECTIONS.HISTORY_SECTION_NAME"

  (glChargeViewSwitchRequested)="handleMasterDetailSwitch()"
  [isGLChargeViewSwitchDisabled]="false"
  [isGLChargeViewSwitchHidden]="false"
  [isGLChargeViewSwitchOn]="GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled"
  [isCSVDisabled]="false"
  (downloadCSVRequested)="onDownloadCSVRequested()"
  [isDoNotProcessHidden]="true"
  [isGoToRuleExecutionPageHidden]="false"
  [isDocumentTypeHidden]="true"
  [isAddDisputeHidden]="true"
  [isDeleteInvoiceHidden]="false"
  [isOnHoldHidden]="false"
  [isOnHoldDisabled]="true"
  [isChangeAdjustmentHidden]="true"
  [isAddDirectGLHidden]="true"
  [isEditDirectGLHidden]="true"
  [isGridSettingsDisabled]="false"
  (openGridSettingsRequested)="onOpenGridSettingsRequested()"
  [isFilterDisabled]="false"

  [isDropDownFileHidden] = "false"
  [isDropDownCsvHidden] = "false"
  [isDropDownCsvDisabled] = "false"
  [isDropDownUploadDocumentDisabled] = "true"

  [isDownloadContainerHidden]="true"
  [isDownloadPDFHidden]="true"
  [isCSVHidden]="true"
  [notesCount]="notesCount"

  (backRequested)="back()"
></app-invoice-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
  <app-invoice-details-tabs [activeTabIndex]="6" [invoice]="invoice"></app-invoice-details-tabs>
  <div class="app-content app-content-list tabbed-content tabbed-content-no-scroll-except-first-ch" style="top: 0;">
    <!-- Tabs Body -->
    <div
      [ngClass]="{'master-details-enabled': GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled }"
      class="tabs-body master-detail-arrow-color tabs-disable-scroll"
    >
      <dx-data-grid
        class="app-grid-with-pager"
        style="height: 100%"
        #chargeGrid
        [columns]="GL_CHARGE_DETAIL_TAB_INFO.columns"
        (onSelectionChanged)="
          GL_CHARGE_DETAIL_TAB_INFO.selectionChanged($event)
        "
        [masterDetail]="{
          enabled: GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled,
          template: 'detail'
        }"
        keyExpr="id"
        [dataSource]="GL_CHARGE_DETAIL_TAB_INFO.dataSource"
        (onCellPrepared)="isExpandable($event)"
        (onRowExpanded)="rowExpanded($event)"
        (onRowCollapsed)="rowCollapsed($event)"
        (onCellClick)="onGLChargesCellClick($event)"
        [showRowLines]="true"
        [showColumnLines]="false"
        [allowColumnResizing]="true"
      >
        <dxo-sorting mode="none"></dxo-sorting>
        <dxo-paging [pageSize]="10" [enabled]="false"></dxo-paging>
        <div class="icon-td" *dxTemplate="let d of 'glWarningTemplate'" style="position: relative;">
          <a [ngClass]="d.data && d.data.gl_warning_type ? 'icon-danger' : 'disabled'"
             class="charge-grid-icons">
            <ca-icon [key]="'DISPUTE'" [innerClass]="'charge-grid-icon'" matTooltip="{{d.data && d.data.gl_warning_type ? getHoverMessage(d.data.gl_warning_type) : ''}}" matTooltipClass="line-broken-tooltip"></ca-icon>
          </a>
        </div>


        <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caCurrency:d.data.currency}}
          </div>
        </div>
        <div *dxTemplate="let d of 'dateTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caDate }}
          </div>
        </div>
        <div *dxTemplate="let d of 'chargeRateTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caNumber }}
          </div>
        </div>
        <div *dxTemplate="let d of 'numberTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caNumber }}
          </div>
        </div>

        <!--FLAT VIEW COLUMNS-->
        <div *dxTemplate="let d of 'glOutputAmountTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caCurrency:invoice?.currency }}
          </div>
        </div>
        <!--FLAT VIEW COLUMNS-->

        <div *dxTemplate="let d of 'chargeCodedTemplate'">
          <div caGridTooltip>{{ d.value ? "Coded" : "Uncoded" }}</div>
        </div>
        <div *dxTemplate="let d of 'booleanTemplate'">
          <div caGridTooltip>{{ d.value ? 'Missing' : (d.value === false ? 'Found' : '')}}</div>
        </div>
        <div *dxTemplate="let charge of 'detail'" class="template-grid">
          <dx-data-grid
            class="app-grid-with-pager invoice-gl-charge-details-template"
            [columns]="GL_CHARGE_DETAIL_TAB_INFO.masterDetailColumns"
            [dataSource]="charge.data.gl_output"
            [showBorders]="true"
            [showRowLines]="true"
            [showColumnLines]="false"
            [allowColumnResizing]="true"
            [columnMinWidth]="50"

          >
            <div *dxTemplate="let d of 'amountCurrencyTemplate'">
              <div caGridTooltip class="right">
                {{ d.value | caCurrency: invoice?.currency}}
              </div>
            </div>
            <div *dxTemplate="let d of 'booleanTemplate'">
              <div caGridTooltip>{{ d.value ? 'Missing' : (d.value === false ? 'Found' : '')}}</div>
            </div>
          </dx-data-grid>
        </div>
      </dx-data-grid>
      <div class="grid-pager">
        <ca-pager
          [pageContext]="this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled ? glRuleExecutionChargesGridService : glRuleExecutionChargesFlatGridService"
          [gridPager]="defaultGridPager"
          [query]="query"
          class="app-pager ca-default-border-top"
          (onChange)="onPagerChange()"
        ></ca-pager>
      </div>
    </div>
  </div>
  <mat-sidenav #panelSide
               [mode]="'side'"
               position="end"
               [opened]="false">
    <ca-history
      #history
      [entityId]="invoice?.invoice_id"
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME) }"
      [entityType]="'invoice'"
      [invoice]="invoice"
    ></ca-history>

    <ca-notes
      #testNotes
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
      [entityId]="invoice?.invoice_id"
      [entityType]="SYSTEM_MODULE.INVOICE"
      (countChanged)="onNotesCountChanged($event)"
      (notesCountChanged)="getDNPInfo($event)"
    >
      <ca-account-notes [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                        [entityId]="invoice?.account?.id"
                        [entityType]="SYSTEM_MODULE.ACCOUNT"
                        [entityName]="'Account'"
                        [enableReply]="false"></ca-account-notes>
    </ca-notes>

<!--    <gl-filters-->
<!--      [activeFilter]="activeFilter"-->
<!--      [chargeQuery]="query"-->
<!--      [masterDetailsEnabled]="GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled"-->
<!--      (chargeDetailsCleared)="clearFilter()"-->
<!--      (chargeDetailsFiltered)="filterData($event)"-->
<!--      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"-->
<!--    ></gl-filters>-->

    <app-filter-container
      *ngIf="!GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled"
      [ngClass]="{'hidden': activeFilter !== 'flatChargeDetails'}"
      [query]="query"
      [filterService]="glRuleExecutionFlatFilterService"
      (filterRequested)="filterData($event)"
      (clearFilterRequested)="clearFilter()"></app-filter-container>

    <app-filter-container
      *ngIf="GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled"
      [ngClass]="{'hidden': activeFilter !== 'groupedChargeDetails'}"
      [query]="query"
      [filterService]="invoiceGLRuleExecutionFilterService"
      (filterRequested)="filterData($event)"
      (clearFilterRequested)="clearFilter()"></app-filter-container>

  </mat-sidenav>
</mat-sidenav-container>
