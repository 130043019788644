import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';

@Component({
  selector: 'ca-image-cropper',
  templateUrl: 'ca-image-cropper-dialog.component.html',
  styleUrls: ['./ca-image-cropper-dialog.component.css']
})

export class CaImageCropperComponent implements OnInit {
  image: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperReady = false;
  initialImage: any;
  cropperPreviewMessage: string
  text: string;
  hasImage = false;
  avatarCropperClass: string;
  vendorNoImagePlaceholderStyle: any;
  imageToSave: string;
  loadedImageQuality = 92; // 92 is default quality

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'avatar';

  @ViewChild('upload') upload: ElementRef;
  constructor(public dialogRef: MatDialogRef<CaImageCropperComponent>) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    if (this.image) {
      this.initialImage = this.croppedImage = this.image;
      this.cropperPreviewMessage = this.messages.get('GENERATING');
      this.hasImage = true;
    }

    if (this.avatarCropperClass === 'vendor-cropper') {
      this.vendorNoImagePlaceholderStyle = 'vendor-avatar';
    }
  }

  clear() {
    this.image = this.croppedImage = this.imageToSave = null;
    this.hasImage = false;
  }

  public close() {
    if (this.initialImage) {
      this.dialogRef.close(this.initialImage);
    } else {
      this.dialogRef.close();
    }
  }

  public save() {
    this.dialogRef.close(this.imageToSave)
  }

  browse() {
    this.upload.nativeElement.click()
  }

  fileChangeEvent(event: any): void {
    if (event.srcElement.files.length > 0) {
      this.cropperPreviewMessage = this.messages.get('GENERATING')

      this.resizeImage(event);
    }
  }

  public extractMimeType(dataUrl) {
    return dataUrl.indexOf('image/png') > -1 ? 'image/png' : 'image/jpeg';
  }

  public calculateDimensions(img) {
    let MAX_WIDTH = 400;
    let MAX_HEIGHT = 400;
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }

    return { width, height };
  }

  resizeImage(fileEvent) {
    const reader = new FileReader;

    reader.onload = () => {

      fileEvent.srcElement.value = null;

      let img = document.createElement('img');
      img.src = reader.result.toString();
      img.onload = () => {
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        let dim = this.calculateDimensions(img);

        ctx.drawImage(img, dim.width, dim.height);
        canvas.width = 400;
        canvas.height = 400;
        ctx = canvas.getContext('2d');

        // Create wrapper and add image over an image, then center it
        let wrapper = new Image();
        ctx.drawImage(wrapper, 0, 0, 400, 100);
        ctx.globalAlpha = 1;
        ctx.drawImage(img,
          (canvas.width - dim.width) * 0.5,
          (canvas.height - dim.height) * 0.5,
          dim.width, dim.height);

        const source = canvas.toDataURL(this.extractMimeType(reader.result));

        // Do standard needed stuff
        this.image = source;
        this.croppedImage = this.image;
        this.hasImage = true;
      };
    };

    reader.readAsDataURL(fileEvent.target.files[0]);
  }

  imageCropped(image: string) {
    this.imageToSave = image;
  }

  imageLoaded() {
    this.cropperReady = true;
    this.cropperPreviewMessage = this.messages.get('GENERATING')
  }

  loadImageFailed() {
    this.cropperPreviewMessage = this.messages.get('FAILED')
  }

}
