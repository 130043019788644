import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import {DictionaryService} from "../../dictionary/core/dictionary.service";
import {Observable} from "rxjs";
import {LOOKUP_MODELS_ENUM} from "../../dictionary/core/lookup-models.enum";
import {map} from "rxjs/operators";
export class SubclientLookupProvider extends IFilterLookupProvider{

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  readonly lookupModel: string = LOOKUP_MODELS_ENUM.SUBCLIENT.modelName;


  findAll(searchQuery?): Observable<any> {
    return this.dictionaryService.getByLookup(this.lookupModel, searchQuery).pipe(
      map((result: any) => {
        return {
          items: result.items.map((entry) => {
            return entry.value
          }),
          total: result.total
        }
      }));
  }
}
