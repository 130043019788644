<mat-toolbar color="accent" mat-scroll-shrink>

  <span class="sneak-peek-title">{{ inventory?.unique_id }}</span>

  <span class="example-fill-remaining-space"></span>

  <button mat-icon-button (click)="closeSide()">
    <ca-icon [key]="'CLOSE'"></ca-icon>
  </button>
</mat-toolbar>
<div class="ca-card ca-card-sneak-peek">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Vendor</label>
          <p>
            {{ inventory?.vendor?.name || 'N/A'}}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Type</label>
          <p>
            {{ inventory?.type?.value }}
          </p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Install Date</label>
          <p>
            {{ (inventory?.install_date | caDate) || 'N/A'}}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Status</label>
          <p>
            {{ inventory?.inventory_status?.value || 'N/A'}}
          </p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Internal ID</label>
          <p>
            {{ inventory?.internal_id || 'N/A'}}
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<!--<mat-toolbar color="accent" mat-scroll-shrink *ngIf="inventory?.site_a_id || inventory.site_z_id">
  <span class="title muted">Sites</span>
</mat-toolbar>-->
  <div *ngIf="inventory?.topology_id === INVENTORY_TOPOLOGY.POINT_TO_POINT">
    <div class="ca-card ca-card-sneak-peek">
      <mat-card>
        <mat-card-content>
          <div class="flex-item" fxFlex>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Site A (Carrier Location)</label>
                <p>
                  {{ inventory?.siteA?.site_id || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Vendor</label>
                <p>
                  {{ inventory?.siteA?.vendor?.name || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Building</label>
                <p>
                  {{ inventory?.siteA?.building?.name || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Site type</label>
                <p>
                  {{ inventory?.siteA?.type?.value || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Address</label>
                <p>
                  {{ inventory?.siteA?.building?.address?.address1 || 'N/A' }}
                </p>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- site z -->
    <div class="ca-card ca-card-sneak-peek">
      <mat-card>
        <mat-card-content>
          <div class="flex-item" fxFlex>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Site Z (Customer Location)</label>
                <p>
                  {{ inventory?.siteZ?.site_id || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Vendor</label>
                <p>
                  {{ inventory?.siteZ?.vendor?.name || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Building</label>
                <p>
                  {{ inventory?.siteZ?.building?.name || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Site type</label>
                <p>
                  {{ inventory?.siteZ?.type?.value || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Address</label>
                <p>
                  {{ inventory?.siteZ?.building?.address?.address1 || 'N/A' }}
                </p>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
</div>
