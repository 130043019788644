<div>
  <div class="mat-dialog-top-spacer"></div>
<div class="ca-dialog-title">
  <h1 mat-dialog-title>
    <span [innerHTML]="getTitle()"></span>
  </h1>
</div>

<div mat-dialog-content
     class="default-card ca-dialog-content" *ngIf="form">
  <ca-flow [step]="flow?.currentStep"
           [flow]="flow"
           (onStepSelection)="onStepSelection($event)"></ca-flow>
  <div class="flow-content-dialog">
    <form *ngIf="form" [formGroup]="form" class="ca-form" >

      <!-- FIRST STEP -->
      <div *ngIf="flow.isCurrentStep(STEPS.FORM)"
           class="flow-content-dialog-content">

        <div class="flex-container first-step-container" fxLayout>
          <div fxLayout="column" fxFlex="45%" class="caGapRight">
            <div
              fxFlex="{{UI.addressColumnHeight}}">
              <label *ngIf="form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.P2P">Site A</label>
               <ca-address-selection caInput [address]="form?.controls.address?.value" class="quote-address"
                                    [ngClass]="{'quote-address-vertical': form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.P2P}"
                                    (addressChanged)="onAddressChanged($event)"
                                    [required]="true"></ca-address-selection>
            </div>
            <div *ngIf="form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.P2P"
                 fxFlex="{{UI.addressColumnHeight}}">
              <label>Site Z</label>
              <ca-address-selection caInput [address]="form?.controls.secondary_address?.value" class="quote-address"
                                    [ngClass]="{'quote-address-vertical': form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.P2P}"
                                    (addressChanged)="onSecondaryAddressChanged($event)"
                                    [required]="form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.P2P ? true : false"></ca-address-selection>
            </div>
          </div>

          <div fxFlex="55%">
            <div fxLayout="row wrap" *ngIf="form?.controls.quote_service_type_id?.value !== QUOTING_SERVICE_TYPE.OTHER">
              <div fxFlex class="caGap caGapRight">
                <ca-date-picker caInput required="true" [disabled]="isUpdate" [minDate]="now" placeholder="Desired Install Date"
                                formControlName="desired_install_date"></ca-date-picker>
              </div>
              <div fxFlex class="caGap">
                <ca-service-type-picker
                  caInput
                  placeholder="Service Type"
                  formControlName="service_type"
                  [disabled]="isUpdate"
                  [pickerForm]="form"
                  [clearEnabled]="true"
                  [searchEnabled]="true"
                  [required]="true"
                  [standalone]="true"
                  [query]="inventoryTechnologyQuery"
                  (onPickerChange)="serviceTypeChanged($event)">
                </ca-service-type-picker>
                <mat-hint class="ca-select-hint"
                          *ngIf="form?.hasError('required', 'service_type') && form?.controls.service_type.touched">
                  {{VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </div>

            </div>

            <div fxLayout="row" *ngIf="form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.OTHER">
              <div class="flex-item caGap caGapRight" fxFlex>
                <ca-client-service-type-picker
                  caInput
                  placeholder="Service Type"
                  formControlName="client_service_type"
                  [disabled]="isUpdate"
                  [pickerForm]="form"
                  [clearEnabled]="true"
                  [searchEnabled]="false"
                  [standalone]="true"
                  (onPickerChange)="clientServiceTypeChanged($event)">
                </ca-client-service-type-picker>
              </div>
            </div>

            <div fxLayout="row wrap" *ngIf="form?.controls.quote_service_type_id?.value !== QUOTING_SERVICE_TYPE.OTHER">
              <div fxFlex class="caGap caGapRight">
                <ca-bandwidth-picker
                  caInput
                  name="circuit_bandwidth_id"
                  formControlName="circuit_bandwidth_id"
                  placeholder="Bandwidth"
                  [pickerForm]="form"
                  [searchEnabled]="true"
                  [required]="true"
                  [disabled]="isUpdate"
                  (onPickerChange)="onBandwidthChange($event, 'circuitBandwidth')"
                >
                </ca-bandwidth-picker>

                <mat-hint class="ca-select-hint"
                          *ngIf="form?.hasError('required', 'circuit_bandwidth_id') && form?.controls.circuit_bandwidth_id.touched">
                  {{VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </div>
              <div fxFlex class="caGap">
                <ca-bandwidth-picker
                  caInput
                  name="port_bandwidth_id"
                  formControlName="port_bandwidth_id"
                  placeholder="Port Bandwidth"
                  [pickerForm]="form"
                  [searchEnabled]="true"
                  [disabled]="isUpdate"
                  (onPickerChange)="onBandwidthChange($event, 'portBandwidth')"
                >
                </ca-bandwidth-picker>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center" fxLayoutWrap="true" style="margin-bottom: 14px">
              <div fxFlex="33%" *ngFor="let term of terms">
                <mat-checkbox [disabled]="isUpdate"
                              [(ngModel)]="term.selected"
                              [ngModelOptions]="{standalone: true}">
                  {{term.text}}
                </mat-checkbox>
              </div>
            </div>

            <div class="flex-container caGap" fxLayoutAlign="start center"
                 *ngIf="form?.controls.quote_service_type_id?.value !== QUOTING_SERVICE_TYPE.OTHER">
              <mat-slide-toggle [disabled]="isUpdate" formControlName="diversity" style="margin-right: 24px">
                Diversity
              </mat-slide-toggle>
              <div class="flex-item" fxFlex="50%">
                <ca-vendor-picker
                  caInput
                  formControlName="current_vendor_id"
                  [required]="form?.controls.diversity.value"
                  [disabled]="isUpdate || !form?.controls.diversity.value"
                  [pickerForm]="form"
                  placeholder="Current Vendor"
                  [clearEnabled]="true"
                  customMethod="findAll">
                </ca-vendor-picker>
                <mat-hint class="ca-select-hint"
                          *ngIf="form?.hasError('required', 'current_vendor_id') && form?.controls.current_vendor_id.touched">
                  {{VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </div>
            </div>

            <div>
              <mat-form-field caInput>
              <textarea matInput placeholder="Other Service Details"
                        type="text"
                        formControlName="service_details"
                        style="resize: none;"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>

      </div>
      <!-- /FIRST STEP -->

      <!-- SECOND STEP -->
      <div *ngIf="flow.isCurrentStep(STEPS.VENDOR_SELECTION)"
           class="flow-content-dialog-content ca-form">
        <div fxLayout="row" *ngIf="form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.ACCESS_CIRCUIT">
          <div fxFlex="25%" fxLayout="row" fxLayoutGap="15px" fxFlexOffset="28px">
            <div class="flex-item" fxFlex="50%">
              <ca-npa-picker
                caInput
                formControlName="npa"
                placeholder="NPA"
                [pickerForm]="form"
                [clearEnabled]="true"
                [required]="true">
              </ca-npa-picker>
              <mat-hint class="ca-select-hint" *ngIf="form?.hasError('required', 'npa') && form?.controls.npa.touched">
                {{VALIDATION.REQUIRED_ERROR}}
              </mat-hint>
            </div>
            <div class="flex-item" fxFlex="50%">
              <ca-nxx-picker formControlName="nxx"
                             caInput
                             placeholder="NXX"
                             [pickerForm]="form"
                             [disabled]="!service.npa"
                             [npa]="service.npa"
                             [clearEnabled]="true"
                             [required]="true">
              </ca-nxx-picker>
              <mat-hint class="ca-select-hint" *ngIf="form?.hasError('required', 'nxx') && form?.controls.nxx.touched">
                {{VALIDATION.REQUIRED_ERROR}}
              </mat-hint>
            </div>

          </div>
        </div>
        <table class="ca-table ca-table-stripped ca-table-with-select" *ngIf="nonExhaustedVendors.length">
          <thead>
          <tr>
            <th class="col-checkbox">
              <mat-checkbox (change)="selectAllVendors($event)"
                            [(ngModel)]="allVendorsSelected"
                            [ngModelOptions]="{standalone: true}"
                            [checked]="allVendorsSelected"></mat-checkbox>
            </th>
            <th>Vendor</th>
            <th>Method</th>
            <th *ngIf="form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.ACCESS_CIRCUIT">Hub Location
            </th>
            <th *ngIf="tierVendors.length">Tier</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let vendor of nonExhaustedVendors | orderBy: 'name':false">
            <td class="col-checkbox">
              <mat-checkbox class="ca-checkbox-no-padding" (change)="selectVendor($event, vendor)"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="vendor.selected">
              </mat-checkbox>
            </td>
            <td>
              {{vendor.name}}
            </td>
            <td class="td-picker-padding">
              <ca-default-picker>
                <mat-form-field caInput class="td-picker-no-padding picker-without-label">
                  <mat-select [(ngModel)]="vendor.method_id"
                              [ngModelOptions]="{standalone: true}"
                              [disabled]="vendor.methods.length===1"
                              [ngClass]="{'read-only': vendor.methods.length===1}">
                    <mat-option
                      *ngFor="let method of offerMethods"
                      [disabled]="method.used"
                      [value]="method.id">
                      {{method.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ca-default-picker>
            </td>
            <td class="td-picker-padding" *ngIf="form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.ACCESS_CIRCUIT">
              <ca-default-picker>
                <mat-form-field caInput class="td-picker-no-padding picker-without-label">
                  <mat-select [(ngModel)]="vendor.hub_id" [ngModelOptions]="{standalone: true}"
                              [disabled]="!form?.controls.nxx.value">
                    <mat-option *ngFor="let hub of vendor.hubs"
                                [value]="hub.id">
                      {{hub.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ca-default-picker>

            </td>
            <td class="td-picker-padding" style="width: 80px;" *ngIf="tierVendors.length">

              <ng-container *ngIf="vendor.is_tier_contract_quoting">
                <ca-default-picker>
                  <mat-form-field caInput class="td-picker-no-padding">
                    <mat-select [(ngModel)]="vendor.rate_tier"
                                [ngModelOptions]="{standalone: true}"
                                placeholder="Tier"
                                [disabled]="vendor.method_id !== QUOTE_SERVICE_OFFER_METHOD.CONTRACT && vendor.method_id !== QUOTE_SERVICE_OFFER_METHOD.EMAIL">
                      <mat-option *ngFor="let tier of vendor.tiers"
                                  [value]="tier">
                        {{tier}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ca-default-picker>
              </ng-container>

            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- /SECOND STEP -->

      <!-- THIRD STEP -->
      <div *ngIf="flow.isCurrentStep(STEPS.FINAL)"
           class="flow-content-dialog-content">
        <div class="flex-container" fxLayout="row" fxFlexFill fxLayoutGap="15px">
          <div fxFlex="55%" fxLayout="column" fxFlexFill fxLayoutGap="15px">
            <mat-card fxFlex="150px" class="flex-item">
              <mat-card-content>
                <div fxLayout="row" class="entry-list">
                  <div fxFlex="{{UI.addressColumnWidth}}" class="entry-list-item">
                    <label *ngIf="form?.controls.quote_service_type_id?.value !== QUOTING_SERVICE_TYPE.P2P">Service
                      Address</label>
                    <label *ngIf="form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.P2P">Site A</label>
                    <div *ngIf="service?.address?.address1">
                      {{ service?.address?.address1}}, {{ service?.address?.city }}&nbsp; {{
                      service?.address?.stateAbbreviation ?
                      service?.address?.stateAbbreviation
                      : service?.address?.subdivision?.abbreviation }}&nbsp; {{ service?.address?.postal_code }}
                    </div>
                    <br>
                  </div>
                  <div  fxFlex="{{UI.addressColumnWidth}}" class="entry-list-item" *ngIf="form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.P2P">
                    <label>Site Z</label>
                    <div *ngIf="service?.secondary_address?.address1">
                      {{ service?.secondary_address?.address1}}, {{ service?.secondary_address?.city }}&nbsp; {{
                      service?.secondary_address?.stateAbbreviation ?
                      service?.secondary_address?.stateAbbreviation
                      : service?.secondary_address?.subdivision?.abbreviation }}&nbsp; {{ service?.secondary_address?.postal_code }}
                    </div>
                    <br>
                  </div>
                </div>

                <div fxLayout="row" class="entry-list">

                  <div fxFlex class="entry-list-item" *ngIf="form?.controls.quote_service_type_id?.value === QUOTING_SERVICE_TYPE.OTHER">
                    <label>Service Type</label>
                    <p>
                      {{clientServiceTypeValue || 'N/A'}}
                    </p>
                  </div>

                  <div fxFlex class="entry-list-item" *ngIf="form?.controls.quote_service_type_id?.value !== QUOTING_SERVICE_TYPE.OTHER">
                    <label>Service Type</label>
                    <p>
                      {{serviceTypeValue || 'N/A'}}
                    </p>
                    <label>Bandwidth</label>
                    <p>
                      {{ (circuitBandwidthValue) || 'N/A'}}
                    </p>
                  </div>

                  <div fxFlex class="entry-list-item" *ngIf="form?.controls.quote_service_type_id?.value !== QUOTING_SERVICE_TYPE.OTHER">
                    <label>Desired Install Date</label>
                    <p>
                      {{ (service?.desired_install_date | date) || 'N/A'}}
                    </p>
                    <label>Port Bandwidth</label>
                    <p>
                      {{ portBandwidthValue || 'N/A'}}
                    </p>
                  </div>
                  <div fxFlex class="entry-list-item">
                    <label>Term</label>
                    <div>
                  <span class="badge ca-theme-background" *ngFor="let term of service?.terms" style="font-size: .8em">
                        {{term.text}}
                      </span>
                      <span *ngIf="service?.terms?.length==0">
                        {{'N/A'}}
                      </span>
                    </div>
                  </div>
                </div>

              </mat-card-content>
            </mat-card>

            <div fxFlex="180px" style="overflow-y: auto">
              <table class="ca-table ca-table-stripped" *ngIf="selectedOrExistingRequests.length > 0">
                <thead>
                <tr>
                  <th class="col-checkbox">
                    <mat-checkbox (change)="selectAllRequests($event)"
                                  [(ngModel)]="allRequestsSelected"
                                  [ngModelOptions]="{standalone: true}"
                                  [checked]="allRequestsSelected"></mat-checkbox>
                  </th>
                  <th>Vendor</th>
                  <th>Method</th>
                  <th>Hub&nbsp;Location</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let request of selectedOrExistingRequests">
                  <td class="col-checkbox">
                    <mat-checkbox (change)="selectRequest($event, request)"
                                  [disabled]="request.used"
                                  [ngModelOptions]="{standalone: true}"
                                  [(ngModel)]="request.selected">
                    </mat-checkbox>
                  </td>
                  <td>
                    {{request.vendor.name}}
                  </td>
                  <td>
                    {{request.method?.value}}
                  </td>
                  <td>
                    {{request.selected_hub?.name || request.selected_hub?.hub_name}}
                  </td>
                  <td>
                  <span
                    *ngIf="request.status !== QUOTE_SERVICE_OFFER_STATUS.FAILED && request.method && request.method.id !== QUOTE_SERVICE_OFFER_METHOD.CONTRACT">{{request.status | quoteRequestStatus:request.method.id}}</span>
                    <span
                      *ngIf="request.status === QUOTE_SERVICE_OFFER_STATUS.FAILED && request.method && request.method.id === QUOTE_SERVICE_OFFER_METHOD.CONTRACT">{{request.failure_reason | contractQuoteFailureReason}}</span>
                  </td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div fxFlex="45%" fxFlexFill fxLayout="column">
            <div *ngIf="!anyEmailRequestSelected()" class="ca-empty-state-container">
              <p class="small-div-message">
                No immediate email.
              </p>
            </div>
            <mat-card fxFlex="100%" class="details-main" *ngIf="anyEmailRequestSelected()">
              <mat-card-content>

                <div class="ca-tab ca-form tabbed-content">
                  <mat-tab-group dynamicHeight="true"
                                 [selectedIndex]="selectedEmailTabIndex"
                                 (selectedIndexChange)="onEmailsTabChange($event)">
                    <mat-tab label="To"></mat-tab>
                    <mat-tab label="Cc"></mat-tab>
                    <mat-tab label="Bcc"></mat-tab>
                  </mat-tab-group>
                </div>


                <div class="ca-form" fxLayout="column">
                  <div fxFlex="40px" class="flex-item" *ngIf="selectedEmailTabIndex === 0" style="margin-top: 8px; overflow-y: auto;">
                    <mat-chip-list>
                      <mat-chip *ngFor="let request of selectedRequests">{{request.vendor.name}}</mat-chip>
                    </mat-chip-list>
                  </div>
                  <div fxFlex="40px" class="flex-item" *ngIf="selectedEmailTabIndex > 0" style="margin-top: 8px; overflow-y: auto">

                    <app-email-tag [form]="form"
                                   caInput
                                   [functionKey]="'QUOTING'"
                                   [placeholder]="email.cc.length ? '' : 'Type to add email'"
                                   *ngIf="selectedEmailTabIndex === 1"
                                   [(value)]="email.cc"
                                   (onPickerChange)="onEmailChange($event, 'cc')"></app-email-tag>
                    <app-email-tag [form]="form"
                                   caInput
                                   [functionKey]="'QUOTING'"
                                   [placeholder]="email.bcc.length ? '' : 'Type to add email'"
                                   *ngIf="selectedEmailTabIndex === 2"
                                   [(value)]="email.bcc"
                                   (onPickerChange)="onEmailChange($event, 'bcc')"></app-email-tag>
                  </div>
                  <div fxFlex="60px" class="flex-item">
                    <mat-form-field caInput style="width: 100%">
                      <input matInput type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="email.subject"
                             placeholder="Subject">
                    </mat-form-field>
                  </div>
                  <div fxFlex="180px" class="flex-item no-left-padding">
                    <mat-form-field caInput style="width: 100%">
                      <textarea matInput
                                type="email"
                                [(ngModel)]="email.header"
                                [ngModelOptions]="{standalone: true}"
                                placeholder="Message"
                                maxlength="1050" rows="8">
                      </textarea>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
      <!-- THIRD STEP -->
    </form>
  </div>
</div>


<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Close
  </button>
  <button *ngIf="UI.previousButtonVisible"
          #genericButton
          mat-button
          [disabled]="!UI.canPrev"
          (click)="previous()"
          class="previous">
    Previous
  </button>
  <button mat-button
          #genericButton
          autofocus
          [disabled]="!UI.canNext"
          *ngIf="UI.nextButtonVisible"
          (click)="next()">
    Next
  </button>
  <button type="submit"
          (click)="submit(form)"
          *ngIf="UI.confirmButtonVisible"
          [disabled]="!UI.canConfirm"
          mat-button
          #genericButton>
    Send
  </button>
</mat-dialog-actions>

</div>
