import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Vendor } from '../../core/vendor';
import { VendorEntity } from '../../core/vendor-entity';
import { VendorService } from '../../core/vendor.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-move-entity-dialog',
  templateUrl: './move-entity-dialog.component.html',
})
export class MoveEntityDialogComponent implements OnInit {

  vendor: Vendor;
  entity: VendorEntity;
  vendor_id: number;
  form: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public vendorService: VendorService,
    public dialogRef: MatDialogRef<MoveEntityDialogComponent>
  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      vendor_id: new FormControl(null, Validators.required)
    });
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  moveEntity(vendor: Vendor, entity: VendorEntity) {

    const { vendor_id } = this.form.value;

    this.vendorService.assignEntity(vendor_id, entity.id)
      .subscribe(
      (result) => {
        this.close(result);
      },
      (error) => {
        console.log('error creating new entity');
      }
      );
  }

}
