<app-contract-schedule-toolbar
    [isTermsConditionsTab]="true"
    (addNewTCRequested)="addNewTC()"
    (editTCRequested)="editTC()"
    (deleteTCRequested)="deleteTC()"
    [isEditTCDisabled]="!selection"
    (gridSettingsRequested)="termsAndConditionsGridSettings()"
    (csvRequested)="keyAndTerminationCsv()"
    [isFilterDisabled]="false"
    [isFilterOn] = "this.sider.getActiveSection()?.name === this.SECTIONS.FILTER_SECTION_NAME"
    (toggleFilterRequested)="toggleFilter()"
    [notesCount]="notesCount"
    [isNotesOn] = "this.sider.getActiveSection()?.name === this.SECTIONS.NOTES_SECTION_NAME"
    (toggleNotesRequested)="toggleNotes()"
></app-contract-schedule-toolbar>
<mat-sidenav-container class="app-content app-content-list tabbed-content dx-nodata-absolute">
    <app-contract-schedule-tabs [activeTabIndex]="1" [schedule]="schedule"></app-contract-schedule-tabs>
    <div class="tabs-body grid-container grid-with-toolbar"
         style="top: -2px; z-index: -1; overflow: hidden;">
      <div class="grid-body">
        <dx-data-grid #termsAndConditionGrid
                      class="app-grid-with-pager"
                      [columns]="termConditionColumns"
                      [dataSource]="termsConditions"
                      [height]="'100%'"
                      [width]="'100%'"
                      [showColumnLines]="false"
                      [showRowLines]="true"
                      [allowColumnResizing]="true"
                      (onSelectionChanged)="onSelectionChanged($event)"
                      (onCellClick)="onTermsAndConditionCellClick($event)">
          <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>
          <dxo-paging [enabled]="false" visible="false"></dxo-paging>
          <dxo-sorting mode="none"></dxo-sorting>
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-remote-operations [sorting]="true"></dxo-remote-operations>
  
          <div matTooltip="{{d.data.section_id}}" *dxTemplate="let d of 'termsAndConditionSectionCellTemplate'">
            {{ d.data }}
          </div>
  
          <div class="expanded-cell" *dxTemplate="let d of 'contractTextCellTemplate'">
            <span class="expanded-text" matTooltip="{{d.value}}">
              {{ d.value }}
            </span>
          </div>
  
        </dx-data-grid>
        <div class="grid-pager">
          <ca-pager class="app-pager ca-default-border-top" [pageContext]="contractScheduleDetailTermConditionGridService" [query]="queryTermAndCondition" (onChange)="loadTermsAndConditions()"></ca-pager>
        </div>
      </div>
    </div>

    <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false" (close-start)="panelClose()">
      <new-app-filter
        [ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
        [query]="queryTermAndCondition"
        [filterService]="contractScheduleDetailTermAndConditionFilterService"
        (filterRequested)="loadTermsAndConditions($event)"
        (clearFilterRequested)="clearTermsAndConditionFilter($event)"
      >
      </new-app-filter>
      <ca-notes [entityId]="schedule.id" [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                [entityType]="SYSTEM_MODULE.CONTRACT" (countChanged)="onNotesCountChanged($event)"></ca-notes>
    </mat-sidenav>
</mat-sidenav-container>