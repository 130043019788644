import {Component, AfterViewInit} from '@angular/core';
import {PageManageDialogComponent} from '../../../core/page-manage-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {QuotingService} from '../../core/quoting.service';
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Config} from "../../../core/config/config";
import {ConfigService} from "../../../core/config/config.service";
import {Quote} from "../../core/quote";

@Component({
  selector: 'app-email-send-form',
  templateUrl: './email-send-form.component.html',
  styleUrls: ['./email-send-form.component.css']
})
export class EmailSendFormComponent extends PageManageDialogComponent implements AfterViewInit {

  readonly QUOTE_SERVICE_OFFER_METHOD = LOOKUP_ENUM.QUOTE_SERVICE_OFFER_METHOD;
  readonly QUOTE_SERVICE_OFFER_STATUS = LOOKUP_ENUM.QUOTE_SERVICE_OFFER_STATUS;
  readonly QUOTE_SERVICE_TYPE = LOOKUP_ENUM.QUOTING_SERVICE_TYPE;
  readonly MESSAGES_MODULE: string = "quote";

  formTitle: string;
  quoteRequests: Array<any> = [];
  messages: IMessagesResourceService;
  form: FormGroup;
  selectedEmailTabIndex: number = 0;
  quote: Quote;
  email = {
    cc: [],
    bcc: [],
    subject: "",
    header: ""
  };


  private config: Config;

  constructor(public alert: AlertService,
              public dialogService: DialogService,
              public quotingService: QuotingService,
              public dialogRef: MatDialogRef<EmailSendFormComponent>,
              public formBuilder: FormBuilder,
              public configService: ConfigService) {
    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {

    this.email.subject = this.quote.quote_id;

    this.configService.findAll()
      .subscribe(
        (config: Config) => {
          this.config = config;
          this.email.header = this.config.quoting.default_email_header;
        }
      );

    this.formTitle = 'Email Quote Requests';

    this.quoteRequests = this.quoteRequests.filter((request) => {
      return request.quote_type && request.quote_type.id === this.QUOTE_SERVICE_OFFER_METHOD.EMAIL;
    })
      .map((offer) => {
        return Object.assign(offer, {
          method: offer.quote_type,
          selected_hub: offer.network_hub,
          selected: true,
          used: true
        });
      });

    if (this.quoteRequests.length) {

    }

    this.form = this.formBuilder.group({fake: ['']});
  }

  getUniqueVendors() {
    let result = [];
    this.quoteRequests.forEach((request) => {
      let vendor = this.findVendor(result, request.vendor.id)

      if (!result.length) {
        result.push(request.vendor);
      }
      else if (!vendor) {
        result.push(request.vendor);
      }
    })
    return result;
  }

  findVendor(vendors, id) {
    return vendors.filter((vendor) => {
      return vendor && vendor.id === id;
    })[0];

  }

  onEmailsTabChange(index: number) {
    this.selectedEmailTabIndex = index;
  }

  onEmailChange(entry, source) {
    this.email[source] = entry;
  }

  send() {
    this.closeDialog(true);
    this.quotingService.sendBulk(this.quote.id, this.quoteRequests, {
      cc: this.email.cc,
      bcc: this.email.bcc,
      subject: this.email.subject,
      header: this.email.header
    });
    this.alert.success('', this.messages.get('EMAIL_SEND'));
  }
}
