import {ICsvInterceptor} from "../../core/csv/csv-interceptor";

export class AlertPreGlValidationCsvInterceptor implements ICsvInterceptor {
  transform(item: any) {

    let copy = Object.assign({}, item);
    copy.status = item.status ? 'Excluded' : 'Included';
    return copy;
  }
}
