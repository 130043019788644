import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

import { AuditConfig } from "./audit-config";
import { BaseService } from '../../core/base.service';
import { EntityLockService } from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class AuditConfigService extends BaseService<AuditConfig> {

  constructor(public restangular: Restangular, private entityLock: EntityLockService) {
    super('audit-config', restangular, entityLock);
  }

  getCurrentConfiguration(auditId: number) {
    return this.service().one('audit-config/' + auditId).customGET();
  }

  // updateConfiguration(auditId: number, config: any) {
  //   return this.service().one('audit-config/' + auditId).customPOST(config);
  // }

  runFromInvoices(auditIds: any, invoiceIds: any) {
    return this.service().one('audit-process', 'invoices').customPOST({ auditIds, invoiceIds });
  }

  runFromAudits(auditIds: any, keys: any) {
    return this.service().one('audit-process', 'accounts').customPOST({ auditIds, keys });
  }

  getAllAccountsForSelectedAudits(auditIds: any []) {
    return this.service().one('audit-config', 'accounts').customPOST({ auditIds });
  }

  getTreeForAudits(audits: any [], vendor_id: any) {
    return this.service().one('audit-process', 'accountstree').customPOST({audits, vendor_id});
  }

  getAuditConfig(audit_id: any) {
    return this.service().one('audit-config/' + audit_id).customGET();
  }

  getTreeList(audit_id) {
    return this.service().one('tree-list/audit/' + audit_id).customGET();
  }

  updateConfiguration(audit_id: any, selectedRows: any) {
    return this.service().one('audit-config/' + audit_id).customPOST(selectedRows);
  }
}
