import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';

import { Order } from '../../core/order';
import { OrderService } from '../../core/order.service';

@Component({
  selector: 'ca-order-sneak-peek',
  templateUrl: './order-sneak-peek.component.html'
})
export class OrderSneakPeekComponent implements OnInit, OnChanges {
  @Input() order: Order;
  @Input() isServiceLevel: boolean;
  @Output() closeRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();

  id: number;

  constructor(public orderService: OrderService) {}

  ngOnInit() {}

  deleteOrder() {
    this.deleteRequested.emit(this.order);
  }

  closeSide() {
    this.closeRequested.emit();
  }

  public async loadData(id: number) {
    this.order = await this.orderService.findById(id).toPromise();
  }

  ngOnChanges(): void {
    if (this.order && this.isServiceLevel) {
      this.loadData(this.order.id);
      this.id = this.order.id;
    }
  }
}
