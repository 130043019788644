<app-contract-details-toolbar
      [isSchedulesTab]="true"
      [isEditScheduleDisabled]="!selection"
      (createScheduleRequested)="createSchedule()"
      (editScheduleRequested)="editSchedule()"
      (deleteScheduleRequested)="deleteSchedule()"
      [isFilterDisabled]="true"
      [notesCount]="notesCount"
      [isNotesOn] = "this.sider.getActiveSection().name === this.SECTIONS.NOTES_SECTION_NAME"
      (toggleNotesRequested)="toggleNotes()"
      [isHistoryOn] = "this.sider.getActiveSection().name === this.SECTIONS.HISTORY_SECTION_NAME"
      (toggleHistoryRequested)="toggleHistory()"
>

</app-contract-details-toolbar>

<mat-sidenav-container class="app-content app-content-list tabbed-content dx-nodata-absolute">
    <app-contract-details-tabs [activeTabIndex]="1" [contract]="contract"></app-contract-details-tabs>
    <div class="tabs-body">
        <div *ngIf = "scheduleSeries?.length < 1" class="no-data-class">No Data</div>
        <div fxLayout="row">
          <div fxLayout="column" *ngFor="let type of scheduleSeries" class="flex-container ca-card">
            <div class="column-title">
              {{ type.name }}
            </div>
            <div *ngFor="let schedule of schedules" style="border-radius: 0.25em;">
              <mat-card *ngIf="type.id === schedule.schedule_series_id" [class.select-schedule]="schedule.id === scheduleID && isSelected === 'select-schedule'" [ngClass]="seriesID === schedule.schedule_series_id ? 'schedule-card-direction-row' : 'schedule-card-direction-column'" (click)="selectSchedule(schedule)" (dblclick)="openScheduleDetails(schedule)" caCard>
                <mat-card-header class="schedule-header">
                  <div fxLayout="column" style="flex: 1;">
                    <div fxLayout="row" style="width: 100%; justify-content: space-between;">
                      <div class="schedule-title" [matTooltip]="schedule.schedule_name">{{ schedule.schedule_name }}</div>
                      <div class="selection-badge gl-selection-badge" [ngClass]="isTerminated(moment(schedule?.effective_date).subtract(1, 'days').format('YYYY-MM-DD')) ? 'badge-accent' : isTerminated(schedule?.termination_date) ? 'badge-success' : 'badge-danger'" style="height: max-content;">
                        {{ isTerminated(moment(schedule?.effective_date).subtract(1, 'days').format('YYYY-MM-DD')) ? 'Future' : isTerminated(schedule?.termination_date) ? 'Active' : 'Expired' }}
                      </div>
                    </div>
                    <div class="font-size-13">{{ schedule?.schedule_series?.value }}</div>
                  </div>
                </mat-card-header>
                <mat-card-content style="width: 380px">
                  <div fxLayout="row" class="entry-list">
                    <div fxFlex class="entry-list-item">
                      <label>Customer Doc Sign Date</label>
                      <p>
                        {{ (schedule?.customer_sign_date | caDate) || 'N/A' }}
                      </p>
                    </div>
                    <div fxFlex class="entry-list-item">
                      <label>Vendor Doc Sign Date</label>
                      <p>
                        {{ (schedule?.vendor_sign_date | caDate) || 'N/A' }}
                      </p>
                    </div>
                  </div>
                  <div fxLayout="row" class="entry-list">
                    <div fxFlex class="entry-list-item">
                      <label>Eff Date - Term Date</label>
                      <p>
                        {{ (schedule?.effective_date | caDate) + ' • ' + (schedule?.termination_date | caDate) || 'N/A' }}
                      </p>
                    </div>
                    <div fxFlex class="entry-list-item">
                      <label>Term</label>
                      <p> {{ schedule?.rate_term || 'N/A' }} </p>
                    </div>
                  </div>
                  <div fxLayout="row" class="entry-list">
                    <div fxFlex class="entry-list-item">
                      <label>Total Commit Value</label>
                      <p>
                        {{ (schedule?.contract_schedule_commitment?.commit_total | caCurrency: schedule?.contract_schedule_commitment?.currency?.currency) || 'N/A'}}
                      </p>
                    </div>
                    <div fxFlex class="entry-list-item">
                      <label>Commit Type</label>
                      <p> {{ schedule?.contract_schedule_commitment?.schedule_commitment_type?.value || 'N/A'}} </p>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </div>

      <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false" (close-start)="panelClose()">
        <ca-notes [entityId]="contract?.id" [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                  [entityType]="SYSTEM_MODULE.CONTRACT" (countChanged)="onNotesCountChanged($event)"></ca-notes>
    
        <ca-history #history
                    [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
                    [entityId]="contract?.id"
                    [entityType]="'contract'"
        ></ca-history>
      </mat-sidenav>
</mat-sidenav-container>