import { Directive, HostListener, ElementRef} from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({ selector: ` 
  input[type=text]:not([readonly]):not([disabled])[formControlName], 
  textarea:not([readonly])[formControlName]`
})

export class TrimDirective {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private control : NgControl
  ) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    let newValue =  value.replace(/\s\s+/g, ' ').trim();
    this.el.value = newValue;
    this.control.control.setValue(newValue);
  }
}
