import {Injectable} from '@angular/core';
import {BaseService} from '../../../core/base.service';
import {Document} from './document';
import {Restangular} from 'ngx-restangular';
// import {URLSearchParams} from '@angular/http';
import {HttpClient} from "@angular/common/http";

import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import Auth from "../../user-session/auth";
import Query from "../../../core/query/query";

@Injectable()
export class DocumentService extends BaseService<Document> {

  constructor(public restangular: Restangular,
              public http: HttpClient) {
    super('document', restangular);
  }

  getEntityDocuments(entityId: number, type: any, query?) {
    return this.service().one(this.name, entityId).customGET('entity', {type: type, filter: query});
  }

  deleteEntity(id: number, entityId: number, parentType: any) {
    return this.service().one(this.name, id).customDELETE(null, {eid: entityId, parent_type: parentType});
  }

  upload(id: number, data) {
    return this.service().all(this.name).post(data);
  }

  isFileNameTaken(entityId: number, query: any): Observable<any> {
    return this.service().all(this.name).one(`${entityId}`, 'file-name').customGET(null, this.toFilter(query));
  }

  downloadDocument(id: number, folder: string, subfolder?: string) {
    let cookie = Auth.getSession();
    let params: URLSearchParams = new URLSearchParams();
    params.set('authorization', cookie.token);

    let endPoint = `${environment.API_URL + this.name}/${id}/download/${folder}`;

    if (subfolder) {
      endPoint = `${endPoint}/${subfolder}`;
    }

    // @ts-ignore
    return this.http.get(endPoint, {
      responseType: 'blob',
      headers: {
        authorization: `Bearer ${cookie.token}`
      }
    });
  }

  initiateDownload(name, response: Blob) {
    let objectUrl = URL.createObjectURL(response);
    let save = document.createElement('a');
    save.href = objectUrl;
    save.target = '_blank';
    save.download = name;
    let event = document.createEvent('MouseEvents');
    event.initMouseEvent(
      "click", true, false, window, 0, 0, 0, 0, 0
      , false, false, false, false, 0, null
    );
    save.dispatchEvent(event);
  }


  findAllForMaster(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('master').customGET(null, this.toFilter(transformedQuery));
  }
}
