import {Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core';

import {Quote} from '../../core/quote';
import {QuoteService} from '../../core/quote-service.model';
import {QuotingService} from '../../core/quoting.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-quoting-sneak-peek',
  templateUrl: './quoting-sneak-peek.component.html',
  styleUrls: ['./quoting-sneak-peek.component.css']
})
export class QuotingSneakPeekComponent implements OnInit, OnChanges {
  public quoteServices: QuoteService[];

  @Input() quote: Quote;
  @Input() serviceLevel: boolean;
  @Output() closeRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();

  id: number;
  subscription: Subscription;

  constructor(public quotingService: QuotingService) {
  }

  ngOnInit() {
  }

  deleteQuote() {
    this.deleteRequested.emit(this.quote);
  }

  closeSide() {
    this.closeRequested.emit();
  }

  public loadData(id: number) {
    this.subscription =
      this.quotingService.findById(id).subscribe((quote: Quote) => {
        let {services} = quote;
        this.quoteServices = services.sort((a, b) => {
          return a.quote_service_id.localeCompare(b.quote_service_id);
        });
      });
  }

  ngOnChanges(): void {
    if (this.quote) {
      if (this.subscription && !this.subscription.closed) {
        this.subscription.unsubscribe();
      }

      this.id = this.quote.id;
      this.loadData(this.id);
    }
  }
}
