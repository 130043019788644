import {
  Component,
  OnInit,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-document-manager-commands',
  templateUrl: './document-manager-commands.component.html',
})
export class DocumentManagerCommandsComponent implements OnInit {

  @Input('deleteDocumentActionHandler') deleteDocumentActionHandler;
  @Input('uploadDocumentActionHandler') uploadDocumentActionHandler;
  @Input('downloadDocumentActionHandler') downloadDocumentActionHandler;

  @Input('deleteDocumentDisablePredicate') deleteDocumentDisablePredicate: any =  false;
  @Input('uploadDocumentDisablePredicate') uploadDocumentDisablePredicate: any = false;
  @Input('downloadDocumentDisablePredicate') downloadDocumentDisablePredicate: any =  false;

  constructor() { }

  ngOnInit() { }

}
