import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { BaseService } from '../../core/base.service';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';
import CustomerInventory from './customer-inventory';
import Query from "../../core/query/query";


@Injectable()
export class CustomerInventoryService extends BaseService<CustomerInventory> {
  constructor(
    public restangular: Restangular,
    private entityLock: EntityLockService
  ) {
    super('customer-inventory', restangular, entityLock);
  }

  fetchPricing(circuitId: number) {
    return this.service().one(this.name, circuitId).one('pricings').get();
  }
  fetchSortPricing(circuitId: number,query?:any) {
    query.where = {...query.where, customer_circuit_id: circuitId}
    return this.service().one(this.name, circuitId).one('sort-pricings').customGET(null, this.toFilter(query));
  }

  fetchSites(circuitId: number) {
    return this.service().one(this.name, circuitId).one('sites').get();
  }

  fetchNetworkCircuits(circuitId: number, query?: any) {
    return this.service().one(this.name, circuitId).one('circuits').customGET(null, this.toFilter(query));
  }

  fetchGridFilterNetworkCircuits(circuitId: number, query?: any) {
    query.where = {...query.where, customer_circuit_id: circuitId}
    return this.service().one(this.name, circuitId).one('filter-grid-circuits').customGET(null, this.toFilter(query));
  }

  addPricing(circuitId: number, pricings: Array<any>) {
    return this.service().one(this.name, circuitId).one('pricings').customPOST(pricings);
  }

  addSites(circuitId: number, sites: Array<any>) {
    return this.service().one(this.name, circuitId).one('sites').customPOST(sites);
  }

  addNetworkCircuits(circuitId: number, networkCircuits: Array<any>) {
    return this.service().one(this.name, circuitId).one('circuits').customPOST(networkCircuits);
  }

  linkCustomerWithNetworkCircuit(circuitId: number, network_circuit_id: number) {
    return this.service().one(this.name, circuitId).one('link', network_circuit_id).post();
  }

  findCustomerInventoryOnly(query?: Query): any {
    let concreteQuery = (query || new Query());
    return this.service().one(this.name).one('customer-inventory-only').customGET(null, this.toFilter(concreteQuery));
  }
}
