<nav>
  <ul class="navigation-list">
    <li *ngFor="let entry of getVisibleEntries(entries)" class="menu-item">
      <button mat-button type="button" [routerLink]="entry.value" *ngIf="!entry.hasItems()"
              [disabled]="!entry.allowed"
              [ngClass]="{'active-link': isSelected(entry), 'highlight-icon': entry.focused, 'navigation-item-disabled': !entry.allowed}">
        <ca-icon [key]="entry.icon" [innerClass]="'menu-icon'"></ca-icon>
        <span class="label">{{entry.title}}</span>
      </button>
      <button mat-button type="button" [class.open]="entry.open" (click)="toggle(entry)"
              [ngClass]="{'highlight-icon': entry.focused, 'focused': entry.focused, 'navigation-item-disabled': !entry.allowed}"
              *ngIf="entry.hasItems()" [disabled]="!entry.allowed">
        <ca-icon [key]="entry.icon" [innerClass]="'menu-icon'"></ca-icon>
        <span class="label">{{entry.title}}</span>
        <div class="indicator">
          <ca-icon [key]="entry.open ? 'NAV_ARROW_UP' : 'NAV_ARROW_DOWN'"></ca-icon>
        </div>
      </button>
      <ul *ngIf="entry.open">
        <li *ngFor="let child of  getVisibleEntries(entry.items)" class="submenu-item">
          <button mat-button type="button" class="submenu" [ngClass]="{'active-link': child.selected, 'navigation-item-disabled': !child.allowed}"
                  [disabled]="!child.allowed"
                  [routerLink]="child.value">
            <span>{{child.title}}</span>
          </button>
        </li>
      </ul>
    </li>
  </ul>
</nav>
