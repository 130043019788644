import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

import {UserSettingsService} from "../../../user/core/user-settings.service";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {DialogService} from '../../../shared/dialog/dialog.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {ContractSchedule} from "../../core/contract-schedule";

import {ContractService} from '../../core/contract.service';
import {Contract} from '../../core/contract';
import {ContractScheduleManageDialogComponent} from "../../shared/contract-schedule-manage-dialog/contract-schedule-manage-dialog.component";
import { IMessagesResourceService, ResourcesService } from '../../../core/resources/resources.service';

@Component({
  selector: 'app-contract-schedule-summary',
  templateUrl: './contract-schedule-summary.component.html',
  styleUrls: ['./contract-schedule-summary.component.scss']
})
export class ContractScheduleSummaryComponent extends PageDetailsComponent implements OnInit, AfterViewInit {

  @ViewChild('panelSide') panelSide;
  scheduleMessages: IMessagesResourceService;

  readonly SCHEDULE_MODULE: string = 'schedule';
  readonly COMMITMENT_TYPE = LOOKUP_ENUM.CONTRACT_SCHEDULE_COMMITMENT_TYPE;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  public contractSchedule;
  public scheduleCommitmentType: any;
  public commitTotal: number = 0;


  public contract: Contract;
  public notesCount: number = 0;

  constructor(
      public contractService: ContractService,
      private readonly route: ActivatedRoute,
      public settingsService: UserSettingsService,
      public dialog: DialogService,
      public alert: AlertService,
  ) {
  super(new PageContext({
      name: 'app.contract.contract-schedule-summary',
      settings: settingsService
  }));
  this.scheduleMessages = ResourcesService.messages(this.SCHEDULE_MODULE);
  }

  ngOnInit(): void {
      this.contractSchedule = this.route.parent.snapshot.data.schedule;
      this.loadContractScheduleInfo(this.contractSchedule.id);
  }

  loadContractScheduleInfo(id?){
      this.contractService.findScheduleById(id).subscribe(scheduleData => {
        this.contractSchedule = scheduleData;
  
        if(scheduleData?.contract_schedule_commitment?.schedule_commitment_type){
          if(scheduleData.contract_schedule_commitment && scheduleData.contract_schedule_commitment.schedule_commitment_type.id == this.COMMITMENT_TYPE.MONTHLY){
            this.scheduleCommitmentType = 'Months'
          }
          if(scheduleData.contract_schedule_commitment && scheduleData.contract_schedule_commitment.schedule_commitment_type.id == this.COMMITMENT_TYPE.ANNUAL){
            this.scheduleCommitmentType = 'Years'
          }
          if(scheduleData.contract_schedule_commitment && scheduleData.contract_schedule_commitment.schedule_commitment_type.id == this.COMMITMENT_TYPE.TERM){
            this.scheduleCommitmentType = 'Term'
          }
        }else {
          this.scheduleCommitmentType = '';
        }
  
        if (scheduleData?.contract_schedule_commitment) {
          this.commitTotal = scheduleData.contract_schedule_commitment.commit_per_term * scheduleData.contract_schedule_commitment.term;
        }
      })
  }

  editSchedule() {
    this.dialog.open(ContractScheduleManageDialogComponent, {
      contract: new Contract({id: this.contractSchedule.contract_shell_id}),
      contractSchedule: new ContractSchedule(
        {...this.contractSchedule}),
      isUpdate: true
    }, {width: 'auto'})
      .afterClosed()
      .subscribe(result => {
          if (result) {
            this.loadContractScheduleInfo(this.contractSchedule.id);
            this.alert.success('', this.scheduleMessages.get('UPDATE_SUCCESS'));
          }
        },
        (error) => {
          this.alert.error('', this.scheduleMessages.get('UPDATE_ERROR'));
        });
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME);
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
  }
}