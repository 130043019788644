import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {GLStringQuery} from "../../../gl-string/core/gl-string.query";
import {GLService} from "../../../gl-rule-execution/core/gl.service";
import {GLStringService} from "../../../gl-string/core/gl-string.service";
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {ITabInformation} from "../../core/tab.information";
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {Select, Store} from "@ngxs/store";
import {QueryBuilder} from "../../../common/query/query.builder";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {GlRuleExecutionGLStringsGridService} from "../../../gl-rule-execution/core/gl-rule-execution-gl-strings-grid.service";
import {SortingBuilder, SortingService} from "../../../shared/sorting/sorting.service";
import {HistoryComponent} from "../../../shared/history/history.component";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {Observable, Subject} from "rxjs";
import {INVOICE_STATUS_ENUM} from "../../core/invoice-status.enum";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {InvoiceFlowService} from "../../core/invoice-flow.service";
import {HistoryService} from "../../../shared/history/history.service";
import {UpdateInvoiceGLStringQuery, UpdateInvoiceGLStringSiderSection} from "./state/invoice-gl-string.actions";
import Query from "../../../core/query/query";
import {InvoiceGlStringFilterService} from "../../core/invoice-gl-string-filter.service";
import {GLStringCsvInterceptor} from "../../../gl-string/core/gl-string-csv-interceptor";
import {UserService} from "../../../user/core/user.service";
import {GridSettingsComponent} from "../../../core/grid/grid-settings.component";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {takeUntil} from "rxjs/operators";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";

@Component({
  selector: 'app-invoice-gl-strings',
  templateUrl: './invoice-gl-strings.component.html',
  styleUrls: ['./invoice-gl-strings.component.css']
})
export class InvoiceGlStringsComponent implements OnInit, AfterViewInit {

  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    HISTORY_SECTION_NAME: "history",
    NOTES_SECTION_NAME: 'notes',
  };

  invoice: InvoiceFacepage;
  defaultGridPager: any;
  defaultGridPagerGLString: any;
  pagerSizeString: any;
  chargesSorting: SortingBuilder;
  glStringQuery: GLStringQuery = new GLStringQuery();
  private chargesColumnsSorting: any[];
  private gridSettings: any;
  //sider: Sider;

  dnpInfo: any = null
  onHoldInfo: any = null
  notesCount = 0;
  invoiceStatusEnum = INVOICE_STATUS_ENUM;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'invoice';
  readonly COMPONENT_NAME = 'invoice-gl-strings';
  gridLimit: number = 20;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('glStringsGrid') glStringsGrid: DxDataGridComponent;
  @ViewChild('panelSide') panelSide: any = '';
  @ViewChild('history') history: HistoryComponent;
  @ViewChild('testNotes') testNotes;
  sider: Sider = new Sider(new SiderSettings([], this.panelSide));

  @Select(state => state.invoice_gl_string.activeSiderSection) $activeSiderSection: Observable<any>;
  @Select(state => state.invoice_gl_string.query) $query: Observable<Query>;

  // TODO:  check key / tab key is 7
  public query = new GLStringQuery();

  public GL_STRING_DETAIL_TAB_INFO: ITabInformation =
  {
    key: 8,
    active: false,
    dataSource: [],
    handler: this.handleGLStringDetailTab.bind(this),
    masterDetailEnabled: false,
    masterDetailColumns: [],
    query: this.query,
    columns: [],
    selectionChanged: event => {
    }
  };

  constructor(
    private readonly invoiceFacepageService: InvoiceFacepageService,
    public store: Store,
    public queryBuilder: QueryBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public glService: GLService,
    public glStringService: GLStringService,
    public glRuleExecutionGLStringsGridService: GlRuleExecutionGLStringsGridService,
    public sortingService: SortingService,
    public settingsService: UserSettingsService,
    public invoiceFlowService: InvoiceFlowService,
    public historyService: HistoryService,
    public glStringFilterService: InvoiceGlStringFilterService,
    public userService: UserService,
    public dialogService: DialogService,
  ) {
    this.invoice = this.route.parent.snapshot.data.invoice;
    this.chargesSorting = this.sortingService.builder();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.invoiceFacepageService.invoiceChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(invoice => {
      this.invoice = invoice;
      this.history.refreshList();
    })
  }

  ngOnInit(): void {
    this.pagerSizeString = this.glRuleExecutionGLStringsGridService.getGridPager();
    this.defaultGridPagerGLString = this.pagerSizeString;

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => this.onQueryParamsChange(params));
    this.$query.pipe(takeUntil(this.destroy$)).subscribe((query) => this.onQueryChange(query));

    setTimeout(() => {
      this.glRuleExecutionGLStringsGridService.create(
        this.glStringsGrid.instance,
        {
          noDataText: this.glRuleExecutionGLStringsGridService.noDataMessage
        }
      );
    });
    this.GL_STRING_DETAIL_TAB_INFO.active = true;
   // this.GL_STRING_DETAIL_TAB_INFO.handler();
    this.getOnHoldInfo()
    this.testNotes && this.testNotes.emitCount();
    //this.getDNPInfo(this.invoice.notes, false)
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  private prepareGLStringDetailTab() {
    this.GL_STRING_DETAIL_TAB_INFO.columns = this.glRuleExecutionGLStringsGridService.columns();
  }

  private initStringDetailsColumns(settings) {
    this.gridSettings = settings;
    this.chargesColumnsSorting = settings ? settings.sorting : [];
    this.GL_STRING_DETAIL_TAB_INFO.columns = this.glRuleExecutionGLStringsGridService.getColumns(settings && settings.columns ? settings.columns : []);

    this.defaultGridPager = settings ? settings.gridPager : 20;
    this.query.limit = this.defaultGridPager;

    if (this.chargesColumnsSorting && this.chargesColumnsSorting.length) {
      this.query.orderBy = this.glRuleExecutionGLStringsGridService.getOrderBy(this.chargesColumnsSorting);
    }

    if (this.glStringsGrid && this.glStringsGrid.instance) {
      this.glStringsGrid.instance.option('columns', this.GL_STRING_DETAIL_TAB_INFO.columns);
    }

    this.refresh(this.query);
  }

  public resetDataPager() {
    this.defaultGridPager = 20;
  }

  onOpenGridSettingsRequested() {
    this.dialogService
      .open(GridSettingsComponent, {
        service: this.glRuleExecutionGLStringsGridService,
        settings: this.gridSettings,
        sliderMinimum: 0
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this.initStringDetailsColumns(settings);
        }
      });
  }

  private handleGLStringDetailTab(queryChanged?: GLStringQuery) {
    // if (queryChanged) {
    //   queryChanged.limit = this.query.limit;
    //   this.query = queryChanged;
    // }
    // const query = this.query;
    //
    // this.glService
    //   .loadGLStrings(this.glStringsGrid, query)
    //   .subscribe(result => {
    //     this.GL_STRING_DETAIL_TAB_INFO.dataSource = result.items;
    //   });
  }

  private refresh(query?: GLStringQuery) {
    this.glService
      .loadGLStrings(this.glStringsGrid, query)
      .subscribe(result => {
        this.GL_STRING_DETAIL_TAB_INFO.dataSource = result.items;
      });
  }

  filterData(query: GLStringQuery) {
    // delete necessary to make filter and clear hash equal if no filter params are present
    delete query.where['vendor_id']
    delete query.where['acct_level_1']
    delete query.where['invoice_id']
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query, route: this.route, queryBuilder: GLStringQuery
    })
  }

  clearFilter() {
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query: new GLStringQuery(), route: this.route, queryBuilder: GLStringQuery
    })
  }

  private onQueryParamsChange(params: Params) {
    if (Object.keys(params).length === 0) {
      this.query = new GLStringQuery({
        where: {
          vendor_id: this.invoice.vendor_id,
          acct_level_1: this.invoice.acct_level_1,
          invoice_id: this.invoice.invoice_id
        }
      })
    }
    const query = this.queryBuilder.build(this.query, null, this.COMPONENT_NAME).getQuery()
    this.store.dispatch([
      new UpdateInvoiceGLStringQuery({query, key: params.key})
    ]);
  }

  private onQueryChange(query: GLStringQuery) {
    this.query = new GLStringQuery(query);
    this.query.where = {
      ...this.query.where,
      vendor_id: this.invoice.vendor_id,
      acct_level_1: this.invoice.acct_level_1,
      invoice_id: this.invoice.invoice_id
    }
    this.prepareList()
  }

  public prepareList() {
    this.glRuleExecutionGLStringsGridService.loadSettings()
      .subscribe((settings) => {
        this.initStringDetailsColumns(settings);
      });
  }

  public onGlStringsCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.chargesSorting.apply(event, this.query);
      this.refresh(this.query);
    }
  }

  changePagerString(event) {
    this.pagerSizeString = event.limit;
    this.defaultGridPagerGLString = this.pagerSizeString;
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInvoiceGLStringSiderSection(activeSection.name)
    ]);
  }

  get activeFilter() {
    return this.glService.activeFilter(
      this.GL_STRING_DETAIL_TAB_INFO.masterDetailEnabled,
      this.sider,
      this.SECTIONS,
      {
        isChargeDetailsTab: false,
        isStringsTab: true
      }
    );
  }

  async getOnHoldInfo() {
    if (!this.invoice.account.on_hold) {
      this.onHoldInfo = null;
      return;
    }
    let accountHistory = await this.historyService.findAllForEntity('account', this.invoice.account.id).toPromise()
    let onHoldFound = accountHistory.filter(account => (account.meta_data.on_hold && account.meta_data.on_hold.value === true))
    this.onHoldInfo = (onHoldFound && onHoldFound.length > 0) ? onHoldFound[0] : null
  }

  getDNPInfo(notes: any, forceEmpty: boolean = false) {
    const dnpStatus = this.invoiceFlowService.LOCALS.DO_NOT_PROCESS

    if (forceEmpty || this.invoice.header.status_code !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
      this.dnpInfo = null
    } else {
      const dnpNotes = notes.filter(note => note.content.startsWith(dnpStatus));
      this.dnpInfo = (dnpNotes.length) ? dnpNotes[0] : {};
      this.dnpInfo.status = dnpStatus;
      this.dnpInfo.message = this.dnpInfo.content ? this.dnpInfo.content.substring(dnpStatus.length + 2) : this.messages.get('DNP_FALLBACK_MESSAGE');
    }
  }

  /* Notes count changed */
  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }


  onNoteCreated() {
    // this.refresh(this.query)
    // // TODO: was this.loadCharges(this.invoice); now refresh / check if valid
    // this.selectedCharges.length = 0;
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/invoice']);
    }
  }

  onDownloadCSVRequested() {
    const {
      invoice: {sp_inv_num},
      glStringService,
      query
    } = this;

    this.glRuleExecutionGLStringsGridService.csvMap().subscribe(fieldsGLStrings => {
      const glStringTransformMiddleware = (items) => {
        const glStringCSVInterceptor = new GLStringCsvInterceptor();
        return items.map(item => glStringCSVInterceptor.transform(item));
      };

      glStringService.exportToCSV(`${sp_inv_num}_gl_strings`, {
        fields: fieldsGLStrings,
        query: query,
        middleware: [glStringTransformMiddleware],
        fetchDataHandler: glStringService.findAllWithCharges.bind(glStringService)
      });
    });
  }
}
