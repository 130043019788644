import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import {Observable, of, Subject} from "rxjs";
import {Inventory} from "../../core/inventory";
import {InventoryService} from "../../core/inventory.service";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {InventoryCircuitsGridService} from "../../core/inventory-circuits-grid.service";
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {LoaderService} from "../../../shared/loader/loader.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {Select, Store} from "@ngxs/store";
import {UpdateInventoryRelatedCircuitActiveSiderSection} from "./state/inventory-related-circuit.actions";
import Query from "../../../core/query/query";

@Component({
  selector: 'app-inventory-related-circuit',
  templateUrl: './inventory-related-circuit.component.html',
})
export class InventoryRelatedCircuitComponent extends PageDetailsComponent  implements OnInit {
  inventory: Inventory;

  destroy$: Subject<boolean> = new Subject<boolean>();

  public circuitsColumns: any;
  public circuits: Array<any> = [];
  @ViewChild('circuitsGrid') circuitsGrid: DxDataGridComponent;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'inventory';

  circuitQuery: Query = new Query();
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  sider: Sider;
  notesCount: number;
  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    NOTES_SECTION_NAME: 'notes',
    HISTORY_SECTION_NAME: 'history'
  };

  @ViewChild('panelSide') panelSide;
  @Select(state => state.inventory_related_circuit.activeSiderSection) $activeSiderSection: Observable<any>;


  constructor(
    public inventoryService: InventoryService,
    public route: ActivatedRoute,
    public settingsService: UserSettingsService,
    public inventoryCircuitsGridService: InventoryCircuitsGridService,
    public router: Router,
    public loaderService: LoaderService,
    public alertService: AlertService,
    private readonly store: Store,
  ) {
    super(new PageContext({
      name: 'app.inventory.inventory-pricing',
      settings: settingsService
    }))
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  loadPermissions() {
    return of({});
  }

  private getInventory() {
    return this.route.parent.snapshot.data.inventory;
  }

  ngOnInit(): void {

    this.inventory = this.getInventory();
    this.loadCircuits(this.inventory.id)
    this.circuitsColumns = this.inventoryCircuitsGridService.columns();
  }

  loadCircuits(id) {
    this.loaderService.displayLoaderAndManageGrid([this.circuitsGrid]);
    this.circuitQuery.where = {inventory_id: id}
    this.inventoryService.fetchNetworkCircuits(id)
      .subscribe(
        result => {
          this.circuitQuery.total = result.total;
          this.loaderService.hideLoaderAndManageGrid([this.circuitsGrid]);

          this.circuits = result.items

        },
        error => {
          this.loaderService.displayLoaderAndManageGrid([this.circuitsGrid]);
          this.alertService.error('', this.messages.get('FETCH_CIRCUITS_ERROR'));
        }
      );
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();
    this.inventoryCircuitsGridService.create(this.circuitsGrid.instance, {
      noDataText: 'No Data'
    });
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    // TODO: delete this / used $activeSiderSection to reopen sider when back button is used
    // sider.onChange((section: SiderSection) => {
    //   // TODO: Save to state
    //   // this.saveSettings({siderSection: section.name});
    // });

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInventoryRelatedCircuitActiveSiderSection(activeSection.name)
    ]);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME)
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME)
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/inventory']);
    }
  }

  /** Toolbar actions */
  onDownloadCSVRequested() {
    // TODO: check if middleware is needed and crete if it is
    this.inventoryCircuitsGridService.csvMap().subscribe(fields => {
      this.inventoryService.exportToCSV('inventory-circuit', {
        fields,
        query: this.circuitQuery,
        fetchDataHandler: (query) => {
          return this.inventoryService.fetchNetworkCircuitsCsv(this.inventory.id , query)
        },
        middleware: []
      })
    })
  }
}
