<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{formTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <div fxLayout="row" class="entry-list">
    <div fxFlex class="entry-list-item" (click)="test(dispute)">
      <label>Billed Charges</label>
      <p>
        {{ dispute?.total_amount | caCurrency: dispute?.currency?.currency || dispute?.currency : custom_symbol }}
      </p>
    </div>

    <div fxFlex class="entry-list-item">
      <label>Calculated Charges*</label>
      <p>
        {{ dispute?.calculated_amount | caCurrency: dispute?.currency?.currency || dispute?.currency : custom_symbol  }}
      </p>
    </div>

    <div fxFlex class="entry-list-item">
      <label>Dispute Amount</label>
      <p>
        {{ dispute?.disputed_amount | caCurrency: dispute?.currency?.currency || dispute?.currency : custom_symbol  }}
      </p>
    </div>
  </div>

  <div fxLayout="row" class="entry-list">
    <div fxFlex class="entry-list-item">
      <label>Dispute Awarded</label>
      <p>
        {{ dispute?.dispute_value_awarded | caCurrency: dispute?.currency?.currency || dispute?.currency : custom_symbol }}
      </p>
    </div>

    <div fxFlex class="entry-list-item">
      <label>O/(U) Disp Amt Awarded</label>
      <p>
        {{ dispute?.payback_amount | caCurrency: dispute?.currency?.currency || dispute?.currency : custom_symbol  }}
      </p>
    </div>

    <div fxFlex class="entry-list-item">
      <label>Withholding Status</label>
      <p>
        {{ dispute?.withheld_status_obj?.value || 'N/A' }}
      </p>
    </div>
  </div>

  <div style="height: 300px; overflow: hidden; margin-top: 10px;" class="app-content-list app-content-list-no-pager">
    <dx-data-grid #chargesGrid
                  [showColumnLines]="false"
                  [height]="'100%'"
                  [showRowLines]="true"
                  [showBorders]="true"
                  [dataSource]="disputeCharges"
                  (onRowUpdating)="onRowUpdating($event)"
                  (onRowUpdated)="onRowUpdated($event)"
                  [columns]="columns"
                  (onEditorPreparing)="onEditorPreparing($event)"
                  (onCellClick)="onCellClick($event)"
                  (onCellPrepared)="onCellPrepared($event)">

      <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>

      <div *dxTemplate="let d of 'dateTimeTemplate'">
        <div caGridTooltip class="right">
          {{ d.value | caDate}}
        </div>
      </div>

      <div *dxTemplate="let d of 'numberTemplate'">
        <div caGridTooltip class="right">
          {{ d.value | caNumber}}
        </div>
      </div>

      <div *dxTemplate="let d of 'disputeChargeIdCellTemplate'">
        <div caGridTooltip>{{ d.row.data.charge?.invoice?.sp_inv_num }}_{{ d.row.data.charge?.id }}</div>
      </div>

      <div *dxTemplate="let d of 'disputeInvoiceCellTemplate'">
        <div caGridTooltip>{{ d.row.data.charge?.invoice?.sp_inv_num }}</div>
      </div>

      <div *dxTemplate="let d of 'disputeChargesStatusCellTemplate'">
        <div caGridTooltip>{{ d.data.status_item?.value }}</div>
      </div>

      <i *dxTemplate="let d of 'disputeChargesHeaderTemplate'">
        {{ d.column.caption }}
      </i>

      <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
        <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.charge.currency }}</div>
      </div>

      <div *dxTemplate="let d of 'statusTemplate'">
        <div caGridTooltip>{{ d.value ? d.value.value : 'Filed' }}</div>
      </div>

    </dx-data-grid>
  </div>
</div>

<mat-dialog-actions class="ca-align-baseline ca-dialog-action" #dialogButtons>
  <div class="example-fill-remaining-space">
  </div>

  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>

  <button mat-button #saveButton type="submit" (click)="onSubmit()" [disabled]="missingCalculatedAmount || missingAmountAwarded">
    Save
  </button>
</mat-dialog-actions>
