import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Notes } from './notes';
import { BaseService } from '../../../core/base.service';
import Query from "../../../core/query/query";

@Injectable()
export class NotesService extends BaseService<Notes> {

  constructor(public restangular: Restangular) {
    super('note', restangular);
  }

  findAllForMaster(query?: Query): any {

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().all(this.name).all('master').customGET(null, this.toFilter(transformedQuery));
  }
}
