<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button matTooltip="Add new offer" mat-icon-button (click)="addRequest()">
      <ca-icon [key]="'QUOTE_ADD_REQUEST'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Update" (click)="updateRequest()">
      <ca-icon [key]="'QUOTE_EDIT_REQUEST'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list">
  <dx-data-grid #grid (onRowClick)="onRowClick($event)" (onSelectionChanged)="onSelectionChanged($event)" (onCellClick)="onCellClick($event)"
    [columns]="columns" [height]="'100%'" [width]="'100%'" class="app-grid-with-pager" [dataSource]="service?.offers">
  </dx-data-grid>
</mat-sidenav-container>
