import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { NetworkHub } from './network-hub';
import { Restangular } from 'ngx-restangular';
import Query from '../../core/query/query';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';

enum CIRCUIT_TYPE_BOOLEAN_FILTER {
  'HOST' = 'false',
  'CUSTOMER' = 'true'
}

@Injectable({
  providedIn: 'root'
})
export class NetworkHubService extends BaseService<NetworkHub> {
  constructor(restangular: Restangular, private entityLock: EntityLockService) {
    super('network-hub', restangular, entityLock);
  }

  fetchHubCircuits(id): any {
    return this.service()
      .all(this.name)
      .one(`${id}`, 'circuits')
      .get();
  }

  fetchHubCircuitsQueryFormater(
    query: any,
    hubId: any,
    parentId: any,
    typeLabel: any,
    showInventory: any,
    value: any
  ): any {
    let concreteQuery = query || new Query();
    let transformedQuery = concreteQuery.transform();
    const r =
      'circuits' +
      '/' +
      'format' +
      '/' +
      hubId +
      '/' +
      parentId +
      '/' +
      typeLabel +
      '/' +
      showInventory +
      '/' +
      value +
      '/';
    return this.service()
      .several(this.name, r)
      .customGET(null, this.toFilter(transformedQuery));
  }

  findAllHubCircuitsById(id, query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .all(this.name)
      .one(`${id}`, 'circuits_list')
      .customGET(null, this.toFilter(transformedQuery));
  }

  generateNetworkHubHierarchy(networkHubCircuits: Array<any>): Array<any> {
    const structuredNetworkHubCircuits = networkHubCircuits
      .map(item => {
        item.children = networkHubCircuits.filter(
          circuit => circuit.parent_id === item.id
        );

        return item;
      })
      .filter(item => !item.parent_id);

    return this.sortCircuitChildrenByOrder(structuredNetworkHubCircuits[0]);
  }

  sortCircuitChildrenByOrder(circuit) {
    circuit.children = circuit.children.sort((a, b) => a.order - b.order);

    circuit.children.map(item => {
      if (item.children && item.children.length) {
        this.sortCircuitChildrenByOrder(item);
      }
    });

    return circuit;
  }

  updateHubCircuit(id, item) {
    return this.service()
      .all(this.name)
      .one(`${id}`, 'circuits')
      .customPUT(item);
  }

  createHubCircuit(item) {
    return this.service()
      .all(this.name)
      .one('circuits')
      .customPOST(item);
  }

  updateInventoryCircuit(ids: any) {
    return this.service()
      .all(this.name)
      .one('update_inventory_circuit')
      .customPOST(ids);
  }

  public interceptAndTransformQuery(query: Query): Query {
    // replaces circuit_type with needed parent_id filter as circuit_type field is generated in the client side
    delete query.where['parent_id'];
    if (
      query.where &&
      query.where['circuit_type'] &&
      query.where['circuit_type']['$in']
    ) {
      const filterQuery = query.where['circuit_type']['$in'];
      if (filterQuery.length === 1) {
        query.where['parent_id'] = {};
        if (filterQuery[0] === CIRCUIT_TYPE_BOOLEAN_FILTER.CUSTOMER) {
          query.where['parent_id'] = { $ne: null };
        } else if (filterQuery[0] === CIRCUIT_TYPE_BOOLEAN_FILTER.HOST) {
          query.where['parent_id'] = null;
        }
      }
      delete query.where['circuit_type'];
    }

    if (query && query.orderBy) {
      query.orderBy.forEach(order => {
        const field = order[0];
        if (field !== 'DESC' || field !== 'ASC') {
          if (field === 'ckt_type') {
            order[0] = 'parent_id'
          } else if (field === 'level_1' || field === 'level_2') {
            order[0] = 'order';
          }
        }
      })
    }
    return query;
  }
}
