
import {mergeMap} from 'rxjs/operators';
import { OrderFlowService } from '../../order/core/order-flow.service';
import { InventoryService } from '../../inventory/core/inventory.service';
import { NotesService } from '../../shared/notes/core/notes.service';
import { Notes } from '../../shared/notes/core/notes';
import { OrderServiceService } from './order-service.service';
import { OrderService } from './order-service';
import { OrderService as OrderSvc } from '../../order/core/order.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { FlowStep } from '../../shared/flow/flow-step';
import { Flow } from '../../shared/flow/flow';
import { FlowService } from '../../shared/flow/flow.service';
import { Injectable, EventEmitter } from '@angular/core';
import { ORDER_SERVICE_DISCONNECT_STATUS } from "./order-service-disconnect-status.enum";
import { ORDER_STATUS } from "../../order/core/order-status.enum";
import { DataLockService } from "../../core/data-lock/data-lock.service";
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import { AlertService } from '../../shared/alert/alert.service';
import { DisconnectDateComponent } from '../flow/disconnect-date/disconnect-date.component';
import { StopBillingDateComponent } from '../flow/stop-billing-date/stop-billing-date.component';
import { DisconnectCompleteDateComponent } from '../flow/disconnect-complete-date/disconnect-complete-date.component';
import { ORDER_SERVICE_STATUS } from './order-service-status.enum';
import { Inventory } from 'app/inventory/core/inventory';
import { FinalBillingDateComponent } from '../flow/final-billing-date/final-billing-date.component';

@Injectable()
export class OrderServiceDisconnectFlowService extends FlowService {

  public orderService: OrderService;

  readonly ORDER_SERVICE_DISCONNECT_STATUS = ORDER_SERVICE_DISCONNECT_STATUS;
  readonly ORDER_STATUS_ENUM = ORDER_STATUS;

  readonly ICONS = {
    INVOICE_FLOW_WARNING: 'INVOICE_FLOW_WARNING',
    INVOICE_FLOW_ERROR: 'INVOICE_FLOW_ERROR',
    APPROVE_INVOICES:'APPROVE_INVOICES'
  };

  readonly DATA_LOCK_CLOSE_STATUS = {
    CANCEL_BY_USER: 0,
    TIME_EXTEND: 1,
    CANCEL_BY_TIMER: 2
  };

  public orderServiceFlow;
  public onStatusChange: EventEmitter<any> = new EventEmitter();
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "order";

  constructor(public dialogService: DialogService,
    public orderServiceService: OrderServiceService,
    public orderFlowService: OrderFlowService,
    public orderSvc: OrderSvc,
    public notesService: NotesService,
    public alertService: AlertService,
    public inventoryService: InventoryService,
    public dataLockService: DataLockService) {
    super();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  elapsedTimeModal(status:any){
    return status !== this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_USER && status !== this.DATA_LOCK_CLOSE_STATUS.TIME_EXTEND && status !== this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_TIMER
  }

  createFlow(orderService: OrderService): Flow {
    this.orderService = orderService;
    this.orderServiceFlow = super.create({ currentStep: 1 });

    let status: number = orderService.disconnect_state;
    if (!status && status !== this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_PENDING) {
      status = this.ORDER_SERVICE_DISCONNECT_STATUS.NEW;
    }
    this.orderServiceFlow.steps.push(new FlowStep({
      name: 'New', code: 1,
      revertStatusAlert: 'To reset to New, item needs to be in Vendor Accepted state before.'
    }));
    this.orderServiceFlow.steps.push(new FlowStep({
      name: 'Vendor Accepted', code: 2,
      revertStatusAlert: 'To reset to Vendor Accepted, item needs to be in Disconnect Date state before.'
    }));
    this.orderServiceFlow.steps.push(new FlowStep({
      name: 'Disconnect Date', code: 3,
      revertStatusAlert: 'To reset to Disconnect Date, item needs to be in Stop Billing Date state before.',
      progressStatusAlert: 'Item needs to be in Vendor Accepted state before.'
    }));
    this.orderServiceFlow.steps.push(new FlowStep({
      name: 'Stop Billing Date', code: 4,
      revertStatusAlert: 'To reset to Stop Billing Date, item needs to be in Disconnect Complete Date state before.',
      progressStatusAlert: 'Item needs to be in Disconnect Date state before.'
    }));
    this.orderServiceFlow.steps.push(new FlowStep({
      name: 'Disconnect Complete Date', code: 5,
      revertStatusAlert: 'To reset to Disconnect Complete Date, item needs to be in Complete Date state before.',
      progressStatusAlert: 'Item needs to be in Stop Billing Date state before.'
    }));
    this.orderServiceFlow.steps.push(new FlowStep({
      name: 'Final Billing Date', code: 6,
      revertStatusAlert: 'To reset to Final Billing Date, item needs to be in Complete Date state before.',
      progressStatusAlert: 'Item needs to be in Disconnect Complete Date state before.'
    }));
    this.orderServiceFlow.steps.push(new FlowStep({
      name: 'Complete', code: 7,
      progressStatusAlert: 'Item needs to be in Final Billing Date state before.'
    }));
    this.setStepDates(this.orderServiceFlow, orderService);
    this.updateSteps(this.orderServiceFlow, status);
    return this.orderServiceFlow;
  }

  handleStepSelection(flow: Flow, orderSvc: OrderService, step) {
    if (ORDER_SERVICE_STATUS.COMPLETED === orderSvc.disconnect_state)
      return;

    let code = step.code;
    let orderService = Object.assign({}, orderSvc);
    if (code === 1 && orderSvc.disconnect_state === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_PENDING) {
      this.alertService.error('', this.messages.get('ORDER_SERVICE_REQUIRED'));
    } else if (code == 3) {
      this.dialogService.edit(
        DisconnectDateComponent,
        {
          order: this.orderServiceService.findByIdForEdit(orderService.id)
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result && this.elapsedTimeModal(result.status)) {
            // get inventory id to update it's date
            const updateInventoryId = (result.disconnect_date && orderService && orderService.inventory)
              ? orderService.inventory.id
              : null

            if (flow.currentStep >= code) {
              orderService.disconnect_state = orderService.date_received = null;
            }

            let status: number = this.ORDER_SERVICE_DISCONNECT_STATUS.DISCONNECT_DATE;
            orderService.disconnect_state = status;
            orderService.date_received = result.date_received;
            orderService.disconnect_date = result.disconnect_date;

            this.orderServiceService.update(orderService.id, orderService)
              .subscribe((ord) => {
                Object.assign(orderSvc, ord);
                this.setStepDates(flow, orderSvc);
                this.updateSteps(flow, status);
              });
          }
        })
    } else if (code == 4) {
      this.dialogService.edit(
        StopBillingDateComponent,
        {
          order: this.orderServiceService.findByIdForEdit(orderService.id)
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result && this.elapsedTimeModal(result.status)) {

            if (flow.currentStep >= code) {
              orderService.stop_billing_date = null;
            }

            let status: number = this.ORDER_SERVICE_DISCONNECT_STATUS.STOP_BILLING_DATE;
            orderService.disconnect_state = status;
            orderService.stop_billing_date = result.stop_billing_date;

            this.orderServiceService.update(orderService.id, orderService)
              .subscribe((ord) => {
                Object.assign(orderSvc, ord);
                this.setStepDates(flow, orderSvc);
                this.updateSteps(flow, status);
              });
          }
        })
    } else if (code == 5) {
      this.dialogService.edit(
        DisconnectCompleteDateComponent,
        {
          order: this.orderServiceService.findByIdForEdit(orderService.id)
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result && this.elapsedTimeModal(result.status)) {

            if (flow.currentStep >= code) {
              orderService.disconnect_complete_date = null;
            }

            let status: number = this.ORDER_SERVICE_DISCONNECT_STATUS.DISCONNECT_COMPLETE_DATE;
            orderService.disconnect_state = status;
            orderService.disconnect_complete_date = result.disconnect_complete_date;

            this.orderServiceService.update(orderService.id, orderService)
              .subscribe((ord) => {
                Object.assign(orderSvc, ord);
                this.setStepDates(flow, orderSvc);
                this.updateSteps(flow, status);
              });
          }
        })
    } else if (code == 6) {
      this.dialogService.edit(
        FinalBillingDateComponent,
        {
          order: this.orderServiceService.findByIdForEdit(orderService.id)
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result && this.elapsedTimeModal(result.status)) {

            let status: number = this.ORDER_SERVICE_DISCONNECT_STATUS.FINAL_BILLING_DATE;
            orderService.disconnect_state = status;
            orderService.final_billing_date = result.final_billing_date;

            this.orderServiceService.update(orderService.id, orderService)
              .subscribe((ord) => {
                Object.assign(orderSvc, ord);
                this.setStepDates(flow, orderSvc);
                this.updateSteps(flow, status);
              });
          }
        })
    } else if (code == 7) {
      let status: number = this.ORDER_SERVICE_DISCONNECT_STATUS.COMPLETED;
      orderService.disconnect_state = status;

      this.orderServiceService.update(orderService.id, orderService)
        .subscribe((ord) => {
          this.updateSteps(flow, status);
          this.onStatusChange.emit({status, orderService: orderService});
        });
    }

  }

  public async setStepDates(flow: Flow, orderService: OrderService){
    flow.steps.forEach((item) => {
      switch (item.code) {
        case(1): {
          item.dateInfo = orderService.created_at || this.orderFlowService.order.vendor_reject_date || null;
          break;
        }
        case(2): {
          item.dateInfo = this.orderFlowService.order.vendor_accept_date ? this.orderFlowService.order.vendor_accept_date : null;
          break;
        }
        case (3): {
          item.dateInfo = orderService.disconnect_date || null;
          break;
        }
        case (4): {
          item.dateInfo = orderService.stop_billing_date || null;
          break;
        }
        case (5): {
          item.dateInfo = orderService.disconnect_complete_date || null;
          break;
        }
        case (6): {
          item.dateInfo = orderService.final_billing_date || null;
          break;
        }
      }
    });
  }

  public updateSteps(flow, status: number) {
    if(status === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_REJECTED){
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = 'New (Reject)';
          //I have to research for what we use this code
          // s.revertStatusAlert =
          //   `To reject to ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_REJECTED)},
          // item needs to be in ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.RFA)} state before.`
        }
      });
    }

    if(status === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_RESET){
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = 'New (Reset)';
          //I have to research for what we use this code
          // s.revertStatusAlert =
          //   `To reject to ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_REJECTED)},
          // item needs to be in ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.RFA)} state before.`
        }
      });
    }
    if (status === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_PENDING) {
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = 'New (Pending)';
          s.icon = this.ICONS.INVOICE_FLOW_ERROR
        }
      });
    }
    if (status === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW) {
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = 'New ';
          s.icon = this.ICONS.APPROVE_INVOICES
        }
      });
    }

    if (status === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW) {
      this.updateStep(1, 'New');
      flow.currentStep = 1;
    }else if(status === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_PENDING) {
      this.updateStep(1,'NEW_PENDING','mat-error', this.ICONS.INVOICE_FLOW_ERROR)
      flow.currentStep = 1;
    }
    else if(status === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_REJECTED){
      this.updateStep(1, 'New (Rejected)');
      flow.currentStep = 1
    }

    else if(status === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_RESET){
      this.updateStep(1, 'New (Reset)');
      flow.currentStep = 1
    }

    else if (status === this.ORDER_SERVICE_DISCONNECT_STATUS.VENDOR_ACCEPTED) {
      flow.currentStep = 2;
    }
    else if (status === this.ORDER_SERVICE_DISCONNECT_STATUS.DISCONNECT_DATE) {
      flow.currentStep = 3;
    }
    else if (status === this.ORDER_SERVICE_DISCONNECT_STATUS.STOP_BILLING_DATE) {
      flow.currentStep = 4;
    }
    else if (status === this.ORDER_SERVICE_DISCONNECT_STATUS.DISCONNECT_COMPLETE_DATE) {
      flow.currentStep = 5;
    }
    else if (status === this.ORDER_SERVICE_DISCONNECT_STATUS.FINAL_BILLING_DATE) {
      flow.currentStep = 6;
    }
    else if (status === this.ORDER_SERVICE_DISCONNECT_STATUS.COMPLETED) {
      flow.currentStep = 7;
    }
    else if (status === this.ORDER_SERVICE_DISCONNECT_STATUS.CANCELLED) {
      this.updateServiceStep(1, 'Cancelled');
      flow.currentStep = 1;
    }
    this.onStatusChange.emit(status);
  }

  public addNote(noteMessage) {
    let note = new Notes({
      entity_id: this.orderService.order_id,
      entity_type: "order",
      content: noteMessage
    });

    this.notesService.create(note);
  }

  private updateServiceStep(code: number, name: string, className?: string, icon?: string) {
    let step = this.findServiceStep(code);

    if (step) {
      step.name = name;
      step.className = className;
      step.icon = icon;
    }
  }

  private findServiceStep(code) {
    return this.orderServiceFlow.steps.filter((step) => {
      return step.code === code;
    })[0]
  }
}
