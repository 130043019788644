import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-inventory-details-tabs',
  templateUrl: './inventory-details-tabs.component.html',
  styleUrls: ['./inventory-details-tabs.component.css']
})
export class InventoryDetailsTabsComponent implements OnInit {

  @Output() tabChangeRequested: EventEmitter<any> = new EventEmitter<any>();

  tabs = [
    {label: 'Summary', key: 'summary'},
    {label: 'Sites', key: 'site'},
    {label: 'Orders', key: 'order'},
    {label: 'Billing', key: 'billing'},
    {label: 'GL Codes', key: 'code'},
    {label: 'Pricing', key: 'pricing'},
    {label: 'Related Service', key: 'related-circuit'},
    {label: 'Documents', key: 'document'},
    {label: 'Contract Terms', key: 'contract-terms'}
  ];

  @Input() activeTabIndex: number = 0;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router) {
  }

  ngOnInit(): void {
  }

  onTabChange(index: number) {
    const inventory = this.activatedRoute.parent.snapshot.data.inventory;
    const key = this.activatedRoute.snapshot.queryParams['key'];
    const activeTab = this.tabs[index].key;
    this.router.navigate(['inventory', inventory.id, 'show', activeTab], {
      replaceUrl: !key
    });
  }
}
