import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({name: 'caPercent'})
export class CaPercentagePipe extends DecimalPipe implements PipeTransform {

  constructor() {
    super("en-US");
  }
  private __currLocale: any = JSON.parse(localStorage.getItem('__currLocale'));
  private __currDigit: any = JSON.parse(localStorage.getItem('__currDigit'));

  transform(value: any): any {

    let dig = this.__currDigit ? this.__currDigit : '1.2-2';
    let loc = this.__currLocale ? this.__currLocale : 'en-US';

    if(!value){
      return value;
    }

    let formattedByCurrencyPipe = super.transform(value, dig , loc);

    return formattedByCurrencyPipe + '%'

  }
}
