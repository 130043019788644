
import {of as observableOf,  Observable, forkJoin } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { Address } from '../../../address/core/address.model';
import { AddressService } from '../../../address/core/address.service';
import { ConfigService } from '../../../core/config/config.service';
import { DataLockService } from '../../../core/data-lock/data-lock.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { IMessagesResourceService, ResourcesService } from '../../../core/resources/resources.service';
import { LocationService } from '../../../location/core/location.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { EntityEditContext } from '../../../shared/entity-lock/entity-edit-context';
import { EntityLockData } from '../../../shared/entity-lock/entity-lock-data';
import { VendorRemitAddress } from '../../core/vendor-remit-address';
import { VendorRemitAddressService } from '../../core/vendor-remit-address.service';
import {PermissionService} from '../../../permissions/core/permission.service';
import {map} from "rxjs/operators";
import {flatMap} from "rxjs/internal/operators";

@Component({
  selector: 'app-vendor-remit-address-manage-dialog',
  templateUrl: './vendor-remit-address-manage-dialog.component.html',
  styleUrls: ['./vendor-remit-address-manage-dialog.component.css']
})
export class VendorRemitAddressManageDialogComponent extends PageManageDialogComponent implements OnInit {
  messages: IMessagesResourceService;
  messagesCore: IMessagesResourceService;

  readonly MESSAGES_MODULE: string = 'vendor-remit-address';
  readonly MESSAGES_MODULE_CORE: string = 'core';

  vendorRemitAddress: any;

  address: Address;
  deletePermission = false;
  formModeEnabled = false;

  constructor(public formBuilder: FormBuilder,
    public alert: AlertService,
    public dialogService: DialogService,
    public locationService: LocationService,
    public configService: ConfigService,
    public vendorRemitAddressService: VendorRemitAddressService,
    public dialogRef: MatDialogRef<VendorRemitAddressManageDialogComponent>,
    public addressService: AddressService,
    private permissionService: PermissionService,
    public dataLockService?: DataLockService) {
    super(dialogService, dialogRef, dataLockService);

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.messagesCore = ResourcesService.messages(this.MESSAGES_MODULE_CORE);
  }

  ngOnInit() {
    this.loadPermissions().subscribe(() => {});
    this.vendorRemitAddress = this.vendorRemitAddress || new VendorRemitAddress();
    this.isUpdate = !!this.vendorRemitAddress.id;
    this.address = (this.isUpdate && this.vendorRemitAddress.address) ? this.vendorRemitAddress.address : null;

    this.form = this.formBuilder.group({
      vendor_location_id: new FormControl(this.vendorRemitAddress.vendor_location_id, Validators.required),
      vendor_id: new FormControl(this.vendorRemitAddress.vendor_id, Validators.required),
    });

    this.formTitle = this.isUpdate ? 'Edit Vendor Remit Address' : 'Create Vendor Remit Address';
    this.initViewOnlyForm(
      this.vendorRemitAddress.id,
      'View Vendor Remit Addrss',
      'Edit Vendor Remit Address',
      this.dataLockService.DATA_LOCK_TYPES.VENDOR_REMIT_ADDRESS
    );
    this.afterInit();
  }

  init() {
    if (this.vendorRemitAddress && this.vendorRemitAddress.id) {
      return this.vendorRemitAddressService.findByIdForEdit(this.vendorRemitAddress.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .pipe(flatMap((vendorRemitAddress) => {
          this.vendorRemitAddress = vendorRemitAddress;
          return observableOf(vendorRemitAddress);
        }))
    }
    return observableOf(this.vendorRemitAddress);
  }

  onAddressChanged(address: Address) {
    if (address) {
      if (address.internal || address.is_validated) {
        this.address = new Address(address);
      } else {
        this.address = null;
      }
    } else {
      this.address = null;
    }
  }

  onFormModeChanged(isFormMode: boolean) {
    this.formModeEnabled = isFormMode;
  }

  onSubmit({ value, valid }: { value: VendorRemitAddress, valid: boolean }) {
    if (valid && this.address) {
      value.vendor_location_id = value?.vendor_location_id?.trim();
      this.vendorRemitAddress = Object.assign({}, this.vendorRemitAddress, value);

      this.addressCheck(this.address)
        .subscribe((address: Address) => {
          this.vendorRemitAddress.address_id = address.id;

          if (this.vendorRemitAddress.id) {
            this.update(this.vendorRemitAddress);
          } else {
            this.create(this.vendorRemitAddress);
          }
        }, (err) => {
          this.alert.error('', this.messages.get('CREATE_ADDRESS_ERROR'));
        });
    } else {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }

  addressCheck(address: Address): Observable<Address> {
    if (address.internal && address.id) {
      return observableOf(address);
    } else {
      return this.addressService.createOrUpdate(address);
    }
  }

  create(vendorRemitAddress: VendorRemitAddress) {
    this.toggleDialogButtons();
    this.vendorRemitAddressService.create(vendorRemitAddress)
      .subscribe(
        (result) => {
          this.closeDialog(result, true);
        },
        (error) => {
          this.toggleDialogButtons(false);
          const message = (error.error && error.error['message'] == 'Duplicate error') ? 'DUPLICATE_VENDOR_REMIT_ADDRESS' : 'CREATE_ERROR';
          this.alert.error('', this.messages.get(message));
        }
      );
  }

  update(vendorRemitAddress: VendorRemitAddress) {
    this.toggleDialogButtons();
    this.vendorRemitAddressService.update(this.vendorRemitAddress.id, vendorRemitAddress)
      .subscribe(
        (result) => {
          this.closeDialog(result, true);
        },
        (error) => {
          this.toggleDialogButtons(false);
          const message = (error.error && error.error['message'] == 'Duplicate error') ? 'DUPLICATE_VENDOR_REMIT_ADDRESS' : 'UPDATE_ERROR';
          this.alert.error('', this.messages.get(message));
        }
      )
  }

  deleteVendorRemitAddress(event) {
    if (this.isUpdate && event) {
      this.toggleDialogButtons();
      this.vendorRemitAddressService.delete(this.vendorRemitAddress.id)
        .subscribe((deleteResult) => {
          this.toggleDialogButtons(false);
          if (deleteResult) {
            this.dialogRef.close({ deleted: true });
          }
        }, (err) => {
          this.toggleDialogButtons(false);
          if (err.status === 400) {
            setTimeout(() => {
              this.alert.error('', this.messages.get('DELETE_ACTIVE_ITEM_ERROR'));
            }, 600);
          } else {
            this.alert.error('', this.messages.get('DELETE_ERROR'));
          }
        });
    }
  }

  editForm() {
    this.vendorRemitAddressService.findByIdForEdit(this.vendorRemitAddress.id).subscribe((result: EntityLockData) => {
      if (result['$lock'] && result['$lock']['status'] === 403) {
        this.alert.error('', `${this.messagesCore.get('DATA_LOCKED')}${result['$lock']['user']}`)
      } else {
        this.form.enable();
        this.viewOnly = false;
        this.formTitle = this.editTitle;
      }
    })
  }

  cancel() {
    this.vendorRemitAddressService.cancelEdit();
    this.closeDialog();
  }

  loadPermissions() {
    const deletePermiss$ = this.permissionService.isAllowed("vendor-remit-address", "DELETE");
    return forkJoin(
      deletePermiss$
    ).pipe(map(([deletePermiss]) => {
      this.deletePermission = deletePermiss;
    }));
  }
}
