import { DialogService } from '../../../shared/dialog/dialog.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-final-billing-date',
  templateUrl: './final-billing-date.component.html',
})
export class FinalBillingDateComponent extends PageManageDialogComponent implements OnInit {

    public order: any;
    public final_billing_date: Date;

    constructor(public formBuilder: FormBuilder,
        public dialogService: DialogService,
        public dialogRef: MatDialogRef<FinalBillingDateComponent>) {
        super(dialogService, dialogRef);
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
        final_billing_date: [this.order.final_billing_date || null, Validators.required],
        });
    }

    submit({ value, valid }: { value: any, valid: boolean }) {
        this.dialogRef.close({
        final_billing_date: value.final_billing_date,
        });
    }

    cancel() {
        this.closeDialog();
    }

}
