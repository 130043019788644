<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>
    {{ formTitle }}
  </h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <div class="flex-container" fxLayout="row" fxLayout="end center">
    <div class="flex-item" fxFlex>
      <h3 class="muted">Drag to reorder</h3>
    </div>

    <!-- sorts only by value -->
    <ng-container *ngIf="!isBandwidthDictionary">
      <div class="flex-item" fxFlex>
        <button
          mat-icon-button
          class="sort-button sort-button-margin"
          (click)="sortItems()"
        >
          <i class="material-icons">sort_by_alpha</i>
        </button>
      </div>
    </ng-container>

    <!-- opens sort menu to sort by value or standardized value -->
    <ng-container *ngIf="isBandwidthDictionary">
      <div class="flex-item" fxFlex>
        <button
          mat-icon-button
          class="sort-button sort-button-margin"
          [matMenuTriggerFor]="dictionaryBandwidthSortOptions"
        >
          <i class="material-icons">sort_by_alpha</i>
        </button>

        <mat-menu #dictionaryBandwidthSortOptions="matMenu">
          <button mat-menu-item (click)="sortItems('value')">Value</button>
          <button mat-menu-item (click)="sortItems('standardized_value')">
            Standardized Value
          </button>
        </mat-menu>
      </div>
    </ng-container>
  </div>
  <div class="panel-body order-list-container">
    <ul
      class="list-group order-list"
      dnd-sortable-container
      [sortableData]="lookupItems"
    >
      <li
        *ngFor="let item of lookupItems; let i = index"
        class="list-group-item ca-default-border"
        dnd-sortable
        [sortableIndex]="i"
      >
        <div class="item-center-container">
          <i class="material-icons item-center">reorder</i>
          <span class="item-center">{{ item.value }}</span>
        </div>
      </li>
    </ul>
  </div>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space"></div>

  <button mat-button type="submit" (click)="close()">
    Cancel
  </button>

  <button mat-button type="submit" (click)="save()">
    Save
  </button>
</mat-dialog-actions>
