
import {of as observableOf, Observable, forkJoin, Subscription, from} from 'rxjs';

import {map, tap} from 'rxjs/operators';
import { EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Query from '../../core/query/query';


export const PICKER_COMPONENT_TEMPLATE = `
    <ca-picker [textField]="TEXT_FIELD"
               [secondTextField]="SECOND_TEXT_FIELD"
               [customFormating]="CUSTOM_FORMATING"
               [customFormatingDate]="CUSTOM_FORMATING_DATE"
               [keyField]="KEY_FIELD"
               [pickerIcon]="pickerIcon"
               [control]="control"
               [entries]="entries"
               [initialItem]="_initialItem"
               (onScrollEnd)="onScrollEnd($event)"
               (onSearchChange)="onSearchChange($event)"
               (onSelectionChange)="onSelectionChange($event)"
               [disabled]="disabled"
               [required]="required"
               [multiple]="multiple"
               [searchEnabled]="searchEnabled"
               [placeholder]="placeholder"
               [clearEnabled]="clearEnabled"
               [italicStyleOptionPredicate]="italicStyleOptionPredicate"
               #caPicker
               (onClear)="handleClear()"
               [secondLineTextField]="SECOND_LINE_TEXT_FIELD"
               [pickerType]="pickerType"></ca-picker>
  `;

export const PICKER_COMPONENT_PROVIDERS = (service, component) => [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component),
    multi: true
  },
  service
];

// @ts-ignore
// @Directive({
//   selector: 'ca-picker-base'
// })
@Directive()
export abstract class PickerComponentBase implements OnInit, ControlValueAccessor {
  loadDataSusbscription: Subscription;
  get _initialItem() {
    return this.initialItem;
  }

  set _initialItem(val) {
    this.initialItem = val;
    if (val) {
      this.entries.push(val);
    }
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
  }

  entries: any = [];
  _value: any = '';
  initialItem: any = null;
  TEXT_FIELD = 'name';
  KEY_FIELD = 'id';
  CUSTOM_FORMATING = [];
  CUSTOM_FORMATING_DATE = [];
  SECOND_LINE_TEXT_FIELD = null;
  SECOND_TEXT_FIELD = '';
  pickerType = '';
  addNew = '';

  // tslint:disable-next-line
  @Output('onSelectionChange') onSelectionChangeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() onPickerChange: EventEmitter<any> = new EventEmitter();
  @Output() onPickerClear: EventEmitter<any> = new EventEmitter();
  @Input() searchEnabled = true;
  @Input() multiple = false;
  @Input() pickerIcon = false;
  @Input() siteType: boolean;
  initialItemId: any;

  query: Query = new Query({
    limit: 20
  });

  @ViewChild('caPicker') caPicker;

  @Input() italicStyleOptionPredicate = (option) => false;

  onChange = (_: any) => {
  };

  onTouched = () => {
  };

  abstract loadEntries(): Observable<any>;

  abstract search(value: string): Observable<any>;

  // REDEFINE THIS IN CHILD CLASS (OPTIONAL)
  loadInitialItem(): Observable<any> {
    return observableOf({});
  }

  loadData(): void {
    const data$ = this.initialItemId != null ? forkJoin([
      this.loadEntries(),
      this.loadInitialItem()
      ]
    ).pipe(
      tap(([_, initialItem]) => {
        this.initialItem = initialItem;
      }),
      map(([allEntries]) => {
        return allEntries;
      }),) : this.loadEntries();


     if (this.loadDataSusbscription) {
       this.loadDataSusbscription.unsubscribe();
     }

    this.loadDataSusbscription = from(data$).subscribe((result: any) => {
      this.entries = result.items ? Array.from(result.items) : Array.from(result);
      this.query = this.query || new Query({
        limit: 20
      });
      this.query.total = result.total;
      this.query.page = 1;
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  writeValue(value: any): void {
    this.value = value;
  }

  writeFiledStatus(functionName: string): void{
    this['control'][functionName]();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onScrollEnd($event) {
    if (this.query.canNext()) {
      this.query.page++;
      this.query.offset = this.query.from() - 1;
      from(this.loadEntries())
        .subscribe((result) => {
          from(result.items).subscribe((item: any) => {
            if (item.id === this.value) {
              this.entries.subscribe((initialItem: any, i) => {
                if (initialItem.id === this.value) {
                  this.entries.splice(i, 1);
                }
              });
            }
          });
          this.entries = this.entries.concat(result.items);
          this.query.total = result.total;
        });
    }
  }

  handleClear() {
    this.onPickerClear.emit();
  }

  onSearchChange($event) {
    if (this.searchEnabled) {
      this.query.offset = 0;
      from(this.search($event.value))
        .subscribe((result: any) => {
          this.entries = result.items;
          this.query.total = result.total;
          this.query.page = 1;
        });
    }
  }

  onSelectionChange($event) {
    let selectedItems;

    if (Array.isArray($event.value)) {
      selectedItems = this.entries.filter(item => {
        return $event.value.includes(item.account_no || item.id);
      })
    } else {
      selectedItems = this.entries.filter(item => {
        return item[this.KEY_FIELD] === $event.value || item[this.KEY_FIELD] === $event.value[this.KEY_FIELD];
      })[0];
    }
    // tslint:disable-next-line
    let selectedItemsString = Array.isArray(selectedItems) ? selectedItems.map(e => e.account_no || e.value || e.name).join(',') : selectedItems[this.KEY_FIELD];


    if (this.siteType) {
      this.onSelectionChangeEmitter.emit({ value: $event.value, site_type_id: selectedItems['site_type_id'] });
    } else {
      this.onSelectionChangeEmitter.emit({ value: $event.value, text: selectedItemsString,
        selection: selectedItems});
    }

    if (!Array.isArray(selectedItems)) {
      this.onPickerChange.emit({ value: $event.value, text: selectedItemsString });
    } else {
      this.onPickerChange.emit({
        value: $event.value, text: selectedItemsString
      });
    }
  }
}
