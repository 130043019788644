
export const auditAlertRateFilterContext = {
  filters: [
    {
      field: 'charge_id',
      label: 'Charge ID',
      type: 'number',
    },
    {
      field: 'cost_invoice_charge.sp_serv_id',
      label: 'SPID',
      type: 'string',
    },
  ]
};
