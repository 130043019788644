import { Injectable } from '@angular/core';
import { VendorRemitAddressLookupProvider } from 'app/vendor-remit-address/core/vendor-remit-address-lookup.provider';
import { VendorRemitAddressService } from 'app/vendor-remit-address/core/vendor-remit-address.service';
import { AccountLookupProvider } from "../../account/core/account-lookup.provider";
import { AccountService } from "../../account/core/account.service";
import { SubclientLookupProvider } from "../../account/core/subclient-lookup.provider";
import { FilterService } from "../../core/filter/filter.service";
import Query from "../../core/query/query";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { LOOKUP_ENUM } from "../../dictionary/core/lookup.enum";
import { CurrencyLookupProvider } from "../../location/core/currency-lookup.provider";
import { LocationService } from "../../location/core/location.service";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { VendorLookupProvider } from "../../vendor/core/vendor-lookup.provider";
import { VendorService } from "../../vendor/core/vendor.service";
import { invoiceFilterContext } from "./invoice-filter.context";
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class InvoiceFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(public userSettingsService: UserSettingsService,
              public vendorService: VendorService,
              public vendorRemitAddressService: VendorRemitAddressService,
              dictionaryService: DictionaryService,
              public accountService: AccountService,
              public locationService: LocationService
  ) {
    super("app.cost.invoice-filter", invoiceFilterContext, userSettingsService);

    this.addLookupProvider('account', new AccountLookupProvider(accountService));
    this.addLookupProvider('subclient', new SubclientLookupProvider(dictionaryService));
    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true, true));
    this.addLookupProvider('vendorRemitAddress', new VendorRemitAddressLookupProvider(vendorRemitAddressService, true));
    this.addLookupProvider('invoiceStatus', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.INVOICE_STATUS.modelName, false, false,
      new Query({
        where: { id: { $notIn: [LOOKUP_ENUM.INVOICE_STATUS.NEW_PENDING, LOOKUP_ENUM.INVOICE_STATUS.GL_PENDING, LOOKUP_ENUM.INVOICE_STATUS.GL_BATCH_PROCESSING], $gte: [0]  }},
        orderBy: [["value", "ASC"]],
        limit:100
      })));
      this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));
    this.addLookupProvider('currency', new CurrencyLookupProvider(locationService));
    this.addLookupProvider('documentInvoiceType', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.DOCUMENT_INVOICE_TYPE.modelName));
    this.addLookupProvider('dnpReasonCode', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.DNP_REASON_CODE.modelName,true, true));
  }

}
