import {of as observableOf} from 'rxjs';

import {takeUntil} from 'rxjs/operators';
import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {PageListComponent} from '../../core/page-list.component';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {PageContext} from '../../core/page.context';
import {CommonAlertService} from '../core/common-alert.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

import {InventoryQuery} from '../../inventory/core/inventory.query';
import {Inventory} from '../../inventory/core/inventory';
import {AlertInventoryGridService} from '../core/alert-inventory-grid.service';
import {GridSettingsComponent} from '../../core/grid/grid-settings.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {InventoryService} from '../../inventory/core/inventory.service';
import {TabGroup, TabService} from '../../shared/tabs/tab.service';
import {AlertInventoryFilterService} from '../core/alert-inventory/alert-inventory-filter.service';
import { AlertChargeTotalGridService } from '../core/alert-charge-total-grid.service';

import {GridService} from '../../shared/grid/grid.service';

import {InvoiceFacepageService} from 'app/invoice/core/invoice-facepage.service';
import {InvoiceFacepage} from 'app/invoice/core/invoice-facepage';
import {IMessagesResourceService} from 'app/core/resources/resources.service';
import {AlertService} from 'app/shared/alert/alert.service';
import {ResourcesService} from '../../core/resources/resources.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {LOOKUP_ENUM} from "../../dictionary/core/lookup.enum";


@Component({
  selector: 'app-alert-inventory',
  templateUrl: './alert-inventory.component.html',
  styleUrls: ['./alert-inventory.component.css']
})
export class AlertInventoryComponent extends PageListComponent
  implements AfterViewInit, OnDestroy {
  alert;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTab = 0;
  public inventories: Array<any> = [];
  public columns: any;
  public sorting: any[][];
  public selection: Inventory;

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @ViewChild('chargeGrid') chargeGrid: DxDataGridComponent;

  readonly MESSAGES_MODULE: string = 'common-alert';
  readonly sideSections = {
    none: null,
    info: 'info',
    filter: 'filter'
  };
  isReloadDisabled = false;
  _isSidePanelOpen = false;
  activeTabIndex = 0;
  isGridSettingsDisabled = true;
  invoice: InvoiceFacepage;
  messages: IMessagesResourceService;

  chargeTotalColumns = [];
  chargeTotalData = [];

  readonly PLACEHOLDERS = {
    OWNER_UNASSIGNED: 'Unassigned'
  };

  public query: InventoryQuery = new InventoryQuery({
    orderBy: [['id', 'ASC']]
  });

  public alertId: number;

  constructor(
    public settingsService: UserSettingsService,
    public route: ActivatedRoute,
    public commonAlertService: CommonAlertService,
    public alertService: AlertService,
    public alertInventoryGridService: AlertInventoryGridService,
    public router: Router,
    public dialogService: DialogService,
    public loaderService: LoaderService,
    public inventoryService: InventoryService,
    public inventoryFilterService: AlertInventoryFilterService,
    public invoiceService: InvoiceFacepageService,
    public tabService: TabService,
    public alertInventoryChargeTotalGridService: AlertChargeTotalGridService,
  ) {
    super(
      new PageContext({
        name: 'app.alert.alert-inventory',
        settings: settingsService
      })
    );

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    if (
      tab.key === 2 &&
      this.sider.getActiveSection().name === this.sideSections.filter
    ) {
      this._isSidePanelOpen = true;

      observableOf(true).subscribe(() => {
        this._isSidePanelOpen
          ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME)
          : this.sider.closeComponent();

        this.tabGroup.forceRerender();
      });
    }

    if (
      tab.key !== 2 &&
      this.sider.getActiveSection().name === this.sideSections.filter
    ) {
      this._isSidePanelOpen = false;

      observableOf(true).subscribe(() => {
        this._isSidePanelOpen
          ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME)
          : this.sider.closeComponent();

        this.tabGroup.forceRerender();
      });
    }

    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }

    /* Settings grid disabled */
    if (
      this.tabGroup.isActive(2) ||
      this.tabGroup.isActive(3) ||
      this.tabGroup.isActive(4)
    ) {
      this.isGridSettingsDisabled = false;
    } else {
      this.isGridSettingsDisabled = true;
    }
  }

  public loadTabs() {
    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({key: 1, title: 'Summary'});
    this.tabGroup.addTab({key: 2, title: 'Details'});

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe(tab => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }

  public reloadInvoice() {
    this.commonAlertService.findById(this.route.params['_value'].id).subscribe(
      alert => {
        if(alert.invoice.header.status_code >= LOOKUP_ENUM.INVOICE_STATUS.GL_BATCH_OUTPUT){
          this.isReloadDisabled = true;
          this.alertService.error('', this.messages.get('INVOICE_RELOAD_STATUS_GREATER_THAN_BATCH'))
        } else {
          this.loaderService.displayLoader();
          this.invoiceService.reload(this.alert.invoice_id)
            .subscribe(
              (invoice: InvoiceFacepage) => {
                this.invoice = invoice;
                this.alertService.success('', this.messages.get('INVOICE_RELOAD_SUCCESS'));
              }, error => {
                // if(response.status === 409) {
                //   this.alertService.error('', response.error.message);
                // }
                // else {
                this.loaderService.hideLoader();
                this.alertService.error('', this.messages.get('INVOICE_RELOAD_FAILED'));
                //}
              }, () => {
                this.loaderService.hideLoader();
              });
        }
      }
    );
  }

  loadData(alertId: number) {
    //this.loadInventories(this.query);
    this.commonAlertService.findById(this.route.params['_value'].id).subscribe(
      alert => {
        this.alert = alert;
        this.alert.rule_name === 'CHARGE_TOTAL' ? this.getChargeTotal() : this.loadInventories(this.query);
        if(alert.invoice.header.status_code >= LOOKUP_ENUM.INVOICE_STATUS.GL_BATCH_OUTPUT){
          this.isReloadDisabled = true;
        }
        },
      error => {
        console.log('Error fetching alert info');
      }
    );
  }

  loadInventories(query: InventoryQuery) {
    this.commonAlertService
      .findInventory(this.alertId, query)
      .subscribe(inventory => {
        // TODO: This should be inventory_dwetails taking info on inventory instead of 2 requests
        let inventories = inventory.rows;
        inventories.forEach(inventory => {
          let id = inventory.id;
          let details = this.alert.inventory_details.find(
            detail => id === detail.inventory_id
          );
          if (details) {
            inventory.message = details.message;
          }
        });

        this.inventories = inventories;

        if (!this.inventories.length) {
          this.tabGroup.disable(this.tabGroup.find(2), true);
        }

        query.total = inventory.count;
        this.selectFirstRow();
        setTimeout(() => {
          this.dataGrid.instance.refresh();
          this.dataGrid.instance.repaint();
        }, 500);
      });
  }

  refresh() {
    this.alert.rule_name === 'CHARGE_TOTAL' ? this.getChargeTotal() : this.loadInventories(this.query);
  }

  onSelectionChanged(row) {
    this.selection = <Inventory>row.selectedRowsData[0];
  }

  onPageChange(query: InventoryQuery) {
    this.loadInventories(query);
  }

  filterData(query: InventoryQuery) {
    this.query = query;
    this.loadInventories(query);
  }

  clearFilter(query: InventoryQuery) {
    this.query = query;
    this.alert.rule_name === 'CHARGE_TOTAL' ? this.getChargeTotal() : this.loadInventories(this.query);
  }

  ngOnInit() {
    //this.alert = this.route.data['alert'];
    this.alert = this.route.snapshot.data.alert;
    this.loadTabs();
    this._init();
  }

  gridSettings() {
    this.dialogService
      .open(GridSettingsComponent, {
        service: this.alertInventoryGridService
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.alertInventoryGridService;
  }

  getDefaultSort(): any {
    return [['id', 'ASC']];
  }

  _init() {
    this.chargeTotalColumns = this.alertInventoryChargeTotalGridService.columns();
    this.prepareList();
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.alertId = params['id'];
      this.alert.rule_name === 'CHARGE_TOTAL' ? this.getChargeTotal() : this.loadInventories(this.query);
    });
  }

  ngAfterViewInit(): void {
    if(this.dataGrid) {
      this.alertInventoryGridService.create(this.dataGrid.instance, {
        noDataText: this.alertInventoryGridService.noDataMessage
      });
    }
    if (this.chargeGrid) {
      this.alertInventoryChargeTotalGridService.create(this.chargeGrid.instance, {
        noDataText: 'No Data'
      });
    }
    super.ngAfterViewInit();
  }

  sortColumn(sorting) {
    this.query['orderBy'] = sorting;

    this.alert.rule_name === 'CHARGE_TOTAL' ? this.getChargeTotal() : this.loadInventories(this.query);
  }

  back(): void {
    if (document.referrer.indexOf(window.location.host) >= 0) {
      history.back();
    } else {
      this.router.navigate(['/alert']);
    }
  }

  csv() {
    const {
      alertInventoryGridService,
      query,
      inventoryService,
      commonAlertService,
      alertId,
      alert
    } = this;
    alertInventoryGridService.csvMap().subscribe(fields => {
      const alertInventoryCSVTransform = data => {
        const items = data.rows;

        return items.map(item => {
          const {id} = item;
          const details = alert.inventory_details.find(
            detail => id === detail.inventory_id
          );
          item.is_customer_provided_access = item.is_customer_provided_access ? 'Yes' : 'No'
          if (details) {
            item.message = details.message;
          }

          return item;
        });
      };

      inventoryService.exportToCSV('alert-inventories', {
        fields,
        query: query,
        fetchDataHandler: commonAlertService.findInventory.bind(
          commonAlertService,
          alertId,
          query
        ),
        middleware: [alertInventoryCSVTransform]
      });
    });
  }

  toggleFilter() {
    this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
    this.tabGroup.forceRerender();
  }

  ngOnDestroy() {
    if (this.sider.getActiveSection().name === this.sideSections.filter) {
      this.toggleFilter();
    }
    this.destroy$.next(true);
    this.destroy$.complete();
    this.tabGroup.onActivate.unsubscribe();
  }

  public goToInvoiceDetails() {
    if (this.alert && this.alert.invoice && this.alert.invoice.invoice_id) {
      this.router.navigate(['/invoice', this.alert.invoice.invoice_id, 'show', 'overview']);
    }
  }

  getChargeTotal() {
    this.chargeTotalData = [{
      message: this.alert.message
    }];
    setTimeout(() => {
      this.chargeGrid.instance.refresh();
      this.chargeGrid.instance.repaint();
    }, 500);
  }
}
