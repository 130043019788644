import {from, Observable} from "rxjs";
import {LocationService} from "./location.service";
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import Query from "../../core/query/query";
import {map} from "rxjs/operators";

export class CountryLookupProvider extends IFilterLookupProvider {
  constructor(public locationService: LocationService,
              public isAlphaCode?: boolean) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    searchQuery = searchQuery || new Query();
    searchQuery.orderBy = [['alpha_2_code', 'ASC']];
    
    return this.locationService.findLookupCountries(searchQuery)
      .pipe(map((result: any) => {
          return result.items.map((entry) => {
            return {
              key: entry.id,
              value: entry.alpha_2_code,
              name: entry.name
            };
          });
      }));
  }
}
