<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Revert to New</h1>
</div>
<div mat-dialog-content class="ca-dialog-content" *ngIf="form">

  <form class="ca-form dialog-content-no-margin" [formGroup]="form">
    <mat-form-field caInput>
      <textarea formControlName="note" name="note" rows="3" matInput placeholder="Note" required></textarea>
      <mat-hint *ngIf="!form.valid">{{this.messages.get('CHARACTER_LENGTH_ERROR')}}</mat-hint>
    </mat-form-field>
  </form>
</div>

<mat-dialog-actions class="ca-dialog-action" *ngIf="form">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="submit" (click)="cancel()">
      Cancel
    </button>

  <button mat-button
          type="button"
          [disabled]="form.invalid || !form.controls['note'].value"
          (click)="reset(form)">
    Confirm
  </button>
</mat-dialog-actions>
