import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Demarc} from "../../core/demarc.model";

@Component({
  selector: 'ca-demarc-form',
  templateUrl: './demarc-form.component.html',
  styleUrls: ['./demarc-form.component.css']
})

export class DemarcFormComponent implements OnInit {

  @Input() siteId: number;
  @Output() saveRequested = new EventEmitter();
  @Output() formCancelled = new EventEmitter();

  @Input() demarc: Demarc;

  readonly VALIDATION = {
    REQUIRED_ERROR: 'Field is required',
  };
  form: FormGroup;


  constructor(public formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.demarc = this.demarc || new Demarc({unsaved: true});
    this.form = this.formBuilder.group({
      rack_id: [this.demarc.rack_id, Validators.required],
      shelf_id: [this.demarc.shelf_id],
      cpe_id: [this.demarc.cpe_id],
      card_id: [this.demarc.card_id],
      port_id: [this.demarc.port_id],
      notes: [this.demarc.notes],
      extended: [this.demarc.extended]
    });
  }

  onSubmit({value, valid}: { value: Demarc, valid: boolean }) {
    if (valid && this.siteId) {
      this.demarc.site_id = this.siteId;
      Object.assign(this.demarc, value);

      this.saveRequested.emit(this.demarc);
    }
  }

  cancel() {
    this.formCancelled.emit(true);
  }

}
