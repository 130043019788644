import {Component, EventEmitter, Input} from '@angular/core';
import {FilterBaseComponent} from '../filter-base.component';
import {FormControl} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FilterEntry} from "../../../core/filter/filter-entry";

const OPERATORS = {
  IN: '$in',
};

@Component({
  selector: 'cas-filter-boolean',
  templateUrl: './filter-boolean.component.html',
  styleUrls: ['./filter-boolean.component.scss']
})
export class FilterBooleanComponent implements FilterBaseComponent {
  private _value: any;

  inputTrue: FormControl = new FormControl(false);
  inputFalse: FormControl = new FormControl(false);

  @Input() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() entry: FilterEntry;

  get value(): any {
    return this._value;
  }

  @Input() set value(value: any) {
    const currentValue = value?.hasOwnProperty('currentValue') ? value?.currentValue : value;
    if (!FilterEntry.isEqual(currentValue, this._value)) {
      this._value = currentValue;
      this.setValues(currentValue);
    }
  }

  onTrueSelectionChange(change: MatCheckboxChange): void {
    if(change.checked) {
      this.inputFalse.setValue(false);
    }
    this.update();
  }

  onFalseSelectionChange(change: MatCheckboxChange): void {
    if(change.checked) {
      this.inputTrue.setValue(false);
    }
    this.update();
  }

  private setValues(value: any): void {
    const simpleValue = this.getSimpleValue(value);
    if (simpleValue) {
      this.inputTrue.setValue(simpleValue[0]);
      this.inputFalse.setValue(!simpleValue[0]);
    } else {
      this.inputTrue.setValue(false);
      this.inputFalse.setValue(false);
    }
  }

  private getSimpleValue(value): any {
    if (typeof value === 'object') {
      return Object.values(value)[0];
    }
    return value;
  }

  private update(): void {
    let value: boolean[] = [];
    const trueValue = this.inputTrue.value;
    const falseValue = this.inputFalse.value;
    if ((trueValue && falseValue) || (!trueValue && !falseValue)) {
      value = null;
    } else {
      if (trueValue) {
        value.push(true);
      }

      if (falseValue) {
        value.push(false);
      }
    }

    if (!value) {
      this.change.emit(null);
    } else {
      this.change.emit({[OPERATORS.IN]: value});
    }

  }
}
