import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';

import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../../../common/state/queryable.state';
import {QueryBuilder} from "../../../../common/query/query.builder";
import {
  UpdateInventoryOrderActiveSiderSection,
} from "./inventory-order.actions";

const INVENTORY_ORDER_STATE_TOKEN = new StateToken<InventoryOrderStateModel>('inventory_order');

export interface InventoryOrderStateModel extends QueryableStateModel<any> {

}

@State<InventoryOrderStateModel>({
  name: INVENTORY_ORDER_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
// @ts-ignore
export class InventoryOrderState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
  ) {
    super(store, queryBuilder);
  }

  // @ts-ignore
  @Action(UpdateInventoryOrderActiveSiderSection)
  updateInventoryOrderActiveSiderSection(ctx: StateContext<any>, action: any): void {
    ctx.patchState({
      activeSiderSection: action.name || null
    })
  }
}
