<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Contracts</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <!-- ADD, REFRESH -->
  <button  *permission="'Create'" matTooltip="New Contract" mat-icon-button (click)="newContract()">
    <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>
  <button matTooltip="Refresh" mat-icon-button (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <!-- Download -->
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <!-- Grid settings -->
  <div class="vertical-divider"></div>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
      <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
    </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list contracts-grid-container dx-nodata-absolute">

  <dx-data-grid #grid
                (onRowClick)="onRowClick($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onCellClick)="onCellClick($event)"
                class="app-grid-with-pager"
                [width]="'100%'"
                [height]="'100%'"
                [showColumnLines]="false"
                [showRowLines]="true"
                [showBorders]="true"
                [columns]="columns"
                [dataSource]="vendorContracts">

                <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>

    <div *dxTemplate="let d of 'nameTemplate'">
      <div caGridTooltip>{{d.data.vendor_name}}
<!--         For now, we don't need this part of the code. We commented on this part of the code when we did refactor the contract-->
        <span *ngIf="d.data.danger_count && d.data.danger_count !== '0'" [matTooltip]="getExpiryMessage(d.data.expiring_in)" class="selection-badge grid-badge badge-danger count-badge" [ngClass]="">
          {{d.data.danger_count}}
        </span>
        <span *ngIf="d.data.warning_count && d.data.warning_count !== '0'" [matTooltip]="getExpiryMessage(d.data.warning_expiring_in)" style="margin-left: 4px;" class="selection-badge grid-badge badge-warning count-badge">
          {{d.data.warning_count}}
        </span>
      </div>
    </div>

    <div *dxTemplate="let d of 'numberTemplate'">
      <div caGridTooltip class="right">{{ d.value }}</div>
    </div>

    <div *dxTemplate="let d of 'currencyTemplate'">
      <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency }}</div>
    </div>
  </dx-data-grid>

  <ca-pager [gridPager]="defaultGridPager"
            [pageContext]="contractGridService"
            [query]="query"
            class="app-pager ca-default-border-top"
            (onChange)="loadData(query)"></ca-pager>

</mat-sidenav-container>
