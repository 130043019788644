import {
  Component, EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import Query from '../../query/query';
import {ChargesCallCostService} from "../../../charges-call-cost/core/charges-call-cost.service";


@Component({
  selector: 'vendor-grouping-filter',
  templateUrl: './vendor-grouping-filter.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VendorGroupingFilterComponent),
      multi: true
    },
  ]
})
export class VendorGroupingFilterComponent implements OnInit, OnChanges {

  @Input('query') query: Query;
  @Input('entry') entry: any;
  vendors = [];
  selectedVendor;// = 'IBBS';

  reportTypes = [{label: 'Cost', value: 'Cost'}, {label: 'Duration', value: 'Duration'}];
  selectedReportType: string = 'Cost';


  constructor(public chargesCallCostService: ChargesCallCostService) {
  }


  ngOnChanges(changes: SimpleChanges): void {

    if (changes['query']) {
      let query = changes['query'].currentValue;
      query['where'] = query['where'] || {};
      this.selectedVendor = null;
      this.selectedReportType = "Cost";
    }
  }

  ngOnInit() {
    this.loadVendors();
  }

  loadVendors() {
    this.chargesCallCostService.getDistinctVendors()
      .subscribe(
        (result) => {
          this.vendors = result;
        },
        (err) => {
        });
  }

  vendorChange() {
    this.query.set('vendor', this.selectedVendor);
  }

  reportChange() {
    this.query.set('strat_type', this.selectedReportType);
  }

  onVendorPickerClear(control) {
    control.close();
    setTimeout(() => {
      this.selectedVendor = null;
      delete this.query.where['vendor'];
    });
  }
}






