<form
  class="filter-form ca-form"
  fxLayout="column"
  (keyup.enter)="applyFilter()"
  style="
    height: 100%;
    overflow-y: hidden;
    padding: 0 6px 5px 6px;
">
  <mat-toolbar
    cdkDropList
    #trash="cdkDropList"
    (cdkDropListDropped)="onRemove($event)"
    [cdkDropListSortingDisabled]="true"
    class="filters-commands"
    fxFlex="50px"
    color="accent">
    <div *cdkDragPlaceholder style="height: 0"></div>
    <div *cdkDragPreview style="height: 0"></div>
    <mat-toolbar-row class="filters-trash hide" [ngClass]="{'dnd-drag-over' : dragging, 'show': dragging}">
      <ca-icon [key]="'DELETE'"></ca-icon>
    </mat-toolbar-row>
    <mat-toolbar-row [ngClass]="{'hide' : dragging}">
      <span class="filters-title">Filters</span>
      <span class="example-fill-remaining-space"></span>
      <button
        mat-icon-button
        [matMenuTriggerFor]="filtersMenu"
        matTooltip="Add Filter Card"
        [disabled]="!availableFilters.length"
        type="button"
      >
        <ca-icon [key]="'SRM_CUSTOM_FIELDS_ADD'"></ca-icon>
      </button>

      <mat-menu #filtersMenu="matMenu" class="filters-menu">
        <div
          *ngFor="let entry of availableFilters"
          (click)="onAvailableFilterSelected(entry)"
        >
          <div mat-menu-item>
            {{ entry?.label }}
          </div>
        </div>
      </mat-menu>

      <button
        mat-icon-button
        (click)="applyFilter()"
        matTooltip="Apply"
        type="button"
      >
        <ca-icon [key]="'APPLY_FILTER'"></ca-icon>
      </button>
      <button mat-icon-button (click)="clearFilter()" matTooltip="Clear All" type="button">
        <ca-icon [key]="'CLEAR_FILTER'"></ca-icon>
      </button>
      <button mat-icon-button (click)="settings()" matTooltip="Settings" type="button">
        <ca-icon [key]="'FILTER_SETTINGS'"></ca-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-accordion id="accordion"
                 cdkScrollable
                 #accordion
                 cdkDropList
                 [cdkDropListConnectedTo]="[trash]"
                 (cdkDropListDropped)="onDrop($event)"
                 [multi]="true">
    <mat-expansion-panel #group
                         (cdkDragStarted)="onDragStarted()"
                         (cdkDragEnded)="onDragEnded()"
                         [cdkDragData]="entry"
                         [expanded]='entry.expanded'
                         *ngFor="let entry of visibleFilters; let index = index;"
                         cdkDrag
                         [ngStyle]="{opacity : index === dragOverIndex ? '0.4' : '1'}">
      <mat-expansion-panel-header (click)="toggleEntryExpand(entry)"
                                  cdkDragHandle
                                  class="ca-expansion-panel-header-title"
                                  [expandedHeight]="'48px'"
                                  [collapsedHeight]="'48px'"
                                  style="align-content: center">
        <span style="align-self: center; font-size: 16px; font-weight: 500">{{entry?.label}}</span>
      </mat-expansion-panel-header>
      <cas-filter-entry *ngIf="entry"
                        [entry]="entry"
                        [filterService]="filterService"
                        [value]="internalQuery?.where[entry.field]"
                        (change)="onEntryChange($event)"></cas-filter-entry>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="empty-filter-input" *ngIf="!visibleFilters?.length">
    <div class="message-holder">
      <p>Empty Set</p>
    </div>
  </div>
</form>
