import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Vendor} from './vendor';
import {Restangular} from 'ngx-restangular';
import Query from "../../core/query/query";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {ApiService} from "../../core/api";

@Injectable()
export class VendorService extends BaseService<Vendor> {

  constructor(public restangular: Restangular,
              private entityLock: EntityLockService,
              private apiService: ApiService) {
    super('vendor', restangular, entityLock);
  }

  public service() {
    return this.restangular;
  }

  getEntities(id: number) {
    return this.apiService.get([this.name, id, 'entities'], {limit: 100000});
    // return this.service().one(this.name, id).all('getEntities').customGET();
  }

  addEntity(id: number, entity: string): any {
    return this.apiService.post([this.name, id, 'entities'], entity);
    // return this.service().one(this.name, id).all('addEntity').customPOST(null, null, {entity: entity});
  }

  assignEntity(id: number, entityId: number): any {
    return this.apiService.post([this.name, id, 'entities', entityId]);
    //return this.service().one(this.name, id).all('assignEntity').customPUT(null, null, {entity_id: entityId});
  }

  removeEntity(id: number, entityId: number) {
    return this.apiService.delete([this.name, id, 'entities', entityId], entityId);
  }

  findAllEntities(vendorId: number): any {
    return this.apiService.get([this.name, vendorId, 'entities']);
  }

  findAllinEntityList(query?: Query): any {
    return this.apiService.get([this.name, 'allEntities'], query);
  }

  findAllEntitiesByQuery(vendorId: number, query?: any): any {
    return this.apiService.get([this.name, vendorId, 'entities'], query);
  }

  findAllWithActiveBANsAndInvoices(): any {
    return this.apiService.get([this.name, 'findAllWithActiveBANsAndInvoices']);
  }

  findVendorsWithBans(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('findVendorsWithBans').customPOST(this.toFilter(transformedQuery));
  }

  findVendorsWithActiveBans(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().all(this.name).all('findVendorsWithActiveBans').customPOST(this.toFilter(transformedQuery));
  }

  findAllWithActiveBANsAndSubAccounts(): any {
    return this.apiService.get([this.name, 'findAllWithActiveBANsAndSubAccounts']);
  }

  codingUpdate(id, vendor): any {
    return this.service().one(`${this.name}/coding`, id).customPUT(vendor);
  }

  findOnlyVendors(query?: Query): any {
    return this.apiService.get([this.name, 'findOnlyVendors'], query);
  }

  findVendorsForPicker(query?: Query): any {
    return this.apiService.get([this.name, 'findVendorsForPicker'], query);
  }

  findOnlyVendorsAccPicker(query?: Query): any {
    return this.apiService.get([this.name, 'findOnlyVendorsAccPicker'], query);
  }

  findOnlySimpleVendors(query?: Query): any {
    return this.apiService.get([this.name, 'findOnlySimpleVendors'], query);
  }
}
