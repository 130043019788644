import { Injectable } from '@angular/core';
import { FilterService } from "../../core/filter/filter.service";
import Query from "../../core/query/query";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { LOOKUP_ENUM } from "../../dictionary/core/lookup.enum";
import { CurrencyLookupProvider } from "../../location/core/currency-lookup.provider";
import { LocationService } from "../../location/core/location.service";
import { SiteLookupProvider } from "../../site/core/site-lookup.provider";
import { SiteService } from "../../site/core/site.service";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { VendorService } from "../../vendor/core/vendor.service";
import { scvOrderServiceFilterContext } from "./svc-order-service-filter.context";
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';

@Injectable()
export class SvcOrderServiceFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  public documentTypeQuery = new Query({
    where: { id: { $in: [LOOKUP_ENUM.DOCUMENT_TYPE.MSA, LOOKUP_ENUM.DOCUMENT_TYPE.SERVICE_SCHEDULE]  } }
  });

  constructor(public vendorService: VendorService,
              public userSettingsService: UserSettingsService,
              public dictionaryService: DictionaryService,
              public locationService: LocationService,
              public siteService: SiteService
              ) {
    super("app.contract.svc-order-service-filter", scvOrderServiceFilterContext, userSettingsService);

      this.addLookupProvider(
        'circuitType',
        new LookupModelLookupProvider(
          dictionaryService,
          LOOKUP_MODELS_ENUM.INVENTORY_CKT_TYPE.modelName,
          true,
          true
        )
      );
      this.addLookupProvider(
        'circuitTech',
        new LookupModelLookupProvider(
          dictionaryService,
          LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName,
          true,
          true
        )
      );
      this.addLookupProvider(
        'bandwidth',
        new LookupModelLookupProvider(
          dictionaryService,
          LOOKUP_MODELS_ENUM.BANDWIDTH.modelName,
          true,
          true,
          new Query({ limit: 1000 })
        )
      );

    this.addLookupProvider(
      'int_bandwidth',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.INVENTORY_BW_MODEL.modelName,
        true,
        true,
        new Query({ limit: 1000 })
      )
    );

    this.addLookupProvider(
      'term_unit_of_measure',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.TERM_UNIT_OF_MEASURE.modelName,
        true,
        true,
        new Query({ limit: 1000 })
      )
    );

    this.addLookupProvider(
      'qty_unit_of_measure',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.QTY_UNIT_OF_MEASURE.modelName,
        true,
        true,
        new Query({ limit: 1000 })
      )
    );
    this.addLookupProvider('currency',new CurrencyLookupProvider(locationService));
    this.addLookupProvider('site', new SiteLookupProvider(siteService, true));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));

    this.addLookupProvider(
      'at_exp_event',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.AT_EXP_EVENT.modelName,
        true,
        true,
        new Query({ limit: 10000 })
      )
    );
  }
}
