import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Query from "../../../core/query/query";
import {FilterContext} from "../../../core/filter/filter-context";
import {FilterEntry} from "../../../core/filter/filter-entry";
import {QueryBuilder} from "../../query/query.builder";
import {FilterService} from "../../../core/filter/filter.service";
import {FilterSettingsComponent} from "../../../core/filter/filter-settings/filter-settings.component";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

//OLD FILTER
@Component({
  selector: 'app-filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.css']
})
export class FilterContainerComponent implements OnInit {

  private _query: Query;
  internalQuery: Query;
  context: FilterContext;
  dragging = false;

  get query(): Query {
    return this._query;
  }

  @Input() set query(value: Query) {
    this._query = this.queryBuilder.build(value).getQuery();
    this.internalQuery = this._query;
  }

  @Input() filterService: FilterService;

  @Output() filterRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearFilterRequested: EventEmitter<any> = new EventEmitter<any>();

  availableFilters: FilterEntry[] = [];
  visibleFilters: FilterEntry[] = [];
  dragOverIndex: number;

  constructor(private readonly queryBuilder: QueryBuilder,
              private readonly dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.createContext();
  }

  onEntryChange(entry: any) {
    this.query = this.queryBuilder.applyEntry(entry).getQuery();
  }

  onAvailableFilterSelected(entry: any) {
    this.context.filterSet.unshift(entry);
    this.refreshFilterSettings();
    this.saveFilterSettings();
  }

  onDragStarted(): void {
    this.dragging = true;
  }

  onDragEnded(): void {
    this.dragging = false;
  }

  onDrop(event: CdkDragDrop<any>) {
    moveItemInArray(this.context.filterSet, event.previousIndex, event.currentIndex);
    this.refreshFilterSettings();
    this.saveFilterSettings();
  }

  onRemove(event: CdkDragDrop<string[]>): void {
    const index = this.context.filterSet.findIndex(e => e.field === event.item.data.field);
    this.context.filterSet.splice(index, 1);
    this.removeFilterFromQuery(event.item.data.field);
    this.refreshFilterSettings();
    this.saveFilterSettings();
  }

  removeFilterFromQuery(field) {
    for (let property in this.query.where) {
      if (property === field) {
        delete this.query.where[field];
      }
    }
  }

  applyFilter() {
    this.filterRequested.emit(this.query);
  }

  clearFilter() {
    this.clearFilterRequested.emit();
  }

  settings() {
    this.dialogService
      .open(FilterSettingsComponent, {
        context: this.context
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.createContext();
          this.clearFilter();
        }
      });
  }

  toggleEntryExpand(entry: any) {
    entry.expanded = !entry.expanded;
    this.refreshFilterSettings();
    this.saveFilterSettings();
  }

  private createContext() {
    this.filterService.create().subscribe((context) => {
      this.context = context;
      this.refreshFilterSettings();
    });
  }

  private refreshFilterSettings(): void {
    this.reindexEntries(this.context.filterSet);
    this.visibleFilters = this.context.filterSet.filter(e => e.visible)
    this.availableFilters = this.context.filters
      .filter(e => e.visible)
      .filter((e) => !this.context.filterSet.find(x => x.field === e.field));
    this.visibleFilters.sort((a, b) => a.index > b.index ? 1 : -1)
    this.availableFilters.sort((a, b) => a.label > b.label ? 1 : -1)
  }

  private reindexEntries(filters: FilterEntry[]): void {
    filters.forEach((entry: FilterEntry, index: number) => Object.assign(entry, {index}));
  }

  private saveFilterSettings() {
    this.context.service
      .saveSettings({
        filterSet: this.context.filterSet.map((entry) => {
          const {
            index: index,
            field: field,
            fieldToFilterWith: fieldToFilterWith,
            secondRowField: secondRowField,
            expanded: expanded,
            caseSensitive: caseSensitive,
            editor: editor,
            exact: exact,
            isActiveCard: isActiveCard,
            label: label,
            lookupProviderName: lookupProviderName,
            exactMatch: exactMatch,
            matchCase: matchCase,
            trueLabel: trueLabel,
            falseLabel: falseLabel,
            max: max,
            defaultValue: defaultValue,
            type: type,
            visible: visible,
          } = entry;
          return {
            index: index,
            field: field,
            fieldToFilterWith: fieldToFilterWith,
            secondRowField: secondRowField,
            expanded: expanded,
            caseSensitive: caseSensitive,
            editor: editor,
            exact: exact,
            isActiveCard: isActiveCard,
            label: label,
            lookupProviderName: lookupProviderName,
            exactMatch: exactMatch,
            matchCase: matchCase,
            trueLabel: trueLabel,
            falseLabel: falseLabel,
            max: max,
            defaultValue: defaultValue,
            type: type,
            visible: visible,
          }
        }),
        filters: this.context.filters.map((entry) => {
          const {
            index: index,
            field: field,
            fieldToFilterWith: fieldToFilterWith,
            secondRowField: secondRowField,
            expanded: expanded,
            caseSensitive: caseSensitive,
            editor: editor,
            exact: exact,
            isActiveCard: isActiveCard,
            label: label,
            lookupProviderName: lookupProviderName,
            exactMatch: exactMatch,
            matchCase: matchCase,
            trueLabel: trueLabel,
            falseLabel: falseLabel,
            max: max,
            defaultValue: defaultValue,
            type: type,
            visible: visible,
          } = entry;
          return {
            index: index,
            field: field,
            fieldToFilterWith: fieldToFilterWith,
            secondRowField: secondRowField,
            expanded: expanded,
            caseSensitive: caseSensitive,
            editor: editor,
            exact: exact,
            isActiveCard: isActiveCard,
            label: label,
            lookupProviderName: lookupProviderName,
            exactMatch: exactMatch,
            matchCase: matchCase,
            trueLabel: trueLabel,
            falseLabel: falseLabel,
            max: max,
            defaultValue: defaultValue,
            type: type,
            visible: visible,
          }
        }),
      }).subscribe(() => {});
  }
}
