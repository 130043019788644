import {of as observableOf, combineLatest } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';

import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { AlertService } from '../../../shared/alert/alert.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { DocumentService } from '../../../shared/document/core/document.service';
import { EntityEditContext } from '../../../shared/entity-lock/entity-edit-context';
import { LoaderService } from '../../../shared/loader/loader.service';
import { ContractService } from '../../core/contract.service';
import {flatMap, startWith, takeUntil} from "rxjs/operators";
import {SvcOrderService} from "../../core/svc-order.service";
import {SvcOrderServices} from "../../core/svc-order-service";
import {TabService} from "../../../shared/tabs/tab.service";
import {LocationService} from "../../../location/core/location.service";
import {InventoryService} from "../../../inventory/core/inventory.service";
import * as moment from 'moment';

@Component({
  selector: 'app-contract-manage-dialog',
  templateUrl: './svc-order-service-manage-dialog.component.html',
  styleUrls: ['./svc-order-service-manage-dialog.component.css']
})
export class SvcOrderServiceManageDialogComponent extends PageManageDialogComponent implements OnInit {
  @ViewChild('fileInput') fileInput;

  svcOrderService: any;
  svcOrderHeaderId: number;
  svcOrderHeader: any;
  selectedRowKeys: Array<any>;
  selectedCurrency: any;
  isUpdate: boolean;
  disabledSites: any;

  readonly INVENTORY_TECHNOLOGY: string = LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName;
  readonly INVENTORY_CKT_TYPE: string = LOOKUP_MODELS_ENUM.INVENTORY_CKT_TYPE.modelName;
  readonly INVENTORY_BW_MODEL: string = LOOKUP_MODELS_ENUM.INVENTORY_BW_MODEL.modelName;
  readonly BANDWIDTH: string = LOOKUP_MODELS_ENUM.BANDWIDTH.modelName;
  readonly TERM_UNIT_OF_MEASURE: string = LOOKUP_MODELS_ENUM.TERM_UNIT_OF_MEASURE.modelName;
  readonly QTY_UNIT_OF_MEASURE: string = LOOKUP_MODELS_ENUM.QTY_UNIT_OF_MEASURE.modelName;
  readonly AT_EXP_EVENT: string = LOOKUP_MODELS_ENUM.AT_EXP_EVENT.modelName;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'contract';

  constructor(
    public dialog: DialogService,
    public formBuilder: FormBuilder, public alert: AlertService,
    public contractService: ContractService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<SvcOrderServiceManageDialogComponent>,
    public loaderService: LoaderService,
    public documentService: DocumentService,
    public svcOrderHeaderService: SvcOrderService,
    public tabService: TabService,
    public locationService: LocationService,
    public inventoryService: InventoryService
  ) {
    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.isUpdate = !!this.svcOrderService.id;

    this.form = this.formBuilder.group({

      at_exp_event_id: [this.svcOrderService ? this.svcOrderService.at_exp_event_id : ''],
      cancel_notice_pd: [this.svcOrderService ? this.svcOrderService.cancel_notice_pd : '', Validators.pattern("^[0-9]*$")],
      cancel_notice_dt: [this.svcOrderService ? this.svcOrderService.cancel_notice_dt : ''],

      description: [this.svcOrderService ? this.svcOrderService.description : '', Validators.required],
      svc_type_tech_id: [this.svcOrderService ? this.svcOrderService.svc_type_tech_id : ''],
      ckt_type_id: [this.svcOrderService ? this.svcOrderService.ckt_type_id : ''],
      rate_eff_date: [this.svcOrderService ? this.svcOrderService.rate_eff_date : ''],
      rate_term_date: [this.svcOrderService ? this.svcOrderService.rate_term_date : ''],
      int_bandwidth_id: [this.svcOrderService ? this.svcOrderService.int_bandwidth_id : ''],
      ckt_bandwidth_id: [this.svcOrderService ? this.svcOrderService.ckt_bandwidth_id : ''],
      port_bandwidth_id: [this.svcOrderService ? this.svcOrderService.port_bandwidth_id : ''],
      currency_id: [this.svcOrderService ? this.svcOrderService.currency_id : '', Validators.required],
      term: [this.svcOrderService ? this.svcOrderService.term : '', Validators.pattern('^[0-9]*$')],
      term_qty_uom_id: [this.svcOrderService ? this.svcOrderService.term_qty_uom_id : ''],
      service_qty: [this.svcOrderService ? this.svcOrderService.service_qty : ''],
      service_qty_uom_id: [this.svcOrderService ? this.svcOrderService.service_qty_uom_id : ''],
      mrc: [this.svcOrderService ? this.svcOrderService.mrc : ''],
      nrc: [this.svcOrderService ? this.svcOrderService.nrc : ''],
      location_a_id: [this.svcOrderService ? this.svcOrderService.location_a_id : ''],
      location_z_id: [this.svcOrderService ? this.svcOrderService.location_z_id : ''],
      service_details: [this.svcOrderService ? this.svcOrderService.service_details : '']
    });
    if (this.isUpdate) {
      this.locationService.findCurrencyById(this.svcOrderService.currency_id)
        .subscribe(res => {
          this.selectedCurrency = res.currency;
        })
    }

    this.form.get('cancel_notice_pd').valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(cancelNoticePd => {
      if (cancelNoticePd && this.form.controls.rate_term_date.value) {
        const calculatedDate = moment(this.form.controls.rate_term_date.value).subtract(cancelNoticePd, 'days')
        this.form.controls.cancel_notice_dt.setValue(calculatedDate.format('YYYY-MM-DD'))
      } else {
        this.form.controls.cancel_notice_dt.reset()
      }

    })

    this.form.get('rate_term_date').valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(termDate => {
      if (termDate && this.form.controls.cancel_notice_pd.value) {
        const calculatedDate = moment(termDate).subtract(this.form.controls.cancel_notice_pd.value, 'days')
        this.form.controls.cancel_notice_dt.setValue(calculatedDate.format('YYYY-MM-DD'))
      } else {
        this.form.controls.cancel_notice_dt.reset()
      }
    })

    combineLatest([
      this.form.get('location_a_id').valueChanges.pipe(startWith(this.form.value.location_a_id)),
      this.form.get('location_z_id').valueChanges.pipe(startWith(this.form.value.location_z_id))]
    ).pipe(
      takeUntil(this.destroy$))
      .subscribe((selectedSites: Array<number>) => {
        this.disabledSites = selectedSites;
      });

    this.afterInit();
  }

  init() {
    if (this.svcOrderService && this.svcOrderService.id) {
      return this.contractService.findByIdForEdit(this.svcOrderService.id, new EntityEditContext({
        dialogRef: this.dialogRef
      })).pipe(
        flatMap((service) => {
          this.svcOrderService = service;
          return observableOf(service);
        }))
    }
    return observableOf(this.svcOrderService);
  }

  onSubmit({ value, valid }: { value: SvcOrderServices, valid: boolean }) {
    const svcOrderHeaderId = { svc_order_header_id : this.svcOrderHeader.id};
    const newValue = Object.assign(value,svcOrderHeaderId);
    if (!valid) {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
    if (this.svcOrderService && this.svcOrderService.id) {
      this.update(Object.assign({}, this.svcOrderService, newValue));
    } else {
      this.create(newValue);
    }
  }

  create(svcOrderService: SvcOrderServices) {

    this.loaderService.displayLoader();

    this.toggleDialogButtons();
    svcOrderService.svc_order_header_id = this.svcOrderHeaderId
    this.svcOrderHeaderService.createService(svcOrderService)
      .subscribe((result) => {
        this.loaderService.hideLoader();
        this.alert.success('', this.messages.get('CREATE_SUCCESS'));
        this.closeDialog(result, true);
      }, (err) => {
        this.toggleDialogButtons(false);
        this.loaderService.hideLoader();
        this.alert.success('', this.messages.get('CREATE_ERROR'));
      });
  }

  update(svcOrderService: SvcOrderServices) {
    this.toggleDialogButtons();
    this.svcOrderHeaderService.updateService(svcOrderService.id, svcOrderService)
      .subscribe((result) => {
        this.alert.success('', this.messages.get('UPDATE_SUCCESS'));
        this.closeDialog(result, true);
        this.loaderService.hideLoader();
      }, (err) => {
        this.toggleDialogButtons(false);
        this.alert.error('', this.messages.get('UPDATE_ERROR'));
      });
  }

  cancel() {
    this.contractService.cancelEdit();
    this.closeDialog();
  }

  onCurrencyChange(event) {
    this.selectedCurrency = event.selection.currency;
  }

  deleteOrderService(event) {
    if (this.isUpdate && event) {
      this.toggleDialogButtons();
      this.svcOrderHeaderService.deleteService(this.svcOrderHeaderId, this.svcOrderService.id)
        .subscribe((deleteResult) => {
          if (deleteResult) {
            this.dialogRef.close({ deleted: true });
          }
        }, (err) => {
          this.toggleDialogButtons(false);
          if (err.data.name === 'SequelizeForeignKeyConstraintError') {
            setTimeout(() => {
              this.alert.error('', this.messages.get('CONSTRAIN_DELETE_ERROR'));
            }, 600);
          } else {
            this.alert.error('', this.messages.get('DELETE_ERROR'));
          }
        });
    }
  }
}
