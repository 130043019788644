import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MasterComponent} from './master.component';
import {UserSettingsResolve} from '../user/core/user-settings.resolve';
import {ConfigResolve} from '../core/config/config.resolve';
import {PermissionGuardService} from "../core/security";
import {UserResolve} from "../user/core/user.resolve";
import {PermissionsResolver} from "../permissions/core/permissions.resolver";

const routes: Routes = [
  {
    path: '',
    component: MasterComponent,
    resolve: {
      permissions: PermissionsResolver
    },
    children: [
      {
        path: 'report-gallery',
        loadChildren: () => import('../report-gallery/report-gallery.module').then(m => m.ReportGalleryModule),
        data: {preload: true, key: 'reports'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'auto-approver',
        loadChildren: () => import('../auto-approver/auto-approver.module').then(m => m.AutoApproverModule),
        data: {preload: true, key: 'auto-approver'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'taxes',
        loadChildren: () => import('../tax/tax.module').then(m => m.TaxModule),
        data: {preload: true, key: 'taxes'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'dictionary',
        loadChildren: () => import('../dictionary/dictionary.module').then(m => m.DictionaryModule),
        data: {preload: true, key: 'dictionary'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'inventory',
        loadChildren: () => import('../inventory/inventory.module').then(m => m.InventoryModule),
        data: {preload: true, key: 'inventory'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'order',
        loadChildren: () => import('../order/order.module').then(m => m.OrderModule),
        data: {preload: true, key: 'orders'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'roles',
        loadChildren: () => import('../roles/roles.module').then(m => m.RolesModule),
        data: {preload: true, key: 'roles'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve
        }
      },
      {
        path: 'vendor-remit-address',
        loadChildren: () => import('../vendor-remit-address/vendor-remit-address.module').then(m => m.VendorRemitAddressModule),
        data: {preload: true, key: 'vendor-remit-address'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'site',
        loadChildren: () => import('../site/site.module').then(m => m.SiteModule),
        data: {preload: true, key: 'sites'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'audit',
        loadChildren: () => import('../audit/audit.module').then(m => m.AuditModule),
        data: {preload: false},
        resolve: {
          userSettings: UserSettingsResolve
        }
      },
      {
        path: 'account',
        loadChildren: () => import('../account/account.module').then(m => m.AccountModule),
        data: {preload: true, key: 'accounts'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'project',
        loadChildren: () => import('../project/project.module').then(m => m.ProjectModule),
        data: {preload: true, key: 'projects'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'vendor',
        loadChildren: () => import('../vendor/vendor.module').then(m => m.VendorModule),
        data: {preload: true, key: 'vendors'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        },
      },
      {
        path: 'customer',
        loadChildren: () => import('../customer/customer.module').then(m => m.CustomerModule),
        data: {preload: true, key: 'customers'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'contact',
        loadChildren: () => import('../contact/contact.module').then(m => m.ContactModule),
        data: {preload: true, key: 'contacts'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }

      },
      {
        path: 'user',
        loadChildren: () => import('../user/user.module').then(m => m.UserModule),
        data: {preload: true, key: 'users'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }

      },
      {
        path: 'contract',
        loadChildren: () => import('../contract/contract.module').then(m => m.ContractModule),
        data: {preload: true, key: 'contracts'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          config: ConfigResolve,
          user: UserResolve,
        }

      },
      {
        path: 'alert',
        loadChildren: () => import('../common-alert/common-alert.module').then(m => m.CommonAlertModule),
        data: {preload: true, key: 'alerts'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'suspense',
        loadChildren: () => import('../suspense/suspense.module').then(m => m.SuspenseModule),
        data: {preload: true, key: 'suspense'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'building',
        loadChildren: () => import('../building/building.module').then(m => m.BuildingModule),
        data: {preload: true, key: 'buildings'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }

      },
      {
        path: 'dispute',
        loadChildren: () => import('../dispute/dispute.module').then(m => m.DisputeModule),
        data: {preload: true, key: 'disputes'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'gl-rule-execution',
        loadChildren: () => import('../gl-rule-execution/gl-rule-execution.module').then(m => m.GLRuleExecutionModule),
        data: {preload: true, key: 'gl'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'gl-rule',
        loadChildren: () => import('../gl-rule/gl-rule.module').then(m => m.GLRuleModule),
        data: {preload: true, key: 'gl'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'gl-system-rules',
        loadChildren: () => import('../gl-system-rules/gl-system-rule.module').then(m => m.GLSystemRuleModule),
        data: {preload: true, key: 'gl'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'gl-code',
        loadChildren: () => import('../gl-code/gl-code.module').then(m => m.GLCodeModule),
        data: {preload: true, key: 'gl'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'gl-string',
        loadChildren: () => import('../gl-string/gl-string.module').then(m => m.GLStringModule),
        data: {preload: true, key: 'gl'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'gl-batch',
        loadChildren: () => import('../gl-batch/gl-batch.module').then(m => m.GLBatchModule),
        data: {preload: true, key: 'gl'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'charge',
        loadChildren: () => import('../charge/charge.module').then(m => m.ChargeModule),
        data: {preload: true, key: 'charges'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'batch-browser',
        loadChildren: () => import('../gl-batch-browser/gl-batch-browser.module').then(m => m.GLBatchBrowserModule),
        data: {preload: true, key: 'gl'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'invoice',
        loadChildren: () => import('../invoice/invoice.module').then(m => m.InvoiceModule),
        data: {preload: true, key: 'invoices'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'reports',
        loadChildren: () => import('../srm/srm.module').then(m => m.SRMModule),
        canActivate: [PermissionGuardService],
        data: {preload: true, key: 'reports'},
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'dash',
        loadChildren: () => import('../dash/dash.module').then(m => m.DashModule),
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'quotes',
        loadChildren: () => import('../quoting/quoting.module').then(m => m.QuotingModule),
        data: {preload: true, key: 'quotes'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'address',
        loadChildren: () => import('../address/address.module').then(m => m.AddressModule),
        data: {preload: true},
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'customer-inventory',
        loadChildren: () => import('../customer-inventory/customer-inventory.module').then(m => m.CustomerInventoryModule),
        data: {preload: true, key: 'customer-inventory'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'customer-equipment',
        loadChildren: () => import('../customer-equipment/customer-equipment.module').then(m => m.CustomerEquipmentModule),
        data: {preload: true, key: 'customers'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve
        }
      },
      {
        path: 'recon',
        loadChildren: () => import('../recon/recon.module').then(m => m.ReconModule),
        data: {preload: true, key: 'inventory'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'network-hub',
        loadChildren: () => import('../network-hub/network-hub.module').then(m => m.NetworkHubModule),
        data: {preload: true, key: 'network-hub'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'did-tfn',
        loadChildren: () => import('../tn-tfn/tn-tfn.module').then(m => m.TnTfnModule),
        data: {preload: true, key: 'did-tfn'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'mobile-devices',
        loadChildren: () => import('../wireless/wireless.module').then(m => m.WirelessModule),
        data: {preload: true, key: 'mobile-devices'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'mobile-analytics',
        loadChildren: () => import('../wireless-analytics/wireless-analytics.module').then(m => m.WirelessAnalyticsModule),
        data: {preload: true, key: 'mobile-analytic'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'mobile-users',
        loadChildren: () => import('../wireless-users/wireless-users.module').then(m => m.WirelessUsersModule),
        data: {preload: true, key: 'mobile-users'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'mobile-plans',
        loadChildren: () => import('../wireless-plan/wireless-plan.module').then(m => m.WirelessPlanModule),
        data: {preload: true, key: 'mobile-plans'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'file-download',
        loadChildren: () => import('../file/file.module').then(m => m.FileModule),
        data: {
          preload: true
        },
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'subaccount-exclusion',
        loadChildren: () => import('../subaccount-exclusion/subaccount-exclusion.module').then(m => m.SubaccountExclusionModule),
        data: {preload: true, key: 'gl'},
        canActivate: [PermissionGuardService],
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'bulk-upload',
        loadChildren: () => import('../bulk-upload/bulk-upload.module').then(m => m.BulkUploadModule),
        data: {preload: true, key: 'bulk-upload'},
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      },
      {
        path: 'intuit',
        loadChildren: () => import('../intuit/intuit.module').then(m => m.IntuitModule),
        data: {preload: true, key: 'intuit'},
        resolve: {
          userSettings: UserSettingsResolve,
          user: UserResolve,
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class MasterRoutingModule {
}
