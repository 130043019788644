import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InvoiceCharge} from "../../../charge/core/invoice-charge";

@Component({
  selector: 'app-alert-charge-sneak-peek',
  templateUrl: './alert-charge-sneak-peek.component.html',
})
export class AlertChargeSneakPeekComponent {

  constructor() { }

  @Input() charge: InvoiceCharge;
  @Output() closeRequested = new EventEmitter();

  closeSide() {
    this.closeRequested.emit();
  }

}
