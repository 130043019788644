import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

import {PermissionService} from '../../permissions/core/permission.service';
import {ActivatedRoute} from "@angular/router";
import {ResourceService} from "../../core/security";
import {Select, Store} from "@ngxs/store";

@Directive({selector: '[permission] , [casPermission]'})
export class CasActionDirective {

  @Select(state => state.permissions) $permissions;

  @Input('permission')
  set permission(value) {
    this.refresh(value)
  }

  constructor(
    private store: Store,
    private templateRef: TemplateRef<any>,
    private permissionService: PermissionService,
    private resourceService: ResourceService,
    private viewContainer: ViewContainerRef,
    private route: ActivatedRoute
  ) {
  }

  refresh(permission) {
    const permissions = this.store.selectSnapshot((state: any) => state.permissions.permissions);
    const resources = this.store.selectSnapshot((state: any) => state.permissions.resources);
    const key = this.route.snapshot.data.key;
    const allowed = permission.key
      ? this.permissionService.isAllowedTo(permissions, resources, permission.key, permission.action)
      : this.permissionService.isAllowedTo(permissions, resources, key, permission);

    if (allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
