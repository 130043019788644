<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title" style="padding-bottom: 10px">
  <h1 mat-dialog-title>Theme</h1>
</div>
<div mat-dialog-content class="ca-dialog-content ca-dialog-theme" style="padding-bottom: 0">
  <form #f="ngForm" class="ca-form ca-theme-manage-form ca-tab tabbed-content">

    <mat-tab-group (selectedIndexChange)="onSelectedIndexChange($event)">
      <mat-tab label="Application">
        <div mat-dialog-content>
          <div class="modal-box" style="display: flex; height: 440px">
            <div class="theme-list">
              <div class="theme"
                  fxFlex="0 0 25%"
                  [class.selected]="theme.name === selectedTheme?.name"
                  *ngFor="let theme of themes"
                  (click)="selectTheme(theme)">
                <div class="entry-container">
                  <label>{{theme.name}}</label>
                  <span class="rounded-corners-left margin-right-minus-4"
                        [style.background]="theme.settings?.primary.color"></span>
                  <span class="margin-right-minus-4" [style.background]="theme.settings?.primaryDark.color"></span>
                  <span class="rounded-corners-right" [style.background]="theme.settings?.accent.color"></span>
                </div>
              </div>
            </div>

            <hr class="ca-dotted-divider ca-default-border-color" />

            <div *ngIf="customTheme" class="color-grid">
              <div fxLayout="row">

                <div class="flex-container" fxLayout="column">
                  <div class="flex-item flex-item-multiple overflow-visible">
                    <h5 class="no-margin">Primary</h5>
                    <div fxLayout fxLayoutAlign="space-between">
                      <mat-form-field class="color-input" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border color-box"
                              (click)="selectInput(INPUTS.THEME_PRIMARY_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.primary.color">&nbsp;</span>
                        <input placeholder="Color"
                              name="primary_color" pattern="{{hexColorValPattern}}" required
                              [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                              type="text" matInput [(ngModel)]="customTheme?.settings?.primary.color"
                              (click)="selectInput(INPUTS.THEME_PRIMARY_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              class="color-picker-trigger">
                        <span
                          [(colorPicker)]="customTheme?.settings?.primary.color"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_PRIMARY_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          class="color-picker-trigger"
                          (colorPickerChange)="onThemeColorChange($event, 'primary', 'color')"
                        ></span>
                      </mat-form-field>

                      <mat-form-field class="color-input" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_PRIMARY_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.primary.contrastColor">&nbsp;</span>
                        <input placeholder="Font Color"
                              [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                              type="text" matInput [(ngModel)]="customTheme?.settings?.primary.contrastColor"
                              name="primary_contrastColor" pattern="{{hexColorValPattern}}" required
                              class="color-picker-trigger"
                              (click)="selectInput(INPUTS.THEME_PRIMARY_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)">


                        <span
                          [(colorPicker)]="customTheme?.settings?.primary.contrastColor"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_PRIMARY_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          class="color-picker-trigger"
                          (colorPickerChange)="onThemeColorChange($event, 'primary', 'contrastColor')"
                        ></span>
                      </mat-form-field>
                    </div>

                  </div>

                  <div class="flex-item overflow-visible">
                    <h5 class="no-margin">Primary Dark</h5>

                    <div fxLayout fxLayoutAlign="space-between">
                      <mat-form-field class="color-input" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_PRIMARY_DARK_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.primaryDark.color">&nbsp;</span>
                        <input placeholder="Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               class="color-picker-trigger"
                               type="text" matInput [(ngModel)]="customTheme?.settings?.primaryDark.color"
                               (click)="selectInput(INPUTS.THEME_PRIMARY_DARK_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="primaryDark_color" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.primaryDark.color"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_PRIMARY_DARK_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'primaryDark', 'color')"></span>
                      </mat-form-field>
                      <mat-form-field class="color-input" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_PRIMARY_DARK_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix
                              [style.background-color]="customTheme?.settings?.primaryDark.contrastColor">&nbsp;</span>
                        <input placeholder="Font Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               class="color-picker-trigger"
                               (click)="selectInput(INPUTS.THEME_PRIMARY_DARK_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               type="text" matInput
                               [(ngModel)]="customTheme?.settings?.primaryDark.contrastColor"
                               name="primaryDark_contrastColor" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.primaryDark.contrastColor"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_PRIMARY_DARK_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'primaryDark', 'contrastColor')"></span>
                      </mat-form-field>
                    </div>

                  </div>

                  <div class="flex-item overflow-visible">
                    <h5 class="no-margin">Primary Light</h5>

                    <div fxLayout fxLayoutAlign="space-between">
                      <mat-form-field class="color-input" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_PRIMARY_LIGHT_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.primaryLight.color">&nbsp;</span>
                        <input placeholder="Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               class="color-picker-trigger"
                               type="text" matInput [(ngModel)]="customTheme?.settings?.primaryLight.color"
                               (click)="selectInput(INPUTS.THEME_PRIMARY_LIGHT_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="primaryLight_color" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.primaryLight.color"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_PRIMARY_LIGHT_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'primaryLight', 'color')"></span>
                      </mat-form-field>
                      <mat-form-field class="color-input" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_PRIMARY_LIGHT_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix
                              [style.background-color]="customTheme?.settings?.primaryLight.contrastColor">&nbsp;</span>
                        <input placeholder="Font Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               class="color-picker-trigger"
                               type="text" matInput [(ngModel)]="customTheme?.settings?.primaryLight.contrastColor"
                               (click)="selectInput(INPUTS.THEME_PRIMARY_LIGHT_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="primaryLight_contrastColor" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.primaryLight.contrastColor"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_PRIMARY_LIGHT_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'primaryLight', 'contrastColor')"></span>
                      </mat-form-field>
                    </div>


                  </div>

                  <div class="overflow-visible">
                    <h5 class="no-margin">Accent</h5>

                    <div fxLayout fxLayoutAlign="space-between">
                      <mat-form-field class="color-input" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_ACCENT_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.accent.color">&nbsp;</span>
                        <input placeholder="Color"
                              [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                              class="color-picker-trigger"
                              type="text" matInput [(ngModel)]="customTheme?.settings?.accent.color"
                              (click)="selectInput(INPUTS.THEME_ACCENT_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              name="accent_color" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.accent.color"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_ACCENT_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'accent', 'color')"
                        ></span>
                      </mat-form-field>
                      <mat-form-field class="color-input" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_ACCENT_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.accent.contrastColor">&nbsp;</span>
                        <input placeholder="Font Color"
                              [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                              class="color-picker-trigger"
                              type="text" matInput [(ngModel)]="customTheme?.settings?.accent.contrastColor"
                              (click)="selectInput(INPUTS.THEME_ACCENT_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              name="accent_contrastColor" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.accent.contrastColor"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_ACCENT_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'accent', 'contrastColor')"
                        ></span>
                      </mat-form-field>
                    </div>


                  </div>


                </div>
                <!-- SECOND COLUMN -->
                <div class="flex-container theme_middle_column" fxLayout="column">

                  <div class="flex-item overflow-visible">
                    <h5 class="no-margin">Background</h5>

                    <div fxLayout fxLayoutAlign="space-between">
                      <mat-form-field class="color-input" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_BACKGROUND_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.background.color">&nbsp;</span>
                        <input placeholder="Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               type="text" matInput [(ngModel)]="customTheme?.settings?.background.color"
                               class="color-picker-trigger"
                               (click)="selectInput(INPUTS.THEME_BACKGROUND_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="background_color" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.background.color"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_BACKGROUND_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'background', 'color')"></span>
                      </mat-form-field>
                      <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_BACKGROUND_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix
                              [style.background-color]="customTheme?.settings?.background.contrastColor">&nbsp;</span>
                        <input placeholder="Font Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               class="color-picker-trigger"
                               type="text" matInput [(ngModel)]="customTheme?.settings?.background.contrastColor"
                               (click)="selectInput(INPUTS.THEME_BACKGROUND_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="background_contrastColor" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.background.contrastColor"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_BACKGROUND_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'background', 'contrastColor')"></span>
                      </mat-form-field>
                    </div>


                  </div>

                  <div class="flex-item overflow-visible">
                    <h5 class="no-margin">Foreground</h5>

                    <div fxLayout fxLayoutAlign="space-between">
                      <mat-form-field class="color-input" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_FOREGROUND_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.foreground.color">&nbsp;</span>
                        <input placeholder="Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               class="color-picker-trigger"
                               type="text" matInput [(ngModel)]="customTheme?.settings?.foreground.color"
                               (click)="selectInput(INPUTS.THEME_FOREGROUND_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="foreground_color" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.foreground.color"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_FOREGROUND_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'foreground', 'color')"></span>
                      </mat-form-field>
                      <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_FOREGROUND_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix
                              [style.background-color]="customTheme?.settings?.foreground.contrastColor">&nbsp;</span>
                        <input placeholder="Font Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               class="color-picker-trigger"
                               type="text" matInput [(ngModel)]="customTheme?.settings?.foreground.contrastColor"
                               (click)="selectInput(INPUTS.THEME_FOREGROUND_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="foreground_contrastColor" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.foreground.contrastColor"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_FOREGROUND_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'foreground', 'contrastColor')"></span>
                      </mat-form-field>
                    </div>


                  </div>

                  <div class="overflow-visible">
                    <h5 class="no-margin">Disabled</h5>

                    <div fxLayout fxLayoutAlign="space-between">
                      <mat-form-field class="color-input" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border" (click)="selectInput(INPUTS.THEME_DISABLED_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.disabled.color">&nbsp;</span>
                        <input placeholder="Color" [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME" class="color-picker-trigger" type="text"
                               matInput [(ngModel)]="customTheme?.settings?.disabled.color" (click)="selectInput(INPUTS.THEME_DISABLED_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="disabled_color" pattern="{{hexColorValPattern}}" required>
                        <span
                          [(colorPicker)]="customTheme?.settings?.disabled.color"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_DISABLED_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'disabled', 'color')"></span>
                      </mat-form-field>
                    </div>

                    <div fxLayout></div>

                  </div>

                </div>
                <div class="flex-container"></div>
                <!-- THIRD COLUMN -->
                <div class="flex-container" fxLayout="column">

                  <div class="overflow-visible">
                    <h5 class="no-margin">Danger</h5>

                    <div fxLayout fxLayoutAlign="space-between">
                      <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_DANGER_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.danger.color">&nbsp;</span>
                        <input placeholder="Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               type="text" matInput [(ngModel)]="customTheme?.settings?.danger.color"
                               class="color-picker-trigger"
                               (click)="selectInput(INPUTS.THEME_DANGER_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="danger_color" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.danger.color"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_DANGER_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'danger', 'color')"></span>
                      </mat-form-field>
                      <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_DANGER_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.danger.contrastColor">&nbsp;</span>
                        <input placeholder="Font Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               class="color-picker-trigger"
                               type="text" matInput [(ngModel)]="customTheme?.settings.danger.contrastColor"
                               (click)="selectInput(INPUTS.THEME_DANGER_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="danger_contrastColor" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.danger.contrastColor"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_DANGER_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'danger', 'contrastColor')"></span>
                      </mat-form-field>
                    </div>


                  </div>

                  <div class="overflow-visible">
                    <h5 class="no-margin">Warning</h5>

                    <div fxLayout fxLayoutAlign="space-between">
                      <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_WARNING_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.warning.color">&nbsp;</span>
                        <input placeholder="Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               type="text" matInput [(ngModel)]="customTheme?.settings?.warning.color"
                               class="color-picker-trigger"
                               (click)="selectInput(INPUTS.THEME_WARNING_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="warning_color" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.warning.color"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_WARNING_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'warning', 'color')"></span>
                      </mat-form-field>
                      <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_WARNING_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.warning.contrastColor">&nbsp;</span>
                        <input placeholder="Font Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               class="color-picker-trigger"
                               type="text" matInput [(ngModel)]="customTheme?.settings.warning.contrastColor"
                               (click)="selectInput(INPUTS.THEME_WARNING_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="warning_contrastColor" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.warning.contrastColor"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_WARNING_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'warning', 'contrastColor')"></span>
                      </mat-form-field>
                    </div>


                  </div>

                  <div class="overflow-visible">
                    <h5 class="no-margin">Success</h5>
                    <div fxLayout fxLayoutAlign="space-between">
                      <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_SUCCESS_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.success.color">&nbsp;</span>
                        <input placeholder="Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               class="color-picker-trigger"
                               type="text" matInput [(ngModel)]="customTheme?.settings?.success.color"
                               (click)="selectInput(INPUTS.THEME_SUCCESS_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="success_color" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.success.color"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_SUCCESS_COLOR, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'success', 'color')"></span>
                      </mat-form-field>
                      <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                        <span class="color-picker-trigger ca-default-border"
                              (click)="selectInput(INPUTS.THEME_SUCCESS_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                              matPrefix [style.background-color]="customTheme?.settings?.success.contrastColor">&nbsp;</span>
                        <input placeholder="Font Color"
                               [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME"
                               class="color-picker-trigger"
                               type="text" matInput [(ngModel)]="customTheme?.settings?.success.contrastColor"
                               (click)="selectInput(INPUTS.THEME_SUCCESS_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                               name="success_contrastColor" pattern="{{hexColorValPattern}}" required>

                        <span
                          [(colorPicker)]="customTheme?.settings?.success.contrastColor"
                          [cpToggle]="displayColorPicker(INPUTS.THEME_SUCCESS_CONTRAST, selectedTheme?.name !== CUSTOM_THEME_NAME)"
                          [cpDialogDisplay]="'popup'"
                          [cpOKButton]="true"
                          (colorPickerChange)="onThemeColorChange($event, 'success', 'contrastColor')"></span>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

              </div>

              <!--<div fxLayout="row">
                <div class="flex-container" fxLayout="column" class="share-theme-container">
                  <h5 class="no-margin">Share your theme</h5>
                  <div fxLayout fxLayoutAlign="space-between">
                    <mat-form-field fxFlex class="color-input">
                      <input placeholder="Copy" [disabled]="selectedTheme?.name !== CUSTOM_THEME_NAME" class="color-picker-trigger" type="text"
                             matInput name="share_theme" [(ngModel)]="shareColors" (ngModelChange)="setColorsOnChange($event)" >
                    </mat-form-field>
                  </div>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Charts">
        <div mat-dialog-content>

          <div class="modal-box" style="display: flex; height: 440px">
            <div class="theme-list">
              <div class="theme"
                  fxFlex="0 0 25%"
                  [class.selected]="palette.name === chartPelette?.name"
                  *ngFor="let palette of chartPelettes"
                  (click)="selectPalette(palette)">
                <div class="entry-container">
                  <label>{{palette.name}}</label>
                  <span class="rounded-corners-left margin-right-minus-4" [style.background]="palette.settings.color1"></span>
                  <span class="margin-right-minus-4" [style.background]="palette.settings.color2"></span>
                  <span class="rounded-corners-right" [style.background]="palette.settings.color3"></span>
                </div>
              </div>
            </div>
            <div class="color-grid">
            <div fxLayout="row">

              <div fxLayout="column" fxFlex class="flex-container">
                <h5 class="no-margin">Palette</h5>
                <div class="flex-item flex-item-multiple overflow-visible" style="padding: 0">
                  <div fxLayout fxLayoutAlign="space-between">
                    <mat-form-field class="color-input" fxFlex="47%" caInput>
                    <span class="color-picker-trigger ca-default-border color-box"
                          (click)="selectInput(INPUTS.CHART_ONE, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                          [style.background-color]="chartColors.color1">&nbsp;</span>
                      <input (click)="selectInput(INPUTS.CHART_ONE, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             placeholder="Color #1"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             class="color-picker-trigger"
                             type="text" matInput
                             [(ngModel)]="chartColors.color1"
                             name="chartColors_one" pattern="{{hexColorValPattern}}" required>

                      <span
                        [(colorPicker)]="chartColors.color1"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_ONE, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'one')"></span>
                    </mat-form-field>

                    <mat-form-field class="color-input" fxFlex="47%" caInput>
                    <span class="color-picker-trigger ca-default-border"
                          (click)="selectInput(INPUTS.CHART_TWO, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                          [style.background-color]="chartColors.color2">&nbsp;</span>
                      <input placeholder="Color #2"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             class="color-picker-trigger"
                             type="text" matInput
                             [(ngModel)]="chartColors.color2"
                             (click)="selectInput(INPUTS.CHART_TWO, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_two" pattern="{{hexColorValPattern}}" required>


                      <span
                        [(colorPicker)]="chartColors.color2"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_TWO, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'two')"></span>
                    </mat-form-field>
                  </div>
                </div>
                <div class="flex-item overflow-visible">
                  <div fxLayout fxLayoutAlign="space-between">
                    <mat-form-field class="color-input" fxFlex="47%" caInput>
                    <span class="color-picker-trigger ca-default-border"
                          (click)="selectInput(INPUTS.CHART_THREE, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                          [style.background-color]="chartColors.color3">&nbsp;</span>
                      <input placeholder="Color #3"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             class="color-picker-trigger"
                             type="text" matInput
                             [(ngModel)]="chartColors.color3"
                             (click)="selectInput(INPUTS.CHART_THREE, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_three" pattern="{{hexColorValPattern}}" required>

                      <span
                        [(colorPicker)]="chartColors.color3"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_THREE, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'three')"></span>
                    </mat-form-field>

                    <mat-form-field class="color-input" fxFlex="47%" caInput>
                    <span class="color-picker-trigger ca-default-border"
                          (click)="selectInput(INPUTS.CHART_FOUR, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                          [style.background-color]="chartColors.color4">&nbsp;</span>
                      <input placeholder="Color #4"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             class="color-picker-trigger"
                             type="text" matInput
                             [(ngModel)]="chartColors.color4"
                             (click)="selectInput(INPUTS.CHART_FOUR, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_four" pattern="{{hexColorValPattern}}" required>

                      <span
                        [(colorPicker)]="chartColors.color4"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_FOUR, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'four')"></span>
                    </mat-form-field>
                  </div>
                </div>
                <div class="flex-item overflow-visible">
                  <div fxLayout fxLayoutAlign="space-between">
                    <mat-form-field class="color-input" fxFlex="47%" caInput>
                    <span class="color-picker-trigger ca-default-border"
                          (click)="selectInput(INPUTS.CHART_FIVE, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                          [style.background-color]="chartColors.color5">&nbsp;</span>
                      <input placeholder="Color #5"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             class="color-picker-trigger"
                             type="text" matInput
                             [(ngModel)]="chartColors.color5"
                             (click)="selectInput(INPUTS.CHART_FIVE, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_five" pattern="{{hexColorValPattern}}" required>

                      <span
                        [(colorPicker)]="chartColors.color5"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_FIVE, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'five')"></span>
                    </mat-form-field>

                    <mat-form-field class="color-input" fxFlex="47%" caInput>
                    <span class="color-picker-trigger ca-default-border"
                          (click)="selectInput(INPUTS.CHART_SIX, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                          [style.background-color]="chartColors.color6">&nbsp;</span>
                      <input placeholder="Color #6"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             type="text" matInput
                             [(ngModel)]="chartColors.color6"
                             class="color-picker-trigger"
                             (click)="selectInput(INPUTS.CHART_SIX, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_six" pattern="{{hexColorValPattern}}" required>

                      <span
                        [(colorPicker)]="chartColors.color6"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_SIX, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'six')"></span>
                    </mat-form-field>
                  </div>
                </div>
                <div class="flex-item overflow-visible" style="padding: 0">
                  <div fxLayout fxLayoutAlign="space-between">
                    <mat-form-field class="color-input" fxFlex="47%" caInput>
                    <span class="color-picker-trigger ca-default-border"
                          (click)="selectInput(INPUTS.CHART_SEVEN, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                          [style.background-color]="chartColors.color7">&nbsp;</span>
                      <input placeholder="Color #7"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             class="color-picker-trigger"
                             type="text" matInput
                             [(ngModel)]="chartColors.color7"
                             (click)="selectInput(INPUTS.CHART_SEVEN, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_seven" pattern="{{hexColorValPattern}}" required>


                      <span
                        [(colorPicker)]="chartColors.color7"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_SEVEN, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'seven')"></span>
                    </mat-form-field>

                    <mat-form-field class="color-input"  fxFlex="47%" caInput>
                    <span class="color-picker-trigger ca-default-border"
                          (click)="selectInput(INPUTS.CHART_EIGHT, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                          [style.background-color]="chartColors.color8">&nbsp;</span>
                      <input placeholder="Color #8"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             type="text" matInput
                             [(ngModel)]="chartColors.color8"
                             class="color-picker-trigger"
                             (click)="selectInput(INPUTS.CHART_EIGHT, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_eight" pattern="{{hexColorValPattern}}" required>

                      <span
                        [(colorPicker)]="chartColors.color8"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_EIGHT, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        class="color-picker-trigger"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'eight')"></span>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div fxLayout="column" fxFlex class="theme_middle_column flex-container">
                <h5 class="no-margin">General</h5>
                <div class="flex-item flex-item-multiple overflow-visible" style="padding: 0">
                  <div fxLayout fxLayoutAlign="space-between">
                    <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                    <span class="color-picker-trigger ca-default-border"
                          (click)="selectInput(INPUTS.CHART_COLOR_FONT, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                          [style.background-color]="chartColors.font_color">&nbsp;</span>
                      <input placeholder="Font Color"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             class="color-picker-trigger"
                             type="text" matInput
                             [(ngModel)]="chartColors.font_color"
                             (click)="selectInput(INPUTS.CHART_COLOR_FONT, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_font" pattern="{{hexColorValPattern}}" required>

                      <span
                        [(colorPicker)]="chartColors.font_color"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_COLOR_FONT, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'font_color')"></span>
                    </mat-form-field>

                    <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                      <span class="color-picker-trigger ca-default-border"
                            (click)="selectInput(INPUTS.CHART_BACKGROUND, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                            [style.background-color]="chartColors.background">&nbsp;</span>
                      <input placeholder="Background Color"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             type="text" matInput
                             [(ngModel)]="chartColors.background"
                             class="color-picker-trigger"
                             (click)="selectInput(INPUTS.CHART_BACKGROUND, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_background" pattern="{{hexColorValPattern}}" required>

                      <span
                        [(colorPicker)]="chartColors.background"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_BACKGROUND, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'background')"></span>
                    </mat-form-field>
                  </div>
                </div>
                <div class="flex-item overflow-visible">
                  <div fxLayout fxLayoutAlign="space-between">
                    <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                      <span class="color-picker-trigger ca-default-border"
                            (click)="selectInput(INPUTS.CHART_TOOLTIP_COLOR_FONT, chartPelette?.name !== CUSTOM_THEME_NAME)"
                            matPrefix [style.background-color]="chartColors.tooltip_font_color">&nbsp;</span>
                      <input placeholder="Tooltip Font Color"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             class="color-picker-trigger"
                             type="text" matInput [(ngModel)]="chartColors.tooltip_font_color"
                             (click)="selectInput(INPUTS.CHART_TOOLTIP_COLOR_FONT, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_tooltip_font_color" pattern="{{hexColorValPattern}}" required>

                      <span [(colorPicker)]="chartColors.tooltip_font_color"
                            [cpToggle]="displayColorPicker(INPUTS.CHART_TOOLTIP_COLOR_FONT, chartPelette?.name !== CUSTOM_THEME_NAME)"
                            [cpDialogDisplay]="'popup'" [cpOKButton]="true"
                            (colorPickerChange)="onChartColorChange($event, 'tooltip_font_color')"></span>
                    </mat-form-field>

                    <mat-form-field class="color-input color-picker-right" fxFlex="47%" caInput>
                      <span class="color-picker-trigger ca-default-border"
                            (click)="selectInput(INPUTS.CHART_TOOLTIP_BACKGROUND, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                            [style.background-color]="chartColors.tooltip_background">&nbsp;</span>
                      <input placeholder="Tooltip Background Color"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             class="color-picker-trigger"
                             type="text" matInput
                             [(ngModel)]="chartColors.tooltip_background"
                             (click)="selectInput(INPUTS.CHART_TOOLTIP_BACKGROUND, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_tooltip_background" pattern="{{hexColorValPattern}}" required>

                      <span
                        [(colorPicker)]="chartColors.tooltip_background"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_TOOLTIP_BACKGROUND, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'tooltip_background')"></span>
                    </mat-form-field>
                  </div>
                </div>
                <div class="flex-item overflow-visible" style="padding: 0;">
                  <div fxLayout fxLayoutAlign="space-between" fxFlex="47%">
                    <mat-form-field class="color-input color-picker-right" caInput style="margin: 0">
                      <span class="color-picker-trigger ca-default-border"
                            (click)="selectInput(INPUTS.CHART_INNER_FONT, chartPelette?.name !== CUSTOM_THEME_NAME)" matPrefix
                            [style.background-color]="chartColors.inner_font">&nbsp;</span>
                      <input placeholder="Inner Font Color"
                             [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME"
                             type="text" matInput
                             [(ngModel)]="chartColors.inner_font"
                             class="color-picker-trigger"
                             (click)="selectInput(INPUTS.CHART_INNER_FONT, chartPelette?.name !== CUSTOM_THEME_NAME)"
                             name="chartColors_inner_font" pattern="{{hexColorValPattern}}" required>

                      <span
                        [(colorPicker)]="chartColors.inner_font"
                        [cpToggle]="displayColorPicker(INPUTS.CHART_INNER_FONT, chartPelette?.name !== CUSTOM_THEME_NAME)"
                        [cpDialogDisplay]="'popup'"
                        [cpOKButton]="true"
                        (colorPickerChange)="onChartColorChange($event, 'inner_font')"></span>
                    </mat-form-field>
                  </div>
                  <div fxFlex="47%"></div>
                </div>
              </div>
              <div fxLayout="column" fxFlex class="flex-container">
                <div class="flex-item flex-item-multiple overflow-visible">
                  <div fxFlex="47%" fxLayout fxLayoutAlign="space-between"></div>
                  <div fxFlex="47%" fxLayout fxLayoutAlign="space-between"></div>
                </div>
              </div>

            </div>
              <!--<div fxLayout="row">
                <div class="flex-container" fxLayout="column" class="share-theme-container">
                  <h5 class="no-margin">Share your theme</h5>
                  <div fxLayout fxLayoutAlign="space-between">
                    <mat-form-field fxFlex class="color-input">
                      <input placeholder="Copy" [disabled]="chartPelette?.name !== CUSTOM_THEME_NAME" class="color-picker-trigger" type="text"
                             matInput name="share_theme" [(ngModel)]="shareChartColors" (ngModelChange)="setChartColorsOnChange($event)" >
                    </mat-form-field>
                  </div>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="submit" (click)="close()">
      Cancel
  </button>
  <button mat-button type="button" [disabled]="f.invalid"
          (click)="apply(f)">
    Apply
  </button>
</mat-dialog-actions>
