import { Request } from './offer';
import {Address} from "../../address/core/address.model";
import {Quote} from "./quote";

export class QuoteService {
  id: number;
  quote_id: number;
  quote_service_id: string;
  service_type: number;
  client_service_type: number;
  circuit_bandwidth_id: number;
  diversity: string;
  desired_install_date: Date;
  quote_status: number;
  customer_id: number;
  current_vendor_id: number;
  service_term_id: number[];
  service_details: string;
  vendors: any[];
  offers: Request[];
  npa: string;
  nxx: string;
  create_date: Date;
  complete_date: Date;
  port_bandwidth_id: number;
  circuit_bandwidth: any;
  port_bandwidth: any;
  quote_service_status: any;
  notes: any;
  quote_service_type: any;
  address_id: number;
  secondary_address_id: number;
  address: Address;
  secondary_address: Address;
  quote: Quote;
  terms: any[];
  quote_service_type_id: number;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
