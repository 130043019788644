import {NgModule} from "@angular/core";
import {DemarcService} from "./core/demarc.service";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import { DxDataGridModule } from "devextreme-angular/ui/data-grid";
import {MaterialModule} from "app/material-barrel/material-barrel.module";
import {DemarcListComponent} from "./shared/demarc-list/demarc-list.component";
import {CoreModule} from "../core/core.module";
import {SharedModule} from "../shared/shared.module";
import {DemarcGridService} from "./core/demarc-grid.service";
import {DemarcFormComponent} from "./shared/demarc-form/demarc-form.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    DxDataGridModule,
    MaterialModule,
    CoreModule,
    SharedModule
  ],
  exports: [
    DemarcListComponent,
    DemarcFormComponent
  ],
  declarations: [
    DemarcListComponent,
    DemarcFormComponent
  ],
  entryComponents: [
    DemarcListComponent,
    DemarcFormComponent
  ],
  providers: [
    DemarcService,
    DemarcGridService
  ]
})
export class DemarcModule { }
