
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { BuildingService } from '../core/building.service';
import { BuildingManageDialogComponent } from '../shared/building-manage-dialog/building-manage-dialog.component';
import { Building } from '../core/building';
import { DialogService } from '../../shared/dialog/dialog.service';
import { AlertService } from '../../shared/alert/alert.service';
import { BuildingQuery } from '../core/building.query';
import { PageListComponent } from '../../core/page-list.component';
import { BuildingGridService } from '../core/building-grid.service';
import { PageContext } from '../../core/page.context';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { DataLockDialogService } from '../../core/data-lock/data-lock-dialog.service';
import { BuildingFilterService } from '../core/building-filter.service';
import { GridService } from '../../shared/grid/grid.service';
import {ConfigService} from "../../core/config/config.service";

@Component({
  selector: 'app-building-list',
  templateUrl: './building-list.component.html',
})
export class BuildingListComponent extends PageListComponent implements OnInit, AfterViewInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  public query: BuildingQuery = new BuildingQuery({
    orderBy: [['address.subdivision_id', 'DESC'], ['name', 'ASC']]
  });

  public buildings: Array<Building>;
  public totalBuildings: number;
  public selection: Building;

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'building';
  readonly CORE_MODULE: string = 'core';
  readonly custom_values_config_key = 'appdata_building';

  public columns = [];
  public sorting: any[][];

  constructor(
    public buildingService: BuildingService,
    public buildingGridService: BuildingGridService,
    public dialogService: DialogService,
    public settingsService: UserSettingsService,
    public alertService: AlertService,
    public loaderService: LoaderService,
    public dataLockDialogService: DataLockDialogService,
    public buildingFilterService: BuildingFilterService,
    public configService: ConfigService
  ) {
    super(new PageContext({
      name: 'app.building.building-list',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  async showDetails() {
    const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key);

    let building = this.selection;
    if (building) {
      this.dialogService.open(BuildingManageDialogComponent, {
        building: building,
        viewOnly: true
      }, {
        width: hasCustomFields ? '900px' : '560px'
      })
        .afterClosed()
        .subscribe((result) => {
          this.afterModalClosed(result, building);
        });
    }
  }

  afterModalClosed(result, building) {
    if (result) {
      if (result.cancelByTimer || result.status == 2) {
        return this.alertService.success('', this.coreMessages.get('TIMER_EXPIRED'));
      } else if (result.deleted) {
        this.loadData(this.query);
        this.selectFirstRow();
        setTimeout(() => {
          this.alertService.snackBar.dismiss();
          this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
        }, 255);
      } else if (result.id) {
        Object.assign(building, result);
        this.dataGrid.instance.repaint();
        this.refresh();
        this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
      }

    }
  }

  loadData(query?: BuildingQuery) {
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.buildingService.findAll(query)
      .subscribe((result) => {
          this.buildings = result.items;
          this.query.total = this.totalBuildings = result.total;
          this.selectFirstRow();
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        },
        (error) => {
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        });
  }

  filterData(query: BuildingQuery) {
    this.query = query;
    this.loadData(query);
  }

  clearFilter() {
    this.query.where = null;
    this.loadData(this.query);
  }

  refresh() {
    this.loadData(this.query);
  }

  async newBuilding() {
    const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key);

    this.dialogService.open(BuildingManageDialogComponent, {
      building: new Building({})
    }, {
      width: hasCustomFields ? '900px' : '560px'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.refresh();
          this.dataGrid.instance.refresh();
          this.alertService.success('', this.messages.get('CREATE_SUCCESS'));
        }
      });
  }

  async editBuilding(building: any) {
    const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key);

    this.dialogService.edit(
      BuildingManageDialogComponent,
      {
        building: this.buildingService.findByIdForEdit(building.id)
      }, {
      width: hasCustomFields ? '900px' : '560px'
    })
      .subscribe((items: any) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(mergeMap(x => x.afterClosed()))
            .subscribe((result) => {
              this.afterModalClosed(result, building);
            })
        }
      })
  }

  gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.buildingGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }


  csv() {
    const { buildingGridService, query, buildingService } = this;
    buildingGridService.csvMap().subscribe(fields => {
      buildingService.exportToCSV(
        'buildings',
        {
          fields,
          query,
        }
      );
    });
  }

  onSelectionChanged(row) {
    this.selection = <Building>row.selectedRowsData[0];
  }

  ngOnInit() {
    this._init();
  }

  ngAfterViewInit(): void {
    this.buildingGridService.create(this.dataGrid.instance, {});
    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  getDefaultSort(): any {
    return [['address.subdivision_id', 'DESC'], ['name', 'ASC']];
  }

  getGridService(): GridService {
    return this.buildingGridService;
  }
}
