import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Inventory} from "../../core/inventory";
import {INVENTORY_TOPOLOGY_ENUM} from "../../core/inventory-topology.enum";

@Component({
  selector: 'ca-inventory-sneak-peek',
  templateUrl: './inventory-sneak-peek.component.html',
})
export class InventorySneakPeekComponent implements OnInit {

  public INVENTORY_TOPOLOGY = INVENTORY_TOPOLOGY_ENUM;


  constructor() { }

  id: number;

  @Input() inventory: Inventory;
  @Output() closeRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();

  ngOnInit() {
  }

  deleteInventory() {
    this.deleteRequested.emit(this.inventory);
  }

  closeSide() {
    this.closeRequested.emit();
  }

  ngOnChanges(): void {
    if (this.inventory) {
      this.id = this.inventory.id;
    }
  }
}
