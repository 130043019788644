import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
})
export class PromptDialogComponent implements OnInit {

  public value: string = "";
  public title: string = "Enter value";
  public placeholder: string = "Value";

  constructor(public dialogRef: MatDialogRef<PromptDialogComponent>) {
  }

  ngOnInit() {
  }

  confirm() {
    this.dialogRef.close(this.value);
  }

  cancel() {
    this.dialogRef.close(this.value);
  }

}
