import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SvcOrderHeader } from "../../core/svc-order-header";

@Component({
  selector: 'app-svc-order-details-toolbar',
  templateUrl: './svc-order-details-toolbar.component.html',
  styleUrls: ['./svc-order-details-toolbar.component.css']
})
export class SvcOrderDetailsToolbarComponent implements OnInit {

  readonly DOCUMENT_MANAGER_SETTINGS = {
    DELETE_DOCUMENT_ACTION: this.deleteDocument.bind(this),
    UPLOAD_DOCUMENT_ACTION: this.uploadDocument.bind(this),
    DOWNLOAD_DOCUMENT_ACTION: this.downloadDocument.bind(this)
  }

  @Input() svcOrderHeader: SvcOrderHeader
  @Input() isEditSvcOrderDisabled: boolean = true;
  @Input() isEditSvcOrderHidden: boolean = true;
  @Input() isAddServicesDisabled: boolean = false;
  @Input() isAddServicesHidden: boolean = false;
  @Input() isEditServicesDisabled: boolean = true;
  @Input() isEditServicesHidden: boolean = false;
  @Input() isAddTermsConditionDisabled: boolean = false;
  @Input() isAddTermsConditionHidden: boolean = false;
  @Input() isEditTermsConditionDisabled: boolean = true;
  @Input() isEditTermsConditionHidden: boolean = false;
  @Input() isDeleteTermsConditionDisabled: boolean = true;
  @Input() isDeleteTermsConditionHidden: boolean = false;
  @Input() isUploadAttachmentHidden: boolean = true;
  @Input() isUploadAttachmentDisabled: boolean = false;
  @Input() isDownloadAttachmentHidden: boolean = false;
  @Input() isDeleteAttachmentDisabled: boolean = true;
  @Input() isDeleteAttachmentHidden: boolean = false;
  @Input() isFilterOn: boolean = false;
  @Input() isFilterDisabled: boolean = true;
  @Input() isCsvDisabled: boolean = false;
  @Input() isCsvHidden: boolean = true;
  @Input() isGridSettingsDisabled: boolean = false;
  @Input() isGridSettingsHidden: boolean = true;
  @Input() isDocumentAction: boolean = false;
  @Input() documentSelection: Document;
  @Input() isFilterHidden = false;


  @Output() editSvcOrderRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() backRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() newServiceRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() editServiceRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateServiceRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() addNewSvcOrderTCRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() editSvcOrderTCRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteSvcOrderTCRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridSettingsRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() csvRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleFilterRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleNotesRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadDocumentRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadDocumentRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteDocumentRequested: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  back() {
    this.backRequested.emit();
  }

  updateSvcOrder() {
    this.editSvcOrderRequested.emit();
  }

  newService(){
    this.newServiceRequested.emit();
  }

  updateService(){
    this.editServiceRequested.emit();
  }

  addNewSvcOrderTC(){
    this.addNewSvcOrderTCRequested.emit();
  }

  editSvcOrderTC(){
    this.editSvcOrderTCRequested.emit();
  }

  deleteSvcOrderTC(){
    this.deleteSvcOrderTCRequested.emit();
  }

  gridSettings(){
    this.gridSettingsRequested.emit()
  }

  csv(){
    this.csvRequested.emit();
  }

  toggleFilter(){
    this.toggleFilterRequested.emit();
  }

  deleteDocument(){
    this.deleteDocumentRequested.emit();
  }

  uploadDocument(){
    this.uploadDocumentRequested.emit();
  }

  downloadDocument(){
    this.downloadDocumentRequested.emit();
  }

}
