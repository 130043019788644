
import {of as observableOf, Observable} from 'rxjs';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {PageManageDialogComponent} from '../../../core/page-manage-dialog.component';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Customer} from '../../core/customer';
import {AlertService} from '../../../shared/alert/alert.service';
import {CustomerService} from '../../core/customer.service';
import { MatDialogRef } from '@angular/material/dialog';
import {Address} from '../../../address/core/address.model';
import {AddressService} from '../../../address/core/address.service';
import {IMessagesResourceService, ResourcesService} from '../../../core/resources/resources.service';
import {DataLockService} from '../../../core/data-lock/data-lock.service';
import {EntityEditContext} from '../../../shared/entity-lock/entity-edit-context';
import {EntityLockData} from '../../../shared/entity-lock/entity-lock-data';
import {flatMap} from "rxjs/internal/operators";


@Component({
  selector: 'app-customer-manage-dialog',
  templateUrl: './customer-manage-dialog.component.html',
  styleUrls: ['./customer-manage-dialog.component.css']
})
export class CustomerManageDialogComponent extends PageManageDialogComponent implements OnInit {

  customer: any;
  firstFormModeEnabled = false;
  secondFormModeEnabled = false;

  corporateAddress: Address;
  billingAddress: Address;

  messages: IMessagesResourceService;
  messagesCore: IMessagesResourceService;

  readonly MESSAGES_MODULE: string = 'customer';
  readonly MESSAGES_MODULE_CORE: string = 'core';
  phoneRegexMask = '(000) 000-0000';
  phoneRegex;

  constructor(public formBuilder: FormBuilder,
              public alertService: AlertService,
              public customerService: CustomerService,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<CustomerManageDialogComponent>,
              public addressService: AddressService,
              public dataLockService?: DataLockService) {
    super(dialogService, dialogRef, dataLockService);

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.messagesCore = ResourcesService.messages(this.MESSAGES_MODULE_CORE);
  }

  ngOnInit() {

    this.customer = this.customer || new Customer();
    this.isUpdate = !!this.customer.id;
    this.corporateAddress = (this.isUpdate && this.customer.corporate_address) ? this.customer.corporate_address : null;
    this.billingAddress = (this.isUpdate && this.customer.billing_address) ? this.customer.billing_address : null;

    this.form = this.formBuilder.group({
      company: [this.customer ? this.customer.company : '', Validators.required],
      office_phone: [this.customer ? this.customer.office_phone : '', Validators.pattern(this.phoneRegex)],
      account_number: [this.customer ? this.customer.account_number : ''],
      master_customer_number: [this.customer ? this.customer.master_customer_number : ''],
      corporate_address2: [this.customer ? this.customer.corporate_address2 : ''],
      corporate_address3: [this.customer ? this.customer.corporate_address3 : ''],
      billing_address2: [this.customer ? this.customer.billing_address2 : ''],
      billing_address3: [this.customer ? this.customer.billing_address3 : ''],
    });

    this.formTitle = this.isUpdate ? 'Edit Customer' : 'Create Customer';
    this.initViewOnlyForm(this.customer.id, 'View Customer', 'Edit Customer', this.dataLockService.DATA_LOCK_TYPES.CUSTOMER);
    this.afterInit();
  }

  init() {
    if (this.customer && this.customer.id) {
      return this.customerService.findByIdForEdit(this.customer.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .pipe(flatMap((customer) => {
          this.customer = customer;
          return observableOf(customer);
        }))
    }
    return observableOf(this.customer);

  }

  onAddressChanged(address: Address) {
    if (address) {
      if (address.internal || address.is_validated) {
        this.corporateAddress = new Address(address);
      } else {
        this.corporateAddress = null;
      }
    } else {
      this.corporateAddress = null;
    }
  }
  onCountryChanged(event){
    this.phoneRegexMask = event.phone_regex_mask
    this.phoneRegex = event.phone_regex
    this.form.get('office_phone').markAsTouched();
  }

  onBillingAddressChanged(address: Address) {
    if (address) {
      if (address.internal || address.is_validated) {
        this.billingAddress = new Address(address);
      } else {
        this.billingAddress = null;
      }
    } else {
      this.billingAddress = null;
    }
  }

  onFirstFormModeChanged(isFormMode: boolean) {
    this.firstFormModeEnabled = isFormMode;
  }

  onSecondFormModeChanged(isFormMode: boolean) {
    this.secondFormModeEnabled = isFormMode;
  }

  onSubmit({value, valid}: { value: Customer, valid: boolean }) {
    if (valid) {
      value.company = value?.company?.trim();
      this.customer = Object.assign({}, this.customer, value);

      this.addressCheck(this.billingAddress)
        .subscribe(
          (billingAddress: Address) => {

            this.customer.billing_address_id = billingAddress.id;

            this.addressCheck(this.corporateAddress)
              .subscribe((address: Address) => {
                this.customer.corporate_address_id = address.id;

                if (this.customer.id) {
                  this.update(this.customer);
                } else {
                  this.create(this.customer);
                }

              }, (err) => {
                this.alertService.error('', this.messages.get('CREATE_ADDRESS_ERROR'));

              });

          },
          error => this.alertService.error('', this.messages.get('CREATE_ADDRESS_ERROR'))
        )


    } else {
      this.alertService.error('', this.messages.get('FORM_INVALID'));
    }
  }

  addressNullCheck(address) {
    if (address) {
      this.addressCheck(address)
    }
  }

  addressCheck(address: Address): Observable<any> {

    if (!address) {
      return observableOf({});
    }

    if (address.internal && address.id) {
      return observableOf(address);
    } else {
      return this.addressService.createOrUpdate(address);
    }
  }

  create(customer: Customer) {
    this.toggleDialogButtons();
    this.customerService.create(customer)
      .subscribe((result) => {
        this.closeDialog(result, true);
      }, (error) => {
        this.toggleDialogButtons(false);
        const message = (error.error && error.error['message'] == 'Duplicate error') ? 'DUPLICATE_CUSTOMER' : 'CREATE_ERROR';
        this.alertService.error('', this.messages.get(message));
      });
  }

  update(customer: Customer) {
    this.toggleDialogButtons();
    this.customerService.update(customer.id, customer)
      .subscribe((result) => {
        this.closeDialog(result, true);
      }, (error) => {
        this.toggleDialogButtons(false);
        const message = (error.error && error.error['message'] == 'Duplicate error') ? 'DUPLICATE_CUSTOMER' : 'UPDATE_ERROR';
        this.alertService.error('', this.messages.get(message));
      });
  }

  deleteCustomer(event) {
    if (this.isUpdate && event) {
      this.toggleDialogButtons();
      this.customerService.delete(this.customer.id)
        .subscribe((deleteResult) => {
          if (deleteResult) {
            this.dialogRef.close({deleted: true});
          }
        }, (err) => {
          this.toggleDialogButtons(false);
          if (err.data === 'SequelizeForeignKeyConstraintError') {
            setTimeout(() => {
              this.alertService.error('', this.messages.get('CONSTRAIN_DELETE_ERROR'));
            }, 250);
          } else {
            setTimeout(() => {
              this.alertService.error('', this.messages.get('DELETE_ERROR'));
            }, 250);
          }
        });
    }
  }

  editForm() {
    this.customerService.findByIdForEdit(this.customer.id).subscribe((result: EntityLockData) => {
      if (result['$lock'] && result['$lock']['status'] === 403) {
        this.alertService.error('', `${this.messagesCore.get('DATA_LOCKED')}${result['$lock']['user']}`)
      } else {
        this.form.enable();
        this.viewOnly = false;
        this.formTitle = this.editTitle;
      }
    })
  }

  cancel() {
    this.customerService.cancelEdit();
    this.closeDialog();
  }
}
