<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
    <h1 mat-dialog-title>{{formTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">
  <form class="ca-form" [formGroup]="form">
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <mat-form-field caInput>
          <input matInput placeholder="Company" required formControlName="company" type="text">
          <mat-hint *ngIf="form.hasError('required', ['company']) && form.controls.company.touched" class="error-class">{{VALIDATION.REQUIRED_ERROR}}</mat-hint>
        </mat-form-field>
      </div>
      <div class="flex-item caGap caGapRight" fxFlex>
        <mat-form-field caInput>
          <input matInput placeholder="Phone" formControlName="office_phone" type="text" [mask]="phoneRegexMask">
          <mat-hint *ngIf="(form.hasError('pattern', ['office_phone']) || this.form.get('office_phone').invalid) && form.controls.office_phone.touched" class="error-class">{{phoneRegexMask}}</mat-hint>
        </mat-form-field>
      </div>

      <div class="flex-item caGap caGapRight" fxFlex>
        <mat-form-field caInput>
          <input matInput placeholder="Account Number" formControlName="account_number" type="text">
        </mat-form-field>
      </div>
      <div class="flex-item caGap" fxFlex>
        <mat-form-field caInput>
          <input matInput placeholder="Master Number" formControlName="master_customer_number" type="text">
        </mat-form-field>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">

      <div class="flex-item dialog-content-no-margin-form-child caGapRight" fxFlex>
        <ca-address-selection 
          [disabled]="viewOnly" 
          [required]="!firstFormModeEnabled"
          [componentTitle]="'Corporate Address'" 
          [address]="customer?.corporate_address"
          (addressChanged)="onAddressChanged($event)"
          (countryChanged)="onCountryChanged($event)"
          (isFormMode)="onFirstFormModeChanged($event)"
        ></ca-address-selection>
      </div>

      <div class="flex-item dialog-content-no-margin-form-child" fxFlex>
        <ca-address-selection 
          [required]="!secondFormModeEnabled"
          [disabled]="viewOnly" 
          [componentTitle]="'Billing Address'" 
          [address]="customer?.billing_address" 
          (addressChanged)="onBillingAddressChanged($event)"
          (isFormMode)="onSecondFormModeChanged($event)"
        ></ca-address-selection>
      </div>
    </div>

    <!-- Adress 2 & 3 container ( for both corporate and billing ) -->
    <div class="flex-container corporate-billing-addresses" fxLayout="row" style="margin-top: 24px" fxLayout.sm="column">
        <div class="flex-item caGap caGapRight" fxFlex>
          <mat-form-field caInput>
            <input matInput placeholder="Corporate Address 2" formControlName="corporate_address2" type="text">
          </mat-form-field>
        </div>
        <div class="flex-item caGap caGapRight" fxFlex>
          <mat-form-field caInput>
            <input matInput placeholder="Corporate Address 3" formControlName="corporate_address3" type="text">
          </mat-form-field>
        </div>

        <div class="flex-item caGap caGapRight" fxFlex>
          <mat-form-field caInput>
            <input matInput placeholder="Billing Address 2" formControlName="billing_address2" type="text">
          </mat-form-field>
        </div>
        <div class="flex-item caGap" fxFlex>
          <mat-form-field caInput>
            <input matInput placeholder="Billing Address 3" formControlName="billing_address3" type="text">
          </mat-form-field>
        </div>
      </div>
</form>

  </div>

  <mat-dialog-actions class="ca-align-baseline ca-dialog-action" *ngIf="!viewOnly">
    <div class="example-fill-remaining-space">
      <ca-confirm-delete-button #genericButton *ngIf="isUpdate" [confirmText]="'Delete?'" (deleteRequested)="deleteCustomer($event)"></ca-confirm-delete-button>
    </div>

    <button mat-button #cancelButton type="submit" (click)="cancel()">
      Cancel
    </button>

    <button mat-button #saveButton type="submit" (click)="onSubmit(form)" [disabled]="form?.invalid || !corporateAddress">
      Save
    </button>
  </mat-dialog-actions>

  <mat-dialog-actions class="ca-dialog-action" *ngIf="viewOnly">
    <div class="example-fill-remaining-space"></div>

    <button mat-button type="submit" (click)="closeDialog()">
      Cancel
    </button>

    <button mat-button type="submit" (click)="editForm()">
      Edit
    </button>
  </mat-dialog-actions>
