<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{title}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content ca-form">
    <mat-form-field hiDensityInput>
      <input matInput required [placeholder]="placeholder" [(ngModel)]="value" />
    </mat-form-field>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="submit" (click)="cancel()">
      Cancel
  </button>
  <button [disabled]="!value && value.length == 0" mat-button (click)="confirm()">Confirm</button>
</mat-dialog-actions>
