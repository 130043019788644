<app-contract-schedule-toolbar
  [isRatesTab]="true"
  [isDeleteRateDisabled]="!this.selectedRates.length"
  [isSaveDisabled]="ratesSaving || !isRateDataValid"
  (addRateRequested)="addRateData()"
  (saveRatesRequested)="saveRates()"
  (deleteRateRequested)="deleteRates()"
  (gridSettingsRequested)="rateGridSettings()"
  (csvRequested)="rateCsv()"
  [isFilterDisabled]="false"
  [isFilterOn]="this.sider.getActiveSection()?.name === this.SECTIONS.FILTER_SECTION_NAME"
  (toggleFilterRequested)="toggleFilter()"
  [notesCount]="notesCount"
  [isNotesOn]="this.sider.getActiveSection()?.name === this.SECTIONS.NOTES_SECTION_NAME"
  (toggleNotesRequested)="toggleNotes()"
></app-contract-schedule-toolbar>
<mat-sidenav-container class="app-content app-content-list tabbed-content dx-nodata-absolute">
    <app-contract-schedule-tabs [activeTabIndex]="3" [schedule]="schedule"></app-contract-schedule-tabs>
    <div class="tabs-body grid-container grid-with-toolbar dx-nodata-absolute"
         style="top: -2px; z-index: -1; overflow: hidden;">
      <div class="grid-body">
        <dx-data-grid #rateGrid
                      class="grid-with-selection-badge app-content-list first-column"
                      [height]="'100%'"
                      [columns]="rateColumns"
                      [dataSource]="rates"
                      [showColumnLines]="false"
                      [showRowLines]="true"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [columnMinWidth]="50"
                      (onRowUpdated)="onRowUpdated($event)"
                      (onCellClick)="onRatesCellClick($event)"
                      (onCellPrepared)="onRatesCellPrepared($event)"
                      (onSelectionChanged)="onSelectionChangedRate($event)">
  
          <dxo-selection mode="multiple"></dxo-selection>
          <dxo-paging [enabled]="false" visible="false"></dxo-paging>
          <ng-container *permission ="'Modify'">
            <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
          </ng-container>
  
          <div *dxTemplate="let d of 'dateTimeTemplate'">
            <div caGridTooltip>{{ d.value | caDate }}</div>
          </div>
  
          <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
            <a class="selection-badge grid-badge" matTooltip="Selection">
              {{selectedRates.length}}
            </a>
          </div>
          <div *dxTemplate="let d of 'selectionBadgeCellTemplate'"> </div>
  
          <div *dxTemplate="let d of 'rateCurrencyTemplate'">
            <div caGridTooltip class="right">{{ d.data.currency_id ? (d.value | caCurrencyId:'USD': 'symbol-narrow': '1.2-8': 'en': d.data.currency_id) : (d.value | caCurrency:'USD': 'symbol-narrow': '1.2-8': 'en') }}</div>
          </div>
          <i *dxTemplate="let d of 'contractScheduleDetailRateHeaderTemplate'">
            {{ d.column.caption }}
          </i>
          <dxo-sorting mode="none"></dxo-sorting>
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
        </dx-data-grid>
        <ca-pager class="app-pager ca-default-border-top" [query]="queryRate" [pageContext]="contractScheduleDetailRateGridService" (onChange)="loadRates()"></ca-pager>
      </div>
    </div>
    <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false" (close-start)="panelClose()">
      <new-app-filter
          [ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
          [query]="queryRate"
          [filterService]="contractScheduleDetailRateFilterService"
          (filterRequested)="loadRates($event)"
          (clearFilterRequested)="loadRates($event)"
      >
    </new-app-filter>
    <ca-notes
      [entityId]="schedule.id"
      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
      [entityType]="SYSTEM_MODULE.CONTRACT"
      (countChanged)="onNotesCountChanged($event)"
    ></ca-notes>
  </mat-sidenav>
</mat-sidenav-container>
