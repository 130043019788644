import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { Restangular } from 'ngx-restangular';

@Injectable()
export class GlDirectService extends BaseService<any> {
  constructor(restangular: Restangular) {
    super('gl-direct', restangular);
  }

  createDirectGL(apportions, charges, invoice) {
    return this.service().all(this.name).customPOST({
      apportions: apportions.map((x) => {
        return {
          allocation: x.gl_apportion_pct || x.allocation,
          gl_string_id: x.gl_string_id,
          dynamic_included: x.dynamic_included,
        }
      }),
      charges: charges.map(x => x.id),
      invoice_id: invoice.invoice_id
    });
  }

  update(id, {apportions, charges, invoice}) {
    return this.service().one(this.name, id).customPUT({
      apportions: apportions.map((x) => {
        return {
          allocation: x.gl_apportion_pct || x.allocation,
          gl_string_id: x.gl_string_id,
          dynamic_included: x.dynamic_included,
        }
      }),
      charges: charges.map(x => x.id),
      invoice_id: invoice.invoice_id
    });
  }
}
