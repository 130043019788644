import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderManageDialogComponent } from './shared/order-manage-dialog/order-manage-dialog.component';
import { CustomFieldsResolver } from './core/custom-fields.resolver';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':id/show',
        component: OrderDetailsComponent
      },
      {
        path: '',
        component: OrderListComponent
      }
    ]
  },
  {
    path: 'custom-fields',
    component: OrderManageDialogComponent,
    resolve: { data: CustomFieldsResolver}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CustomFieldsResolver]
})
export class OrderRoutingModule { }
