import { Injectable } from '@angular/core';
import { GridService } from '../../../shared/grid/grid.service';
import { FormatService } from '../../../shared/format/format.service';
import { UserSettingsService } from '../../../user/core/user-settings.service';

@Injectable()
export class OrderServiceLevelGridService extends GridService {
  constructor(
    public userSettings: UserSettingsService,
    public formatService: FormatService
  ) {
    super('app.orders.order-service-level-grid', userSettings);
  }

  defaultColumns = [
    {
      dataField: 'order.full_order_id',
      caption: 'Order Number',
      alignment: 'left',
      width: 130
    },
    {
      caption: 'Service Number',
      dataField: 'full_service_id',
      width: 180
    },
    {
      caption: 'Status',
      dataField: 'status.value',
      cellTemplate: 'statusType',
      width: 120
    },
    {
      caption: 'Status Date',
      cellTemplate: 'statusDate',
      dataField: 'des_due_date', // check
      dataType: 'date',
      format: "dateTemplate",
      width: 120
    },
    {
      caption: 'Vendor',
      dataField: 'inventory.vendor.name',
      width: 120
    },
    {
      caption: 'Type',
      dataField: 'order.type.value',
      width: 120
    },
    {
      caption: 'Customer',
      dataField: 'inventory.customer.company',
      width: 150
    },
    {
      caption: 'SP Circuit ID',
      dataField: 'inventory.sp_ckt_id', // check
      width: 150
    },
    {
      caption: 'Circuit System ID',
      dataField: 'inventory.id', // check
      width: 150,
      visible: false
    },
    {
      caption: 'Service Type',
      dataField: 'inventory.type.value',
      width: 150
    },
    {
      caption: 'Circuit Bandwidth',
      dataField: 'inventory.circuit_bandwidth.value',
      width: 150
    },
    {
      caption: 'Site A',
      dataField: 'inventory.siteA.site_id',
      width: 180
    },
    {
      caption: 'Site Z',
      dataField: 'inventory.siteZ.site_id',
      width: 180
    },
  ];
}
