import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'app-invoice-status-gl',
  templateUrl: 'invoice-status-gl.component.html',
})
export class InvoiceStatusGlComponent implements OnInit {

  state: any;

  constructor(public dialogRef: MatDialogRef<InvoiceStatusGlComponent>, public router: Router) {
  }

  ngOnInit() {
  }

  proceedToGL() {
    this.dialogRef.close();
    this.router.navigate(['gl-rule-execution'])
  }

  cancel() {
    this.dialogRef.close();
  }

}
