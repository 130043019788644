import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from 'app/material-barrel/material-barrel.module';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CoreModule} from "../core/core.module";
import {VendorRemitAddressComponent} from './vendor-remit-address.component';
import {VendorRemitAddressRoutingModule} from './vendor-remit-address-routing.module';
import {VendorRemitAddressManageDialogComponent} from './shared/vendor-remit-address-manage-dialog/vendor-remit-address-manage-dialog.component';
import {VendorRemitAddressService} from './core/vendor-remit-address.service';
import {VendorRemitAddressFilterService} from './core/vendor-remit-address-filter.service';
import {VendorModule} from "../vendor/vendor.module";
import {VendorRemitAddressGridService} from "./core/vendor-remit-address-grid.service";
import {VendorRemitAddressListComponent} from './vendor-remit-address-list/vendor-remit-address-list.component';
import {VendorRemitAddressSneakPeekComponent} from './shared/vendor-remit-address-sneak-peek/vendor-remit-address-sneak-peek.component';
import {DxDataGridModule} from "devextreme-angular/ui/data-grid";
import {VendorRemitAddressPickerComponent} from './shared/vendor-remit-address-picker/vendor-remit-address-picker.component';
import {LocationModule} from "../location/location.module";
import {SharedModule} from "../shared/shared.module";
import {AddressModule} from "../address/address.module";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    VendorRemitAddressRoutingModule,
    FormsModule,
    VendorModule,
    LocationModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    DxDataGridModule,
    CoreModule,
    AddressModule
  ],
  exports: [VendorRemitAddressPickerComponent],
  declarations: [
    VendorRemitAddressComponent,
    VendorRemitAddressManageDialogComponent,
    VendorRemitAddressListComponent,
    VendorRemitAddressSneakPeekComponent,
    VendorRemitAddressPickerComponent
  ],
  entryComponents: [VendorRemitAddressManageDialogComponent],
  providers: [
    VendorRemitAddressService,
    VendorRemitAddressGridService,
    VendorRemitAddressFilterService
  ]
})
export class VendorRemitAddressModule {
}
