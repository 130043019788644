import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { PageDetailsComponent } from "../../core/page-details.component";
import { PageContext } from '../../core/page.context';
import { IMessagesResourceService } from '../../core/resources/resources.service';
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LOOKUP_ENUM } from "../../dictionary/core/lookup.enum";
import { LocationService } from "../../location/core/location.service";
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { DocumentFilterService } from "../../shared/document/core/document-filter.service";
import { HistoryComponent } from '../../shared/history/history.component';
import { LoaderService } from '../../shared/loader/loader.service';
import { SortingBuilder, SortingService } from "../../shared/sorting/sorting.service";
import { TabGroup, TabService } from '../../shared/tabs/tab.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { UserService } from "../../user/core/user.service";
import { Contract } from '../core/contract';
import { DocumentQuery } from "../core/contract-document.query";
import { ContractGridService } from '../core/contract-grid.service';
import { ContractRateQuery } from '../core/contract-rate.query';
import { ContractScheduleDetailRateFilterService } from "../core/contract-schedule-detail-rate-filter.service";
import { ContractScheduleDetailRateGridService } from "../core/contract-schedule-detail-rate-grid.service";
import { ContractScheduleDetailTermAndConditionFilterService } from "../core/contract-schedule-detail-term-and-condition-filter.service";
import { ContractScheduleDetailTermConditionGridService } from "../core/contract-schedule-detail-term-condition-grid.service";
import { ContractTermAndConditionQuery } from "../core/contract-term-and-condition.query";
import { ContractTermsConditions } from "../core/contract-terms-conditions";
import { ContractQuery } from '../core/contract.query';
import { ContractService } from '../core/contract.service';


enum TABGROUP {
  SUMMARY = 1,
  KEY_SECTIONS,
  DOCUMENTS,
  RATES
}

@Component({
  selector: 'app-contract-schedule-details',
  templateUrl: './contract-schedule-details.component.html',
  styleUrls: ['./contract-schedule-details.component.scss']
})
export class ContractScheduleDetailsComponent extends PageDetailsComponent {
  vendorContracts: Array<any>;

  public contracts: Array<Contract>;
  public total: number;
  public selection: any;
  public columns: Array<any>;
  public columnDepth = 0;
  public sorting: any[][];
  public scheduleCommitmentType: any;

  public selectionRow;

  nestedSources = [];

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  scheduleMessages: IMessagesResourceService;

  readonly MESSAGES_MODULE: string = 'schedule';
  readonly CORE_MODULE: string = 'core';
  readonly SCHEDULE_MODULE: string = 'schedule';
  readonly CONTRACT_DOCUMENT_FOLDER_NAME: string = 'contracts';


  //This part of the code we need for badges. For now, we didn't implement this functionality
  query: ContractQuery = new ContractQuery({
  });
  queryRate: ContractRateQuery = new ContractRateQuery({});
  queryTermAndCondition: ContractTermAndConditionQuery = new ContractTermAndConditionQuery({});
  public queryDocument = new DocumentQuery({});

  activeTabIndex: number = 0;
  tempIdCounter: number = 0;

  public tabGroup: TabGroup;
  _isSidePanelOpen = false;
  tabs = TABGROUP;

  readonly SIDENAV_SECTIONS = {
    NOTES: 'notes',
    HISTORY: 'history',
    FILTER: 'filter'
  }

  public SIDENAV_SECTIONS_ACTIVATES = {};
  public tabChanged: boolean;
  public contractSchedule;

  @ViewChild('panelMore') panelMore;
  @ViewChild('commitmentGrid') commitmentGrid: DxDataGridComponent;
  @ViewChild('documentsManager') documentsManager;
  @ViewChild('history') history: HistoryComponent;
  @ViewChild('rateGrid') rateGrid: DxDataGridComponent;
  @ViewChild('termsAndConditionGrid') termsAndConditionGrid: DxDataGridComponent;

  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  readonly COMMITMENT_TYPE = LOOKUP_ENUM.CONTRACT_SCHEDULE_COMMITMENT_TYPE;

  DOCUMENT_INFO = {
    FOLDER: 'contracts',
    SUBFOLDER: '',
    PARENT_TYPE: this.SYSTEM_MODULE.CONTRACT,
    ENTITY_TYPE: this.SYSTEM_MODULE.CONTRACT
  };

  public rateColumns: Array<any>;
  public rates: Array<any> = [];
  public isRateDataValid: boolean = false;
  public rateRequiredFields = [
    'rate_code',
    'rate_category',
    'mrc',
    'nrc',
    'currency_id'
  ];
  selectionRate: any;
  public selectedRates: Array<any> = [];
  public termConditionColumns: Array<any>;
  public termsConditions: Array<ContractTermsConditions>
  public scheduleId: number;
  public contractId: number;
  public notesCount: number = 0;
  public commitTotal: number = 0;


  public ratesSaving: boolean = false;
  public deleteFunctions;
  public rateColumnsSorting: any[];
  public termConditionColumnsSorting: any[];
  isGridSettingsDisabled = true;
  public termsConditionSorting: SortingBuilder;
  public ratesSortingBuilder: SortingBuilder;

  constructor(public contractService: ContractService,
              public contractGridService: ContractGridService,
              public dialog: DialogService,
              public alert: AlertService,
              public router: Router,
              public route: ActivatedRoute,
              public settingsService: UserSettingsService,
              public loaderService: LoaderService,
              public tabService: TabService,
              public contractScheduleDetailRateGridService: ContractScheduleDetailRateGridService,
              public contractScheduleDetailTermConditionGridService: ContractScheduleDetailTermConditionGridService,
              public dictionaryService: DictionaryService,
              public locationService: LocationService,
              public contractScheduleDetailTermAndConditionFilterService: ContractScheduleDetailTermAndConditionFilterService,
              public contractScheduleDetailRateFilterService: ContractScheduleDetailRateFilterService,
              public documentFilterService: DocumentFilterService,
              public sortingService: SortingService,
              public userService: UserService,
  ) {
    super(new PageContext({
      name: 'app.contract.contract-schedule-details',
      settings: settingsService
    }));
  }
 
}
