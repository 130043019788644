<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{title}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
<div class="grid-container" *ngIf="!config">
  <div style="height: 220px" class="grid-body app-content-list app-content-list-no-pager dx-nodata-absolute">
    <dx-data-grid #grid
                  class="grid-with-checkboxes"
                  [columns]="columns"
                  [dataSource]="audits">
      <div *dxTemplate="let d of 'rateCurrencyTemplate'">
        <div caGridTooltip class="right">{{ d.data?.contract_schedule_rate.currency?.currency ? (d.value | caCurrency:d.data.contract_schedule_rate?.currency?.currency) : (d.value | caCurrency) }}</div>
      </div>
    </dx-data-grid>
  </div>
</div>
<form class="ca-form" style="padding-top: 8px">
  <div fxLayout="row wrap">
    <mat-form-field caInput class="caGap">
      <textarea matInput [placeholder]="placeHolder" [formControl]="note" fxFlex="100%" rows="2" [required]="'true'"></textarea>
    </mat-form-field>
  </div>
</form>
</div>


<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
    <ca-confirm-delete-button
      #genericButton [requestInput]="true" (deleteRequested)="delete()" [deleteText]="actionText"
      [snackBarDeleteInputMessage]="'Please type in '+ randomString + ' to ' +  actionText"
      [placeHolder]="deletePlaceholder" [entityName]="randomString" [disabled]="note.invalid">
    </ca-confirm-delete-button>
  </div>
  <button #cancelButton mat-button type="submit" (click)="close()">
    Cancel
  </button>
</mat-dialog-actions>

