import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class TagAdministrationGridService extends GridService {


  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super("app.tags.tag-administration-grid", userSettings);
  }

  defaultColumns = [
    {
      caption: "",
      headerCellTemplate: 'selectionBadgeHeaderTemplate',
      cellTemplate: "selectionBadgeCellTemplate",
      fixed: true,
      width: 40,
      allowSorting: false,
      allowResizing: false,
    },
    {
      caption: 'Name',
      dataField: 'name',
      width: '*'
    },
    {
      caption: 'Usage',
      dataField: 'entities',
      cellTemplate: 'usageTemplate',
      width: 120
    }];
}
