export const DocumentFilterContext = {
  filters: [
    {
      field: 'file_name',
      label: 'Name',
      type: 'string',
      index: 2
    },
    {
      field: 'documentType.key',
      label: 'Type',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'documentType',
      index: 1
    },
    {
      field: 'description',
      label: 'Description',
      type: 'string',
      index: 3
    }
  ]
};
