import {Injectable} from '@angular/core';
import {FilterService} from '../../core/filter/filter.service';
import {vendorFilterContext} from './vendor-filter.context';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class VendorFilterService extends FilterService {

  constructor(public userSettingsService: UserSettingsService) {
    super("app.appdata.vendor-filter", vendorFilterContext, userSettingsService);
  }
}




