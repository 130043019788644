
<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
    <button mat-icon-button matTooltip="Back" (click)="back()">
      <ca-icon [key]="'BACK'"></ca-icon>
    </button>
    <span class="toolbar-title">{{ (alert?.type?.value || '') + ' Alert: ' + (batchKey || 'N/A') }}</span>
    <span class="example-fill-remaining-space">&nbsp;</span>
    <button mat-icon-button matTooltip="Refresh" (click)="refresh()">
      <ca-icon [key]="'REFRESH'"></ca-icon>
    </button>
    <div class="vertical-divider"></div>
    <button [disabled]="!tabGroup.isActive(2)" mat-icon-button (click)="csv()">
      <ca-icon [key]="'DOWNLOAD'" [tooltip]="'Download CSV'"></ca-icon>
    </button>
    <button [disabled]="!tabGroup.isActive(2) || isGridSettingsDisabled" mat-icon-button (click)="gridSettings()">
      <ca-icon [key]="'GRID_SETTINGS'" [tooltip]="'Settings'"></ca-icon>
    </button>
    <div class="vertical-divider"></div>
    <button [disabled]="!tabGroup.isActive(2)"
            [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(2)}"
            (click)="toggleFilter()" mat-icon-button>
      <ca-icon [key]="'FILTER'" [tooltip]="'Filter'"></ca-icon>
    </button>
  </mat-toolbar>


  <mat-sidenav-container class="app-content tabbed-content">

      <mat-tab-group (selectedIndexChange)="onTabChange($event)">
        <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs">
        </mat-tab>
      </mat-tab-group>


      <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(1)}" class="tabs-body" fxLayout="column">
        <div class="flex-container ca-card custom-mat-card-container-small" fxFlex="0 0 325px" fxLayout="row">
          <div fxFlex>
            <mat-card fxFlex fxFlexFill caCard>

              <mat-card-header>
                Alert Info
              </mat-card-header>

              <mat-card-content>
                <div fxLayout="row" class="entry-list">
                  <div fxFlex class="entry-list-item">
                    <label>Category</label>
                    <p>
                      {{ alert?.category?.value || 'N/A' }}
                    </p>
                  </div>

                  <div fxFlex class="entry-list-item">
                    <label>Created At</label>
                    <p>
                      {{ (alert?.created_at | caDateTime) || 'N/A' }}
                    </p>
                  </div>
                </div>

                <div fxLayout="row" class="entry-list">
                  <div fxFlex class="entry-list-item">
                    <label>Created By</label>
                    <p>
                      {{ alert?.creator?.full_name || 'System' }}
                    </p>
                  </div>

                  <div fxFlex class="entry-list-item">
                    <label>Owner</label>
                    <p>
                      {{ alert?.owner?.full_name || PLACEHOLDERS.OWNER_UNASSIGNED }}
                    </p>
                  </div>
                </div>

                <div fxLayout="row" class="entry-list">
                  <div fxFlex class="entry-list-item">
                    <label>Priority</label>
                    <p>
                      {{ alert?.priority.value || 'N/A' }}
                    </p>
                  </div>

                  <div fxFlex class="entry-list-item">
                    <label>Status</label>
                    <p>
                      {{ alert?.status.value || 'N/A' }}
                    </p>
                  </div>
                </div>

                <div fxLayout="row" class="entry-list">
                  <div fxFlex class="entry-list-item">
                    <label>Last Modified At</label>
                    <p>
                      {{ (alert?.last_modified_at | caDate) || 'N/A' }}
                    </p>
                  </div>

                  <div fxFlex class="entry-list-item">
                    <label>Closed At</label>
                    <p>
                      {{ (alert?.closed_at | caDate) || 'N/A' }}
                    </p>
                  </div>
                </div>


                <div fxLayout="row" class="entry-list">
                  <div fxFlex class="entry-list-item">
                    <label>Message</label>
                    <p>
                      {{ alert?.message || 'N/A' }}
                    </p>
                  </div>
                </div>
              </mat-card-content>

            </mat-card>
          </div>
      </div>
    </div>


    <mat-sidenav-container [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}" class="tabs-body grid-container grid-with-pager app-content-list dx-nodata-absolute" style="overflow-y: hidden">

          <dx-data-grid #dataGrid class="grid-with-selection-badge-inner"
            [columns]="columns"
            [dataSource]="invoices"
            (onSelectionChanged)="onSelectionChanged($event)"
            (onCellClick)="onCellClick($event)"
            (onRowClick)="onRowClick($event)">

                <div *dxTemplate="let d of 'invNumTemplate'">
                    <div caGridTooltip>{{d.column.caption}}</div>
                </div>
                <div *dxTemplate="let d of 'invoiceDate'">
                  <div caGridTooltip>{{ d.value | caDate }}</div>
                </div>

          </dx-data-grid>
        <ca-pager [gridPager]="defaultGridPager" [pageContext]="alertBatchInvoicesGridService" [query]="query" class="app-pager ca-default-border-top"
                  (onChange)="onPageChange(query)"></ca-pager>
      </mat-sidenav-container>


    <mat-sidenav #panelSide mode="side" position="end" opened="false">
      <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                  [query]="query"
                  [filterService]="alertBatchInvoicesFilterService"
                  (filterRequested)="filterData($event)"
                  (clearFilterRequested)="clearFilter($event)">
      </new-app-filter>
    </mat-sidenav>

  </mat-sidenav-container>
