import {Inventory} from "./inventory";
import {LookupModel} from "../../dictionary/core/lookup.model";
import {ContractShellMenageDialogComponent} from "../../contract/shared/contract-shell-menage-dialog/contract-shell-menage-dialog.component";
import {Contract} from "../../contract/core/contract";
import {SvcOrderHeader} from "../../contract/core/svc-order-header";
import {ContractSchedule} from "../../contract/core/contract-schedule";

export class InventoryContractTerm {
  id: number;
  inventory_id: number;
  inventory: Inventory;
  contract_type_id: number;
  contract_type: LookupModel;
  contract_id: number;
  contract: Contract;
  contract_schedule_id: number;
  contract_schedule: ContractSchedule;
  svc_order_id: number;
  svc_order: SvcOrderHeader;
  mrc: number;
  nrc: number;
  term: number;
  expiration_date: Date;
  stop_billing_date: Date;
  currency: any;
  currency_id: number;
}
