import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Dispute } from '../../core/dispute';

@Component({
  selector: 'app-dispute-sneak-peek',
  templateUrl: './dispute-sneak-peek.component.html',
})
export class DisputeSneakPeekComponent implements OnChanges {

  @Input() dispute: Dispute;

  @Output() closeRequested = new EventEmitter();
  @Output() deletedRequested = new EventEmitter();

  id: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dispute) {
      this.id = this.dispute.id;
    }
  }

  closeSide() {
    this.closeRequested.emit();
  }

}
