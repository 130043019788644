<mat-toolbar color="accent" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
      <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-title">{{title}}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>

  <button mat-icon-button (click)="exportToPdf()" matTooltip="Download as PDF">
      <ca-icon [key]="'PDF_DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button (click)="csv()" matTooltip="Download CSV">
      <ca-icon [key]="'FILE_DOWNLOAD'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button matTooltip="Filter" [ngClass]="{'on': isFilterOpen}" (click)="toggleFilter()" mat-icon-button>
      <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content">
  <div class="call-cost-container flex-container ca-card" fxLayout="column">
    <mat-card fxFlex class="card-table card-table-no-header">
      <mat-card-content>
        <dx-chart [dataSource]="chartData" scrollingMode="all" zoomingMode="all">
        </dx-chart>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-sidenav class="srm" #panelSide mode="side" position="end" [opened]="isFilterOpen">
    <new-app-filter [ngClass]="{'hidden': !isFilterOpen}"
                [query]="filterQuery"
                [filterService]="quotingReportFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter($event)">
    </new-app-filter>
  </mat-sidenav>
</mat-sidenav-container>

