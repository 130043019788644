import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonAlertService } from "../../../common-alert/core/common-alert.service";
import { TabService, TabGroup } from "app/shared/tabs/tab.service";

@Component({
  selector: "app-contract-details-tabs",
  templateUrl: "./contract-details-tabs.component.html",
  styleUrls: ["./contract-details-tabs.component.scss"],
})
export class ContractDetailsTabsComponent implements OnInit, OnDestroy {
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public index: number;
  public oldIndex: number;

  @Output() displayPreventChangeModal: EventEmitter<any> = new EventEmitter<any>();

  @Input() activeTabIndex = 0;
  @Input() contract;
  @Input() disabled = false;

  constructor(
    private readonly router: Router,
    private route: ActivatedRoute,
    public commonAlertService: CommonAlertService,
    public tabService: TabService
  ) {
    this.loadTabs();
  }

  ngOnInit() {
    const urlParam = this.route.snapshot.url[0].path;
    this.index = this.tabGroup.tabs.find((tab) => tab.route === urlParam).key;
  }

  loadTabs() {
    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({ key: 0, label: "Summary", route: "summary" });
    this.tabGroup.addTab({ key: 1, label: "Schedules", route: "schedules" });
    this.tabGroup.addTab({ key: 2, label: "Key Terms & Conditions", route: "terms-conditions" });
    this.tabGroup.addTab({ key: 3, label: "Inventory", route: "inventory" });
    this.tabGroup.addTab({ key: 4, label: "Attachments",route: "attachments" });
    this.tabGroup.addTab({ key: 5, label: "Rates", route: "rates" });

    this.tabGroup.onActivate.subscribe(() => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }

  checkForDisabledProperty() {
    if (!this.disabled) {
      return;
    }
    this.displayPreventChangeModal.emit();
  }

  onTabChange(tab: any = null, index: number) {
    if (tab && (!this.oldIndex || this.oldIndex === index)) {
      this.oldIndex = index
    }
    if (tab && this.disabled) {
      tab.selectedIndex = this.activeTabIndex;
    }
    else if (!tab && this.disabled) {
      this.index = this.oldIndex;
      const activeTab = this.tabGroup.tabs[index].route;
      this.router.navigate(["contract", this.contract.id, "show", activeTab]);
    } else {
      this.index = index;
      const activeTab = this.tabGroup.tabs[index].route;
      this.router.navigate(["contract", this.contract.id, "show", activeTab]);
    }
  }

  ngOnDestroy(): void {
    this.tabGroup.onActivate.unsubscribe();
  }
}
