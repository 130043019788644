import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {GLStringService} from '../../gl-string/core/gl-string.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class GLRuleStringGridService extends GridService {
  constructor(public userSettings: UserSettingsService, public glStringService: GLStringService) {
    super('app.gl.gl-rule-string-grid', userSettings);
  }


  generateColumns(suffix = '') {
    return new Promise((resolve, reject) => {
      this.glStringService.findGLSegments()
        .then((data: Array<any>) => {
          let apportionStringCols = [];

          data.forEach((o) => {
            o.caption = o.value;
            o.dataField = [`gl_string${suffix}.`, o.system_key, '_obj.segment_value'].join('');
            o.allowEditing = false;
            o.width = 130;
            apportionStringCols.push(o);

            let desc = Object.assign({}, o);
            desc.caption = [o.value, 'Description'].join(' ');
            desc.width = 200;
            desc.dataField = [`gl_string${suffix}.`, o.system_key, '_obj.segment_desc'].join('');
            apportionStringCols.push(desc);
          });
          let selectedStringsDefaultColumns = [
            {
              caption: 'Apportion %',
              dataField: 'apportion_pct',
              alignment: 'right',
              width: 120
            },
            {
              caption: 'Full GL String',
              dataField: `gl_string${suffix}.full_string_formatted`,
              allowEditing: false,
              width: 220
            },
            {
              caption: 'GL String Desc',
              dataField: `gl_string${suffix}.full_string_text`,
              allowEditing: false,
              width: 280
            }
          ];

          let apportionCols = selectedStringsDefaultColumns.concat(apportionStringCols);

          resolve(apportionCols);
        });
    })
  }
}
