import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class InvoiceAccountGridService extends GridService {

  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super('app.cost.invoice-account-grid', userSettings);
  }

  defaultColumns = [{
    caption: 'Invoice Number',
    dataField: 'sp_inv_num',
    width: 220
  }, {
    caption: 'Status',
    dataField: 'header.status.value',
    width: 120
  }, {
    caption: 'Batch ID',
    dataField: 'batch.batch_key',
    // cellTemplate: 'batchIdTemplate',
    width: 120
  }, {
    caption: 'AP Invoice Number',
    dataField: 'batch_item.ap_invoice_number',
    // cellTemplate: 'batchIdTemplate',
    width: 220
  }, {
    caption: 'Issue Date',
    dataField: 'date_issued',
    dataType: 'date',
    cellTemplate: 'dateTemplate',
    alignment: 'left',
    width: 100
  }, {
    caption: 'Due Date',
    dataField: 'due_date',
    dataType: 'date',
    cellTemplate: 'dateTemplate',
    alignment: 'left',
    width: 100
  }, {
      caption: 'Invoice Date',
      dataField: 'inv_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      width: 100
    }, {
    caption: 'Balance Forward',
    dataField: 'bal_fwd',
    cellTemplate: 'invoiceCurrencyTemplate',
    dataType: 'currency',
    alignment: 'right',
    width: 150
  }, {
    caption: 'Total New Charges Adj',
    dataField: 'tot_new_chg_adj',
    cellTemplate: 'invoiceCurrencyTemplate',
    dataType: 'currency',
    alignment: 'right',
    width: 170
  }, {
    caption: 'Total Due',
    dataField: 'tot_amt_due',
    cellTemplate: 'invoiceCurrencyTemplate',
    dataType: 'currency',
    alignment: 'right',
    width: 120
  }, {
    caption: 'Total MRC',
    dataField: 'tot_mrc_chgs',
    cellTemplate: 'invoiceCurrencyTemplate',
    dataType: 'currency',
    alignment: 'right',
    width: 120
  }, {
    caption: 'Total OCC',
    dataField: 'tot_occ_chgs',
    cellTemplate: 'invoiceCurrencyTemplate',
    dataType: 'currency',
    alignment: 'right',
    width: 120
  }, {
    caption: 'Total Usage',
    dataField: 'tot_usage_chgs',
    cellTemplate: 'invoiceCurrencyTemplate',
    dataType: 'currency',
    alignment: 'right',
    width: 120
  }, {
    caption: 'Total Taxes / Surcharge',
    dataField: 'tot_taxsur',
    cellTemplate: 'invoiceCurrencyTemplate',
    dataType: 'currency',
    alignment: 'right',
    width: 170
  }, {
    caption: 'Total Discount',
    dataField: 'tot_disc_amt',
    cellTemplate: 'invoiceCurrencyTemplate',
    dataType: 'currency',
    alignment: 'right',
    width: 120
  }, {
    caption: 'Total New Charges',
    dataField: 'tot_new_chgs',
    cellTemplate: 'invoiceCurrencyTemplate',
    dataType: 'currency',
    alignment: 'right',
    width: 160
  },{
    caption: 'Currency',
    dataField: 'currency',
    width: 160
  }, {
    caption: 'New Charges Diff',
    dataField: 'header.tot_new_chg_diff',
    cellTemplate: 'invoiceCurrencyTemplate',
    dataType: 'currency',
    alignment: 'right',
    width: 160
  }, {
    caption: 'New Charges Diff %',
    dataField: 'header.tot_new_chg_diff_pct',
    dataType: 'number',
    precision: 2,
    width: 170
  }];

  /*columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
