import { Injectable } from "@angular/core";
import { AccountLookupProvider } from "../../account/core/account-lookup.provider";
import { AccountService } from "../../account/core/account.service";
import { FilterService } from "../../core/filter/filter.service";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { SiteLookupProvider } from "../../site/core/site-lookup.provider";
import { SiteService } from "../../site/core/site.service";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { VendorService } from '../../vendor/core/vendor.service';
import { contractInventoryFilterContext } from "./contract-inventory-filter.context";
import { ContractService } from "./contract.service";
import { DefaultDateRangesLookupProvider } from "app/core/filter/default-date-ranges-lookup.provider";
@Injectable()
export class InventoryFilterService extends FilterService {
  customRanges: any = [
    {
      label: 'Last 2 years'
    },
    {
      label: 'Last 6 months'
    }
  ];

  excludeRanges: any = [
    {
      label: 'Prior month'
    },
    {
      label: 'Last week'
    }
  ];

  constructor(public userSettingsService: UserSettingsService,
              public contractService: ContractService,
              public vendorService: VendorService,
              public accountService: AccountService,
              public dictionaryService: DictionaryService,
              public siteService: SiteService,
  ) {
    super('app.contract.contract-inventory-filter', contractInventoryFilterContext, userSettingsService);

    this.addLookupProvider('inventoryStatus', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.INVENTORY_STATUS.modelName));
    this.addLookupProvider('networkType', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.INVENTORY_TYPE.modelName, true, true));
    this.addLookupProvider('site', new SiteLookupProvider(siteService, true));
    this.addLookupProvider('account', new AccountLookupProvider(accountService));
    this.addLookupProvider('inventoryTechnology', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName, true, true));
    this.addLookupProvider('serviceType', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.INVENTORY_CKT_TYPE.modelName, true, true));
    this.addLookupProvider('serviceBandwidth', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.BANDWIDTH.modelName, true, true));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges,true));
  }
}
