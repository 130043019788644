import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class AlertChargeTotalGridService extends GridService {


  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super("app.alert-inventory.alert-inventory-charge-total", userSettings);
  }

  defaultColumns = [
    {
      caption: 'Message',
      dataField: 'message'
    }
  ];

}
