<div class="tag-filter ca-form">
  <mat-form-field hiDensityInput>
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let entry of selection"
        (removed)="remove(entry)">
        <span>{{entry}}</span>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        #optionsInput
        style="margin-top: 16px"
        [formControl]="inputControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (focusout)="clearQueryOnFocusOut()">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete"
                      (optionsScroll)="onScroll()"
                      (optionSelected)="selected($event)">
      <mat-option *ngFor="let option of options" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
