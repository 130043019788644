import {AfterContentInit, Component, EventEmitter, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FilterBaseComponent} from '../filter-base.component';
import {FormControl} from '@angular/forms';
import {FilterEntry} from "../../../core/filter/filter-entry";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

const OPERATORS = {
  GTE: '$gte',
  LTE: '$lte',
  BETWEEN: '$between',
};

@Component({
  selector: 'cas-filter-number-range',
  templateUrl: './filter-number-range.component.html',
  styleUrls: ['./filter-number-range.component.scss']
})
export class FilterNumberRangeComponent implements AfterContentInit, OnChanges, FilterBaseComponent {

  private _value: any;

  get value(): any {
    return this._value;
  }

  @Input() set value(value: any) {
    const currentValue = value?.hasOwnProperty('currentValue') ? value?.currentValue : value;
    if (!FilterEntry.isEqual(currentValue, this._value)) {
      this._value = currentValue;
      this.setValues(currentValue);
    }
  }

  @Input() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() entry: FilterEntry;

  from: FormControl = new FormControl('');
  to: FormControl = new FormControl('');

  ngAfterContentInit(): void {
    this.from.valueChanges
      .pipe(
        debounceTime(1200),
      )
      .subscribe((value: any) => this.update());
    this.to.valueChanges
      .pipe(
        debounceTime(1200),
      )
      .subscribe((value: any) => this.update());
    this.setValues(this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setValues(changes.value);
  }

  private setValues(value: any): void {
    const values = this.getSimpleValues(value);
    this.from.setValue(values?.from);
    this.to.setValue(values?.to);
  }

  private update(): void {
    if(!this.isValidInput()) {
      return;
    }
    const result = this.getValue();
    this.change.emit(result);
  }

  private getSimpleValues(value): any {

    if (!value) {
      return undefined;
    }
    if (value[OPERATORS.BETWEEN]) {
      const values = value[OPERATORS.BETWEEN];
      return {from: values[0], to: values[1]};
    } else if (value[OPERATORS.GTE] || value[OPERATORS.GTE] === 0) {
      const values = value[OPERATORS.GTE];
      return {from: values};
    } else if (value[OPERATORS.LTE] || value[OPERATORS.LTE] === 0) {
      const values = value[OPERATORS.LTE];
      return {to: values};
    }
  }

  private getValue(): any {
    let operator = OPERATORS.GTE;

    if (this.endsWithDecimalSeparator(this.from.value)) {
      this.from.setValue([this.from.value, '0'].join(''))
    }

    if (this.endsWithDecimalSeparator(this.to.value)) {
      this.to.setValue([this.to.value, '0'].join(''))
    }

    let from = this.from.value;
    let to = this.to.value;

    let result;

    if (!this.valueExists(from)
      && !this.valueExists(to)) {
      return null;
    }

    if (this.valueExists(from)
      && this.valueExists(to)) {
      operator = OPERATORS.BETWEEN;
      result = [parseFloat(from), parseFloat(to)];
    } else if (this.valueExists(from)) {
      operator = OPERATORS.GTE;
      result = parseFloat(from);
    } else if (this.valueExists(to)) {
      operator = OPERATORS.LTE;
      result = parseFloat(to);
    }

    return {
      [operator]: result
    };
  }

  private valueExists(val: any): boolean {
    return val !== undefined
      && val !== null
      && val !== '';
  }

  private endsWithDecimalSeparator(s) {
    if (!s) return false;
    return s.length >= s.length && s.substr(s.length - s.length) == s;
  }

  private isValidInput() {
    let from = parseFloat(this.from.value || '0');
    let to = parseFloat(this.from.value || '0');

    return (!isNaN(from) && !isNaN(to));
  }
}
