import { Injectable } from "@angular/core";

@Injectable()
export class CustomThemeService {
  constructor() {}

  generateCustomTheme(themeSchema, pallete?) {
    if (!themeSchema) {
      return;
    }

    const customThemeStyleTag: any = document.querySelector(
      'style[name="custom-theme-style"]'
    );

    let dynCss = `
    /* Theme accent colors */
    ${this.getThemeAccent(themeSchema.settings)}

    /* Theme background colors */
    ${this.getThemeBackground(themeSchema.settings)}

    /* Border Colors */
    ${this.getBorderColors(themeSchema.settings)}

    .fadeMe {
      background-color: ${this.hexToRGB(
        themeSchema.settings.foreground.color,
        0.5
      )} !important;
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }

    .app-content {
      background-color: ${themeSchema.settings.background.color} !important;
    }
    .mat-primary {
      background-color: ${themeSchema.settings.primary.color} !important;
      color: ${themeSchema.settings.primary.contrastColor} !important;
    }
    .mat-primary[disabled] {
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
    .mat-accent {
      background-color: ${themeSchema.settings.accent.color} !important;
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }
    .mat-toolbar.mat-accent,
     .replace-toolbar {
      background-color: ${themeSchema.settings.primary.color} !important;
      color: ${themeSchema.settings.primary.contrastColor} !important;
    }
    .replace-mode-title {
          color: ${themeSchema.settings.primary.contrastColor} !important;
    }
    mat-sidenav .mat-toolbar.mat-accent, .mat-sidenav .mat-toolbar.mat-accent {
      background-color: ${themeSchema.settings.background.color} !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .mat-icon-button.mat-primary {
      background-color: transparent !important;
    }
    mat-checkbox.mat-accent {
      background-color: transparent !important;
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: ${themeSchema.settings.accent.color} !important;
    }
    .mat-dialog-container {
      background-color: ${themeSchema.settings.background.color} !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .mat-dialog-container mat-checkbox {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .mat-dialog-container .mat-slide-toggle-content,
    .mat-dialog-container .mat-radio-label-content,
    .srm-toogle {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .mat-dialog-container .mat-radio-button {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .mat-dialog-container label, .mat-select-placeholder {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .mat-dialog-container input, .mat-select-value-text, input.search-input, .ca-picker-search-container input {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }

    .ap-config-card{
           background: ${this.hexToRGB(
            themeSchema.settings.background.contrastColor,
            0.04
          )} !important;
          justify-content: center;
          padding-left: 8px;
          /*margin-bottom: 15px;*/
          margin-bottom: unset;
          min-width: 100%;
      }

    [caInput] .mat-form-field-flex, [hidensityinput] .mat-form-field-flex {
      background: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.04
      )} !important;
    }

    .ca-form [hiDensityInput] .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
        color: ${themeSchema.settings.background.contrastColor};
    }

    .checkbox-container, .group-background, .ca-group-divided {
      background-color: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.04
      )} !important;
    }

    .mat-checkbox-inner-container {
      background-color: ${themeSchema.settings.background.color} !important;
    }

    .modal-rework .mat-form-field-disabled .mat-form-field-flex {
      background: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.08
      )} !important;
    }

    .ca-form .mat-form-field-underline,
    .mat-dialog-container .mat-form-field-underline,
    .ca-picker-search-container .mat-form-field-underline{
      background-color: ${
        themeSchema.settings.background.contrastColor
      } !important;
    }

    .ca-form .mat-form-field-ripple,
    .mat-dialog-container .mat-form-field-ripple,
    .ca-picker-search-container .mat-form-field-ripple {
      background-color: ${
        themeSchema.settings.background.contrastColor
      } !important;
    }

    .mat-drawer {
      background-color: ${themeSchema.settings.background.color} !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .btn-enabled {
      background-color: ${themeSchema.settings.success.color} !important;
      color: ${themeSchema.settings.success.contrastColor} !important;
    }
    .btn-disabled {
      background-color: ${themeSchema.settings.danger.color} !important;
      color: ${themeSchema.settings.danger.contrastColor} !important;
    }
    .btn-warning {
      background-color: ${themeSchema.settings.warning.color} !important;
      color: ${themeSchema.settings.danger.contrastColor} !important;
    }
    .btn-neutral{
      background-color: ${themeSchema.settings.primaryLight.color} !important;
      color: ${themeSchema.settings.primaryLight.contrastColor} !important;
      }
    .ca-dialog-action .mat-button, .note-button, .flat-button {
      color: ${themeSchema.settings.accent.color} !important;
    }
    .mat-card, .filter-group .mat-header-text, .function-container, .billing-list-container {
      background-color: ${themeSchema.settings.foreground.color} !important;
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .ca-form tag-input .ng2-tag-input {
      border-bottom-color: ${
        themeSchema.settings.foreground.contrastColor
      } !important;
    }
    .mat-autocomplete-panel .mat-optgroup {
      border-bottom-color: ${themeSchema.settings.background.color} !important;
    }
    .toast-success {
      background-color: ${themeSchema.settings.success.color} !important;
      color: ${themeSchema.settings.success.contrastColor} !important;
    }
    .toast-error {
      background-color: ${themeSchema.settings.danger.color} !important;
      color: ${themeSchema.settings.danger.contrastColor} !important;
    }

    .site-navigation {
      background-color: ${themeSchema.settings.primaryLight.color} !important;
      color: ${themeSchema.settings.primaryLight.contrastColor} !important;
    }
    .site-navigation nav a,
    .site-navigation nav a:link,
    .site-navigation nav a:hover,
    .site-navigation nav a:focus,
    .site-navigation nav a:active {
      color: ${themeSchema.settings.primaryLight.contrastColor} !important;
    }
    .dx-datagrid {
      background-color: ${themeSchema.settings.foreground.color} !important;
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .dx-datagrid .mat-primary,
    .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
      background-color: ${themeSchema.settings.foreground.color} !important;
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .dx-datagrid .mat-primary, .dx-datagrid-content .dx-datagrid-table .dx-row.dx-selection .dx-command-select {
      background-color: ${themeSchema.settings.background.color} !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .dx-datagrid .mat-primary, .dx-datagrid-content .dx-datagrid-table .dx-row.dx-selection .dx-command-select > ca-mat-select-full-width {
      width: 100% !important;
    }
    .ca-mat-select-full-width .mat-form-field-wrapper {
      padding: 0 !important;
    }
    .ca-mat-select-full-width .mat-input-wrapper .mat-input-flex .mat-input-infix {
      border: 0 !important;
    }
    .ca-mat-select-full-width .mat-input-wrapper .mat-form-field-underline {
      bottom: 0 !important;
    }
    .dx-checkbox-icon {
      border-color: ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.5
      )} !important;
      background-color: ${themeSchema.settings.foreground.color} !important;
    }
    .dx-datagrid-headers {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .dx-checkbox.dx-state-hover .dx-checkbox-icon {
      border: 1px solid ${themeSchema.settings.accent.color} !important;
    }
    .dx-checkbox.dx-state-focused .dx-checkbox-icon {
      border: 1px solid ${themeSchema.settings.accent.color} !important;
    }
    .dx-checkbox-checked .dx-checkbox-icon {
      background-color: ${themeSchema.settings.accent.color} !important;
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }
    .dx-checkbox-indeterminate .dx-checkbox-icon:before {
      background-color: ${themeSchema.settings.accent.color} !important;
    }
    .dx-datagrid-nodata, .ca-empty-state-container p, .ca-empty-state-container span, .cropper-loader p {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .dx-datagrid-rowsview .dx-row.dx-group-row {
      color: ${themeSchema.settings.background.contrastColor} !important;
      background-color: ${themeSchema.settings.background.color} !important;
    }
    .dx-datagrid-rowsview .dx-row.dx-group-row .dx-datagrid-group-closed,
    .dx-datagrid-rowsview .dx-row.dx-group-row .dx-datagrid-group-opened,
    .dx-datagrid-rowsview .dx-row.dx-group-row .dx-datagrid-summary-item {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .dx-datagrid tr {
      background-color: ${themeSchema.settings.foreground.color} !important;
    }
    .dx-editor-cell .dx-texteditor .dx-texteditor-input, .dx-list-item  {
      color: ${themeSchema.settings.background.contrastColor} !important;
      background-color: ${themeSchema.settings.background.color} !important;
    }
    .found-results {
      color: ${themeSchema.settings.success.color} !important;
    }
    .no-results {
      color: ${themeSchema.settings.warning.color} !important;
    }
    .app-pager mat-toolbar {
      background-color: ${themeSchema.settings.foreground.color} !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    mat-sidenav {
      background-color: ${themeSchema.settings.background.color} !important;
      border-color: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.12
      )} !important;
    }
    .mat-stepper-number {
      background-color: ${themeSchema.settings.foreground.color} !important;
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .mat-stepper-indicator.mat-completed .mat-stepper-number,
    .mat-stepper-indicator.mat-active .mat-stepper-number,
    .mat-step-header .mat-step-icon,
    .mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step .mdl-stepper-circle,
    .mdl-stepper-horizontal-alternative .mdl-stepper-step.available-as-next .mdl-stepper-circle {
      background-color: ${themeSchema.settings.accent.color} !important;
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }
    .mdl-stepper-horizontal-alternative .mdl-stepper-optional {
      color: ${themeSchema.settings.disabled.color} !important;
    }
    .mdl-stepper-title.flow-title {
       color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .mat-stepper-horizontal, .mat-stepper-vertical {
      background-color: none !important;
    }
    .mat-stepper-icon mat-icon {
      font-size: 16px;
      line-height: 1.5em;
    }
    .mat-stepper-title, .mat-stepper-optional {
      color: ${themeSchema.settings.background.contrastColor} !important;
      background-color: ${themeSchema.settings.background.color} !important;
    }
    .mat-stepper-indicator-wrapper {
      background-color: ${themeSchema.settings.background.color} !important;
    }

    .matl-stepper-optional {
      color: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.4
      )} !important;
    }

    .mat-ink-bar {
      background-color: ${themeSchema.settings.primary.color} !important;
    }

    .active-underscore .mat-tab-group.mat-accent .mat-ink-bar{
      background-color: ${
        themeSchema.settings.primary.contrastColor
      } !important;
    }

    accordion-heading .mat-toolbar.srm-reports-heading,
    accordion-heading .mat-toolbar.dictionary-heading,
    accordion-heading .mat-toolbar.notes-heading,
    .order-service-heading,
    .srm-pivot-settings-heading,
    .order-service-heading .material-icons,
    /*mat-expansion-panel-header,
    .mat-expansion-indicator::after {
      color: ${themeSchema.settings.primaryLight.contrastColor} !important;
      background-color: ${themeSchema.settings.primaryLight.color} !important;
    }*/
    accordion-heading .mat-toolbar.dictionary-heading,
    accordion-heading .mat-toolbar.notes-heading
     {
      border-bottom: dotted 1px ${
        themeSchema.settings.primaryLight.contrastColor
      } !important;
    }
    .ap-config-card {
       background-color: ${themeSchema.settings.foreground.color} !important;
    }
    .dx-datagrid-group-closed {
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }
    .dx-datagrid-rowsview .dx-selection.dx-row > td,
    .dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
      background-color: ${themeSchema.settings.background.color} !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
    }

    .master-detail .dx-datagrid-group-closed,
    .master-detail-arrow-color .dx-datagrid-group-closed {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .theme-list .theme.selected::after {
      background-color: ${themeSchema.settings.primary.color} !important;
    }
    .dxc-val-constant-lines path {
      stroke: ${themeSchema.settings.danger.color} !important;
    }
    .icon-warning {
      color: ${themeSchema.settings.warning.color} !important;
    }
    .icon-danger, .error-class, .ca-select-hint,  .red-message {
      color: ${themeSchema.settings.danger.color} !important;
    }
    .icon-accent{
      color: ${themeSchema.settings.accent.color} !important;
    }
    .mat-progress-spinner path, .mat-progress-circle path, .mat-spinner path {
      stroke: ${themeSchema.settings.accent.color} !important;
    }
    .site-navigation nav a.open {
      //background-color: ${themeSchema.settings.primaryDark.color} !important;
      //color: ${themeSchema.settings.primaryDark.contrastColor} !important;
    }
    .site-navigation nav a.active-link:hover,.site-navigation nav a.open:hover,
    .site-navigation nav a:hover {
      background-color: ${themeSchema.settings.primaryDark.color} !important;
      color: ${themeSchema.settings.primaryDark.contrastColor} !important;
    }

    .site-navigation nav button.active-link, .site-navigation nav button.active-link:focus, .site-navigation .mat-button:not([disabled]):focus {
      background: ${this.hexToRGB(
        themeSchema.settings.primaryLight.contrastColor,
        0.05
      )} !important;
    }

    .site-navigation nav button.highlight-icon .menu-icon {
      color: ${themeSchema.settings.accent.color} !important;
    }

    /*.site-navigation nav a.highlight-item .menu-icon, .site-navigation nav a:link.active-link .menu-icon,
     .site-navigation nav a.last-clicked .menu-icon {
      color: ${themeSchema.settings.accent.color} !important;
    }*/
    /*.site-navigation nav .submenu.active-link {
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }*/
    .site-navigation nav a.submenu:hover {
      background-color: unset !important;
      color: ${themeSchema.settings.primaryLight.contrastColor} !important;
    }

    .site-navigation .mat-button:not([disabled]):hover {
      background: ${this.hexToRGB(
        themeSchema.settings.primaryLight.contrastColor,
        0.15
      )} !important;
    }

    .banner-danger {
      background-color: ${this.hexToRGB(
        themeSchema.settings.danger.color,
        0.2
      )} !important;
    }
    .banner-warning {
      background-color: ${this.hexToRGB(
      themeSchema.settings.warning.color,
      0.2
    )} !important;
    }

    .dashboard-gridster {
      background-color: ${themeSchema.settings.foreground.color} !important;
    }
    .dashboard-gridster > .gridster-grid .row
    {
      border-top: 1px solid ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.12
      )} !important;
      border-bottom: 1px solid ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.12
      )} !important;
    }
    .dashboard-gridster > .gridster-grid .column
    {
      border-left: 1px solid ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.12
      )} !important;
      border-right: 1px solid ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.12
      )} !important;
    }
    .widget-panel .widget-header {
      background-color: ${themeSchema.settings.foreground.color};
    }
    .widget-panel {
      border: 1px solid ${this.hexToRGB(
        themeSchema.settings.primary.color,
        0.2
      )} !important;
    }
        mat-tab-group.mat-accent.mat-tab-group,
    .mat-dash-toolbar,
    .ca-tab.tabbed-content .tabbed-navigation-container, .ca-dash {
      background-color: ${themeSchema.settings.primary.color} !important;
      color: ${themeSchema.settings.primary.contrastColor} !important;
    }
    .mat-dash-toolbar .active-underscore .mat-tab-group.mat-accent .mat-ink-bar,
    .mat-dash-toolbar .active-underscore .mat-tab-nav-bar.mat-accent .mat-ink-bar {
        background-color: ${
          themeSchema.settings.primary.contrastColor
        } !important;
    }
    mat-tab-group.mat-accent.mat-tab-group .mat-tab-link,
    mat-tab-group.mat-accent.mat-tab-group .mat-tab-label
    {
      color: ${themeSchema.settings.primary.contrastColor} !important;
    }
    .mat-radio-checked .mat-radio-inner-circle {
      background-color: ${themeSchema.settings.accent.color} !important;
    }
    .mat-radio-checked .mat-radio-outer-circle {
      border-color: ${themeSchema.settings.accent.color} !important;
    }
    .mat-checkbox-checkmark-path {
      stroke: ${themeSchema.settings.accent.contrastColor} !important;
    }
    .app-search-input .mat-input-underline {
      border-color: ${
        themeSchema.settings.primaryDark.contrastColor
      } !important;
    }
    .app-search-input .mat-input-underline .mat-input-ripple {
      background-color: ${
        themeSchema.settings.primaryDark.contrastColor
      } !important;
    }
    .app-search-input input {
      caret-color: ${themeSchema.settings.primary.contrastColor} !important;
    }
    .ca-form.app-search-input .mat-input-underline {
      background-color: ${
        themeSchema.settings.primary.contrastColor
      } !important;
    }

    /* Vis Timeline styles */

    ${pallete ? this.getVisTimelineColors(pallete) : ''}

     /* *** */

     .selected-cell {
      background-color: ${themeSchema.settings.accent.color} !important;
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }
    mat-select.mat-select.mat-primary {
      background-color: transparent !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    mat-select.mat-select.mat-primary .mat-select-trigger {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    mat-select.mat-select.mat-primary .mat-select-arrow {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .mat-dialog-container .ng-invalid.ng-touched.mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-underline,
    .mat-dialog-container .ng-invalid.ng-touched > .mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-underline {
      background-color: ${themeSchema.settings.danger.color} !important;
      color: ${themeSchema.settings.danger.color} !important;
    }
    .mat-dialog-container mat-select.ng-invalid.ng-touched .mat-select-underline,
    .mat-dialog-container .ng-invalid.ng-touched > mat-select .mat-select-underline {
      background-color: ${themeSchema.settings.danger.color} !important;
    }
    .mat-dialog-container mat-select.ng-invalid.ng-touched .mat-select-arrow,
    .mat-dialog-container .ng-touched.ng-invalid > mat-select .mat-select-arrow,
    .ca-form .mat-hint  {
      color: ${themeSchema.settings.danger.color} !important;
    }
    .ca-card-filter mat-select.mat-select.mat-primary .mat-select-arrow,
    .ca-card-filter .mat-input-placeholder {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }

    .ca-form .mat-select-underline,
    .page-size-select .mat-select-underline,
    .mat-dialog-container .mat-select-underline {
      background-color: ${
        themeSchema.settings.background.contrastColor
      } !important;
    }

    .mat-form-field-disabled .mat-form-field-underline,
    .mat-select-disabled .mat-select-underline {
      background-color: transparent !important;
      background-image: linear-gradient(to right, ${
        themeSchema.settings.background.contrastColor
      } 0%, ${
      themeSchema.settings.background.contrastColor
    } 33%, transparent 0%);
    }

    mat-slide-toggle.mat-accent {
      background-color: transparent !important;
    }
    nav.mat-tab-nav-bar.mat-primary {
      background-color: transparent !important;
    }
    mat-spinner.loader {
      background-color: transparent !important;
    }
    .mat-radio-button.mat-accent {
      background-color: transparent !important;
    }
    mat-tab-group.mat-tab-group {
      background-color: transparent !important;
    }
    .mat-dialog-container .mat-tab-label {
      opacity: 1;
      color: ${themeSchema.settings.primary.color} !important;
    }
    .mat-tab-link, .mat-tab-label {
      color: ${themeSchema.settings.primary.color} !important;
    }
    .mat-tab-link:focus {
      background-color: transparent !important;
    }
    .mat-dialog-container .modal-content-dialog {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .ca-address-container, .building-address-container, .ca-demarc-form{
      background-color: ${themeSchema.settings.foreground.color} !important;
    }
    ca-notes {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .custom-theme-title {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .ca-theme-manage-form h5, .ca-theme-manage-form h4 {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    a.dispute-active {
      color: ${themeSchema.settings.danger.color} !important;
    }
    .batch_active {
      color: ${themeSchema.settings.success.color} !important;
    }
    .batch_inactive {
      color: ${themeSchema.settings.danger.color} !important;
    }
    .batch_file {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    /*settings for require fields*/
    .mat-focused .mat-form-field-placeholder.mat-form-field-float .mat-form-field-required-marker{
      color: unset;
    }
    .ng-invalid .ng-touched .mat-select-required .mat-select-placeholder::after{
      color: ${themeSchema.settings.danger.color} !important;
    }
    /*end require fileds settings*/
    .batch_file:hover, .batch_file:focus {
      color: ${themeSchema.settings.background.contrastColor} !important;
      background-color: ${themeSchema.settings.background.color} !important;
    }
    .ng2-tag-input__text-input {
      background-color: transparent;
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .mat-dialog-container table.table.table-stripped {
      background-color: transparent !important;
    }

    .mat-checkbox-disabled.mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-disabled .mat-slide-toggle-thumb, .mat-checkbox-disabled .mat-checkbox-background,
    .mat-checkbox-disabled .mat-checkbox-background .mat-checkbox-checked {
      background-color: ${themeSchema.settings.disabled.color} !important;
    }
    .mat-icon-button[disabled] {
      color: ${themeSchema.settings.disabled.color} !important;
    }
   .mat-checkbox-disabled.mat-checkbox-checked.mat-accent .mat-checkbox-frame {
      border-color: ${this.hexToRGB(
        themeSchema.settings.disabled.color,
        0.5
      )} !important;
      border-width: 0px;
    }
    .mat-disabled .mat-slide-toggle-bar {
      background-color: ${this.hexToRGB(
        themeSchema.settings.disabled.color,
        0.4
      )} !important;
    }
    .mat-radio-disabled .mat-radio-outer-circle {
      border-color: ${themeSchema.settings.disabled.color} !important;
    }
    .mat-radio-disabled .mat-radio-inner-circle {
      background-color: ${themeSchema.settings.disabled.color} !important;
      border-color: ${themeSchema.settings.disabled.color} !important;
    }
    .preview-message-div {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .mat-option {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }

    .mat-option-disabled {
      color: ${themeSchema.settings.disabled.color} !important;
    }

    .mat-select-panel.mat-primary {
      background-color: ${themeSchema.settings.background.color} !important;
    }
    .mat-select-content {
      background-color: ${themeSchema.settings.background.color} !important;
    }
    .mat-drawer-container {
      background-color: ${themeSchema.settings.background.color} !important;
    }
    ca-pager mat-toolbar.mat-toolbar {
      background-color: ${themeSchema.settings.background.color} !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    ca-pager .mat-select-value, ca-pager .mat-select-arrow {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .mat-select-value-text {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .mat-checkbox-label {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .grid-badge.active {
      background-color: ${themeSchema.settings.accent.color} !important;
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }
    .badge-danger {
      background-color: ${themeSchema.settings.danger.color} !important;
      color: ${themeSchema.settings.danger.contrastColor} !important;
    }
    .badge-warning {
      background-color: ${themeSchema.settings.warning.color} !important;
      color: ${themeSchema.settings.warning.contrastColor} !important;
    }
    .badge-success {
      background-color: ${themeSchema.settings.success.color} !important;
      color: ${themeSchema.settings.success.contrastColor} !important;
    }
    .badge-accent {
      background-color: ${themeSchema.settings.accent.color} !important;
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }

    .card-list-entry-bgr,
    .vendor-image-empty {
      background-color: ${themeSchema.settings.background.color} !important;
    }
    .avatar {
      background-color: ${themeSchema.settings.primaryDark.color} !important;
      color: ${themeSchema.settings.primaryDark.contrastColor} !important;
    }
    .title-avatar .avatar {
      background-color: ${
        themeSchema.settings.primaryDark.contrastColor
      } !important;
      color: ${themeSchema.settings.primaryDark.color} !important;
    }
    .topbar {
      background-color: ${themeSchema.settings.primaryDark.color} !important;
      color: ${themeSchema.settings.primaryDark.contrastColor} !important;
    }
    .history-item {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .history .history-item .history-entry-user, .history .history-item .history-entry-item {
      color: ${themeSchema.settings.accent.color} !important;
    }
    .a-link-tag{
       color: ${themeSchema.settings.accent.color} !important;
    }

    .navigation-item-disabled {
    color: ${this.hexToRGB(
      themeSchema.settings.primaryLight.contrastColor,
      0.38
    )} !important;
    }
    .history-compare-static-row,
    .history-compare-row:hover,
    .history-compare-static-row:hover {
      background-color: ${this.hexToRGB(
        themeSchema.settings.foreground.color,
        0.4
      )} !important;
    }

    .info {
      background-color: ${this.hexToRGB(
        themeSchema.settings.accent.color,
        0.3
      )} !important;
    }

    .charges-add-note {
      color: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.5
      )} !important;
    }

    .or {
      color: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.5
      )} !important;
    }

    .mat-hint {
      color: ${themeSchema.settings.danger.color} !important;
    }

    .ca-user-menu {
      background-color: ${themeSchema.settings.background.color} !important;
    }
    .ca-user-menu .mat-menu-item, .ca-user-menu .mat-menu-item .mat-icon {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .ca-user-menu .mat-menu-item[disabled], .ca-user-menu .mat-menu-item[disabled] .mat-icon {
      color: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.38
      )} !important;
    }
    .mat-menu-panel {
      background-color: ${themeSchema.settings.background.color} !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .audit-nav .submenu-item.menu-item-focused {
      background-color: #eaeaea !important;
    }
    .mat-menu-item {
      color: inherit;
    }
    .ca-identifier-label {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .mat-input-placeholder {
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .btn-gl-method-static {
        background-color: ${themeSchema.settings.primary.color} !important;
        color: ${themeSchema.settings.primary.contrastColor} !important;
    }
    .btn-gl-method-dynamic {
        background-color: ${themeSchema.settings.accent.color} !important;
        color: ${themeSchema.settings.accent.contrastColor} !important;
    }
    .ca-no-drag {
      background-color: ${themeSchema.settings.foreground.color} !important;
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .ca-drag-over {
      background-color: ${themeSchema.settings.accent.color} !important;
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }
    .note-card {
      background-color: ${themeSchema.settings.foreground.color} !important;
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    button.on mat-icon, button.active mat-icon, a.active mat-icon {
      color: ${themeSchema.settings.accent.color} !important;
    }

    .ca-address-search-input[data-ca-required="true"] + .mat-input-placeholder-wrapper label.mat-input-placeholder::after {
        content: ' *';
    }

    mat-datepicker-content, mat-calendar {
      background-color: ${themeSchema.settings.background.color} !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
    }
    button.on mat-icon, button.active mat-icon, a.active mat-icon {
      color: ${themeSchema.settings.accent.color} !important;
    }

     .notification-list .notification-entry:not(.unread) .notification-entry-title,
      .notification-list .notification-entry:not(.unread) .notification-entry-date {
        color: ${themeSchema.settings.disabled.color} !important;
     }
     .notification-list .notification-entry.unread {
        color: ${themeSchema.settings.background.fontColor} !important;
     }
    .mat-slider-track-fill, .mat-slider-thumb {
      background-color: ${themeSchema.settings.accent.color} !important;
    }
    .mat-slider {
      background-color: unset !important;
      color: unset !important;
    }
    .mat-slider-thumb-label, .mat-slider-thumb-label-text {
      background-color: ${themeSchema.settings.accent.color} !important;
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }
    .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb {
      background-color: ${themeSchema.settings.primaryLight.color} !important;
    }
    .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label,
    .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label span {
      background-color: ${themeSchema.settings.primaryLight.color} !important;
      color: ${themeSchema.settings.primaryLight.contrastColor} !important;
    }

    .dx-filterbuilder-text.dx-filterbuilder-group-operation {
      background-color: ${themeSchema.settings.primaryLight.color} !important;
      color: ${themeSchema.settings.primaryLight.contrastColor} !important;
    }

    .dx-filterbuilder-text.dx-filterbuilder-item-field {
      background-color: ${themeSchema.settings.primaryLight.color} !important;
      color: ${themeSchema.settings.primaryLight.contrastColor} !important;
    }

    .dx-filterbuilder-text.dx-filterbuilder-item-operation {
      background-color: ${themeSchema.settings.primaryLight.color} !important;
      color: ${themeSchema.settings.primaryLight.contrastColor} !important;
    }

    .dx-filterbuilder-item-value-text {
      background-color: ${themeSchema.settings.primaryLight.color} !important;
      color: ${themeSchema.settings.primaryLight.contrastColor} !important;
    }
    .dx-filterbuilder .dx-filterbuilder-group .dx-icon-plus {
      color: ${themeSchema.settings.primaryDark.color} !important;
    }
    .dx-filterbuilder .dx-filterbuilder-group .dx-icon-plus:hover {
      color: ${themeSchema.settings.primaryDark.color} !important;
    }

    .dx-filterbuilder .dx-filterbuilder-group .dx-icon-remove {
      color: ${themeSchema.settings.primaryDark.color} !important;
    }

    .dx-filterbuilder .dx-filterbuilder-group .dx-icon-remove:hover {
      color: ${themeSchema.settings.primaryDark.color} !important;
    }
    /* Get input underline styles */

    ${this.getInputUnderlineStyles(themeSchema.settings)}

    /*  */

    .mat-calendar-arrow {
      border-top-color: inherit !important;
    }

    .mat-calendar-table-header,
    .mat-calendar-body-label,
    .mat-calendar-body-cell-content {
      color: inherit !important;
    }

    .mat-calendar-body-today:not(.mat-calendar-body-selected) {
      border-color: ${themeSchema.settings.foreground.contrastColor} !important;
    }

    .mat-calendar-body-selected {
      border-color: ${themeSchema.settings.accent.color} !important;
      background-color: ${themeSchema.settings.accent.color} !important;
      color: ${themeSchema.settings.accent.contrastColor} !important;
    }

    .mat-calendar-body-today.mat-calendar-body-selected {
      box-shadow: inset 0 0 0 1px ${
        themeSchema.settings.foreground.contrastColor
      } !important;
    }

    :not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
    .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      background-color: ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.04
      )} !important;
    }

    .gl-execution-rules {
      background-color: ${themeSchema.settings.background.color} !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
    }

    .custom-field-not-unique {
        color: ${themeSchema.settings.danger.color} !important;
    }
    .tag-item.selected {
        background-color: ${this.hexToRGB(
          themeSchema.settings.foreground.color
        )} !important;
    }
    .ca-picker-search-container .mat-input-wrapper,
    .ca-picker-search-container .mat-form-field-infix {
        border-bottom: 1px solid ${
          themeSchema.settings.warning.contrastColor
        } !important;
    }
    .clear-option.mat-button {
        color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .mat-stepper-indicator.mat-error .mat-stepper-title, .mat-stepper-indicator.mat-error .mat-stepper-icon {
        color: ${themeSchema.settings.danger.color} !important;
    }

    .mat-stepper-indicator.mat-success .mat-stepper-title, .mat-stepper-indicator.mat-success .mat-stepper-icon {
        color: ${themeSchema.settings.success.color} !important;
    }
    .mat-steppers-horizontal .mat-stepper-indicator:after {
        background-color: ${
          themeSchema.settings.warning.contrastColor
        } !important;
    }
    .matl-stepper-horizontal-alternative .matl-stepper-step .matl-stepper-bar-left,
    .matl-stepper-horizontal-alternative .matl-stepper-step .matl-stepper-bar-right {
        border-color: ${themeSchema.settings.warning.contrastColor} !important;
    }
    .mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-circle .mdl-stepper-icon .material-icons.undefined-icon {
      color: #ffffff !important;
    }
    .undefined-icon {
        color: ${themeSchema.settings.danger.color} !important;
    }
    .mdl-stepper-icon.step-warning {
      color: ${themeSchema.settings.warning.color} !important;
    }
    .mdl-stepper-icon.step-error,
    .mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-circle .mdl-stepper-icon.step-error .material-icons.undefined-icon {
      color: ${themeSchema.settings.danger.color} !important;
    }
    ca-notes .selected {
      outline-color: ${themeSchema.settings.accent.color} !important;
    }
    .mat-slide-toggle-bar {
      background-color: ${this.hexToRGB(
        themeSchema.settings.disabled.color,
        0.4
      )} !important;
    }
    .mat-slide-toggle:not(.mat-checked):not(.mat-disabled) .mat-slide-toggle-thumb {
      background-color: ${themeSchema.settings.background.color} !important;
      border-color: ${themeSchema.settings.background.contrastColor} !important;
      box-shadow: 0 1px 5px 0 ${themeSchema.settings.background.contrastColor};
    }
    snack-bar-container.error-with-action simple-snack-bar button {
       color: ${themeSchema.settings.danger.color} !important;
    }
    .mat-button:not([disabled]):hover, .mat-button:not([disabled]):focus {
      background-color: ${this.hexToRGB(
        themeSchema.settings.accent.color,
        0.12
      )} !important;
    }
    .dx-datagrid .dx-row-lines > td,
    .dx-datagrid-rowsview,
    .dx-datagrid-headers,
    .dx-datagrid-borders > .dx-datagrid-headers,
    .dx-datagrid-headers .dx-row > td,
    .dx-last-cell {
      border-color: ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.12
      )};
    },
    .dx-datagrid-columns-separator {
      background-color: ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.12
      )} !important;
    }
    .theme-list {
      border-color: ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.12
      )} !important;
    }
    .theme_middle_column {
      border-color: ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.12
      )} !important;
     }
    .color-picker {
      background-color: ${themeSchema.settings.background.color} !important;
      border-color: ${themeSchema.settings.background.contrastColor} !important;
    }
    .theme-chart-left-side {
      border-color: ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.12
      )} !important;
    }

    .theme .entry-container:hover{
      background: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.08
      )};
    }

    .theme.selected .entry-container{
      background: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.16
      )};
    }

    .color-input.mat-form-field-disabled .mat-form-field-flex {
      background: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.08
      )} !important;
    }

    input.ng2-tag-input__text-input::-webkit-input-placeholder {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    input.ng2-tag-input__text-input:-ms-input-placeholder {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    input.ng2-tag-input__text-input::placeholder {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    div::-webkit-scrollbar,
    mat-sidenav::-webkit-scrollbar {
      background: ${this.hexToRGB(
        themeSchema.settings.disabled.color,
        0.2
      )} !important;
    }
    div::-webkit-scrollbar-track,
    mat-sidenav::-webkit-scrollbar-track,
    .dx-scrollable-scrollbar {
        background: ${this.hexToRGB(
          themeSchema.settings.disabled.color,
          0.2
        )} !important;
    }
    div::-webkit-scrollbar-thumb,
    mat-sidenav::-webkit-scrollbar-thumb,
    .dx-scrollable-scroll-content {
      background: ${themeSchema.settings.disabled.color} !important;
      outline: 1px solid ${themeSchema.settings.disabled.color} !important;
    }
    .dx-pivotgrid {
      background-color: ${themeSchema.settings.foreground.color} !important;
    }
    .dx-pivotgrid td:not(.dx-total):not(.dx-area-description-cell) {
      background-color: ${themeSchema.settings.foreground.color} !important;
      color: ${themeSchema.settings.foreground.contrastColor} !important;
      border-color: ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.12
      )} !important;
    }
    .dx-pivotgrid .dx-area-description-cell,
    .dx-pivotgrid .dx-row-total,
    .dx-pivotgrid .dx-total {
      background-color: ${this.hexToRGB(
        themeSchema.settings.background.color,
        1
      )} !important;
      color: ${themeSchema.settings.background.contrastColor} !important;
      border-color: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.12
      )} !important;
    }
    .dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td,
    .dx-pivotgrid .dx-area-data-cell,
    .dx-pivotgrid tr,
    .dx-pivotgrid td {
      border-color: ${this.hexToRGB(
        themeSchema.settings.foreground.contrastColor,
        0.12
      )} !important;
    }
    .dx-pivotgrid .dx-expand-icon-container .dx-expand {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .ca-second-level-toolbar {
      background-color: ${themeSchema.settings.foreground.color} !important;
    }

    app-network-hub-manage-dialog .accordion-title-container {
      color: ${themeSchema.settings.accent.color} !important;
    }
    .dx-treeview .dx-treeview-node:not(.dx-treeview-item-with-checkbox).dx-state-focused > .dx-treeview-item,
    .dx-treeview-node .dx-state-selected > .dx-treeview-item,
    .dx-treeview-node.dx-state-selected > .dx-treeview-item {
      background-color: ${themeSchema.settings.accent.color} !important;
    }
    .dx-treeview .dx-treeview-node:not(.dx-treeview-item-with-checkbox).dx-state-focused > .dx-treeview-item * {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .available-hub-slot {
      background-color: ${themeSchema.settings.disabled.color} !important;
    }

    .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      color: ${themeSchema.settings.disabled.color} !important;
    }

    .mat-expansion-panel {
      background-color: ${themeSchema.settings.foreground.color} !important;
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }

    .dictionary-accordion {
      background-color: ${themeSchema.settings.background.color} !important;
    }

    .ca-order-service-accordion .mat-stepper-indicator-wrapper,
    .ca-order-service-accordion .mat-stepper-title {
      background-color: transparent !important;
    }

    .mat-accordion .mat-list .mat-list-item,
    .mat-expansion-indicator::after {
      color: ${themeSchema.settings.foreground.contrastColor} !important;
    }
    .mat-list-base .mat-list-item .mat-list-item-content {
      color: ${themeSchema.settings.background.contrastColor}
    }

    .ca-file-name-taken-error-text > i {
      color: ${themeSchema.settings.danger.color} !important;
    }
    .mat-optgroup-label {
      background-color: ${themeSchema.settings.primaryLight.color} !important;
      color: ${themeSchema.settings.primaryLight.contrastColor} !important;
    }
    .horizontal-divider {
      background-color: ${this.hexToRGB(
        themeSchema.settings.background.contrastColor,
        0.2
      )} !important;
    }

    .filter-remove-area-bg{
          background: ${this.hexToRGB(
            themeSchema.settings.background.color,
            1
          )} !important;
    }

.filter-remove-area {
  background: ${this.hexToRGB(
    themeSchema.settings.foreground.color,
    0.12
  )} !important;
  border: 1px ${this.hexToRGB(themeSchema.settings.disabled.color, 1)} dashed;
}
.gl-string-pair:hover {
        background-color: ${this.hexToRGB(
      themeSchema.settings.primaryLight.contrastColor,
      0.05
    )} !important;
}

.ca-strings-container{
  background-color: ${this.hexToRGB(
      themeSchema.settings.background.color)} !important;
}
.divider {
      color: ${themeSchema.settings.primary.color} !important;
    }

.filter-remove-area ca-icon{
  color: ${this.hexToRGB(themeSchema.settings.disabled.color, 1)} !important;
}
.filter-remove-area.dnd-drag-over {
    border: 1px ${this.hexToRGB(themeSchema.settings.accent.color, 1)} dashed;
    background: ${this.hexToRGB(
      themeSchema.settings.accent.color,
      0.2
    )} !important;
  }
  .filters-trash.dnd-drag-over {
    border: 1px ${this.hexToRGB(themeSchema.settings.accent.color, 1)} dashed;
    background: ${this.hexToRGB(
      themeSchema.settings.accent.color,
      0.2
    )} !important;
  }

  .filter-remove-area.dnd-drag-over ca-icon{
    color: ${this.hexToRGB(themeSchema.settings.accent.color, 1)} !important;
  }
  .filters-trash.dnd-drag-over ca-icon{
    color: ${this.hexToRGB(themeSchema.settings.accent.color, 1)} !important;
  }
  .mat-checked .mat-ripple-element {
    background-color: ${this.hexToRGB(
      themeSchema.settings.accent.color,
      0.1
    )} !important;
  }
  .mat-disabled .mat-slide-toggle-bar {
    background-color: ${this.hexToRGB(
      themeSchema.settings.disabled.color,
      0.4
    )} !important;
  }
  .mat-disabled .mat-slide-toggle-thumb {
    background-color: ${this.LightenDarkenColor(
      this.hexToRGB(themeSchema.settings.disabled.color, 1),
      -5
    )} !important;
  }
  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: ${themeSchema.settings.accent.color} !important;
  }
      .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: ${this.hexToRGB(
      themeSchema.settings.accent.color,
      0.4
    )} !important;
  }

  .mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-right,
  .mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-left,
  .mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-circle {
    background-color: ${this.hexToRGB(
      themeSchema.settings.disabled.color,
      1
    )} !important;
  }

  .grid-link-style {
    text-decoration: underline !important;
    color: ${themeSchema.settings.accent.color};
    cursor: pointer !important;
  }
    `;

    if (customThemeStyleTag) {
      customThemeStyleTag.innerText = dynCss;
    } else {
      let head = document.head;
      let style = document.createElement("style");
      style.setAttribute("name", "custom-theme-style");

      style.innerText = dynCss;
      head.appendChild(style);
    }
  }

  // TODO: Refactor this when enough time
  LightenDarkenColor(col, amt) {
    let usePound = false;

    if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }

    let num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  hexToRGB = (hex, opacity?) => {
    hex = hex.replace("#", "");

    if (hex.length === 3) {
      hex = [hex[0], hex[0], hex[1], hex[1], hex[2], hex[2]].join("");
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity || 0.4})`;
  };

  calculateColor = (hex, difference) => {
    hex = hex.replace("#", "");

    if (hex.length === 3) {
      hex = [hex[0], hex[0], hex[1], hex[1], hex[2], hex[2]].join("");
    }

    const r = parseInt(hex.substring(0, 2), 16) + difference;
    const g = parseInt(hex.substring(2, 4), 16) + difference;
    const b = parseInt(hex.substring(4, 6), 16) + difference;
    return `rgba(${r}, ${g}, ${b}, 1)`;
  };

  getBorderColors(colors) {
    // TODO: when we decide which theme color to use for borders this is where we'll set it
    return `
    .ca-default-border,
    .ca-default-border-top,
    .ca-default-border-right,
    .ca-default-border-left,
    .ca-default-border-bottom,
    .ca-default-border-color,
    mat-sidenav,
    .ca-table th,
    .ca-table-stripped td,
    mat-toolbar.toolbar-details,
    mat-toolbar.border-top,
    mat-toolbar.border-bottom {
      border-color: ${this.hexToRGB(
        colors.background.contrastColor,
        0.12
      )} !important;
    }
    .site-navigation {
      border-right: 1px solid ${this.hexToRGB(
        colors.background.contrastColor,
        0.12
      )} !important;
    }
    .vertical-divider {
      border-color: ${this.hexToRGB(
        colors.primary.contrastColor,
        0.4
      )} !important;
      color: ${colors.primary.contrastColor} !important;
    }
    `;
  }

  getThemeBackground(colors) {
    return `
      .ca-theme-background,
      .custom-accorion-list-item:hover,
      .ca-table {
        background-color: ${colors.background.color} !important;
        color: ${colors.background.contrastColor} !important;
        }
    `;
  }

  getThemeAccent(colors) {
    return `
      .ca-theme-accent {
        background-color: ${colors.accent.color} !important;
        color: ${colors.accent.contrastColor} !important;
        }
`;
  }

  getInputUnderlineStyles(colors) {
    return `
      .ca-form .mat-form-field.ng-invalid.ng-touched .mat-form-field-underline {
        background-color: ${colors.danger.color} !important;
      }
      .ca-form .mat-form-field.ng-invalid.ng-touched .mat-form-field-ripple {
        background-color: ${colors.danger.color} !important;
      }
      .ca-form .mat-form-field.ng-invalid.ng-touched input,
      .ca-form .mat-input-container.ng-invalid.ng-touched .mat-datepicker-toggle,
      .ca-form .mat-form-field-invalid .mat-form-field-placeholder.mat-form-field-float .mat-form-field-required-marker {
        color: ${colors.danger.color} !important;
      }


      .mat-dialog-container .mat-form-field.ng-invalid.ng-touched .mat-form-field-underline {
        background-color: ${colors.danger.color} !important;
      }
      .mat-dialog-container .mat-form-field.ng-invalid.ng-touched .mat-form-field-ripple {
        background-color: ${colors.danger.color} !important;
      }
      .mat-dialog-container .mat-form-field.ng-invalid.ng-touched input,
      .mat-dialog-container .mat-input-container.ng-invalid.ng-touched .mat-datepicker-toggle,
      .mat-dialog-container .mat-form-field-invalid .mat-form-field-placeholder.mat-form-field-float .mat-form-field-required-marker {
        color: ${colors.danger.color} !important;
      }

      .audit-vendor-picker .mat-form-field.mat-primary {
        background-color: unset !important;
      }

      .mat-dialog-container .mat-form-field.ng-invalid.ng-touched .ca-date-input {
        color: ${colors.background.contrastColor} !important;
      }

      .ca-date-form .mat-form-field.ng-invalid.ng-touched.mat-form-field-should-float .mat-form-field-label {
        color: ${colors.danger.color} !important;
      }

      .ca-filter-card .mat-input-underline,
      .ca-filter-card .mat-select-underline,
      .ca-filter-card .mat-input-ripple,
      .ca-card-filter .mat-select-underline,
      .ca-card-filter .mat-input-underline,
      .ca-card-filter .mat-select-underline,
      .ca-card-filter .mat-input-ripple,
      .ca-card-filter .mat-form-field-underline {
        background-color: ${colors.foreground.contrastColor} !important;
      }
      .ca-card-filter .mat-select-placeholder,
      .ca-card-filter .mat-select-value-text {
        color: ${colors.foreground.contrastColor} !important;
      }

      .ca-card-filter .ca-form tag-input .ng2-tag-input.ng2-tag-input--focused {
        border-bottom-color: ${colors.foreground.contrastColor} !important;
      }

      [cainput].mat-focused .mat-form-field-ripple,  [cainput] .mat-focused .mat-form-field-ripple, [hidensityinput].mat-focused .mat-form-field-ripple,  [hidensityinput] .mat-focused .mat-form-field-ripple{
        background-color: ${colors.accent.color} !important;
      }

      [cainput].mat-focused .mat-form-field-label, [cainput] .mat-focused .mat-form-field-label, [hidensityinput].mat-focused .mat-form-field-label, [hidensityinput] .mat-focused .mat-form-field-label {
        color: ${colors.accent.color} !important;
      }

      [cainput].mat-form-field-invalid .mat-form-field-label, [cainput] .mat-form-field-invalid .mat-form-field-label, [hidensityinput].mat-form-field-invalid .mat-form-field-label, [hidensityinput] .mat-form-field-invalid .mat-form-field-label {
        color: ${colors.danger.color} !important;
      }

      [cainput].ng-pristine .mat-form-field-underline, [cainput] .ng-pristine .mat-form-field-underline, [hidensityinput].ng-pristine .mat-form-field-underline, [hidensityinput] .ng-pristine .mat-form-field-underline {
        background-color: transparent !important;
      }

      [cainput].mat-form-field-wrapper:hover .mat-form-field-underline, [cainput] .mat-form-field-wrapper:hover .mat-form-field-underline, [hidensityinput].mat-form-field-wrapper:hover .mat-form-field-underline, [hidensityinput] .mat-form-field-wrapper:hover .mat-form-field-underline{
        background-color: ${colors.background.contrastColor} !important;
      }

      [cainput].mat-form-field-disabled .mat-form-field-wrapper:hover .mat-form-field-underline, [cainput] .mat-form-field-disabled .mat-form-field-wrapper:hover .mat-form-field-underline, [hidensityinput].mat-form-field-disabled .mat-form-field-wrapper:hover .mat-form-field-underline, [hidensityinput] .mat-form-field-disabled .mat-form-field-wrapper:hover .mat-form-field-underline{
        background-color: transparent !important;
      }

      .ca-address-search-input[data-ca-required="true"] + .mat-input-placeholder-wrapper label.mat-input-placeholder::after {
        content: ' *';
      }

      [cainput].ca-address-input-invalid label.mat-input-placeholder::after, [cainput].ca-address-input-invalid label {
        color: ${colors.danger.color} !important;
      }

      [cainput].ca-address-input-invalid .mat-input-underline,
      [cainput].ca-address-input-invalid .mat-input-ripple {
        background-color: ${colors.danger.color} !important;
      }

      .sign-in .mat-form-field-invalid .mat-form-field-label {
        color: ${colors.danger.color} !important;
      }

      .sign-in .ng-pristine .mat-form-field-underline {
        background-color: transparent !important;
      }

      .mat-step-header .mat-step-label, .mat-step-header .mat-step-optional,
      .mat-horizontal-stepper-header,
      .mat-step-header .mat-step-label.mat-step-label-active {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
      }

      .mat-stepper-horizontal, .mat-stepper-vertical {
        background-color: none !important;
      }
  }
    `;
  }

  getVisTimelineColors(pallete) {
    if (!pallete) return;
    return `
    .vis-timeline {
      background-color: ${pallete.settings.background} !important;
      color: ${pallete.settings.font_color} !important;
    }
    .vis-line,
    .vis-dot,
    .vis-group,
    .vis-timeline,
    .vis-panel.vis-bottom,
    .vis-panel.vis-center,
    .vis-panel.vis-left,
    .vis-panel.vis-right,
    .vis-panel.vis-top,
    .vis-labelset .vis-label,
    .vis-foreground .vis-group,
    .vis-item,
    .vis-time-axis .vis-grid.vis-minor,
    .vis-time-axis .vis-grid.vis-major{
      border-color: ${pallete.settings.font_color} !important;
    }
    .vis-item-content {
      color: ${pallete.settings.inner_font} !important;
    }
    `;
  }
}
