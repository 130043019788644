import { Component, EventEmitter, Input, Output } from '@angular/core';

import { VendorRemitAddress } from '../../core/vendor-remit-address';

@Component({
  selector: 'ca-vendor-remit-address-sneak-peek',
  templateUrl: './vendor-remit-address-sneak-peek.component.html',
})
export class VendorRemitAddressSneakPeekComponent {
  @Input() vendorRemitAddress: VendorRemitAddress;

  @Output() closeRequested = new EventEmitter();
  @Output() deleteRequested = new EventEmitter();

  constructor() { }

  deleteVendorRemitAddress() {
    this.deleteRequested.emit(this.vendorRemitAddress);
  }

  closeSide() {
    this.closeRequested.emit();
  }
}
