import {Component, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DialogService} from "../shared/dialog/dialog.service";
import {ThemeManageComponent} from "../theme/shared/theme-manage/theme-manage.component";
import {NavigationEnd, Router} from "@angular/router";
import {UserService} from "../user/core/user.service";
import {User} from "../user/core/user";
import {PageInfoComponent} from '../shared/page-info/page-info.component';
import {AppService} from "../app.service";
import {CustomThemeService} from "../theme/core/custom-theme.service";
import {ThemeService} from "../theme/core/theme.service";
import {NavStateService} from "./nav-state.service";
import {ChartService} from "../shared/chart/chart.service";
import {ApiService} from "../core/api";

import {ConfigService} from "../core/config/config.service";
import {Config} from "../core/config/config";
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import {PermissionService} from "../permissions/core/permission.service";

@Component({
  selector: 'app-dash',
  templateUrl: 'master.component.html',
  styleUrls: ['master.component.css']
})
export class MasterComponent implements OnInit {

  readonly EMPTY_THEME_NAME = 'empty';

  @ViewChild('container') container;
  @ViewChild('navigation') navigation;
  @ViewChild('perfectScroll') perfectScroll: PerfectScrollbarComponent;

  navigationModeMini: boolean = false;
  themeUrl: SafeResourceUrl;
  currentTheme: string = this.EMPTY_THEME_NAME;
  me: User;
  navigationOpened: boolean = false;
  miniNavigationHovered: boolean = false;
  onlineFlag = navigator.onLine;
  dashEnabled: boolean = true;
  isDashEdit: boolean;
  isReportPermissionAvailable = false;

  public resultConfiguration: any;
  public support_url_config: string;

  constructor(public sanitizer: DomSanitizer,
              public router: Router,
              public dialog: DialogService,
              public themeService: ThemeService,
              public chartService: ChartService,
              public appService: AppService,
              public customThemeService: CustomThemeService,
              public navStateService: NavStateService,
              public apiService: ApiService,
              public userService: UserService,
              public configService: ConfigService,
              private permissionService: PermissionService) {


    window.addEventListener('online', () => {
      this.onlineFlag = true
    });
    window.addEventListener('offline', () => {
      this.onlineFlag = false
    });


    this.appService.subscribe((data) => {
      if (data.type === "user") {
        let user = data.object;
        if (user.id === this.me.id) {
          this.me = user;
        }
      }
    })

  }

  dashEdit() {
    this.isDashEdit = !this.isDashEdit;
    this.appService.emit("DASH:EDIT", {
      enabled: this.isDashEdit
    })
  }

  update() {
    this.perfectScroll.directiveRef.update();
  }

  ngOnInit() {

    this.apiService.clearAllCache();

    if (this.currentTheme !== this.EMPTY_THEME_NAME) {
      this.themeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.buildUrl(this.currentTheme));
    }

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isDashEdit = false;
        this.appService.emit("DASH:EDIT", {
          enabled: this.isDashEdit
        })
      }
    });

    this.permissionService.reportPermission().subscribe(permission => {
      this.isReportPermissionAvailable = !permission;
    })

    this.userService.settings()
      .subscribe(
        (result) => {
          const selTheme = result[this.themeService.selectedKey];
          const selChart = result[this.chartService.selectedKey];

          ThemeService.setActiveTheme(this.themeService.getActiveTheme(selTheme, result));
          ChartService.setActiveChartTheme(this.chartService.getActiveChartTheme(selChart, result));

          this.customThemeService.generateCustomTheme(ThemeService.activeTheme, ChartService.activeChartTheme);
        }
      );

    this.userService.me()
      .subscribe((user: User) => {
        this.me = user;
        localStorage.setItem('date_format', user.country.date_format);
        localStorage.setItem('date_time_format', user.country.date_time_format);

        localStorage.setItem('__currencyDisplay', JSON.stringify(user.country.currency_display));
        localStorage.setItem('__currDigit', JSON.stringify(user.country.curr_num_digit));
        localStorage.setItem('__currLocale', JSON.stringify(user.country.curr_num_locale));
        localStorage.setItem('__negativeValue', JSON.stringify(user.country.negative_value));
        this.navigationOpened = true;
      });

    this.configService.findAll().subscribe( (config: Config) => {
      this.support_url_config = config.application_info.support_url;
    });

  }

  public buildUrl(theme: string): string {
    return `assets/themes/${theme}.css`;
  }

  profile() {

    if (this.me) {
      /* Subscriber in SearchResultsService - GPX-2401 */
      this.appService.emit('USER:PROFILE', {user: this.me});
    }
  }

  support() {
    let supportTab = window.open(this.support_url_config, '_blank');
    supportTab.focus();
  }

  customize() {
    this.dialog.open(ThemeManageComponent, {
      theme: Object.assign({}, this.currentTheme)
    }, {
      width: '996px'
    })
      .afterClosed()
      .subscribe((theme) => {

        if (!theme) {
          return;
        }

        this.refreshChartColors(location.pathname);

        this.currentTheme = Object.assign({},theme);
      });
  }

  refreshChartColors(urlString: string) {

    const urlsWithCharts: Array<string> = [
      '/dash',
      '/srm',
      '/account',
      '\/account\/[0-9]+\/show',
      '\/report-gallery\/[0-9]+\/show'
    ];

    const pageWillRefresh = urlsWithCharts.filter(item => urlString.match(item)).length;

    if (pageWillRefresh) {
      location.reload();
    }

  }

  info() {
    this.dialog.open(PageInfoComponent, {}, {width: '400px'});
  }

  signOut() {
    localStorage.removeItem('__localeId');
    localStorage.removeItem('__countries');
    localStorage.removeItem('__currencyDisplay');
    localStorage.removeItem('__currDigit');
    localStorage.removeItem('__currLocale');
    localStorage.removeItem('__negativeValue');
    localStorage.removeItem('currency');
    localStorage.removeItem('__numberOfDigits');
    localStorage.removeItem('__currencies');
    this.router.navigate(['/auth/sign-out']);
  }

  toggleNavigation() {
    this.navigationModeMini = !this.navigationModeMini;
    this.miniNavigationHovered = false;
    this.navStateService.setNavState(!this.navigationModeMini);

    //this.navigationMode = this.navigationModeMini ? 'side' : 'over';
  }

  navigationMouseOver() {
    setTimeout(() => {
      this.miniNavigationHovered = this.navigationModeMini;
      if (this.navigationModeMini) {
        this.navStateService.setNavState(true);
      }
    });
  }

  navigationMouseLeave() {
    setTimeout(() => {
      this.miniNavigationHovered = false;
      if (this.navigationModeMini) {
        this.navStateService.setNavState(false);
      }
    });
  }

  getUserInitials(): string {
    return this.userService.getUserInitials(this.me);
  }

  dashEnable() {
    if (this.router.url === '/dash') {
      this.dashEnabled = true;
    } else {
      this.dashEnabled = false;
    }
  }
}
