import { of as observableOf, Subject } from 'rxjs';

import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";
import { NotesComponent } from "app/shared/notes/notes.component";
import { LoaderService } from "app/shared/loader/loader.service";
import { DisputeChargesStaticGridService } from "app/dispute/core/dispute-charges-static-grid.service";
import {
  IMessagesResourceService,
  ResourcesService,
} from "app/core/resources/resources.service";
import Query from "app/core/query/query";
import { GridSettingsComponent } from "app/core/grid/grid-settings.component";
import { DataLockDialogService } from "../../core/data-lock/data-lock-dialog.service";
import { PageDetailsComponent } from "../../core/page-details.component";
import { PageContext } from "../../core/page.context";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { LOOKUP_ENUM } from "../../dictionary/core/lookup.enum";
import { AlertService } from "../../shared/alert/alert.service";
import { DialogService } from "../../shared/dialog/dialog.service";
import { HistoryComponent } from "../../shared/history/history.component";
import { TabGroup, TabService } from "../../shared/tabs/tab.service";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { Dispute } from "../core/dispute";
import { DisputeChargeQuery } from "../core/dispute-charge-query";
import { DisputeService } from "../core/dispute.service";
import { DisputeManageDialogComponent } from "../shared/dispute-manage-dialog/dispute-manage-dialog.component";
import { DisputeCsvInterceptor } from "../core/dispute-csv-interceptor";
import { DocumentService } from "../../shared/document/core/document.service";
import { throwError as observableThrowError } from "rxjs/internal/observable/throwError";
import { NewFilterComponent } from "app/core/filter/new-filter-component/new-filter.component";
import { DisputeChargeFilterService } from "../core/dispute-charge-filter.service";
import {
  SortingBuilder,
  SortingService,
} from "app/shared/sorting/sorting.service";
import { DisputeStatusLookup } from "../core/dispute-status.lookup";
import { DisputeWithheldStatusLookup } from "../core/dispute-witheld-status.lookup";
import { LookupModel } from "../../dictionary/core/lookup.model";
import { INVOICE_STATUS_ENUM } from "../../invoice/core/invoice-status.enum";
import { RevertWithheldManageDialogComponent } from "../shared/revert-withheld-manage-dialog/revert-withheld-manage-dialog.component";
import { CommonAlertService } from "../../common-alert/core/common-alert.service";
import { InvoiceFacepageService } from "app/invoice/core/invoice-facepage.service";
import { DeleteDisputeChargesManageDialog } from "../shared/dispute-delete-charges-manage-dialog/dispute-delete-charges-manage-dialog.component";

@Component({
  selector: 'app-dispute-details',
  templateUrl: './dispute-details.component.html',
  styleUrls: ['./dispute-details.component.scss']
})
export class DisputeDetailsComponent extends PageDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chargesGrid') chargesGrid: DxDataGridComponent;
  @ViewChild('panelMore') panelMore;
  @ViewChild('panelMoreTabs') panelMoreTabs;
  @ViewChild('history') history: HistoryComponent;
  @ViewChild('notesComponent') notesComponent: NotesComponent;
  @ViewChild('documentsManager') documentsManager;
  @ViewChild('appFilter') appFilter: NewFilterComponent;

  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  DOCUMENT_INFO = {
    FOLDER: 'disputes',
    SUBFOLDER: '',
    PARENT_TYPE: this.SYSTEM_MODULE.DISPUTE,
    ENTITY_TYPE: this.SYSTEM_MODULE.DISPUTE
  };

  disputeId: number;
  createNoteAllowed = false;
  public dispute: Dispute;
  public columns: Array<any>;
  public sorting: any[][];
  public disputeCharges: Array<any> = [];
  public paymentWithheld:number = 0;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  activeTabIndex = 0;
  alertId: any;
  public alert: any;
  public invoice_ids: Array<any> = [];
  public charge_ids: Array<any> = [];
  public selection: any;
  public selectedWithheld: boolean = false;

  public DISPUTE_CATEGORY_LOOKUP_MODEL = LOOKUP_MODELS_ENUM.DISPUTE_CATEGORY.modelName;
  readonly DISPUTE_STATUS_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.DISPUTE_STATUS.modelName;
  public ALERT_TYPE_LOOKUP_MODEL = LOOKUP_ENUM.ALERT_TYPE;

  public notesCount = 0;

  selectedCharges = [];
  delaySelectionChange = false;
  filter: any;
  selectedCharge: any;
  chargesSelectionActive = false;
  chargesNote: boolean;
  selectedNoteId: number;
  selectedChargesForNotesComponent = [];

  disputeChargesQuery = new Query({});
  documentQuery = new Query({});
  alertQuery = new Query({});
  notesQuery = new Query({});

  disputeChargeQuery: DisputeChargeQuery = new DisputeChargeQuery({});

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'dispute';
  chargesSorting: SortingBuilder;
  allowChargeReload: boolean = true;

  readonly DISPUTE_STATUS_ENUM = LOOKUP_ENUM.DISPUTE_STATUS;
  readonly DOCUMENT_MANAGER_SETTINGS = {
    DELETE_DOCUMENT_ACTION: this.deleteDocument.bind(this),
    UPLOAD_DOCUMENT_ACTION: this.upload.bind(this),
    DOWNLOAD_DOCUMENT_ACTION: this.download.bind(this)
  }

  disputeStatusLookup: DisputeStatusLookup = new DisputeStatusLookup();
  disputeWithheldStatusLookup: DisputeWithheldStatusLookup = new DisputeWithheldStatusLookup();
  readonly DISPUTE_WITHHELD_STATUS_ENUM = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS;
  readonly DISPUTE_WITHHELD_STATUS_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.DISPUTE_WITHHELD_STATUS.modelName;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public disputeService: DisputeService,
    public documentService: DocumentService,
    public route: ActivatedRoute,
    public router: Router,
    public disputeChargesGridService: DisputeChargesStaticGridService,
    public dialog: DialogService,
    public alertService: AlertService,
    public commonAlertService: CommonAlertService,
    public settingsService: UserSettingsService,
    public tabService: TabService,
    public dictionaryService: DictionaryService,
    public invoiceService: InvoiceFacepageService,
    public loaderService: LoaderService,
    public dataLockDialogService: DataLockDialogService,
    public disputeChargeFilterService: DisputeChargeFilterService,
    private sortingService: SortingService,
  ) {
    super(new PageContext({
      name: 'app.disputes.dispute-details',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.chargesSorting = this.sortingService.builder();
  }

  public disputeCalculations() {
    this.paymentWithheld = 0;
    if(this.dispute.dispute_charges) {
      this.dispute.dispute_charges.forEach((x)=>{
        if(x.dispute_withheld){
          this.paymentWithheld += Number((+x.disputed_amount).toFixed(2))
        }
      });
    }
  }

  getAlert(){
    this.commonAlertService.findById(this.alertId)
      .subscribe((results) => {
        this.alert = results
      })
  }

  public loadData(id: number) {

    this.disputeService.findById(id)
      .subscribe(
        (dispute) => {
          this.dispute = dispute;
          this.alertId = dispute.alert_id

          this.getAlert();

          this.disputeCalculations();

          this.disputeCharges = [];

          this.DOCUMENT_INFO.SUBFOLDER = `${dispute.id}/`;
          this.loadTabs();

          this.loaderService.hideLoaderAndManageGrid([this.chargesGrid]);
        },
        (error) => {
          this.loaderService.hideLoaderAndManageGrid([this.chargesGrid]);
          this.alertService.error('', this.messages.get('GET_DISPUTE_BY_ID_ERROR'));
        });
  }

  /**
   * Charges related
   */
  public loadCharges(disputeId: number, chargeQuery?: any) {
    this.delaySelectionChange = true;
    this.disputeCharges = [];
    this.loaderService.displayLoaderAndManageGrid([this.chargesGrid]);

    this.disputeChargeQuery = chargeQuery || this.disputeChargeQuery;
    this.disputeChargeQuery.set('dispute_id', disputeId);

    this.disputeService.findAllDisputeChargesLargeRequest(disputeId, this.disputeChargeQuery).subscribe(
      result => {
        this.disputeCharges = result.items;
        this.disputeChargeQuery.total = result.total;

        result.items.map(item => {
          this.charge_ids.push(item.charge.id)
          if(!this.invoice_ids.includes(item.charge.invoice_id)){
            this.invoice_ids.push(item.charge.invoice_id)
          }
        })
        this.loaderService.hideLoaderAndManageGrid([this.chargesGrid]);
        if (this.disputeCharges.length) {
          setTimeout(() => {
            this.delaySelectionChange = false;
            this.chargesGrid.instance.selectRows(this.selectedCharges, false);
          }, 500);
        }

        // this.selectedChargesForNotesComponent = this.disputeCharges.map(disputeCharge => disputeCharge.charge);
        this.chargesNote = true;
      }
    )
  }
  public onChargesCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.chargesSorting.apply(event, this.disputeChargeQuery);
      this.loadCharges(this.disputeId);
    }
  }

  loadSelected() {
    if (this.disputeChargeQuery && !this.disputeChargeQuery['where'].hasOwnProperty('id')) {
      this.filter = JSON.parse(JSON.stringify(this.disputeChargeQuery['where']));
    }
    if (this.chargesSelectionActive) {
      this.disputeChargeQuery.remove('id');
      this.disputeChargeQuery['where'] = this.filter;
    } else {
      this.disputeChargeQuery['where'] = {};
      this.disputeChargeQuery.set('id', {
        $in: this.selectedCharges.map(x => {
          return x.id;
        })
      });
    }

    this.disputeChargeQuery.offset = 0;
    this.disputeChargeQuery.page = 1;
    this.chargesSelectionActive = !this.chargesSelectionActive;
    this.loadCharges(this.disputeId,this.query);
  }

  findIndexInSelectedRows(id): number {
    for (let i = 0, l = this.selectedCharges.length; i < l; i++) {
      if (this.selectedCharges[i].id === id) {
        return i;
      }
    }
    return -1;
  }
  onSelectionChanged(event) {
    if (!this.delaySelectionChange && this.disputeCharges.length) {
      //** Deselected rows */
      if (event.currentDeselectedRowKeys.length) {
        event.currentDeselectedRowKeys.forEach(item => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index > -1) {
            this.selectedCharges.splice(index, 1);
          }
        });
      }

      //** Selected rows */
      if (event.currentSelectedRowKeys.length) {
        event.currentSelectedRowKeys.forEach(item => {
          let index = this.findIndexInSelectedRows(item.id);
          if (index === -1) {
            this.selectedCharges.push(item);
          }
        });
      }

      if (this.selectedCharges.length > 1) {
        this.selection = null;
      } else {
        this.selection = event.selectedRowsData[0];
      }
      if (this.chargesSelectionActive && !this.selectedCharges.length) {
        this.loadSelected();
      }
      this.selectedWithheld = this.selectedCharges.some(charge => charge.dispute_withheld);
    }

    this.selectedChargesForNotesComponent = this.selectedCharges.map(disputeCharge => disputeCharge.charge);

  }

  revertWithheld() {
    const filteredCharges = this.selectedCharges.filter(charge => charge.dispute_withheld);
    if (this.dispute) {
      this.disputeService.findByIdForEdit(this.dispute.id)
        .subscribe(res => {
          this.dialog.edit(
            RevertWithheldManageDialogComponent,
            {
              withheldCharges: filteredCharges,
              dispute: res,
            }, { width: '60%' })
            .subscribe((items: any) => {
              if (items) {
                let obs = observableOf(items);
                obs.pipe(mergeMap(x => x.afterClosed()))
                  .subscribe((result) => {
                    if (result) {
                      Object.assign(this.dispute, result);
                      this.chargesGrid.instance.deselectAll();
                      this.disputeCharges = this.dispute.dispute_charges;
                      this.disputeChargeQuery.total = this.disputeCharges.length;
                      this.disputeCalculations();
                      this.history.refreshList();
                      this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
                    }
                  })
              }
            })
        })
    }
  }

  ngOnInit() {
    this.loaderService.displayLoaderAndManageGrid([this.chargesGrid]);

    this.tabGroup = this.tabService.create();

    this.dictionaryService.getByLookup(this.DISPUTE_CATEGORY_LOOKUP_MODEL)
      .subscribe((result) => {
        this.disputeChargesGridService.disputeCategories = result.items;
        setTimeout(() => {
          this.columns = this.disputeChargesGridService.columns();
        });
      });
    this.dictionaryService.getByLookup(this.DISPUTE_STATUS_LOOKUP_MODEL)
      .subscribe((result) => {
        this.populateDisputeStatusLookup(result.items)
      });
    this.dictionaryService.getByLookup(
      this.DISPUTE_WITHHELD_STATUS_LOOKUP_MODEL
    ).subscribe((result) => {
      this.populateDisputeWithheldStatusLookup(result.items)
    });

    this.route.params.pipe(
      takeUntil(this.destroy$))
      .subscribe(
        (params: Params) => {
          this.disputeId = params['id'];
          this.loadData(params['id'])
        },
        (error) => {
          console.log('Error getting params', error);
        }
      );

    this.notesQuery.set('invoice_id', {
      $in: this.invoice_ids
    });

    this.notesQuery.set('id', {
      $in: this.charge_ids
    });
  }

  public loadTabs() {
    this.tabGroup.addTab({ key: 1, title: 'Summary' });
    this.tabGroup.addTab({ key: 3, title: 'Charges' });
    this.tabGroup.addTab({ key: 2, title: 'Documents' });
    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe((tab) => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
        this.documentsManager.clearSelection();
      });
    });
  }

  refresh() {
    this.loadCharges(this.disputeId);
  }

  csv() {
    const { disputeChargeQuery, disputeService } = this;

    disputeChargeQuery.where['dispute_id'] = this.disputeId;

    const disputeTransformMiddleware = (items) => {
      const disputeCSVInterceptor = new DisputeCsvInterceptor();
      // items.forEach(item => {
      //   item.charge.invoice.sp_inv_num = this.dispute.invoice.sp_inv_num;
      // });
      return items.map(item => disputeCSVInterceptor.transform(item));
    };

    this.disputeChargesGridService.csvMap().subscribe(fields => {
      this.disputeService.exportToCSV(
        'Dispute Charges',
        {
          fields,
          query: disputeChargeQuery,
          fetchDataHandler: disputeService.filters.bind(disputeService),
          settings: {currencyField: 'currency.currency'},
          middleware: [disputeTransformMiddleware]
        }
      );
    })
  }

  onNoteCreated() {
    this.loadCharges(this.disputeId);
  }

  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }

    if(this.sider.isActive(this.SECTIONS.FILTER_SECTION_NAME)) {
      this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME)
      this.sider.closeComponent();
    }

    if(index === 1) {
      this.allowChargeReload = true;
    }

    if (index !== 1) {
      if(this.allowChargeReload) {
        this.disputeCharges = [];
        this.loaderService.displayLoaderAndManageGrid([this.chargesGrid]);
        this.disputeChargeQuery = new DisputeChargeQuery(
          {
            where: {dispute_id: this.disputeId},
            orderBy: [['charge.id', 'ASC']]
          }
        );
        this.loadCharges(this.disputeId, this.disputeChargeQuery);
        this.allowChargeReload = false;
      }
    }
  }

  ngAfterViewInit(): void {
    this.disputeChargesGridService.create(this.chargesGrid.instance, {
      showBorders: true,
      selection: {
        mode: 'multiple',
        selectAllMode: 'page'
      }
    });

    super.ngAfterViewInit();
  }

  back(): void {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/dispute']);
    }
  }

  edit(): void {
    if (this.dispute) {
      this.disputeService.findByIdForEdit(this.dispute.id)
        .subscribe(res => {
          this.dialog.edit(
            DisputeManageDialogComponent,
            {
              dispute: res,
              disputeChargeList : this.dispute.dispute_charges
            }, { width: '60%' })
            .subscribe((items: any) => {
              if (items) {
                let obs = observableOf(items);
                obs.pipe(mergeMap(x => x.afterClosed()))
                  .subscribe((result) => {
                    if (result) {
                      Object.assign(this.dispute, result);
                      this.disputeCharges = this.dispute.dispute_charges;
                      this.disputeChargeQuery.total = this.disputeCharges.length;
                      this.disputeCalculations();
                      this.history.refreshList();
                      this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
                    }
                  })
              }
            })
        })
    }
  }

  toggleHistory() {
    if (this.panelMoreTabs.selectedIndex !== 1) {
      this.panelMoreTabs.selectedIndex = 1;
      this.panelMore.open();
    } else {
      this.panelMore.toggle();
    }
  }

  toggleNotes() {
    if (this.panelMoreTabs.selectedIndex !== 0) {
      this.panelMoreTabs.selectedIndex = 0;
      this.panelMore.open();
    } else {
      this.panelMore.toggle();
    }
  }

  isDeleteDisabled(selection: any) {
    return !(this.tabGroup.isActive(2) && selection);
  }

  isUploadDisabled(selection: any) {
    const documentsActive = this.tabGroup.isActive(2);
    return !(documentsActive);
  }

  isDownloadDisabled(selection: any) {
    return !(this.tabGroup.isActive(2) && selection);
  }

  docSelected(doc: Document) {
    this.selection = doc;
  }
  onPageDocumentChange(query:Query){
    this.documentQuery = query
    this.documentsManager.fetchDocsForMaster(this.documentQuery)
  }

  upload() {
    this.documentsManager.addEntityDocument(this.documentQuery);
  }

  download() {
    this.documentService.downloadDocument(this.selection.id, this.DOCUMENT_INFO.FOLDER)
      .pipe(map(response => this.extractBlob(response)),
      catchError(error => observableThrowError(error)))
      .subscribe((response: Blob) => {
        const name = this.selection.file_name;
        this.documentService.initiateDownload(name, response);
      });
  }

  public extractBlob(data) {
    let blob = new Blob([data], {type: this.selection.type});
    return blob || {};
  }

  deleteDocument() {
    this.documentsManager.deleteEntityDocument(this.selection,this.documentQuery);
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  // we do not use this function
  onChargesSelectionChanged(event) {
    if (!event.selectedRowsData.length) {
      this.setChargesDataSource(this.disputeCharges);
      this.chargesSelectionActive = false;
      this.selectedCharges = [];
      this.createNoteAllowed = false;
    } else {
      this.createNoteAllowed = true;
      this.selectedCharges = event.selectedRowsData;
    }
    this.chargesNote = true;
    let chargesTemp = !event.selectedRowsData.length ? this.disputeCharges : this.selectedCharges;
    this.selectedChargesForNotesComponent = chargesTemp.map(disputeCharge => disputeCharge.charge);
  }

  loadSelectedCharges() {
    if (!this.selectedCharges.length) {
      return;
    }

    this.chargesSelectionActive = !this.chargesSelectionActive;

    if (this.chargesSelectionActive) {
      this.setChargesDataSource(this.selectedCharges)
    } else {
      this.setChargesDataSource(this.disputeCharges);
    }
  }

  setChargesDataSource(chargesDataSource) {
    this.chargesGrid.instance.option('dataSource', chargesDataSource);
  }

  openNotesPanel(chargesNote?: boolean, selectedNoteId?: number,charge?:any) {
    // this.selectedNoteId = selectedNoteId;
    this.selectedCharges = [charge];
    this.chargesGrid.instance.selectRows(this.selectedCharges, false);

    if (!this.sider.isActive(this.SECTIONS.NOTES_SECTION_NAME)) {
      this.sider.toggle(this.SECTIONS.NOTES_SECTION_NAME);
    }
  }

  onViewNoteCharges(event) {
    const noteChargeIds = event.map(item => item.id);

    this.selectedCharges = this.disputeCharges.filter(
      disputeCharge => noteChargeIds.find(noteChargeId => noteChargeId === disputeCharge.charge.id)
    );

    this.setChargesDataSource(this.selectedCharges);
    this.chargesSelectionActive = true;
    setTimeout(() => {
      this.chargesGrid.instance.selectRows(this.selectedCharges, true);
    }, 100);
  }

  gridSettings() {
    this.dialog.open(GridSettingsComponent, {
      service: this.disputeChargesGridService,
      sliderMinimum: 1
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.refreshChargesGridSettings(settings)
        }
      });
  }

  refreshChargesGridSettings(settings) {
    this.disputeCharges = [];
    this.loaderService.displayLoaderAndManageGrid([this.chargesGrid]);

    this.columns = this.disputeChargesGridService.getColumns(settings && settings.columns ? settings.columns : []);
    this.sorting = this.disputeChargesGridService.sorting();

    if (this.chargesGrid.instance) {
      this.chargesGrid.instance.option('columns', this.columns);
    }

    let chargesQuery = this.disputeChargeQuery ? this.disputeChargeQuery : new DisputeChargeQuery({});
    chargesQuery.orderBy = [['charge.id', 'ASC']];
    this.loadCharges(this.disputeId, chargesQuery);
  }

  onPageChange() {
    this.loadCharges(this.disputeId);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.tabGroup.onActivate.unsubscribe();
  }

  clearChargeFilter() {
    this.disputeChargeQuery.where = {dispute_id: this.disputeId}
    this.loadCharges(this.disputeId, this.disputeChargeQuery);
  }

  isChargesDeleteDisabled() {
    //If findChargeWithheld exist disable delete icon
    let findChargeWithheld = this.selectedCharges.find(charge => {
      return charge.dispute_withheld === true && charge.charge.invoice.header.status_code >= LOOKUP_ENUM.INVOICE_STATUS.RFA
    });

    return !(this.tabGroup.isActive(3) && this.selectedCharges.length > 0 && !(findChargeWithheld && findChargeWithheld.dispute_withheld));
  }

  /**
   * Delete selected dispute charges
   */
  deleteSelectedCharges() {
    let chargeIds = [];
    if(this.selectedCharges.length > 0){
      chargeIds = this.selectedCharges.map((charge) => {
         return charge.id
      })
    }

    // Find dispute charges which are not selected
    let intersectChargeDisputes = this.dispute.dispute_charges.filter(item1 => !this.selectedCharges.some(item2 => (item2.id === item1.id)))

    // Calculate price for not selected dispute charges
    let newCalculatedValue = Object.assign(
      {},
      this.disputeService.calculateDisputeTotals(
        intersectChargeDisputes,
        this.disputeStatusLookup,
        this.disputeWithheldStatusLookup
      )
    );
    this.dialog.open(DeleteDisputeChargesManageDialog, {
      deactivationMessage:`Are you sure you want to delete charges from Dispute?`
    }).afterClosed().subscribe( async result => {
        if (result) {
          this.loaderService.displayLoader();
          const charges = await this.disputeService.findDisputeChargesCheck(this.disputeId,{chargeIds}).toPromise()
          const deleteChargeDisabled = charges.some(ds => {
            return (ds.dispute_withheld == true && ds.charge.invoice.header.status_code >= INVOICE_STATUS_ENUM.RFA)
          })
          if (!deleteChargeDisabled) {
            try {
              let subclientsFromSelectedCharges = [];
              let invoicesToReset = [];

              this.selectedCharges.forEach(charge => {
                if( charge.charge.invoice.account.subclient !== null && !subclientsFromSelectedCharges.includes(charge.charge.invoice.account.subclient.id)){
                  subclientsFromSelectedCharges.push(charge.charge.invoice.account.subclient.id)
                }
                if (charge.dispute_withheld && charge.charge.invoice.header.status_code > INVOICE_STATUS_ENUM.NEW_PENDING && !invoicesToReset.includes(charge.charge.invoice.invoice_id)) {
                  invoicesToReset.push(charge.charge.invoice.invoice_id)
                }
              })
              let currentCharge;
              let subclient;
              for (let i = 0; i < subclientsFromSelectedCharges.length; i++) {
                subclient = subclientsFromSelectedCharges[i];
                let filteredCharges = [];
                this.disputeCharges.forEach(item => {
                  currentCharge = item;
                  if(item.charge.invoice.account.subclient && item.charge.invoice.account.subclient.id == subclient && !this.selectedCharges.includes(item)){
                    filteredCharges.push(item);
                  }
                })
                if(filteredCharges.length == 0){
                  await this.disputeService.deleteSubclient(currentCharge.dispute_id, subclient);
                }
              }
              if(invoicesToReset.length) {
                invoicesToReset.forEach(invoice_id => {
                  this.invoiceService.updateStatus(invoice_id, {status: INVOICE_STATUS_ENUM.NEW_PENDING, statusReset: INVOICE_STATUS_ENUM.NEW_RESET})
                    .subscribe(() => {
                      this.invoiceService.processMultipleInvoices(invoice_id);
                    })
                })
              }
              let spInvNumbers = this.selectedCharges.map(disputeCharge => {
                return disputeCharge.charge.invoice.sp_inv_num
              })
              let uniqueSpInvNumbers = [...new Set(spInvNumbers)];

              const chargesRes = await this.disputeService.deleteCharges(this.disputeId, {
                chargeIds, newCalculatedValue, uniqueSpInvNumbers, note: result['note']
              }).toPromise();

              if (chargesRes.status && chargesRes.status === 200) {
                this.alertService.success('', this.messages.get('DELETE_CHARGES_SUCCESS'))

                const disputeCharges = await this.disputeService.findDisputeCharges(this.disputeId, this.disputeChargeQuery).toPromise()
                // Delete dispute if no charges present
                if (!disputeCharges.items.length) {
                  const deletedDisputeResult = await this.disputeService.deleteDispute(this.disputeId, result['note']).toPromise()
                  // If dispute is deleted, go back to the list / else make sure to warn the user
                  if (deletedDisputeResult.status || deletedDisputeResult.status === 200)
                    this.back()
                  else
                    this.alertService.error('', this.messages.get('DELETE_ERROR'))
                } else {
                  const dispute = await this.disputeService.findById(this.disputeId).toPromise()
                  Object.assign(this.dispute, dispute);
                }
              } else {
                this.alertService.error('', this.messages.get('DELETE_CHARGES_ERROR'))
              }

              this.loaderService.hideLoader();
              this.selectedCharges = []
              this.loadSelected()
              this.disputeCalculations()
              this.history.refreshList();
              this.refresh()
            } catch (error) {
              this.loaderService.hideLoader();
              this.alertService.error('', this.messages.get('DELETE_CHARGES_ERROR'));
            }
          } else {
            this.alertService.error('', this.messages.get('DELETE_WITHHELD_CHARGE'));
          }
        }
    })
  }


  /** populate disputeStatusLookup */
  populateDisputeStatusLookup(disputeStatuses: Array<LookupModel>): void {
    disputeStatuses.forEach(item => {
      if (item.id === this.DISPUTE_STATUS_ENUM.FILED) {
        this.disputeStatusLookup.FILED = item;
      }
      if (item.id === this.DISPUTE_STATUS_ENUM.CLOSED_WON) {
        this.disputeStatusLookup.CLOSED_WON = item;
      }
      if (item.id === this.DISPUTE_STATUS_ENUM.IN_PROGRESS) {
        this.disputeStatusLookup.IN_PROGRESS = item;
      }
      if (item.id === this.DISPUTE_STATUS_ENUM.CLOSED_LOST) {
        this.disputeStatusLookup.CLOSED_LOST = item;
      }
    });
  }

  /** populate disputeWithheldStatusLookup */
  populateDisputeWithheldStatusLookup(
    disputeWithheldStatuses: Array<LookupModel>
  ): void {
    disputeWithheldStatuses.forEach(item => {
      if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.NOT_WITHHELD) {
        this.disputeWithheldStatusLookup.NOT_WITHHELD = item;
      }
      if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.PARTIALLY_WITHHELD) {
        this.disputeWithheldStatusLookup.PARTIALLY_WITHHELD = item;
      }
      if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.FULL_WITHHELD) {
        this.disputeWithheldStatusLookup.FULL_WITHHELD = item;
      }
    });
  }

  getAlertType(){
    if(this.ALERT_TYPE_LOOKUP_MODEL.DISCONNECT_AUDIT === this.alert.type_id){
      return 'disconnect'
    }
    if(this.ALERT_TYPE_LOOKUP_MODEL.MISSING_INVENTORY === this.alert.type_id){
      return 'missing-inventory'
    }
    if(this.ALERT_TYPE_LOOKUP_MODEL.RATE_AUDIT === this.alert.type_id){
      return 'rate-audit'
    }
    if(this.ALERT_TYPE_LOOKUP_MODEL.INVENTORY_MRC === this.alert.type_id){
      return 'inventory-mrc'
    }
    if(this.ALERT_TYPE_LOOKUP_MODEL.MULTIPLE_AUDITS === this.alert.type_id){
      return 'audit'
    }
  }

  getAlertDetailPage(){
    let string = this.getAlertType();

    this.router.navigate(['/alert', this.alertId, string]);

  }

}
