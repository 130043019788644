<form [formGroup]="vendorFilterForm">
  <div style="flex-direction: column;">
    <div fxFlex class="form-group ca-form tag-filter">
      <mat-form-field hiDensityInput>
        <input matInput type="text"
               placeholder="Vendor"
               [matAutocomplete]="autoVendor"
              formControlName="vendor"
               (focus)="searchAllOnInitialFocus()"
               (focusout)="clearQueryOnFocusOut()">
      </mat-form-field>
      <mat-autocomplete #autoVendor="matAutocomplete">
        <div class="scrollable-container" (scroll)="scrollHandlerVendor($event)" style="max-height: 250px; overflow-y: scroll">
        <mat-option *ngFor="let val of vendorList"
                    [value]="val.text" (click)="findSelectedVendor();">{{ val.text || val }}
        </mat-option>
        </div>
      </mat-autocomplete>
    </div>

    <div fxFlex class="form-group ca-form">
      <mat-form-field hiDensityInput>
        <input matInput type="text"
               placeholder="Account"
               [matAutocomplete]="autoAcct"
               formControlName="account"
               (focus)="searchAllAcctOnInitialFocus()"
               (focusout)="clearAcctQueryOnFocusOut()">
      </mat-form-field>
      <mat-autocomplete #autoAcct="matAutocomplete">
        <div class="scrollable-container" (scroll)="scrollHandlerAccount($event)" style="max-height: 250px; overflow-y: scroll">
        <mat-option *ngFor="let val of accountList"
                    [value]="val.value">{{ val.text || val }}
        </mat-option>
        </div>
      </mat-autocomplete>
    </div>
  </div>
</form>
