import { Injectable } from '@angular/core';

@Injectable()
export class RandomService {
  constructor() {
  }

  getRandomLetter() {
    return String.fromCharCode(Math.floor(65 + 25 * Math.random()));
  }

  getRandomDigit() {
    return Math.floor(Math.random() * 10);
  }

  getRandomCheckString() {
    const letters = Array.from({length: 2}).map(this.getRandomLetter).join('');
    const digits = Array.from({length: 3}).map(this.getRandomDigit).join('');
    return `${letters}${digits}`;
  }
}
