import {Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {PickerComponentBase} from 'app/shared/picker/ca-picker-base.component';
import {Observable} from 'rxjs';
import {SiteService} from 'app/site/core/site.service';

const mapItems = disabledSites => (items = []) => items.map(item => ({
  ...item,
  disabled: disabledSites.includes(item.id)
}));

@Component({
  selector: 'ca-site-picker',
  templateUrl: './site-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SitePickerComponent),
      multi: true
    },
    SiteService
  ]
})
export class SitePickerComponent extends PickerComponentBase implements OnChanges {
  readonly TEXT_FIELD = 'site_id';
  readonly SECOND_LINE_TEXT_FIELD = 'search_address'

  @Input() formControlName;
  @Input() initialItem;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() searchEnabled: boolean;
  @Input() placeholder = 'Select ...';
  @Input() pickerForm;
  @Input() clearEnabled: boolean;
  @Input() paginationSize = 20;
  @Input() customerID: number;
  @Input() vendorID: number;
  @Input() returnWholeEntry = false;
  @Input() disabledSites: Array<number> = [];
  @Input() initialItemId: number;

  public control;

  constructor(
    public siteService: SiteService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];
    if (changes['customerID']) {
      this.query.where['customer_id'] = this.customerID;
      this.loadData();
    }
    if (changes['vendorID']) {
      this.query.where['vendor_id'] = this.vendorID;
      this.query.orderBy = [['site_id', 'ASC']];
      this.loadData();
      this._value = null;
      this.onChange(null);
    }

    if (changes.disabledSites) {
      this.entries = mapItems(changes.disabledSites.currentValue)(this.entries);
    }
  }

  private findAllSites = (query, disabledSites, includeAddressSearch) => {
    let data;
    data = this.siteService.findSitesOnly(query);
    data.items = mapItems(disabledSites)(data.items);
    return data;
  };

  loadEntries(): Observable<any> {
    this.query.orderBy = [['site_id', 'ASC']];
    if (this.customerID) {
      this.query.where['customer_id'] = this.customerID;
    }

    return this.findAllSites(this.query, this.disabledSites, true);
  }

  loadInitialItem(): Observable<any> {
    return this.siteService.findById(this.initialItemId);
  }

  search(value: string): Observable<any> {
    if (value !== '') {
      this.query.where.search_address_site_picker = value;
    } else {
      if(this.query.where.search_address_site_picker) delete this.query.where.search_address_site_picker;
    }
    return this.findAllSites(this.query, this.disabledSites, true);
  }
}
