import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';
import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AccountDialogService} from 'app/account/core/account-dialog.service';
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';
import {Config} from '../../core/config/config';
import {GLBatchService} from '../../gl-batch/core/gl-batch.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {Flow} from '../../shared/flow/flow';
import {FlowStep} from '../../shared/flow/flow-step';
import {FlowService} from '../../shared/flow/flow.service';
import {NotesService} from '../../shared/notes/core/notes.service';
import {InvoiceFacepage} from './invoice-facepage';
import {InvoiceFacepageService} from './invoice-facepage.service';
import {INVOICE_STATUS_ENUM} from './invoice-status.enum';
import {LOOKUP_ENUM} from '../../dictionary/core/lookup.enum';
import {AlertService} from '../../shared/alert/alert.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {CommonAlertService} from '../../common-alert/core/common-alert.service';
import {PermissionService} from "../../permissions/core/permission.service";
import {ConfigService} from "../../core/config/config.service";
import 'rxjs/add/operator/do';
import {AccountService} from "../../account/core/account.service";
import {InvoiceFlowSteps} from "./invoice-flow-steps.context"
import {InvoiceFlowHandleService} from "./invoice-flow-handle.service";

@Injectable()
export class InvoiceFlowService extends FlowService {
  public config: Config;
  public invoiceId: number;
  public invoice: InvoiceFacepage;

  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  messages: IMessagesResourceService;

  isGlButtonDisabled: boolean = true;
  public isGlBatchOutputButtonDisabled: boolean = false;
  isApproveButtonDisabled: boolean = true;
  hasModifyPermission: boolean;
  permissions;

  readonly LOCALS = {
    INVOICE_STATUS_CHANGE_ALERT_TITLE: 'Invoice Status Change',
    READY_FOR_APPROVAL_TITLE: 'Ready For Approval',
    READY_FOR_APPROVAL_BODY: 'Invoice not fully GL coded',
    CREATE_OUTPUT_FILE_BODY: 'Create GL output file',
    INVOICE_IS_INACTIVE: 'This account is in inactive state and can not be output while it\'s in this state.',
    REVERT_TO_GL_CODED_BODY: 'Revert Invoice status to GL Coded',
    REVERT_TO_BATCH_OUPUT: 'Revert to GL output',
    SET_TO_COMPLETED_BODY: 'Set Invoice state to Completed',
    GL_OUTPUT_SENT: 'Confirm GL output was sent',
    REVERT_TO_RFA: 'Revert Invoice to Ready For Approval',
    AP_FEED_RECEIVED: 'Confirm AP Feed Received',
    DO_NOT_PROCESS: 'Do Not Process',
    REVERT_TO_GL_OUTPUT_SENT: 'Revert to GL output sent'
  };

  readonly MESSAGES_MODULE: string = 'gl';

  invoiceStatusEnum = INVOICE_STATUS_ENUM;
  isGlOutputAutoSend: boolean;

  public isAdminUser: boolean;
  public approve: boolean;
  readonly DATA_LOCK_CLOSE_STATUS = {
    CANCEL_BY_USER: 0,
    TIME_EXTEND: 1,
    CANCEL_BY_TIMER: 2
  };

  constructor(public invoiceService: InvoiceFacepageService,
              public dialog: DialogService,
              public glBatchService: GLBatchService,
              public notesService: NotesService,
              public router: Router,
              private alert: AlertService,
              private accountDialogService: AccountDialogService,
              private loaderService: LoaderService,
              private commonAlertService: CommonAlertService,
              private permissionService: PermissionService,
              public configService: ConfigService,
              public accountService: AccountService,
              public invoiceFlowSteps: InvoiceFlowSteps,
              public invoiceFlowHandleService: InvoiceFlowHandleService

  ) {
    super();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.configService.get()
      .subscribe((config) => {
        this.config = config;
        this.isGlOutputAutoSend = config && config.invoice_flow_settings ? config.invoice_flow_settings.gl_output_auto_send : false;
      })

    // moved into invoice component
    // this.loadFlowPermissions().subscribe(()=>{})
  }

  loadFlowPermissions(invoice?: InvoiceFacepage) {
    const glView$ = this.permissionService.isAllowed("gl", "VIEW");
    const glModify$ = this.permissionService.isAllowed("gl", "MODIFY");
    const glCreate$ = this.permissionService.isAllowed("gl", "CREATE");
    const approve$ = this.permissionService.isAllowed("invoices", "APPROVE");
    const invoiceModify$ = this.permissionService.isAllowed("invoices", "MODIFY");
    const isAdminUser$ = this.permissionService.isAdmin();

    return forkJoin(
      glView$,
      glModify$,
      glCreate$,
      approve$,
      invoiceModify$,
      isAdminUser$
    ).pipe(map(([glView, glModify, glCreate, approve, invoiceModify, isAdminUser]) => {
      this.isAdminUser = isAdminUser
      this.approve = approve

      this.isGlButtonDisabled = !glView || !glModify || !glCreate;
      this.isGlBatchOutputButtonDisabled = !glCreate;
      this.isApproveButtonDisabled = !(isAdminUser || approve) || (!isAdminUser && invoice?.header?.status_code > this.invoiceStatusEnum.APPROVED);
      this.hasModifyPermission = invoiceModify;
      const permissions = {
        isAdminUser,
        approve,
        glButtonDisabled: this.isGlButtonDisabled,
        glBatchOutputButtonDisabled: this.isGlBatchOutputButtonDisabled,
        approveButtonDisabled: this.isApproveButtonDisabled,
        modifyPermission:invoiceModify
      }
      this.permissions = permissions
    }));
  }

  createFlow(invoice: InvoiceFacepage): Flow {
    this.invoiceId = invoice.invoice_id;
    this.invoice = invoice;
    let status = invoice.header.status_code;

    if (!status) {
      status = this.invoiceStatusEnum.NEW;
    }
    this.flow = this.create();
    this.flow.currentStep = 1;

    if (status === this.invoiceStatusEnum.DO_NOT_PROCESS) {
      this.flow.linear = false;
    }
    this.invoiceFlowHandleService.updateSteps(status,this.flow,this.invoice);
    return this.flow;
  }

  create(): Flow {
    const  steps  = this.invoiceFlowSteps.getSteps(this.permissions,this.config,this.invoice,this.invoiceFlowHandleService.invoiceStatusMap)
    let flow = new Flow();
    let index = 0;
    steps.forEach(step => {
      if (step.visible) {
        flow.addStep(new FlowStep({
          name: step.name,
          key: step.key,
          disabled: step.disabled,
          onClickFromPreviousStep: step.onClickFromPreviousStep,
          onClickFromNextStep: step.onClickFromNextStep,
          onClick: step.onClick,
          code: ++index
        }));
      }
    })
    if(this.invoice.header.status_code === INVOICE_STATUS_ENUM.DO_NOT_PROCESS){
      flow.steps.splice(flow.steps.length - 2, 1);
      flow.steps[flow.steps.length - 1].code = flow.steps.length;
    }
    return flow;
  }

  getStepNumberForKey(key) {
    return this.flow.steps.findIndex(step => {
      return step.key === key;
    }) + 1;
  }

  async handleStepSelection(data, mediator?: EventEmitter<any>, callFunc?: Function) {
    const {currentStep} = this.flow;
    const {key} = data;
    if (currentStep < this.getStepNumberForKey(key)) {
      data.onClickFromPreviousStep(this.flow,this.invoice,mediator,callFunc,data);
    } else if (
      currentStep > this.getStepNumberForKey(key) &&
      (currentStep === this.getStepNumberForKey(key) + 1
        || currentStep === this.invoiceFlowHandleService.getStepNumberForKey('DO_NOT_PROCESS')
      )) {
      data.onClickFromNextStep(this.flow,this.invoice,mediator,callFunc,data);
    } else {
      data.onClick(this.flow,this.invoice,mediator,callFunc,data);
    }

    this.isApproveButtonDisabled = !(this.isAdminUser || this.approve) || (!this.isAdminUser && this.invoice.header.status_code >= this.invoiceStatusEnum.APPROVED);
  }

  getAccountUpdatedMessage(prevAccount, currentAccount) {
    if (prevAccount.on_hold === currentAccount.on_hold) {
      return 'ACCOUNT_UPDATED';
    }

    return currentAccount.on_hold ? 'ON_HOLD' : 'OFF_HOLD';
  }
}
