import {Injectable} from "@angular/core";
import {FilterService} from "../../core/filter/filter.service";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {termsAndConditionFilterContext} from "./contract-term-and-condition-filter.context";
import {ContractService} from "./contract.service";
import {ContractScheduleLookupProvider} from "./contract-schedule-lookup.provider";
@Injectable()
export class ContractTermAndConditionFilterService extends FilterService {



  constructor(public userSettingsService: UserSettingsService,
              public contractService: ContractService,
             ) {
    super('app.cost.contract-term-and-condition-filter', termsAndConditionFilterContext, userSettingsService);

    this.addLookupProvider('contractSchedule', new ContractScheduleLookupProvider(contractService));

  }
}
