import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Inventory} from './inventory';
import {Restangular} from 'ngx-restangular';
import {HttpClient} from '@angular/common/http';
import {ApiService} from 'app/core/api';


import {environment} from '../../../environments/environment';
import Auth from '../../shared/user-session/auth';
import {ConfigService} from '../../core/config/config.service';
import {Config} from '../../core/config/config';
import {Observable} from 'rxjs';
import Query from '../../core/query/query';
import {EntityLockService} from '../../shared/entity-lock/entity-lock.service';
import {map} from "rxjs/operators";

@Injectable()
export class InventoryService extends BaseService<Inventory> {
  public inventoryGLStringPath = 'inventory_gl_string';

  constructor(public configService: ConfigService,
              public restangular: Restangular,
              public http: HttpClient,
              private entityLock: EntityLockService,
              private apiService: ApiService) {
    super('inventory', restangular, entityLock);
  }

  checkAlias(query) {
    return this.service().all(this.name).all('alias/check').customGET(null, this.toFilter(query));
  }

  findAllAssignable(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().all(this.name).all('assignable').customGET(null, this.toFilter(transformedQuery));
  }

  fetchCustomerInventoryForNetworkInventory(inventoryId: number) {
    return this.service().one(this.name, inventoryId).one('customer-inventory').get();
  }

  fetchNetworkCircuits(inventoryId: number) {
    return this.service().one(this.name, inventoryId).one('circuits').get();
  }

  fetchNetworkCircuitsCsv(inventoryId: number, query?: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().one(this.name, inventoryId).one('circuitsCsv').customGET(null, this.toFilter(transformedQuery));
  }

  addNetworkCircuits(inventoryId: number, networkCircuits: Array<any>) {
    return this.service().one(this.name, inventoryId).one('circuits').customPOST(networkCircuits);
  }

  /* Billing alias section */
  fetchInventoryAliases(inventoryId: number) {
    return this.service().one(this.name, inventoryId).one('alias').get();
  }

  addInventoryAliases(inventoryId: number, linkedInventory: Array<any>) {
    return this.service().one(this.name, inventoryId).one('alias').customPOST(linkedInventory);
  }

  /* EO billing alias section */

  addSite(inventory_id: number, site_id: number, contact_id: number): any {
    let conId = contact_id ? contact_id : -1;
    return this.service().one(this.name, inventory_id).one('site', site_id).one('contact', conId).put();
  }

  deleteSite(inventory_id: number, site_id: number): any {
    return this.service().one(this.name, inventory_id).one('site', site_id).remove();
  }

  addPricing(inventory_id: number, data) {
    return this.service().one(this.name, inventory_id).all('document').post(data);
  }

  getPricing(inventory_id: number) {
    return this.service().one(this.name, inventory_id).one('documents').get();
  }

  getPricings(query: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().one(this.name, 'pricings').customGET(null, this.toFilter(transformedQuery));
  }

  deletePricing(inventory_id: number, document_id: number) {
    return this.service().one(this.name, inventory_id).one('document', document_id).remove();
  }



  downloadPricing(inventory_id: number, document_id: number) {
    let cookie = Auth.getSession();
    let params: URLSearchParams = new URLSearchParams();
    params.set('authorization', cookie.token);

    let endPoint = `${environment.API_URL + this.name}/${inventory_id}/document/${document_id}/download`;

    // @ts-ignore
    return this.http.get(endPoint, {responseType: 'blob', headers: {authorization: `Bearer ${cookie.token}`}});
  }

  initiateDownload(name, response: Blob) {
    let objectUrl = URL.createObjectURL(response);
    let save = document.createElement('a');
    save.href = objectUrl;
    save.target = '_blank';
    save.download = name;
    let event = document.createEvent('MouseEvents');
    event.initMouseEvent(
      "click", true, false, window, 0, 0, 0, 0, 0
      , false, false, false, false, 0, null
    );
    save.dispatchEvent(event);
  }

  isFileNameTaken(entityId: number, query: any): Observable<any> {
    return this.service().one(this.name, entityId).one(`documents`, `file-name`).customGET(null, this.toFilter(query));
  }


  getGLStrings(query: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().one(this.inventoryGLStringPath).customGET(null, this.toFilter(transformedQuery));
  }

  getOrders(query: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().one(this.name, 'order-services').customGET(null, this.toFilter(transformedQuery));
  }

  saveGLStrings(inventory_id: number, glStrings: Array<any>) {
    let saveData = glStrings.filter((o) => {
      o.inv_id = inventory_id;
      return o;
    });
    let data = {inv: inventory_id, values: saveData};
    return this.service().all(this.inventoryGLStringPath).all('save').post(data);
  }

  canBeEdited(inventoryId: number) {
    return this.service().one(this.name, inventoryId).one('canedit').get();
  }

  unlinkFromNetworkHubCircuisMap(inventoryId: number) {
    return this.service().one(this.name, inventoryId).one('unlinknetworkhubcircuit').remove();
  }

  findOnlyInventory(query?: Query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .all(this.name)
      .one('filtered')
      .customGET(null, this.toFilter(transformedQuery));
  }

  findOnlySvcInventory(query?: Query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = concreteQuery.transform();

    return this.service()
      .all(this.name)
      .one('svc-filtered')
      .customGET(null, this.toFilter(transformedQuery));
  }

  addRecordAfterOSDelete(inventoryId: number, inventory, service_id) {
    let data = {inv: inventory, id: inventoryId, service_id: service_id}
    return this.service().one(this.name, inventoryId).all('order-servicedeleted').post(data);
  }

  findAllContractTerms(id, query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().one(this.name, id).all('contract-term').customGET(null, this.toFilter(transformedQuery));
  }

  createContractTerm(inventoryId, item) {
    return this.service().one(this.name, inventoryId).one('contract-term').customPOST(item);
  }

  updateContractTerm(inventoryId, id, item) {
    return this.service().one(this.name, inventoryId).one('contract-term', id).customPUT(item);
  }

  deleteContractTerm(inventory_id: number, id: number) {
    return this.service().one(this.name, inventory_id).one('contract-term', id).remove();
  }

  updateContract(selectedInventoryIds,contractId) {
    let item = {
      contract_id: contractId,
      inventory_ids: selectedInventoryIds
    };
    return this.service().one(this.name).one('contract').customPUT(item)
  }

  findAllForContract(query?: Query, contractId?, inventoryIds?): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.service().one(this.name, contractId).all('contract').customGET(null, this.toFilter(transformedQuery), inventoryIds);
  }

  // TODO: check if needed and remove / using old 'inventory_contracts_map' table
  findAllContracts(query?: Query, contractId?): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().one(this.name, contractId).all('inventory-contract').customGET(null, this.toFilter(transformedQuery));
  }

  findAllInventoriesForContract(contractId?): any {
    return this.service().one(this.name, contractId).all('contractinventories').customGET(null);
  }

  unlinkInventory(query?: Query, contractId?): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().one(this.name, contractId).all('unlink-inventory').customDELETE(null, this.toFilter(transformedQuery));
  }

  findContractById(id): any {
    return this.service().one(this.name, id).all('getcontractbyid').customGET(null);
  }

  findContractInventories(query?: Query, contractId?): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().one(this.name, contractId).all('contract-inventories').customGET(null, this.toFilter(transformedQuery));
  }

  findContractTypeById(id: number): any {
    return this.service().one(this.name, id).all('contracttype').customGET(null);
  }

  findAllSvcOrderServices(query: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().one(this.name).all('inventory-svc-order-service').customGET(null, this.toFilter(transformedQuery));
  }

  findOnlyInventoryRelationshipType(query: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service()
      .one(this.name)
      .all('relationship-type')
      .customGET(null, this.toFilter(transformedQuery));
  }

  findAllScheduleRates(query: Query):any{
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.service().one('contract').all('rates').customGET(null, this.toFilter(transformedQuery));
  }
}
