import {filter, takeUntil} from 'rxjs/operators';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {GLService} from "../../../gl-rule-execution/core/gl.service";
import {ITabInformation} from "../../core/tab.information";
import {ChargeQuery} from "../../../charge/core/charge.query";
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {Select, Store} from "@ngxs/store";
import {QueryBuilder} from "../../../common/query/query.builder";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {ChargeStringsGridService} from "../../../gl-rule-execution/core/charge-strings-grid.service";
import {GlRuleExecutionChargesGridService} from "../../../gl-rule-execution/core/gl-rule-execution-charges-grid.service";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {Observable, Subject} from "rxjs";
import {HistoryComponent} from "../../../shared/history/history.component";
import {INVOICE_STATUS_ENUM} from "../../core/invoice-status.enum";
import {InvoiceFlowService} from "../../core/invoice-flow.service";
import {HistoryService} from "../../../shared/history/history.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {UpdateInvoiceGLChargeQuery, UpdateInvoiceGLChargeSiderSection} from "./state/invoice-gl-charge.actions";
import {InvoiceGlRuleExecutionFilterService} from "../../core/invoice-gl-rule-execution-filter.service";
import {InvoiceGlRuleExecutionFlatFilterService} from "../../core/invoice-gl-rule-execution-flat-filter.service";
import {UserService} from "../../../user/core/user.service";
import {GridSettingsComponent} from "../../../core/grid/grid-settings.component";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {
  GlRuleExecutionChargesFlatGridService
} from "../../../gl-rule-execution/core/gl-rule-execution-charges-flat-grid.service";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";

@Component({
  selector: 'app-invoice-gl-charge-details',
  templateUrl: './invoice-gl-charge-details.component.html',
  styleUrls: ['./invoice-gl-charge-details.component.scss']
})
export class InvoiceGlChargeDetailsComponent implements OnInit, AfterViewInit {

  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    HISTORY_SECTION_NAME: "history",
    NOTES_SECTION_NAME: 'notes',
  };

  invoice: InvoiceFacepage;
  defaultGridPager: any;
  chargeQuery: ChargeQuery = new ChargeQuery({orderBy: [['id', 'ASC']]});
  chargesRowsExpanded: any[] = [];
  readonly GL_VALIDATION_RULES = LOOKUP_ENUM.GL_VALIDATION_RULES;
  defaultGridPagerGL: any;
  pagerSize: any;

  dnpInfo: any = null
  onHoldInfo: any = null
  notesCount = 0;
  invoiceStatusEnum = INVOICE_STATUS_ENUM;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'invoice';
  readonly COMPONENT_NAME = 'invoice-gl-charge-details';

  gridLimit: number = 20;

  private chargesColumnsSorting: any[];
  private gridSettings: any;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('chargeGrid') chargeGrid: DxDataGridComponent;
  @ViewChild('panelSide') panelSide: any = '';
  @ViewChild('history') history: HistoryComponent;
  @ViewChild('testNotes') testNotes;
  sider: Sider = new Sider(new SiderSettings([], this.panelSide));


  @Select(state => state.invoice_gl_charge.activeSiderSection) $activeSiderSection: Observable<any>;
  @Select(state => state.invoice_gl_charge.query) $query: Observable<ChargeQuery>;

  public query = new ChargeQuery();

  // TODO:  check key / tab key is 7
  public GL_CHARGE_DETAIL_TAB_INFO: ITabInformation = {
    key: 7,
    active: false,
    dataSource: [],
    handler: this.handleGLChargeDetailTab.bind(this),
    masterDetailEnabled: false,
    masterDetailColumns: [],
    query: this.query,
    columns: [],
    selectionChanged: (event: any) => {
    }
  };

  private handleGLChargeDetailTab(queryChanged?: ChargeQuery) {
    if (queryChanged) {
      this.query = queryChanged;
    }
    const {query, masterDetailEnabled} = this.GL_CHARGE_DETAIL_TAB_INFO;
    const {vendor_id, invoice_id, account_id} = this.invoice;

    this.glService
      .loadCharges(query, {
        masterDetailsEnabled: masterDetailEnabled,
        account_id: account_id,
        vendorId: vendor_id,
        invoice: invoice_id,
        grid: this.chargeGrid
      })
      .subscribe(this.applyChargesResult.bind(this));
  }

  constructor(
    private readonly invoiceFacepageService: InvoiceFacepageService,
    public store: Store,
    public queryBuilder: QueryBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public glService: GLService,
    public chargeStringsGridService: ChargeStringsGridService,
    public glRuleExecutionChargesGridService: GlRuleExecutionChargesGridService,
    public glRuleExecutionChargesFlatGridService: GlRuleExecutionChargesFlatGridService,
    public invoiceFlowService: InvoiceFlowService,
    public historyService: HistoryService,
    public invoiceGLRuleExecutionFilterService: InvoiceGlRuleExecutionFilterService,
    public glRuleExecutionFlatFilterService: InvoiceGlRuleExecutionFlatFilterService,
    public userService: UserService,
    public dialogService: DialogService,
  ) {
    this.invoice = this.route.parent.snapshot.data.invoice;
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.invoiceFacepageService.invoiceChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(invoice => {
      this.invoice = invoice;
      this.history.refreshList();
    })
  }

  ngOnInit(): void {
    this.GL_CHARGE_DETAIL_TAB_INFO.active = true;

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => this.onQueryParamsChange(params));
    this.$query.pipe(takeUntil(this.destroy$)).subscribe((query) => this.onQueryChange(query));

    this.getOnHoldInfo()
    this.testNotes && this.testNotes.emitCount();
  }

  filterData(query: ChargeQuery) {
    // delete necessary to make filter and clear hash equal if no filter params are present
    delete query.where['vendor_id']
    delete query.where['acct_level_1']
    delete query.where['invoice_id']
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query, route: this.route, queryBuilder: ChargeQuery, queryToSet: undefined
    })
  }

  clearFilter() {
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query: new ChargeQuery(), route: this.route, queryBuilder: ChargeQuery, queryToSet: undefined
    })
  }

  private onQueryParamsChange(params: Params) {
    // to make sure query is empty when no params in the url
    if (Object.keys(params).length === 0) {
      this.query = new ChargeQuery({
        where: {
          vendor_id: this.invoice.vendor_id,
          acct_level_1: this.invoice.acct_level_1,
          invoice_id: this.invoice.invoice_id
        }
      })
    }
    const query = this.queryBuilder.build(this.query, null, this.COMPONENT_NAME).getQuery()
    this.store.dispatch([
      new UpdateInvoiceGLChargeQuery({query, key: params.key})
    ]);
  }

  public resetDataPager() {
    this.defaultGridPager = 20;
  }

  onOpenGridSettingsRequested() {
    const chargesDetailGridService = this.glService.gridService(this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled);

    this.dialogService
      .open(GridSettingsComponent, {
        service: chargesDetailGridService,
        settings: this.gridSettings,
        sliderMinimum:  this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled ? 0 : 1
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this.initChargeDetailsColumns(settings);
          this.handleGLChargeDetailTab();
        }
      });
  }

  onPagerChange() {
    this.refresh()
  }

  private refresh() {
    const { masterDetailEnabled} = this.GL_CHARGE_DETAIL_TAB_INFO;

    this.glService
      .loadCharges(this.query, {
        masterDetailsEnabled: masterDetailEnabled,
        account_id: this.invoice.account_id,
        vendorId: this.invoice.vendor_id,
        invoice: this.invoice.invoice_id,
        grid: this.chargeGrid
      })
      .subscribe(this.applyChargesResult.bind(this));
  }

  private onQueryChange(query: ChargeQuery) {
    this.query = new ChargeQuery(query);
    this.query.where = {
      ...this.query.where,
      vendor_id: this.invoice.vendor_id,
      acct_level_1: this.invoice.acct_level_1,
      invoice_id: this.invoice.invoice_id
    }
    this.prepareList()
  }

  public prepareList() {
    const gridService = this.glService.gridService(this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled);
    gridService.loadSettings()
      .subscribe(settings => {
        this.initChargeDetailsColumns(settings)
      })
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get activeFilter() {
    return this.glService.activeFilter(
      this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled,
      this.sider,
      this.SECTIONS,
      {
        isChargeDetailsTab: true,
        isStringsTab: false
      }
    );
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  private handleMasterDetailColumns() {
    // this.GL_CHARGE_DETAIL_TAB_INFO.columns = this.glService.getChargeDetailsColumns(this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled);
    const chargesDetailGridService = this.glService.gridService(this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled);
    chargesDetailGridService.loadSettings()
      .subscribe((settings) => {
          this.initChargeDetailsColumns(settings)
      });
  }

  private prepareGLChargeDetailTab() {
    this.GL_CHARGE_DETAIL_TAB_INFO.columns = this.glRuleExecutionChargesGridService.columns();
  }

  initChargeDetailsColumns(settings) {
    const chargesDetailGridService = this.glService.gridService(this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled);

    this.gridSettings = settings;
    this.chargesColumnsSorting = settings ? settings.sorting : [];
    this.GL_CHARGE_DETAIL_TAB_INFO.columns = chargesDetailGridService.getColumns(settings && settings.columns ? settings.columns : []);
    !this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled && this.GL_CHARGE_DETAIL_TAB_INFO.columns.unshift({
      caption: '',
      cellTemplate: 'glWarningTemplate',
      dataField: 'gl_warning_type',
      fixed: true,
      width: 40,
      allowResizing: false,
      showInSettings: false
    })
    // Page limit is assigned inside onQueryParamsChange method
    this.defaultGridPager = settings ? settings.gridPager : 20;
    this.query.limit = this.defaultGridPager;

    if (this.chargesColumnsSorting?.length) {
      this.query.orderBy = chargesDetailGridService.getOrderBy(this.chargesColumnsSorting);
    }

    if (this.chargeGrid && this.chargeGrid.instance) {
      this.chargeGrid.instance.option('columns', this.GL_CHARGE_DETAIL_TAB_INFO.columns);
    }
    //  this.handleGLChargeDetailTab();

    this.refresh();
  }


  applyChargesResult(result: { items: any[]; query: ChargeQuery }) {
    this.GL_CHARGE_DETAIL_TAB_INFO.dataSource = result.items;
    this.query = result.query;

    if (this.chargesRowsExpanded.length) {
      this.chargesRowsExpanded.forEach(row => {
        this.glService.rowExpanded(row, this.GL_CHARGE_DETAIL_TAB_INFO.dataSource);
      })
    }
  }

  public isExpandable(charge) {
    if (charge.rowType === 'data' && charge.column.command === 'expand') {
      if (charge && charge.data && !charge.data.charge_coded) {
        charge.cellElement.classList.remove("dx-datagrid-expand");
        charge.cellElement.innerHTML = "";
      }
    }
  }

  public rowExpanded(row) {
    this.glService.rowExpanded(row, this.GL_CHARGE_DETAIL_TAB_INFO.dataSource);
    this.chargesRowsExpanded.push(row);
  }

  public rowCollapsed(row) {
    this.chargesRowsExpanded.splice(this.chargesRowsExpanded.findIndex(rowItem => {
      return rowItem.key === row.key
    }), 1);
  }

  public onGLChargesCellClick(event) {
    const {query, masterDetailEnabled} = this.GL_CHARGE_DETAIL_TAB_INFO;
    const {vendor_id, invoice_id, account_id} = this.invoice;

    this.glService
      .handleChargesCellClick(event, query, {
        masterDetailsEnabled: masterDetailEnabled,
        account_id: account_id,
        vendorId: vendor_id,
        invoice: invoice_id,
        grid: this.chargeGrid
      })
      .pipe(filter(result => !!result))
      .subscribe(this.applyChargesResult.bind(this));
  }

  getHoverMessage(warningTypes) {
    let str = "GL Validation: \n";

    const isNotInventorySpid = warningTypes.findIndex(type => type === this.GL_VALIDATION_RULES.INVENTORY_SPID) === -1;
    warningTypes.forEach(type => {
      switch (type) {
        case this.GL_VALIDATION_RULES.INVENTORY:
          if (isNotInventorySpid) {
            str += `· Missing Inventory \n`;
          }
          break;
        case this.GL_VALIDATION_RULES.INVENTORY_SPID:
          str += `· Missing Inventory \n· Missing SPID \n`;
          break;
        case this.GL_VALIDATION_RULES.SPID:
          if (isNotInventorySpid) {
            str += `· Missing SPID \n`;
          }
          break;
        case this.GL_VALIDATION_RULES.SUBACCOUNT:
          str += `· Missing Subaccount \n`;
          break;
        default:
          break;
      }
    });

    return str;
  }

  // changePager(event) {
  //   this.pagerSize = event.limit;
  //   this.defaultGridPagerGL = this.pagerSize;
  // }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInvoiceGLChargeSiderSection(activeSection.name)
    ]);
  }

  public handleMasterDetailSwitch() {
    this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled = !this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled;
    this.query = new ChargeQuery({
      where: {
        /*charge_coded: true,*/
        invoice_id: this.invoice.invoice_id
      }
    });

    const chargesDetailGridService = this.glService.gridService(this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled);
    chargesDetailGridService.loadSettings()
      .subscribe((settings) => {
        this.query.page = 1;
        this.query.offset = 0;
        this.query.limit = settings ? settings.gridPager : 20;
        this.defaultGridPager = settings ? settings.gridPager : 20;
        this.query.flat_data = !this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled;
        this.GL_CHARGE_DETAIL_TAB_INFO.dataSource = [];
        this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailColumns = this.chargeStringsGridService.columns();

        this.GL_CHARGE_DETAIL_TAB_INFO.query = this.query;

        this.handleGLChargeDetailTab();
        this.initChargeDetailsColumns(settings);
      });
  }

  async getOnHoldInfo() {
    if (!this.invoice.account.on_hold) {
      this.onHoldInfo = null;
      return;
    }
    let accountHistory = await this.historyService.findAllForEntity('account', this.invoice.account.id).toPromise()
    let onHoldFound = accountHistory.filter(account => (account.meta_data.on_hold && account.meta_data.on_hold.value === true))
    this.onHoldInfo = (onHoldFound && onHoldFound.length > 0) ? onHoldFound[0] : null
  }

  getDNPInfo(notes: any, forceEmpty: boolean = false) {
    const dnpStatus = this.invoiceFlowService.LOCALS.DO_NOT_PROCESS

    if (forceEmpty || this.invoice.header.status_code !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
      this.dnpInfo = null
    } else {
      const dnpNotes = notes ? notes.filter(note => note.content.startsWith(dnpStatus)) : [];
      this.dnpInfo = (dnpNotes.length) ? dnpNotes[0] : {};
      this.dnpInfo.status = dnpStatus;
      this.dnpInfo.message = this.dnpInfo.content ? this.dnpInfo.content.substring(dnpStatus.length + 2) : this.messages.get('DNP_FALLBACK_MESSAGE');
    }
  }

  /* Notes count changed */
  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }


  onNoteCreated() {
    // this.refresh(this.query)
    // // TODO: was this.loadCharges(this.invoice); now refresh / check if valid
    // this.selectedCharges.length = 0;
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/invoice']);
    }
  }

  onDownloadCSVRequested() {
    this.glService.exportChargesToCsv(this.query, {
      masterDetailsEnabled: this.GL_CHARGE_DETAIL_TAB_INFO.masterDetailEnabled,
      invoice: this.invoice.invoice_id,
      vendorId: this.query.where.vendor_id,
      grid: this.chargeGrid
    });
  }
}
