import {LookupModel} from "../../dictionary/core/lookup.model";
export class DisputeWithheldStatusLookup {
  NOT_WITHHELD: LookupModel;
  PARTIALLY_WITHHELD: LookupModel;
  FULL_WITHHELD: LookupModel;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
