
import {of as observableOf,  Observable } from 'rxjs';

import { mergeMap } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import { PageListComponent } from '../../core/page-list.component';
import { SiteQuery } from '../core/site.query';
import { Site } from '../core/site';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { SiteService } from '../core/site.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { AlertService } from '../../shared/alert/alert.service';
import { SiteGridService } from '../core/site-grid.service';
import { SiteManageDialogComponent } from '../shared/site-manage-dialog/site-manage-dialog.component';
import { PageContext } from '../../core/page.context';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { SiteFilterService } from '../core/site-filter.service';
import { DataLockDialogService } from '../../core/data-lock/data-lock-dialog.service';
import {ConfigService} from "../../core/config/config.service";

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
})
export class SiteListComponent extends PageListComponent implements AfterViewInit, OnInit {
  public query: SiteQuery = new SiteQuery();
  public sites: Array<Site>;
  public totalSites: number;
  public selection: Site;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public sorting: any[][];

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'site';
  readonly CORE_MODULE: string = 'core';
  readonly custom_values_config_key = 'appdata_site';

  constructor(public siteService: SiteService,
              public dialogService: DialogService,
              public alertService: AlertService,
              public settingsService: UserSettingsService,
              public siteGridService: SiteGridService,
              public loaderService: LoaderService,
              public dataLockDialogService: DataLockDialogService,
              public siteFilterService: SiteFilterService,
              public configService: ConfigService) {
    super(new PageContext({
      name: 'app.site.site-list',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  async showDetails() {
    const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key)

    const { id } = this.selection;
    const site = await this.siteService.findById(id).toPromise();

    if (site) {
      this.dialogService.open(SiteManageDialogComponent, {
        site,
        viewOnly: true
      },{
        width: hasCustomFields ? '1000px' : '650px'
      })
        .afterClosed()
        .subscribe((result) => {
          this.afterModalClosed(result, site);
        });
    }
  }

  csv() {
    const { siteGridService, siteService, query } = this;
    siteGridService.csvMap().subscribe(fields => {
      siteService.exportToCSV(
        'sites',
        {
          fields,
          query
        }
      );
    });
  }

  afterModalClosed(result, item) {
    if (result) {
      if (result.cancelByTimer || result.status == 2) {
        return this.alertService.success('', this.coreMessages.get('TIMER_EXPIRED'));
      } else if (result.deleted) {
        this.loadData(this.query);
        this.selectFirstRow();
        setTimeout(() => {
          this.alertService.snackBar.dismiss();
          this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
        }, 255);
      } else if (result.id) {
        this.loadData(this.query);
        Object.assign(item, result);
        this.refresh();
        this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
      }
    }
  }

  loadData(query?: SiteQuery) {
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.siteService.findAll(query).subscribe((result) => {
      this.sites = result.items;
      this.query.total = this.totalSites = result.total;
      this.selectFirstRow();
      this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
    },
      () => {
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      });
  }

  filterData(query: SiteQuery) {
    this.query = query;
    this.loadData(query);
  }

  clearFilter() {
    this.query.where = null;
    this.loadData(this.query);
  }

  refresh() {
    this.loadData(this.query);
  }

  async newSite() {
    const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key);

    this.dialogService.open(SiteManageDialogComponent, {
      site: {}
    },{
      width: hasCustomFields ? '1000px' : '650px'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.refresh();
          this.alertService.success('', this.messages.get('CREATE_SUCCESS'));
        }
      });
  }

  async editSite(site) {
    const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key);

    this.dialogService.edit(
      SiteManageDialogComponent,
      {
        site: this.siteService.findByIdForEdit(site.id)
      },{
        width: hasCustomFields ? '1000px' : '650px'
      })
      .subscribe((items: any) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(mergeMap(x => x.afterClosed()))
            .subscribe((result) => {
              this.afterModalClosed(result, site);
            })
        }
      });
  }

  onSelectionChanged(row) {
    this.selection = <Site>row.selectedRowsData[0];
  }

  ngOnInit() {
    this._init();
  }

  ngAfterViewInit(): void {
    this.siteGridService.create(this.dataGrid.instance, {});
    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.siteGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService()  {
    return this.siteGridService;
  }

  getDefaultSort() {
    return [['site_id', 'ASC'], ['vendor_id', 'ASC']];
  }
}
