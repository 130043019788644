import {TopUsageFilterEntry} from "./top-usage-filter/top-usage-filter.component";
import {QueryParamType} from '../query/query-param-type';
import {IFilterLookupProvider} from './filter-lookup.provider';
import {AddressFilterEntry} from './address-filter-entry';
import {SitesFilterEntry} from './sites-filter-entry';
import {VendorAccountFilterEntry} from "./vendor-account-filter-entry";

export class FilterEntry {
  field: string;
  label: string;
  exact: boolean;
  caseSensitive: boolean;
  type: QueryParamType;
  lookupProvider: IFilterLookupProvider;
  lookupItems: Array<any> = [];
  editor: string;
  index: number;
  visible = true;
  trueLabel: string;
  falseLabel: string;
  lookupProviderName: string;
  addressFields: AddressFilterEntry;
  sitesFields: SitesFilterEntry;
  vendorFields: VendorAccountFilterEntry;
  expanded = false;
  isActiveCard = false;
  exactMatch: boolean;
  matchCase: boolean;
  max: any;
  defaultValue: Array<number>;
  topUsage: TopUsageFilterEntry
  fieldToFilterWith: string | null;
  secondRowField: any;


  getEditor() {
    if (this.editor) {
      return this.editor;
    } else if (this.type === QueryParamType.String) {
      return 'inputText';
    } else if (this.type === QueryParamType.Date) {
      return 'inputDate';
    } else if (this.type === QueryParamType.Number) {
      return 'inputNumber';
    } else if (this.type === QueryParamType.NegativeNumber) {
      return 'inputNegativeNumber';
    } else if (this.type === QueryParamType.Boolean) {
      return 'booleanSelect';
    } else if (this.type === QueryParamType.Lookup) {
      return 'inputLookup';
    }
  }

  static isEqual(object1, object2) {
    if (!object1 && !object2) return true;
    if (object1 && !object2) return false;
    if (!object1 && object2) return false;

    const keys1 = Object.keys(JSON.parse(JSON.stringify(object1)));
    const keys2 = Object.keys(JSON.parse(JSON.stringify(object2)));
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = FilterEntry.isObject(val1) && FilterEntry.isObject(val2);
      if (
        areObjects && !FilterEntry.isEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }
    return true;
  }

  static isObject(object) {
    return object != null && typeof object === 'object';
  }

}
