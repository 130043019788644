import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {FilterBaseComponent} from '../filter-base.component';
import {FilterEntry} from "../../../core/filter/filter-entry";
import {ICONS_ENUM} from "../../../core/resources/icons.enum";

const OPERATORS = {
  ILIKE: '$ilike',
  LIKE: '$like',
  EQ: '$eq',
};

@Component({
  selector: 'cas-filter-text',
  templateUrl: './filter-text.component.html',
  styleUrls: ['./filter-text.component.scss']
})
export class FilterTextComponent implements FilterBaseComponent, OnInit, OnChanges {

  private _value: any;

  ICONS = ICONS_ENUM;

  get value(): any {
    return this._value;
  }

  @Input() set value(value: any) {
    const val = value?.currentValue ?? value;
    this._value = val;
    this.setValue(val);
  }

  @Input() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() entry: FilterEntry;

  input: FormControl = new FormControl('');
  exactMatch = false;
  caseSensitive = false;

  ngOnInit(): void {
    this.input.valueChanges
      .subscribe((value: any) => {
        this.update(value);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setValue(changes.value);
  }

  toggleExactMatch(): void {
    this.exactMatch = !this.exactMatch;
    this.update(this.input.value);
  }

  toggleMatchCase(): void {
    this.caseSensitive = !this.caseSensitive;
    this.update(this.input.value);
  }

  private setValue(value: any): void {
    const currentValue = value?.hasOwnProperty('currentValue') ? value?.currentValue : value;
    const val = this.getSimpleValue(currentValue);
    if (val !== this.input.value) {
      this.input.setValue(val);
    }
  }

  private update(value): void {
    const result = this.getOutputValue(value, {
      exactMatch: this.exactMatch,
      caseSensitive: this.caseSensitive
    });
    this.change.emit(result);
  }

  private getSimpleValue(value): any {
    let val = value;
    if (value && typeof value === 'object') {
      val = Object.values(value)[0];
    }

    val = this.trimValue(val);
    return val;
  }

  private getOutputValue(inputValue, config = {exactMatch: false, caseSensitive: false}): any {
    let operator = OPERATORS.ILIKE;
    const {exactMatch, caseSensitive} = config;

    if (exactMatch) {
      operator = OPERATORS.EQ;
    } else if (caseSensitive) {
      operator = OPERATORS.LIKE;
    }

    if (!inputValue) {
      return null;
    } else {
      return {[operator]: exactMatch ? inputValue : `%${inputValue}%`};
    }
  }

  private trimValue(val) {
    if(!val) return val;
    const character = '%';
    const first = [...val].findIndex(char => char !== character);
    const last = [...val].reverse().findIndex(char => char !== character);
    return val.substring(first, val.length - last);
  }

}
