import {StateContext, Store} from "@ngxs/store";
import {QueryBuilder} from "../query/query.builder";
import Query from "../../core/query/query";
import {UpdateQueryOptions} from "./query.actions";

export const QUERYABLE_STATE_MODEL_DEFAULTS = {
  queryHistory: new Map<string, Query>(),
};

export interface QueryableStateModel<T> {
  queryHistory: Map<string, Query>;
}

export class QueryableState {

  constructor(public store: Store, public queryBuilder: QueryBuilder) {
  }

  /**
   * Updates query based on options
   * @param ctx State context
   * @param options State options
   */
  updateQuery(ctx: StateContext<any>, {options}: { options: UpdateQueryOptions }): void {

    const state = ctx.getState();
    const queryBuilder = this.queryBuilder.build(options.query, options.key);
    // .setOrGenerateKey(options?.key || options?.query?.key);

    const query = queryBuilder.getQuery();
    const queryFromHistory = state.queryHistory.get(query.key);

    if (queryFromHistory) {
      ctx.patchState({query: queryFromHistory});
    } else {
      state.queryHistory.set(query.key, query);
      ctx.patchState({query});
    }

    // DOESN'T WORK // keep only 20 records in query history
    // if (state.queryHistory && state.queryHistory.size > 20) {
    //   const firstKey = [...state.queryHistory][0][0];
    //   state.queryHistory.delete(firstKey)
    //   ctx.patchState({queryHistory: state.queryHistory})
    // }
  }
}
