import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BaseService} from '../../core/base.service';
import {Order} from './order';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import { DialogService } from 'app/shared/dialog/dialog.service';
import { ConfirmCancelDialogComponent } from 'app/shared/dialog/confirm-cancel-dialog/confirm-cancel-dialog.component';

@Injectable()
export class OrderService extends BaseService<Order> {

  constructor(
    public restangular: Restangular, 
    private entityLock: EntityLockService,
    public dialogService: DialogService
  ) {
    super('order', restangular, entityLock);
  }

  openConfirmationDialog({title, bodyText, order}) {
    return this.dialogService.open(ConfirmCancelDialogComponent, {
      title,
      bodyText,
      order
    }, {
      width: order ? '850px' : '450px',
    });
  }
}
