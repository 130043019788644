export const inventoryFilterContext = {
  filters: [
    {
      field: 'sp_ckt_id',
      label: 'SP Service ID',
      type: 'string',
      index: 1,
      visible: true
    },
    {
      field: 'internal_id',
      label: 'Internal ID',
      type: 'string',
      index: 3,
      visible: true
    },
    {
      field: 'vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 4,
      visible: true
    },
    {
      field: 'install_date',
      label: 'Install Date',
      type: 'date',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 5,
      visible: true
    },
    {
      field: 'siteA.site_id',
      label: 'Site A (Carrier Location)',
      type: 'lookup',
      lookupProviderName: 'site',
      index: 6,
      visible: true,
    },
    {
      field: 'siteZ.site_id',
      label: 'Site Z (Customer Location)',
      type: 'lookup',
      lookupProviderName: 'site',
      index: 7,
      visible: true,
      secondRowField: 'siteZ.building.address.search_address'
    },
    {
      field: 'inventory_status.key',
      label: 'Status ',
      type: 'list',
      lookupProviderName: 'inventoryStatus',
      index: 8,
      visible: true
    },
    {
      field: 'inventory_ckt_type.value',
      label: 'Service Type',
      type: 'lookup',
      lookupProviderName: 'circuitType',
      index: 9,
      visible: true
    },
    {
      field: 'technology.value',
      label: 'Svc Tech',
      type: 'lookup',
      lookupProviderName: 'circuitTech',
      index: 10,
      visible: true
    },
    {
      field: 'circuit_bandwidth.value',
      label: 'Service Bandwidth',
      type: 'lookup',
      lookupProviderName: 'bandwidth',
      index: 11,
      visible: true
    },
    {
      field: 'bandwidth_max.value',
      label: 'Port Bandwidth',
      type: 'lookup',
      lookupProviderName: 'bandwidth',
      index: 12,
      visible: true
    },
    {
      field: 'vlan_id',
      label: 'VLAN ID',
      type: 'string',
      index: 13,
      visible: true
    },
    {
      field: 'is_customer_provided_access',
      label: 'Customer Provided Access',
      type: 'boolean',
      trueLabel: 'Yes',
      falseLabel: 'No',
      index: 14,
      visible: true
    },
    {
      field: 'type.value',
      label: 'Network Type',
      type: 'lookup',
      lookupProviderName: 'networkType',
      index: 15,
      visible: true
    },
    {
      field: 'ban_object.subclient.value',
      label: 'Subclient',
      type: 'lookup',
      lookupProviderName: 'subclient',
      index: 16,
      visible: true
    },
    {
      field: 'customer_inventory.customer_circuit_id',
      label: 'Customer Service ID',
      type: 'string',
      index: 17,
      visible: true
    },
    {
      field: 'ban',
      label: 'Account',
      type: 'lookup',
      lookupProviderName: 'account',
      index: 18,
      visible: true
    },
    {
      field: 'customer.company',
      label: 'Customer ',
      type: 'lookup',
      lookupProviderName: 'customer',
      index: 19,
      visible: true
    },
    {
      field: 'disc_date',
      label: 'Disconnect Date',
      type: 'date',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 20,
      visible: true
    },
    {
      field: 'currency_obj.currency',
      label: 'Currency',
      type: 'lookup',
      lookupProviderName: 'currency',
      index: 21,
      visible: true
    },
    {
      field: 'est_mrc',
      label: 'MRC',
      type: 'negativeNumber',
      index: 22,
      visible: true
    },
    {
      field: 'est_nrc',
      label: 'NRC',
      type: 'negativeNumber',
      index: 23,
      visible: true
    },
    {
      field: 'id',
      label: 'System ID',
      type: 'negativeNumber',
      index: 1
    },
  ]
};
