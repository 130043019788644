<form [formGroup]="sitesFilterForm">

  <div style="flex-direction: column;">
      <div fxFlex class="form-group address-entry-container ca-form">
        <mat-form-field hiDensityInput>
          <input matInput type="text"
                 placeholder="{{entry?.sitesFields?.site?.label || 'Site name'}}"
                 [matAutocomplete]="autoSite"
                 formControlName="site">
        </mat-form-field>
        <mat-autocomplete #autoSite="matAutocomplete">
          <mat-option *ngFor="let site of sites"
                     [value]="site.value">{{ site.text }}
          </mat-option>
        </mat-autocomplete>
      </div>

      <div fxFlex class="form-group address-entry-container ca-form">
        <mat-form-field hiDensityInput>
          <input placeholder="{{entry?.sitesFields?.building?.label || 'Building name'}}"
                 [matAutocomplete]="autoBuilding"
                 formControlName="building"
                 type="text" matInput>
        </mat-form-field>
        <mat-autocomplete #autoBuilding="matAutocomplete">
          <mat-option *ngFor="let building of buildings"
                     [value]="building.value">{{ building.text }}
          </mat-option>
        </mat-autocomplete>
      </div>

      <div fxFlex class="form-group address-entry-container ca-form">
        <mat-form-field hiDensityInput>
          <input placeholder="{{entry?.sitesFields?.address1?.label || 'Address'}}"
                 [(ngModel)]="internalQuery[FIELDS.ADDRESS1]"
                 [ngModelOptions]="{standalone: true}"
                 (ngModelChange)="setQuery()"
                 type="text" matInput>
        </mat-form-field>
      </div>

      <div fxFlex class="form-group address-entry-container ca-form">
        <mat-form-field hiDensityInput>
          <input placeholder="{{entry?.sitesFields?.city?.label || 'City'}}"
                 [(ngModel)]="internalQuery[FIELDS.CITY]"
                 [ngModelOptions]="{standalone: true}"
                 (ngModelChange)="setQuery()"
                 type="text" matInput>
        </mat-form-field>
      </div>
      <div fxFlex fxLayout="row" class="ca-form" fxLayoutGap="12px">
        <div fxFlex class="form-group">

          <ca-default-picker hiDensityInput>
            <mat-form-field>
                        <mat-select placeholder="{{entry?.sitesFields?.subdivision?.label || 'State'}}"
                        [(ngModel)]="internalQuery[FIELDS.SUBDIVISION]"
                        [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="setQuery()">
              <mat-option *ngFor="let val of entry?.sitesFields?.subdivision?.lookupItems"
                          [value]="val.key">{{ val.value }}
              </mat-option>
            </mat-select>
            </mat-form-field>
          </ca-default-picker>

        </div>

        <div fxFlex class="form-group address-entry-container ca-form">
          <mat-form-field hiDensityInput>
            <input placeholder="{{entry?.sitesFields?.postal_code?.label || 'Zip'}}"
                   [(ngModel)]="internalQuery[FIELDS.POSTAL_CODE]"
                   [ngModelOptions]="{standalone: true}"
                   (ngModelChange)="setQuery()"
                   type="text" matInput>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group address-entry-container ca-form">
        <mat-form-field hiDensityInput>
          <input placeholder="{{entry?.sitesFields?.vendor?.label || 'Vendor name'}}"
                 [matAutocomplete]="autoVendor"
                 formControlName="vendor"
                 type="text" matInput>
        </mat-form-field>
        <mat-autocomplete #autoVendor="matAutocomplete">
          <mat-option *ngFor="let vendor of vendors"
                     [value]="vendor.value">{{ vendor.text }}
          </mat-option>
        </mat-autocomplete>
      </div>
  </div>
</form>
