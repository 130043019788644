<div class="grid-body app-content-list dx-nodata-absolute">
  <dx-data-grid #dataGrid 
      (onSelectionChanged)="onSelectionChanged($event)" 
      (onCellClick)="onCellClick($event)"
      [columns]="columns" 
      [dataSource]="documents" 
      [showBorders]="true">
    <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>
  </dx-data-grid>

  <ca-pager
    [gridPager]="defaultGridPager"
    [pageContext]="documentGridService"
    [query]="query"
    class="app-pager ca-default-border-top"
    (onChange)="loadData(query)"
  ></ca-pager>
</div>
