import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {
    SortingService,
    SortingBuilder
  } from '../../../shared/sorting/sorting.service';
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {HistoryService} from "../../../shared/history/history.service";
import Query from "../../../core/query/query";
import {AlertService} from '../../../shared/alert/alert.service';
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {HistoryComponent} from "../../../shared/history/history.component";
import {DialogService} from '../../../shared/dialog/dialog.service';
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {ConfirmDialogComponent} from "../../../shared/dialog/confirm-dialog/confirm-dialog.component";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {GridSettingsComponent} from "../../../core/grid/grid-settings.component";
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';

import {Contract} from '../../core/contract';
import {ContractService} from '../../core/contract.service';
import {Inventory} from '../../../inventory/core/inventory';
import {InventoryQuery} from '../../../inventory/core/inventory.query';
import {InventoryService} from '../../../inventory/core/inventory.service';
import {InventoryFilterService} from "../../core/contract-inventory-filter.service";
import {ContractInventoryGridService} from '../../core/contract-inventory-grid.service';
import {ContractInventoryManageDialogComponent} from "../../shared/contract-inventory-manage-dialog/contract-inventory-manage-dialog.component";

@Component({
    selector: 'app-contract-details-inventory',
    templateUrl: './contract-details-inventory.component.html',
    styleUrls: ['./contract-details-inventory.component.scss']
  })
  export class ContractInventoryComponent extends PageDetailsComponent implements OnInit, AfterViewInit {

    @ViewChild('panelSide') panelSide;
    @ViewChild('history') history: HistoryComponent;
    @ViewChild('testNotes') testNotes;
    @ViewChild('inventoryGrid') inventoryGrid: DxDataGridComponent;

    inventoryMessages: IMessagesResourceService;
    readonly INVENTORY_MODULE: string = 'inventory';
    readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

    public contract: Contract;
    public inventoryQuery: InventoryQuery = new InventoryQuery();
    public inventories: Array<Inventory> = [];
    public inventoryColumns: Array<any>;
    public delaySelectionChange = false;
    selectedInventory: Array<any> = [];

    public filter: any;
    public selectionActive = false;
    public inventorySorting: SortingBuilder;
    public inventoryColumnsSorting: any[];
    defaultGridPager: number = 20;
    public unlinkInventoryQuery: Query = new Query({
      'order': [['id', 'ASC']]
    });
    public notesCount: number = 0;

    constructor(
        public contractService: ContractService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        public settingsService: UserSettingsService,
        public inventoryService: InventoryService,
        public inventoryGridService: ContractInventoryGridService,
        public inventoryFilterService: InventoryFilterService,
        public dialog: DialogService,
        public sortingService: SortingService,
        public alert: AlertService,
        public historyService: HistoryService
    ) {
    super(new PageContext({
        name: 'app.contract.contract-details-inventory',
        settings: settingsService
    }));

    this.inventorySorting = this.sortingService.builder();
    this.inventoryMessages = ResourcesService.messages(this.INVENTORY_MODULE);

    }

    ngOnInit(): void {
        this.contract = this.route.parent.snapshot.data.contract;
        this.inventoryGridService.loadSettings()
        .subscribe((settings) => {
          this.initInventoryColumns(settings);
        });

    }

    initInventoryColumns(settings?) {
        this.inventoryColumnsSorting = this.inventoryGridService.getOrderBy(settings && settings.sorting ? settings.sorting : []);
        this.inventoryColumns = this.inventoryGridService.getColumns(settings && settings.columns ? settings.columns : []);

        this.defaultGridPager = settings ? settings.gridPager : 20;
        this.inventoryQuery.limit = this.defaultGridPager;

        if (this.inventoryColumnsSorting.length) {
          this.inventoryQuery.orderBy = this.inventoryColumnsSorting;
        }

        if (this.inventoryGrid && this.inventoryGrid.instance) {
          this.inventoryGrid.instance.option('columns', this.inventoryColumns);
        }
        this.loadInventories()
      }

    public loadInventories(query?) {
        this.delaySelectionChange = true;
        if(query){
          this.inventoryQuery = query;
        }

        // this.inventoryService.findAllContracts(this.inventoryQuery, this.contract.id)
        this.inventoryService.findContractInventories(this.inventoryQuery, this.contract.id)
          .subscribe((result) => {
            this.inventories = result.items;
            this.inventoryQuery.total = result.total;
            if (this.inventories.length) {
              setTimeout(() => {
                this.delaySelectionChange = false;
                this.inventoryGrid.instance.selectRows(this.selectedInventory, false);
              }, 500);
            }
          }, (err) => {
            console.log('inventoryService.findAll error', err);
          });
    }

    addInventory(){
      this.dialog.open(ContractInventoryManageDialogComponent, {
        contract: this.contract,
        inventoryIds: this.inventories.map(inv => inv.id)
      }, {width: '1400px'})
        .afterClosed()
        .subscribe(result => {
          if (result) {
            this.history.refreshList();
            this.alert.success('', this.inventoryMessages.get('ASSIGNED_SUCCESS'));
            this.loadInventories();
          }
        });
    }

    removeInventory(){
      const selectedInventoryIds = this.selectedInventory.map(inventory => inventory.id)
      this.dialog.open(ConfirmDialogComponent)
        .afterClosed()
        .subscribe(result => {
          if (result) {
            this.unlinkInventoryQuery.where['contract_id'] = this.contract.id
            this.unlinkInventoryQuery.where['inventory_id'] = {'$in' : selectedInventoryIds}
            this.inventoryService.unlinkInventory(this.unlinkInventoryQuery,this.contract.id)
              .subscribe(res => {
                this.history.refreshList();
                this.alert.success('', this.inventoryMessages.get('UNASSIGNED_SUCCESS'));
                this.selectedInventory = [];
                this.selectionActive = true;
                this.loadSelected()
                this.loadInventories();
              })
          }

        });
    }

    loadSelected() {
        if (this.inventoryQuery && !this.inventoryQuery['where'].hasOwnProperty('id')) {
          this.filter = JSON.parse(JSON.stringify(this.inventoryQuery['where']));
        }
        if (this.selectionActive) {
          this.inventoryQuery.remove('id');
          this.inventoryQuery['where'] = this.filter;
        } else {
          this.inventoryQuery['where'] = {};
          this.inventoryQuery.set('id', {
            $in: this.selectedInventory.map(x => {
              return x.id;
            })
          });
        }

        this.inventoryQuery.offset = 0;
        this.inventoryQuery.page = 1;
        this.selectionActive = !this.selectionActive;
        this.loadInventories(this.inventoryQuery);
    }

    onInventoryCellClick(event) {
        if (event.rowType === 'header' && event.column.allowSorting) {
          this.inventorySorting.apply(event, this.inventoryQuery);
          this.loadInventories(this.inventoryQuery);
        }
    }

    findIndexInSelectedInventoryRows(id): number {
        for (let i = 0, l = this.selectedInventory.length; i < l; i++) {
          if (this.selectedInventory[i].id === id ) {
            return i;
          }
        }
        return -1;
    }

  onSelectionInventoryChanged(event) {
    if (!this.delaySelectionChange && this.inventories.length) {

      if (event.currentDeselectedRowKeys.length) {
        event.currentDeselectedRowKeys.forEach(item => {
          let index = this.findIndexInSelectedInventoryRows(item.id);
          if (index > -1) {
            this.selectedInventory.splice(index, 1);
          }
        });
      }

      if (event.currentSelectedRowKeys.length) {
        event.currentSelectedRowKeys.forEach(item => {
          let index = this.findIndexInSelectedInventoryRows(item.id);
          if (index === -1) {
            this.selectedInventory.push(item);
          }
        });
      }
      if (this.selectionActive && !this.selectedInventory.length) {
        this.loadSelected();
      }
    }
  }

  inventoryCsv() {
    const { inventoryGridService, inventoryService, inventoryQuery: query } = this;
    inventoryGridService.csvMap().subscribe(fields => {
      inventoryService.exportToCSV(
        'Inventory',
        {
          fields,
          query,
          settings: {currencyField: 'currency_obj.currency'},
          fetchDataHandler: (query) => { return inventoryService.findContractInventories(query, this.contract.id)}
        })
    });
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  toggleFilter() {
    this.toggleSider(this.SECTIONS.FILTER_SECTION_NAME);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME);
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME);
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
  }

  clearInventoryFilter(inventoryQuery){
    this.loadInventories(inventoryQuery)
  }

  inventoryGridSettings() {
    this.dialog
      .open(GridSettingsComponent, {
        service: this.inventoryGridService,
        sliderMinimum: 1
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this.initInventoryColumns(settings)
        }
      });
  }
}
