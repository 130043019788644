
import {EventEmitter, Injectable} from '@angular/core';
import {BrandService} from './shared/brand/brand.service';

@Injectable()
export class AppService {
  public onChange: EventEmitter<any>;

  constructor(public brandService: BrandService) {
    this.onChange = new EventEmitter();

    // TODO: This goes somewhere else
    this.brandService.get('favicon.png')
      .subscribe((result) => {
        const link = document.createElement('link');
        link.type = 'image/png';
        link.rel = 'icon';
        link.href = result.url;
        document.getElementsByTagName('head')[0].appendChild(link);
      });
  }

  emit(type: string, object: any) {
    this.onChange.emit({
      type: type,
      object: object
    })
  }

  subscribe(generatorOrNext?: any, error?: any, complete?: any) {
    this.onChange.subscribe(generatorOrNext, error, complete);
  }

  unsubscribe() {
    this.onChange.unsubscribe();
  }
}
