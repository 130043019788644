export const findIndexInSelectedCharges = (chargeId, selectedCharges): number => {
  for (let i = 0, l = selectedCharges.length; i < l; i++) {
    if (selectedCharges[i].id === chargeId) {
      return i;
    }
  }
  return -1;
}

export const sortChargesByIdAsc = (valueObject) => {
  return Object.keys(valueObject).map(value =>
  {
    return valueObject[value].sort((a, b) => {
      (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
    })
  })
}

export const sortCalculatedChargesByChargeId = (chargeList) => chargeList?.sort((a, b) => a.id - b.id)

export const getTotalChargeAmountFromInvoice = (value) => {
  return value[0].billed_mrc
}

export const calculateDisputesFromTotalAmount = (value, totalChargeAmt) => {
  return value.map(charge => {
    let percentage = charge.chg_amt / totalChargeAmt * 100 || 0;
    let variance = charge.variance;
    return { id: charge.id, value: +charge.chg_amt - +variance * percentage / 100 }
  })
}

export const groupDisputesBySpid = (value) => {
  const spidFinal = []
  value.forEach(charge => {
    if (!Array.isArray(spidFinal[charge.cp_ckt])) {
      spidFinal[charge.cp_ckt] = [charge]
    } else {
      spidFinal[charge.cp_ckt].push(charge)
    }
  })
  return spidFinal
}
