import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class AlertInventoryChargeTotalGridService extends GridService {


  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super("app.alert-inventory.inventory-charge-total", userSettings);
  }

  defaultColumns = [
    {
      caption: 'Invoice ID',
      dataField: 'alert.invoice_id',
      width: 120
      },
    {
    caption: 'Invoice Number',
    dataField: 'alert.invoice.sp_inv_num',
    width: 150
    },
    {
      caption: 'Account',
      dataField: 'alert.account_no',
      width: 150
    },
    {
      caption: 'Vendor',
      dataField: 'alert.vendor.name',
      width: 120
    },
    {
      caption: 'Message',
      dataField: 'message',
      width: 240
    }
  ];

}
