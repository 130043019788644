import {Injectable} from '@angular/core';

import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class DisputeChargesStaticGridService extends GridService {
  public disputeCategories: any = [];

  defaultColumns = [
    {
      caption: '',
      headerCellTemplate: 'selectedChargesHeaderTemplate',
      cellTemplate: 'chargeNoteTemplate',
      width: 60,
      fixed: true,
      allowSorting: false
    },
    {
      caption: 'Dispute Charge ID',
      dataField: 'dispute_charge_id.disp_chg_id',
      allowEditing: false,
      width: 250
    },
    {
      caption: 'Account',
      dataField: 'charge.acct_level_1',
      allowEditing: false,
      width: 160
    },
    {
      caption: 'Subclient',
      dataField: 'charge.invoice.account.subclient.value',
      allowEditing: false,
      width: 160
    },
    {
      cellTemplate: 'disputeInvoiceCellTemplate',
      caption: 'Invoice Number',
      dataField: 'charge.invoice.sp_inv_num',
      allowEditing: false,
      width: 180
    },
    {
      caption: 'Invoice Date',
      dataField: 'charge.invoice.inv_date',
      allowEditing: false,
      dataType: 'date',
      cellTemplate: "dateTemplate",
      alignment: 'left',
      width: 130
    },
    {
      caption: 'Invoice Last Update',
      dataField: 'charge.invoice.header.updated_at',
      allowEditing: false,
      dataType: 'datetime',
      cellTemplate: "dateTimeTemplate",
      width: 180
    },
    {
      caption: 'Charge Type',
      dataField: 'charge.chg_class',
      allowEditing: false,
      width: 130
    },
    {
      caption: 'SPID',
      dataField: 'charge.sp_serv_id',
      allowEditing: false,
      width: 230
    },
    {
      caption: 'Charge Desc 1',
      dataField: 'charge.chg_desc_1',
      allowEditing: false,
      width: 230
    },
    {
      caption: 'Charge Desc 2',
      dataField: 'charge.chg_desc_2',
      allowEditing: false,
      width: 230
    },
    {
      caption: 'Beg Chg Date',
      dataField: 'charge.beg_chg_date',
      allowEditing: false,
      dataType: 'date',
      cellTemplate: "dateTemplate",
      alignment: 'left',
      width: 130
    },
    {
      caption: 'Charge Qty',
      dataField: 'charge.chg_qty1_billed',
      dataType: 'number',
      allowEditing: false,
      width: 130
    },
    {
      caption: 'Charge Rate',
      dataField: 'charge.chg_rate',
      allowEditing: false,
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      // csvDataType: 'float',
      alignment: 'right',
      width: 130
    },
    {
      caption: 'Billed Amount',
      dataField: 'charge.chg_amt',
      allowEditing: false,
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 130
    },
    {
      headerCellTemplate: 'disputeChargesHeaderTemplate',
      caption: 'Dispute Category',
      dataField: 'category.value',
      width: 150
    },
    {
      headerCellTemplate: 'disputeChargesHeaderTemplate',
      caption: 'Dispute Description',
      dataField: 'description',
      width: 200
    },
    {
      headerCellTemplate: 'disputeChargesHeaderTemplate',
      caption: 'Calculated Charges',
      dataField: 'calculated_amount',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 150
    },
    {
      caption: 'Dispute Amount',
      dataField: 'disputed_amount',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      allowEditing: false,
      width: 130
    },
    {
      caption: 'Currency',
      dataField: 'currency.currency',
      allowEditing: false,
      width: 130
    },
    {
      headerCellTemplate: 'disputeChargesHeaderTemplate',
      caption: 'Dispute Amount Awarded',
      dataField: 'dispute_value_awarded',
      cellTemplate: 'invoiceCurrencyTemplate',
      dataType: 'currency',
      alignment: 'right',
      width: 195
    },
    {
      caption: 'O/(U) Disp Amt Awarded',
      dataField: 'payback_amount',
      cellTemplate: 'invoiceCurrencyTemplate',
      allowEditing: false,
      dataType: 'currency',
      alignment: 'right',
      width: 130
    },
    {
      headerCellTemplate: 'disputeChargesHeaderTemplate',
      cellTemplate: 'withheldCellTemplate',
      caption: 'Payment Withheld',
      dataField: 'dispute_withheld',
      dataType: 'boolean',
      allowEditing: false,
      width: 130,
      transformValue: {
        true: 'Yes',
        false: 'No'
      }
    },
    {
      headerCellTemplate: 'disputeChargesHeaderTemplate',
      caption: 'Resolution Date',
      dataField: 'resolution_date',
      dataType: 'date',
      cellTemplate: "dateTemplate",
      width: 150
    },
    {
      alignment: 'left',
      caption: 'Status',
      dataField: 'status_obj.value',
      allowEditing: false,
      cellTemplate: 'statusTemplate',
      width: 170
    }
  ];

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.cost.dispute-charges-static-grid', userSettings);
  }
}
