import {Injectable, Type} from '@angular/core';
import {EntityLock} from './entity-lock';
import {EntityLockData} from './entity-lock-data';
import {EntityLockAlertComponent} from './entity-lock-alert.component';
import {DialogService} from "../dialog/dialog.service";
import {Restangular} from "ngx-restangular";
import {Router} from "@angular/router";

@Injectable()
export class EntityLockService {

  locks: Array<EntityLock> = [];

  constructor(private restangular: Restangular,
              private router?: Router) {
  }

  // TODO: make this method to return observable for God sake so the client can now when the process is done !!!
  cancelAll() {
    this.locks.forEach((lock) => {
      lock.killTimer();
      this.remove(lock)
    });
    this.locks = [];
  }

  completeAll() {
    this.locks.forEach((lock) => {
      lock.killTimer();
      this.remove(lock)
    });
    this.locks = [];
  }

  createLock(
    lockData: EntityLockData,
    dialogRef?: any,
    dialogService?: any,
    component: Type<any> = EntityLockAlertComponent) {
    const lock = new EntityLock(lockData, dialogRef, dialogService, component, this.router);
    this.locks.push(lock);
    return lock;
  }

  remove(lock) {
    return this.restangular.one('data-lock', lock.lockData['$lock'].id).remove();
  }
}
