import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";

import { InvoiceFacepage } from '../core/invoice-facepage';
import { GLBatchService } from '../../gl-batch/core/gl-batch.service';
import { UserService } from '../../user/core/user.service';
import { AlertService } from '../../shared/alert/alert.service';
import { User } from '../../user/core/user';
import { GLBatch } from '../../gl-batch/core/gl-batch.model';
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { GlBatchCreateInvoiceGridService } from 'app/gl-batch/core/gl-batch-create-invoice-grid.service';
import {InvoiceFacepageService} from "../core/invoice-facepage.service";
import {InvoiceBalanceErrorDialogComponent} from "../shared/invoice-balance-error-dialog/invoice-balance-error-dialog.component";
import {DialogService} from "../../shared/dialog/dialog.service";
import Query from "../../core/query/query";
import {CommonAlertService} from '../../common-alert/core/common-alert.service';
import { isArray } from 'rxjs/internal-compatibility';
import {InvoiceQuery} from "../core/invoice.query";
import { ConfigService } from 'app/core/config/config.service';


@Component({
  selector: 'app-gl-batch-create-dialog',
  templateUrl: './gl-batch-create-dialog.component.html',
})
export class GLBatchCreateDialogComponent implements OnInit, AfterViewInit {

  invoices: Array<InvoiceFacepage>;
  @ViewChild('invoicesGrid') invoicesGrid: DxDataGridComponent;
  columns: any;

  createDate: Date = new Date();
  creator: string;
  isInvoicesOnHold: boolean = true;

  public isProcessing: boolean = false;
  invoiceNotInBalance: InvoiceFacepage;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'invoice';
  batchPaymentIconEnabled = false;

  constructor(public dialogRef: MatDialogRef<GLBatchCreateDialogComponent>,
              public glBatchService: GLBatchService,
              public glBatchCreateInvoiceGridService: GlBatchCreateInvoiceGridService,
              public userService: UserService,
              public invoiceService: InvoiceFacepageService,
              public loaderService: LoaderService,
              public dialog: DialogService,
              public commonAlertService: CommonAlertService,
              public configService: ConfigService,
              public alertService: AlertService) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {

    this.columns = this.glBatchCreateInvoiceGridService.columns();
    this.configService.get().subscribe((config) => {
      this.batchPaymentIconEnabled = config.invoice_flow_settings?.merge_batch_into_one_step && config.gl_coding?.format === 'tailwind'
    });

    this.userService.me()
      .subscribe(
        ((me: User) => {
          this.creator = me.full_name;
        }),
        (error) => {
          console.log(error);
        }
      );

    let ids = this.invoices.map(item => item.invoice_id);
    this.isInBalance(ids);
  }

  ngAfterViewInit(): void {

    this.glBatchCreateInvoiceGridService.create(this.invoicesGrid.instance, {
      selection: {
        mode: 'none'
      },
      //height: 350,
      allowSorting: false
    });
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  onSubmit() {
    if (this.invoices && this.invoices.length) {

      let newQuery: InvoiceQuery = new InvoiceQuery();
      newQuery.set('id', {
        $in: this.invoices.map((x) => {
          return x.id;
        })
      });

      this.invoiceService.isInvoiceOnHold(newQuery)
        .subscribe(res => {
          let newArray = []
          this.isInvoicesOnHold = res.filter(invoice => {
            if(invoice.account.on_hold){
              newArray.push(invoice.invoice_id)
              return invoice
            }
          }).length > 0;

          if(this.isInvoicesOnHold){
            this.alertService.error('', `${this.messages.get('INVOICE_ON_HOLD_MESSAGE')}${newArray}`)
            this.dialogRef.close({success: false});
          } else {
            if (!this.invoiceNotInBalance) {
              let glBatch = new GLBatch();
              glBatch.invoices = this.invoices;

              this.isProcessing = true;
              this.loaderService.displayLoader();

              this.glBatchService.create(glBatch)
                .subscribe((result: GLBatch) => {
                  this.dialogRef.close(result);
                  this.isProcessing = false;
                  this.loaderService.hideLoader();
                }, (err) => {
                  let findInvoice: Array<InvoiceFacepage> = [];

                  this.invoices.map((invoice) => {
                    if(isArray(err.error.messages)) {
                      err.error.messages.map((message) => {
                        if(message.invoice_id === invoice.invoice_id){
                          findInvoice = [...findInvoice, invoice]
                        }
                      })
                      return
                    }
                    if (err.error.messages.invoiceIds) {
                      err.error.messages.invoiceIds.map((invId) => {
                        if (invId === invoice.invoice_id) {
                          findInvoice = [...findInvoice, invoice]
                        }
                      })
                    }
                  });

                  if(err.status === 409){
                    const errorMessages = err.error.messages
                    if (err.status === 409 && errorMessages[0] && errorMessages[0].message === 'Excel file not created successfully'){
                      for(let i=0; i < findInvoice.length; i++) {
                        this.commonAlertService.create(
                          {
                            message: `${this.messages['_MESSAGES_ENUM']['invoice']['GL_OUTPUT_ERROR']} Invoice ${findInvoice[i].invoice_id}`,
                            priority_id: 30,
                            status_id: 10,
                            type_id: 30,
                            invoice_id: findInvoice[i].invoice_id || null,
                            category_id: 20,
                            vendor_id: findInvoice[i].vendor_id || null,
                            vendor_name: findInvoice[i].sp_name || null,
                            account_no: findInvoice[i].account.account_no || null,
                            account_id: findInvoice[i].account_id || null,
                            sp_inv_num: findInvoice[i].sp_inv_num || null
                          });
                      }

                      this.alertService.error('', this.messages['_MESSAGES_ENUM']['invoice']['GL_OUTPUT_ERROR']);
                    } else if (err.status === 409 && typeof errorMessages == 'object' && !isArray(errorMessages) && errorMessages.message && errorMessages.message.indexOf('Invoice can be only part of one active batch') !== -1) {
                      this.alertService.error('', this.messages['_MESSAGES_ENUM']['invoice']['ACTIVE_BATCH_ERROR']);
                    } else if (err.status === 409 && typeof errorMessages == 'object' && !isArray(errorMessages) && errorMessages.message && errorMessages.message.indexOf('Non existing region in the config file') !== -1) {
                      this.alertService.error('', this.messages['_MESSAGES_ENUM']['invoice']['CONFIG_REGION_ERROR']);
                    } else if (err.status === 409 && typeof errorMessages == 'object' && !isArray(errorMessages) && errorMessages.message && errorMessages.message.indexOf('Non existing region on invoice account') !== -1) {
                      this.alertService.error('', this.messages['_MESSAGES_ENUM']['invoice']['ACCOUNT_REGION_ERROR']);
                    } else if (err.status === 409 && typeof errorMessages == 'object' && !isArray(errorMessages) && errorMessages.message && errorMessages.message.indexOf('Source file does not exist') !== -1) {
                      this.alertService.error('', this.messages['_MESSAGES_ENUM']['invoice']['SOURCE_FILE_ERROR'])
                    } else if (err.status === 409 && err.data.messages.invoices){
                      let ids = err.data.messages.invoices.map(invoice => invoice.invoice_id);
                      this.alertService.error('', `${this.messages['_MESSAGES_ENUM']['invoice']['UPDATED_INVOICES_ERROR']}: ${ids}`);
                    } else {
                      this.alertService.error('', 'Something went wrong');
                    }

                    this.dialogRef.close();
                  } else {
                    this.alertService.error('', this.messages.get('GL_BATCH_NO_INVOICES'));
                  }
                  this.isProcessing = false;
                  this.loaderService.hideLoader();
                });
            } else {
              this.dialog.open(InvoiceBalanceErrorDialogComponent,
                {
                  inv_num: this.invoiceNotInBalance
                });
            }
          }
        })

    } else {
      this.alertService.error('', this.messages.get('GL_BATCH_NO_INVOICES'));
    }
  }

  isInBalance(invoiceIds) {
    let query = new Query({
      where: {invoice_id: {$in: invoiceIds}},
      limit: 10000
    })
    this.invoiceService.getNonBalancedInvoices(query)
      .subscribe(res => {
        this.invoiceNotInBalance = res && res['0'] ? res['0'] : null;
      })
  }

}
