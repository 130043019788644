<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
<h1 mat-dialog-title>Vendor Accept</h1>
</div>
<div mat-dialog-content class="ca-dialog-content" *ngIf="form">

<form class="ca-form dialog-content-no-margin" [formGroup]="form">
  <div>
    <div class="flex-container" fxLayout="row">
      <div class="flex-item caGap" fxFlex="50%">
        <ca-date-picker caInput placeholder="Accept/Reject Date" [minDate]="minAllowedDate" formControlName="date" required="true"></ca-date-picker>
      </div>
    </div>
    <mat-form-field caInput class="caGap">
      <textarea formControlName="note" name="note" rows="3" matInput placeholder="Note"></textarea>
      <mat-hint *ngIf="form.hasError('minlength', ['note']) && form.controls.note.touched" class="error-class">Minimum 20 characters</mat-hint>
    </mat-form-field>
  </div>
</form>
</div>

 <mat-dialog-actions class="ca-dialog-action">
    <span class="example-fill-remaining-space"></span>
    <button mat-button type="submit" (click)="cancel()">
        Cancel
    </button>
    <button mat-button
            type="button"
            [disabled]="form?.invalid || !form?.controls['note'].value"
            (click)="reject(form)">
      Reject
    </button>
    <button mat-button
            type="button"
            style="margin-right: 15px;"
            (click)="approve(form)"
            [disabled]="form?.invalid || form.controls['date'].value < minAllowedDate">
      Approve
    </button>
  </mat-dialog-actions>
