
import {of as observableOf,  Observable } from 'rxjs';

import {takeUntil, mergeMap} from 'rxjs/operators';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LOOKUP_ENUM } from 'app/dictionary/core/lookup.enum';
import * as moment from 'moment';
import { DictionaryService } from 'app/dictionary/core/dictionary.service';
import { LOOKUP_MODELS_ENUM } from 'app/dictionary/core/lookup-models.enum';

import { QUOTE_SERVICE_STATUS } from '../core/quote-service-status.enum';
import { QUOTE_HEADER_STATUS } from '../core/quote-header-status.enum';
import { QuotingService } from '../core/quoting.service';
import { Quote } from '../core/quote';
import { QuotingDetailsGridService } from '../core/quoting-details-grid.service';
import { SummaryPageGridService } from '../core/summary-page-grid.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { AlertService } from '../../shared/alert/alert.service';
import { TabGroup, TabService } from '../../shared/tabs/tab.service';
import { QoutingRequestManageDialogComponent } from '../shared/qouting-offer-manage-dialog/qouting-offer-manage-dialog.component';
import { ConfirmQuoteDialogComponent } from '../shared/confirm-quote-dialog/confirm-quote-dialog.component';
import { DataLockService } from '../../core/data-lock/data-lock.service';
import { QuotingQuery } from '../core/quoting.query';
import { HistoryComponent } from '../../shared/history/history.component';
import { PageContext } from '../../core/page.context';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { QuoteRequestsCsvInterceptor } from '../core/quote-offers-csv-interceptor';
import { PageDetailsComponent } from '../../core/page-details.component';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import Entity from '../../core/entity.model';
import { ReportService } from '../../report/core/report.service';
import Query from '../../core/query/query';
import { QuotingOverviewGridService } from '../core/quoting-overview-grid.service';
import { EmailSendFormComponent } from '../shared/email-send-form/email-send-form.component';
import { QuotingServiceDialogComponent } from '../shared/quoting-service-manage-dialog/quoting-service-manage-dialog.component';
import { ConfirmDialogComponent } from '../../shared/dialog/confirm-dialog/confirm-dialog.component';
import { LoaderService } from '../../shared/loader/loader.service';


const SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
const QUOTE_SERVICE_OFFER_METHOD = LOOKUP_ENUM.QUOTE_SERVICE_OFFER_METHOD;
const QUOTE_SERVICE_OFFER_STATUS = LOOKUP_ENUM.QUOTE_SERVICE_OFFER_STATUS;

@Component({
  selector: 'app-quoting-details',
  templateUrl: './quoting-details.component.html',
  styleUrls: ['./quoting-details.component.css']
})
export class QuotingDetailsComponent extends PageDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  readonly DOCUMENT_INFO = {
    FOLDER: 'quotes',
    SUBFOLDER: '',
    PARENT_TYPE: this.SYSTEM_MODULE.QUOTE,
    ENTITY_TYPE: this.SYSTEM_MODULE.QUOTE
  };

  public selection: any;

  public tabChanged: boolean;

  public serviceQuery: QuotingQuery = new QuotingQuery({
    orderBy: [['id', 'ASC']],
    limit: 100000
  });

  public offerQuery: QuotingQuery = new QuotingQuery({
    orderBy: [['quote_service_offer_id', 'ASC']],
    limit: 100000
  });

  public overviewQuery: QuotingQuery = new QuotingQuery({
    orderBy: [['quoteService.id', 'ASC'], ['quote_service_offer_id', 'ASC']],
    limit: 100000
  });

  public quote: Quote;
  public quoteId: number;
  quoteServices: Array<any> = [];
  quoteServiceRequests: Array<any> = [];
  lineItems: Array<any> = [];

  overviewRequests: Array<any> = [];
  selectedOverviewRows: Array<any> = [];

  public tabGroup: TabGroup;
  public tabServices: any[];
  public activeTab = 0;
  public activeTabIndex = 0;

  public serviceQuoteId: number;

  public noteEntityId: number;
  public activeServiceIndex: number;

  @ViewChild('panelMore') panelMore;
  @ViewChild('panelMoreTabs') panelMoreTabs;
  @ViewChild('history') history: HistoryComponent;
  @ViewChildren('offerGrid') dataGrids: DxDataGridComponent;
  @ViewChildren('offerGrid') offerGrid: DxDataGridComponent;
  @ViewChild('summaryGrid') summaryGrid: DxDataGridComponent;
  @ViewChild('overviewGrid') overviewGrid: DxDataGridComponent;
  @ViewChild('documentsManager') documentsManager;

  public requestGridColumns: Array<any>;
  public summaryGridColumns: Array<any>;
  public overviewGridColumns: Array<any>;
  public sortingSummary: any[][];
  public sortingRequests: any[][];
  public sortingOverview: any [][];
  entities: Array<Entity> = [];
  public selectedRequest: any;
  public selectedQuoteService = null;

  public isClosed: any;
  public isQuoteClosed: any;
  public enableSelectQuote = false;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'quote';

  public notesCount = 0;

  public editButtonToolTip: string;


  public QUOTE_SERVICE_OFFER_STATUS = QUOTE_SERVICE_OFFER_STATUS;
  public QUOTE_SERVICE_OFFER_METHOD = LOOKUP_ENUM.QUOTE_SERVICE_OFFER_METHOD;
  selectedEntity: Entity;
  selectedMaster: Entity;

  groups: Array<any> = [];
  dataReady = false;

  SRM_REPORTS = {
    QUOTE_SUMMARY: {
      reportKey: 'quote_summary',
      fileNamePrefix: 'QuoteSummary'
    },
    QUOTE_DETAILED_SUMMARY: {
      reportKey: 'quote_detailed_summary',
      fileNamePrefix: 'QuoteDetailedReport'
    },
    QUOTE_SERVICE_SUMMARY: {
      reportKey: 'quote_service_summary',
      fileNamePrefix: 'QuoteServiceSummary'
    }
  };

  SRM_REPORT_VIEW_TYPE = {
    VIEW: 'view',
    CSV: 'csv'
  };

  readonly DOCUMENT_MANAGER_SETTINGS = {
    DELETE_DOCUMENT_ACTION: this.deleteDocument.bind(this),
    UPLOAD_DOCUMENT_ACTION: this.upload.bind(this),
    DOWNLOAD_DOCUMENT_ACTION: this.download.bind(this)
  };

  readonly OVERVIEW_TAB_KEY = 0.5;
  serviceTypes: any[];

  private otherServiceTypes: any;

  readonly QUOTING_SERVICE_TYPE = LOOKUP_ENUM.QUOTING_SERVICE_TYPE;
  constructor(public summaryPageGridService: SummaryPageGridService,
              public quotingDetailsGridService: QuotingDetailsGridService,
              public quotingService: QuotingService,
              public route: ActivatedRoute,
              public router: Router,
              public settingsService: UserSettingsService,
              public dialogService: DialogService,
              public alertService: AlertService,
              public dataLockService: DataLockService,
              public tabService: TabService,
              public reportService: ReportService,
              public overviewGridService: QuotingOverviewGridService,
              public dictionaryService: DictionaryService,
              public loaderService: LoaderService) {
    super(new PageContext({
      name: 'app.quoting.quoting-details',
      settings: settingsService
    }));
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  srmReport(srmReport, viewType) {
    let reportKey = srmReport.reportKey;
    let reportFilter;
    if (reportKey === this.SRM_REPORTS.QUOTE_SUMMARY.reportKey || reportKey === this.SRM_REPORTS.QUOTE_DETAILED_SUMMARY.reportKey) {
      reportFilter = { header_quote_id: this.quote.quote_id };
    } else if (reportKey === this.SRM_REPORTS.QUOTE_SERVICE_SUMMARY.reportKey && this.selectedQuoteService) {
      reportFilter = { service_id: this.selectedQuoteService.quote_service_id }
    }

    if (!reportFilter) {
      return;
    }
    if (viewType === this.SRM_REPORT_VIEW_TYPE.VIEW) {
      this.router.navigate(['report-gallery', reportKey, 'show'], { queryParams: reportFilter });
    } else if (viewType === this.SRM_REPORT_VIEW_TYPE.CSV) {
      let filterString = [];
      Object.keys(reportFilter).forEach(key => filterString.push(reportFilter[key]));
      let fileName = `${srmReport.fileNamePrefix}_${filterString.join('_')}_${moment().format('MM-DD-YYYY')}`;

      let report;
      let fields;

      this
        .reportService
        .loadByIdOrKey(reportKey)
        .subscribe(
          result => {
            report = result;
            fields = report
              .settings
              .fields
              .filter(field => field.selected)
              .map(field => ({ value: field.display_name, label: field.display_name }));

            report.limit = 1000000;
            report.offset = 0;

            const csvQuery = new Query({ where: reportFilter });

            report.dynamic_filter_query = csvQuery.where;

            this.reportService.exportToCSV(
              fileName,
              {
                fields,
                query: csvQuery,
                fetchDataHandler: this.reportService.execute.bind(this.reportService, { data: report })
              },
            );
          }
        );
    }
  }

  onTabChange(index) {
    this.activeTabIndex = index;
    let tab = this.tabGroup.tabs[index];
    if (tab) {
      this.onSelectedTabChange({index: tab.key});
      this.tabGroup.activate(tab.key);
      if (this.selectedQuoteService && this.activeTab !== 0 && this.activeTab !== 0.5 && this.activeTab !== 3) {
        this.serviceQuoteId = this.selectedQuoteService.id;
      }
    }
  }

  ngOnInit() {
    this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.QUOTING_SERVICE_TYPE.modelName).subscribe(res => {
      this.serviceTypes = res.items;
    });

    this.editButtonToolTip = this.messages.get('EDIT_QUOTE_TOOLTIP');
    this.tabGroup = this.tabService.create();
    this.initBasicGridSettings();

    this.route.params.pipe(
      takeUntil(this.destroy$))
      .subscribe((params: Params) => {
        let id = params['id'];
        this.quoteId = id;
        if (params ['serviceId']) {
          this.serviceQuoteId = params ['serviceId'];
          this.loadData(id, this.serviceQuoteId);
        } else {
          this.loadData(id);
        }
      });

    this.loadOtherServiceTypes();
  }

  private async loadOtherServiceTypes(): Promise<any> {
    const { items } = await this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.QUOTING_CLIENT_SERVICE_TYPE.modelName).toPromise();
    this.otherServiceTypes = items;
  }

  public getOtherServiceType(service_type_id = -1): string {
    const clientServiceType = this.otherServiceTypes.find((type: { id: number; value: string }) => type.id === service_type_id);
    return clientServiceType ? clientServiceType.value : 'N/A';
  }

  isDocumentCommandsVisible() {
    return this.activeTabIndex === this.tabGroup.tabs.length - 1;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.initSummaryGrid();
  }

  onViewEntityRequested(note) {
    // FIND SERVICE WHERE NOTE BELONGS
    let service = note.group_id;

    // ACTIVATE TAB
    let active = this.tabGroup.tabs.findIndex((tab) => tab.key === service);
    this.activeTabIndex = active;

    // TRIGGER DIRECTLY IF THE TAB IS ALREADY ACTIVE
    if (this.activeTab === note.group_id) {
      this.noteEntityId = note.entity_id;
      this.activeServiceIndex = this.quoteServices.findIndex((quoteService) => quoteService.id === service);
      let requestIndex = this.quoteServices[this.activeServiceIndex].offers
        .findIndex((serviceReq) => serviceReq.id === this.noteEntityId);
      this.offerGrid['_results'][this.activeServiceIndex].instance.selectRowsByIndexes([requestIndex]);
    } else {
      this.noteEntityId = note.entity_id;
      // FIND SERVICE INDEX TO ACCESS DATA GRID INDEX
      this.activeServiceIndex = this.quoteServices.findIndex((quoteService) => quoteService.id === service);
    }
  }

  public loadData(id: number, serviceId?) {
    this.toggleLoadingIndicator();
    this.quotingService.findById(id)
      .subscribe((quote: Quote) => {
        this.toggleLoadingIndicator(false);
        this.quote = quote;
        this.selectedMaster = new Entity(id, this.SYSTEM_MODULE.QUOTE, quote.quote_id);
        this.entities.push(this.selectedMaster);
        this.groups.push({ id: quote.id, type: this.SYSTEM_MODULE.QUOTE, label: quote.quote_id });
        if (serviceId != null) {
          this.loadServices(serviceId);
        } else {
          this.loadServices(null);
        }
        /* this.initSummaryGrid(); */
      });
  }

  public loadServices(tab) {
    this.toggleLoadingIndicator();
    this.quotingService.findServices(this.quote.id, this.serviceQuery)
      .subscribe((services) => {
        this.toggleLoadingIndicator(false);
        this.tabServices = [].concat(services.items);
        services.items.forEach((service) => {
          let entity = new Entity(service.id, this.SYSTEM_MODULE.QUOTE_SERVICE, service.quote_service_id);
          this.entities.push(entity);
          this.groups.push({ id: service.id, type: this.SYSTEM_MODULE.QUOTE_SERVICE, label: service.quote_service_id });
          service.offers = service.offers || [];
          service.offers.forEach((offer) => {
            entity.entities.push(new Entity(offer.id, this.SYSTEM_MODULE.QUOTE_SERVICE_REQUEST,
              `${service.quote_service_id}-${offer.quote_service_offer_id}`,
              `${offer.vendor.name}, ${this.resolveStatus(offer.status, offer.quote_type)},
              ${(offer.network_hub || {}).hub_name || 'N/A'}`));
          })
        });
        this.dataReady = true;
        this.quoteServices = services.items;
        this.loadTabs(tab);
        this.isQuoteServicesClosed();
      })
  }

  public loadAllRequests() {
    this.quotingService.findQuoteHeaderRequests(this.quote.id, this.overviewQuery)
      .subscribe((offers) => {
        this.overviewRequests = offers.items;
        this.overviewQuery.total = offers.total;
        /*this.emailTypeSummaryRowsIDs = this.overviewRequests.filter((item) => {
          return item.quote_type && item.quote_type.id === this.QUOTE_SERVICE_OFFER_METHOD.EMAIL;
        }).map(filtered => filtered.id);*/
      }, (err) => {
        console.log('Error loading quote header requests', err);
      });
  }

  public loadServiceRequests() {
    if (this.selectedQuoteService) {
      this.quotingService.findRequests(this.quote.id, this.selectedQuoteService.id, this.offerQuery)
        .subscribe((offers) => {
          this.quoteServiceRequests = offers.items;
          this.lineItems = this.quoteServiceRequests.map((request) => {
            return {
              id: request.id,
              label: request.quote_service_offer_id,
              type: this.SYSTEM_MODULE.QUOTE_SERVICE_REQUEST
            }
          });

          this.initRequestGrid();

          if (this.selectedQuoteService && this.selectedQuoteService.selected_offer) {
            /* this.quoteServiceRequests = [this.selectedQuoteService.selected_offer].concat(this.quoteServiceRequests
              .filter((offer) => {
                return offer.id !== this.selectedQuoteService.selected_offer.id
              })) */

            let tabIndex = this.tabServices.map(service => service.id).indexOf(this.activeTab);
            let grids = this.dataGrids['_results'];
            let dataGrid = grids[tabIndex];
            // FIND OFFER REQUEST AND TRIGGER SELECT


            setTimeout(() => {
              if (this.noteEntityId && this.activeServiceIndex && this.quoteServices[this.activeServiceIndex].id === this.activeTab) {
                let requestIndex = this.quoteServiceRequests.findIndex((serviceReq) => serviceReq.id === this.noteEntityId);
                this.offerGrid['_results'][this.activeServiceIndex].instance.selectRowsByIndexes([requestIndex]);
              } else if (dataGrid && dataGrid.instance) {
                dataGrid.instance.selectRowsByIndexes([0]);
              }
            }, 200);
          } else {
            setTimeout(() => {
              if (this.noteEntityId && this.activeServiceIndex) {
                let requestIndex = this.quoteServiceRequests.findIndex((serviceReq) => serviceReq.id === this.noteEntityId);
                this.offerGrid['_results'][this.activeServiceIndex].instance.selectRowsByIndexes([requestIndex]);
              }
            }, 200)
          }
        })
    }
  }

  openAnalyzeReport() {
    this.router.navigate(['quotes', this.quote.id, 'report', this.selectedQuoteService.id, 'show']);
  }


  bulkEmail() {
    this.dialogService.open(EmailSendFormComponent, {
      quoteRequests: this.selectedOverviewRows,
      quote: this.quote
    }, { width: '1100px' })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.loadAllRequests();
        }

        if (result && result.refresh) {
          this.loadServices(this.tabServices.length + 1);
          this.alertService.success('', this.messages.get('CREATE_SERVICE_SUCCESS'));
          this.history.refreshList();
        }
      });
  }

  mapNotesMeta(entity: any) {
    return {
      title: entity.title || entity.label,
      type: entity.type
    };
  }

  mapDocumentsMeta(entity: any, quote: Quote) {
    return {
      title: entity ? entity.title || entity.label : quote.quote_id,
      subtitle: entity ? entity.subtitle : '',
      type: entity ? entity.type : SYSTEM_MODULE.QUOTE
    };
  }

  showNotesViewLink(note) {
    // tslint:disable-next-line
    return parseInt(note.entity_type) === SYSTEM_MODULE.QUOTE_SERVICE_REQUEST;
  }

  csvSummary() {
    this.quotingDetailsGridService.csvMap().subscribe((field)=>{
      let fields = [{
        value: 'quoteService.quote_service_id',
        label: 'Quote Service ID',
        type: 'text'
      }].concat(field);

      let ids = this.quoteServices.map(service => service.id);
      let q = new QuotingQuery({
        limit: 100000,
        where: {
          quote_service_id: { $in: ids }
        },
        order: [['quote_service_id', 'ASC'], ['quote_service_offer_id', 'asc']]
      });
      this.quotingService.findAllRequests(this.quote.id, q)
        .subscribe((offers) => {
          let inerceptor = new QuoteRequestsCsvInterceptor();
          offers = offers.items.map((item) => {
            return inerceptor.transform(item);
          });

          this.quotingService.exportToCSV(
            this.quote.quote_id,
            {
              fetchDataHandler: () => observableOf(offers),
              fields,
            }
          );
        })
    });
  }

  csvServiceRequests() {
    const {
      quotingDetailsGridService,
      selectedQuoteService,
      quote,
      offerQuery,
      quotingService
    } = this;

    const fields = quotingDetailsGridService.csvMap();
    const csvOfferQuery = quotingService.generateCSVQuery(offerQuery);

    const quoteRequestTransformMiddleware = (items) => {
      const quoteRequestsCsvInterceptor = new QuoteRequestsCsvInterceptor();

      return items.map(item => quoteRequestsCsvInterceptor.transform(item));
    };

    quotingService.exportToCSV(
      selectedQuoteService.quote_service_id,
      {
        query: offerQuery,
        fields,
        fetchDataHandler: quotingService.findRequests.bind(quotingService, quote.id, selectedQuoteService.id, csvOfferQuery),
        middleware: [quoteRequestTransformMiddleware]
      }
    );
  }

  gridSettings() {
    let service;
    if (this.activeTab === 0) {
      service = this.summaryPageGridService;
    } else if (this.activeTab === this.OVERVIEW_TAB_KEY) {
      service = this.overviewGridService;
    } else {
      service = this.quotingDetailsGridService;
    }

    this.dialogService.open(GridSettingsComponent, {
      service: service
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.initBasicGridSettings();
          if (this.activeTab !== 0 && this.activeTab !== this.OVERVIEW_TAB_KEY) {
            this.loadServiceRequests();
          }
        }
      });
  }

  public loadTabs(key) {
    this.tabServices.sort((a, b) => {
      let diff = a.id - b.id;
      if (diff > 0) {
        return 1;
      }
      if (diff < 0) {
        return -1;
      }
      return 0;
    });
    this.tabGroup = this.tabService.create();
    this.tabGroup.addTab({ key: 0, title: 'Summary' });
    this.tabGroup.addTab({ key: this.OVERVIEW_TAB_KEY, title: 'Overview' });
    this.tabServices.forEach((service) => {
      this.tabGroup.addTab({ key: service.id, title: service.quote_service_id });
    });
    this.tabGroup.addTab({ key: 3, title: 'Documents' });

    if (this.serviceQuoteId) {
      this.activeTabIndex = this.tabGroup.tabs.findIndex(tab => tab.key === this.serviceQuoteId);
    } else if (key != null) {
      this.activeTabIndex = key;
    }

    this.tabGroup.activate(this.serviceQuoteId || this.activeTabIndex);

    if (key > 0) {
      this.onTabChange(this.activeTabIndex);
    } else {
      this.initSummaryGrid();
    }
  }

  isQuoteServicesClosed() {
    if (this.quoteServices.length > 0) {
      this.isQuoteClosed = this.quoteServices.every((service) => {
        return service.service_status !== QUOTE_SERVICE_STATUS.OPEN && service.service_status != null;
      });
      if (this.isQuoteClosed) {
        this.quotingService.updateQuote(this.quote.id, {

          quote_header_status: QUOTE_HEADER_STATUS.CLOSED,
          close_date: Date.now()
        })
          .subscribe((result) => {
            Object.assign(this.quote, result);
          })
      }
    } else {
      this.isQuoteClosed = false;
    }
  }

  isTermSelected(column) {
    if (!this.selectedQuoteService) {
      return false;
    } else {
      let terms = this.selectedQuoteService.service_term_id;
      return terms.indexOf(column.termNumber) >= 0;
    }
  }

  initBasicGridSettings() {
    this.summaryGridColumns = this.summaryPageGridService.columns();
    this.sortingSummary = this.summaryPageGridService.sorting();
    this.overviewGridColumns = this.overviewGridService.columns();
    this.sortingOverview = this.overviewGridService.sorting();
    this.requestGridColumns = this.quotingDetailsGridService.columns();
    this.sortingRequests = this.quotingDetailsGridService.sorting();
    if (this.sortingSummary.length) {
      this.serviceQuery.orderBy = this.sortingSummary;
    } else {
      this.serviceQuery.orderBy = [['id', 'ASC']];
    }

    if (this.sortingOverview.length) {
      this.overviewQuery.orderBy = this.sortingOverview;
    } else {
      this.overviewQuery.orderBy = [['quoteService.id', 'ASC'], ['quote_service_offer_id', 'ASC']];
    }
    if (this.sortingRequests.length) {
      if (this.selectedQuoteService && this.selectedQuoteService.offers) {
        this.selectedQuoteService.offers.orderBy = this.sortingRequests;
      }
      this.offerQuery.orderBy = this.sortingRequests;
    } else {
      this.offerQuery.orderBy = [['quote_service_offer_id', 'ASC']];
    }

    this.initRequestGrid()
    this.initOverviewGrid()
    this.initSummaryGrid()
    if (this.quote && (this.activeTab === 0 || this.activeTab === this.OVERVIEW_TAB_KEY)) {
      this.refresh()
    }
  }

  initSummaryGrid() {
    if (this.summaryGrid && this.summaryGrid.instance) {
      this.summaryPageGridService.create(this.summaryGrid.instance, {
        noDataText: this.summaryPageGridService.noDataMessage,
        sorting: {
          mode: 'multiple'
        }
      });
      this.summaryGrid.instance.option('columns', this.summaryGridColumns);
    }
  }

  initOverviewGrid() {
    if (this.overviewGrid && this.overviewGrid.instance) {
      this.overviewGridService.create(this.overviewGrid.instance, {
        noDataText: this.overviewGridService.noDataMessage,
        selection: {
          mode: 'multiple',
          selectAllMode: 'page'
        },
        sorting: {
          mode: 'multiple'
        }
      });
      this.overviewGrid.instance.option('columns', this.overviewGridColumns);
    }
  }

  initRequestGrid() {
    if (this.dataGrids && this.quoteServices.length > 0) {
      let grids = this.dataGrids['_results'];
      grids.forEach((dataGrid) => {
        if (dataGrid && dataGrid.instance && this.selectedQuoteService) {
          this.quotingDetailsGridService.create(dataGrid.instance, {
            noDataText: this.quotingDetailsGridService.noDataMessage,
            selection: {
              mode: this.selectedQuoteService.selected_offer ? 'none' : 'single'
            },
            sorting: {
              mode: 'multiple'
            },
            rowPrepared: function (rowElement, rowInfo) {
              if (rowInfo.data.selected_term === 1) {
                rowElement.css('background', 'green');
              } else if (rowInfo.data.selected_term === 2) {
                rowElement.css('background', 'yellow');
              } else if (rowInfo.data.selected_term === 3) {
                rowElement.css('background', 'red');
              } else if (rowInfo.data.selected_term === 4) {
                rowElement.css('background', 'green');
              } else if (rowInfo.data.selected_term === 5) {
                rowElement.css('background', 'yellow');
              } else if (rowInfo.data.selected_term === 6) {
                rowElement.css('background', 'red');
              }
            }
          });

          this.requestGridColumns.forEach((column) => {
            if (column.termNumber) {
              column.visible = this.isTermActive(column.termNumber);
            }
          });

          dataGrid.instance.option('columns', this.requestGridColumns);
        }
      });
    }
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/quotes']);
    }
  }

  onSelectedTabChange(event) {
    this.lineItems = [];
    let itemKey = event.index;
    this.editButtonToolTip = itemKey === 0 ? this.messages.get('EDIT_QUOTE_TOOLTIP') : this.messages.get('EDIT_SERVICE_TOOLTIP');
    this.selectedQuoteService = itemKey === 0 ? null : this.tabServices.filter(service => service.id === itemKey)[0];
    if (this.selectedQuoteService) {
      this.isClosed = this.selectedQuoteService.service_status != null &&
        this.selectedQuoteService.service_status !== QUOTE_SERVICE_STATUS.OPEN;
      this.selectEntity(this.selectedQuoteService.id, this.SYSTEM_MODULE.QUOTE_SERVICE, this.selectedQuoteService.quote_service_id);
    }

    this.selectedRequest = null;
    this.selectedOverviewRows = [];
    this.activeTab = itemKey;
    if (this.activeTab === 0) {
      this.initSummaryGrid();
      this.isQuoteServicesClosed();
      this.selectedEntity = null;
    } else if (this.activeTab === this.OVERVIEW_TAB_KEY) {
      this.initOverviewGrid();
      this.loadAllRequests();
    } else {
      this.initRequestGrid();
      this.loadServiceRequests();
    }
  }

  refresh() {
    this.loadData(this.quote.id, this.activeTab);
    this.serviceQuoteId = this.activeTab;
  }

  selectEntity(id, type, title) {
    this.selectedEntity = new Entity(id, type, title);
  }

  // for wizard testing
  addNewQuoteService(quoteServiceTypeId, titleValue: string) {
    this.dialogService.open(QuotingServiceDialogComponent/*WizardServiceDialogComponent*/, {
      quote: this.quote,
      quote_service_type_id: quoteServiceTypeId,
      titleValue: titleValue
    }, { width: '1100px' })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (result.refresh) {
            this.loadServices(this.tabServices.length + 2);
            this.alertService.success('', this.messages.get('CREATE_SERVICE_SUCCESS'));
            this.history.refreshList();
          }
        }
      });
  }

  editQuoteService() {
    this.dialogService.edit(
      QuotingServiceDialogComponent,
      {
        service: this.quotingService.findByServiceIdForEdit(this.quote.id, this.selectedQuoteService.id),
        quote: this.quote,
        currentStep: 2
      }, { width: '1100px' }).subscribe((items) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(mergeMap(x => x.afterClosed()))
            .subscribe(() => {
              this.refresh()
            })
        }
      })
  }

  confirmRequest() {
    this.dialogService.edit(
      ConfirmQuoteDialogComponent,
      {
        quote: this.quotingService.findByIdForEdit(this.quote.id),
        service: this.selectedQuoteService,
        offer: this.selectedRequest
      },
      { width: '25%' }).pipe(
      mergeMap(x => x.afterClosed()))
      .subscribe(result => {
        this.loadServiceRequests();
        Object.assign(this.selectedQuoteService, result);
        this.isQuoteServicesClosed();
        this.isClosed = true;
        this.alertService.success('', this.messages.get('CLOSE_QUOTE_SUCCESS'));
        this.history.refreshList();
      })
  }

  closeRequest() {
    this.dialogService.edit(
      ConfirmDialogComponent,
      {
        quote: this.quotingService.findByIdForEdit(this.quote.id)
      },
      {
        bodyText: `Are you sure you want to close?`
      }).pipe(
      mergeMap(x => x.afterClosed()))
      .subscribe(result => {
        if (result) {
          this.quotingService.updateService(this.selectedQuoteService.id, {
            service_status: QUOTE_SERVICE_STATUS.CANCELED,
            complete_date: Date.now(),
            completeAllRequests: true
          })
            .subscribe((resultQuoteService) => {
              Object.assign(this.selectedQuoteService, resultQuoteService);
              this.loadServiceRequests();
              this.isQuoteServicesClosed();
              this.isClosed = true;
              this.alertService.success('', this.messages.get('CLOSE_QUOTE_SUCCESS'));
              this.history.refreshList();
            });
        }
      });
  }

  addManual() {
    this.updateRequest({
      isManual: true
    });
  }

  updateRequest(options?) {
    this.dialogService.edit(
      QoutingRequestManageDialogComponent,
      {
        quote: this.quote,
        service: this.selectedQuoteService,
        offer: this.quotingService.findRequestForEdit(this.quote.id, this.selectedQuoteService.id, this.selectedRequest.id),
        isManual: options ? options.isManual : false,
      }, { width: '700px' }).subscribe((items) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(mergeMap(x => x.afterClosed()))
            .subscribe((result) => {
              if (result) {
                this.refresh();
                this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
              }
            })
        }
      })
  }

  onSelectionChanged(row) {
    let one_year_mrc;
    let two_year_mrc;
    let three_year_mrc;
    let five_year_mrc;
    let seven_year_mrc;
    let mtm_mrc;
    this.selectedRequest = <Quote>row.selectedRowsData[0];

    if (this.selectedRequest) {
      one_year_mrc = this.selectedRequest.one_year_mrc;
      two_year_mrc = this.selectedRequest.two_year_mrc;
      three_year_mrc = this.selectedRequest.three_year_mrc;
      five_year_mrc = this.selectedRequest.five_year_mrc;
      seven_year_mrc = this.selectedRequest.seven_year_mrc;
      mtm_mrc = this.selectedRequest.mtm_mrc;
    }
    this.enableSelectQuote = !!(one_year_mrc || two_year_mrc || three_year_mrc || five_year_mrc || seven_year_mrc || mtm_mrc);
  }

  onRowClick(event) {
  }

  onCellClick(event) {
  }

  /* If mail is sent, then checkbox is disabled but we can still select checkbox by clicking on row.
  With this, we see if mail is sent and then deselect selected rows that can not be selected; */
  onSelectionOverviewChanged(e: any) {
    e.selectedRowsData.map(item => {
      if (!item.quote_type || item.quote_type.id !== QUOTE_SERVICE_OFFER_METHOD.EMAIL
        || item.status === this.QUOTE_SERVICE_OFFER_STATUS.SENT) {
        e.component.deselectRows([item]);
      }
    })
  }

  onOverviewGridCellPrepared(e: any) {
    if (e.rowType === 'data' && e.column.command === 'select'
      && (!e.data.quote_type ||
        e.data.quote_type.id !== QUOTE_SERVICE_OFFER_METHOD.EMAIL ||
        e.data.status === this.QUOTE_SERVICE_OFFER_STATUS.SENT)) {
      let instance = e.cellElement.find('.dx-select-checkbox').dxCheckBox('instance');
      instance.option('disabled', true);
      e.cellElement.off();
    }
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  isDownloadDisabled(selection: any) {
    return !(this.tabGroup.isActive(3) && selection);
  }

  isDeleteDisabled(selection: any) {
    return !(this.tabGroup.isActive(3) && selection);
  }

  docSelected(doc: Document) {
    this.selection = doc;
  }

  upload() {
    this.documentsManager.addEntityDocument();
  }

  download() {
    this.documentsManager.downloadDocument(this.selection, this.DOCUMENT_INFO.FOLDER);
  }

  deleteDocument() {
    this.documentsManager.deleteEntityDocument(this.selection);
  }

  isTermActive(termId) {
    return this.selectedQuoteService &&
      this.selectedQuoteService.service_term_id && this.selectedQuoteService.service_term_id.indexOf(termId) > -1;
  }

  resolveStatus(status: number, method: number): any {
    if (method === QUOTE_SERVICE_OFFER_METHOD.EMAIL || method === QUOTE_SERVICE_OFFER_METHOD.MANUAL) {
      return status === QUOTE_SERVICE_OFFER_STATUS.COMPLETE ? 'Received' : 'Not Received';
    } else {
      if (status === QUOTE_SERVICE_OFFER_STATUS.UNKNOWN) {
        return 'Pending';
      }

      return status === QUOTE_SERVICE_OFFER_STATUS.COMPLETE ? 'Complete' : 'Failed';
    }
  }

  private toggleLoadingIndicator(show = true) {
    show && !this.loaderService.loaderVisible ? this.loaderService.displayLoader() : this.loaderService.hideLoader();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
