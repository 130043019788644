import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "app/material-barrel/material-barrel.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {DxDateBoxModule} from "devextreme-angular/ui/date-box";
import { DxFilterBuilderModule, DxTagBoxModule } from "devextreme-angular";
import {TagInputModule} from 'ngx-chips';
import {DndModule, DragDropConfig, DragDropService, DragDropSortableService} from "ng2-dnd";
import {FilterInputComponent} from "./filter/filter-input.component";
import {FilterSettingsComponent} from "./filter/filter-settings/filter-settings.component";
import {OrderByPipe} from "./order/order-pipe.pipe";
import {ColumnCaptionPipe} from "./settings-caption/column-caption-pipe";
import {TagComponent} from "./tag/tag.component";
import {GridSettingsComponent} from "./grid/grid-settings.component";
import {CanDeactivateGuard} from "./guard/can-deactivate-guard.searvice";
import {TagService} from "./tag/core/tag.service";
import {DataLockExtendPromptComponent} from "./data-lock/data-lock-extend-prompt/data-lock-extend-prompt.component";
import {SharedModule} from '../shared/shared.module';
import {SitesFilterComponent} from "./filter/sites-filter/sites-filter.component";
import {VendorAccountFilterComponent} from "./filter/vendor-account-filter/vendor-account-filter.component";
import {VendorAccountAllFilterComponent} from "./filter/vendor-account-all-filter/vendor-account-all-filter.component";

import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {TagFilterInputComponent} from "./filter/tag-filter-input/tag-filter-input.component";

import {FilterComponent} from './filter/filter.component';
import {NewFilterComponent} from "./filter/new-filter-component/new-filter.component";

import {RangeFilterComponent} from './filter/date-range-filter/date-range-filter.component';
import {DateRangeFilterService} from './filter/date-range-filter/core/date-range-filter.service';
import {AddressFilterComponent} from './filter/address-filter/address-filter.component';
import {CountryFilterComponent} from './filter/country-filter/country-filter.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NewFilterPickerComponent} from './filter/new-filter-picker/new-filter-picker.component';
import {NewFilterInputComponent} from "./filter/new-filter-input/new-filter-input.component";
import {TopUsageFilterComponent} from './filter/top-usage-filter/top-usage-filter.component';
import {PageDetailsComponent} from "./page-details.component";
import {PageListComponent} from "./page-list.component";
import {PageManageDialogComponent} from "./page-manage-dialog.component";
import {ApiService, HttpBearerInterceptor, JwtService} from "./api";
import {PermissionGuardService, ResourceManager, ResourceRegistry, ResourceService} from "./security";
import { NewRuleBuilderComponent } from './dx-rule-builder/dx-rule-builder.component';
import { NewRuleBUilderService } from './dx-rule-builder/dx-rule-builder.service';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    MaterialModule,
    FlexLayoutModule,
    DxDateBoxModule,
    HttpClientModule,
    DndModule,
    MatMomentDateModule,
    DxFilterBuilderModule,
    DxTagBoxModule
  ],
    exports: [
        FilterInputComponent,
        FilterSettingsComponent,
        OrderByPipe,
        ColumnCaptionPipe,
        TagComponent,
        RangeFilterComponent,
        AddressFilterComponent,
        CountryFilterComponent,
        FilterComponent,
        NewFilterComponent,
        NewFilterPickerComponent,
        TopUsageFilterComponent,
        NewRuleBuilderComponent,
        TagFilterInputComponent
    ],
  declarations: [
    TopUsageFilterComponent,
    FilterInputComponent,
    NewFilterInputComponent,
    NewFilterPickerComponent,
    FilterSettingsComponent,
    GridSettingsComponent,
    OrderByPipe,
    ColumnCaptionPipe,
    DataLockExtendPromptComponent,
    TagComponent,
    SitesFilterComponent,
    VendorAccountFilterComponent,
    VendorAccountAllFilterComponent,
    TagFilterInputComponent,
    RangeFilterComponent,
    AddressFilterComponent,
    CountryFilterComponent,
    FilterComponent,
    NewFilterComponent,
    PageDetailsComponent,
    PageListComponent,
    PageManageDialogComponent,
    NewRuleBuilderComponent
  ],
  providers: [
    DragDropService,
    DragDropConfig,
    DragDropSortableService,
    CanDeactivateGuard,
    TagService,
    DateRangeFilterService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpBearerInterceptor, multi: true},
    ApiService,
    JwtService,
    PermissionGuardService,
    ResourceService,
    ResourceManager,
    ResourceRegistry,
    NewRuleBUilderService,
    NewRuleBuilderComponent
  ],
  entryComponents: [
    FilterSettingsComponent,
    GridSettingsComponent,
    DataLockExtendPromptComponent,
  ]
})
export class CoreModule {
}
