
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Query from 'app/core/query/query';
import { OrderServiceService } from 'app/order-service/core/order-service.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DataLockDialogService } from '../../core/data-lock/data-lock-dialog.service';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { GridService } from '../../shared/grid/grid.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { SortingBuilder, SortingService } from '../../shared/sorting/sorting.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { Order } from '../core/order';
import { OrderFilterService } from '../core/order-filter.service';
import { OrderGridService } from '../core/order-grid.service';
import { OrderServiceLevelFilterService } from '../core/order-service-level/order-service-filter.service';
import { OrderServiceLevelGridService } from '../core/order-service-level/order-service-level.grid.service';
import { OrderSortInterceptor } from '../core/order-sort-interceptor';
import { ORDER_STATUS } from '../core/order-status.enum';
import { OrderQuery } from '../core/order.query';
import { OrderService } from '../core/order.service';
import { OrderManageDialogComponent } from '../shared/order-manage-dialog/order-manage-dialog.component';
import {LOOKUP_ENUM} from '../../dictionary/core/lookup.enum';
import {ORDER_TYPE} from "../core/order-type.enum";
import {OrderCsvInterceptor} from "../core/order-csv-interceptor";



@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html'
})
export class OrderListComponent extends PageListComponent implements OnInit, AfterViewInit {
  public orders: Array<Order>;
  public selection: Order;
  public orderServiceSelection: OrderService;
  public sortingBuilder: SortingBuilder;

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'order';
  readonly CORE_MODULE: string = 'core';

  @ViewChild('grid') dataGrid: DxDataGridComponent;

  @ViewChild('orderServiceLevelGrid')
  orderServiceLevelGrid: DxDataGridComponent;

  public columns: Array<any>;
  public sorting: any[][];
  defaultServiceGridPager: number;
  public orderServiceLevelColumns: Array<any>;
  public orderServiceLevelSorting: any[][];
  public serviceLevel: boolean;
  public orderServiceLevelItems: any;

  public orderServiceLevelQuery: OrderQuery = new OrderQuery({
    orderBy: [['order.id', 'DESC']]
  });

  public query: OrderQuery = new OrderQuery({
    orderBy: [['id', 'DESC']]
  });

  readonly ORDER_STATUS_ENUM = ORDER_STATUS;
  readonly ORDER_TYPE_ENUM = ORDER_TYPE;

  adjustmentsKeys = Object.keys(LOOKUP_ENUM.ORDER_TYPE).filter(
    item => item !== 'DISCONNECT' && item !== 'CHANGE'
  );

  constructor(
    public alertService: AlertService,
    public dialogService: DialogService,
    public sortingService: SortingService,
    public orderService: OrderService,
    public orderGridService: OrderGridService,
    public orderServiceLevelGridService: OrderServiceLevelGridService,
    public router: Router,
    public settingsService: UserSettingsService,
    public loaderService: LoaderService,
    public dataLockDialogService: DataLockDialogService,
    public orderFilterService: OrderFilterService,
    public orderServiceLevelFilterService: OrderServiceLevelFilterService,
    private orderServiceService: OrderServiceService
  ) {
    super(
      new PageContext({
        name: 'app.order.order-list',
        settings: settingsService
      })
    );
    this.sortingBuilder = this.sortingService.builder();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  loadData(query?: OrderQuery) {
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.orderService.findAll(query).subscribe(
      result => {
        this.orders = result.items;
        this.orders.map(order => {
          order['requesters'] = this.getRequesters(order)
        })
        this.query.total = result.total;

        if (!this.serviceLevel) {
          this.selectFirstRow();
        }

        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      },
      error => {
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      }
    );
  }

  getRequesters(order) {
    let requesters = []
    if (order.requester) {
      requesters.push(order.requester.full_name)
    }
    if (order.contacts) {
      order.contacts.forEach(contact => {
        if (contact.contact_function == 'REQUESTER') requesters.push(contact.first_name + ' ' + contact.last_name)
      });
    }
    return requesters.join(', ')
  }

  newOrder(type) {
    this.dialogService
      .open(OrderManageDialogComponent,{order: {},type:type},{ width: '1200px' })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.refresh();
          this.router.navigate(['/order', result.id, 'show']);

          this.alertService.success('', this.messages.get('CREATE_SUCCESS'));
        }
      });
  }

  editOrder(order) {
    this.dialogService
      .edit(
        OrderManageDialogComponent,
      {
        order: this.orderService.findByIdForEdit(order.id)
      },
        { width: '1200px' }
      )
      .subscribe((items: any) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(
            mergeMap(x => x.afterClosed()))
            .subscribe(result => {
              if (result) {
                this.refresh()
                if (result['cancelByTimer'] || result['status'] == 2) {
                  return this.alertService.success('', this.coreMessages.get('TIMER_EXPIRED'));
                } else if (result['deleted']) {
                  setTimeout(() => {
                    this.alertService.snackBar.dismiss();
                    this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
                  }, 255);
                } else if (result['id']) {
                  this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
                }
              }
            });
        }
      });
  }

  refresh() {
    !this.serviceLevel
      ? this.loadData(this.query)
      : this.loadOrderServiceLevelItems(this.orderServiceLevelQuery);
  }

  filterData(query: Query) {
    if (!this.serviceLevel) {
      this.query = query;
      this.loadData(query);
    } else {
      this.orderServiceLevelQuery = query;
      this.loadOrderServiceLevelItems(this.orderServiceLevelQuery);
    }
  }

  clearFilter(query: Query) {
    if (!this.serviceLevel) {
      this.query = query;
      this.loadData(query);
    } else {
      this.orderServiceLevelQuery = query;
      this.loadOrderServiceLevelItems(this.orderServiceLevelQuery);
    }
  }

  csv() {
    if (!this.serviceLevel) {
      const { orderGridService, orderService, query } = this;
      orderGridService.csvMap().subscribe(fields => {
        const orderTransformMiddleware = (items) => {
          const orderCSVInterceptor = new OrderCsvInterceptor();
          return items.map(item => orderCSVInterceptor.transform(item));
        }
        orderService.exportToCSV('orders', {
          fields,
          query,
          middleware: [orderTransformMiddleware]
        });
      });
    } else {
      const { orderServiceLevelGridService, orderServiceService, orderServiceLevelQuery } = this;
      orderServiceLevelGridService.csvMap().subscribe(fields => {
        const dataQuery = orderServiceService.generateCSVQuery(orderServiceLevelQuery)
        orderServiceService.exportToCSV('order-service', {
          fields,
          query: orderServiceLevelQuery,
        });
      });
    }
  }

  onSelectionChanged(row) {
    let order;
    if (
      this.serviceLevel &&
      row.selectedRowsData[0] &&
      row.selectedRowsData[0].order
    ) {
      order = row.selectedRowsData[0].order;
    } else {
      order = row.selectedRowsData[0];
    }
    this.selection = order;
    this.orderServiceSelection = row.selectedRowsData[0];
  }

  ngOnInit() {
// tslint:disable-next-line: no-unused-expression
    this.settings && this.settings.hasOwnProperty('service_level') && (this.serviceLevel = this.settings.service_level);
    this._init();
  }

  ngAfterViewInit(): void {
    this.orderGridService.create(this.dataGrid.instance, {});
    this.orderServiceLevelGridService.create(
      this.orderServiceLevelGrid.instance,
      {}
    );
    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  showDetails() {
    if (this.selection) {
      this.router.navigate(['/order', this.selection.id, 'show']);
    }
  }

  public onCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      if (!this.serviceLevel) {
        this.sortingBuilder.apply(
          event,
          this.query,
          new OrderSortInterceptor()
        );
        this.loadData(this.query);
      } else {
        this.sortingBuilder.apply(
          event,
          this.orderServiceLevelQuery,
          new OrderSortInterceptor()
        );
        this.loadOrderServiceLevelItems(this.orderServiceLevelQuery);
      }
    }
  }

  gridSettings() {
    this.dialogService
      .open(GridSettingsComponent, {
        service: !this.serviceLevel
          ? this.orderGridService
          : this.orderServiceLevelGridService
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.orderGridService;
  }

  getDefaultSort(): any {
    return [['id', 'DESC']];
  }

  _init() {
    super._init();
    const {columns, sorting, query, limit } = PageListComponent.prepareList({
      gridService: this.orderServiceLevelGridService,
      defaultSort: [['order.id', 'DESC']]
    });

    Object.assign(this, {
      orderServiceLevelColumns: columns,
      orderServiceLevelSorting: sorting,
      defaultServiceGridPager: limit
    });

    Object.assign(this.orderServiceLevelQuery, query);


    if (this.orderServiceLevelGrid && this.orderServiceLevelGrid.instance) {
      this.orderServiceLevelGrid.instance.option(
        'columns',
        this.orderServiceLevelColumns
      );
    }

    this.loadOrderServiceLevelItems(this.orderServiceLevelQuery);
  }

  public serviceLevelToggle(): void {
    this.serviceLevel = !this.serviceLevel;
    this.context.settings.save(this.context.name, {service_level: this.serviceLevel});

    this._init();
  }

  public async loadOrderServiceLevelItems(query?: Query): Promise<any> {
    if (!query) {
      query = this.orderServiceLevelQuery;
    }
    this.loaderService.displayLoaderAndManageGrid([this.orderServiceLevelGrid]);
    const { items, total } = await this.orderServiceService
      .findAllList(this.orderServiceLevelQuery)
      .toPromise();
    this.orderServiceLevelItems = items;
    this.orderServiceLevelQuery.total = total;

    this.loaderService.hideLoaderAndManageGrid([this.orderServiceLevelGrid]);
  }

  public isEditDisabled() {
    return !this.selection || this.selection != null && this.selection.status_id === this.ORDER_STATUS_ENUM.CANCELLED;
  }
}
