import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import { MatDialogRef } from '@angular/material/dialog';
import {SortingBuilder, SortingService} from '../../../shared/sorting/sorting.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {IMessagesResourceService, ResourcesService} from '../../../core/resources/resources.service';
import { AuditRatesGridService } from '../../core/audit-rates-grid.service'
import { PageManageDialogComponent } from 'app/core/page-manage-dialog.component';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { FormControl } from '@angular/forms';
import { RandomService } from 'app/shared/random/random.service';

@Component({
  selector: 'app-audits-rates-manage-dialog',
  templateUrl: './rates-audit-manage-dialog.html',
})
export class ManageRatesDialogComponent extends PageManageDialogComponent implements AfterViewInit, OnInit {
  public audits: [];
  public onDeleteConfirmed: Function;
  actionText = 'delete';
  title = 'Delete rates';
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public note: FormControl;
  public auditsSorting: SortingBuilder;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'rate-audit';
  deletePlaceholder = 'Type in generated string shown below';
  randomString = this.randomService.getRandomCheckString();
  placeHolder = 'Note';
  config : any ;

  constructor(
    public randomService: RandomService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<ManageRatesDialogComponent>,
    public sortingService: SortingService,
    public alertService: AlertService,
    public auditRatesGridService: AuditRatesGridService) {
    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.auditsSorting = this.sortingService.builder();
  }

  ngOnInit() {
    this.columns = this.auditRatesGridService.columns();
    this.note = new FormControl('');
  }


  ngAfterViewInit(): void {
    this.setDialogContent();
  }

  close(deleted = false) {
    this.dialogRef.close({
      deleted
    });
  }

  // delete() {
  //   this.onDeleteConfirmed({
  //     note: this.note.value,
  //     closeDialog: this.dialogRef.close.bind(this.dialogRef)
  //   });
  // }
  delete() {
    this.closeDialog({note: this.note.value,deleted:true});
  }

  setDialogContent() {
    if (!this.dialogRef.componentInstance['config']) {
      this.placeHolder = 'Note';
      this.auditRatesGridService.create(this.dataGrid.instance, {
        noDataText: 'No data'
      });
    } else {
      this.placeHolder = 'Reason';
    }
  }
}
