<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{contract.name}} &bull; Contract T/C</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">
  <form class="ca-form" [formGroup]="form" fxLayout="column">
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column" >
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-contract-schedule-picker
          caInput
          formControlName="contract_schedule_id"
          [pickerForm]="form"
          placeholder="Contract Schedule"
          [clearEnabled]="true"
          [required]="true"
          [contractId]="contract.id"
          [svcOrderServices]="false"
          [disabled]="this.disableFields && this.disableFields['contractSchedule']"
        >
        </ca-contract-schedule-picker>
      </div>
      <div class="flex-item caGap " fxFlex>
      </div>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex="">
        <mat-form-field caInput>
          <input
            matInput
            placeholder="Name"
            formControlName="name"
            type="text">
        </mat-form-field>
      </div>


      <div class="flex-item caGap " fxFlex="">
        <mat-form-field caInput>
          <input
            matInput
            placeholder="Section ID"
            formControlName="section_id"
            type="text">
        </mat-form-field>
      </div>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">

    <div class="flex-item caGap" fxFlex="">
        <mat-form-field caInput>
          <textarea
            id="contract_text"
            matInput
            placeholder="Contract Text"
            formControlName="text"
            type="text"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap" fxFlex="">
        <mat-form-field caInput>
          <textarea
            id="contract_abstract"
            matInput
            placeholder="Abstract"
            formControlName="abstract"
            type="text"></textarea>
        </mat-form-field>
      </div>

    </div>


  </form>
</div>

<mat-dialog-actions class="ca-align-baseline ca-dialog-action">
  <div class="example-fill-remaining-space"></div>

  <button
    mat-button
    #cancelButton
    type="submit"
    (click)="cancel()">
    Cancel
  </button>

  <button
    mat-button
    #saveButton
    type="submit"
    (click)="onSubmit(form)"
    [disabled]="form?.invalid">
    Save
  </button>
</mat-dialog-actions>
