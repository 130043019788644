<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Disconnect Dates</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">

<form class="ca-form dialog-content-no-margin" [formGroup]="form">
  <div style="overflow: hidden !important;">
    <div class="flex-container" fxLayout="row">
      <div class="flex-item caGap caGapRight" fxFlex="40%">
        <ca-date-picker caInput placeholder="Disconnect Date" formControlName="disconnect_date" required="true"></ca-date-picker>
      </div>
      <div class="flex-item caGap" fxFlex="40%">
        <ca-date-picker caInput placeholder="Date Received" formControlName="date_received" required="true"></ca-date-picker>
      </div>
    </div>
  </div>

</form>

</div>
<mat-dialog-actions class="ca-dialog-action">
    <span class="example-fill-remaining-space"></span>
    <button mat-button type="submit" (click)="cancel()">
        Cancel
    </button>
    <button mat-button
            [disabled]="form.invalid"
            type="button"
            (click)="submit(form)">
      Save
    </button>
  </mat-dialog-actions>
