import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ca-default-picker',
  templateUrl: './ca-default-picker.component.html',
})
export class CaDefaultPickerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
