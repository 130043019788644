import {Injectable} from '@angular/core';
import {ResourceManager} from "../../core/security";
import {RolesService} from "../../roles/core/roles.service";
import {Observable} from "rxjs";
import {Role} from "../../roles/core/role";
import { zip } from 'rxjs';
import {map} from "rxjs/operators";

@Injectable()
export class PermissionManager {

  constructor(
    private resourceManager: ResourceManager,
    private roleService: RolesService
  ) {
  }

  loadPermissions(roleId: number) {
    return zip(
      this.roleService.findById(roleId),
      this.resourceManager.findAll()
    )
      .pipe(map((data: any) => {
        const role: Role = data[0] || new Role({permissions: []});
        const resources = data[1];
        return this.mapPermissions(role.permissions || [], resources);
      }),
        map((permissions: any) => {
        permissions.forEach(({actions}) => {
          const all = actions.find(x => x.key === 'ALL');
          const selected = actions.filter(x => x.selected && x.key !== 'ALL');
          all.selected = selected.length === actions.length - 1;
        })
        return permissions;
      }))
  }

  mapPermissions(permissions, resources = []) {

    resources.forEach((resource) => {
      const {actions = []} = resource;
      actions.forEach((action) => {
        const perm = permissions.find((permission) =>
          permission.resource_id === resource.resource_id
          && permission.action.toUpperCase() === action.key.toUpperCase());

        action.selected = !!perm;
      });
      const view = actions.find((action) => ['VIEW'].indexOf(action.key) > -1);
      actions.forEach((action) => action.enabled = view.selected);
    });

    return resources;
  }
}
