import {Injectable} from '@angular/core';
import {UserSessionModel} from "../models/user-session-model";
import * as Cookie from 'js-cookie';
import {environment} from "../../../../environments/environment";

@Injectable()
export class UserSessionService {

  constructor() {
  }

  readonly _COOKIE_KEY: string = 'authorization_token';

  storeSession(session: Partial<UserSessionModel>) {
    Cookie.set(this._COOKIE_KEY, session.token);
  }

  getSession(): UserSessionModel {
    let token = Cookie.get(this._COOKIE_KEY);
    if(token) {
      return new UserSessionModel({token})
    }
    return null;
  }

  removeSession() {
    Cookie.remove(this._COOKIE_KEY);
  }

  isSessionValid(): boolean {
    let session = this.getSession();
    return !!session;
  }

}
