<ng-container *ngIf="vendorRemitAddress">
  <mat-toolbar color="accent" mat-scroll-shrink>

    <span class="sneak-peek-title">{{ vendorRemitAddress?.vendor_location_id }}</span>

    <span class="example-fill-remaining-space"></span>

    <button mat-icon-button (click)="closeSide()">
      <ca-icon [key]="'CLOSE'"></ca-icon>
    </button>
  </mat-toolbar>

  <div class="ca-card ca-card-sneak-peek">
    <mat-card caCard>
      <mat-card-content>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Vendor Location ID</label>
            <p>
              {{ vendorRemitAddress?.vendor_location_id || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Vendor</label>
            <p>
              {{ vendorRemitAddress?.vendor?.name || 'N/A'}}
            </p>
          </div>
        </div>


        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Address</label>
            <div>
              <p *ngIf="vendorRemitAddress?.address?.address1">
                {{ vendorRemitAddress?.address?.address1}}
              </p>
              <p>
                {{ vendorRemitAddress?.address?.city}}&nbsp;
                {{ vendorRemitAddress?.address?.subdivision?.abbreviation}}&nbsp;
                {{ vendorRemitAddress?.address?.postal_code}}
              </p>
              <p *ngIf="vendorRemitAddress?.address?.country">
                {{ vendorRemitAddress?.address?.country?.name}}
              </p>
            </div>
          </div>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="!vendorRemitAddress">
  <div class="ca-empty-state-container">
    <p class="card-message">
      No vendor remit address selected
    </p>
  </div>
</ng-container>
