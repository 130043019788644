<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
    <h1 mat-dialog-title>Upload Document</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">

<form class="ca-form dialog-content-no-margin" [formGroup]="form">
    <div class="flex-container" fxLayout="column">
      <input class="ca-document-file-input" #fileInput placeholder="Document" type="file"
             (change)="onFileSelect($event)" >
      <div class="ca-dropzone ca-default-border-color" caDropzone (dropped)="onDrop($event)" fxLayout="column">
        <div class="text-wrapper" fxLayoutAlign="center center">
          <ca-icon [key]="'FILE_CLOUD_UPLOAD'" [innerClass]="'upload-icon'"></ca-icon>
          <span class="document-upload-text"> Drop file to upload or</span>
          <button #genericButton type="button" class="document-upload-button flat-button" mat-button (click)="fileInput.click()">Browse</button>
        </div>
        <div [ngClass]="{'ca-file-name-taken-error-text': (fileNameTaken !== null && fileNameTaken)}" class="text-wrapper selected-file" fxLayoutAlign="center center">
          <i>{{ fileName }} <span *ngIf="fileNameTaken !== null && fileNameTaken">already exists</span></i>
        </div>
      </div>
    </div>

  <div class="flex-container ca-margin-top-15" fxLayout="row wrap" fxLayoutGap="24px">
    <div class="flex-item remove-max-height caGap" fxFlex>
      <ca-contract-schedule-picker
        caInput
        clearEnabled="true"
        #contractSchedulePicker
        formControlName="contract_schedule_id"
        [pickerForm]="form"
        [contractId]="contractId"
        [required]="true"
        [svcOrderServices]="false"
        placeholder="Contract Schedule">
      </ca-contract-schedule-picker>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxFlex>
      <ca-lookup-model-picker fxFlex
                              caInput
                              [lookupModel]="CONTRACT_DOCUMENT_TYPE_MODEL"
                              [required]="true"
                              formControlName="document_type_id"
                              placeholder="Type"
                              [pickerForm]="form"
      >
      </ca-lookup-model-picker>
    </div>
  </div>

    <div class="flex-container ca-margin-top-15" fxLayout="row">
      <div class="flex-item" fxFlex>
        <mat-form-field caInput>
          <textarea matInput formControlName="description" matInput placeholder="Description" rows="2"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
 </div>


  <mat-dialog-actions class="ca-align-baseline ca-dialog-action">
    <div class="example-fill-remaining-space">
    </div>

    <button mat-button #cancelButton type="submit" (click)="close()">
      Cancel
    </button>

    <button mat-button #saveButton type="submit"  [disabled]="(fileNameTaken === null || fileNameTaken) || form?.invalid"
      (click)="onSubmit(form)">
      Save
    </button>
  </mat-dialog-actions>

