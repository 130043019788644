import { Injectable } from '@angular/core';
import { GridService } from "../../shared/grid/grid.service";
import { UserSettingsService } from "../../user/core/user-settings.service";
import {FormatService} from "../../shared/format/format.service";

@Injectable()
export class AlertMissingInventorySpidGridService extends GridService {

  constructor(
    public userSettings: UserSettingsService,
    public formatService: FormatService
  ) {
    super("app.alert-inventory.missing-inventory-spid", userSettings);
  }

  defaultColumns = [
    {
      dataField: 'sp_serv_id',
      caption: 'SPID',
      width: 220,
    },
  ];
}
