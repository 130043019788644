import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Restangular} from 'ngx-restangular';
import Query from '../../core/query/query';
import {HttpClient} from "@angular/common/http";
import {ServiceLocator} from "../../core/locator.servce";
import {ApiService} from "../../core/api";

@Injectable()
export class ReconService extends BaseService<any> {

  constructor(public restangular: Restangular,
              public http: HttpClient) {
    super('recon', restangular);
  }

  getInvoices(query) {
    return this.service().one('recon').one('invoices').customGET(null, this.toFilter(query));
  }

  getInventories(query?: Query): any {
    let concreteQuery = (query || new Query());
    return this.fetchAll(`${this.name}/inventories`, concreteQuery)
  }


  assignBillingAlias(alias, inventory_id) {
    return this.service().one(this.name).one('alias').customPOST({alias, inventory_id});
  }

  fetchUnlinkedInventoryVendors(limit) {
    return this.service().one(this.name).one('vendors').customGET(null, {limit});
  }

  fetchUnlinkedInventoryAccounts(limit, vendor_id) {
    return this.service().one(this.name).one('accounts').customGET(null, {limit, vendor_id});
  }

}
