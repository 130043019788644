<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Link Inventory</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">
  <form class="ca-form" [formGroup]="form" fxLayout="column" style="overflow: hidden!important;">

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-vendor-picker
          caInput
          formControlName="vendor_id"
          [pickerForm]="form"
          [disabled]="true"
          placeholder="Vendor"
          [initialItem]="contract?.vendor"
          [clearEnabled]="true"
          [required]="true"
          [initialItemId]="contract?.vendor_id">
        </ca-vendor-picker>
        <mat-hint *ngIf="form.hasError('required', ['vendor_id']) && form.controls['vendor_id'].touched" class="error-class hint-possition-fix">
          {{VALIDATION.REQUIRED_ERROR}}
        </mat-hint>
      </div>


      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-account-picker
          caInput
          formControlName="ban"
          [pickerForm]="form"
          (onSelectionChange)="loadInventories($event)"
          placeholder="Account"
          [vendorId]="form.get('vendor_id').value">
        </ca-account-picker>
        <mat-hint *ngIf="form.hasError('required', ['vendor_id']) && form.controls['vendor_id'].touched" class="error-class hint-possition-fix">
          {{VALIDATION.REQUIRED_ERROR}}
        </mat-hint>
      </div>
    </div>

    <div style="height: 300px; overflow: hidden; margin-top: 10px;" class="app-content-list app-content-list-no-pager first-column">
      <dx-data-grid #inventoryGrid
                    [showColumnLines]="false"
                    [height]="'100%'"
                    [showRowLines]="true"
                    [showBorders]="true"
                    [dataSource]="inventories"
                    [columns]="columns"
                    (onSelectionChanged)="onSelectionChanged($event)">

        <dxo-selection mode="multiple"></dxo-selection>

        <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
          <a class="selection-badge grid-badge" matTooltip="Selection">
            {{selectedRows.length}}
          </a>
        </div>
        <div *dxTemplate="let d of 'selectionBadgeCellTemplate'"></div>

        <div *dxTemplate="let d of 'dateTimeTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caDate}}
          </div>
        </div>

        <div *dxTemplate="let d of 'installDateTemplate'">
          <div caGridTooltip>{{ d.value | caDate }}</div>
        </div>

        <div *dxTemplate="let d of 'mrcTemplate'">
          <div caGridTooltip>{{ d.value | caCurrency: d.currency_obj?.currency }}</div>
        </div>
        <div *dxTemplate="let d of 'nrcTemplate'">
          <div caGridTooltip>{{ d.value | caCurrency: d.currency_obj?.currency }}</div>
        </div>

        <div *dxTemplate="let d of 'numberTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caNumber}}
          </div>
        </div>

        <div *dxTemplate="let d of 'disputeChargeIdCellTemplate'">
          <div caGridTooltip>{{ d.row.data.charge?.invoice?.sp_inv_num }}_{{ d.row.data.charge?.id }}</div>
        </div>

        <div *dxTemplate="let d of 'disputeInvoiceCellTemplate'">
          <div caGridTooltip>{{ d.row.data.charge?.invoice?.sp_inv_num }}</div>
        </div>

        <div *dxTemplate="let d of 'disputeChargesStatusCellTemplate'">
          <div caGridTooltip>{{ d.data.status_item?.value }}</div>
        </div>

        <i *dxTemplate="let d of 'disputeChargesHeaderTemplate'">
          {{ d.column.caption }}
        </i>

        <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
          <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.charge.currency }}</div>
        </div>

        <div *dxTemplate="let d of 'statusTemplate'">
          <div caGridTooltip>{{ d.value ? d.value.value : 'Filed' }}</div>
        </div>
        <dxo-paging [enabled]="false"></dxo-paging>
      </dx-data-grid>
    </div>

    <div>
      <ca-pager style="position: relative !important;"
        [pageContext]="inventoryGridService"
        [query]="inventoryQuery" class="app-pager detail-pager ca-default-border-top"
        (onChange)="onPageChange(inventoryQuery)"></ca-pager>
    </div>

  </form>
</div>

<mat-dialog-actions class="ca-align-baseline ca-dialog-action">
  <div class="example-fill-remaining-space">
  </div>

  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>

  <button mat-button #saveButton type="submit" (click)="onSubmit(form)"
    [disabled]="form?.invalid || !selectedRows.length">
    Save
  </button>
</mat-dialog-actions>
