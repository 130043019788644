<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
    <h1 mat-dialog-title>{{formTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">

  <form class="ca-form" [formGroup]="form">

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <mat-form-field caInput>
          <input matInput placeholder="Project ID" required formControlName="project_id" type="text">
           <mat-hint *ngIf="form.hasError('required', ['project_id']) && form.controls.project_id.touched">{{VALIDATION.REQUIRED_ERROR}}</mat-hint>
        </mat-form-field>
      </div>
      <div class="flex-item caGap" fxFlex>
        <mat-form-field caInput>
          <input matInput placeholder="Project Name" required formControlName="name" type="text">
          <mat-hint *ngIf="form.hasError('required', ['name']) && form.controls.name.touched">{{VALIDATION.REQUIRED_ERROR}}</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap" fxFlex="45%">
        <ca-person-picker
          caInput
          required="true"
          formControlName="owner_id"
          type="{{ CONTACT_TYPE_ENUM.INTERNAL }}"
          [initialItem]="project?.owner"
          [pickerForm]="form"
          placeholder="Project Owner"
          pickerType="contact">
        </ca-person-picker>
        <mat-hint class="ca-select-hint"
        *ngIf="form.hasError('required', ['owner_id']) && form.controls.owner_id.touched ">{{VALIDATION.REQUIRED_ERROR}}</mat-hint>
      </div>
      <div fxFlex></div>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex="">
        <ca-lookup-model-picker
          caInput
          [lookupModel]="PROJECT_STATUS_LOOKUP_MODEL"
          required="true"
          [initialItem]="project.status"
          [pickerForm]="form"
          formControlName="status_id"
          placeholder="Status">
        </ca-lookup-model-picker>

        <mat-hint class="ca-select-hint"
                  *ngIf="form.hasError('required', ['status_id']) && form.controls.status_id.touched">{{VALIDATION.REQUIRED_ERROR}}</mat-hint>
      </div>

      <div class="flex-item caGap caGapRight" fxFlex="">
        <ca-date-picker caInput placeholder="Start Date" formControlName="start_date" [maxDate]="form.get('end_date').value"></ca-date-picker>
      </div>
      <div class="flex-item caGap" fxFlex="">
        <ca-date-picker caInput placeholder="End Date" formControlName="end_date" [minDate]="form.get('start_date').value"></ca-date-picker>
      </div>
    </div>

    <div class="flex-container" fxLayout="row">
      <mat-form-field class="flex-item caGap" fxFlex caInput>
        <textarea matInput placeholder="Description" formControlName="description" rows="2"></textarea>
      </mat-form-field>
    </div>
    </form>

  </div>


  <mat-dialog-actions class="ca-dialog-action">
    <div class="example-fill-remaining-space">
        <ca-confirm-delete-button #genericButton *ngIf="project && project.id"
        (deleteRequested)="deleteProject($event)"></ca-confirm-delete-button>
    </div>
    <button mat-button #cancelButton type="submit" (click)="cancel()">
        Cancel
    </button>
    <button mat-button #saveButton type="submit" (click)="onSubmit(form)"
            [disabled]="form?.invalid">Save
    </button>
  </mat-dialog-actions>
