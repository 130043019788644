
import {zip as observableZip, of as observableOf,  Observable ,  Subscription } from 'rxjs';

import {map} from 'rxjs/operators';
import { SimpleAlertDialogComponent } from './simple-alert-dialog/simple-alert-dialog.component';
import { SaveDialogComponent } from './save-dialog/save-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmVATDialogComponent } from '../../invoice/shared/invoice-vat-warning-dialog/confirm-dialog-vat-warning.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';
import { EntityLockService } from '../entity-lock/entity-lock.service';
import { EntityLockData } from '../entity-lock/entity-lock-data';
import { EntityLockAlertComponent } from '../entity-lock/entity-lock-alert.component';
import {AppService} from "../../app.service";
import {AlertService} from "../alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../core/resources/resources.service";
import {ConfigService} from "../../core/config/config.service";
import {ConfirmCancelDialogComponent} from "./confirm-cancel-dialog/confirm-cancel-dialog.component";
import {flatMap} from "rxjs/internal/operators";


@Injectable()
export class DialogService {

  readonly DATA_LOCK_CLOSE_STATUS = {
    CANCEL_BY_USER: 0,
    TIME_EXTEND: 1,
    CANCEL_BY_TIMER: 2
  };

  public entityDataLock: any;
  public warningTimer;
  public config:any;
  static component;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "core";

  constructor(public dialog: MatDialog,
              public lockService: EntityLockService,
              public configService: ConfigService,
              public alertService: AlertService) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  edit<T>(component: ComponentType<T>, data?: any, config?: MatDialogConfig | any): Observable<MatDialogRef<any, any>> {


    let defaultConfig = new MatDialogConfig();
    defaultConfig.width = '500px';
    defaultConfig.disableClose = true;
    defaultConfig.autoFocus = false;

    Object.assign(defaultConfig, config);

    let keys = Object.keys(data);
    let observables = keys
      .map(key => data[key] && data[key].subscribe ? data[key] : observableOf(data[key]));

    return this.configService.get().pipe(
      flatMap((config) => {
        this.config = config;
        let lock_config =  this.config['data_lock'];
        return observableZip(...observables).pipe(
          map((result: Array<EntityLockData>) => {
            let lockIndex : number;
            lockIndex = result.findIndex((item) =>item && item['$lock'] && item['$lock']['status'] === 403)
            if (lockIndex !== undefined && lockIndex >= 0) {
              this.alertService.error('', `${this.messages.get('DATA_LOCKED')}${result[lockIndex]['$lock']['user']}`)
            }

            else {
              let dialogRef = this.dialog.open(component, defaultConfig);

              dialogRef.beforeClosed()
                .subscribe(() => {
                  if (this.lockService) {
                    this.lockService.completeAll();
                  }
                });

              result.forEach((key, index) => {
                dialogRef.componentInstance[keys[index]] = result[index];
                if (data && data[keys[index]] && data[keys[index]].subscribe) {
                  key['timeout'] = lock_config['lock_timeout'];
                  this.lockService.createLock(result[index], dialogRef, this, EntityLockAlertComponent)
                    .startTimer(lock_config['lock_duration']);
                }
              });
              return dialogRef;
            }
          }));
      }));
  };

  open<T>(component: ComponentType<T>, data?: any, config?: MatDialogConfig): MatDialogRef<T> {
    let defaultConfig = new MatDialogConfig();
    defaultConfig.disableClose = true;
    defaultConfig.autoFocus = false;
    defaultConfig.width = '500px';

    Object.assign(defaultConfig, config);

    let dialogRef = this.dialog.open(component, defaultConfig);

    let keys = Object.keys(data || {});
    keys.forEach((value, index) => {
      dialogRef.componentInstance[value] = data[value];
    });


    return dialogRef;
  };

  confirm(data?: any, config?: MatDialogConfig): MatDialogRef<ConfirmDialogComponent> {
    let defaultConfig = new MatDialogConfig();
    defaultConfig.width = '450px';
    defaultConfig.autoFocus = false;

    let dialogRef = this.dialog.open(ConfirmDialogComponent, defaultConfig);

    let keys = Object.keys(data || {});

    keys.forEach((value, index) => {
      dialogRef.componentInstance[value] = data[value];
    });


    return dialogRef;
  };

  confirmCancel(data?: any, config?: MatDialogConfig): MatDialogRef<ConfirmCancelDialogComponent> {
    let defaultConfig = new MatDialogConfig();
    defaultConfig.width = '450px';
    defaultConfig.autoFocus = false;

    let dialogRef = this.dialog.open(ConfirmCancelDialogComponent, defaultConfig);

    let keys = Object.keys(data || {});

    keys.forEach((value, index) => {
      dialogRef.componentInstance[value] = data[value];
    });


    return dialogRef;
  };

  vatWarning(data?: any, config?: MatDialogConfig): MatDialogRef<ConfirmVATDialogComponent> {
    let defaultConfig = new MatDialogConfig();
    defaultConfig.width = '450px';
    defaultConfig.autoFocus = false;

    let dialogRef = this.dialog.open(ConfirmVATDialogComponent, defaultConfig);

    let keys = Object.keys(data || {});

    keys.forEach((value, index) => {
      dialogRef.componentInstance[value] = data[value];
    });


    return dialogRef;
  };

  save(data?: any, config?: MatDialogConfig): MatDialogRef<SaveDialogComponent> {
    let defaultConfig = new MatDialogConfig();
    defaultConfig.width = '320px';

    let dialogConfig = typeof config !== 'undefined' ? config : defaultConfig;

    let dialogRef = this.dialog.open(SaveDialogComponent, dialogConfig);

    let keys = Object.keys(data || {});

    keys.forEach((value, index) => {
      dialogRef.componentInstance[value] = data[value];
    });


    return dialogRef;
  };

  prompt(data?: any, config?: MatDialogConfig): MatDialogRef<PromptDialogComponent> {
    let defaultConfig = new MatDialogConfig();
    defaultConfig.width = '320px';

    let dialogRef = this.dialog.open(PromptDialogComponent, defaultConfig);

    let keys = Object.keys(data || {});

    keys.forEach((value, index) => {
      dialogRef.componentInstance[value] = data[value];
    });

    return dialogRef;
  };

  alert(data?: any, config?: MatDialogConfig): MatDialogRef<AlertDialogComponent> {
    let defaultConfig = new MatDialogConfig();
    defaultConfig.width = '320px';
    defaultConfig.autoFocus = false;

    let dialogRef = this.dialog.open(AlertDialogComponent, defaultConfig);

    let keys = Object.keys(data || {});

    keys.forEach((value, index) => {
      dialogRef.componentInstance[value] = data[value];
    });


    return dialogRef;
  };

  simpleAlert(data?: any, config?: MatDialogConfig): MatDialogRef<SimpleAlertDialogComponent> {
    let defaultConfig = new MatDialogConfig();
    defaultConfig.width = '320px';

    let dialogRef = this.dialog.open(SimpleAlertDialogComponent, defaultConfig);

    let keys = Object.keys(data || {});

    keys.forEach((value, index) => {
      dialogRef.componentInstance[value] = data[value];
    });


    return dialogRef;
  };

}
