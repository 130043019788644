import {Injectable} from '@angular/core';
import {FilterService} from '../../core/filter/filter.service';
import {customerFilterContext} from './customer-filter.context';
import {LocationService} from "../../location/core/location.service";
import {SubdivisionLookupProvider} from "../../location/core/subdivision-lookup.provider";
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class CustomerFilterService extends FilterService {

  constructor(public locationService: LocationService,
              public userSettingsService: UserSettingsService) {
    super("app.appdata.customer-filter", customerFilterContext, userSettingsService);
    this.addLookupProvider('subdivision', new SubdivisionLookupProvider(locationService, true));
  }
}

