<form class="ca-form ca-contact-selection-form">
  <div class="flex-container contact-section" fxLayout="row wrap">
        <!-- Required  -->
        <div class="flex-container single-contact" fxLayout="row wrap"
            *ngFor="let contact of contacts.controls; let i = index">
          <div class="flex-item caGap section-values" fxLayout="row" fxFlex="100%">
            <div *ngIf="contact?.get('required')?.value === true && !contact?.valid" fxLayout="row" class="order-section empty">
            <div fxLayout="column" fxFlex>
              <p class="card-message order-selection-header">{{getFunctioName(contact)}}*</p>
              <p class="card-message not-selected">No {{getFunctioName(contact)}} selected</p>
            </div>
              <div class="option-icon" fxFlex="40px">
                <mat-menu #appMenu="matMenu" yPosition="below">
                  <button mat-menu-item (click)="editForm(contact)">Edit</button>
                  <button mat-menu-item [disabled]="contact?.get('required')?.value === true" (click)="deleteForm(i)">Delete</button>
                </mat-menu>

                <button mat-icon-button [matMenuTriggerFor]="appMenu">
                  <mat-icon >more_horiz</mat-icon>
                </button>
              </div>
            </div>
            <div class="order-section" fxLayout="row" *ngIf="contact.valid">
              <div class="entry-list" fxFlex fxLayout="column">
                <div fxFlex class="entry-list-item contact-required order-selection-header">
                  <p>{{getFunctioName(contact)}}
                    <ng-container *ngIf="contact?.get('required')?.value === true && contact?.valid">*</ng-container>
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <p>{{contact?.get('first_name')?.value}} {{contact?.get('last_name')?.value}}</p>
                </div>

                <div fxFlex class="entry-list-item" >
                  <p>{{contact?.get('email')?.value}}</p>
                </div>

                <div fxFlex class="entry-list-item">
                  <p>{{ contact?.get('mobile_number')?.value | mask: getCountryPhoneRegexMask(contact?.get('country_id')?.value) }}</p>
                </div>
              </div>

              <div class="option-icon" fxFlex="40px">
                <mat-menu #appMenu="matMenu" yPosition="below">
                  <button mat-menu-item (click)="editForm(contact)">Edit</button>
                  <button mat-menu-item [disabled]="contact?.get('required')?.value === true" (click)="deleteForm(i)">Delete</button>
                </mat-menu>

                <button mat-icon-button [matMenuTriggerFor]="appMenu">
                  <mat-icon >more_horiz</mat-icon>
                </button>
              </div>
            </div>


          </div>
        </div>

        <div class="flex-container single-contact" fxLayout="row wrap">
          <div class="flex-item caGap section-values" fxLayout="row" fxFlex="100%">
            <div class="order-section empty add-new" fxFlex style="height: 100%">
              <div fxFlex>
                <p class="card-message not-selected">Add new contact</p>
              </div>
              <div class="option-icon" fxFlex="40px" style="height: 100%">
                <button mat-icon-button (click)="addNewContact()">
                  <mat-icon>add</mat-icon>
                </button>
              </div>

            </div>
          </div>
        </div>
  </div>
</form>
