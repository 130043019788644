import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PermissionService} from "./core/permission.service";
import {PermissionManager} from './core/permission.manager';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    PermissionService,
    PermissionManager
  ],
  declarations: []
})
export class PermissionsModule { }
