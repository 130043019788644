export class FlowStep {
  name: string;
  key: string;
  code: number;
  resource: string;
  className: string;
  icon: string;
  dateInfo: Date;
  secondDateInfo: Date;
  progressStatusAlert: string;
  revertStatusAlert: string;
  completed = false;
  optional = false;
  disabled;
  visible = true;
  nextSteps: Array<FlowStep>;
  previousSteps: Array<FlowStep>;

  constructor(data?: Partial<FlowStep>) {
    Object.assign(this, data);
  }

  public onClickFromPreviousStep(flow,invoiceId,mediator,call,callFunc) {}
  public onClickFromNextStep(flow,invoiceId,mediator,call,callFunc) {}
  public onClick(flow,invoiceId,mediator,call,callFunc) {}
}
