<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-title">{{ (alert?.type?.value || '') + ' Alert: ' + (alert?.rule_name || 'N/A') }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button *permission="{'action':'View', 'key':'invoice'}"  [disabled]="!(alert && alert.invoice && alert.invoice.invoice_id)" (click)="goToInvoiceDetails()" mat-icon-button>
    <ca-icon [key]="'VIEW_INVOICE_DETAILS'" [tooltip]="'View Invoice Details'"></ca-icon>
  </button>
  <button
    *permission="{'action':'Create', 'key':'disputes'}"
    mat-icon-button
    [disabled]="addDisputeDisabled"
    (click)="addDispute()"
    [hidden] = "!tabGroup.isActive(2)"
  >
    <ca-icon [key]="'ADD_DISPUTE'" [tooltip]="'Create Dispute'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [disabled]="!tabGroup.isActive(2)" matTooltip="Filter"
          [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(2)}"  (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>

</mat-toolbar>


<mat-sidenav-container class="app-content tabbed-content">

  <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="currentTabIndex">
    <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs" [disabled]="tab.disabled">
    </mat-tab>
  </mat-tab-group>


  <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(1)}" class="tabs-body" fxLayout="column">
      <div class="flex-container ca-card" fxLayout="row">
        <mat-card fxFlex="60%" fxFlexFill caCard>

          <mat-card-header>
            Audit Info
          </mat-card-header>

          <mat-card-content>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Category</label>
                <p>
                  {{ alert?.category?.value || 'N/A' }}
                </p>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Created At</label>
                <p>
                  {{ alert?.created_at | date : 'M/D/YYYY h:mm:ss a' || 'N/A' }}
                </p>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Created By</label>
                <p>
                  {{ alert?.creator?.full_name || 'System' }}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Owner</label>
                <p>
                  {{ alert?.owner?.full_name || PLACEHOLDERS.OWNER_UNASSIGNED }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Priority</label>
                <p>
                  {{ alert?.priority.value || 'N/A' }}
                </p>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Status</label>
                <p>
                  {{ alert?.status.value || 'N/A' }}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">

              <div fxFlex class="entry-list-item">
                <label>Last Modified At</label>
                <p>
                  {{ (alert?.last_modified_at | date) || 'N/A' }}
                </p>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Closed At</label>
                <p>
                  {{ (alert?.closed_at | date) || 'N/A' }}
                </p>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Message</label>
                <p>
                  {{ alert?.message || 'N/A' }}
                </p>
              </div>
            </div>

          </mat-card-content>

        </mat-card>
        <mat-card fxFlex fxFlexFill caCard>

        <mat-card-header>
          Invoice Info
        </mat-card-header>

        <mat-card-content>

          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>BAN</label>
              <p>
                {{ (alert?.account_no) || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Vendor</label>
              <p>
                {{ (alert?.vendor?.name) || 'N/A' }}
              </p>
            </div>
          </div>

          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Invoice Number</label>
              <p>
                {{ (alert?.invoice?.sp_inv_num) || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
              <label>Invoice ID</label>
              <p>
                {{ (alert?.invoice?.invoice_id) || 'N/A' }}
              </p>
            </div>
          </div>

          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Invoice Date</label>
              <p>
                {{ (alert?.invoice?.inv_date | date) || 'N/A' }}
              </p>
            </div>

            <div fxFlex class="entry-list-item">
            </div>
          </div>

        </mat-card-content>

      </mat-card>

      </div>
  </div>

  <!----------------------------- DETAILS TAB ------------------------------------>

  <mat-sidenav-container [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}" class="tabs-body master-detail-arrow-color">
    <dx-data-grid #masterGrid class="app-grid-with-pager grid-margin"
                  [columns]="masterColumns"
                  [dataSource]="rules"
                  [masterDetail]="{ enabled: true, template: 'details' }"
                  (onRowExpanded)="onRowExpanded($event)"
                  (onRowCollapsed)="onRowCollapsed($event)"
                  keyExpr="id"
                  >
      <div *dxTemplate="let d of 'rateCellTemplate'">
        <div caGridTooltip class="right">
          {{ (d.value | caInvoiceCurrency:"USD":"symbol-narrow":"1.2-2") || '$0.00' }}
        </div>
      </div>
      <div *dxTemplate="let ruleResult of 'details'"
           (click)="onChargesDataGridClick($event)"
      >
        <mat-card fxFlex="100%" class="flex-item result-card" caCard>
          <mat-card-content>
            <dx-data-grid
              #detailsGrid
              class="rate-details-grid"
              [ngClass]="{
                'pager-grid': ruleResult.data.resultQuery && ruleResult.data.resultQuery.total < 5,
                'pager-visible': ruleResult.data.resultQuery && ruleResult.data.resultQuery.total >= 5
              }"
              [columns]="detailColumns"
              [dataSource]="ruleResult.data.charges"
              [showBorders]="true"
              [showRowLines]="true"
              [showColumnLines]="false"
              [allowColumnResizing]="true"
              [columnMinWidth]="80"
              (onSelectionChanged)="onChargesSelectionChanged($event, ruleResult.data)"
              keyExpr="id"
              [id]="'charges-grid-' + ruleResult.data.id"
            >
              <dxo-selection mode="multiple"></dxo-selection>

              <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
                <a class="selection-badge grid-badge"
                    matTooltip="Selection"
                    (click)="loadSelectedRuleResults(ruleResult.data)"
                    [ngClass]="{'active':ruleResult.data.selectionActive}">
                  {{ruleResult.data.selectedRows ? ruleResult.data.selectedRows.length : '0'}}
                </a>
              </div>
              <div
                  *dxTemplate="let d of 'chargeDisputeNoteTemplate'"
                  style="position: relative;"
                  class="icon-td"
                >
                <a [ngClass]="
                      d.data.cost_invoice_charge?.charge_dispute ? 'icon-danger' : 'disabled'
                    "
                  (click)="showDispute(d.data.cost_invoice_charge?.charge_dispute.dispute_id)"
                  class="charge-grid-icons"
                >
                  <ca-icon
                    [key]="'DISPUTE'"
                    [innerClass]="'charge-grid-icon'"
                    [tooltip]="'Dispute'"
                  ></ca-icon>
                </a>
              </div>
              <div *dxTemplate="let d of 'rateCellTemplate'">
                <div caGridTooltip class="right">
                  {{ (d.value | caInvoiceCurrency:"USD":"symbol-narrow":"1.2-2") || '$0.00' }}
                </div>
              </div>
            </dx-data-grid>
            <ca-pager
              *ngIf="ruleResult.data.resultQuery ? ruleResult.data.resultQuery.total >= 5 : resultQuery.total >= 5"
              [query]="ruleResult.data.resultQuery || resultQuery"
              class="app-pager ca-default-border-top"
              [sizes]="sizes"
              (onChange)="onResultPageChange(ruleResult.data.resultQuery || resultQuery, ruleResult)"
              [gridPager]="ruleResult.data.resultQuery.limit || 20"
            >
            </ca-pager>
          </mat-card-content>
        </mat-card>
    </div>
    </dx-data-grid>
    <ca-pager
      [gridPager]="defaultGridPager"
      [pageContext]="alertRateAuditGridService"
      [query]="query"
      class="app-pager ca-default-border-top"
              (onChange)="onPageChange(query)"></ca-pager>
              <mat-sidenav #panelSide mode="side" position="end" opened="false" [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}">
                <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)||!tabGroup.isActive(2)}"
                            [query]="ruleFilterQuery"
                            [filterService]="alertRateAuditFilterService"
                            (filterRequested)="filterData($event)"
                            (clearFilterRequested)="clearFilter()">
                </new-app-filter>
              </mat-sidenav>
  </mat-sidenav-container>
</mat-sidenav-container>
