<div class="mat-dialog-top-spacer"></div>

<mat-dialog-content class="ca-dialog-content">{{bodyText}}</mat-dialog-content>
<!-- <div mat-dialog-content class="ca-dialog-content">
  {{bodyText}}
</div> -->

<div class="mat-dialog-top-spacer"></div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button (click)="close()">Close</button>
</mat-dialog-actions>
