import { FormBuilder, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

import { Store } from '../../core/store';

@Injectable()
export class SystemRuleStore extends Store {
  constructor(private formBuilder: FormBuilder) {
    super({
      spidForm: formBuilder.group({
        vendor_id: [null, Validators.required],
        ban: [null, Validators.required]
      }),
      inventoryForm: formBuilder.group({
        vendor_id: [null, Validators.required]
      }),
      subAccountForm: formBuilder.group({
        vendor_id: [null, Validators.required],
        ban: [null, Validators.required]
      }),
      banForm: formBuilder.group({
        vendor_id: [null, Validators.required]
      })
    });
  }

  resetSpidForm() {
    this.getState().spidForm.reset({
      vendor_id: null,
      ban: null
    });
  }

  resetSubAccountForm() {
    this.getState().subAccountForm.reset({
      vendor_id: null,
      ban: null
    })
  }

  resetInventoryForm() {
    this.getState().inventoryForm.reset({
      vendor_id: null
    })
  }

  resetBanForm() {
    this.getState().banForm.reset({
      vendor_id: null
    })
  }

  private resetFormControl = (formName, controlName) => {
    this.getState()[formName].get(controlName).reset(null);
  };

  resetSpidBan() {
    this.resetFormControl('spidForm', 'ban');
  }

  resetSubAccountBan() {
    this.resetFormControl('subAccountForm', 'ban');
  }

  resetState() {
    super.resetState();
    this.resetSpidForm();
    this.resetSubAccountForm();
    this.resetInventoryForm();
    this.resetBanForm();
  }
}
