import {Injectable} from '@angular/core';
import {GridService} from '../../shared/grid/grid.service';
import {UserSettingsService} from "../../user/core/user-settings.service";

@Injectable()
export class AccountGridService extends GridService {

  defaultColumns = [
    {
      dataField: 'account_no',
      caption: 'Account',
      width: 200,
    },
    {
      dataField: 'vendor.name',
      caption: 'Vendor',
      width: 200
    },
    {
      dataField: 'vendor_remit_address.vendor_location_id',
      caption: 'Vendor Remit Code',
      width: 160
    },
    {
      dataField: 'status.value',
      caption: 'Status',
      width: 160
    },
    {
      dataField: 'acct_desc',
      caption: 'Description',
      width: 160
    },
    {
      dataField: 'subclient.value',
      caption: 'Subclient',
      width: 160
    },
    {
      dataField: 'on_hold',
      caption: 'On Hold',
      cellTemplate: 'onHoldCellTemplate',
      width: 150,
      transformValue: {
        true: 'Yes',
        false: 'No'
      }
    },
    {
      dataField: 'entity.name',
      caption: 'Entity',
      width: 160
    },
    {
      dataField: 'billing_cycle',
      caption: 'Billing Cycle',
      width: 160
    },
    {
      dataField: 'billing_frequency.value',
      caption: 'Billing Frequency',
      width: 160
    },
    {
      dataField: 'country.alpha_2_code',
      caption: 'Country',
      width: 160
    },
    {
      dataField: 'currency_obj.currency',
      caption: 'Currency',
      width: 160
    },
    {
      dataField: 'region.value',
      caption: 'Region',
      width: 160
    },
    {
      caption: 'Tax Exempt',
      dataField: 'is_tax_exempt',
      cellTemplate: 'taxExemptCellTemplate',
      width: 150,
      transformValue: {
        true: 'Yes',
        false: 'No'
      },
      visible: false
    },
  ];

  constructor(public userSettings: UserSettingsService) {
    super("app.accounts.account-grid", userSettings);
  }
}
