import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as _ from "lodash";
import {FormGroup} from '@angular/forms';
import {ValidationService} from '../validation/validation.service';

@Component({
  selector: 'ca-custom-fields',
  templateUrl: './ca-custom-fields.component.html',
  styleUrls: ['./ca-custom-fields.component.scss']
})
export class CaCustomFieldsComponent implements OnInit, OnChanges {

  @Input() showCategory: false;
  readonly VALIDATION = ValidationService.MESSAGES;

  @Input() fields: Array<any>;
  @Input() categories: any = [];
  @Input() onlyRequiredCustomFields: boolean = true;
  showStar = true;
  @Input() customFieldService: any;
  @Input() customField: any;
  @Input() form: FormGroup;
  @Input() clearEnabled: boolean;

  selectedValue: any;

  constructor() {
  }

  // Workaround for angular component issue #13870
  disableAnimation = true;

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields && changes.fields.currentValue && changes.fields.currentValue.length !== changes.fields.previousValue) {
      if (changes.fields.currentValue.filter((filed) => filed['is_required']).length === 0) {
        this.onlyRequiredCustomFields = false;
        this.showStar = false;
      }
    }
  }

  toggleReqFields() {
    this.onlyRequiredCustomFields = !this.onlyRequiredCustomFields;
  }

  getObjectProperty(obj, prop) {
    return _.get(obj, prop);
  }

  handleClear(field) {
    this.form.controls[field].reset(null);
    this.selectedValue = null;
  }
}
