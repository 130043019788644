import {InvoiceFacepage} from "../../invoice/core/invoice-facepage";
import {DisputeCharge} from "../../charge/core/dispute-charge";
import {User} from "../../user/core/user";
import {LookupModel} from "../../dictionary/core/lookup.model";

export class Dispute {

  id: number;
  invoice_id: number;
  user_id: number;
  created_at: Date;
  status_id: number;
  status_changed_at: Date;
  content: string;
  dispute_id: string;
  category_id: number;
  disp_stat_dt: Date;
  dispute_withheld: any;
  invoice: InvoiceFacepage;
  dispute_charges: Array<DisputeCharge>;
  charges: Array<DisputeCharge>;
  /** Only cause dispute API on create method expects charges property */

  status_obj: LookupModel;

  withheld_status: number;
  withheld_status_obj: LookupModel;

  total_amount: number;
  disputed_amount: number;
  dispute_value_awarded: number;
  payback_amount: number;
  calculated_amount: number;

  resolution_date: Date;

  user: User;
  currency: any;
  currency_id: number;
  currency_obj: any;
  disputed_charges: number;
  appWithheld: number;
  paymentWithheld: number;

  dispute:any;
  vendor: any;
  subclient:string;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    } else {
      this.created_at = new Date();
      this.calculated_amount = 0;
      this.dispute_value_awarded = null;
      this.total_amount = 0;
      this.disputed_amount = 0;
      this.payback_amount = 0;
      this.resolution_date = null;
      this.subclient = null;
    }
  }

}
