export const customerFilterContext = {
  filters: [
    {
      field: 'company',
      label: 'Company',
      type: 'string',
      index: 1,
      visible: true
    },
    {
      field: 'account_number',
      label: 'Account Number',
      type: 'string',
      index: 2,
      visible: true
    },
    {
      field: 'master_customer_number',
      label: 'Master Number',
      type: 'string',
      index: 3,
      visible: true
    },
    {
      field: 'corporate_address',
      label: 'Corporate Address',
      editor: 'address',
      addressFields: {
        address1: {
          label: 'Address',
          field: 'corporate_address.address1'
        },
        city: {field: 'corporate_address.city'},
        subdivision: {
          field: 'corporate_address.subdivision_id',
          lookupProviderName: 'subdivision',
        },
        postal_code: {field: 'corporate_address.postal_code'}
      },
      index: 4,
      visible: true
    },
    {
      field: 'billing_address',
      label: 'Billing Address',
      editor: 'address',
      addressFields: {
        address1: {
          label: 'Address',
          field: 'billing_address.address1'
        },
        city: {field: 'billing_address.city'},
        subdivision: {
          field: 'billing_address.subdivision_id',
          lookupProviderName: 'subdivision',
        },
        postal_code: {field: 'billing_address.postal_code'}
      },
      index: 5,
      visible: true
    },
    {
      field: 'office_phone',
      label: 'Phone',
      type: 'string',
      exactMatch: false,
      matchCase: false,
      index: 6,
      visible: true
    }
  ]
};
