<mat-toolbar color="accent" mat-scroll-shrink>

  <span class="sneak-peek-title">{{ entry?.unique_id }}</span>

  <span class="example-fill-remaining-space"></span>

  <button mat-icon-button (click)="closeSide()">
    <ca-icon [key]="'CLOSE'"></ca-icon>
  </button>
</mat-toolbar>
<div class="ca-card ca-card-sneak-peek">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>SPID</label>
          <p>
            {{ entry?.sp_ckt || 'N/A'}}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Billed MRC</label>
          <p>
            {{ entry?.billed_mrc | caCurrency: entry?.currency }}
          </p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Inventory MRC</label>
          <p>
            {{ entry?.inventory_mrc | caCurrency: entry?.currency }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Type</label>
          <p>
            {{ entry?.result_type }}
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
