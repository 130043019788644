<div class="mat-dialog-top-spacer"></div>

<mat-dialog-content class="ca-dialog-content">

  <div mat-dialog-content class="ca-dialog-content">
    <div class="mat-dialog-top-spacer"></div>

    <div class="ca-dialog-title" style="padding-left: 0">
      <h1 mat-dialog-title>VAT WARNING</h1>
    </div>
    <div>{{bodyText}}</div>
  </div>

</mat-dialog-content>

<!-- <div mat-dialog-content class="ca-dialog-content">
  {{bodyText}}
</div> -->

<div class="mat-dialog-top-spacer"></div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="submit" (click)="cancel()">
    Cancel
  </button>
  <button mat-button (click)="proceed()">{{proceedButtonLabel}}</button>
</mat-dialog-actions>
