import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { LOOKUP_ENUM } from '../../dictionary/core/lookup.enum';

/*
 * Returning proper contract failure reason
*/
@Pipe({ name: 'contractQuoteFailureReason' })
@Injectable()
export class ContractQuoteFailureReason implements PipeTransform {
  transform(value: number): any {
    if (value === LOOKUP_ENUM.CONTRACT_QUOTE_FAILURE_REASON.NO_BUILDING) {
      return 'No building'
    } else if (value === LOOKUP_ENUM.CONTRACT_QUOTE_FAILURE_REASON.NO_QUOTE_BUILDING) {
      return 'No geo id'
    } else if (value === LOOKUP_ENUM.CONTRACT_QUOTE_FAILURE_REASON.NO_RATE) {
      return 'No rate'
    }

    return 'No rate';
  }
}
