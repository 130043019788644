import {Injectable} from '@angular/core';
import {ResourceService} from './resource.service';
import {map} from "rxjs/operators";

@Injectable()
export class ResourceManager {
  resources = [];

  constructor(
    public resourceService: ResourceService
  ) {

  }

  findAll() {
    return this.resourceService.findAll()
      .pipe(map((resources) => {
        this.resources = resources;
        return this.resources.map(resource => {
          const actionKeys = Object.keys(resource.actions);
          const actions = [];
          actions.push({key: 'ALL', caption: 'All', selected: false});
          actionKeys.forEach((key) => {
            actions.push({key, caption: resource['actions'][key], selected: false})
          });
          return {
            resource_id: resource.id,
            name: resource.name,
            key: resource.key,
            actions
          }
        });
      }))
  }
}
