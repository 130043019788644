import {Component, OnInit, Input} from '@angular/core';
import {BrandService} from './brand.service';


@Component({
  selector: 'ca-brand',
  templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit {

  private _key;

  get key() {
    return this._key;
  }
  @Input()
  set key(value) {
    this._key = value;
    if (value) {
      this.loadBrand();
    }
  }

  @Input() public css;
  @Input() public innerClass;
  public selector;

  constructor(public brandService: BrandService) {
  }

  ngOnInit() {

  }

  loadBrand() {
    this.brandService.get(this._key)
      .subscribe((result) => {
        this.selector = "url('" + result.url + "')";
        if (this.css) {
          this.selector += this.css;
        }
      }, (err) => {
        console.log(err)
      });
  }

}
