import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class DisconnectOrderGridService extends GridService {


    constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
        super("app.orders.disconnect-order-grid", userSettings);
    }

    defaultColumns = [
        {
            caption: 'BAN',
            dataField: 'ban',
            allowEditing: false,
            width: 120
        },
        {
            caption: 'Description',
            dataField: 'description',
            headerCellTemplate: 'headerTemplate',
            allowEditing: true,
            width: 170
        },
        {
            caption: 'Expedited Order',
            dataField: 'expedited_order',
            headerCellTemplate: 'headerTemplate',
            allowEditing: true,
            dataType:"boolean",
            width: 120
        },
        {
            caption: "ID",
            dataField: "unique_id",
            allowEditing: false,
            width: 250
        },
        {
            caption: "Type",
            dataField: "type.value",
            allowEditing: false,
            width: 130
        },
        {
            caption: "Customer Service ID",
            dataField: "customer_inventory.customer_circuit_id",
            allowEditing: false,
            width: 150
        },
        {
            caption: "Site A (Carrier Location)",
            dataField: "siteA.site_id",
            allowEditing: false,
            width: 200
        },
        {
            caption: "Site Z (Customer Location)",
            dataField: "siteZ.site_id",
            allowEditing: false,
            width: 200
        },
        {
            caption: "Status",
            dataField: "inventory_status.value",
            allowEditing: false,
            width: 130
        },
        {
            caption: "Internal ID",
            dataField: "internal_id",
            allowEditing: false,
            width: 200
        },
        {
            caption: "MRC",
            dataField: "est_mrc",
            allowEditing: false,
            cellTemplate: 'currencyTemplate',
            width: 100
        },
        {
            caption: "NRC",
            dataField: "est_nrc",
            cellTemplate: 'currencyTemplate',
            allowEditing: false,
            width: 100
        },
        {
            caption: "Term (Months)",
            dataField: "term",
            allowEditing: false,
            width: 130
        }

    ];
}
